<template>
  <!-- 
    Компонент "Питання та відповіді", дозволяє - 
    - переглянути список категорій, питань (скористуватись пошуком)
    - додати категорію та пост faq
    - та багато іншого :)
  -->

  <div>
    <!-- Шапка -->
    <section id="kb-category-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage:`url(${require('@/assets/images/back/back_faq_1.png')})`}"
      >
      <div class="row btn-dot">
        <div class="col text-start dropdown">
            <a
              id="dropdownMenuLink2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="ri-more-fill fs-17"></i>
            </a>
            <ul
              class="dropdown-menu dropdown-menu-start"
              aria-labelledby="dropdownMenuLink2"
            >

              <!-- 
                Кнопка "Додати категорію" 
                - надає змогу створити категорію
                - йде перевірка на права доступу під номером 1001 (створення питання та віповіді) та 2000 (режим бога)
              -->
              <li>
                <a
                  v-if="perms[1001] || perms[2000]"
                  class="dropdown-item"
                  @click="showModalAdd = true"
                >
                  <i class="bx bx-folder-plus me-2 align-middle"></i>
                  {{ $t('addCategory') }}
                </a>
              </li>

              <!-- 
                Кнопка "Додати FAQ" 
                - надає змогу створити пост з питанням та відповіді
                - йде перевірка на права доступу під номером 1001 (створення питання та віповіді) та 2000 (режим бога)
              -->
              <li>
                <a
                  v-if="perms[1001] || perms[2000]"
                  class="dropdown-item"
                  @click="showModalPost = true"
                >
                  <i class="bx bxs-plus-circle me-2 align-middle"></i>
                  {{ $t('addFaq') }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Заголовок розділу та поле для введення пошукового запиту -->
        <b-card-body class="card-body" style="text-align: left;">
          <h2 class="text-h2 fw-semibold">
            {{ $t('qua_and_ans') }}
          </h2>

          <!-- Популярні запити -->
          <b-card-text class="mb-2">
            <span class="text-white">{{ $t('Popular_queries') }}: </span>
            <span class="font-weight-bolder">
              <template v-for="item in listPopQuer" :key="item">
                <span 
                  class="badge bg-secondary itemPop"
                  @click="this.objParamsPost.search = item.label;this.getPosts()"
                >
                  {{ item.label }}
                </span>
              </template>
            </span>
          </b-card-text>

          <!-- Поле пошуку -->
          <b-form class="kb-search-input" style="max-width: 500px;">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="searchbar"
                v-model="this.objParamsPost.search"
                @input="searchData($event)"
                :placeholder="$t('search_word')"
              />
            </b-input-group>
          </b-form>

          <!-- Результати пошуку -->
          <div :class="`mt-1 dropdown-menu dropdown-menu-lg ${this.objParamsPost.search ? 'show': ''}`"  >
            <div class="scroll_s">
              <div class="dropdown-header" >
                <h5 class="text-overflow text-muted mb-0 text-uppercase"><b>{{ $t('SearchResults') }}</b></h5>
              </div>

              <!-- Список результатів пошуку -->
              <div 
                v-for="item in searchPosts" 
                :key="item" 
                class="card-header align-items-center d-flex border-bottom-dashed" 
                style="padding:0.5rem 1rem!important"
              >
                <h4 @click="open(item, item.categoryId)" class="card-title mb-0 flex-grow-1 copy history_of_numbers">
                  <!-- Назва поста -->
                  {{item.question}}<br />
                  <!-- Назва категорії -->
                  <span class="tt_title_s fs-11 text-muted">{{ nameCategory(item.categoryId) }}</span>
                </h4>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </section>

    <!-- Розділ питань та відповідей -->
    <section id="faq-tabs" class="faq-tabs" >
      <div class="row">
        <!-- Ліва частина - список категорій -->
        <div class="col-sm-12 col-md-4">
          <div class="list-group" id="list-tab" role="tablist">

            <!-- Елементи списку категорій -->
            <a 
              @click="this.currentIdx = ''" 
              class="list-group-item list-group-item-action" 
              id="list-profile-list" 
              data-bs-toggle="list" 
              :href="'#list-' + idx" 
              role="tab" 
              aria-controls="list-profile" 
              v-for="category, idx in this.categories" 
              :key="idx" 
              :class="{'active': this.currentIdx == idx}"
            >
              <div style="display: grid; grid-template-columns: 9fr 1fr; justify-content: center; align-items: center;">
                <!-- Назва категорії -->
                <div>
                  {{  category.categoryName }}
                </div>

                <!-- 
                  Кнопки додавання, редагування та видалення 
                  - йде перевірка на права доступу під номером 1001 (створення), 1002 (видалення), 1003 (редагування) та 2000 (режим бога)
                -->
                <div class="flex-shrink-0" v-if="this.perms[1001] || this.perms[1002] || this.perms[1003] || this.perms[2000]">
                  <div class="dropdown">
                    <button class="btn btn-soft-white btn-icon btn-sm fs-14" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">

                      <!-- Додати пост -->
                      <button v-if="perms[1001]" class="dropdown-item" @click="add(category)">{{ $t('Add') }}</button> 

                      <!-- Редагувати категорію -->
                      <button v-if="perms[1003]" class="dropdown-item" @click="editCat(category)">{{ $t('Edit') }}</button>

                      <!-- Видалити категорію -->
                      <button v-if="perms[1002]" class="dropdown-item" @click="removeCat(category)">{{ $t('Delete') }}</button>

                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <!-- Права частина - відображення постів категорій -->
        <div class="col-sm-12 col-md-8">
          <div class="tab-content" id="nav-tabContent">
            <!-- Запитання та відповіді категорій -->
            <div 
              v-for="(category, idx) in this.categories" 
              :key="idx" 
              class="tab-pane fade" 
              :id="'list-' + idx" 
              role="tabpanel" 
              aria-labelledby="list-profile-list" 
              :class="{'active show': this.currentIdx == idx}"
            >
              <!-- Компонент списку питань -->
              <FaqQuestionAnswer 
                @open="open" 
                :obj="category" 
                :options="category.posts" 
                @getData="this.getdata()" 
                @edit="edit" 
                style="overflow: hidden !important;"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div> 

  <!-- 
    Виклик відкриття картки поста з faq
    - для відкриття застосовується перевірка значення faqShow
    - @close - подія, яка спрацьовує при закритті картки
    - :obj - параметр-об'єкт з даними по картці поста
    - :categories - параметр з категоріями
    - @edit - подія, яка вмикає режим редагування
    - @getdata - подія, яка спрацьовує при отриманні даних/оновлення 
  -->
  <faqView 
    v-if="faqShow == true" 
    @close="faqShow = false" 
    :obj="this.obj" 
    :categories="this.categories"
    @edit="edit" 
    @getdata="this.getdata()"
  />

  <!-- 
    Виклик вікна створення категорії
    - для відкриття застосовується перевірка значення showModalAdd
    - @close - подія, яка спрацьовує при закритті картки
    - @getdata - подія, яка спрацьовує при отриманні даних/оновлення 
    - :obj - параметр-об'єкт з даними
    - :perm - параметр-прапорець режиму редагування
    - @emoji - подія, яка відкриває вікно вибору іконки
  -->
  <addCateg 
    v-if="showModalAdd" 
    @emoji="emojiFirstStage" 
    @getData="this.getdata()" 
    :obj = 'this.obj' 
    :perm="this.permEdit"  
    @close="closeWithPerma()"
  />

  <!-- 
    Виклик вікна створення картки поста
    - для відкриття застосовується перевірка значення showModalPost
    - @close - подія, яка спрацьовує при закритті картки
    - :objCategory - параметр з даними категорії
    - :categories - масив категорій
    - :edit_perm - параметр-прапорець режиму редагування
    - :obj - параметр-об'єкт з даними 
    - @getdata - подія, яка спрацьовує при отриманні даних/оновлення 
  -->
  <addFAQ 
    v-if="showModalPost" 
    @close="closeAddModal()" 
    :objCategory="this.category" 
    :categories="this.categories" 
    :edit_perm="this.editPerm" 
    :obj = 'this.obj' 
    @getData="this.getdata()"
  />
  

  <!-- 
    Модальне вікно підтвердження видалення поста
  -->
  <dialogdelete 
    v-if="this.delmodal === true" 
    :obj="this.obj" 
    @remove="remove" 
    @close="delmodal = false"
  />

  <!-- Модальне вікно переміщення уроків при видалені -->
  <dialogDeleteCateg 
    v-if="this.delmodalWithLessons === true" 
    :categories="this.categories" 
    :objdata="this.obj" 
    @remove="remove" 
    @close="delmodalWithLessons = false" 
    @getdata = "this.getdata()"
  />
    
</template>

<script>
import dialogDeleteCateg from './actions/delcategmodal' // компонент діалогового вікна видалення категорії
import dialogdelete from './actions/dialogdelete' // компонент діалогового вікна видалення
import faqView from './view/index' // картка поста faq
import addCateg from '@/components/faq/actions/addCategory' // картка створення категорії
import addFAQ from '@/components/faq/actions/addPost' // картка створення поста
import FaqQuestionAnswer from './list.vue' // компонент відображення списку постів до категорії
import { FAQ } from '@/API.js' // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new FAQ();

export default {
  components: {
    dialogDeleteCateg,
    dialogdelete,
    FaqQuestionAnswer,
    addCateg,
    addFAQ,
    faqView
  },
  data() {
    return {
      currentIdx: 0, // Поточний індекс для списку категорій
      searchTime: '', // Змінна для відстеження часу при пошуку
      delmodalWithLessons: false, // прапорець відображення вікна переміщення уроків при видалені категорії
      delmodal: false, // прапорець відображення видалення поста
      faqShow: false, // прапорець відображення вікна поста
      categories: [], // Список категорій
      permEdit: false,  // Триггер для редагування категорій
      editPerm: false, // прапорець режиму редагування
      objParams: { // Параметри для фільтрації даних по категоріям
        page: '1',
        pagelimit: '10',
        search: '',
        lang: '',
        tags: ''
      },
      showModalAdd: false,  // прапорець відображення вікна створення категорії
      showModalPost: false,  // прапорець відображення вікна створення поста
      category: '', // Змінна для категорії
      searchPosts: [], // Список постів під час пошуку
      objParamsPost: { // Параметри для фільтрації даних по постам
        pagelimit: '200',
        search: ''
      },
      listPopQuer: [  // Список популярних запитань
        { label: 'ПРРО' },
        { label: this.$t('CashDesk') },
        { label: this.$t('device_printer') },
        { label: this.$t('terminal') },
      ]
    };
  },
  created() {
    // Ініціалізація даних під час створення компонента
    this.getdata();
  },
  methods: {
    add(e){
      /**
       * Додавання поста
       * @param {Object} e - категорія.
      */

      this.category = e;
      this.showModalPost = true
    },
    closeAddModal(){
      // Закриття модального вікна додавання поста FAQ

      this.category = '';
      this.editPerm = false;
      this.showModalPost = false
    },
    getPosts(){
      // Отримання постів FAQ з сервера

      apiServe.getPosts(this.objParamsPost).then(result => {
        if(result.status == 'done'){
          this.searchPosts = result.data.items;
        } else {
          this.$toast.error(this.$t('error') + " " + result.error[0]);

          var errorText = 'Помилка отримання постів FAQ'; 
          var statusRequest = result.status;
          var api = 'getPosts';
          var fileRequest = 'src/components/faq/index.vue';
          var params = this.objParamsPost;
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    nameCategory(item){
      // Отримання назви категорії за ідентифікатором

      var name = "";
      this.categories.forEach((el) => {
          if(el.categoryId === item) {
              name = el.categoryName
          }
      })
      return name
    },
    closeWithPerma() {
      // Закриття модального вікна при зміні пермісів

      this.permEdit = false;
      this.showModalAdd = false //закриття редагування зі зміненими пермсами для виправлення багів
    },
    remove(e){
      /**
       * Видалення категорії
       * @param {Object} e - категорія.
      */

      apiServe.deleteCateg(e.categoryId).then(result => {
        if(result.status === 'done') {
          this.$toast.success(this.$t('Removed'));
          this.getdata()
        } else {
          this.$toast.error(this.$t('error') + " " + result.error[0]);

          var errorText = 'Помилка видалення категорії'; 
          var statusRequest = result.status;
          var api = 'deleteCateg';
          var fileRequest = 'src/components/faq/index.vue';
          var params = e.categoryId;
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    removeCat(e) {
      // Видалення категорії з перевіркою наявності постів

      this.obj = e;

      if(this.obj.posts.length > 0) {
        this.delmodalWithLessons = true
      } else {
        this.delmodal = true //удалить реквест
      }
    },
    editCat(e) {
      // Редагування категорії

      this.permEdit = true
      this.obj = e;
      this.showModalAdd = true //изменить категорию
    },
    closeModal() {
      // Закриття модального вікна

      this.editPerm = false;
      this.showModalPost = false; //хитромудрі перміси і закриття модальки. Баги, ненавиджу їх
    },
    edit(e) {
      // Редагування запитання та відповіді

      this.obj = e;
      this.editPerm = true;
      this.showModalPost = true; //підготовка до змін та перміси. Легасі код - топ
    },
    open(e) {
      /**
        Функція для відкриття модального вікна з інформацією про пост за його ідентифікатором.
        * @param {Object} e - об'єкт картки поста

        Викликає API для отримання інформації про пост FAQ.
        Результат присвоюється властивості obj, та встановлюється прапорці faqShow в true для відображення модального вікна.
      */
      
      apiServe.getPost(e.postId).then(result => {
        if(result.status === 'done') {
          this.obj = result.data
          this.faqShow = true
        } else {
          this.$toast.error(this.$t('error') + " " + result.error[0]);

          var errorText = 'Помилка отримання картки поста FAQ'; 
          var statusRequest = result.status;
          var api = 'getPost';
          var fileRequest = 'src/components/faq/index.vue';
          var params = e.postId;
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    getdata(){
      /**
        Отримуємо дані про категорії та пости з API та встановлює їх у властивості компонента.
      */

      this.categories = [];
      this.objParams.lang = storeS.lang; // фільтр мови 

      apiServe.getCateg(this.objParams).then(result => {
        if(result.status === 'done'){
          result.data.items.forEach((el)=> {
            let lesson = {posts: []}
            Object.assign(el, lesson)
            this.categories.push(el)
          })
          apiServe.getPosts({pagelimit: 200}).then(result=>{
            if(result.status == 'done'){
              result.data.items.forEach((el)=>{
                let current = el
                if(this.categories.find((element)=> element.categoryId == current.categoryId)) {
                  this.categories[(this.categories.indexOf(this.categories.find((k)=> k.categoryId == current.categoryId)))].posts.push(current)
                } 
              })
            }
          })
        }
      })
    },
    searchData(e) {
      // Пошук даних за введеним запитом

      clearTimeout(this.searchTime)
      this.objParamsPost.search = e;
      this.searchTime = setTimeout(() => this.getPosts(), 500)
    },
  },
  mounted() {
    // Додавання слухача подій для клавіші Esc
    window.addEventListener('keydown', this.escKeyEvent);
    
    // Cлухання події saveCateg для оновлення даних після збереження категорії
    this.eventBus.off('saveCateg')
    this.eventBus.on('saveCateg', (status) => {
      if(status == true) {
        this.getdata();
      }
    }),
      
    // Cлухання події saveFAQ для оновлення даних після збереження поста
    this.eventBus.off('saveFAQ')
    this.eventBus.on('saveFAQ', (status) => {
      if(status == true) {
        this.getdata();
      }
    })
  },
  computed: {
    perms() {
      /*
        Повертає дані прав доступу користувача з глобального стору.
      */
      return storeS.perms
    },
    user(){
      /*
        Повертає дані користувача з глобального стору.
      */
      return storeS.userbase
    }
  }
};
</script>

<style scoped>
.knowledge-base-bg .card-body{
    padding: 70px;
    /* color: white */
}
.btn-dot {
  margin-top:20px;
  margin-left: 20px;
  color:white;
}
/* .btn-dot #dropdownMenuLink2{
  color:white;
} */
.text-h2 {
    color: #d1e1ff;
    font-size: 37px;
    font-weight: bold;
}
.text-wrap {
  white-space: pre-line;
  word-wrap: break-word;
}
.faq-tabs .nav-link {
  padding: 12px 16px;
}
#faq-tabs.faq-tabs{
  margin-bottom: 70px !important
}
.itemPop {
  margin: 0 3px;
  font-size: 12px;
  cursor: pointer;
}
</style>