<template>
    <!-- 
        Компонент модального вікна середнього розміру
    -->

    <!-- 
        Використовуємо Teleport щоб винести компонент за межі їхнього власного DOM.
        - В даному випадку, компонент, буде переміщений в елемент body документу. 
    -->
    <teleport to="body">
        <div class="modal fade flip show" style="display: block;" aria-modal="true" role="dialog" :style="this.styleAdd" v-touch:swipe="swipeHandler">
            <div :class="`modal-dialog modal-dialog-scrollable modal-${sizeFullModal ? 'fullscreen' : 'xl'} modal-dialog-centered`">
                <div class="modal-content">
                    <div class="modal-headers">

                        <!-- Верхня частина модального вікна з назвою та кнопками -->
                        <div class="border-bottom border-bottom-dashed" style="padding:10px 20px" >
                            <div class="row" >
                                <div class="col" >
                                    <!-- Назва модального вікна -->
                                    <h5 class="modal-title">
                                        <slot name="status"></slot> 
                                    </h5>
                                    <h5 class="modal-title" id="fullscreeexampleModalLabel">
                                        <!-- Назва модального вікна, передана через параметр title -->
                                        <slot name="title">{{title}}</slot>
                                    </h5>
                                </div>
                                <div class="col-auto" >

                                    <!-- Кнопки у верхньому правому куті для попереднього, наступного та закриття модального вікна -->
                                    <div class="hstack gap-sm-1 gap-1 align-items-center flex-wrap email-topbar-link" >

                                        <!-- Кнопка попередньої позиції, "<" -->
                                        <button 
                                            v-if="this.ifPrevopen" 
                                            @click="prevOpen" 
                                            type="button" 
                                            class="btn btn-soft-primary waves-effect waves-light" 
                                            style="margin-right: 5px;"
                                        >
                                            <i class="bx bx-left-arrow-alt"></i>
                                        </button>

                                        <!-- Кнопка наступної позиції, ">" -->
                                        <button 
                                            v-if="this.ifNextopen" 
                                            @click="nextOpen" 
                                            type="button" 
                                            class="btn btn-soft-primary waves-effect waves-light" 
                                            style="margin-right: 5px;"
                                        >
                                            <i class="bx bx-right-arrow-alt"></i>
                                        </button>

                                        <!-- Інші кнопки, які будуть передані через слот -->
                                        <slot name="head-button"></slot>

                                        <!-- Кнопка закриття вікна, "x" -->
                                        <button 
                                            type="button" 
                                            class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  
                                            @click="close" 
                                            style="margin-right: 5px;"
                                        >
                                            <i class="ri-close-fill align-bottom"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Основний вміст модального вікна -->
                    <div class="modal-body" style="background: var(--vz-body-bg);min-height: 300px;">
                        <slot name="body"></slot>
                    </div>

                    <!-- Нижня частина модального вікна з кнопками -->
                    <div class="modal-footer">
                        <!-- Кнопки, які будуть передані через слот -->
                        <slot name="footer-bottom"></slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import { storeS } from "@/store"; // глобальне сховище даних

export default ({
    data() {
        return {
            sizeFullModal: false
        };
    },
    // Параметри, передані в компонент через властивості
    props:['title', 'ifPrevopen', 'ifNextopen', 'styleAdd', 'sizeModal'],
    created() {
        // Додавання класу модального вікна в масив відкритих модалок
        storeS.modalsOpen.push('modal-lg')

        // Додавання обробників клавіш "Esc"
        this.eventKey.on('esc', () => {
            console.log('1', storeS.modalsOpen)
            if(storeS.modalsOpen[storeS.modalsOpen.length - 1] == 'modal-lg' && !document.querySelector('#preloader')) {
                storeS.modalsOpen.pop()
                this.close()
            }
        });
        
        // Додавання обробників клавіш "Вліво"
        this.eventKey.off('arrowleft')
        this.eventKey.on('arrowleft', () => {
            if(this.ifPrevopen){
                this.prevOpen()
            }
        })

        // Додавання обробників клавіш "Вправо"
        this.eventKey.off('arrowright')
        this.eventKey.on('arrowright', () => {
            if(this.ifNextopen){
                this.nextOpen()
            }
        })

        // if(this.sizeModal){
            this.sizeFullModal = this.sizeModal
        // }
    },
    methods: {
        swipeHandler(event){
            // Обробник події свайпу

            if(this.checks['024']){
                if(this.ifNextopen && event == 'right'){
                    if (window.screen.width <= 767) {
                        this.$emit('nextOpen')
                    }
                } else if(this.ifPrevopen && event == 'left'){
                    if (window.screen.width <= 767) {
                        this.$emit('prevOpen')
                    }
                }
            }
        },
        close: function(){
            // Закриття модального вікна
            this.$emit('close');
        },
        prevOpen(){
            // Перехід до попереднього відкритого елемента
            this.$emit('prevOpen')
        },
        nextOpen(){
            // Перехід до наступного відкритого елемента
            this.$emit('nextOpen')
        },
    },
    computed: {
        checks(){
            /*
                Повертає дані налаштувань користувача з глобального стору.
            */
            return storeS.checks
        },
        scale(){
            return this.maxHeight(window.devicePixelRatio)
        },
        zoom() {
            return window.devicePixelRatio
        }
    },
})
</script>

<style scoped>
.modal {
  overflow: auto;
  backdrop-filter: blur(5px);
}

.modal-body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.modal-body::-webkit-scrollbar
{
	width: 5px;
    height: 5px;
	background-color: #F5F5F5;
}

.modal-body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
.modal-fullscreen {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: -webkit-fill-available;
    margin: 20px;
}
.modal-fullscreen .modal-content {
    border-radius: 15px;
}
.modal {
    background: #2e2e2eb8;
}
@media (max-width: 768px) {
    .modal-fullscreen {
        margin: 0;
    }
    .modal-fullscreen .modal-content {
        border-radius: 0px;
    }
}
</style>
