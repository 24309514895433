<template>
    <!-- Телепортуємо модальне вікно до тіла документа -->
    <teleport to="body">
        <!-- Модальне вікно -->
        <div class="modal fade show" style="display: block;" ref="modal" aria-modal="true" aria-labelledby="modal-headline">
            <!-- Діалогове вікно -->
            <div class="modal-dialog modal-fullscreen">
                <!-- Зміст модального вікна -->
                <div class="modal-content">
                    <!-- Заголовок модального вікна -->
                    <div class="modal-headers">
                        <div class="border-bottom border-bottom-dashed" style="padding:10px 20px" >
                            <div class="row" >
                                <!-- Лівий заголовок -->
                                <div class="col" >
                                    <h5 class="modal-title" id="fullscreeexampleModalLabel">
                                        <!-- Слот для лівого заголовка -->
                                        <slot name="lefthead"></slot> 
                                        <!-- Заголовок модального вікна -->
                                        {{title}} 
                                        <!-- Слот для статусу -->
                                        <slot name="status"></slot>
                                    </h5> 
                                </div>
                                <!-- Правий заголовок -->
                                <div class="col-auto" >
                                    <!-- Кнопки навігації -->
                                    <div class="hstack gap-sm-1 gap-1 align-items-center flex-wrap email-topbar-link" >
                                        <!-- Кнопка попереднього елементу -->
                                        <button v-if="this.ifPrevopen" @click="prevOpen" type="button" class="btn btn-soft-primary waves-effect waves-light">
                                            <i class="bx bx-left-arrow-alt"></i>
                                        </button>
                                        <!-- Кнопка наступного елементу -->
                                        <button v-if="this.ifNextopen" @click="nextOpen" type="button" class="btn btn-soft-primary waves-effect waves-light">
                                            <i class="bx bx-right-arrow-alt"></i>
                                        </button>
                                        <!-- Слот для нижнього заголовка -->
                                        <slot name="head-bottom"></slot>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Тіло модального вікна -->
                    <div class="modal-body" :id="idModal == null ? 'noneid' : idModal" :style="stylebody == null ? '' : 'background: var(--vz-body-bg)'">
                        <!-- Слот для тіла модального вікна -->
                        <slot name="body"></slot>
                        <!-- Компонент autotag -->
                        <!-- <autotag></autotag> -->
                    </div>
                    <!-- Нижній колонтитул модального вікна -->
                    <div class="modal-footer">
                        <!-- Слот для нижнього колонтитулу -->
                        <slot name="footer-bottom"></slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
    
</template>

<script>
// Імпортуємо store з файлу store.js
import { storeS } from '@/store'
import { webviewCheck } from '@/usabilityScripts/webViewCheck.js'

// Описуємо компонент
export default ({
    // Дані компонента
    data() {
        return {
            // Ідентифікатор компонента
            component_id: null
        };
    },
    // Властивості компонента
    props: [
        // Флаг відкриття модального вікна
        'showModal',
        // Заголовок модального вікна
        'title',
        // Флаг відкриття попереднього елементу
        'ifPrevopen',
        // Флаг відкриття наступного елементу
        'ifNextopen',
        // Стиль тіла модального вікна
        'stylebody',
        // Ідентифікатор модального вікна
        'idModal'
    ],
    // Методи, які викликаються при створенні компонента
    created() {
        // Додати модальне вікно до списку відкритих модальних вікон
        storeS.modalsOpen.push('modal')
        
        // Встановити слухачі подій
        this.eventKey.off('esc')
        this.eventKey.on('esc', () => {
            // Закрити модальне вікно, якщо воно є останнім у списку відкритих модальних вікон
            if(storeS.modalsOpen[storeS.modalsOpen.length - 1] == 'modal' && !document.querySelector('#preloader')) {
                storeS.modalsOpen.pop()
                this.close()
            }
        }); 
        
        // Встановити слухачі подій для стрілок навігації
        this.eventKey.off('arrowleft')
        this.eventKey.on('arrowleft', () => {
            // Перейти до попереднього елементу, якщо кнопка попереднього елементу є активною
            if(this.ifPrevopen){
                this.prevOpen()
            }
        })
        this.eventKey.off('arrowright')
        this.eventKey.on('arrowright', () => {
            // Перейти до наступного елементу, якщо кнопка наступного елементу є активною
            if(this.ifNextopen){
                this.nextOpen()
            }
        })

        // Встановити слухач події для надсилання форми
        this.eventKey.off('sendForm')
        this.eventKey.on('sendForm', () => {
            // Надіслати форму
            this.send()
        });
    },
    // Методи компонента
    methods: {
        androidFixMono () {
        // Адаптація інсайду під камеру на Android та IOS

        // Перевірка, чи використовується веб-в'ю на Android або iOS
        if(webviewCheck() == 'android_webview' || webviewCheck() == 'ios_webview') {
            // Додавання класу для фіксації висоти бічного меню
            document.querySelector('.modal-headers').classList.add('fixWindow')
        } else {
            if(document.querySelector('.modal-headers')) {
            // Видалення класу для відміни фіксації висоти бічного меню
            document.querySelector('.modal-headers').classList.remove('fixWindow')
            }
        }
        },
        // Надіслати форму
        send() {
            this.$emit('send')
        },
        // Закрити модальне вікно
        close: function(){
            this.$emit('close');
        },
        // Відкрити попередній елемент
        prevOpen(){
            this.$emit('prevOpen')
        },
        // Відкрити наступний елемент
        nextOpen(){
            this.$emit('nextOpen')
        },
    },
    // Метод, який викликається після монтування компонента
    mounted(){
        
    },
})
</script>

<style scoped>
.modal-fullscreen {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: -webkit-fill-available;
    margin: 20px;
}
.modal-fullscreen .modal-content {
    border-radius: 15px;
}
.modal {
    background: #2e2e2eb8;
}

.modal-body{
    overflow: auto;
}
.modal-body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.modal-body::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.modal-body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
@media (max-width: 768px) {
    .modal-fullscreen {
        margin: 0;
    }
    .modal-fullscreen .modal-content {
        border-radius: 0px;
    }
}
@media (orientation: portrait) {
  .fixWindow {
    padding-top: 30px !important;
  }
}
</style>
