<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('decidedStatus') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radiodecidedStatus" id="all_decidedStatus" v-model="decidedStatus" :value="''">
                    <label class="form-check-label" for="all_decidedStatus">{{ $t('all') }}</label>
                </div>
            </div>
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radiodecidedStatus" :id="'decidedStatus'+index" v-model="decidedStatus" :value="value.value">
                        <label class="form-check-label" :for="'decidedStatus'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { storeS } from '@/store';

export default {
    data(){
        return {
            decidedStatus: "",
            opt: [
                {
                    name: this.$t('waitTesting'),
                    value: "waittesting",
                },
                {
                    name: this.$t('waitRelease'),
                    value: "waitrelease",
                },
                {
                    name: this.$t('inRelease'),
                    value: "inrelease",
                },
                {
                    name: this.$t('noProblem'),
                    value: "noProblem",
                }
            ]
        }
    },
    methods: {
        change(){
            this.$emit('change', 'decidedStatus', this.decidedStatus)
        },
    },
    computed: {
        // providers(){
        //     return storeS.providers
        // }
    }
}
</script>