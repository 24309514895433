<template>
  <!-- 
    Компонент "База знань", дозволяє - 
    - переглянути список категорій, уроків (скористуватись пошуком)
    - додати категорію та уроки
    - та багато іншого :)
  -->

  <div>
    <!-- Шапка -->
    <section id="kb-category-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage:`url(${require('@/assets/images/back/back_faq_1.png')})`}"
      >
      <div class="row btn-dot" v-if="this.perms[802]">
          <div class="col text-start dropdown">

            <!-- Кнопка назад -->
            <router-link 
              v-if="this.$route.params.id"
              to="/knowledge-base/KnowledgeBase" 
            >
              <button type="button" class="btn btn-soft-light waves-effect fw-semibold" style="margin-right: 10px;">
                <i class="ri-arrow-left-s-line"></i>
              </button>
            </router-link>

            <!-- Дропдаун меню -->
            <button 
              type="button" 
              id="dropdownMenuLink2"
              data-bs-toggle="dropdown"
              aria-expanded="false" 
              class="btn btn-soft-success waves-effect fw-semibold" 
              style="margin-right: 10px;"
            >
              <i class="ri-add-line"></i>
            </button>
            <ul
              class="dropdown-menu dropdown-menu-start"
              aria-labelledby="dropdownMenuLink2"
            >

              <!-- 
                Кнопка "Додати категорію" 
                - надає змогу створити категорію
                - йде перевірка на права доступу під номером 802 (створення уроку/категорії) та 2000 (режим бога)
              -->
              <li>
                <a
                  v-if="perms[802] || perms[2000]"
                  class="dropdown-item"
                  @click="showModalAdd = true"
                >
                  <i class="bx bx-folder-plus me-2 align-middle"></i>
                  {{ $t('addCategory') }}
                </a>
              </li>

              <!-- 
                Кнопка "Додати Урок" 
                - надає змогу створити урок
                - йде перевірка на права доступу під номером 802 (створення уроку/категорії) та 2000 (режим бога)
              -->
              <li>
                <a
                  v-if="perms[802] || perms[2000]"
                  class="dropdown-item"
                  @click="showModalPost = true"
                >
                  <i class="bx bxs-plus-circle me-2 align-middle"></i>
                  {{ $t('addLesson') }}
                </a>
              </li>
              
            </ul>
          </div>
        </div>

        <!-- Заголовок розділу та поле для введення пошукового запиту -->
        <b-card-body class="card-body" style="text-align: left;">
          <h2 class="text-h2">
            {{ $t('Knowledge_base') }} {{ this.$route.params.title }} | {{ countLess }} {{ $t('lessonsCount') }}
          </h2>
          
          <!-- Популярні запити -->
          <b-card-text class="mb-2">
            <span>{{ $t('Popular_queries') }}: </span>
            <span class="font-weight-bolder">
              <template v-for="item in listPopQuer" :key="item">
                <span 
                  class="badge bg-secondary itemPop"
                  @click="this.objParamsPost.search = item.label;this.getPosts()"
                >
                  {{ item.label }}
                </span>
              </template>
            </span>
          </b-card-text>

          <!-- Поле пошуку -->
          <b-form class="kb-search-input" style="max-width: 500px;">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="searchbar"
                v-model="this.objParamsPost.search"
                @input="searchData($event)"
                :placeholder="$t('search_word')"
              />
            </b-input-group>
          </b-form>

          <!-- Результати пошуку -->
          <div :class="`mt-1 dropdown-menu dropdown-menu-lg ${this.objParamsPost.search ? 'show': ''}`"  >
            <div class="scroll_s">
              <div class="dropdown-header" >
                <h5 class="text-overflow text-muted mb-0 text-uppercase"><b>{{ $t('SearchResults') }}</b></h5>
              </div>

              <!-- Список результатів пошуку -->
              <div 
                v-for="item, idx in searchPosts" 
                :key="idx" 
                class="card-header align-items-center d-flex border-bottom-dashed" 
                style="padding:0.5rem 1rem!important"
              >
                <h4 
                  @click="open(item, item.categoryId)" 
                  class="card-title mb-0 flex-grow-1 copy history_of_numbers"
                >
                  <!-- Назва уроку -->
                  {{item.lessonName}}<br />
                  <!-- Назва категорії -->
                  <span 
                    class="tt_title_s fs-11 badge bg-info border-bottom mt-2" 
                    :style="`background-color: ${getColor(item, idx)} !important`"
                  >
                    {{ getNameKWCategory(item.categoryId) }}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </section>

    <!-- продумать мультиязычность, копирование урока на другой язык, просмотреть на каких языках есть урок todo dev -->

    <!-- Категорії -->
    <template v-for="item in categoriesTree" :key="item">
      <!-- Категорія -->
      <div class="row justify-content-center mt-5" >
        <div class="col-lg-5" >
            <div class="d-flex pb-3 mb-3 text-center" style="justify-content: center;">
              <!-- Назва категорії -->
              <h4 class=" fw-semibold mb-0 fs-20">{{ item.categoryName }}</h4>
              <!-- Додаткові дії з категорією -->
              <div class="flex-shrink-0" style="margin-left: 10px;">
                <div class="dropdown">
                  <button 
                    type="button" 
                    :class="`btn btn-soft-info btn-sm`" 
                    data-bs-toggle="dropdown"
                    aria-haspopup="true" 
                    aria-expanded="false"
                  >
                    <i class="bx bx-dots-vertical-rounded "></i></button>
                    <div class="dropdown-menu dropdown-menu-end">

                      <!-- Додати урок -->
                      <button v-if="perms[802]" class="dropdown-item" @click="addInCategory(item)">{{ $t('Add') }}</button>

                      <!-- Редагувати категорію -->
                      <button v-if="perms[803]" class="dropdown-item" @click="this.editreq(item)">{{ $t('Edit') }}</button>

                      <!-- Видалити категорію -->
                      <button v-if="perms[803]" class="dropdown-item" @click="this.delreq(item)">{{ $t('Delete') }}</button> 
                    </div>
                  </div>
              </div>
            </div>
        </div>

        <!-- Дочірні категорії -->
        <b-row v-if="item.children">
          <b-col lg="4" v-for="child in item.children" :key="child">
            <b-card>
              <div class="d-flex pb-2">
                <!-- Назва дочірньої категорії -->
                <h6 
                  class="card-title fw-semibold mb-0 flex-grow-1 fs-20 text-center copy" 
                  @click="openCategory(child)"
                >
                  {{ child.categoryName }} <span class="fs-14 badge badge-soft-info">{{ child.countLessons }}</span>
                </h6>

                <!-- Додаткові дії з доч. категорією -->
                <div class="flex-shrink-0">
                  <div class="dropdown">
                    <button 
                      type="button" 
                      :class="`btn btn-soft-info btn-sm`" 
                      data-bs-toggle="dropdown"
                      aria-haspopup="true" 
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-vertical-rounded "></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">

                      <!-- Додати урок -->
                      <button v-if="perms[802]" class="dropdown-item" @click="add(child)">{{ $t('Add') }}</button>

                      <!-- Редагувати категорію -->
                      <button v-if="perms[803]" class="dropdown-item" @click="this.editreq(child)">{{ $t('Edit') }}</button>

                      <!-- Видалити категорію урок -->
                      <button v-if="perms[803]" class="dropdown-item" @click="this.delreq(child, item)">{{ $t('Delete') }}</button> 
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </template>
  </div>

  <!-- 
    Виклик відкриття картки уроку
    - для відкриття застосовується перевірка значення showlesson
    - @close - подія, яка спрацьовує при закритті картки
    - @editLesson - подія, яка вмикає режим редагування уроку
    - :obj - параметр-об'єкт з даними по картці уроку
    - :objCategory - параметр з категоріями
    - :objLessons - параметр з уроками в категорії
    - :phone - параметр номера клієнта
    - @addLesson - подія, яка відкриває вікно створення уроку
    - @getdata - подія, яка спрацьовує при отриманні даних/оновлення 
  -->
  <lesson 
    v-if="showlesson" 
    @editLesson="editLesson" 
    @close="closeLesson()" 
    :obj="this.obj" 
    :objCategory="this.category" 
    :objLessons="objLessons" 
    :phone="this.phone"
    @getdata="this.getdata()" 
    @addLesson="showlesson = false; showModalPost = true" 
  />

  <!-- 
    Виклик вікна створення категорії
    - для відкриття застосовується перевірка значення showModalAdd
    - @close - подія, яка спрацьовує при закритті картки
    - @getdata - подія, яка спрацьовує при отриманні даних/оновлення 
    - :obj - параметр-об'єкт з даними
    - :perms_edit - параметр-прапорець режиму редагування
  -->
  <addCateg 
    v-if="showModalAdd" 
    :obj="this.obj" 
    :perms_edit="this.categ_perms" 
    @getdata="this.getdata()" 
    @close="closeEdit"
  />

  <!-- 
    Виклик вікна створення картки уроку
    - для відкриття застосовується перевірка значення showModalPost
    - @close - подія, яка спрацьовує при закритті картки
    - :objCategory - параметр з даними категорії
    - :categories - масив категорій
    - @create - подія, для отримання дерева категорій
  -->
  <addPost 
    v-if="showModalPost" 
    :categories="this.kb" 
    :objCategory="this.category" 
    @create="this.getCategoriesTree()" 
    @close="showModalPost = false"
  />

  <!-- 
    Модальне вікно підтвердження видалення уроку
  -->
  <dialogdelete 
    v-if="this.delmodal === true" 
    :obj="this.obj" 
    @remove="removeCateg" 
    @close="delmodal = false" 
    @getdata = "this.getCategoriesTree()"
  />

  <!-- Модальне вікно переміщення уроків при видалені -->
  <dialogDeleteCateg 
    v-if="this.delmodalWithLessons === true" 
    @close="delmodalWithLessons = false" 
    @getdata = "this.getCategoriesTree()" 
    :father="this.father" 
    :categoryForDelete="this.categoryForDelete" 
    :otherCateg="this.otherCategories" 
    :lessons="this.searchPosts"
  />

</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';

import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/gsqxdxog.json";

import dialogDeleteCateg from './delcategmodal' // компонент діалогового вікна видалення категорії
import dialogdelete from './actions/dialogdelete' // компонент діалогового вікна видалення
import lesson from '@/components/knowledge-base/view/index' // картка уроку
import addCateg from '@/components/knowledge-base/actions/addCategory' // картка створення категорії
import addPost from '@/components/knowledge-base/actions/addPost' // картка створення уроку
import { getNameKWCategory } from '@/usabilityScripts/globalMutate'
import { KW, knowledgeBaseProject } from '@/API.js'  // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new KW();
let apiknowledgeBaseProject = new knowledgeBaseProject();

export default {
  data() {
    return {
      father: '', // Обрана батьківська категорія для видалення
      categoryForDelete: '', // Категорія, яку потрібно видалити
      otherCategories: '', // Інші категорії для переміщення уроків
      searchTime: '', // Ідентифікатор таймауту для пошукового запиту
      delmodalWithLessons: false, // Прапорець відображення вікна видалення з уроками
      categ_perms: false, // Дозволи на редагування категорії
      delmodal: false, // Прапорець відображення вікна видалення
      showlesson: false, // Прапорець відображення вікна уроку
      kb: [], // База знань
      showModalAdd: false, // Прапорець відображення вікна створення категорії
      showModalPost: false, // Прапорець відображення вікна створення уроку
      obj: "", // Об'єкт
      objLessons: "", // Уроки
      lessons: "", // Урок
      category: "", // Обрана категорія
      objParams:{ // Параметри для фільтрації даних по категоріям
        page: '1',
        pagelimit: '100',
        search: '',
        lang: ''
      },
      searchPosts: [], // Список уроків під час пошуку
      objParamsPost: { // Параметри для фільтрації даних по урокам
        pagelimit: '200',
        search: ''
      },
      categoriesTree: [], // Дерево категорій
      defaultOptions: { animationData: animationData },  // Налаштування анімації Lottie
      countLess: 0, // Кількість уроків
      colorInUse: [], // Використані кольори для категорій
      colors: [
        { color: '#1ABC9C', title: '1ABC9C' },
        { color: '#2ECC71', title: '2ECC71' },
        { color: '#3498DB', title: '3498DB' },
        { color: '#9B59B6', title: '9B59B6' },
        { color: '#34495E', title: '34495E' },
        { color: '#F1C40F', title: 'F1C40F' },
        { color: '#F39C12', title: 'F39C12' },
        { color: '#E74C3C', title: 'E74C3C' },
        { color: '#C2185B', title: 'C2185B' },
        { color: '#BDC3C7', title: 'BDC3C7' },
        { color: '#95A5A6', title: '95A5A6' },
        { color: '#607D8B', title: '607D8B' },
      ],
      listPopQuer: [  // Список популярних запитів
        // { label: 'ПРРО' },
        // { label: this.$t('Product') },
        // { label: this.$t('resetPassword') },
        // { label: this.$t('terminal') },
      ]
    };
  },
  props:['phone'],
  components: {
      lesson,
      addCateg,
      addPost,
      dialogdelete,
      dialogDeleteCateg,
      draggable: VueDraggableNext,
      lottie: Lottie,
  },
  created() {
    this.getCategoriesTree();
    
    // Популярні запити під проект
    switch (this.$route.params.title) {
      case 'SkyService':
        this.listPopQuer = [
          { label: 'ПРРО' },
          { label: this.$t('Product') },
          { label: this.$t('resetPassword') },
          { label: this.$t('terminal') },
        ]
        break;
      case 'Inside':
        this.listPopQuer = [
          { label: 'Як створити заявку' },
        ]
        break;
    
      default:
        break;
    }
  },
  methods: {
    getColor(e, idx) {
      // Отримання кольору для категорії
      // Використовується для пошуку, щоб візуально одразу відрізняти уроки по категоріям

      // Перевірка, чи вже використовується колір для даної категорії
      if(this.colorInUse.find(k => k.categ == e.categoryId)) {
        
        // Якщо так, повертаємо вже використаний колір для цієї категорії
        return this.colorInUse[this.colorInUse.indexOf(this.colorInUse.find(k => k.categ == e.categoryId))].color
      } else {

        // Якщо колір ще не використовується, перевіряємо, чи є ще доступні кольори
        if(idx < this.colors.length) {

          // Якщо є, додаємо новий колір для категорії та повертаємо його
          this.colorInUse.push({categ: e.categoryId, color: this.colors[idx].color})
          return this.colors[idx].color
        } else {

          // Якщо доступних кольорів не залишилося, обираємо колір зі списку та повертаємо його
          this.colorInUse.push({categ: e.categoryId, color: this.colors[idx%5].color})
          return this.colors[idx%5].color
        }
      }
      
    },
    getdata(){
      // Метод для отримання даних для відображення в компоненті KnowledgeBase

      this.kb = []
      this.objParams.lang = this.user.lang;

      // Створення списку категорій та уроків для відображення
      for(var item in this.KBCategories) {
        this.kb.push(
            {
              data: this.KBCategories[item],
              lessons: [],
              status: false,
              show: 5
            }
          )
      }
      
      // Отримання уроків
      apiServe.getPosts({pagelimit: '200'}).then(result => {
        if(result.status == 'done'){
          var arrLess = [];
          arrLess = result.data.items;
          arrLess.forEach((el)=>{
            let current = el
            if(this.kb.find((element)=> element.data.categoryId == current.categoryId)){
              this.kb[(this.kb.indexOf(this.kb.find((k)=> k.data.categoryId == current.categoryId)))].lessons.push(current);
            }
          })
        } else {
          //
        }
      })
    },
    getPosts(){
      // Метод для отримання уроків

      apiServe.getPosts(this.objParamsPost).then(result => {
        if(result.status == 'done'){
          this.searchPosts = result.data.items;
        } else {
          this.$toast.error(this.$t('err'))
        }
      })
    },
    closeLesson(){
      // Закриття вікна уроку

      this.category = '';
      this.objLessons = '';
      this.obj = '';
      this.showlesson = false
    },
    getNameKWCategory(e){
      // Отримання імені категорії

      return getNameKWCategory(e)
    },
    searchData(e) {
      // Пошук уроків за запитом

      clearTimeout(this.searchTime)
      this.objParamsPost.search = e;
      this.searchTime = setTimeout(() => this.getPosts(), 500)
    },
    add(e){
      // Додавання уроку в обрану категорію

      this.category = e;
      this.showModalPost = true
    },
    closeAddModal(){
      // Закриття модального вікна додавання уроку

      this.category = '';
      this.showModalPost = false;
    },
    editLesson(e) {
      // Редагування уроку

      let id = e.lessonId;

      // apiServe.editLesson(id, e).then(result=>{
      //   if(result.status === 'done') {
      //     this.$toast.success(this.$t('alertChanged'));
      //     this.showlesson = false
      //     this.getdata() //редактировать урок
      //   } else {
      //     this.$toast.error(this.$t('error'))
      //   }
      // })
    },
    closeEdit() {
      // Закриття модального вікна редагування

      this.showModalAdd = false;
      this.categ_perms = false //закрыть редактор
      this.obj = ''
      this.getCategoriesTree();
    },
    editreq(e) {
      // Запит на редагування категорії

      this.categ_perms = true
      this.obj = e;
      this.showModalAdd = true //реквест на едит
    },
    addInCategory(e){
      // Запит на додавання уроку в категорію

      // this.categ_perms = true
      this.obj = e;
      this.showModalAdd = true //реквест на едит
    },
    delreq(e, a) {
      // Запит на видалення категорії

      console.log(e, a)
      this.categoryForDelete = e
      if(e.parentCategoryId != 0) {
        let obj = {
          of: "sort",
          ot: 'asc'
        }

        // Отримання списку уроків для категорії
        apiServe.getLessonsList(e.categoryId, obj).then(result => {
          if(result.status == 'done') {
            if(result.data.items.length > 0) {
              this.father = a
              this.searchPosts = result.data.items
              console.log(this.categoriesTree[this.categoriesTree.indexOf(this.father)])
              this.otherCategories = (this.categoriesTree[this.categoriesTree.indexOf(this.father)]).children.filter(el => el.categoryId != this.categoryForDelete.categoryId)
              this.delmodalWithLessons = true
            } else {
              this.obj = this.categoryForDelete
              this.delmodal = true //удалить реквест
            }
          }
        })
      } else {
        if(this.categoryForDelete.children?.length > 0) {
          this.father = ''
          this.otherCategories = this.categoriesTree.filter(el => el.parentCategoryId == 0 && el.categoryId != this.categoryForDelete.categoryId)
          this.delmodalWithLessons = true
        } else {
          this.obj = this.categoryForDelete
          this.delmodal = true
        }
      }
    },
    open: function(e, category){
      // Відкриття карточки уроку

      
      apiServe.getPost(e.lessonId).then(result => {
        if(result.status==='done') {
          this.obj = result.data
          this.objLessons = result.data.lessonsByCategory;
          this.category = category;
          this.showlesson = true
        } else {
            this.$toast.error(this.$t('err'));
            this.$emit('close')
          }
      })
    },
    openCategory(e){
      // Відкриття категорії

      let obj = {
        of: "sort",
        ot: 'asc'
      }

        // Отримання списку уроків для категорії

      apiServe.getLessonsList(e.categoryId, obj).then(result => {
        if(result.status == 'done' && result.data.total == 0){
          // console.log('res', result);
          this.$toast.warning('notLessInCategory')
        } else if(result.status == 'done') {
          this.objLessons = result.data.items
          this.category = e;
          this.showlesson = true
        } else {
          this.$toast.error(this.$t('err'))
        }
      })
    },
    removeCateg(e) {
      // Отримання списку уроків для категорії

      apiServe.deleteCateg(e.categoryId).then(result=>{
        if(result.status === 'done') {
          this.$toast.success(this.$t('Removed'));
          this.getCategoriesTree() //удалить категорию
          this.delmodal = false;
        } else {
          this.$toast.error(this.$t('err'));
        }
      })
    },
    getCategoriesTree(){
      // Отримання дерева категорій
      
      var id = this.$route.params.id ? this.$route.params.id : 2
      
      this.countLess = 0;
      apiknowledgeBaseProject.getCategoriesTree(id).then(result => {
        if(result.status == 'done'){
          this.categoriesTree = result.data;
          storeS.KBCategoriesMain = result.data;

          console.log("categoriesTree", this.categoriesTree);
          for(var item in this.categoriesTree){
            if(this.categoriesTree[item].children){
              for(var op in this.categoriesTree[item].children){
                var child = this.categoriesTree[item].children[op];
                this.countLess = Number(this.countLess) + Number(child.countLessons);
              }
            }
          }
        }
      })
    //   apiServe.getCategoriesTree().then(result => {
    //     if(result.status === 'done'){
    //       this.categoriesTree = result.data;
    //       storeS.KBCategoriesMain = result.data;

    //       for(var item in this.categoriesTree){
    //         if(this.categoriesTree[item].children){
    //           for(var op in this.categoriesTree[item].children){
    //             var child = this.categoriesTree[item].children[op];
    //             this.countLess = Number(this.countLess) + Number(child.countLessons);
    //           }
    //         }
    //       }
    //     }
    //   })
    // }

    }

  },
  computed: {
    perms() {
      /*
        Повертає дані прав доступу користувача з глобального стору.
      */
      return storeS.perms
    },
    user(){
      /*
        Повертає дані користувача з глобального стору.
      */
      return storeS.userbase
    },
    KBCategories(){
      /*
        Повертає категорії бази знань з глобального стору.
      */
      return storeS.KBCategoriesMain
    }
  },
  mounted() {
    // Додавання слухача подій для клавіші Esc
    window.addEventListener('keydown', this.escKeyEvent);

    // Cлухання події saveCateg для оновлення даних після збереження категорії
    this.eventBus.off('saveCateg');
    this.eventBus.on('saveCateg', (status) => {
      if(status == true) {
        this.getdata();
      }
    }),
   
    // Cлухання події saveFAQ для оновлення даних після збереження поста
    this.eventBus.off('savePost');
    this.eventBus.on('savePost', (status) => {
      if(status == true) {
        this.getdata();
      }
    })
  }
};
</script>

<style scoped>
.knowledge-base-bg .card-body{
    padding: 70px;
    color: white
}
.btn-dot {
  margin-top:20px;
  margin-left: 20px;
  color:white;
}
.btn-dot #dropdownMenuLink2{
  color:white;
}
.text-h2 {
    color: #d1e1ff;
    font-size: 37px;
    font-weight: bold;
}

.kb-title-button h6 {
  margin-bottom: 0;
  align-self: center;
}
.kb-title-button {
  display: grid;
  grid-template-columns: 90% 10%;
}
.lesson {
  display: grid;
  grid-template-columns: 90% 10%;
  align-items: center;
}
.lesson p {
  margin-bottom: 0;
}
.lesson button{
  color: rgba(207, 11, 11, 0.877);
  border: none;
  border-radius: 7px;
  height: 100%;
}

.scroll_s {
overflow: auto;
display: block;
max-height: 400px;
}
.scroll_s::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.scroll_s::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.scroll_s::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
.listLess{
  list-style-type: initial;
  margin-left: 25px;
}

.listLess h6:hover{
  color: #0ab39c;
}

.itemPop {
  margin: 0 3px;
  font-size: 12px;
  cursor: pointer;
}
.btn-soft-light:hover {
    color: black;
}
</style>
