<template>
    <!-- модальное окно создания услуги -->
    <modal>
        <template v-slot:title>{{ $t('add_connect_setting') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('information_create') }}</h4>
                            <input type="number" class="form-control" id="contactNumber" placeholder="+380" v-model="form.phone" disabled>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('device') }}</h4>
                            <input type="text" class="form-control" v-model="form.deviceName" >
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Status') }}</h4>
                        <Multiselect 
                            v-model="form.status"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.statusSevice"
                            :placeholder="$t('Status')"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Choose_Point_Sale') }}</h4>
                        <Multiselect 
                            v-model="form.tradepointId"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.tt_connects"
                        />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-4">
                            <h4 class="fs-15">{{ $t('enter_app_for_connect') }}</h4>
                            <Multiselect 
                                v-model="form.program"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="this.appConnect"
                                :placeholder="$t('Search')"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('Specify_Сonnection_ID') }}</h4>
                            <input type="text" class="form-control" v-model="form.connectionId" >
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('type_connect') }}</h4>
                            <Multiselect 
                                v-model="form.connectionType"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="this.typeСonnects"
                                :placeholder="$t('type_connect')"
                            />
                        </div>
                    </div>
                    <div class="col-md-6" v-if="form.connectionType == 'connect_with_pass'">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('password') }}</h4>
                            <input type="text" class="form-control" v-model="form.password" >
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="VertimeassageInput" class="form-label">{{ $t('Comment') }}</label>
                    <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="form.comment" :placeholder="this.$t('information_for_service')"></textarea>
                </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
            <button type="button" class="btn btn-primary" v-on:click="add">{{ $t('Add') }}</button>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Connects } from '@/API.js';
import { storeS } from '@/store.js'

let apiServe = new Connects();

export default ({
    data(){
        return {
            selectedtype: "",
            form: {
                phone: "", //номер клиента
                deviceName: "",  //устройство
                status: "", //статус настройки
                tradepointId: "", //id точки
                program: "", //программа подключения
                connectionId: "", //идентификатор подключения
                // password: '', // пароль to dev
                connectionType: "",//тип подключения
                comment: "" //коммент создания
            },
            tt_connects: [],
            appConnect: [
                {
                    "id": 1,
                    "label": "AnyDesk",
                    "value": "anydesk"
                },
                {
                    "id": 2,
                    "label": "TeamViewer",
                    "value": "teamviewer"
                },
                {
                    "id": 3,
                    "label": "Other",
                    "value": "othrer"
                }
            ],
            typeСonnects: [
                {
                    "label": this.$t('Connections_WithoutPass'),
                    "value": "connectionWithoutPassword"
                },
                {
                    "label": this.$t('PasswordConnection'),
                    "value": "connect_with_pass"
                }
            ],
            selectedStatus: "",
            statusSevice: [ 
                {
                    "id": 1,
                    "label": this.$t('active'),
                    "value": "active"
                },
                {
                    "id": 2,
                    "label": this.$t('noactive'),
                    "value": "noactive"
                }
            ]
        }
    },
    props: ["userPhone", "sklad"],
    components: { modal, Multiselect },
    created(){
        this.form.phone = Number(this.userPhone);
        for(var item in this.sklad) {
            this.tt_connects.push(
                {
                    label: this.sklad[item].title,
                    value: this.sklad[item].tradepointId
                }
            )
        }
    },
    methods: {
        add() {
            apiServe.createSettings(this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('сreatedT'));
                    this.$emit('close')
                    this.eventBus.emit('saveSettingConnect', true)
                }
            })
        },
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
})
</script>
