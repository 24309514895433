<template>
    <activity :obj="form" :activeTab="activeTab" @openclient="openclient" @inRelease="inRelease" @updateCard="this.$emit('updateCard')"/>
</template>

<script>
import activity from './activity/index'
import { storeS } from '@/store';

export default{
    props: ['obj', 'activeTab'],
    components: {
        activity
    },
    data(){
        return{
            msg: "",
            form: {
                message: ""
            }
        }
    },
    created(){
        this.getdata();
    },
    methods:{
        inRelease(e) {
            this.$emit('inRelease', e)
        },
        getdata(){
            this.form = this.obj
        },
        openclient(e){
            this.$emit('openclient', e)
        }
    },
    computed: {
        user() {
            return storeS.userbase
        },
        workers(){
            return storeS.workers
        }
    }
}
</script>