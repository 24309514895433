<template>
   <b-card>
        <!-- <hr /> -->
        <!-- {{ filteredItems }} -->
        <div class="mb-3" >
            <div v-if="this.perms.filter(el => el.hide == false).length == 0">
                <p class="text-center">{{this.$t('pashNotFound')}}</p>
            </div>

            <input class="form-control" v-model="search" placeholder="search"  @input="this.searchB(this.option, this.search, item)"/>

            <template v-for="(item, i) in this.option" :key="i.key" >

                <ul class="list-unstyled mb-0" :style="(item.hide !== true) ? '' : 'display: none'">
                    <li class="d-flex block-as" >
                        <div class="check-as form-check form-check-success" >
                            <input type="checkbox" autocomplete="off" v-model="mainCateg[item.value]" disabled class="form-check-input" :id="`first_level_input_${item.value}`" :checked="((item.value == 'accessToTradePoints' || item.value == 'acessToCashs' || item.value == 'acessToWarehouses' || item.value == 'accessToInvoices' || item.value ==  'acessToExpensesCategories'|| item.value == 'acessToIncomesCategories' || item.value == 'acessToWorkShops') && item.child.length > 0)">
                        </div>
                        <div class="flex-grow-1" >
                            <label class="form-check-label fs-14" :for="`first_level_input_${item.value}`" style="padding-top: 6px;">
                                <b :style="item.highlight == true ? 'background-color: orange' : ''">{{ item.name }}</b> <span v-if="item.disabled" class="badge bg-danger">{{ $t('inDevelop') }}</span>
                            </label>
                        </div>
                        <div class="col-auto" v-if="item.isOpen != undefined">
                            <button type="button" class="btn btn-icon btn-sm fs-16 close-btn-email " :class="this.openedAll[i] == false ? 'btn-soft-success' : 'btn-soft-danger'" @click="this.openedAll[i] = !this.openedAll[i];this.idx=i;openAll(item, i)" style="margin-right: 7px;" ><i class='bx ' :class="this.openedAll[i] == false ? 'bx-select-multiple' : 'bx bx-hide'"></i></button>
                            <button type="button" class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email" v-if="item.child && item.child.length > 0" @click="item.isOpen = !item.isOpen" style="margin-right: 7px;" ><i class="bx bx-down-arrow-alt align-bottom"></i></button>
                        </div>
                    </li>

                    

                    <div v-if="item.child && item.isOpen == true">
                        <template  v-for="(subIndex, j) in item.child" :key="j">
                            <ul  :style="subIndex.hide !== true ? '' : 'display: none'">
                                <li class="d-flex block-as" >
                                    <div class="check-as">
                                        <input type="checkbox" autocomplete="off" v-model="permsWorker[subIndex.value]" disabled class="form-check-input" :id="`second_level_input_${subIndex.value}`" :checked="(subIndex.parent == 'accessToTradePoints' || subIndex.parent ==  'acessToCashs' || subIndex.parent == 'acessToWarehouses' || subIndex.parent == 'accessToInvoices' || subIndex.parent == 'acessToExpensesCategories' || subIndex.parent == 'acessToIncomesCategories' || subIndex.parent == 'acessToWorkShops')" />
                                    </div>
                                    <div class="flex-grow-1" >
                                        <label class="form-check-label fs-14" :for="`second_level_input_${subIndex.value}`" style="padding-top: 6px;">
                                            <b :style="subIndex.highlight == true ? 'background-color: orange' : ''">{{ subIndex.name }}</b> <span v-if="item.disabled" class="badge bg-danger">{{ $t('inDevelop') }}</span>
                                        </label>
                                        <!-- <p class="text-muted">
                                            {{ subIndex.desc}}
                                        </p> -->
                                    </div>
                                    <div class="col-auto" v-if="subIndex.isOpen != undefined">
                                        <button type="button" v-if="subIndex.child && subIndex.child.length > 0" class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email" @click="subIndex.isOpen = !subIndex.isOpen" style="margin-right: 7px;" ><i class="bx bx-down-arrow-alt align-bottom"></i></button>
                                    </div>
                                </li>
                            </ul>
                            <div v-show="this.noResult == true">{{ this.$t('Null') }}</div>
                            <div v-if="subIndex.child && subIndex.isOpen == true">
                                <template  v-for="(subsubIndex, k) in subIndex.child" :key="k">
                                    <ul class="block-sub"  :style="subsubIndex.hide !== true ? '' : 'display: none'">
                                        <li class="d-flex ">
                                            <div class="check-as">
                                                <input type="checkbox" v-model="permsWorker[subsubIndex.value]" disabled autocomplete="off" class="form-check-input" :id="`three_level_input_${subsubIndex.value}`" />
                                            </div>
                                            <div class="flex-grow-1" >
                                                <label class="form-check-label fs-14" :for="`three_level_input_${subsubIndex.value}`" style="padding-top: 6px;">
                                                    <b :style="subsubIndex.highlight == true ? 'background-color: orange' : ''">{{ subsubIndex.name }}</b>
                                                </label>
                                                <!-- <p class="text-muted">
                                                    {{ subsubIndex.desc}}
                                                </p> -->
                                            </div>
                                        <div class="col-auto" v-if="subsubIndex.isOpen != undefined">
                                            <button type="button" class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email" v-if="subsubIndex.child && subsubIndex.child.length > 0" @click="subsubIndex.isOpen = !subsubIndex.isOpen" style="margin-right: 7px;" ><i class="bx bx-down-arrow-alt align-bottom"></i></button>
                                        </div>
                                        </li>
                                    </ul>
                                    <div v-if="subsubIndex.child && subsubIndex.isOpen == true">
                                        <template  v-for="(child, k) in subsubIndex.child" :key="k">
                                            <ul class="block-fch"  :style="child.hide !== true ? '' : 'display: none'">
                                                <li class="d-flex ">
                                                    <div class="check-as">
                                                        <input type="checkbox" v-model="permsWorker[child.value]" disabled autocomplete="off" class="form-check-input" :id="`three_level_input_${child.value}`" :selected="item.name"/>
                                                    </div>
                                                    <div class="flex-grow-1" >
                                                        <label class="form-check-label fs-14" :for="`three_level_input_${child.value}`" style="padding-top: 6px;">
                                                            <b :style="child.highlight == true ? 'background-color: orange' : ''">{{ child.name }}</b>
                                                        </label>
                                                        <!-- <p class="text-muted">
                                                            {{ child.desc}}
                                                        </p> -->
                                                    </div>
                                                <div class="col-auto" v-if="child.isOpen != undefined">
                                                    <button type="button" class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email" v-if='child.child && child.child.length > 0' @click="child.isOpen = !child.isOpen" style="margin-right: 7px;" ><i class="bx bx-down-arrow-alt align-bottom"></i></button>
                                                </div>
                                                </li>
                                            </ul>
                                            <div v-if="child.child && child.isOpen == true">
                                                <template  v-for="(child, k) in child.child" :key="k">
                                                    <ul class="block-fich"  :style="child.hide !== true ? '' : 'display: none'">
                                                        <li class="d-flex ">
                                                            <div class="check-as">
                                                                <input type="checkbox" v-model="permsWorker[child.value]" disabled autocomplete="off" class="form-check-input" :id="`three_level_input_${child.value}`" />
                                                            </div>
                                                            <div class="flex-grow-1" >
                                                                <label class="form-check-label fs-14" :for="`three_level_input_${child.value}`" style="padding-top: 6px;">
                                                                    <b :style="child.highlight == true ? 'background-color: orange' : ''">{{ child.name }}</b>
                                                                </label>
                                                                <!-- <p class="text-muted">
                                                                    {{ child.desc}}
                                                                </p> -->
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </template>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                            
                        </template>

                    </div>

                </ul>
                <hr :style="(item.hide !== true) ? '' : 'display: none'"/>
            </template>
        </div>
    </b-card>
</template>

<script>

export default{
    props: ['objPerms'],
    created(){
        if(this.objPerms != null || this.objPerms != undefined) {
            this.permsWorker = this.objPerms.perms
        }
    },
    methods: {
        takeTP() { // витягнути всі торгові точки для "Доступ до торгових точок"
            if(this.objPerms.tradepoints != null) {
                this.objPerms.tradepoints.forEach(el => {
                    this.option[1].child.push({
                        name: el.tradepointName,
                        desc: null,
                        hide: false,
                        parent: 'accessToTradePoints',
                        disabled: false,
                        highlight: false,
                    })
                })
            }
        }, 
        takeCashs() { // витягнути всі каси (термінали) точки для "Доступ до кас"
            if(this.objPerms.terminals != null) {
                this.objPerms.terminals.forEach(el => {
                    this.option[2].child.push({
                        name: el.terminalName,
                        desc: null,
                        hide: false,
                        parent: 'acessToCashs',
                        disabled: false,
                        highlight: false,
                    })
                })
            }
        },
        takeWareHouses() { // витягнути всі склади точки для "Доступ до складів"
            if(this.objPerms.warehouses != null) {
                this.objPerms.warehouses.forEach(el => {
                    this.option[3].child.push({
                        name: el.warehouseName,
                        desc: null,
                        hide: false,
                        parent: 'acessToCashs',
                        disabled: false,
                        highlight: false,
                    })
                })
            }
        },
        takeWallets() { // витягнути всі склади точки для "Доступ до складів"
            if(this.objPerms.wallets != null) {
                this.objPerms.wallets.forEach(el => {
                    this.option[4].child.push({
                        name: el.walletName,
                        desc: null,
                        hide: false,
                        parent: 'accessToInvoices',
                        disabled: false,
                        highlight: false,
                    })
                })
            }
        },
        takeIncomingCateg() {
            if(this.objPerms.incomeCategories != null) {
                this.objPerms.incomeCategories.forEach(el => {
                    this.option[5].child.push({
                        name: el.title,
                        desc: null,
                        hide: false,
                        parent: 'acessToIncomesCategories',
                        disabled: false,
                        highlight: false,
                    })
                })
            }
        },
        takeOutgoingCateg() {
            if(this.objPerms.outgoCategories != null) {
                this.objPerms.outgoCategories.forEach(el => {
                    this.option[6].child.push({
                        name: el.title,
                        desc: null,
                        hide: false,
                        parent: 'acessToExpensesCategories',
                        disabled: false,
                        highlight: false,
                    })
                })
            }
        },
        takeWorkShops() {
            if(this.objPerms.workshops != null) {
                this.objPerms.workshops.forEach(el => {
                    this.option[7].child.push({
                        name: el.workshopName,
                        desc: null,
                        hide: false,
                        parent: 'acessToWorkShops',
                        disabled: false,
                        highlight: false,
                    })
                })
            }
        },
        vidkat(arr) { //для відкату історії
            this.history.push(arr)
            if (arr != undefined) {
                if(arr.isOpen != undefined) {
                    arr.isOpen = true
                }
                if(arr.hide != undefined) {
                    arr.hide = false
                }
                if((arr.name.toLowerCase()).indexOf(this.search.toLowerCase()) !== -1) {
                    arr.highlight = true
                }
                if(arr.parent) {
                    this.vidkat(this.perms.find(el => el.value == arr.parent))
                }
                if(arr.child != undefined) {
                    arr.child.forEach(el => {
                        if(el.highlight == false && (el.name.toLowerCase()).indexOf(this.search.toLowerCase()) !== -1) {
                            el.highlight = true
                            el.hide = false
                        }
                    })
                }
                // if(!arr.parent) {
                    // this.hideExtras(this.history)
                // }
            }
        },
        // hideExtras(arr) {
        //     for(let i = arr.length - 1; i > 0; i--) {
        //         if(arr[i].child) {
        //             arr[i].child.forEach(el => {
        //                 if(arr.indexOf(el) == -1) {
        //                     el.hide = true
        //                 }
        //             })
        //         }
        //     }
        // }, // зайве з пошуку
        collectAllPerms(arr) {
            arr.forEach(el => {
                this.perms.push(el)
                if(el.child) {
                    this.collectAllPerms(el.child)
                }
            })
        }, // зібрати пермси в єдину строку
        searchB(arr, search, category) {
            console.log(arr, search, category, this.modeSearch)
            // if(category.value == "accessRights") { 
                clearTimeout(this.coldown)
                this.coldown = setTimeout(() => {
                    if(search.length > 0) {     
                        this.clearPrev(this.option)               
                        this.zamikashka(arr, search)
                    } else {
                        this.clearAll(this.option)  
                    }
                }, 1000)
            // } else if(category.value == "accessToTradePoints") {
                // if(category.child.length == 0) {
                //     this.noResult == true
                // } else {
                //     category.child.forEach(el => {
                //         if(this.search.indexOf(el.name) === -1) {
                //             el.hide = true
                //         }
                //     })
                // }
            // }
        }, //пошук
        clearPrev(arr) {
            arr.forEach(el => {
                el.isOpen = false
                el.hide = true
                el.highlight = false
                if(el.child) {
                    this.clearPrev(el.child)
                }
            })
        }, // clear prev search
        openAll(arr) { // ця штука працює трошки нелогічну в if, бо треба змінювати статус, а в тілі функції це викличе баги
            if(this.openedAll[this.idx] == true) {
                arr.isOpen = true
                if(arr.child) {
                    arr.child.forEach(el => {
                        this.openAll(el)
                    })
                }
            } else {
                arr.isOpen = false
                if(arr.child) {
                    arr.child.forEach(el => {
                        this.openAll(el)
                    })
                }
            }
        },
        clearAll(arr) {
            // modeSearch = ''
            arr.forEach(el => {
                el.isOpen = false
                el.hide = false
                el.highlight = false
                if(el.child) {
                    this.clearAll(el.child)
                }
            })
        }, // clear prev search (search.length == 0)
        zamikashka(arr, search) { //рекурсія для пошуку
            for(let i = 0; i<arr.length; i++) {
                if((arr[i].name.toLowerCase()).indexOf(search.toLowerCase()) !== -1) {
                    console.log(arr[i])
                    if(arr[i].child == undefined && Number(arr[i].parent) === NaN) {
                        arr[i].hide = false 
                        console.log(this.option, arr[i].parent, this.option.indexOf(this.option.find(el => el.value == arr[i].parent)))
                        if(this.option.indexOf(this.option.find(el => el.value == arr[i].parent)) != -1) {
                            this.option[this.option.indexOf(this.option.find(el => el.value == arr[i].parent))].hide = false
                            this.option[this.option.indexOf(this.option.find(el => el.value == arr[i].parent))].isOpen = true
                        }
                    } else {
                        this.vidkat(arr[i])
                    }
                } else if(arr[i].child){
                    this.zamikashka(arr[i].child, this.search)
                }
            }
        },
        isShowItem(option){
            var status = true;
            if(option.perms){
                if(option.perms.length > 1) {
                    for(var item in option.perms){
                        // status = this.perms[option.perms[item]]
                        if(this.perms[option.perms[item]] == true){
                            status = true;
                        }
                    }
                } else {
                    status = this.perms[option.perms]
                }
            }
            return status
        },
        searchTrue(){ // поставити галочки, де це потрібно на головні категорії
            this.option.forEach(el => {
                if(el?.child) {
                    el.child.forEach(k => {
                        if(Object.keys(this.permsWorker).indexOf(k.value) !== -1) {
                            this.mainCateg[el.value] = true
                        } 
                    })
                }
            })
        }
    },  
    mounted() {
        this.collectAllPerms(this.option)
        this.searchTrue() //для заповнення головних прапорців
        this.takeTP(),
        this.takeCashs()
        this.takeWareHouses()
        this.takeWallets()
        this.takeOutgoingCateg()
        this.takeIncomingCateg()
        this.takeWorkShops()
    },
    data(){
        return{
            noResult: false,
            modeSearch: '',
            idx: '', //idx for openedAll
            openedAll: [false, false, false,false, false, false,false, false, false],
            mainCateg: {
                accessRights: false,
                accessToTradePoints: false,
                accessToPrograms: false,
                acessToWorkShops: false,
                acessToExpensesCategories: false,
                acessToIncomesCategories: false,
                accessToInvoices: false,
                acessToWarehouses: false,
                acessToCashs: false
            },
            coldown: '',
            search: '',
            form: '',
            permsWorker: '',
            perms: [],
            hide: false,
            history: [],
            option: [
                {
                    name: this.$t('accessRights'),
                    // desc: this.$t('displayInfoBlocksdesc'),
                    isOpen: false,
                    value: 'accessRights',
                    disabled: false,
                    highlight: false,
                    hide: false,
                    child: [
                        {
                            name: this.$t('Products'),
                            desc: null,
                            highlight: false,
                            value: '1',
                            parent: 'accessRights',
                            isParent: true,
                            isOpen: false,
                            hide: false,
                            disabled: false,
                            child : [
                                {
                                    name: this.$t('AllProducts'),
                                    desc: null,
                                    highlight: false,
                                    hide: false,
                                    value: '100',
                                    parent: '1',
                                    disabled: false,
                                },
                                {
                                    name: this.$t('warehousesGoods'),
                                    desc: null,
                                    value: '101',
                                    hide: false,
                                    isOpen: false,
                                    highlight: false,
                                    parent: '1',
                                    disabled: false,
                                    child: [
                                        {
                                            name: this.$t('addWarheousesGoods'),
                                            desc: null,
                                            hide: false,
                                            value: '6900',
                                            highlight: false,
                                            parent: '101',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('editWarhousesGoods'),
                                            desc: null,
                                            value: '6901',
                                            hide: false,
                                            highlight: false,
                                            parent: '101',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeWarhousesGoods'),
                                            desc: null,
                                            value: '6902',
                                            highlight: false,
                                            hide: false,
                                            parent: '101',
                                            disabled: false,
                                        },
                                    ]
                                },
                                {
                                    name: this.$t('ingredients'),
                                    desc: null,
                                    value: '102',
                                    hide: false,
                                    isOpen: false,
                                    highlight: false,
                                    parent: '1',
                                    disabled: false,
                                    child: [
                                        {
                                            name: this.$t('addIngredients'),
                                            desc: null,
                                            hide: false,
                                            value: '8100',
                                            highlight: false,
                                            parent: '102',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('editIngredients'),
                                            desc: null,
                                            value: '8101',
                                            hide: false,
                                            highlight: false,
                                            parent: '102',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeIngredients'),
                                            desc: null,
                                            value: '8102',
                                            highlight: false,
                                            hide: false,
                                            parent: '102',
                                            disabled: false,
                                        },
                                    ]
                                },
                                {
                                    name: this.$t('TechnologicalCards'),
                                    desc: null,
                                    value: '103',
                                    hide: false,
                                    isOpen: false,
                                    highlight: false,
                                    parent: '1',
                                    disabled: false,
                                    child: [
                                        {
                                            name: this.$t('AddingtechCards'),
                                            desc: null,
                                            hide: false,
                                            value: '8200',
                                            highlight: false,
                                            parent: '103',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('EditingThoseCards'),
                                            desc: null,
                                            value: '8201',
                                            hide: false,
                                            highlight: false,
                                            parent: '103',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('RemovingthoseCards'),
                                            desc: null,
                                            value: '8202',
                                            highlight: false,
                                            hide: false,
                                            parent: '103',
                                            disabled: false,
                                        },
                                    ]
                                },
                                {
                                    name: this.$t('SemifinishedProducts'),
                                    desc: null,
                                    value: '104',
                                    hide: false,
                                    isOpen: false,
                                    parent: '1',
                                    highlight: false,
                                    disabled: false,
                                    child: [
                                        {
                                            name: this.$t('AddingSemiProd'),
                                            desc: null,
                                            value: '8300',
                                            hide: false,
                                            parent: '104',
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('EditingSemiProd'),
                                            desc: null,
                                            value: '8301',
                                            hide: false,
                                            highlight: false,
                                            parent: '104',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('RemovingSemiProd'),
                                            desc: null,
                                            value: '8302',
                                            highlight: false,
                                            hide: false,
                                            parent: '104',
                                            disabled: false,
                                        },
                                    ]
                                },
                                {
                                    name: this.$t('WarehouseBalances'),
                                    desc: null,
                                    highlight: false,
                                    hide: false,
                                    value: '107',
                                    isOpen: false,
                                    parent: '1',
                                    disabled: false,
                                },
                                {
                                    name: this.$t('ShowItemCost'),
                                    desc: null,
                                    highlight: false,
                                    hide: false,
                                    value: '108',
                                    isOpen: false,
                                    parent: '1',
                                    disabled: false,
                                },
                                {
                                    name: this.$t('ShowProductQuantity'),
                                    desc: null,
                                    value: '109',
                                    isOpen: false,
                                    hide: false,
                                    parent: '1',
                                    disabled: false,
                                    highlight: false,
                                },
                            ]
                        },
                        {
                            name: this.$t('statistics'),
                            desc: null,
                            value: '2',
                            highlight: false,
                            parent: 'accessRights',
                            hide: false,
                            isParent: true,
                            isOpen: false,
                            hide: false,
                            disabled: false,
                            child : [
                                {
                                    name: this.$t('main'),
                                    desc: null,
                                    value: '201',
                                    highlight: false,
                                    parent: '2',
                                    hide: false,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('MovementofGoods'),
                                    desc: null,
                                    value: '202',
                                    highlight: false,
                                    hide: false,
                                    isOpen: false,
                                    parent: '2',
                                    disabled: false,
                                    child: [
                                        {
                                            name: this.$t('Sales'),
                                            desc: null,
                                            value: '2000',
                                            parent: '202',
                                            hide: false,
                                            highlight: false,
                                            isOpen: false,
                                            disabled: false,
                                            child: [
                                                {
                                                    name: this.$t('CheckCancellation'),
                                                    desc: null,
                                                    value: '3000',
                                                    hide: false,
                                                    highlight: false,
                                                    parent: '2000',
                                                    disabled: false,
                                                }
                                            ]
                                        },
                                        {
                                            name: this.$t('Coming'),
                                            desc: null,
                                            value: '2001',
                                            highlight: false,
                                            hide: false,
                                            parent: '202',
                                            isOpen: false,
                                            disabled: false,
                                            child: [
                                                {
                                                    name: this.$t('ParishCreation'),
                                                    desc: null,
                                                    highlight: false,
                                                    value: '3100',
                                                    parent: '2001',
                                                    hide: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('ParishEditing'),
                                                    desc: null,
                                                    value: '3101',
                                                    highlight: false,
                                                    parent: '2001',
                                                    hide: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('ParishCancel'),
                                                    desc: null,
                                                    value: '3102',
                                                    hide: false,
                                                    highlight: false,
                                                    parent: '2001',
                                                    disabled: false,
                                                },
                                            ]
                                        },
                                        {
                                            name: this.$t('returns'),
                                            desc: null,
                                            hide: false,
                                            value: '2002',
                                            highlight: false,
                                            parent: '202',
                                            disabled: false,
                                            isOpen: false,
                                            child: [
                                                {
                                                    name: this.$t('DeletingReceipt'),
                                                    desc: null,
                                                    hide: false,
                                                    value: '3200',
                                                    highlight: false,
                                                    parent: '2002',
                                                    disabled: false,
                                                }
                                            ]
                                        },
                                        {
                                            name: this.$t('WriteOff'),
                                            desc: null,
                                            value: '2003',
                                            hide: false,
                                            parent: '202',
                                            highlight: false,
                                            disabled: false,
                                            isOpen: false,
                                            child: [
                                                {
                                                    name: this.$t('CreateWriteoff'),
                                                    desc: null,
                                                    value: '3300',
                                                    hide: false,
                                                    parent: '2003',
                                                    highlight: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('EditWriteoff'),
                                                    desc: null,
                                                    value: '3301',
                                                    highlight: false,
                                                    hide: false,
                                                    parent: '2003',
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('CancelWriteoff'),
                                                    desc: null,
                                                    highlight: false,
                                                    hide: false,
                                                    value: '3302',
                                                    parent: '2003',
                                                    disabled: false,
                                                },
                                            ]
                                        },
                                        {
                                            name: this.$t('moving'),
                                            desc: null,
                                            value: '2004',
                                            isOpen: false,
                                            highlight: false,
                                            parent: '202',
                                            disabled: false,
                                            hide: false,
                                            child: [
                                                {
                                                    name: this.$t('CreateMoving'),
                                                    desc: null,
                                                    value: '3400',
                                                    hide: false,
                                                    parent: '2004',
                                                    highlight: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('EditMoving'),
                                                    desc: null,
                                                    hide: false,
                                                    value: '3401',
                                                    parent: '2004',
                                                    highlight: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('CancelMoving'),
                                                    desc: null,
                                                    hide: false,
                                                    value: '3402',
                                                    parent: '2004',
                                                    highlight: false,
                                                    disabled: false,
                                                },
                                            ]
                                        },
                                        {
                                            name: this.$t('Production'),
                                            desc: null,
                                            hide: false,
                                            value: '2005',
                                            highlight: false,
                                            parent: '202',
                                            isOpen: false,
                                            disabled: false,
                                            child: [
                                                {
                                                    name: this.$t('CreateProduction'),
                                                    desc: null,
                                                    highlight: false,
                                                    value: '3500',
                                                    parent: '2005',
                                                    disabled: false,
                                                    hide: false,
                                                },
                                                {
                                                    name: this.$t('EditProduction'),
                                                    desc: null,
                                                    hide: false,
                                                    value: '3501',
                                                    parent: '2005',
                                                    highlight: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('CancelProduction'),
                                                    desc: null,
                                                    hide: false,
                                                    value: '3502',
                                                    highlight: false,
                                                    parent: '2005',
                                                    disabled: false,
                                                },
                                            ]
                                        }]
                                },
                                    {
                                        name: this.$t('shifts'),
                                        desc: null,
                                        highlight: false,
                                        hide: false,
                                        value: '203',
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('openShifts'),
                                        desc: null,
                                        value: '204',
                                        highlight: false,
                                        hide: false,
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('productSpend'),
                                        desc: null,
                                        highlight: false,
                                        hide: false,
                                        value: '205',
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('topSales'),
                                        desc: null,
                                        hide: false,
                                        value: '206',
                                        highlight: false,
                                        parent: '2',
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('topClient'),
                                        desc: null,
                                        highlight: false,
                                        hide: false,
                                        value: '207',
                                        parent: '2',
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('byDays'),
                                        desc: null,
                                        value: '208',
                                        hide: false,
                                        parent: '2',
                                        highlight: false,
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('salesDynamic'),
                                        desc: null,
                                        value: '210',
                                        parent: '2',
                                        hide: false,
                                        highlight: false,
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('RevenueReport'),
                                        desc: null,
                                        value: '211',
                                        highlight: false,
                                        parent: '2',
                                        hide: false,
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('shopReport'),
                                        desc: null,
                                        value: '212',
                                        hide: false,
                                        parent: '2',
                                        highlight: false,
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('Inventory'),
                                        desc: null,
                                        value: '213',
                                        parent: '2',
                                        disabled: false,
                                        isOpen: false,
                                        hide: false,
                                        highlight: false,
                                        child: [
                                            {
                                                name: this.$t('CreateInventory'),
                                                desc: null,
                                                hide: false,
                                                value: '3601',
                                                parent: '213',
                                                disabled: false,
                                                highlight: false,
                                            },
                                            {
                                                name: this.$t('EditInventory'),
                                                desc: null,
                                                value: '3602',
                                                hide: false,
                                                highlight: false,
                                                parent: '213',
                                                disabled: false,
                                            }
                                        ]
                                    },
                                    {
                                        name: this.$t('dangerOperation'),
                                        desc: null,
                                        hide: false,
                                        value: '214',
                                        parent: '2',
                                        highlight: false,
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('recomendedForBuy'),
                                        desc: null,
                                        hide: false,
                                        value: '215',
                                        highlight: false,
                                        parent: '2',
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('Taxes'),
                                        desc: null,
                                        value: '216',
                                        parent: '2',
                                        hide: false,
                                        highlight: false,
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('reportByMarketing'),
                                        desc: null,
                                        hide: false,
                                        value: '217',
                                        parent: '2',
                                        highlight: false,
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('ABC'),
                                        desc: null,
                                        value: '218',
                                        hide: false,
                                        highlight: false,
                                        parent: '2',
                                        isOpen: false,
                                        disabled: false,
                                        child:[
                                            {
                                                name: this.$t('Products'),
                                                desc: null,
                                                value: '7400',
                                                hide: false,
                                                parent: '218',
                                                disabled: false,
                                                highlight: false,
                                            },
                                            {
                                                name: this.$t('productCategories'),
                                                desc: null,
                                                hide: false,
                                                highlight: false,
                                                value: '7401',
                                                parent: '218',
                                                disabled: false,
                                            }
                                        ]
                                    },
                                    {
                                        name: this.$t('topIncoming'),
                                        desc: null,
                                        value: '219',
                                        parent: '2',
                                        hide: false,
                                        highlight: false,
                                        disabled: false,
                                    },
                                    {
                                        name: this.$t('RevenueReport'),
                                        desc: null,
                                        value: '211',
                                        hide: false,
                                        parent: '2',
                                        highlight: false,
                                        disabled: false,
                                    },
                            ]
                        },
                        {
                            name: this.$t('Finance'),
                            desc: null,
                            value: '3',
                            parent: 'accessRights',
                            isParent: true,
                            isOpen: false,
                            highlight: false,
                            hide: false,
                            disabled: false,
                            child : [
                                {
                                    name: this.$t('walletBalance'),
                                    desc: null,
                                    value: '1001',
                                    highlight: false,
                                    parent: '3',
                                    hide: false,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('magazine'),
                                    desc: null,
                                    value: '1002',
                                    isOpen: false,
                                    hide: false,
                                    parent: '3',
                                    highlight: false,
                                    disabled: false,
                                    child: [
                                        {
                                            name: this.$t('allTransactions'),
                                            desc: null,
                                            value: '3700',
                                            parent: '1002',
                                            highlight: false,
                                            hide: false,
                                            disabled: false,   
                                        },
                                        {
                                            name: this.$t('incomings'),
                                            desc: null,
                                            value: '3701',
                                            parent: '1002',
                                            disabled: false,
                                            isOpen: false,
                                            highlight: false,
                                            hide: false,
                                            child: [
                                                {
                                                    name: this.$t('addTransaction'),
                                                    desc: null,
                                                    value: '3800',
                                                    hide: false,
                                                    parent: '3701',
                                                    highlight: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('editTransaction'),
                                                    desc: null,
                                                    value: '3801',
                                                    parent: '3701',
                                                    disabled: false,
                                                    hide: false,
                                                    highlight: false,
                                                },
                                                {
                                                    name: this.$t('deleteTransaction'),
                                                    desc: null,
                                                    hide: false,
                                                    value: '3802',
                                                    parent: '3701',
                                                    highlight: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('recoveryTransaction'),
                                                    desc: null,
                                                    value: '3803',
                                                    hide: false,
                                                    parent: '3701',
                                                    highlight: false,
                                                    disabled: false,
                                                }
                                            ]
                                        },
                                        {
                                            name: this.$t('spend'),
                                            desc: null,
                                            hide: false,
                                            value: '3702',
                                            parent: '1002',
                                            highlight: false,
                                            disabled: false,
                                            isOpen: false,
                                            child: [
                                                {
                                                    name: this.$t('addSpendTranscation'),
                                                    desc: null,
                                                    hide: false,
                                                    value: '3900',
                                                    parent: '3701',
                                                    highlight: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('editSpendTranscation'),
                                                    desc: null,
                                                    value: '3901',
                                                    hide: false,
                                                    parent: '3701',
                                                    highlight: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('delSpendTranscation'),
                                                    desc: null,
                                                    value: '3902',
                                                    parent: '3701',
                                                    highlight: false,
                                                    hide: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('recSpendTranscation'),
                                                    desc: null,
                                                    value: '3903',
                                                    highlight: false,
                                                    hide: false,
                                                    parent: '3701',
                                                    disabled: false,
                                                }
                                            ]
                                        },
                                        {
                                            name: this.$t('transfer2'),
                                            desc: null,
                                            hide: false,
                                            value: '3703',
                                            parent: '1002',
                                            isOpen: false,
                                            disabled: false,
                                            highlight: false,
                                            child: [
                                                {
                                                    name: this.$t('addTransferTransaction'),
                                                    desc: null,
                                                    value: '4000',
                                                    hide: false,
                                                    parent: '3701',
                                                    disabled: false,
                                                    highlight: false,
                                                },
                                                {
                                                    name: this.$t('editTransferTransaction'),
                                                    desc: null,
                                                    value: '4001',
                                                    hide: false,
                                                    parent: '3701',
                                                    highlight: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('delTransferTransaction'),
                                                    desc: null,
                                                    value: '4002',
                                                    highlight: false,
                                                    parent: '3701',
                                                    hide: false,
                                                    disabled: false,
                                                },
                                                {
                                                    name: this.$t('recTransferTransaction'),
                                                    desc: null,
                                                    value: '4003',
                                                    parent: '3701',
                                                    hide: false,
                                                    disabled: false,
                                                    highlight: false,
                                                }
                                            ]
                                        },
                                        {
                                            name: this.$t('edited'),
                                            hide: false,
                                            desc: null,
                                            highlight: false,
                                            value: '3705',
                                            parent: '1002',
                                            disabled: false,   
                                        },
                                    ]
                                },
                                {
                                    name: this.$t('statReport'),
                                    desc: null,
                                    value: '1003',
                                    hide: false,
                                    parent: '3',
                                    highlight: false,
                                    disabled: false,
                                    isOpen: false,
                                    child: [
                                        {
                                            name: this.$t('CashFlow Month'),
                                            desc: null,
                                            value: '4203',
                                            highlight: false,
                                            hide: false,
                                            parent: '1003',
                                            disabled: false,
                                        },
                                    ]
                                },
                            ]
                        },
                        {
                            name: this.$t('Marketing'),
                            desc: null,
                            value: '4',
                            parent: 'accessRights',
                            highlight: false,
                            isParent: true,
                            isOpen: false,
                            hide: false,
                            disabled: false,
                            child : [
                                {
                                    name: this.$t('clients'),
                                    desc: null,
                                    value: '1100',
                                    isOpen: false,
                                    hide: false,
                                    parent: '4',
                                    highlight: false,
                                    disabled: false,
                                    child: [
                                        {
                                            name: this.$t('addClient'),
                                            desc: null,
                                            value: '4300',
                                            hide: false,
                                            parent: '1100',
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeClient'),
                                            desc: null,
                                            value: '4301',
                                            parent: '1100',
                                            highlight: false,
                                            hide: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('saveClient'),
                                            desc: null,
                                            value: '4302',
                                            parent: '1100',
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ]
                                },
                                {
                                    name: this.$t('StockAk'),
                                    desc: null,
                                    value: '1102',
                                    highlight: false,
                                    parent: '4',
                                    isOpen: false,
                                    disabled: false,
                                    hide: false,
                                    child: [
                                        {
                                            name: this.$t('addStockAk'),
                                            desc: null,
                                            value: '4500',
                                            parent: '1002',
                                            highlight: false,
                                            hide: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeStockAk'),
                                            desc: null,
                                            value: '4501',
                                            parent: '1002',
                                            hide: false,
                                            disabled: false,
                                            highlight: false,
                                        },
                                        {
                                            name: this.$t('editStockAk'),
                                            desc: null,
                                            value: '4502',
                                            highlight: false,
                                            parent: '1002',
                                            hide: false,
                                            disabled: false,
                                        },
                                    ]
                                },
                                {
                                    name: this.$t('Discounts'),
                                    desc: null,
                                    value: '1103',
                                    hide: false,
                                    parent: '4',
                                    disabled: false,
                                    highlight: false,
                                    isOpen: false,
                                    child: [
                                        {
                                            name: this.$t('addDiscount'),
                                            desc: null,
                                            value: '4203',
                                            parent: '1003',
                                            hide: false,
                                            disabled: false,
                                            highlight: false,
                                        },
                                        {
                                            name: this.$t('removeDiscount'),
                                            desc: null,
                                            value: '4203',
                                            parent: '1003',
                                            hide: false,
                                            disabled: false,
                                            highlight: false,
                                        },
                                        {
                                            name: this.$t('editDiscount'),
                                            desc: null,
                                            value: '4203',
                                            parent: '1003',
                                            disabled: false,
                                            hide: false,
                                            highlight: false,
                                        },
                                    ]
                                },
                                {
                                    name: this.$t('bonusProgramm'),
                                    desc: null,
                                    hide: false,
                                    value: '7900',
                                    parent: '4',
                                    isOpen: false,
                                    disabled: false,
                                    highlight: false,
                                    child: [
                                        {
                                            name: this.$t('addBonus'),
                                            desc: null,
                                            highlight: false,
                                            value: '4700',
                                            parent: '1003',
                                            hide: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeBonus'),
                                            desc: null,
                                            value: '4701',
                                            parent: '1003',
                                            disabled: false,
                                            hide: false,
                                            highlight: false,
                                        },
                                        {
                                            name: this.$t('editBonus'),
                                            desc: null,
                                            value: '4702',
                                            hide: false,
                                            parent: '1003',
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ]
                                },
                            ]
                        },
                        {
                            name: this.$t('Settings'),
                            desc: null,
                            value: '5',
                            parent: 'accessRights',
                            isParent: true,
                            isOpen: false,
                            highlight: false,
                            hide: false,
                            disabled: false,
                            child : [
                                {
                                    name: this.$t('mains'),
                                    desc: null,
                                    value: '1200',
                                    parent: '5',
                                    disabled: false,
                                    highlight: false,
                                    hide: false,
                                },
                                {
                                    name: this.$t('Outlets'),
                                    desc: null,
                                    value: '1201',
                                    highlight: false,
                                    isOpen: false,
                                    parent: '5',
                                    disabled: false,
                                    hide: false,
                                    child:[
                                        {
                                            name: this.$t('addTradePoint'),
                                            desc: null,
                                            value: '4800',
                                            parent: '1201',
                                            disabled: false,
                                            hide: false,
                                            highlight: false,
                                        },
                                        {
                                            name: this.$t('editTradePoint'),
                                            desc: null,
                                            value: '4801',
                                            highlight: false,
                                            hide: false,
                                            parent: '1201',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeTradePoint'),
                                            desc: null,
                                            value: '4802',
                                            parent: '1201',
                                            highlight: false,
                                            hide: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('giveAccess'),
                                            desc: null,
                                            value: '4803',
                                            parent: '1201',
                                            hide: false,
                                            disabled: false,
                                            highlight: false,
                                        },
                                        {
                                            name: this.$t('freezeTradePoint'),
                                            desc: null,
                                            value: '4806',
                                            highlight: false,
                                            hide: false,
                                            parent: '1201',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('unFreezeTradePoint'),
                                            desc: null,
                                            value: '4805',
                                            parent: '1201',
                                            hide: false,
                                            disabled: false,
                                            highlight: false,
                                        },
                                        {
                                            name: this.$t('recoverTradePoint'),
                                            desc: null,
                                            value: '4804',
                                            hide: false,
                                            parent: '1201',
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ]
                                },
                                {
                                    name: this.$t('productsCateg'),
                                    desc: null,
                                    value: '1202',
                                    parent: '5',
                                    isOpen: false,
                                    disabled: false,
                                    hide: false,
                                    highlight: false,
                                    child:[
                                        {
                                            name: this.$t('addCateg'),
                                            desc: null,
                                            highlight: false,
                                            hide: false,
                                            value: '4900',
                                            parent: '1202',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('editCateg'),
                                            desc: null,
                                            value: '4901',
                                            hide: false,
                                            parent: '1202',
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeCateg'),
                                            desc: null,
                                            value: '4902',
                                            parent: '1202',
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ],

                                },
                                {
                                    name: this.$t('categIncome'),
                                    desc: null,
                                    highlight: false,
                                    value: '1203',
                                    isOpen: false,
                                    parent: '5',
                                    hide: false,
                                    disabled: false,
                                    child:[
                                        {
                                            name: this.$t('addCateg'),
                                            desc: null,
                                            value: '5000',
                                            parent: '1203',
                                            highlight: false,
                                            hide: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('editCateg'),
                                            desc: null,
                                            hide: false,
                                            value: '5001',
                                            highlight: false,
                                            parent: '1203',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeCateg'),
                                            desc: null,
                                            highlight: false,
                                            hide: false,
                                            value: '5002',
                                            parent: '1203',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('giveAccess'),
                                            desc: null,
                                            value: '5003',
                                            hide: false,
                                            parent: '1203',
                                            disabled: false,
                                            highlight: false,
                                        },
                                    ],

                                },
                                {
                                    name: this.$t('settingsexpenses'),
                                    desc: null,
                                    value: '1204',
                                    highlight: false,
                                    parent: '5',
                                    hide: false,
                                    disabled: false,
                                    isOpen: false,
                                    child:[
                                        {
                                            name: this.$t('addCateg'),
                                            desc: null,
                                            value: '5100',
                                            parent: '1204',
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('editCateg'),
                                            desc: null,
                                            value: '5101',
                                            parent: '1204',
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeCateg'),
                                            desc: null,
                                            value: '5102',
                                            highlight: false,
                                            parent: '1204',
                                            hide: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('giveAccess'),
                                            desc: null,
                                            highlight: false,
                                            hide: false,
                                            value: '5103',
                                            parent: '1204',
                                            disabled: false,
                                        },
                                    ],

                                },
                                {
                                    name: this.$t('settingstax'),
                                    desc: null,
                                    value: '1205',
                                    hide: false,
                                    isOpen: false,
                                    parent: '5',
                                    highlight: false,
                                    disabled: false,
                                    child:[
                                        {
                                            name: this.$t('addTax'),
                                            desc: null,
                                            value: '5200',
                                            hide: false,
                                            highlight: false,
                                            parent: '1205',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeTax'),
                                            desc: null,
                                            value: '5202',
                                            parent: '1205',
                                            highlight: false,
                                            hide: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('editTax'),
                                            desc: null,
                                            value: '5201',
                                            parent: '1205',
                                            highlight: false,
                                            disabled: false,
                                            hide: false,
                                        },
                                    ],
                                },
                                {
                                    name: this.$t('warehouses'),
                                    desc: null,
                                    hide: false,
                                    value: '1206',
                                    isOpen: false,
                                    highlight: false,
                                    parent: '5',
                                    disabled: false,
                                    child:[
                                        {
                                            name: this.$t('addWarehouse'),
                                            desc: null,
                                            value: '5300',
                                            hide: false,
                                            parent: '1206',
                                            disabled: false,
                                            highlight: false,
                                        },
                                        {
                                            name: this.$t('editWarehouse'),
                                            desc: null,
                                            value: '5301',
                                            hide: false,
                                            highlight: false,
                                            parent: '1206',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeWarehouse'),
                                            desc: null,
                                            value: '5302',
                                            hide: false,
                                            highlight: false,
                                            parent: '1206',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('giveAccess'),
                                            hide: false,
                                            desc: null,
                                            value: '5303',
                                            parent: '1206',
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ],
                                },
                                {
                                    name: this.$t('cash-desk'),
                                    desc: null,
                                    value: '1207',
                                    parent: '5',
                                    highlight: false,
                                    isOpen: false,
                                    disabled: false,
                                    hide: false,
                                    child:[
                                        {
                                            name: this.$t('addCash-desk'),
                                            desc: null,
                                            hide: false,
                                            value: '5400',
                                            parent: '1206',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeCash-desk'),
                                            desc: null,
                                            hide: false,
                                            value: '5401',
                                            parent: '1206',
                                            disabled: false,
                                            highlight: false,
                                        },
                                        {
                                            name: this.$t('editCash-desk'),
                                            desc: null,
                                            hide: false,
                                            value: '5402',
                                            parent: '1206',
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('giveAccess'),
                                            desc: null,
                                            highlight: false,
                                            hide: false,
                                            value: '5403',
                                            parent: '1206',
                                            disabled: false,
                                        },
                                    ],
                                    //Didnt done
                                },
                                {
                                    name: this.$t('workshops'),
                                    desc: null,
                                    hide: false,
                                    value: '1208',
                                    parent: '5',
                                    isOpen: false,
                                    highlight: false,
                                    disabled: false,
                                    child:[
                                        {
                                            name: this.$t('addWorkshops'),
                                            desc: null,
                                            value: '5500',
                                            parent: '1208',
                                            highlight: false,
                                            disabled: false,
                                            hide: false,
                                        },
                                        {
                                            name: this.$t('editWorkshops'),
                                            desc: null,
                                            value: '5501',
                                            parent: '1208',
                                            disabled: false,
                                            hide: false,
                                            highlight: false,
                                        },
                                        {
                                            name: this.$t('removeWorkshops'),
                                            hide: false,
                                            desc: null,
                                            value: '5502',
                                            parent: '1208',
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('giveAccess'),
                                            hide: false,
                                            desc: null,
                                            value: '5503',
                                            highlight: false,
                                            parent: '1208',
                                            disabled: false,
                                        },
                                    ],
                                },
                                {
                                    name: this.$t('Employees'),
                                    hide: false,
                                    desc: null,
                                    value: '1212',
                                    parent: '5',
                                    disabled: false,
                                    highlight: false,
                                    isOpen: false,
                                    child:[
                                        {
                                            name: this.$t('addEmployee'),
                                            desc: null,
                                            hide: false,
                                            value: '5900',
                                            parent: '1212',
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeEmployee'),
                                            desc: null,
                                            value: '5902',
                                            hide: false,
                                            parent: '1212',
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('editEmployee'),
                                            desc: null,
                                            value: '5901',
                                            parent: '1212',
                                            highlight: false,
                                            disabled: false,
                                            hide: false,
                                        },
                                    ],
                                },
                                {
                                    name: this.$t('invoices'),
                                    desc: null,
                                    value: '1213',
                                    parent: '5',
                                    disabled: false,
                                    highlight: false,
                                    isOpen: false,
                                    hide: false,
                                    child:[
                                        {
                                            name: this.$t('addInvoice'),
                                            desc: null,
                                            highlight: false,
                                            hide: false,
                                            value: '6000',
                                            parent: '1213',
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('removeInvoice'),
                                            desc: null,
                                            value: '6002',
                                            parent: '1213',
                                            highlight: false,
                                            disabled: false,
                                            hide: false,
                                        },
                                        {
                                            name: this.$t('editInvoice'),
                                            desc: null,
                                            value: '6001',
                                            hide: false,
                                            parent: '1213',
                                            disabled: false,
                                            highlight: false,
                                        },
                                        {
                                            name: this.$t('giveAccess'),
                                            desc: null,
                                            value: '6003',
                                            parent: '1213',
                                            disabled: false,
                                            hide: false,
                                            highlight: false,
                                        },
                                    ],
                                },
                            ]
                        },
                        {
                            name: this.$t('apps'),
                            desc: null,
                            value: '7',
                            parent: 'accessRights',
                            isParent: true,
                            isOpen: false,
                            hide: false,
                            disabled: false,
                            highlight: false,
                            child : [
                                {
                                    name: this.$t('activeApp'),
                                    desc: null,
                                    value: '7700',
                                    hide: false,
                                    parent: '7',
                                    disabled: false,
                                    highlight: false,
                                },
                                {
                                    name: this.$t('access'),
                                    desc: null,
                                    value: '7701',
                                    hide: false,
                                    parent: '7',
                                    disabled: false,
                                    highlight: false,
                                },
                            ]
                        },
                    ],
                },
                {
                    name: this.$t('accessToTradePoints'),
                    // desc: this.$t('displayInfoBlocksdesc'),
                    isOpen: false,
                    value: 'accessToTradePoints',
                    disabled: false,
                    hide: false,
                    child: [
                        // {
                        //     name: this.$t('activeApp'),
                        //     desc: null,
                        //     value: '7700',
                        //     hide: false,
                        //     parent: '7',
                        //     disabled: false,
                        //     highlight: false,
                        // }, //example of object
                    ]
                },
                {
                    name: this.$t('acessToCashs'),
                    // desc: this.$t('displayInfoBlocksdesc'),
                    isOpen: false,
                    value: 'acessToCashs',
                    disabled: false,
                    hide: false,
                    child: [
                        //     name: this.$t('activeApp'),
                        //     desc: null,
                        //     value: '7700',
                        //     hide: false,
                        //     parent: '7',
                        //     disabled: false,
                        //     highlight: false,
                        // }, //example of object
                    ]
                },
                {
                    name: this.$t('acessToWarehouses'),
                    // desc: this.$t('displayInfoBlocksdesc'),
                    isOpen: false,
                    value: 'acessToWarehouses',
                    disabled: false,
                    hide: false,
                    child: []
                },
                {
                    name: this.$t('accessToInvoices'),
                    // desc: this.$t('displayInfoBlocksdesc'),
                    isOpen: false,
                    value: 'accessToInvoices',
                    disabled: false,
                    hide: false,
                    child: []
                },
                {
                    name: this.$t('acessToIncomesCategories'),
                    // desc: this.$t('displayInfoBlocksdesc'),
                    isOpen: false,
                    value: 'acessToIncomesCategories',
                    disabled: false,
                    hide: false,
                    child: []
                },
                {
                    name: this.$t('acessToExpensesCategories'),
                    // desc: this.$t('displayInfoBlocksdesc'),
                    isOpen: false,
                    value: 'acessToExpensesCategories',
                    disabled: false,
                    hide: false,
                    child: []
                },
                {
                    name: this.$t('acessToWorkShops'),
                    // desc: this.$t('displayInfoBlocksdesc'),
                    isOpen: false,
                    value: 'acessToWorkShops',
                    disabled: false,
                    hide: false,
                    child: []
                },
                {
                    name: this.$t('accessToPrograms'),
                    // desc: this.$t('displayInfoBlocksdesc'),
                    isOpen: false,
                    value: 'accessToPrograms',
                    disabled: false,
                    highlight: false,
                    hide: false,
                    child: [
                        {    
                            name: this.$t('CashDesk'),
                            desc: null,
                            highlight: false,
                            value: '6',
                            parent: 'accessToPrograms',
                            isOpen: false,
                            hide: false,
                            disabled: false,
                            child : [
                                {
                                    name: this.$t('Inventory'),
                                    desc: null,
                                    highlight: false,
                                    value: '1400',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                    child : [
                                        {
                                            name: this.$t("CreateInventory"),
                                            value: '3601',
                                            parent: '1400',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('EditInventory'),
                                            value: '3602',
                                            parent: '1400',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ]   
                                },
                                {
                                    name: this.$t('Incomings'),
                                    desc: null,
                                    highlight: false,
                                    value: '1401',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                    child : [
                                        {
                                            name: this.$t("ParishCreation"),
                                            value: '6400',
                                            parent: '1401',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('ParishEditing'),
                                            value: '6401',
                                            parent: '1401',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('ParishCancel'),
                                            value: '6402',
                                            parent: '1401',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ]   
                                },
                                {
                                    name: this.$t('WriteOff'),
                                    desc: null,
                                    highlight: false,
                                    value: '1402',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                    child : [
                                        {
                                            name: this.$t("CreateWriteoff"),
                                            value: '6500',
                                            parent: '1402',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('EditWriteoff'),
                                            value: '6501',
                                            parent: '1402',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('CancelWriteoff'),
                                            value: '6502',
                                            parent: '1402',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ]   
                                },
                                {
                                    name: this.$t('moving'),
                                    desc: null,
                                    highlight: false,
                                    value: '1403',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                    child : [
                                        {
                                            name: this.$t("CreateMoving"),
                                            value: '6600',
                                            parent: '1403',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t("EditMoving"),
                                            value: '6601',
                                            parent: '1403',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('CancelMoving'),
                                            value: '6602',
                                            parent: '1403',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ]   
                                },
                                {
                                    name: this.$t('cashTransaction'),
                                    desc: null,
                                    highlight: false,
                                    value: '1404',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                    child : [
                                        {
                                            name: this.$t("incasation"),
                                            value: '6700',
                                            parent: '1404',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('putting'),
                                            value: '6701',
                                            parent: '1404',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t("spend"),
                                            value: '6702',
                                            parent: '1404',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ]   
                                },
                                {
                                    name: this.$t('reportForDuty'),
                                    desc: null,
                                    highlight: false,
                                    value: '1405',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('historyOfSale'),
                                    desc: null,
                                    highlight: false,
                                    value: '1406',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('returns'),
                                    desc: null,
                                    highlight: false,
                                    value: '1407',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('prinPreBill'),
                                    desc: null,
                                    highlight: false,
                                    value: '1408',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('cancelOrder'),
                                    desc: null,
                                    highlight: false,
                                    value: '1409',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('sortGoods'),
                                    desc: null,
                                    highlight: false,
                                    value: '1410',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('splitBill'),
                                    desc: null,
                                    highlight: false,
                                    value: '1411',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('closeBillWithoutPay'),
                                    desc: null,
                                    highlight: false,
                                    value: '1412',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('CreateTransaction'),
                                    desc: null,
                                    highlight: false,
                                    value: '1413',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                    child : [
                                        {
                                            name: this.$t("addTransaction"),
                                            value: '3800',
                                            parent: '1413',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('addSpendTranscation'),
                                            value: '3900',
                                            parent: '1413',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t("addTransferTransaction"),
                                            value: '4000',
                                            parent: '1413',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ]   
                                },
                                {
                                    name: this.$t('giveAccessToAdmin'),
                                    desc: null,
                                    highlight: false,
                                    value: '1414',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('Production'),
                                    desc: null,
                                    highlight: false,
                                    value: '1415',
                                    parent: '6',
                                    isOpen: false,
                                    hide: false,
                                    disabled: false,
                                    child : [
                                        {
                                            name: this.$t("CreateProduction"),
                                            value: '8000',
                                            parent: '1415',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t('EditProduction'),
                                            value: '8001',
                                            parent: '1415',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                        {
                                            name: this.$t("CancelProduction"),
                                            value: '8002',
                                            parent: '1415',
                                            isOpen: false,
                                            hide: false,
                                            highlight: false,
                                            disabled: false,
                                        },
                                    ]   
                                },
                            ]
                        },
                        {    
                            name: 'Monobank',
                            desc: null,
                            highlight: false,
                            value: '7805',
                            parent: 'accessToPrograms',
                            isOpen: false,
                            hide: false,
                            disabled: false,
                        },
                        {    
                            name: this.$t('BeremoIRobymo'),
                            desc: null,
                            highlight: false,
                            value: '7806',
                            parent: 'accessToPrograms',
                            isOpen: false,
                            hide: false,
                            disabled: false,
                        },
                        {    
                            name: "Expirenza by mono",
                            desc: null,
                            highlight: false,
                            value: '7807',
                            parent: 'accessToPrograms',
                            isOpen: false,
                            hide: false,
                            disabled: false,
                        },
                    ]
                },
            ]
        }
    },
    computed: {

    }
}

</script>

<style scoped>
.block-as {
    margin: 15px auto;
}
.check-as {
    margin: 5px;
    margin-right: 14px;
}
.check-as .form-check-input {
    width: 1.6em;
    height: 1.6em;
}
.block-sub {
    margin-left: 30px ;
}
.block-fch {
    margin-left: 60px;
}
.block-fich {
    margin-left: 90px;
}
</style>