<template>
    <div class="col-12" bis_skin_checked="1">
                            <table class="body-wrap" style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; background-color: transparent; margin: 0;">
                                <tbody><tr style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                    <td style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;" valign="top"></td>
                                    <td class="container" width="600" style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; display: block !important; max-width: 600px !important; clear: both !important; margin: 0 auto;" valign="top">
                                        <div class="content" style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; max-width: 600px; display: block; margin: 0 auto; padding: 20px;" bis_skin_checked="1">
                                            <table class="main" width="100%" cellpadding="0" cellspacing="0" itemprop="action" itemscope="" itemtype="http://schema.org/ConfirmAction" style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; border-radius: 3px; margin: 0; border: none;">
                                                <tbody><tr style="font-family: 'Roboto', sans-serif; font-size: 14px; margin: 0;">
                                                    <td class="content-wrap" style="font-family: 'Roboto', sans-serif; box-sizing: border-box; color: #495057; font-size: 14px; vertical-align: top; margin: 0;padding: 30px; box-shadow: 0 3px 15px rgba(30,32,37,.06); ;border-radius: 7px; background-color: #fff;" valign="top">
                                                        <meta itemprop="name" content="Confirm Email" style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                                        <table width="100%" cellpadding="0" cellspacing="0" style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                                            <tbody><tr style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                                                <div bis_skin_checked="1" style="
    display: b;
    background-image: url(https://ci5.googleusercontent.com/proxy/rfMI1bQfskaO7jml4fVVRqwr52M9HIgC303HuAkX0S0aSsOmvXVZrwjbfg_IIm7iMFHDzZcb7APATKKq6nhzkPHddAHxgHD0IupZTSxnYIKNKimSdtp9tTaafzcr3s_1Wd3mnMioj0XpHpj-171OTxG698lj5YmfH9hRDEYhFcDyyQ=s0-d-e1-ft#https://s6896511.sendpul.se/files/emailservice/userfiles/f7edf6c2e51106f116f27a420f2dbde76896511/Vizitka_UA.png);
    height: 100%;
">
    ds
    
</div>
                                                                <td class="content-block" style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                                                                    <div style="text-align: center;" bis_skin_checked="1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock" style="color: #0ab39c;fill: rgba(10,179,156,.16); height: 30px; width: 30px;"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                                                <td class="content-block" style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 24px; vertical-align: top; margin: 0; padding: 0 0 10px;  text-align: center;" valign="top">
                                                                    <h4 style="font-family: 'Roboto', sans-serif; margin-bottom: 0px;font-weight: 500; line-height: 1.5;">Доброго дня!</h4>
                                                                </td>
                                                            </tr>
                                                            <tr style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                                                <td class="content-block" style="font-family: 'Roboto', sans-serif; color: #878a99; box-sizing: border-box; font-size: 15px; vertical-align: top; margin: 0; padding: 0 0 12px; text-align: center;" valign="top">
                                                                    <p style="margin-bottom: 13px; line-height: 1.5;">SkyService POS піклується про своїх клієнтів! Наша технічна підтримка на зв'язку 24/7!</p>
                                                                    <p style="margin-bottom: 13px; line-height: 1.5;">Якщо у вас залишилися питання, звертайтесь за вказаними Контактами. <br />Ми відповимо на всі ваші запитання!</p>
                                                                </td>
                                                            </tr>

                                                            <hr />

                                                            <tr style="font-family: 'Roboto', sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                                                                <td class="content-block" style="font-family: 'Roboto', sans-serif; color: #878a99; box-sizing: border-box; font-size: 15px; vertical-align: top; margin: 0; padding: 0 0 12px; text-align: center;" valign="top">
                                                                    <p class="mb-2 fs-18 fw-semibold">Ми працюємо для Вас! <br />З повагою, команда SkyService POS.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <div style="text-align: center; margin: 28px auto 0px auto;" bis_skin_checked="1">
                                                <h4>Need Help ?</h4>
                                                <p style="color: #878a99;">Please send and feedback or bug info to <a href="" style="font-weight: 500px;">info@velzon.com</a></p>
                                                <p style="font-family: 'Roboto', sans-serif; font-size: 14px;color: #98a6ad; margin: 0px;">2022 Velzon. Design &amp; Develop by Themesbrand</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody></table>
                            <!-- end table -->
                        </div>
</template>

<script>

export default ({
    data() {
        return{
            form: ''
        }
    },
})
</script>
