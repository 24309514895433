<template>
    
    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        :objParams="objParams"
        @open="open"
    />

    <viewTerminal 
        v-if="showTerminal"
        @close="showTerminal = false"
        :obj="objCard"
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewTerminal from '../terminals/viewTerminal/index'
import { mutateTariffingOutlets, mutateServerSyncOutlets } from '@/usabilityScripts/globalMutate.js'
import { Dashboard } from '@/API_Dash'

let apiServe = new Dashboard();

export default{
    props: ['obj', 'objTradepoints', 'companyId'],
    components: {
        tablecustom,
        viewTerminal
    },
    data(){
        return{
            form: '',
            objCard: '',
            showTerminal: false,
            showModal: false,
            objParams: {
                search: ''
            },
            rows: '',
            columns: [
                {
                    name: "ID",
                    text: "terminalId",
                    align: "left",
                    status: false,
                    copy: true,
                },
                {
                    name: this.$t("Name"),
                    text: "terminalName",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("outlet"),
                    text: "tradepointId",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item) => item ? this.getTitleTT(item) : '-'
                },
            ],
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.rows = this.obj
        },
        open(e){
            apiServe.getTerminal(this.companyId, e.terminalId).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data;
                    this.showTerminal = true;
                } else if(result.status == 'error' && result.error[0] == 'empty company url'){
                    this.$toast.error(this.$t('emptyCompanyUrl'));
                } else {
                    this.$toast.error(this.$t('terminalNotFound'))
                }
            })
        },
        getTitleTT(item){
            var name = ''
            this.objTradepoints.forEach(element => {
                if(element.tradepointId == item){
                    name = element.tradepointName
                }
            });
            return name
        },
        mutateTariffing(e){
          return mutateTariffingOutlets(e)
        },
        mutateServerSync(e){
            return mutateServerSyncOutlets(e)
        },
        searchB(e){
            this.objParams.search = e
        },
    },
    computed: {
        filteredItems: function() {
            return this.rows
                // по пошуку
                .filter(item => {
                    return this.objParams.search == '' || item.tradepointName.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>