<template>
    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        @open="open"
        :datafilter="filterAll"
        @change="change" 
        @clearFilter="clearFilter"
    />

    <modal v-if="showModal" @close="showModal = false" :title="item.name">
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <viewbox v-if="showModal" @close="showModal = false" :obj="this.item" :editform="this.editform" />
        </template>
    </modal>
    <!-- <viewbox v-if="showModal" @close="showModal = false" :obj="this.item" :editform="this.editform" /> -->

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import modal from '@/components/modal/modal-lg'
import viewbox from './view'

export default {
    props: ['obj', 'tt'],
    components: { 
        tablecustom, 
        viewbox, 
        modal 
    },
    data() {
        return {
            form: {},
            oldRow: "",
            search: "",
            showModal: false,
            item: "", 
            editform: false,
            columns: [
                {
                    name: this.$t("ID"),
                    text: "id",
                    align: "left",
                    status: false,
                },
                {
                    name: "ТТ",
                    text: "namett",
                    align: "left",
                    status: true,
                },
                // {
                //     name: "Status",
                //     text: "statustt",
                //     align: "left",
                // },
                {
                    name: this.$t("Point_name"),
                    text: "name",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("type"),
                    text: "type",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("manufacturer"),
                    text: "manufacturer",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("bank_terminal_model"),
                    text: "model",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("OS"),
                    text: "os",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Protocol"),
                    text: "protocol",
                    align: "left",
                    status: true,
                }
          ],
            filterAll: [
                {
                    page: "devices",
                    name: "outlet",
                    category: "0",
                    items: []
                },
                {
                    page: "devices",
                    name: "type",
                    category: "0",
                    items: []
                }
            ],
            selectnamett: "0",
            selecttype: "0"
        }
    },
    created(){
        this.form = this.obj;
        this.outlets = this.tt;
    
        for(var item in this.form){
            for(var value in this.outlets){
                if(this.form[item].tradepointId === this.outlets[value].tradepointId){
                    // this.form[item].statustt = this.$t('active');
                    this.form[item].namett = this.outlets[value].title;
                    break;
                } else {
                    this.form[item].namett = 'remove';
                }
            }
        }
        let newobj = this.form;
        let newArrStatus = Array.from(new Set(newobj.map(item => JSON.stringify(item.namett)))).map(item => JSON.parse(item));
        this.filterAll[0]['items'] = newArrStatus;
        let newArrType = Array.from(new Set(newobj.map(item => JSON.stringify(item.type)))).map(item => JSON.parse(item));
        this.filterAll[1]['items'] = newArrType;

        // for(var item in this.form){
        //     this.form[item].protocol = this.mutateProtocol(this.form[item].protocol)
        // }
        // this.getdata()
    },
    methods: {
        getdata(value, oldValue){
            if(value != undefined){
                this.form = value;
            }
        },
        searchB(e){
            this.search = e
        },
        open(e){
            this.item = e;
            this.showModal = true;
        },
        change(name, e){
            if(name == 'outlet'){
                this.selectnamett = e;
            } else if (name == 'type'){
                this.selecttype = e;
            }
        },
        clearFilter(){
            this.selectnamett = "0";
            this.selecttype = "0";
        },
        mutateProtocol(e){
            var name = ""
            switch (e) {
                case "electron_escpos_ethernet":
                    name = this.$t('electron_escpos_ethernet')
                    break;
                case "electron_windowssystem_printer":
                    name = this.$t('electron_windowssystem_printer')
                    break;
                case "electron_escpos_bluetooth":
                    name = this.$t('electron_escpos_bluetooth')
                    break;
                case "electron_escpos_usb":
                    name = this.$t('electron_escpos_usb')
                    break;
                case "printserver_windows_1808":
                    name = this.$t('printserver_windows_1808')
                    break;
                case "epson_sdk_android_bluetooth_new":
                    name = "Bluetooth"
                    break;
                case "epson_sdk_android_usb_new":
                    name = "USB"
                    break;
                case "epson_sdk_android_ethernet_new":
                    name = this.$t('electron_escpos_ethernet')
                    break;
                case "universal_sdk_ios_ethernet":
                    name = this.$t('electron_escpos_ethernet')
                    break;
                case "epson_sdk_ios_bluetooth":
                    name = "Bluetooth"
                    break;
                case "system_printer_label_windows":
                    name = this.$t('system_printer_label_windows')
                    break;
                case "usb_printer_label_windows":
                    name = this.$t('electron_escpos_usb')
                    break;
                case "usb_label_android":
                    name = "USB"
                    break;
                case "d2mini":
                    name = "Sunmi SDK"
                    break;
                case "escpos_ingeniko_smart":
                    name = "SDK Driver"
                    break;
                case "verifone_x990":
                    name = "APK Driver"
                    break;
                case "smart_pos":
                    name = "APK Driver"
                    break;
                case "possectorSmartCubeDisplay":
                    name = "SmartCube APK"
                    break;
                case "androidcenterm":
                    name = "Centerm SDK"
                    break;
                case "android_pos_posin_new":
                    name = "Posin SDK"
                    break;
                case "androidsunmi":
                    name = "Sunmi SDK"
                    break;
                case "helpmicro":
                    name = "HTTP"
                    break;
                case "helpmicro2":
                    name = "HTTP 2"
                    break;
                case "minifp_usb_android_v2":
                    name = "USB"
                    break;
                case "minifp_ip_android_v2":
                    name = "Ethernet/WiFi"
                    break;
                case "artsoft_box":
                    name = "artsoft.ua"
                    break;
                case "uaprro":
                    name = "ПРРО"
                    break;
                case "banking_emulator":
                    name = "Emulator"
                    break;
                case "smartposmini":
                    name = "APK Driver"
                    break;
                case "portmone_QR":
                    name = "Portmone.com QR pay"
                    break;
                case "android_sumup_sdk":
                    name = "SunIP EMV"
                    break;
                case "android_ingenico_bpos_sdk_ua":
                    name = "BPOS USB"
                    break;
                case "android_ingenico_bpos_ip":
                    name = "BPOS Ethernet/WiFi"
                    break;
                case "android_ingenico_bpos_privatbank_sdk_ua":
                    name = "PrivatBank BPOS USB"
                    break;
                case "android_ingenico_bpos_privatbank_sdk_ua_tcpip":
                    name = "PrivatBank BPOS Ethernet/WiFi"
                    break;
                case "android_jsonbased_privatbank_usb":
                    name = "PrivatBank json-based USB"
                    break;
                case "android_jsonbased_privatbank_ip":
                    name = "PrivatBank json-based Ethernet/WiFi"
                    break;
                case "android_ingenico_bposlight_usb_2":
                    name = "BposLight USB"
                    break;
                case "android_ingenico_bposlight_ip_2":
                    name = "BposLight Ethernet/WiFi"
                    break;
                case "android_ingenico_bpos_sdk_ua_tcpip":
                    name = "BPOS Ethernet/WiFi"
                    break;
                case "android_ingenico_bpos_noprinter_sdk_ua_tcpip":
                    name = "BPOS Ethernet/WiFi"
                    break;
                case "android_ingenico_bpos_noprinter_privatbank_sdk_ua_tcpip":
                    name = "PrivatBank BPOS Ethernet/WiFi"
                    break;
                case "android_ingenico_smart_sdk_ua_2":
                    name = "SDK Driver"
                    break;
                case "android_verifone_usb_2":
                    name = "USB SSI"
                    break;
                case "android_verifone_ip_2":
                    name = "Ethernet/WiFi SSI"
                    break;
                case "android_jsonbased_privatbank_usb_pax":
                    name = "PrivatBank json-based USB"
                    break;
                case "android_jsonbased_privatbank_ip_pax":
                    name = "PrivatBank json-based Ethernet/WiFi"
                    break;
                case "windows_jsonbased_privatbank_com_pax":
                    name = "PrivatBank json-based COM"
                    break;
                case "android_privatreader_sdk":
                    name = "Bluetooth"
                    break;
                case "shtrih_m_weightserver":
                    name = "Skyservice weight server"
                    break;
                case "windows_shtrih_m_ip":
                    name = "Ethernet/WiFi"
                    break;
                case "cas_skyapp_libra":
                    name = "Skyservice Desktop"
                    break;
                case "cas_weightserver":
                    name = "Skyservice weight server"
                    break;
                case "ikc_skyapp_libra":
                    name = "Skyservice Desktop"
                    break;
                case "datalogic_weightserver":
                    name = "Skyservice weight server"
                    break;
                case "axis_weightserver":
                    name = "Skyservice weight server"
                    break;
                case "axis_skyapp_libra":
                    name = "Skyservice Desktop"
                    break;
                case "aurora_skyapp_libra":
                    name = "Skyservice Desktop"
                    break;
                case "cas_usb":
                    name = "COM -> USB Adapter"
                    break;
                case "cas_com":
                    name = "COM (only jassway)"
                    break;
                case "ikcweightandroid":
                    name = "COM -> USB Adapter"
                    break;
                case "axis_android_libra":
                    name = "USB"
                    break;
                case "aurora_android_libra_serial":
                    name = "Serial (Only POS-terminal support)"
                    break;
                case "aurora_android_libra_usb":
                    name = "USB"
                    break;
                case "scanner_datalogic_windows_com":
                    name = "COM"
                    break;
                case "poolserver1":
                    name = "Poolserver v1.0"
                    break;
                case "hallscreen_windows":
                    name = this.$t('hallscreen_windows');
                    break;
                default:
                    break;
            }
            return name
        },
    },
    mounted(){
    },
    watch:{
        obj(value, oldValue){
            this.getdata(value, oldValue)
        }
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.id.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.name.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.namett.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ;
                })

                // По точке
                .filter(item => {
                    return this.selectnamett == 0 || item.namett == this.selectnamett;
                })

                // По типу
                .filter(item => {
                    return this.selecttype == 0 || item.type == this.selecttype;
                })
        },
    }
}
</script>