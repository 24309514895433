<template>
    <infoblocks  :obj="this.blocks" style="margin-top:15px;" />
    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="checks['001']">
      <button @click="infoblockShow == true ? infoblockShow = false : infoblock()" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
    </div>
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />
    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        @open="open"
    />
    <!-- <template v-if="showModal == true" >
        <viewpay @close="showModal = false" :shortapplic="cardApplic"/>
    </template> -->
</template>

<script>
import tablecustom from '@/components/globaltable/index';
import infoblocks from '@/components/info/blocks'
import { storeS } from '@/store';
import { mutatePayPayments } from '@/usabilityScripts/globalMutate.js'
// import viewpay from './view'

export default {
    props: ['obj', 'license'],
    components: { 
        tablecustom, 
        // viewpay, 
        infoblocks 
    },
    data() {
        return {
            form: {},
            search: "",
            cardApplic: "",
            showModal: false,
            infoblockShow: false,
            currency: "",
            columns: [
                {
                    name: this.$t("Result"),
                    text: "status",
                    align: "left",
                    copy: true,
                    status: true,
                    mutate: (item) => this.mutatePay(item).name,
                    mutateClass: (item) => this.mutatePay(item).color
                },
                {
                    name: this.$t("Gateway"),
                    text: "description",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("Sum"),
                    text: "amount",
                    align: "right",
                    copy: true,
                    status: true,
                    mutate: (item) => item + " " + this.currency,
                },
                {
                    name: this.$t("date_time"),
                    text: "date",
                    align: "right",
                    status: true,
                    copy: true
                },
            ],
            filterAll: [
                {
                    page: "outlets",
                    name: "Status",
                    category: "0",
                    items: []
                }
            ],
            information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                    sum: "0"
                },
                {
                    title: this.$t('successfully'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success",
                    sum: "0"
                },
                {
                    title: this.$t('notSuccessful'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning",
                    sum: "0"
                },
            ],
            blocks: [
                {
                    title: this.$t('tariffPlan'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning",

                },
                {
                    title: this.$t('SubscriptionFee'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger",
                },
                {
                    title: this.$t('PaidUpTo'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success",
                },
                {
                    title: this.$t('CurrentBalance'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                },
                {
                    title: this.$t('Lacks'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "primary",
                }
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj;
            this.currency = this.form[0].currency;
            let newobj = this.form
            let newArrStatus = Array.from(new Set(newobj.map(item => JSON.stringify(item.status)))).map(item => JSON.parse(item));
            this.filterAll[0]['items'] = newArrStatus;

            this.blocks[0].value = this.license.tariff_name
            this.blocks[1].value = this.license.summ + " " + this.currency
            this.blocks[2].value = this.license.active_account
            this.blocks[3].value = this.license.balance + " " + this.currency
            this.blocks[4].value = this.license.summ - this.license.balance + " " + this.currency
        },
        infoblock(){
            this.information[0].value = this.filteredItems.length
            this.information[1].value = this.filteredItems.filter(item => item.status == 'success').length
            this.information[2].value = this.filteredItems.filter(item => item.status == 'failure').length
            
            this.information[0].sum = this.arraySum(this.filteredItems).toFixed(2)
            this.information[1].sum = this.arraySum(this.filteredItems.filter(item => item.status == 'success')).toFixed(2)
            this.information[2].sum = this.arraySum(this.filteredItems.filter(item => item.status == 'failure')).toFixed(2)
            this.infoblockShow = true
        },
        arraySum(array){
            var sum = 0;
            for(var i = 0; i < array.length; i++){
                sum += Number(array[i].amount);
            }
            return sum
        },
        searchB(e){
            this.search = e
        },
        // change(name, e){
        //     if(name == 'Status'){
        //         this.selectStatus = e;
        //     }
        // },
        open(e){
            this.cardApplic = e;
            this.showModal = true;
        },
        mutatePay(e){
            return mutatePayPayments(e)
        },
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.amount.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })

        },
        checks(){
            return storeS.checks
        }
    }
}
</script>