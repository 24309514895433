<template>
    <div class="border-end" style="padding-right: 7px;">
        <div style="margin: 10px;">
            <div class="border-bottom">
                <div class="d-flex align-items-center mb-2" @click="showAbout = !showAbout" style="cursor: pointer;">
                    <div class="flex-grow-1" >
                        <h5 class="card-title mb-0">{{ $t('aboutApplication') }} 
                            <i :class="`${showAbout ? 'ri-arrow-down-line' : 'ri-arrow-right-line'} fs-16`"></i>
                        </h5>
                    </div>
                </div>
                <div class="card-body" v-if="showAbout">
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-2 text-muted">ID</h5>
                                <p class="fs-15 text-black mb-0 fw-normal">{{ form.requestId }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-2 text-muted">{{ $t('phone_number') }}</h5>
                                <p class="fs-15 text-black mb-0 fw-normal">{{ form.userPhone }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-2 text-muted">{{ $t('Message') }}</h5>
                                <p class="fs-15 text-black mb-0 fw-normal">{{ form.createComment }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin: 10px;">
            <div class="border-bottom">
                <div class="d-flex align-items-center mb-2" @click="showCreatedT = !showCreatedT" style="cursor: pointer;">
                    <div class="flex-grow-1" >
                        <h5 class="card-title mb-0">{{ $t('сreatedT') }} 
                            <i :class="`${showCreatedT ? 'ri-arrow-down-line' : 'ri-arrow-right-line'} fs-16`"></i>
                        </h5>
                    </div>
                </div>
                <div class="card-body" v-if="showCreatedT">
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-2 text-muted">{{ $t('Employee') }}</h5>
                                <p class="fs-15 text-black mb-0 fw-normal">{{ form.createWorkerName }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-2 text-muted">{{ $t('сreatedT') }}</h5>
                                <p class="fs-15 text-black mb-0 fw-normal">{{ form.createDatetime }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin: 10px;">
            <div class="border-bottom">
                <div class="d-flex align-items-center mb-2" @click="showPerformance = !showPerformance" style="cursor: pointer;">
                    <div class="flex-grow-1" >
                        <h5 class="card-title mb-0">{{ $t('performance') }} 
                            <i :class="`${showPerformance ? 'ri-arrow-down-line' : 'ri-arrow-right-line'} fs-16`"></i>
                        </h5>
                    </div>
                </div>
                <div class="card-body" v-if="showPerformance">
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-2 text-muted">{{ $t('TakentoWork') }}</h5>
                                <p class="fs-15 text-black mb-0 fw-normal">{{ form.inJobWorkerName }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-2 text-muted">{{ $t('StartedRunning') }}</h5>
                                <p class="fs-15 text-black mb-0 fw-normal">{{ form.inJobDatetime }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-check form-check-success mb-3">
                        <input class="form-check-input" type="checkbox" id="formCheck8" v-model="checked">
                        <label class="form-check-label" for="formCheck8">{{ $t('LeaveCall') }}</label>
                    </div>
                    <div v-if="checked == true" class="mb-3">
                        <label class="form-label mb-0">{{ $t('specify_date_timeNeedCall') }}</label>
                        <flat-pickr
                        v-model="date1"
                        :config="dateTimeConfig"
                        class="form-control"
                        ></flat-pickr>
                    </div>
                    <form class="mt-4">
                        <div class="row g-3">
                            <div class="col-lg-12">
                                <label for="exampleFormControlTextarea1" class="form-label">{{ $t('Comment') }}</label>
                                <textarea class="form-control bg-light border-light" id="exampleFormControlTextarea1" rows="3" v-model="comment" :placeholder="$t('enterComment')"></textarea>
                            </div>
                            <div class="col-12 text-end">
                                <a  class="btn btn-success" @click="addComment(comment)">{{ $t('save') }}</a>
                            </div>
                        </div>
                    </form>  -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default({
    props: ['obj'],
    data() {
        return {
            form: '',
            showAbout: true,
            showCreatedT: true,
            showPerformance: true,
            checked: false
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj
        }
    }
})
</script>
