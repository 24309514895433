<template>
    <tablecustom @search="searchB" :columns="columns" :rows="filteredItems" @open="open"/>

    <viewbox v-if="showModal" @close="showModal = false" :obj="this.item" />
</template>

<script>
import tablecustom from '@/components/globaltable/index';
// import viewbox from './skymarket/view'
import viewbox from './view'
import { Clients } from '@/API'

let apiServe = new Clients();

export default {
    props: ['number'],
    components: { 
        tablecustom, 
        viewbox 
    },
    data() {
        return {
            form: [],
            oldRow: "",
            search: "",
            showModal: false,
            item: "", 
            columns: [
                {
                    name: this.$t("Point_name"),
                    text: "name",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item) => item ? item : 'mono'
                }
          ],
        }
    },
    created(){
        // this.form = this.obj;
        // for(var item in this.obj){
        //     if(this.obj[item].name){
        //         this.form.push(this.obj[item])
        //     }else{
        //         var value = { name: this.obj[item].data[0].app_name, data: this.obj[item].data }
        //         this.form.push(value)
        //     }
        // }
        this.getdata()
    },
    methods: {
        getdata(){
            apiServe.getClient(this.number).then(result => {
                if(result.status == 'done'){
                    console.log('re', result);
                    this.form = result.data.apps;
                }
            })
        },
        searchB(e){
            this.search = e
        },
        open(e){
            this.item = e;
            this.showModal = true;
        }
    },
    mounted(){
    },
    watch:{
        obj(value, oldValue){
            this.getdata(value, oldValue)
            // ("value", value)
            // ("oldValue", oldValue)
        }
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.id.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.name.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ;
                })
        },
    }

}
</script>