<template>
    <!-- 
        Модальне вікно створення штрафу 
    -->
    
    <modal>
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('addFine') }}</template>
        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            <!-- Співробітник/кому штраф -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('Employee') }}</h4>
                <Multiselect 
                    v-model="form.workerId"
                    :close-on-select="true"
                    :searchable="true"
                    :options="this.workersList"
                    :placeholder="$t('enterSearchTerm')"
                />
            </div>

            <!-- Тип штрафу -->
            <div class="mb-4">
                <h4 class="fs-15">{{ $t('typeFine') }}</h4>
                <Multiselect 
                    v-model="form.type"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.types"
                    :placeholder="$t('enterSearchTerm')"
                />
            </div>
            <div class="row">
                <!-- Статус штрафу -->
                <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('choose_a_status') }}</h4>
                        <Multiselect 
                            v-model="form.status"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.status"
                            :placeholder="$t('Status')"
                            disabled
                        />
                    </div>
                </div>
                <!-- Сума штрафу -->
                <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Enter_sum_fine') }}</h4>
                        <Multiselect 
                            v-model="form.sum"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="true"
                            :options="this.sumSevice"
                            :placeholder="$t('Sum')"
                        />
                    </div>
                </div>
            </div>
            <!-- Коментар до штрафу -->
            <div class="mb-3">
                <label for="VertimeassageInput" class="form-label">{{ $t('Message') }}</label>
                <div class="mt-1">
                    <QuillEditor v-model:content="this.text" theme="snow" contentType="html" toolbar="full" @textChange="textChange"/>
                </div>
            </div>
        </template>

        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom>
            <!-- 
                Кнопка "Додати" 
                - надає змогу створити штраф
            -->
            <button 
                v-if="perm == false" 
                type="button" 
                class="btn btn-success" 
                v-on:click="add"
            >
                {{ $t('Add') }}
            </button>

            <!-- 
                Кнопка "Редагувати" 
                - надає змогу редагувати штраф
            -->
            <button 
                v-else 
                type="button" 
                class="btn btn-success" 
                v-on:click="edit"
            >
                {{ $t('Edit') }}
            </button>

        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small' // компонент модального вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Fines } from '@/API.js' // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Fines();

export default ({
    props: ['obj', 'perm'],
    data(){
        return {
            text: '', // коментар
            form: { // Дані для форми
                type:"", // тип штрафу
                status: "waitingСonfirmation", // статус штрафу
                sum: "", // сума штрафу
                message: "", // коментар до штрафу
                workerId: "" // id співробітника кому штраф
            },
            workersList: [{}], // список співробітників
            status: [ 
                {
                    // статус "анульовано"
                    "label": this.$t('annul'),
                    "value": "annul"
                },
                {
                    // статус "чекає на підтвердження"
                    "label": this.$t('AwaitingСonfirmation'),
                    "value": "waitingСonfirmation"
                },
                {
                    // статус "підтверджено"
                    "label": this.$t('Confirmed'),
                    "value": "confirmed"
                }
            ],
            types: [ // список типів штрафу
                {
                    // тип штрафу "запізнення"
                    "label": this.$t('beinglate'),
                    "value": "beinglate"
                },
                {
                    // тип штрафу "не виконано завдання у термін"
                    "label": this.$t('taskNotCompletedTime'),
                    "value": "taskNotCompletedTime"
                },
                {
                    // тип штрафу "не виконано проект у термін дедлайну"
                    "label": this.$t('projectNotCompletedTime'),
                    "value": "projectNotCompletedTime"
                },
                {
                    // тип штрафу "Образлива поведінка стосовно клієнта"
                    "label": this.$t('rudeBehavior'),
                    "value": "rudeBehavior"
                },
            ],
            sumSevice: [ // суми штрафу
                {
                    "label": "0",
                    "value": "0.00"
                },
                {
                    "label": "100",
                    "value": "100.00"
                },
                {
                    "label": "200",
                    "value": "200.00"
                },
                {
                    "label": "300",
                    "value": "300.00"
                },
                {
                    "label": "500",
                    "value": "500.00"
                },
                {
                    "label": "1000",
                    "value": "1000.00"
                },
                {
                    "label": "1500",
                    "value": "1500.00"
                }
            ]
        }
    },
    components: { modal, Multiselect },
    created(){

        // Встановлення значень форми на основі об'єкта, якщо він існує
        if(this.obj){
            this.form.fineId = this.obj.fineId;
            this.form.type = this.obj.type;
            this.form.status = this.obj.fineStatus;
            this.form.sum = this.obj.fineSum;
            this.form.message = this.obj.message;
            this.form.workerId = this.obj.workerId;
        }

        // Заповнення списку співробітників для вибору в формі
        for(var item in this.workers){
            this.workersList.push({
                label: this.workers[item].workerName,
                value: this.workers[item].workerId,
            })
        }
    },
    methods: {
        textChange(e) {
            /**
                * Обробник події для зміни тексту в текстовому редакторі.
                * @param {Object} e - Об'єкт події зміни тексту.
            */

            if(e.delta.ops[1] == undefined) {
                if(e.delta.ops[0]?.delete || e.delta.ops[1]?.delete) {
                    // Видалення символа, якщо операція видалення виконана
                    this.form.message = this.form.message.slice(0, -1)
                } else {
                    // Додавання нового символу, якщо операція вставки виконана
                    this.form.message += e.delta.ops[0].insert
                }
                
            } else if(e.delta.ops[1].insert){
                // Додавання символу, якщо операція вставки виконана
                this.form.message += e.delta.ops[1].insert
            } else if(e.delta.ops[1].delete) {
                // Видалення символа, якщо операція видалення виконана
                this.form.message = this.form.message.slice(0, -1)
            }
        },
        add() {
            /**
                * Додавання нового штрафу з урахуванням валідації полів.
            */

            if (!this.form.type) {
                //перевіряємо чи вказаний тип штрафу
                this.$toast.error(this.$t('EnterTypeFine'));
            }
            if (!this.form.workerId) {
                //перевіряємо чи вказаний співробітник
                this.$toast.error(this.$t('EnterWorker'));
            }
            if (!this.form.sum) {
                //перевіряємо чи вказана сума штрафу
                this.$toast.error(this.$t('EnterSum'));
            }
            if (!this.form.message) {
                //перевіряємо чи вказаний коментар до штрафу
                this.$toast.error(this.$t('EnterDesc'));
            }
            if(this.form.type && this.form.workerId && this.form.sum && this.form.message){

                apiServe.addFine(this.form.workerId, this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('Added'));
                        this.eventBus.emit('saveFines', true)
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var tempObj = {
                            "workerId": this.form.workerId,
                            "params": this.form
                        }

                        var errorText = 'Помилка створення штрафу'; 
                        var statusRequest = result.status;
                        var api = 'addFine';
                        var fileRequest = 'src/views/statistics/fines/create.vue';
                        var params = tempObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
        edit(){
            /**
                * Редагування існуючого штрафу.
            */

            apiServe.editFine(this.form.fineId, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Added'));
                    // this.eventBus.emit('saveFines', true)
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "fineId": this.form.fineId,
                        "params": this.form
                    }

                    var errorText = 'Помилка редагування штрафу'; 
                    var statusRequest = result.status;
                    var api = 'editFine';
                    var fileRequest = 'src/views/statistics/fines/create.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        workers() {
            /*
                Беремо список співробітників з глобального стору.
            */
            return storeS.workers
        }
    }
})
</script>
