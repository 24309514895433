<template>
    <modal >
        <template v-slot:title>{{ $t('newApplication') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <div class="mb-3">
            <h4 class="fs-15">{{ $t('information_create_application') }}</h4>
            <input type="text" class="form-control" id="contactNumber" placeholder="+380"  v-model="this.form.userPhone">
            </div>
            <div class="mb-3">
                <label for="VertimeassageInput" class="form-label">{{ $t('Message') }}</label>
                <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="form.comment" :placeholder="$t('infoFutureCall')"></textarea>
            </div>

            <template v-if="infoBlock">
                <hr />
                <h5 class="card-title fw-semibold" style="margin: 10px 10px;">{{ $t('Вибрана група') }} - </h5>
                <b-row>
                    <b-col lg="4">
                        <div class="card" >
                            <div class="card-body">
                                <div :class="`p-3 mt-n3 mx-n3 ${this.mutatePriority(infoBlock.priority).color} rounded-top`">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="mb-0 fs-14 click" 
                                        >
                                            <b>{{ infoBlock.categoryName }}</b>
                                        </h5>
                                    </div>
                                </div>
                                </div>

                                <div class="py-3">
                                <div class="row gy-3">
                                    <div class="col-6">
                                        <div>
                                            <p class="text-muted mb-1">{{ $t('priority') }}</p>
                                            <div :class="`badge ${this.mutatePriority(infoBlock.priority).color} fs-12`">
                                            {{ this.mutatePriority(infoBlock.priority).name }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div>
                                            <p class="text-muted mb-1">{{ infoBlock.taskName }}</p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </template>
            
        </template>
        <template v-slot:footer-bottom>
            <button type="button" v-if="this.perm !== true" class="btn btn-success" v-on:click="addR">{{ $t('Add') }}</button>
            <button type="button" v-else class="btn btn-success" v-on:click="edit">{{ $t('Edit') }}</button>
        </template>
    </modal>

</template>
 
<script>
import modal from '@/components/modal-small'
import { mutatePriorityOther } from '@/usabilityScripts/globalMutate.js'
import { OtherCalls } from '@/API.js';
import { storeS } from '@/store';

let apiServe = new OtherCalls();

export default ({
    props: ["id", "editForm", "perm", "userPhone", "infoBlock"],
    components: {
        modal 
    },
    data(){
        return {
            form: {
                userPhone: "",
                comment: ""
            },
        }
    },
    created(){
        if(this.perm == true) {
            this.form = this.editForm
            this.form.comment = this.form.createComment
        }

        if(this.userPhone){
            this.form.userPhone = this.userPhone
        }
        console.log(this);
    },
    beforeUnmount() {
        if(this.form.userPhone !== '' || this.form.comment !== '') {
            let openRequest = indexedDB.open("store", 1);
                openRequest.onsuccess = function() {
                    let db = openRequest.result;
                    let transaction = db.transaction("data", "readwrite"); // (1)
                
                    // получить хранилище объектов для работы с ним
                    let data = transaction.objectStore("data"); // (2)
                    let category_text = 'otherCall';
                    let name = this.user.name
                    let detail = {
                        dateTime: new Date(),
                        text: name,
                        category: category_text,
                        form: JSON.stringify(this.form)
                    };

                    let request = data.add(detail); // (3)

                    request.onsuccess = function() { // (4)
                        console.log("Данные добавлены в хранилище", request.result);
                    };

                    request.onerror = function() {
                        console.log("Ошибка", request.error);
                    };
                } //додавання в indexedDB
        }
    },
    methods: {
        addR() {
            if(this.form.userPhone == '') {
                this.$toast.error(this.$t('enterPhoneNumber'))
            } else {
                apiServe.addRequest(this.id, this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('close')
                        this.$emit('getdata')
                    }
                })
            }
            
        },
        edit() {
            delete this.form.createComment
            if(this.form.comment == '') {
                this.$toast.error(this.$t('enterComment'))
            } else if(this.form.userPhone) {
                this.$toast.error(this.$t('enterPhoneNumber'))
            } else {
                apiServe.editRequest( this.form, this.form.requestId).then(result=>{
                    if(result.status=='done') {
                        this.$toast.success(this.$t('alertChanged'));
                        this.$emit('close')
                        this.$emit('getdata')
                    }
                })
            }
            
        },
        mutatePriority(e){
            return mutatePriorityOther(e);
        },
        
    },
    mounted(){
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
})
</script>
 
<style>
.item_list {
    border-bottom: 2px dashed #ced4da;
    padding: 15px 0px;
}
</style>