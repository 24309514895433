<template>
    <modal :title="form.tradepointName" >
        <template v-slot:status>
            <span :class="`fs-12 ${mutateTariffing(form.tradepointStatus).color}`">{{ mutateTariffing(form.tradepointStatus).name }}</span>
        </template>
        <template v-slot:body>
            <b-card>
                <b-row>
                    <div class="card-header mb-3">
                        <h5 class="card-title mb-0"><b>{{ $t('main') }}</b></h5>
                    </div>
                    <b-col lg="6">
                        <h4 class="fs-18 fw-semibold">{{ $t('id_tt') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.tradepointId" disabled>
                            <button @click="copy(form.tradepointId)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h4 class="fs-18 fw-semibold">{{ $t('Point_name') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.tradepointName" disabled>
                            <button @click="copy(form.tradepointName)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <div class="card-header mb-3">
                        <h5 class="card-title mb-0"><b>{{ $t('Location') }}</b></h5>
                    </div>
                    <b-col lg="6" >
                        <h4 class="fs-18 fw-semibold">{{ $t('city') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.tradepointData.contacts.city ? form.tradepointData.contacts.city : '-'" disabled>
                            <button @click="copy(form.tradepointData.contacts.city)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6" >
                        <h4 class="fs-18 fw-semibold">{{ $t('address') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.tradepointData.contacts.address ? form.tradepointData.contacts.address : '-'" disabled>
                            <button @click="copy(form.tradepointData.contacts.address)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/modal-lg'
import { mutateTariffingOutlets, mutateServerSyncOutlets } from '@/usabilityScripts/globalMutate.js'
import {copy} from '@/usabilityScripts/copy.js'

export default{
    props: ['obj'],
    components: {
        modal
    },
    data(){
        return{
            form: ''
        }
    },
    created(){
        this.form = this.obj
        console.log('f', this.form);
    },
    methods: {
        mutateTariffing(e){
          return mutateTariffingOutlets(e)
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    }
}
</script>