<template>
    <b-card>
        <b-row>
            <h3><b>{{ $t('client') }}</b></h3>
            <b-col lg="6">
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-hashtag"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">ID :</p>
                            <h6 class="text-truncate mb-0"># {{ form.accountId }}</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bxs-phone"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('phone_number') }}:</p>
                            <h6 class="text-truncate mb-0" >{{ form.personalData && form.personalData?.phone != '' ? form.personalData?.phone : form.userPhone ? form.userPhone : '-' }}</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-mail-check-line"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('mail') }} :</p>
                            <h6 class="text-truncate mb-0">{{ form.personalData && form.personalData.email != '' ? form.personalData.email : form.userEmail ? form.userPhone : '-' }}</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bxs-user"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('name') }}:</p>
                            <h6 class="text-truncate mb-0">{{ form.personalData && form.personalData?.user_name != '' ? form.personalData?.user_name : form.userName ? form.userName : '-'  }}</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bxs-user"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('company') }}:</p>
                            <h6 class="text-truncate mb-0">{{ jsonComp && jsonComp.companyName != '' ? jsonComp.companyName : '-'  }}</h6>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bx-comment-dots"></i></div>
                        </div>
                        <div class="flex-grow-1" >
                            <p class="mb-1">{{ $t('Position') }} :</p>
                            <h6 class="mb-0">хз чи потрібно</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-hashtag"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('country') }} :</p>
                            <h6 class="text-truncate mb-0">{{ form.personalData && form.personalData.country != '' ? getNameCountry(form.personalData.country) : '-' }}</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bxs-phone"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('city') }}:</p>
                            <h6 class="text-truncate mb-0"> Киев (Waiting API)</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-mail-check-line"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('Connection_type') }} :</p>
                            <h6 class="text-truncate mb-0">{{ form.foundOutSky != null  ? mutateTypeFound(form.foundOutSky) : '-' }}</h6>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { mutateTypeFound, getNameCountry } from '@/usabilityScripts/globalMutate'

export default({
    props: ['obj', 'jsonComp'],
    components: {},
    data() {
        return{
            form: ""
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        mutateTypeFound(e){
            return mutateTypeFound(e)
        },
        getNameCountry(e){
            return getNameCountry(e)
        }
    }
})
</script>
