<template>
    <!-- 
        Модальне вікно по expirenza, які точки та каси підключені
    -->

    <modal>
        <!-- Заголовок -->
        <template v-slot:title>Expirenza by mono</template>

        <template v-slot:body>

            <!-- 
                Компонент таблиці
                - :objParams - параметри для фільтрації таблиці 
                - :columns - конфігурація стовпців таблиці
                - :rows - масив з даними рядків таблиці
                - @search - подія, яка спрацьовує при пошуку у таблиці
            -->
            <tablecustom 
                :objParams="objParams"
                :columns="columns" 
                :rows="filteredItems" 
                @search="searchB" 
            />

        </template>
    </modal>
    
</template>

<script>
import modal from '@/components/modal/modal-lg'
import tablecustom from '@/components/globaltable/index';

export default {
    props: ['objCard'],
    components: {
        modal,
        tablecustom
    },
    data(){
        return{
            objParams: {
                search: ''
            },
            columns: [
                {
                    name: "ID",
                    text: "tradepointId",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t('outlet'),
                    text: "tradepointName",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t('CashDesk'),
                    text: "terminalTitle",
                    align: "left",
                    status: true,
                    copy: true
                },
            ],
            rows: []
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){

            // Перебір об'єкту в потрібний вигляд
            for (const key in this.objCard.tradepoints) {
                if (this.objCard.tradepoints.hasOwnProperty(key)) {
                    const item = this.objCard.tradepoints[key];
                    item.terminals.forEach(terminal => {
                        this.rows.push({
                            tradepointId: item.tradepointId,
                            tradepointName: item.tradepointName,
                            terminalId: terminal.terminalId,
                            terminalTitle: terminal.terminalTitle
                        });
                    });
                }
            }
        },
        searchB(e){
            // пошук по назві точок та терміналів

            this.objParams.search = e
        },
    },
    computed: {
        filteredItems: function() {
            // пошук
            return Object.values(this.rows)
                // По полю поиска
                .filter(item => {
                    return this.objParams.search == '' 
                        || item.tradepointName.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1
                        || item.terminalTitle.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1;
                })
        }
    }
}

</script>