<template>
    <files 
        :obj="this.obj" 
        :objFiles="this.obj.files" 
        @uploadFiles="uploadFiles"
        @downloadFile="downloadFile"
        @deleteFile="deleteFile"
        @getFile="getFile"
    />
    <modallg v-if="showImg" @close="showImg = false">
        <template v-slot:body>
            <template v-if="gFile.type == 'image/jpeg' || gFile.type == 'image/png' || gFile.type == 'image/heic'">
                <img  :src="`${gFile.src}`" style="">
            </template>
            <template v-if="gFile.type == 'video/quicktime' || gFile.type == 'video/mp4'">
                <video controls>
                    <source :src="`${gFile.src}`" type="video/mp4">
                </video>
            </template>
        </template>
        <template v-slot:buttons>
            <button class="gdownload gbtn" @click="this.downloadFile(gFile)" ><i class="ri-download-cloud-2-fill"></i></button>
            <button class="gdelete gbtn" @click="showRemDialog(gFile)" ><i class="ri-delete-bin-fill"></i></button>
            <button :class="`gprev gbtn ${ifPrevimg == true ? '' : 'disabled'}`" @click="prevOpen(gFile)" aria-label="Previous" data-taborder="2"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg></button>
            <button :class="`gnext gbtn ${ifNextimg == true ? '' : 'disabled'}`" @click="nextOpen(gFile)" aria-label="Next" data-taborder="1"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg></button>
        </template>
    </modallg>

    <modalremove v-if="dialogShow" @close="dialogShow = false" @remove="this.deleteFile(gFile)" />

</template>

<script>
import files from '@/components/files/index'
import { Tickets } from '@/API'
import modallg from '@/components/modal/img'
import modalremove from '@/components/modal/modalremove'
import { storeS } from '@/store'

let apiServe = new Tickets();

export default{
    props: ['obj'],
    components: {
        files,
        modallg,
        modalremove
    },
    data(){
        return{
            form: {
                files: []
            },
            gFile: {
                fileId: '',
                src: '',
                type: '',
                name: ''
            },
            showImg: false,
            dialogShow: false,
            ifPrevimg: false,
            ifNextimg: false,
            urlCaches: []
        }
    },
    created(){
        this.getdata();
    },
    methods:{
        getdata(){
            this.form = this.obj
        },
        getFiles(){
            apiServe.getFiles(this.obj.ticketId).then(result => {
                if(result.status == 'done'){
                    this.form.files = result.data
                }
            })
        },
        uploadFiles(obj, formData){
            apiServe.uploadFiles(obj.ticketId, formData).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('FileUploadedSuccessfully'));
                    storeS.uploadPercentage = 0;
                    this.getFiles();
                }
            })
        },
        downloadFile(item){
            apiServe.getFile(item.fileId, item.type)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.name);
                    document.body.appendChild(link);
                    link.click();
                    storeS.uploadPercentage = 0;
                })
                .catch((error) => console.log(error));
        },
        prevOpen(e){
            var current_id = e.fileId;
            var prev_id = ""; 

            for(var value in this.form.files){
                if(this.form.files[value].fileId == current_id){
                    prev_id = this.form.files[Number(value)-Number('1')];
                }
            }
            this.getFile(prev_id);
        },
        nextOpen(e){
            var current_id = e.fileId;
            var next_id = "";
            for(var value in this.form.files){
                if(this.form.files[value].fileId == current_id){
                    next_id = this.form.files[Number(value)+Number('1')];
                }
            }
            this.getFile(next_id);
        },
        showRemDialog(e){
            this.gFile.fileId = e.fileId
            this.dialogShow = true;
        },
        getFile(item){
            var id = item.fileId ? item.fileId : item;
            this.ifPrevimg = id == this.form.files[0].fileId ? false : true;
            this.ifNextimg = id == this.form.files[this.form.files.length-1].fileId ? false : true;
            // поки blob посилання інсує до перезапуску сторінки
            // if(localStorage.getItem('urlFiles')) {
                
            if(storeS.blob != undefined){
                var foundName = 'urlFrom' + this.form.ticketId + "fileId" + id;
                var urlCache = ''
                var statusCache = this.urlCaches.some(function(e){ 
                    urlCache = e.url
                    return e.name == foundName;
                });
                if(statusCache){
                    console.log(urlCache);
                    // якщо відео є в локалстор
                    this.gFile.fileId = id;
                    this.gFile.src = urlCache;
                    this.gFile.type = item.type;
                    this.gFile.name = item.name
                    this.showImg = true;
                }else{
                    // якщо відео немає в локалстор - додаємо
                    apiServe.getFile(id, item.type)
                        .then((response) => {
                            console.log(response)
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            console.log(url)
                            // localStorage.setItem('urlFrom' + this.form.ticketId + "fileId" + id, url);
                            let opUrl = {
                                name: 'urlFrom' + this.form.ticketId + "fileId" + id,
                                url: url,
                            }
                            this.urlCaches.push(opUrl)
                            this.saveUrlFile(this.urlCaches);
                            this.gFile.fileId = id;
                            this.gFile.src = url;
                            this.gFile.type = item.type;
                            this.gFile.name = item.name
                            this.showImg = true;
                            storeS.uploadPercentage = 0;
                        })
                }
            } else {
                apiServe.getFile(id, item.type)
                    .then((response) => {
                        var blob = new Blob([response.data])
                        const url = window.URL.createObjectURL(blob);
                        
                        // var base64String = '';
                        // var reader = new FileReader();
                        // reader.readAsDataURL(blob);
                        // reader.onloadend = function () {
                        //     base64String = reader.result;
                        //     console.log('Base64 String - ', base64String);

                        //     let openRequest = indexedDB.open("store", 1);
                        //     openRequest.onsuccess = function() {
                        //         let db = openRequest.result;
                        //         let transaction = db.transaction("data", "readwrite"); // (1)
                            
                        //         // получить хранилище объектов для работы с ним
                        //         let data = transaction.objectStore("data"); // (2)
                        //         let category_text = 'saveVideo';
                        //         let detail = {
                        //             dateTime: new Date(),
                        //             text: id,
                        //             category: category_text,
                        //             form: base64String
                        //         };

                        //         let request = data.add(detail); // (3)

                        //         request.onsuccess = function() { // (4)
                        //             sessionStorage.removeItem('actual')
                        //             console.log("Данные добавлены в хранилище", request.result);
                        //         };

                        //         request.onerror = function() {
                        //             console.log("Ошибка", request.error);
                        //         };
                        //     } //додавання в indexedDB
                        // }

                        let opUrl = {
                            name: 'urlFrom' + this.form.ticketId + "fileId" + id,
                            url: url
                        }
                        // this.urlCaches.push(opUrl)
                        // this.saveUrlFile(this.urlCaches);
                        this.urlCaches.push(opUrl)
                        this.saveUrlFile(this.urlCaches);
                        this.gFile.fileId = id;
                        this.gFile.src = url;
                        this.gFile.type = item.type;
                        this.gFile.name = item.name
                        this.showImg = true;
                        storeS.uploadPercentage = 0;
                    })
                // }
            }
        },
        saveUrlFile(e){
            // let parsed = JSON.stringify(e);
            // localStorage.setItem('urlFiles', parsed);
            storeS.blob = e;
        },
        deleteFile(e){
            apiServe.deleteFile(e.fileId).then(result => {
                if(result.status == 'done'){
                    this.showImg = false
                    this.dialogShow = false
                    this.$toast.success(this.$t('FileDeleted'));
                    this.getFiles();
                }
            })
        },
        showRemDialog(e){
            this.gFile.fileId = e.fileId
            this.dialogShow = true;
        },
    },
    mounted(){
        this.urlCaches = storeS.blob != undefined ? storeS.blob : [];
        // if(localStorage.getItem('urlFiles')) {
        //     this.urlCaches = this.urlCaches != null ? JSON.parse(localStorage.getItem('urlFiles')) : [] ;
        // }
    },
    computed: {
        user(){
            return storeS.userbase
        },
        uploadPercentage(){
            return storeS.uploadPercentage
        }
    }
}
</script>