<template>
    <modal :key="componentKey" :title="$t('project')" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" :stylebody="'var(--vz-body-bg)'" @close="this.$emit('close')">
        <template v-slot:head-bottom>
            <headmodal 
                :obj="this.form" 
                @getUpdates="getUpdates"
                @edit="edit"
                @copy="toCopy"
                @close="close" 
            />
        </template>
        <template v-slot:body>
            <!-- <topblock :obj="this.form" /> -->
            <b-row>
                <b-col
                lg="3">
                    <rightblock 
                        :obj="this.form" 
                        @openCard="openCard" 
                        @checkCompleted="checkCompleted" 
                        @getdata="this.$emit('getData'); 
                        this.$emit('close')"
                    />
                </b-col>
                <b-col
                lg="9">
                    <leftblock 
                        @openCard="openCard" 
                        :obj="this.form" 
                        :activeTab="activeTab"
                    />
                </b-col>
            </b-row>
        </template>

        <template v-slot:footer-bottom>

            <button 
                v-if="(perms[2000] == true || perms[353] == true) && this.form.status == 'deleted'"  
                @click="toRecover(this.form)"  
                type="button" 
                class="btn btn-danger r waves-effect waves-light"
            >
                {{ $t('Delete') }}
            </button> <!-- удалить проект -->

            <button 
                v-if="this.user.userid === this.form.teamlead && (perms[2000] == true ||perms[353] == true)"  
                @click="dialogShow = true"  
                type="button" 
                class="btn btn-danger r waves-effect waves-light"
            >
                {{ $t('Delete') }}
            </button> <!-- удалить проект -->
            
            <button 
                v-if="(this.user.userid === this.form.teamlead || this.form.workers.filter(item => item.workerId == this.user.userid).length > 0) && form.status == 'new' " 
                @click="toWork()"  
                type="button" 
                class="btn btn-success r waves-effect waves-light"
            >
                {{ $t('toWork') }}
            </button>  <!-- взять в работу проект -->
            
            <button 
                v-if="this.form.status == 'jobs' || form.status == 'wait_verification'" 
                @click="this.showModalDec = true"  
                type="button" class="btn btn-success r waves-effect waves-light"
            >
                {{ $t('Decision') }}
            </button>  <!-- решение к проекту -->

            <button 
                v-if="(this.form.status == 'completed'  
                        || this.form.status != 'closed') 
                        && (this.form.tasks != null || this.form.tasks.length != 0 ) 
                        && this.user.userid === this.form.teamlead"
                @click="showCrEditTask = true" 
                class="btn btn-warning"
            >
                {{ $t('addEdit') }}
            </button> <!-- редактирование к таскам проекта -->
            
            <button 
                v-if="(form.status == 'completed' || form.status == 'decided') && (form.teamlead == this.user.userid) " 
                @click="showModalClose = true" 
                class="btn btn-success"
            >
                <i class="ri-arrow-right-line align-bottom me-1"></i>{{ $t('Confirm') }}
            </button> <!-- подтвердить решение к проекту к проекту -->

        </template>
    </modal>

    <!-- решение к проекту -->
    <modalDec 
        v-if="showModalDec == true"
        @close="showModalDec = false"
        :id="this.form.projectId"
        :checkTask="checkTask"
    />
    <!-- решение к проекту -->

    <!-- закрыть проект -->
    <modalClose 
        v-if="showModalClose == true"
        @close="showModalClose = false"
        :id="this.form.projectId"
    />
    <!-- закрыть проект -->

    <!-- добавить правки по таскам -->
    <addEditTask 
        v-if="showCrEditTask" 
        @close="showCrEditTask = false" 
        :id="this.form.projectId"
        :obj="this.form" 
    />
    <!-- добавить правки по таскам -->

    <!-- редактирование проекта -->
    <editmodal 
        v-if="editmodal == true" 
        @close="editmodal = false" 
        :editform="this.form" 
    />
    <!-- редактирование проекта -->

    <modalDialog 
        v-if="showDialog == true"
        @close="showDialog = false"
    />
    
    <!-- редагування дедлайну -->
    <editDeadline
        v-if="this.modalEdit == true"
        @close = "this.modalEdit = false"
        :deadlines = "this.updateslist"
        :obj = "this.form"
    ></editDeadline>
    <!-- редагування дедлайну -->
    
    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove(this.form)" 
    />

</template>

<script>
import editDeadline from './actions/editDeadline'
import modal from '@/components/modal'
import dialogBox from '@/components/modal/dialog'
import editmodal from '../new.vue'
import headmodal from './headmodal'
import topblock from './topblock'
import leftblock from './formleft/leftblock'
import rightblock from './formright/rightblock'
import addEditTask from './actions/addEditTask'
import modalClose from './actions/closeProject'
import modalDec from './actions/decision'
import modalDialog from './actions/dialog'
import modalremove from '@/components/modal/modalremove'
import { storeS } from '@/store';
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js'
import { Projects, Updates } from '@/API.js';

let apiServe = new Projects();
let apiUpdates = new Updates();

export default {
    props: ["obj", 'ifPrevopen', 'ifNextopen', 'activeTab'],
    components: { 
        editDeadline,
        modal, 
        dialogBox, 
        headmodal,
        topblock, 
        leftblock, 
        rightblock,
        addEditTask,
        editmodal,
        modalClose,
        modalDec,
        modalDialog,
        modalremove
    },
    data() {
        return {
            commenttt: {
                comment: ''
            },
            showDialog: false,
            showModalDec: false,
            userid: storeS.userbase.userid,
            editmodal: false,
            showCrEditTask: false,
            showModalClose: false,
            modalEdit: false,
            form: "",
            updateslist: {},
            checkTask: false,
            componentKey: '',
            dialogShow: false
        }
    },
    created(){
        this.getData();
        storeS.checkModal.type = 'project';
        storeS.checkModal.id = this.form.projectId;
    },
    methods: { 
        toRecover(e) {
            apiServe.recoverProject(e.projectId).then(result=> {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('Restored'))
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        pressButton(e) {
        },
        openCard(e) {
            this.$emit('openCard', e, this.form)
            this.$emit('close')
        },
        getData(){
            this.form = this.obj
        },
        close(){
            storeS.checkModal.type = '';
            storeS.checkModal.id = '';
            this.$emit('close')
        },
        prevOpen(){
            this.$emit('close');
            this.$emit('prevOpen', this.form.projectId)
        },
        nextOpen(){
            this.$emit('close');
            this.$emit('nextOpen', this.form.projectId)
        },
        updateCard(){
            // componentKey
            apiServe.getCardProjects(this.form.projectId).then(result =>{
                if(result.status === 'done'){
                    this.form = result.data
                    this.componentKey += 1
                }
            })
        },
        getUpdates(){
            apiUpdates.getAllUpdates().then(result => {
                if(result.status === 'done'){
                    this.updateslist = result.data.items
                    this.modalEdit = true
                }
            })
        },
        checkCompleted(){
            this.checkTask = true;
            this.showModalDec = true;
        },
        toWork() {
            apiServe.toWorkProject(this.form.projectId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success('TakentoWork');
                    this.updateCard();
                    // this.$emit('close')
                } //взять в работу
            })
        },
        toClose() {
            apiServe.toCloseProject(this.form.projectId, this.commenttt.comment).then(result => {
                if(result.status === 'done') {
                    this.$toast.success('projectClose')
                    this.$emit('close') //закрыть проект
                }
            })
        },
        toSolve() {
            apiServe.toSolveProject(this.form.projectId, this.commenttt.comment).then(result => {
                if(result.status === 'done') {
                    this.$toast.success('projectSolved')
                    this.$emit('close')
                } //кинуть решение ибо заебались решать уже проект
            })
        },
        edit(e){
            this.$emit('edit', e)
            this.$emit('close')
        },
        toCopy(e){
            apiServe.addProject(e).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Copy'));
                    this.$emit('close')
                    this.eventBus.emit('saveProject', true)
                }
            })
        },
        toConfirm(){
            this.form.confirmation_user = this.user.name;
            this.form.confirmation_date_time = this.currentDate();
            this.form.status = "statusCompleted";
            this.showModalClose = false;
            apiServe.editProject(this.form.id, this.form).then(res =>{
                if(res){
                    this.$toast.success(this.$t('сreatedT'));
                    apiServe.deleteProject(this.form.projectId).then(del => {
                        if(del){
                            apiServe.addProjArchive(this.form).then(result => {
                                if(result){
                                    this.$toast.success(this.$t('gonetoArchive'));
                                    this.eventBus.emit('saveProject', true)
                                    this.close()
                                }
                            })
                        }
                    })
                }
            })
        },
        remove(){
            apiServe.deleteProject(this.form.projectId).then(res => {
                if(res){
                    this.$toast.success(this.$t('Removed'));
                    this.eventBus.emit('saveProject', true)
                    this.close()
                }
            })
        },
    },
    mounted(){
        this.eventBus.on('UpdateCard', (status) => {
            if(status == true) {
                this.updateCard();
            }
        }),
        this.eventBus.on('UpdateCardProject', (status) => {
            if(status == true) {
                this.updateCard();
            }
        }),
        this.eventBus.on('closeProject', (status) => {
            if(status == true){
                this.close()
            }
        })
    },
    computed: {
        user(){
            return storeS.userbase
        },
        perms(){
            return storeS.perms
        }
    }
}
</script>