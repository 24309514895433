<template>
    <b-card>
        <b-card-title> 
            <!-- <div class="card-header">
                <h5 class="card-title mb-0">{{$t('teams')}}</h5>
            </div> -->
            <div class="card-header align-items-center d-flex border-bottom-dashed" >
                <h4 class="card-title mb-0 flex-grow-1">{{$t('teams')}}</h4>
                <div class="flex-shrink-0" v-if="this.form.teamlead == this.user.userid && form.status != 'new'">
                    <button type="button" class="btn btn-soft-danger btn-sm" @click="showModal++"><i class="ri-share-line me-1 align-bottom"></i>{{ $t('Add') }}</button>
                </div>
            </div>
        </b-card-title> 
        <div class="card-body">
            <div class="col-lg-12 border-bottom mb-3">
                <label for="choices-priority-input" class="form-label">{{ $t('teamLead')}}</label>
                <p class="copy" @click="open(form.teamlead)">
                    {{ this.nameWorker(form.teamlead) }}
                </p>
            </div>
            <div>
                <label class="form-label fw-semibold">{{ $t('Employees') }}</label>
                <ul class="list-group list-group-flush border-dashed" v-if="this.form.workers.length > 0">
                    <li class="list-group-item ps-0" v-for="item in this.form.workers" :key="item">
                        <div class="d-flex align-items-start" >
                            <div class="flex-grow-1" >
                                <h5 class="fs-13 mb-0 copy" @click="open(item.workerId)">{{item.workerName}}</h5>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        
    </b-card>

    <modal
        v-for="forms in showModal"
        @close="showModal--"
        @invite="invite"
        @open="open"
        :projectWorkers="this.form.workers"
    />

    <modalWorker 
        v-for="form in showModalWorker"
        @close="showModalWorker--"
        :objcard="objcard"
    />

</template>

<script>
import modal from '@/components/modal/modal-members'
import modalWorker from '@/views/users/view/index'
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { storeS } from '@/store'
import { Projects, Users } from '@/API'

let apiServe = new Projects();
let apiUsers = new Users();

export default{
    props: ['obj'],
    components: {
        modal,
        modalWorker
    },
    data(){
        return{
            form:'',
            objcard: '',
            showModal: 0,
            showModalWorker: 0
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        nameWorker(item){
            return nameWorker(item)
        },
        invite(workersList){
            apiServe.editWorkersInProject(this.form.projectId, workersList).then(result => {
                if(result.status == 'done'){
                    this.eventBus.emit('UpdateCard', true)
                }
            })
        },
        open(id){
            apiUsers.getUser(id).then(result => {
                if(result.status == 'done') {
                    this.objcard = result.data
                    this.$emit('openCard', this.objcard)
                }
            })
        }
    },
    computed: {
        workers() {
            return storeS.workers
        },
        user(){
            return storeS.userbase
        }
    }
}
</script>