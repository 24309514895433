<template>
    <!-- Блок wrapper -->
    <div class="wrapper">
      <!-- Кнопка з часом -->
      <button class="resButton" @click="showTime = !showTime" >
        <!-- Показати поточний час -->
        {{ this.currentIdxHour }}:{{ this.currentIdxMin }}
      </button>
      
      <!-- Карточка з вибором часу (показується, якщо showTime == true) -->
      <b-card v-if="showTime == true" style="margin-top: 5px;">
        <!-- Блок з обмеженням висоти -->
        <div style="max-height: 250px;">
          <!-- Таблиця з вибором часу -->
          <table style="text-align:center">
            <!-- Заголовок таблиці -->
            <thead style="border: 1px solid rgb(202, 202, 202); border-top-left-radius: 20px; border-top-right-radius: 20px;">
              <!-- Заголовок для годин -->
              <th colspan="5">{{ this.$t('hour') }}</th>
              <!-- Заголовок для хвилин -->
              <th 
                colspan="6" 
                style="border-left: 3px solid black">
                {{ this.$t('minutes') }}
              </th>
            </thead>
            <!-- Тіло таблиці -->
            <tbody>
              <!-- Цикл по масиву годин -->
              <tr v-for="symbols, n in this.hours">
                <!-- Цикл по масиву годин для кожної години -->
                <td 
                  v-for="items, i in this.hours[n]"
                  style="border: 1px solid rgb(202, 202, 202); padding: 5px; font-size: 14px; cursor: pointer;" 
                  :style="items == this.currentIdxHour ? 'background-color: black; color: white' : ''" 
                  :key="i" @click="chooseHour(items)">
                  <!-- Показати годину -->
                  {{ items }}
                </td>
                <!-- Цикл по масиву хвилин для кожної години -->
                <td 
                  v-for="item, k in this.minutes[n]" 
                  style="border: 1px solid rgb(202, 202, 202); padding: 5px; font-size: 14px; cursor: pointer" 
                  :style="(k !== 0 ? '' : 'border-left: 3px solid black')" 
                  :key="k" @click="chooseMinute(item)">
                  <!-- Показати хвилину -->
                  {{ item }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </div>
  </template>

<script>
export default {
  // Властивості, які передаються з батьківського компонента
  props: ['timeProps'],

  // Дані компонента
  data() {
    return {
      // Поточна година
      currentIdxHour: '',
      // Поточна хвилина
      currentIdxMin: '',
      // Показати карточку з вибором часу
      showTime: false,
      // Масив годин
      hours: [
        ['0','1','2','3', '4'], 
        ['4','5','6','7','8'], 
        ['9','10','11','12','13'], 
        ['14','15','16','17','18'], 
        ['19','20','21','22','23']
      ],
      // Масив хвилин
      minutes: [],
    }
  },

  // Метод, який викликається після створення компонента
  created() {
    // Якщо властивість timeProps не передана, то встановлюємо поточний час
    if(!this.timeProps) {
      this.currentIdxHour = String(new Date()).split(' ')[4].split(':')[0] 
      this.currentIdxMin = String(new Date()).split(' ')[4].split(':')[1] 
    } else {
      // Якщо властивість timeProps передана, то встановлюємо час з неї
      this.currentIdxHour = this.timeProps.split(':')[0]
      this.currentIdxMin = this.timeProps.split(':')[1]
      // Додавання нуля до хвилини, якщо вона менше 10
      if(Number(this.currentIdxMin) < 10 && Number(this.currentIdxMin)!= 0) {
        this.currentIdxMin = '0' + this.currentIdxMin
      }
    }

    // Створення масиву хвилин
    let min = []
    for(let i = 0; i<60; i++) {
      if(i % 5 == 0) {
        if(i == 5 || i == 0) {
          min.push('0' + String(i))
        } else {
          min.push(String(i))
        }
      }
    }
    
    // Розподіл хвилин по масивах
    this.minutes = [[], [], [], []]
    min.forEach((el, index) => {
      switch (Math.trunc(index / 3)){
        case 0:
          this.minutes[0].push(el)
        break;
        case 1:
          this.minutes[1].push(el)
        break;
        case 2:
          this.minutes[2].push(el)
        break;
        case 3:
          this.minutes[3].push(el)
        break;

      }
    })
  },

  // Методи компонента
  methods: {
    // Метод вибору години
    chooseHour(e) {
      this.currentIdxHour = e
      // Надсилання події setHour до батьківського компонента
      this.$emit('setHour', this.currentIdxHour)
    },
    // Метод вибору хвилини
    chooseMinute(e) {
      this.currentIdxMin = e
      // Надсилання події setMinute до батьківського компонента
      this.$emit('setMinute', this.currentIdxMin)
      // Сховати карточку з вибором часу
      this.showTime = false
    }
  }
}
</script>

<style scoped>
    .chooseData{
        border: 1px solid black;
        padding: 10px;
        border-radius: 10px;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }
    .resButton{
        width: 100%;
        height: 35px;
        border: none;
        background-color: white;
        box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
    }
    .card {
        position: absolute;
        z-index: 3;
    }
    .card-body {
        width: 110%;
    }

    [data-layout-mode="dark"] .resButton {
        background-color: var(--vz-input-bg)!important;
        color: var(--vz-body-color);
    }

</style>