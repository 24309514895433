<template>
    <dialogBox >
        <template v-slot:title>{{ $t('ChangeEnvProblem') }}</template>
        <template v-slot:body>
            <div class="mt-1 text-center">
                <h3 class="mb-3"><b>{{ $t('selectDifferentProblem') }}</b></h3>
                <Multiselect 
                    v-model="form.problemEnvironment"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.problemEnvironment"
                    :placeholder="$t('device_printer')+', '+$t('device_rro')+', '+$t('dir_pos')"
                />
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
                <button class="btn btn-success" @click="change">{{ $t('Edit') }}</button>
            </div>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Tickets } from '@/API'

let apiServe = new Tickets()

export default{
    props: ['objCard'],
    components: {
        dialogBox,
        Multiselect
    },
    data(){
        return{
            form: {
                problemEnvironment: ''
            },
            problemEnvironment: [
                {
                    "label": this.$t('App'),
                    "value": "App"
                },
                {
                    "label": 'Inside',
                    "value": "inside"
                },
                {
                    "label": this.$t('NewAdmin_panel'),
                    "value": "newDashboard"
                },
                {
                    "label": this.$t('admin_panel'),
                    "value": "admin_panel"
                },
                {
                    "label": this.$t('dir_posnew'),
                    "value": "posnew"
                },
                {
                    "label": this.$t('dir_pos'),
                    "value": "pos_terminal"
                },
                {
                    "label": this.$t('admin_allappsSkymarket'),
                    "value": "admin_allappsSkymarket"
                },
                {
                    "label": this.$t('admin_allappsSellup'),
                    "value": "admin_allappsSellup"
                },
                {
                    "label": this.$t('admin_allappsHscreen'),
                    "value": "admin_allappsHscreen"
                },
                {
                    "label": this.$t('admin_allappsMono'),
                    "value": "admin_allappsMono"
                },
                {
                    "label": this.$t('admin_allappsPrivat'),
                    "value": "admin_allappsPrivat"
                },
                {
                    "label": this.$t('admin_shake_to_pay'),
                    "value": "admin_shake_to_pay"
                },
                {
                    "label": this.$t('admin_allappsBookBeauty'),
                    "value": "admin_allappsBookBeauty"
                },
                {
                    "label": this.$t('admin_sendcheck'),
                    "value": "admin_sendcheck"
                },
                
                
                // {
                //     "label": this.$t('admin_allProducts'),
                //     "value": "admin_allProducts"
                // },
                // {
                //     "label": this.$t('admin_warehouseProducts'),
                //     "value": "admin_warehouseProducts"
                // },
                // {
                //     "label": this.$t('admin_services'),
                //     "value": "admin_services"
                // },
                // {
                //     "label": this.$t('admin_Onsale'),
                //     "value": "admin_Onsale"
                // },
                // {
                //     "label": this.$t('admin_remains'),
                //     "value": "admin_remains"
                // },
                // {
                //     "label": this.$t('admin_reportsMain'),
                //     "value": "admin_reportsMain"
                // },
                // {
                //     "label": this.$t('admin_reportsSale'),
                //     "value": "admin_reportsSale"
                // },
                // {
                //     "label": this.$t('admin_reportsComings'),
                //     "value": "admin_reportsComings"
                // },
                // {
                //     "label": this.$t('admin_reportsReturns'),
                //     "value": "admin_reportsReturns"
                // },
                // {
                //     "label": this.$t('admin_reportsMove'),
                //     "value": "admin_reportsMove"
                // },
                // {
                //     "label": this.$t('admin_reportsManufacture'),
                //     "value": "admin_reportsManufacture"
                // },
                // {
                //     "label": this.$t('admin_reportsOpenshifts'),
                //     "value": "admin_reportsOpenshifts"
                // },
                // {
                //     "label": this.$t('admin_reportsBonusreports'),
                //     "value": "admin_reportsBonusreports"
                // },
                // {
                //     "label": this.$t('admin_reportsGoodsconsumption'),
                //     "value": "admin_reportsGoodsconsumption"
                // },
                // {
                //     "label": this.$t('admin_reportsTopsales'),
                //     "value": "admin_reportsTopsales"
                // },
                // {
                //     "label": this.$t('admin_reportsABCproducts'),
                //     "value": "admin_reportsABCproducts"
                // },
                // {
                //     "label": this.$t('admin_reportsABCcategories'),
                //     "value": "admin_reportsABCcategories"
                // },
                // {
                //     "label": this.$t('admin_reportsTopcomings'),
                //     "value": "admin_reportsTopcomings"
                // },
                // {
                //     "label": this.$t('admin_reportsTopclients'),
                //     "value": "admin_reportsTopclients"
                // },
                // {
                //     "label": this.$t('admin_reportsDays'),
                //     "value": "admin_reportsDays"
                // },
                // {
                //     "label": this.$t('admin_reportsDecommissionedgoods'),
                //     "value": "admin_reportsDecommissionedgoods"
                // },
                // {
                //     "label": this.$t('admin_reportsGoods'),
                //     "value": "admin_reportsGoods"
                // },
                // {
                //     "label": this.$t('admin_reportsTopsales'),
                //     "value": "admin_reportsTopsales"
                // },
                // {
                //     "label": this.$t('admin_reportsABCproducts'),
                //     "value": "admin_reportsABCproducts"
                // },
                // {
                //     "label": this.$t('admin_reportsABCcategories'),
                //     "value": "admin_reportsABCcategories"
                // },
                // {
                //     "label": this.$t('admin_reportsTopcomings'),
                //     "value": "admin_reportsTopcomings"
                // },
                // {
                //     "label": this.$t('admin_reportsTopclients'),
                //     "value": "admin_reportsTopclients"
                // },
                // {
                //     "label": this.$t('admin_reportsDays'),
                //     "value": "admin_reportsDays"
                // },
                // {
                //     "label": this.$t('admin_reportsDecommissionedgoods'),
                //     "value": "admin_reportsDecommissionedgoods"
                // },
                // {
                //     "label": this.$t('admin_reportsGoods'),
                //     "value": "admin_reportsGoods"
                // },
                // {
                //     "label": this.$t('admin_reportsGoodscategory'),
                //     "value": "admin_reportsGoodscategory"
                // },
                // {
                //     "label": this.$t('admin_reportsSalesdynamic'),
                //     "value": "admin_reportsSalesdynamic"
                // },
                // {
                //     "label": this.$t('admin_reportsRevenuereport'),
                //     "value": "admin_reportsRevenuereport"
                // },
                // {
                //     "label": this.$t('admin_reportsWorkshop'),
                //     "value": "admin_reportsWorkshop"
                // },
                // {
                //     "label": this.$t('admin_reportsEmployee'),
                //     "value": "admin_reportsEmployee"
                // },
                // {
                //     "label": this.$t('admin_reportsInventory'),
                //     "value": "admin_reportsInventory"
                // },
                // {
                //     "label": this.$t('admin_reportsDanger'),
                //     "value": "admin_reportsDanger"
                // },
                // {
                //     "label": this.$t('admin_reportsRecommendpurchase'),
                //     "value": "admin_reportsRecommendpurchase"
                // },
                // {
                //     "label": this.$t('admin_reportsTaxes'),
                //     "value": "admin_reportsTaxes"
                // },
                // {
                //     "label": this.$t('admin_reportsShifts'),
                //     "value": "admin_reportsShifts"
                // },
                // {
                //     "label": this.$t('admin_reportsWalletsstatus'),
                //     "value": "admin_reportsWalletsstatus"
                // },
                // {
                //     "label": this.$t('admin_reportsFin'),
                //     "value": "admin_reportsFin"
                // },
                // {
                //     "label": this.$t('admin_reportsCashboxallmotions'),
                //     "value": "admin_reportsCashboxallmotions"
                // },
                // {
                //     "label": this.$t('admin_reportsCashboxinkmotions'),
                //     "value": "admin_reportsCashboxinkmotions"
                // },
                // {
                //     "label": this.$t('admin_reportsCashboxoutmotions'),
                //     "value": "admin_reportsCashboxoutmotions"
                // },
                // {
                //     "label": this.$t('admin_reportsCashboxsalemotions'),
                //     "value": "admin_reportsCashboxsalemotions"
                // },
                // {
                //     "label": this.$t('admin_reportsCashboxinmotions'),
                //     "value": "admin_reportsCashboxinmotions"
                // },
                // {
                //     "label": this.$t('admin_reportsWalletallmotions'),
                //     "value": "admin_reportsWalletallmotions"
                // },
                // {
                //     "label": this.$t('admin_reportsWalletinmotions'),
                //     "value": "admin_reportsWalletinmotions"
                // },
                // {
                //     "label": this.$t('admin_reportsWalletoutmotions'),
                //     "value": "admin_reportsWalletoutmotions"
                // },
                // {
                //     "label": this.$t('admin_marketingClients'),
                //     "value": "admin_marketingClients"
                // },
                // {
                //     "label": this.$t('admin_marketingOffers'),
                //     "value": "admin_marketingOffers"
                // },
                // {
                //     "label": this.$t('admin_marketingStock'),
                //     "value": "admin_marketingDiscount"
                // },
                // {
                //     "label": this.$t('admin_marketingBonus'),
                //     "value": "admin_marketingBonus"
                // },
                // {
                //     "label": this.$t('admin_settingsglobal'),
                //     "value": "admin_settingsglobal"
                // },
                // {
                //     "label": this.$t('admin_settingstradepoints'),
                //     "value": "admin_settingstradepoints"
                // },
                // {
                //     "label": this.$t('admin_settingscategory'),
                //     "value": "admin_settingscategory"
                // },
                // {
                //     "label": this.$t('admin_settingsexpenses'),
                //     "value": "admin_settingsexpenses"
                // },
                // {
                //     "label": this.$t('admin_settingstax'),
                //     "value": "admin_settingstax"
                // },
                // {
                //     "label": this.$t('admin_settingsworkshop'),
                //     "value": "admin_settingsworkshop"
                // },
                // {
                //     "label": this.$t('admin_settingsnotices'),
                //     "value": "admin_settingsnotices"
                // },
                // {
                //     "label": this.$t('admin_settingshalls'),
                //     "value": "admin_settingshalls"
                // },
                // {
                //     "label": this.$t('admin_settingstables'),
                //     "value": "admin_settingstables"
                // },
                // {
                //     "label": this.$t('admin_settingsdevices'),
                //     "value": "admin_settingsdevices"
                // },
                // {
                //     "label": this.$t('admin_settingsusers'),
                //     "value": "admin_settingsusers"
                // },
                // {
                //     "label": this.$t('admin_settingsprovider'),
                //     "value": "admin_settingsprovider"
                // },
                // {
                //     "label": this.$t('admin_settingswallets'),
                //     "value": "admin_settingswallets"
                // },
                // {
                //     "label": this.$t('admin_settingsmybalance'),
                //     "value": "admin_settingsmybalance"
                // },
                // {
                //     "label": this.$t('admin_settingspartnersprogram'),
                //     "value": "admin_settingspartnersprogram"
                // },
                // {
                //     "label": this.$t('admin_help'),
                //     "value": "admin_help"
                // }
            ],
        }
    },
    created(){
        this.form.problemEnvironment = this.objCard.problemEnvironment
    },
    methods: {
        change(){
            apiServe.changeProblemEnvironment(this.objCard.ticketId, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('ProblemChangSucc'));
                    this.$emit('close')
                    var data = {
                        status: true,
                        data: result.data
                    }
                    this.eventBus.emit('getCardTicket', data);
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        }
    }
}
</script>