<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('problem_area') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <input 
                v-model="search"
                class="form-control mb-2"
            />
            <div style="max-height: 300px;overflow: auto;">
                <div class="col-lg-12" style="margin: 12px auto;">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" :checked="this.problems.length == 0" type="radio" @change="change()" name="radioproblem" id="allproblem" v-model="problem" :value="''">
                        <label class="form-check-label" for="allproblem">{{ $t('all') }}</label>
                    </div>
                </div>
                <template v-for="(value, index) in filteredItems" :key="index" >
                    <div class="col-lg-12" style="margin: 12px auto;">
                        <div class="form-check mb-2" >
                            <input class="form-check-input" type="checkbox" @change="changes()" name="radioproblem" :id="'problem'+index" v-model="problems" :value="value.value">
                            <label class="form-check-label" :for="'problem'+index">{{ value.label }}</label>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store';

export default {
    components: { Multiselect },
    data(){
        return {
            search: '',
            problems: [],
            problem: "",
            problemEnvironment: [
                {
                    "label": 'Inside',
                    "value": "inside"
                },
                {
                    "label": 'SkyService',
                    "value": "skyservice"
                },
                {
                    "label": this.$t('NewAdmin_panel'),
                    "value": "newDashboard"
                },
                {
                    "label": this.$t('admin_allappsSkymarket'),
                    "value": "admin_allappsSkymarket"
                },
                {
                    "label": this.$t('admin_allappsSellup'),
                    "value": "admin_allappsSellup"
                },
                {
                    "label": this.$t('admin_allappsHscreen'),
                    "value": "admin_allappsHscreen"
                },
                {
                    "label": this.$t('admin_allappsMono'),
                    "value": "admin_allappsMono"
                },
                {
                    "label": this.$t('admin_allappsPrivat'),
                    "value": "admin_allappsPrivat"
                },
                {
                    "label": this.$t('admin_shake_to_pay'),
                    "value": "admin_shake_to_pay"
                },
                {
                    "label": this.$t('admin_allappsBookBeauty'),
                    "value": "admin_allappsBookBeauty"
                },
                {
                    "label": this.$t('admin_sendcheck'),
                    "value": "admin_sendcheck"
                },
                {
                    "label": this.$t('admin_panel'),
                    "value": "admin_panel"
                },
                {
                    "label": this.$t('dir_pos'),
                    "value": "pos_terminal"
                },
                {
                    "label": this.$t('dir_posnew'),
                    "value": "posnew"
                },
                {
                    "label": this.$t('admin_allProducts'),
                    "value": "admin_allProducts"
                },
                {
                    "label": this.$t('admin_warehouseProducts'),
                    "value": "admin_warehouseProducts"
                },
                {
                    "label": this.$t('admin_services'),
                    "value": "admin_services"
                },
                {
                    "label": this.$t('admin_Onsale'),
                    "value": "admin_Onsale"
                },
                {
                    "label": this.$t('admin_remains'),
                    "value": "admin_remains"
                },
                {
                    "label": this.$t('admin_reportsMain'),
                    "value": "admin_reportsMain"
                },
                {
                    "label": this.$t('admin_reportsSale'),
                    "value": "admin_reportsSale"
                },
                {
                    "label": this.$t('admin_reportsComings'),
                    "value": "admin_reportsComings"
                },
                {
                    "label": this.$t('admin_reportsReturns'),
                    "value": "admin_reportsReturns"
                },
                {
                    "label": this.$t('admin_reportsMove'),
                    "value": "admin_reportsMove"
                },
                {
                    "label": this.$t('admin_reportsManufacture'),
                    "value": "admin_reportsManufacture"
                },
                {
                    "label": this.$t('admin_reportsOpenshifts'),
                    "value": "admin_reportsOpenshifts"
                },
                {
                    "label": this.$t('admin_reportsBonusreports'),
                    "value": "admin_reportsBonusreports"
                },
                {
                    "label": this.$t('admin_reportsGoodsconsumption'),
                    "value": "admin_reportsGoodsconsumption"
                },
                {
                    "label": this.$t('admin_reportsTopsales'),
                    "value": "admin_reportsTopsales"
                },
                {
                    "label": this.$t('admin_reportsABCproducts'),
                    "value": "admin_reportsABCproducts"
                },
                {
                    "label": this.$t('admin_reportsABCcategories'),
                    "value": "admin_reportsABCcategories"
                },
                {
                    "label": this.$t('admin_reportsTopcomings'),
                    "value": "admin_reportsTopcomings"
                },
                {
                    "label": this.$t('admin_reportsTopclients'),
                    "value": "admin_reportsTopclients"
                },
                {
                    "label": this.$t('admin_reportsDays'),
                    "value": "admin_reportsDays"
                },
                {
                    "label": this.$t('admin_reportsDecommissionedgoods'),
                    "value": "admin_reportsDecommissionedgoods"
                },
                {
                    "label": this.$t('admin_reportsGoods'),
                    "value": "admin_reportsGoods"
                },
                {
                    "label": this.$t('admin_reportsTopsales'),
                    "value": "admin_reportsTopsales"
                },
                {
                    "label": this.$t('admin_reportsABCproducts'),
                    "value": "admin_reportsABCproducts"
                },
                {
                    "label": this.$t('admin_reportsABCcategories'),
                    "value": "admin_reportsABCcategories"
                },
                {
                    "label": this.$t('admin_reportsTopcomings'),
                    "value": "admin_reportsTopcomings"
                },
                {
                    "label": this.$t('admin_reportsTopclients'),
                    "value": "admin_reportsTopclients"
                },
                {
                    "label": this.$t('admin_reportsDays'),
                    "value": "admin_reportsDays"
                },
                {
                    "label": this.$t('admin_reportsDecommissionedgoods'),
                    "value": "admin_reportsDecommissionedgoods"
                },
                {
                    "label": this.$t('admin_reportsGoods'),
                    "value": "admin_reportsGoods"
                },
                {
                    "label": this.$t('admin_reportsGoodscategory'),
                    "value": "admin_reportsGoodscategory"
                },
                {
                    "label": this.$t('admin_reportsSalesdynamic'),
                    "value": "admin_reportsSalesdynamic"
                },
                {
                    "label": this.$t('admin_reportsRevenuereport'),
                    "value": "admin_reportsRevenuereport"
                },
                {
                    "label": this.$t('admin_reportsWorkshop'),
                    "value": "admin_reportsWorkshop"
                },
                {
                    "label": this.$t('admin_reportsEmployee'),
                    "value": "admin_reportsEmployee"
                },
                {
                    "label": this.$t('admin_reportsInventory'),
                    "value": "admin_reportsInventory"
                },
                {
                    "label": this.$t('admin_reportsDanger'),
                    "value": "admin_reportsDanger"
                },
                {
                    "label": this.$t('admin_reportsRecommendpurchase'),
                    "value": "admin_reportsRecommendpurchase"
                },
                {
                    "label": this.$t('admin_reportsTaxes'),
                    "value": "admin_reportsTaxes"
                },
                {
                    "label": this.$t('admin_reportsShifts'),
                    "value": "admin_reportsShifts"
                },
                {
                    "label": this.$t('admin_reportsWalletsstatus'),
                    "value": "admin_reportsWalletsstatus"
                },
                {
                    "label": this.$t('admin_reportsFin'),
                    "value": "admin_reportsFin"
                },
                {
                    "label": this.$t('admin_reportsCashboxallmotions'),
                    "value": "admin_reportsCashboxallmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxinkmotions'),
                    "value": "admin_reportsCashboxinkmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxoutmotions'),
                    "value": "admin_reportsCashboxoutmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxsalemotions'),
                    "value": "admin_reportsCashboxsalemotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxinmotions'),
                    "value": "admin_reportsCashboxinmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletallmotions'),
                    "value": "admin_reportsWalletallmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletinmotions'),
                    "value": "admin_reportsWalletinmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletoutmotions'),
                    "value": "admin_reportsWalletoutmotions"
                },
                {
                    "label": this.$t('admin_marketingClients'),
                    "value": "admin_marketingClients"
                },
                {
                    "label": this.$t('admin_marketingOffers'),
                    "value": "admin_marketingOffers"
                },
                {
                    "label": this.$t('admin_marketingStock'),
                    "value": "admin_marketingDiscount"
                },
                {
                    "label": this.$t('admin_marketingBonus'),
                    "value": "admin_marketingBonus"
                },
                {
                    "label": this.$t('admin_settingsglobal'),
                    "value": "admin_settingsglobal"
                },
                {
                    "label": this.$t('admin_settingstradepoints'),
                    "value": "admin_settingstradepoints"
                },
                {
                    "label": this.$t('admin_settingscategory'),
                    "value": "admin_settingscategory"
                },
                {
                    "label": this.$t('admin_settingsexpenses'),
                    "value": "admin_settingsexpenses"
                },
                {
                    "label": this.$t('admin_settingstax'),
                    "value": "admin_settingstax"
                },
                {
                    "label": this.$t('admin_settingsworkshop'),
                    "value": "admin_settingsworkshop"
                },
                {
                    "label": this.$t('admin_settingsnotices'),
                    "value": "admin_settingsnotices"
                },
                {
                    "label": this.$t('admin_settingshalls'),
                    "value": "admin_settingshalls"
                },
                {
                    "label": this.$t('admin_settingstables'),
                    "value": "admin_settingstables"
                },
                {
                    "label": this.$t('admin_settingsdevices'),
                    "value": "admin_settingsdevices"
                },
                {
                    "label": this.$t('admin_settingsusers'),
                    "value": "admin_settingsusers"
                },
                {
                    "label": this.$t('admin_settingsprovider'),
                    "value": "admin_settingsprovider"
                },
                {
                    "label": this.$t('admin_settingswallets'),
                    "value": "admin_settingswallets"
                },
                {
                    "label": this.$t('admin_settingsmybalance'),
                    "value": "admin_settingsmybalance"
                },
                {
                    "label": this.$t('admin_settingspartnersprogram'),
                    "value": "admin_settingspartnersprogram"
                },
                {
                    "label": this.$t('admin_help'),
                    "value": "admin_help"
                }
            ],
        }
    },
    created() {
    },
    methods: {
        change(){            
            console.log(this.problems)
            this.problems = []
            this.$emit('change', 'problemEnvironment', this.problem)
        },
        changes() {
            this.$emit('change', 'problemEnvironments', this.problems)
        }
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.problemEnvironment)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.label.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>