<template>
    <modal @close="$emit('close')">
        <template v-slot:title>Зберегти додаткові дані</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('name') }}</h4>
                        <input type="text" class="form-control" v-model="form.additionalName">
                    </div>
                </b-col>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('mail') }}</h4>
                        <Multiselect
                            v-model="form.additionalEmail"
                            mode="tags"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="true"
                            :options="form.additionalEmail"
                        />
                    </div>
                </b-col>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Kind_of_activity') }}</h4>
                        <Multiselect
                            v-model="form.activity"
                            mode="tags"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="listActivity"
                        />
                    </div>
                </b-col>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Business_type') }}</h4>
                        <Multiselect
                            v-model="form.typeOfBusiness"
                            mode="tags"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="listBusiness"
                        />
                    </div>
                </b-col>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('HowFindaboutSkyService') }}</h4>
                        <Multiselect
                            v-model="form.foundOutSky"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="listTypeFound"
                        />
                    </div>
                </b-col>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('GeneralManager') }}</h4>
                        <Multiselect
                            v-model="form.personToCommunicate"
                            mode="tags"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="true"
                            :options="form.personToCommunicate"
                        />
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom >
            <div>
                <button type="button" class="btn btn-success" v-on:click="save">{{ $t('save') }}</button>
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { SuperAdmin } from '@/API'

let apiServe = new SuperAdmin();

export default{
    props: ['obj', 'number'],
    components: {
        modal,
        Multiselect
    },
    data(){
        return{
            form: {
                additionalName: "",
                typeOfBusiness: [],
                activity: [],
                personToCommunicate: [],
                foundOutSky: "",
                additionalEmail: []
            },
            listActivity: [
                { value: 'institutions', label: this.$t('Institutions')},
                { value: 'trade', label: this.$t('Trade')},
                { value: 'production', label: this.$t('Production')},
                { value: 'services', label: this.$t('Services')}
            ],
            listBusiness: [
                { value: 'bar', label: this.$t('bar_title')},
                { value: 'cafe', label: this.$t('Cafe_title')},
                { value: 'coffeeshop', label: this.$t('coffee_shop_title')},
                { value: 'restaurant', label: this.$t('restaurant_title')},
                { value: 'canteen', label: this.$t('Canteen')},
                { value: 'pizzeria', label: this.$t('Pizzeria')},
                { value: 'sushibar', label: this.$t('sushibar')},
                { value: 'fastfood', label: this.$t('fastfood')},
                { value: 'foodtruck', label: this.$t('foodtruck')},
                { value: 'hookah', label: this.$t('Hookah')},
                { value: 'kiosk', label: this.$t('kiosk')},
                { value: 'boutique', label: this.$t('Boutique')},
                { value: 'market', label: this.$t('market')},
                { value: 'shop', label: this.$t('shop')},
                { value: 'jewshop', label: this.$t('jewshop')},
                { value: 'petshop', label: this.$t('petshop')},
                { value: 'pharmacy', label: this.$t('pharmacy')},
                { value: 'bakery', label: this.$t('bakery')},
                { value: 'candyshop', label: this.$t('candyshop')},
                { value: 'business', label: this.$t('business')},
                { value: 'franchise', label: this.$t('franchise')},
                { value: 'deliveryfood', label: this.$t('deliveryfood')},
                { value: 'tirefitting', label: this.$t('tirefitting')},
                { value: 'carwash', label: this.$t('carwash')},
                { value: 'hospital', label: this.$t('hospital')},
                { value: 'dentistry', label: this.$t('dentistry')},
                { value: 'vetclinic', label: this.$t('vetclinic')},
                { value: 'spasalon', label: this.$t('spasalon')},
                { value: 'beautysaloon', label: this.$t('beautysaloon')},
                { value: 'barbershop', label: this.$t('barbershop')},
                { value: 'nailsalon', label: this.$t('nailsalon')},
                { value: 'cosmetology', label: this.$t('cosmetology')}
            ],
            listTypeFound: [
                { value: 'recommendation', label: this.$t('Recommendation')},
                { value: 'himself', label: this.$t('independently')},
                { value: 'movedadvert', label: this.$t('MovedAdvertisement')},
                { value: 'integrator', label: this.$t('ThroughIntegrator')},
                { value: 'socialnetworks', label: this.$t('sawSocialNetworks')}
            ],
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj;
        },
        save(){
            if (!this.number) {
                //перевіряємо чи вказаний номер телефону
                this.$toast.error(this.$t('EnterNumberC'));
            } else {
                apiServe.saveAccountData(this.number, this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('alertChanged'));
                        this.$emit('getdata', this.form)
                        this.$emit('close');
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);
                    }
                })
            }
        }
    }
}
</script>