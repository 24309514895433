<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0"><b>{{ $t('Comments') }}</b></h5>
        </div>
        <div class="card-body">
            <tablecustom 
                :rows="this.rows"
                :columns="this.columns"
            />
        </div>
    </b-card>
</template>

<script>
import tablecustom from '@/components/globaltable/index.vue' 

export default{
   props: ['obj'],
   data(){
       return {
            rows: '',
            columns: [
                {
                    name: "ID",
                    text: "id",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Comment"),
                    text: "comment",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Manager"),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("date_time"),
                    text: "createDatetime",
                    align: "left",
                    status: true,
                },
            ]
        }
   },
   created(){
       this.rows = this.obj
       console.log(this.form)
   },
   methods: {
   },
   components: {
    tablecustom
   }
}
</script>

<style lang="css" scoped>
.card-body {
    padding: 0.5rem !important;
}
</style>