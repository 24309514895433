<template>
    <b-card>
        <b-row>
            <h3><b>{{ $t('statusClient') }}</b></h3>
            <b-col lg="6">
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-hashtag"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('Status') }} :</p>
                            <h6 class="text-truncate mb-0">
                                <span :class="`fs-14 ${ form.status != null ? mutateStatusAccounts(form.status).color : ''}`">{{ form.status != null ? mutateStatusAccounts(form.status).name : '-'}}</span>    
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bxs-phone"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">Оплата:</p>
                            <h6 class="text-truncate mb-0">Активный, нет задолжностей (Waiting API)</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-mail-check-line"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('tariffPlan') }} :</p>
                            <h6 class="text-truncate mb-0">{{ company.license && company.license.tariffName ? company.license.tariffName : '-' }}</h6>
                        </div>
                    </div>
                </div>
                    <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-mail-check-line"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('numberTT') }} :</p>
                            <h6 class="text-truncate mb-0">
                                <span class="text-success fw-semibold"> {{ company.license.countTradepoints }} | 
                                    <span style="color: #ffc107;">{{ company.license.countActiveTradepoints }}</span>
                                </span>
                            </h6>
                        </div>
                    </div>
                </div>
                    <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-mail-check-line"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('soldDevice') }} :</p>
                            <h6 class="text-truncate mb-0">- (оборудование которое именно мы продали, возможно доп форма) (Waiting API)</h6>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bxs-user"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('Kind_of_activity') }}:</p>
                            <div class="hstack text-wrap gap-1">
                                <span v-if="form.activity != null" class="badge badge-soft-info fs-14" v-for="item in form.activity" :key="item">{{ this.mutateActivity(item) }}</span>
                                <span v-else>-</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bx-comment-dots"></i></div>
                        </div>
                        <div class="flex-grow-1" >
                            <p class="mb-1">{{ $t('Business_type') }} :</p>
                            <div class="hstack text-wrap gap-1">
                                <span v-if="form.typeOfBusiness != null" class="badge badge-soft-secondary fs-14" v-for="item in form.typeOfBusiness" :key="item">{{ this.mutateBusiness(item) }}</span>
                                <span v-else>-</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bx-comment-dots"></i></div>
                        </div>
                        <div class="flex-grow-1" >
                            <p class="mb-1">{{ $t('connectedModules') }} :</p>
                            <h6 class="mb-0">SkyMarket (цена хз) (название, цена, статус), экран в зал (Waiting API)</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bx-comment-dots"></i></div>
                        </div>
                        <div class="flex-grow-1" >
                            <p class="mb-1">{{ $t('connectedAddonsServices') }}:</p>
                            <h6 class="mb-0">Настройка пРРО (цена), выезд мастера (цена) + (мб статус платежа)(Waiting API)</h6>
                            
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bx-comment-dots"></i></div>
                        </div>
                        <div class="flex-grow-1" >
                            <p class="mb-1">{{ $t('referrals') }} :</p>
                            <div class="hstack text-wrap gap-1">
                                <span v-if="form.referallsData.referrals.length > 0" class="badge badge-soft-danger fs-12" v-for="item in form.referallsData.referrals" :key="item">{{ item.phone }}</span>
                                <span v-else>-</span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import statusclient from '../clientstatus.vue'
import { mutateBusiness, mutateActivity, mutateStatusAccounts } from '@/usabilityScripts/globalMutate'


export default({
    props: ['obj', 'jsonComp'],
    components: { statusclient },
    data() {
        return{
            form: "",
            company: "",
            services: ""
        }
    },
    created(){
        this.form = this.obj
        this.company = this.jsonComp.companyDataObj;
        console.log('co', this);
    },
    methods: {
        mutateActivity(e){
            return mutateActivity(e)
        },
        mutateBusiness(e){
            return mutateBusiness(e)
        },
        mutateStatusAccounts(e){
            if(e != null ){
                return mutateStatusAccounts(e.toString())
            }
        }
    }
})
</script>
