<template>
    <!-- 
        Модальне вікно вибору іконки 
    -->

    <dialogBox >
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('choose_icon') }}</template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            <div style="display:grid; grid-template-columns: repeat(4, 1fr);">

                <!-- Відображення першого ряду іконок -->
                <div v-for="item in this.iconList1" :key="item">
                    <input type="checkbox" :checked="item == this.form.icon" @change="foo(item)">
                    <i :class=" item" style="font-size: 30px;"></i>
                </div>

                <!-- Відображення другого ряду іконок -->
                <div v-for="item in this.iconList2" :key="item">
                    <input type="checkbox" :checked="item == this.form.icon" @change="foo(item)">
                    <i :class=" item" style="font-size: 30px;"></i>
                </div>

                <!-- Відображення третього ряду іконок -->
                <div v-for="item in this.iconList3" :key="item">
                    <input type="checkbox" :checked="item == this.form.icon" @change="foo(item)">
                    <i :class=" item" style="font-size: 30px;"></i>
                </div>

                <!-- Відображення четвертого ряду іконок -->
                <div v-for="item in this.iconList4" :key="item">
                    <input type="checkbox" :checked="item == this.form.icon" @change="foo(item)">
                    <i :class=" item" style="font-size: 30px;"></i>
                </div>

            </div>
        </template>
        <template v-slot:footer-bottom>
            <div>

                <!-- Кнопка для закриття модального вікна -->
                <button class="btn link-success fw-medium" @click="$emit('close')">
                    <i class="ri-close-line me-1 align-middle"></i>
                    {{ $t('close') }} 
                </button>

                <!-- Кнопка для додавання нової іконки, якщо вибір відбувається при створенні категорії -->
                <button 
                    type="button" 
                    class="btn btn-primary" 
                    v-on:click="emoji2"
                    v-if="this.form.icon && this.perm == false"
                >
                    {{ $t('Add') }}
                </button>

                <!-- Кнопка для редагування іконки, якщо вибір відбувається при редагуванні категорії -->
                <button 
                    type="button" 
                    class="btn btn-primary" 
                    v-on:click="emojiEdit"
                    v-if="this.form.icon && this.perm == true"
                >
                    {{ $t('Edit') }}
                </button>

            </div>
        </template>
    </dialogBox>

</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модального вікна
import { FAQ } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних

let apiServe = new FAQ();

export default {
    props: ['obj', 'perm'],
    components: { dialogBox },
    data() {
        return {
            select: 0, // Змінна для відстеження кількості вибраних іконок
            bar: [], // Змінна для зберігання обраних іконок
            // Масив іконок для відображення на сторінці
            iconList1: '',
            iconList2: '',
            iconList3: '',
            iconList4: '',
            iconList: [ // Масив іконок для вибору
            "bx bx-money-withdraw",
            "bx bx-candles",
            "bx bx-math",
            "bx bx-store-alt",
            "bx bx-bar-chart-alt-2",
            "bx bx-message-dots",
            "bx bx-wallet-alt",
            "bx bx-user-pin",
            "bx bx-transfer-alt",
            "bx bxl-visa",
            "bx bx-check-square",
            "bx bx-line-chart",
            "bx bx-basket",
            "bx bx-purchase-tag-alt",
            "bx bx-receipt",
            "bx bx-credit-card-alt",
            "bx bx-wifi-off",
            "ri-scales-2-fill",
            "bx bx-printer",
            "bx bx-archive-in",
            "bx bx-archive-out",
            "bx bx-check-shield",
            "bx bx-food-menu",
            "bx bx-bookmark",
            "bx bx-bookmarks",
            "bx bx-error",
            "bx bx-menu",
            "bx bx-rss",
            "bx bx-bluetooth",
            "bx bx-desktop",
            "bx bx-data",
            "bx bx-dollar",
            "bx bx-table",
            "bx bx-barcode",
            "bx bx-trash",
            "bx bx-phone-outgoing",
            "bx bx-hdd",
            "bx bx-qr",
            "bx bx-money",
            "bx bx-barcode-reader",
            // <i class=""> </i>
            ],
            form:{ // Дані для форми
                categoryName: "", // назва категорії
                comment: "",  // опис категорії
                icon: "", // іконка категорії
                lang: ""  // мова категорії
            },
            lang:[ // Масив з доступними мовами
                {
                    title:"Українська",
                    value: "uk"
                },
                {
                    title:"Poland",
                    value: "pl"
                },
                {
                    title:"Російська",
                    value: "ru"
                }
            ]
        };
    },
    created(){
        // Ініціалізація даних форми при створенні компонента
        this.form = this.obj
        this.iconList1 = this.iconList.splice(0, 10)
        this.iconList2 = this.iconList.splice(0, 10)
        this.iconList3 = this.iconList.splice(0, 10)
        this.iconList4 = this.iconList.splice(0, 10)  //для вёрстки. Расчитано на 40 строк
    },
    methods: {
        emoji2() {
            // Метод для вибору іконки при створенні категорії
            this.$emit('emoji', this.form);
            this.$emit('close') //при виборі іконки надіслати на створення
        },
        emojiEdit() {
            // Метод для вибору іконки при редагуванні категорії
            this.$emit('emojiEdit', this.form);
            this.$emit('close') //при виборі іконки надіслати на редагування
        },
        foo(e) {
            // Метод для обробки події зміни вибору іконки
            this.form.icon = e
        },
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    },
    mounted(){
        // Cлухання події saveCateg для оновлення даних після збереження категорії
        this.eventBus.off('saveCateg')
        this.eventBus.on('saveCateg', (status) => {
            if(status == true) {
                this.getdata();
            }
        }),
        
        // Cлухання події savePost для оновлення даних після збереження поста
        this.eventBus.off('savePost')
        this.eventBus.on('savePost', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    }
}
</script>