<template>
    <!-- Компонент діалогу для підтвердження читання повідомлень -->

    <dialogRead>
        <!-- Заголовок діалогу -->
        <template v-slot:title>{{ $t('confirmation') }}</template>

        <!-- Вміст діалогу -->
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <div class="mb-3">
                        <!-- Текст у центрі діалогу з підтвердженням читання повідомлень -->
                        <h4 class="fs-15 text-center">{{ $t('confReadMsg') }}</h4>
                    </div>
                </b-col>
            </b-row>
        </template>

        <!-- Нижні кнопки діалогу -->
        <template v-slot:footer-bottom>
            <!-- Кнопка "Ні" для закриття діалогу -->
            <button 
                type="button" 
                class="btn btn-success" 
                @click="this.$emit('close')"
            >
                {{ $t('n') }}
            </button>

            <!-- Кнопка "Так" для очищення повідомлень або сповіщень -->
            <button 
                type="button" 
                class="btn btn-danger" 
                @click="clearAlerts()"
            >
                {{ $t('y') }}
            </button>
        </template>
    </dialogRead>

</template>

<script>
    import dialogRead from '@/components/modal/dialog' // компонент модального вікна

    export default {
        components: {
            dialogRead
        },
        // Властивості, які передаються у компонент
        props: ['type', 'messages', 'alerts'],
        methods: {
            clearAlerts() {
                // Метод для очищення повідомлень або сповіщень в залежності від типу
                this.$emit('clearAlerts', this.type, (this.type == 'messages' ? this.messages : this.alerts))
            }
        },
        created() { 
            
        },
    }
</script>

<style scoped>

</style>