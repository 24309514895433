<template>
    <!-- 
        Модальне вікно по налаштуванню чек по смс
    -->

    <modal>
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('sendcheck') }}</template>

        <template v-slot:body>
            <b-row>
                <b-col>
                    <b-card>
                        <h5 class="card-title text-decoration-underline mb-3" style="cursor: pointer;">
                            <b>{{ $t('SendingcheckBySMS') }}:</b>
                        </h5>
                        <ul class="list-unstyled mb-0">
                            <li class="d-flex block-as">
                                <div class="check-as form-check form-check-success">
                                    <input 
                                        type="checkbox" 
                                        autocomplete="off" 
                                        class="form-check-input" 
                                        :id="this.form.appData.sendFromPos" 
                                        v-model="this.form.appData.sendFromPos" 
                                        disabled
                                    >
                                </div>
                                <div class="flex-grow-1" style="margin-top: 7px;">
                                    <label class="form-check-label fs-14" >
                                        <b>{{ $t('SendingcheckBySMScheck1') }}</b>
                                    </label>
                                    <!-- <p class="text-muted">Відображення інформаційних блоків</p> -->
                                </div>
                            </li>
                            <li class="d-flex block-as">
                                <div class="check-as form-check form-check-success">
                                    <input 
                                        type="checkbox" 
                                        autocomplete="off" 
                                        class="form-check-input" 
                                        :id="this.form.appData.sendFromDashboard" 
                                        v-model="this.form.appData.sendFromDashboard" 
                                        disabled
                                    >
                                </div>
                                <div class="flex-grow-1" style="margin-top: 7px;">
                                    <label class="form-check-label fs-14" >
                                        <b>{{ $t('SendingcheckBySMScheck2') }}</b>
                                    </label>
                                    <!-- <p class="text-muted">Відображення інформаційних блоків</p> -->
                                </div>
                            </li>
                            <li class="d-flex block-as">
                                <div class="check-as form-check form-check-success">
                                    <input 
                                        type="checkbox" 
                                        autocomplete="off" 
                                        class="form-check-input" 
                                        :id="this.form.appData.questionOnSale" 
                                        v-model="this.form.appData.questionOnSale" 
                                        disabled
                                    >
                                </div>
                                <div class="flex-grow-1" style="margin-top: 7px;">
                                    <label class="form-check-label fs-14" >
                                        <b>{{ $t('SendingcheckBySMScheck3') }}</b>
                                    </label>
                                    <!-- <p class="text-muted">Відображення інформаційних блоків</p> -->
                                </div>
                            </li>
                            <li class="d-flex block-as" style="padding-left: 15px;">
                                <div class="check-as form-check form-check-success">
                                    <input 
                                        type="checkbox" 
                                        autocomplete="off" 
                                        class="form-check-input" 
                                        :id="this.form.appData.defaultOnSale" 
                                        v-model="this.form.appData.defaultOnSale" 
                                        disabled
                                    >
                                </div>
                                <div class="flex-grow-1" style="margin-top: 7px;">
                                    <label class="form-check-label fs-14" >
                                        <b>{{ $t('SendingcheckBySMScheck4') }}</b>
                                    </label>
                                    <!-- <p class="text-muted">Відображення інформаційних блоків</p> -->
                                </div>
                            </li>
                        </ul>
                    </b-card>
                </b-col>
            </b-row>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import { copy } from '@/usabilityScripts/copy.js'

export default ({
    props: ['objCard'],
    components: { modal },
    data() {
        return{
            form: {},
        }
    },
    created(){
        this.form = this.objCard
        console.log("this, ", this.form);
        
    },
})
</script>