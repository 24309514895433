<template>
    <!-- <div class="col-sm-auto" style="margin-bottom:15px;">
        <button @click="showall()" type="button" :class="`btn btn-${icon == true ? 'primary': 'info'} waves-effect waves-light`"><i :class="`${icon == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
    </div> -->
    <h4 ><b>Token</b></h4>
    <hr />
    <b-row>
        <b-col
        lg="8">
            <b-card>
                <b-card-title>
                    <h4><b>Bot Token</b></h4>
                </b-card-title>
                <b-card-text>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" v-model="form.token" disabled>
                        <button @click="copy(form.token)" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="4">
            <b-card>
                <div class="hstack gap-sm-5">
                    <div>
                        <label class="fs-15">Market ID</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" v-model="market_id" disabled style="width: 70px;">
                            <button @click="copy(market_id)" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </div>
                    <div>
                        <label class="fs-15">SellUP ID</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" v-model="sellup_id" disabled style="width: 70px;">
                            <button @click="copy(sellup_id)" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </div>
                </div>
            </b-card>
        </b-col>
    </b-row>
    <h4 ><b>{{$t('main')}}</b></h4>
    <hr />
    <b-row>
        <b-col
        lg="6">
            <b-card>
                <b-card-title>
                    <h4><b>{{$t('nickname')}}</b></h4>
                </b-card-title>
                <b-card-text>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" v-model="form.nickname" disabled>
                        <button @click="copy('https://t.me/'+form.nickname)" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        <a target="_blank" :href="`https://t.me/${form.nickname}`" class="btn btn-success" type="button"><i class="ri-external-link-line"></i></a>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6">
            <b-card>
                <b-card-title>
                    <h4><b>{{$t('lang')}}</b></h4>
                </b-card-title>
                <b-card-text>
                    <h5> - {{form.lang}}</h5>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col
        lg="6">
            <b-card>
                <b-card-title>
                    <h4><b>{{$t('onlinePayment')}}</b></h4>
                </b-card-title>
                <b-card-text>
                    <div class="hstack text-wrap gap-1">
                        <span v-if="form.botpayments.courier.active == 'true'" :class="`fs-14 badge badge-soft-success`" >{{$t('CourierDelivery')}}</span>
                        <span v-if="form.botpayments.pickup.active == 'true'" :class="`fs-14 badge badge-soft-primary`" >{{$t('Pickup')}}</span>
                        <span v-if="form.botpayments.deliveryservice.active == 'true'" :class="`fs-14 badge badge-soft-warning`" >{{$t('DeliveryService')}}</span>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        v-if="form.tips.active == 'true'"
        lg="6">
            <b-card>
                <b-card-title>
                    <h4><b>{{$t('TipAmount')}}</b></h4>
                </b-card-title>
                <b-card-text>
                    <div class="hstack text-wrap gap-1">
                        <span :class="`fs-14 badge badge-soft-primary`" v-for="item in form.tips.values" :key="item" >{{item}}</span>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="12">
            <b-card>
                <b-card-title>
                    <h4><b>{{$t('startHiTg')}}</b></h4>
                </b-card-title>
                <b-card-text>
                    <h5>- {{form.msg_start}}</h5>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
    <h4 ><b>{{$t('Providers')}}</b></h4>
    <hr />
    <b-row>
        <b-col
        lg="12">
            <b-card>
                <b-card-text>
                    <b-row>
                        <b-col lg="6" v-for="(item, index) in providers" :key="item">
                            <div style="margin-top:5px;">
                                <label for="disabledInput" class="form-label fs-14">{{index}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control fs-14" v-model="item.key" disabled>
                                    <span class="input-group-text bg-info text-white fs-14" id="basic-addon2">{{item.commission}} %</span>
                                    <button @click="copy(item.key)" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
    <h4 ><b>{{$t('Information')}}</b></h4>
    <hr />
    <b-row>
        <b-col
        lg="12">
            <b-card>
                <b-card-title>
                    <h4><b>{{$t('terms_of_use')}}</b></h4>
                </b-card-title>
                <b-card-text>
                    <h5> - {{form.terms_of_use}}</h5>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="12">
            <b-card>
                <b-card-title>
                    <h4><b>{{$t('privacy_policy')}}</b></h4>
                </b-card-title>
                <b-card-text>
                    <h5> - {{form.privacy_policy}}</h5>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {copy} from '@/usabilityScripts/copy.js'

export default ({
    props: ['obj'],
    components: {  },
    data() {
        return{
            market_id: "",
            sellup_id: "",
            form: "",
            providers: {},
            // ifMain: true,
            // ifWeb: true
        }
    },
    created(){
        this.market_id = this.obj.market_id
        this.sellup_id = this.obj.sellup_id
        this.form = this.obj.data
        for(var item in this.form.providers){
            if(this.form.providers[item].key != null){
                this.providers[item] = this.form.providers[item]
            }
        }
    },
    methods: {
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    }
})
</script>
