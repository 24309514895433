<template>
    <!-- Компонент -->
    <div class="col-md-6">
            <div class="mb-3">
                <!-- Модель банківського терміналу -->
                <h4 class="fs-15">{{ $t('bank_terminal_model') }}</h4>
                    <Multiselect class="mb-3"
                        @input="input"
                        v-model="form.model.value"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="true"
                        :options="models"
                        :disabled="this.editform == false"
                    />
                    <!-- ОС -->
                <h4 class="fs-15">{{ $t('operating_system') }}</h4>
                    <select
                        class="form-select mb-3"
                        data-plugin="choices"
                        v-model="form.oc_types.value"
                        :disabled="this.editform == false"
                        >
                        <option v-for="item in oc_types" :key="item.id">{{ item.title }}</option>
                    </select>
                    <!-- Версія додатку -->
                <h4 class="fs-15">{{ $t('ver_apps') }}</h4>
                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.version_os.value" placeholder="9.9">
            </div>
        </div>
    <div class="col-md-6">
        <div class="mb-3">
            <!-- Метод підключення -->
                <h4 class="fs-15">{{ $t('connection_method') }}</h4>
                <select
                    class="form-select mb-3"
                    data-plugin="choices"
                    v-model="form.connect_types.value"
                    :disabled="this.editform == false"
                    >
                    <option v-for="item in connect_types" :key="item.id">{{ item.title }}</option>
                </select>
                <!-- Версія ОС -->
                <h4 class="fs-15">{{ $t('ver_oc') }}</h4>
                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.version_apps.value" placeholder="10">
            </div>
        </div>
        <hr>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
export default ({
    props: ['obj','editform'],
    components: { Multiselect },
    data(){
        return {
                models: [
                    {
                        "label": "SkyService s8",
                        "value": "skyservice s8",
                    },
                    {
                        "label": "SkyService s16",
                        "value": "skyservice s16",
                    }
                ],
                oc_types: [
                    {
                        "title": "Windows"
                    },
                    {
                        "title": "Android"
                    },
                    {
                        "title": "IOS"
                    }
                ],
                connect_types: [
                    {
                        "title": "Poolserver v1.0",
                    }
                ],
                form: {
                    model: {
                        icon: "ri-cpu-fill",
                        label: "bank_terminal_model",
                        value: ""
                    },
                    connect_types: {
                        icon: "mdi-connection",
                        label: "connection_method",
                        value: ""
                    },
                    oc_types: {
                        icon: "ri-user-2-fill",
                        label: "operatingSystem",
                        value: ""
                    },
                    version_os: {
                        icon: "ri-user-2-fill",
                        label: "ver_oc",
                        value: ""
                    },
                    version_apps: {
                        icon: "ri-user-2-fill",
                        label: "verSkyService",
                        value: ""
                    },
                }
            }
        },
        created(){
            if(this.obj != ''){
                this.form = this.obj
            }
        },
        methods:{
            input(){
                this.$emit('input', this.form)
            }
        },
        mounted() {
        }
    }
)
</script>
