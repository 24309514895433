<template>
    <!-- 
        Модальне вікно створення ярлика відповіді 
    -->

    <modal>
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('addQuickReplay') }}</template>
        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        
        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            <b-row>
                <!-- Назва ярлика -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Label') }}</h4>
                        <input type="text" class="form-control" v-model="form.shortcutName">
                    </div>
                </b-col>

                <!-- Поле для вибору групи ярлика -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Group') }}</h4>
                        <div class="row">
                            <div class="col" >
                                <Multiselect
                                    v-model="group"
                                    :close-on-select="true"
                                    :object="true"
                                    :searchable="true"
                                    :create-option="false"
                                    :clear-on-search="true"
                                    :options="listGroup"
                                />
                            </div>
                            <div class="col-auto" >
                                <!-- Кнопка для створення нової групи -->
                                <button 
                                    v-if="group == null || group == ''" 
                                    class="btn btn-soft-success small_btn" 
                                    style="margin-right: 5px;" 
                                    @click="showCreatedGroup = true"
                                >
                                    <i class="bx bxs-plus-circle" ></i>
                                </button>

                                <!-- Кнопка для редагування наявної групи -->
                                <button 
                                    v-if="ifGroup(listGroup,group)" 
                                    class="btn btn-soft-info small_btn" 
                                    style="margin-right: 5px;" 
                                    @click="editGroup(group)"
                                >
                                    <i class="ri-edit-2-fill" ></i>
                                </button>
                                
                                <!-- Кнопка для видалення наявної групи -->
                                <button 
                                    v-if="ifGroup(listGroup,group)" 
                                    class="btn btn-soft-danger small_btn" 
                                    @click="removeGroup(group)"
                                >
                                    <i class="ri-delete-bin-2-line" ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <!-- Поле для введення тексту повідомлення -->
            <div class="mb-3">
                <label class="form-label">{{ $t('Message') }}</label>
                <textarea class="form-control" rows="3" v-model="form.message" ></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>

            <!-- 
                Кнопка "Додати" 
                - надає змогу додати ярлик
            -->
            <b-button 
                variant="success" 
                @click="add" 
                class="waves-effect waves-light"
            >
                <i class="ri-edit-bin-line align-bottom me-1"></i>{{ $t('Add')}}
            </b-button>
        </template>
    </modal>
    
    <!-- Модальне вікно для створення нової групи -->
    <createGroup 
        v-if="showCreatedGroup == true" 
        @close="showCreatedGroup = false" 
        :objGroup="group"
    />

</template>

<script>
import modal from '@/components/modal-small' // компонент модального вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import createGroup from './group/create' // картка створення групи ярлика
import { Shortcuts } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Shortcuts();

export default ({
    data(){
        return {
            dontSave: false,
            group: "",
            form: { // Дані для форми
                shortcutName: "", // назва ярлику
                groupId: "", // id групи
                message: "" // повідомлення
            },
            listGroup: [],  // список груп
            showCreatedGroup: false, // прапорець відображення вікна створення групи
        }
    },
    props: [],
    components: { modal, Multiselect, createGroup },
    created(){
        this.getGroup();
    
        //перевірка на наявність не закінченних заявок
        let openRequest = indexedDB.open("store", 1);
            openRequest.onsuccess = function() {
                let db = openRequest.result;
                let transaction = db.transaction("data", "readwrite"); // (1)
                
                // получить хранилище объектов для работы с ним
                let data = transaction.objectStore("data"); // (2)
                let categoryIndex = data.index("сategory_idx");

                let request = categoryIndex.getAll('shortcut')

                request.onsuccess = function() {
                    if (request.result !== undefined && request.result.length > 0) {
                        eventB.emit('haveForms', {status: true, forms: request.result})
                    } else {
                        console.log('Незакінченні форми не знайдені. Дякую за збереження екології, бо ви не зайняли зайвого місця на серверах Зімбабве =)')
                    }
                };

                request.onerror = function() {
                    alert('Щось пішло не так, перезапустіть будь ласка сторінку')
                }
            }
        //перевірка на наявність не закінченних заявок

        //вибір дії було зроблено, обробка
        
        this.eventBus.off('openOld')
        this.eventBus.on('openOld', status => {
            if(status.status == true) {
                let openRequest = indexedDB.open("store", 1);
                    openRequest.onsuccess = function() {
                        let db = openRequest.result;
                        let transaction = db.transaction("data", "readwrite"); // (1)
                        
                        // получить хранилище объектов для работы с ним
                        let data = transaction.objectStore("data"); // (2)
                        let categoryIndex = data.index("сategory_idx");

                        let request = categoryIndex.getAll(status.form.category)

                        request.onsuccess = function() {
                            if (request.result !== undefined) {
                                request.result.forEach(el=> {
                                    if(el.id == status.form.id) {
                                        let deleteReq = data.delete(el.id)
                                            deleteReq.onsuccess = function() {
                                                console.log('All okey') //видалення, щоб не було дублікатів
                                            }
                                    }
                                })
                            }
                        };

                        request.onerror = function() {
                            alert('Щось пішло не так, перезапустіть будь ласка сторінку')
                        }
                    }
                this.form = JSON.parse(status.form.form)
                if(status.form.text !== '') {
                    this.perm = status.form.text
                }
            }
        })
        
        this.eventBus.off('createNew')
        this.eventBus.on('createNew', status => {
            if(status.status == true) {
                return
            }
        })
        
        //вибір дії було зроблено, обробка
    },
    beforeUnmount() {
        if(this.dontSave == false) {
            sessionStorage.setItem('actual', JSON.stringify(this.form))
            if(this.form.userPhone !== '' || this.form.comment !== '' || this.form.delayedDatetime !== '') {
                let openRequest = indexedDB.open("store", 1);
                    openRequest.onsuccess = function() {
                        let db = openRequest.result;
                        let transaction = db.transaction("data", "readwrite"); // (1)
                    
                        // получить хранилище объектов для работы с ним
                        let data = transaction.objectStore("data"); // (2)
                        let category_text = 'shortcut';
                        let detail = {
                            dateTime: new Date(),
                            text: '',
                            category: category_text,
                            form: sessionStorage.getItem('actual')
                        };

                        let request = data.add(detail); // (3)

                        request.onsuccess = function() { // (4)
                            sessionStorage.removeItem('actual')
                            console.log("Данные добавлены в хранилище", request.result);
                        };

                        request.onerror = function() {
                            console.log("Ошибка", request.error);
                        };
                    } //додавання в indexedDB
            }   
        }
    },
    methods: {
        add() {
            /**
                * Додавання нового ярлику з урахуванням валідації полів.
            */

            this.form.groupId = this.group.value;
            if (!this.form.shortcutName) {
                this.$toast.error(this.$t('enterNameLabel'));
            }
            if (!this.form.message) {
                this.$toast.error(this.$t('enterMsg'));
            }
            if (!this.form.groupId) {
                this.$toast.error(this.$t('EnterGroup'));
            }
            if(this.form.shortcutName && this.form.message && this.form.groupId){
                apiServe.addShortcut(this.form).then(result => {
                    if(result.status == 'done'){
                        this.dontSave = true
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('close')
                        this.eventBus.emit('saveShortcuts', true)
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var errorText = 'Помилка створення ярлика відповіді'; 
                        var statusRequest = result.status;
                        var api = 'addShortcut';
                        var fileRequest = 'src/views/shortcuts/create.vue';
                        var params = this.form;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
        editGroup(e){
            // вікликаємо вікно редагування групи 

            this.showCreatedGroup = true
        },
        removeGroup(e){
            /**
                * Видалення групи
                * @param {Object} e - Об'єкт групи для видалення.
            */

            apiServe.deleteGroup(e.value).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Removed'))
                    const index = this.listGroup.findIndex(n => n.value === Number(result.data));
                    if (index !== -1) {
                        this.listGroup.splice(index, 1);
                    }
                    this.group = ""
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка видалення групи'; 
                    var statusRequest = result.status;
                    var api = 'deleteGroup';
                    var fileRequest = 'src/views/shortcuts/create.vue';
                    var params = e.value;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        getGroup(){
            /**
                * Отримання списку груп ярликів відповідей.
            */
            apiServe.getGroupsLabels().then(result => {
                if(result.status == 'done'){
                    this.listGroup = [];
                    for(var item in result.data){
                        this.listGroup.push({
                            label: result.data[item].groupName,
                            value: result.data[item].groupId
                        })
                    }
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка отримання списку груп'; 
                    var statusRequest = result.status;
                    var api = 'getGroupsLabels';
                    var fileRequest = 'src/views/shortcuts/create.vue';
                    var params = {};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        ifGroup(arr, val) {
            /**
                * Перевірка наявності групи у списку груп.
                * @param {Array} arr - Масив груп.
                * @param {Object} val - Об'єкт групи для перевірки.
                * @returns {boolean} - Повертає `true`, якщо група існує, або `false` в іншому випадку.
            */
            if(val == null){
                return false
            } else {
                return arr.some(arrVal => val.value === arrVal.value);
            }
        }
    },
    mounted(){
        // Cлухання події saveGroup для оновлення даних після збереження групи
        this.eventBus.off('saveGroup')
        this.eventBus.on('saveGroup', (status) => {
            if(status == true) {
                this.getGroup();
            }
        })
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
})
</script>
