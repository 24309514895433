import { reactive } from 'vue';

export var storeS = reactive({
    menu: null, //место хранения содержимого файла menu.js
    lang: '',
    break: '',
    perms:{},
    userbase:{},
    name: '',
    checks: {},
    interface: {},
    dialogComponent: {
        opened: []
    },
    nightTheme: localStorage.getItem('nightTheme'),
    routeKey: 0, // Ключ для ререндера компонента(обновление страницы без перезагрузки)
    voipPosition: {
        status: "",
        left: "",
        top: ""
    },
    workers: {},
    productCategories: {},
    providers: {},
    preloader: false,
    preloaderTable: false,
    dealines: {},
    music: '',
    tableSettings: [],
    currencyShop: '',
    invoice: {
        FOP: {},
        TOV: {}
    },
    groups: [],
    token: '',
    socket: '',
    wsConnection: '',
    ip: '',
    checkModal: {
        type: '',
        id: ''
    },
    KBCategories: [],
    KBCategoriesMain: [],
    readMsg: [],
    modalsOpen: [],
    update: false,
    tags: [], //мітки для тикетів та проектів
    tagsFaq: [], //мітки для faq
    tagsLessons: [], //мітки постів бази знань,
    token: '',
    tours: {
        11: false,
        100: false,
        101: false,
        102: false
    },
    messages: '',
    alerts: '',
    uploadPercentage: 0,
    usersWS: [],
    usersATS: [],
    cacheVideo: [], //для blob посилань
    folders: [],
    db: '',
    notesATS: [],
    tabIndex: 0 // для чату, щоб відслідковувати, де парсяться файли
})

