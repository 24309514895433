<template>

    <!-- компонент таблицы -->
    <tablecustom 
        v-if="this.obj != null" 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        :createdform="true" 
        @open="open"
    />

    <!-- карточка тикета -->
    <viewbox 
      v-if="showModal" 
      @close="showModal = false" 
      :obj="objCard"
    ></viewbox>

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewbox from '@/views/newtickets/view/index.vue'
import { mutatePriorityTicket, mutateStatusTicket } from '@/usabilityScripts/globalMutate.js'
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { storeS } from '@/store';
import { Tickets } from '@/API.js';

let apiServe = new Tickets();

export default {
    props: ['obj'],
    components: { tablecustom, viewbox },
    data() {
        return {
            showModal: false,
            objCard: '',
            search: '',
            rows: '',
            columns: [
                {
                    name: "ID",
                    text: "ticketId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("ticket_name"),
                    text: "ticketName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("description"),
                    text: "createComment",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "left",
                    mutate: (item) => this.mutateStatus(item).name,
                    mutateClass: (item) => this.mutateStatus(item).color,
                    status: true
                },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "left",
                    mutate: (item) => this.mutatePriority(item).name,
                    mutateClass: (item) => this.mutatePriority(item).color,
                    status: true
                },
                {
                    name: this.$t("added"),
                    text: "createWorkerId",
                    align: "right",
                    mutate: (item) => this.nameWorker(item),
                    status: true
                },
                {
                    name: this.$t("date"),
                    text: "createDatetime",
                    align: "right",
                    status: true
                },
                {
                    name: this.$t("clients"),
                    text: "userPhones",
                    align: "right",
                    mutate: (item) => item ? this.mutateNumber(item) : '-',
                    status: false,
                },
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.rows = this.obj
        },
        searchB(e){
            this.search = e
        },
        open(e){
            apiServe.getTicket(e.ticketId).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data;
                    this.showModal = true;
                } else {
                //
                }
            })
        },
        nameWorker(item){
            return nameWorker(item)
        },
        mutateNumber(value){
            var r = []
            if(value != ''){
                for(var item in value){
                    r.push('<span class="fs-11 badge badge-soft-info">'+value[item]+'</span>')
                }
            }
            return r
        }, 
        mutateStatus(e){
            return mutateStatusTicket(e);
        },
        mutatePriority(e){
            return mutatePriorityTicket(e);
        }
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.rows)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.ticketName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.createComment.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        workers() {
            return storeS.workers
        },
    }
}
</script>