<template>
  <!-- 
    Модальне вікно з відображення картки поста FAQ 
  -->

  <modal 
    :title="this.$t('question')+ ' # ' + this.form.postId" 
    :stylebody="'var(--vz-body-bg)'" 
    @close="this.$emit('close')"
  >
    <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
    <template v-slot:head-button>
      <div class="dropdown">
        <!-- Кнопка зі стрілкою для дій з уроком -->
        <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bx bx-dots-vertical-rounded align-bottom"></i>
        </button>
        <!-- Випадаюче меню з діями для уроку -->
        <div class="dropdown-menu dropdown-menu-end">
          <!-- Додати ярлик -->
          <button class="dropdown-item" @click="showTagBox = true">{{ $t('addLabel') }}</button>
        </div>
      </div>
    </template>

    <!-- Слот тіло модального вікна -->
    <template v-slot:body>
      <b-row>
        <b-col lg="12">
          <b-card >
            <div class="p-4">
              <!-- Заголовок поста -->
              <h5 class="card-title fs-20 fw-semibold">{{  this.form.question }}</h5>
              
              <!-- Відображення категорії та її значення -->
              <span class="fs-12 text-muted pb-3">{{ this.$t('Categories') }}: <b>{{ getNameGroup(form.categoryId) }}</b></span>
              
              <!-- Відображення відповіді -->
              <p class="fs-16 border-top mt-2 pt-2" v-html="this.form.answer"></p>
            </div>
            <hr />

            <!-- Відображення тегів, які призначені для уроку -->
            <label>{{ $t('labels') }}</label>
            <div class="pt-3">
                <div class="col-lg-12 mb-1">
                  <!-- Відображення окремого тегу -->
                  <span class="fs-14 fw-semibold text-white" 
                    :style="`word-break: break-all;margin-right:6px;margin-bottom:5px;padding:7px;border-radius:5px;background-color: ${nameTagFaq(item).color};`" 
                    v-for="item in form.tags" 
                    :key="item">{{ nameTagFaq(item).name }}
                  </span>
                </div>
              </div>
          </b-card>
        </b-col>
      </b-row>
    </template>

    <!-- Слот футера модального вікна з кнопками -->
    <template v-slot:footer-bottom>

      <!-- 
        Кнопка "Видалити" 
        - надає змогу видалити пост
        - відкривається модальне вікно, для підтвердження видалення поста
        - йде перевірка на права доступу під номером 1002 (видалення faq) та 2000 (режим бога)
      -->
      <button 
        type="button" 
        v-if="this.perms['1002'] == true || this.perms['2000'] == true" 
        @click="showDialogRemove = true" 
        class="btn btn-danger"
      >
        {{ $t('Delete') }}
      </button>

      <!-- 
        Кнопка "Редагувати" 
        - надає змогу відредагувати пост
        - йде перевірка на права доступу під номером 1003 (редагування faq) та 2000 (режим бога)
      -->
      <button 
        type="button" 
        v-if="this.perms['1003'] == true || this.perms['2000'] == true"
        @click="editreq(form)" 
        class="btn btn-info"
      >
        {{ $t('Edit') }}
      </button>

      <button type="button" @click="copy(this.form.answer)" class="btn btn-success">{{ $t('Copy') }}</button> <!-- концепт кнопки копіювання відповіді (поки неготова) -->
    </template>
  </modal>

  <!-- 
    Модальне вікно підтвердження видалення поста
  -->
  <dialogRemove 
    v-if="showDialogRemove" 
    @close="showDialogRemove = false" 
    @remove="remove(this.form)"
  />

  <!-- 
    Модальне вікно створення/вибору тегу
  -->
  <modalTag 
    v-if="showTagBox"
    @close="showTagBox = false"
    :typeTags="'faq'"
    :checkedTags="form.tags"
    @selectedTags="selectedTags"
  />

</template>

<script>
import modal from '@/components/modal/modal-lg' // компонент модального вікна
import dialogRemove from '@/components/modal/modalremove' // компонент діалогового вікна видалення
import createsmsBox from '@/views/application/createsms.vue' // вікно надсилання смс
import modalTag from '@/components/tags/list' // компонент з тегами
// import modalTag from '@/components/tags/add'
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";
import { FAQ } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних
import { nameTagFaq } from '@/usabilityScripts/globalMutate'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import {copy} from '@/usabilityScripts/copy.js'

let apiFAQ = new FAQ();

export default {
  data() {
    return {
      permEdit: false, // прапорець режиму редагування
      showTagBox: false, // прапорець відображення вікна вибору тегів
      form: { // Об'єкт форми для створення/редагування уроку
        id:"",
        title: "", 
        content: "",
        content1: "",
        category: "0",
        lang:"0",
        create_user: "",
        create_date_time: "",
        url: "",
        tags:[]
      },
      modalSmsbox: false, // прапорець відображення вікна sms
      showDialogRemove: false, // прапорець відображення вікна видалення
      categor: [], // масив категорій
    };
  },
  props: ['obj', 'less', 'categories', 'phone'],
  components: {
    modal,
    createsmsBox,
    dialogRemove,
    modalTag
    // Multiselect
  },
  created() {
    // Ініціалізація даних форми при створенні компонента
    this.getdata();
    
    this.categor = this.categories
    if(!this.categories) {
      this.categor = storeS.FAQCateg
    }
  },
  methods: {
    editreq(e) {
      // Функція для відправлення події редагування
      this.$emit('edit', e)
    },
    getdata(){
      this.form = this.obj
    },
    getNameGroup(item){
      // Функція для отримання імені категорії за ідентифікатором
      var name = "";
      this.categor.forEach((el) => {
          if(el.categoryId === item) {
              name = el.categoryName
          }
      })
      return name
    },
    selectedTags(ids){
      // Додавання тегів до поста

      this.form.tags = ids;

      // Відправлення запиту на редагування уроку з новими тегами
      apiFAQ.editLesson(this.form.postId, this.form).then(result => {
        if(result.status == 'done'){
          this.$toast.success('done');
        } else {
          this.$toast.error($t('error'))
        }
      })

    },
    copy(item){
        // копіювання
        if(copy(item) == true) {
            this.$toast.success(this.$t('Copied') + ' - ' + item);
        } else {
            this.$toast.error(`Fucking mistake, contact Borys`);
        }
        return
    },
    remove: function(e){
        /**
          * Видаляє поточний пост за допомогою API-запиту та виводить сповіщення про успішне видалення.
          * @param {object} е - об'єкт поста FAQ.
        */

        apiFAQ.deleteLesson(e.postId).then(result=> {
          if(result.status==='done') {
            this.$toast.success(this.$t('Removed'));
            this.$emit('close')
            this.$emit('getdata')
          } else {
            this.$toast.error(this.$t('error') + " " + result.error[0]);

            var errorText = 'Помилка видалення поста FAQ'; 
            var statusRequest = result.status;
            var api = 'deleteLesson';
            var fileRequest = 'src/components/faq/view/index.vue';
            var params = e.postId;
            var response = result

            // Викликаємо функцію для відправки повідомлення в телеграм
            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
            .then(response => console.log('Telegram API response:', response.data))
            .catch(error => console.error('Telegram API error:', error));
          }
        })
    },
    nameTagFaq(e){
      // Отримання імені тегу за ідентифікатором

      return nameTagFaq(e)
    }
  },
  computed: {
    user(){
      /*
        Повертає дані за користувача з глобального стору.
      */
      return storeS.userbase
    },
    perms() {
      /*
        Повертає дані прав доступу користувача з глобального стору.
      */
      return storeS.perms
    },
    tagsFaq(){
      /*
        Повертає дані тегів з глобального стору.
      */
      return storeS.tagsFaq
    }
  },
  mounted() {
    
  },
};
</script>

<style scoped>
.arrow_back {
  margin-bottom: 10px;
}
</style>