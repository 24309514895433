<template>
    <!-- модальное окно добавления языков и шаблонов -->
    <dialogBox>
        <template v-slot:title>
            <div v-if="this.params == 'lang'">{{ $t('AddLang') }}</div>
            <div v-if="this.params == 'pattern'">{{ $t('AddPattern') }}</div>
        </template>
        <template v-slot:body>
                    <div v-if="this.params == 'lang'">
                        <h4 class="fs-15" >{{ $t('AddLang') }}</h4>
                        <Multiselect 
                            v-model="this.langForPattern"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.langForPatternList"
                            :placeholder= "this.$t('AddLang')"
                        />
                        <div> <p></p> </div>
                        <input type="text" class="form-control" id="contactNumber" v-model="this.sendPulseId" :placeholder= "$t('SendPulseId')">
                    </div>
                    <div v-else>
                        <h4 class="fs-15" >{{ $t('PrintPattern') }}</h4>
                        <input type="text"  class="form-control" id="contactNumber" v-model="this.name" :placeholder= "$t('PrintPattern')">
                    </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium" @click="$emit('close')">
                <i class="ri-close-line me-1 align-middle"></i>
                {{ $t('close') }} 
            </button>
            <button type="button" v-if="this.params == 'lang'" class="btn btn-success" @click="addLang()">{{ $t('Add') }}</button>
            <button type="button" v-else class="btn btn-success" @click="addPattern()">{{ $t('Add') }}</button>
        </template>
    </dialogBox>

</template>

<script>
import dialogBox from '@/components/modal/dialog'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
    props: ['params', 'id'],
    components: { dialogBox, Multiselect },
    data() {
        return {
            name: '',
            langForPattern:"",
            sendPulseId: '',
            langForPatternList: [
                {
                    label: "PL",
                    value: "PL",
                },
                {
                    label: "UA",
                    value: "UA",
                },
                {
                    label: "EN",
                    value: "EN",
                },
                {
                    label: "RU",
                    value: "RU",
                },
            ],
        }          
    },
    created(){
        if(this.params == 'lang') {
            this.newId = this.id
        }
    },
    methods: {
        addPattern() {
            this.$emit('addPattern', this.name)
            this.$emit("close")
        },
        addLang() {
            this.$emit('addLang', this.newId, this.langForPattern, this.sendPulseId)
            this.$emit("close")
        },
    },
    computed: {
        
    },
    mounted(){
        
    }
}
</script>