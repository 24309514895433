<template>
    <!-- Телепортуємо модальне вікно до кінця тега body -->
    <teleport to="body">
        <!-- Модальне вікно -->
        <div class="modal fade bs-example-modal-center show" style="display: block;">
            <!-- Діалог модального вікна -->
            <div class="modal-dialog modal-dialog">
                <!-- Зміст модального вікна -->
                <div class="modal-content">
                    <!-- Заголовок модального вікна -->
                    <div class="modal-header border-bottom pb-3">
                        <!-- Назва модального вікна та статус -->
                        <h5 class="mb-1"><b><slot name="title"></slot></b> <br /><slot name="status"></slot></h5>
                        <!-- Кнопки навігації та додатковий контент -->
                        <div class="col-auto" >
                            <div class="hstack gap-sm-1 gap-1 align-items-center flex-wrap email-topbar-link" >
                                <!-- Кнопка попереднього елементу -->
                                <button v-if="this.ifPrevopen" @click="prevOpen" type="button" class="btn btn-soft-primary waves-effect waves-light"><i class="bx bx-left-arrow-alt"></i></button>
                                <!-- Кнопка наступного елементу -->
                                <button v-if="this.ifNextopen" @click="nextOpen" type="button" class="btn btn-soft-primary waves-effect waves-light"><i class="bx bx-right-arrow-alt"></i></button>
                                <!-- Додатковий контент у заголовку -->
                                <slot name="head-bottom"></slot>
                            </div>
                        </div>
                    </div>
                    <!-- Тіло модального вікна -->
                    <div class="modal-body" :style="stylebody == null ? '' : 'background: var(--vz-body-bg)'">
                        <!-- Контент тіла модального вікна -->
                        <slot name="body"></slot>
                    </div>
                    <!-- Нижня частина модального вікна -->
                    <div class="modal-footer">
                        <!-- Додатковий контент у нижній частині -->
                        <slot name="footer-bottom"></slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
// Імпортуємо store з файлу store.js
import { storeS } from '../store'

// Описуємо компонент
export default ({
    // Властивості компонента
    props: [
        // Флаг відкриття попереднього елементу
        'ifPrevopen',
        // Флаг відкриття наступного елементу
        'ifNextopen',
        // Стиль тіла модального вікна
        'stylebody'
    ],
    data() {
        return {
            // Ключ компонента
            componentKey: 0
        }
    },
    // Методи, які викликаються при створенні компонента
    created() {
        // Додати модальне вікно до списку відкритих модальних вікон
        storeS.modalsOpen.push('modal-small')
        
        // Встановити слухачі подій
        this.eventKey.off('esc')
        this.eventKey.on('esc', () => {
            // Закрити модальне вікно, якщо воно є останнім у списку відкритих модальних вікон
            if(storeS.modalsOpen[storeS.modalsOpen.length - 1] == 'odal-small' &&!document.querySelector('#preloader')) {
                storeS.modalsOpen.pop()
                this.close()
            } else {
                // Інакше збільшити ключ компонента
                this.componentKey++
            }
        });

        this.eventKey.off('sendForm')
        this.eventKey.on('sendForm', () => {
            // Надіслати форму
            this.send()
        });
        
        this.eventKey.off('arrowleft')
        this.eventKey.on('arrowleft', () => {
            // Перейти до попереднього елементу, якщо кнопка попереднього елементу є активною
            if(this.ifPrevopen){
                this.prevOpen()
            }
        })
        this.eventKey.off('arrowright')
        this.eventKey.on('arrowright', () => {
            // Перейти до наступного елементу, якщо кнопка наступного елементу є активною
            if(this.ifNextopen){
                this.nextOpen()
            }
        })
    },
    // Методи компонента
    methods: {
        // Надіслати форму
        send() {
            this.$emit('send')
        },
        // Закрити модальне вікно
        close: function(){
          this.$emit('close');
        },
        // Відкрити попередній елемент
        prevOpen(){
            this.$emit('prevOpen')
        },
        // Відкрити наступний елемент
        nextOpen(){
            this.$emit('nextOpen')
        },
    },
    // Метод, який викликається після монтування компонента
    mounted(){
    },
    // Компоненти, які використовуються в цьому компоненті
    components: {
        // viewbox
    }
})
</script>

<style scoped>
/* background: var(--vz-body-bg); */
.modal {
    background: #2e2e2eb8;
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 1000px;
        margin: 1.75rem auto;
    }
}
.modal-body {
    min-height: 500px;
    overflow: auto;
}

.modal-body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.modal-body::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.modal-body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
</style>
