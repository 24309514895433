<template>
    <b-card>
        <div class="d-flex mb-3">
            <h6 class="card-title mb-0 flex-grow-1 "><b>{{ $t('report') }}</b></h6>
        </div>
        <ul class="list-unstyled vstack gap-3 mb-0">
            <li v-for="(item, index) in form" :key="index" @click="open(item)" class="click">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                        <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-dark text-black">#{{index}}</div></div>
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="mb-1"><b>{{item.reportName}}</b></h6>
                        <p class="text-muted mb-0">{{ this.nameWorker(item.createWorkerId)}}</p>
                    </div>
                </div>
            </li>
        </ul>
    </b-card>
</template>

<script>
import { ReportsTickets } from '@/API.js';
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { storeS } from '@/store'

let apiServe = new ReportsTickets();

export default{
    props: ['obj', 'ticketId'],
    data(){
        return{
            form: "",
        }
    },
    created(){
        this.form = this.obj
    },
    methods:{
        open(e){
            this.$emit('openreport', e)
        },
        updateReports(id){
            apiServe.getReportsTickets(id).then(result => {
                if(result.status == 'done'){
                    this.form = result.data
                }
            })
        },
        nameWorker(item){
            return nameWorker(item)
        }
    },
    mounted() {
        this.eventBus.on('saveReport', id => {
            this.updateReports(id);
        })
    },
    computed: {
        workers(){
            return storeS.workers
        }
    }
}
</script>

<style>
.click{
    cursor: pointer;
}
</style>