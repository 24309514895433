<template>
    <!-- 
        Модальне вікно для перейменування файлу 
    -->

    <modal @close="$emit('close')">
        <!-- Заголовок модального вікна -->
        <template v-slot:title><i class="ri-pencil-line me-2 align-bottom text-muted"></i>{{ $t('Renaming') }}</template>

        <template v-slot:head-bottom>
            <!-- Кнопка закриття модального вікна -->
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <!-- Вміст модального вікна -->
        <template v-slot:body>
            <!-- Рядок з інформацією про файл -->
            <b-row>
                <b-col lg="12">
                    <div class="team-profile-img d-flex mb-4 " style="align-items: center;" >
                        <div class="avatar-md" >
                            <div class="avatar-title bg-soft-info bg-info-subtle text-info rounded fs-24" >
                                <i class="ri-folder-2-fill"></i>
                            </div>
                        </div>
                        <div class="team-content" style="margin-left: 15px;" >
                            <!-- Назва файлу -->
                            <a class="member-name"> 
                                <h5 class="fs-16 mb-1">
                                    {{ form.filename }}
                                </h5> 
                            </a>
                            <!-- Опис файлу -->
                            <p class="text-muted member-designation mb-0 description" v-html="form.description"></p>
                        </div>
                    </div>
                </b-col>

                <!-- Поле для введення нової назви файлу -->
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15 fw-semibold">{{ $t('enterNewName') }}</h4>
                        <input type="text" class="form-control" v-model="form.filename">
                    </div>
                </b-col>

                <!-- Поле для введення нового опису файлу -->
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15 fw-semibold">{{ $t('enterNewDesc') }}</h4>
                        <!-- Редактор тексту для введення опису -->
                        <textQE @input="inputText" :objText="form.description" />
                    </div>
                </b-col>
            </b-row>
        </template>

        <template v-slot:footer-bottom >
            <div class="pt-2">

                <!-- Кнопка "Перейменувати" -->
                <button 
                    type="button" 
                    class="btn btn-success" 
                    v-on:click="edit"
                >
                    {{ $t('RenameItem') }}
                </button>

            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import textQE from '@/components/textQE'

export default{
    props: ['objItem'],
    components: {
        modal,
        textQE
    },
    data(){
        return{
            ind: '', // Індекс файлу в масиві
            form: { // Дані про файл для редагування
                id: '',
                filename: '',
                description: '',
                type: ''
            }
        }
    },
    created(){
        // Ініціалізація даних при створенні компонента
        this.ind = this.objItem.ind
        this.form.filename = this.objItem.item.filename;
        this.form.id = this.objItem.item.id;
        this.form.description = this.objItem.item.description
        this.form.type = this.objItem.item.type
    },
    methods: {
        inputText(text) {
            // Обробник введення тексту для опису
            this.form.description = text.target ? text.target.innerHTML : text;
        },
        edit(){
            // Редагування назви та опису файла
            this.$emit('renameFile', this.ind, this.form)
            this.$emit('close')
        }
    }
}
</script>

<style>
p.text-muted.member-designation.mb-0.description > *{
    margin: 0;
}
</style>