<template>
    <!-- 
        Список змін по співробітнику
        - дозволяє переглянути список змін з фільтрацією по даті
        - відкрити картку зміни
        - та інше :)
    -->

    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
        <!-- кнопка відображення інфо-блоків -->
        <button 
            v-if="this.checks['001'] == true" 
            @click="infoblockShow = !infoblockShow" 
            type="button" 
            :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
        >
            <i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i>
        </button>
    </div>

    <!-- 
        Інформаційні блоки з підсумком по -
        - суми часу змін та кількість змін
    -->
    <infoblocks 
        v-if="infoblockShow" 
        :obj="this.information" 
        style="margin-top:15px;" 
    />

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - @open - подія, яка спрацьовує при відкритті картки штрафу
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true"
        @open="open"
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
    />

    <!-- 
       Виклик відкриття картки зміни
       - для відкриття застосовується перевірка значення modalDialog
       - @close - подія, яка спрацьовує при закритті картки
       - :objcard - параметр-об'єкт з даними про зміну
    -->
    <viewbox 
        v-if="modalDialog == true"
        @close="modalDialog = false" 
        :objcard="objCard" 
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index';  // компонент таблиці
import viewbox from './view' // картка зміни
import infoblocks from '@/components/info/blocks' // інфо-блоки
import { Users } from "@/API.js"; // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiUsers = new Users();

export default {
    props: ['id'],
    components: { tablecustom, viewbox, infoblocks },
    data() {
        return {
            modalDialog: false, // прапорець відображення вікна зміни
            infoblockShow: false, // прапорець відображення інфо-блоків
            objCard: {}, // Об'єкт з даними зміни для відображення в модальному вікні
            objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: false, // true - показати кнопку фільтр, false - сховати
                page: "shifts"
            },
            objParams: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                workerId: '',
                pr1: '',
                pr2: ''
            },
            rows: [], // Дані для відображення у таблиці
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                {
                    name: "ID",
                    text: "shiftId",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('Employee'),
                    text: "closedWorkerName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("ShiftisOpen"),
                    text: "openTime",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("ShiftisClose"),
                    text: "closeTime",
                    align: "left",
                    status: true
                },
            ],
            information: [
                // Дані компонента, які відображають інформацію про кількість та суми годин в
                // Кожен елемент масиву містить:
                // * title - назву інформаційного блоку,
                // * value - кількість змін
                // * icon - клас іконки
                // * color - колір інформаційного блоку
                // * sum - загальну суму годин змін
                {
                    title: this.$t('totalShiftlength'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                    sum: "0"
                },
            ]
        }
    },
    created(){
        // Ініціалізація компонента та отримання даних
        this.getdata()
    },
    methods: {
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        getdata(page){
            // this.objParams.workerId = this.id
            this.objParams.page = page != undefined ? page : '1';
            this.objParams.workerId = this.id
            apiUsers.getAllShifts(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                    this.objPages = result.data;
                    
                    this.information[0].value = result.data.total;
                    this.information[0].sum = result.data.sumTime.hours + " " + this.$t('hours') + " " + result.data.sumTime.minutes + " " + this.$t('min');
                }
            })
        }, 
        open(e){
            /**
                Функція для відкриття модального вікна з інформацією про зміну за його ідентифікатором.
                * @param {Object} e - об'єкт картки зміни

                Викликає API для отримання інформації про зміну.
                Результат присвоюється властивості objCard, та встановлюється прапорці modalDialog в true для відображення модального вікна.
            */

            apiUsers.getCardShift(e.shiftId).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data;
                    this.modalDialog = true;
                } else {
                    this.$toast.error(this.$t('err'));

                    var errorText = 'Помилка відкриття картки зміни'; 
                    var statusRequest = result.status;
                    var api = 'getCardShift';
                    var fileRequest = 'src/views/users/view/formright/shifts/index.vue';
                    var params = e.shiftId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(name == 'all'){
                this.objParams.search = '',
                this.objParams.priority = '',
                this.objParams.status = '',
                this.objParams.workerId = '',
                this.objParams.pr1 = '',
                this.objParams.pr2 = '';
                this.getdata();
            } else if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        }
    }
}
</script>