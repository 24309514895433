<template>
    <dialogModal>
        <!-- Вікно перегляду нотаток -->
        <template v-slot:title>
            {{ this.$t('Edit') }}
        </template>
        <template v-slot:body>  
            <table class="table">
                <!-- Табличка з нотатками -->
                <thead>
                    <tr>
                        <th style="width: 10%;" scope="col">{{ this.$t('date_time') }}</th>
                        <th style="width: 70%;" scope="col">{{ this.$t('note') }}</th>
                        <th style="width: 10%;" scope="col">{{ this.$t('phone_num') }}</th>
                        <th style="width: 10%;" scope="col">{{ this.$t('action') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="items, i in this.noteArr" :key="i">
                        <td>{{ new Date(items.dateTime).toLocaleString() }}</td>
                        <td style="word-break: break-word;">{{ JSON.parse(items.form).comment }}</td>
                        <td>{{ JSON.parse(items.form).number }}</td>
                        <td>
                            <div class="d-grid gap-2" style="grid-template-columns: repeat(2, 1fr);">
                                <!-- <button style="padding: 7px;" class="btn btn-success" @click="editNote(items.id)">
                                    <i class='bx bx-edit-alt' ></i>
                                </button> -->
                                <button style="padding: 7px;" class="btn btn-danger" @click="removeNote(items.id, i)">
                                    <i class='bx bx-trash' ></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
        <template v-slot:footer-bottom>
            
        </template>
    </dialogModal>
</template>

<script>
import dialogModal from "@/components/modal/dialog.vue"
import dbPromise from '../../indexedDB';

export default {
    data() {
        return {
            noteArr: []
        }
    },
    props: ['notes'],
    components: {
        dialogModal
    },
    methods: {
        // editNote(id) {

        // }, 
        removeNote(id, idx) {
            dbPromise.remove(id)
            this.eventBus.emit('getDataNotes', {status: true})
            this.noteArr.splice(idx, 1)
        }
    },
    created() {
        this.noteArr = this.notes
    }
}
</script>