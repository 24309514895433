<template>
    <!-- Модальне вікно з повторенням запиту -->

    <dialogmodal>
        <template v-slot:body>
          <div class="mt-4 text-center">
              <h3><b>{{ $t('error') }}</b></h3>
              <p class="text-muted fs-15 mb-1">{{ $t('DataNotSaved') }}</p>
          </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 justify-content-center remove">

                <!-- Якщо елемент має тип "урок", буде запропоновано завантажити урок -->
                <button 
                    class="btn btn-info" 
                    v-if="fromType == 'lesson'" 
                    @click="$emit('download')"
                >
                    {{ $t('Download') }}
                </button>

                <!-- Повторити запит -->
                <button 
                    class="btn btn-success" 
                    @click="$emit('repeat')"
                >
                    {{ $t('repeat') }}
                </button>
            </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue' // компонент модального вікна

export default {
    props: ['obj', 'fromType'],
    components: {
        dialogmodal
    },
    data(){
        return{
            from: ""
        }
    },
    created(){
        this.form = this.obj
    },
}
</script>