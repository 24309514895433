<template>
    <b-card >
        <h6 class="fw-semibold text-uppercase mb-3">{{ $t('toEdit') }}</h6> 
        <ul class="list-unstyled vstack gap-3 mb-0">
            <li v-for="(item, index) in form.edits" :key="index" class="click" @click="open(item)">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                        <div class="avatar-xs flex-shrink-0 me-1">
                            <div :class="`avatar-title rounded bg-${item.status == 'new' ? 'danger' : item.status == 'completed' ? 'warning' : 'success' } text-white`">
                                #{{index+1}}
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="mb-0"><b>{{item.taskId}}</b></h6>
                        <span class="text-muted fs-11">{{ item.datetime }}</span>
                    </div>
                </div>
            </li>
        </ul>
    </b-card>

    <viewbox 
        v-if="showModal == true" 
        @close="showModal = false" 
        :obja="this.objCard"
        @closeModal="closeModal"
        @edit="edit"
    ></viewbox>

</template>

<script>
import viewbox from './view'
import { Projects } from '@/API.js';

let apiServe = new Projects();

export default {
    props: ['obj'],
    components: { viewbox },
    data() {
        return {
            form: "",
            formedits: "",
            objCard: '',
            showModalEdits: false,
            showModal: false,
            info_edit: ""
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.obj
        },
        open(e){
            apiServe.getProjectEdit(e.projectEditId).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data;
                    this.showModal = true;
                } else {
                    //
                }
            })
        },
        closeModal(){
            this.showModal = false;
            var count = this.form.edits.filter(item => item.status == 'confirmed').length
            if(count == (this.form.edits.length - 1)){
                apiServe.completeProject(this.form.projectId).then(result => {
                    if(result.status == 'done'){
                        this.eventBus.emit('UpdateCard', true)
                    }
                })
            }
        },
        // edit: function(e){
        //     var checked = this.formedits.length == this.formedits.filter(item => item.status == true).length ? true : false;
        //     this.form.status = checked == true ? 'stasusDonetoConf' : 'changesMade' ; 
        //     var formedit = e
        //     apiServe.editEditProject(formedit.id, formedit).then(res => {
        //         if(res){
        //             apiServe.editProject(this.form.id, this.form).then(result =>{
        //                 if(result){
        //                     this.eventBus.emit('saveEditProject', true)
        //                     this.eventBus.emit('saveProject', true)
        //                     this.showModalEdits = false
        //                     this.$toast.success(this.$t('done'));
        //                 }
        //             })
        //         }
        //     })
        // },
        // // prgEdit(){
        // //     var items = this.formedits;
        // //     if(items.length != '0'){
        // //         var checkedItem = items.data.filter(item => item.status == true).length
        // //         this.form.progressEditBar = (Number(checkedItem)/Number(this.formedits.length)*100).toFixed(0)
        // //         apiServe.editProject(this.form.id, this.form).then(result =>{
        // //             if(result){
        // //                 this.$toast.success(this.$t('done'));
        // //                 this.eventBus.emit('saveProject', true)
        // //             }
        // //         })
        // //     }
            
        // // }
    },
    mounted(){
    }
}
</script>