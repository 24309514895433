<template>
    <b-card>
       <div class="card-header">
           <h5 class="card-title mb-0"><b>{{ $t('client') }}</b></h5>
       </div>
       <div class="card-body">
           <div class="mb-3">
               <div class="d-flex mt-2">
                   <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                       <div :class="`avatar-title bg-success rounded-circle fs-16 text-white`" >
                           <i :class="`bx bxs-phone`"></i>
                       </div>
                   </div>
                   <div :class="`flex-grow-1 overflow-hidden`" >
                       <p class="mb-1 fs-16">{{ $t('phone_number') }} :</p>
                       <div class="ctext-wrap" style="display:flex">
                           <div class="ctext-wrap-content d-flex">
                               <!-- <h6 :class="`fs-14 mb-0 fw-normal copy`" @click="copy(this.form.userPhone)"> {{ this.form.userPhone }}</h6> -->
                               <h6 :class="`fs-14 mb-0 fw-normal copy`" @click="this.$emit('getClient', this.form.userPhone)"> {{ this.form.userPhone }}</h6>
                               <i class="ri-file-copy-2-fill fs-16 copybtn text-secondary" style="margin-left: 10px;" @click="copy(this.form.userPhone)"></i>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           <!-- <div class="mb-3">
               <div class="d-flex mt-3">
                   <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                       <div :class="`avatar-title bg-success rounded-circle fs-16 text-white`" >
                           <i :class="`bx bx-time`"></i>
                       </div>
                   </div>
                   <div :class="`flex-grow-1 overflow-hidden`" >
                       <p class="mb-1 fs-16">{{ $t('phone_number') }} :</p>
                       <div class="ctext-wrap" style="display:flex">
                           <div class="ctext-wrap-content">
                           </div>
                       </div>
                   </div>
               </div>
           </div> -->
           <div class="mb-3">
               <div class="d-flex mt-3">
                   <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                       <div :class="`avatar-title bg-success rounded-circle fs-16 text-white`" >
                           <i :class="`bx bx-comment-dots`"></i>
                       </div>
                   </div>
                   <div :class="`flex-grow-1 overflow-hidden`" >
                       <p class="mb-1 fs-16">{{ $t('mail') }} :</p>
                       <div class="ctext-wrap" style="display:flex">
                           <div class="ctext-wrap-content">
                               <h6 :class="`fs-14 mb-0 fw-normal copy`" @click="copy(this.form.userEmail)" style="word-break: break-all;"> {{ this.form.userEmail ?  this.form.userEmail : '-' }}</h6>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
    </b-card>
</template>

<script>
import {copy} from '@/usabilityScripts/copy.js'

export default{
   props: ['obj'],
   data(){
       return {
           form: ''
       }
   },
   created(){
       this.form = this.obj
   },
   methods: {
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
   }
}
</script>

<style>
.copybtn {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity .3s;
}
.ctext-wrap-content:hover .copybtn {
    opacity: 1;
    transition: opacity .3s;
}
</style>