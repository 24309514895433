<template>
    <!-- фильтр по таблице -->
    <button
        type="button"
        class="btn btn-info"
        data-bs-toggle="offcanvas"
        :data-bs-target="'#offcanvasRight'+form[0].page"
        aria-controls="offcanvasRight"
        >
        <i class="ri-filter-3-line align-bottom me-1"></i>
    </button>
    <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        :id="'offcanvasRight'+form[0].page"
        aria-labelledby="offcanvasRightLabel"
    >
        <div class="offcanvas-header border-bottom">
            <h5
                class="offcanvas-title"
                id="offcanvasRightLabel"
            >{{ $t('filter') }}</h5>
            <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            ></button>
        </div>
        <div class="offcanvas-body p-4 overflow">
            <div class="mb-4" v-for="(item, index) in form" :key="index" >
                <label
                    for="status-select"
                    class="form-label text-muted text-uppercase fw-semibold mb-3"
                    >{{ $t(item.name) }}</label
                >
                <!-- <br /> -->
                <div class="search-box" v-if="item.page == 'devices'">
                    <Multiselect 
                        v-model="item.category"
                        @click="change(item.name, item.category)"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="false"
                        :options="item.items"
                    />
                </div>
                <br />
                <div class="row g-2 list_filtr">
                    <div class="col-lg-6">
                        <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" value="0" :id="item.name" v-model="item.category"  @change="change(item.name, '0')">
                        <label class="form-check-label" :for="item.name">{{ $t('all') }}</label>
                        </div>
                    </div>
                    <template v-for="value in item.items" :key="value" >
                        <!-- {{ value }} -->
                        <!-- <div class="col-lg-6">
                            <div class="form-check mb-2" >
                                <label class="form-check-label" :for="value+index">{{ value }}</label>
                            </div>
                        </div> -->
                        <div class="col-lg-6">
                            <div class="form-check mb-2" >
                                <input class="form-check-input" type="radio" :id="value+index" :value="value" v-model="item.category" @change="change(item.name, value)">
                                <label class="form-check-label" :for="value+index">{{ value == true ? $t('Activated') : value == false ? $t('blocked_user') : $t(value) }}</label>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="offcanvas-foorter border p-3 text-center">
            <button type="button" class="btn btn-danger w-100" @click="clearFilter">
                {{ $t('clear_filter') }}
            </button>
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store.js'

export default {
  props:['datafilter'],
  data() {
    return {
        form: "",
        selecttt: ""
    }
  },
  created(){
    this.form = this.datafilter
  },
  components: {
    Multiselect
  },
  methods: {
    change(name, e){
        this.$emit('change', name, e)
    },
    clearFilter(){
        for(var item in this.form){
            this.form[item].category = '0'
        }
        this.$emit('clearFilter')
    },
  },
  computed: {
    user(){
      return storeS.userbase
    }
  }
}
</script>

<style scoped>
.list_filtr {
    max-height: 300px;
    overflow: scroll;
}
</style>