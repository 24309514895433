<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label class="form-label">{{ $t('specifyDateTime') }}</label>
                <b-row>
                    <b-col lg="6">
                        <flatpickr v-model="form.delayedDate" :config="configDate" class="form-control border-0 dash-filter-picker shadow" ></flatpickr>
                    </b-col>
                    <b-col lg="6">
                        <!-- <flatpickr v-model="form.delayedTime" :config="configTime" class="form-control border-0 dash-filter-picker shadow"></flatpickr> -->
                        <setTime :timeProps="this.time" @setHour="setHour" @setMinute="setMinute"></setTime>
                    </b-col>
                </b-row>
                <!-- <div class="row g-2 list_filtr" >
                    <flatpickr v-model="date" :config="config" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
                </div> -->
            </div>
            <div class="mb-3">
                <label class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium"  @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="toDelay"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import setTime from "@/components/setTime.vue";
import dialogBox from '@/components/modal/dialog'
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { storeS } from '@/store';

export default{
    components: {
        dialogBox,
        flatpickr: Vueflatpickr,
        setTime
    },
    props: ['id'],
    data(){
        return{
            form: {
                comment: '',
                delayedTime: '',
                delayedDate: ''
            },
            time: '',
            date: "",
            configTime: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                minTime: "09:00",
                maxTime: "18:00",
            },
            configDate: {
                minDate: 'today',
                enableTime: false,
                dateFormat: "Y-m-d",
            },
        }
    },
    created() {
        if(storeS.lang == 'uk'){
            this.configDate.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.configDate.locale = Russian
        }
        this.currentDate();

        let actualDate = new Date()

        if(actualDate.getHours() >= 9 && actualDate.getHours() < 14) {
            this.form.delayedTime = this.time = '14:00'
        } else if(actualDate.getHours() >= 14 && actualDate.getHours() < 16) {
            this.form.delayedTime = this.time = '16:00'
        } else if(actualDate.getHours() >= 16) {
            this.form.delayedTime = this.time = '9:00'
        }
    },
    methods: {
        setHour(e){
            this.time = String(new Date(new Date().setHours(e)).getHours()) + ':' + this.time.split(':')[1]
            this.form.delayedTime = this.time
        },
        setMinute(e) {
            this.time = this.time.split(':')[0] + ':' + String(new Date(new Date().setMinutes(e)).getMinutes())
            this.form.delayedTime = this.time
        },
        currentDate(){
            var date = new Date();
            var dateStr =
            date.getFullYear() + "-" + 
            ("00" + (date.getMonth()+1)).slice(-2) + "-" +
            ("00" + (date.getDate())).slice(-2);

            var timeStr = 
            ("00" + date.getHours()).slice(-2) + ":" +
            String(Number(("00" + date.getMinutes()).slice(-2))+1)

            this.form.delayedTime = timeStr;
            this.form.delayedDate = dateStr;
        },
        toDelay(){
            this.$emit('toDelay', this.id, this.form)
            this.$emit('close')
        }
    }
}
</script>