<template>
    <!-- 
        Модальне вікно створення категорії 
    -->

    <dialogBox @close="this.$emit('close')">
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('Add') }}</template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>

            <!-- Вибір мови -->
            <h5 class="kb-title">
                <b>{{ $t('chooseLang') }}</b>
            </h5>
            <select class="form-select mb-3" v-model="form.lang">
                <option v-for="item in lang" :key="item" :value="item.value">{{item.title}}</option>
            </select>

            <!-- Назва категорії -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('titleCategory') }}</h4>
                <input type="text" class="form-control" id="contactNumber" v-model="form.categoryName">
            </div>

            <!-- Опис категорії -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('descCategory') }}</h4>
                <input type="text" class="form-control" id="contactNumber" v-model="form.comment">
            </div>

            <!-- Вибір іконки -->
            <!-- <div class="mb-3">
                <h4 class="fs-15">{{ $t('choose_icon') }}</h4>
                <button 
                    type="button" 
                    class="btn btn-primary" 
                    v-on:click="choose_emoji()"
                >
                    {{ $t('Add') }}
                </button>
            </div> -->

        </template>

        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom>
            <div>

                <!-- Закрити вікно -->
                <button class="btn link-success fw-medium" @click="$emit('close')">
                    <i class="ri-close-line me-1 align-middle"></i>
                    {{ $t('close') }} 
                </button>
                <!-- <button 
                    type="button" 
                    class="btn btn-primary" 
                    v-on:click="add"
                >
                    {{ $t('Add') }}
                </button> -->
                <button 
                    type="button" 
                    class="btn btn-primary" 
                    v-on:click="choose_emoji()"
                >
                    {{ $t('Add') }}
                </button>
            </div>
        </template>
    </dialogBox>

    <!-- 
        Виклик вікна вибору іконки до категорії
        - для відкриття застосовується перевірка значення showEmoji
        - @close - подія, яка спрацьовує при закритті картки
        - :obj - параметр-об'єкт з даними 
        - @emojiEdit - без поняття
        - @emoji2 - без поняття
    -->
    <addEmoji 
        v-if="showEmoji" 
        :obj="this.form" 
        :perm = "this.perm"
        @emojiEdit="emojiEdit" 
        @emoji="emojiSecondStage" 
        @close="showEmoji = false"
    />

</template>

<script>
import addEmoji from './addEmoji.vue' // вікно з вибором іконок
import dialogBox from '@/components/modal/dialog' // компонент модального вікна
import { FAQ } from '@/API.js'; // клас для взаємодії з API
import { storeS } from '@/store.js' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new FAQ();

export default {
    props: ['obj', 'perm'],
    components: { dialogBox, addEmoji },
    data() {
        return {
            showEmoji: false,
            dontSave: false, // прапорець, що вказує, чи потрібно зберігати дані перед закриттям компонента
            form: { // Дані для форми
                categoryName: "", // назва категорії
                comment: "", // опис категорії
                icon: "", // іконка категорії
                lang: "" // мова категорії
            },
            lang: [ // Масив з доступними мовами
                {
                    title:"Українська",
                    value: "uk"
                },
                {
                    title:"Poland",
                    value: "pl"
                },
                {
                    title:"Російська",
                    value: "ru"
                }
            ]
        };
    },
    created(){
        // Ініціалізація даних форми при створенні компонента
        if(this.perm === true) {
            this.form = this.obj; // дозвіл на редагування
        }
    },
    methods: {
        emojiSecondStage(e) {
            console.log(e)
            // Додавання іконки категорії (другий етап)

            apiServe.addCategoryF(e).then(result => {
                if(result.status === 'done'){
                    this.$toast.success(this.$t('сreatedT'));
                    this.$emit('close')
                    this.$emit('getData') //добавление категории
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка додавання категорії'; 
                    var statusRequest = result.status;
                    var api = 'addCategoryF';
                    var fileRequest = 'src/components/faq/actions/addCategory.vue';
                    var params = e;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            });
        },
        emojiEdit(e) {
            console.log(e)
            // Редагування іконки категорії

            let id = e.categoryId;
            apiServe.editCateg(id, e).then(result=> {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('alertChanged'));
                    this.$emit('getData') //змінення категорії, просто іконка остання крапка
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка редагування категорії'; 
                    var statusRequest = result.status;
                    var api = 'editCateg';
                    var fileRequest = 'src/components/faq/actions/addCategory.vue';
                    var params = {id: id, form: e};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        choose_emoji(){
            this.showEmoji = true
        },
        // add() {
        //     /**
        //         * Додавання нової категорії з урахуванням валідації полів.
        //     */

        //     if (!this.form.categoryName) {
        //         //перевіряємо чи вказана назва
        //         this.$toast.error(this.$t('EnterNameTicket'));
        //     }
        //     if (!this.form.comment) {
        //         //перевіряємо чи вказаний комент
        //         this.$toast.error(this.$t('EnterDesc'));
        //     }
        //     if (!this.form.lang) {
        //         //перевіряємо чи вказана мова
        //         this.$toast.error(this.$t('EnterLang'));
        //     }

        //     if(this.form.categoryName && this.form.comment && this.form.lang){
        //         apiServe.addCategoryF(this.form).then(result => {
        //             if(result.status == 'done'){
        //                 this.$toast.success(this.$t('сreatedT'));
        //                 this.dontSave = true
        //                 this.$emit('close')
        //                 this.eventBus.emit('saveCateg', true)
        //             } else {
        //                 this.$toast.error(this.$t('error') + " " + result.error[0]);

        //                 var errorText = 'Помилка створення категорії'; 
        //                 var statusRequest = result.status;
        //                 var api = 'addCategoryF';
        //                 var fileRequest = 'src/components/faq/actions/addCategory.vue';
        //                 var params = this.form;
        //                 var response = result

        //                 // Викликаємо функцію для відправки повідомлення в телеграм
        //                 sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
        //                 .then(response => console.log('Telegram API response:', response.data))
        //                 .catch(error => console.error('Telegram API error:', error));
        //             }
        //         })
        //     }
        // },
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    },
    mounted(){
        // Cлухання події saveCateg для оновлення даних після збереження категорії
        this.eventBus.off('saveCateg')
        this.eventBus.on('saveCateg', (status) => {
            if(status == true) {
                this.getdata();
            }
        }),
        
        // Cлухання події savePost для оновлення даних після збереження поста
        this.eventBus.off('savePost')
        this.eventBus.on('savePost', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    }
}
</script>