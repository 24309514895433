<template>
    <div id="printSalInv" style="display:none">
        <div style="margin:35px">
            <div style="font-weight: bold; font-size: 14pt; padding-left:5px; border-bottom: 3px solid;"> 
                <template v-if="form.date >= '2023-11-28'">
                    Видаткова накладна № {{ this.form.num.id }} {{ this.toDate() }}
                </template>
                <template v-else >
                    Видаткова накладна № {{ this.form.provider == 'TOV' ? this.form.numTOV.id : this.form.numFOP.id }} {{ this.toDate() }}
                </template>
            </div>
            <!-- <div style="font-weight: bold; font-size: 14pt; padding-left:5px; border-bottom: 3px solid;">Видаткова накладна № {{toNum()}} від 04 серпня 2022 р.</div> -->
                <br />
                <div>
                    <table width="100%" style="font-family: Arial;">
                            <tr>
                                <td style="width: 30mm; vertical-align: top;">
                                    <div style=" padding-left:2px; ">Постачальник:</div>
                                </td>
                                <!-- <td v-if="form.provider == 'tov'">
                                    <div style="font-weight:bold;padding-left:2px;">ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "CКАЙСРПО"
                                    <span style="font-weight: normal;">
                                        <br />IBAN: UA803052990000026001005016734в АТ КБ «ПриватБанк», Україна, 01032, м. Київ, вул. Жилянська, 126/23, оф. 202
                                        <br />Тел. +38(063) 702-59-07
                                        <br />Код ЄДРПОУ 44407531, ІПН 444075326593</span></div>
                                </td>
                                <td v-else>
                                    <div style="font-weight:bold;padding-left:2px;">ФОП Дубовик Микола Вікторович
                                    <span style="font-weight: normal;">
                                    <br />Р/р UA933052990000026006036711177, Банк ПАО КБ "Приватбанк", МФО 380775 код по ЄРДПОУ 3372814150</span></div>
                                </td> -->

                                    <template v-if="form.date >= '2023-11-28'">
                                        <td v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">
                                            <div style="font-weight:bold;padding-left:2px;"> {{ provider.providerTitle }}
                                                <span style="font-weight: normal;">
                                                    <br />IBAN: {{ provider.iban }} в {{ provider.bank }}, {{ provider.address }} 
                                                    <br />Тел. {{ provider.phone }} 
                                                    <br />Код ЄДРПОУ {{ provider.erdpu }}, ІПН {{ provider.ipn }}
                                                </span>
                                            </div>
                                        </td>
                                        <td v-if="provider.businessEntity == 'FOP' || (provider.businessEntity == 'TOV' && provider.workWithPDV == 'false')">
                                            <div style="font-weight:bold;padding-left:2px;"> {{ provider.providerTitle }}
                                                <span style="font-weight: normal;">
                                                    <br />Р/р {{ provider.iban }}, {{ provider.bank }}, МФО {{ provider.mfo }} код по ЄРДПОУ {{ provider.erdpu }}
                                                </span>
                                            </div>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td v-if="form.provider == 'TOV' || form.provider == 'tov'">
                                            <div style="font-weight:bold;padding-left:2px;">{{ settingInv.TOV.provider }}
                                                <span style="font-weight: normal;">
                                                    <br />IBAN: {{ settingInv.TOV.iban }} в {{ settingInv.TOV.bank }}, {{ settingInv.TOV.address }}
                                                    <br />Тел. {{ settingInv.TOV.phone }} 
                                                    <br />Код ЄДРПОУ {{ settingInv.TOV.erdpu }}, ІПН {{ settingInv.TOV.ipn }}
                                                </span>
                                            </div>
                                        </td>
                                        <td v-if="form.provider == 'FOP' || form.provider == 'fop' ">
                                            <div style="font-weight:bold;padding-left:2px;">{{ settingInv.FOP.provider }}
                                            <span style="font-weight: normal;">
                                            <br />Р/р {{ settingInv.FOP.calculatedWallet }}, {{ settingInv.FOP.bank }}, МФО {{ settingInv.FOP.mfi }} код по ЄРДПОУ {{ settingInv.FOP.erdpu }}</span></div>
                                        </td>
                                    </template>

                            </tr>
                            <tr>
                                <td style="width: 30mm; vertical-align: top;">
                                    <div style=" padding-left:2px; ">Покупець:</div>
                                </td>
                                <td>
                                    <div style="font-weight:bold;  padding-left:2px;">{{form.user_name}}
                                    <span style="font-weight: normal;" v-if="form.descbuyer != ''">
                                        {{this.form.sellerName}} 
                                    </span>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 30mm; vertical-align: top;">
                                    <div style=" padding-left:2px; ">Договір:</div>
                                </td>
                                    <td>
                                    <div style="padding-left:2px;"> 
                                        <template v-if="form.date >= '2023-11-28'">
                                            № {{ this.form.num.id }} {{ this.toDate() }}
                                        </template>
                                        <template v-else >
                                            № {{ this.form.provider == 'TOV' ? this.form.numTOV.id : this.form.numFOP.id }} {{ this.toDate() }}
                                        </template>
                                        </div>
                                    </td>
                            </tr>
                            <tr>
                                <td style="width: 30mm; vertical-align: top;">
                                    <div style=" padding-left:2px; ">Розр. док.: </div>
                                </td>
                                <td>
                                    <div style="padding-left:2px;">Рахунок на оплату покупцю № {{ this.form.provider == 'TOV' ? Number(this.form.numTOV.id) + 1 : Number(this.form.numFOP.id) + 1 }} {{ this.toDate() }}</div>
                                </td>
                            </tr>
                        </table>
                </div>
                <br />
                <div>
                    <table border="2" width="100%" cellpadding="2" cellspacing="2" style="border-collapse: collapse; width: 100%; font-family: Arial;">
                        <thead style="border: 1px solid;">
                        <tr class="head-table">
                            <th style="border: 1px solid;">№</th>
                            <th style="border: 1px solid;">{{ $t('Goods') }}</th>
                            <th style="border: 1px solid;">{{ $t('Quantity') }}</th>
                            <th style="border: 1px solid;">{{ $t('unit') }}</th>
                            
                                <template v-if="form.date >= '2023-11-28'">
                                    <th style="border: 1px solid;">{{ provider.businessEntity == 'TOV' && provider.workWithPDV == 'true' ? $t('PriceWithoutVAT') : $t('priceprod') }}</th>
                                    <th style="border: 1px solid;">{{ provider.businessEntity == 'TOV' && provider.workWithPDV == 'true' ?  $t('AmountWithoutVAT') : $t('Sum') }}</th>
                                    <th style="border: 1px solid;" v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">{{ $t('VATamount') }}:</th>
                                </template>
                                <template v-else>
                                    <th style="border: 1px solid;">{{ form.provider == 'TOV' && provider ? $t('PriceWithoutVAT') : $t('priceprod') }}</th>
                                    <th style="border: 1px solid;">{{ form.provider == 'TOV' ?  $t('AmountWithoutVAT') : $t('Sum')}}</th>
                                    <th style="border: 1px solid;" v-if="form.provider == 'TOV'">{{ $t('VATamount') }}:</th>
                                </template>

                            <!-- <th style="border: 1px solid;">{{ form.provider == 'tov' ? $t('PriceWithoutVAT') : $t('priceprod') }}</th>
                            <th style="border: 1px solid;">{{ form.provider == 'tov' ? $t('AmountWithoutVAT') : $t('Sum') }}</th>
                            <th style="border: 1px solid;" v-if="form.provider == 'tov'">{{ $t('VATamount') }}</th> -->
                        </tr>
                        </thead>
                        <tbody >
                                <tr v-for="(item, index) in form.products" :key="index">
                                    <th class="text-center" style="border: 1px solid;">{{index+1}}</th>
                                    <td style="border: 1px solid;">{{item.nomenclatureName}}</td>
                                    <td class="text-center" style="border: 1px solid;">{{item.quantity}}</td>
                                    <td class="text-center" style="border: 1px solid;">шт.</td>
                                    <td class="text-center" style="border: 1px solid;">{{item.priceWithoutVAT.toFixed(2)}}</td>
                                    <td class="text-center" style="border: 1px solid;">{{item.sumWithoutVAT}}</td>

                                    <template v-if="form.date >= '2023-11-28'">
                                        <td class="text-center" style="border: 1px solid;" v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">{{String(Number(item.VATsum).toFixed(2))}}</td>
                                    </template>
                                    <template v-else>
                                        <td class="text-center" style="border: 1px solid;" v-if="form.provider == 'TOV'">{{String(Number(item.VATsum).toFixed(2))}}</td>
                                    </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <div>
                    <table style="font-family: Arial;" border="0" width="100%" cellpadding="1" cellspacing="1">
                            <template v-if="form.date >= '2023-11-28'">
                                <tr v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">
                                    <td></td>
                                    <td style="width:27mm; font-weight:bold;  text-align:right;">{{ $t('TotalwithoutVAT') }}:</td>
                                    <td style="width:27mm; font-weight:bold;  text-align: center; ">{{ String(this.totalWithoutVat()) }} грн.</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr v-if="form.provider == 'TOV'">
                                    <td></td>
                                    <td style="width:27mm; font-weight:bold;  text-align:right;">{{ $t('TotalwithoutVAT') }}:</td>
                                    <td style="width:27mm; font-weight:bold;  text-align: center; ">{{ String(this.totalWithoutVat()) }} грн.</td>
                                </tr>
                            </template>


                            <template v-if="form.date >= '2023-11-28'">
                                <tr v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">
                                    <td></td>
                                    <td style="width:27mm; font-weight:bold;  text-align:right;">{{ $t('TotalVat') }}:</td>
                                    <td style="width:27mm; font-weight:bold;  text-align: center; ">{{String(this.totalVat())}}</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr v-if="form.provider == 'TOV'">
                                    <td></td>
                                    <td style="width:27mm; font-weight:bold;  text-align:right;">{{ $t('TotalVat') }}:</td>
                                    <td style="width:27mm; font-weight:bold;  text-align: center; ">{{String(this.totalVat())}}</td>
                                </tr>
                            </template>

                            <template v-if="form.date >= '2023-11-28'">
                                <tr v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">
                                    <td></td>
                                    <td style="width:27mm; font-weight:bold;  text-align:right;">{{ $t('TotalVat') }}:</td>
                                    <td style="width:27mm; font-weight:bold;  text-align: center; ">{{String(this.totalVat())}}</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr v-if="form.provider == 'TOV'">
                                    <td></td>
                                    <td style="width:27mm; font-weight:bold;  text-align:right;">{{ $t('TotalVat') }}:</td>
                                    <td style="width:27mm; font-weight:bold;  text-align: center; ">{{String(this.totalVat())}}</td>
                                </tr>
                            </template>
                            <tr>
                                <td></td>
                                <td style="width:37mm; font-weight:bold;  text-align:right;">{{ this.$t('Total') }}:</td>
                                <td style="width:27mm; font-weight:bold;  text-align: center; ">{{String(this.totalInv()).indexOf('.') != -1 ? String(this.totalInv()) : String(this.totalInv()) + '.00'}} грн.</td>
                            </tr>
                    </table>
                </div>
                <br />
                    <div style="font-family: Arial;">
                        Всього найменувань {{form.products.length}}, на суму {{String(this.totalInv())}} грн.<br />
                        <label class="sum">{{String(number_to_string(this.totalInv()))}}</label>
                        <br />
                        <template v-if="form.date >= '2023-11-28'">
                            <b v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">У т.ч. ПДВ: <label class="sum">{{String(number_to_string(this.totalVat()))}}</label></b>
                        </template>
                        <template v-else>
                            <b v-if="form.provider == 'tov'">У т.ч. ПДВ: <label class="sum">{{String(number_to_string(this.totalVat()))}}</label></b>
                        </template>
                    </div>
                    <div style="border-bottom: 2px solid; width:100%;">&nbsp;</div><br />
                    <div style="display: flex;">
                        <div style="font-family: Arial;width: 100%;margin-right: 60px;" >
                            <b>Від постачальника*</b><br />
                            <label style="border-bottom: 1px solid; width:100%;"><span>Директор</span><span style="float:right">{{ this.form.providerBusinessEntity == "FOP" ? "ДУБОВИК М.В." : "ПАВЛЮК В.І."}} </span></label><br />
                            <label>* Відповідальний за здійснення господарської операції і правильність її оформлення</label>
                        </div>
                        <div style="width: 100%;">
                            <b>Від покупця</b><br />
                            <label style="border-bottom: 1px solid;width: 100%;">&nbsp;</label><br />
                            <label>За довіреністю &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; № &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; від</label>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import { storeS } from '@/store'

export default ({
   props: ['obj'],
   data() {
       return{
           form: "",
           m:"8",
           provider: ""
       }
   },
   created(){
       this.form = this.obj
       this.getDataProvider()
       console.log(this)
   },
   methods:{
       toDate(){
           var newFullDate = new Date(this.form.datetime);
           var newYear = newFullDate.getFullYear();
           var newMonth = newFullDate.getMonth();
           var newDate = newFullDate.getDate();
           var result = "від " + newDate + " " + this.getdata(newMonth) + " " + newYear + " р.";
           return result
       },
       getdata(m){
           var arr=[
           'січня',
           'лютого',
           'березня',
           'квітня',
           'травня',
           'червня',
           'липня',
           'серпня',
           'вересня',
           'жовтня',
           'листопада',
           'грудня',
           ];
           return arr[m]
       },
       number_to_string(num){
           var def_translite = {
               null: 'нуль',
               a1: ['один','дві','три','чотири',"п'ять",'шість','сім','вісім',"дев'ять"],
               a2: ['одна','дві','три','чотири',"п'ять",'шість','сім','вісім',"дев'ять"],
               a10: ['десять','одинадцять','дванадцять','тринадцять','чотирнадцять',"п'ятнадцять",'шістнадцять','сімнадцять','вісімнадцять',"дев'ятнадцять"],
               a20: ['двадцять','тридцять','сорок',"п'ятдесят",'шістдесят','сімдесят','вісімдесят',"дев'яносто"],
               a100: ['сто','двісті','триста','чотириста',"п'ятсот",'шістсот','сімсот','вісімсот',"дев'ятсот"],
               uc: ['копійка', 'копійки', 'копійок'],
               ur: ['гривня', 'гривні', 'гривень'],
               u3: ['тисяча', 'тисячі', 'тисяч'],
               u2: ['мільйон', 'мільйона', 'мільйонів'],
               u1: ['мільярд', 'мільярда', 'мільярдів'],
           }
           var i1, i2, i3, kop, out, uah, v, zeros, _ref, _ref1, _ref2, ax;
           _ref = parseFloat(num).toFixed(2).split('.'), uah = _ref[0], kop = _ref[1];
           var leading_zeros = 12 - uah.length;
           if (leading_zeros < 0) {
               return false;
           }
           zeros = [];
           while (leading_zeros--) {
               zeros.push('0');
           }

           uah = zeros.join('') + uah;
           out = [];
           if (uah > 0) {
               // Разбиваем число по три символа
               _ref1 = this.str_split(uah, 3);
               for (var i = -1; i < _ref1.length;i++) {
                   v = _ref1[i];
                   if (!(v > 0)) continue;
                   _ref2 = this.str_split(v, 1), i1 = parseInt(_ref2[0]), i2 = parseInt(_ref2[1]), i3 = parseInt(_ref2[2]);
                   out.push(def_translite.a100[i1-1]); // 1xx-9xx
                   ax = (i+1 == 3) ? 'a2' : 'a1';
                   if (i2 > 1) {
                       out.push(def_translite.a20[i2-2] + (i3 > 0 ?  ' ' + def_translite[ax][i3-1] : '')); // 20-99
                   } else {
                       out.push(i2 > 0 ? def_translite.a10[i3] : def_translite[ax][i3-1]); // 10-19 | 1-9
                   }
                   
                   if (_ref1.length > i+1){
                       var name = def_translite['u'+(i+1)]; 
                       out.push(this.morph(v,name));
                   }
               }
           } else {
               out.push(def_translite.null);
           }
           // Дописываем название "грн"
           out.push(this.morph(uah, def_translite.ur));
           // Дописываем название "копейка"
           out.push(kop + ' ' + this.morph(kop, def_translite.uc));
           // Объединяем маcсив в строку, удаляем лишние пробелы и возвращаем результат
           return out.join(' ').replace(RegExp(' {2,}', 'g'), ' ').trimLeft();
       },
       morph(number, titles){
           var cases = [2, 0, 1, 1, 1, 2];
           return titles[(number%100>4 && number%100<20) ? 2 : cases[Math.min(number%10, 5)]]
       },
       str_split(string, length){
           var chunks, len, pos;
   
           string = (string == null) ? "" : string;
           length =  (length == null) ? 1 : length;
           
           chunks = [];
           pos = 0;
           len = string.length;
           while (pos < len) {
               chunks.push(string.slice(pos, pos += length));
           }
           
           return chunks;
       },
       totalWithoutVat() {
            let sum = 0
            this.form.products.forEach(el => {
                sum += Number(el.sumWithoutVAT)
            })

            return sum.toFixed(2)
       },
        totalVat(){
            let sum = 0
            this.form.products.forEach(el => {
                sum += Number(el.VATsum)
            })

            return sum.toFixed(2)
        },
        totalInv(){
            let sum = 0
            this.form.products.forEach(el => {
                sum += (Number(el.VATsum) + Number(el.sumWithoutVAT))
            })
            sum = sum
            return sum.toFixed(2)
        },
        getDataProvider(){
            this.$nextTick(() => {
                this.providers.forEach(element => {
                    if (element.providerId == this.form.providerId) {
                        this.provider = element
                    }
                });
            });
        }
    },
    computed: {
        settingInv(){
            return storeS.invoice
        },
        providers() {
            return storeS.providers
        }
    }
})
</script>

<style scoped>
span, p, label {
    font-size: 16px !important
}
#printSalInv {
   width: 878px;
   margin: 0 auto;
   font-size: 17px !important;
}
.head-table th {
   text-align: center;
   background: #b9b9b96b;
}
label.sum{
   font-weight: bold;
   /* text-transform: lowercase; */
   margin-bottom: 0px;
}
label.sum::first-letter{
   /* text-transform: capitalize; */
}
</style>