<template>
    <!-- 
        Модальне вікно з запитом відкриття зміни
    -->
    
    <dialogmodal>
        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            <div class="mt-4 text-center">
                <h3><b>{{ $t('OpenShift') }}?</b></h3>
            </div>

            <!-- Вказування причини запізнення -->
            <div class="mb-3 mt-4" v-if="this.lateness.status == true">
                <label class="form-label fw-semibold">{{ $t('ReasonBeingLate') }}</label> {{ time }}
                <textarea class="form-control" rows="3" v-model="lateness.lateComment" :placeholder="$t('enterReasonBeingLate')"></textarea>
            </div>
        </template>

        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 justify-content-center remove">
                <!-- Кнопка "Так", закриває зміну -->
                <button class="btn btn-success" @click="openShift">{{ $t('y') }}</button>
            </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue' // компонент модального вікна
import { storeS } from '@/store' // глобальне сховище даних

export default {
    props: ['obj'],
    components: {
        dialogmodal
    },
    data(){
        return{
            lateness: {
                status: false, // статус
                lateComment: "", // причина запізнення
            },
            time: ""
        }
    },
    created(){
        this.form = this.obj
        this.checkTimeOpenShift();
    },
    methods: {
        checkTimeOpenShift(){
            // Перевірка чи запізнився працівник
            var date = new Date();
            var dateStr =
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2);
            this.lateness.status = dateStr > this.user.workerOpenShift ? true : false;
        },
        openShift(){
            this.$emit('openShift', this.lateness);
        }
    },
    mounted(){
        this.eventKey.on('enter', () => {
            this.$emit('close');
        })
    },
    computed: {
        user(){
            /*
                Повертає дані користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
}
</script>