<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>
            <div class="mb-3">
            </div>
            <div class="mb-3">
                <label class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>
            <div class="form-check" v-if="this.client.monitor === true">
                <input class="form-check-input" type="checkbox" v-model="this.commentInMonitoring" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    {{ this.$t("copyInMonitoring") }}
                </label>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium"  @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="toClose"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import "flatpickr/dist/flatpickr.min.css";
import Vueflatpickr from "vue-flatpickr-component";

export default{
    components: {
        dialogBox,
        flatpickr: Vueflatpickr
    },
    props: ['id'],
    data(){
        return{
            form: {
                comment: '',
            },
        }
    },
    created() {
    },
    methods: {
        toClose(){
            if(this.form.comment == '') {
                this.$toast.error(this.$t('enterComment'))
            } else {
                this.$emit('toClose', this.id, this.form)
                this.$emit('close')
            }
        }
    }
}
</script>