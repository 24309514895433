<template>
    <modal :title="$t('Product')" :stylebody="'var(--vz-body-bg)'">
        <template v-slot:head-bottom>
            <div class="" ><button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button></div>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col
                    lg="9"
                    md="6"    
                >
                    <b-card>
                        <div class="mb-3">
                            <h5 class="kb-title">
                                <b>{{ $t('productTitle') }}</b>
                            </h5>
                            <input
                                type="text"
                                class="form-control"
                                id="project-title-input"
                                :placeholder="$t('productTitle')"
                                v-model="form.title"
                            />
                        </div>
                        <div class="mb-3">
                            <label class="form-label">{{ $t('productDescription') }}</label>
                            <QuillEditor v-model:content="form.description" theme="snow" contentType="html" toolbar="full"/>
                        </div>
                    </b-card>
                    <b-tabs pills>
                        <b-tab :title="$t('main')" active>
                            <div style="margin:20px 0px;">
                                <b-card>
                                    <div class="card-body">
                                        <div class="mb-3">
                                            <label class="form-label" for="manufacturer-name-input"
                                                >{{ $t('ManufacturerName') }}</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="manufacturer-name-input"
                                                v-model="form.manufname"
                                                :placeholder="$t('EnterManufacturerName')"
                                            />
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label" for="manufacturer-brand-input"
                                                    >{{ $t('ManufacturerBrand') }}</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="manufacturer-brand-input"
                                                    v-model="form.manufbrand"
                                                    :placeholder="$t('EnterManufacturerBrand')"
                                                />
                                            </div>
                                            </div>
                                            <!-- end col -->
                                            <div class="col-lg-6">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                <div class="mb-3">
                                                    <label class="form-label" for="product-price-input"
                                                    >{{ $t('price') }}</label
                                                    >
                                                    <div class="input-group mb-3">
                                                    <span
                                                        class="input-group-text"
                                                        id="product-price-addon"
                                                        >₴</span
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="product-price-input"
                                                        :placeholder="$t('enterPrice')"
                                                        v-model="form.price"
                                                        aria-label="Price"
                                                        aria-describedby="product-price-addon"
                                                    />
                                                    </div>
                                                </div>
                                                </div>
                                                <div class="col-sm-6">
                                                <div class="mb-3">
                                                    <label
                                                    class="form-label"
                                                    for="product-discount-input"
                                                    >{{ $t('Discount') }}</label
                                                    >
                                                    <div class="input-group mb-3">
                                                    <span
                                                        class="input-group-text"
                                                        id="product-discount-addon"
                                                        >%</span
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="product-discount-input"
                                                        :placeholder="$t('EnterDiscount')"
                                                        v-model="form.discont"
                                                        aria-label="discount"
                                                        aria-describedby="product-discount-addon"
                                                    />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                            <!-- end col -->
                                        </div>
                                    </div>
                                </b-card>
                                <b-card>
                                    <div class="card-header">
                                    <h5 class="card-title mb-0">{{ $t('productGallery') }}</h5>
                                    </div>
                                    <div class="card-body">
                                    <div class="mb-4">
                                        <h5 class="fs-14 mb-1">{{ $t('productImage') }}</h5>
                                        <p class="text-muted">{{ $t('addMainImage') }}</p>
                                        <input
                                        class="form-control"
                                        id="product-image-input"
                                        type="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        />
                                    </div>
                                    <div class="vstack gap-2">
                                        <h5 class="fs-14 mb-1">{{ $t('productGallery') }}</h5>
                                        <p class="text-muted">{{ $t('addGalleryImage') }}</p>
                                        <DropZone @drop.prevent="drop" @change="selectedFile" />

                                        <div
                                        class="border rounded"
                                        v-for="(file, index) of files"
                                        :key="index"
                                        >
                                        <div class="d-flex align-items-center p-2">
                                            <div class="flex-grow-1">
                                            <div class="pt-1">
                                                <h5 class="fs-14 mb-1" data-dz-name="">
                                                {{ file.name }}
                                                </h5>
                                                <p class="fs-13 text-muted mb-0" data-dz-size="">
                                                <strong>{{ file.size / 1024 }}</strong> KB
                                                </p>
                                                <strong
                                                class="error text-danger"
                                                data-dz-errormessage=""
                                                ></strong>
                                            </div>
                                            </div>
                                            <div class="flex-shrink-0 ms-3">
                                            <button
                                                data-dz-remove=""
                                                class="btn btn-sm btn-danger"
                                                @click="deleteRecord"
                                            >
                                                Delete
                                            </button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </b-card>
                            </div>
                        </b-tab>
                        <b-tab :title="$t('attrib')"  >
                            <div style="margin:20px auto">
                                <b-card>
                                    <h5 class="kb-title">
                                        <b>{{ $t('Characteristics') }}</b>
                                    </h5>
                                    <ul class="list-unstyled vstack gap-3 mb-0 mt-3">
                                        <li v-for="(item, index) in form.characteristics" :key="index" class="border-bottom">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0">
                                                    <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-success text-black">#{{index}}</div></div>
                                                </div>
                                                <div class="flex-grow-1 ms-2">
                                                    <div class="row gy-3 gx-3 mb-3 align-items-center">
                                                        <div class="col-xl-5 col-sm-auto">
                                                            <label class="visually" for="autoSizingSelect"><b>{{$t('attrib')}}</b></label>
                                                            <Multiselect
                                                                class="w-xl"
                                                                v-model="item.label"
                                                                :close-on-select="true"
                                                                :searchable="true"
                                                                :create-option="true"
                                                                :options="attr"
                                                                />
                                                        </div>
                                                        <div class="col-xl-5 col-sm-auto">
                                                            <label class="visually" for="autoSizingInputGroup"><b>{{$t('desc')}}</b></label>
                                                            <!-- <input type="text" class="form-control" id="autoSizingInput" v-model="item.value"> -->
                                                            <Multiselect
                                                                class="w-xl"
                                                                v-model="item.value"
                                                                mode="tags"
                                                                :close-on-select="false"
                                                                :searchable="true"
                                                                :create-option="true"
                                                            />
                                                        </div>
                                                        <div class="col-sm-auto hstack gap-sm-1 gap-1 align-items-center flex-wrap email-topbar-link" style="margin-top:43px">
                                                            <button @click="add(index)" class="btn btn-success" type="button"><i class="ri-add-line"></i></button>
                                                            <button @click="remove(index)" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </b-card>
                            </div>
                            
                        </b-tab>
                        
                    </b-tabs>
                    
                    
                </b-col>
                
                <b-col
                    lg="3"
                    md="6"
                >
                   
                    <b-card>
                        <h5 class="kb-title">
                            <b>{{ $t('linkToProduct') }}</b>
                        </h5>
                        <input type="text" class="form-control" v-model="form.url">
                    </b-card>
                    <!-- <b-card>
                        <div class="card-header">
                            <h5 class="card-title mb-0">{{$t('Publish')}}</h5>
                        </div>
                        <div class="card-body">
                            <div class="mb-3">
                                <label for="choices-privacy-status-input" class="form-label"
                                    >{{$t('Status')}}</label
                                >
                                <Multiselect
                                    v-model="form.status"
                                    :close-on-select="true"
                                    :searchable="true"
                                    :create-option="true"
                                    :options="[
                                        { value: 'Published', label: this.$t('Published') },
                                        { value: 'Draft', label: this.$t('Draft') },
                                    ]"
                                />
                            </div>
                            <div>
                                <label for="choices-publish-visibility-input" class="form-label"
                                    >{{ $t('Visibility') }}</label
                                >
                                <Multiselect
                                    class="form-control"
                                    v-model="form.visibility"
                                    :close-on-select="true"
                                    :searchable="true"
                                    :create-option="true"
                                    :options="[
                                    { value: 'Public', label: this.$t('public') },
                                    { value: 'Hidden', label: this.$t('Hidden') },
                                    ]"
                                />
                            </div>
                        </div>
                    </b-card> -->
                    <b-card>
                            <div class="card-header">
                                <h5 class="card-title mb-0">{{$t('Provider')}}</h5>
                            </div>
                            <div class="card-body">
                                <p class="text-muted mb-2">
                                    <a @click="createdProvider()" class="link float-end text-decoration-underline">{{ $t('Add') }}</a
                                    >{{ $t('SelectProvider') }}
                                </p>
                                <Multiselect
                                class="form-control"
                                v-model="form.provider"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="providers"
                                />
                            </div>
                    </b-card>
                    <b-card>
                            <div class="card-header">
                                <h5 class="card-title mb-0">{{$t('ProductCategories')}}</h5>
                            </div>
                            <div class="card-body">
                                <p class="text-muted mb-2">
                                    <a @click="createdCategory()" class="link float-end text-decoration-underline">{{ $t('Add') }}</a
                                    >{{ $t('SelectCategory') }}
                                </p>
                                <Multiselect
                                class="form-control"
                                v-model="form.category"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="categories"
                                />
                            </div>
                    </b-card>
                    <b-card>
                            <div class="card-header">
                                <h5 class="card-title mb-0">{{$t('ProductTags')}}</h5>
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <Multiselect
                                        v-model="form.tags"
                                        mode="tags"
                                        :close-on-select="false"
                                        :searchable="true"
                                        :create-option="true"
                                        :placeholder="$t('tags')"
                                    />
                                </div>
                            </div>
                    </b-card>
                    <b-card>
                            <div class="card-header">
                                <h5 class="card-title mb-0">{{$t('ProductShortDescription')}}</h5>
                            </div>
                            <div class="card-body">
                                <p class="text-muted mb-2">{{ $t('AddProductShortDescription') }}</p>
                                <textarea
                                    class="form-control"
                                    rows="3"
                                    v-model="form.shortdesc"
                                ></textarea>
                            </div>
                    </b-card>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
           <button type="button" v-on:click="addproduct" class="btn btn-success">{{ $t('Add') }}</button>
           <!-- <button type="button" @click="edit" class="btn btn-success">{{ $t('save') }}</button> -->
        </template>
    </modal>
    <createdCategory v-if="modalCreatedCategoryBox == true" @close="modalCreatedCategoryBox = false" />
    <createdProvider v-if="modalCreatedProviderBox == true" @close="modalCreatedProviderBox = false" />
</template>

<script>
import { ref, watch } from "vue";
// import dialogBox from '@/components/modal/dialog'
import modal from '@/components/modal'
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import DropZone from "@/components/widgets/dropZone";
import useVuelidate from "@vuelidate/core";
import createdCategory from './categories/create'
import createdProvider from './providers/create'
import { Products, ProvidersProducts, CategoriesProducts, Attributes } from '@/API.js';
import { storeS } from '@/store.js'

let apiServe = new Products();
let apiProviders = new ProvidersProducts();
let apiCategories = new CategoriesProducts();
let apiAttributes = new Attributes();

export default {
    components: { ckeditor: CKEditor.component, modal, Multiselect, DropZone, createdCategory, createdProvider},
    props: ['obj','id_provider'],
    data() {
        return {
            // modalDialog: false,
            form:{
                id:"",
                title: "HPRT - TP80BE",
                description: "Длинное описание",
                characteristics: [
                    {
                        label: "",
                        value: []
                    }
                ],
                shortdesc: "Короткое описание",
                manufname: "HPRT",
                manufbrand: "HPRT",
                price: "4015",
                discont: "0",
                status: "Draft",
                visibility: "Hidden",
                category: "1",
                create_user: "",
                create_date_time: "",
                provider: "",
                url: "",
                tags:[]
            },
            modalCreatedCategoryBox: false,
            modalCreatedProviderBox: false,
            providers: [],
            categories: [],
            attr: [],
            editor: ClassicEditor,
        };
    },
    created(){
        this.getProviders();
        this.getCategories();
        this.getAttr();
        this.form.provider = this.id_provider
    },
    methods: {
        addproduct() {
            // if (!this.form.nomenclatureName) {
            //     //перевіряємо чи вказаний додаток
            //     this.$toast.error(this.$t('EnterAppName'));
            // }
            // if (!this.form.comment) {
            //     //перевіряємо чи вказаний комент
            //     this.$toast.error(this.$t('EnterDesc'));
            // }
            // if (!this.form.userPhone) {
            //     //перевіряємо чи вказаний номер
            //     this.$toast.error(this.$t('EnterNumberC'));
            // }
            // if (!this.form.priority) {
            //     //перевіряємо чи вказаний пріоритет
            //     this.$toast.error(this.$t('EnterPriority'));
            // }
            // if(this.form.name && this.form.categoryType && this.form.date && this.form.description){
                apiServe.addProduct(this.form).then(res => {
                    if(res){
                        this.$toast.success(this.$t('сreatedT'));
                        this.eventBus.emit('saveProduct', true)
                        this.$emit('close')
                    }
                })
            // }
        },
        createdProvider(){
            this.modalCreatedProviderBox = true
        },
        getProviders(){
            apiProviders.getAllProviders().then(res => {
                if(res){
                    this.providers = res;
                    for(var item in this.providers){
                        this.providers[item].value = this.providers[item].label
                    }
                }
            })
        },
        createdCategory(){
            this.modalCreatedCategoryBox = true
        },
        getCategories(){
            apiCategories.getAllCategories().then(res => {
                if(res){
                    this.categories = res;
                    for(var item in this.categories){
                        this.categories[item].label = this.categories[item].title
                        this.categories[item].value = this.categories[item].title
                    }
                }
            })
        },
        getAttr(){
            apiAttributes.getAllAttrs().then(res => {
                if(res){
                    this.attr = res;
                    for(var item in this.attr){
                        this.attr[item].label = this.attr[item].title
                        this.attr[item].value = this.attr[item].title
                    }
                }
            })
        },
        add() {
            this.form.characteristics.push({
                label: '',
                value: [],
            })
        },
        remove (index) {
            this.form.characteristics.splice(index, 1)
        },
        // edit: function(){

        //     ("this", this.obj)
        // },
        // getCategory(e){
        //     apiServe.getCateg(e).then(res => {
        //         if(res) {
        //             this.category = res;
        //         }
        //     })
        // },
        
        currentDate() {
            var date = new Date();
            var dateStr =
            ("00" + date.getMonth()).slice(-2) + "." +
            ("00" + (date.getDate() + 1)).slice(-2) + "." +
            date.getFullYear() + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
            return dateStr
        }
    },
    setup() {
            let files = ref([]);
            let dropzoneFile = ref("");
            const drop = (e) => {
            dropzoneFile.value = e.dataTransfer.files[0];
            files.value.push(dropzoneFile.value);
            };
            const selectedFile = () => {
            dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
            files.value.push(dropzoneFile.value);
            };
            watch(
            () => [...files.value],
            (currentValue) => {
                return currentValue;
            }
            );
            return { dropzoneFile, files, drop, selectedFile, v$: useVuelidate() };
        },
    mounted(){
        this.eventBus.on('saveProductsCateg', (status) => {
            if(status == true) {
                this.getCategories();
            }
        }),
        this.eventBus.on('saveProvider', (status) => {
            if(status == true) {
                this.getProviders();
            }
        })
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
}
</script>

<style scoped>
.link {
    cursor: pointer;
}
</style>