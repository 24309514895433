<template>
    <div class="">
        <b-tabs 
            pills 
            content-class="mt-3"
        >
            <b-tab :title="$t('Comments')" active>
                <div style="padding: 20px 0px;margin: 10px auto;border-radius: 7px;" >
                    <comments v-if="form.comments" :obj="this.obj.comments" :sklad="this.obj.license" :number="this.obj.user_phone" :name="this.obj.user_name"/>
                </div>
            </b-tab>
            <b-tab :title="$t('Payments')" @click="getPays()">
                <div style="padding: 20px 0px;margin: 10px auto;border-radius: 7px;" >
                    <template v-if="isOpenPay == true" ><payments :obj="this.pay" :license="this.obj.license" /></template>
                </div>
            </b-tab>
            <b-tab :title="$t('Employees')">
                <div style="margin:20px 0px;">
                    <workers v-if="this.obj.users" :obj="this.obj.users"  />
                    <div v-else class="alert alert-warning">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('Outlets')">
                <div style="margin:20px 0px;">
                    <outlets v-if="this.obj.license" :obj="this.obj.license.sklad" :number="this.obj.user_phone"/>
                    <div v-else class="alert alert-danger">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел null</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('devices')" v-if="this.obj.devices != null">
                <div style="margin:20px 0px;">
                    <devices v-if="this.obj.devices" :obj="this.obj.devices" :tt="this.obj.license.sklad"/>
                    <div v-else class="alert alert-ifno">
                        <p class="mb-0">
                            <span class="fw-semibold">С карточки прилетел Null :(</span>
                        </p>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('apps')" v-if="this.obj.apps != null">
                <div style="margin:20px 0px;"><apps :obj="this.obj.apps"  /></div>
            </b-tab>
            <b-tab :title="$t('referrals')" v-if="this.obj.referals != null">
                <div style="margin:20px 0px;"><referals :obj="this.obj.referals"/></div>
            </b-tab>
            <b-tab :title="$t('Story')" @click="isOpenStory = true">
                <template v-if="isOpenStory == true">
                    <div style="margin:20px 0px;">
                        <history @openTicket="openTicket" :company="this.obj" :number="this.obj.user_phone"/>
                    </div>
                </template>
            </b-tab>
            <b-tab v-if="this.perms['510'] == true" :title="$t('Services')" @click="isOpenServices = true">
                <template v-if="isOpenServices == true">
                    <div style="margin:20px 0px;">
                        <services :number="this.obj.user_phone"/>
                    </div>
                </template>
            </b-tab>
            <b-tab v-if="this.perms['1700'] == true" :title="$t('invoices')" @click="isOpenInvoices = true">
                <template v-if="isOpenInvoices == true">
                    <div style="margin:20px 0px;">
                        <invoices :number="this.obj.user_phone"/>
                    </div>
                </template>
            </b-tab>
            <b-tab :title="$t('settings_connects')" @click="isOpenConnects = true">
                <template v-if="isOpenConnects == true">
                    <div style="margin:20px 0px;">
                        <settings_connects :tt="this.obj.license.sklad" :number="this.obj.user_phone" />
                    </div>
                </template>
            </b-tab>
            <!-- нужно добавить историю todo dev -->
        </b-tabs>
    </div>
</template>

<script>
import comments from './comments/index'
import payments from './payments/index'
import workers from './workers/index'
import outlets from './outlets/index'
import devices from './devices/index'
import apps from './apps/index'
import referals from './referals/index'
import services from './services/index'
import invoices from './invoices/index'
import settings_connects from './connects/index'
import history from './history/index'
import { Clients } from "@/API.js";
import { storeS } from '@/store'

let apiServe = new Clients();

export default({
    props: ['obj'],
    components: {
        comments,
        payments,
        workers,
        outlets,
        devices,
        apps,
        referals,
        services,
        invoices,
        settings_connects,
        history
    },
    data() {
        return {
            form: '',
            pay: '',
            isOpenPay: false,
            isOpenServices: false,
            isOpenInvoices: false,
            isOpenConnects: false,
            isOpenStory: false
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj
        },
        getPays() {
            apiServe.getPayClient(this.obj.user_phone).then(res => {
                if(res){
                    this.pay = res.data
                    this.isOpenPay = true
                }
            })
        },
        openTicket(e) {
            this.$emit('openTicket', e)
        },
    },
    computed: {
        perms(){
            return storeS.perms
        },
        checks(){
            return storeS.checks
        }
    }
})
</script>

<style>
/* .nav{
    flex-wrap: nowrap;
    overflow: scroll;
} */
</style>