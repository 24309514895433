<template>
    <!-- Модальне вікно для поповнення/списання коштів балансу в ручному режимі -->
    <!-- Працює лише для нової адмінки (сервера s-2, s-3, s-4) -->
    <modal>
        <template v-slot:title>{{ $t('ToppingUptheAccountBalance') }}</template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <div>
                        <b-form-group :label="$t('type')">
                            <b-form-radio-group
                                v-model="selected"
                                :options="options"
                                :aria-describedby="ariaDescribedby"
                                name="radio-inline"
                            ></b-form-radio-group>
                        </b-form-group>
                    </div>
                    <div class="mb-3">
                        <h4 class="fs-16 fw-semibold">{{ $t('EnterTopupAmount') }}</h4>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="form.sumInv">
                            <span class="input-group-text">{{ contentData.currency }}</span>
                        </div>
                    </div>
                    <div class="mb-3" v-if="selected == 'debit'">
                        <h4 class="fs-16">{{ $t('Cause') }}</h4>
                        <input type="text" class="form-control" v-model="form.сause">
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom >
            <button type="button" class="btn btn-danger" v-on:click="$emit('close')">{{ $t('cancel') }}</button>
            <button type="button" class="btn btn-success" v-on:click="add">{{ $t('Add') }}</button>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import { storeS } from '@/store';

export default{
    props: ['obj', 'type'],
    components: {
        modal,
    },
    data(){
        return{
            contentData:'',
            form: {
                sumInv: '',
                сause: ''
            },
            selected: 'replen',
            options: [
                { text: this.$t('Replenishment'), value: 'replen' },
                { text: this.$t('WriteOff'), value: 'debit' }
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.contentData = this.obj.companyDataObj.license
            this.selected = this.type
        },
    }
}
</script>

<style>
.form-control[readonly] {
    background-color: var(--vz-input-bg)!important;
    opacity: 1;
}
</style>