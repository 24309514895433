<template>
    <!-- Додаткове інформування пм-а -->

    <dialogBox >
        <template v-slot:title>{{ $t('admin_help') }}</template>
        <template v-slot:body>
            <div class="mt-1 text-left">
                <h4 class="fs-15">{{ $t('helpBox_h') }}?</h4>
                <span class="mb-3 text-muted fs-12">{{ $t('helpBox_alert') }}</span>
                <textarea class="form-control mt-3" rows="3" v-model="commentHelp" :placeholder="$t('helpBox_example')"></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
                <button class="btn btn-success" @click="sendTg">{{ $t('Send') }}</button>
            </div>
        </template>
    </dialogBox>

</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { sendHelpTicketTelegram } from '@/usabilityScripts/logsTelegram.js'
import { nameWorker, tgAccWorker } from '@/usabilityScripts/getNameWorker'
import { storeS } from '@/store'

export default ({
    props: ['objCard'],
    components: {
        dialogBox
    },
    data() {
        return {
            commentHelp: ''
        }
    },
    created(){
        console.log("objCard", this.objCard);
        
    },
    methods: {
        sendTg(){
            // Надсилаємо оповіщення в тг

            var ticketdId = this.objCard.ticketId;
            var createWorkerId = this.nameWorker(this.user.userid);
            var tgAccCreateWorker = this.tgWorker(this.user.userid).tgNick;
            var ticketName = this.objCard.ticketName;
            var createComment = this.commentHelp

            // Викликаємо функцію для відправки повідомлення в телеграм
            sendHelpTicketTelegram(ticketName, ticketdId, createWorkerId, tgAccCreateWorker, createComment)
            .then(response => {
                console.log('Telegram API response:', response.data)
                this.$toast.success(this.$t('msgSent'))
                this.$emit('close')
            })
            .catch(error => console.error('Telegram API error:', error)); 
        },
        nameWorker(item){
            // мутейт імені співробітника
            return nameWorker(item)
        },
        tgWorker(item) {
            // отримання нікнейму співробітника
            return tgAccWorker(item);
        },
    },
    computed:{
        user(){
            return storeS.userbase
        }
    }
})
</script>
