<template>
    <!-- Карточка з класом "card" -->
    <div class="card">
        <!-- Тіло карточки з класом "card-body" та центруванням тексту -->
        <div class="card-body text-center">
        <!-- Заголовок карточки з класом "card-title" та лівим вирівнюванням тексту -->
        <h6 class="card-title mb-3 flex-grow-1 text-start">Time Tracking</h6>
        
        <!-- Блок з текстом, який відображається в залежності від умов -->
        <h3 class="mb-1 mb-4" v-if="this.days != '' && this.hours != ''">
            <!-- Відображає кількість днів та годин, якщо обидва значення не пусті -->
            {{ this.days + " " +  this.$t('sdays')  + " " + hours + " " + this.$t('shours') }}
        </h3>
        
        <h3 class="mb-1 mb-4" v-if="this.hours != '' && this.days == ''">
            <!-- Відображає кількість годин, якщо значення годин не пусте, а днів пусте -->
            {{ hours + " " + this.$t('shours') }}
        </h3>
        
        <h3 class="mb-1 mb-4" v-if="this.days == '' && this.hours == ''">
            <!-- Відображає текст "inWorkRecently", якщо обидва значення пусті -->
            {{ this.$t('inWorkRecently') }}
        </h3>
        </div>
    </div>
</template>

<script>
export default {
  // Властивості, які передаються з батьківського компонента
  props: ['startDate', 'endDate'],

  // Дані компонента
  data() {
    return {
      form: '',
      resulttime: 0,
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
    }
  },

  // Методи, які викликаються при створенні компонента
  created() {
    // Розрахунок часу, що минув від startDate до поточної дати
    this.days = String((new Date() - new Date(this.startDate)) / 1000 / 3600 / 24).split('.')[0] != '0' ? String((new Date() - new Date(this.startDate)) / 1000 / 3600 / 24).split('.')[0] : ''
    let hours = Number(String((new Date() - new Date(this.startDate)) / 1000 / 3600).split('.')[0]) - (Number(String((new Date() - new Date(this.startDate)) / 1000 / 3600 / 24).split('.')[0]) * 24)
    this.hours = hours != 0 ? hours : ''
  },
}
</script>