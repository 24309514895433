<template>
    <div class="dropdown" v-if="this.user.userid === this.form.teamlead || this.form.workers.find((el) => this.user.userid == el.workerId)">
        <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bx bx-dots-vertical-rounded align-bottom"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end">
            <button class="dropdown-item" v-if="(perms[354] == true ||  perms[371] == true || perms[2000]) && (this.user.userid === this.form.teamlead || this.form.workers.find((el) => this.user.userid == el.workerId))" @click="this.$emit('getUpdates')">{{ $t('editDeadline') }}</button>
            <button class="dropdown-item" v-if="perms[2000] || perms[354]" @click="this.$emit('edit', this.form)">{{ $t('toEditAp') }}</button>
            <button class="dropdown-item" v-if="perms[351] == true" @click="this.$emit('copy', this.form)">{{ $t('Duplicate') }}</button>
        </div>
    </div>
    <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
</template>

<script>
import { storeS } from '@/store';

export default {
props: ['obj'],
components:{},
data(){
    return {
        form: "",
    }
},
created(){
    this.getdata();
},
methods: {
    getdata(){
        this.form = this.obj
    },
},
computed:{
    perms(){
        return storeS.perms
    },
    user() {
        return storeS.userbase
    }
}
}
</script>