<template>
    <b-row>
        <b-col lg="9" md="12" xs="12">
            <workers :obj="form"/>
        </b-col>
    </b-row>
</template>

<script>
import workers from '../../../formright/teamticket'
export default{
    props: ['obj'],
    components: {
        workers
    },
    data(){
        return{
            form: {}
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.obj
        }
    }
}
</script>