<template>
    <!-- Компонент інфо-блоків, з підсумками по відпусткам та вихідним -->
    <div class="row">
        <!-- Розпочинається цикл для виведення інформаційних блоків -->
        <div 
            :class="`col-xl-${
                form.length == '2' 
                ? form.length + 4 : form.length == '3' 
                ? form.length + 1 : form.length == '4' 
                ? (form.length-1) : form.length == '5' 
                ? form.length - 2 : '6'} col-sm-12`
            " 
            v-for="(item, index) in reversedStatistics" 
            :key="index"
        >
            <!-- Карточка блоку -->
            <div 
                class="card card-animate" 
                :class="{'current-year': isCurrentYear(item.year)}" 
                style="border-radius: 8px;"
            >
                <div 
                    :class="`card-body bg-${isCurrentYear(item.year) ? 'info' : ''}`" 
                    style="border-radius: 8px;" 
                    :id="`infoStep`+index"
                >
                    <!-- Контент блоку з інформацією -->
                    <div class="d-flex align-items-end justify-content-between mt-3">
                        <!-- Умова наявності суми -->
                        <div>
                            <!-- Виведення суми, значення та відповідної позначки -->
                            <h4 :class="`fs-22 fw-semibold ff-secondary mb-4 text-${isCurrentYear(item.year) ? 'white' : 'black'}`">
                                Вихідних: <span class="counter-value">{{ item.totalDayOff }}</span> <br /> Відпусток: <span class="counter-value">{{ item.totalVacation }}</span>
                                <span v-if="isCurrentYear(item.year)" :class="`fs-16 text-${isCurrentYear(item.year) ? 'warning' : 'success'} fw-semibold`"> (залишилось: {{ remainingVacation(item.totalVacation) }})</span>
                            </h4>
                            <span class="badge bg-warning me-1">{{ item.totalDayOff + item.totalVacation }}</span> 
                            <span :class="`text-${isCurrentYear(item.year) ? 'white' : 'muted'}`">{{$t('Total')}}</span>
                        </div>
                        <!-- Виведення року -->
                        <p 
                            :class="`text-uppercase fw-medium fs-18 text-${isCurrentYear(item.year) ? 'white' : 'info'} mb-0`"
                        >
                            <b>{{ item.year }} рік</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    props: ['obj'],
    data() {
        return {
            form: "" // Змінна для зберігання даних інформаційних блоків
        }
    },
    created() {
        this.form = this.obj;
        console.log("form > ", this.form);
    },
    methods: {
        isCurrentYear(year) {
            // перевірка на поточний рік
            const currentYear = new Date().getFullYear();
            return year == currentYear;
        },
        remainingVacation(totalVacation) {
            // підрахунок скільки залишилось відпустки в цьому році
            const annualVacationAllowance = 24; 
            return annualVacationAllowance - totalVacation;
        }
    },
    computed: {
        reversedStatistics() {
            // сортування
            return Object.keys(this.form)
                .map(year => ({ year: parseInt(year), ...this.form[year] }))
                .sort((a, b) => b.year - a.year);
        }
    },
}

</script>

<style>
.statistics-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.statistics-block {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
    width: calc(50% - 10px); /* Two blocks per row with a 20px gap */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.statistics-block h3 {
    margin-top: 0;
}

.statistics-block p {
    margin: 5px 0;
}

.current-year {
    border-color: #007bff;
    background-color: #e7f3ff;
}
</style>
