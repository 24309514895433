<template>
    <modal :title="form.name" :stylebody="'var(--vz-body-bg)'">
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body >
             <b-row>
                 <b-col
                 lg="4">
                     <b-card>
                        <div class="card-body p-4 text-center">
                            <div class="mx-auto mb-3">
                                <div class="avatar-xl" style="margin: 0 auto;">
                                    <div class="avatar-title fs-22 rounded bg-soft-info fw-semibold text-dark">
                                        {{getInitials(form.name)}}
                                    </div>
                                </div>
                            </div>
                        <h5 class="card-title mb-1">{{form.name}}</h5>
                        <!-- <p class="text-muted mb-0">{{form.manager}}</p> -->
                        </div>
                        <div class="table-responsive">
                            <table class="table mb-0 table-borderless">
                            <tbody>
                                <!-- <tr>
                                    <th><span class="fw-medium">{{$t('Manager')}}</span></th>
                                    <td>{{form.manager}}</td>
                                </tr> -->
                                <tr>
                                    <th><span class="fw-medium">E-mail</span></th>
                                    <td @click="copy(form.email)" class="copy">{{form.email}}</td>
                                </tr>
                                <tr>
                                <th><span class="fw-medium">{{$t('site')}}</span></th>
                                <td>
                                    <a :href="form.website" target="_blank" class="link-primary"
                                    >{{form.website}}</a
                                    >
                                </td>
                                </tr>
                                <tr>
                                    <th><span class="fw-medium">{{$t('phone_number')}}</span></th>
                                    <td @click="copy(form.phone)" class="copy">{{form.phone}}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                     </b-card>
                     <b-card>
                        <div class="card-body p-4">
                            <h6 class="fw-semibold text-uppercase mb-3">{{ $t('site') }}</h6>
                            <div class="input-group  mb-3">
                                <input type="text" class="form-control" v-model="form.website" disabled>
                                <button class="btn btn-primary" type="button" @click="copy(form.website)"><i class="bx bxs-copy-alt"></i></button>
                                <a target="_blank" :href="form.website" class="btn btn-success" type="button"><i class="ri-external-link-line"></i></a>
                            </div>
                            <h6 class="fw-semibold text-uppercase mb-3">{{ $t('Telegram') }}</h6>
                            <div class="input-group  mb-3">
                                <input type="text" class="form-control" v-model="form.telegram" disabled>
                                <button class="btn btn-primary" type="button" @click="copy(form.telegram)"><i class="bx bxs-copy-alt"></i></button>
                                <a target="_blank" :href="form.telegram" class="btn btn-success" type="button"><i class="ri-external-link-line"></i></a>
                            </div>
                        </div>
                    </b-card>
                 </b-col>
                 <b-col
                  lg="8">
                     <!-- <b-card> -->
                        <tablecustom 
                            @search="searchB"  
                            :dataParams="dataParams"
                            :objParams="objParams"
                            :columns="columns" 
                            :rows="rows" 
                            :pages="objPages"
                            :showpag="true"
                            @create="create" 
                            @change="change" 
                            @open="open" 
                            @getdata="getProducts"
                            @changelimit="changelimit" 
                        />
                     <!-- </b-card> -->
                 </b-col>
             </b-row>
        </template>
        <template v-slot:footer-bottom>
            <button 
                type="button" 
                v-if="this.perms['1503'] == true"
                @click="dialogShow = true"
                class="btn btn-danger r waves-effect waves-light">
                {{ $t('Delete') }}
            </button>
            <button 
                type="button" 
                v-if="this.perms['1502'] == true"
                @click="edit"
                class="btn btn-warning r waves-effect waves-light">
                {{ $t('Edit') }}
            </button>
        </template>
    </modal>

    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove" 
    />

    <viewbox 
        v-if="showModal" 
        @close="showModal = false" 
        :objcard="obj"  
    ></viewbox>

    <!-- <created v-if="modalCreatedBox == true" @close="modalCreatedBox = false" :id_provider="form.id" /> -->
 </template>
 
 <script>
import modal from '@/components/modal'
import tablecustom from '@/components/globaltable/index';
import created from '../createproduct'
import viewbox from '@/components/shop/view'
import modalremove from '@/components/modal/modalremove'
import { Products, ProvidersProducts } from '@/API.js'
import { storeS } from '@/store.js'
import {copy} from '@/usabilityScripts/copy.js'
 
let apiServe = new Products();
let apiProvidersProducts = new ProvidersProducts();
 
export default ({
    data(){
        return {
            form: {},
            obj: "",
            products: "",
            search: "",
            modalCreatedBox: false,
            showModal: false,
            dialogShow: false,
            dataParams: {
                status: false,
                page: "products"
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                counterpartyId: ''
            },
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "nomenclatureId",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t("Product"),
                    text: "nomenclatureName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("price"),
                    text: "price",
                    align: "left",
                    status: true
                }
            ],
        }
    },
    props: ["objcard"],
    components: { modal, tablecustom, created, viewbox, modalremove},
    created(){
        this.getdata();
        this.getProducts();
    },
    methods: {
        getdata(){
            this.form = this.objcard
        },
        getProducts(page){
            this.objParams.page = page != undefined ? page : '1';
            this.objParams.counterpartyId = this.form.providerId;
            apiServe.getAllProducts(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.items
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` #1300`);
                }
            })
        },
        create: function(){
            this.modalCreatedBox = true
        },
        remove(){
            apiProvidersProducts.deleteProvide(this.form.providerId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Deleted'));
                    // this.$emit('getdata');
                    this.$emit('close');
                } else {
                    this.$toast.error(this.$t('err'));
                }
            })
        },
        edit() {
            this.$emit('edit', this.form)
            // this.$emit('close');
        },
        prevOpen(){
            this.$emit('close');
            this.$emit('prevOpen', this.form.id)
        },
        nextOpen(){
            this.$emit('close');
            this.$emit('nextOpen', this.form.id)
        },
        open: function(e){
            apiServe.getProduct(e.nomenclatureId).then(result => {
                if(result.status == 'done') {
                    this.obj = result.data;
                    this.showModal = true;
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        getInitials(string) {
            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        searchB(e){
            this.objParams.search = e
            this.getProducts();
        },
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getProducts();
        },
    },
    mounted() {
        // this.eventBus.on('saveProduct', (status) => {
        //     if(status == true) {
        //         this.getProducts();
        //     }
        // })
    },
    computed:{
        perms() {
            return storeS.perms
        },
        user() {
            return storeS.userbase
        },
        filteredItems: function() {
          return Object.values(this.products)
              // По полю поиска
              
              .filter(item => {
                  return this.search == '' || item.id.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.title.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ;
              })
      }, 
    }
})
 </script>
 
 <style>
 .badge {
     font-size: 12px;
 }
 .copy {
    cursor: pointer;
 }
 /* .avatar-title {
  font-size: 30px;
  font-weight: 600;
} */
/* .block-safety {
  list-style: none;
  border: 1px solid #09b39c;
  border-radius: 10px;
  padding: 13px;
  margin: 9px!important;
}
.block-as {
  margin: 20px auto;
}
.check-as {
  margin: 5px;
  margin-right: 20px;
}
.check-as .form-check-input {
  width: 1.6em;
  height: 1.6em;
} */
 </style>