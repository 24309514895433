<template>
    <b-card>
        <b-row>
            <h3><b>{{ $t('communicationWithClient') }}</b></h3>
            <b-col lg="6">
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-hashtag"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('salesSupport') }} :</p>
                            <h6 class="text-truncate mb-0">- (общее время потраченное на клиента) (Waiting API)</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bxs-phone"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('firstCall') }}:</p>
                            <h6 class="text-truncate mb-0">- (длительность первого звонка) (Waiting API)</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-mail-check-line"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('activ_client') }} :</p>
                            <h6 class="text-truncate mb-0">- (потраченное время на клиента до первой оплаты) (Waiting API)</h6>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bxs-user"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('payingCustomer') }} - {{ $t('serviceClient') }}:</p>
                            <h6 class="text-truncate mb-0">- (потраченное время на клиента во время обслуживания) (Waiting API)</h6>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bx-comment-dots"></i></div>
                        </div>
                        <div class="flex-grow-1" >
                            <p class="mb-1">{{ $t('technicalSupport') }}:</p>
                            <h6 class="mb-0">- (фиксация времени подключения по энидексу,  тиму) (Waiting API)</h6>
                        </div>
                    </div>
                </div>
                <!-- <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bx-comment-dots"></i></div>
                        </div>
                        <div class="flex-grow-1" >
                            <p class="mb-1">Техническая консультация по оборудованию:</p>
                            <h6 class="mb-0">- (Waiting API)</h6>
                        </div>
                    </div>
                </div> -->
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default({
    props: [],
    components: {},
    data() {
        return{
            form: ""
        }
    },
    created(){
        
    }
})
</script>
