<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Submit') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('ToWhomTransfer') }}</h4>
                <Multiselect 
                    v-model="toWorker"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="listWorkers"
                />
            </div>
            <!-- <div class="mb-3">
                <label class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div> -->
        </template>
        <template v-slot:footer-bottom>
            <!-- <button class="btn link-success fw-medium"  @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button> -->
            <button 
                type="button" 
                class="btn btn-success" 
                @click="toTransfer"
            >
                {{ $t('Submit') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store'

export default{
    components: {
        dialogBox,
        Multiselect
    },
    props: ['id'],
    data(){
        return{
            toWorker: '',
            listWorkers: [
                { label: 'Max', value: 34}
            ],
        }
    },
    created() {
        this.getWorkers();
    },
    methods: {
        toTransfer(){
            if(this.toWorker){
                this.$emit('toTransfer', this.id, this.toWorker)
                this.$emit('close')
            } else {
                this.$toast.error(this.$t('noSelectWorker'))
            }
        },
        getWorkers(){
            this.listWorkers = [];
            for(var item in this.workers){
                this.listWorkers.push({
                    label: this.workers[item].workerName,
                    value: this.workers[item].workerId
                })
            }
        }
    },
    computed: {
        workers(){
            return storeS.workers
        }
    }
}
</script>