<template>
  <!-- Компонент з полем пошуку -->

  <div class="search-box" style="width: 100%;">
      <div v-if="this.typesearch != 'reqt'">
        <input
          type="text"
          class="form-control search"
          :placeholder="$t('searc_inp')"
          v-model="search"
          v-on:keyup.enter="input(search)"
          @input="input(search)"
        />
        <i class="ri-search-line search-icon"></i>
      </div>
      
      <div class="input-group" v-else>
        <input
            type="text"
            class="form-control"
            :placeholder="$t('searc_inp')"
            v-model="this.search"
            v-on:keyup.enter="input(this.search)"
        />
        <button class="btn btn-primary" type="submit" @click="input(this.search)">
            <i class="mdi mdi-magnify"></i>
        </button>
      </div>
  </div>
</template>

<script>
import { storeS } from '@/store.js' // глобальне сховище даних

export default {
  props: ['typesearch'],
  data() {
    return {
      search: "" // Пошук
    }
  },
  components: {
  },
  mounted(){
    if(this.typesearch) {
      this.search = this.typesearch
    }
  },
  methods:{
    input(e){
      this.$emit('searchB', e)
    }
  },
  computed: {
    user(){
      /*
        Повертає дані за користувача з глобального стору.
      */
      return storeS.userbase
    }
  }
}
</script>