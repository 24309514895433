<template>
    <!-- 
        Модальне вікно підтвердження змінення прав доступу співробітника до закриття вікна головного
    -->
    
    <dialogmodal>
        <template v-slot:body>
            <div class="mt-4 text-center">
                <h3><b>{{ $t('SavetempPermsConf') }}?</b></h3>
                <p class="text-muted fs-15 mb-4">{{ $t('SavetempPermsConfDesc') }}</p>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 justify-content-center remove">
                <button class="btn link-danger fw-medium text-decoration-none" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</button>
                <button class="btn btn-success" @click="this.$emit('editUserPerms')">{{ $t('save') }}</button>
            </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue'

export default {
    props: ['obj'],
    components: {
        dialogmodal
    },
    data(){
        return{
            from: ""
        }
    },
    created(){
        this.form = this.obj
    },
}
</script>