<template>
  <!-- Компонент списку постів до категорії -->

  <!-- Перевірка чи існують пости -->
  <div v-if="options.length > 0">
    <div class="d-flex align-items-center">
      <span class="btn-soft-info btn-icon btn-sm fs-21 " style="margin-right: 13px;">
        <i :class="`${form.icon} align-bottom`"></i>
      </span>
      <!-- Назва та коментар категорії -->
      <div>
        <h4 class="mb-0 faq_header_text">
          {{ form.categoryName }}
        </h4>
        <span class="faq_header-sm">{{ form.comment }}</span>
      </div>
    </div> 

    <!-- Список питань категорії -->
    <div class="mb-4"> 
      <!-- Список питань у вигляді списку -->
      <ul class="list-group">

        <li 
          class="list-group-item copy" 
          @click="open(data)" 
          v-for="( data, index) in options" 
          :key="index" 
          aria-current="true"
        >
          {{ data.question }}
        </li>

      </ul>
    </div>
  </div>

  <!-- Модальне вікно для видалення поста -->
  <dialogDelete 
    v-if="showmodal === true" 
    :obj="this.objCard" 
    @close="showmodal = false" 
    @remove="remove" 
  />

</template>

<script>
import dialogDelete from '@/components/globaltable/actions/dialogdelete.vue' // компонент діалогового вікна видалення
import { FAQ } from '@/API.js' // клас для взаємодії з API 
import { storeS } from '../../store.js' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new FAQ()

export default {
  props: ['options', 'obj'],
  components: {
    dialogDelete
  },
  data() {
    return {
      showmodal: false,  // прапорець відображення вікна видалення
      form: "", // Дані для відображення категорії
      objCard: '', // Об'єкт для видалення
      answer: {  // Об'єкт для відображення відповіді
        show: false,
        data: {}
      }
    }
  },
  created() {
    this.form = this.obj
  },
  methods: {
    open(e) {
      // Відкриття питання та відповіді
      this.$emit('open', e)
    },
    edit(e) {
      // Редагування питання та відповіді
      this.$emit('edit', e)
    },
    delete(e) {
      // Підготовка до видалення питання (поста)
      this.objCard = e;
      this.showmodal = true //подготовка к делиту
    },
    remove(e) {
      /**
        * Видаляє поточний пост за допомогою API-запиту та виводить сповіщення про успішне видалення.
        * @param {number} postId - ідентифікатор поста.
      */

      apiServe.deleteLesson(e.postId).then(result => {
        if(result.status === 'done') {
          this.$toast.success(this.$t("Removed"));
          this.$emit('getData');
          this.showmodal = false;
        } else {
          this.$toast.error(this.$t('error') + " " + result.error[0]);
          this.$emit('close');

          var errorText = 'Помилка видалення поста'; 
          var statusRequest = result.status;
          var api = 'deleteLesson';
          var fileRequest = 'src/components/faq/list.vue';
          var params = e.postId;
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
  },
  
  computed: {
    user(){
      /*
        Повертає дані за користувача з глобального стору.
      */
      return storeS.userbase
    },
    perms() {
      /*
        Повертає дані прав доступу користувача з глобального стору.
      */
      return storeS.perms
    }
  }
}


</script>

<style scoped>
.faq_header_text {
    font-weight: 600;
}
.faq_header-sm {
    font-size: 13px;
    color: #9194a1;
}
</style>
