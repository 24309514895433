<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <div class="mb-2">
                    <span class="fs-14 badge bg-success copy mb-2" @click="form.comment = $t('ConfirmedL')" style="margin-right: 5px;">{{ $t('ConfirmedL') }}</span>
                </div>
                <label class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>
            <div class="form-check form-check-success mb-3">
                <input class="form-check-input" type="checkbox" id="formcheckdate" v-model="checkeddate">
                <label class="form-check-label" for="formcheckdate">{{ $t('doitLater') }}</label>
            </div>
            <div v-if="checkeddate == true" class="mb-3">
                <label class="form-label mb-2">{{ $t('specify_date_timeNeedCall') }}</label>
                <b-row>
                    <b-col lg="6">
                    <div class="mb-3">
                            <h4 class="fs-15">{{ $t('date') }}</h4>
                            <flatpickr v-model="form.confirmDate" :config="configDate" class="form-control border-0 dash-filter-picker shadow" ></flatpickr>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('time') }}</h4>
                            <setTime :timeProps="this.time" @setHour="setHour" @setMinute="setMinute"></setTime>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <!-- <button class="btn link-success fw-medium"  @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button> -->
            <button 
                type="button" 
                class="btn btn-success" 
                @click="toConfirm"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import setTime from '../../../components/setTime.vue';
import dialogBox from '@/components/modal/dialog'
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
// import { Tasks } from '@/API'
import { storeS } from '@/store'

// let apiServe = new Tasks();

export default{
    components: {
        setTime,
        dialogBox,
        flatpickr: Vueflatpickr
    },
    props: ['id'],
    data(){
        return{
            time: '',
            checkeddate: false,
            form: {
                comment: '',
                confirmTime: "",
                confirmDate: ""
            },
            datetime: "",
            configDate: {
                mode: "single",
                minDate: "today",
                altInput: true,
                altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: ""
            },
            configTime: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
            }
        }
    },
    created() {
        if(storeS.lang == 'uk'){
            this.configDate.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.configDate.locale = Russian
        }

        this.time = new Date().getHours() + ':' + (Number(new Date().getMinutes()) < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes() )
    },
    methods: {
        setHour(e){
            this.time = String(new Date(new Date().setHours(e)).getHours()) + ':' + this.time.split(':')[1]
            this.form.confirmTime = this.time
        },
        setMinute(e) {
            this.time = this.time.split(':')[0] + ':' + String(new Date(new Date().setMinutes(e)).getMinutes())
            this.form.confirmTime = this.time
        },
        toConfirm(){
            this.$emit('toConfirm', this.id, this.form, this.checkeddate)
            this.$emit('close')
        },
        change(datetimeStr){
            var arrDate = String(datetimeStr)
            arrDate = arrDate.split(' ')
            this.form.confirmDate = arrDate[0];
            this.form.confirmTime = arrDate[1];
        },
    }
}
</script>