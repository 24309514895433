<template>
    <!-- 
        Додаткові сповіщення в інсайд, якщо падає кабінет або сервера Скай
    -->

    <!-- Повідомлення про вимкнений сервер автентифікації/проблеми з кабінетом/авторизацією -->
    <div 
        v-if="this.reason == 'authServerOff'" 
        style="display: grid; grid-template-columns: repeat(1, 3fr); transform: translate(0px, 0px); top: 15px;transition: .3s; max-width: 1000px;" 
        class="toastify on bg-warning toastify-center toastify-top text-center fs-16 fw-semibold" 
        aria-live="polite" 
    >
        {{ $t('authServerOff') }}
        <!-- Кнопка закриття -->
        <button type="button" class="btn-close" aria-label="Close" @click="this.$emit('close')" style="grid-column-start: 2; grid-column-end: 3;"></button>
    </div>

    <!-- Повідомлення про проблеми з серверами Sky -->
    <div 
        v-if="this.reason == 'skyServerOff'" 
        style="display: grid; grid-template-columns: repeat(1, 3fr); transform: translate(0px, 0px); top: 15px;transition: .3s; max-width: 1000px;" 
        class="toastify on bg-warning toastify-center toastify-top text-center fs-16 fw-semibold" 
        aria-live="polite" 
    >
        {{ $t('skyServerOff') }}
        <!-- Кнопка закриття -->
        <button type="button" class="btn-close" aria-label="Close" @click="this.$emit('close')" style="grid-column-start: 2; grid-column-end: 3;"></button>
    </div>

    <!-- Повідомлення про необхідність перезапуску сторінки -->
    <div 
        v-else 
        class="toastify on bg-info toastify-center toastify-top text-center fs-16 fw-semibold" 
        aria-live="polite" 
        style="transform: translate(0px, 0px); top: 15px;transition: .3s; max-width: 1000px;" 
    >
        {{ user.name + ', ' + $t('ChangesAccRef') }}<br />
        <button class="btn btn-light waves-effect waves-light mt-2" type="button" @click="reflesh" ><i class="align-bottom me-1"></i>{{ $t('Restart') }}</button>
    </div>
</template>

<script>
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { storeS } from '@/store'

export default{
    props: ['reason'],
    data(){
        return{
            form:''
        }
    },
    methods: {
        reflesh(){
            // ребут сторінки
            
            location.reload()
        },
    },
    computed: {
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
}
</script>