<template>
    <!-- 
        Верхній блок модального вікна
    -->

    <div class="dropdown" v-if="(perms[2000] || perms[707]) || form.workerId == this.user.userid">
        <!-- 
            Кнопка для виклику меню дій
        -->
        <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bx bxs-plus-circle align-bottom"></i>
        </button>

        <!-- 
            Випадаюче меню з доступними опціями
        -->
        <div class="dropdown-menu dropdown-menu-end">
            <!-- 
                Кнопка для відкриття вікна відпусток, доступна для користувача з правами 2000 (режим бога) або самого користувача
            -->
            <button v-if="form.workerId == this.user.userid" class="dropdown-item" @click="openvacationbox()">{{ $t('TakeDayholiday') }}</button>

            <!-- 
                Кнопка для відкриття вікна штрафів, доступна для користувача з правами 2000 (режим бога) або 707 (додавання штрафів)
            -->
            <button v-if="perms[2000] || perms[707]" class="dropdown-item" @click="openfinebox()">{{ $t('Fine') }}</button>
        </div>
    </div>

    <!-- 
        Кнопка закриття модального вікна
    -->
    <div class="" ><button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button></div>
    
</template>

<script>
import { storeS } from '@/store'; // глобальне сховище даних

export default {
    props: ['obj'],
    components:{},
    data(){
        return {
            form: "",
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            // Метод для отримання даних для форми
            this.form = this.obj
        },
        openfinebox(){
            // Метод для відкриття вікна створення штрафу
            this.$emit('openfinebox')
        },
        openvacationbox(){
            // Метод для відкриття вікна створення заявки на вихідний/відпустку
            this.$emit('openvacationbox')
        }
    },
    computed:{
        perms(){
            /*
                Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
            */
            return storeS.perms
        },
        user(){
             /*
                Властивість, яка повертає дані за користувача зі збережених даних у глобальному стору.
            */
            return storeS.userbase
        }
    }
}
</script>