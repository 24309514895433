<template>
    <div style="padding-right: 7px;" class="mb-3">
    <div class="card-body text-center">
        <!-- <div class="position-relative d-inline-block">
            <img src="assets/images/users/avatar-10.jpg" alt="" class="avatar-lg rounded-circle img-thumbnail">
            <span class="contact-active position-absolute rounded-circle badge-soft-success"><span class="visually-hidden"></span>
        </span></div> -->
        <template v-if="owner != '' && owner != 'noEnterComp'">
            <h5 class="mt-2 mb-1" v-if="owner.ownerPhone">{{ $t('owner_of_company') }}:</h5>
            <h5 class="mt-2 mb-1" v-if="owner.ownerPhone">{{ owner.ownerPhone }} - {{ getNameWorker(owner.ownerPhone) }}</h5>
            <p class="text-muted" v-if="owner != ''">{{ getWorkerJob(owner.ownerPhone) }}</p>
            <hr v-if="owner" />
        </template>
        <h5 class="mt-2 mb-1">{{ form.additionalName ? form.additionalName : form.personalData?.user_name }}</h5>
        <h5 class="mt-2 mb-1">{{ form.personalData?.phone }}</h5>
        <p class="text-muted" v-if="owner != '' && owner != 'noEnterComp'">{{ getWorkerJob(form.personalData?.phone)}}</p>

        <ul class="list-inline mb-0">
            <li class="list-inline-item avatar-xs" v-if="this.checks['006'] || this.perms[2000] == true">
                <button class="avatar-title badge-soft-success text-success fs-15 rounded" style="border:none" @click="call(String(form.personalData?.phone))">
                    <i class="ri-phone-line"></i>
                </button>
            </li>
            <li class="list-inline-item avatar-xs">
                <button @click="sendMAIL()" class="avatar-title badge-soft-danger text-danger fs-15 rounded" style="border: none;">
                    <i class="ri-mail-line"></i>
                </button>
            </li>
            <li class="list-inline-item avatar-xs">
                <button @click="sendSMS()" class="avatar-title badge-soft-warning text-warning fs-15 rounded" style="border: none;">
                    <i class="ri-question-answer-line"></i>
                </button>
            </li>
            <template v-if="owner != '' && owner != 'noEnterComp'">
                <li class="list-inline-item avatar-xs" v-if="owner && owner.more.companyServer != 's-1'">
                    <button @click="$emit('openDashboard')" class="avatar-title badge-soft-secondary text-secondary fs-15 rounded" style="border: none;">
                        <i class="ri-apps-2-line"></i>
                    </button>
                </li>
            </template>
        </ul>
    </div>

        <b-tabs 
            pills
        >
            <b-tab :title="$t('Main')">
                <div style="padding: 0px 12px;;">
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 fw-semibold">{{ $t('name') }}</h5>
                                <p v-if="editForm != true" class="fs-15  mb-0 fw-normal" style="word-break: break-all;">{{ formData.additionalName == '' ? form.personalData?.user_name : formData.additionalName == null ? "-": formData.additionalName }}</p>
                                <input v-else v-model="formData.additionalName"  class="form-control" />
                            </div>
                        </div>
                        <div :class="`flex-shrink-0 ms-2 hstack gap-sm-1 mt-${editForm != true ? '2' : '4'}`">
                            <button type="button" @click="showSaveModal = true" :class="`btn btn-sm btn-outline-${editForm == true ? 'success': 'info'} fs-16`"><i :class="`bx bx-${editForm == true ? 'save': 'edit-alt'} align-middle`"></i></button>
                            <button type="button" @click="copy(formData.additionalName == null ? form.personalData?.user_name : formData.additionalName)" class="btn btn-sm btn-outline-success fs-16"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 text-muted">{{ $t('phone_number') }}</h5>
                                <p class="fs-15 mb-0 fw-normal" style="word-break: break-all;">{{ form.personalData && form.personalData?.phone ? Number(form.personalData?.phone) : '-' }}</p>
                            </div>
                        </div>
                        <div class="flex-shrink-0 ms-2">
                            <button type="button" @click="copy(Number(form.personalData?.phone))" class="btn btn-sm btn-outline-success fs-14"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 text-muted">{{ $t('mail') }}</h5>
                                <template v-if="form.personalData && form.personalData?.email">
                                    <p class="fs-14 m-1 fw-normal" style="word-break: break-all;">{{ form.personalData?.email }}</p>
                                </template>
                                <template v-if="form.personalData?.email == ''">
                                    <p v-if="editForm != true" class="fs-14 m-1 fw-normal" style="word-break: break-all;" v-for="item in formData.additionalEmail" :key="item">{{ item }}</p>
                                    <Multiselect
                                        v-else
                                        v-model="formData.additionalEmail"
                                        mode="tags"
                                        :close-on-select="true"
                                        :searchable="true"
                                        :create-option="true"
                                        :options="formData.additionalEmail"
                                    />
                                </template>
                                <template v-if="form.personalData?.email == '' && formData.additionalEmail.length < 1">
                                    <p class="fs-14 m-1 fw-normal" style="word-break: break-all;">-</p>
                                </template>
                            </div>
                        </div>
                        <div :class="`flex-shrink-0 ms-2 hstack gap-sm-1 mt-${editForm != true ? '2' : '4'}`">
                            <button type="button" @click="showSaveModal = true" :class="`btn btn-sm btn-outline-${editForm == true ? 'success': 'info'} fs-16`"><i :class="`bx bx-${editForm == true ? 'save': 'edit-alt'} align-middle`"></i></button>
                            <button type="button" @click="copy(this.form.personalData?.email)" class="btn btn-sm btn-outline-success fs-16"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                        <!-- <div class="flex-shrink-0 ms-2">
                            <button type="button" @click="showSaveModal = true" :class="`btn btn-sm btn-outline-${editForm == true ? 'success': 'info'} fs-16`"><i :class="`bx bx-${editForm == true ? 'save': 'edit-alt'} align-middle`"></i></button>
                            <button type="button" @click="copy(formData.additionalEmail == null ? form.personalData.email : formData.additionalEmail)" class="btn btn-sm btn-outline-success fs-14"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div> -->
                    </div>
                    <!-- <div class="d-flex align-items-center py-2" v-if="form.refidacc">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 text-muted">{{ $t('CameFrom') }}</h5>
                                <p class="fs-15  mb-0 fw-normal">{{ form.refidacc }}</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </b-tab>
            <b-tab :title="$t('Details')">
                <div class="d-flex align-items-center py-2">
                    <div class="flex-grow-1">
                        <div>
                            <h5 class="fs-14 mb-1 fw-semibold">{{ $t('Registered') }}</h5>
                            <p class="fs-13  mb-0">{{ form.personalData?.register_date ? form.personalData?.register_date : '-' }}</p>
                        </div>
                    </div>
                    <div :class="`flex-shrink-0 ms-2 hstack gap-sm-1 mt-${editForm != true ? '2' : '4'}`">
                        <button type="button" @click="copy(form.personalData?.register_date)" class="btn btn-sm btn-outline-success fs-16"><i class="bx bx-copy-alt align-middle"></i></button>
                    </div>
                </div>
                <div class="d-flex align-items-center py-2">
                    <div class="flex-grow-1">
                        <div>
                            <h5 class="fs-14 mb-1 fw-semibold">{{ $t('name') }}</h5>
                            <p v-if="editForm != true"  class="fs-15  mb-0 fw-normal" style="word-break: break-all;">{{ formData.additionalName == "" ? "-": formData.additionalName }}</p>
                            <input v-else v-model="formData.additionalName"  class="form-control" />
                        </div>
                    </div>
                    <div :class="`flex-shrink-0 ms-2 hstack gap-sm-1 mt-${editForm != true ? '2' : '4'}`">
                        <button type="button" @click="showSaveModal = true" :class="`btn btn-sm btn-outline-${editForm == true ? 'success': 'info'} fs-16`"><i :class="`bx bx-${editForm == true ? 'save': 'edit-alt'} align-middle`"></i></button>
                        <button type="button" @click="copy(formData.additionalName)" class="btn btn-sm btn-outline-success fs-16"><i class="bx bx-copy-alt align-middle"></i></button>
                    </div>
                </div>
                <div class="d-flex align-items-center py-2">
                    <div class="flex-grow-1">
                        <div>
                            <h5 class="fs-14 mb-1 fw-semibold">{{ $t('Kind_of_activity') }}</h5>
                            <p v-if="formData.activity.length < 1" class="fs-15  mb-0 fw-normal" style="word-break: break-all;">-</p>
                            <p v-if="editForm != true" class="fs-14 m-1 fw-semibold badge badge-soft-info" v-for="item in formData.activity" :key="item">{{ this.mutateActivity(item) }}</p>
                            <Multiselect
                                v-else
                                v-model="formData.activity"
                                mode="tags"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="listActivity"
                            />
                        </div>
                    </div>
                    <div :class="`flex-shrink-0 ms-2 hstack gap-sm-1 mt-${editForm != true ? '2' : '4'}`">
                        <button type="button" @click="showSaveModal = true" :class="`btn btn-sm btn-outline-${editForm == true ? 'success': 'info'} fs-16`"><i :class="`bx bx-${editForm == true ? 'save': 'edit-alt'} align-middle`"></i></button>
                        <button type="button" @click="copy(formData.activity)" class="btn btn-sm btn-outline-success fs-16"><i class="bx bx-copy-alt align-middle"></i></button>
                    </div>
                </div>
                <div class="d-flex align-items-center py-2">
                    <div class="flex-grow-1">
                        <div>
                            <h5 class="fs-14 mb-1 fw-semibold">{{ $t('Business_type') }}</h5>
                            <p v-if="formData.typeOfBusiness.length < 1" class="fs-15  mb-0 fw-normal" style="word-break: break-all;">-</p>
                            <p v-if="editForm != true" class="fs-14 m-1 fw-semibold badge badge-soft-secondary" v-for="item in formData.typeOfBusiness" :key="item">{{ this.mutateBusiness(item) }}</p>
                            <Multiselect
                                v-else
                                v-model="formData.typeOfBusiness"
                                mode="tags"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="listBusiness"
                            />
                        </div>
                    </div>
                    <div :class="`flex-shrink-0 ms-2 hstack gap-sm-1 mt-${editForm != true ? '2' : '4'}`">
                        <button type="button" @click="showSaveModal = true" :class="`btn btn-sm btn-outline-${editForm == true ? 'success': 'info'} fs-16`"><i :class="`bx bx-${editForm == true ? 'save': 'edit-alt'} align-middle`"></i></button>
                        <button type="button" @click="copy(formData.typeOfBusiness)" class="btn btn-sm btn-outline-success fs-16"><i class="bx bx-copy-alt align-middle"></i></button>
                    </div>
                </div>
                <div class="d-flex align-items-center py-2">
                    <div class="flex-grow-1">
                        <div>
                            <h5 class="fs-14 mb-1 fw-semibold">{{ $t('GeneralManager') }}</h5>
                            <p v-if="formData.personToCommunicate.length < 1" class="fs-15  mb-0 fw-normal" style="word-break: break-all;">-</p>
                            <p v-if="editForm != true" class="fs-14 m-1 fw-semibold badge badge-soft-dark" v-for="item in formData.personToCommunicate" :key="item">{{ item }}</p>
                            <Multiselect
                                v-else
                                v-model="formData.personToCommunicate"
                                mode="tags"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="true"
                                :options="formData.personToCommunicate"
                            />
                        </div>
                    </div>
                    <div :class="`flex-shrink-0 ms-2 hstack gap-sm-1 mt-${editForm != true ? '2' : '4'}`">
                        <button type="button" @click="showSaveModal = true" :class="`btn btn-sm btn-outline-${editForm == true ? 'success': 'info'} fs-16`"><i :class="`bx bx-${editForm == true ? 'save': 'edit-alt'} align-middle`"></i></button>
                        <button type="button" @click="copy(formData.personToCommunicate)" class="btn btn-sm btn-outline-success fs-16"><i class="bx bx-copy-alt align-middle"></i></button>
                    </div>
                </div>
                <div class="d-flex align-items-center py-2">
                    <div class="flex-grow-1">
                        <div>
                            <h5 class="fs-14 mb-1 fw-semibold">{{ $t('HowFindaboutSkyService') }}</h5>
                            <p v-if="editForm != true" class="fs-15  mb-0 fw-normal" style="word-break: break-all;">{{ formData.foundOutSky == "" ? "-": this.mutateTypeFound(formData.foundOutSky) }}</p>
                            <Multiselect
                                v-else
                                v-model="formData.foundOutSky"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="listTypeFound"
                            />
                        </div>
                    </div>
                    <div :class="`flex-shrink-0 ms-2 hstack gap-sm-1 mt-${editForm != true ? '2' : '4'}`">
                        <button type="button" @click="showSaveModal = true" :class="`btn btn-sm btn-outline-${editForm == true ? 'success': 'info'} fs-16`"><i :class="`bx bx-${editForm == true ? 'save': 'edit-alt'} align-middle`"></i></button>
                        <button type="button" @click="copy(formData.foundOutSky)" class="btn btn-sm btn-outline-success fs-16"><i class="bx bx-copy-alt align-middle"></i></button>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('Access')" v-if="this.services != ''">
                <div class="d-flex mb-3 mt-3" v-if="this.services && (this.services.telegram || this.services.facebook || this.services.google) ">
                    <h6 class="card-title mb-0 flex-grow-1"><b>Services</b></h6>
                </div>
                <div class="mb-3" v-if="this.services && this.services.telegram">
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-telegram-fill"></i></div>
                        </div>
                        <div class="flex-grow-1 c_block overflow-hidden" >
                            <p class="mb-1"><b>Telegram:</b></p>
                            <div class="ctext-wrap" style="display:flex">
                                <div class="ctext-wrap-content">
                                    <h6 class="mb-0" ><a target="_blank" :href="`https://t.me/${this.services.telegram.telegram_name}`">{{ this.services.telegram.telegram_name }} | {{ this.services.telegram.telegram_id }}</a></h6>
                                </div>
                            </div>
                        </div>
                        <div class="flex-shrink-0 ms-2">
                            <button type="button" @click="copy(this.services.telegram.telegram_name)" class="btn btn-sm btn-outline-success fs-14"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-if="this.services && this.services.facebook">
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-facebook-circle-fill"></i></div>
                        </div>
                        <div class="flex-grow-1 c_block overflow-hidden" >
                            <p class="mb-1"><b>Facebook:</b></p>
                            <div class="ctext-wrap" style="display:flex">
                                <div class="ctext-wrap-content">
                                    <h6 class="mb-0" >{{ this.services.facebook.facebook_name }} | {{ this.services.facebook.facebook_id }}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="flex-shrink-0 ms-2">
                            <button type="button" @click="copy(this.services.facebook.facebook_name)" class="btn btn-sm btn-outline-success fs-14"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-if="this.services && this.services.google">
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-google-fill"></i></div>
                        </div>
                        <div class="flex-grow-1 c_block overflow-hidden" >
                            <p class="mb-1"><b>Google:</b></p>
                            <div class="ctext-wrap" style="display:flex">
                                <div class="ctext-wrap-content">
                                    <h6 class="mb-0" >{{ this.services.google.google_name }} | {{ this.services.google.google_id }}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="flex-shrink-0 ms-2">
                            <button type="button" @click="copy(this.services.google.google_name)" class="btn btn-sm btn-outline-success fs-14"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
    
    <saveModal 
        v-if="showSaveModal"
        @close="showSaveModal = false"
        @getdata="refresh"
        :obj="formData"
        :number="this.form.personalData?.phone"
    />

</template>

<script>
import {copy} from '@/usabilityScripts/copy.js'
import saveModal from './saveData'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { mutateBusiness, mutateActivity, mutateTypeFound, mutateWorkerPosition } from '@/usabilityScripts/globalMutate'
// import { SuperAdmin } from '@/API'
import { storeS } from "@/store"

// let apiServe = new SuperAdmin();

export default({
    props: ['obj',  'owner'],
    components: {
        Multiselect,
        saveModal
    },
    data() {
        return {
            form: '',
            showSaveModal: false,
            formData: {
                additionalName: "",
                typeOfBusiness: [],
                activity: [],
                personToCommunicate: [],
                foundOutSky: "",
                additionalEmail: []
            },
            listBusiness: [
                { value: 'bar', label: this.$t('bar_title')},
                { value: 'cafe', label: this.$t('Cafe_title')},
                { value: 'coffeeshop', label: this.$t('coffee_shop_title')},
                { value: 'restaurant', label: this.$t('restaurant_title')},
                { value: 'canteen', label: this.$t('Canteen')},
                { value: 'pizzeria', label: this.$t('Pizzeria')},
                { value: 'sushibar', label: this.$t('sushibar')},
                { value: 'fastfood', label: this.$t('fastfood')},
                { value: 'foodtruck', label: this.$t('foodtruck')},
                { value: 'hookah', label: this.$t('Hookah')},
                { value: 'kiosk', label: this.$t('kiosk')},
                { value: 'boutique', label: this.$t('Boutique')},
                { value: 'market', label: this.$t('market')},
                { value: 'shop', label: this.$t('shop')},
                { value: 'jewshop', label: this.$t('jewshop')},
                { value: 'petshop', label: this.$t('petshop')},
                { value: 'pharmacy', label: this.$t('pharmacy')},
                { value: 'bakery', label: this.$t('bakery')},
                { value: 'candyshop', label: this.$t('candyshop')},
                { value: 'business', label: this.$t('business')},
                { value: 'franchise', label: this.$t('franchise')},
                { value: 'deliveryfood', label: this.$t('deliveryfood')},
                { value: 'tirefitting', label: this.$t('tirefitting')},
                { value: 'carwash', label: this.$t('carwash')},
                { value: 'hospital', label: this.$t('hospital')},
                { value: 'dentistry', label: this.$t('dentistry')},
                { value: 'vetclinic', label: this.$t('vetclinic')},
                { value: 'spasalon', label: this.$t('spasalon')},
                { value: 'beautysaloon', label: this.$t('beautysaloon')},
                { value: 'barbershop', label: this.$t('barbershop')},
                { value: 'nailsalon', label: this.$t('nailsalon')},
                { value: 'cosmetology', label: this.$t('cosmetology')}
            ],
            listActivity: [
                { value: 'institutions', label: this.$t('Institutions')},
                { value: 'trade', label: this.$t('Trade')},
                { value: 'production', label: this.$t('Production')},
                { value: 'services', label: this.$t('Services')}
            ],
            listTypeFound: [
                { value: 'recommendation', label: this.$t('Recommendation')},
                { value: 'himself', label: this.$t('independently')},
                { value: 'movedadvert', label: this.$t('MovedAdvertisement')},
                { value: 'integrator', label: this.$t('ThroughIntegrator')},
                { value: 'socialnetworks', label: this.$t('sawSocialNetworks')}
            ],
            services: '',
            editForm: false
        }
    },
    created(){
        this.getdata()
        console.log('ths', this);
    },
    computed: {
        info() {
            return storeS.userbase
        },
        perms() {
            return storeS.perms
        },
        checks(){
            /*
                Властивість, яка повертає налаштування користувача зі збережених даних у глобальному стору.
            */
            return storeS.checks
        },
    }, 
    methods: {
        refresh(e) {
            if(e.additionalName != '') {
                this.form.additionalName = e.additionalName
            }
        },
        call(a) {
            console.log(a)
            this.$emit('call', a)
        },
        sendSMS() {
            this.$emit('opensmsbox')
        },
        sendMAIL() {
            this.$emit('openmailbox')
        },
        getdata(){
            this.form = this.obj

            this.formData.additionalName = this.obj.additionalName;
            this.formData.additionalEmail = this.obj.additionalEmail ? this.obj.additionalEmail : [];
            this.formData.typeOfBusiness = this.obj.typeOfBusiness ? this.obj.typeOfBusiness : [];
            this.formData.activity = this.obj.activity ? this.obj.activity : [];
            this.formData.personToCommunicate = this.obj.personToCommunicate ? this.obj.personToCommunicate : [];
            this.formData.foundOutSky = this.obj.foundOutSky;

            this.services = this.form.personalData?.services ? JSON.parse(this.form.personalData?.services) : ''
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        mutateBusiness(e){
            return mutateBusiness(e)
        },
        mutateActivity(e){
            return mutateActivity(e)
        },
        mutateTypeFound(e){
            return mutateTypeFound(e)
        },
        getNameWorker(e){
            var name = '';
            this.owner.companyDataObj.workers.forEach(element => {
                if(element.workerPhone == e){
                    name = element.workerName
                }
            });
            return name
        },
        getWorkerJob(e){
            if(this.owner != '' || this.owner != 'noEnterComp'){
                var job = '';
                this.owner.companyDataObj.workers.forEach(element => {
                    if(element.workerPhone == e){
                        job = element.workerJob
                    }
                });
                job = this.mutateWorkerPosition(job)
                return job
            }
        },
        mutateWorkerPosition(e){
            return mutateWorkerPosition(e)
        }
    }
})
</script>
