<template>
    <modal>
        <template v-slot:title>{{this.$t('Add')}}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">{{ this.$t('enterNameLabel') }}</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" v-model="name" :placeholder="this.$t('Last 7 days')">
            </div>
            <div class="mb-3">
                <Multiselect
                    :placeholder="'Одиниця часу'"
                    :options="timeUnits"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    v-model="timeUnit"
                />
            </div>
            <div class="mb-3" v-if="timeUnit !== ''">
                <input type="text" class="form-control" v-model="this.amount" :placeholder="'Кількість'">
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button type="button" class="btn btn-success" @click="saveCustomTime">{{ this.$t('save') }}</button>
        </template>
    </modal>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import modal from '@/components/modal/dialog.vue'
import dbPromise from '../../indexedDB';

export default {
    data(){
        return{
            timeUnit: '',
            timeUnits: [
                {
                    label: this.$t('Days'),
                    value: 'day'
                },
                {
                    label: this.$t('Weeks'),
                    value: 'week'
                },
                {
                    label: this.$t('Months'),
                    value: 'month'
                },
                {
                    label: this.$t('Years'),
                    value: 'year'
                },
            ],
            amount: '',
            name: ''
        }
    },
    components: {modal, Multiselect},
    methods: {
        saveCustomTime(){
            if(!Number.isInteger(Number(this.amount))) {
                this.$toast.error(this.$t('writeInteger'))
                return
            }
            let tab = {
                name: this.name,
                amount: this.amount,
                unit: this.timeUnit
            }
            if(this.name == '' || this.amount == '' || this.timeUnit == '') {
                this.$toast.error(this.$t('Null'))
                return
            } else if(Number(this.amount) == NaN) {
                this.$toast.error(this.$t('notNumber'))
                return
            }
            if(localStorage.getItem('tabsForCalendar')) {
                let arr = JSON.parse(localStorage.getItem('tabsForCalendar'))
                localStorage.removeItem('tabsForCalendar')
                if (arr.find(el => el.name == tab.name)) {
                    this.$toast.error(this.$t('sameNames'))
                    return
                }
                arr.push(tab)
                localStorage.setItem('tabsForCalendar', JSON.stringify(arr))
            } else {
                localStorage.setItem('tabsForCalendar', JSON.stringify([tab]))
            }
            this.eventB.emit('refreshCalendar', {data: tab})
            this.$emit('close')
        }
    }
}
</script>