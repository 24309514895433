<template>
    <!-- <div class="col-sm-auto" style="margin-bottom:15px;" v-if="checks['001']">
        <div class="hstack gap-2">
            <button @click="infoblockShow == true ? infoblockShow = false : infoblock()" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
        </div>
    </div> -->

    <!-- инфо-блоки -->
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <!-- компонент таблицы -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms['511'] || this.perms['2000']" 
        @create="showModalAdd = true"
        @getdata="getdata"
        @changelimit="changelimit"
        @open="open"
    />

    <!-- создание услуги -->
    <created 
        v-if="showModalAdd" 
        @close="showModalAdd = false"
        :number="number"
    ></created>

    <!-- карточка точки -->
    <viewcard  
        v-if="showModal == true" 
        @close="showModal = false" 
        :objcard="objcard"
        @remove="remove"
        @toEdit="toEdit"
    />
</template>

<script>
import tablecustom from '@/components/globaltable/index';
import created from '@/views/services/createservice'
import viewcard from '@/views/services/view'
import infoblocks from '@/components/info/blocks'
import { Services } from '@/API'
import { storeS } from '@/store';
import { mutateServices, mutateStatusServices } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Services();

export default {
    props: ['number'],
    components: { 
        tablecustom, 
        infoblocks,
        viewcard,
        created
    },
    data() {
        return {
            form: {},
            showModal: false,
            showModalAdd: false,
            infoblockShow: false,
            objcard: '',
            objPages: "",
            dataParams: {
                status: false,
                page: "services"
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                userPhone: ''
            },
            rows: [],
            columns: [
                {
                    name: this.$t("service"),
                    text: "nomenclatureName",
                    align: "left",
                    width: 300,
                    status: true,
                    mutate: (item) => this.mutateServices(item)
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "left",
                    width: 200,
                    status: true,
                    mutate: (item) => this.mutateStatus(item).name,
                    mutateClass: (item) => this.mutateStatus(item).color
                },
                {
                    name: this.$t("Price"),
                    text: "price",
                    align: "right",
                    width: 200,
                    status: true,
                },
                {
                    name: this.$t("Comment"),
                    text: "description",
                    align: "right",
                    width: 200,
                    status: true,
                    mutate: (item) => item == "" ? '-' : item,
                },
            ],
            information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info"
                },
                {
                    title: this.$t('successfully'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success"
                },
                {
                    title: this.$t('notSuccessful'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger"
                },
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(page){
            this.objParams.userPhone = Number(this.number);
            this.objParams.page = page != undefined ? page : '1';
            apiServe.getService(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.items
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` #510`);
                }
            })
        },
        open: function(e){
            apiServe.getCardService(e.nomenclatureId).then(result => {
                if(result.status == 'done') {
                    this.objcard = result.data
                    this.showModal = true
                } else {
                    this.$toast.error(this.$t('err'));
                }
            })
        },
        toEdit(e){
            apiServe.editService(e.nomenclatureId, e).then(result => {
                if(result.status == 'done'){
                    this.showModal = false;
                    this.getdata();
                    this.$toast.success(this.$t('alertChanged'));
                } else {
                    this.$toast.error(this.$t('err'));
                }
            })
        },
        remove: function(e){
            apiServe.deleteService(e.nomenclatureId).then(result => {
                if(result.status == 'done'){
                    this.showModal = false;
                    this.getdata();
                    this.$toast.success(this.$t('Removed'));
                } else {
                    this.$toast.error(this.$t('err'));
                }
            })
        },
        infoblock(){
            this.information[0].value = this.filteredItems.length
            this.information[1].value = this.filteredItems.filter(item => item.statusClass == 'bg-success').length
            this.information[2].value = this.filteredItems.filter(item => item.statusClass == 'bg-danger').length
            this.infoblockShow = true
        },
        searchB(e){
            this.objParams.search = e
            this.getdata()
        },
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        mutateTariffing(e){
          var r = "";
          var cl = "";
          switch(e){
            case "Тарифицируется":
              r = this.$t('Charged')
              cl = 'badge bg-success'
              break;
            case "Не тарифицируется":
              r = this.$t('Not_charged')
              cl = 'badge bg-danger'
              break;
          }
          return {r, cl};
        },
        mutateServices(e){
            return mutateServices(e)
        },
        mutateStatus(e){
            return mutateStatusServices(e)
        },
    },
    mounted(){
        this.eventBus.on('saveService', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    },
    computed: {
        checks(){
            return storeS.checks
        },
        perms(){
            return storeS.perms
        }
    }
}
</script>