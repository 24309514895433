<template>
    <div class="card card-info">
        <div class="card-body text-center">
            <div class="d-flex align-items-center">
                <div class="flex-grow-1 ms-3">
                    <h3 class="card-title">#{{form.projectId}} - {{form.projectName}}</h3>
                    <!-- <p class="card-text">
                        <span class="fw-medium">{{ $t('createDate') }} : {{form.createDatetime}}</span> 
                    </p> -->
                </div>
            </div>
        </div>
        <div class="card-footer copy" @click="copy('Проект #' + form.projectId + ' - ' + form.projectName)">
            <div class="text-center">
                <a class="link-light">
                    {{$t('toCopy')}} 
                    <i
                        class="bx bx-copy align-middle lh-1"
                    ></i>
                </a>
            </div>
        </div>
    </div>

    <!-- о проекте -->
    <b-card>
        <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">{{$t('aboutProject')}}</h4>
            <div class="flex-shrink-0" v-if="(form.teamlead == user.userid && this.form.workers.filter(item => item.workerId == this.user.userid).length > 0)">
                <div class="dropdown card-header-dropdown">
                    <a class="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <span class="text-muted"><i class="ri-settings-4-line align-middle me-1 fs-15"></i>{{$t('action')}}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                        <button class="dropdown-item" @click="showPriorityBox = true">{{$t('ChangePriority')}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive table-card">
                <table class="table table-borderless align-middle mb-0">
                    <tbody>
                        <tr>
                            <td class="fw-medium">{{ $t('ticket') }}</td>
                            <td>#{{form.projectId}}</td>
                        </tr>
                        <tr v-if="form.category">
                            <td class="fw-medium">{{ $t('Categories') }}</td>
                            <td class="hstack text-wrap gap-1">
                                {{form.category}}
                            </td>
                        </tr>
                        <tr>
                            <td class="fw-medium">{{ $t('Status') }}:</td>
                            <td>
                                 <span :class="'fs-12 badge '+this.mutatestatus(form.status).color">
                                    {{this.mutatestatus(form.status).name}}
                                </span> 
                            </td>
                        </tr>
                        <tr>
                            <td class="fw-medium">{{ $t('priority') }}</td>
                            <td>
                                <span :class="'fs-12 badge '+this.mutatepriority(form.priority).bgcolor">
                                    {{this.mutatepriority(form.priority).name}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="fw-medium">{{ $t('team') }}</td>
                            <td>{{form.confStatus}}</td>
                        </tr> 
                        <tr class="border-top">
                            <td class="fw-medium">{{ $t('labels') }}</td>
                            <td><button @click="showTagBox = true" type="button" class="btn btn-soft-info waves-effect waves-light">додати</button></td>
                        </tr>
                        <div>
                            <span class="fs-14 fw-semibold text-white" 
                                :style="`word-break: break-all;width:100%;display: block;margin-right:6px;margin-bottom:5px;padding:7px;border-radius:5px;background-color: ${nameTag(item).color};`" 
                                v-for="item in form.tags" 
                                :key="item">{{ nameTag(item).name }}
                            </span>
                        </div>
                    </tbody>
                </table>
            </div>
        </div>
    </b-card>
    <!-- о проекте -->

    <!-- <TimeTracking
        v-if="form.status != 'new'" 
        :startDate="form.injobDatetime"
        :endDate="form.decidedDatetime"
    /> -->
    
    <div class="card card-white">
        <div class="card-body">
            <div class="flex-grow-1 ms-3 mb-4">
                <h3><b>{{$t('progress')}}</b></h3>
            </div>
            <div class="d-flex align-items-center mb-3">
                <div class="flex-grow-1 ms-3">
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <span class="m-5 fs-12 text-muted" v-if="form.edits.length > 0">{{ $t('taskProgress') }}</span>
                            <div class="progress animated-progress custom-progress progress-label">
                                <div class="progress-bar bg-success" role="progressbar" :style="`width: ${form.progressPercent}%`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><div class="label">{{(form.progressPercent).toFixed(2)}}%</div></div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2" v-if="form.edits.length > 0">
                        <div class="flex-grow-1">
                            <span class="m-5 fs-12 text-muted">{{ $t('progressChange') }}</span>
                            <div class="progress animated-progress custom-progress progress-label">
                                <div class="progress-bar bg-warning" role="progressbar" :style="`width: ${form.progressPercentByEdits}%`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><div class="label">{{(form.progressPercentByEdits).toFixed(2)}}%</div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- изменения по задачам -->
    <edits v-if="form.edits.length > 0" :obj="obj" @getdata="this.$emit('getdata')"/>
    <!-- изменения по задачам -->

    <!-- список задач -->
    <tasks :obj="obj" @progressPercent="updateProgress" :bigger="bigger"/>
    <!-- список задач -->

    <!-- список ссылок -->
    <!-- <links :obj="obj" /> -->
    <!-- список ссылок -->

    <!-- приватность -->
    <!-- <privacy :obj="obj" /> -->
    <!-- приватность -->

    <!-- приватность -->
    <teams :obj="obj" @openCard = "openCard"/>
    <!-- приватность -->

    <dialogBox 
        v-if="showPriorityBox"
        @close="showPriorityBox = false"
    >
        <template v-slot:title>{{ $t('ChangePriority') }}</template>
        <template v-slot:body>
            <div class="mt-1 text-center">
                <h3 class="mb-3"><b>{{ $t('selectNewPr') }}</b></h3>
                <Multiselect 
                    v-model="newForm.priority"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.listPriority"
                    :placeholder="$t('priority')"
                />
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
                <button class="btn btn-success" @click="changePriority">{{ $t('Edit') }}</button>
            </div>
        </template>
    </dialogBox>

    <modalTag 
        v-if="showTagBox"
        @close="showTagBox = false"
        :typeTags="'tags'"
        :checkedTags="form.tags"
        @selectedTags="selectedTags"
    />

</template>

<script>
import dialogBox from '@/components/modal/dialog'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import edits from './edits/toEdit'
import tasks from './tasks'
// import links from './links'
import privacy from './privacy'
import teams from './teams/index'
import modalTag from '@/components/tags/list'
import { Projects } from '@/API'
import { storeS } from '@/store'
import { mutateStatusProject, mutatePriorityTicket, nameTag } from '@/usabilityScripts/globalMutate.js'
import {copy} from '@/usabilityScripts/copy.js'
// import TimeTracking from '@/components/timeTracking'

let apiServe = new Projects();

export default{
    props: ['obj'],
    components: { 
        edits, 
        tasks, 
        privacy, 
        teams, 
        dialogBox,
        Multiselect,
        modalTag
        // TimeTracking 
    },
    data(){
        return{
            bigger: false,
            showTagBox: false,
            form: "",
            newForm: {
                priority: ''
            },
            showPriorityBox: false,
            listPriority: [
                {
                    "label": this.$t('priorityHigh'),
                    "value": "2"
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": "1"
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": "0"
                }
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods:{
        openCard(e) {
            this.$emit('openCard',e)
        },
        selectedTags(ids){
            this.form.tags = ids;
            apiServe.editProject(this.form.projectId, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success('done');
                } else {
                    this.$toast.error($t('error'))
                }
            })
            //додавання тегів до тикету
        },
        getdata(){
            this.form = this.obj
            this.newForm.priority = this.form.priority;
        },
        updateProgress(e){
            this.form.progressPercent = e;
            if(e == 100){
                this.$emit('checkCompleted')
            }
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        mutate_category(e){
            var r = "";
            switch(e){
                case "Content":
                r = this.$t('contentPr');
                break;
                case "Development":
                r = this.$t('development');
                break;
            }
            return r;
        },
        mutatestatus(e){
            return mutateStatusProject(e)
        },
        mutatepriority(e){
            return mutatePriorityTicket(e)
        },
        changePriority(){
            apiServe.changePriority(this.form.projectId, this.newForm).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('PriorityChangSucc'));
                    this.eventBus.emit('UpdateCardProject', true);
                    this.showPriorityBox = false;
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        nameTag(e){
            return nameTag(e)
        }
    },
    computed: {
        user(){
            return storeS.userbase
        },
        perms(){
            return storeS.perms
        }
    }
}
</script>