<template>
    <!-- Модальне вікно для додавання та редагування тегів -->

    <modal>
        <!-- Заголовок модального вікна -->
        <template v-slot:title>{{ $t('addLabel') }}</template>

        <!-- Тіло модального вікна з формою для введення даних про тег -->
        <template v-slot:body>
            <div class="border-bottom mb-3 pb-3">
                <b-row>
                    <b-col lg="12">
                        <div class="rounded text-white text-center p-3" :style="`background-color: ${form.color};`">
                            <!-- Заголовок тегу, який можна редагувати подвійним кліком -->
                            <span @dblclick="editTitle = true" class="fs-16 fw-semibold">{{ form.tagName }}</span>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <!-- Карточка з полем введення для назви тегу -->
            <b-card>
                <label class="fs-15"><b>{{ $t('Point_name') }}</b></label>
                <input class="form-control" v-model="form.tagName" />
            </b-card>

            <!-- Карточка зі списком кольорів для вибору фону тегу -->
            <b-card>
                <b-row>
                    <template v-for="item in colorsNew" :key="item">
                        <b-col lg="2" class="mb-2 copy" @click="form.color = item.color">
                            <div class="avatar-sm" >
                                <div class="avatar-title rounded text-white" :style="`background-color: ${item.color};`"></div>
                            </div>
                        </b-col>
                    </template>
                </b-row>
            </b-card>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">

                <!-- Кнопка "Скасувати", видима при додаванні нового тегу -->
                <button 
                    v-if="!item" 
                    class="btn btn-danger" 
                    @click="$emit('close')"
                >
                    {{ $t('cancel') }}
                </button>

                <!-- Кнопка "Додати", видима при додаванні нового тегу -->
                <button 
                    v-if="!item" 
                    class="btn btn-success" 
                    @click="add"
                >
                    {{ $t('Add') }}
                </button>

                <!-- Кнопка "Видалити", видима при редагуванні існуючого тегу -->
                <button 
                    v-if="item" 
                    class="btn btn-danger" 
                    @click="remove"
                >
                    {{ $t('Delete') }}
                </button>

                <!-- Кнопка "Редагувати", видима при редагуванні існуючого тегу -->
                <button 
                    v-if="item" 
                    class="btn btn-info" 
                    @click="edit"
                >
                    {{ $t('Edit') }}
                </button>
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import { Tags } from '@/API'

let apiServe = new Tags();

export default{
    props: ['item', 'typeTags'],
    components: {
        modal
    },
    data(){
        return{
            form: { // Об'єкт для форми введення даних про тег
                tagName: '',
                color: '#1ABC9C'
            },
            colorsNew: [ // Список доступних кольорів для тегів
                { color: '#1ABC9C', title: '1ABC9C' },
                { color: '#2ECC71', title: '2ECC71' },
                { color: '#3498DB', title: '3498DB' },
                { color: '#9B59B6', title: '9B59B6' },
                { color: '#34495E', title: '34495E' },
                { color: '#F1C40F', title: 'F1C40F' },
                { color: '#F39C12', title: 'F39C12' },
                { color: '#E74C3C', title: 'E74C3C' },
                { color: '#C2185B', title: 'C2185B' },
                { color: '#BDC3C7', title: 'BDC3C7' },
                { color: '#95A5A6', title: '95A5A6' },
                { color: '#607D8B', title: '607D8B' },
            ],
        }
    },
    created(){
        if(this.item){
            this.form = this.item
        }
        console.log('d', this);
    },
    methods: {
        add(){
            // Додавання нового тегу

            this.$emit('addTag', this.typeTags, this.form)
            this.$emit('close')
        },
        edit(){
            // Редагування існуючого тегу
            
            this.$emit('editTag', this.typeTags, this.form)
            this.$emit('close')
        },
        remove(){
            // Видалення існуючого тегу

            this.$emit('deleteTag', this.typeTags, this.form)
            this.$emit('close')
        }
    }
}
</script>