<template>
    <b-card>
       <div class="card-header">
           <h5 class="card-title mb-0"><b>{{ $t('aboutApplication') }}</b></h5>
       </div>
       <div class="card-body">
            <div class="mb-3">
                <div class="d-flex mt-3">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">ID :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-15 mb-0 text-truncate `" > {{ this.form.requestId }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-2">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`bx bx-task`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('phone_number') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy`" @click="copy(form.userPhone)" >{{ form.userPhone }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <div class="mb-3">
               <div class="d-flex mt-3">
                   <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                       <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                           <i :class="`ri-hashtag`"></i>
                       </div>
                   </div>
                   <div :class="`flex-grow-1 overflow-hidden`" >
                       <p class="mb-1 fs-16">{{ $t('priority') }} :</p>
                       <div class="ctext-wrap" style="display:flex">
                           <div class="ctext-wrap-content">
                               <h6 :class="`fs-14 mb-0 fw-semibold copy ${this.mutatePriority(this.form.priority).bg}`" @click="copy(this.mutatePriority(this.form.priority).name)"> {{ this.mutatePriority(this.form.priority).name }}</h6>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
    </b-card>
</template>

<script>
import { mutatePriority } from '@/usabilityScripts/globalMutate.js'
import {copy} from '@/usabilityScripts/copy.js'

export default{
   props: ['obj'],
   data(){
       return {
           form: ''
       }
   },
   created(){
       this.form = this.obj
   },
   methods: {
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        mutatePriority(e){
            return mutatePriority(e)
        },
    }
}
</script>