<template>
    <!-- 
        Список ярликів відповіді
        - дозволяє переглянути список швидких відповідей ( з пошуком по назви ярлика та опису)
        - відкрити картку ярлика відповіді
        - викликати створення ярлика відповіді
        - та інше :)
    -->

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - - йде перевірка на права доступу 2000 (режим бога) та 1101 (створення ярлику)
        - @create - подія, яка спрацьовує при створенні нового запису
        - @open - подія, яка спрацьовує при відкритті картки запису
        - @search - подія, яка спрацьовує при пошуку у таблиці
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
    -->
    <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="pages"
        :showpag="true"
        :createdform="this.perms[1101] || this.perms[2000]"
        @create="modalShow = true" 
        @open="open"
        @getdata="getdata"
        @changelimit="changelimit"
    />

    <!-- 
       Виклик відкриття картки ярлика відповіді
       - для відкриття застосовується перевірка значення showModal
       - @close - подія, яка спрацьовує при закритті картки
       - :objcard - параметр-об'єкт з даними по запису
       - @remove - подія, яка спрацьовує на видалення картки запису
    -->
    <viewbox 
        v-if="showModal" 
        @close="showModal = false" 
        :objcard="objCard" 
        @remove="remove"
    ></viewbox>

    <!-- 
        Виклик вікна створення нового запису
        - для відкриття застосовується перевірка значення modalShow
        - @close - подія, яка спрацьовує при закритті картки
    -->
    <createBox 
        v-if="modalShow"
        @close="closeModal"
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewbox from '@/views/shortcuts/view.vue' // картка ярлика
import createBox from '@/views/shortcuts/create' // модальне вікно створення ярлика
// import dialogBox from '@/components/modal/modalEdit'
import { Shortcuts } from '@/API'; // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Shortcuts();

export default {
    props: ['id'],
    components: { 
        tablecustom,
        viewbox, 
        // dialogBox, 
        createBox 
    },
    data() {
        return {
            showModal: false, // прапорець відображення вікна ярлика
            modalShow: false, // прапорець відображення вікна створення ярлика
            pages: {},  // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: false,
                page: "shortcuts"
            },
            objParams: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                search: '',
                workerId: ''
            },
            rows: [], // Дані для відображення у таблиці
            columns: [ // Конфігурація колонок таблиці з інформацією про кожну колонку
                {
                    name: 'ID', // Назва колонки для локалізованого відображення
                    text: "shortcutId", // Ключове слово для отримання значення з даних
                    align: "left", // Вирівнювання в лівий бік
                    status: true // Статус відображення колонки
                },
                {
                    name: this.$t("Label"),
                    text: "shortcutName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("Message"),
                    text: "message",
                    align: "right",
                    status: true
                },
                {
                    name: this.$t("added"),
                    text: "createWorkerName",
                    align: "right",
                    status: true
                },
            ],
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(page){
            /**
                Отримує дані про ярлики відповіді з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Number} page - Номер сторінки для отримання даних.
            */

            // Встановлення параметрів для запиту до API
            this.objParams.workerId = this.id
            this.objParams.page = page != undefined ? page : '1';

            // Отримання даних про штрафи з API
            apiServe.getLabels(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                    this.pages = result.data.links;
                } else {
                    this.$toast.error(this.$t('err'));

                    var errorText = 'Помилка отримання списку ярликів відповіді'; 
                    var statusRequest = result.status;
                    var api = 'getLabels';
                    var fileRequest = 'src/views/users/view/formright/labels/labels.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
                - пошук працює за назвою ярлика та його повідомленням
            */

            this.objParams.search = e
            this.getdata();
        },
        open(e){
            /**
                Функція для відкриття модального вікна з інформацією про ярлик відповіді за його ідентифікатором.
                * @param {Object} e - об'єкт картки ярлика

                Викликає API для отримання інформації про ярлика.
                Результат присвоюється властивості objCard, та встановлюється прапорці showModal в true для відображення модального вікна.
            */

            apiServe.getLabel(e.shortcutId).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data
                    this.showModal = true;
                } else {
                    this.$toast.error(this.$t('err'));

                    var errorText = 'Помилка відкриття картки ярлику відповіді'; 
                    var statusRequest = result.status;
                    var api = 'getLabel';
                    var fileRequest = 'src/views/users/view/formright/labels/labels.vue';
                    var params = e.shortcutId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        closeModal(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці modalShow в false, та викликає функцію getdata для оновлення даних таблиці.
            */

            this.modalShow = false;
            this.getdata();
        }
    },
    computed: {
        user(){
            /*
                Повертає дані за користувача з глобальномго стору.
            */
            return storeS.userbase
        },
        perms() {
            /*
                Повертає дані прав доступу користувача з глобальномго стору.
            */
            return storeS.perms
        },
    }
}
</script>