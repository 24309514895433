<template>
    <!-- Модальне вікно по повторногому зверненню -->

    <dialogBox >
        <template v-slot:title>{{ $t('Додати звернення') }}</template>
        <template v-slot:body>
            <div class="mt-1 text-left">
                <h4 class="mb-3"><b>{{ $t('Опишіть нове звернення') }}</b></h4>
                
                <b-card>
                    <QuillEditor v-model:content="form.comment" ref="quill" :modules="modules" theme="snow" contentType="html" toolbar="full"/>
                </b-card>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
                <button class="btn btn-success" @click="change">{{ $t('Add') }}</button>
            </div>
        </template>
    </dialogBox>
</template>

<script>
// import dialogBox from '@/components/modal/dialog'
import dialogBox from '@/components/modal/modal-lg'

import BlotFormatter from 'quill-blot-formatter'
export default{
    props: ['objCard'],
    components: {
        dialogBox,
    },
    setup: () => {
        const modules = {
            name: 'blotFormatter',  
            module: BlotFormatter, 
            options: {/* options */}
        }
        return { modules }
    },
    data(){
        return{
            form: {
                comment: ''
            },
        }
    },
    created(){
        // this.getdata()
    },
    methods: {
        getdata() {
            this.form = this.objCard
        }
    }
}
</script>