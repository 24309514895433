<template>
    <!--
        Модальне вікно з закриттям зміни заздалегідь
    -->

    <dialogmodal>
        <template v-slot:body>
            <div class="mt-4 text-center">
                <h3><b>{{ $t('CloseShift') }}?</b></h3>
            </div>
            <div class="mb-3 mt-4">
                <!-- Причина закриття зміни заздалегідь -->
                <label class="form-label fw-semibold">{{ $t('earlyClosingShift') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" :placeholder="$t('enterEarlyClosingShift')"></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 justify-content-center remove">
                <!-- Кнопка "Так", закриває зміну -->
                <button class="btn btn-success" @click="closeShift">{{ $t('y') }}</button>
            </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue' // компонент модального вікна

export default {
    components: {
        dialogmodal
    },
    data(){
        return{
            form: { // параметри форми
                status: false, // статус
                comment: "" // коментар
            }
        }
    },
    created(){
        // this.form = this.obj
    },
    methods: {
        closeShift(){
            this.form.status = true;
            this.$emit('closeShift', this.form);
            this.$emit('close')
        }
    },
    mounted(){

    }
}
</script>