<template>
    <!-- Модальне вікно підтвердженням видалення -->

    <dialogmodal>
        <template v-slot:body>
            <!-- Текст попередження -->
            <div class="mt-4 text-center">
                <h3><b>{{ $t('confirmDeletion') }}?</b></h3>
                <p class="text-muted fs-15 mb-4">{{ $t('deletingPosition') }}</p>
            </div>

            <!-- Блок для вводу перевірочного слова -->
            <div class="mt-4 text-center" v-if="additCheck && additCheck == true">
                <div class="alert alert-info">
                    <p class="mb-0">
                        <span class="fw-semibold">{{ $t('confirmation') }} :</span>
                        {{ $t('checkDelete') }} 
                    </p>
                </div>
                <input class="form-control" v-model="userInput" @input="checkInput" :placeholder="$t('SpecifyTestWord')" />
            </div>
        </template>
        <template v-slot:footer-bottom>
              <div class="hstack gap-2 justify-content-center remove">

                <!-- Кнопка Закрити -->
                <button 
                    class="btn link-success fw-medium text-decoration-none" 
                    @click="$emit('close')"
                >
                    <i class="ri-close-line me-1 align-middle"></i>
                    {{ $t('close') }}
                </button>
                  
                <!-- Кнопка "Так, видалити" -->
                <button 
                    v-if="status"
                    class="btn btn-danger" 
                    @click="this.$emit('remove')"
                >
                    {{ $t('yesDeleteIt') }}
                </button>

              </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue'

export default {
    props: ['obj', 'additCheck'],
    components: {
        dialogmodal
    },
    data(){
        return{
            from: "",
            userInput: "",
            status: true
        }
    },
    created(){
        this.form = this.obj

        if(this.additCheck){
            this.status = false
        }
    },
    methods: {
        checkInput(){
            // Перевіряємо вказане слово
            if (this.userInput === this.$t('Delete')) {
                this.status = true
            } else {
                this.status = false
            }
        }
    }
}
</script>