<template>
    <b-card>
        <template v-if="menuTabs" v-for="item in menuTabs" :key="item">
            <div class="card-header mb-3" v-if="item.children">
                <h5 class="card-title mb-0"><b>{{ item.name }}</b></h5>
            </div>
            <b-row>
                <template v-if="item.children" v-for="ch in item.children" :key="ch">
                    <b-col lg="3" md="4" xs="6" @click="$emit('changeTab', ch.tabName)">
                        <div class="card bg-light shadow-none card-animate">
                            <div class="card-body copy" >
                                <div class="text-center" >
                                    <div class="mb-2" >
                                        <i :class="`${ch.icon} align-bottom text-${item.color} display-5`"></i>
                                    </div>
                                    <h6 class="fs-15 folder-name fw-semibold">{{ ch.name }}</h6>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </template>
            </b-row>
        </template>
    </b-card>
</template>

<script>
export default{
    props: ['menuTabs'],
    data(){
        return{
            form: ''
        }
    },
    created(){
    }
}
</script>