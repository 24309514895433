<template>
    <!-- Компонент для відображення повідомлень -->

    <div 
        class="notification-actions mt-2 pb-1 " 
        id="notification-actions" 
        style="display: block;" 
        v-if="this.alerts.length > 0" 
        :key="componentKey"
    >
        <!-- Блок з кнопкою позначення вибраних повідомлень як прочитаних -->
        <div class="d-flex text-muted justify-content-center" v-if="readCheckbox.length > 0">
            <button type="button" class="btn btn-link link-danger p-0 ms-3" @click="readSelectNotice" >
              {{  $t('readNotice') }}
            </button>
        </div>

        <div style="margin-bottom: 100px;">
            <!-- Блок з відображенням кожного повідомлення -->
            <div v-for="item in this.alerts" :key="item">
                <div class="text-reset notification-item d-block dropdown-item copy border" >
                    <div class="d-flex" >
                        <!-- Аватарка з іконкою та фоновим кольором, які змінюються в залежності від типу повідомлення -->
                        <div class="avatar-xs me-3" @click="open(item)">
                            <span :class="`avatar-title bg-soft-${this.mutateNotice(item).color} text-${this.mutateNotice(item).color} rounded-circle fs-16`"> 
                                <i :class="this.mutateNotice(item).icon"></i> 
                            </span>
                        </div>


                        <div class="flex-1" @click="open(item)">

                             <!-- Заголовок повідомлення -->
                            <h6 class="mt-0 mb-1 lh-base">
                                {{ this.mutateNotice(item).text }}
                            </h6>

                            <!-- Виведення номера тікету, якщо він існує -->
                            <p class="mb-1" v-if="item.noticeData && item.noticeData.ticketId">
                                <span>#{{ item.noticeData.ticketId }}</span>
                            </p>

                            <!-- Відображення інформації про менеджера у випадку повідомлення про додавання відпустки -->
                            <template v-if="item.noticeTitle == 'addHoliday'">
                                <p class="mb-1 fs-11 fw-medium text-uppercase text-muted">
                                    {{ $t('Manager') }} - {{ item.noticeData.workerName }}
                                </p>
                            </template>

                             <!-- Відображення інформації про передавання у випадку певних типів повідомлень -->
                            <template v-if="item.noticeTitle == 'transferRequest' || item.noticeTitle == 'transferDump' || item.noticeTitle == 'transferPresentation' || item.noticeTitle == 'transferTask'">
                                <p class="mb-1 fs-11 fw-medium text-uppercase text-muted">
                                    {{ $t('WhoHandedOver') }} - {{ nameWorker(item.noticeData.fromWorkerId) }}
                                </p>
                                <p class="mb-1 fs-11 fw-medium text-uppercase text-muted">
                                    {{ $t('ToWhomTransferred') }} - {{ nameWorker(Number(item.noticeData.toWorkerId)) }}
                                </p>
                            </template>

                            <!-- Відображення дати та часу повідомлення -->
                            <p class="mb-1 fs-11 fw-medium text-uppercase text-muted">
                                <span><i class="mdi mdi-clock-outline"></i> {{ item.datetime.split(' ')[0].split('-')[2] + ' ' + this.month(item.datetime.split(' ')[0].split('-')[1]) + ' ' + item.datetime.split(' ')[1] }}</span>
                            </p>

                        </div>
                        
                        <!-- Блок з чекбоксом для вибору повідомлення та кнопкою для позначення прочитаних повідомлень -->
                        <div class="px-2 fs-15 d-flex">
                            <!-- Чекбокс для вибору повідомлення -->
                            <div class="form-check notification-check">
                                <input class="form-check-input" v-model="readCheckbox" type="checkbox" :value="item" id="all-notification-check01">
                                <label class="form-check-label" for="all-notification-check01"></label>
                            </div>

                            <!-- Кнопка для позначення повідомлення як прочитаного, якщо вибрано одне повідомлення -->
                            <button 
                                v-if="readCheckbox.length < 1" 
                                @click="readAlert(item)" 
                                :title="this.$t('readNotice')" 
                                type="button"
                                class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email"
                            >
                                <i class="bx bxs-message-alt-check align-bottom"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Блок для відображення, якщо немає повідомлень -->
    <div v-else>
        <div class="tab-pane fade p-4 active show" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
            <div class="empty-notification-elem">
                <div class="text-center pb-5 mt-2">
                    <h6 class="fs-18 fw-semibold lh-base">{{ $t('dontHaveNotifications') }}</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Notices } from '@/API'; // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { mutateNoticeAlerts, getMonth } from '@/usabilityScripts/globalMutate'
import { nameWorker } from '@/usabilityScripts/getNameWorker'

let apiAlerts = new Notices()

export default{
    props: ['typeClear'],
    data(){
        return{
            alerts: [],
            componentKey: 0,
            readCheckbox: []
        }
    },
    created() {
        // Відписка та підписка на подію для отримання повідомлень
        this.eventBus.off('getAlerts')
        this.eventBus.on('getAlerts', status => {
            if(status.status == true) {
                this.getAlerts()
            }
        })

        // Підписка на подію для оновлення повідомлень
        this.eventBus.on('updateAlert', data => {
            if (data.status == true) {
                this.getAlerts()
                this.alerts = this.alerts.reverse()
                this.eventBus.emit('updateAlertsCount', {
                    status:true,
                    count: this.alerts.length
                })  
            }
        })
        
        // Отримання повідомлень при створенні компоненту
        this.getAlerts();
    },
    methods: {
        readAlert(item) {
            // Метод для позначення повідомлення як прочитаного

            let obj = {
                ids: [item.id]
            }

            apiAlerts.readNotices(obj).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('statusViewed'))
                    this.getAlerts()
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        getAlerts() {
            // Метод для отримання повідомлень

            this.alerts = []
            apiAlerts.getNotices().then(result => {
                if(result.status == 'done'){
                    this.alerts = result.data.items
                    this.$emit('updateTitle');
                    this.$emit('updateNotifications', result.data.items.length)
                    this.$emit('itemsAlerts', this.typeClear, this.alerts)
                } else {
                    this.$toast.error(this.$t('err'))
                }   
            })
        },
        open(item) {
            // Метод для відкривання повідомлення
            this.$emit('open', item)
        },
        clearAlert() {
            // Метод для видалення повідомлень
            this.$emit('openAccept', this.typeClear, this.alerts)
        },
        readSelectNotice(){
            // Метод для позначення вибраних повідомлень як прочитаних
            this.$emit('openAccept', this.typeClear, this.readCheckbox)
            this.readCheckbox = []
        },
        month(e) {
            // Метод для отримання назви місяця за його номером
            return getMonth(e)
        },
        mutateNotice(e) {
            // Метод для отримання об'єкту з параметрами для стилізації повідомлення
            return mutateNoticeAlerts(e)
        },
        nameWorker(e) {
            // Метод для отримання імені робітника за його ідентифікатором
            return nameWorker(e)
        },
    },
    computed: {
        user() {
            /*
                Повертає дані користувача з глобального стору.
            */
            return storeS.userbase
        },
    }
}
</script>

<style>
.canvNotice .onlineBl .tab-pane{
    max-height: 100%!important;
    overflow: auto;
}
.canvNotice .tab-content {
    height: 100vh;
}

.canvNotice .onlineBl .tab-pane::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.canvNotice .onlineBl .tab-pane::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.canvNotice .onlineBl .tab-pane::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
</style>