<template>
    <tablecustom @search="searchB" :columns="columns" :rows="filteredItems" @open="open"/>

    <!-- <viewboxref v-if="testshowModalclient == true" @close="testshowModalclient = false" :obj="this.client"></viewboxref> -->

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewboxref from '@/views/clientnew/index.vue'
import modal from '@/components/modal.vue'
import { Clients } from "@/API.js"
import { storeS } from '@/store'

let apiServe = new Clients();

export default {
    props: ['obj'],
    components: { 
        tablecustom, 
        viewboxref, 
        modal 
    },
    data() {
        return {
            form: {},
            oldRow: "",
            search: "",
            showModal: false,
            item: "", 
            testshowModalclient: false,
            number: "",
            user_name: "",
            client: "",
            columns: [
                {
                    name: this.$t("ID"),
                    text: "user_id",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("phone_num"),
                    text: "user_login",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("date"),
                    text: "user_date",
                    align: "left",
                    status: true,
                    copy: true
                }
          ],
        }
    },
    created(){
        this.form = this.obj;
    },
    methods: {
        getdata(value, oldValue){
            if(value != undefined){
                this.form = value;
            }
        },
        searchB(e){
            this.search = e
        },
        open(e){
            this.item = e;
            // this.getClient(e.user_login)
        },
        getClient(customNumber) {
            this.number = customNumber
            this.eventBus.emit('getClient', {status: true, number: this.number, form: this.form})
        }
    },
    mounted(){
    },
    watch:{
        obj(value, oldValue){
            this.getdata(value, oldValue)
            // ("value", value)
            // ("oldValue", oldValue)
        }
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.user_id.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.user_login.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ;
                })
        },
    }

}
</script>