<template>
    <!-- <div class="card">
        <div class="card-body">
            <h6 class="card-title mb-3 flex-grow-1 text-start"><b>Последний комментарий</b></h6>
            <div class="alert alert-info" role="alert">
                {{ filteredItems[filteredItems.length-1].text }}
            </div>
        </div>
    </div> -->

    <b-row v-if="showComment">
      <b-col>
        <comment :obj="this.sklad" :number="this.number" :name="this.name"/>
      </b-col>
    </b-row>

    <!-- компонент таблицы -->
    <tablecustom 
        v-if="this.obj != null" 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        :createdform="true" 
        @sortBy="sortBy"
        @create="showComment = !showComment" 
        @open="open" 
    />

    <viewbox v-if="showModal" @close="showModal = false" :obj="cardApplic"  @remove="remove"></viewbox>
</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewbox from '@/views/invoices/view.vue'
import comment from './comment.vue'
import { Invoices } from '@/API.js';

let apiServe = new Invoices();

export default {
    props: ['obj', 'sklad', 'number', 'name'],
    components: { tablecustom, viewbox, comment },
    data() {
        return {
            form: {},
            showComment: false,
            search: "",
            cardApplic: "",
            showModal: false,
            sort: "Asc",
            columns: [
            {
              name: this.$t("Comments"),
              text: "text",
              align: "left",
              status: true,
              copy: true,
              width: "70" 
            },
            {
              name: this.$t("Manager"),
              text: "user",
              align: "left",
              status: true,
              copy: true,
              width: "15"
            },
            {
              name: this.$t("date_time"),
              text: "date",
              align: "right",
              status: true,
              copy: false,
              width: "15"
            },
            // fix all
          ],
        }
    },
    created(){
        this.form = this.obj;
    },
    methods: {
        getdata(value){
            if(value != undefined){
                this.form = value;
            }
        },
        open(e){
            var text = []
            var id_inv;
            text = e.text.split(' ');
            text[0] == 'Виставлений' ? id_inv = text[2] : id_inv = '-';
            if(id_inv != '-'){
                apiServe.getInv(id_inv).then(res => {
                    this.cardApplic = res;
                    this.showModal = true;
                })
            }
        },
        searchB(e){
            this.search = e
        },
        create(){
            this.$emit('openblockComment')
        },
        sortBy(prop) {
            if (this.sort === "Asc") {
                this.form.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
                this.sort = "Desc";
            } else if (this.sort === "Desc") {
                this.form.sort((a, b) => (a[prop] > b[prop] ? -1 : 1));
                this.sort = "Asc";
            }
        },
    },
    watch:{
        obj(value, oldValue){
            this.getdata(value)
        }
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.text.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.user.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>