<template>
  <!-- 
    Вікно телефонії 
    - дозволяє дзвонити за номером
    - копіювати номер
    - подивитись історію 
    - та інше :)
  -->
  
  <teleport to="body">

    <div
      class="offcanvas-phone show"
      id="voip"
      :style="`left:${this.voipPosition.left};top:${this.voipPosition.top};`"
      style="width: 300px; z-index: 100000; border: none;"
      tabindex="1"
      @mousedown="dragVoip($event)"
    >

      <!-- Прелоадер -->
      <preloaderTable v-if="this.preloaderTable == true" />

      <!-- Карточка VoIP -->
      <b-card 
        style="padding: 0; margin: 0; border-radius: 20px; width: 300px"
        :style="this.fullSize == false ? 'border-bottom-right-radius: 0 !important; border-bottom-left-radius: 0 !important; padding-top: 5px !important' : 'border-radius: 20px !important;'"
      >
        <div class="p-3 text-center" style="padding: 0 !important">
          <!-- Верхня панель з кнопками -->
          <div class="row" id="topPanel">
            <div class="col">
              <div class="btn nav-btn row" style="padding: 0">

                <!-- Кнопка закриття вікна -->
                <button
                  type="button"
                  :style="
                    this.settingSip.connected == true ? 'display:none' : ''
                  "
                  @click="this.$emit('close')"
                  class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"
                >
                  <i class="ri-close-fill align-bottom"></i>
                </button>
                
                <!-- Кнопка закриття вікна -->
                <button
                  v-if="this.settingSip.connected == true && this.fullSize == true"
                  type="button"
                  @click="offFullScreen"
                  class="btn btn-soft-warning btn-icon btn-sm fs-16 close-btn-email"
                >
                  <i class="ri-fullscreen-exit-line align-bottom"></i>
                </button>
                
                <!-- Кнопка закриття вікна -->
                <button
                  v-if="this.settingSip.connected == true && this.fullSize == false"
                  type="button"
                  @click="onFullScreen"
                  class="btn btn-soft-warning btn-icon btn-sm fs-16 close-btn-email"
                >
                  <i class="ri-fullscreen-line align-bottom"></i>
                </button>

                <!-- Кнопка підключення/відключення до SIP -->
                <button
                  style="margin-left: 5px;"
                  type="button"
                  @click="
                    this.settingSip.connected == false
                      ? this.connectToSip(this.settingSip)
                      : this.disconnectSip()
                  "
                  :class="
                    this.settingSip.connected == false
                      ? 'btn-soft-danger'
                      : 'btn-soft-success'
                  "
                  class="btn btn-icon btn-sm fs-16 close-btn-email"
                >
                  <i class="ri-base-station-line"></i>
                </button>

              </div>
            </div>

            <!-- Інформація про користувача та статус телефонії -->
            <div class="col text-center">
              <h5 class="fs-16 mb-1">
                <!-- Ім'я Користувача та номер в СІП -->
                <a class="link-primary username noselect">
                  {{ this.settingSip.name }} ({{ this.settingSip.login }})
                </a>
              </h5>

              <p 
                class="text-muted" 
                style="margin: 0"
                v-if="this.fullSize == true">
                <!-- Йде перевірка по статусу телефонії -->
                <i
                  :class="`ri-checkbox-blank-circle-fill me-1 align-bottom text-${
                    this.settingSip.connected == false ? 'danger' : 'success'
                  }`"
                ></i>
                {{ this.settingSip.connected == false ? "Offline" : "Online" }}
              </p>

            </div>

            <!-- Кнопка історії дзвінків -->
            <div class="col">
              <div class="user-chat-nav d-flex m-2">
                <button
                  v-if="this.fullSize == false && this.settingSip.status == 'busy'"
                  @click="this.hangUp()"
                  style="text-align: center; margin: 0; margin-right: 10px; padding-top: 5px; border: none; bakcground-color: #00000000"
                  class="text-muted" 
                >
                  <i class='bx bx-phone-call' style="font-size: 24px; color: red"></i>
                </button>
                <!-- якщо є номери, то з'являється кнопка, що дає можливість подивитися історію дзвінків -->
                <button
                  v-if="this.numbers?.length > 0 && this.fullSize == true"
                  @click="this.showhistory = !showhistory"
                  class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="ri-history-fill align-bottom"></i>
                </button>
                <!-- якщо є номери, то з'являється кнопка, що дає можливість подивитися історію дзвінків -->
                <!-- <button
                  @click="this.eventBus.emit('addNote', {status: true})"
                  class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class='bx bx-edit align-bottom'></i>
                </button> -->
                <p 
                  class="text-muted" 
                  style="margin: 0"
                  v-if="this.fullSize == false">
                  <!-- Йде перевірка по статусу телефонії -->
                  <i
                    :class="`ri-checkbox-blank-circle-fill me-1 align-bottom text-${
                      this.settingSip.connected == false ? 'danger' : 'success'
                    }`"
                  ></i>
                  {{ this.settingSip.connected == false ? "Offline" : "Online" }}
                </p>
              </div>
            </div>

          </div>

          <!-- блок, при натисканні на котрий, вікно телефонії не можна перетягувати  -->
          <div class="dontDrag" v-show="this.fullSize == true">
            <div class="col-12">
                <!-- якщо є номери, то з'являється кнопка, що дає можливість подивитися історію дзвінків -->
                <!-- <button
                  v-if="this.noteArray.length > 0"
                  @click="this.eventBus.emit('showNotesWindow', {data: this.noteArray})"
                  class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class='bx bx-notepad'></i>
                </button> -->
            </div>
            <div class="d-flex gap-2 justify-content-center">
              <!-- повзунки нашлатування звуку -->
              <div
                class="input-group"
                v-if="this.settingSip.connected == true"
                style="
                  display: grid;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div>
                  <span><i class="bx bx-headphone"></i></span>
                  <input
                    type="range"
                    id="volume"
                    name="volume"
                    step="1"
                    min="0"
                    max="100"
                    v-model="this.volume"
                    @change="showVal('call', this.volume)"
                  />
                  <label for="volume">{{ this.volume }}</label>
                </div>

                <div>
                  <span><i class="bx bx-music"></i></span>
                  <input
                    type="range"
                    id="not"
                    name="not"
                    step="1"
                    min="0"
                    max="100"
                    v-model="this.not"
                    @change="showVal('notification', this.not)"
                  />
                  <label for="not">{{ this.not }}</label>
                </div>
              </div>
            </div>

            <!-- Відображення часу розмови, якщо телефонія зайнята -->
            <div
              class="d-flex gap-2 justify-content-center"
              v-if="
                this.settingSip.connected == true &&
                this.settingSip.status == 'busy'
              "
            >
              <div>
                <span
                  class="time"
                  style="
                    font-size: 14px;
                    font-family: 'Courier New', Courier, monospace;
                  "
                >
                  {{ this.settingSip.resulttime }}
                </span>
              </div>
              


            </div>

            <div
              class="d-flex gap-2 justify-content-center"
              v-if="
                this.settingSip.connected == true &&
                this.settingSip.status == 'busy' &&
                (
                  this.settingSip.login == 305 ||
                  this.settingSip.login == 306 ||
                  this.settingSip.login == 310 ||
                  this.settingSip.login == 307 ||
                  this.settingSip.login == 303 ||
                  this.settingSip.login == 311
                )
              "
              style="margin: 5px 0"
            >
              <!-- Кнопка, що кличе на поміч при дзвінку -->
              <div>
                <button
                  class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"
                  style="
                    font-size: 14px;
                    font-family: 'Courier New', Courier, monospace;
                    color: 'white'
                  "
                  @click="SOS_Button"
                >
                  SOS
                </button>
              </div>
            </div>

            <div class="d-flex gap-2 justify-content-center">
              <div class="input-group" v-if="this.settingSip.connected == true">
                <!-- інпат на вказування номера -->
                <input
                  type="text"
                  @keydown="logKey($event)"
                  min="1"
                  max="20"
                  class="form-control number-input"
                  aria-label="Recipient's username with two button addons"
                  v-model="this.number"
                  :disabled="this.settingSip.status == 'busy'"
                />
                
                <!-- мьютити мікрофон -->
                <button
                  v-if="this.settingSip.status == 'busy'"
                  class="btn"
                  :class="
                    this.settingSip.mute == false ? 'btn-warning' : 'btn-danger'
                  "
                  type="button"
                  @click="
                    this.settingSip.mute == false
                      ? mute(
                          this.settingSip.incomingSessionVue,
                          this.settingSip
                        )
                      : unMute(
                          this.settingSip.incomingSessionVue,
                          this.settingSip
                        )
                  "
                >
                  <i
                    class="bx"
                    :class="
                      this.settingSip.mute == false
                        ? 'bx-volume-mute'
                        : 'bx-volume'
                    "
                  ></i>
                </button>

                <div><p></p></div>
                <!-- пуста строка -->

                <!-- callStart - почати виклик, hangUp - скинути виклик -->
                <button
                  v-if="
                    this.settingSip.callType !== 'incoming' &&
                    this.settingSip.status == 'free'
                  "
                  @click="this.callStart(this.settingSip)"
                  class="btn btn-success"
                  type="button"
                >
                  <i class="bx bxs-phone"></i>
                </button>

                <button
                  v-if="
                    this.settingSip.callType !== 'incoming' &&
                    this.settingSip.status == 'busy'
                  "
                  @click="this.hangUp()"
                  class="btn btn-danger"
                  type="button"
                >
                  <i class="bx bxs-phone-off"></i>
                </button>

                <!-- Отримати акаунт клієнта -->
                <button
                  v-if="this.settingSip.status == 'free'"
                  @click="this.getClient()"
                  class="btn btn-info"
                  type="button"
                >
                  <i class="ri-file-user-line"></i>
                </button>
                <!-- відкриття клавіатури -->
                <button
                  @click="this.showNumbers = !this.showNumbers"
                  class="btn btn-warning" 
                >
                  <i class='bx bx-dialpad-alt'></i>
                </button> 

              </div>
            </div>
          </div>

          <div
            class="d-flex gap-2 justify-content-center"
            v-if="this.settingSip.callType == 'incoming'"
          >
            <div>
              <b-card>
                <div class="p-2">
                  <h5 class="fs-15 mb-3">
                    <b>{{ $t("incomingCall") }}</b>
                  </h5>
                  <div class="vstack gap-2">
                    <div class="border rounded border-dashed p-2">
                      <div class="d-flex align-items-center">
                        <div
                          class="flex-grow-1 overflow-hidden"
                          style="width: 100%"
                        >
                          <h5 class="fs-13 mb-1">
                            <a
                              href="#"
                              class="text-body text-truncate d-block"
                              >{{ this.settingSip.incomingNumber == '+380966576574' ? "Борис, скидуйте" : this.settingSip.incomingNumber}}</a
                            >
                          </h5>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div
                          class="flex-grow-1 overflow-hidden"
                          style="width: 100%"
                        >
                        <!--  
                            answer - відповісти на виклик, hangUp - скинути виклик. 
                            Є перевірка на статус опертора
                        -->
                          <button
                            :style="
                              this.settingSip.status == 'free'
                                ? 'width:50%'
                                : 'width:100%'
                            "
                            @click="
                              this.settingSip.status == 'free'
                                ? this.answer(
                                    this.settingSip.incomingSessionVue,
                                    this.number
                                  )
                                : this.hangUp()
                            "
                            class="btn"
                            :class="
                              this.settingSip.status == 'free'
                                ? 'btn-success'
                                : 'btn-danger'
                            "
                            type="button"
                          >
                            <i
                              class="bx"
                              :class="
                                this.settingSip.status == 'free'
                                  ? 'bxs-phone-incoming'
                                  : 'bxs-phone-off'
                              "
                            ></i>
                          </button>
                          <button
                            :style="
                              this.settingSip.status == 'free'
                                ? 'width:50%'
                                : 'display:none'
                            "
                            @click="this.hangUp()"
                            class="btn"
                            :class="'btn-danger'"
                            type="button"
                          >
                            <i class="bx" :class="'bxs-phone-off'"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
          </div>

          <!-- <b-button variant="outline-dark" @click="showhistory = !showhistory" class="waves-effect waves-light mt-3"><i class="ri-history-fill align-bottom"></i> Показать историю</b-button> -->
        </div>
      </b-card>

      <!-- Нотатка -->
      <!-- <b-card 
        style="margin: 10px" 
        v-if="
          this.settingSip.callType === 'incoming' &&
          this.this.noteArray.find(el => {JSON.parse(el.form).number == this.number})
        "
      >
        res 
      </b-card> -->

      <!-- клавіатура -->
      <b-card style="margin: 10px" v-show="this.showNumbers">
        <div class="row align-items-center number_section">
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('1')">1</p>
          </div>
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('2')">2</p>
          </div>
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('3')">3</p>
          </div>
        </div>
        <div class="row align-items-center number_section">
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('4')">4</p>
          </div>
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('5')">5</p>
          </div>
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('6')">6</p>
          </div>
        </div>
        <div class="row align-items-center number_section">
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('7')">7</p>
          </div>
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('8')">8</p>
          </div>
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('9')">9</p>
          </div>
        </div>
        <div class="row align-items-center number_section">
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('#')">#</p>
          </div>
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('0')">0</p>
          </div>
          <div class="col-4">
            <p class="fs-16 number" @click="sendInfo('*')">*</p>
          </div>
        </div>
      </b-card>

      <!-- блок перегляду історії викликів   -->
      <b-card v-show="showhistory" style="margin-top: 10px">
        <div class="p-2 overflow-pont" style="height: 200px; overflow: auto">
          <h5 class="fs-15 mb-3">
            <b>{{ $t("Story") }}</b>
          </h5>
          <div class="vstack gap-2">
            <div
              class="border rounded border-dashed p-2"
              v-for="(item, index) in this.numbers"
              :key="index"
              style="max-height: 200px; overflow: auto"
            >
              <div class="row">
                <div class="col-6">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title bg-light text-secondary rounded fs-20"
                      >
                        {{ index + 1 }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <button
                    type="button"
                    style="margin-right: 5px;"
                    class="btn btn-outline-secondary btn-icon waves-effect waves-light"
                    @click="copy(String(item.userPhone).replace('+', ''))"
                  >
                    <i class="bx bxs-copy-alt"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-success btn-icon waves-effect waves-light"
                    @click="this.number = item.userPhone"
                  >
                    <i class="bx bxs-phone"></i>
                  </button>
                </div>
                <div
                  class="row justify-content-center"
                  style="text-align: center; margin-top: 10px;"
                >
                  <div class="col-12">
                    <h5 class="fs-13 mb-1">
                      <a href="#" class="text-body text-truncate d-block">{{
                        item.userPhone
                      }}</a>
                    </h5>
                  </div>
                  <!-- <div class="text-muted">{{ item.name }}</div> -->
                  <div class="row">
                    <div class="text-muted col-sm-12 col-xs-12 col-md-6">
                      {{ item.startDatetime }}
                    </div>
                    <div class="text-muted col-sm-12 col-xs-12 col-md-6">
                      {{ item.endDatetime }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <!--end offcanvas-body-->
    <audio id="localAudio" autoPlay muted></audio>
    <audio id="remoteAudio" autoPlay></audio>
  </teleport>
</template>

<script>
import axios from "axios";
import { storeS } from "@/store"; // глобальне сховище
// import { state } from "@/socket";
import { storeSip } from "@/telephony/telephony.js";
import { telephony } from "@/telephony/telephony.js";
import { Clients, Breaks, ATS } from "@/API.js"; // клас для взаємодії з API 
import preloaderTable from "../globaltable/preloader"; // прелоадер
// import JsSIP from "../../jssip-3.5.7";
import {copy} from '@/usabilityScripts/copy.js'
import dbPromise from "../../indexedDB";

let apiATS = new ATS();

export default {
  data() {
    return {
      showNote: false,
      volume: 0, // звук дзвінка
      preloaderTable: false, // завантаження вікна, спінер
      not: 0, // звук сповіщення
      ua: null, // об'єкт телефонії
      showhistory: false,
      number: "", // номер
      fullSize: true,
      showNumbers: false,
    };
  },
  props: ["numberClient", "formApplic"],
  // beforeDestroy() {
  // // удаление слушателя из шины событий
  // this.eventBus.off('saveApplic')
  // },
  methods: {
    sendInfo(a) {
      this.ua.sendInfo(a) // взаємодія з клавоб
    },
    SOS_Button(){
      const telegramToken = '6316239814:AAHt5JxDpETHy6FhSNLupxg-cRXTVP62vGk';
      const telegramApiUrl = `https://api.telegram.org/bot${telegramToken}/sendMessage`;
      const telegramChatId = '369445058'; 

      const logMessage = `
        <b>АХТУНГ</b> Співробітнику <b>${this.settingSip.name.toUpperCase()} (${this.settingSip.login.toUpperCase()})</b> попався душний клієнт! Номер душніли: <b>${this.settingSip.incomingNumber}</b>
      `;

      axios.post(telegramApiUrl, {
        chat_id: telegramChatId,
        text: logMessage,
        parse_mode: 'html'
      });
    },
    // отримуєм історію дзвнків по айді користувача
    getData() {
      apiATS.historyCall(this.user.userid).then((result) => {
        if (result.status == "done") {
          this.numbers = result.data.items;
        }
      });
    },
    logKey(e) {
      // виклик по кнопці "Enter", якщо поле номера заповнено
      if (this.number !== "" && e.key == "Enter") {
        this.callStart(this.settingSip);
      }
    },
    startTimer() {
      // таймер, що працює через інтервали. Заносимо в змінну для його збереження та для можливості зупинки
      this.settingSip.timer = setInterval(() => {
        hours = 0;
        minutes = 0;
        seconds = 0;
        var current = new Date();
        var old = this.settingSip.startCallTime;

        var seconds = Math.floor((current - old) / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);

        this.settingSip.resulttime =
          hours + " ч " + (minutes % 60) + " м " + (seconds % 60) + " с ";
      }, 1000);
    },
    // функція закінчення таймеру та очистки фінального часу через 3 секунди
    endTimer() {
      clearInterval(this.settingSip.timer);
      setTimeout((this.settingSip.resulttime = ""), 3000);
    },
    mute() {
      // відправляємо подію на м'ют
      this.ua.Mute(this.settingSip.incomingSessionVue);
    },
    unMute(a) {
      // відправляємо подію на розм'ют
      this.ua.unMute(a);
    },
    getClient() {
      // отримуємо клієнта
      this.eventBus.emit("getClient", { status: true, number: this.number });
    },
    autoMute() {
      // функція автом'юту, що на даний момент не юзається
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;
      if (navigator.getUserMedia) {
        navigator.getUserMedia(
          {
            audio: true,
          },
          function (stream) {
            var audioContext = new AudioContext();
            console.log("audioContext", audioContext);
            var analyser = audioContext.createAnalyser();
            console.log("analyser", analyser);
            var microphone = audioContext.createMediaStreamSource(stream);
            console.log("microphone", microphone);
            var javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
            console.log("javascriptNode", javascriptNode);

            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 1024;

            microphone.connect(analyser);
            analyser.connect(javascriptNode);
            javascriptNode.connect(audioContext.destination);

            javascriptNode.onaudioprocess = function () {
              var array = new Uint8Array(analyser.frequencyBinCount);
              analyser.getByteFrequencyData(array);
              var values = 0;

              var length = array.length;
              for (var i = 0; i < length; i++) {
                values += array[i];
              }

              var average = values / length;
              // console.log('average -40', Math.round(average - 40))
              // if(Math.round(average - 40) > 10){
              //     this.mute()
              // }
            };
          },
          function (err) {
            console.log("The following error occurred: " + err.name);
          }
        );
      }
    },
    showVal(a, e) {
      // записування значень звуку в localStorage. Якщо параметр = "call", то кидаємо еміт на динамічну зміну звуку під час дзвінка
      var b;
      if (e != 100) {
        b = parseFloat("0." + e);
        sessionStorage.setItem(a, b);
      } else {
        b = 1;
        sessionStorage.setItem(a, 1);
      }
      if (a == "call") {
        this.eventB.emit("changeVolume", {
          status: true,
          volume: b,
        });
      }
    },
    copy(item){
      // копіювання
      if(copy(item) == true) {
          this.$toast.success(this.$t('Copied'));
      } else {
          this.$toast.error(`Fucking mistake, contact Borys`);
      }
      return
    },
    // checkMicro(){
    //     console.log('nav', navigator.mediaDevices);
    //     if (navigator.mediaDevices) {
    //             const constraints = window.constraints = {
    //                 audio: true,
    //                 video: false
    //             }
    //             navigator.mediaDevices.getUserMedia(constraints).then(this.handleSuccess).catch(this.handleError)
    //     }
    // },
    // handleSuccess(stream){
    //     console.log(stream);
    //     if (window.stream) {
    //         window.stream.getAudioTracks().forEach(track => track.stop());
    //         window.stream = null;
    //     } else {
    //         const audio = document.createElement('audio');
    //         audio.controls = true;
    //         audio.autoplay = true;
    //         // window.stream = stream;
    //         audio.srcObject = stream;

    //         console.log(stream)

    //         stream.oninactive = function() {
    //             console.log('Stream ended');
    //         };
    //     }
    // },
    // handleError(e){
    //     console.log("ruin", e.message);
    // },

    connectToSip(storeSip, command) {
      // під'єднуємося до телефонії та юзаємо апі для відображення повідомлення в сокет
      apiATS.registration().then((result) => {
        if (result.status == "done") {
          // відправляємо кастомні події на телефонію
          this.ua.registration(storeSip, {
            // реєстрація трішки не пройшла, пишемо в консоль і не змінюємо статус
            registrationFailed: (e) => {
              this.$toast.error(this.$t('error'))
              console.error("Щось пішло не так при авторизації", e);
            },
            registered: () => {
              // реєстрація успішна. Ставимо статус "вільний" і змінюємо статус телефонії на "З'єднанно"
              this.settingSip.status = "free";
              this.settingSip.connected = true;
              console.info("Зареєстровано");
            },
          });
        }
      });
    },

    disconnectSip() {
      // від'єднуємося від телефонії через апі, що видасть повідомлення в сокет
      apiATS.unregistration().then((result) => {
        if (result.status == "done") {
          // відсиляємо кастомні події, "слухаючи" відповіді
          this.ua.unreg({
            // у випадку успішного дісконекту ми змінюємо статус телефонії та виключаємо спінер
            disconnect: () => {
              this.preloaderTable = false;
              console.info("Відключенно");
              this.settingSip.connected = false;
            },
            unregistered: () => {
              // остаточно отримуємо підтвердження, що ми деавторизувалися
              console.info("Деавторизовано");
            },
          });
          this.$emit("close");
        }
      });
    },
    callStart(storeSip) {
      // починаємо дзвінок
      if (
        this.number == "" ||
        /[a-z]/i.test(this.number) ||
        /[а-я]/i.test(this.number)
      ) {
        // перевірка номеру на наявність букв та чи на фактичну наявність номеру в полі
        this.$toast.error(this.$t("enterPhoneNumber"));
        return;
      }

      if (this.settingSip.connected == false) {
        // якщо чомусь телефонія на законекчина, то спочатку конект оформлюємо
        this.connectToSip(this.settingSip);
      }

      var numcode = [
        "039",
        "067",
        "068",
        "096",
        "097",
        "098",
        "050",
        "066",
        "095",
        "099",
        "063",
        "073",
        "093",
        "091",
        "092",
        "089",
        "094",
      ];

      numcode.forEach((el) => {
        // перевірка на те, чи вказаний український номер просто через "0**"
        // доставляємо 38, якщо так і було
        if (String(this.number).substr(0, 2) == el) {
          this.number = "38" + String(this.number);
        }
      });

      this.ua.call(String(this.number), {
        // якщо ми дійшли сюди, то починається фестиваль
        connecting: (e) => {
          // конектиномося до сесії
          this.settingSip.incomingNumber = this.number;
          console.log("connecting to session", e);
          // змінюємо статус та запускаємо таймер + дату старту дзвінка фіксуємо
          // перший гудок "фронтовський"
          this.settingSip.status = "busy";
          this.settingSip.startCallTime = new Date();
          this.startTimer();
        },
        progress: (event) => {
          // дзвінок пішов, будемо чути гудки 
          console.log("call is in progress", event);
        },
        failed: (e) => {
          // щось пішло не так і дзвінок відмінився
          document.getElementById("remoteAudio").pause();
          document.getElementById("remoteAudio").src = "";
          console.log("fail", e);
          // стопаємо звук

          if (e.cause == "Busy") {
            this.$toast.error(this.$t("Busy"));
          }
          // якщо причина "Зайнято" (якась мрачна діч останнім часом), то видаємо альорт

          //активуємо звук скинутого дзвінка і міняємо статуси 
          document.getElementById("remoteAudio").volume = sessionStorage.getItem("notification");
          document.getElementById("remoteAudio").autoPlay = true;
          document.getElementById("remoteAudio").src = storeSip.sounds.rejected;
          document.getElementById("remoteAudio").src = "";

          this.settingSip.callType = "";
          this.settingSip.status = "free";

          // заглушка, щоб не багався звук

          this.eventB.emit("fixSound", true);

          // заглушка, щоб не багався звук
        },
        ended: (e) => {
          // дзвінок закінчено і нічого не померло
          document.getElementById("remoteAudio").src = "";
          this.settingSip.mute = false;
          document.getElementById("remoteAudio").volume = sessionStorage.getItem("notification");
          document.getElementById("remoteAudio").autoPlay = true;
          //активуємо звук скинутого дзвінка і міняємо статуси + зупиняємо таймер + отримуємо актуальну історію дзвінків
          document.getElementById("remoteAudio").src = storeSip.sounds.rejected;
          this.endTimer();

          console.log("call ended with cause: ", e);
          this.settingSip.callType = "";
          this.settingSip.status = "free";
          this.getData();
        },
        confirmed: (e) => {
          // дзвінок прийнятий клієнтом. Кидаємо звук відповіді і починаємо розмову
        
          document.getElementById("remoteAudio").autoPlay = true;
          document.getElementById("remoteAudio").volume = sessionStorage.getItem("notification");
          document.getElementById("remoteAudio").src = storeSip.sounds.asnwered;

          console.log("call confirmed", e);

          // setInterval(this.autoMute(), 2000)
        },
      });
    },

    hangUp() {
      // відправляємо подію на скидування дзвінка і зупиняємо таймер
      this.endTimer();
      this.ua.hangup();
    },

    answer(a) {
      // вводимо у змінну вхідний номер, відправляємо подію на відовідь
      // фіксуємо дату початку дзвінка та стартуємо таймер
      this.number = this.settingSip.incomingNumber.match(/^.\d{1,}$/)[0];
      this.ua.answer(a, this.number);
      this.settingSip.startCallTime = new Date();
      this.startTimer();
    },

    dragVoip(e){
      console.log(e)
      var fullSize = this.fullSize
      var voip = document.getElementById("voip");
      var safeZone = document.querySelector('.dontDrag')
      var coords = {top: voip.getBoundingClientRect().top, left: voip.getBoundingClientRect().left};
      var shiftX = e.pageX - coords.left;
      var shiftY = e.pageY - coords.top; //зчитуємо координати

      if(safeZone.contains(e.target)) {
        return false
      } 

      voip.style.position = "absolute";
      // document.body.appendChild(voip);
      moveAt(e);

      voip.style.zIndex = 1070; // над другими элементами

      function moveAt(e) {
        var leftStyle = e.pageX - shiftX + "px";
        storeS.voipPosition.left = leftStyle;
        voip.style.left = leftStyle;
        console.log(fullSize)
        if(fullSize == true) {
          var topStyle = e.pageY - shiftY + "px";
          voip.style.top = topStyle;
          storeS.voipPosition.top = topStyle;
        }
        // переміщуємо вікно під час дригань миші
      }

      document.onmousemove = function (e) {
        moveAt(e);
      };

      voip.onmouseup = function () {
        document.onmousemove = null;
        voip.onmouseup = null; // після відпускання миші стопимо
      };
    },

    onFullScreen() {
      this.fullSize = true

      var voip = document.getElementById("voip");
      
      var leftStyle = (document.querySelector(".main-content").getBoundingClientRect().width / 2) + "px";
      var topStyle = (document.querySelector(".main-content").getBoundingClientRect().height / 2) + "px";
      document.querySelector('body').style.overflowY = 'auto'

      voip.style.left = leftStyle;
      voip.style.top = topStyle;

      storeS.voipPosition.left = leftStyle;
      storeS.voipPosition.top = topStyle;
      storeS.voipPosition.fullScreen = true

    },
    offFullScreen() {
      this.fullSize = false

      var voip = document.getElementById("voip");
      var topStyle = document.querySelector(".main-content").getBoundingClientRect().height - document.querySelector('#topPanel').clientHeight;
      document.querySelector('body').style.overflowY = 'hidden'
      
      storeS.voipPosition.top = topStyle + 'px'
      storeS.voipPosition.fullScreen = false
      voip.style.top = topStyle + 'px'

    },
  },
  created() {
    // dbPromise.getAllNotes()
    this.getData();

    // в картці клієнта було натиснуто на виклик, телефонуємо
    this.eventBus.off("updateNumber");
    this.eventBus.on("updateNumber", (status) => {
      if (status.status == true) {
        this.number = status.number;
        console.log(this.settingSip)
        if(this.settingSip.connected === true){
          this.callStart(this.settingSip)
        } else {
          this.connectToSip(this.settingSip, 'call')
        }
      }
    })
    
    // вхідний дзвінок закінчено, треба змінити статус
    this.eventB.off("statusFree");
    this.eventB.on("statusFree", (status) => {
      if (status.status == true) {
        this.settingSip.status = "free";
      }
    });

    // перевірка наявності значень гучності повідомлень в сховищі 
    if (
      sessionStorage.getItem("notification") == undefined ||
      sessionStorage.getItem("notification") == null
    ) {
      // якщо відсутнє, то вкидуємо середнє
      sessionStorage.setItem("notification", "0.5");
      this.not = "50";
    } else {
      // якщо присутнє, то через деякі особливості подачі даних змінюємо під свій формат наявні дані
      console.log(sessionStorage.getItem("notification"));
      this.not =
        sessionStorage.getItem("notification") != 0 &&
        sessionStorage.getItem("notification") != 1
          ? sessionStorage.getItem("notification").split(".")[1]
          : sessionStorage.getItem("notification");
    }

    // теж саме, що з гучністю повідомлень. Описано вище
    if (
      sessionStorage.getItem("call") == undefined ||
      sessionStorage.getItem("call") == null
    ) {
      sessionStorage.setItem("call", "0.5");
      this.volume = "50";
    } else {
      this.volume =
        sessionStorage.getItem("call") !== 0 &&
        sessionStorage.getItem("notification") !== 1
          ? sessionStorage.getItem("call").split(".")[1]
          : sessionStorage.getItem("call");
    }
    // робимо змінну функцією для подальшого звернення
    this.ua = telephony();
  },
  mounted() {

    this.eventBus.off('getDataNotes')
    this.eventBus.on('getDataNotes', async (status) => {
      if(status.status == true) {
        // dbPromise.getAllNotes()
      }
    })

    this.eventB.off("incoming");
    this.eventB.on("incoming", (status) => {
      console.log(this.fullSize, status)
      if(this.fullSize == false) {
        this.onFullScreen() // включити фуллсайз
      }
    }); // подія вхідного дзвінка

    document.addEventListener('keydown', ($event) => {
      console.log($event)
      if(($event.metaKey == true && $event.code == 'KeyM' && $event.ctrlKey == true) || ($event.altKey == true && $event.code == 'KeyM' && $event.ctrlKey == true)) {
        voip.onmousemove = null
      }
    })

    // якщо в сховищі є трігер на автоматичну авторизацію в телефонії, то ми це робимо
    // if (
    //   localStorage.getItem("UA") !== null &&
    //   localStorage.getItem("UA") == "true"
    // ) {
    //   this.connectToSip(this.settingSip);
    // }

    // якщо в пропсі переданий номер клієнта, то вставляємо його
    if (this.numberClient) {
      this.number = this.numberClient;
    }
    this.eventB.off("switchNum");
    this.eventB.on("switchNum", (status) => {
      this.number = status.number;
    });
  },
  components: { preloaderTable },
  computed: {
    width() {
      return {
        a: document.querySelector("#volume").clientWidth,
        b: document.querySelector("#not").clientWidth,
      };
    },
    user() {
      return storeS.userbase;
    },
    voipPosition() {
      return storeS.voipPosition;
    },
    settingSip() {
      return storeSip.info;
    },
    usersATS() {
      return storeS.usersATS;
    },

    break() {
      return storeS.break;
    },
    noteArray() {
      return storeS.notesATS
    }
  },
};
</script>

<style scoped>
div.p-2.overflow-pont {
  height: 200px;
}

.card {
  margin: 0px 10px;
}
.offcanvas {
  bottom: auto;
}
.offcanvas-phone {
  border-radius: 20px;
  border: 3px solid #4b4b4ba1;
  position: absolute;
  z-index: 1070;
  background-color: rgb(241, 246, 248) !important;
  left: 590px;
  top: 95px;
  box-shadow: 5px 7px 11px 0px rgb(0 0 0 / 39%);
  width: 400px;
}

[data-layout-mode="dark"] .offcanvas-phone {
  border: 1px solid white;
  padding: 5px;
  box-shadow: 5px 7px 11px 0px rgb(109 109 109 / 39%);
}

[data-layout-mode="dark"] .card {
  width: auto !important;
}

[data-layout-mode="dark"] .link-primary {
  color: white;
}

[data-layout-mode="dark"] .offcanvas-phone {
  background-color: rgb(241 246 248 / 0%) !important;
  box-shadow: none;
  padding: 0;
  border: 1px solid #ffffff !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.number{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  text-align: center;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 20px;
  padding: 10px;
  -webkit-box-shadow: 2px 5px 14px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 5px 14px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 5px 14px -6px rgba(0, 0, 0, 0.75);
  font-size: 20px;
}

.number:active{
  background-color: aquamarine;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

</style>
