<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label class="form-label">{{ $t('specifyDateTime') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setTime(1)" style="margin-right: 5px;">{{ $t('AfterOneHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setTime(2)" style="margin-right: 5px;">{{ $t('AfterTwoHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setTime(3)" style="margin-right: 5px;">{{ $t('AfterThreeHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setTime(14)" style="margin-right: 5px;">{{ $t('AtLunch') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setTime(16)">{{ $t('AtNight') }}</span>
                </div>
                <div class="row g-2 list_filtr" >
                    <flatpickr v-model="date" :config="config" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label">{{ $t('Comment') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('NoAnswer')" style="margin-right: 5px;">{{ $t('NoAnswer') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('AskedToDialLater')" style="margin-right: 5px;">{{ $t('AskedToDialLater') }}</span>
                </div>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium"  @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="toDelay"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { storeS } from '@/store';

export default{
    components: {
        dialogBox,
        flatpickr: Vueflatpickr
    },
    props: ['id'],
    data(){
        return{
            form: {
                comment: '',
                delayedTime: '',
                delayedDate: ''
            },
            date: "today",
            config: {
                mode: "single",
                minDate: "today",
                altInput: true,
                locale: "",
                enableTime: true,
                dateFormat: "Y-m-d H:i",
                time_24hr: true
            },
        }
    },
    created() {
        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }
    },
    methods: {
        setTime(e){
            const today = new Date()
            const finalDate = new Date(today)
            const currentHours = today.getHours()
            // finalDate.setHours(currentHours + e)lunch
            finalDate.setHours(e == 14 ? e : e == 16 ? e : currentHours + e)
            var dateStr = finalDate.getFullYear() + "-" +
            ("00" + (finalDate.getMonth() + 1)).slice(-2) + "-" +
            ("00" + (finalDate.getDate())).slice(-2) + " " +
            ("00" + finalDate.getHours()).slice(-2) + ":" +
            ("00" + finalDate.getMinutes()).slice(-2) + ":" +
            ("00" + finalDate.getSeconds()).slice(-2);
            this.date = dateStr
            this.change();
        },
        change(){
            var arrDate = String(this.date)
            arrDate = arrDate.split(' ')
            this.form.delayedDate = arrDate[0];
            this.form.delayedTime = arrDate[1];
        },
        toDelay(){
            this.$emit('toDelay', this.id, this.form)
            this.$emit('close')
        }
    }
}
</script>