<template>
    <!-- Компонент для вибору статусу штрафа -->

    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
        >
            {{ $t('Status') }}
        </label>
        <br />
        <div class="row g-2 list_filtr" >
            <!-- Вибір "Усі" -->
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioStatus" id="all_status" v-model="status" :value="''">
                    <label class="form-check-label" for="all_status">{{ $t('all') }}</label>
                </div>
            </div>
            <!-- Цикл для виведення радіобатонів для кожного статусу-->
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioStatus" :id="'status'+index" v-model="status" :value="value.value">
                        <label class="form-check-label" :for="'status'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            status: "", // Обраний статус
            opt: [ // Масив зі списком статусів
                {
                    // очікує на підтвердження
                    name: this.$t('AwaitingСonfirmation'),
                    value: "waitingСonfirmation",
                },
                {
                    // підтверджено
                    name: this.$t('Confirmed'),
                    value: "confirmed",
                },
                {
                    // анульовано
                    name: this.$t('annul'),
                    value: "annul",
                }
            ]
        }
    },
    methods: {
        change(){
            // Метод, що викликається при зміні фільтру
            this.$emit('change', 'status', this.status)
        },
    },
    computed: {
    }
}
</script>