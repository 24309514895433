<template>
    
    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        :objParams="objParams"
        @open="open"
    />

    <viewTax 
        v-if="showModal"
        @close="showModal = false"
        :obj="objCard"
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewTax from './view'
import { mutateTaxTypeCode } from '@/usabilityScripts/globalMutate'

export default{
    props: ['obj', 'companyId'],
    components: {
        tablecustom,
        viewTax
    },
    data(){
        return{
            form: '',
            objCard: '',
            showModal: false,
            objParams: {
                search: ''
            },
            columns: [
                {
                    name: "ID",
                    text: "taxId",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("Name"),
                    text: "taxTitle",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("TaxRate"),
                    text: "value",
                    align: "right",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("TaxTypeCode"),
                    text: "cno",
                    align: "right",
                    status: true,
                    copy: true,
                    mutate: (item) => this.mutateTaxTypeCode(item)
                },
                {
                    name: this.$t("TaxPercentage"),
                    text: "percent",
                    align: "right",
                    copy: true,
                    status: true,
                    mutate: (item) => item ? item + "%" : '-'
                },
            ],
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj
        },
        open(e){
            this.objCard = e;
            this.showModal = true;
        },
        mutateTaxTypeCode(e){
            return mutateTaxTypeCode(e)
        },
        searchB(e){
            this.objParams.search = e
        },
    },
    computed: {
        filteredItems: function() {
            return this.form
                // по пошуку
                .filter(item => {
                    return this.objParams.search == '' || item.taxTitle.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>