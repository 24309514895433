<template>
    <dialogmodal>
        <template v-slot:title>
            {{ this.$t('Payment') }}
        </template>
        <template v-slot:body>
            <table class="table">
                <thead>
                    <tr>
                    <th scope="col">{{this.$t('Name')}}</th>
                    <th scope="col">{{this.$t('Quantity')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="items, key  in this.data" :key="key">
                        <th scope="row">{{ this.mutateKey(key) }}</th>
                        <td>{{ items }}</td>
                    </tr>
                </tbody>
            </table>
        </template>
        <template v-slot:footer-bottom></template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue'

export default {
    props: ['shortapplic'],
    components: {
        dialogmodal
    },
    data() {
        return {
            data: {}
        }
    },
    mounted(){
        console.log('obj', this.shortapplic)

        Object.keys(this.shortapplic.data).forEach((element) => {
            if(
                element !== "paid_apps" &&
                element !== "summ" &&
                element !== "lastsum"
            ) {
                this.data[element] = this.shortapplic.data[element]
            }
        });
    },
    methods: {
        mutateKey(key) {
            let name = ""

            switch(key) {
                case "count_products":
                    name = this.$t('Number_of_goods')
                    break;
                case "count_techcards":
                    name = this.$t('Number_of_technical_cards')
                    break;
                case "count_terminals":
                    name = this.$t('NumberofTerminals')
                    break;
                case "count_tradepoints":
                    name = this.$t('numberTT')
                    break;
                case "tariffed_terminals_count":
                    name = this.$t('NumberofTerminals') + ` (${this.$t('Charged')})`
                    break;
                case "tariffed_tradepoints_count":
                    name = this.$t('numberTT') + ` (${this.$t('Charged')})`
                    break;
                case "datepayments_before_recalc":
                    name = this.$t('datepayments_before_recalc')
                    break;
                case "count_offlineprro":
                    name = this.$t('count_offlineprro')
                    break;
                case "count_servsync":
                    name = this.$t('count_servsync')
                    break;
            }
            return name
        }
    }
};
</script>