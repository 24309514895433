<template>
    <b-card>
       <div class="card-header">
           <h5 class="card-title mb-0"><b>{{ $t('fopInfo') }}</b></h5>
       </div>
       <div class="card-body">
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                       <div :class="`avatar-title bg-primary rounded-circle fs-16 text-white`" >
                           <i :class="`bx bx-info-circle`"></i>
                       </div>
                   </div>
                   <div :class="`flex-grow-1 overflow-hidden`" >
                       <p class="mb-1 fs-16">{{ $t('fopRegistrationDate') }} :</p>
                       <div class="ctext-wrap" style="display:flex">
                           <div class="ctext-wrap-content d-flex">
                               <h6 :class="`fs-14 mb-0 fw-normal copy`">{{this.form.fopRegistrationDate}}</h6>
                               <i class="ri-file-copy-2-fill fs-16 copybtn text-secondary" style="margin-left: 10px;" @click="copy(this.form.fopRegistrationDate)"></i>
                           </div>
                       </div>
                   </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                       <div :class="`avatar-title bg-primary rounded-circle fs-16 text-white`" >
                           <i :class="`bx bx-info-circle`"></i>
                       </div>
                   </div>
                   <div :class="`flex-grow-1 overflow-hidden`" >
                       <p class="mb-1 fs-16">{{ $t('FOPactivity') }} :</p>
                       <div class="ctext-wrap" style="display:flex">
                           <div class="ctext-wrap-content d-flex">
                               <h6 :class="`fs-14 mb-0 fw-normal copy`">{{this.form.activity}}</h6>
                               <i class="ri-file-copy-2-fill fs-16 copybtn text-secondary" style="margin-left: 10px;" @click="copy(this.form.fopRegistrationDate)"></i>
                           </div>
                       </div>
                   </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                       <div :class="`avatar-title bg-primary rounded-circle fs-16 text-white`" >
                           <i :class="`bx bx-info-circle`"></i>
                       </div>
                   </div>
                   <div :class="`flex-grow-1 overflow-hidden`" >
                       <p class="mb-1 fs-16">{{ $t('fopType') }} :</p>
                       <div class="ctext-wrap" style="display:flex">
                           <div class="ctext-wrap-content d-flex">
                               <h6 :class="`fs-14 mb-0 fw-normal copy`">{{this.form.fopType}}</h6>
                               <i class="ri-file-copy-2-fill fs-16 copybtn text-secondary" style="margin-left: 10px;" @click="copy(this.form.fopRegistrationDate)"></i>
                           </div>
                       </div>
                   </div>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import {copy} from '@/usabilityScripts/copy.js'

export default {
    data () {
        return {
            form: ''
        }
    },
    props: ['obj'],
    methods: {
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        getData() {
            this.form = this.obj
        }
    },
    created(){
        this.getData()
    },
}
</script>

<style lang="css" scoped>

</style>