<template>
    <b-card>
        <h6 class="fw-semibold text-uppercase mb-3">{{ $t('refLink') }}</h6>
        <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="this.obj.refLink" disabled="">
            <button class="btn btn-primary" @click="copy(obj.refLink)" type="button"><i class="bx bxs-copy-alt"></i></button>
            <a target="_blank" :href="this.obj.refLink" class="btn btn-success" type="button"><i class="ri-external-link-line"></i></a>
        </div>
    </b-card>
    <tablecustom @search="searchB" :columns="columns" @open="open" :rows="filteredItems"/>
</template>

<script>
import tablecustom from '@/components/globaltable/index';
import {copy} from '@/usabilityScripts/copy.js'

export default {
    props: ['obj', 'client'],
    components: { 
        tablecustom, 
    },
    data() {
        return {
            form: {},
            oldRow: "",
            search: "",
            rows: [],
            columns: [
                {
                    name: this.$t("ID"),
                    text: "account_id",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("phone_num"),
                    text: "phone",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("date"),
                    text: "register_date",
                    align: "left",
                    status: true,
                    copy: true
                }
          ],
        }
    },
    created(){
        this.rows = this.obj.referrals.reverse();
    },
    methods: {
        open(e){
            if(e.phone != undefined) {
                this.eventBus.emit('modalInModal', {
                    status: true,
                    data: e,
                    client: this.client,
                    action: 'openReferal'
                })
                this.$emit('close')
            } else {
                this.$toast.error(this.$t('PhoneError'))
            }
            
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        searchB(e){
            this.search = e
        },
    },
    mounted(){
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.rows)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.phone.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.account_id.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ;
                })
        },
    }

}
</script>