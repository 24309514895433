<template>
    <b-row lg="4">
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('dateEvent') }}</h4>
                <flatpickr v-model="date" :config="configDate" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
            </div>
        </b-row>
        <b-row lg="4">
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('timeEvent') }}</h4>
                <flatpickr v-model="time" :config="configTime" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
            </div>
        </b-row>
</template>

<script>
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { storeS } from '@/store';

export default {
    components: {
        flatpickr: Vueflatpickr
    },
    data(){
        return {
            date: "",
            time: '',
            configTime: {
                
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true

            },
            configDate: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
            }
        }
    },
    created() {
        this.eventBus.off('calendar filters')
        this.eventBus.on('calendar filters', data => {
            this.date = data.form.nameFilter
            this.time = data.form.value
        })
    },
    methods: {
        change(a,b){
            this.$emit('change', a,b)
        },
    },
    computed: {
    }
}
</script>