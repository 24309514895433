<template>
    <!-- 
        Модальне вікно створення групи для ярликів відповіді 
    -->

    <dialogBox >
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:body>
            <!-- Назва групи -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('nameGroup') }}</h4>
                <input type="text" class="form-control" id="contactNumber" v-model="form.groupName">
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div>

                <!-- 
                    Кнопка "Закрити" 
                    - закриває модальне вікно
                -->
                <button 
                    class="btn link-success fw-medium" 
                    @click="$emit('close')"
                >
                    <i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} 
                </button>

                <!-- 
                    Кнопка "Додати" 
                    - додає групу
                -->
                <button 
                    v-if="(this.objGroup == null || this.objGroup == '') && (this.form.groupId == '' || this.form.groupId == undefined) "
                    type="button" 
                    class="btn btn-success" 
                    v-on:click="add"
                >
                    {{ $t('Add') }}
                </button>

                <!-- 
                    Кнопка "Редагувати" 
                    - редагує поточну групу
                -->
                <button 
                    v-if="this.objGroup != null && this.form.groupId != undefined "
                    type="button" 
                    class="btn btn-info" 
                    @click="edit"
                >
                    {{ $t('Edit') }}
                </button>
            </div>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модального вікна
import { Shortcuts } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Shortcuts();

export default {
    props: ['objGroup'],
    components: { dialogBox },
    data() {
        return {
            form:{
                groupId: "",
                groupName: ""
            },
        };
    },
    created(){

        // Заповнення форми даними, якщо група передана для редагування
        if(this.objGroup != null){
            this.form.groupId = this.objGroup.value
            this.form.groupName = this.objGroup.label
        }
        
    },
    methods: {
        add() {
            /**
                * Метод для додавання нової групи.
                * Викликає API-запит для додавання групи та сповіщає про успішну операцію.
            */

            apiServe.addGroup(this.form).then(res => {
                if(res.status == 'done'){
                    this.$toast.success(this.$t('сreatedT'));
                    this.$emit('close')
                    this.eventBus.emit('saveGroup', true)
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка створення групи'; 
                    var statusRequest = result.status;
                    var api = 'addGroup';
                    var fileRequest = 'src/views/shortcuts/group/create.vue';
                    var params = this.form;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        edit(){
            /**
                * Метод для редагування існуючої групи.
                * Викликає API-запит для редагування групи та сповіщає про успішну операцію.
            */

            apiServe.editGroup(this.form.groupId ,this.form).then(res => {
                if(res.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'));
                    this.$emit('close')
                    this.eventBus.emit('saveGroup', true)
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "groupId": this.form.groupId,
                        "params": this.form
                    }

                    var errorText = 'Помилка редагування групи'; 
                    var statusRequest = result.status;
                    var api = 'editGroup';
                    var fileRequest = 'src/views/shortcuts/group/create.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
}
</script>