<template>
    <!-- 
        Модальне вікно з налаштуваннями каси/терміналу
    -->

    <modal 
        :title="form.terminalName" 
        :stylebody="'body'"
    >

        <!-- Статус каси -->
        <template v-slot:status>
            <span :class="`fs-12 badge bg-${form.active == 1 ? 'success' : 'danger'}`">{{ form.active == 1 ? $t('active') : $t('noactive') }}</span>
        </template>

        <template v-slot:head-bottom>
            <!-- Закрити вікно -->
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')" :title="$t('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <template v-slot:body>
            <!-- Таби -->
            <b-row>
                <b-col>
                    <div>
                        <b-tabs pills>
                            <!-- 
                                Таб "Основне"
                                - :obj - параметр-об'єкт з даними по касі
                            -->
                            <b-tab :title="$t('main')" active>
                                <div style="margin:20px 0px;">
                                    <mainTab 
                                        :obj="this.form"
                                    />
                                </div>
                            </b-tab>

                            <!-- 
                                Таб "Співробітники"
                            -->
                            <b-tab 
                                :title="$t('Employees')" 
                            >
                                <workersNew v-if="this.terminalWorkers" :obj="this.terminalWorkers"  />
                            </b-tab>

                            <!-- 
                                Таб "Пристрої"
                            -->
                            <b-tab 
                                :title="$t('devices')" 
                            >
                                <devicesNew v-if="this.terminalDevices" :devices="this.terminalDevices"  />
                            </b-tab>

                            <!-- 
                                Таб "Програми"
                            -->
                            <b-tab 
                                :title="$t('apps')" 
                            >
                                <appsNew :number="this.obj.ownerPhone" :apps="this.terminalApps" />
                            </b-tab>
                        </b-tabs>
                    </div>
                </b-col>
            </b-row>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal'
import mainTab from './tabs/main'
import workersNew from '../../workersNew/index'
import devicesNew from '../../devicesNew/index'
import appsNew from '../../appsNew/index'

export default{
    props: ['obj', 'workers', 'devices', 'apps'],
    components: {
        modal,
        mainTab,
        workersNew,
        devicesNew,
        appsNew
    },
    data(){
        return{
            form: '',
            terminalWorkers: [],
            terminalDevices: [],
            terminalApps: [],
        }
    },
    created(){
        this.form = this.obj

        this.newWorkers();
        
        this.newDevices();

        this.newApps();
    },
    methods: {
        newWorkers(){
            // Перебір співробітників, які мають доступ до каси
        
            this.terminalWorkers = []
            // ID каси/терміналу
            const terminalId = this.obj.terminalId;

            this.terminalWorkers = this.workers.filter(worker => {
                // Перевірка посади "owner"
                const isOwner = worker.workerJob === 'owner';

                // Перевірка доступу до терміналу
                const hasTerminalAccess = worker.perms.terminals.some(terminal => terminal.terminalId === terminalId);

                return isOwner || hasTerminalAccess;
            });
        },
        newDevices(){
            // Перебір пристроїв, які додали до каси

            // ID каси/терміналу
            const terminalId = this.obj.terminalId;

            // Відфільтрувати девайси, які мають доступ до обраного терміналу
            this.terminalDevices = this.devices.filter(device => {
                return device.terminalId === terminalId;
            });
        },
        newApps(){
            // Перебір додатків, які активували до каси

            // ID каси/терміналу
            const terminalId = this.obj.terminalId;

            // Відфільтрувати додатки, які мають доступ до обраного терміналу або не мають жодних даних про термінал
            this.terminalApps = this.apps.filter(app => {
                // Якщо додаток має термінали, перевірте, чи terminalId є серед терміналів
                if (app.terminals && app.terminals.length > 0) {
                    return app.terminals.includes(terminalId);
                }

                // Якщо додаток не має жодних даних про термінал, включіть його
                return !app.terminals;
            });
        },
        // copy(item){
        //     try {
        //         navigator.clipboard.writeText(item);
        //         this.$toast.success(this.$t('Copied') + ` - ` + item);
        //     } catch {
        //         this.$toast.error(`Fucking mistake, обратитесь к Максу`);
        //     }
        // },
    }
}
</script>

<style>
ul.typeGoods {
  list-style-type: none;
}

ul.typeGoods li {
  display: inline-block;
}

input[type="radio"][id^="cb"] {
  display: none;
}

ul.typeGoods label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

ul.typeGoods label:before {
  /* background-color: white; */
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

ul.typeGoods label img {
  /* height: 100px;
  width: 100px; */
  height: 160px;
  width: 210px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

ul.typeGoods :checked + label {
  border-color: #ddd;
}

ul.typeGoods :checked + label:before {
  content: "✓";
  border-color: #2cb29c;
  background: #2cb29c;
  transform: scale(1);
}

ul.typeGoods :checked + label img {
  transform: scale(0.9);
  box-shadow: 0 0 5px #333;
  z-index: -1;
}
</style>