<template>
    <!-- Прелоадер -->

    <transition name="fade">
        <div id="preloader" class="prTable">
            <div id="status">
                <!-- Сам лоадер -->
                <div :class="`spinner-border text-${statusTheme == 'dark' ? 'white' : 'primary' } avatar-md`" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>

                <!-- Відображення відсотка завантаження -->
                <label v-if="uploadPercentage != 0" class="progressBarFile fs-20 fw-semibold text-success m-1">{{uploadPercentage}}%</label>
            </div>
        </div>
    </transition>     
</template>

<script>
import { storeS } from '@/store'; // глобальне сховище даних

export default {
    name: "preloader",
    mounted() {
    },
    created() {
        // console.log('store', storeS);
    },
    methods: {
    },
    computed: {
        uploadPercentage(){
            // Відсоток завантаження
            return storeS.uploadPercentage
        },
        statusTheme(){
            // Отримання статусу теми
            return storeS.nightTheme
        }
    }
}
</script>

<style>
    .prTable {
        background-color: transparent!important;
        position: fixed!important;
    } 
    .progressBarFile {

    }
</style>