<template>
    <!-- 
        Модальне вікно для підтвердження видалення з можливістю перегляду незбережених даних.
    -->
    
    <dialogmodal>
        <template v-slot:body>
            <!-- Заголовок та підтекст модального вікна -->
            <div class="mt-4 text-center" >
                <h3><b>{{ $t('confirm_close') }}?</b></h3>
                <p class="text-muted fs-15 mb-4">{{ $t('unsaved_data') }}</p>
                 <!-- Вивід кнопок та інформації про незбережені дані -->
                <b-row>
                    <b-col lg="12" v-for="(item, idx) in tasksArr" v-bind:key="idx" style="margin-top: 10px;">

                        <!-- Кнопка з інформацією про завдання та можливість видалення -->
                        <button 
                            class="btn btn-danger r waves-effect waves-light" 
                            @click="openPrev(idx)" 
                            style="max-width: 50%; word-break: break-all;"
                        >
                            <div><span>{{item.mainInfo}}</span></div>
                            <div><span>{{item.otherInfo}}</span></div>
                        </button>

                        <!-- Кнопка для видалення конкретного завдання -->
                        <button 
                            class="btn btn-danger r waves-effect waves-light" 
                            style="margin-left: 10px;" 
                            @click="deleteOne(idx)"
                        >
                            <i class='bx bxs-trash'></i>
                        </button>

                    </b-col>
                </b-row>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 justify-content-center remove">
                <button class="btn btn-info" @click="deleteAll()">{{$t("deleteAll")}}</button>
                <button class="btn btn-primary" @click="createNew">{{$t("create_new")}}</button>
            </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue'
import { storeS } from '@/store.js'

export default {
    props: ['notFinish'], // Масив незавершених завдань, переданий як властивість з зовнішнього компонента.
    components: {
        dialogmodal
    },
    data(){
        return{
            tasksArr: [], // Масив об'єктів, що містить інформацію про незбережені заявки
        }
    },
    created(){
        // Заповнює масив tasksArr інформацією про незбережені завдання на підставі категорій.
        this.notFinish.forEach(element => {
            if(element.category == 'applic') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).userPhone + ' ' + (JSON.parse(element.form)).userName,
                    otherInfo: (JSON.parse(element.form)).comment
                })
            } else if(element.category == 'dump') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).userPhones,
                    otherInfo: (JSON.parse(element.form)).comment
                })
            } else if(element.category == 'project') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).projectName,
                    otherInfo: (JSON.parse(element.form)).description.replace(/<\/?[^>]+(>|$)/g, "")
                })
            } else if(element.category == 'tasks') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).userPhone + ' ' + (JSON.parse(element.form)).userEmail,
                    otherInfo: (JSON.parse(element.form)).appName
                })
            } else if(element.category == 'presentation') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).userPhone + ' ' + (JSON.parse(element.form)).userEmail,
                    otherInfo: (JSON.parse(element.form)).taskName
                })
            } else if(element.category == 'ticket') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).problemEnvironment + ' ' + (JSON.parse(element.form)).ticketName,
                    otherInfo: (JSON.parse(element.form)).type
                })
            } else if(element.category == 'invoice') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).sellerName + ' ' + (JSON.parse(element.form)).sellerPhone,
                    otherInfo: (JSON.parse(element.form)).provider
                })
            } else if(element.category == 'FAQCategory') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).categoryName,
                    otherInfo: (JSON.parse(element.form)).lang
                })
            } else if(element.category == 'FAQPost') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).lang + ' ' + (JSON.parse(element.form)).question,
                    otherInfo: (JSON.parse(element.form)).answer
                })
            } else if(element.category == 'KWPost') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).lessonName,
                    otherInfo: (JSON.parse(element.form)).lang
                })
            } else if(element.category == 'KWCategory') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).categoryName,
                    otherInfo: (JSON.parse(element.form)).lang
                })
            } else if(element.category == 'shortcut') {
                this.tasksArr.push({
                    mainInfo: (JSON.parse(element.form)).shortcutName,
                    otherInfo: (JSON.parse(element.form)).message
                })
            }
        });
    }, 
    methods: {
        deleteAll() {
            // Метод для видалення всіх незбережених даних

            this.tasksArr = []
            let openRequest = indexedDB.open("store", 1);
                openRequest.onsuccess = function() {
                    let db = openRequest.result;
                    let transaction = db.transaction("data", "readwrite"); // (1)
                    
                    // получить хранилище объектов для работы с ним
                    let data = transaction.objectStore("data"); // (2)}
                    data.clear()
                }
            this.$emit('close')
        },
        deleteOne(item) {
            // Метод для видалення конкретного незбереженого завдання за індексом.

            this.tasksArr.splice(item, 1)
            sessionStorage.setItem('notFinish', JSON.stringify(this.notFinish))
            let openRequest = indexedDB.open("store", 1);
                openRequest.onsuccess = function() {
                    let db = openRequest.result;
                    let transaction = db.transaction("data", "readwrite"); // (1)
                    
                    // получить хранилище объектов для работы с ним
                    let data = transaction.objectStore("data"); // (2)
                    let categoryIndex = data.index("сategory_idx");
                    console.log(JSON.parse(sessionStorage.getItem('notFinish')), item)

                    let request = categoryIndex.getAll(JSON.parse(sessionStorage.getItem('notFinish'))[0].category)

                    request.onsuccess = function() {
                        if (request.result !== undefined) {
                            request.result.forEach(el=> {
                                if(request.result.indexOf(el) == item) {
                                    let deleteReq = data.delete(el.id)
                                        deleteReq.onsuccess = function() {
                                            console.log('All okey') //видалення, щоб не було дублікатів
                                            sessionStorage.removeItem('notFinish')
                                        }
                                }
                            })   
                        }
                    };

                    request.onerror = function() {
                        alert('Щось пішло не так, перезапустіть будь ласка сторінку')
                    }
                }
            if(this.tasksArr.length == 0) {
                this.$emit('close')
            }
        },
        createNew: function() {
            // Метод для закриття модального вікна та відкриття форми для створення нового запису.

            this.$emit('close')
            this.$emit("createNew");
        },      
        openPrev(item) {
            // Метод для відкриття попереднього запису для перегляду.
            this.$emit('close')
            this.$emit('openOld', this.notFinish[item])
        },
    },
    computed:{
        checks(){
            // Об'єкт зі станом налаштувань, отриманий зі стору.
            return storeS.checks
        },
    },
}
</script>
