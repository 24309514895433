<template>
    <!-- Компонент для вибору фільтру по працівнику для штрафів -->

    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('Employee') }} ( {{ $t('Fine') }} )</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <!-- Поле для пошуку працівників -->
            <input 
                v-model="search"
                class="form-control mb-2"
            />
            <!-- Радіокнопка "Усі працівники" -->
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radiofinedWorker" id="allfinedWorker" v-model="finedWorkerId" :value="''">
                    <label class="form-check-label" for="allfinedWorkerId">{{ $t('all') }}</label>
                </div>
            </div>
            <!-- Цикл для виведення радіокнопок для кожного працівника -->
            <template v-for="(value, index) in filteredItems" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radiofinedWorkerId" :id="'finedWorkerId'+index" v-model="finedWorkerId" :value="value.workerId">
                        <label class="form-check-label" :for="'finedWorkerId'+index">{{ value.workerName }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store'; // глобальне сховище даних

export default {
    components: { Multiselect },
    data(){
        return {
            search: '', // Поле для пошуку працівників
            workerArr: [], // Масив для зберігання обраного фільтру по працівнику
            finedWorkerId: "", // Масив із списком працівників
            workersList: [
                { label: this.$t('all'), value: '' }
            ]
        }
    },
    created() {
        // Заповнення списку працівників
        for(var item in this.workers){
            this.workersList.push({
                label: this.workers[item].workerName,
                value: this.workers[item].workerId,
            })
        }
    },
    methods: {
        change(){    
            // Метод, що викликається при зміні фільтру        
            this.$emit('change', 'finedWorkerId', this.finedWorkerId)
        },
    },
    computed: {
        workers(){
            // Повертає список працівників зі стору
            return storeS.workers
        },
        filteredItems: function() {
            // Фільтрує працівників за полем пошуку
            return Object.values(this.workers)
                // По полю пошуку
                .filter(item => {
                    return this.search == '' || item.workerName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>