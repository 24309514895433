<template>
    <!-- Компонент для відображення повідомлень з кріспу -->


    <div class="notification-actions mt-2 pb-1" id="notification-actions" style="display: block;" v-if="this.obj.length > 0" :key="componentKey">
        <div style="margin-bottom: 100px;">
            <div v-for="item in this.obj" :key="item">
                <div class="text-reset notification-item d-block dropdown-item copy border" >
                    <div class="d-flex" >

                        <div class="avatar-xs me-3" @click="open(item)">
                            <span class="avatar-title bg-soft-info text-info rounded-circle fs-16">
                            <i :class="`bx bx-message-square-add`"></i>
                            </span>
                        </div>

                        <div class="flex-1" @click="open(item)">

                            <!-- Нік клієнта -->
                            <h6 class="mt-0 mb-1 lh-base">
                                {{ item.meta.nickname }}
                            </h6>

                            <!-- Повідомлення останнє -->
                            <p class="mb-1" >
                                {{ $t('Message') }} : <span v-html="item.last_message"></span>
                            </p>

                            <!-- IP клієнта -->
                            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span>IP: {{ item.meta.ip }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Блок для відображення, якщо немає повідомлень -->
    <div v-else>
        <div class="tab-pane fade p-4 active show" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
            <div class="empty-notification-elem">
                <div class="text-center pb-5 mt-2">
                    <h6 class="fs-18 fw-semibold lh-base">{{ $t('YouhaveNoMessages') }}</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props: ['obj'],
    data(){
        return{
            msgs:''
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.msgs = this.obj
        },
        open(e){
            // відкриття повідмолення в крісп
            
            window.open('https://app.crisp.chat/website/'+ e.website_id +'/inbox/'+e.session_id+'/', '_blank');
        }
    }
}
</script>