<template>
    <!-- 
        Відображення історії входу користувача
    -->
    
    <b-card>          
        <div class="mt-2 mb-3 border-bottom pb-2" >
            <h5 class="card-title">{{ $t('loginHistory') }}</h5>
        </div>

        <!-- Відображення інформації про вхідні записи -->
        <template v-for="item in items" :key="item">
            <!-- Кожен елемент історії входу -->
            <div class="d-flex align-items-center mb-3" >
                <div class="flex-shrink-0 avatar-sm" >
                    <div class="avatar-title bg-light text-primary rounded-3 fs-18" >
                        <i :class="mutateImgWorker(item.device).img"></i>
                    </div>
                </div>

                 <!-- Інформація про вхід -->
                <div class="flex-grow-1 ms-3" >
                    <h6>{{ item.os }}</h6>
                    <p class="text-muted mb-0">{{ item.city }}, {{ item.ip }} - {{ item.datetime }} </p>
                </div>
            </div>
        </template>
    </b-card>
</template>

<script>
import { Users } from '@/API' // клас для взаємодії з API 
import { mutateImgWorker } from '@/usabilityScripts/globalMutate'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Users();

export default{
    props: ['id'],
    data(){
        return{
            form:'',
            items: '',
            objParams: {
                page: '1',
                pagelimit: '9999',
            },
        }
    },
    created(){
        // Ініціалізація компонента та отримання даних
        this.getdata();
    },
    methods: {
        getdata(){
            /**
                Отримує дані про історію входу з API та встановлює їх у властивості компонента.
            */

            apiServe.loginHistory(this.id, this.objParams).then(result => {
                if(result.status == 'done'){
                    this.items = result.data.items;
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "id": this.id,
                        "params": this.objParams
                    };

                    var errorText = 'Помилка отримання історії входу'; 
                    var statusRequest = result.status;
                    var api = 'loginHistory';
                    var fileRequest = 'src/views/users/view/formright/loginHistory/index.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        mutateImgWorker(e){
            /*
                Функція для застосування кольору за іконки до операційної системи
            */
            return mutateImgWorker(e)
        }
    }
}
</script>