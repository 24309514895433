<template>
    <!-- модальне вікно надсилання анонімного повідомлення -->
    
    <dialogBox >
        <template v-slot:title>{{ $t('anonMessage') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <div class="text_field">
                    <textarea class="form-control" :placeholder="$t('Message')" v-model="form.message" ></textarea>
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
                <div class="mt-3">
                    <button class="btn link-danger fw-medium"  @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
                    <button 
                        type="button" 
                        class="btn btn-success" 
                        v-on:click="create"
                    >
                        {{ $t('sendSms') }}
                    </button>
                </div>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { Wishes } from '@/API_Dash.js';
import { storeS } from '@/store.js'

let apiServe = new Wishes()

export default {
components: { dialogBox },
data() {
    return {
        // modalDialog: false,
        form:{
            message: '',
        },
    };
},
created(){
},
methods: {
    create() {
        apiServe.addWish(this.form).then(result => {
            if(result.status == 'done'){
                this.$toast.success(this.$t('MessageSentSuccessfully'));
                this.$emit('close')
            } else {
                this.$toast.error(this.$t('err'))
            }
        })
    },
},
computed: {
    user() {
        return storeS.userbase
    }
}
}
</script>

<style scoped>
    .text_field input {
        width: 100%;
        border: 1px #ced4da solid;
        border-radius: 5px;
        height: 40px;
        padding-left: 15px;
    }
</style>