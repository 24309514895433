<template>
    <!-- 
        Модальне вікно редагування повідомлення 
    -->

    <dialogBox >
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('Message') }}</template>
        <template v-slot:body>
            <!-- Поле для вводу нового повідомлення -->
            <div class="mb-3">
                <label class="form-label">{{ $t('Message') }}</label>
                <textarea class="form-control" rows="3" v-model="form.message" ></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-danger fw-medium"  @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            
            <!-- 
                Кнопка "Редагувати" 
                - надає змогу редагувати повідомлення
            -->
            <button 
                type="button" 
                class="btn btn-success" 
                @click="edit"
            >
                {{ $t('Edit') }}
            </button>
            
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'  // компонент модального вікна
import { Projects, Tickets } from '@/API' // класи для взаємодії з API 
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServeTickets = new Tickets();
let apiServeProjects = new Projects();

export default{
    components: {
        dialogBox,
    },
    props: ['id', 'type'],
    data(){
        return{
            form: {
                message: '', // відредаговане повідомлення
            },
        }
    },
    created() {
    },
    methods: {
        edit() {
            /**
                * Редагування обраного повідомлення
            */

            if(this.type == 'ticket') {
                // редагування повідомлення з чату тікету
                apiServeTickets.editMessage(this.id, this.form).then(result => {
                    if(result.status == 'done') {
                        this.$toast.success(this.$t('alertChanged'))
                        this.$emit('close')
                        this.$emit('getdata')
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var tempObj = {
                            "id": this.id,
                            "params": this.form
                        }

                        var errorText = 'Помилка редагування повідомлення тікету'; 
                        var statusRequest = result.status;
                        var api = 'editMessage';
                        var fileRequest = 'src/components/chat/action/edit.vue';
                        var params = tempObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            } else {
                // редагування повідомлення з чату проекту
                apiServeProjects.editMessage(this.id, this.form).then(result => {
                    if(result.status == 'done') {
                        this.$toast.success(this.$t('alertChanged'))
                        this.$emit('close')
                        this.$emit('getdata')
                    } else {    
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var tempObj = {
                            "id": this.id,
                            "params": this.form
                        }

                        var errorText = 'Помилка редагування повідомлення проекту'; 
                        var statusRequest = result.status;
                        var api = 'editMessage';
                        var fileRequest = 'src/components/chat/action/edit.vue';
                        var params = tempObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        }
    }
}
</script>