<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('titleCategory') }}</h4>
                <input type="text" class="form-control" id="contactNumber" v-model="form.categoryName">
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div>
                <!-- <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
                <button 
                    type="button" 
                    class="btn btn-success" 
                    v-on:click="add"
                >
                    {{ $t('Add') }}
                </button>
            </div>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { CategoriesProducts } from '@/API.js';
import { storeS } from '@/store.js'

let apiServe = new CategoriesProducts();

export default {
components: { dialogBox },
data() {
    return {
        form:{
            categoryName: "",
        },
    };
},
created(){
},
methods: {
    add() {
        if (!this.form.categoryName) {
            //перевіряємо чи вказана назва
            this.$toast.error(this.$t('EnterNameTicket'));
        } else {
            apiServe.addCategoryProducts(this.form).then(res => {
                if(res.status == 'done'){
                    this.$toast.success(this.$t('сreatedT'));
                    this.$emit('close')
                    this.eventBus.emit('saveProductsCateg', true)
                }
            })
        }
    },
},
computed: {
    user() {
        return storeS.userbase
    }
}
}
</script>