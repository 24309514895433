<template>
    <!-- модальное окно выполнения правки -->
    <dialogBox @close="this.$emit('close')">
        <template v-slot:title>{{$t('Decision')}}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" :placeholder="$t('Comment')"></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button 
                type="button" 
                class="btn btn-success" 
                @click="toComplete()"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'

export default {
    props: ['id'],
    components: {
        dialogBox
    },
    data(){
        return {
            form: {
                comment: "",
            },
        }
    },
    methods: {
        toComplete(){
            this.$emit('complete', this.form)
        }
    }
}
</script>