<template>
    <!-- 
        Компонент календаря

        Врятуй і збережу павшу душу, що зайшла в цей компонент щось фіксити.
        Цей код тримається на молитвах фронтів, що захотіли зробити красиво і +- чисто
    -->

    <div class="dropdownCalendar">
        <!-- Сам каледнар, що зникає при натискання вне його області. Має ключ для оновлення -->
        <div class="calendar p-2 shadowCal" :key="this.componentKey">
            <div class="calendar-fastButtons text-left" style="padding-right: 5px;">
                <div class="calendar-fastButtons-text">
                    <!-- Кнопки зі швидкими датами -->
                    <div v-for="items, i in fastButtons" :style="items.unit ? 'display: grid; grid-template-columns: 4fr 1fr; ' : ''" :class='items.class' :key="i">
                        <button type="button" :key="i" class="btn btn-ghost-info waves-effect waves-light w-100" style="text-align:left" :style="screenChecker(items) == true ? 'display: none' : ''" @click="items.value == 'Custom' ? this.createCustom() : setRange(items.value, items)">{{ items.title }}</button>
                        <button type="button" class="close" aria-label="Close" style="color: red" v-if="items.unit" @click="deleteCustom(items)"><span aria-hidden="true">&times;</span></button> 
                    </div>
                    
                </div>
            </div>
            <!-- Компонент з першим календарем -->
            <div class="calendar-startDate calendar-startDate-grid">
                <!-- Кнопки для скролінгу місяців  -->
                
                <!-- Якщо не вибрано перший місяць року, то просто скролимо на минулий місяць -->
                <button class="controlPanel-arrowLeft" v-if="showPickFirst === false" @click="this.prevMonth(null, 'prev', 'first')"><i class="ri-arrow-left-s-line"></i></button>
                <!-- Скролимо на останній місяць минулого року -->
                <button class="controlPanel-arrowLeft" v-else :disabled="this.pickYearOptions[0]?.value == 'Jan'" @click="prevYears('first')"><i class="ri-arrow-left-line" v-if="this.pickYearOptions[0]?.value != 'Jan'"></i></button>
                <div class="monthButtons">
                    <!-- Кнопка швидкого вибору місяця -->
                    <button class="btn btn-ghost-info waves-effect waves-light w-100" @click="this.pickMonth('first')">{{ this.$t(`${this.month(this.firstCalendarData.month + 1)}`) }}</button>
                    <!-- Кнопка швидкого вибору року -->
                    <button class="btn btn-ghost-info waves-effect waves-light w-100" @click="this.pickYear('first')">{{ this.firstCalendarData.year }}</button>
                </div>
                <!-- Кнопка наступного місяця, якщо зараз не останній місяць року. Просто переключення на наступний місяць -->
                <button class="controlPanel-arrowRight" v-if="showPickFirst === false" @click="this.nextMonth(null, 'next', 'first')"><i class="ri-arrow-right-s-line"></i></button>
                <!-- Кнопка наступного місяця, якщо зараз останній місяць року. Переключення на наступний рік і перший місяць -->
                <button class="controlPanel-arrowRight" :disabled="this.pickYearOptions[0]?.value == 'Jan'" v-else @click="nextYears('first')"><i class="ri-arrow-right-line" v-if="this.pickYearOptions[0]?.value != 'Jan'"></i></button>
                <!-- Кнопки для скролінгу місяців  -->
                
                <!-- Блок з датами -->
                <div class="dates">
                    <!--  Блок, куди виведуться місяці та роки,, коли ми захочемо швидко їх вибрати -->
                    <div class="pickYear" v-if="showPickFirst === true">
                        <button class="btn btn-ghost-info waves-effect waves-light w-100" v-for="item, i in pickYearOptions" @click="picked('first', item)" :key="i">{{item.name}}</button>
                    </div>
                    <!-- Таблиця з числами -->
                    <table style="text-align: center;" v-if="showPickFirst === false">
                        <thead>
                            <tr>
                                <!-- Дні тижня -->
                                <th scope="col" v-for="items, i in days" :key="i">{{  items.title }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Розраховані дні. Масив рахується динамічно, при кліці, в інший масив запишемо вибраний день -->
                            <tr v-for="items, i in this.actualMonthWeeks" :key="i">
                                <td 
                                    v-for="child, k in items" :key="k" 
                                    :class="child.class"
                                    class="kostyak"
                                    style="cursor: pointer;"
                                    :style="child.lastMonth == true || child.nextMonth == true ? 'color: gray' : ''" 
                                    @click="this.selectDay(child)"
                                >{{ child.dayOfMonth }}</td>
                                <!-- :class тут прописаний для динамічної зміни, в іншому випадку, не буде відбуватися оновлення без component key -->
                            </tr>
                        </tbody>
                        </table>
                </div>
            </div>
            <!-- Календар з другим місяцем поруч для зручності діапазону з двох дат -->
            <div class="calendar-endDate calendar-endDate-grid">
                <!-- Cтрілочки переключення місяців -->
                <button class="controlPanel-arrowLeft" v-if="showPickSecond === false" @click="this.prevMonth(null, 'prev', 'second')"><i class="ri-arrow-left-s-line"></i></button>
                <button class="controlPanel-arrowLeft" :disabled="this.pickYearOptions[0]?.value == 'Jan'" v-else @click="prevYears('second')"><i class="ri-arrow-left-line" v-if="this.pickYearOptions[0]?.value != 'Jan'"></i></button>
                <!-- Кнопки для швидкого вибору місяця та року -->
                <div class="monthButtons">
                    <button class="btn btn-ghost-info waves-effect waves-light w-100" @click="this.pickMonth('second')">{{ this.$t(`${this.month(this.secondCalendarData.month+1)}`) }}</button>
                    <button class="btn btn-ghost-info waves-effect waves-light w-100" @click="this.pickYear('second')">{{ this.secondCalendarData.year }}</button>
                </div>
                <!-- Cтрілочки переключення місяців -->
                <button class="controlPanel-arrowRight" v-if="showPickSecond === false" @click="this.nextMonth(null, 'next', 'second')"><i class="ri-arrow-right-s-line"></i></button>
                <button class="controlPanel-arrowRight" :disabled="this.pickYearOptions[0]?.value == 'Jan'" v-else @click="nextYears('second')"><i class="ri-arrow-right-line" v-if="this.pickYearOptions[0]?.value != 'Jan'"></i></button>
                <!-- Блок з числами -->
                <div class="dates">
                    <!-- Вікно для швидкого вибору місяців та року -->
                    <div class="pickYear" v-if="showPickSecond === true">
                        <button class="btn btn-ghost-info waves-effect waves-light w-100" v-for="item, i in pickYearOptions" @click="picked('second', item)" :key="i">{{item.name}}</button>
                    </div>
                    <!-- Таблиця днів та чисел -->
                    <table style="text-align: center;" v-if="showPickSecond === false">
                        <thead>
                            <!-- Дні тижня -->
                            <tr>
                                <th scope="col" v-for="items, i in days" :key="i">{{  items.title }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Розраховані дні. Масив рахується динамічно, при кліці, в інший масив запишемо вибраний день -->
                            <tr v-for="items, i in this.actualMonthWeeksSecondCalendar" :key="i">
                                <td
                                    v-for="child, k in items" :key="k" 
                                    style="cursor: pointer;"
                                    :class="child.class"
                                    class="kostyak"
                                    :style="child.lastMonth == true || child.nextMonth == true ? 'color: gray' : ''" 
                                    @click="this.selectDay(child)"
                                >{{ child.dayOfMonth }}</td>
                                <!-- :class тут прописаний для динамічної зміни, в іншому випадку, не буде відбуватися оновлення без component key -->
                            </tr>
                        </tbody>
                        </table>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import {getMonth} from '@/usabilityScripts/globalMutate.js'
import { storeS } from '../store'

export default {
    props: ['styleForSidebar', 'data'],
    data(){
        return{
            pickYearOptions: [],
            showPickSecond: false,
            showPickFirst: false,
            months: [
                {
                    name: this.$t('January'),
                    value: 'Jan'
                },
                {
                    name: this.$t('February'),
                    value: 'Feb'
                },
                {
                    name: this.$t('March'),
                    value: 'Mar'
                },
                {
                    name: this.$t('April'),
                    value: 'Apr'
                },
                {
                    name: this.$t('May'),
                    value: 'May'
                },
                {
                    name: this.$t('June'),
                    value: 'Jun'
                },
                {
                    name: this.$t('July'),
                    value: 'Jul'
                },
                {
                    name: this.$t('August'),
                    value: 'Aug'
                },
                {
                    name: this.$t('September'),
                    value: 'Sep'
                },
                {
                    name: this.$t('October'),
                    value: 'Oct'
                },
                {
                    name: this.$t('November'),
                    value: 'Nov'
                },
                {
                    name: this.$t('December'),
                    value: 'Dec'
                },
            ],
            componentKey: 0,
            inputDate1: '',
            inputDate2: '',
            selectedDays: [],
            showCalendar: false,
            day: '',
            secondCalendarData: {month: '', year: ''},
            firstCalendarData: {month: '', year: ''},
            days: [
                {
                    title: 'ПН',
                    value: 'Monday'
                },
                {
                    title: 'ВТ',
                    value: 'Tuesday'
                },
                {
                    title: 'СР',
                    value: 'Wednesday'
                },
                {
                    title: 'ЧТ',
                    value: 'Thursday'
                },
                {
                    title: 'ПТ',
                    value: 'Friday'
                },
                {
                    title: 'СБ',
                    value: 'Saturday'
                },
                {
                    title: 'ВС',
                    value: 'Sunday'
                },
            ],
            actualMonthWeeks: [],
            actualMonth:[],
            actualMonthWeeksSecondCalendar: [],
            actualMonthSecondCalendar:[],
            shortcuts: {
                day: '',
                year: '',
                month: ''
            },
            fastButtons: [
                {
                    title: this.$t('Last 7 days'),
                    value: 'Last 7 days',
                },
                {
                    title: this.$t('Last 14 days'),
                    value: 'Last 14 days',
                },
                {
                    title: this.$t('Last 3 months'),
                    value: 'Last 3 months',
                },
                {
                    // title: this.$t('Last 12 months'),
                    title: this.$t('InYear'),
                    value: 'Last 12 months',
                },
                {
                    title: this.$t('Month to date'),
                    value: 'Month to date',
                },
                {
                    title: this.$t('Today'),
                    value: 'today',
                },
                {
                    title: this.$t('Custom'),
                    value: 'Custom',
                },
            ],
            clickOnCalend: '',
        }
    },
    mounted() {
        console.log('DATA', this.data)
        if(this.styleForSidebar) {
            document.querySelector('.dropdownCalendar').style.marginRight = '200px' 
        }
        // перевірка кліку на каледнар
            document.querySelector('.calendar').addEventListener('click', () => {
                this.clickOnCalend = true
            }) 

            this.eventBus.off('click')
            this.eventBus.on('click', (status) => {
                if(status.status == true && document.querySelector('.calendar')) {
                    if(this.clickOnCalend === false) {
                        this.setRange('Today')
                        this.$emit('close')
                    } else {
                        this.clickOnCalend = false
                    }
                }
            })
        // перевірка кліку на календар
        switch (this.user.lang) {
            case 'uk':
                this.shortcuts.day = 'д'
                this.shortcuts.month = 'м'
                this.shortcuts.year = 'р'
            break;
            case 'ru':
                this.shortcuts.day = 'д'
                this.shortcuts.month = 'м'
                this.shortcuts.year = 'г'
            break;
            default:
                this.shortcuts.day = 'd'
                this.shortcuts.month = 'y'
                this.shortcuts.year = 'm'
            break;
        }

        this.day = new Date() // вираховуємо поточну дату

        this.createCalendar(this.day.getMonth(), this.day.getFullYear()) // створюємо перший календар
        // розраховуємо дату і місяць для другого календаря
        this.secondCalendarData.month = this.day.getMonth() == 10 ? (this.day.getMonth() == 11 ? 1 : 0) : this.day.getMonth() + 1
        if(this.secondCalendarData.month == 12) {
            this.secondCalendarData.month = 0
        }
        this.secondCalendarData.year = this.day.getMonth() >= 10 ? this.day.getFullYear() + 1 : this.day.getFullYear()
        this.firstCalendarData.month = this.day.getMonth()
        this.firstCalendarData.year = this.day.getFullYear()
        this.createCalendarSecond(this.secondCalendarData.month, this.secondCalendarData.year) // створюємо другий календар
           
        console.table(['first',this.day.getMonth(), this.day.getFullYear()], ['second', this.secondCalendarData.month, this.secondCalendarData.year])

        // після створення кастомного часу, оновлюємо календар і додаємо в кнопки
        this.eventB.off('refreshCalendar')
        this.eventB.on('refreshCalendar', status => {
            this.fastButtons.push({
                title: status.data.name,
                unit: status.data.unit,
                value: status.data.amount
            })
        })

        this.applyStyles(this.data) // отримавши збережені дати, накатуємо стилі в календар
    },
    created() {
        if(localStorage.getItem('tabsForCalendar')) { // якщо маємо збережені таби, то додаємо їх в масив
            JSON.parse(localStorage.getItem('tabsForCalendar')).forEach(el => {
                this.fastButtons.push({
                    title: el.name,
                    unit: el.unit,
                    value: el.amount
                })
            })
        }
    },
    methods: {
        screenChecker(e) { // перевіряємо розміри екрану і вкидуємо адаптив
            if(window.screen.height < 1000 && window.screen.width < 750 && e.value == 'Custom') {
                return true
            }
        },
        applyStyles(date) { // наверстуємо стилі 
            console.log('date', date, date.month, date.month)
            if(date.value?.indexOf(' - ') != -1) {
                date = date.value.split(' - ')
                if(date[0].split(' ')[1] == date[1].split(' ')[1] && date[0].split(' ')[2] == date[1].split(' ')[2]){ // перевірка на формат дат
                    this.createCalendar(Number(date[0].split(' ')[1]) - 1, Number(date[0].split(' ')[2])) // створюємо перший календар
                    this.actualMonthWeeks.forEach(el => { // перераховуємо всі дні і масиві днів місяця і шукаємо вибрані дні з дати
                        el.forEach(k => {
                            if(k.dayOfMonth == Number(date[0].split(' ')[0]) && k.nextMonth == false && k.lastMonth == false) {
                                k.class = 'edgeDayInRangeLeft' // якщо день ліву границю займає, то даємо відповідний клас
                            } else if(k.dayOfMonth == Number(date[1].split(' ')[0]) && k.nextMonth == false && k.lastMonth == false) {
                                k.class = 'edgeDayInRangeRight' // якщо день праву границю займає, то даємо відповідний клас
                            } else if(
                                k.dayOfMonth > Number(date[0].split(' ')[0]) && k.nextMonth == false && k.lastMonth == false 
                                &&
                                k.dayOfMonth < Number(date[1].split(' ')[0]) && k.nextMonth == false && k.lastMonth == false 
                            ) {
                                k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                            }
                        })
                    })
                    this.firstCalendarData = {month: Number(date[0].split(' ')[1]) - 1, year: Number(date[0].split(' ')[2])} // кидаємо дату на перший календар
                } else {
                    if(Number(date[0].split(' ')[2]) > Number(date[1].split(' ')[2])) { // міняємо місцями дати в масиві, щоб коректно класи перекинути
                        date = date.reverse()
                    } else if(Number(date[0].split(' ')[1]) > Number(date[1].split(' ')[1])){ // міняємо місцями дати в масиві, щоб коректно класи перекинути
                        date = date.reverse()
                    }
                    this.createCalendar(Number(date[0].split(' ')[1]) - 1, (date[0].split(' ')[2])) // створюємо перший календар
                    this.actualMonthWeeks.forEach(el => { // перераховуємо всі дні і масиві днів місяця і шукаємо вибрані дні з дати
                        el.forEach(k => {
                            if(k.dayOfMonth == Number(date[1].split(' ')[0]) && k.nextMonth == false && k.lastMonth == false) {
                                k.class = 'edgeDayInRangeLeft' // якщо день ліву границю займає, то даємо відповідний клас 
                            }  else if(
                                k.dayOfMonth > Number(date[1].split(' ')[0]) && k.nextMonth == false && k.lastMonth == false 
                                || 
                                k.nextMonth == true
                            ) {
                                k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                            } 
                        })
                    })
                    this.createCalendarSecond(Number(date[1].split(' ')[1]) - 1, Number(date[1].split(' ')[2])) // створюємо другий календар
                    this.actualMonthWeeksSecondCalendar.forEach(el => { // перераховуємо всі дні і масиві днів місяця і шукаємо вибрані дні з дати
                        el.forEach(k => {
                            if(k.dayOfMonth == Number(date[0].split(' ')[0]) && k.nextMonth == false && k.lastMonth == false) {
                                k.class = 'edgeDayInRangeRight' // якщо день праву границю займає, то даємо відповідний клас
                            }  else if(
                                k.dayOfMonth < Number(date[0].split(' ')[0]) && k.nextMonth == false && k.lastMonth == false 
                                || 
                                k.lastMonth == true
                            ) {
                                k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                            } 
                        })
                    })

                    console.log(date)
                    
                    // вносимо дані в об'єкти
                    this.firstCalendarData = {month: Number(date[1].split(' ')[1]) - 1, year: date[1].split(' ')[2]}
                    this.secondCalendarData = {month: Number(date[0].split(' ')[1]) - 1, year: Number(date[0].split(' ')[2])}
                }
            } else {
                console.log(date.month, 'heres')
                if(date.month) { // якщо є дата в місяці
                    console.log('here')
                    this.firstCalendarData = {month: Number(date.month), year: Number(date.value.split('-')[0])} // вносимо дані в об'єкт
                    this.createCalendar(date.month, Number(date.value.split('-')[0])) // створюємо перший календар
                    this.actualMonthWeeks.forEach(el => {
                        el.forEach(k => {
                            if(k.dayOfMonth == Number(date.value.split(' ')[0]) && k.nextMonth == false && k.lastMonth == false) {
                                k.class = 'selectedDay' // ставимо клас на початкову дату
                            }
                        })
                    })
                } else {
                    console.log(date)
                    if(Number(date.value.split(' ')[1])){
                        this.firstCalendarData = {month: Number(date.value.split(' ')[1]) - 1, year: Number(date.value.split(' ')[2])}
                        this.createCalendar(Number(date.value.split(' ')[1]) - 1, Number(date.value.split(' ')[2]))
                        this.actualMonthWeeks.forEach(el => {
                            el.forEach(k => {
                                if(k.dayOfMonth == Number(date.value.split(' ')[0]) && k.nextMonth == false && k.lastMonth == false) {
                                    k.class = 'selectedDay' // ставимо клас на початкову дату
                                }
                            })
                        })
                    } else {
                        this.firstCalendarData = {month: (this.getNumberOfMonth(date.value.split(' ')[1].substring(0, 3))) - 1, year: Number(date.value.split(' ')[2])}
                        this.createCalendar((this.getNumberOfMonth(date.value.split(' ')[1].substring(0, 3)) - 1), Number(date.value.split(' ')[2]))
                        this.actualMonthWeeks.forEach(el => {
                            el.forEach(k => {
                                if(k.dayOfMonth == Number(date.value.split(' ')[0]) && k.nextMonth == false && k.lastMonth == false) {
                                    k.class = 'selectedDay' // ставимо клас на початкову дату
                                }
                            })
                        })
                    }
                }
            }
            
        },
        prevYears(a) { // рахуємо минулий рік
            for(let i = 0; i < this.pickYearOptions.length; i++) {
                this.pickYearOptions[i].name -= 12
                this.pickYearOptions[i].value -= 12
            }
        },
        nextYears(a) { // рахуємо наступний рік
            for(let i = 0; i < this.pickYearOptions.length; i++) {
                this.pickYearOptions[i].name += 12
                this.pickYearOptions[i].value += 12
            }
        },
        picked(a, b) { // стався вибір року\місяця
            if(a == 'first') {
                this.showPickFirst = false
                if(typeof b.value === 'string') {
                    this.firstCalendarData.month = Number(this.getNumberOfMonth(b.value)) - 1 // змінюємо місяць
                    this.createCalendar(this.firstCalendarData.month, this.firstCalendarData.year) // створюємо 1-ший календар
                } else {
                    this.firstCalendarData.year = b.value
                    this.createCalendar(this.firstCalendarData.month, this.firstCalendarData.year) // створюємо 1-ший календар
                }
            } else {
                this.showPickSecond = false
                if(typeof b.value === 'string') {
                    this.secondCalendarData.month = Number(this.getNumberOfMonth(b.value)) - 1
                    this.createCalendarSecond(this.secondCalendarData.month, this.secondCalendarData.year) // створюємо 2-ий календар
                } else {
                    this.secondCalendarData.year = b.value
                    this.createCalendarSecond(this.secondCalendarData.month, this.secondCalendarData.year) // створюємо 2-ий календар
                }
            }
            this.pickYearOptions = []
        },
        pickMonth(a) { // вибираємо місяць в календарях, включаємо відображення
            if(a == 'first') {
                this.showPickFirst = true
                this.showPickSecond = false
            } else {
                this.showPickSecond = true
                this.showPickFirst = false
            }
            this.pickYearOptions = this.months
        },
        pickYear(a) { // вибираємо рік в календарях, включаємо відображення
            if(a == 'first') {
                this.showPickFirst = true
                this.showPickSecond = false
                let arr = []
                for(let i = 0; i<=11; i++) {
                    if(i == 0) {
                        arr.push({name: this.firstCalendarData.year, value: this.firstCalendarData.year})
                    } else {
                        arr.push({name: Number(this.firstCalendarData.year) + i, value: Number(this.firstCalendarData.year) + i})
                    } 

                }
                this.pickYearOptions = arr
            } else {
                this.showPickSecond = true
                this.showPickFirst = false
                let arr = []
                for(let i = 0; i<=11; i++) {
                    if(i == 0) {
                        arr.push({name: this.secondCalendarData.year, value: this.secondCalendarData.year})
                    } else {
                        arr.push({name: Number(this.secondCalendarData.year) + i, value: Number(this.secondCalendarData.year) + i})
                    } 

                }
                this.pickYearOptions = arr
            }
        },
        cancel(){ // відміна всіх вибраних даних і класів
            this.selectedDays = []; 
            this.inputDate1 = ''; 
            this.inputDate2 = ''
            this.actualMonthWeeks.forEach(el => {
                el.forEach(el => {
                    if(el.class != '') {
                        el.class = ''
                    }
                })
            })
            this.actualMonthWeeksSecondCalendar.forEach(el => {
                el.forEach(el => {
                    if(el.class != '') {
                        el.class = ''
                    }
                })
            })
            this.fastButtons.forEach(el => {
                if(el.class === 'selectedRange') {
                    el.class = ''
                }
            })
        },
        deleteCustom(item) { // видалення кастомного періоду
            this.fastButtons.splice(this.fastButtons.indexOf(item), 1)
            let arr = JSON.parse(localStorage.getItem('tabsForCalendar'))
            localStorage.removeItem('tabsForCalendar')
            arr.forEach(el => {
                if(el.name === item.title) {
                    arr.splice(arr.indexOf(el), 1)
                }
            })
            localStorage.setItem('tabsForCalendar', JSON.stringify(arr))
        },
        sendData(){ // для оновлення дати в фільтри
            if(this.inputDate2 == '') {
                this.inputDate2 = this.inputDate1 
            }
            this.inputDate1 = this.inputDate1.split('/').reverse().join('-')
            this.inputDate2 = this.inputDate2.split('/').reverse().join('-')
            this.$emit('setDate', 'pr', [this.inputDate1, this.inputDate2])
        },
        setRange(item, fullItem) { // активуємо періоди часу
            if(this.selectedDays.length == 0) {
                let today = new Date()
                this.inputDate1 = today.getDate() + "/" + (Number(today.getMonth())+1) + "/" + today.getFullYear() // починаємо відрахунок
            } else
            if(this.selectedDays.length > 1 || (this.inputDate1 != '' && this.inputDate2 != '')) { // оброблюємо помилки
                this.$toast.error(this.$t('onlyOnePoint'))
                return
            } else if(this.fastButtons.find(el => el === fullItem) && fullItem.class === 'selectedRange') { // оброблюємо помилки
                this.$toast.error(this.$t('youCantUseSameDistance'))
                return
            } else if(this.selectedDays.length == 1) { // якщо вибраний день є, то цей клік відправить команду на відправку дати в фільтр і закриє календар
                this.sendData()
                this.$emit('close')
                return
            }
                let firstInp = this.inputDate1.split('/')
                this.fastButtons.forEach(el => {
                    if(el === fullItem) {
                        el.class = 'selectedRange'
                    } else if(el.class === 'selectedRange') {
                        el.class = ''
                    }
                })
                switch (item) {
                    // перебір періодів і заповнення полів, що  потім повернуться у фільтр
                    case "Last 7 days":
                        this.inputDate2 = String(new Date(Number(firstInp[2]), Number(firstInp[1]) - 1, Number(firstInp[0]) - 7)).
                        split(' ')
                        this.inputDate1 = this.inputDate2[2] + '/' + this.getNumberOfMonth(this.inputDate2[1]) + '/' + this.inputDate2[3]
                        this.inputDate2 = firstInp.join('/')
                    break;
                    case "Today":
                        this.inputDate2 = this.inputDate1
                    break;
                    case "Last 14 days":
                        this.inputDate2 = String(new Date(Number(firstInp[2]), Number(firstInp[1]) - 1, Number(firstInp[0]) - 14)).
                        split(' ')
                        this.inputDate1 = this.inputDate2[2] + '/' + this.getNumberOfMonth(this.inputDate2[1]) + '/' + this.inputDate2[3]
                        this.inputDate2 = firstInp.join('/')
                    break;
                    case "Last 3 months":
                        this.inputDate2 = String(new Date(Number(firstInp[2]), Number(firstInp[1]) - 4, Number(firstInp[0]))).
                        split(' ')
                        this.inputDate1 = this.inputDate2[2] + '/' + this.getNumberOfMonth(this.inputDate2[1]) + '/' + this.inputDate2[3]
                        this.inputDate2 = firstInp.join('/')
                    break;
                    case "Last 12 months":
                        this.inputDate2 = String(new Date(Number(firstInp[2]), Number(firstInp[1]) - 13, Number(firstInp[0]))).
                        split(' ')
                        this.inputDate1 = this.inputDate2[2] + '/' + this.getNumberOfMonth(this.inputDate2[1]) + '/' + this.inputDate2[3]
                        this.inputDate2 = firstInp.join('/')
                    break;
                    case "Month to date":
                        this.inputDate2 = String(new Date(Number(firstInp[2]), Number(firstInp[1]) - 2, Number(firstInp[0]))).
                        split(' ')
                        this.inputDate1 = this.inputDate2[2] + '/' + this.getNumberOfMonth(this.inputDate2[1]) + '/' + this.inputDate2[3]
                        this.inputDate2 = firstInp.join('/')
                    break;
                    default: 
                    // якщо у нас кастомний період, то орієнтуємося на міру виміру (день, тижні, місяці, роки)
                        if(fullItem.unit == 'day') {
                            this.inputDate2 = String(new Date(Number(firstInp[2]), Number(firstInp[1]) - 1, Number(firstInp[0]) - Number(fullItem.value))).
                            split(' ')
                            this.inputDate1 = this.inputDate2[2] + '/' + this.getNumberOfMonth(this.inputDate2[1]) + '/' + this.inputDate2[3]
                            this.inputDate2 = firstInp.join('/')
                        } else if(fullItem.unit == 'week') {
                            this.inputDate2 = String(new Date(Number(firstInp[2]), Number(firstInp[1]) - 1, Number(firstInp[0]) - (Number(fullItem.value) * 7))).
                            split(' ')
                            this.inputDate1 = this.inputDate2[2] + '/' + this.getNumberOfMonth(this.inputDate2[1]) + '/' + this.inputDate2[3]
                            this.inputDate2 = firstInp.join('/')
                        } else if(fullItem.unit == 'month') {
                            this.inputDate2 = String(new Date(Number(firstInp[2]), Number(firstInp[1]) - 1 - Number(fullItem.value), Number(firstInp[0]))).
                            split(' ')
                            this.inputDate1 = this.inputDate2[2] + '/' + this.getNumberOfMonth(this.inputDate2[1]) + '/' + this.inputDate2[3]
                            this.inputDate2 = firstInp.join('/')
                        } else if(fullItem.unit == 'year') {
                            this.inputDate2 = String(new Date(Number(firstInp[2]) - Number(fullItem.value), Number(firstInp[1]) - 1, Number(firstInp[0]))).
                            split(' ')
                            this.inputDate1 = this.inputDate2[2] + '/' + this.getNumberOfMonth(this.inputDate2[1]) + '/' + this.inputDate2[3]
                            this.inputDate2 = firstInp.join('/')
                        }
                    break;
                }
                this.sendData()
                this.$emit('close')
        },
        createCalendar(month, year) { // збираємо календар
            console.log(month, year)
            this.selectedDays = []
            this.actualMonth = []
            this.actualMonthWeeks = []
            let firstDayMonth = String(new Date(year, month)).split(' ')[1]
            // в JS рахунок днів тижня та місяців починається з 0. Неділя - є першим днем тижня (нульовим днем в JS). Але перше число в місяці буде 1
            for (let i = 0; i<32; i++) {
                if(firstDayMonth == String(new Date(year, month, i)).split(' ')[1]) {
                    this.actualMonth.push({class:'',year:year, month: firstDayMonth, numberDayOfWeek: (new Date(year, month, i).getDay() == 0 ? 6 : (new Date(year, month, i).getDay())-1), dayOfMonth: i, dayofWeek: this.mutateDayFromEng(new Date(year, month, i)), lastMonth: false, nextMonth: false})
                }
            }
            if(this.actualMonth[0].numberDayOfWeek !== 0) {
                let firstDay =  this.actualMonth[0]
                for(let i = firstDay.numberDayOfWeek - 1; i >= 0; i--) {
                    let day = String(new Date(year, month, i - firstDay.numberDayOfWeek + 1)).split(' ')
                    this.actualMonth.unshift({month: String(new Date(year, month-1)).split(' ')[1], numberDayOfWeek: i, dayOfMonth: Number(day[2]), dayofWeek: this.mutateDayFromEng(day.join(' ')), lastMonth: true, nextMonth: false})
                }
            } // додавання днів з минулого місяця для заповнення строки 
            if(this.actualMonth[this.actualMonth.length - 1].numberDayOfWeek !== 6) {
                let lastDay = this.actualMonth[this.actualMonth.length - 1]
                for(let i = lastDay.numberDayOfWeek + 1; i <= 6; i++){
                    let day = String(new Date(year, month, lastDay.dayOfMonth + i - lastDay.numberDayOfWeek)).split(' ')
                    this.actualMonth.push({class:'',month: String(new Date(year, month+1)).split(' ')[1], numberDayOfWeek: i, dayOfMonth: Number(day[2]), dayofWeek: this.mutateDayFromEng(day.join(' ')), lastMonth: false, nextMonth: true})
                }
            } // додавання днів з наступного місяця для заповнення строки 
            let arr = []
            for(let i = 0; i < this.actualMonth.length; i++) {
                if(this.actualMonth[i].dayofWeek !== 'НД') {
                    arr.push(this.actualMonth[i])
                } else {
                    arr.push(this.actualMonth[i])
                    this.actualMonthWeeks.push(arr)
                    arr = []
                }
            }
        },
        createCalendarSecond(month, year) { // збираємо календар
            this.selectedDays = []
            this.actualMonthSecondCalendar = []
            this.actualMonthWeeksSecondCalendar = []
            let firstDayMonth = String(new Date(year, month)).split(' ')[1]
            // в JS рахунок днів тижня та місяців починається з 0. Неділя - є першим днем тижня (нульовим днем в JS). Але перше число в місяці буде 1
            for (let i = 0; i<32; i++) {
                if(firstDayMonth == String(new Date(year, month, i)).split(' ')[1]) {
                    this.actualMonthSecondCalendar.push({class:'',year: year, month: firstDayMonth, numberDayOfWeek: (new Date(year, month, i).getDay() == 0 ? 6 : (new Date(year, month, i).getDay())-1), dayOfMonth: i, dayofWeek: this.mutateDayFromEng(new Date(year, month, i)), lastMonth: false, nextMonth: false})
                }
            }
            if(this.actualMonthSecondCalendar[0].numberDayOfWeek !== 0) {
                let firstDay =  this.actualMonthSecondCalendar[0]
                for(let i = firstDay.numberDayOfWeek - 1; i >= 0; i--) {
                    let day = String(new Date(year, month, i - firstDay.numberDayOfWeek + 1)).split(' ')
                    this.actualMonthSecondCalendar.unshift({month: String(new Date(year, month-1)).split(' ')[1], numberDayOfWeek: i, dayOfMonth: Number(day[2]), dayofWeek: this.mutateDayFromEng(day.join(' ')), lastMonth: true, nextMonth: false})
                }
            } // додавання днів з минулого місяця для заповнення строки 
            if(this.actualMonthSecondCalendar[this.actualMonthSecondCalendar.length - 1].numberDayOfWeek !== 6) {
                let lastDay = this.actualMonthSecondCalendar[this.actualMonthSecondCalendar.length - 1]
                for(let i = lastDay.numberDayOfWeek + 1; i <= 6; i++){
                    let day = String(new Date(year, month, lastDay.dayOfMonth + i - lastDay.numberDayOfWeek)).split(' ')
                    this.actualMonthSecondCalendar.push({class:'',month: String(new Date(year, month+1)).split(' ')[1], numberDayOfWeek: i, dayOfMonth: Number(day[2]), dayofWeek: this.mutateDayFromEng(day.join(' ')), lastMonth: false, nextMonth: true})
                }
            } // додавання днів з наступного місяця для заповнення строки 
            let arr = []
            for(let i = 0; i < this.actualMonthSecondCalendar.length; i++) {
                if(this.actualMonthSecondCalendar[i].dayofWeek !== 'НД') {
                    arr.push(this.actualMonthSecondCalendar[i])
                } else {
                    arr.push(this.actualMonthSecondCalendar[i])
                    this.actualMonthWeeksSecondCalendar.push(arr)
                    arr = []
                }
            }
        },
        mutateDayFromEng(e){ // мутейт для днів з англійської
            let res;
            switch(String(e).split(' ')[0]){
                case 'Mon':
                    res = 'ПН'
                break;
                case 'Tue':
                    res = 'ВТ'
                break;
                case 'Wed':
                    res = 'СР'
                break;
                case 'Thu':
                    res = 'ЧТ'
                break;
                case 'Fri':
                    res = 'ПТ'
                break;
                case 'Sat':
                    res = 'СБ'
                break;
                case 'Sun':
                    res = 'НД'
                break;
            }
            return res
        },
        currentDate() { // вираховуємо gjnjxye lfne
            var date = new Date();
            var dateStr = {
                day: ("00" + (date.getDate())).slice(-2),
                month: (this.month(date.getMonth())),
                year: date.getFullYear()
            }
            return dateStr
        },
        nextMonth(item, action, calendar) { // вираховуємо настуний місяць
            this.cancel()
            if(action) {
                this.secondCalendar = true
                if(calendar == 'second') { // якщо календар другий
                    if(this.secondCalendarData.month == 11) {
                        // якщо місяць останній, то міняємо рік на наступний
                        this.secondCalendarData = {
                            month: 0,
                            year: Number(this.secondCalendarData.year) + 1
                        }
                        this.createCalendarSecond(this.secondCalendarData.month, this.secondCalendarData.year) // створюємо другий календар наново
                    } else {
                        this.secondCalendarData.month = Number(this.secondCalendarData.month) + 1
                        this.createCalendarSecond(this.secondCalendarData.month, this.secondCalendarData.year) // створюємо другий календар наново
                    }
                } else {
                    if((this.secondCalendarData.year == this.firstCalendarData.year && Number(this.firstCalendarData.month) + 1 == this.secondCalendarData.month) || this.secondCaledar == false) {
                        this.$toast.error(this.$t('Не можна вибрати місяць більший або рівний за місяць зправа'))
                    } else {
                        if(this.firstCalendarData.month == 11) {
                            // якщо місяць останній, то міняємо рік на наступний
                            this.firstCalendarData = {
                                month: 0,
                                year: Number(this.firstCalendarData.year) + 1
                            }
                        } else {
                            this.firstCalendarData.month = Number(this.firstCalendarData.month) + 1
                        }
                        this.createCalendar(this.firstCalendarData.month, this.firstCalendarData.year) // створюємо перший календар наново
                    }
                }
            }
        },
        createCustom() { // створити кастомний період
            this.eventB.emit('createCustomCalendarTime', {
                status: true
            })
        },
        prevMonth(item, action, calendar) { // минулий місяць теж по різним календарям. Якщо місяць перший, то міняємо рік і перестворюємо календарі
            this.cancel()
            if(action) {
                if(calendar == 'second') {
                    if(this.secondCalendarData.year == this.firstCalendarData.year && this.firstCalendarData.month == Number(this.secondCalendarData.month) - 1) {
                        this.$toast.error(this.$t('Не можна вибрати місяць менший або рівний за місяць зліва'))
                    } else {
                        if(this.secondCalendarData.month == 0) {
                            this.secondCalendarData = {
                                month: 11,
                                year: Number(this.secondCalendarData.year) - 1
                            }
                            this.createCalendarSecond(this.secondCalendarData.month, this.secondCalendarData.year)
                        } else {
                            this.secondCalendarData.month = Number(this.secondCalendarData.month) - 1
                            this.createCalendarSecond(this.secondCalendarData.month, this.secondCalendarData.year)
                        }
                    }
                } else {
                    if(this.firstCalendarData.month == 0) {
                        this.firstCalendarData = {
                            month: 11,
                            year: Number(this.firstCalendarData.year) - 1
                        }
                    } else {
                        this.firstCalendarData = {
                            month: Number(this.firstCalendarData.month) - 1,
                            year: this.firstCalendarData.year
                        }
                        this.createCalendar(this.firstCalendarData.month, this.firstCalendarData.year)
                    }
                }
            }
        },
        selectDay(item) {
            // zroj l
            if(item.nextMonth === true || item.lastMonth === true) {
                this.$toast.error(this.$t('dontPickLastAndNextMonth'))
                return
            }
            if(this.selectedDays.find(el => el === item)) {
                this.$toast.error(this.$t('noSameDay'))
                return
            }
            this.selectedDays.push(item)
            if(this.selectedDays.length === 3) {
                this.selectedDays = [this.selectedDays[2]]
                this.actualMonthWeeks.forEach(el => {
                    el.forEach(k => {
                        if(k.class != '' && k != this.selectedDays[0]) {
                            k.class = ''
                        } else if(k == this.selectedDays[0]) {
                            k.class = 'selectedDay'
                            this.inputDate1 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                            this.inputDate2 = ''
                        }
                    })
                })
                this.actualMonthWeeksSecondCalendar.forEach(el => {
                    el.forEach(k => {
                        if(k.class != '' && k != this.selectedDays[0]) {
                            k.class = ''
                        } else if(k == this.selectedDays[0]) {
                            k.class = 'selectedDay'
                            this.inputDate1 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                            this.inputDate2 = ''
                        }
                    })
                })
            } else if(this.selectedDays.length === 2) {
                if(this.selectedDays[0].month !== this.selectedDays[1].month) {
                    if(this.getNumberOfMonth(this.selectedDays[0].month) < this.getNumberOfMonth(this.selectedDays[1].month) && this.selectedDays[0].year == this.selectedDays[1].year) {
                        this.actualMonthWeeks.forEach(el => {
                            el.forEach(k => {
                                if(k === this.selectedDays[0]) {
                                    k.class = 'edgeDayInRangeLeft' // якщо день ліву границю займає, то даємо відповідний клас
                                    this.inputDate1 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                                } else if(k.nextMonth == true) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                } else if(k.dayOfMonth > this.selectedDays[0].dayOfMonth && k.lastMonth == false && k.nextMonth == false) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                }
                            })
                        })
                        this.actualMonthWeeksSecondCalendar.forEach(el => {
                            el.forEach(k => {
                                if(k === this.selectedDays[1]) {
                                    k.class = 'edgeDayInRangeRight' // якщо день праву границю займає, то даємо відповідний клас
                                    this.inputDate2 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                                } else if(k.lastMonth == true) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                } else if(k.dayOfMonth < this.selectedDays[1].dayOfMonth && k.lastMonth == false && k.nextMonth == false) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                }
                            })
                        })
                    } else if(this.selectedDays[0].year === this.selectedDays[1].year) {
                        this.actualMonthWeeks.forEach(el => {
                            el.forEach(k => {
                                if(k === this.selectedDays[1]) {
                                    k.class = 'edgeDayInRangeLeft' // якщо день ліву границю займає, то даємо відповідний клас
                                    this.inputDate1 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                                } else if(k.dayOfMonth > this.selectedDays[1].dayOfMonth && k.lastMonth == false && k.nextMonth == false) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                } else if(k.nextMonth == true) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                }
                            })
                        })
                        this.actualMonthWeeksSecondCalendar.forEach(el => {
                            el.forEach(k => {
                                if(k === this.selectedDays[0]) {
                                    k.class = 'edgeDayInRangeRight' // якщо день праву границю займає, то даємо відповідний клас
                                    this.inputDate2 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                                } else if(k.dayOfMonth < this.selectedDays[0].dayOfMonth && k.lastMonth == false && k.nextMonth == false) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                } else if(k.lastMonth == true) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                }
                            })
                        })
                    } else {
                        this.actualMonthWeeksSecondCalendar.forEach(el => {
                            el.forEach(k => {
                                if(k === this.selectedDays.find(n => k === n)) {
                                    k.class = 'edgeDayInRangeRight' // якщо день праву границю займає, то даємо відповідний клас
                                    this.inputDate2 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                                } else if(k.dayOfMonth < this.selectedDays.find(n => k.month === n.month)?.dayOfMonth) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                } else if(k.lastMonth == true) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                }
                            })
                        })
                        this.actualMonthWeeks.forEach(el => {
                            el.forEach(k => {
                                if(k === this.selectedDays.find(n => k === n)) {
                                    k.class = 'edgeDayInRangeLeft' // якщо день ліву границю займає, то даємо відповідний клас
                                    this.inputDate1 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                                } else if(k.dayOfMonth > this.selectedDays.find(n => k.month === n.month)?.dayOfMonth) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                } else if(k.nextMonth == true) {
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                }
                            })
                        })
                    }
                } else {
                    if(this.selectedDays[0].dayOfMonth > this.selectedDays[1].dayOfMonth) {
                        this.selectedDays = this.selectedDays.reverse()
                    }
                    // [1][0] беремо другий тиждень місяця і порівнюємо назви місяців для правильного розподілення класів
                    if(this.actualMonthWeeks[1][0].month === this.selectedDays[0].month) {
                        this.actualMonthWeeks.forEach(el => {
                            el.forEach(k => {
                                if(k == this.selectedDays[0]) {
                                    k.class = 'edgeDayInRangeLeft' // якщо день ліву границю займає, то даємо відповідний клас
                                    this.inputDate1 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                                } else if(k == this.selectedDays[1]){
                                    k.class = 'edgeDayInRangeRight' // якщо день праву границю займає, то даємо відповідний клас
                                    this.inputDate2 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                                } else if(k.dayOfMonth > this.selectedDays[0].dayOfMonth && k.dayOfMonth < this.selectedDays[1].dayOfMonth && k.lastMonth == false && k.nextMonth == false){
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                }
                            })
                        })
                    } else {
                        this.actualMonthWeeksSecondCalendar.forEach(el => {
                            el.forEach(k => {
                                if(k == this.selectedDays[0]) {
                                    k.class = 'edgeDayInRangeLeft' // якщо день ліву границю займає, то даємо відповідний клас
                                    this.inputDate1 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                                } else if(k == this.selectedDays[1]){
                                    k.class = 'edgeDayInRangeRight' // якщо день праву границю займає, то даємо відповідний клас
                                    this.inputDate2 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                                } else if(k.dayOfMonth > this.selectedDays[0].dayOfMonth && k.dayOfMonth < this.selectedDays[1].dayOfMonth && k.lastMonth == false && k.nextMonth == false){
                                    k.class = 'dayInRange' // якщо день між границями, то даємо відповідний клас
                                } 
                            })
                        })
                    }
                }
                this.$emit('close')
                this.sendData()
            } else if(this.selectedDays.length == 1) {
                this.actualMonthWeeks.forEach(el => {
                    el.forEach(k => {
                        if(k.class != '' && k != this.selectedDays[0]) {
                            k.class = ''
                        } else if(k == this.selectedDays[0]) {
                            k.class = 'selectedDay'
                            this.inputDate1 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                            this.inputDate2 = ''
                        }
                    })
                })
                this.actualMonthWeeksSecondCalendar.forEach(el => {
                    el.forEach(k => {
                        if(k.class != '' && k != this.selectedDays[0]) {
                            k.class = ''
                        } else if(k == this.selectedDays[0]) {
                            k.class = 'selectedDay'
                            this.inputDate1 = k.dayOfMonth + '/' + this.getNumberOfMonth(k.month) + '/' + k.year
                            this.inputDate2 = ''
                        }
                    })
                })
            }
        },
        month(e) {
            // повертаємо місяць
            return getMonth(e)
        },
        getNumberOfMonth(e) {
            // отримуємо місяць в номері
            var res
            switch(e) {
                case "Jan":
                    res = 1
                break;
                case "Feb":
                    res = 2
                break;
                case "Mar":
                    res = 3
                break;
                case "Apr":
                    res = 4
                break;
                case "May":
                    res = 5
                break;
                case "Jun":
                    res = 6
                break;
                case "Jul":
                    res = 7
                break;
                case "Aug":
                    res = 8
                break;
                case "Sep":
                    res = 9
                break;
                case "Oct":
                    res = 10
                break;
                case "Nov":
                    res = 11
                break;
                case "Dec":
                    res = 12
                break;
            }
            return res
        },
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
}
</script>

<style scoped>
/* .mainCalendarButton{
    height: 30px;
    border: none;
    border-radius: 0.25rem;
    background-color: #eff2f7;
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    width: 200px;
} */
.dropdownCalendar{
    position: absolute;
    /* width: 1000px; */ /* розкоментити, якщо зламається */
    width: 60vw;
    z-index: 2;
    /* right: -300px;  */ /* розкоментити, якщо зламається */
    right: 5px;
}
.calendar {
    display: grid;
    grid-template-columns: 1fr repeat(2, 2fr);
    /* grid-template-rows: 4fr 1fr ; */
    background-color: white;
    border-radius: 20px;
    /* max-width: 80%; */ /* розкоментити, якщо зламається */
    border: 1px solid #ced4da;
}
.shadowCal {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.shadowCal:hover{
    box-shadow: 0px 8px 20px rgba(0,0,0,0.25), 0px 0px 3px 0px rgba(0,0,0,0.22);
}
.calendar-fastButtons{
    grid-row-start: 1;
    grid-row-end: 3;
    border-right: 1px solid rgb(200, 200, 200);
    margin-top: 10px;
}
.calendar-startDate-grid{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr 9fr ;
    /* border-bottom: 1px solid rgb(200, 200, 200); */
    border-right: 1px solid rgb(200, 200, 200);
    padding: 10px;
}
.controlPanel-arrowLeft{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    justify-self: start;
    background-color: rgba(1, 1, 1, 0);
    border: none;
    font-size: 18px;
}
calendar-startDate-grid > span {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    justify-self: center;
}
.calendar-endDate-grid {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr 9fr ;
    /* border-bottom: 1px solid rgb(200, 200, 200); */
    padding: 10px;
}
calendar-endDate-grid > span {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    justify-self: center;
}
.controlPanel-arrowRight{
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    justify-self: end;
    background-color: rgba(1, 1, 1, 0);
    border: none;
    font-size: 18px;
}
.dates{
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
}
.dates > table {
    width: 100%;
    text-align: center;
    height: 100%;
}
.extraButtons{
    align-self: center;
    justify-self: center;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 4;
    border-radius: 10px;
    background-color: white;
    width: 100%;
    height: 100%;
}
.extraButtons-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

/* for range and chosen days */
.dayInRange {
    color: rgba(112,72,236,255);
    background-color: #f8f8fa;
}

.selectedDay{
    background-color:  rgba(112,72,236,255);
    color: white;
    border-radius: 10px;
}



.edgeDayInRangeLeft{
    background-color:  rgba(112,72,236,255);
    color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.edgeDayInRangeRight{
    background-color:  rgba(112,72,236,255);
    color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
/* for range and chosen days */

/* .calendar-fastButtons-text button{
    display: block;
    border: none;
    background-color: rgba(1, 1, 1, 0);
} */

.selectedRange{
    color: rgba(112,72,236,255);
    background-color: rgb(240, 240, 240) !important;
}
.fastButtons{
    padding-left: 10px;
    padding-right: 0;
}
.kostyak{
    transition: 0.3s;
}
td.kostyak:hover{
    background: rgba(112,72,236,255);
    color: white;
    border-radius: 10px;
}
.monthButtons{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.monthButtons > button {
    padding: 5px;
    font-weight: 500;
}
.pickYear{
    height: 100%;
    width: 100%;
    background-color: white;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
}

@media all and (max-width: 1155px) {
    .calendar {
        grid-template-columns: repeat(2, 2fr);
        grid-template-rows: 100px 300px;
        height: 400px;
    }
    .dropdownCalendar{
        height: 200px;
        width: 75vw;
        right: -157px
    }
    .calendar-fastButtons{
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 3;
        border-bottom: 1px solid #ced4da;
        border-right: none;
        padding-bottom: 10px;
    }
    .calendar-fastButtons-text{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: auto;
    }

    .calendar-endDate, .calendar-startDate{
        height: 290px;
    }
}

@media (min-height: 750px) and (max-width: 750px) {
    .dropdownCalendar {
        width: 100vw;
        height: 100vh;
        left: 20px;
        top: 1em  !important;
    }
    .shadowCal { 
        display: grid;
        grid-template-rows: 30vh 30vh 20vh;
        grid-template-columns: 1fr;
        padding: 20px !important
    }
    .calendar-fastButtons{
        grid-row-start: 3;
        grid-row-end: 4;
    }

    .calendar-endDate{
        grid-row-start: 2;
        grid-row-end: 3;
        height: 100%;
    }

    .calendar-startDate-grid{
        border-right: none;
        height: 100%;
    }
    .calendar-fastButtons-text{
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-content: center;
    }
}

@media all and (max-height: 599px) {
    .dropdownCalendar {
        width: 100vw;
        height: 100vh;
        top: 1em  !important;
    }
    .shadowCal { 
        display: grid;
        grid-template-rows: 30vh 50vh;
        grid-template-columns: repeat(2, 1fr);
        padding: 20px !important
    }
    .calendar-fastButtons{
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .calendar-endDate{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        height: 100%;
    }

    .calendar-startDate-grid{
        grid-column-start: 1;
        grid-column-end: 2;
        border-right: none;
        height: 100%;
    }
    .calendar-fastButtons-text{
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-content: center;
    }
}

/* темна тема */
[data-layout-mode="dark"] .calendar {
    background-color: #1A1D1E !important;
    border: none
}
/* темна тема */
</style>
