<template >
    <div class="col-md-6">
        <!-- Компонент принтеру -->
            <div class="mb-3">
                <!-- Модель принтеру -->
                <h4 class="fs-15">{{ $t('printer_model') }}</h4>
                <input type="text" class="form-control mb-3" v-model="form.manufacturer" :disabled="this.editform == false" @input="input" placeholder="Xprinter">

                <!-- Режим друку -->
                <h4 class="fs-15">{{ $t('print_mode') }}</h4>

                <!-- Варіанти режиму друку -->
                <select
                    class="form-select mb-3"
                    v-model="form.data.printParams.printMode"
                    :disabled="this.editform == false"
                    >
                    <option v-for="item in modes_print" :key="item.id" :value="item.value">{{ item.title }}</option>
                </select>
                <h4 class="fs-15">{{ $t('verSkyService') }}</h4>
                <input type="number" class="form-control mb-3" :disabled="this.editform == false" v-model="version.app" placeholder="9.9">
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <!-- Метод підключення -->
                <h4 class="fs-15">{{ $t('connection_method') }}</h4>
                <input type="text" class="form-control mb-3" :disabled="this.editform == false" v-model="form.protocol">

                <!-- Операційна система -->
                <h4 class="fs-15">{{ $t('operatingSystem') }}</h4>

                <!-- Варіанти операційних систем -->
                <select
                    class="form-select mb-3"
                    data-plugin="choices"
                    v-model="form.os"
                    :disabled="this.editform == false"
                    >
                    
                    <option v-for="item in oc_types" :key="item.value">{{ item.value }}</option>
                </select>
                <h4 class="fs-15">{{ $t('ver_oc') }}</h4>
                <input type="number" class="form-control mb-3" :disabled="this.editform == false" v-model="version.os" placeholder="10">
            </div>
        </div>
        <hr>
</template>

<script>

export default ({
    props: ['obj','editform'],
    data(){
        return {
                select_device:"",
                device_types: [
                    {
                        "title": this.$t('PC'),
                    },
                    {
                        "title": this.$t('tablet'),
                    },
                    {
                        "title": this.$t('telephone'),
                    }
                ],
                select_oc:"",
                oc_types: [
                    {
                        "title": "Windows",
                        "value": "windows"
                    },
                    {
                        "title": "Android",
                        "value": "android"
                    },
                    {
                        "title": "IOS",
                        "value": "ios"
                    }
                ],
                select_type_connect:"",
                connect_types: [
                    {
                        "title": "USB",
                    },
                    {
                        "title": "Lan/ Wi-Fi",
                    },
                    {
                        "title": "Bluetooth",
                    }
                ],
                select_mode_print:"",
                modes_print: [
                    {
                        "title": this.$t('mode_text'),
                        "value": "text"
                    },
                    {
                        "title": this.$t('mode_graphic'),
                        "value": "image"
                    }
                ],
                version: {
                    app: "",
                    os: ""
                },
                form: {
                    data:{
                        version: {
                            app: "",
                            os: ""
                        }
                    }
                }
                // form: {
                //     model: {
                //         icon: "ri-cpu-fill",
                //         label: "bank_terminal_model",
                //         value: ""
                //     },
                //     connect_types: {
                //         icon: "mdi-connection",
                //         label: "connection_method",
                //         value: ""
                //     },
                //     modes_print: {
                //         icon: "ri-user-2-fill",
                //         label: "print_mode",
                //         value: ""
                //     },
                //     device_types: {
                //         icon: "ri-user-2-fill",
                //         label: "device",
                //         value: ""
                //     },
                //     oc_types: {
                //         icon: "ri-user-2-fill",
                //         label: "operatingSystem",
                //         value: ""
                //     },
                //     version_os: {
                //         icon: "ri-user-2-fill",
                //         label: "ver_oc",
                //         value: ""
                //     },
                //     version_apps: {
                //         icon: "ri-user-2-fill",
                //         label: "verSkyService",
                //         value: ""
                //     }
                // }

            }
        },
        created(){
            if(this.obj != ''){
                this.form = this.obj
            }
        },
        methods:{
            input(){
                this.$emit('input', this.form)
            }
        }
    }
)
</script>
