<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('team') }}</label>
        <br />
        <div class="row g-2 list_filtr" >
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioTeam" id="all_teams" v-model="team" :value="''">
                    <label class="form-check-label" for="all_teams">{{ $t('all') }}</label>
                </div>
            </div>
            <template v-for="(value, index) in teams" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioTeam" :id="'team'+value+index" v-model="team" :value="value.value">
                        <label class="form-check-label" :for="'team'+value+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return {
            team: "",
            teams: [
                {
                    name: "FrontEnd",
                    value: "FrontEnd",
                },
                {
                    name: "BackEnd",
                    value: "BackEnd",
                },
                {
                    name: "Developer",
                    value: "developer",
                },
               
            ]
        }
    },
    methods: {
        change(){
            this.$emit('change', 'team', this.team)
        },
    },
    computed: {
        // providers(){
        //     return storeS.providers
        // }
    }
}
</script>