<template>
    <span class="d-none d-lg-flex d-xl-flex">
        <div class="d-flex" style="min-width:200px">
            <statusclient :clientstatus="form.status" :phone="form.userPhone"/>
        </div>
    </span>
    <!-- <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" @click="updatedata()" :title="$t('refresh')"><i class="bx bx bx-refresh align-bottom"></i></button> -->
    <button class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" @click="opencalculator()" :title="$t('openCalc')"><i class="bx bx-calculator align-bottom"></i></button>
    <button class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" @click="openshop()" :title="$t('openShop')"><i class="bx bxs-shopping-bags align-bottom"></i></button>
    <!-- <button class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" @click="openstatistics()" :title="$t('openStatistics')"><i class="bx bx-analyse align-bottom"></i></button> -->
    <button class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" @click="openknowledge()" :title="$t('openKnowledge')"><i class="ri-information-line align-bottom"></i></button>
    <button class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" @click="openfaq()" :title="$t('openFaq')"><i class="ri-question-line align-bottom"></i></button>
    <button class="d-none d-lg-flex d-xl-flex btn btn-success" style="min-width: 100px;" @click="this.$emit('addToMonitoring', this.obj.userPhone)">
        {{ this.$t('addToMonitor') }}
    </button>
    <div class="dropdown">
        <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bx bxs-plus-circle align-bottom"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end">
            <button class="dropdown-item" @click="openapplicbox()">{{ $t('Application') }}</button>
            <button class="dropdown-item" @click="opentaskBox()">{{ $t('Task') }}</button >
            <button class="dropdown-item" @click="openticketBox()">{{ $t('ticket') }}</button > 
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item" @click="opensmsbox()">{{ $t('Message') }}</button > 
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item">{{ $t('E-mail') }}</button > 
            <button class="dropdown-item" @click="openblockComment()">{{ $t('Comment') }}</button >
            <button class="dropdown-item" @click="createdconnectbox()">{{ $t('settings_connects') }}</button >
            <button class="dropdown-item" @click="openservicebox()">{{ $t('service') }}</button >
            <button class="dropdown-item" @click="openinvoicesbox()">{{ $t('invoic') }}</button >
            <div class="dropdown-divider d-flex d-sm-flex d-md-flex d-lg-none d-xl-none"></div>
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item" @click="openshop()">{{ $t('shop_title') }}</button >
            <!-- <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item" @click="openstatistics()">{{ $t('statistics') }}</button > -->
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item" @click="openknowledge()">{{ $t('Knowledge_base') }}</button >
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item" @click="openfaq()">{{ $t('qua_and_ans') }}</button >
        </div>
    </div>
    <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')" :title="$t('close')"><i class="ri-close-fill align-bottom"></i></button>
</template>

<script>
import statusclient from '../../allAccounts/viewnew/clientstatus.vue';
export default {
    props: ['obj'],
    data(){
        return {
            form: "",
        }
    },
    created(){
        this.getdata();
    },
    components: {
        statusclient
    },
    methods: {
        getdata(){
            this.form = this.obj
            console.log("this", this.obj)
        },
        openshop(){
            this.$emit('openshop')
        },
        openstatistics(){
            this.$emit('openstatistics')
        },
        openknowledge(){
            this.$emit('openknowledge')
        },
        openfaq(){
            this.$emit('openfaq')
        },
        opensmsbox(){
            this.$emit('opensmsbox')
        },
        openapplicbox(){
            this.$emit('openapplicbox')
        },
        opentaskBox(){
            this.$emit('opentaskBox')
        },
        openticketBox(){
            this.$emit('openticketBox')
        },
        openservicebox(){
            this.$emit('openservicebox')
        },
        createdconnectbox(){
            this.$emit('createdconnectbox')
        },
        openblockComment(){
            this.$emit('openblockComment')
        },
        openinvoicesbox(){
            this.$emit('openinvoicesbox')
        },
        opencalculator(){
            this.$emit('opencalculator')
        }
    }
}
</script>