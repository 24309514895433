<template>
    <!-- Модальне вікно додавання клієнта в обрані -->

    <dialogBox>
        <template v-slot:title>{{ $t('AddClientFavorites') }}</template>
        <template v-slot:body>

            <!-- Рівень адекватності -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('SpecifyLevelAdequacy') }}</h4>
                <b-form-select v-model="form.adequacy" :options="listAdequacy"></b-form-select>
            </div>

            <!-- Коментар -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('EnterComment') }}</h4>
                <textarea class="form-control" v-model="form.comment"></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <!-- Кнопка для закриття модального вікна -->
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>

                <!-- Кнопка додати/зберегти -->
                <button class="btn btn-success" @click="this.objForm.permEdit == true ? save() : addFavorite()">{{ this.objForm.permEdit == true ? $t('save') : $t('Add') }}</button>
            </div>
        </template>
    </dialogBox>

</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модального вікна

export default{
    props: ['obj','objForm'],
    components: {
        dialogBox
    },
    data(){
        return{
            form: {
                adequacy: '1',
                comment: ''
            },
            objCard: {},
            listAdequacy: [
                { value: '3', text: this.$t('priorityHigh') }, // високий
                { value: '2', text: this.$t('priorityMedium') }, // середній
                { value: '1', text: this.$t('priorityLow') }, // низький
            ],
            accounts: [] // акаунти
        }
    },
    created(){
        console.log("this", this);

        this.objCard = this.obj

        // редагуємо картку
        if(this.objForm != undefined) {
            this.form = this.objForm.obj
        }

        this.accounts = JSON.parse(localStorage.getItem('favoriteAccounts')) || [];
    },
    methods: {
        addFavorite(){
            // Додаємо акаунт в обрані

            console.log(this.objCard);
            let accounts = JSON.parse(localStorage.getItem('favoriteAccounts')) || [];
            
            let newAcc = {
                "accountId": this.objCard.accountId,
                "userPhone": this.objCard.userPhone,
                "type": 'account',
                "date": this.currentDate(),
                "adequacy": this.form.adequacy,
                "comment": this.form.comment,
            };

            // Перевірка, чи існує номер в масиві
            let existingAccount = accounts.find(acc => acc.userPhone === newAcc.userPhone);

            if (!existingAccount) {
                accounts.push(newAcc);
                localStorage.setItem('favoriteAccounts', JSON.stringify(accounts));
                this.checkFavorite = true
                this.$toast.success("numberAddedtoFavorites")
                this.$emit('close')
                this.$emit('changeFav')
            } else {
                console.log('Account already exists in the favorites.');
                this.$toast.error(this.$t('"AccountAlreadyExistsFavorites"'))
            }
        },
        save(){
            // Редагуваємо картку 

            let accounts = JSON.parse(localStorage.getItem('favoriteAccounts')) || [];
    
            let updatedAcc = {
                "accountId": this.objForm.obj.accountId,
                "userPhone": this.objForm.obj.userPhone,
                "type": 'account',
                "date": this.currentDate(),
                "adequacy": this.form.adequacy,
                "comment": this.form.comment,
            };

            console.log("updatedAcc", updatedAcc);

            // Пошук індексу обраного акаунта за userPhone
            let indexToUpdate = accounts.findIndex(acc => acc.userPhone === updatedAcc.userPhone);

            if (indexToUpdate !== -1) {
                // Заміна акаунта за індексом
                accounts[indexToUpdate] = updatedAcc;
                localStorage.setItem('favoriteAccounts', JSON.stringify(accounts));
                this.$toast.success("numberUpdatedInFavorites");
                this.$emit('close');
            } else {
                console.log('Account not found in the favorites.');
                this.$toast.error(this.$('AccountNotInFavorites'));
            }
        },
        currentDate() {
            var date = new Date();
            var dateStr =
            date.getFullYear() + "-" +
            ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
            ("00" + (date.getDate())).slice(-2) + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2);
            return dateStr
        },
    }
}
</script>