<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0"><b>{{ $t('aboutApplication') }}</b></h5>
        </div>
        <div class="card-body">
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('phone_number') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content d-flex">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `" @click="this.$emit('getClient', this.obj)">{{ this.obj.userPhone }}</h6>
                                <i class="ri-file-copy-2-fill fs-16 copybtn text-secondary" style="margin-left: 10px;" @click="copy(this.obj.userPhone)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('priority') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy ${this.mutatePriority(obj.priority).bg}`" >{{ this.mutatePriority(obj.priority).name }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="obj.createWorkerName">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('created') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `" @click="copy(this.obj.createWorkerName)">{{ obj.createWorkerName }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="obj.createDatetime">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('createDate') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `" @click="copy(this.obj.createDatetime)">{{ obj.createDatetime }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="obj.createComment || this.obj.ticketName">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('Comment') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`mb-0 fs-16 `" v-if="this.obj.createComment" @click="this.obj.createComment == 'new_registration' ? copy(this.$t('newRegister')) :  copy(this.obj.createComment)">{{ obj.createComment == 'new_registration' ? this.$t('newRegister') : (obj.createComment == 'create_company' ? this.$t('newComp') :  this.obj.createComment) }}</h6>
                                <h6 :class="`mb-0 fs-16 `" style="cursor: pointer" @click="this.openTicket(this.obj.ticketId)" v-if="this.obj.ticketName">{{this.$t('createdFromTicket')}}: {{ this.obj.ticketName }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="this.obj.fromSite == 1">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('Comment') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`mb-0 fs-16 `" @click="copy(this.$t('fromSite'))">{{ this.$t('fromSite') }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('Cause') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`mb-0 fs-16 `" @click="this.mutateTriggers(this.trigger)">{{ this.mutateTriggers(this.trigger) }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import { mutatePriority, mutateTriggers } from '@/usabilityScripts/globalMutate.js'
import {copy} from '@/usabilityScripts/copy.js'

export default{
    props: ['obj', 'trigger'],
    data(){
        return {
            form: '',
        }
    },
    created(){
        this.form = this.obj
        console.log(this.form)
    },
    methods: {
        mutatePriority(e){
            return mutatePriority(e)
        },
        mutateTriggers(name) {
            return mutateTriggers(name)
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    }
}
</script>
