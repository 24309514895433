<template>
            <!-- <div class="col-sm-auto" style="margin-bottom:15px;">
                <button @click="showall()" type="button" :class="`btn btn-${icon == true ? 'primary': 'info'} waves-effect waves-light`"><i :class="`${icon == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
            </div> -->
            <h4 @click="ifMain = !ifMain" class="copy"><b>{{$t('main')}}</b></h4>
            <hr />
            <b-row
            v-if="ifMain">
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('nameOnlineShop')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5> - {{form.store_title}}</h5>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('desc')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5> - {{form.description}}</h5>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('lang')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5> - {{form.lang}}</h5>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <h4 @click="ifWeb = !ifWeb" class="copy"><b>{{$t('webadr')}}</b></h4>
            <hr />
            <b-row
            v-if="ifWeb">
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('DomUsage')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5> - {{this.mutateDomain(form.domainSettings.type)}}</h5>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-text>
                            <!-- <h5> - {{form.domainSettings.type}}</h5> -->
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.domainSettings.domain" disabled>
                                <button @click="copy(form.domainSettings.domain)" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                                <a target="_blank" :href="`https://${form.domainSettings.domain}`" class="btn btn-success" type="button"><i class="ri-external-link-line"></i></a>
                            </div>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <h4><b>{{$t('additionally')}}</b></h4>
            <hr />
            <h5 @click="ifAdMain = !ifAdMain" class="copy"><b>{{$t('main')}}</b></h5>
            <hr />
            <b-row
            v-if="ifAdMain">
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('menuOfProducts')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5> - {{form.sklad_id}}</h5>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('MinimalOrder')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5> - {{form.minOrderSum}}</h5>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('soundNotifi')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5> - {{form.sound}}</h5>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('theme')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5> - {{form.theme}}</h5>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <h5 @click="ifContacts = !ifContacts" class="copy"><b>{{$t('contacts')}}</b></h5>
            <hr />
            <b-row
            v-if="ifContacts">
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('contacts')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5 v-for="item in form.contactarr" :key="item"> - <b>{{item.type}}</b> - {{item.str}}</h5>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('ChannelsConn')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5><i class="ri-mail-fill me-2 align-middle"></i> - {{form.email}}</h5>
                            <h5><i class="ri-facebook-box-fill me-2 align-middle"></i> - {{form.facebook}}</h5>
                            <h5><i class="ri-telegram-fill me-2 align-middle"></i> - {{form.teleg}}</h5>
                            <h5><i class="ri-youtube-fill me-2 align-middle"></i> - {{form.youtube}}</h5>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <h5 @click="ifDelivery = !ifDelivery" class="copy"><b>{{$t('Delivery')}}</b></h5>
            <hr />
            <b-row
            v-if="ifDelivery">
                <b-col
                v-if="form.deliverytype.pickup.status == true"
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('Pickup')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5> - {{$t('Payment')}} :</h5>
                            <div class="hstack text-wrap gap-1">
                                <span :class="`fs-14 badge badge-soft-${this.mutatePayPickup(item).color}`" v-for="item in form.deliverytype.pickup.payments" :key="item">{{this.mutatePayPickup(item).name}}</span>
                            </div><br />
                            <h5> - {{$t('Outlets')}} :</h5>
                            <div class="hstack text-wrap gap-1">
                                <span class="fs-14 badge badge-soft-primary" v-for="item in form.deliverytype.pickup.tradepoints" :key="item">{{item.tradepointName}}</span>
                            </div>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                v-if="form.deliverytype.courier.status == true"
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('CourierDelivery')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5> - {{$t('Payment')}} :</h5>
                            <div class="hstack text-wrap gap-1">
                                <span :class="`fs-14 badge badge-soft-${this.mutatePayPickup(item).color}`" v-for="item in form.deliverytype.courier.payments" :key="item">{{this.mutatePayPickup(item).name}}</span>
                            </div>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                v-if="form.deliverytype.deliveryservice.status == true"
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('DeliveryService')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5> - {{$t('Payment')}} :</h5>
                            <div class="hstack text-wrap gap-1">
                                <span :class="`fs-12 badge badge-soft-${this.mutatePayPickup(item).cl}`" v-for="item in form.deliverytype.deliveryservice.payments" :key="item">{{this.mutatePayPickup(item).r}}</span>
                            </div>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <h5 @click="ifOther = !ifOther" class="copy"><b>{{$t('Other')}}</b></h5>
            <hr />
            <b-row
            v-if="ifOther">
                <b-col
                lg="12">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('aboutTT')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <div class="ql-editor" v-html="form.pages[0].text">
                            </div>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="12">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('deliveryinfo')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <div class="ql-editor" v-html="form.pages[1].text">
                            </div>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
</template>

<script>
import modal from '@/components/modal/modal-lg'
import {copy} from '@/usabilityScripts/copy.js'

export default ({
    props: ['obj'],
    components: { modal },
    data() {
        return{
            form: "",
            ifMain: true,
            ifWeb: true,
            ifAdMain: true,
            ifContacts: false,
            ifDelivery: false,
            ifOther: false,
            icon: false
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        showall(){
            this.ifMain = !this.ifMain,
            this.ifWeb = !this.ifWeb,
            this.ifAdMain = !this.ifAdMain,
            this.ifContacts = !this.ifContacts,
            this.ifDelivery = !this.ifDelivery,
            this.ifOther = !this.ifOther;
            this.icon = !this.icon
        },
        mutateDomain(e){
            return mutateDomainSkyMarket(e)
        },
        mutatePayPickup(e){
            return mutatePayPickupSkyMarket(e)
        }
    }
})
</script>
