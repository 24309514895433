<template>
    <!-- 
        Компонент модального для запрошення працівників (створювалось під список співробітників)
    -->
    
    <div class="modal fade show" id="inviteMembersModal" style="display: block;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-0" >

                <!-- Заголовок модального вікна -->
                <div class="modal-header p-3 ps-4 bg-soft-success" >
                    <h5 class="modal-title" id="inviteMembersModalLabel">
                        {{$t('Employees')}}
                    </h5>

                    <!-- Кнопка закриття модального вікна -->
                    <button type="button" class="btn-close" @click="$emit('close')"></button>
                </div>

                <!-- Тіло модального вікна -->
                <div class="modal-body p-4" >

                    <!-- Пошукове поле -->
                    <div class="search-box mb-3">
                        <input type="text" class="form-control bg-light border-light" v-model="search" :placeholder="$t('searc_inp')">
                        <i class="ri-search-line search-icon"></i>
                    </div>

                    <!-- Кнопки для вибору та зняття виділення з усіх працівників -->
                    <div class="mt-2 mb-2 hstack gap-sm-1 gap-1 align-items-center flex-wrap email-topbar-link">
                        <span class="copy badge bg-success fs-14" @click="selectAll">{{ $t('SelectAll') }}</span>
                        <span class="copy badge bg-danger fs-14" @click="removeAll">{{ $t('RemoveAll') }}</span>
                    </div>

                    <!-- Відображення вибраних працівників -->
                    <div class="mb-2 d-flex align-items-center" >
                        <div class="me-2" >
                            <h5 class="mb-0 fs-16 fw-semibold mb-2">{{ $t('selectedWorkers') }} :</h5>
                            <template v-for="item in form.workers" :key="item">
                                <span v-if="item.checked" class="badge bg-info fs-12 m-1" style="margin-right: 5px;" >{{ item.workerName }}</span>
                            </template>
                        </div>
                    </div>

                    <!-- Список працівників з можливістю вибору -->
                    <div style="max-height: 257px; overflow: auto;" >
                        <!-- Перевірка, чи є працівники, які відповідають критеріям пошуку -->
                        <ul class="list-group list-group-flush border-dashed" v-if="filterWorkers.length > 0">
                            <!-- Виведення працівників -->
                            <li class="list-group-item ps-0" v-for="(item, index) in filterWorkers" :key="index">
                                <div class="d-flex align-items-start" >
                                    <div class="flex-grow-1" >
                                        <!-- Ім'я працівника -->
                                        <h5 class="fs-13 mb-0 copy" @click="this.$emit('open', item.workerId)">{{item.workerName}}</h5>
                                    </div>
                                    <div class="flex-shrink-0 ms-2">
                                        <!-- Кнопка додавання або видалення працівника -->
                                        <button 
                                            type="button" 
                                            class="btn btn-secondary btn-sm" 
                                            @click="closeShift(item)"
                                        >
                                            {{ $t('close') }}
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        
                        <!-- Виведення повідомлення про відсутність працівників, які відповідають критеріям пошуку -->
                        <b v-else>{{ $t('noWorkersWithName') }}</b>
                    </div>

                </div>

                <div class="modal-footer" >
                    <!-- Кнопка для збереження вибраних працівників -->
                    <button 
                        type="button" 
                        class="btn btn-success w-xs"
                        @click="invite"
                    >
                        {{ $t('save') }}
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { storeS } from '@/store' // глобальне сховище даних

export default ({
    props: ['projectWorkers'],
    data() {
        return{
            form: {
                workers: []
            },
            search: '' // Пошук
        }
    },
    created(){
        
        for(var item in this.workers){
            this.form.workers.push({
                workerId: this.workers[item].workerId,
                workerName: this.workers[item].workerName,
            })
        }
        
    },
    methods: {
        closeShift(item) {
            this.$emit('closeShift', item)
        }
    },
    computed: {
        filterWorkers: function() {
            // Відфільтровані працівники
            return Object.values(this.form.workers)
                // По полю пошуку
                .filter(item => {
                    return this.search == '' || item.workerName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        workers(){
            /*
                Беремо список співробітників з глобального стору.
            */
            return storeS.usersWS
        }
    }
})
</script>

<style scoped>
.modalshow {
    display: block;
    opacity: 1;
}

.modal {
    background: #2e2e2eb8;
    transition: opacity 0.15s linear;
    backdrop-filter: blur(5px);
}

</style>
