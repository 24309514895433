<template>
    <!-- 
        Модальне вікно створення категорії 
    -->

    <dialogBox >
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('Add') }} {{ form.categoryName }}</template>
        <template v-slot:body>

            <!-- Вибір мови -->
            <h5 class="kb-title">
                <b>{{ $t('chooseLang') }}</b>
            </h5>
            <select class="form-select mb-3" v-model="form.lang">
                <option value="0">{{ $t('chooseLangList') }}</option>
                <option v-for="item in lang" :key="item" :value="item.value">{{item.title}}</option>
            </select>

            <!-- Назва категорії -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('titleCategory') }}</h4>
                <input type="text" class="form-control" id="contactNumber" v-model="form.categoryName">
            </div>

            <!-- Сортування -->
            <div class="mb-3 mt-2" >
                <h4 class="fs-15">{{ $t('Sorting') }}</h4>
                <input type="text" class="form-control" placeholder="№" v-model="form.sort">
            </div>

            <!-- Чек на батьківську категорію -->
            <div class="d-flex align-items-start">
                <div class="form-check ps-0 flex-sharink-0">
                    <input type="checkbox" class="form-check-input ms-0" v-model="checkParrent" @click="checkParrent == false ? getCategories() : ''" :value="checkParrent" id="checkParrent" />
                </div>
                <div class="flex-grow-1">
                    <label class="form-check-label mb-0 ps-2" for="checkParrent" >{{ $t('SelectParentCategory') }}</label>
                </div>
            </div>

            <!-- Поле вибору батьківської категорії -->
            <div class="mb-3 mt-2" v-if="checkParrent == true">
                <h4 class="fs-15">{{ $t('parentCategory') }}</h4>
                <Multiselect 
                    v-model="form.parentCategoryId"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="categoriesList"
                />
            </div>
        </template>

        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom>

            <!-- Закрити вікно -->
            <button 
                class="btn link-success fw-medium" 
                @click="$emit('close')"
            >
                <i class="ri-close-line me-1 align-middle"></i> 
                {{ $t('close') }}
            </button>

            <!-- Кнопка для додавання нової категорії -->
            <button 
                v-if="this.perms_edit === false"
                type="button" 
                class="btn btn-primary" 
                v-on:click="add"
            >
                {{ $t('Add') }}
            </button>

            <!-- Кнопка для редагування категорії -->
            <button 
                v-if="this.perms_edit === true"
                type="button" 
                class="btn btn-primary" 
                v-on:click="edit"
            >
                {{ $t('Edit') }}
            </button>
            
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модального вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { KW, knowledgeBaseProject } from '@/API.js';  // клас для взаємодії з API
import { storeS } from '@/store.js' // глобальне сховище даних
import { eventB } from '@/main' //for indexedDB
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new KW();
let apiKWProjects = new knowledgeBaseProject();

export default {
    components: { dialogBox, Multiselect },
    props:['obj', 'perms_edit'],
    data() {
        return {
            dontSave: false, // прапорець, що вказує, чи потрібно зберігати дані перед закриттям компонента
            checkParrent: false,
            form: { // Дані для форми
                categoryId: '', //id категорії
                categoryName: '', // назва категорії
                lang: "", // мова категорії
                parentCategoryId: "0",
                sort: "0", // порядковий номер
                projectId: null // id проекту 
            },
            lang:[ // Масив з доступними мовами
                {
                    title:"Українська",
                    value: "uk"
                },
                {
                    title:"Poland",
                    value: "pl"
                },
                {
                    title:"Російська",
                    value: "ru"
                }
            ],
            categoriesList: []
        };
    },
    created(){
        // Ініціалізація даних форми при створенні компонента

        // Застосовуємо id проекту до категорії
        if(this.$route.params.id){
            this.form.projectId = this.$route.params.id;
        }

        // перевірка на редагувавння
        if(this.perms_edit === true) {
            this.form.categoryId = this.obj.categoryId;
            this.form.parentCategoryId = this.obj.parentCategoryId;
            this.form.categoryName = this.obj.categoryName;
            this.form.lang = this.obj.lang;
            this.form.sort = this.obj.sort;
            this.form.projectId = this.obj.projectId
        } else {
            if(this.obj.children){
                this.form.parentCategoryId = this.obj.categoryId;
                this.form.lang = this.obj.lang;
                this.form.sort = this.obj.children.length+1;
                this.form.projectId = this.obj.projectId

                this.checkParrent = true
            } else {
                this.form ={
                    categoryName: '',
                    lang: "",
                    parentCategoryId: "0",
                    sort: "0",
                    projectId: this.$route.params.id
                }
            }
        }
        
        // Батьківські категорії
        // this.categories.forEach(el => {
        //     if(el.lang == this.user.lang) {
        //         this.categoriesList.push({
        //             label: el.categoryName,
        //             value: el.categoryId
        //         })
        //     }
        // })
        
        //перевірка на наявність не закінченних заявок
        let openRequest = indexedDB.open("store", 1);
            openRequest.onsuccess = function() {
                let db = openRequest.result;
                let transaction = db.transaction("data", "readwrite"); // (1)
                
                // получить хранилище объектов для работы с ним
                let data = transaction.objectStore("data"); // (2)
                let categoryIndex = data.index("сategory_idx");

                let request = categoryIndex.getAll('KWCategory')

                request.onsuccess = function() {
                    console.log(request.result)
                    if (request.result !== undefined && request.result.length > 0) {
                        eventB.emit('haveForms', {status: true, forms: request.result})
                    } else {
                        console.log('Незакінченні форми не знайдені. Дякую за збереження екології, бо ви не зайняли зайвого місця на серверах Зімбабве =)')
                    }
                };

                request.onerror = function() {
                    alert('Щось пішло не так, перезапустіть будь ласка сторінку')
                }
            }
        //перевірка на наявність не закінченних заявок

        //вибір дії було зроблено, обробка
        
        this.eventBus.off('openOld')
        this.eventBus.on('openOld', status => {
            console.log(status)
            if(status.status == true) {
                let openRequest = indexedDB.open("store", 1);
                    openRequest.onsuccess = function() {
                        let db = openRequest.result;
                        let transaction = db.transaction("data", "readwrite"); // (1)
                        
                        // получить хранилище объектов для работы с ним
                        let data = transaction.objectStore("data"); // (2)
                        let categoryIndex = data.index("сategory_idx");

                        let request = categoryIndex.getAll(status.form.category)

                        request.onsuccess = function() {
                            if (request.result !== undefined) {
                                request.result.forEach(el=> {
                                    if(el.id == status.form.id) {
                                        let deleteReq = data.delete(el.id)
                                            deleteReq.onsuccess = function() {
                                                console.log('All okey') //видалення, щоб не було дублікатів
                                            }
                                    }
                                })   
                            }
                        };

                        request.onerror = function() {
                            alert('Щось пішло не так, перезапустіть будь ласка сторінку')
                        }
                    }
                this.form = JSON.parse(status.form.form)
            }
        })
        
        this.eventBus.off('createNew')
        this.eventBus.on('createNew', status => {
            if(status.status == true) {
                return
            }
        })
        //вибір дії було зроблено, обробка
    },
    mounted() {
        if(this.obj != '' && this.form.categoryName == '' && this.form.lang == '') { //перевірки на випадок взяття старої форми з indexedDB
            this.form.parentCategoryId = this.obj.categoryId
            this.checkParrent = true
            console.log(this.form.parentCategoryId)
        }
    },
    beforeUnmount() {
        if(this.dontSave == false) {
            sessionStorage.setItem('actual', JSON.stringify(this.form))
            if(this.form.categoryName !== '' || this.form.lang !== '') {
                let openRequest = indexedDB.open("store", 1);
                    openRequest.onsuccess = function() {
                        let db = openRequest.result;
                        let transaction = db.transaction("data", "readwrite"); // (1)
                    
                        // получить хранилище объектов для работы с ним
                        let data = transaction.objectStore("data"); // (2)
                        let category_text = 'KWCategory';
                        let detail = {
                            dateTime: new Date(),
                            text: '',
                            category: category_text,
                            form: sessionStorage.getItem('actual')
                        };

                        let request = data.add(detail); // (3)

                        request.onsuccess = function() { // (4)
                            sessionStorage.removeItem('actual')
                            console.log("Данные добавлены в хранилище", request.result);
                        };

                        request.onerror = function() {
                            console.log("Ошибка", request.error);
                        };
                    } //додавання в indexedDB
            }
        }
    },
    methods: {
        edit() {
            /**
                * Редагування категорії
            */

            let id = this.form.categoryId;
            delete this.form.categoryId;

            apiServe.editCateg(id, this.form).then(result=> {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('alertChanged'));
                    this.dontSave = true
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка редагування категорії'; 
                    var statusRequest = result.status;
                    var api = 'editCateg';
                    var fileRequest = 'src/components/knowledge-base/actions/addCategory.vue';
                    var params = this.form;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        add() {
            /**
                * Додавання нової категорії з урахуванням валідації полів.
            */

            if (!this.form.categoryName) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
            }
            if (!this.form.lang) {
                //перевіряємо чи вказаний ленг
                this.$toast.error(this.$t('EnterLang'));
            }
            if(this.form.categoryName && this.form.lang){
                apiServe.addCategory(this.form).then(result => {
                    if(result.status==='done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.dontSave = true
                        this.$emit('close')
                        this.$emit('getdata')
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var errorText = 'Помилка створення категорії'; 
                        var statusRequest = result.status;
                        var api = 'addCategory';
                        var fileRequest = 'src/components/knowledge-base/actions/addCategory.vue';
                        var params = this.form;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
        getCategories(){
            // отримання батьківський категорій по проекту

            this.categoriesList = []

            apiKWProjects.getCategoriesTree(this.form.projectId).then(result => {
                if(result.status == 'done'){
                    result.data.forEach(el => {
                        if(el.lang == this.user.lang && el.parentCategoryId == 0) {
                            this.categoriesList.push({
                                label: el.categoryName,
                                value: el.categoryId
                            })
                        }
                    })
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        }
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        categories() {
            /*
                Повертає категорії з глобального стору.
            */
            return storeS.KBCategories
        }
    }
}
</script>