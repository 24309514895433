<template>
    <b-card style="margin-bottom: 0rem;">
        <b-row>
            <h3><b>{{ $t('clientOperations') }}</b></h3>
        </b-row>
    </b-card>
        <b-row>
            <b-col lg="12">
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <!-- фильтра выбора даты добавить -->
                        <div class="flex-grow-1 overflow-hidden" >
                            <b-tabs pills>
                                <b-tab :title="$t('payMainProduct')" active>
                                    <!-- <div style="margin:20px 0px;"><payMainProduct :obj="this.pay" :license="this.obj.license" /></div> -->
                                </b-tab>
                                <b-tab :title="$t('payAddonsServices')" @click="getServices()" >
                                    <template v-if="isOpenServices == true"><div style="margin:20px 0px;"><payAddonsServices :obj="this.services" /></div></template>
                                </b-tab>
                                <b-tab :title="$t('payAddonsModules')">
                                    <div style="margin:20px 0px;">dev</div>
                                </b-tab>
                                <b-tab :title="$t('payDevices')">
                                    <div style="margin:20px 0px;">dev</div>
                                </b-tab>
                                <b-tab :title="$t('bonusConnectFriend')" >
                                    <div style="margin:20px 0px;">dev</div>
                                </b-tab>
                                
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
</template>

<script>
// import payMainProduct from '../../blocks/activity/payments/index'
import payAddonsServices from './payAddonsServices.vue'
import { Services } from "@/API.js";

let apiServices = new Services();

export default({
    props: ['obj'],
    components: {
        // payMainProduct,
        payAddonsServices
    },
    data() {
        return{
            form: "",
            services: "",
            isOpenServices: false,
        }
    },
    created(){
        
    },
    methods: {
        getServices(){
            apiServices.getServiceClient(this.obj.user_login).then(result => {
                this.services = result.filter(item => item.status == 'problem_solved');
                this.isOpenServices = true
            })
        },
    }
})
</script>
