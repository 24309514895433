<template>
    <!-- Футер секція -->
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    <!-- Поточний рік та версія -->
                    {{new Date().getFullYear()}} © SkyInside | Ver : <b>{{ this.getVer() }}</b>
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-end d-none d-sm-block">
                        <!-- Кредити розробників -->
                        Develop by <a href="https://t.me/golovan_maxim" target="_blank" class="fw-semibold">Max</a>, <a href="https://t.me/B1sTOnFire" target="_blank" class="fw-semibold">Borys</a> and <a href="https://t.me/olegkrkr" target="_blank" class="fw-semibold">Oleg</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { storeS } from '@/store'

export default {
    data() {
        return {
            form: '' // порожній рядок
        }
    },
    created() {
        // немає коду
    },
    methods: {
        getVer() {
            // Отримати поточну дату
            var date = new Date();
            var dateStr =
                date.getFullYear() + "-" +
                ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                ("00" + (date.getDate() + 1)).slice(-2) + " " +
                ("00" + date.getHours()).slice(-2) + ":" +
                ("00" + date.getMinutes()).slice(-2) + ":" +
                ("00" + date.getSeconds()).slice(-2);

            // Отримати версію на основі поточної дати та термінів
            var vers = '';
            var insLines = this.dealines.filter(item => item.createWorkerId == 34);

            if (dateStr > this.dealines[this.dealines.length - 1].deadlineDate) {
                vers = this.dealines[this.dealines.length - 1].version;
            } else {
                for (var item in insLines) {
                    if (dateStr > insLines[item].deadlineDate) {
                        vers = insLines[item].version;
                    }
                }
            }
            return vers;
        }
    },
    computed: {
        dealines() {
            // Отримати терміни з магазину
            return storeS.dealines;
        }
    }
}
</script>