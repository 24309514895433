//Модуль перехвата нажатия клавиши
//Используем всегда когда надо перехватить нажатие клавиши что бы повторно не навешивать addEventListener который надо будет как-то убивать и заново добавлять...
import { eventKey } from '@/main.js';
import { storeS } from '@/store.js';
export default window.addEventListener("keydown", event => {
	var eventK = event;
	if(storeS.checks['002'] === true){
		if(eventK.altKey == true && eventK.shiftKey == false && eventK.key == 'Enter') {
			eventKey.emit('sendForm') // під вінду
		} else if (eventK.keyCode === 27) {
			eventKey.emit('esc');
		} else if (eventK.keyCode === 8) {
			// eventKey.emit('remove');
		} else if (eventK.keyCode === 13) {
			// eventKey.emit('enter')
		} else if ((eventK.keyCode === 37 && eventK.altKey == true && eventK.ctrlKey == true) || (eventK.metaKey == true && eventK.ctrlKey == true && eventK.key == 'ArrowLeft')) {
			eventKey.emit('arrowleft')
		} else if ((eventK.keyCode === 39 && eventK.altKey == true && eventK.ctrlKey == true) || (eventK.metaKey == true && eventK.ctrlKey == true && eventK.key == 'ArrowRight')) {
			eventKey.emit('arrowright')
		} else if (eventK.keyCode === 75 && eventK.metaKey == true && eventK.key == 'k') {
			eventKey.emit('showGS')
		} else if (eventK.keyCode === 75 && eventK.metaKey == false && eventK.altKey == true && eventK.code == 'KeyK') {
			eventKey.emit('showGS')
		}
		// else if (eventK.keyCode === 75 && eventK.altKey == true && eventK.code == 'KeyK') {
		// 	eventKey.emit('dublicate')
		// }
	}
})
