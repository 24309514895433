<template>
    <!-- Компонент для вибору фільтру по країні -->

    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="countryFilter-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
        >
            {{ $t('country') }}
        </label>
        <br />
        <div class="row g-2 list_filtr" >
            <!-- Вибір "Усі країни" -->
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioStatus" id="all_countryFilter" v-model="countryFilter" :value="''">
                    <label class="form-check-label" for="all_countryFilter">{{ $t('all') }}</label>
                </div>
            </div>
            <!-- Цикл для виведення чекбоксів для кожної країни -->
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="checkbox" @change="change(value.value)" name="radioStatus" :id="'countryFilter'+index" v-model="countryFilter" :value="value.value">
                        <label class="form-check-label" :for="'countryFilter'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            countryFilter: [], // Масив для зберігання обраних фільтрів по країні
            opt: [ // Масив зі списком країн та їх значень
                {
                    name: 'Україна',
                    value: "UA",
                },
            ]
        }
    },
    methods: {
        change(e){
            // Метод, що викликається при зміні фільтру

            // Додаємо обрані значення в масив
            this.countryFilter.push(e)
            // Відправляємо подію для внесення змін у батьківський компонент
            this.$emit('change', 'countryFilter', this.countryFilter)
        },
    },
    computed: {
    }
}
</script>