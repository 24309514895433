<template>
    <!-- модальное окно просмотра урока -->
    <modal :title="this.$t('ForRemoval')" :stylebody="'var(--vz-body-bg)'">
      <template v-slot:head-bottom>
        <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')">
          <i class="ri-close-fill align-bottom"></i>
        </button>
      </template>
      <template v-slot:body>
         <main class = 'main_section'>
          <div style="background-color: white; border-radius: 7px; padding: 20px; margin-top: 10px;">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" style="width: calc(100%/10);">#</th>
                  <th scope="col" style="width: calc(100% - ((100%/10)*7)); text-align: center;">{{ this.$t('name') }}</th>
                  <th scope="col" style="width: calc(100% - ((100%/10)*6)); text-align: center;">{{ this.$t('action') }}</th>
                  <th scope="col" style="width: calc(100% - ((100%/10)*8));  text-align: center;">{{ this.$t('transferedTo') }}</th>
                </tr>
              </thead>
              <!-- при видаленні батьківської категорії -->
              <tbody v-if="whatDelete == 'fatherCategory'">
                <tr v-for="(item, idx) in this.items" :key="idx" :class="this.onDelete.indexOf(item) == -1 ? (this.onTransfer.indexOf(item) == -1 ? '' : 'table-success') : 'table-danger'">
                  <th scope="row" style="text-align: center;">{{idx}}</th>
                  <td style="text-align: center;">{{ item.categoryName }}</td>
                  <td style="text-align: center; display: grid; align-items: center; justify-content: center;">
                    <select class="form-select" aria-label="Default select example" v-if="this.onTransfer.indexOf(item) != -1" @input="chooseCategory($event.target.value, item)">
                      <option value="null">{{ this.$t('Null') }}</option>
                      <option v-for="item in this.otherCateg" :value="item.categoryId">{{ item.categoryName }}</option>
                    </select>
                    <div><p></p></div>
                    <button type="button" class="btn btn-danger" style="margin-bottom: 5px;" @click="this.delete(item, idx)">{{ this.$t('Delete') }}</button>
                    <button type="button" class="btn btn-success" @click="this.transfer(item, idx)">{{this.$t('transfer')}}</button>
                  </td>
                  <td style="text-align: center;">
                    {{ this.otherCateg.find(el => el.categoryId == item.category) ? this.otherCateg.find(el => el.categoryId == item.category).categoryName : (this.onDelete.indexOf(item) != -1 ? this.$t('Removed') : '')}}
                  </td>
                </tr>
              </tbody>
              <!-- при видаленні батьківської категорії -->

              <!-- при видаленні категорії з постами -->
              <tbody v-if="whatDelete == 'childCategory'">
                <tr v-for="(item,index) in this.items" :key="index" :class="this.onDelete.indexOf(item) == -1 ? (this.onTransfer.indexOf(item) == -1 ? '' : 'table-success') : 'table-danger'">
                  <th scope="row" style="text-align: center;">{{ index }}</th>
                  <td style="text-align: center;">{{ item.lessonName }}</td>
                  <td style="text-align: center; display: grid; align-items: center; justify-content: center;">
                    <select class="form-select" aria-label="Default select example"  v-if="this.onTransfer.indexOf(item) != -1" @input="chooseCategory($event.target.value, item)">
                      <option value="null">{{ this.$t('Null') }}</option>
                      <option  v-for="item in this.otherCateg" :value="item.categoryId">{{ item.categoryName }}</option>
                    </select>
                    <div><p></p></div>
                    <button type="button" class="btn btn-danger" style="margin-bottom: 5px;"  @click="this.delete(item, index)">{{ this.$t('Delete') }}</button>
                    <button type="button" class="btn btn-success"  @click="this.transfer(item, index)">{{this.$t('transfer')}}</button>
                  </td>
                  <td style="text-align: center;">
                    {{ this.otherCateg.find(el => el.categoryId == item.category) ? this.otherCateg.find(el => el.categoryId == item.category).categoryName : (this.onDelete.indexOf(item) != -1 ? this.$t('Removed') : '')}}
                  </td>
                </tr>
              </tbody>
              <!-- при видаленні категорії з постами -->
            </table>
          </div>
        </main>
      </template>
      <template v-slot:footer-bottom>
        <button type="button"  class="btn btn-success" v-if="this.onDelete.length + this.onTransfer.length == this.items.length" @click="finish()">{{ $t('done') }}</button>
        <button type="button"  class="btn btn-info" @click="checkall()">{{ this.$t('takeAll') }}</button>
      </template>
    </modal>
    

  </template>
  
  <script>
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import modal from '@/components/modal'
  import { KW } from '@/API.js';
  import { storeS } from '@/store.js'
  
  let apiServe = new KW();
  
  export default {
    data() {
      return {
        whatDelete: '',
        formForFather: {
          transferCategories:[
            // {
            //   "categoryId": 88,
            //   "parentCategoryId": 84
            // }
          ],

        },
        formForChild: {
          deleted: [
            // id категорій
          ],
          transferLessons: [
            // {
            //   "categoryId": 1,
            //   "lessonId": 1
            // } 
          ],
        },
        onDelete: [],
        onTransfer: [],
        items: []
      };
    },
    props: ['father', 'categoryForDelete', 'otherCateg', 'lessons'],
    components: {
      modal,
      Multiselect
    },
    created() {
      console.log('father:', this.father, 'categoryForDelete:', this.categoryForDelete, 'otherCateg:', this.otherCateg, 'lessons', this.lessons)
      if(this.father == '') {
        this.whatDelete = 'fatherCategory'
        this.categoryForDelete.children.forEach(el => {
          this.items.push(el)
        })
      } else {
        this.whatDelete = 'childCategory'
        this.lessons.forEach(el => {
          this.items.push(el)
        })
      }
    },
    methods: {
      transfer(item, index) {
        if(this.onDelete.indexOf(item) != -1) {
          this.onDelete.splice(this.onDelete.indexOf(item), 1)
        }
        this.onTransfer.push(item)
      },
      delete(item, index) {
        if(item['category']) {
          delete item['category']
        }
        if(this.onTransfer.indexOf(item) != -1) {
          this.onTransfer.splice(this.onTransfer.indexOf(item), 1)
        }
        this.onDelete.push(item)
      },
      chooseCategory(e, item) {
        this.onTransfer[this.onTransfer.indexOf(item)]['category'] = e
      }, 
      checkall() {
        if(this.whatDelete == 'fatherCategory') {
          this.items.forEach(el => {
            if(this.onTransfer.indexOf(el) == -1) {
              this.onDelete.push(el)
            }
          })
        } else {

        }
      },
      finish() {
        if(this.whatDelete == 'fatherCategory') {
          if(this.onDelete.length > 0) {
          }

          if(this.onTransfer.length > 0) {
            if(this.onTransfer.find(el => el.category == undefined) || this.onTransfer.find(el => el.category == 'null')) {
              this.$toast.error(this.$t('enterTransferCategoryFor') + ` ${this.onTransfer.find(el => el.category == undefined) ? this.onTransfer.find(el => el.category == undefined).categoryName : this.onTransfer.find(el => el.category == 'null').categoryName}`)
              return
            } // альорт, щоб не було пустих переміщень і багів у витоці
            this.onTransfer.forEach(el => {
              this.formForFather.transferCategories.push({
                categoryId: el.categoryId,
                parrentCategoryId: Number(el.category)
              })
            })
          }
          console.log(this.formForFather)
        } else {
          if(this.onDelete.length > 0) {
            this.onDelete.forEach(el => {
              this.formForChild.deleted.push(el.lessonId)
            })
          }

          if(this.onTransfer.length > 0) {
            if(this.onTransfer.find(el => el.category == undefined) || this.onTransfer.find(el => el.category == 'null')) {
              this.$toast.error(this.$t('enterTransferCategoryFor') + ` ${this.onTransfer.find(el => el.category == undefined) ? this.onTransfer.find(el => el.category == undefined).lessonName : this.onTransfer.find(el => el.category == 'null').lessonName}`)
              return
            } // альорт, щоб не було пустих переміщень і багів у витоці
            this.onTransfer.forEach(el => {
              this.formForChild.transferLessons.push({
                lessonId: el.lessonId,
                categoryId: Number(el.category)
              })
            })
          }
          apiServe.delWithLessons(this.categoryForDelete.categoryId, this.formForChild).then(result => {
            if(result.status == 'done') {
              this.$toast.success(this.$t('Removed'))
              this.$emit('getdata')
              this.$emit('close')
            } else {
              this.$toast.error(this.$t('error'))
            }
          })
        }
      }
    },
    computed: {
      user(){
        return storeS.userbase
      },
      checkedNames() {
        return this.checked.join(', ')
      },
    },
    mounted() {
      
    },
  };
  </script>
  
  <style scoped>
  .arrow_back {
    margin-bottom: 10px;
  }
  </style>