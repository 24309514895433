<template>
    <div class="col-sm-auto" style="margin-bottom:15px;">
      <button @click="infoblockShow == true ? infoblockShow = false : infoblock()" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
    </div>
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />
    <tablecustom @search="searchB" :columns="columns" :rows="filteredItems" @open="open"/>
    <template v-if="showModal == true" >
        <viewpay @close="showModal = false" :shortapplic="cardApplic"/>
    </template>
</template>

<script>
import tablecustom from '@/components/globaltable/index';
import infoblocks from '@/components/info/blocks'
// import viewpay from './view'
import { mutateServices } from '@/usabilityScripts/globalMutate.js'

export default {
    props: ['obj'],
    components: { tablecustom, infoblocks },
    data() {
        return {
            form: {},
            search: "",
            cardApplic: "",
            showModal: false,
            infoblockShow: false,
            columns: [
                {
                    name: this.$t("servicePackage"),
                    text: "service_title",
                    align: "right",
                },
                {
                    name: this.$t("Sum"),
                    text: "service_cost",
                    align: "left",
                },
                {
                    name: this.$t("date"),
                    text: "create_datetime",
                    align: "left",
                }
          ],
          information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                    sum: "0"
                }
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj;
            for(var value in this.form) {
                this.form[value].service_title = this.mutate_services(this.form[value].service_title)
            }
        },
        mutate_services(e){
            return mutateServices(e)
        },
        infoblock(){
            this.information[0].value = this.filteredItems.length
            
            this.information[0].sum = this.arraySum(this.filteredItems).toFixed(2)
            this.infoblockShow = true
        },
        arraySum(array){
            var sum = 0;
            for(var i = 0; i < array.length; i++){
                sum += Number(array[i].service_cost);
            }
            return sum
        },
        searchB(e){
            this.search = e
        },
        // open(e){
        //     this.cardApplic = e;
        //     this.showModal = true;
        // },
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.service_title.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })

        },
    }
}
</script>