<template>
    <!-- 
        Компонент верхнього меню/бару
    -->

    <!-- Прелоадер, який відображається при завантаженні даних -->
    <preloader v-if="this.show_in_preloader" :show_in_preloader="loader" />

    <!-- Тестовий компонент на повідомлення, у правому кутку екрана -->
    <notifyMsg />
    

    <!-- Верхня частина сторінки з головним меню -->
    <header id="page-topbar" :style="this.fullSize == true ? 'width: 100%; left: 0px' : ''">
        <div class="layout-width">
            <div class="navbar-header">
                <div class="d-flex head_menu">

                    <!-- Гамбургер-меню -->
                    <hamburger v-if="this.fullSize === false"/>

                    <!-- 
                        Верхній бар з посиланнями на різні функціональні блоки
                        - @openrequestbox - подія, яка відкриває картку створення заявки
                        - @opentaskbox - подія, яка відкриває картку створення завдання
                        - @openticketbox - подія, яка відкриває картку створення тікету
                        - @openinvoicesbox - подія, яка відкриває картку створення рахунку
                        - @openprojectbox - подія, яка відкриває картку створення проекту
                        - @opencalculator - подія, яка відкриває картку створення калькулятору
                        - @openphone - подія, яка відкриває телефонію
                    -->
                    <topmenu 
                        @openrequestbox="openrequestbox" 
                        @opentaskbox="opentaskbox" 
                        @openticketbox="openticketbox" 
                        @openinvoicesbox="openinvoicesbox" 
                        @openprojectbox="openprojectbox" 
                        @opencalculator="opencalculator" 
                        @openphone="openphone"
                    />

                    <!-- 
                        Віджет пошуку за номером, електронною поштою, айді
                        - @openacc - 
                        - @call -  
                    -->
                    <searchacc 
                        @openacc="openacc" 
                        @call="call"
                    />
                </div>
                
                <div class="d-flex align-items-center">

                    <!-- 
                        Віджет профілю користувача 
                    -->
                    <profile 
                        @openbreakbox="openbreakbox" 
                        @openAnonimMessageModal = "openAnonimMessageModal"
                        @myprofile="myprofile" 
                        @openShift="openShift"
                        @closeShift="checkTimeCloseShift"
                        @closeShiftWorker="closeShiftWorker"
                        @checkOpenShift="checkOpenShift"
                        @closeBreak="closeBreak"
                    />
                </div>
            </div>
        </div>
    </header>

    <!-- 
        Вікно з глобальним пошуком 
        - @close - закриває вікно
        - @openclient - подія, яка відкриває картку клієнта
        - @openrequestbox - подія, яка відкриває картку створення заявки
        - @opentaskbox - подія, яка відкриває картку створення завдання
        - @openticketbox - подія, яка відкриває картку створення тікету
        - @openinvoicesbox - подія, яка відкриває картку створення рахунку
        - @openprojectbox - подія, яка відкриває картку створення проекту
    -->
    <globalSearch 
        v-if="showGS" 
        @close="showGS = false" 
        @openclient="openclient"
        @openrequestbox="openrequestbox" 
        @opentaskbox="opentaskbox" 
        @openticketbox="openticketbox" 
        @openinvoicesbox="openinvoicesbox" 
        @openprojectbox="openprojectbox"
        @openuserbox="openuserbox"
        @openpresentbox="openpresentbox"
    />

    <!-- Модальне вікно для додавання заявки -->
    <createapplicBox 
        v-if="modalRequestBox" 
        @close="modalRequestBox = false" 
        @open="open"
    />

    <!-- Модальне вікно для додавання презентації -->
    <createPresentBox 
        v-if="modalPresentBox" 
        @close="modalPresentBox = false" 
        @open="open"
    />

    <!-- Модальне вікно для додавання завдання -->
    <createTaskBox 
        v-if="modalTaskBox" 
        @close="modalTaskBox = false" 
        @open="open"
    />

    <!-- Модальне вікно для додавання тікета -->
    <createTicketBox 
        v-if="modalTicketBox" 
        @close="modalTicketBox = false" 
        @openTickets="open"
    />

    <!-- Модальне вікно для додавання проекту -->
    <createProjectBox 
        v-if="modalProjectBox" 
        @close="modalProjectBox = false" 
        @open="open"
    />
    
    <!-- Модальне вікно для додавання рахунку -->
    <createInvoicesBox 
        v-if="modalInvoicesBox" 
        @close="modalInvoicesBox = false" 
        @open="open"
    />

    <!-- Модальне вікно для відкриття калькулятора -->
    <calculator 
        v-if="modalcalculator" 
        @close="modalcalculator = false"
    />

    <!-- Модальне вікно перерви -->
    <breakmodal 
        v-if="showmodalbreak == true" 
        @close="showmodalbreak = false" 
    />

    <!-- Модальне вікно для анонімних повідомлень -->
    <anonimMessage 
        v-if="showmodalanonMessage == true" 
        @close="showmodalanonMessage = false" 
    />

    <!-- Модальне вікно для додавання нового співробітника -->
    <createUserBox 
        v-if="modalUserBox == true"
        @close="modalUserBox = false" 
    />

    <!-- Модальне вікно власного профілю -->
    <viewuser 
        v-if="showProfile == true" 
        @close="showProfile = false" 
        :objcard="this.objcard" 
    ></viewuser>

    <!-- Модальне вікно з запитом на відкриття зміни -->
    <openShift 
        v-if="modalOpenShift"
        @close="gulyayPole"
        @openShift="openShift"
    />

    <!-- Модальне вікно закриття зміни раніше часу -->
    <closeShift 
        v-if="modalCloseShift"
        @close="modalCloseShift = false"
        @closeShift="closeShift"
    />

    <!-- Відображення інформації про смену -->
    <infoShift
        v-if="modalInfoShift"
        @close="modalInfoShift = false"
        :obj="infoObj"
    />

    <!-- Модальне вікно закриття перерви -->
    <closeBreak 
        v-if="modalICloseBreak"
        @close="gulyayPole"
        @closeBreak="closeBreak"
    />

    <!-- Відображення модального вікна для підтвердження SMS -->
    <acceptSMS 
        v-if="showAcceptSMS == true" 
        @close = "showAcceptSMS = false" 
        :obj="this.obj"
    ></acceptSMS>

    <!-- Модалка зі співробітниками -->
    <workersNew
        v-if="this.workersModal == true"
        @close="this.workersModal = false"
        @closeShift="closeShiftForWorker"
    ></workersNew>

</template>

<script>
import dbPromise from '../../indexedDB.js'
import preloader from "@/components/preloader"; // Компонент лоадеру
import topmenu from './topmenu' // компонент верхнього меню
import searchacc from './searchacc' // компонент віджета пошуку за номером, електронною поштою, айді.
import profile from './profile' // компонент віджет профілю користувача
import createapplicBox from '@/views/application/createapplic.vue' // модальне вікно для додавання заявки
import createTaskBox from '@/views/tasks/createtask.vue' // модальне вікно для додавання завдання
import createTicketBox from '@/views/newtickets/createticket.vue' // модальне вікно для додавання тікету
import createProjectBox from '@/views/projects/new.vue' // модальне вікно для додавання проекту
import createInvoicesBox from "@/views/invoices/createinvoices.vue" // модальне вікно для додавання рахунку
import createUserBox from "@/views/users/newuser.vue" // модальне вікно для додавання нового співробітника
import createPresentBox from "@/views/presentations/add.vue" // модальне вікно для додавання нової презентації
import calculator from "@/components/calc_tariff.vue" // компонент калькулятора тарифів
import viewclientnew from '@/views/clientnew/index.vue' // компонент для перегляду картки клієнта
import breakmodal from "./shifts/break" // модальне вікно перерви
import viewTickets from '@/views/newtickets/view/index.vue' // компонент для перегляду картки тікету
import viewuser from '@/views/users/view/index.vue' // компонент для перегляду картки власного профілю
import { Users, Breaks, Clients } from "@/API.js" // // клас для взаємодії з API 
import hamburger from "./hamburger" // компонент для гамбургер-меню
import { storeS } from '@/store' // глобальне сховище даних
import i18n from "@/i18n" //
import globalSearch from './globalsearch/index' // компонент глобального пошуку
import openShift from './shifts/open' // модальне вікно для відкриття зміни
import closeShift from './shifts/close' // модальне вікно для закриття зміни
import infoShift from './shifts/infoShift' // модальне вікно для відображення інформації про зміну
import closeBreak from './shifts/closeBreak' // модальне вікно для закриття перерви
import acceptSMSVue from '@/views/application/acceptSMS.vue'; // компонент для підтвердження SMS
import anonimMessage from "./anonimMessage" // модальне вікно для анонімних повідомлень
import { webviewCheck } from '@/usabilityScripts/webViewCheck.js' // перевірка з його типу пристрою перегляають інсайд
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логів в телеграм
import notifyMsg from './noticeNew/notifyMsg' // Тестовий компонент на повідомлення, у правому кутку екрана
import workersNew from '@/components/modal/peek-worker';

let apiServe = new Users();
let apiBreaks = new Breaks();
let apiClients = new Clients();

export default {
    props:['fullSize'],
    data(){
        return {
            showAcceptSMS: false, // Стан видимості модального вікна для підтвердження SMS
            tour: null, // Змінна для тура по функціоналу
            from:"", // Початкова точка
            modalRequestBox: false, // Стан видимості модального вікна додавання заявки
            modalTaskBox: false, // Стан видимості модального вікна додавання завдання
            modalTicketBox: false, // Стан видимості модального вікна додавання тікету
            modalProjectBox: false, // Стан видимості модального вікна додавання проекту
            modalInvoicesBox: false, // Стан видимості модального вікна додавання рахунку
            modalUserBox: false, // Стан видимості модального вікна для додавання нового користувача
            modalPresentBox: false, // Стан видимості модального вікна для додавання презентації
            modalcalculator: false, // Стан видимості модального вікна калькулятора
            testshowModalclient: false, // Стан видимості модального вікна для заявки
            number: "", // Номер
            user_name: "", // Ім'я користувача
            obj: "",
            showmodalanonMessage: false, // Стан видимості модального вікна для анонімних повідомлень
            showmodalbreak: "", // Стан видимості модального вікна для перерви
            showProfile: false, // Стан видимості модального вікна для перегляду власного профілю
            objcard: "", // Об'єкт карти користувача
            signal: false, // Стан сигналу
            lan: i18n.locale, // Обрана мова
            showGS: false, // Стан видимості глобального пошуку
            showTicket: false,  // Стан видимості модального вікна картки тікета
            modalOpenShift: false, // Стан видимості модального вікна для відкриття зміни
            modalCloseShift: false, // Стан видимості модального вікна для закриття зміни
            modalInfoShift: false, // Стан видимості модального вікна для відображення інформації про зміну
            modalICloseBreak: false, // Стан видимості модального вікна для закриття перерви
            infoObj: "", // Об'єкт інформації про зміну
            modals: [], // Масив модальних вікон
            show_in_preloader: false, // Стан видимості прелоадера
            workersModal: false
        }
    },
    components: {
        workersNew,
        anonimMessage,
        acceptSMSVue,
        topmenu,
        viewTickets,
        searchacc,
        profile,
        createapplicBox,
        createTaskBox,
        createTicketBox,
        createProjectBox,
        createInvoicesBox,
        calculator,
        viewclientnew,
        breakmodal,
        viewuser,
        hamburger,
        globalSearch,
        createUserBox,
        createPresentBox,
        openShift,
        closeShift,
        infoShift,
        closeBreak,
        preloader,
        notifyMsg
    },
    created(){
        // Ініціалізуємо підключення до бази даних (DB)
        dbPromise.init()

        // Перевіряємо умови для відображення модальних вікон відкриття та закриття зміни
        if(this.user.statusshift == 'close' && this.user.workerOpenShift != '00:00' && this.user.workerCloseShift != '00:00'){
            this.modalOpenShift = true;
        }

        // Перевіряємо умову для очищення локального сховища
        if(this.checks['009'] != true && this.user.workerJob != "managercallcenter"){
            localStorage.clear();
        }

        // Викликаємо компонент глобального пошуку
        this.eventKey.off('showGS')
        this.eventKey.on('showGS', () => {
            this.showGS = true
        })
        
        // Перевіряє умову для відображення модального вікна закриття перерви
        if(this.break.statusBreak == true){
            this.modalICloseBreak = true;
        }
    },
    methods: {
        closeShiftForWorker(item) {
            apiServe.closeShiftForWorker(item.workerId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('done'))
                    this.workersModal = false
                }
            })
        },
        closeShiftWorker() {
            this.workersModal = true
        },
        androidFixMono () {
            // метод, який вносить зміни до DOM для виправлення відображення з камерою на Android та IOS
            if(webviewCheck() == 'android_webview' || webviewCheck() == 'ios_webview') {
                document.querySelector('.navbar-header').classList.add('fixWindow')
            } else {
                document.querySelector('.navbar-header').classList.remove('fixWindow')
            }
        },
        sendSMS(e){
            // метод для відправлення SMS з додатковими діями, такими як встановлення змінної obj і відображення модального вікна підтвердження SMS
            this.obj = e 
            this.showAcceptSMS = true
        },
        gulyayPole() {
            // метод для перевірки на право закриття вікна відкриття зміни, щоб дозволити користувачу закрити його без редіректа.
            
            // Перевірка на крутого челіка, який захотів буде краще інших
            // дозволяємо юзеру просто закрити вікно, без редіректа в кабінет, всі інші редірект
            
            if(this.user.userid == '51' || this.user.userid == '37'){
                this.modalOpenShift = false;
            } else {
                window.location.href = 'https://cabinet.skyservice.online/'
            }
        },
        open(e, type) {
            // метод для відкриття різних типів елементів, таких як заявки, презентації, завдання, тікети, проекти та рахунки

            switch(type) {
                case 'application':
                    this.modalRequestBox = false
                    this.$emit('openApplication', e)
                break;
                case 'presentation':
                    this.modalPresentBox = false
                    this.$emit('openPresentation', e)
                break;
                case 'task':
                    this.modalTaskBox = false
                    this.$emit('openTask', e)
                break;
                case 'ticket':
                    this.modalTicketBox = false
                    this.$emit('openTicket', e)
                break;
                case 'project':
                    this.$emit('openProject', e)
                    this.modalProjectBox = false    
                break;
                case 'invoice':
                    this.modalInvoicesBox = false
                    this.$emit('openInvoice', e)
                break;
            }
        },
        closeModal() {
            // метод для закриття останнього модального вікна у масиві modals.
            this.modals.pop()
        },
        closeTicket() {
            // метод для закриття останнього модального вікна тікета у масиві modals.
            this.modals.tickets.pop()   
            
        },
        call(e) {
            // метод для виклику зовнішньої функції в родительському компоненті.
            this.$emit('call', e)
        },
        // createTour(){
        // не видаляти (потрібно для доопрацювання візуального турінгу)
        //     this.tour = this.$shepherd({
        //         useModalOverlay: true
        //     });

        //     this.tour.addStep({
        //         attachTo: { element: 'page-topbar', on: 'top' },
        //         text: 'Test',
        //         buttons: [
        //             {
        //             action() {
        //                 return this.back();
        //             },
        //             classes: 'shepherd-button-secondary',
        //             text: 'Back'
        //             },
        //             {
        //             action() {
        //                 return this.next();
        //             },
        //             text: 'Next'
        //             }
        //         ],
        //     });
        //     this.tour.addStep({
        //         attachTo: { element: this.$refs.el, on: 'top' },
        //         text: 'Test',
        //         buttons: [
        //             {
        //             action() {
        //                 return this.back();
        //             },
        //             classes: 'shepherd-button-secondary',
        //             text: 'Back'
        //             },
        //             {
        //             action() {
        //                 return this.next();
        //             },
        //             text: 'Next'
        //             }
        //         ],
        //     });
        // },
        openrequestbox: function(){
            // відкриває модальне вікно для додавання заявки
            this.modalRequestBox = true
        },
        opentaskbox: function(){
            // відкриває модальне вікно для додавання завдання
            this.modalTaskBox = true
        },
        openticketbox: function(){
            // відкриває модальне вікно для додавання тікету
            this.modalTicketBox = true
        },
        openinvoicesbox: function(){
            // відкриває модальне вікно для додавання рахунку
            this.modalInvoicesBox = true
        },
        openprojectbox: function(){
            // відкриває модальне вікно для додавання проекту
            this.modalProjectBox = true
        },
        openuserbox: function(){
            // відкриває модальне вікно для додавання нового користувача
            this.modalUserBox = true
        },
        openpresentbox: function(){
            // відкриває модальне вікно для додавання презентації
            this.modalPresentBox = true
        },
        opencalculator: function(){
            // відкриває модальне вікно калькулятора
            this.modalcalculator = true
        },
        openTicket(e) {
            /**
                * Відкриває карточку тікету.
                * @param {object} e - дані тікету
            */

            this.showTicket = true

            if(!this.modals.tickets) {
                this.modals.tickets = []
            }

            this.modal={
                name: 'modal-' + this.modals.tickets.length,
                id: this.modals.tickets.length,
                obj: e,
            }

            this.showTicket = true

            this.modals.tickets.push(this.modal) // додавання модалки в архів модалок

            this.currentTab = this.modal.name;
        },
        openclient: function(num, name, obj){
            /**
                * Відкриває карточку клієнта
                * @param {number} num - номер
                * @param {string} name - ім'я
                * @param {object} obj - картка клієнта
            */
           
            this.testshowModalclient = false

            this.number = num;
            this.user_name = name;
            this.form = obj

            // работа с модалками

            this.modal={
                name: 'modal-' + Number(this.modals.length),
                id: this.modals.length,
                obj: this.form,
            }

            this.currentTab = this.modal.name;


            this.modals.push(this.modal) // додавання модалки в архів модалок
            
            // работа с модалками

            this.testshowModalclient = true
        },
        openbreakbox: function(){
            // відкриває модальне вікно для перерви
            this.showmodalbreak = true
        },
        openAnonimMessageModal: function(){
            // відкриває модальне вікно для анонімного повідомлення
            this.showmodalanonMessage = true
        },
        closeBreak(){
            // Закриття перерви. Викликає API для закриття перерви та виводить інформацію про тривалість перерви
            
            apiBreaks.closeBreak(this.break.breakId).then(result =>{
                if(result.status == 'done'){
                    storeS.break.statusBreak = false
                    this.modalICloseBreak = false
                    this.$toast.success((this.$t("Duration") + " " + result.data.duration.split(', ')[0].split(' ')[0]    + " " + this.$t('shours') + " " + result.data.duration.split(', ')[1].split(' ')[0] + " " + this.$t('min') + " " + result.data.duration.split(', ')[2].split(' ')[0] + " " + this.$t('sec')));
                } else {
                    this.$toast.error(this.$t('error'));
                    this.gulyayPole()
                }
            })
        },
        myprofile: function(e){
            // Відображення профілю користувача. Викликає API для отримання даних про користувача та відображає їх в профільному вікні

            // this.show_in_preloader = true;
            apiServe.getUser(e.userid).then(result => {
                if(result.status == 'done'){
                    this.objcard = result.data;
                    this.showProfile = true;
                    // this.show_in_preloader = false;
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        openphone(){
            // Відкриття телефонного компоненту. Запускає подію для відкриття телефону
            this.$emit('openphone')
        },
        checkTimeCloseShift(){
            // Перевірка часу для закриття зміни. Порівнює поточний час із заданим часом закриття та викликає функцію закриття

            var date = new Date();
            var dateStr =
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2)
            
            this.modalCloseShift = dateStr >= this.user.workerCloseShift ? this.closeShift() : true;
        },
        openShift(lateness) {
            // Відкриття нової зміни. Викликає API для відкриття зміни та обробляє можливий пізний початок.

            apiServe.getOpenShift().then(result => {
                if(result.status == 'done'){
                    storeS.userbase.statusshift = 'open'
                    storeS.userbase.shiftId = result.data.shift.shiftId

                    this.$toast.success(this.$t('ShiftOpenedSuccess'));
                    this.modalOpenShift = false

                    if(lateness.status == true){
                        var formComment = {
                            lateComment: lateness.lateComment
                        }
                        apiServe.addlateForShift(result.data.shift.shiftId, formComment).then(res => {
                            if(res.status == 'done'){
                                this.$toast.success(this.$t('ReasonBeingLate') + " " + lateness.lateComment);
                                this.modalOpenShift = false
                            }
                        })
                    }
                } else if (result.status == 'error'){
                
                    if(result.error[0] == 'access denied by ip'){
                        this.$toast.error(this.$t('ErrorOpenShiftIP'));
                    } else {
                        this.$toast.error(this.$t('shiftNowOpen'));

                        var errorText = 'Помилка відкриття зміни'; 
                        var statusRequest = result.status;
                        var api = 'getOpenShift';
                        var fileRequest = 'src/components/navbar/index.vue';
                        var params = '';
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                }
            })
        },
        closeShift(earlycomment) {
            // Закриття поточної зміни. Викликає API для закриття зміни та додає коментар у разі заздалегідь закриття

            if(earlycomment != undefined && (earlycomment.status == true)){
                var formComment = {
                    comment: earlycomment.comment
                }
                apiServe.addearlyCloseShift(this.user.shiftId, formComment).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('Cause') + earlycomment.comment);
                        storeS.userbase.statusshift = 'close'
                        
                        this.infoObj = result.data;
                        this.modalInfoShift = true
                        this.$toast.success(this.$t('ShiftClosedSuccess'));

                    } else if (result.status == 'error'){
                        if(result.error[0] == 'access denied by ip'){
                            this.$toast.error(this.$t('ErrorCloseShiftIP'));
                        } else if(result.error[0] == 'Please enter a reason for closing shift early!') {
                            this.$toast.error(this.$t('enterEarlyClosingShift'));
                        } else {
                            this.$toast.error(this.$t('shiftNowClose'));

                            var tempObj = {
                                "shiftId": this.user.shiftId,
                                "params": formComment
                            }

                            var errorText = 'Помилка закриття зміни заздалегідь'; 
                            var statusRequest = result.status;
                            var api = 'addearlyCloseShift';
                            var fileRequest = 'src/components/navbar/index.vue';
                            var params = tempObj;
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }
                    }
                })
            } else {
                apiServe.getCloseShift(this.user.shiftId).then(result => {
                    if(result.status == 'done'){

                        storeS.userbase.statusshift = 'close';
                        this.infoObj = result.data;
                        this.modalInfoShift = true;
                        this.$toast.success(this.$t('ShiftClosedSuccess'));
                    } else if (result.status == 'error'){
                        if(result.error[0] == 'access denied by ip'){
                            this.$toast.error(this.$t('ErrorCloseShiftIP'));
                        } else {
                            this.$toast.error(this.$t('shiftNowClose'));

                            var errorText = 'Помилка закриття зміни'; 
                            var statusRequest = result.status;
                            var api = 'getCloseShift';
                            var fileRequest = 'src/components/navbar/index.vue';
                            var params = this.user.shiftId;
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }
                    }
                })
            }
        },
        checkOpenShift() {
            // Перевірка відкриття зміни. Викликає API для перевірки статусу відкриття зміни
            apiServe.getCheckOpenShift().then(result => {
                if(result.status == 'done'){
                    // this.$toast.success(this.$t('ShiftClosedSuccess'));
                }
            })
        }
    },
    mounted() {
        // this.tour.start();
        this.androidFixMono();
    },
    computed: {
        checks() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.checks
        },
        lang() {
            /*
                Повертає дані налаштувань мови інтерфейсу користувача з глобального стору.
            */
            return storeS.lang
        },
        user() {
            /*
                Повертає дані користувача з глобального стору.
            */
            return storeS.userbase
        },
        break() {
            /*
                Повертає дані перерви користувача з глобального стору.
            */
            return storeS.break
        },
    }
}
</script>

<style>
.click {
    cursor: pointer;
}

header{
    padding-top: env(safe-area-inset-top) !important;
    /* height: calc(env(safe-area-inset-top) + 60px) !important; */
}

@media (orientation: portrait) {
    .fixWindow {
        padding-top: 50px !important;
        height: 570px;
    }
}
</style>
