<template>
    <!-- 
        Компонент: Пошук та відображення останніх номерів та тикетів 
    -->

    <!-- Секція для великих екранів -->
    <div class="app-search d-none d-md-block" style="margin-left: 10px;margin-top:3px">

        <!-- Область пошуку та відображення результатів -->
        <div class="position-relative">
            <div class="input-group">

                <!-- Поле введення номеру клієнта -->
                <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('phone_num')"
                    v-model="this.number"
                    v-on:keyup.enter="searchType(this.number)"
                    autocomplete="off"
                    id="search-options"
                    @input="removeSpaces"
                />
                <span class="mdi mdi-magnify search-widget-icon"></span>
                
                <span
                    class="
                        mdi mdi-close-circle
                        search-widget-icon search-widget-icon-close
                        d-none"
                    id="search-close-options"
                ></span>

                <!-- Кнопка для відображення/приховування останніх номерів -->
                <button 
                    v-if="filteredItems.length != 0 || filteredTickets.length != 0"
                    @click="shownumbers = !shownumbers" 
                    type="button"
                    :class="`btn btn-${shownumbers == true ? 'primary' : 'info'} waves-effect waves-light`"
                >
                    <i :class="`${shownumbers == true ? 'bx bxs-hide' : 'bx bxs-show'}`"></i>
                </button>

            </div>
        </div>

        <!-- Випадаюче меню для відображення останніх номерів та тикетів -->
        <div :class="`dropdown-menu dropdown-menu-lg ${shownumbers == true ? 'show': ''}`">

            <!-- Секція для останніх номерів -->
            <div v-if="filteredItems.length > 0 && this.checks['007'] == true" class="scroll_s">
                <div class="dropdown-header" >
                    <h5 class="text-overflow text-muted mb-0 text-uppercase"><b>{{ $t('recent_numbers') }}</b></h5>
                </div>

                <!-- Відображення інформації про останні номери -->
                <div v-for="(item, i) in filteredItems" :key="item" class="card-header align-items-center d-flex border-bottom-dashed" style="padding:0.5rem 1rem!important">
                    <h4 @click="searchType(item.number)" v-if="length <= 15" class="card-title mb-0 flex-grow-1 copy history_of_numbers">{{ item.number }}<br /><span class="tt_title_s">{{ item.user_name }}</span></h4>
                    <h4 @click="searchType(item.number)" v-else class="card-title mb-0 flex-grow-1 copy history_of_numbers">{{ item.number.slice(0,15) }}...<br /><span class="tt_title_s">{{ item.user_name }}</span></h4>
                    
                    <!-- Кнопка для видалення номера -->
                    <div class="flex-shrink-0" >
                        <button 
                            @click="removeNumber(i, item)" 
                            type="button" 
                            class="btn btn-danger btn-icon waves-effect waves-light"
                        >
                            <i class="ri-delete-bin-5-line"></i>
                        </button>
                    </div>
                </div>

                <!-- Кнопка для очищення списку останніх номерів -->
                <div class="text-center pt-3 pb-1" >
                    <a @click="clearNumbers()" class="btn btn-warning btn-sm fs-14">{{ $t('Clear')}} </a>
                </div>
            </div>

            <!-- Секція для останніх тикетів -->
            <div v-if="filteredTickets.length > 0 && (this.checks['007'] == true || this.checks['006'] == true)" class="scroll_s">
                <div class="dropdown-header" >
                    <h5 class="text-overflow text-muted mb-0 text-uppercase"><b>{{ $t('Recent_tickets') }}</b></h5>
                </div>

                <!-- Відображення інформації про останні тикети -->
                <div v-for="item in filteredTickets" :key="item" class="card-header align-items-center d-flex border-bottom-dashed" style="padding:0.5rem 1rem!important">
                    <h4 @click="openticket(item.id)" class="card-title mb-0 flex-grow-1 copy">
                        #{{item.id}}<br />
                        <span class="tt_title_s">{{ item.name }}</span>
                    </h4>
                    
                     <!-- Кнопка для видалення тикета -->
                    <div class="flex-shrink-0" >
                        <button 
                            @click="removeTicket(i, item.id)" 
                            type="button" 
                            class="btn btn-danger btn-icon waves-effect waves-light"
                        >
                            <i class="ri-delete-bin-5-line"></i>
                        </button>
                    </div>
                </div>

                <!-- Кнопка для очищення списку останніх тикетів -->
                <div class="text-center pt-3 pb-1" >
                    <a @click="clearTickets()" class="btn btn-warning btn-sm fs-14">{{ $t('Clear')}} </a>
                </div>
            </div>
        </div>
    </div>

    <!-- Секція для малих екранів -->
    <div class="dropdown d-md-none topbar-head-dropdown header-item" style="margin-left: 13px;margin-top: 2px;">
        <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            id="page-header-search-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            <i class="bx bx-search fs-22"></i>
        </button>

        <!-- Відображення поля пошуку для малих екранів -->
        <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-search-dropdown"
        >
            <div class="p-3">
              <div class="form-group m-0">
                  <div class="input-group">
                    <!-- Поле введення номеру клієнта для малих екранів -->
                    <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('phone_num')"
                        v-model="this.number"
                        v-on:keyup.enter="searchType(this.number)"
                        aria-label="Recipient's username"
                    />

                    <!-- Кнопка пошуку для малих екранів -->
                    <button class="btn btn-primary" type="submit" @click="searchType(this.number)">
                        <i class="mdi mdi-magnify"></i>
                    </button>

                    <!-- Кнопка для відображення/приховування останніх номерів для малих екранів -->
                    <button 
                      v-if="numbers.length > 0"
                      @click="shownumbersMob = !shownumbersMob" 
                      type="button"
                      :class="`btn btn-${shownumbersMob == true ? 'primary' : 'info'} waves-effect waves-light`"
                    >
                        <i :class="`${shownumbersMob == true ? 'bx bxs-hide' : 'bx bxs-show'}`"></i>
                    </button>

                  </div>
              </div>
            </div>

            <!-- Відображення останніх номерів та тикетів для малих екранів -->
            <div v-if="shownumbersMob == true">
                <!-- Секція для останніх номерів -->
                <div v-if="filteredItems.length > 0 && this.checks['007'] == true" class="scroll_s">
                    <div class="dropdown-header" >
                        <h5 class="text-overflow text-muted mb-0 text-uppercase"><b>{{ $t('recent_numbers') }}</b></h5>
                    </div>

                    <!-- Відображення інформації про останні номери для малих екранів -->
                    <div v-for="(item, i) in filteredItems" :key="item" class="card-header align-items-center d-flex border-bottom-dashed" style="padding:0.5rem 1rem!important">
                        <h4 @click="searchType(item.number)" v-if="length <= 15" class="card-title mb-0 flex-grow-1 copy history_of_numbers">{{ item.number }}<br /><span class="tt_title_s">{{ item.title_tt }} | {{ item.tariff_name }}</span></h4>
                        <h4 @click="searchType(item.number)" v-else class="card-title mb-0 flex-grow-1 copy history_of_numbers">{{ item.number.slice(0,15) }}...<br /><span class="tt_title_s">{{ item.title_tt }} | {{ item.tariff_name }}</span></h4>
                        
                        <!-- Кнопка для видалення номера для малих екранів -->
                        <div class="flex-shrink-0" >
                            <button @click="removeNumber(i, item)" type="button" class="btn btn-danger btn-icon waves-effect waves-light"><i class="ri-delete-bin-5-line"></i></button>
                        </div>
                    </div>

                    <!-- Кнопка для очищення списку останніх номерів для малих екранів -->
                    <div class="text-center pt-3 pb-1" >
                        <a @click="clearNumbers()" class="btn btn-warning btn-sm fs-14">{{ $t('Clear')}} </a>
                    </div>
                </div>

                <!-- Секція для останніх тикетів -->
                <div v-if="filteredTickets.length > 0 && this.checks['007'] == true" class="scroll_s">
                    <div class="dropdown-header" >
                        <h5 class="text-overflow text-muted mb-0 text-uppercase"><b>{{ $t('Recent_tickets') }}</b></h5>
                    </div>
                    
                    <!-- Відображення інформації про останні тикети для малих екранів -->
                    <div v-for="item in filteredTickets" :key="item" class="card-header align-items-center d-flex border-bottom-dashed" style="padding:0.5rem 1rem!important">
                        <h4 @click="openticket(item)" class="card-title mb-0 flex-grow-1 copy">#{{item}}</h4>
                        
                        <!-- Кнопка для видалення тикета для малих екранів -->
                        <div class="flex-shrink-0" >
                            <button @click="removeTicket(i, item)" type="button" class="btn btn-danger btn-icon waves-effect waves-light"><i class="ri-delete-bin-5-line"></i></button>
                        </div>
                    </div>

                    <!-- Кнопка для очищення списку останніх тикетів для малих екранів -->
                    <div class="text-center pt-3 pb-1" >
                        <a @click="clearTickets()" class="btn btn-warning btn-sm fs-14">{{ $t('Clear')}} </a>
                    </div>
                </div>
            </div> 
        </div>
    </div>    

    <!-- Модальне вікно для відображення картки клієнта -->
    <viewbox 
        v-if="showModalUser == true" 
        @close="showModalUser = false;" 
        @call = "call"
        :objcard="this.objcard" 
        :companyId="this.searchComp.companyId"
    ></viewbox>

    <!-- Модальне вікно для відображення картки незареєстрованого абонента -->
    <viewUnregBox 
        v-if="showModalUnUser == true" 
        @close="showModalUnUser = false" 
        @call = "call"
        :objCard="this.objcard" 
    ></viewUnregBox>

    <!-- Модальне вікно для відображення картки тікета -->
    <viewticketbox 
        v-if="showModalTicket == true" 
        @close="showModalTicket = false" 
        :obj="this.objCard" 
    ></viewticketbox>

    <!-- Модальне вікно для відображення повідомлення про пошукову компанію -->
    <dialogModal v-if="showCompClient" @close="showCompClient = false;  this.searchComp.companyId = ''" >
        <template v-slot:title>{{ $t('CompanyFound') }}</template>
        <template v-slot:body>
            <div class="mt-4 text-center">
                <h3><b>{{ $t('warningDescSearchByCompid') }}</b></h3>
                <p class="text-muted fs-15 mb-4">
                    {{ $t('CompanyFound') }} - {{ searchComp.companyTitle }}, {{ $t('byNumber') }} - {{ searchComp.number }}. 
                    <br />{{ $t('actionOpenCardbtn') }}
                </p>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <!-- Закрити -->
                <button class="btn btn-danger" @click="showCompClient = false">{{ $t('close') }}</button>
                <!-- Відкрити картку клієнта з компанією -->
                <button class="btn btn-success" @click="searchType(searchComp.number)">{{ $t('open') }}</button>
            </div>
        </template>
    </dialogModal>

</template>

<script>
import viewbox from '@/views/accounts/allAccounts/viewnew/index.vue'
import viewUnregBox from '@/views/accounts/unregistered/view/index'
import viewticketbox from '@/views/newtickets/view/index.vue'
import dialogModal from '@/components/modal/dialog'
import { SuperAdmin, Tickets } from "@/API.js"
import { storeS } from '@/store'

let apiServe = new SuperAdmin();
let apiTickets = new Tickets();

export default {
    data(){
        return {
            objcard: {},
            shownumbers: false, // стан відображення останніх номерів
            number: "",
            numbers: [],
            tickets: [],
            showModalUser: false,
            showModalUnUser: false,
            showModalTicket: false,
            showCompClient: false,
            searchComp: {
                companyTitle: '',
                number: '',
                companyId: '',
            }
        }
    },
    components: { 
        viewbox,
        viewUnregBox,
        viewticketbox,
        dialogModal
    },
    create(){
    },
    methods: {
        getClient(num, method) {
            // Метод отримання клієнта за номером та методом пошуку

            // Додаємо можливість пошуку, якщо номер вказаний без 38
            var customNumber = num.toString();
            
            apiServe.getCardAcc(method, customNumber).then(result => {
                if(result.status == 'done'){
                    if(result.data.accountId != false && result.data.accountId != undefined){
                        this.objcard = result.data
                        this.$toast.success(this.$t('Account') + ` - ` + result.data.personalData.phone);
                        this.showModalUser = true;

                        this.number = ''; //очищаємо поле number

                        if(this.checks['009'] == true){ 
                            // додавання номера в стор
                            let foo = [];
                            for (let i = 0; i<this.numbers.length; i++) {
                                foo.push((Object.values(this.numbers[i]))[0]);
                                }
                            if (foo.indexOf(customNumber) === -1 && customNumber.length >= 7) {
                                this.numbers.push(
                                    {
                                        number: customNumber, 
                                        user_name: this.objcard.personalData ? this.objcard.personalData.user_name : '-'
                                    }
                                );
                                this.saveNumbers();
                            } 
                        }
                    } else {
                        this.objcard = result.data;
                        this.showModalUnUser = true
                    }
                } 
            })
        },
        saveNumbers() {
            // збереження номерів в локалстор
            let parsed = JSON.stringify(this.numbers);
        },
        removeNumber(x, num) {
            // видалити номер з локалстор
            this.numbers.splice(x,1);
            this.$toast.error(this.$t('Removed') + ` - ` + "+" +(Object.values(num))[0]);
            this.saveNumbers();
            if(this.numbers.length == 0){
                this.shownumbers = false
            }
        },
        clearNumbers() {
            // видалити всі номера з локалстор
            this.numbers = [];
            this.saveNumbers();
            if(this.numbers.length == 0){
                this.shownumbers = false
            }
        },
        saveTickets() {
            // збереження тикетів в локалстор
            let parsed = JSON.stringify(this.tickets);
            localStorage.setItem('tickets', parsed);
        },
        removeTicket(x, id) {
            // видалити тикет з локалстор
            this.tickets.splice(x,1);
            this.$toast.error(this.$t('Removed') + ` - # ` + id);
            this.saveTickets();
            if(this.tickets.length == 0){
                this.shownumbers = false
            }
        },
        clearTickets() {
            // видалити всі тикети з локалстор
            this.tickets = [];
            this.saveTickets();
            if(this.tickets.length == 0){
                this.shownumbers = false
            }
        },
        call(e) {
            // Метод виклику події call
            this.$emit('call', e)
        },
        searchType(num){
            this.showCompClient = false
            // Метод пошуку за типом

            // додаємо можливість пошуку, якщо номер вказаний без 38
            var customNumber = num.toString();
            var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if(customNumber.length > 6 && customNumber.length < 12) {
                var numcode = ["039", "067", "068", "096", "097", "098", "050", "066", "095", "099", "063", "073", "093", "091", "092", "089", "094"]
                for(var item in numcode) {
                    if(customNumber.substr(0, 3) == numcode[item] ) {
                        customNumber = "38" + this.number
                    }
                }
            } 

            // для швидкого виклику картки клієнта
            if(this.checks['007'] == true){
                if(emailRegex.test(customNumber)){
                    this.getClient(customNumber, 'email')
                    return
                }
                if(customNumber == 'мой' || customNumber == 'мій'){
                    this.methodSearch(this.user.phone);
                    this.number = "";
                } else if(customNumber == 'осама' || customNumber == 'Осама'){
                    this.methodSearch('380983700768');
                    this.number = "";
                } else if(customNumber == 'витушка' || customNumber == 'Витушка'){
                    // this.getClient('380983700768');
                    this.methodSearch('380676603122');
                    this.number = "";
                } else if(customNumber == 'сушизум' || customNumber == 'Сушизум'){
                    this.methodSearch('380965740305');
                    this.number = "";
                } else if(customNumber == 'флоріст' || customNumber == 'флорист' || customNumber == 'квіточник' || customNumber == 'цветочник'){
                    this.methodSearch('380635269949');
                    this.number = "";
                } else if(customNumber.length > 6 && customNumber.length < 20){
                    this.methodSearch(customNumber);
                    this.number = "";
                } else if(customNumber.length > 20){
                    var compid = customNumber.replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, "$1-$2-$3-$4-$5");

                    apiServe.getListCompanyBycompanyId(compid).then(result => {
                        if(result.status == 'done' && result.data.show > 0){
                            this.searchComp.companyId = result.data.items[0].companyId
                            this.searchComp.companyTitle = result.data.items[0].companyName
                            this.searchComp.number = result.data.items[0].ownerPhone
                            this.showCompClient = true
                        } else if(result.data.show == 0) {
                            this.$toast.error(this.$t('AccountnotFound'))
                        }
                    })

                    this.number = ""
                } else if(customNumber.length > 0 && customNumber.length < 5){
                    this.openticket(customNumber);
                    this.number = "";
                } else { 
                    this.$toast.error(this.$t('Null'))
                }
            } else {
                this.$toast.error(this.$t('dontSearch'))
            }
        },
        openCompany(e){
             // Метод відкриття компанії за ідентифікатором

            apiServe.getCompClient(e).then(result => {
                if(result.status == 'done'){
                    console.log(result);
                    this.objcard = result.data
                    this.$toast.success(this.$t('Account') + ` - ` + result.data.companyId);
                    this.showModalUser = true;
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        methodSearch(e){
            // Метод пошуку за різними методами

            try{
                var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if(emailRegex.test(e)){
                    this.getClient(e, 'email')
                }
            }catch(err){
                this.$toast.error(this.$t('error'))
            }

            try{
                const search = e.replace(/\D/g, "");
                if(search.length < 30){
                    this.getClient(search, 'phone')
                }
            }catch(err){
                this.$toast.error(this.$t('error'))
            }

            try{
                if(e.length > 31){
                    this.getClient(e, 'accountId')
                }
            }catch(err){
                this.$toast.error(this.$t('error'))
            }
        },
        openticket(id){
            // Відкрити картку тікета

            apiTickets.getTicket(id).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('TicketFound') + ` # ` + id);
                    this.objCard = result.data;
                    this.showModalTicket = true;

                    if(this.checks['009'] == true){
                        // додавання тікета в стор
                        if(!id) return;
                        this.tickets.push(
                            {
                                id: id,
                                name: this.objCard.ticketName ? this.objCard.ticketName : '-',
                            }
                        );
                        this.saveTickets();
                    }

                } else if(result.status == 'error') {
                    this.$toast.error(this.$t('NotFoundTicket') + ` # ` + id);
                }
            }).catch(err => {
                this.$toast.error(this.$t('NotFoundTicket') + ` # ` + id);
            })
        },
        removeSpaces(event){
            // Метод для очищення номера телефону від небажаних символів

            // Отримуємо значення з події
            let inputValue = event.target.value;
            // Видаляємо всі небажані символи (пробіли, дужки, дефіси, та інші) за допомогою регулярного виразу
            let cleanedNumber = inputValue.replace(/[\s()-]/g, "");
            // Оновлюємо значення в моделі
            this.number = cleanedNumber;
        }
    },
    mounted() {
        // if(localStorage.getItem('numbers')) {
        //     try {
        //         this.numbers = JSON.parse(localStorage.getItem('numbers'));
        //     } catch(e) {
        //         localStorage.removeItem('numbers');
        //     }
        // }

        if(localStorage.getItem('tickets')) {
            try {
                this.tickets = JSON.parse(localStorage.getItem('tickets'));
            } catch(e) {
                localStorage.removeItem('tickets');
            }
        }
    },
    computed: {
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        checks(){
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        filteredItems: function() {
            // Пошук по номерам клієнтів
            return this.numbers
                .filter(item => {
                    return this.number == '' || item.number.toString().toLowerCase().indexOf(this.number.toLowerCase()) !== -1;
                })
        },
        filteredTickets: function() {
            // Пошук по номерам тікетів
            return this.tickets
                // По полю поиска
                .filter(item => {
                    return this.number == '' || item.toString().toLowerCase().indexOf(this.number.toLowerCase()) !== -1;
                })
        },
    },
}
</script>

<style scoped>
@media screen and (min-width: 980px) {
    .d-md-block {
        display: block !important;
    }
    .d-md-none {
        display: none !important;
    }
}

@media screen and (max-width: 979px) {
    .d-md-block {
        display: none !important;
    }
    .d-md-none {
        display: block !important;
        margin-top: 20px !important;
    }
}
@media screen and (min-width: 767px) {
    .navbar-header .topbar-head-dropdown .dropdown-menu.show {
        left: 20% !important
    }
}
.scroll_s {
overflow: auto;
display: block;
max-height: 400px;
}
.scroll_s::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.scroll_s::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.scroll_s::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}

.tt_title_s {
font-size: 0.6rem;
color: #878a99;
}
</style>