<template>
    <div class="card">
        <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">{{$t('tasks')}}</h4>
            <div class="flex-shrink-0">
                <div class="dropdown card-header-dropdown">
                    <a class="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <span class="text-muted"><i class="ri-settings-4-line align-middle me-1 fs-15"></i>{{$t('action')}}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                        <button class="dropdown-item" @click="showSearch = !showSearch">{{$t('searc_inp')}}</button>
                        <button class="dropdown-item" @click="allTasks">{{ $t('all_tasks') }}</button>
                        <button class="dropdown-item" @click="markedTasks">{{ $t('markedTasks') }}</button>
                        <button class="dropdown-item" @click="unmarkedTasks">{{ $t('unmarkedTasks') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body p-0">
            <div class="align-items-center p-3 justify-content-between d-flex">
                <div class="flex-shrink-0">
                    <div class="text-muted" v-if="form.tasks != null">
                        {{$t('Remaining')}} <span class="fw-semibold">{{ this.form.tasks.filter(item => item.checked == 0).length }}</span> 
                        {{$t('ofRemaining')}} <span class="fw-semibold">{{ form.tasks.length}}</span> 
                    </div>
                </div>
                <button 
                    v-if="this.form.status != 'completed'"
                    @click="showtask == true ? this.edittask() : this.showTask()"
                    type="button" 
                    class="btn btn-sm btn-success">
                    <i class="ri-add-line align-middle me-1"></i> {{$t('Edit')}}
                </button>
            </div>
            <div class="p-3" v-if="showSearch">
                <input class="form-control" v-model="search" />
            </div>
            <div v-if="showtask" class="card-body">
                <h6 class="fw-semibold text-uppercase mb-3">{{ $t('addNewTasks') }}</h6> 
                <div class="flex-grow-1 ms-2 mb-2" v-if="objForm.tasks.length == 0">
                    <div class="input-group">
                        <input type="text" v-model="item.taskName" class="form-control" :placeholder="$t('EntertheTask')">
                        <select v-model="item.team"  class="form-select" aria-label="Default select example">
                            <option value="0">{{ $t('ChooseRunningCommand') }}</option>
                            <option value="frontEnd">FrontEnd</option>
                            <option value="backEnd">BackEnd</option>
                            <option value="developer">Developer</option>
                        </select>
                        <button @click="add(item)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                    </div>
                </div>
                <div v-else>
                    <ul class="list-unstyled vstack gap-3 mb-0" >
                        <li v-for="(input, index) in objForm.tasks" :key="index">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-dark text-black">#{{index}}</div></div>
                                </div>
                                <div class="flex-grow-1 ms-2">
                                    <div class="input-group">
                                        <input type="text" v-model="input.taskName" v-on:keyup.enter="add(input)" class="form-control">
                                        <select v-model="input.team"  class="form-select" aria-label="Default select example">
                                            <option value="0">{{ $t('ChooseRunningCommand') }}</option>
                                            <option value="frontEnd">FrontEnd</option>
                                            <option value="backEnd">BackEnd</option>
                                            <option value="developer">Developer</option>
                                        </select>
                                        <button @click="add(input, index)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                                        <button @click="remove(input, index)" v-if="this.userid === this.form.teamlead || this.objForm.tasks[this.objForm.tasks.indexOf(input)].taskId == null" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>    
            </div>
            <div data-simplebar class="simplebar" style="max-height: 257px;overflow: auto;" v-if="this.showtask == false && this.tasks.length > 0" :style="this.bigger == true ? 'max-height: 80%px !important' : ''">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col"> </th>
                            <th scope="col">{{ this.$t('tasks') }}</th>
                            <th scope="col">{{ this.$t('team') }}</th>
                            <th scope="col">{{ this.$t('createDate') }}</th>
                            <th scope="col">{{this.$t('name')}}</th>
                            <th scope="col">{{this.$t('executionDateTime')}}</th>
                            <th scope="col">{{ this.$t('name') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in tasks" :key="index">
                            <th scope="row">
                                <input type="checkbox" :disabled="this.form.status == 'completed'" class="form-check-input ms-0" :id="item.taskName" v-model="item.checked" false-value="0" true-value="1" :checked="item.checked == 0 ? false : true" @change="change(item)" />
                            </th>
                            <td :style="`text-decoration:${item.checked == 1 ? 'line-through' : 'auto'}`">{{item.taskName}}</td>
                            <td :style="`text-decoration:${item.checked == 1 ? 'line-through' : 'auto'}`">{{item.team}}</td>
                            <td :style="`text-decoration:${item.checked == 1 ? 'line-through' : 'auto'}`">{{item.createDatetime}}</td>
                            <td :style="`text-decoration:${item.checked == 1 ? 'line-through' : 'auto'}`">{{this.nameWorker(item.createWorkerId)}}</td>
                            <td :style="`text-decoration:${item.checked == 1 ? 'line-through' : 'auto'}`">{{item.completeWorkerId != null && item.completeDatetime != null ? item.completeDatetime : this.$t('Null')}}</td>
                            <td :style="`text-decoration:${item.checked == 1 ? 'line-through' : 'auto'}`">{{item.completeWorkerId != null && item.completeDatetime != null ? this.nameWorker(item.completeWorkerId) : this.$t('Null')}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
    </div>

</template>

<script>
import { Tickets } from '@/API.js';
import { storeS } from '@/store';
import { nameWorker } from '@/usabilityScripts/getNameWorker'

let apiServe = new Tickets();

export default {
    props: ['obj', 'bigger'],
    components: {
        
    },
    data() {
        return {
            dragging: -1,
            showCopy: false,
            search: '',
            form: '',
            objForm: {
                tasks: []
            },
            tasks: [],
            item: {
                taskName: '',
                team: '0',
                checked: 0
            },
            showtask: false,
            showSearch: false
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        moveItem(from, to) {
            if (to === -1) {
                this.removeItemAt(from);
            } else {
                this.form.tasks.splice(to, 0, this.form.tasks.splice(from, 1)[0]);
            }
        },
        removeItemAt(index) {
            this.form.tasks.splice(index, 1);
        },
        getdata(){
            this.form = this.obj
            this.tasks = this.form.tasks;
            console.log(this.tasks)
            sessionStorage.setItem('tasksTicket', JSON.stringify(this.tasks))
        },
        add(e, index) {
            if(e.taskName == '') {
                this.$toast.error(this.$t("EntertheTask"))
                return
            } else if(e.team == '0'){
                this.$toast.error(this.$t("ChooseRunningCommand"))
                return
            }
            if(index == undefined) {
                this.objForm.tasks.push({
                    taskName: e.taskName,
                    team: e.team,
                    checked: 0
                })
            } else {
                if(e.taskName == '') {
                    this.$toast.error(this.$t("EntertheTask"))
                    return
                } else if(e.team == '0') {
                    this.$toast.error(this.$t("ChooseRunningCommand"))
                    return
                }
                if(this.objForm.tasks.length > 0){
                    this.objForm.tasks.splice(index+1, 0, {
                        taskName: e.taskName,
                        team: e.team,
                        checked: 0
                    });
                } else {
                    this.objForm.tasks.push({
                        taskName: e.taskName,
                        team: e.team,
                        checked: 0
                    })
                }
            }
        },
        remove(item, index) {
            console.log(item, index)
            apiServe.taskRemove(item.taskId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('success'))
                    // this.$emit('updateCard')
                } else if (result.error[0] == 'ticket not found') {
                    this.objForm.tasks.splice(index, 1)
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        showTask(){
            this.objForm.tasks = this.tasks
            this.showtask = true
        },
        allTasks(){
            this.form.tasks = this.tasks
        },
        markedTasks(){
            this.form.tasks = this.tasks.filter(item => item.checked == 1);
        },
        unmarkedTasks(){
            this.form.tasks = this.tasks.filter(item => item.checked == 0);
        },
        edittask(){
            let tasksStart = JSON.parse(sessionStorage.getItem('tasksTicket'))
            tasksStart.forEach(el => {
                this.objForm.tasks.splice(this.objForm.tasks.indexOf(this.objForm.tasks.find(k => k.taskId == el.taskId)), 1)
            })
            if(this.objForm.tasks.length != 0) {
                apiServe.addTask(this.form.ticketId, this.objForm).then(result =>{
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('alertChanged'));
                        this.showtask = false
                        // this.$emit('updateCard')
                    }
                })
            } else {
                this.showtask = false
                this.tasks = tasksStart
            }
        },
        nameWorker(item){
            return nameWorker(item)
        },
        change(e){
            let obj = {
                checked: e.checked
            }
            apiServe.checkTask(e.taskId, obj).then(result =>{
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'));
                    // this.$emit('progressPercent', result.data.progressPercent)
                    // this.$emit('updateCard')
                }
            })
        },
    },
    beforeUnmount() {
        sessionStorage.removeItem('tasksTicket')
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form.tasks)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.taskName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        user(){
            return storeS.userbase
        }
    }
}
</script>
<style scoped>
.isSelected {
        color: red
}
.simplebar::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.simplebar::-webkit-scrollbar{
	width: 5px;
	background-color: #F5F5F5;
}

.simplebar::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
</style>