<template>
    <!--
        Компонент кнопки "гамбургер" для мобільного меню:
        - Відображає кнопку "гамбургер" для виклику мобільного меню.
        - Використовує іконку "гамбургера" з трьома полосами.
        - При кліку на кнопку викликає функцію activeMobmenu для управління станом мобільного меню.
    -->

    <button
        type="button"
        class="
            btn btn-sm
            px-3
            fs-16
            header-item
            vertical-menu-btn
            topnav-hamburger
        "
        id="topnav-hamburger-icon"
        @click="activeMobmenu"
    >
        <span class="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
        </span>
    </button>
</template>

<script>
export default{
    data() {
        return {
            width: null, // Змінна для визначення ширини вікна браузера
        }
    },
    methods: {
        updateWidth() {
            /*
                Метод для оновлення значення змінної width із шириною вікна браузера.
            */
            this.width = window.innerWidth;
        },
        activeMobmenu(){
            /*
                Метод для активації мобільного меню згідно з правилами взалежності від ширини вікна браузера.
            */
            var windowSize = document.documentElement.clientWidth;

            if (document.documentElement.getAttribute("data-layout") === "vertical") {
                if (windowSize < 1025 && windowSize > 767) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
                        document.documentElement.setAttribute("data-sidebar-size", "") :
                        document.documentElement.setAttribute("data-sidebar-size", "sm");
                } else if (windowSize > 1025) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
                        document.documentElement.setAttribute("data-sidebar-size", "sm") :
                        document.documentElement.setAttribute("data-sidebar-size", "lg");
                } else if (windowSize <= 767) {
                    document.body.classList.add("vertical-sidebar-enable");
                    document.documentElement.setAttribute("data-sidebar-size", "lg");
                }
            }
        },
    },
    created(){
        // Вішаємо обробник події resize на вікно для оновлення ширини вікна.
        window.addEventListener('resize', this.updateWidth);
        this.updateWidth();
    },
    mounted() {
    },
}
</script>