<template>
    <!-- модальное окно закрытия проекта -->
    <dialogBox @close="this.$emit('close')">
        <template v-slot:title>{{$t('Decision')}}</template>
        <template v-slot:body>
            <div class="mb-3" v-if="this.checkTask == true">
                <p>{{ $t('doneAllTask') }}</p>
            </div>
            <div class="mb-3">
                <label class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" :placeholder="$t('Comment')"></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-danger fw-medium" @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            <button 
                type="button" 
                class="btn btn-success" 
                @click="toDec()"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { Projects } from '@/API'
import { storeS } from '@/store'

let apiServe = new Projects();

export default {
    props: ['id', 'checkTask'],
    components: {
        dialogBox
    },
    data(){
        return {
            form: {
                comment: "",
            },
        }
    },
    methods: {
        toDec(){
            apiServe.decideProject(this.id, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(`done`);
                    this.eventBus.emit('UpdateCard', true)
                    this.$emit('close')
                } else if(result.status == 'error'){
                    this.$toast.error(result.error[0]);
                }
            })
        }
    }
}
</script>