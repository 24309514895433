<template>
    <b-card>
        <div class="card-header mb-3">
            <h5 class="card-title mb-0"><b>{{ $t('main') }}</b></h5>
        </div>
        <b-row>
            <b-col lg="6">
                <h4 class="fs-18 fw-semibold">{{ $t('Name') }}</h4>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" v-model="form.terminalName" >
                    <button @click="copy(form.terminalName)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                </div>
            </b-col>
            <b-col lg="6">
                <h4 class="fs-18 fw-semibold">{{ $t('lang') }}</h4>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" v-model="form.language"  >
                    <button @click="copy(form.language)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                </div>
            </b-col>
        </b-row>
    </b-card>
    <b-card v-if="form.fastAuth.value == true">
        <div class="card-header mb-3">
            <h5 class="card-title mb-0"><b>{{ $t('QuickAuthorization') }}</b></h5>
        </div>
        <b-row>
            <b-col lg="6">
                <h4 class="fs-18 fw-semibold">{{ $t('login') }}</h4>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" v-model="form.fastAuth.terminalLogin" >
                    <button @click="copy(form.fastAuth.terminalLogin)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                </div>
            </b-col>
            <b-col lg="6">
                <h4 class="fs-18 fw-semibold">{{ $t('password') }}</h4>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" v-model="form.fastAuth.terminalPassword" >
                    <button @click="copy(form.fastAuth.terminalPassword)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                </div>
            </b-col>
        </b-row>
    </b-card>


    <b-card>
        <div class="card-header mb-3">
            <h5 class="card-title mb-0"><b>{{ $t('CheckoutSettings') }}</b></h5>
        </div>
        <b-row>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('GlobalParameters') }}</span><hr />
                    <b-row>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('useTable') }}</span>
                            <div class="d-flex mt-2 m-3">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="maptable" id="maptableY" :value="true" v-model="form.maptable"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="maptableY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="maptable" id="UmaptablesN" :value="false" v-model="form.maptable" >
                                    <label class="form-check-label fs-14 fw-semibold" for="maptableN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('UseListOrders') }}</span>
                            <div class="d-flex mt-2 m-3">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="UseListOrders" id="UseListOrdersY" :value="true" v-model="form.useOrderList"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="UseListOrdersY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="UseListOrders" id="UseListOrdersN" :value="false" v-model="form.useOrderList" >
                                    <label class="form-check-label fs-14 fw-semibold" for="UseListOrdersN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('UseBigunok') }}</span>
                            <div class="d-flex mt-2 m-3">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="UseBigunok" id="UseBigunokY" :value="true" v-model="form.begunok.useBegunok" >
                                    <label class="form-check-label fs-14 fw-semibold" for="UseBigunokY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="UseBigunok" id="UseBigunokN" :value="false" v-model="form.begunok.useBegunok" >
                                    <label class="form-check-label fs-14 fw-semibold" for="UseBigunokN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('SynchronizationWithServer') }}</span> <span class="fs-12 badge bg-danger">{{ $t('inDevelop') }}</span>
                            <div class="d-flex mt-2 m-3">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="serverSync" id="serverSyncY" :value="'full'" v-model="form.serverSync" >
                                    <label class="form-check-label fs-14 fw-semibold" for="serverSyncY">
                                        {{ $t('fullServerSync') }}
                                    </label>
                                </div>
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="serverSync" id="serverSyncN" :value="'off'" v-model="form.serverSync" >
                                    <label class="form-check-label fs-14 fw-semibold" for="serverSyncN">
                                        {{ $t('offServerSync') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="serverSync" id="serverSyncN" :value="'onlyPush'" v-model="form.serverSync" >
                                    <label class="form-check-label fs-14 fw-semibold" for="serverSyncN">
                                        {{ $t('onlyPushServerSync') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('PaymentOptions') }}</span><hr />
                    <b-row>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('nopayments') }}</span> <span class="fs-12 badge bg-danger">{{ $t('inDevelop') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="nopayments" id="nopaymentsY" :value="true" v-model="form.nopayments" >
                                    <label class="form-check-label fs-14 fw-semibold" for="nopaymentsY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="nopayments" id="nopaymentsN" :value="false" v-model="form.nopayments" >
                                    <label class="form-check-label fs-14 fw-semibold" for="nopaymentsN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('UseFastPayment') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="quickPay" id="quickPayY" :value="true" v-model="form.quickPay" >
                                    <label class="form-check-label fs-14 fw-semibold" for="quickPayY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="quickPay" id="quickPayN" :value="false" v-model="form.quickPay" >
                                    <label class="form-check-label fs-14 fw-semibold" for="quickPayN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('PaymentNotification') }}</span> <span class="fs-12 badge bg-danger">{{ $t('inDevelop') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="noMoneyPaymentNotification" id="noMoneyPaymentNotificationY" :value="true" v-model="form.noMoneyPaymentNotification" >
                                    <label class="form-check-label fs-14 fw-semibold" for="noMoneyPaymentNotificationY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="noMoneyPaymentNotification" id="noMoneyPaymentNotificationN" :value="false" v-model="form.noMoneyPaymentNotification" >
                                    <label class="form-check-label fs-14 fw-semibold" for="noMoneyPaymentNotificationN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('SuggestionAmountUponPayment') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="payingType" id="payingTypeY" :value="'smart'" v-model="form.payingType" >
                                    <label class="form-check-label fs-14 fw-semibold" for="payingTypeY">
                                        {{ $t('NearestAmount') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="payingType" id="payingTypeN" :value="'all-bills'" v-model="form.payingType" >
                                    <label class="form-check-label fs-14 fw-semibold" for="payingTypeN">
                                        {{ $t('AllBills') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('AfterPaymentCloseWindow') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="returnToAfterSale" id="returnToAfterSaleY" :value="'orderlist'" v-model="form.returnToAfterSale" >
                                    <label class="form-check-label fs-14 fw-semibold" for="returnToAfterSaleY">
                                        {{ $t('OrderList') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="returnToAfterSale" id="returnToAfterSaleN" :value="'terminal'" v-model="form.returnToAfterSale" >
                                    <label class="form-check-label fs-14 fw-semibold" for="returnToAfterSaleN">
                                        {{ $t('closeWindow') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('OrderParameters') }}</span><hr />
                    <b-row>
                        <b-col lg="6">
                            <span class="fs-14 fw-semibold">{{ $t('UseTimeOrders') }}</span> <span class="fs-12 badge bg-danger">{{ $t('inDevelop') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="ordersOnTimeAsk" id="ordersOnTimeAskY" :value="true" v-model="form.ordersOnTimeAsk"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="ordersOnTimeAskY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="ordersOnTimeAsk" id="ordersOnTimeAskN" :value="false" v-model="form.ordersOnTimeAsk" >
                                    <label class="form-check-label fs-14 fw-semibold" for="ordersOnTimeAskN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="6">
                            <span class="fs-14 fw-semibold">{{ $t('remindOfOrders') }}</span> <span class="fs-12 badge bg-danger">{{ $t('inDevelop') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="postponeOrderSettings" id="postponeOrderSettingsY" :value="true" v-model="form.postponeOrderSettings.value" >
                                    <label class="form-check-label fs-14 fw-semibold" for="postponeOrderSettingsY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="postponeOrderSettings" id="postponeOrderSettingsN" :value="false" v-model="form.postponeOrderSettings.value" >
                                    <label class="form-check-label fs-14 fw-semibold" for="postponeOrderSettingsN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                            <template v-if="form.postponeOrderSettings.value == true">
                                <label class="fs-12 text-muted mb-0">{{ $t('TimeStartOrdering') }}</label>
                                <input class="form-control mb-3" v-model="form.postponeOrderSettings.timeBeforeShow" :placeholder="'30' + $t('min')" />
                            </template>
                        </b-col>
                        <b-col lg="6">
                            <span class="fs-14 fw-semibold">{{ $t('UseDeliveryOrder') }}</span> <span class="fs-12 badge bg-danger">{{ $t('inDevelop') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="ordersDeliveryAsk" id="ordersDeliveryAskY" :value="true" v-model="form.ordersDeliveryAsk" >
                                    <label class="form-check-label fs-14 fw-semibold" for="ordersDeliveryAskY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="ordersDeliveryAsk" id="ordersDeliveryAskN" :value="false" v-model="form.ordersDeliveryAsk" >
                                    <label class="form-check-label fs-14 fw-semibold" for="ordersDeliveryAskN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="6">
                            <span class="fs-14 fw-semibold">{{ $t('UseOrderbyDate') }}</span> <span class="fs-12 badge bg-danger">{{ $t('inDevelop') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="ordersOnDateAsk" id="ordersOnDateAskY" :value="true" v-model="form.ordersOnDateAsk" >
                                    <label class="form-check-label fs-14 fw-semibold" for="ordersOnDateAskY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="ordersOnDateAsk" id="ordersOnDateAskN" :value="false" v-model="form.ordersOnDateAsk" >
                                    <label class="form-check-label fs-14 fw-semibold" for="ordersOnDateAskN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="6">
                            <span class="fs-14 fw-semibold">{{ $t('maxIDOrder') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="maxLocalId" id="maxLocalIdY" :value="true" v-model="form.maxLocalId.use"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="maxLocalIdY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="maxLocalId" id="maxLocalIdN" :value="false" v-model="form.maxLocalId.use" >
                                    <label class="form-check-label fs-14 fw-semibold" for="maxLocalIdN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                            <template v-if="form.maxLocalId.use == true">
                                <label class="fs-12 text-muted mb-0">{{ $t('maxIDOrder') }}</label>
                                <input class="form-control" v-model="form.maxLocalId.value" />
                            </template>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('ShiftParameters') }}</span><hr />
                    <b-row>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('RequestOpenShift') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="shiftOpenAsk" id="shiftOpenAskY" :value="true" v-model="form.shiftOpenAsk"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="shiftOpenAskY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="shiftOpenAsk" id="shiftOpenAskN" :value="false" v-model="form.shiftOpenAsk" >
                                    <label class="form-check-label fs-14 fw-semibold" for="shiftOpenAskN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('DepositAfterOpeningShift') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="introductionAskAfterOpenShift" id="introductionAskAfterOpenShiftY" :value="true" v-model="form.introductionAskAfterOpenShift"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="introductionAskAfterOpenShiftY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="introductionAskAfterOpenShift" id="introductionAskAfterOpenShiftN" :value="false" v-model="form.introductionAskAfterOpenShift" >
                                    <label class="form-check-label fs-14 fw-semibold" for="introductionAskAfterOpenShiftN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('ShiftOpeningOnlyTT') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="openShiftOnTradepoint" id="openShiftOnTradepointY" :value="true" v-model="form.openShiftOnTradepoint" >
                                    <label class="form-check-label fs-14 fw-semibold" for="openShiftOnTradepointY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="openShiftOnTradepoint" id="openShiftOnTradepointN" :value="false" v-model="form.openShiftOnTradepoint" >
                                    <label class="form-check-label fs-14 fw-semibold" for="openShiftOnTradepointN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                            <template v-if="form.openShiftOnTradepoint == true">
                                <label class="fs-12 text-muted mb-0">{{ $t('IP') }}</label>
                                <input class="form-control mb-3" v-model="form.openShiftByIp"  />
                            </template>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('CollectionEndShift') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="incassationOnCloseShift" id="incassationOnCloseShiftY" :value="'manually'" v-model="form.incassationOnCloseShift.value"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="incassationOnCloseShiftY">
                                        {{ $t('Manually') }}
                                    </label>
                                </div>
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="incassationOnCloseShift" id="incassationOnCloseShiftN" :value="'auto'" v-model="form.incassationOnCloseShift.value" >
                                    <label class="form-check-label fs-14 fw-semibold" for="incassationOnCloseShiftN">
                                        {{ $t('Automatically') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="incassationOnCloseShift" id="incassationOnCloseShiftN" :value="'notPerform'" v-model="form.incassationOnCloseShift.value" >
                                    <label class="form-check-label fs-14 fw-semibold" for="incassationOnCloseShiftN">
                                        {{ $t('DonotCarryOut') }}
                                    </label>
                                </div>
                            </div>
                            <template v-if="form.incassationOnCloseShift.value == 'auto'">
                                <label class="fs-12 text-muted mb-0">{{ $t('AcID') }}</label>
                                <input class="form-control mb-3" v-model="form.incassationOnCloseShift.walletId"  />
                            </template>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('ClintsParameters') }}</span><hr />
                    <b-row>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('RequestNumberCustomers') }}</span> <span class="fs-12 badge bg-danger">{{ $t('inDevelop') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="clientsCount" id="clientsCountY" :value="true" v-model="form.clientsCount"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="clientsCountY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="clientsCount" id="clientsCountN" :value="false" v-model="form.clientsCount" >
                                    <label class="form-check-label fs-14 fw-semibold" for="clientsCountN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('SearchClientsBy') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="clientSearch" id="clientSearchY" :value="'onlyPhone'" v-model="form.clientSearch"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="clientSearchY">
                                        {{ $t('SearchClientsNumphone') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="clientSearch" id="clientSearchN" :value="'multiSearch'" v-model="form.clientSearch" >
                                    <label class="form-check-label fs-14 fw-semibold" for="clientSearchN">
                                        {{ $t('SearchClientsAllparams') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('AddChangeClientsCard') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="changeToClientCard" id="changeToClientCardY" :value="true" v-model="form.changeToClientCard"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="changeToClientCardY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="changeToClientCard" id="changeToClientCardN" :value="false" v-model="form.changeToClientCard" >
                                    <label class="form-check-label fs-14 fw-semibold" for="changeToClientCardN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('BuyersChoicebyCard') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="cardCustomerSelection" id="cardCustomerSelectionY" :value="true" v-model="form.cardCustomerSelection"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="cardCustomerSelectionY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="cardCustomerSelection" id="cardCustomerSelectionN" :value="false" v-model="form.cardCustomerSelection" >
                                    <label class="form-check-label fs-14 fw-semibold" for="cardCustomerSelectionN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('LoyaltyProgramOptions') }}</span><hr />
                    <b-row>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('discountProgramType') }}</span> <span class="fs-12 badge bg-danger">{{ $t('inDevelop') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="bonusesAndDiscount" id="discountsAndBonuses" :value="'both'" v-model="form.bonusesAndDiscount"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="discountsAndBonuses">
                                        {{ $t('discountsAndBonuses') }}
                                    </label>
                                </div>
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="bonusesAndDiscount" id="discounts_for" :value="'discount'" v-model="form.bonusesAndDiscount" >
                                    <label class="form-check-label fs-14 fw-semibold" for="discounts_for">
                                        {{ $t('discounts_for') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="bonusesAndDiscount" id="Bonuses" :value="'bonuses'" v-model="form.bonusesAndDiscount" >
                                    <label class="form-check-label fs-14 fw-semibold" for="Bonuses">
                                        {{ $t('Bonuses') }}
                                    </label>
                                </div>
                            </div>
                            <template v-if="form.bonusesAndDiscount == 'both' || form.bonusesAndDiscount == 'bonuses'">
                                <label class="fs-12 text-muted mb-0">{{ $t('maxBonusesWriteoff') }}</label>
                                <input class="form-control mb-3" v-model="form.maxBonusesWriteoff"  />
                            </template>
                        </b-col>
                        <b-col lg="12" v-if="form.bonusesAndDiscount == 'both' || form.bonusesAndDiscount == 'bonuses'">
                            <span class="fs-14 fw-semibold">{{ $t('BonusWithdrawalType') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="bonusesWriteoffType" id="fullPrice" :value="'zero'" v-model="form.bonusesWriteoffType"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="fullPrice">
                                        {{ $t('fullPrice') }}
                                    </label>
                                </div>
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="bonusesWriteoffType" id="dotOne" :value="'dotOne'" v-model="form.bonusesWriteoffType" >
                                    <label class="form-check-label fs-14 fw-semibold" for="dotOne">
                                        {{ $t('dotOne') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12" v-if="form.bonusesAndDiscount == 'both' || form.bonusesAndDiscount == 'discount'">
                            <span class="fs-14 fw-semibold">{{ $t('combinedDiscount') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="combinedDiscount" id="combinedDiscountY" :value="true" v-model="form.loyaltyProgram.combinedDiscount"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="combinedDiscountY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="combinedDiscount" id="combinedDiscountN" :value="false" v-model="form.loyaltyProgram.combinedDiscount" >
                                    <label class="form-check-label fs-14 fw-semibold" for="combinedDiscountN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12" v-if="form.bonusesAndDiscount == 'both' || form.bonusesAndDiscount == 'bonuses'">
                            <span class="fs-14 fw-semibold">{{ $t('combinedBonus') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="combinedBonus" id="combinedBonusY" :value="true" v-model="form.loyaltyProgram.combinedBonus"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="combinedBonusY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="combinedBonus" id="combinedBonusN" :value="false" v-model="form.loyaltyProgram.combinedBonus" >
                                    <label class="form-check-label fs-14 fw-semibold" for="combinedBonusN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12" v-if="form.bonusesAndDiscount == 'both' || form.bonusesAndDiscount == 'discount'">
                            <span class="fs-14 fw-semibold">{{ $t('manuallyChangeDiscount') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="manuallyChangeDiscount" id="manuallyChangeDiscountY" :value="true" v-model="form.loyaltyProgram.manuallyChangeDiscount"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="manuallyChangeDiscountY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="manuallyChangeDiscount" id="cmanuallyChangeDiscountN" :value="false" v-model="form.loyaltyProgram.manuallyChangeDiscount" >
                                    <label class="form-check-label fs-14 fw-semibold" for="cmanuallyChangeDiscountN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('printParameters') }} <span class="fs-12 badge bg-danger">{{ $t('inDevelop') }}</span></span><hr />
                    <b-row>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('printPositionSum') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="printPositionSum" id="printPositionSumY" :value="true" v-model="form.begunok.printPositionSum"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="printPositionSumY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="printPositionSum" id="printPositionSumN" :value="false" v-model="form.begunok.printPositionSum" >
                                    <label class="form-check-label fs-14 fw-semibold" for="printPositionSumN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('printDeliveryInfo') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="printDeliveryInfo" id="printDeliveryInfoY" :value="true" v-model="form.printDeliveryInfo"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="printDeliveryInfoY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="printDeliveryInfo" id="printDeliveryInfoN" :value="false" v-model="form.printDeliveryInfo" >
                                    <label class="form-check-label fs-14 fw-semibold" for="printDeliveryInfoN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('printusertocheck') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="printUserName" id="printUserNameY" :value="true" v-model="form.begunok.printUserName"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="printUserNameY">
                                        {{ $t('y') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="printUserName" id="printUserNameN" :value="false" v-model="form.begunok.printUserName" >
                                    <label class="form-check-label fs-14 fw-semibold" for="printUserNameN">
                                        {{ $t('n') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('AdditionalFeatures') }}</span><hr />
                    <b-row>
                        <b-col lg="12">
                            <span class="fs-14 fw-semibold">{{ $t('DisplayTextinCart') }}</span>
                            <div class="d-flex mt-2 m-3 ">
                                <div class="form-check" style="margin-right: 15px;">
                                    <input class="form-check-input" type="radio" name="basketTextDisplay" id="basketTextDisplayDefault" :value="'default'" v-model="form.basketTextDisplay"  >
                                    <label class="form-check-label fs-14 fw-semibold" for="basketTextDisplayDefault">
                                        {{ $t('LineWrap') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="basketTextDisplay" id="clientsCountNowrap" :value="'nowrap'" v-model="form.basketTextDisplay" >
                                    <label class="form-check-label fs-14 fw-semibold" for="clientsCountNowrap">
                                        {{ $t('NoTransfer') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="basketTextDisplay" id="clientsCountAnimated" :value="'animated'" v-model="form.basketTextDisplay" >
                                    <label class="form-check-label fs-14 fw-semibold" for="clientsCountAnimated">
                                        {{ $t('WithAnimation') }}
                                    </label>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </b-card>
    <b-card>
        <div class="card-header mb-3">
            <h5 class="card-title mb-0"><b>{{ $t('invoices') }}</b></h5>
        </div>
        <b-row>
            <b-col lg="6">
                <span class="fs-16" >{{ $t('cash') }}</span>
                <div class="border rounded border-dashed p-2 mb-2">
                    <template v-for="(item, index) in form.wallets.cash">
                        <div class="d-flex align-items-center" >
                            <div class="flex-shrink-0" >
                                <div class="avatar-xs flex-shrink-0 me-1" ><div class="avatar-title rounded bg-soft-info text-black" >#{{ index+1 }}</div></div>
                            </div>
                            <div class="flex-grow-1 ms-2" >
                                <h6 class="mb-1"><b>{{ item.walletName }}</b></h6>
                            </div>
                        </div>
                    </template> 
                </div>
            </b-col>
            <b-col lg="6">
                <span class="fs-16" >{{ $t('Cashless') }}</span>
                <div class="border rounded border-dashed p-2 mb-2">
                    <template v-for="(item, index) in form.wallets.cashless">
                        <div class="d-flex align-items-center" >
                            <div class="flex-shrink-0" >
                                <div class="avatar-xs flex-shrink-0 me-1" ><div class="avatar-title rounded bg-soft-info text-black" >#{{ index+1 }}</div></div>
                            </div>
                            <div class="flex-grow-1 ms-2" >
                                <h6 class="mb-1"><b>{{ item.walletName }}</b></h6>
                            </div>
                        </div>
                    </template> 
                </div>
            </b-col>
        </b-row>
    </b-card>
    <b-card>
        <div class="card-header mb-3">
            <h5 class="card-title mb-0"><b>{{ $t('UsingMarkup') }}</b> <span class="fs-12 badge bg-danger">{{ $t('inDevelop') }}</span></h5>
        </div>
        <b-row>
            <b-col md="6" lg="2">
                <span class="fs-16" >{{ $t('Percent') }}</span><br />
                <b-form-select
                    v-model="form.priceMarkup.percent"
                    :options="priceMarkupValues"
                    class="form-control"
                >
                    <option :value="null">
                        {{ $t('n') }}
                    </option>
                </b-form-select>
            </b-col>
            <b-col md="6" lg="2">
                <span class="fs-16" >{{ $t('Rounding') }}</span><br />
                <b-form-select
                    v-model="form.priceMarkup.round"
                    :options="priceMarkupRoundValues"
                    class="form-control"
                >
                    <option :value="null">
                        {{ $t('n') }}
                    </option>
                </b-form-select>
            </b-col>
            <b-col md="6" lg="2">
                <span class="fs-16" >{{ $t('type') }}</span><br />
                <b-form-select
                    v-model="form.priceMarkup.auto"
                    :options="priceMarkupAutoValues"
                    class="form-control"
                >
                    <option :value="null">
                        {{ $t('n') }}
                    </option>
                </b-form-select>
            </b-col>
            <b-col md="6" lg="2">
                <span class="fs-16" >{{ $t('Count') }}</span><br />
                <b-form-select
                    v-model="form.priceMarkup.includingDiscount"
                    :options="priceMarkupIncludingDiscountValues"
                    class="form-control"
                >
                    <option :value="null">
                        {{ $t('n') }}
                    </option>
                </b-form-select>
            </b-col>
            <b-col md="6" lg="3">
                <span class="fs-16" >{{ $t('SettingsFromCheckout') }}</span><br />
                <b-form-select
                    v-model="form.priceMarkup.changeInTerminal"
                    :options="priceMarkupTerminalValues"
                    class="form-control"
                >
                    <option :value="null">
                        {{ $t('n') }}
                    </option>
                </b-form-select>
            </b-col>
        </b-row>
    </b-card>
    <b-card>
        <div class="card-header mb-3">
            <h5 class="card-title mb-0"><b>{{ $t('TypeGoodsinCart') }}</b></h5>
        </div>
        <ul class="typeGoods">
            <li>
                <input type="radio" name="detailedProducts" :value="false" v-model="form.detailedProducts" id="cb1" />
                <label for="cb1" >
                    <img class="img-thumbnail" alt="400x400" width="400" src="@/assets/images/terminal/withoutImage.jpg">
                </label>
            </li>
            <li>
                <input type="radio" name="detailedProducts" :value="true" v-model="form.detailedProducts" id="cb2" />
                <label for="cb2" >
                    <img class="img-thumbnail" alt="400x400" width="400" src="@/assets/images/terminal/withImage.jpg">
                </label>
            </li>
        </ul>
    </b-card>
    <b-card v-if="form.quickComments.length > 0">
        <div class="card-header mb-3">
            <h5 class="card-title mb-0"><b>{{ $t('QuickCommentsonOrder') }}</b></h5>
        </div>
        <template v-for="item in form.quickComments" :key="item">
            <span class="fs-14 badge bg-success m-2">{{ item }}</span>
        </template>
    </b-card>
    <b-card>
        <div class="card-header mb-3">
            <h5 class="card-title mb-0"><b>{{ $t('PasswordMaster') }}</b></h5>
        </div>
        <div class="form-check form-switch form-switch-md" dir="ltr">
            <input type="checkbox" class="form-check-input" id="customSwitchsizemd" v-model="form.masterPasswordsDetailed">
            <label class="form-check-label" for="customSwitchsizemd">{{ $t('separatePasswordForEachSetting') }}</label>
        </div>
        <p class="text-muted">
            {{ $t('preciseSettings') }}
        </p> 
        <template v-if="form.masterPasswordsDetailed == false">
            <label class="fs-12 text-muted mb-0">{{ $t('LockPassword') }}</label>
            <div class="input-group mb-3">
                <input type="text" class="form-control" v-model="form.masterPasswordMainValue" disabled>
                <button @click="copy(form.masterPasswordMainValue)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
            </div>
        </template>
        <b-row>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('WorkwithOrder') }}</span><hr />
                    <b-row>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('DeleteOrder') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.cancelOrder.value" disabled>
                                <button @click="copy(form.masterPasswords.cancelOrder.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('SplittheOrder') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.cutOrder.value" disabled>
                                <button @click="copy(form.masterPasswords.cutOrder.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('OrderReturn') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.returnSale.value" disabled>
                                <button @click="copy(form.masterPasswords.returnSale.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('PrintOrderTermination') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.printOrder.value" disabled>
                                <button @click="copy(form.masterPasswords.printOrder.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('CreatingDocument') }}</span><hr />
                    <b-row>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('CreationComing') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.addNewComing.value" disabled>
                                <button @click="copy(form.masterPasswords.addNewComing.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('CreateTransaction') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.addNewTransaction.value" disabled>
                                <button @click="copy(form.masterPasswords.addNewTransaction.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('CreatingWriteoff') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.addNewWriteoff.value" disabled>
                                <button @click="copy(form.masterPasswords.addNewWriteoff.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('CreateMove') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.addNewMoving.value" disabled>
                                <button @click="copy(form.masterPasswords.addNewMoving.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('CreationInventory') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.addNewInventory.value" disabled>
                                <button @click="copy(form.masterPasswords.addNewInventory.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('ViewStatistics') }}</span><hr />
                    <b-row>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('ViewSalesHistory') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.viewSalesHistoryStat.value" disabled>
                                <button @click="copy(form.masterPasswords.viewSalesHistoryStat.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('ViewAdvancedSalesHistory') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.viewOtherSalesHistoryStat.value" disabled>
                                <button @click="copy(form.masterPasswords.viewOtherSalesHistoryStat.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('ViewChangeReport') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.viewShiftStat.value" disabled>
                                <button @click="copy(form.masterPasswords.viewShiftStat.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('AdditionalFeatures') }}</span><hr />
                    <b-row>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('PromotionCancellation') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.cancelPromotion.value" disabled>
                                <button @click="copy(form.masterPasswords.cancelPromotion.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('CancellationDiscount') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.removeDiscount.value" disabled>
                                <button @click="copy(form.masterPasswords.removeDiscount.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('CustomerChoice') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.customerSelection.value" disabled>
                                <button @click="copy(form.masterPasswords.customerSelection.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('ChangeMarkupTerm') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.priceMarkup.value" disabled>
                                <button @click="copy(form.masterPasswords.priceMarkup.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('ChangingOrderDuringPrinting') }}</span><hr />
                    <b-row>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('ChangingOrderAfterPrinting') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.editOrderAfterPrintOrder.value" disabled>
                                <button @click="copy(form.masterPasswords.editOrderAfterPrintOrder.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="border rounded border-dashed p-2 mb-2">
                    <span class="fs-14 fw-semibold pt-1 text-center" style="display: block;">{{ $t('ChangingOrderWhileCooking') }}</span><hr />
                    <b-row>
                        <b-col lg="12" class="mb-2">
                            <span class="fs-14 fw-semibold">{{ $t('ChangingOrdersentPreparationg') }}</span>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="form.masterPasswords.editOrderAfterPrintKitchenOrder.value" disabled>
                                <button @click="copy(form.masterPasswords.editOrderAfterPrintKitchenOrder.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {copy} from '@/usabilityScripts/copy.js'

export default {
    props: ['obj'],
    data(){
        return{
            form: '',
            priceMarkupValues: [
                { value: null, text: this.$t('NotSpecified') },
                { value: 0, text: this.$t('n') },
                { value: 1, text: '1%' },
                { value: 2, text: '2%' },
                { value: 3, text: '3%' },
                { value: 4, text: '4%' },
                { value: 5, text: '5%' },
                { value: 6, text: '6%' },
                { value: 7, text: '7%' },
                { value: 8, text: '8%' },
                { value: 9, text: '9%' },
                { value: 10, text: '10%' },
                { value: 11, text: '11%' },
                { value: 12, text: '12%' },
                { value: 13, text: '13%' },
                { value: 14, text: '14%' },
                { value: 15, text: '15%' },
                { value: 16, text: '16%' },
                { value: 17, text: '17%' },
                { value: 18, text: '18%' },
                { value: 19, text: '19%' },
                { value: 20, text: '20%' },
                { value: 21, text: '21%' },
                { value: 22, text: '22%' },
                { value: 23, text: '23%' },
                { value: 24, text: '24%' },
                { value: 25, text: '25%' },
            ],
            priceMarkupRoundValues: [
                { value: null, text: this.$t('NotSpecified') },
                { value: 0, text: this.$t('DoNotRound') },
                { value: 1, text: this.$t('roundOff') },
            ],
            priceMarkupAutoValues: [
                { value: null, text: this.$t('NotSpecified') },
                { value: 0, text: this.$t('Manually') },
                { value: 1, text: this.$t('Automatically') },
            ],
            priceMarkupTerminalValues: [
                { value: null, text: this.$t('NotSpecified') },
                { value: 1, text: this.$t('changeValueFromPos') },
                { value: 0, text: this.$t('dontChangeValueFromPos') },
            ],
            priceMarkupIncludingDiscountValues: [
                { value: null, text: this.$t('NotSpecified') },
                { value: 1, text: this.$t('priceMarkupWithDiscount') },
                { value: 0, text: this.$t('priceMarkupWithoutDiscount') },
            ],
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    }
}
</script>