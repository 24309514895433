<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('Group') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <input 
                v-model="search"
                class="form-control mb-2"
            />
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radiogroup" id="allgroup" v-model="groupId" :value="''">
                    <label class="form-check-label" for="allgroup">{{ $t('all') }}</label>
                </div>
            </div>
            <template v-for="(value, index) in filteredItems" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radiogroup" :id="'group'+index" v-model="groupId" :value="value.value">
                        <label class="form-check-label" :for="'group'+index">{{ value.label }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store';

export default {
    components: { Multiselect },
    data(){
        return {
            search: '',
            groupId: "",
            groupsList: [
                
            ]
        }
    },
    created() {
        for(var item in this.groups){
            this.groupsList.push({
                label: this.groups[item].groupName,
                value: this.groups[item].groupId,
            })
        }
    },
    methods: {
        change(){            
            this.$emit('change', 'groupId', this.groupId)
        },
    },
    computed: {
        groups(){
            return storeS.groups
        },
        filteredItems: function() {
            return Object.values(this.groupsList)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.label.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>