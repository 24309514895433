<template>
    
    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        :objParams="objParams"
        @open="open"
    />

    <viewBox 
        v-if="showModal"
        @close="showModal = false"
        :obj="objCard"
    />
    
</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewBox from './view'
import { Dashboard } from '@/API_Dash'

let apiServe = new Dashboard();

export default{
    props: ['obj', 'companyId'],
    components: {
        tablecustom,
        viewBox
    },
    data(){
        return{
            form: '',
            objCard: '',
            showModal: false,
            objParams: {
                search: ''
            },
            rows: '',
            columns: [
                {
                    name: "ID",
                    text: "discountId",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("Name"),
                    text: "discountTitle",
                    align: "left",
                    status: true,
                    copy: true
                },
            ],
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.rows = this.obj
        },
        open(e){
            apiServe.getDiscount(this.companyId, e.discountId).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data;
                    this.showModal = true;
                } else if(result.status == 'error' && result.error[0] == 'empty company url'){
                    this.$toast.error(this.$t('emptyCompanyUrl'));
                } else {
                    this.$toast.error(this.$t('discountNotFound'))
                }
            })
        },
        searchB(e){
            this.objParams.search = e
        },
    },
    computed: {
        filteredItems: function() {
            return this.rows
                // по пошуку
                .filter(item => {
                    return this.objParams.search == '' || item.discountTitle.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>