<template>
    <!-- 
        Модальне вікно перегляду таски

    -->
    <modal :key="componentKey" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @close="closeModal" >
        <template v-slot:title>{{ $t('Task') }} #{{ this.form.id ? this.form.id : '-' }}</template>
        <template v-slot:status >
            <span :class="`badge badge ${this.mutatestatus(this.form.status, this.form).color} align-middle fs-12`">{{this.mutatestatus(this.form.status, this.form).name}}</span>
        </template>
        <template v-slot:head-button>
            <!-- Йде перевірка на:
                    - причастність юзера в виконані\створені завдання:
                        * юзер повинен взяти завдання в роботу, як коллцентр (коли завдання потребує підтвердження ("wait_confirm" на фронті і беці))
                        * юзер повинен взяти завдання в роботу, як виконавець (завдання "очікує виконання" ("wait_completed" на фронті і беці))
                        * юзер створив це завдання але виконавці (коллцентр не почав підтвердження та той, хто його виконав - ще не взяли його в роботу)
                    - права доступа:
                        * юезр може взяти завдання в роботу
                        * юзер може його редагувати
                        * юзер має повний доступ
                    - по статусам:
                        * завдання взято в роботу коллцентром
                        * завдання взято в роботу виконавцем
                        * завдання відкладено
                        * завдання очікує підтвердження
                        * завдання очікує виконання 
            -->
            <div class="dropdown">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <!-- 
                        Редагування завдання 
                        Є перевірка:
                        - права доступу:
                            * юзер має повний доступ
                            * юзер може брати завданн в роботу 
                        - статус завдання:
                            * завдання має статус "новий"
                            * завдання має статус "чекає виконання"
                            * завдання має статус "чекає підтвердження"
                    -->
                    <button 
                        class="dropdown-item"   
                        v-if="(
                            perms[2000] || perms[204]
                        ) && 
                        ( 
                            form.status == 'new' || 
                            form.status == 'wait_completed' || 
                            form.status == 'wait_confirm'
                        )" 
                        @click="edit"
                    >{{ $t('toEditAp') }}</button>
                    <!-- 
                        Відкладання завдання
                        Є перевірка:
                            - id виконавця (коллцентр чи той, хто виконує) може відкласти
                            - права доступу:
                                * юзер має повний доступ
                                * юзер може редагувати завдання
                            - статус завдання:
                                * завдання має статус "новий"
                                * завдання має статус "чекає виконання"
                                * завдання має статус "чекає підтвердження"
                    -->
                    <button 
                        class="dropdown-item" 
                        v-if="
                            ((form.inJob.workerId == this.user.userid || form.perform.workerId == this.user.userid) && 
                            (perms[205]) && 
                            (form.status == 'jobs' || form.status == 'perform' || form.status == 'delayed')) || perms[2000]
                        " 
                        @click="showModalDelay=true"
                    >{{ $t('postpone') }}</button> 
                    <!-- 
                        Включити калькулятор:
                        Є перевірка: 
                            - Таска "Перенесення коштів"
                    -->

                    <button 
                        class="dropdown-item" 
                        v-if="
                            this.form.taskName == 'transfer_funds_companies_between_accounts' || this.form.taskName == 'transfer_funds_companies_in_account'
                        " 
                        @click="showCalc=true"
                    >{{ $t('Calculator') }}</button> 

                    <!-- 
                        Передача завдання
                        Є перевірка:
                            - id виконавця (коллцентр чи той, хто виконує) може відкласти
                            - права доступу:
                                * юзер має повний доступ
                                * юзер може брати завдання в роботу 
                    -->
                    <button 
                        class="dropdown-item" 
                        v-if="
                            (form.inJob.workerId == this.user.userid || form.perform.workerId == this.user.userid) && 
                            (perms[202] || perms[2000])
                        " 
                        @click="showModalTransfer = true"
                    >{{ $t('Submit')}}</button> <!-- передать заявку -->
                    <!-- 
                        Cтворити заявку в інші дзвінки
                        Є перевірка: 
                            * Права доступу:
                                - Створення заявки
                                - Повний доступ
                            * Статус завдання: 
                                - Завершено
                                - Відмінено
                    -->
                    <button 
                        class="dropdown-item" 
                        v-if="
                            (perms[2000] || perms[101]) && 
                            (form.status == 'completed' || form.status == 'canceled') 
                        " 
                        @click="showAddReqOth = true"
                    >{{ $t('AddRequestToGroup') }}</button>
                </div>
            </div>
        </template>
        <template v-slot:body >
            <!-- альорт, якщо таска завершена -->
            <b-alert v-if="checkCompleted" show variant="info" class="text-center">
                <h5 class="alert-heading" >
                    <b>{{ this.$t('completedTask') }}</b>
                </h5>
            </b-alert>

            <!-- Альорт з позиціями, які не скопіювались -->
            <b-alert v-if="transferredObj.transferred && transferredObj.list.length > 0" show variant="warning" class="text-center">
                <h5 class="alert-heading" >
                    <b>{{ $t('alertCopyTechCards') }}</b>
                    <!-- Копіювати дані з альорту в буфер -->
                    <button 
                        type="button" 
                        class="btn btn-outline-warning btn-icon waves-effect waves-light" 
                        style="margin-left: 5px;"
                        @click="copyList()"
                    >
                        <i class="ri-file-copy-2-line"></i>
                    </button>

                    <div style="max-height: 215px; max-width: 500px;margin: auto;overflow: auto;" class="mt-3"> 
                        <ul class="list-group">
                            <template v-for="item in transferredObj.list">
                                <li class="list-group-item">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <div class="d-flex">
                                                <!-- Назва товару та його id -->
                                                <div class="flex-shrink-0 ms-2" style="text-align: left;">
                                                    <h6 class="fs-14 mb-0 fw-semibold">{{ item.nomenclatureName }}</h6>
                                                    <small class="text-muted"># {{ item.nomenclatureId }}</small>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="flex-shrink-0">
                                            <!-- Кнопка копіювати назву -->
                                            <button 
                                                type="button" 
                                                @click="copy(item.nomenclatureName ? item.nomenclatureName : this.$t('err'))"
                                                class="btn btn-success btn-icon waves-effect waves-light"
                                            >
                                                <i class="ri-file-copy-2-fill"></i>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                </h5>
            </b-alert>


            <!-- 
                альорт, якщо таска передана
                Є перевірка:
                    - якщо запит відправлено
            -->
            <b-alert v-if="form.status == 'transfered'" show variant="warning" class="text-center">
                <h5 class="alert-heading" v-if="this.user.userid != form.transfer.workerId">
                    <b>{{ form.transfer.fromWorkerName }} {{ $t('submittedRequest') }} {{ form.transfer.workerName }}</b>
                </h5>
                <h5 class="alert-heading" v-else>
                    <b>{{ form.transfer.fromWorkerName }} {{ $t('sentYouRequest') }}</b>
                </h5>
                <span v-if="form.transfer.comment">{{ $t('Comment') }}: {{ form.transfer.comment }}</span>
            </b-alert>
            <b-row>
                <!-- блок "Про завдання" -->
                <b-col lg="4">
                    <aboutBlock 
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                </b-col>
                <b-col lg="4">
                    <!-- блок "Cтворено" -->
                    <createdBlock
                        :obj="this.form.created"
                        :comment="this.form.comment"
                    />
                </b-col>
                <b-col lg="4">
                    <!-- блок "Клієнт" -->
                    <clientBlock
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                    
                </b-col>
            </b-row>
            <hr v-if="form.status == 'jobs' || form.status == 'perform' || form.status == 'confirmed' || form.status == 'completed' || form.status == 'transfered' || form.status == 'canceled'" />
            <b-row v-if="
                form.status == 'jobs' || 
                form.status == 'confirmed' || 
                form.status == 'completed' || 
                form.status == 'transfered' || 
                form.status == 'perform' || 
                form.status == 'canceled' ||
                (   
                    (
                        this.form.taskName == 'transfer_funds_companies_in_account' || 
                        this.form.taskName == 'transfer_funds_companies_between_accounts'
                    )
                )
                ">
                <!-- 
                    Блок "Виконання КЦ" 
                    Є перевірка: 
                        - Присутній id юзера, що виконує таску
                -->
                <b-col lg="4" v-if="form.inJob.workerId !== null && form.confirmed.workerId == null">
                    <jobsCC 
                        :obj="this.form.inJob"
                    />
                </b-col>
                <!-- 
                    Блок "Підтвердженно" (Підтвердженно працівниками коллцентру. Викликається, коли завдання потребує підтвердження після створення) 
                    Є перевірка: 
                        - Присутній id юзера що взяв у роботу
                            ***Або***
                        - Присутній id юзера, що підтвердив завдання
                -->
                <b-col lg="4" v-if="form.confirmed.workerId != null"> 
                    <confirmedBlock 
                        :obj="this.form.confirmed"
                    />
                </b-col>
                <!-- 
                    Блок "Виконання" 
                    Є перевірка: 
                        - Присутній id юзера, що виконує таску
                -->
                <b-col lg="4" v-if="form.perform.workerId !== null">
                    <jobsBlock 
                        :obj="this.form.perform"
                    />
                </b-col>
                <!-- 
                    Блок "Передано" 
                    Є перевірка: 
                        - Присутні дані про передачу
                -->
                <b-col lg="4" v-if="form.transfer.workerId !== null && form.transfer.workerId !== 0">
                    <tranferBlock 
                        :obj="this.form.transfer"
                    />
                </b-col>
                <!-- 
                    Блок "Закриття" 
                    Є перевірка: 
                        - Присутній id юзера, що закрив завдання
                -->
                <b-col lg="4" v-if="form.closed.workerId !== null">
                    <closedBlock
                        :obj="this.form.closed" 
                    />
                </b-col>
                <!-- 
                    Блок "Скасовано"
                    Є перевірка: 
                        - Присутній id юзера, що скасував завдання
                -->
                <b-col lg="4" v-if="form.canceled.workerId !== null || form.canceled.comment">
                    <canceledBlock
                        :obj="this.form.canceled" 
                    />
                </b-col>

                <!-- 
                    Блок "Перенесення коштів"
                    Є перевірка:
                        - Назва таски "Перенесення коштів в межах акаунту"
                        - Назва таски "Перенесення коштів між акаунтами"
                -->
                <b-col lg="4" v-if="this.form.taskName == 'transfer_funds_companies_in_account' || this.form.taskName == 'transfer_funds_companies_between_accounts'">
                    <transferFund
                        :obj="this.form"
                        :first="this.form.companyId"
                        :second="this.form.copyOnCompany[0].companyId"
                    />
                </b-col>
            </b-row>
        </template>
        <!-- кнопки закриття та вирішення завдань, що знаходяться в тісній кооперації з Sky Service -->
        <template v-slot:footer-bottom >

                <!-- 
                    Відміна задачі
                    Є перевірка:
                        - Права доступу:
                            * Юзер може видалити заявку
                            * Юзер має фуллдоступ 
                        - Юзер є виконавцем цього завдання
                        - Статус заявки:
                            * Не скасована
                            * Не зроблена
                -->
                <button 
                    type="button" 
                    v-if="
                        perms[2000] ||
                        (perms[203] && 
                        ((form.inJob.workerId == this.user.userid) || (this.form.created.workerId === this.user.userid) || (this.form.perform.workerId === this.user.userid)) && 
                        (
                            form.status != 'canceled' || 
                            form.status != 'completed'
                        ))
                    "
                    @click="showModalCancel = true" 
                    class="btn btn-danger r waves-effect waves-light">
                    {{ $t('cancel') }}
                </button>
                <!-- 
                    Створити компанію з новою адмінкою 
                    Є перевірка:
                        - Таска не завершена
                        - Статус завдання "В роботі за виконавцем"
                        - Тип завдання "Включити нову версію"
                        - Права доступу:
                            * Увімкнути нову версію
                            * Повний доступ
                -->
                <button 
                    type="button" 
                    v-if="
                        checkCompleted == false && 
                        form.status == 'perform' && 
                        form.taskName == 'enable_new_version' && 
                        (perms[2021] || perms[2000])
                    "
                    @click="switchPerformTask" 
                    class="btn btn-secondary r waves-effect waves-light">
                    {{ $t('perform') }} 
                </button>

                <!-- 
                    Скопіювати дані з старої адмінки в нову
                    Є перевірка:
                        - Таска не завершена
                        - Статус завдання "В роботі за виконавцем"
                        - Тип завдання "Копіювати дані з компанії на іншу компанію (в межах акаунту або між ними)"
                        - Права доступу:
                            * Увімкнути нову версію
                            * Повний доступ
                -->
                <button 
                    type="button" 
                    v-if="
                        checkCompleted == false && 
                        form.status == 'perform' && 
                        (
                            form.taskName == 'trans_data_from_old_company' || 
                            form.taskName == 'trans_data_from_old_company_franchise' ||
                            form.taskName == 'trans_data_from_newServer_company_franchise' ||
                            form.taskName == 'trans_data_from_newServer_company'
                        ) && 
                        (perms[20212] || perms[2000] || perms[2021])"
                    @click="switchPerformTask" 
                    class="btn btn-secondary r waves-effect waves-light">
                    {{ $t('perform') }} 
                </button> 
                <!-- 
                    Змінити тип акаунту на властника
                    Є перевірка:
                        - Таска не завершена
                        - Статус завдання "В роботі за виконавцем"
                        - Тип завдання "Змінити тип облікового запису"
                        - Права доступу:
                            * Увімкнути нову версію
                            * Повний доступ
                -->
                <button 
                    type="button" 
                    v-if="
                        checkCompleted == false && 
                        form.status == 'perform' && 
                        form.taskName == 'change_account_type' && 
                        (perms[2022] || perms[2000])
                    "
                    @click="switchPerformTask" 
                    class="btn btn-secondary r waves-effect waves-light">
                    {{ $t('perform') }} 
                </button>
                <!-- 
                    Скинути пароль
                    Є перевірка:
                        - Таска не завершена
                        - Статус завдання "В роботі за виконавцем"
                        - Тип завдання "Змінити тип облікового запису"
                        - Права доступу:
                            * Увімкнути нову версію
                            * Повний доступ
                -->
                <button 
                    type="button" 
                    v-if="
                        checkCompleted == false 
                        && form.status == 'perform' && 
                        form.taskName == 'discharge_password' && 
                        (perms[2027] || perms[2000])
                    "
                    @click="switchPerformTask" 
                    class="btn btn-danger r waves-effect waves-light">
                    {{ $t('resetPassword') }} 
                </button>
                <!-- 
                    Змінити ліміт компаній
                    Є перевірка:
                        - Таска не завершена
                        - Статус завдання "В роботі за виконавцем"
                        - Тип завдання "Змінити тип облікового запису"
                        - Права доступу:
                            * Увімкнути нову версію
                            * Повний доступ
                -->
                <button 
                    type="button" 
                    v-if="
                        checkCompleted == false &&
                        form.status == 'perform' && 
                        form.taskName == 'change_limit_companies' && 
                        (perms[20211] || perms[2000])
                    "
                    @click="showLimitBox = true" 
                    class="btn btn-danger r waves-effect waves-light">
                    {{ $t('ChangeLimit') }} 
                </button>
                <!-- 
                    Якщо задача потребує підтвердження
                    Є первірка:
                        - Статус задачі - в роботі за колл-центром
                        - Той, хто взяв в роботу
                -->
                <button 
                    type="button" 
                    v-if="form.status == 'jobs' && form.inJob.workerId == this.user.userid"
                    @click="showModalConfirm = true"
                    class="btn btn-success r waves-effect waves-light">
                    {{ $t('Confirm') }}
                </button>
                <!-- 
                    Взяти задачу в роботу (коллцентр) 
                    Є перевірка:
                        - Статус:
                            * Очікує підтвердження
                            * Відкладено
                        - Права доступу: 
                            * Повний доступ
                            * Взяття в роботу заявок
                -->
                <button 
                    type="button" 
                    v-if="(form.status == 'wait_confirm' || form.status == 'delayed') && ( perms[2000] || perms[104] )"
                    @click="toWorkCallCenter"
                    class="btn btn-success r waves-effect waves-light">
                    {{ $t('toWork') }}
                </button>
                <!--  
                    Взяття в роботу задаі виконавцем
                    Є перевірка:
                        - Статус:
                            * Задача підтвердження
                            * Задача очікує виконання (прокається, коли задача не потребувала підтвердження з самого початку)
                        - Права доступу:
                            * Взяття завдання в роботу
                            * Повний доступ
                -->
                <button 
                    type="button" 
                    v-if="(form.status == 'confirmed' || form.status == 'wait_completed') && ( perms[2000] || perms[202] )"
                    @click="toWork"
                    class="btn btn-success r waves-effect waves-light">
                    {{ $t('toWork') }}
                </button>

                <!--  
                    Підтвердження виконання завдання
                    Є перевірка:
                        - Права доступу:
                            * Користувач може брати завдання в роботу
                        - Статус:
                            * В роботі за виконавцем
                        - id:
                            * id виконавця співпадає з id юзера
                -->
                <button 
                    type="button" 
                    v-if="perms[202] && form.status == 'perform' && this.form.perform.workerId == this.user.userid"
                    @click="showModalComplete = true"
                    class="btn btn-success r waves-effect waves-light">
                    {{ $t('done') }}
                </button>

                <!-- 
                    Відмовитися від передачі завдання:
                    Є перевірка:
                        - Статус:
                            * Передано
                        - id користувача:
                            * id користувача дорівнює id того, кому передали
                -->
                <button 
                    type="button"
                    v-if="form.status == 'transfered' && form.transfer.workerId == this.user.userid"
                    @click="rejectTransfer()"
                    :class="`btn btn-danger ss waves-effect waves-light`">
                    {{ $t('Refusal') }}
                </button>
                <!-- 
                    Підтвердити передачю завдання:
                    Є перевірка:
                        - Статус:
                            * Передано
                        - id користувача:
                            * id користувача дорівнює id того, кому передали
                -->
                <button 
                    type="button"
                    v-if="form.status == 'transfered' && form.transfer.workerId == this.user.userid"
                    @click="confirmTransfer()"
                    :class="`btn btn-success ss waves-effect waves-light`">
                    {{ $t('ConfirmTransfer') }}
                </button>
                <!-- <button 
                    type="button"
                    v-if="(form.status == 'perform' || form.status == 'closed') && (form.taskName == 'syncmode' || form.taskName == 'multimode')"
                    @click="showMailBox = true"
                    :class="`btn btn-info ss waves-effect waves-light`">
                    {{ $t('sendMail') }}
                </button>  -->
                <!-- виклик mailbox -->
                
        </template>
    </modal>

    <!-- модалка відміни -->
    <modalCancel 
        v-if="showModalCancel == true" 
        @close="showModalCancel = false"
        :id="this.form.id"
        @toCancel="toCancel"
    />
    <!-- модалка відміни -->

    <!-- модалка підтвердження -->
    <modalConfirm 
        v-if="showModalConfirm == true" 
        @close="showModalConfirm = false"
        :id="this.form.id"
        @toConfirm="toConfirm"
    />
    <!-- модалка підтвердження -->

    <!-- модалка додавання рішення -->
    <modalComplete 
        v-if="showModalComplete == true" 
        @close="showModalComplete = false"
        :id="this.form.id"
        :taskName="this.form.taskName"
        @toComplete="toComplete"
    />
    <!-- модалка додавання рішення -->

    <!-- модалка передачі задачі -->
    <modalTransfer 
        v-if="showModalTransfer == true" 
        @close="showModalTransfer = false"
        :id="this.form.id"
        @toTransfer="toTransfer"
    />
    <!-- модалка передачі задачі     -->

    <!-- событие в календарь -->
    <addEvent
        v-if="showAddEvent"
        @close="showAddEvent = false"
        :crItem="crItem"
        @updateCard="updateCard"
    />
    <!-- событие в календарь -->


    <modalDelay
        v-if="showModalDelay == true" 
        @close="showModalDelay = false"
        :id="this.form.id"
        @toDelay="toDelay"
    />

    <!-- надіслати mail -->
    <!-- <modalSM v-if="showMailBox" @close="showMailBox = false">
        <template v-slot:title>E-mail</template>
        <template v-slot:body>
            <b-row>
                <b-col>
                    <b-card-body class="text-center">
                        <span class="fs-16">{{ $t('SendCompletionMail') }} - {{ this.form.userEmail }} ?</span>
                    </b-card-body>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <button type="button" class="btn btn-success" @click="sendEmail">{{ $t('Send') }}</button>
        </template>
    </modalSM> -->
    <!-- надіслати mail -->

    <changeLimitBox 
        v-if="showLimitBox"
        @close="showLimitBox = false"
        :accountId="form.accountId"
        :newLimit="form.descType"
    /> 

    <addRequestOther 
        v-if="showAddReqOth"
        @close="showAddReqOth = false"
        :obj="form"
    />

    <calc 
        v-if="showCalc"
        @close="showCalc = false"
        :first="this.form.companyId"
        :second="this.form.copyOnCompany[0].companyId"
        :obj="this.form"
    />

</template>

<script>
import calc from '../actions/calc.vue'
import transferFund from './blocks/transferFunds.vue'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import modal from '@/components/modal-small'
import modal from '@/components/modal/modal-lg'
import modalSM from '@/components/modal/dialog'
import aboutBlock from './blocks/about'
import createdBlock from './blocks/created'
import clientBlock from './blocks/client'
import confirmedBlock from './blocks/confirmed'
import jobsBlock from './blocks/jobs'
import closedBlock from './blocks/closed'
import jobsCC from './blocks/jobsCC'
import canceledBlock from './blocks/canceled'
import tranferBlock from './blocks/transfer'
import dialogBox from '@/components/modal/dialog'
import modalCancel from '../actions/cancel'
import modalConfirm from '../actions/confirm'
import modalComplete from '../actions/complete'
import modalTransfer from '../actions/transfer.vue'
import addEvent from '@/components/calendar/addEvent'
import clientview from '../../clientnew/index'
import changeLimitBox from "./blocks/changeLimit.vue";
import addRequestOther from "../actions/addRequestOther"
import { Clients, Tasks, SuperAdmin, Patterns } from '@/API.js'
import modalDelay from "../actions/delay.vue"
import { storeS } from "../../../store";
import { mutateStatusTask, mutateTypeTask } from "../../../usabilityScripts/globalMutate";
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js'
import {copy} from '@/usabilityScripts/copy.js'

let apiClients = new Clients();
let apiTasks = new Tasks();
let apiAdmin = new SuperAdmin()
let apiPatterns = new Patterns();

export default ({
    props: ["phone", "shortapplic", 'ifPrevopen', 'ifNextopen', 'closeOne', 'client'],
    components: { 
        modal, 
        aboutBlock,
        createdBlock,
        clientBlock,
        confirmedBlock,
        jobsBlock,
        canceledBlock,
        dialogBox, 
        modalCancel, 
        tranferBlock,
        flatPickr, 
        modalConfirm,
        modalComplete,
        modalTransfer,
        clientview,
        closedBlock,
        addEvent,
        modalDelay,
        jobsCC,
        modalSM,
        changeLimitBox,
        addRequestOther,
        transferFund,
        calc
    },
    data(){
        return {
            showCalc: false,
            showMailBox: false,
            showModalDelay: false,
            showAddEvent: false,
            showLimitBox: false,
            crItem: {
                id: '',
                type: 'task',
                name: '',
                description: '',
                date: '',
                time: ''
            },
            date1:"",
            status:false,
            checked: false,
            modalDialog: false,
            showModalCancel: false,
            showModalConfirm: false,
            showModalComplete: false,
            showModalTransfer: false,
            showModalClient: false,
            dateTimeConfig: {
                enableTime: true,
                dateFormat: "d.m.y H:i",
            },
            ifComp: true,
            comment:"",
            objClient: {},
            form: {
                userPhone: "", //номер клиента
                userEmail: "", //почта клиента
                taskName: "", //тип задачи
                priority: "",
            },
            showModal: false,
            number: "",
            componentKey: '',
            showAddReqOth: false,
            checkCompleted: false,
            isUpdateBlocked: false, // прапорець для блокування
            transferredObj: {
                transferred: false,
                list: []
            }
        }
    },
    created(){
        this.form = this.shortapplic;
        storeS.checkModal.type = 'task';
        storeS.checkModal.id = this.form.id;

    },
    methods: {
        // Копіювати між новими версіями
        copyInNewVersions() {
            
            // Копіювання даних між новими версіями (між аккаунтами)
            // Копіювання даних між новими версіями (в межах облікового запису)

            // Є перевірка:
            //     - Таска не завершена
            //     - Статус завдання "В роботі за виконавцем"
            //     - Тип завдання "
            //         Копіювання даних між новими версіями (між аккаунтами)
            //             або
            //         Копіювання даних між новими версіями (в межах облікового запису)
            //     "
            //     - Права доступу:
            //         * Увімкнути нову версію
            //         * Повний доступ
                
            let obj = {
                newCompanyId: this.shortapplic.copyOnCompany[0].companyId,
                oldCompanyId: this.shortapplic.companyId,
                tradepointIds: this.shortapplic.tradepoints,
            }

            apiAdmin.copyInNewVersions(obj).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('done'))
                    this.updateCard()

                    let completedTasks = JSON.parse(localStorage.getItem('completedTasks')) || [];

                    let newTask = {
                        "taskId": this.form.id,
                        "status": true
                    };

                    completedTasks.push(newTask);
                    this.checkCompleted = true
                    localStorage.setItem('completedTasks', JSON.stringify(completedTasks));
                } else if(result.status == 'error' && result.error[0] == 'newCompanyId not exist'){
                    this.$toast.error(this.$t('Користувач не увійшов в компанію для активації'))

                    var errorText = 'Помилка при копіюванні даних між новими компаніям | Користувач не увійшов в компанію для активації'; 
                    var statusRequest = result.status;
                    var api = 'copyInNewVersions';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = obj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else {
                    this.$toast.error(this.$t('err'));

                    var errorText = 'Помилка при копіюванні даних між новими компаніям'; 
                    var statusRequest = result.status;
                    var api = 'copyInNewVersions';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = obj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        toDelay(a,b) {
            //a = id
            //b = form
            // Блокуємо оновлення через eventBus
            this.isUpdateBlocked = true;
            
            apiTasks.delayTask(a,b).then(result=>{
                if(result.status==='done') {
                    this.$toast.success(this.$t('alertChanged'))
                    if(this.settingCheck['026'] == true) {
                        crItem= {
                            id: a,
                            type: 'task',
                            name: this.mutateType(this.form.taskName, this.form),
                            description: b.comment,
                            date: b.delayedDate,
                            time: b.delayedTime
                        }
                        this.showAddEvent = true
                    } else {
                        this.$emit('close')
                        this.updateCard();
                    }
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка перенесення завдання'; 
                    var statusRequest = result.status;
                    var api = 'delayTask';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = {id: a, form: b};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        edit() {
            this.$emit('edit', this.form)
        },
        closeModal() {
            //закрытие задачи
            storeS.checkModal.type = '';
            storeS.checkModal.id = '';
            this.$emit('close');
        },
        prevOpen(){
            //пред задача
            this.$emit('close');
            this.$emit('prevOpen', this.form.id)
        },
        nextOpen(){
            //след задача
            this.$emit('close');
            this.$emit('nextOpen', this.form.id)
        },
        getClient(number){
            //открыть карточку клиента
            
            this.number = number
            this.eventBus.emit('getClient', {status: true, number: this.number})
            this.$emit('close')
        },
        // resetPass(){
        //     //сбросить пароль старое апи
        //     apiClients.reset_passwordClient(this.form.user_login).then(res => {
        //         if(res.status == "done"){
        //             this.$toast.success(this.$t('resetPasswordDone'));
        //         }else {
        //             this.$toast.error(this.$t('err'));
        //         }
        //     })
        // },
        newResetPass(){
            //скинути пароль
            apiClients.new_reset_passwordClient(this.form.accountId).then(res => {
                if(res.status == "done"){
                    this.$toast.success(this.$t('resetPasswordDone'));
                    let completedTasks = JSON.parse(localStorage.getItem('completedTasks')) || [];

                    let newTask = {
                        "taskId": this.form.id,
                        "status": true
                    };

                    completedTasks.push(newTask);
                    this.checkCompleted = true
                    localStorage.setItem('completedTasks', JSON.stringify(completedTasks));
                }else {
                    this.$toast.error(this.$t('err'));

                    var errorText = 'Помилка при скидувані паролю'; 
                    var statusRequest = result.status;
                    var api = 'new_reset_passwordClient';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = this.form.accountId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        // sendEmail(){
        //     switch (this.form.taskName) {
        //         case 'syncmode':
        //             var objMail = {
        //                 userPhone: this.form.userPhone,
        //                 userEmail: this.form.userEmail,
        //                 groupId: 28,
        //                 lang: "UA",
        //                 text: ''
        //             };
        //             break;
        //         case 'multimode':
        //             var objMail = {
        //                 userPhone: this.form.userPhone,
        //                 userEmail: this.form.userEmail,
        //                 groupId: 29,
        //                 lang: "UA",
        //                 text: ''
        //             };
        //             break;
            
        //         default:
        //             break;
        //     }

        //     apiPatterns.sendEmail(objMail).then(result=>{ 
        //         if(result.status === 'done') {
        //             this.$toast.success(this.$t('success'));
        //             this.closeModal();
        //         } else {
        //             this.$toast.error(this.$t('error') + " " + result.error[0]);
        //         }
        //     })
        // },
        toConfirm(id, e, checkDate){
            //подтвердить задачу

            apiTasks.confirmTask(id, e).then(result => {
                if(result.status == 'done'){
                    // this.updateCard();
                    
                    if(this.settingCheck['026']==true && checkDate == true) {
                        this.crItem.id = id;
                        this.crItem.date = e.confirmDate
                        this.crItem.time = e.confirmTime
                        this.crItem.name = this.mutateType(this.form.taskName, this.form);
                        this.crItem.description = e.comment;
                        this.showAddEvent = true;
                    } else {   
                        this.$toast.success(this.$t('Confirmed'))
                        // this.$emit('close')
                        // this.updateCard();
                    }
                } else {
                    this.$toast.error(this.$t('error') + ' #202');

                    var errorText = 'Помилка при підтверджені таски'; 
                    var statusRequest = result.status;
                    var api = 'confirmTask';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = {id: id, form: e};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },  
        toComplete(id, e){
            //выполнить задачу

            // Блокуємо оновлення через eventBus
            this.isUpdateBlocked = true;
            
            apiTasks.completeTask(id, e).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('performedT'));
                    // this.updateCard();
                    // this.sendEmail();
                    this.closeModal();
                    // if(this.form.taskName == 'syncmode' || this.form.taskName == 'multimode'){
                    //     this.showMailBox = true;
                    // } else {
                    //     this.closeModal();
                    // }   
                } else {
                    this.$toast.error(this.$t('error') + ' #202');

                    var errorText = 'Помилка при завершені таски'; 
                    var statusRequest = result.status;
                    var api = 'completeTask';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = {id: id, form: e};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        toTransfer(id, workerId, e){
            //передать задачу

            apiTasks.transferTask(id, workerId, e).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('transfered'))
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + ' #202');

                    var errorText = 'Помилка при передачі таски'; 
                    var statusRequest = result.status;
                    var api = 'transferTask';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = {id: id, form: e, workerId: workerId};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        confirmTransfer(){
            apiTasks.fixationTransferTask(this.form.id, this.form.transfer.workerId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('threw'))
                    this.$emit('close') //фікасація отримання задачі
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка при підтверджені передачі таски'; 
                    var statusRequest = result.status;
                    var api = 'fixationTransferTask';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = {id: this.form.id, workerId: this.form.transfer.workerId};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        rejectTransfer(){
            apiTasks.rejectTransferTask(this.form.id).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('didn`t accept'))
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка при відхилені передачі таски'; 
                    var statusRequest = result.status;
                    var api = 'rejectTransferTask';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = this.form.id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        updateCard(){
            //обновить данные
            apiTasks.getTask(this.form.id).then(result => {
                if(result.status == 'done'){
                    this.form = result.data;
                    this.componentKey += 1
                    // Розблоковуємо оновлення через eventBus
                    this.isUpdateBlocked = false;
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка при отримані таски'; 
                    var statusRequest = result.status;
                    var api = 'getTask';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = this.form.id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        toWorkCallCenter(){
            //взять в работу задачу

            // Блокуємо оновлення через eventBus
            this.isUpdateBlocked = true;

            apiTasks.addTaskInJobCallCenter(this.form.id).then(result => {
                if(result.status == 'done'){
                    this.updateCard();
                } else {
                    this.$toast.error(this.$t('error') + ' #202');

                    var errorText = 'Помилка при взяття таски в роботу колцентром'; 
                    var statusRequest = result.status;
                    var api = 'addTaskInJobCallCenter';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = this.form.id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        toWork(){
            //взять в работу задачу

            // Блокуємо оновлення через eventBus
            this.isUpdateBlocked = true;

            apiTasks.addTaskInJob(this.form.id).then(result => {
                if(result.status == 'done'){
                    this.updateCard();
                } else {
                    this.$toast.error(this.$t('error') + ' #202');

                    var errorText = 'Помилка при взяття таски в роботу виконавцем'; 
                    var statusRequest = result.status;
                    var api = 'addTaskInJob';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = this.form.id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        toCancel: function(id, e){
            //отменить задачу
            
            // Блокуємо оновлення через eventBus
            this.isUpdateBlocked = true;

            apiTasks.cancelTask(id, e).then(result => {
                if(result.status == 'done'){
                    this.closeModal();
                    this.$toast.success(this.$t('canceled'));
                    // this.eventBus.emit('saveTask', true)
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка при cкасуванні таски'; 
                    var statusRequest = result.status;
                    var api = 'cancelTask';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = {id: id, form: e};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        createSkyNewForClient(){
            apiAdmin.createSkyNewForClient(this.form.accountId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('done'))
                    let completedTasks = JSON.parse(localStorage.getItem('completedTasks')) || [];

                    let newTask = {
                        "taskId": this.form.id,
                        "status": true
                    };

                    completedTasks.push(newTask);
                    this.checkCompleted = true
                    localStorage.setItem('completedTasks', JSON.stringify(completedTasks));
                } else if(result.status == 'error' && result.error[0] == 'account not found'){
                    this.$toast.error(this.$t('AccountNotFound'))

                    var errorText = 'Помилка при створені компанії нової-адмінки таски'; 
                    var statusRequest = result.status;
                    var api = 'createSkyNewForClient';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = this.form.accountId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'enter account id'){
                    this.$toast.error(this.$t('EnterYourAccount'))

                    var errorText = 'Помилка при створені компанії нової-адмінки таски'; 
                    var statusRequest = result.status;
                    var api = 'createSkyNewForClient';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = this.form.accountId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка при створені компанії нової-адмінки таски'; 
                    var statusRequest = result.status;
                    var api = 'createSkyNewForClient';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = this.form.accountId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        createAndTransferDataSkyNew(){
            // створити нову компанію з новою адмінкою клієнту (з копіюванням даних зі старого скаю)
            var newObj = {
                "accountId": this.form.accountId,
                "oldCompanyId": this.form.companyId,
                "tradepointId": this.form.tradepoints.length > 0 ? this.form.tradepoints[0] : ""
            }

            apiAdmin.createAndTransferDataSkyNew(newObj).then(result => {
                if(result.status == 'done'){

                    if(result.data?.transferred == true || result.data == null ) {
                        this.$toast.success(this.$t('done'))
                        let completedTasks = JSON.parse(localStorage.getItem('completedTasks')) || [];

                        // відображаємо які позиції не скопіювались, якщо такі є
                        if(result.data?.techcardsWithoutRecipe) {
                            this.transferredObj.transferred = result.data.transferred;
                            this.transferredObj.list = result.data.techcardsWithoutRecipe

                            // Форматування даних у вигляді списку
                            const formattedList = this.transferredObj.list.map(item => `- ${item.nomenclatureName}`).join('\n');

                            var errorText = 'Не скопіювались позиції по завданню #'+this.form.id + " (через відсутність складу тех. карти)"; 
                            var statusRequest = '-';
                            var api = '-';
                            var fileRequest = '-';
                            var params = newObj;
                            var response = formattedList

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error)); 

                        }

                        let newTask = {
                            "taskId": this.form.id,
                            "status": true,
                            "transferredObj": this.transferredObj
                        };

                        completedTasks.push(newTask);
                        this.checkCompleted = true
                        localStorage.setItem('completedTasks', JSON.stringify(completedTasks));
                    } else {
                        this.$toast.error(this.$t('CopyingNotOccur'))

                        var errorText = 'Помилка при створені компанії нової-адмінки з копіюванням зі статусом Done'; 
                        var statusRequest = result.status;
                        var api = 'createAndTransferDataSkyNew';
                        var fileRequest = 'src/views/tasks/view/viewtask.vue';
                        var params = newObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    }

                } else if(result.status == 'error' && result.error[0] == 'account not found'){
                    this.$toast.error(this.$t('AccountNotFound'))

                    var errorText = 'Помилка при створені компанії нової-адмінки з копіюванням даних'; 
                    var statusRequest = result.status;
                    var api = 'createAndTransferDataSkyNew';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'enter account id'){
                    this.$toast.error(this.$t('EnterYourAccount'))

                    var errorText = 'Помилка при створені компанії нової-адмінки з копіюванням даних'; 
                    var statusRequest = result.status;
                    var api = 'createAndTransferDataSkyNew';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка при створені компанії нової-адмінки з копіюванням даних'; 
                    var statusRequest = result.status;
                    var api = 'createAndTransferDataSkyNew';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        transDataFromOldCompany(){
            // скопіювати дані з старої адмінки в нову
            var newObj = {
                "oldCompanyId": this.form.companyId,
                "newCompanyId": this.form.copyOnCompany[0].companyId,
                "tradepointId": this.form.tradepoints.length > 0 ? this.form.tradepoints[0] : ""
            }

            apiAdmin.transDataFromOldCompany(newObj).then(result => {
                if(result.status == 'done'){

                    if(result.data?.transferred == true) {
                        this.$toast.success(this.$t('done'))
                        
                        let completedTasks = JSON.parse(localStorage.getItem('completedTasks')) || [];

                        // відображаємо які позиції не скопіювались, якщо такі є
                        if(result.data?.techcardsWithoutRecipe) {
                            this.transferredObj.transferred = result.data.transferred;
                            this.transferredObj.list = result.data.techcardsWithoutRecipe

                            // Форматування даних у вигляді списку
                            const formattedList = this.transferredObj.list.map(item => `- ${item.nomenclatureName}`).join('\n');

                            var errorText = 'Не скопіювались позиції по завданню #'+this.form.id + " (через відсутність складу тех. карти)"; 
                            var statusRequest = '-';
                            var api = '-';
                            var fileRequest = '-';
                            var params = newObj;
                            var response = formattedList

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error)); 
                        }

                        let newTask = {
                            "taskId": this.form.id,
                            "status": true,
                            "transferredObj": this.transferredObj
                        };

                        completedTasks.push(newTask);
                        this.checkCompleted = true
                        localStorage.setItem('completedTasks', JSON.stringify(completedTasks));
                    } else {
                        this.$toast.error(this.$t('CopyingNotOccur'))

                        var errorText = 'Помилка при копіюванні даних з статусом Done'; 
                        var statusRequest = result.status;
                        var api = 'transDataFromOldCompany';
                        var fileRequest = 'src/views/tasks/view/viewtask.vue';
                        var params = newObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    }
                    
                } else if(result.status == 'error' && result.error[0] == 'account not found'){
                    this.$toast.error(this.$t('AccountNotFound'))

                    var errorText = 'Помилка при копіюванні даних'; 
                    var statusRequest = result.status;
                    var api = 'transDataFromOldCompany';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'enter account id'){
                    this.$toast.error(this.$t('EnterYourAccount'))

                    var errorText = 'Помилка при копіюванні даних'; 
                    var statusRequest = result.status;
                    var api = 'transDataFromOldCompany';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'enter data'){
                    this.$toast.error(this.$t('enterData'))

                    var errorText = 'Помилка при копіюванні даних'; 
                    var statusRequest = result.status;
                    var api = 'transDataFromOldCompany';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'access denied'){
                    this.$toast.error(this.$t('accessDenied'))

                    var errorText = 'Помилка при копіюванні даних'; 
                    var statusRequest = result.status;
                    var api = 'transDataFromOldCompany';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка при копіюванні даних'; 
                    var statusRequest = result.status;
                    var api = 'transDataFromOldCompany';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        editUserType(){
            var objEdit = {
                accountType: "owner",
                masterPassword: "1514"
            }
            apiAdmin.editUserType(this.form.accountId, objEdit).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('done'))
                    let completedTasks = JSON.parse(localStorage.getItem('completedTasks')) || [];

                    let newTask = {
                        "taskId": this.form.id,
                        "status": true
                    };

                    completedTasks.push(newTask);
                    this.checkCompleted = true
                    localStorage.setItem('completedTasks', JSON.stringify(completedTasks));
                } else if(result.status == 'error' && result.error[0] == 'account not found'){
                    this.$toast.error(this.$t('AccountNotFound'))

                    var errorText = 'Помилка при зміні типу акаунту'; 
                    var statusRequest = result.status;
                    var api = 'editUserType';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = objEdit;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'enter account id'){
                    this.$toast.error(this.$t('EnterYourAccount'))

                    var errorText = 'Помилка при зміні типу акаунту'; 
                    var statusRequest = result.status;
                    var api = 'editUserType';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = objEdit;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'wrong account type'){
                    this.$toast.error(this.$t('wrongAccountType'))

                    var errorText = 'Помилка при зміні типу акаунту'; 
                    var statusRequest = result.status;
                    var api = 'editUserType';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = objEdit;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка при зміні типу акаунту'; 
                    var statusRequest = result.status;
                    var api = 'editUserType';
                    var fileRequest = 'src/views/tasks/view/viewtask.vue';
                    var params = objEdit;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        switchPerformTask() {
            switch (this.form.taskName) {
                case 'trans_data_from_old_company':
                    this.transDataFromOldCompany();
                    break;
                case 'trans_data_from_old_company_franchise':
                    this.transDataFromOldCompany();
                    break;
                case 'enable_new_version':
                    if (this.form.tradepoints.length > 0 && this.form.tradepoints[0] != null) {
                        this.createAndTransferDataSkyNew();
                    } else {
                        this.createSkyNewForClient();
                    }
                    break;
                case 'change_account_type':
                    this.editUserType();
                    break;
                case 'discharge_password':
                    this.newResetPass();
                    break;
                case 'trans_data_from_newServer_company_franchise':
                    this.copyInNewVersions()
                    break
                case 'trans_data_from_newServer_company':
                    this.copyInNewVersions();
                    break
                default:
                    break;
            }
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        copyList(){
            // Форматування даних у вигляді списку
            const formattedList = this.transferredObj.list.map(item => `- ${item.nomenclatureName}`).join('\n');

            const h = this.form.userPhone + "\n\nНе скопійовані тех. карти по завданню #"+this.form.id + " (через відсутність складу тех. карти) :";

            const text = h + '\n' + formattedList
            // Копіювання у буфер обміну
            navigator.clipboard.writeText(text).then(() => {
                this.$toast.success(this.$t('Copied'));
            }).catch(err => {
                this.$toast.error(`Fucking mistake, зверніться до Бориса :)`);
            });
        },
        mutateType(e, obj){
            return mutateTypeTask(e, obj)
        },
        mutatestatus(e, obj){
            return mutateStatusTask(e, obj)
        },
    },
    mounted() {
        this.eventBus.on('changeOnReady', status => {
            if(status === true) {
                this.checkCompleted = true
            }
        })

        this.comment = JSON.parse(localStorage.getItem("check_comment"+this.form.id)) || []

        this.eventBus.off('updateTask');
        this.eventBus.on('updateTask', (status) => {
            if (status == true && !this.isUpdateBlocked) {
                this.updateCard();
            }
        })

        // Отримайте поточний масив виконаних завдань з localStorage
        let completedTasks = JSON.parse(localStorage.getItem('completedTasks')) || [];

        // id, яке ви шукаєте
        let taskIdToCheck = this.form.id;

        // Перевірте, чи існує id в масиві
        let isIdPresent = completedTasks.some(task => task.taskId === taskIdToCheck);

        // isIdPresent має значення true, якщо id присутнє в масиві
        if (isIdPresent) {
            console.log(`Завдання з id ${taskIdToCheck} вже виконано.`);
            this.checkCompleted = true

            // Дістаємо дані про не скопійовані позиції
            completedTasks.forEach(element => {
                if(element.taskId == taskIdToCheck){
                    if(element.transferredObj){
                        this.transferredObj = element.transferredObj;
                    }
                }
            });
            
            // this.transferredObj = 
        } else {
            console.log(`Завдання з id ${taskIdToCheck} ще не виконано.`);
        }
    },
    watch: {
        comment(newValue, oldValue) {
            localStorage.setItem("check_comment"+this.form.id, JSON.stringify(newValue));
        }
    },
    computed:{
        perms() {
            return storeS.perms
        },
        settingCheck() {
            return storeS.checks
        },
        user() {
            return storeS.userbase
        },
        // modals() {
        //     return storeS.modalsOpen
        // }
    }
})
</script>

<style scoped>
b.headAlert {
    padding: 5px;
    margin-right: 10px;
    border-radius: 5px;
}
.copybtn {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity .3s;
}
.c_block:hover .copybtn {
    opacity: 1;
    transition: opacity .3s;
}

.copybtn:checked {
    width: 500px;
}
</style>
