<template>
    <b-row>
        <b-col lg="6" v-for="item in form" :key="item">
            <b-card>
                <div class="monobank-card" >
                    <div class="monobank-card-image-block" >
                        <div>
                            <img src="https://online.skyservice.pro/img/apps/privatbank/privat_white-card-blank_min.png" class="monobank-card-image" style="width: 100%">
                            <div class="monobank-card-number copy" @click="copy(item.app_data.cardData.cardNumber)">{{item.app_data.cardData.cardNumber}} </div>
                        </div>
                    </div>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {copy} from '@/usabilityScripts/copy.js'

export default ({
    props: ['obj'],
    components: {  },
    data() {
        return{
            form: "",
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    }
})
</script>

<style scoped>
.black-card .monobank-card-number {
    color: #fff;
}
.monobank-card-number {
    position: absolute;
    letter-spacing: 5px;
    font-size: 13px;
    color: #000;
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
}
</style>