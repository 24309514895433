<template>
    <modal :title="$t('Dashboard')" @close="close" :stylebody="'null'"> 
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')" :title="$t('close')"><i class="ri-close-fill align-bottom"></i></button>
            <span>{{  }}</span>
        </template>
        <template v-slot:body>
                <div class="d-flex">
                    <div class="menuResp">
                        <div class="app-menu navbar-menu dashMenu">
                            <div class="navbar-brand-box">
                                <div class="navbar-nav menuScroll" style="height: 100vh;">
                                    <template v-for="(option, i) in leftMenu" :key="i">
                                        <template v-if="option.children">
                                            <li class="nav-item copy">
                                                <a class="nav-link menu-link collapsed tabmenu" :href="option.children ? '#'+option.tabName : undefined" data-bs-toggle="collapse" role="button" :aria-expanded="checkArrow(option)" aria-controls="sidebarDashboards" >
                                                    <i :class="option.icon" ></i>
                                                    <span>{{ option.name }}</span>
                                                </a>
                                            </li>
                                            <div :class="`menu-dropdown collapse`" :id="option.children ? option.tabName : undefined">
                                                <template v-for="(suboption, j) in option.children" :key="j">
                                                    <ul :class="`nav nav-sm flex-column tabmenu ${selectTab == suboption.tabName ? 'activeTab' : ''}`" style="overflow: hidden">
                                                        <li class="nav-item" @click="getSection(suboption.tabName)" >
                                                            <a :class="`nav-link menu-link `">
                                                                {{ suboption.name }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </template>
                                            </div>
                                        </template>
                                        <template v-if="!option.children">
                                            <li class="nav-item copy" :key="i" @click="getSection(option.tabName)">
                                                <a :class="`nav-link menu-link tabmenu ${selectTab == option.tabName ? 'activeTab' : ''}`">
                                                    <i :class="option.icon" ></i>
                                                    {{ option.name }}
                                                </a> 
                                            </li>
                                        </template>
                                    </template>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <b-card>
                            <div class="d-flex">
                                <button 
                                    class="avatar-xs badge-soft-secondary fs-15 rounded menuShow" 
                                    style="border: none; margin-right: 10px;"
                                    data-bs-toggle="offcanvas"
                                    :data-bs-target="'#offcanvasLeftSkyMenu'"
                                    aria-controls="offcanvasLeft"
                                    ><i class="ri-apps-2-line"></i>
                                </button>
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb" style="margin-bottom: 0rem!important;">
                                        <li class="breadcrumb-item"><a href="#" @click="comeToMain()"><i class="ri-home-5-fill"></i></a></li>
                                        <template v-for="item in breadcrumb" :key="item">
                                            <li class="breadcrumb-item active" aria-current="page">{{ item }}</li>
                                        </template>
                                    </ol>
                                </nav>
                            </div>
                            <div
                                class="offcanvas offcanvas-start d-lg-none"
                                tabindex="-1"
                                :id="'offcanvasLeftSkyMenu'"
                                aria-labelledby="offcanvasLeftLabel"
                                style="width: 251px;border-color: #212222;"
                            >
                                <div class="offcanvas-body p-0 overflow">
                                    <div class="app-menu navbar-menu dashMenu" style="border-radius: 0%;">
                                        <div class="navbar-brand-box">
                                            <div class="navbar-nav menuScroll" >
                                                <template v-for="(option, i) in leftMenu" :key="i">
                                                    <template v-if="option.children">
                                                        <li class="nav-item copy">
                                                            <a class="nav-link menu-link collapsed" :href="option.children ? '#'+option.name : undefined" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards" >
                                                            <span>{{ option.name }}</span>
                                                            </a>
                                                        </li>
                                                        <div class="menu-dropdown collapse" :id="option.children ? option.name : undefined">
                                                            <template v-for="(suboption, j) in option.children" :key="j">
                                                                <ul class="nav nav-sm flex-column tabmenu" style="overflow: hidden">
                                                                    <li class="nav-item" @click="getSection(suboption.tabName)" >
                                                                        <a class="nav-link menu-link">
                                                                            {{ suboption.name }}
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </template>
                                                        </div>
                                                    </template>
                                                    <template v-if="!option.children">
                                                        <li class="nav-item copy" :key="i">
                                                            <a class="nav-link menu-link">
                                                                {{ option.name }}
                                                            </a>
                                                        </li>
                                                    </template>
                                                </template>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card>

                        <mainTab v-if="selectTab == 'main'" :menuTabs="leftMenu" @changeTab="getSection" />

                        <stockTab v-if="selectTab == 'stock'" :obj="dataObj.marketing.stock" :companyId="obj.companyId" />

                        <discountTab v-if="selectTab == 'discount'" :obj="dataObj.marketing.discount" :companyId="obj.companyId" />

                        <bonusTab v-if="selectTab == 'bonusprograms'" :obj="dataObj.marketing.bonus" :companyId="obj.companyId" />

                        <outletsTab v-if="selectTab == 'outlets'" :obj="dataObj.tradepoints" :companyId="obj.companyId" />

                        <taxTab v-if="selectTab == 'taxes'" :obj="dataObj.taxes" :companyId="obj.companyId" />

                        <terminalsTab v-if="selectTab == 'terminals'" :obj="dataObj.terminals" :objTradepoints="dataObj.tradepoints" :companyId="obj.companyId" />

                        <workersTab v-if="selectTab == 'workers'" :obj="dataObj.workers" :companyId="obj.companyId" />

                        <devicesTab v-if="selectTab == 'devices'" :obj="dataObj.devices" :companyId="obj.companyId" :objTerminals="dataObj.terminals" :objTradepoints="dataObj.tradepoints" />

                    </div>

            </div>
        </template>
    </modal>
</template>


<script>
import modal from '@/components/modal'
import mainTab from './main/index'
import stockTab from './marketing/stock/index'
import discountTab from './marketing/discount/index'
import bonusTab from './marketing/bonus/index'
import outletsTab from './settings/tradepoints/index'
import taxTab from './settings/tax/index'
import terminalsTab from './settings/terminals/index'
import workersTab from './settings/workers/index'
import devicesTab from './settings/devices/index'

export default{
    props: ['obj'],
    components: {
        modal,
        mainTab,
        stockTab,
        discountTab,
        bonusTab,
        outletsTab,
        taxTab,
        terminalsTab,
        workersTab,
        devicesTab
    },
    data(){
        return{
            form: '',
            breadcrumb: [],
            dataObj: '',
            selectTab: 'main',
            leftMenu: [
                {
                    name: this.$t('startPage'),
                    icon: 'ri-apps-2-line',
                    tabName: 'main',
                    perms: []
                },
                {
                    name: this.$t('Marketing'),
                    icon: 'ri-group-fill',
                    tabName: 'marketing',
                    perms: [],
                    color: 'warning',
                    show: false,
                    children: [
                        {
                            name: this.$t('StockAk'),
                            tabName: 'stock',
                            icon: 'bx bxs-discount'
                        },
                        {
                            name: this.$t('Discounts'),
                            tabName: 'discount',
                            icon: 'bx bxs-discount'
                        },
                        {
                            name: this.$t('BonusPrograms'),
                            tabName: 'bonusprograms',
                            icon: 'bx bxs-discount'
                        },
                    ]
                },
                {
                    name: this.$t('Settings'),
                    icon: 'bx bxs-wrench',
                    tabName: 'settings',
                    perms: [],
                    color: 'danger',
                    show: false,
                    children: [
                        {
                            name: this.$t('Outlets'),
                            tabName: 'outlets',
                            icon: 'bx bx-restaurant'
                        },
                        {
                            name: this.$t('Taxes'),
                            tabName: 'taxes',
                            icon: 'bx bxs-bank'
                        },
                        {
                            name: this.$t('cash-desk'),
                            tabName: 'terminals',
                            icon: 'bx bxs-wrench',
                        },
                        {
                            name: this.$t('devices'),
                            tabName: 'devices',
                            icon: 'ri-device-fill'
                        },
                        {
                            name: this.$t('Employees'),
                            tabName: 'workers',
                            icon: 'ri-team-fill'
                        },
                    ]
                },
                {
                    name: this.$t('Products'),
                    icon: 'bx bx-shopping-bag',
                    tabName: 'products',
                    perms: [],
                    color: 'success',
                    show: false,
                    children: [
                        {
                            name: this.$t('allProducts'),
                            tabName: 'allProducts',
                            icon: 'ri-store-line'
                        },
                        {
                            name: this.$t('warehouseProducts'),
                            tabName: 'warehouseProducts',
                            icon: 'ri-dropbox-line'
                        },
                        {
                            name: this.$t('Ingredients'),
                            tabName: 'Ingredients',
                            icon: 'ri-cake-3-line'
                        },
                        {
                            name: this.$t('cookCards'),
                            tabName: 'cookCards',
                            icon: 'ri-file-list-line'
                        },
                        {
                            name: this.$t('semiFinishedProducts'),
                            tabName: 'semiFinishedProducts',
                            icon: 'ri-fridge-line'
                        },
                        {
                            name: this.$t('adds'),
                            tabName: 'adds',
                            icon: 'ri-increase-decrease-line'
                        },
                        {
                            name: this.$t('leftsOnWarheouse'),
                            tabName: 'leftsOnWarheouse',
                            icon: 'ri-stock-fill'
                        },
                    ]
                }
            ]
        }
    },
    created(){
        this.dataObj = this.obj.companyDataObj
        console.log('obj', this);
    },
    methods: {
        checkArrow(e){
            console.log(e, this.breadcrumb)
            if(this.breadcrumb.find(el => el == e.name)) {
                return true
            } else {
                if(document.getElementById(e.tabName)){
                    document.getElementById(e.tabName).classList.remove('show')
                    return false
                }
            }
        },
        comeToMain(){
            this.selectTab = 'main'; 
            this.breadcrumb = []
            this.checkShow()
        },
        getSection(e){
            console.log('e', e);
            this.breadcrumb = [];
            switch (e) {
                case 'main':
                    this.breadcrumb.push(this.$t('startPage'))
                    this.selectTab = e
                    break;
                case 'stock':
                    this.breadcrumb.push(this.$t('Marketing'))
                    this.breadcrumb.push(this.$t('StockAk'))
                    this.leftMenu[1].show = true
                    this.selectTab = e
                    break;
                case 'discount':
                    this.breadcrumb.push(this.$t('Marketing'))
                    this.breadcrumb.push(this.$t('Discounts'))
                    this.selectTab = e
                    break;
                case 'bonusprograms':
                    this.breadcrumb.push(this.$t('Marketing'))
                    this.breadcrumb.push(this.$t('BonusPrograms'))
                    this.selectTab = e
                    break;
                case 'outlets':
                    this.breadcrumb.push(this.$t('Settings'))
                    this.breadcrumb.push(this.$t('Outlets'))
                    this.selectTab = e
                    break;
                case 'taxes':
                    this.breadcrumb.push(this.$t('Settings'))
                    this.breadcrumb.push(this.$t('Taxes'))
                    this.selectTab = e
                    break;
                case 'terminals':
                    this.breadcrumb.push(this.$t('Settings'))
                    this.breadcrumb.push(this.$t('cash-desk'))
                    this.selectTab = e
                    break;
                case 'workers':
                    this.breadcrumb.push(this.$t('Settings'))
                    this.breadcrumb.push(this.$t('Employees'))
                    this.selectTab = e
                    break;
                case 'devices':
                    this.breadcrumb.push(this.$t('Settings'))
                    this.breadcrumb.push(this.$t('devices'))
                    this.selectTab = e
                    break;
                default:
                    break;
            }
            this.checkShow();
        },
        onRoutechange(ele) {
            this.initActiveMenu(ele);
            if (document.getElementsByClassName("mm-active").length > 0) {
                const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
                if (currentPosition > 500)
                if (this.$refs.isSimplebar)
                    this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
            }
        },
        checkShow(){
            var check = '';
            var parent = '';
            this.leftMenu.forEach(element => {
                if(element.children){
                    element.children.forEach(ch => {
                        if(ch.tabName == this.selectTab){
                            check = true
                            console.log('ch', ch);
                            parent = element.tabName
                        }
                    });
                }
            });
            console.log('parent', parent);
            if(parent){
                document.getElementById(parent).classList.add('show')
            } 
            
        }
    },
    mounted(){
        if (document.querySelectorAll(".navbar-nav .collapse")) {
        let collapses = document.querySelectorAll(".navbar-nav .collapse");

        collapses.forEach((collapse) => {
            // Hide sibling collapses on `show.bs.collapse`
            collapse.addEventListener("show.bs.collapse", (e) => {
            e.stopPropagation();
            let closestCollapse = collapse.parentElement.closest(".collapse");
            if (closestCollapse) {
                let siblingCollapses =
                closestCollapse.querySelectorAll(".collapse");
                siblingCollapses.forEach((siblingCollapse) => {
                if (siblingCollapse.classList.contains("show")) {
                    siblingCollapse.classList.remove("show");
                }
                });
            } else {
                let getSiblings = (elem) => {
                // Setup siblings array and get the first sibling
                let siblings = [];
                let sibling = elem.parentNode.firstChild;
                // Loop through each sibling and push to the array
                while (sibling) {
                    if (sibling.nodeType === 1 && sibling !== elem) {
                    siblings.push(sibling);
                    }
                    sibling = sibling.nextSibling;
                }
                return siblings;
                };
                let siblings = getSiblings(collapse.parentElement);
                siblings.forEach((item) => {
                if (item.childNodes.length > 2)
                    item.firstElementChild.setAttribute("aria-expanded", "false");
                let ids = item.querySelectorAll("*[id]");
                ids.forEach((item1) => {
                    item1.classList.remove("show");
                    if (item1.childNodes.length > 2) {
                    let val = item1.querySelectorAll("ul li a");

                    val.forEach((subitem) => {
                        if (subitem.hasAttribute("aria-expanded"))
                        subitem.setAttribute("aria-expanded", "false");
                    });
                    }
                });
                });
            }
            });

            // Hide nested collapses on `hide.bs.collapse`
            collapse.addEventListener("hide.bs.collapse", (e) => {
            e.stopPropagation();
            let childCollapses = collapse.querySelectorAll(".collapse");
            childCollapses.forEach((childCollapse) => {
                let childCollapseInstance = childCollapse;
                childCollapseInstance.style.display = "none";
            });
            });
        });
        }
    },
}
</script>

<style>
.dashMenu {
    height: 100%;
    position: relative!important;
    border-radius: 15px;
    padding-top: 30px!important;
}
.activeTab {
    background: #009688;
    border-radius: 15px;
    cursor: pointer;
}
.tabmenu{
    padding: 0.625rem 0.5rem!important;
}
ul.nav.nav-sm.flex-column.tabmenu:hover, a.nav-link.menu-link.tabmenu:hover {
    background: #009688;
    border-radius: 15px;
    cursor: pointer;
    transition: .3s;
    opacity: 1;
}
.menuShow {
    display: none;
}
@media screen and (max-width: 991px) {
    .menuResp {
        display: none;
    }
    .menuShow {
        display: block;
    }
}
</style>