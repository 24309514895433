<template>
    <!-- диалоговое окно -->
    <dialogBox @close="this.$emit('close')">
        <template v-slot:title>{{ $t('choose_your_slaves') }}</template>
        <template v-slot:body>
            <div class="mb-3 text-center fs-16 fw-semibold" >
                {{$t('recruit_test_subjects')}}?
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-danger fw-medium" @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('independently') }} </button>
            <button 
                type="button" 
                class="btn btn-success" 
                @click="select()"
            >
                {{ $t('choose_slaves')  }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { Projects } from '@/API'
import { storeS } from '@/store'

let apiServe = new Projects();

export default {
    props: ['id', 'checkTask'],
    components: {
        dialogBox
    },
    data(){
        return {
            form: {
                comment: "",
            },
        }
    },
    methods: {
        toDec(){
            apiServe.completeProject(this.id, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(`done`);
                    this.eventBus.emit('UpdateCard', true)
                    this.$emit('close')
                } else if(result.status == 'error'){
                    this.$toast.error(result.error[0]);
                }
            })
        }
    }
}
</script>