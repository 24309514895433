<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="type-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('type') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radionomenclatureName" id="all_nomenclatureName" v-model="nomenclatureName" :value="''">
                    <label class="form-check-label" for="all_nomenclatureName">{{ $t('all') }}</label>
                </div>
            </div>
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radionomenclatureName" :id="'nomenclatureName'+index" v-model="nomenclatureName" :value="value.value">
                        <label class="form-check-label" :for="'nomenclatureName'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { storeS } from '@/store';

export default {
    data(){
        return {
            nomenclatureName: "",
            opt: [
                {
                    name: this.$t('prro_setting'),
                    value: "prro_setting",
                },
                {
                    name: this.$t('data_recovery'),
                    value: "data_recovery",
                },
                {
                    name: this.$t('connecting_printer'),
                    value: "connecting_printer",
                },
                {
                    name: this.$t('terminal_connection'),
                    value: "terminal_connection",
                },
                {
                    name: this.$t('scale_connection'),
                    value: "scale_connection",
                },
                {
                    name: this.$t('rro_connection'),
                    value: "rro_connection",
                },
            ]
        }
    },
    methods: {
        change(){
            this.$emit('change', 'nomenclatureName', this.nomenclatureName)
        },
    },
    computed: {

    }
}
</script>