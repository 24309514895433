<template>
    <modal :key="componentKey" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @close="this.$emit('close')">
        <template v-slot:title>
            {{ this.$t('monitoringLabel') }} #{{ this.form.monitoringId }}
        </template>
        <template v-slot:status></template>
        <template v-slot:head-button>
            <button class="btn btn-success" type="button" @click="this.showCommentBox = true">
                {{ $t('addCom') }}
            </button>
            <div class="dropdown">
                <!-- Кнопка зі стрілкою для дій з уроком -->
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <!-- Випадаюче меню з діями для уроку -->
                <div class="dropdown-menu dropdown-menu-end">
                    <!-- Редагувати, якщо нема призначенних співробітників -->
                    <button 
                        v-if="this.form.assignedWorkers.length === 0"
                        class="dropdown-item" 
                        @click="this.$emit('edit', this.form)"
                    >{{ $t('Edit') }}</button>

                    <!-- Редагувати, якщо співробітник є призначенний, тільки він може це робити -->
                    <button 
                        v-if="this.form.assignedWorkers.length > 0 && this.form.assignedWorkers[0].workerId === this.user.userid"
                        class="dropdown-item" 
                        @click="this.$emit('edit', this.form)"
                    >{{ $t('Edit') }}</button>
                </div>
            </div>
        </template>
        <template v-slot:body>
            <!-- Модалка додавання співробітників -->
            <modalMembers
                v-if="showModal"
                :isInviteOne="true"
                @close="showModal = false"
                @inviteOne="inviteOne"
                @open="open"
                :projectWorkers="[]"
            />

            <div class="row">
                <div class="col-md-6">
                    <about :obj="this.form" @getClient="getClient"/> 
                </div>
                <div class="col-md-6">
                    <workInfo :obj="this.form" @getClient="getClient"/>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <commentForm :obj="this.form.comments" /> 
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <!-- Закінчити моніторинг -->
            <button class="btn btn-danger" v-if="this.form.status !== 'stop'" @click="showCloseBox = true">{{ this.$t('Stop') }}</button>

            <!-- Призначити співробітника на моніторинг -->
            <button class="btn btn-warning" @click="showModal = true">{{ this.$t('Appoint') }}</button>

            <!-- Поновити моніторинг -->
            <button class="btn btn-success" v-if="this.form.status === 'stop'" @click="resumeWork">{{ this.$t('resumeMonitoringWork') }}</button>

            <!-- Вийти з моніторингу -->
            <button class="btn btn-info" v-if="this.form.assignedWorkers.find(el => el.workerId === this.user.userid)" @click="leaveFromMonitoring">{{ this.$t('logout') }}</button>
            
            <!-- Видалити моніторинг -->
            <button class="btn btn-danger" @click="remove">{{ this.$t('Delete') }}</button>
        </template>
    </modal>

    <!-- Вікно коменту для закриття моніторингу -->
    <closeBox 
        v-if="showCloseBox === true"    
        :id="this.form.monitoringId"
        @toStop="stopMonitoring"
        @close="showCloseBox = false"
    />
    
    <!-- Вікно коменту для закриття моніторингу -->
    <commentBox 
        v-if="showCommentBox === true"  
        :id="this.form.monitoringId"
        @close="showCommentBox = false"
        @updateCard="updateCard"
    />

</template>

<script>
import commentBox from "./actions/addComment"
import modalMembers from '@/components/modal/modal-members'
import closeBox from "./actions/stopMonitoring.vue"
import modal from '@/components/modal/modal-lg'
import about from './blocks/about.vue';
import workInfo from './blocks/workInfo.vue';
import commentForm from './blocks/commentForm.vue';
import { Monitoring } from '../../../API';
import {storeS} from "@/store.js"

let apiServe = new Monitoring()

export default {
    data() {
        return {
            showCloseBox: false,
            showCommentBox: false,
            form: {},
            showModal: false,
            componentKey: 0
        }
    },
    props: ['obj'],
    components: {
        modalMembers,
        closeBox,
        modal,
        about,
        workInfo,
        commentForm,
        commentBox
    },
    created() {
        this.form = this.obj
    },
    methods: {
        remove() {
            apiServe.removeFromMonitoring(this.form.monitoringId).then(result => {
                return result.status === 'done' ? this.$emit('close') : this.sendLog()
            })
        },
        sendLog(data) {
            alert('krua')
        },
        updateCard() {
            apiServe.getMonitoring(this.fomr.monitoringId).then(result => {
                if(result.status === 'done') {
                    this.form = result.data
                    this.componentKey++
                } else {
                    // todo logs
                }
            })
        },
        getClient(num){
            this.eventBus.emit('getClient', {status: true, number: num})
        },
        resumeWork(){
            apiServe.resumeWork(this.form.monitoringId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit("getdata")
                    this.$emit("close")
                } else {
                    // todo logs
                }
            })
        },
        inviteOne(e) {
            apiServe.assignWorkers(this.form.monitoringId, {workers: [e[0].workerId]}).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit("getdata")
                    this.$emit("close")
                } else {
                    // todo logs
                }
            })
        },
        leftFromMonitoring() {
            apiServe.exitFromMonitoring(this.form.monitoringId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit("getdata")
                    this.$emit("close")
                } else {
                    // todo logs
                }
            })
        },
        stopMonitoring(id, obj) {
            apiServe.stopWork(id, obj).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit("getdata")
                    this.$emit("close")
                } else {
                    // todo logs
                }
            })
        },
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
}
</script>

<style lang="css" scoped>
.card-body {
    padding: 0.5rem !important;
}
</style>
