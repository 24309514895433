<template>
    <!-- Блок для відображення деталей завдання (основна інформація) -->

    <b-card>
        <!-- Заголовок карточки -->
        <div class="card-header">
            <h5 class="card-title mb-0"><b>{{ $t('aboutApplication') }}</b></h5>
        </div>

        <!-- Тіло карточки з відомостями про завдання -->
        <div class="card-body">

            <!-- Блок з інформацією про завдання -->
            <div class="mb-3">
                <div class="d-flex mt-2">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`bx bx-task`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <!-- Тип завдання -->
                        <p class="mb-1 fs-16">{{ $t('task') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy`" @click="copy(this.mutateType(this.form.taskName))" >{{ this.mutateType(this.form.taskName) }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 
                Блок про тип завдання перенесення коштів
            -->
            <div class="mb-3" v-if="this.form.taskName == 'transfer_funds_companies_between_accounts' || this.form.taskName == 'transfer_funds_companies_in_account'">
                <div class="d-flex mt-2">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`bx bx-task`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('aboutTask') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 
                                    :class="`fs-14 mb-0 fw-normal copy`"
                                >

                                    <!-- Відображаємо що потрібно перенести баланс -->
                                    <template v-if="this.form.descType != 'period' && this.form.descType != 'balance_period'">
                                        {{ $t('aboutTransferBalance') }} {{ this.form.descType }}₴
                                    </template>

                                    <!-- Відображаємо що потрібно перенести активний період -->
                                    <template v-if="this.form.descType == 'period'">
                                        {{ $t('aboutTransferPeriod') }}
                                    </template>
                                    
                                    <!-- Відображаємо що потрібно перенести активний період та баланс -->
                                    <template v-if="this.form.descType == 'balance_period'">
                                        {{ $t('aboutTransferPeriodBalance') }}
                                    </template>

                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Блок з інформацією про компанію (якщо існує) -->
            <div class="mb-3" v-if="this.form.companyId">
                <div class="d-flex mt-3">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <!-- Поле з якої компанії -->
                        <p class="mb-1 fs-16">{{ $t('FromCompany') }} :</p>
                        <div class="d-flex align-items-center">
                            <div class="flex-grow-1" >
                                <h6 
                                    v-if="form.companyName" 
                                    class="mb-1 copy" 
                                    @click="this.$emit('getClient', this.form.companyId)" 
                                >
                                    {{ this.form.companyName }}
                                </h6>
                                <h6 
                                    v-else 
                                    class="mb-1 copy"
                                    @click="this.$emit('getClient', this.form.companyId)"
                                >
                                    {{ this.form.companyId }}
                                </h6>
                            </div>
                            <i class="ri-file-copy-2-fill fs-16 text-success btnCopy"  @click="copy(this.form.companyId)"></i>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Блок з інформацією про компанії (якщо вказані) -->
            <div 
                class="mb-3" 
                v-if="
                        this.form.taskName == 'copy_data_franchise' 
                        || this.form.taskName == 'copying_data_inside' 
                        || form.taskName == 'trans_data_from_old_company' 
                        || form.taskName == 'trans_data_from_old_company_franchise'
                        || form.taskName == 'transfer_funds_companies_in_account'
                        || form.taskName == 'transfer_funds_companies_between_accounts'
                        || form.taskName == 'trans_data_from_newServer_company_franchise'
                        || form.taskName == 'trans_data_from_newServer_company'
                ">
                <div class="d-flex mt-3">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <!-- Поле на яку компанію -->
                        <p class="mb-1 fs-16">{{ $t('PerCompany') }} :</p>

                        <template 
                            v-for="items, i in this.form.copyOnCompany" 
                            :key="i"
                        >
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1" >
                                    <h6 
                                        class="mb-1 copy" 
                                    >
                                        <span 
                                            @click="this.$emit('getClient', items.companyId)" 
                                            :key="i" style="word-break: break-all; display: block;"
                                        >
                                            {{ items.companyName ? items.companyName : items.companyId}}
                                        </span>
                                    </h6>
                                </div>
                                <i class="ri-file-copy-2-fill fs-16 text-success btnCopy"  @click="copy(items.companyName ? items.companyName : items.companyId)"></i>
                            </div>
                        </template>

                        <!-- <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 v-if="form.status == 'wait_confirm'" :class="`fs-14 mb-0 fw-normal copy`"><span v-for="items, i in this.form.copyOnCompany" @click="this.$emit('getClient', items.companyId)" :key="i" style="word-break: break-all; display: block;">{{ items.companyName ? items.companyName : items.companyId}}</span></h6>
                                <h6 v-else :class="`fs-14 mb-0 fw-normal copy`"><span v-for="items, i in this.form.copyOnCompany" @click="this.$emit('getClient', items.companyId)" :key="i" style="word-break: break-all; display: block;">{{ items.companyName ? items.companyName : items.companyId}}</span></h6>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>

            <!-- Блок з інформацією про торгові точки (якщо вказані) -->
            <div class="mb-3" v-if="form.tradepoints && form.tradepoints.length > 0 && form.tradepoints[0] != null">
                <div class="d-flex mt-3">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-home-3-line`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <!-- Торгові точки -->
                        <p class="mb-1 fs-16">{{ $t('Outlets') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-semibold `" v-html="this.mutateNumber(this.form.tradepoints)" ></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Блок з інформацією про домен клієнта (якщо вказаний) -->
            <div class="mb-3" v-if="form.descType != '' && this.form.taskName == 'add_change_domain'">
                <div class="d-flex mt-3">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('ClientDomain') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy`" @click="copy(this.form.descType)">{{ form.descType }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Блок з інформацією про бажаний ліміт (якщо вказаний) -->
            <div class="mb-3" v-if="form.descType != '' && this.form.taskName == 'change_limit_companies'">
                <div class="d-flex mt-3">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('DesiredLimit') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy`" @click="copy(this.form.descType)">{{ form.descType }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Блок з інформацією про статус завдання -->
            <div class="mb-3">
                <div class="d-flex mt-3">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('Status') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-semibold copy ${this.mutateStatus(this.form.status, this.form).color}`" @click="copy(this.mutateStatus(this.form.status, this.form).name)"> {{ this.mutateStatus(this.form.status, this.form).name }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Блок з інформацією про пріоритет завдання -->
            <div class="mb-3">
                <div class="d-flex mt-3">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('priority') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-semibold copy ${this.mutatePriority(this.form.priority).bg}`" @click="copy(this.mutatePriority(this.form.priority).name)"> {{ this.mutatePriority(this.form.priority).name }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </b-card>
</template>

<script>
import { mutateTypeTask, mutatePriority, mutateStatusTask } from '@/usabilityScripts/globalMutate.js'
import {copy} from '@/usabilityScripts/copy.js'

export default{
   props: ['obj'],
   data(){
       return {
           form: ''
       }
   },
   created(){
       this.form = this.obj
   },
   methods: {
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        mutateNumber(value){
            // Метод для форматування числових значень

            var r = []
            if(value != ''){
                for(var item in value){
                    r.push('<span class="fs-11 badge badge-soft-info">'+value[item]+'</span>')
                }
            }
            return r
        }, 
        mutateType(e){
            // Метод для мутації типу завдання
            return mutateTypeTask(e)
        },
        mutateStatus(e, obj){
            // Метод для мутації статусу завдання
            return mutateStatusTask(e, obj)
        },
        mutatePriority(e){
            // Метод для мутації пріоритету завдання
            return mutatePriority(e)
        },
    }
}
</script>

<style>

.btnCopy {
    margin-left: 10px;
    opacity: 0.4;
    margin-top: -7px;
}

.btnCopy:hover {
    opacity: 1!important;
    transition: .3s;
    cursor: pointer;
}

</style>