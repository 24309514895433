<template>
    <!-- Модальне вікно для продовження оплати компанії/змінити день наступної оплати -->
    <!-- Працює лише для нової адмінки (сервера s-2, s-3, s-4) -->
    <modal>
        <template v-slot:title>{{ $t('ContinueAccountPayment') }}</template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-16 fw-semibold">{{ $t('EnterDateNextDebit') }}</h4>
                        <b-form-group>
                            <b-form-radio-group
                                id="radio-group-1"
                                v-model="type"
                                :options="optionsType"
                                :aria-describedby="ariaDescribedby"
                                @change="changeType( $event )"
                                name="radio-options"
                            ></b-form-radio-group>
                            </b-form-group>
                        <flatpickr v-model="date" :config="configDate" class="form-control border-0 dash-filter-picker shadow" @onChange="change"></flatpickr>
                    </div>
                    <div class="mb-3">
                        <h4 class="fs-16 fw-semibold">{{ $t('Cause') }}</h4>
                        <input class="form-control" v-model="form.reason" />
                    </div>
                </b-col>
            </b-row>
            <hr />
            <div class="mb-3">
                <h4 class="fs-16 fw-semibold">{{ $t('CurrentCompanyData') }}</h4>
            </div>
            <b-row>
                <b-col lg="12">
                    <div class="card card-animate" style="border-radius: 8px;">
                        <div class="card-body bg-success" id="infoStep2" style="border-radius: 8px;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <p class="text-uppercase fw-medium text-white mb-0"><b>{{ $t('Paid_to') }}</b></p>
                                </div>
                            </div>
                            <div class="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                    <h4 class="fs-22 fw-semibold ff-secondary text-white"><span class="counter-value">{{ contentData.activeAccount }}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>

                </b-col>
                <b-col lg="12">
                    <div class="card card-animate"  style="border-radius: 8px; cursor: pointer;">
                        <div class="card-body bg-warning"  style="border-radius: 8px;">
                            <div class="d-flex align-items-center" >
                                <div class="flex-grow-1" >
                                    <p class="text-uppercase fw-medium text-white mb-0" ><b>{{ $t('tariffPlan') }}</b></p>
                                </div>
                            </div>
                            <div class="d-flex align-items-end justify-content-between mt-3" >
                                <div class="breakText" >
                                    <h4 class="fs-22 fw-semibold ff-secondary text-white" ><span class="counter-value" >{{ contentData.tariffName }}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>

                </b-col>
                <b-col lg="12">
                    <div class="card card-animate" style="border-radius: 8px;">
                        <div class="card-body bg-danger" id="infoStep1" style="border-radius: 8px;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <p class="text-uppercase fw-medium text-white mb-0"><b>{{ $t('SubscriptionFee') }}</b></p>
                                </div>
                            </div>
                            <div class="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                    <h4 class="fs-22 fw-semibold ff-secondary text-white"><span class="counter-value">{{ contentData.userSumm }} {{ contentData.currency }}</span></h4>
                                </div>
                                <!--v-if-->
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom >
            <button type="button" class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
            <button type="button" class="btn btn-success" @click="editPayDate">{{ $t('Edit') }}</button>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { storeS } from '@/store';
import { Banking } from '@/API'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Banking();

export default{
    props: ['obj', 'companyId'],
    components: {
        modal,
        flatpickr: Vueflatpickr
    },
    data(){
        return{
            contentData:'',
            date: '',
            configDate: {
                mode: "single",
                altInput: true,
                // altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: "",
                defaultDate: []
            },
            form: {
                companyId: '',
                userPhone: '',
                oldDatepayments: '',
                newDatepayments: '',
                reason: ''
            },
            type: 'client',
            optionsType: [
                { text: this.$t('client'), value: 'client' },
                { text: this.$t('Employee'), value: 'worker'}
            ]
        }
    },
    created(){
        if(storeS.lang == 'uk'){
            this.configDate.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.configDate.locale = Russian
        }

        this.form.companyId = this.companyId
        this.form.userPhone = this.obj.ownerPhone
        this.form.oldDatepayments = this.obj.companyDataObj.license.activeAccount

        this.getdata()
    },
    methods: {
        getdata(){
            this.contentData = this.obj.companyDataObj.license
            this.configDate.defaultDate = this.obj.companyDataObj.license.activeAccount
        },
        change(selectedDates, dateStr, instance){
            var newDate = String(dateStr)
            this.form.newDatepayments = newDate;
        },
        changeType(e){
            console.log('type', e);
            if(e === "worker"){
                // this.newDate = t
                var date3 = new Date(this.configDate.defaultDate); 
                var dateStr = "2099-" + ("00" + (date3.getMonth() + 1)).slice(-2) + "-" + ("00" + date3.getDate()).slice(-2);
                console.log("dateStr",dateStr);
                this.date = dateStr;
                this.form.newDatepayments = this.date
            } else {
                this.date = this.configDate.defaultDate
            }
        },
        editPayDate(){
            console.log(this.form);
            // "companyId": "a5b39765-f32a-4800-b479-1dabe6f34f82",
            // "userPhone": "38095454234",
            // "oldDatepayments": "2023-10-28",
            // "newDatepayments": "2023-10-29",
            // "reason": "test"

            apiServe.setNewDatepayments(this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('success'))
                    this.$emit('close')
                } else if(result.status == 'error' && result.error[0] == 'wrong datetime'){
                    this.$toast.error(this.$t('IncorrectDateFormat'));
                } else if(result.status == 'error' && result.error[0] == 'newDatepayments not found'){
                    this.$toast.error(this.$t('EnterDateNextDebit'));
                } else if(result.status == 'error' && result.error[0] == 'enter data'){
                    this.$toast.error(this.$t('enterData'))
                } else if(result.status == 'error' && result.error[0] == 'access denied'){
                    this.$toast.error(this.$t('accessDenied'))
                } else if(result.status == 'error' && result.error[0] == 'no auth'){
                    this.$toast.error(this.$t('noAuth'))
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка продовження дати оплати'; 
                    var statusRequest = result.status;
                    var api = 'setNewDatepayments';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/dangerActions/extendPayment.vue';
                    var params = this.form;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }
    }
}
</script>

<style>
.form-control[readonly] {
    background-color: var(--vz-input-bg)!important;
    opacity: 1;
}
</style>