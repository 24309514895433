<template>
    <files 
        :obj="this.obj" 
        :objFiles="this.form.files" 
        @uploadFiles="uploadFiles"
        @downloadFile="downloadFile"
        @deleteFile="deleteFile"
        @getFile="getFile"
    />
    
    <modallg v-if="showImg" @close="showImg = false">
        <template v-slot:body>
            <template v-if="gFile.type == 'image/jpeg' || gFile.type == 'image/png' || gFile.type == 'image/heic'">
                <img  :src="`${gFile.src}`" style="">
            </template>
            <template v-if="gFile.type == 'video/quicktime' || gFile.type == 'video/mp4'">
                <video controls>
                    <source :src="`${gFile.src}`" type="video/mp4">
                </video>
            </template>
        </template>
        <template v-slot:buttons>
            <button class="gdownload gbtn" @click="this.downloadFile(gFile)" ><i class="ri-download-cloud-2-fill"></i></button>
            <button class="gdelete gbtn" @click="showRemDialog(gFile)" ><i class="ri-delete-bin-fill"></i></button>
            <button :class="`gprev gbtn ${ifPrevimg == true ? '' : 'disabled'}`" @click="prevOpen(gFile)" aria-label="Previous" data-taborder="2"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg></button>
            <button :class="`gnext gbtn ${ifNextimg == true ? '' : 'disabled'}`" @click="nextOpen(gFile)" aria-label="Next" data-taborder="1"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg></button>
        </template>
    </modallg>

    <modalremove v-if="dialogShow" @close="dialogShow = false" @remove="this.deleteFile(gFile)" />

</template>

<script>
import files from '@/components/files/index'
import modallg from '@/components/modal/img'
import modalremove from '@/components/modal/modalremove'
import { Projects } from '@/API'
import { storeS } from '@/store'

let apiServe = new Projects();

export default{
    props: ['obj'],
    components: {
        files,
        modallg,
        modalremove
    },
    data(){
        return{
            form: {
                files: []
            },
            gFile: {
                fileId: '',
                src: '',
                type: '',
                name: ''
            },
            showImg: false,
            dialogShow: false,
            ifPrevimg: false,
            ifNextimg: false
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            apiServe.getFiles(this.obj.projectId).then(result => {
                if(result.status == 'done'){
                    this.form.files = result.data
                }
            })
        },
        uploadFiles(obj, formData){
            apiServe.uploadFiles(obj.projectId, formData).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('FileUploadedSuccessfully'));
                    storeS.uploadPercentage = 0;
                    this.getdata();
                }
            })
        },
        downloadFile(item){
            apiServe.getFile(item.fileId, item.type)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.name);
                    document.body.appendChild(link);
                    link.click();
                    storeS.uploadPercentage = 0;
                })
                .catch((error) => console.log(error));
        },
        prevOpen(e){
            var current_id = e.fileId;
            var prev_id = ""; 

            for(var value in this.form.files){
                if(this.form.files[value].fileId == current_id){
                    prev_id = this.form.files[Number(value)-Number('1')];
                }
            }
            this.getFile(prev_id);
        },
        nextOpen(e){
            var current_id = e.fileId;
            var next_id = "";
            for(var value in this.form.files){
                if(this.form.files[value].fileId == current_id){
                    next_id = this.form.files[Number(value)+Number('1')];
                }
            }
            this.getFile(next_id);
        },
        getFile(item){
            var id = item.fileId ? item.fileId : item;
            this.ifPrevimg = id == this.form.files[0].fileId ? false : true;
            this.ifNextimg = id == this.form.files[this.form.files.length-1].fileId ? false : true;

            apiServe.getFile(id, item.type)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    this.gFile.fileId = id;
                    this.gFile.src = url;
                    this.gFile.type = item.type;
                    this.gFile.name = item.name
                    this.showImg = true;
                    storeS.uploadPercentage = 0;
                })
        },
        deleteFile(e){
            apiServe.deleteFile(e.fileId).then(result => {
                if(result.status == 'done'){
                    this.showImg = false
                    this.dialogShow = false
                    this.$toast.success(this.$t('FileDeleted'));
                    this.getdata();
                }
            })
        },
        showRemDialog(e){
            this.gFile.fileId = e.fileId
            this.dialogShow = true;
        },
    },
    computed: {
        uploadPercentage(){
            return storeS.uploadPercentage
        }
    }
}
</script>