<template>
    <!-- Компонент РРО -->

    <div class="col-md-6">
        <div class="mb-3">
            <!-- Заголовок для моделі РРО -->
            <h4 class="fs-15">{{ $t('bank_terminal_model') }}</h4>

            <!-- Випадаючий список для вибору моделі  -->
            <Multiselect class="mb-3"
                @input="input"
                v-model="form.model.value"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="models"
                :disabled="this.editform == false"
            />

            <!-- Заголовок для операційної системи -->
            <h4 class="fs-15">{{ $t('operating_system') }}</h4>

            <!-- Вибір типу операційної системи зі списку -->
            <select
                class="form-select mb-3"
                data-plugin="choices"
                v-model="form.oc_types.value"
                :disabled="this.editform == false"
                >
                <option v-for="item in oc_types" :key="item.id">{{ item.title }}</option>
            </select>

            <!-- Заголовок для версії додатку -->
            <h4 class="fs-15">{{ $t('ver_apps') }}</h4>

            <!-- Введення текстового поля для версії додатку -->
            <input 
                type="text" 
                class="form-control mb-3" 
                id="text" 
                :disabled="this.editform == false" 
                v-model="form.version_os.value" 
                placeholder="9.9"
            >
        </div>
    </div>
    <div class="col-md-6">
        <div class="mb-3">
            <!-- Заголовок для методу підключення -->
            <h4 class="fs-15">{{ $t('connection_method') }}</h4>

            <!-- Вибір методу підключення зі списку -->
            <select
                class="form-select mb-3"
                data-plugin="choices"
                v-model="form.connect_types.value"
                :disabled="this.editform == false"
                >
                <option v-for="item in connect_types" :key="item.id">{{ item.title }}</option>
            </select>

            <!-- Заголовок для версії операційної системи -->
            <h4 class="fs-15">{{ $t('ver_oc') }}</h4>
            
            <!-- Введення текстового поля для версії операційної системи -->
            <input 
                type="text" 
                class="form-control mb-3" 
                id="text" 
                :disabled="this.editform == false" 
                v-model="form.version_apps.value" 
                placeholder="10"
            >
        </div>
    </div>
    <hr>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
export default ({
    props: ['obj','editform'],
    components: { Multiselect },
    data(){
        return {
                models: [ // Список моделей 
                    {
                        "label": "ПРРО",
                        "value": "prro",
                    },
                    {
                        "label": "MG N707TS",
                        "value": "MG N707TS",
                    },
                    {
                        "label": "FR 90XM",
                        "value": "FR 90XM",
                    },
                    {
                        "label": "MINI FP54",
                        "value": "MINI FP54",
                    },
                    {
                        "label": "SystemGroup Innovate",
                        "value": "SystemGroup Innovate",
                    }
                ],
                oc_types: [ // Список типів операційних систем
                    {
                        "title": "Windows"
                    },
                    {
                        "title": "Android"
                    },
                    {
                        "title": "IOS (ПРРО)"
                    }
                ],
                connect_types: [ // Список типів підключення
                    {
                        "title": "Online (ПРРО)"
                    },
                    {
                        "title": "HTTP (HelpMicro)",
                    },
                    {
                        "title": "HTTP 2 (HelpMicro)",
                    },
                    {
                        "title": "USB (MiniFP)"
                    },
                    {
                        "title": "Ethernet (MiniFP)"
                    },
                    {
                        "title": "Innovate"
                    }
                ],
                form: { // Об'єкт форми з даними 
                    model: {
                        icon: "ri-cpu-fill",
                        label: "bank_terminal_model",
                        value: ""
                    },
                    connect_types: {
                        icon: "mdi-connection",
                        label: "connection_method",
                        value: ""
                    },
                    oc_types: {
                        icon: "ri-user-2-fill",
                        label: "operatingSystem",
                        value: ""
                    },
                    version_os: {
                        icon: "ri-user-2-fill",
                        label: "ver_oc",
                        value: ""
                    },
                    version_apps: {
                        icon: "ri-user-2-fill",
                        label: "verSkyService",
                        value: ""
                    },
                }
            }
        },
        created(){

            // Якщо передані дані, встановлюємо їх у форму
            if(this.obj != ''){
                this.form = this.obj
            }
        },
        methods: {
            input(){
                // Обробка події введення
                this.$emit('input', this.form)
            }
        },
        mounted() {
        }
    }
)
</script>
