<template>
    <!-- 
        Модальне вікно вибору компанії до номеру клієнта в тікет
    -->

    <dialogmodal>
        <template v-slot:body>
            <div class="mt-4 text-center">
                <h3><b>{{ $t('selectCompanyForNumber') }}?</b></h3>
                <p class="text-muted fs-15 mb-4">{{ $t('phone_num') }}: {{ this.number }}</p>
            </div>
            <div class="row" style="gap: 10px" v-if="this.companies.length">
                <div class="col-5" v-for="items, i in this.companies" :key="i">
                    <button class="btn " :class="items.more.companyServer == 's-1' ? 'btn-success' : 'btn-warning'" @click="send(items)">{{ items.companyName }} - {{ items.more.companyServer == 's-1' ? this.$t('OldAdmin') : this.$t('NewAdmin_panel') }}</button>
                </div>
            </div>
            <div class="row" v-else style="display: grid; justify-items: center;">
                <h5><b>{{ $t('noCompaniesOrContactNumb') }}?</b></h5>
                <div class="col-sm-auto" style="justify-self: center; margin-top: 15px">
                    <!-- відправляємо фейк-об'єкт, якщо компаній нема або нема дозволів у юзера, що створює тікет -->
                    <button class="btn btn-danger" @click="this.send({companyId: 'none', companyName: 'none'})">{{ this.$t('Add') }}</button> 
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
              <div class="hstack gap-2 justify-content-center remove">
                  <button class="btn btn-link link-success fw-medium text-decoration-none" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</button>
                  <!-- <button class="btn btn-danger" @click="remove(this.form)">{{ $t('yesDeleteIt') }}</button> -->
              </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue'
import { SuperAdmin } from "@/API.js"

let apiAdmin = new SuperAdmin()

export default {
    props: ['number'],
    components: {
        dialogmodal
    },
    data(){
        return{
            companyName: "",
            companyId: '',
            companies: [],
        }
    },
    created(){
        apiAdmin.getCard(this.number).then(result => {
            if(result.status == 'done'){
                result.data.сompanies?.forEach(el => {
                    this.companies.push(el)
                })
            }
        })
    },
    methods: {
        send(e){
            this.$emit('choseComp', {companyId: e.companyId, companyName: e.companyName, number: this.number})
        }
    },
    mounted(){
        // this.eventKey.on('enter', () => {
        //     this.send(this.form)
        //     this.$emit('close');
        // })
    }
}
</script>