<template>
    <!-- 
        Модальне вікно відображення картки запізнення 
    -->

    <!-- Модальне вікно відображення картки запізнення -->
    <dialogBox @close="$emit('close')">

        <!-- Заголовок модального вікна -->
        <template v-slot:title>{{ $t('beinglate') }}</template>

        <!-- Тіло модального вікна -->
        <template v-slot:body>

            <!-- Інформація про працівника -->
            <b-row>
                <b-col lg="12">
                    <div class="mb-3">
                        <label class="form-label">{{ $t('name') }}</label>
                        <input class="form-control" v-model="form.openedWorkerName" disabled />
                    </div>
                </b-col>
            </b-row>
            
            <!-- Блок інформації про зміну -->
            <div class="row border rounded border-dashed p-2 mb-2">
                <!-- Статус зміни -->
                <div class="col-lg-12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('statusShift') }}</h4>
                        <div class="input-group">
                            <input type="text" :value="form.shiftCloseDateTime != '-' ? $t('closeShift') : $t('openShift')" class="form-control" autocomplete="off" disabled />
                            <!-- <button type="button"  class="btn btn-info waves-effect waves-light">
                                <i class="bx bxs-show"></i>
                            </button> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('startShift') }}</h4>
                        <input class="form-control" v-model="form.shiftOpenDatetime" disabled />
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('endShift') }}</h4>
                        <input class="form-control" v-model="form.shiftCloseDateTime" disabled />
                    </div>
                </div>
            </div>

            <!-- Блок інформації про запізнення -->
            <div class="row border rounded border-dashed p-2 mb-2">
                <!-- Коментар до запізнення -->
                <div class="col-lg-12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('beinglate') }}</h4>
                        <input class="form-control" v-model="form.lateComment" disabled />
                    </div>
                </div>
                <!-- Початок робочої зміни та тривалість запізнення -->
                <div class="col-lg-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('OpeningShift') }}</h4>
                        <input class="form-control" v-model="form.shiftOpenTime" disabled />
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('lateTime') }}</h4>
                        <input class="form-control" v-model="form.lateTimeInMin" disabled />
                    </div>
                </div>
            </div>
        </template>

        <!-- Кнопки у нижній частині модального вікна -->
        <template v-slot:footer-bottom>

            <!-- Кнопка для створення штрафу -->
            <button 
                type="button" 
                class="btn btn-success mt-2" 
                @click="create"
            >
                {{ $t('addFine') }}
            </button>

        </template>
    </dialogBox>

    <!-- Компонент для створення штрафу -->
    <fineBox 
        v-if="showFineBox"
        @close="showFineBox = false"
        :obj="this.obj" 
    />

</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модального вікна
import fineBox from '../fines/create' // компонент створення штрафу

export default{
    props: ['objCard'],
    components: {
        dialogBox,
        fineBox
    },
    data(){
        return{
            form: '', // Об'єкт для введення даних
            showFineBox: false, // Прапорець для відображення fineBox
            obj: {} // Об'єкт для передачі в fineBox
        }
    },
    created(){
        this.form = this.objCard
    },
    methods: {
        create(){
            /**
                Створення об'єкту штрафу на основі введених даних та відкриття fineBox.

                @method create
            */

            var fineLate = {
                workerId: this.form.openedWorkerId,
                typeLate: 'beinglate',
                status: 'waitingСonfirmation',
                sum: this.form.lateTimeInMin > 60 ? '500' : '200',
                comment: this.form.openedWorkerName + ' ' + this.$t('lateFor') + ' ' + this.form.lateTimeInMin + " " + this.$t('min') + "\n" + this.$t('Cause') + ": " + this.form.lateComment + ""
            }
            this.obj = fineLate;
            this.showFineBox = true;
        }
    }
}
</script>