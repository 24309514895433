<template>
    <b-card>
        <h5 class="fw-semibold text-uppercase mb-3">{{ $t('projectDescription') }}</h5>
        <div class="ql-container">
            <div class="ql-editor" v-html="form.description">
            </div>
        </div>
    </b-card>
    <b-row>
        <b-col lg="6">
            <!-- <b-card v-if="form.decidedComment != null">
                <h5 class="fw-semibold text-uppercase mb-3 border-bottom pb-2">{{ $t('Decision') }}  <span :class="'fs-14 '+this.mutateStatus(form.decidedStatus).color">{{ this.mutateStatus(form.decidedStatus).name }}</span></h5> 
                <p>{{form.decidedComment}}</p>
            </b-card> -->
        </b-col>
    </b-row>
</template>

<script>

export default{
    props: ['obj'],
    data(){
        return{
            form:''
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.obj
        },
    }
}
</script>