<template>
    <!-- 
        Модальне вікно закриття перерви
    -->

    <dialogBox >
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('closeBreak') }}</template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            <div class="mb-3">
                <h4 class="fs-15">{{ this.user.name }} , {{ $t('confCloseBreak') }}</h4>
            </div>
        </template>

        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom>
            <!-- Кнопка "Так", закриває перерву -->
            <button 
                type="button" 
                class="btn btn-primary" 
                v-on:click="closeBreak"
            >
                {{ $t('y') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модального вікна
import { Breaks } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних

let apiServe = new Breaks();

export default {
components: { dialogBox },
data() {
    return {
        form:{
            shiftId: "", // id зміни
        },
    };
},
created(){
},
methods: {
    closeBreak() {
       this.$emit('closeBreak')
    },
},
computed: {
    user() {
        /*
            Повертає дані користувача з глобального стору.
        */
        return storeS.userbase
    }
}
}
</script>

<style scoped>
    .text_field input {
        width: 100%;
        border: 1px #ced4da solid;
        border-radius: 5px;
        height: 40px;
        padding-left: 15px;
    }
</style>