<template>
    <modal 
        @close="$emit('close')"
    >
        <template v-slot:title>З{{ $t('ChangeLimitCompanies') }}</template>
        <template v-slot:body>
            <div class="mt-1 pb-3 text-center">
                <h3><b>{{ $t('SpecifyLimitCompanies') }}</b></h3>
                <input type="number" class="form-control w-50 m-auto" :placeholder="$t('enteraNumber')" v-model="form.newNumberOfCompanies" oninput="this.value = this.value.replace(/\./g, '')"  />
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
                <button class="btn btn-success" @click="editMaxNumber()">{{ $t('Edit') }}</button>
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import { SuperAdmin } from '@/API'

let apiServe = new SuperAdmin();

export default{
    props: ['accountId', 'newLimit'],
    components: {
        modal
    },
    data(){
        return{
            form: {
                newNumberOfCompanies: ''
            }
        }
    },
    created(){
        this.form.newNumberOfCompanies = this.newLimit
    },
    methods: {
        editMaxNumber(){
            apiServe.editMaxNumberOfCompanies(this.accountId, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('newLimitBeenApplied'))
                    this.eventBus.emit('changeOnReady', true)
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        }
    }
}
</script>