<template>
    <chat :key="componentKey" v-if="objMsg.length != 0" @getdata="this.getdata()" :type="this.type" :msg="objMsg" @sendMsg="sendMsg" @sendReplyMsg="sendReplyMsg" @removeMsg="removeMsg" />
    <chat v-else @getdata="this.getdata()" :type="this.type" :msg="objMsg" @sendMsg="sendMsg" @sendReplyMsg="sendReplyMsg" @removeMsg="removeMsg" />
    <!-- тимчасовий костиль -->
</template>

<script>
import chat from '@/components/chat/index'
import { Projects } from '@/API'

let apiChats = new Projects();

export default{
    props: ['id'],
    components: {
        chat
    },
    data(){
        return{
            type: 'project',
            form:"",
            objMsg: [],
            componentKey: ''
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            apiChats.getMessages(this.id).then(result => {
                if(result.status == 'done'){
                    this.objMsg = result.data.reverse();
                    this.componentKey += 1
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        sendMsg(text){
            apiChats.sendMessage(this.id, text).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('msgSent'));
                    this.getdata();
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        sendReplyMsg(objReply){
            apiChats.replyOnMessage(this.id, objReply.id, objReply).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('msgSent'));
                    this.getdata();
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        removeMsg(e){
            apiChats.deleteMessage(e.id).then(result=>{
                if(result.status == 'done') {
                    this.$toast.success(this.$t('Removed'));
                    this.getdata()
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        }
    },
    mounted() {
        this.eventBus.off('newMsgproject');
        this.eventBus.on('newMsgproject', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    }
}
</script>