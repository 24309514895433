<template>
    <b-card>
        <div class="card-header mb-3">
            <h5 class="card-title mb-0"><b>{{ $t('Wage') }} <span :class="form.status == 'active' ? 'badge bg-success' : 'badge bg-danger'">{{ form.status == 'active' ? $t('active') : $t('noactive') }} </span></b></h5>
        </div>
        <b-row>
            <b-col lg="6">
                <h4 class="fs-15">{{ $t('monthlyRate') }}</h4>
                <div class="input-group mb-2">
                    <span
                        class="input-group-text"
                        id="product-price-addon"
                        >₴</span
                    >
                    <input
                        type="number"
                        class="form-control"
                        id="product-price-input"
                        :value="form.monthlyRate ? form.monthlyRate : '-'"
                        disabled
                    />
                </div>
            </b-col>
            <b-col lg="6">
                <h4 class="fs-15">{{ $t('PercentageTotalSales') }}</h4>
                <div class="input-group mb-2">
                    <span
                        class="input-group-text"
                        id="product-price-addon"
                        >%</span
                    >
                    <input
                        type="number"
                        class="form-control"
                        id="product-price-input"
                        :value="form.monthlyPercent ? form.monthlyPercent : '-'"
                        disabled
                    />
                </div>
            </b-col>
            <b-col lg="12">
                <div class="border rounded border-dashed p-2 mb-2">
                    <div class="m-2">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked disabled>
                            <label class="form-check-label fs-14 fw-semibold" for="flexRadioDefault2">
                                {{ form.rate == 'fixed' ? $t('FixedRateShift') : $t('DifferentRateDifferentShifts') }}
                            </label>
                        </div>
                    </div>
                    <b-row >
                        <b-col lg="6" v-if="form.rate == 'fixed'">
                            <h4 class="fs-15">{{ $t('RateShift') }}</h4>
                                <div class="input-group mb-2">
                                    <span
                                        class="input-group-text"
                                        id="product-price-addon"
                                        >₴</span
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="product-price-input"
                                        :value="form.shiftRate ? form.shiftRate : '-'"
                                        disabled
                                    />
                                </div>
                        </b-col>
                        <b-col lg="4" v-if="form.rate == 'different'">
                            <h4 class="fs-15">{{ $t('NightShiftRate') }}</h4>
                                <div class="input-group mb-2">
                                    <span
                                        class="input-group-text"
                                        id="product-price-addon"
                                        >₴</span
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="product-price-input"
                                        :value="form.nightRate ? form.nightRate : '-'"
                                        disabled
                                    />
                                </div>
                        </b-col>
                        <b-col lg="4" v-if="form.rate == 'different'">
                            <h4 class="fs-15">{{ $t('RewaDayShiftRaterd') }}</h4>
                                <div class="input-group mb-2">
                                    <span
                                        class="input-group-text"
                                        id="product-price-addon"
                                        >₴</span
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="product-price-input"
                                        :value="form.noontimeRate ? form.noontimeRate : '-'"
                                        disabled
                                    />
                                </div>
                        </b-col>
                        <b-col lg="4" v-if="form.rate == 'different'">
                            <h4 class="fs-15">{{ $t('DailyShiftRate') }}</h4>
                                <div class="input-group mb-2">
                                    <span
                                        class="input-group-text"
                                        id="product-price-addon"
                                        >₴</span
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="product-price-input"
                                        :value="form.dailyRate ? form.dailyRate : '-'"
                                        disabled
                                    />
                                </div>
                        </b-col>
                        <b-col lg="12" >
                            <h4 class="fs-15">{{ $t('PercentageAllSalesShift') }}</h4>
                                <div class="input-group mb-2">
                                    <span
                                        class="input-group-text"
                                        id="product-price-addon"
                                        >%</span
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="product-price-input"
                                        :value="form.salesPercent ? form.salesPercent : '-'"
                                        disabled
                                    />
                                </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="12">
                <div class="border rounded border-dashed p-2 mb-2">
                    <div class="m-2">
                        <h4 class="fs-15">{{ $t('PercentagePersonalSales') }}</h4>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="personalSales" id="personalSales" checked disabled>
                            <label class="form-check-label fs-14 fw-semibold" for="personalSales">
                                {{ form.personalSales == 'fixed' ? $t('Fixed') : $t('Floating') }}
                            </label>
                        </div>
                    </div>
                    <b-row >
                        <b-col lg="6" v-if="form.personalSales == 'fixed'">
                            <h4 class="fs-15">{{ $t('Percent') }}</h4>
                                <div class="input-group mb-2">
                                    <span
                                        class="input-group-text"
                                        id="product-price-addon"
                                        >%</span
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="product-price-input"
                                        :value="form.fixedPercent ? form.fixedPercent : '-'"
                                        disabled
                                    />
                                </div>
                        </b-col>
                        <b-col lg="6" v-if="form.personalSales == 'fixed'">
                            <h4 class="fs-15">{{ $t('RateHour') }}</h4>
                                <div class="input-group mb-2">
                                    <span
                                        class="input-group-text"
                                        id="product-price-addon"
                                        >₴</span
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="product-price-input"
                                        :value="form.hourlyRate ? form.hourlyRate : '-'"
                                        disabled
                                    />
                                </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </b-card>
    <b-row>
        <b-col lg="12">
            <div class="border rounded border-dashed p-2 mb-2">
                <div class="m-2">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="productReward" id="productReward" :checked="form.productReward" disabled>
                        <label class="form-check-label fs-14 fw-semibold" for="productReward">
                            {{ $t('RewardSaleGoods') }}
                        </label>
                    </div>
                </div>
                <div class="mt-3">
                    <tablecustom 
                    @search="searchB" 
                        :columns="columns" 
                        :rows="filteredItems" 
                    />
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import tablecustom from '@/components/globaltable/index';

export default{
    props: ['obj'],
    components: {
        tablecustom
    },
    data(){
        return{
            search: '',
            form: '',
            columns: [
                {
                    name: 'ID',
                    text: "nomenclatureId",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("Point_name"),
                    text: "nomenclatureName",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("price"),
                    text: "price",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("Reward"),
                    text: "reward",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item, obj) => obj.unit == 'prc' ? item + ' %' : item + ' ₴'
                },
            ]
        }
    },
    created(){
        this.form = this.obj
    },
    methods:{
        searchB(e){
            this.search = e
        },
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form.products)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.nomenclatureName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })

        },
    }
}

</script>