<template>
    <!-- 
        Компонент відображення модального вікна для вибору співробітника.
        Містить поле пошуку, список команд з можливістю додавання, та кнопки "Закрити" та "Увійти в команду".
    -->

    <modal>
        <!-- Слот шапки модального вікна  -->
        <template v-slot:title>{{ $t('team') }}</template>

        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <div class="" ><button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button></div>
        </template>

        <!-- Слот тіло модального вікна з формою для введення даних -->
        <template v-slot:body>

            <!-- Поле пошуку -->
            <div class="search-box mb-3">
                <input type="text" class="form-control bg-light border-light" v-model="this.search" :placeholder="$t('searc_inp')">
                <i class="ri-search-line search-icon"></i>
            </div>
            
            <!-- <div class="mb-4 d-flex align-items-center">
            </div> -->

            <!-- Список команд -->
            <div class="mx-n4 px-4" data-simplebar style="max-height: 225px;">
                <div class="vstack gap-3">
                    <div class="d-flex align-items-center" v-for="item in filteredItems" :key="item.id">
                        <div class="avatar-xs flex-shrink-0 me-3">
                            <div class="avatar-title rounded bg-soft-info text-info">
                                {{ getInitials(item.team_title) }}
                            </div>
                        </div>

                        <!-- Інформація про команду -->
                        <div class="flex-grow-1">
                            <h5 class="fs-13 mb-0"><a class="text-body d-block">{{item.team_title}} ({{ item.develop }})</a></h5>
                        </div>

                        <!-- Кнопки дій: Увійти в команду -->
                        <div class="flex-shrink-0">
                            <button type="button" class="btn btn-light btn-sm" @click="add(item)">{{ $t('enterTeam') }}</button>
                            <!-- <button type="button" class="btn btn-danger btn-sm" @click="">{{ $t('Delete') }}</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <!-- Нижні кнопки модального вікна -->
        <template v-slot:footer-bottom>

            <!-- Кнопка "Закрити" -->
            <button 
                type="button" 
                class="btn btn-light w-xs" 
                data-bs-dismiss="modal"  
                @click="$emit('close')"
            >
                {{ $t('close') }}
            </button>

            <!-- Кнопка "Увійти в команду" -->
            <button 
                type="button" 
                class="btn btn-success w-xs"
            >
                {{ $t('enterTeam') }}
            </button>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small'
import { Teams } from '@/API.js';

let apiServe = new Teams();

export default ({
    data(){
        return {
            form: "",
            search: "",
            obj: ""
        }
    },
    components: { modal },
    props: ['checkteams'],
    created(){
        this.obj = this.checkteams
        this.getdata();
    },
    computed: {
         filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.team_title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.develop.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
         }
    },
    methods: {
        getdata(){
            apiServe.getTeams().then(res => {
                this.form = res
            })
        },
        add(e){
            this.$emit('add', e)
        },
        closeModal() {
            this.$emit('close');
        },
        getInitials(string) {
            var names = string.split(' '),
                initials = names[0].substring(0, 1).toUpperCase();
                
                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
        },
    },
    mounted() {
    },
})
</script>
