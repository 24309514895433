<template>
    <!-- модалка подтверждения удаления -->
    <dialogmodal>
        <template v-slot:body>
          <div class="mt-4 text-center">
              <h3><b>{{ $t('twoFunctionsAvailable') }}?</b></h3>
          </div>
        </template>
        <template v-slot:footer-bottom>
              <div class="hstack gap-2 justify-content-center remove">
                 
                  <button class="btn btn-danger" @click="this.$emit('calendar', this.form)">{{ $t('AddReminderInCalendar') }}</button>
                
                  <button class="btn btn-warning" @click="this.$emit('doBoth', this.form)">{{ $t('doBoth') }}</button>
              </div>
              <div class="hstack gap-2 justify-content-center remove">
                  <button class="btn btn-link link-success fw-medium text-decoration-none" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</button>
                  
                  <button class="btn btn-secondary" @click="this.$emit('open', this.form)">{{ $t("open") }}</button>
                  
              </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue'

export default {
    props: ['obj'],
    components: {
        dialogmodal
    },
    data(){
        return{
            from: ""
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        remove(e){
            this.$emit('remove', e)
            this.$emit('close');
        }
    },
    mounted(){
        this.eventKey.on('enter', () => {
            this.remove(this.form)
            this.$emit('close');
        })
    }
}
</script>