<template>
    
    <globalTable 
        :columns="columns" 
        :rows="rows" 
        @search="searchB" 
        @open="open"
    />

</template>

<script>
import globalTable from '@/components/globaltable/index';
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import {
        mutatePriorityTicket,
        mutateProblemEnv,
        mutateHistoryTicketAction
      } from '@/usabilityScripts/globalMutate.js'

export default{
    props: ['obj'],
    components: {
        globalTable
    },
    data(){
        return{
            rows: [],
            columns: [
                {
                    name: this.$t("action"),
                    text: "action",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("ValueTo"),
                    text: "oldPriority",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => {
                        if (obj.action == "changePriority"){
                            return this.mutatepriority(obj.oldPriority).name
                        } else {
                            return this.mutateProblemEnv(obj.oldProblemEnvironment)
                        }
                    },
                    mutateClass: (item) => this.mutatepriority(item).color,
                },
                {
                    name: this.$t("ValueAfter"),
                    text: "newPriority",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => {
                        if (obj.action == "changePriority"){
                            return this.mutatepriority(obj.newPriority).name
                        } else {
                            return this.mutateProblemEnv(obj.newProblemEnvironment)
                        }
                    },
                    mutateClass: (item) => this.mutatepriority(item).color,
                },
                {
                    name: this.$t("date_time"),
                    text: "datetime",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Employee"),
                    text: "workerId",
                    align: "left",
                    status: true,
                    mutate: (item) => this.nameWorker(item),
                }
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.rows = this.obj.changeHistory
        },
        nameWorker(item){
            return nameWorker(item)
        },
        mutatepriority(e){
            // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
        mutateAction(e){
            // мутейт дії
            return mutateHistoryTicketAction(e)
        },
        mutateProblemEnv(e){
            // мутейт середовища проблеми
            return mutateProblemEnv(e)
        },
    },
    computed: {
    }
}
</script>