import { storeS } from '@/store'
import { number } from 'echarts';
import i18n from '@/i18n'

function nameWorker(item) {
    var name = "";
    if(String(item).length <= 3) {
        storeS.workers.forEach((el) => {
            if(el.workerId === item) {
                name = el.workerName
            }
        })
    } else {
        storeS.workers.forEach((el) => {
            if(el.workerPhone === item) {
                name = el.workerName
            } 
            if(name == '') {
                name = item
            }
        })
    }
    return name
}

function tgAccWorker(item) {
    var tgNick = "";

    switch (item) {
        case 32:
            tgNick = "@moks1m";
        break;
        case 33:
            tgNick = "@B1sTOnFire";
        break;
        case 34:
            tgNick = "@golovan_maxim";
        break;
        case 36:
            tgNick = "@JuliaDenidova";
        break;
        case 37:
            tgNick = "@llLostSoull";
        break;
        case 40:
            tgNick = "Немає тг";
        break;
        case 41:
            tgNick = "@hacksli";
        break;
        case 42:
            tgNick = "@d_r_o_p_database";
        break;
        case 43:
            tgNick = "@kultimothy";
        break;
        case 45:
            tgNick = "@mariia_lanko";
        break;
        case 46:
            tgNick = "@InTheBlack";
        break;
        case 47:
            tgNick = "@alekseevna_vb";
        break;
        case 49:
            tgNick = "@vasia_yatsishin";
        break;
        case 50:
            tgNick = "@Lockheed_Mart";
        break;
        case 51:
            tgNick = "@zapah_mandarin";
        break;
        case 52:
            tgNick = "@vikitorin";
        break;
        case 53:
            tgNick = "..";
        break;
        case 54:
            tgNick = "@oleoot";
        break;
        case 55:
            tgNick = "@IIiya0L";
        break;
        case 67:
            tgNick = "@Hikouji";
        break;
        case 71:
            tgNick = "@Lilu_OsO";
        break;
        case 72:
            tgNick = "@Trakerzot_Tsarev";
        break;
        case 75:
            tgNick = "@Mironika55";
        break;
        case 70:
            tgNick = "@odiomendax";
        break;
        default: 
            tgNick = "Немає тг або не доданий";
        break
    }

    return { tgNick }
}

//по accid
function nameWorkerAcc(item) {
    var name = "";
    storeS.workers.forEach((el) => {
        if(el.accountId === item) {
            name = el.workerName
        }
    })
    return name
}


export { nameWorker, nameWorkerAcc, tgAccWorker }