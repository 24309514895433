<template>
    <modal :title="$t('project')" :stylebody="'var(--vz-body-bg)'" @send="send()">
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col
                    lg="9"
                    md="6"    
                >
                    <b-card>
                        <div class="mb-3">
                            <h5 class="kb-title">
                                <b>{{ $t('projectTitle') }}</b>
                            </h5>
                            <input
                                type="text"
                                class="form-control"
                                id="project-title-input"
                                :placeholder="$t('projectTitle')"
                                v-model="this.form.projectName"
                            />
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-4">
                                <div class="mb-3 mb-lg-0">
                                    <label for="choices-priority-input" class="form-label">{{$t('priority')}}</label>
                                    <Multiselect 
                                        v-model="form.priority"
                                        :close-on-select="true"
                                        :searchable="true"
                                        :create-option="false"
                                        :options="this.listPriority"
                                        :placeholder="$t('priority')"
                                    />
                                </div>
                            </div> 
                            <!-- <div class="col-lg-4">
                                <div class="mb-3 mb-lg-0">
                                    <label for="choices-status-input" class="form-label">{{$t('Status')}}</label>
                                    <Multiselect
                                        v-model="form.status"
                                        :close-on-select="true"
                                        :searchable="true"
                                        :create-option="false"
                                        :options="this.listStatus"
                                        :placeholder="$t('Status')"
                                    />
                                </div>
                            </div> -->
                            <div class="col-lg-4">
                                <div class="mb-3 mb-lg-0">
                                    <label for="choices-priority-input" class="form-label">{{$t('project')}}</label>
                                    <Multiselect
                                        v-model="type"
                                        :close-on-select="true"
                                        :searchable="true"
                                        :create-option="false"
                                        :options="typeUpdates"
                                        @select="getUpdates"
                                        :placeholder="'SkyService, Inside'"
                                    />
                                </div>
                             </div>
                             <div class="col-lg-4">
                                <div class="mb-3 mb-lg-0">
                                    <p class="text-muted mb-2">
                                        <a @click="showModalCreate = true" class="link copy float-end text-decoration-underline">{{ $t('Add') }}</a
                                        >{{ $t('Deadline') }}
                                    </p>
                                    <Multiselect
                                            v-model="form.deadlineId"
                                            :close-on-select="true"
                                            :searchable="true"
                                            :create-option="false"
                                            :options="dealinesList"
                                            :disabled="type == ''"
                                        />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">{{ $t('projectDescription') }}</label>
                            <textqe @input="inputText" :value="form.description" :objText="form.description" />
                            <!-- <QuillEditor theme="snow" v-model:content="this.form.description" contentType="html" toolbar="full" @ready="ready" placeholder="Write..." />  -->
                        </div>
                            
                    </b-card>
                    <!-- <b-card>
                        <div class="card-header">
                            <h5 class="card-title mb-0">{{ $t('attachedFiles') }}</h5>
                        </div>
                        <div class="card-body">
                        <div>
                        <p class="text-muted">{{ $t('addAttachedFilesHere') }}</p>

                        <DropZone @drop.prevent="drop" @change="selectedFile" class="mb-2" />
                        <div class="vstack gap-2">
                        <div
                            class="border rounded"
                            v-for="(file, index) of files"
                            :key="index"
                        >
                        <div class="d-flex align-items-center p-2">
                            <div class="flex-grow-1">
                                <div class="pt-1">
                                <h5 class="fs-14 mb-1" data-dz-name="">
                                    {{ file.name }}
                                </h5>
                                <p class="fs-13 text-muted mb-0" data-dz-size="">
                                    <strong>{{ file.size / 1024 }}</strong> KB
                                </p>
                                <strong
                                    class="error text-danger"
                                    data-dz-errormessage=""
                                ></strong>
                                </div>
                            </div>
                            <div class="flex-shrink-0 ms-3">
                                <button
                                data-dz-remove=""
                                class="btn btn-sm btn-danger"
                                @click="deleteRecord"
                                >
                                {{ $t('Delete') }}
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </b-card> -->
                </b-col>
                
                <b-col
                    lg="3"
                    md="6"
                >
                    <b-card>
                        <b-card-title>
                        <div class="d-flex mb-3">
                            <h6 class="card-title mb-0 flex-grow-1">{{ $t('Task') }}</h6>
                        </div>
                        <div class="flex-grow-1 ms-2 mb-2" v-if="this.form.tasks.length == 0">
                            <div class="input-group">
                                <input type="text" v-model="item.taskName" class="form-control" >
                                <button @click="add(item)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                            </div>
                        </div>
                        <ul class="list-unstyled vstack gap-3 mb-0">
                            <li v-for="(input, index) in this.form.tasks" :key="index">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-dark text-black">#{{index}}</div></div>
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="input.taskName" v-on:keyup.enter="add(index)">
                                            <button @click="add(input,index)" class="btn btn-success" type="button"><i class="ri-add-line"></i></button>
                                            <button @click="remove(index)" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                                        </div>
                                        <h6 class="mb-1"><b>{{item.name_report}}</b></h6>
                                        <p class="text-muted mb-0">{{item.name_worker}}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        </b-card-title>
                    </b-card> 
                     <!-- <b-card>
                        <b-card-title>
                        <div class="d-flex mb-3">
                            <h6 class="card-title mb-0 flex-grow-1">{{ $t('link') }}</h6>
                        </div>
                         <ul class="list-unstyled vstack gap-3 mb-0">
                             <li v-for="(input, index) in form.links" :key="index">
                                 <div class="d-flex align-items-center">
                                     <div class="flex-shrink-0">
                                         <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-success text-black">#{{index}}</div></div>
                                     </div>
                                     <div class="flex-grow-1 ms-2">
                                         <div class="input-group">
                                             <input type="text" class="form-control" v-model="input.url">
                                             <button @click="addLink(index)" class="btn btn-success" type="button"><i class="ri-add-line"></i></button>
                                             <button @click="removeLink(index)" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                                         </div>
                                          <h6 class="mb-1"><b>{{item.name_report}}</b></h6>
                                         <p class="text-muted mb-0">{{item.name_worker}}</p> 
                                      </div>
                                 </div>
                             </li>
                         </ul> 
                       </b-card-title>
                    </b-card> -->
                    <!-- <b-card>
                         <b-card-title class="mb-1"> 
                             <div class="card-body">
                                 <div>
                                 <label for="choices-privacy-status-input" class="form-label">{{$t('Status')}}</label>
                                 <Multiselect 
                                    v-model="form.status"
                                    :close-on-select="true"
                                    :searchable="true"
                                    :create-option="true"
                                    :options="this.listStatus"
                                    :placeholder="$t('Status')"
                                />
                                 </div>
                             </div>
                        </b-card-title>
                    </b-card> -->
                    <b-card>
                       <b-card-title class="mb-1">
                            <div class="card-header">
                                <h5 class="card-title mb-0">{{$t('Settings')}}</h5>
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <label for="choices-categories-input" class="form-label">{{$t('Categories')}}</label>
                                    <Multiselect
                                        v-model="this.form.settings.category"
                                        :close-on-select="true"
                                        :searchable="true"
                                        :create-option="false"
                                        :options="this.categoryList" 
                                    />  
                                </div>
                                <!-- <div>
                                    <label for="choices-text-input" class="form-label">{{$t('tags')}}</label>
                                    <Multiselect
                                        v-model="form.tags"
                                        mode="tags"
                                        :close-on-select="false"
                                        :searchable="true"
                                        :create-option="true"
                                        :placeholder="$t('tags')"
                                    />
                                </div> -->
                            </div>
                        </b-card-title>
                    </b-card>
                    <b-card>
                         <b-card-title> 
                            <div class="card-header">
                                <h5 class="card-title mb-0">{{$t('teams')}}</h5>
                            </div>
                        </b-card-title> 
                        <b-card-body> 
                            <div class="mb-3">
                                <Multiselect
                                    v-model="this.form.confStatus"
                                    :close-on-select="true"
                                    :searchable="true"
                                    :create-option="false"
                                    :options=this.teamListNew
                                />
                            </div>
                            <div class="col-lg-12">
                                <label for="choices-priority-input" class="form-label">{{this.sda['024'] ? $t('big_balls') : $t('teamLead')}}</label>
                                    <Multiselect 
                                    v-model="form.teamlead"
                                    :close-on-select="true"
                                    :searchable="true"
                                    :create-option="false"
                                    :options="this.teamLeaders"
                                    :placeholder="$t('teamLead')"
                                    />
                            </div>
                        </b-card-body> 
                    </b-card>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
           <button type="button" v-if="Boolean(this.newform) != false || this.newform == undefined" v-on:click=" addProj() " class="btn btn-success">{{$t('Add') }}</button>
           <button type="button" v-else v-on:click=" editProj() " class="btn btn-success">{{$t('Edit') }}</button>
        </template>
    </modal>
    <dialogOpenVue v-if="showDialogOpen == true " :type="this.type" :obj="this.form" :edit="this.edit" @openFromEdit="openFromEdit" @close="this.showDialogOpen = false; this.dontSave = true; this.$emit('close')" @open="open"></dialogOpenVue>

    <addEvent
        v-if="showAddEvent"
        :obj = "this.form"
        @doBoth = 'open'
        @close="showAddEvent = false"
        :crItem="crItem"
        :doBoth="this.doBothVar"
        @updateCard="updateCard"
    />

    <dialogChooseVue v-if="showDialogChoose == true" :obj="this.form" @calendar = "calendar" @open="open" @doBoth="doBoth" @close="this.showDialogChoose = false; this.dontSave = true; this.$emit('close')"></dialogChooseVue>

    <createDead v-if="showModalCreate == true" @close="this.getUpdates('inProject')"></createDead>
</template>

<script>
import createDead from '../changelog/new.vue'
import { ref, watch } from "vue";
import dialogBox from '@/components/modal/dialog'
import modal from '@/components/modal'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DropZone from "@/components/widgets/dropZone";
import useVuelidate from "@vuelidate/core";
import textqe from '@/components/textQE'
import { Projects, Updates } from '@/API.js';
import { storeS } from '@/store.js'
import { QuillEditor } from '@vueup/vue-quill'
import dialogOpenVue from '@/components/globaltable/actions/dialogOpen.vue';
import addEvent from '@/components/calendar/addEvent'
import dialogChooseVue from './dialogChoose.vue';
import dbPromise from '../../indexedDB';

let apiServe = new Projects();
let apiUpdates = new Updates();

export default {
    components: { 
        createDead,
        dialogBox, 
        modal, 
        Multiselect,
        DropZone,
        flatPickr, 
        QuillEditor,
        textqe,
        dialogOpenVue,
        addEvent,
        dialogChooseVue
    },
    props: ['editform', 'newform'],
    data() {
        return {
            showModalCreate: false,
            typeUpdates: [
                {
                    label: 'SkyService',
                    value: "skyservice"
                },
                {
                    label: 'Pos-terminal',
                    value: "posterminal"
                },
                {
                    label: 'Dashboard',
                    value: "dashboard"
                },
                {
                    label: 'Sky Market',
                    value: "skymarket"
                },
                {
                    label: 'SellUP',
                    value: "sellup"
                },
                {
                    label: this.$t('orderBoard'),
                    value: "hallscreen"
                },
                {
                    label: 'Inside',
                    value: "inside"
                },
                {
                    label: this.$t('onlyForAndroid'),
                    value: "android"
                },
                {
                    label: this.$t('onlyForIOS'),
                    value: "ios"
                },
            ],
            dealinesList: [],
            deadlines: [],
            doBothVar: false,
            showDialogChoose: false,
            showAddEvent: false,
            crItem: {
                id: '',
                type: 'project',
                name: '',
                description: '',
                date: '',
                time: ''
            },
            showDialogOpen: false,
            type: 'project',
            edit: '',
            sda: storeS.checks,
            form:{
                projectName: '',
                description: '',
                deadlineId: '',
                status: 'new',
                priority: '0',
                teamlead: '',
                confStatus: "",
                settings: {
                    category: ""
                },
                tasks: []
            },
            item: {
                taskName: '',
                checked: 0
            },
            teamLeaders: [],
            updateslist: {},
            listPriority: [
                {
                    "label": this.$t('priorityHigh'),
                    "value": "2"
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": "1"
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": "0"
                }
            ],
            listStatus: [
                {
                    "label": this.$t('statusNew'),
                    "value": "new"
                },
                {
                    "label": this.$t('statusInWork'),
                    "value": "statusInWork"
                },
                {
                    "label": this.$t('statusCompleted'),
                    "value": "statusCompleted"
                }
            ],
           
            categoryList: [
                { value: 'Content', label: this.$t('contentPr') },
                { value: 'Development', label: this.$t('development')}
            ],
            teamListNew: [
                {
                    label: "FrontEnd",
                    value: "FrontEnd"
                },
                {
                    label: "BackEnd",
                    value: "BackEnd"
                },
                {
                    label: "Developer",
                    value: "developer"
                }
            ],
            dontSave: false,
        };
    },
    created(){
        // this.getUpdates()
        if(this.newform == false) {
            this.form = this.editform
            this.edit = true
        } else {
            this.edit = false
        }

        //TeamLead list
        storeS.workers.forEach(element => {
            this.teamLeaders.push({
                label: element.workerName,
                value: element.workerId //список работяг 
            })    
        });
        //TeamLead list
    
        //перевірка на наявність не закінченних заявок
        dbPromise.getAll('project')
        //перевірка на наявність не закінченних заявок

        //вибір дії було зроблено, обробка
        
        this.eventBus.off('openOld')
        this.eventBus.on('openOld', status => {
            if(status.status == true) {
                dbPromise.get(status.form.id, status.form.category) // ставимо галочку, що взяли форму, далі вона видалиться
                
                this.form = JSON.parse(status.form.form)                
                if(status.form.text !== '') {
                    this.newform = status.form.text
                }
            }
        })
        
        this.eventBus.off('createNew')
        this.eventBus.on('createNew', status => {
            if(status.status == true) {
                return
            }
        })
        
        //вибір дії було зроблено, обробка
    },
    beforeUnmount() {
        if(this.dontSave == false) {
            if(this.newform == false) {
                sessionStorage.setItem('perm', this.perm)
            }
            if(this.form.projectName !== '' || this.form.description !== '' || this.form.deadlineId !== '' || this.form.teamlead !== '' || this.form.confStatus !== '' || this.form.settings.category !== '' || this.form.tasks.length > 0) {
                
                let detail = {
                    dateTime: new Date(),
                    text: sessionStorage.getItem('perm') ? sessionStorage.getItem('perm') : '',
                    category: 'project',
                    form: JSON.stringify(this.form)
                }; 

                dbPromise.add(detail)
                
                //додавання в indexedDB
            }
        }
    },
    setup() {
        let files = ref([]);
        let dropzoneFile = ref("");
        const drop = (e) => {
        dropzoneFile.value = e.dataTransfer.files[0];
        files.value.push(dropzoneFile.value);
        };
        const selectedFile = () => {
        dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
        files.value.push(dropzoneFile.value);
        };
        watch(
        () => [...files.value],
        (currentValue) => {
            return currentValue;
        }
        );
        return { dropzoneFile, files, drop, selectedFile, v$: useVuelidate() };
    },
    methods: {
        send() {
            if(this.newform == true) {
                this.addProj()
            } else {
                this.editProj()
            }
        },
        getUpdates(a){
            this.deadlines = []
            this.dealinesList = []
            if(this.showModalCreate == true) {
                this.showModalCreate = false
            }
            let objParams = {
                pagelimit: '10000',
                update: this.type,
                status: 'new'
            }
            apiUpdates.getAllUpdates(objParams).then(result => {
                if(result.status == 'done'){
                    result.data.items.forEach(element => {
                        if(element.update == this.type && (new Date().getTime() - new Date(element.deadlineDate + " 23:59").getTime()) <= 0){
                            this.dealinesList.push({
                                label: `${this.$t('app')}: ` + element.update.charAt(0).toUpperCase() + element.update.slice(1) + ` | ${this.$t('version')}: ` + element.version + ` | ${this.$t('date')}: ` + element.deadlineDate,
                                value: element.deadlineId
                            })
                        }
                    });
                } else {
                    this.$toast.error(`error`);
                }
            })
        },
        // changeListUpdate(){
        //     console.log(this.deadlines)
        //     this.deadlines.forEach(element => {
        //         console.log(element, element.update == this.type && (new Date().getTime() - new Date(element.deadlineDate + " 23:59").getTime()) <= 0, element.update == this.type, (new Date().getTime() - new Date(element.deadlineDate + " 23:59").getTime()) <= 0)
        //             if(element.update == this.type && (new Date().getTime() - new Date(element.deadlineDate + " 23:59").getTime()) <= 0){
        //                 this.dealinesList.push({
        //                     label: `${this.$t('app')}: ` + element.update.charAt(0).toUpperCase() + element.update.slice(1) + ` | ${this.$t('version')}: ` + element.version + ` | ${this.$t('date')}: ` + element.deadlineDate,
        //                     value: element.deadlineId
        //                 })
        //             }
        //         // } 
        //     });
        //     // прибираємо дублікати
        //     // let bucket = []

        //     // console.log(this.dealinesList)

        //     // this.dealinesList.forEach(el => {
        //     //     bucket.push(el.value)
        //     // })
        //     // bucket.forEach((index, el) => {
        //     //     console.log(bucket.indexOf(el), el, index, bucket)
        //     //     if(bucket.indexOf(el) !== index) {
        //     //         this.dealinesList.splice(index, 1)
        //     //     }
        //     // })

        //     console.log(this.dealinesList)
        //     // прибираємо дублікати
        // },
        doBoth(e) {
            this.form = e
            this.crItem.id = this.form.projectId;
            this.crItem.name = this.$t('projectWithSmall');
            this.crItem.description = this.filterText(this.form.description);
            this.doBothVar = true
            this.showAddEvent = true;
            this.showDialogChoose = false
        },
        calendar(e) {
            this.form = e
            this.crItem.id = this.form.projectId;
            this.crItem.name = this.$t('projectWithSmall');
            this.crItem.description = this.filterText(this.form.description);
            this.showAddEvent = true;
            this.showDialogChoose = false

        },
        openFromEdit(e) {
            this.showDialogOpen = false
            this.dontSave = true
            this.$emit('close')
            this.$emit('open', e)
        },
        open(e) {
            this.showDialogChoose = false
            if(this.doBothVar == true) {
                this.doBothVar = false;
            }
            this.showDialogOpen = false
            this.dontSave = true
            this.$emit('close')
            let type = 'project'
            this.$emit('open', e, type)
        },
        inputText(text) {
            this.form.description = text.target ? text.target.innerHTML : text;
        },
        add(e, index) {;
            if(this.form.tasks.length > 0){
                this.form.tasks.splice(index+1, 0, {
                    taskName: "",
                    checked: 0
                });
            } else {
                this.form.tasks.push({
                    taskName: e.taskName,
                    checked: 0
                })
            }
        },
        edit(e){
            var checkedItem = this.form.tasks.filter(item => item.checked == true).length;
            this.form.progressBar = checkedItem/this.form.tasks.length*100
            apiServe.editProject(this.form.id, this.form).then(res =>{
                if(res){
                    ("done")
                }
            })

        },
        remove (index) {
            this.form.tasks.splice(index, 1)
        },
        addLink () {
            this.form.links.push({
                url: '',
            })
        },
        removeLink (index) {
            this.form.links.splice(index, 1)
        },
        addProj() {
            if (!this.form.projectName) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
                return
            }
            if (!this.form.description) {
                //перевіряємо чи вказаний опис 
                this.$toast.error(this.$t('EnterDesc'));
            }
            if (!this.form.priority) {
                //перевіряємо чи вказаний пріоритет
                this.$toast.error(this.$t('EnterPriority'));
            }
            if (!this.form.teamlead) {
                //перевіряємо чи вказаний тимлід
                this.$toast.error(this.$t('EnterTeamLead'));
            }
            if (!this.form.confStatus) {
                //перевіряємо чи вказана команда
                this.$toast.error(this.$t('EnterTeam'));
            }
            if (!this.form.settings.category) {
                //перевіряємо чи вказана категорія
                this.$toast.error(this.$t('EnterCategory'));
            }
            if (!this.form.deadlineId) {
                //перевіряємо чи вказаний дедлайн
                this.$toast.error(this.$t('enterDeadline'));
            }
            if(this.form.projectName && this.form.description && this.form.priority && this.form.teamlead && this.form.confStatus && this.form.settings.category && this.form.deadlineId){   
                apiServe.addProject(this.form).then(result => {
                    if(result.status === 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        if(this.checks['025'] == true && this.checks['026'] == true) {
                            this.dontSave == true
                            this.form = result.data
                            this.showDialogChoose = true
                        } else if(this.checks['025'] == true) {
                            this.dontSave == true
                            this.form = result.data
                            this.showDialogOpen = true;
                        } else if(this.checks['026'] == true) {
                            this.dontSave == true
                            this.crItem.id = this.form.projectId;
                            this.crItem.name = this.$t('projectWithSmall');
                            this.crItem.description = this.filterText(this.form.description);
                            this.showAddEvent = true;
                        }  else {
                            this.dontSave == true
                            this.$emit('close')
                            this.$emit('getdata')
                        }
                        this.eventBus.emit('saveProject', true)
                    } else {
                        this.$toast.error('Error #AddProject');
                    }
                })
            }
        },
        filterText(e) {
            return e.replace(/<font[^>]*>/g,'<p>').replace(/<\/font>/g,'</p>');
        },
        editProj() {
            apiServe.editProject(this.form.projectId, this.form).then(result => {
                if(result.status == 'done'){
                    if(this.checks['025'] == true && this.checks['026'] == true) {
                        this.dontSave == true
                        this.form = result.data
                        this.showDialogChoose = true
                    } else if(this.checks['025'] == true) {
                        this.dontSave == true
                        this.form = result.data
                        this.showDialogOpen = true;
                    } else if(this.checks['026'] == true) {
                        this.dontSave == true
                        this.crItem.id = this.form.projectId;
                        this.crItem.name = this.$t('projectWithSmall');
                        this.crItem.description = this.filterText(this.form.description);
                        this.showAddEvent = true;
                    }  else {
                        this.dontSave = true
                        this.$emit('close')
                    }
                    this.$emit('change')
                }
            })
        },
    },
    
    
    mounted(){
    },
    computed: {
        user() {
            return storeS.userbase
        },
        workers() {
            return storeS.workers
        },
        checks(){
            return storeS.checks
        },
        dealines(){
            return storeS.dealines
        }
    }
}
</script>