<template>
    <!-- модалка создания заявки презент -->
    <modal @close="$emit('close')">
        <template v-slot:title>{{ $t('newpresentation') }}</template>
        <template v-slot:head-bottom>
                <div class="" ><button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button></div>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('information_create_application') }}</h4>
                        <input type="number" class="form-control" placeholder="+380" v-model="this.form.userPhone">
                    </div>
                </b-col >
                <b-col lg="6">
                    <div class="mb-3">
                        <!-- тут идут проверки на всякую красоту -->
                        <h4 class="fs-15" v-if="this.perm !== false && this.form.userEmail">{{ $t('mail') }}</h4>
                        <h4 class="fs-15" v-if="this.perm == false">{{ $t('mail') }}</h4>
                        <input type="text" class="form-control" placeholder="m@skyservice.pro" v-if="this.perm !== false && this.form.userEmail" v-model="this.form.userEmail">
                        <input type="text" class="form-control" placeholder="m@skyservice.pro" v-if="this.perm == false" v-model="this.form.userEmail"> 
                        <!-- тут идут проверки на всякую красоту -->
                    </div>
                </b-col >
            </b-row>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('Manager') }}</h4>
                <Multiselect 
                    v-model="this.form.inJobWorkerId"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.managers"
                    :placeholder="this.managers.workerName"
                />
            </div>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('app') }}</h4>
                <Multiselect 
                    v-model="this.form.appName"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.appsPres"
                    :placeholder="this.managers.appName"
                />
            </div>
            <b-row>
                <b-col lg="6" v-if="form.appName == 'skype'">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('login') }}</h4>
                        <input type="text" class="form-control" v-model="this.form.skypeLogin">
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('choose_a_priority') }}</h4>
                        <Multiselect 
                            v-model="this.form.priority"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.listPriority"
                            :placeholder="$t('priority')"
                        />
                    </div>
                </b-col>
            </b-row>
            <div class="mb-3">
                <label class="form-label">{{ $t('Message') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" placeholder="Введите полезную информацию для будущего звонка"></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <!-- <a class="btn btn-link link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </a> -->
            <button type="button" v-if="this.perm === false" class="btn btn-primary" v-on:click="add">{{ $t('Add') }}</button>
            <button type="button" v-else class="btn btn-primary" v-on:click="edit">{{ $t('Edit') }}</button>
        </template>
    </modal>

    <addEvent
        v-if="showAddEvent"
        @close="showAddEvent = false"
        :obj="this.form"
        :crItem="crItem"
    />

    <dialogOpen v-if="this.showDialogOpen == true" :type="this.crItem.type" :edit="this.perm" :obj="this.form" @openFromEdit="openRequestFromEdit" @open="openRequest" @close="this.showDialogOpen=false; this.$emit('close')"></dialogOpen>

</template>

<script>
import modal from '@/components/modal-small'
import addEvent from '@/components/calendar/addEvent'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Presentations } from '@/API.js';
import { storeS } from '@/store';
import dialogOpen from '@/components/globaltable/actions/dialogOpen.vue';
import dbPromise from '../../indexedDB';

let apiServe = new Presentations();

export default ({
    data(){
        return {
            showAddEvent: false,
            showDialogOpen: false,
            crItem: {
                id: '',
                type: 'presentation'
            },
            form: {
                appName: "", //приложение для презентации
                inJobWorkerId: "", // мендежер для презентации
                comment: "", // коммент
                userPhone: "", //телефон клиента
                priority: "0", //приоритет
                userEmail: "", //почта
                skypeLogin: "" //login скайп
            },
            checked: false,
            checkedname: false,
            selectedStatus: "",
            selectedPriority: "",
            listStatus: [
                {
                    "label": this.$t('statusNew'),
                    "value": "new"
                },
                {
                    "label": this.$t('statusrescheduled'),
                    "value": "rescheduled"
                }
            ],
            listPriority: [
                {
                    "label": this.$t('priorityHigh'),
                    "value": "2"
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": "1"
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": "0"
                }
            ],
            appsPres: [
                {
                    "value": "skype", 
                    "label": "Skype",
                    "enable": true
                },
                {
                    "value": "zoom", 
                    "label": "Zoom",
                    "enable": true
                },
                {
                    "value": "telegram", 
                    "label": "Telegram",
                    "enable": true
                },
                {
                    "value": "viber", 
                    "label": "viber",
                    "enable": false
                }
            ],
            dontSave: false,
            managers: [],
        }
    },
    props: ["phone", "obj", "perm"],
    components: { 
        modal, 
        Multiselect, 
        addEvent,
        dialogOpen
    },
    created(){
        this.listManager();
        if(this.perm == true) {
            this.form = this.obj
            this.form.comment = this.form.createComment
        }

        //перевірка на наявність не закінченних заявок
        dbPromise.getAll('presentation')
        //перевірка на наявність не закінченних заявок

        this.eventBus.off('openOld')
        this.eventBus.on('openOld', status => {
            console.log(status)
            if(status.status == true) {
                dbPromise.get(status.form.id, status.form.category)
                this.form = JSON.parse(status.form.form)
                if(status.form.text !== '') {
                    this.perm = status.form.text
                }
            }
        })  
    },
    beforeUnmount() {
        if(this.dontSave == false) {
            if(this.perm) {
                sessionStorage.setItem('perm', this.perm)
            }
            sessionStorage.setItem('actual', JSON.stringify(this.form))
            if(this.form.appName != ""  || this.form.userPhone != "" || this.form.userEmail != "" || this.form.skypeLogin != "") {
                
                let detail = {
                    dateTime: new Date(),
                    text: sessionStorage.getItem('perm') ? sessionStorage.getItem('perm') : '',
                    category: 'presentation',
                    form: JSON.stringify(this.form)
                }; 

                dbPromise.add(detail) //додавання в indexedDB
            }
        }
    },
    methods: {
        openRequest(e){
            this.showDialogOpen = false;
            let type = 'presentation'
            this.$emit('open',e, type) 
        },
        openRequestFromEdit(e){
            this.showDialogOpen = false;
            this.$emit('closeLast');
            this.$emit('open',e) 
        },
        edit() {
            if (!this.form.appName) {
                //перевіряємо чи вказаний додаток
                this.$toast.error(this.$t('EnterAppName'));
                return
            }
            if (!this.form.comment) {
                //перевіряємо чи вказаний комент
                this.$toast.error(this.$t('EnterDesc'));
                return
            }
            if (!this.form.userPhone) {
                //перевіряємо чи вказаний номер
                this.$toast.error(this.$t('EnterNumberC'));
                return
            }
            if (!this.form.priority) {
                //перевіряємо чи вказаний пріоритет
                this.$toast.error(this.$t('EnterPriority'));
                return
            }
            let id = this.form.presentationId;
            delete this.form.presentationId
            delete this.form.accountId
            delete this.form.createDatetime
            delete this.form.createComment
            delete this.form.createWorkerId
            delete this.form.delayedDatetime
            delete this.form.status //удаляем ненужные поляя
            
            apiServe.edit(id, this.form).then(result => {
                if(result.status==='done') {
                    this.$toast.success(this.$t('alertChanged'));
                    if(this.check['025'] == true) {
                        this.dontSave = true
                        this.form = result.data
                        this.type = 'presentation'
                        this.showDialogOpen = true

                    } else {
                        this.dontSave = true
                        this.$emit('close')
                    }
                } else {
                    this.$toast.error(this.$t('error')); 
                }
            })
        },
        add() {
            if (!this.form.appName) {
                //перевіряємо чи вказаний додаток
                this.$toast.error(this.$t('EnterAppName'));
                return
            }
            if (!this.form.comment) {
                //перевіряємо чи вказаний комент
                this.$toast.error(this.$t('EnterDesc'));
                return
            }
            if (!this.form.userPhone) {
                //перевіряємо чи вказаний номер
                this.$toast.error(this.$t('EnterNumberC'));
                return
            }
            if (!this.form.priority) {
                //перевіряємо чи вказаний пріоритет
                this.$toast.error(this.$t('EnterPriority'));
                return
            }

            if(this.form.appName && this.form.comment && this.form.userPhone && this.form.priority){
                apiServe.add(this.form).then(result => {
                    if(result.status === 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.eventBus.emit('savePres', true)
                        if(this.check['025'] == true) {
                            this.dontSave = true
                            this.form = result.data
                            this.type = 'presentation'
                            this.showDialogOpen = true

                        } else {
                            this.dontSave = true
                            this.$emit('close')
                        }
                        // this.showAddEvent = true
                    } else {
                        this.$toast.error(this.$t('error') + ' #AddPresent'); 
                    }//запрос к серверу
                })
            }
        },
        listManager(){
            this.workers.forEach((el) => {
                if(el.workerJob === 'managercallcenter'){
                        this.managers.push({
                            label:el.workerName,
                            value: el.workerId
                        })
                    }
            }) 
        },
        currentDate() {
            var date = new Date();
            var dateStr =
            ("00" + date.getMonth()).slice(-2) + "." +
            ("00" + (date.getDate() + 1)).slice(-2) + "." +
            date.getFullYear() + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
            return dateStr
        },
    },
    mounted(){
    },
    computed: {
        user() {
            return storeS.userbase
        },
        workers() {
            return storeS.workers
        },
        check(){
            return storeS.checks
        }
    }
})
</script>
