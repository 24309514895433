<template>
    <!-- Лівий блок зі списком компаній -->
    <div class="border-end border-top border-bottom">
        <div style="margin: 10px;" >
            <div class="d-flex align-items-center mb-2" @click="showCompany = !showCompany" style="cursor: pointer;">
                <div class="flex-grow-1" >
                    <h5 class="card-title mb-0">{{ $t('AllCompany') }}
                        <i :class="`${showCompany ? 'ri-arrow-down-line' : 'ri-arrow-right-line'} fs-16`"></i>
                    </h5>
                </div>
            </div>
            <div class="d-flex align-items-center py-1" style="margin: 10px;" v-if="showCompany">
                <div class="flex-grow-1">
                    <div>
                        <h5 class="fs-14 mb-1 text-muted"><i class="bx bx-info-circle fs-16"></i> {{ $t('Account') }}</h5>
                        <p class="fs-15 mb-0 fw-normal" style="word-break: break-all;">{{ form.accountId }}</p>
                    </div>
                </div>
                <div class="flex-shrink-0 ms-2">
                    <button type="button" @click="copy(form.accountId)" class="btn btn-sm btn-outline-success fs-14"><i class="bx bx-copy-alt align-middle"></i></button>
                </div>
            </div>
            <div class="d-flex align-items-center py-2" v-if="this.company" style="margin: 10px;">
                <div class="flex-grow-1">
                    <div>
                        <h5 class="fs-14 mb-1 text-muted"><i class="bx bx-info-circle fs-16"></i> {{ $t('admin_panel') }}</h5>
                        <p class="fs-15 mb-0 fw-normal" style="word-break: break-all;">{{ this.company.more.companyServer != 's-1' ? this.$t('NewAdmin_panel') : this.$t('OldAdmin') }}</p>
                    </div>
                </div>
                <div class="flex-shrink-0 ms-2">
                    <button type="button" @click="copy(companyName)" class="btn btn-sm btn-outline-success fs-14"><i class="bx bx-copy-alt align-middle"></i></button>
                </div>
            </div>
            <div class="d-flex align-items-center py-2" v-if="companyId" style="margin: 10px;">
                <div class="flex-grow-1">
                    <div>
                        <h5 class="fs-14 mb-1 text-muted"><i class="bx bx-info-circle fs-16"></i> {{ $t('company') }}</h5>
                        <p class="fs-15 mb-0 fw-normal" style="word-break: break-all;">{{ companyName }}</p>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center py-2" v-if="companyId" style="margin: 10px;">
                <div class="flex-grow-1">
                    <div>
                        <h5 class="fs-14 mb-1 text-muted"><i class="bx bx-info-circle fs-16"></i> {{ $t('company') }} (ID)</h5>
                        <p class="fs-15 mb-0 fw-normal" style="word-break: break-all;">{{ companyId }}</p>
                    </div>
                </div>
                <div class="flex-shrink-0 ms-2">
                    <button type="button" @click="copy(companyId)" class="btn btn-sm btn-outline-success fs-14"><i class="bx bx-copy-alt align-middle"></i></button>
                </div>
            </div>
        </div>
        
        <b-row v-if="showCompany">
            <b-col>
                <div class="mb-3" style="margin: 10px;">
                    <input type="text" class="form-control" v-model="search" />
                </div>
                <div class="scroll_comp breakText">
                    <b-card v-for="item in filteredItems" :key="item" class="card-animate" style="cursor: pointer;" @click="openComp(item.companyId)">
                            <div class="d-flex mb-4 align-items-center" >
                                <div class="flex-grow-1 " >
                                    <h5 class="card-title mb-1 fs-14 text-white">{{ item.companyName }}</h5>
                                    <!-- <p class="text-muted mb-0">{{ item.companyId != "" ? item.companyId : '-' }}</p> -->
                                </div>
                            </div>
                            <!-- <h6 :class="`fs-12 text-white`">{{ item.personAdmin }}</h6> -->
                    </b-card>
                    <b-card v-for="item in filteredItemsDel" :key="item" class="card-animate del-card" style="cursor: pointer;" @click="openComp(item.companyId)">
                            <div class="d-flex mb-4 align-items-center" >
                                <div class="flex-grow-1 " >
                                    <h5 class="card-title mb-1 fs-14 text-white">{{ item.companyName }}</h5>
                                    <!-- <p class="text-muted mb-0">{{ item.companyId != "" ? item.companyId : '-' }}</p> -->
                                </div>
                            </div>
                            <!-- <h6 :class="`fs-12 text-white`">{{ item.personAdmin }}</h6> -->
                    </b-card>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {copy} from '@/usabilityScripts/copy.js'

export default({
    props: ['obj', 'companyId', 'companyName', 'company'],
    data(){
        return {
            search: '',
            form: '',
            companies: '',
            showCompany: false
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj
            this.companies = this.obj.сompanies
        },
        openComp(accID){
            this.$emit('opencompany', accID)
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    },
    computed: {
        filteredItems: function() {
            // По полю пошуку
            return this.companies?.filter(item => {
                    return this.search == '' || item.companyName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        filteredItemsDel: function() {
            // По полю пошуку
            return this.form.deleted_companies?.filter(item => {
                    return this.search == '' || item.companyName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
    }
})
</script>

<style>
.scroll_comp .card {
    /* margin-right: 10px; */
    /* max-width: 250px; */
    background-color: #009688;
}
.scroll_comp .del-card {
    background: #ee664e;
}
.breakText h5{
    word-break: break-all;
}
</style>