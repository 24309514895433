<template>
    <!-- модальное окно закрытия проекта -->
    <dialogBox @close="this.$emit('close')">
        <template v-slot:title>{{$t('copy')}}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label class="form-label">Выберете проект</label>
                <select class="form-control">
                    <option v-for="item in this.rows" :key="item">{{ item }}</option>
                </select>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-danger fw-medium" @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            <button 
                type="button" 
                class="btn btn-success" 
                @click="toCopy()"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { Projects } from '@/API'
import { storeS } from '@/store'

let apiServe = new Projects();

export default {
    props: ['id', 'checkTask'],
    components: {
        dialogBox
    },
    data(){
        return {
            form: {
                comment: "",
            },
            rows: [],
            objParams:{
                page: '1',
                pagelimit: '9999',
                search: '',
            },
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            apiServe.getProjects(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                }
            })
        },
        toCopy(){
            apiServe.completeProject(this.id, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(`done`);
                    this.eventBus.emit('UpdateCard', true)
                    this.$emit('close')
                } else if(result.status == 'error'){
                    this.$toast.error(result.error[0]);
                }
            })
        }
    }
}
</script>