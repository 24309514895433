<template>
    <!-- 
        Модальне вікно створення картки штрафу співробітнику
    -->

    <modal>
        <!-- Слот шапки модального вікна  -->
        <template v-slot:title>{{ $t('addFine') }}</template>

        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>

            <!-- Кнопка для закриття модального вікна -->
            <button 
                type="button" 
                class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  
                @click="$emit('close')"
            >
                <i class="ri-close-fill align-bottom"></i>
            </button>

        </template>

        <!-- Слот тіло модального вікна з формою для введення даних -->
        <template v-slot:body>

            <!-- Вибір співробітника -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('Employee') }}</h4>
                <Multiselect 
                    v-model="form.workerId"
                    :close-on-select="true"
                    :searchable="true"
                    :options="this.workersList"
                    :placeholder="$t('enterSearchTerm')"
                />
            </div>

            <!-- Вибір типу штрафу -->
            <div class="mb-4">
                <h4 class="fs-15">{{ $t('typeFine') }}</h4>
                <Multiselect 
                    v-model="form.type"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="true"
                    :options="this.types"
                    :placeholder="$t('enterSearchTerm')"
                />
            </div>

            <!-- Розбиття на дві колонки для введення статусу та суми штрафу -->
            <div class="row">
                <!-- Вибір статусу -->
                <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('choose_a_status') }}</h4>
                        <Multiselect 
                            v-model="form.status"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.status"
                            :placeholder="$t('Status')"
                        />
                    </div>
                </div>
                <!-- Введення суми штрафу -->
                <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Enter_sum_fine') }}</h4>
                        <Multiselect 
                            v-model="form.sum"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="true"
                            :options="this.sumSevice"
                            :placeholder="$t('Sum')"
                        />
                    </div>
                </div>
            </div>
            <!-- Введення коментаря -->
            <div class="mb-3">
                <label for="VertimeassageInput" class="form-label">{{ $t('Message') }}</label>
                <div class="mt-1">
                    <QuillEditor v-model:content="form.message" theme="snow" contentType="html" toolbar="full" />
                </div>
            </div>
        </template>

        <!-- Слот нижньої частини модального вікна з кнопкою для додавання штрафу -->
        <template v-slot:footer-bottom>

            <!-- Кнопка для додавання штрафу -->
            <button 
                type="button"
                class="btn btn-success" 
                @click="add"
            >
                {{ $t('Add') }}
            </button>

        </template>
    </modal>
</template>
    
<script>
import modal from '@/components/modal-small' // компонент модального вікна
import Multiselect from "@vueform/multiselect"; // компонент мультіселектора
import "@vueform/multiselect/themes/default.css"; // стилі до компоненту мультіселектора
import { Fines } from '@/API' // клас API, який викорстовується для запиту
import { storeS } from '@/store.js' // Глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Fines();
    
export default ({
    props: ['obj'],
    data(){
        return {
            form: { // Дані для форми
                type:"", // тип штрафу
                status: "waitingСonfirmation", // статус штрафу
                sum: "", // сума штрафу
                message: "", // коментар до штрафу
                workerId: "" // id співробітника кому штраф
            },
            workersList: [{}], // список співробітників
            status: [ 
                {
                    // статус "анульовано"
                    "label": this.$t('annul'),
                    "value": "annul"
                },
                {
                    // статус "чекає на підтвердження"
                    "label": this.$t('AwaitingСonfirmation'),
                    "value": "waitingСonfirmation"
                },
                {
                    // статус "підтверджено"
                    "label": this.$t('Confirmed'),
                    "value": "confirmed"
                }
            ],
            types: [ // список типів штрафу
                {
                    // тип штрафу "запізнення"
                    "label": this.$t('beinglate'),
                    "value": "beinglate"
                },
                {
                    // тип штрафу "не виконано завдання у термін"
                    "label": this.$t('taskNotCompletedTime'),
                    "value": "taskNotCompletedTime"
                },
                {
                    // тип штрафу "не виконано проект у термін дедлайну"
                    "label": this.$t('projectNotCompletedTime'),
                    "value": "projectNotCompletedTime"
                },
                {
                    // тип штрафу "Образлива поведінка стосовно клієнта"
                    "label": this.$t('rudeBehavior'),
                    "value": "rudeBehavior"
                },
            ],
            sumSevice: [ // суми штрафу
                {
                    "label": "0",
                    "value": "0"
                },
                {
                    "label": "100",
                    "value": "100"
                },
                {
                    "label": "200",
                    "value": "200"
                },
                {
                    "label": "300",
                    "value": "300"
                },
                {
                    "label": "500",
                    "value": "500"
                },
                {
                    "label": "1000",
                    "value": "1000"
                },
                {
                    "label": "1500",
                    "value": "1500"
                }
            ]
        }
    },
    components: { modal, Multiselect },
    created(){

        // Встановлення значень форми на основі об'єкта, якщо він існує
        if(this.obj){
            this.form.workerId = this.obj.workerId;
            this.form.type = this.obj.typeLate;
            this.form.status = this.obj.status;
            this.form.sum = this.obj.sum;
            this.form.message = this.obj.comment
        }
        
        // Заповнення списку співробітників для вибору в формі
        for(var item in this.workers){
            this.workersList.push({
                label: this.workers[item].workerName,
                value: this.workers[item].workerId,
            })
        }
    },
    methods: {
        add() {
            /**
                Додає новий штраф за допомогою відправки даних до API.
            */

            apiServe.addFine(this.form.workerId, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Added'));
                    // this.eventBus.emit('saveFines', true)
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('err'));

                    var tempObj = {
                        "workerId": this.form.workerId,
                        "params": this.form
                    }

                    var errorText = 'Помилка створення штрафу'; 
                    var statusRequest = result.status;
                    var api = 'addFine';
                    var fileRequest = 'src/views/users/view/formright/fines/create.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
    },
    computed: {
        user() {
            // Властивість, яка повертає дані юзера з глобального сховища
            return storeS.userbase
        },
        workers() {
            // Властивість, яка повертає список співробітників з глобального сховища
            return storeS.workers
        }
    }
})
</script>
