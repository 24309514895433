<template>
    <modal>
        <!-- Слот шапки модального вікна  -->
        <template v-slot:title>{{ $t('Детальна інформація по завданням') }}</template>

        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <!-- Кнопка для закриття модального вікна, та збереження інформації, щоб продовжити її потім -->
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <template v-slot:body>
            <!-- Left Icon Accordions -->
            <div class="accordion lefticon-accordion custom-accordionwithicon accordion-border-box" id="accordionlefticon">

                <template v-for="(item, index) in tasks" :key="item">
                    <div class="accordion-item">
                        <h2 class="accordion-header" :id="'accordionlefticonExample'+index">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#accor_lefticonExamplecollapse'+index" aria-expanded="true" :aria-controls="'accor_lefticonExamplecollapse'+index">
                                {{ item.taskName }}
                            </button>
                        </h2>
                        <div :id="'accor_lefticonExamplecollapse'+index" class="accordion-collapse collapse" :aria-labelledby="'accordionlefticonExample'+index" data-bs-parent="#accordionlefticon">
                            <div class="accordion-body">
                                {{ item.taskDesc }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>

    </modal>
</template>

<script>
import modal from '@/components/modal-small'

export default{
    components: {
        modal
    },
    data(){
        return{ 
            form:'',
            tasks: [
                {
                    taskName: 'Тип завдання "Увімкнути нову версію"',
                    taskDesc: 'У кабінеті клієнта створюється нова компанія "SkyNew", в ній буде доступне оновлення',
                    taskTime: 'Обробка займає до 3 робочих днів з моменту подання заявки на відновлення.'
                },
                {
                    taskName: 'Тип завдання "Увімкнути стару версію"',
                    taskDesc: 'У кабінеті клієнта створюється нова компанія "SkyOld"',
                    taskTime: 'Обробка займає до 3 робочих днів з моменту подання заявки на відновлення.'
                }
            ]
        }
    }
}
</script>