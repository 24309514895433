<template>
    <!-- Компонент для вибору проекту по оновленням -->

    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="type-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
        >
            {{ $t('update') }}
        </label>
        <br />
        <div class="row g-2 list_filtr" >
             <!-- Вибір "Усі" -->
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioupdate" id="all_update" v-model="selectupdate" :value="''">
                    <label class="form-check-label" for="all_update">{{ $t('all') }}</label>
                </div>
            </div>
            <!-- Цикл для виведення чекбоксів для кожного проекту -->
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioupdate" :id="'update'+index" v-model="selectupdate" :value="value.value">
                        <label class="form-check-label" :for="'update'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { storeS } from '@/store';

export default {
    data(){
        return {
            selectupdate: "", // Обраний проект
            opt: [ // Масив зі списком проектів
                {
                    name: 'SkyService',
                    value: "skyservice"
                },
                {
                    name: 'Pos-terminal',
                    value: "posterminal"
                },
                {
                    name: 'Dashboard',
                    value: "dashboard"
                },
                {
                    name: 'Sky Market',
                    value: "skymarket"
                },
                {
                    name: 'SellUP',
                    value: "sellup"
                },
                {
                    name: this.$t('orderBoard'),
                    value: "hallscreen"
                },
                {
                    name: 'Inside',
                    value: "inside"
                },
                {
                    name: this.$t('onlyForAndroid'),
                    value: "android"
                },
                {
                    name: this.$t('onlyForIOS'),
                    value: "ios"
                },
            ]
        }
    },
    methods: {
        change(){
            // Метод, що викликається при зміні фільтру

            this.$emit('change', 'update', this.selectupdate)
        },
    },
    computed: {
    }
}
</script>