<template>
    <!-- Компонент для вибору постачальника -->

    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
        >
            {{ $t('Provider') }}
        </label>

        <br />
        <div class="row g-2 list_filtr" >
            <!-- Вибір "Усі" -->
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" name="radioProv" id="all_prov" v-model="provider" :value="0">
                    <label class="form-check-label" for="all_prov">{{ $t('all') }}</label>
                </div>
            </div>

            <!-- Цикл для виведення радіокнопок для кожного постачальника -->
            <template v-for="(value, index) in providers" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" name="radioProv" :id="'provider'+index" v-model="provider" :value="value.providerId">
                        <label class="form-check-label" :for="'provider'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { storeS } from '@/store'; // глобальне сховище даних

export default {
    data(){
        return {
            provider: "" // Обраний постачальник
        }
    },
    computed: {
        providers(){
            /*
                Повертає дані постачальників з глобального стору.
            */
            return storeS.providers
        }
    }
}
</script>