<template>
    <!-- вікно глобального пошуку -->
    <teleport to="body">
        <div class="modal fade bs-example-modal-lg show" tabindex="-1" aria-labelledby="myLargeModalLabel"  aria-modal="true" role="dialog" style="display: block;">
            <div class="modal-dialog modal-lg " >
            <!-- <div class="modal-dialog modal-lg modal-dialog-centered" > -->
                <div class="modal-content" >
                    <div class="modal-header border-bottom pb-2" >
                        <button class="d-none d-lg-flex d-xl-flex btn  btn-icon btn-sm fs-16" style="margin-right: 5px;"><i class="bx bx-search-alt align-bottom"></i></button>
                        <input class="form-control" style="border: none;" v-model="search" autofocus @input="this.searchB(this.search)"/>
                        <button type="button" class="btn-close" style="margin-left: 5px;" @click="this.$emit('close')"></button>
                    </div>
                    <div class="modal-body" >
                        <div class="mb-3 buttons">
                            <button type="button" @click="this.currentIdx = 0; this.searchB()" class="btn " :class="this.currentIdx == 0 ? 'btn-dark' : 'btn-outline-primary'">{{ this.$t('globalSearch') }}</button>
                            <button type="button" @click="this.currentIdx = 1; this.searchB()" class="btn " :class="this.currentIdx == 1 ? 'btn-success' : 'btn-outline-success'">{{ this.$t('lessons') }}</button>
                            <button type="button" @click="this.currentIdx = 2; this.searchB()" class="btn " :class="this.currentIdx == 2 ? 'btn-danger' : 'btn-outline-danger'">{{ this.$t('posts') }}</button>
                        </div>
                        <b-row v-if="this.search == ''">
                            <b-col lg="6" v-for="item in blocks" :key="item">
                                <div class="dropdown-header mt-2" >
                                    <h6 class="text-overflow fw-semibold fs-14 text-muted mb-1 text-uppercase">{{ item.title }}</h6>
                                </div>
                                <template v-if="item.type == 'req'">
                                    <div v-for="key in item.items" :key="key" @click="this[key.showModal] = true">
                                        <span v-if="isShowItem(key)" @click="this.$emit(key.showModal)" class="dropdown-item notify-item" style="display: block;cursor: pointer; margin: 7px 0px;" >
                                            <i :class="`${key.icon} align-middle fs-18 text-muted me-2`"></i>
                                            <span>{{ key.title }}</span>
                                        </span>
                                    </div>
                                </template>
                                <template v-if="item.type == 'pages'">
                                    <div @click="this.$emit('close')" v-for="key in item.items" :key="key">
                                        <router-link :to="key.path" v-if="isShowItem(key)" >
                                            <span class="dropdown-item notify-item" style="display: block;cursor: pointer; margin: 7px 0px;" >
                                                <i :class="`${key.icon} align-middle fs-18 text-muted me-2`"></i>
                                                <span>{{ key.title }}</span>
                                            </span>
                                        </router-link>
                                    </div>
                                </template>
                            </b-col>
                        </b-row>
                        <b-row v-if="this.search != ''">
                            <b-col lg="12" v-if="this.currentIdx === 0">
                                <div class="dropdown-header mt-2" >
                                    <h6 class="text-overflow fw-semibold text-muted mb-1 text-uppercase">{{ $t('Result') }}</h6>
                                </div>
                                <div class="dropdown-item bg-transparent text-wrap border-bottom mb-2 pb-2" >
                                    <button @click="getClient(search)" class="btn btn-soft-secondary btn-sm btn-rounded fw-semibold">{{ $t('cardClient') }}<i class="mdi mdi-magnify ms-1"></i></button>
                                    <button @click="openticket(search)" class="btn btn-soft-secondary btn-sm btn-rounded fw-semibold" style="margin-left: 5px;">{{ $t('ticket') }}<i class="mdi mdi-magnify ms-1"></i></button>
                                    <button @click="openProject(search)" class="btn btn-soft-secondary btn-sm btn-rounded fw-semibold" style="margin-left: 5px;">{{ $t('project') }}<i class="mdi mdi-magnify ms-1"></i></button>
                                    <button @click="openInv(search)" class="btn btn-soft-secondary btn-sm btn-rounded fw-semibold" style="margin-left: 5px;">{{ $t('invoic') }}<i class="mdi mdi-magnify ms-1 "></i></button>
                                </div>
                                <template v-for="item in filteredItems" :key="item">
                                    <span class="fs-14 fw-semibold text-info pt-3 pb-3">{{ item.page }} </span>
                                    <span v-if="item.type == 'reqt' && isShowItem(item)" @click="this.$emit(item.showModal)" class="dropdown-item notify-item" style="display: block;cursor: pointer;" >
                                        <i :class="`${item.icon} align-middle fs-18 text-muted me-2`"></i>
                                        <span>{{ item.title }}</span>
                                    </span>
                                    <router-link :to="item.path" v-if="item.type == 'pages' && isShowItem(item)" @click="this.$emit('close')">
                                        <span v-if="item.type == 'pages'" class="dropdown-item notify-item" style="display: block;cursor: pointer;" >
                                            <i :class="`${item.icon} align-middle fs-18 text-muted me-2`"></i>
                                            <span>{{ item.title }}</span>
                                        </span>
                                    </router-link>
                                </template>
                            </b-col>
                            <b-col lg="12" v-if="this.currentIdx === 1">
                                <div class="dropdown-header mt-2" >
                                    <h6 class="text-overflow fw-semibold text-muted mb-1 text-uppercase">{{ $t('Result') }}</h6>
                                </div>
                                <div class="dropdown-item bg-transparent text-wrap border-bottom mb-2 pb-2" >
                                    
                                </div>
                                <b-list-group class="list-group-circle mt-1">
                                    <b-list-group-item
                                        v-for="item in searchItemsCustom"
                                        :key="item"
                                        @click="open(item, 'KW')"
                                        :style="`cursor: pointer`"
                                        style="text-align: center;"
                                    >
                                        {{ item.lessonName }}  <span class="badge bg-danger" :style="`background-color: ${this.findKWCateg(item).color} !important;`">{{ this.findKWCateg(item).category }}</span>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-col>
                            <b-col lg="12" v-if="this.currentIdx === 2">
                                <div class="dropdown-header mt-2" >
                                    <h6 class="text-overflow fw-semibold text-muted mb-1 text-uppercase">{{ $t('Result') }}</h6>
                                </div>
                                <div class="dropdown-item bg-transparent text-wrap border-bottom mb-2 pb-2" >
                                    
                                </div>
                                <b-list-group class="list-group-circle mt-1">
                                    <b-list-group-item
                                        v-for="item in searchItemsCustom"
                                        :key="item"
                                        @click="open(item, 'FAQ')"
                                        :style="`cursor: pointer`"
                                        style="text-align: center;"
                                    >
                                        {{ item.question }}  <span class="badge bg-danger" :style="`background-color: ${this.findFAQCateg(item).color} !important;`">{{ this.findFAQCateg(item).category }}</span>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-col>
                        </b-row>
                        <!-- <template v-if="this.search != ''">
                            <tabsMain />
                        </template> -->
                    </div>
                    <div class="modal-footer" >
                        <button class="btn link-success fw-medium" @click="this.$emit('close')" ><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>

    <viewticketbox v-if="showModalTicket == true" @close="showModalTicket = false" :obj="this.cardApplic" ></viewticketbox>

    <viewinvoicebox v-if="showModalInv" @close="showModalInv = false" :obj="obj"/>

    <!-- картка проекту -->
    <viewProjectBox 
        v-if="showModalProject"
        @close="showModalProject = false" 
        :obj="this.obj" 
    />

</template>

<script>
import viewticketbox from '@/views/newtickets/view/index.vue'
import viewProjectBox from '@/views/projects/view/index.vue'
import viewinvoicebox from '@/views/invoices/view'
import tabsMain from './tabs/index' 
import { Clients, Tickets, Projects, Invoices, KW, FAQ } from "@/API.js"
import { storeS } from '@/store'

let apiServe = new Clients();
let apiTickets = new Tickets();
let apiProjects = new Projects();
let apiInvoices = new Invoices();
let apiKW = new KW();
let apiFAQ = new FAQ()

export default{
    components:{ 
        viewticketbox, 
        viewinvoicebox, 
        viewProjectBox,
        tabsMain
    },
    data(){
        return{
            search: "",
            client: {
              data: null
            },
            currentIdx: 0,
            form: "",
            show_in_preloader: false,
            cardApplic: "",
            showModalTicket: false,
            showModalProject: false,
            showModalInv: false,
            obj: "",
            objParamsPost: {
                pagelimit: '200',
                search: ''
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                lang: '',
                tags: ''
            },
            searchItems: [
                {
                    title: this.$t('AddApplication'),
                    page: this.$t('calls_all'),
                    type: "reqt",
                    icon: "ri-phone-fill",
                    showModal: "openrequestbox",
                    show: true,
                    perm: 101
                },
                {
                    title: this.$t('AddPresentation'),
                    page: this.$t('presentations'),
                    icon: "bx bx-fullscreen",
                    showModal: "openpresentbox",
                    show: true,
                    perm: 111
                },
                {
                    title: this.$t('AddTask'),
                    page: this.$t('tasks'),
                    type: "reqt",
                    icon: "bx bx-task",
                    showModal: "opentaskbox",
                    show: true,
                    perm: 201
                },
                {
                    title: this.$t('AddTicket'),
                    page: this.$t('tickets'),
                    type: "reqt",
                    icon: "ri-ticket-2-fill",
                    showModal: "openticketbox",
                    show: true,
                    perm: 301
                },
                {
                    title: this.$t('addInvoice'),
                    page: this.$t('invoices'),
                    type: "reqt",
                    icon: "ri-wallet-fill",
                    showModal: "openinvoicesbox",
                    show: true,
                    perm: 1701
                },
                {
                    title: this.$t('AddProject'),
                    page: this.$t('navProjects'),
                    type: "reqt",
                    icon: "ri-pages-line",
                    showModal: "openprojectbox",
                    show: true,
                    perm: 351
                },
                {
                    title: this.$t('addWorker'),
                    page: this.$t('users'),
                    type: "reqt",
                    icon: "ri-user-2-fill",
                    showModal: "openuserbox",
                    show: true,
                    perm: 701
                },
                {
                    title: this.$t('calls_all'),
                    type: "pages",
                    icon: 'ri-phone-fill',
                    path: '/calls/all',
                    show: true,
                    perm: 100
                },
                {
                    title: this.$t('presentations'),
                    type: "pages",
                    path: '/calls/presentations',
                    icon: 'bx bx-fullscreen',
                    show: true,
                    perm: 110
                },
                {
                    title: this.$t('tasks'),
                    type: "pages",
                    path: '/tasks/all',
                    icon: 'ri-task-line',
                    show: true,
                    perm: 200
                },
                {
                    title: this.$t('tickets'),
                    type: "pages",
                    path: '/newtickets/all',
                    icon: 'ri-ticket-2-fill',
                    show: true,
                    perm: 300
                },
                {
                    title: this.$t('navProjects'),
                    type: "pages",
                    path: '/projects/all',
                    icon: 'ri-pages-line',
                    show: true,
                    perm: 350
                },
                {
                    title: this.$t('statistics'),
                    type: "pages",
                    path: '/statistics/all',
                    icon: 'bx bx-analyse',
                    show: true,
                    perm: 504
                },
                {
                    title: this.$t('Services'),
                    type: "pages",
                    path: '/services/all',
                    icon: 'bx bxs-info-square',
                    show: true,
                    perm: 510
                },
                {
                    title: this.$t('Products'),
                    type: "pages",
                    path: '/ecommerce/products',
                    icon: 'bx bxs-shopping-bags',
                    show: true,
                    perm: 1300
                },
                {
                    title: this.$t('invoices'),
                    type: "pages",
                    path: '/invoices/all',
                    icon: 'ri-safe-2-fill',
                    show: true,
                    perm: 1700
                },
                {
                    title: this.$t('users'),
                    type: "pages",
                    path: '/users/users',
                    icon: 'ri-team-fill',
                    show: true,
                    perm: 700
                },
                {
                    title: this.$t('Accounts'),
                    type: "pages",
                    path: '/accounts/allAccounts',
                    icon: 'ri-account-box-fill',
                    show: true,
                    perm: 1810
                }
            ],
            blocks: [
                {
                    title: this.$t('Creation'),
                    type: "req",
                    show: true,
                    items: [
                        {
                            title: this.$t('AddApplication'),
                            icon: "ri-phone-fill",
                            showModal: "openrequestbox",
                            show: true,
                            perm: 101
                        },
                        {
                            title: this.$t('AddPresentation'),
                            icon: "bx bx-fullscreen",
                            showModal: "openpresentbox",
                            show: true,
                            perm: 111
                        },
                        {
                            title: this.$t('AddTask'),
                            icon: "bx bx-task",
                            showModal: "opentaskbox",
                            show: true,
                            perm: 201
                        },
                        {
                            title: this.$t('AddTicket'),
                            icon: "ri-ticket-2-fill",
                            showModal: "openticketbox",
                            show: true,
                            perm: 301
                        },
                        {
                            title: this.$t('addInvoice'),
                            icon: "ri-wallet-fill",
                            showModal: "openinvoicesbox",
                            show: true,
                            perm: 1701
                        },
                        {
                            title: this.$t('AddProject'),
                            icon: "ri-pages-line",
                            showModal: "openprojectbox",
                            show: true,
                            perm: 351
                        },
                        {
                            title: this.$t('addWorker'),
                            icon: "ri-user-2-fill",
                            showModal: "openuserbox",
                            show: true,
                            perm: 701
                        }
                    ]
                },
                {
                    title: this.$t('Sections'),
                    type: "pages",
                    show: true,
                    items: [
                        {
                            title: this.$t('calls_all'),
                            path: '/calls/all',
                            icon: 'ri-phone-fill',
                            show: true,
                            perm: 100
                        },
                        {
                            title: this.$t('presentations'),
                            path: '/calls/presentations',
                            icon: 'bx bx-fullscreen',
                            show: true,
                            perm: 110
                        },
                        {
                            title: this.$t('tasks'),
                            path: '/tasks/all',
                            icon: 'ri-task-line',
                            show: true,
                            perm: 200
                        },
                        {
                            title: this.$t('tickets'),
                            path: '/newtickets/all',
                            icon: 'ri-ticket-2-fill',
                            show: true,
                            perm: 300
                        },
                        {
                            title: this.$t('navProjects'),
                            path: '/projects/all',
                            icon: 'ri-pages-line',
                            show: true,
                            perm: 350
                        },
                        {
                            title: this.$t('Calendar'),
                            path: '/calendar',
                            icon: 'ri-calendar-todo-fill',
                            show: true,
                            perm: ''
                        },
                        {
                            title: this.$t('Notes'),
                            path: '/note',
                            icon: 'bx bx-notepad',
                            show: true,
                            perm: ''
                        },
                        {
                            title: this.$t('callcenterStatistics'),
                            path: '/statistics/callcentre',
                            icon: 'bx bx-analyse',
                            show: true,
                            perm: 502
                        },
                        {
                            title: this.$t('develStatistics'),
                            path: '/statistics/dev',
                            icon: 'bx bx-analyse',
                            show: true,
                            perm: 503
                        },
                        {
                            title: this.$t('usersStatistics'),
                            path: '/statistics/all',
                            icon: 'bx bx-analyse',
                            show: true,
                            perm: 504
                        },
                        {
                            title: this.$t('Fines'),
                            path: '/statistics/fines',
                            icon: 'bx bx-money-withdraw',
                            show: true,
                            perm: 505
                        },
                        {
                            title: this.$t('timeOff'),
                            path: '/statistics/holidays',
                            icon: 'bx bxs-calendar-event',
                            show: true,
                            perm: 520
                        },
                        {
                            title: this.$t('Services'),
                            path: '/services/all',
                            icon: 'bx bxs-info-square',
                            show: true,
                            perm: 510
                        },
                        {
                            title: this.$t('Products'),
                            path: '/ecommerce/products',
                            icon: 'bx bxs-shopping-bags',
                            show: true,
                            perm: 1300
                        },
                        {
                            title: this.$t('invoices'),
                            path: '/invoices/all',
                            icon: 'ri-safe-2-fill',
                            show: true,
                            perm: 1700
                        },
                        {
                            title: this.$t('users'),
                            path: '/users/users',
                            icon: 'ri-team-fill',
                            show: true,
                            perm: 700
                        },
                        {
                            title: this.$t('Accounts'),
                            path: '/accounts/allAccounts',
                            icon: 'ri-account-box-fill',
                            show: true,
                            perm: 1810
                        }
                    ]
                }
            ],
            componentKey: 0,
            interval: '',
            activeCateg: [],
            colors: [
                { color: '#1ABC9C', title: '1ABC9C' },
                { color: '#2ECC71', title: '2ECC71' },
                { color: '#3498DB', title: '3498DB' },
                { color: '#9B59B6', title: '9B59B6' },
                { color: '#34495E', title: '34495E' },
                { color: '#F1C40F', title: 'F1C40F' },
                { color: '#F39C12', title: 'F39C12' },
                { color: '#E74C3C', title: 'E74C3C' },
                { color: '#C2185B', title: 'C2185B' },
                { color: '#BDC3C7', title: 'BDC3C7' },
                { color: '#95A5A6', title: '95A5A6' },
                { color: '#607D8B', title: '607D8B' },
            ],
            searchItemsCustom: [],
            filteredItems: []
        }
    },
    created(){
        this.filteredItems = this.searchItems
        storeS.modalsOpen.push('index')
    },
    mounted() {
        this.eventKey.off('esc')
        this.eventKey.on('esc', () => {
            console.log(storeS.modalsOpen)
            if(storeS.modalsOpen[storeS.modalsOpen.length - 1] == 'index' && !document.querySelector('#preloader')) {
                storeS.modalsOpen.pop()
                this.close()
            } else {
                this.componentKey++
            }
        });
    },
    methods:{
        open(e, type){
            console.log(e, type)
            if(type === 'FAQ') {
                this.eventBus.emit('openFAQ', {
                    form: e
                })
            } else {
                this.eventBus.emit('openKW', {
                    form: e
                })
            }
        },
        findKWCateg(e){
            let colorPicked = '';
            if(this.activeCateg.find(el => el.categoryId === e.categoryId)) {
                colorPicked = this.activeCateg.find(el => el.categoryId === e.categoryId).color
            } else {
                let rand = Math.round(Math.random()*this.colors.length) - 1
                if(rand === -1) {
                    colorPicked =  this.colors[rand + 1].color
                } else {
                    colorPicked =  this.colors[rand].color
                }
                this.activeCateg.push({categoryId: e.categoryId, color: colorPicked})
            }
            return {
                category: this.knowledgeBaseCategories.find(el => el.categoryId == e.categoryId)?.categoryName,
                color: this.activeCateg.find(el => el.categoryId === e.categoryId)?.color
            }
        },
        findFAQCateg(e){
            let colorPicked = '';
            if(this.activeCateg.find(el => el.categoryId === e.categoryId)) {
                colorPicked = this.activeCateg.find(el => el.categoryId === e.categoryId).color
            } else {
                let rand = Math.round(Math.random()*this.colors.length) - 1
                if(rand === -1) {
                    colorPicked =  this.colors[rand + 1].color
                } else {
                    colorPicked =  this.colors[rand].color
                }
                this.activeCateg.push({categoryId: e.categoryId, color: colorPicked})
            }
            return {
                category: this.FAQCategories.find(el => el.categoryId == e.categoryId)?.categoryName,
                color: this.activeCateg.find(el => el.categoryId === e.categoryId)?.color
            }
        },
        searchB(e) {
            console.log(e, this.search)
            clearTimeout(this.interval)
            // this.searchItems = []
            if(this.search != '') {
                this.interval = setTimeout( () => {
                    if(this.currentIdx === 0) {
                        this.filteredItems = this.searchItems.filter(item => item.title.toLowerCase().includes(e.toLowerCase()))
                    } else if(this.currentIdx === 1) {
                        this.searchItemsCustom = []
                        this.activeCateg = []
                        this.objParamsPost.search = this.search
                        apiKW.getPosts(this.objParamsPost).then(result => {
                            if(result.status == 'done'){
                                this.searchItemsCustom = result.data.items;
                            } else {
                                this.$toast.error(this.$t('err'))
                            }
                        }) // search in KnowledgeBase
                    } else if(this.currentIdx === 2) {
                        this.searchItemsCustom = []
                        this.activeCateg = []
                        this.objParamsPost.search = this.search
                        apiFAQ.getPosts(this.objParamsPost).then(result => {
                            if(result.status == 'done'){
                                this.searchItemsCustom = result.data.items;
                            } else {
                                this.$toast.error(this.$t('err'))
                            }
                        }) // search in FAQ
                    }
                }, 777)
            }
        },
        isShowItem(option){
            var status = '';
            if(option.perm == ''){
                status = true
            } else {
                status = this.perms[option.perm]
            }
            return status;
        },
        openticket(id){
          apiTickets.getTicket(id).then(result => {
            if(result.status == 'done'){
              this.$toast.success(this.$t('TicketFound') + ` # ` + id);
              this.cardApplic = result.data;
              this.showModalTicket = true;
            } else if(result.status == 'error') {
              this.$toast.error(this.$t('NotFoundTicket') + ` # ` + id);
            }
          }).catch(err => {
            this.$toast.error(this.$t('NotFoundTicket') + ` # ` + id);
          })
        },
        openProject(id){
            apiProjects.getCardProjects(id).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('ProjectFound') + ` # ` + id);
                    this.obj = result.data;
                    this.showModalProject = true;
                } else if(result.status == 'error') {
                    this.$toast.error(this.$t('NotFoundProject') + ` # ` + id);
                }
            }).catch(err => {
                this.$toast.error(this.$t('NotFoundProject') + ` # ` + id);
            })
        },
        openInv(id){
            storeS.preloader = true
            apiInvoices.getInv(id).then(result => {
                if(result.status === 'done'){
                    this.obj = result.data;
                    this.showModalInv = true;
                } else if(result.status == 'error'){
                    this.$toast.error(this.$t('NotFoundInv') + ` # ` + id);
                }
            }).catch(err => {
                this.$toast.error(this.$t('NotFoundInv') + ` # ` + id);
            })
        },
        getClient(num) {
            var customNumber = num.toString();
            var numcode = ["039", "067", "068", "096", "097", "098", "050", "066", "095", "099", "063", "073", "093", "091", "092", "089", "094"]
            for(var item in numcode) {
              if(customNumber.substr(0, 3) == numcode[item] ) {
                customNumber = "38" + this.number
              }
            }
            
            this.number = customNumber
            console.log(this.number)
            this.eventBus.emit('getClient', {status: true, number: this.number})
        },
        close: function(){
          this.$emit('close');
        },
    },
    computed: {
        // filteredItems() {
        //     const knowledgeBaseSearchQueryLower = this.search.toLowerCase();
            
        //     return this.searchItems.
        //     filter(item => 
        //     item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower))

            
        // },
        perms() {
            return storeS.perms
        },
        knowledgeBaseCategories() {
            return storeS.KBCategories
        },
        FAQCategories() {
            return storeS.FAQCateg
        }
    }
} 
</script>

<style scoped>
.modal{
    background: #2e2e2eb8;
}
.buttons {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
}
</style>