<template>
    <dialogBox 
        @close="this.$emit('close')"
    >
      <template v-slot:title>{{ $t('Add') }}</template>
      <template v-slot:body>
            <div class="mb-3">
                <!-- <h4 class="fs-15 text-left">{{$t('enterCateg')}}</h4> -->
                <b-row>
                        <b-col lg="4">
                            <label class="fs-14 fw-semibold">{{ $t('selectType') }}</label>
                            <select
                                class="form-select mb-3"
                                v-model="selected.selectedType"
                            >
                            <option v-for="typ in listType" :key="typ" :value="typ" >{{typ.title}}</option>
                          </select>
                        </b-col>
                        <b-col lg="4" v-if="selected.selectedType != ''">
                            <label class="fs-14 fw-semibold">{{ $t('selectValue') }}</label>

                            <select
                                v-if="selected.selectedType.value == 'Result'"
                                class="form-select mb-3"
                                v-model="selected.selectedValue"
                            >
                                <option v-for="val in listResults" :key="val" :value="val" >{{val.title}}</option>
                            </select>

                            <select
                                v-if="selected.selectedType.value == 'Gateway'"
                                class="form-select mb-3"
                                v-model="selected.selectedValue"
                            >
                                <option v-for="val in listGateway" :key="val" :value="val" >{{val.title}}</option>
                            </select>
                            
                            
                        </b-col>
                        <b-col lg="3" v-if="selected.selectedType != '' && selected.selectedValue != ''">
                            <label class="fs-14 fw-semibold">{{ $t('selectColor') }}</label>
                            <span class="copy" v-if="selected.selectedType != '' && selected.selectedValue != ''" @click="openColorBox = true">
                                <div class="rounded text-white text-center p-2 fw-semibold" :style="`word-break: break-all;background-color: ${selected.selectedColor};`">
                                    {{ selected.selectedValue.title }}
                                </div>
                            </span>
                        </b-col>
                        <b-col lg="1" v-if="selected.selectedType != '' && selected.selectedValue != ''">
                            <button type="button" class="btn btn-soft-success btn-icon btn-sm fs-16" style="margin-right: 5px;margin-top: 30px;" @click="changeTabs(selected)"><i class="ri-add-fill align-bottom"></i></button>
                        </b-col>
                </b-row>
            </div>
            <div>
                <template v-for="(item, index) in othertabs" :key="item">
                    <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2">
                        <div class="copy ">
                            <!-- <div class="avatar-title bg-light text-secondary rounded fs-24"><i class="ri-video-line"></i></div> -->
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :id="item.tab + index"
                                v-model="item.checked"
                            />
                        </div>
                        <div class="flex-grow-1 ms-3 copy">
                            <label class="fs-14 fw-semibold text-white copy" :for="item.tab+index" :style="`background: ${item.color};padding: 8px;border-radius:6px;display:block;width:90%;`">
                                {{ item.title }}
                            </label>
                        </div>
                        <div class="hstack gap-3 fs-16">
                            <!-- <a class="text-muted copy">
                                <i class="ri-edit-2-line"></i>
                            </a> -->
                            <a class="text-muted copy" @click="removeTab(index)">
                                <i class="ri-delete-bin-line"></i>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button type="button" class="btn btn-success mt-3" @click="save">{{ $t('save') }}</button>
        </template>
    </dialogBox>

    <dialogBox v-if="openColorBox" @close="openColorBox = false; editTitle = false">
        <template v-slot:title>{{ $t('change color') }}</template>
        <template v-slot:body>
            <div class="border-bottom mb-3 pb-3">
                <b-row>
                    <b-col lg="12">
                        <div class="rounded text-white text-center p-3" :style="`background-color: ${selected.selectedColor};`">
                            <span @dblclick="editTitle = true" v-if="editTitle == false" class="fs-16 fw-semibold">{{ selected.selectedValue.title }}</span>
                            <input class="form-control" v-model="selected.selectedValue.title" :style="`border: none;border-bottom: 1px solid white;background: ${selected.selectedColor};color:white`" v-if="editTitle == true" />
                        </div>
                    </b-col>
                </b-row>
            </div>
            <b-card>
                <b-row>
                    <template v-for="item in colorsNew" :key="item">
                        <b-col lg="2" class="mb-2 copy" @click="selected.selectedColor = item.color">
                            <div class="avatar-sm" >
                                <div class="avatar-title rounded text-white" :style="`background-color: ${item.color};`"></div>
                            </div>
                        </b-col>
                    </template>
                </b-row>
            </b-card>
        </template>
        <template v-slot:footer-bottom>
            <button type="button" class="btn btn-success mt-3" @click="openColorBox = false; editTitle = false">{{ $t('save') }}</button>
        </template>
    </dialogBox>

</template>

<script>
// import dialogBox from '@/components/modal/dialog'
import dialogBox from '@/components/modal/modal-lg'
import { storeS } from '@/store'

export default{
    props: ['othertabs'],
    components: { dialogBox },
    data(){
        return{
            form: '',
            openColorBox: false,
            editTitle: false,
            selected: {
                selectedType: '',
                selectedValue: '',
                selectedColor: '#1ABC9C',
            },
            listType: [
                { title: this.$t('searc_inp'), value: 'search' },
                { title: this.$t('date'), value: 'pr1' },
                { title: this.$t('Result'), value: 'Result' },
                { title: this.$t('Gateway'), value: 'Gateway' },
            ],
            listResults: [
                { title: this.$t('notSuccessful'), value: 'failure' },
                { title: this.$t('successfully'), value: 'success' },
            ],
            listGateway: [
                { title: this.$t('new'), value: 'new' },
                { title: this.$t('statusInWork'), value: 'jobs' },
                { title: this.$t('statusCompleted'), value: 'statusCompleted' },
            ],
            colorsNew: [
                { color: '#1ABC9C', title: '1ABC9C' },
                { color: '#2ECC71', title: '2ECC71' },
                { color: '#3498DB', title: '3498DB' },
                { color: '#9B59B6', title: '9B59B6' },
                { color: '#34495E', title: '34495E' },
                { color: '#F1C40F', title: 'F1C40F' },
                { color: '#F39C12', title: 'F39C12' },
                { color: '#E74C3C', title: 'E74C3C' },
                { color: '#C2185B', title: 'C2185B' },
                { color: '#BDC3C7', title: 'BDC3C7' },
                { color: '#95A5A6', title: '95A5A6' },
                { color: '#607D8B', title: '607D8B' },
            ],
            listDate: [
                {title: this.$t('Today'), value: 'today'},
                {title: this.$t('Yesterday'), value: 'yesterday'}
            ],
            listWorkers: [],
            tabs: [],
        }
    },
    created(){
        for(var item in this.workers){
            this.listWorkers.push({
                title: this.workers[item].workerName,
                value: this.workers[item].workerId
            })
        }
        this.tabs = this.othertabs;
    },
    methods: {
        inputSearch(e){
            var obj = {
                title: e.target.value,
                value: e.target.value
            }
            this.selected.selectedValue = obj
        },
        changeTabs(selected){
            var tab = {
                title: selected.selectedValue.title,
                // tab: "priorityMedium",
                tab: selected.selectedType.value+selected.selectedValue.value,
                color: selected.selectedColor,
                obj: selected.selectedType.value,
                value: selected.selectedValue.value,
                checked: true
            }	
            this.tabs.push(tab)
            // this.$emit('changeTabs', this.tabs)
            // this.$emit('close');
        },
        removeTab(i){
            this.tabs.splice(i, 1)
        },
        save(){
            this.$emit('changeTabs', this.tabs)
            this.$emit('close');
        }
    },
    computed: {
        workers(){
            return storeS.workers
        }
    }
}
</script>