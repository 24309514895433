<template>
    <!-- 
        Модальне вікно підтвердження запиту відгулу
    -->

    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>
            <!-- коментар до підтвердження -->
            <div class="mb-3">
                <label class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <!-- 
                Кнопка "Додати" 
                - підтвердження запиту відгулу та додає коментар (якщо вказали)
            -->
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="toConfirm"
            >
                {{ $t('Confirm') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент вікна

export default{
    components: {
        dialogBox,
    },
    props: ['id'],
    data(){
        return{
            form: {
                comment: '',
            },
        }
    },
    created() {
    },
    methods: {
        toConfirm(){
            // звертаємось до методу підтвердження з API
            // закриваємо вікно

            this.$emit('toConfirm', this.id, this.form)
            this.$emit('close')
        },
    }
}
</script>