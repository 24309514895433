<template>
    <!-- 
        Вкладка проектів, дозволяє - 
        - переглянути проекти за спіробітником
        - та відкрити картку проекту
        * відкритий розробникам та ceo
    -->

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - @open - подія, яка спрацьовує при відкритті картки штрафу
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @search - подія, яка спрацьовує при пошуку у таблиці
    -->
    <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows"
        :pages="pages"
        :showpag="true"
        @getdata="getdata"
        @open="open"
    />

    <!-- 
       Виклик відкриття картки проекту
       - для відкриття застосовується перевірка значення modalShowview
       - @close - подія, яка спрацьовує при закритті картки
       - :obj - параметр-об'єкт з даними про проект
       - :ifPrevopen - параметр, який передає статус відображення стрілочки назад
       - :ifNextopen - параметр, який передає статус відображення стрілочки вперед
       - @prevOpen - подія, яка спрацьовує коли натискаєш на стрілочку назад
       - @nextOpen - подія, яка спрацьовує коли натискаєш на стрілочку вперед
       - @getdata - подія, яка спрацьовує при отриманні даних для таблиці (оновлення списку)
    -->
    <viewbox 
        v-if="modalShowview === true" 
        @close="modalShowview = false" 
        :obj="this.obj" 
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen"
        @getData="getdata"
    ></viewbox>

</template>

<script>
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewbox from '@/views/projects/view/index' // картка проекту
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { Projects } from "@/API.js"; // клас для взаємодії з API 
import { storeS } from "@/store" // глобальне сховище даних
import { mutateStatusProject, mutatePriorityTicket, getDateDeadline } from '@/usabilityScripts/globalMutate.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Projects();

export default {
    props: ['id'],
    components: { tablecustom, viewbox },
    data() {
        return {
            ifNextopen: "", // Прапорець статусу відображення кнопки "<"
            ifPrevopen: "", // Прапорець статусу відображення кнопки ">"
            modalShowview: false, // Прапорець відображення вікна проекту
            obj: {}, // Об'єкт з даними штрафу для відображення в модальному вікні
            pages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: false,  // true - показати кнопку фільтр, false - сховати
                page: "breaks"
            },
            objParams: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                search: '',
                workerId: ''
            },
            rows: [], // Дані для відображення у таблиці
            columns: [ 
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: 'ID',
                    text: "projectId",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("Point_name"),
                    text: "projectName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutatestatus(item).name,
                    mutateClass: (item) => this.mutatestatus(item).cl 
                },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutatepriority(item).name,
                    mutateClass: (item) => this.mutatepriority(item).color 
                },
                {
                    name: this.$t("Progress"),
                    text: "progressPercent",
                    align: "right",
                    status: true
                },
                {
                    name: this.$t("Deadline"),
                    text: "deadlineId",
                    align: "right",
                    status: true,
                    mutate: (item) => item != null ? this.getDeadline(item) : '-'
                    // getDeadline
                },
                {
                    name: 'TeamLead',
                    text: "teamlead",
                    align: "right",
                    status: true,
                    mutate: (item) => item != null ? this.nameWorker(item) : '-'
                    // getDeadline
                }
            ],
        }
    },
    created(){
        // Ініціалізація компонента та отримання даних
        this.getdata()
    },
    methods: {
        getdata(page){
            /**
                Отримує дані про проекти з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Number} page - Номер сторінки для отримання даних.
            */

            this.objParams.page = page != undefined ? page : '1';
            this.objParams.workerId = this.id;

            // Отримання даних про проекти з API
            apiServe.getProjects(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                    this.pages = result.data.links;
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            })
        },
        open(e){
            /**
                Функція для відкриття модального вікна з інформацією про проект за його ідентифікатором.
                * @param {Object|String} e - Ідентифікатор проекту або об'єкт з інформацією про робітника.

                Викликає API для отримання інформації про проект.
                Результат присвоюється властивості obj, та встановлюється прапорці modalShowview в true для відображення модального вікна.

                Для відображення стрілочок перемикання вперед та назад - 
                * ifPrevopen - перевіряється, чи є ще картка проекту, до поточної відкритої картки
                * ifNextopen - перевіряється, чи є ще картка проекту, після поточної відкритої картки
            */

            // Визначення ідентифікатора проекту
            var id = e.projectId ? e.projectId : e;

            // Перевірка, чи проект є першим у списку для відкриття вікна "Попередній проект".
            this.ifPrevopen = id == this.rows[0].projectId ? false : true;
            
            // Перевірка, чи проект є останнім у списку для відкриття вікна "Наступний проект".
            this.ifNextopen = id == this.rows[this.rows.length-1].projectId ? false : true;

            apiServe.getCardProjects(id).then(res =>{
                if(res.status === 'done'){
                    this.obj = res.data
                    this.modalShowview = true
                } else {
                    this.$toast.error(this.$t('err'));

                    var errorText = 'Помилка відкриття картки проекту'; 
                    var statusRequest = result.status;
                    var api = 'getCardProjects';
                    var fileRequest = 'src/views/users/view/formright/projects/index.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        prevOpen(e){
            /*
                Функція для відкриття модального вікна попереднього запису у списку
                Отримує ідентифікатор поточного проекту, знаходить ідентифікатор попереднього проекту в списку та викликає функцію open для відображення інформації про проект.
            */

            var current_id = e;
            var prev_id = ""; 

            for(var value in this.rows){
                if(this.rows[value].projectId == current_id){
                    prev_id = this.rows[Number(value)-Number('1')].projectId;
                }
            }
            this.open(prev_id);
        },
        nextOpen(e){
            /*
                Функція для відкриття модального вікна наступного запису у списку
                Отримує ідентифікатор поточного проекту, знаходить ідентифікатор наступного проекту в списку та викликає функцію open для відображення інформації про проект.
            */

            var current_id = e;
            var next_id = "";

            for(var value in this.rows){
                if(this.rows[value].projectId == current_id){
                    next_id = this.rows[Number(value)+Number('1')].projectId;
                }
            }
            this.open(next_id);
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
            */

            this.objParams.search = e;
            this.getdata();
        },
        mutatestatus(e){
            /*
                Функція для застосування перекладу до статусу проекту
            */
            return mutateStatusProject(e)
        },
        mutatepriority(e){
            /*
                Функція для застосування перекладу до пріоритету проекту
            */
            return mutatePriorityTicket(e)
        },
        getDeadline(item){
            /*
                Функція для застосування дедлайну
            */
            return getDateDeadline(item)
        },
        nameWorker(item){
            /*
                Функція для застосування імені співробітника
            */
            return nameWorker(item)
        }
    },
    computed: {
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        deadlines(){
            /*
                Повертає дані дедлайнів з глобального стору.
            */
            return storeS.dealines
        },
        workers(){
            /*
                Повертає дані співробітників з глобального стору.
            */
            return storeS.workers
        }
    }
}
</script>