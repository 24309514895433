<template>

    <!-- 
        Модальне вікно з відображенням детальної інформації по рішенню тікета 
    -->
    
    <modal @close="this.$emit('close')">
        <!-- Заголовок модалки -->
        <template v-slot:title>{{ $t('Details') }} <span style="text-transform: lowercase;">{{ $t('Decision') }}</span></template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>

            <b-alert show variant="info">Дані фіксуються з 28.08.2024</b-alert>

            <b-card>

                <h5 class="fs-14 mb-3 text-info fw-semibold">{{ $t('AdditionalonProblem') }}</h5>

                <!-- Чому виникла проблема -->
                <div class="mb-3" >
                    <div class="me-3 mb-1 text-muted fs-14" >
                        {{ $t('WhydidProblemOccur') }}
                    </div>
                    <div class="" >
                        <p class="d-block fw-semibold mb-0 fs-16">{{ form.problemIn != "" ? form.problemIn : "-" }}</p>
                    </div>
                </div>

                <!--  Чи була ця проблема раніше -->
                <div class="mb-3" >
                    <div class="me-3 mb-1 text-muted fs-14" >
                        {{ $t('HasProblemHappenedBefore') }}
                    </div>
                    <div class="mt-2">
                        <p :class="`fw-semibold mb-0 fs-14 badge bg-${form.problemWas ? 'danger' : 'success'}`">{{ form.problemWas ? $t('y') : $t('n') }}</p>
                    </div>
                </div>

                <template v-if="form.problemWas == true"> 

                    <!-- Чому виникла проблема -->
                    <div class="mb-3" >
                        <div class="me-3 mb-1 text-muted fs-14" >
                            {{ $t('HowLonghasProblemKnown') }}
                        </div>
                        <div class="">
                            <p class="fw-semibold mb-0 fs-16">{{ this.mutateProblemKnow(form.sinceProblemKnown) }}</p>
                            <!-- <p class="fw-semibold mb-0 fs-16">{{ form.sinceProblemKnown }}</p> -->
                        </div>
                    </div>

                    <!-- Причина довгого вирішення проблеми -->
                    <div class="mb-3" v-if="form.solutionReason" >
                        <div class="me-3 mb-1 text-muted fs-14" >
                            {{ $t('reasonForLongtermSolutionProblem') }}?
                        </div>
                        <div class="">
                            <p class="fw-semibold mb-0 fs-16">{{ form.solutionReason != "" ? form.solutionReason : "-" }}</p>
                        </div>
                    </div>

                </template>

            </b-card>

            <b-card v-if="this.form.decidedStatus == 'waittesting' || this.form.decidedStatus == 'waitrelease' || this.form.decidedStatus == 'inrelease'" >

                <h5 class="fs-14 mb-3 text-danger fw-semibold">{{ $t('Testing') }}</h5>

                <!--  Чи була ця проблема раніше -->
                <div class="mb-3" >
                    <div class="me-3 mb-1 text-muted fs-14" >
                        {{ $t('HasSolutionToticketBeenTested') }}
                    </div>
                    <div class="mt-2">
                        <p :class="`mb-0 fs-14 badge bg-${form.passedTest ? 'success' : 'danger'}`">{{ form.passedTest ? $t('y') : $t('n') }}</p>
                    </div>
                </div>

                <!-- Ким проводилось тестування? -->
                <div class="mb-3" v-if="form.passedTest == true" >
                    <div class="me-3 mb-1 text-muted fs-14" >
                        {{ $t('WhoConductedtheTesting') }}
                    </div>
                    <div class="mt-3">
                        <div style="max-height: 215px;overflow: auto;"> 
                            <ul class="list-group">

                                <template v-for="(item, index) in form.whoPassedTest" :key="index">
                                    <li class="list-group-item">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-grow-1">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 ms-2">
                                                        <h6 class="fs-14 mb-0">{{item.workerName}}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </template>

                            </ul>
                        </div>
                    </div>
                </div>

            </b-card>

        </template>

    </modal>


</template>

<script>
import modal from '@/components/modal/dialog' // компонент модального вікна
import { mutateSinceProblemKnown } from '@/usabilityScripts/globalMutate.js'

export default {
    props: ['objCard'],
    components: {
        modal
    },
    data(){
        return{
            form: ''
        }
    },
    created() {
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objCard
            console.log('form', this.form);
            
        },
        mutateProblemKnow(e){
            /*
                Функція для застосування перекладу до періоду
            */
            return mutateSinceProblemKnown(e)
        },
    }
}

</script>
