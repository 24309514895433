<template>
    <modal :title="form.name" @close="this.$emit('close')">
        <template v-slot:head-bottom>
            <div class="" >
                <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
            </div>
        </template>
        <template v-slot:body>

            <skymarketbox v-if="form.name == 'skymarket'" :obj="form.settings" />

            <sellupbox v-if="form.name == 'sellup'" :obj="form.settings" />

            <monobox v-if="form.name == 'monobank'" :obj="form.data" />

            <privatbox v-if="form.name == 'privatbank'" :obj="form.data" />

        </template>
    </modal>
    <hallscreenbox v-if="form.name == 'hallscreen'" :obj="form.data" @close="this.$emit('close')" />
</template>

<script>
import modal from '@/components/modal/modal-lg'
import skymarketbox from './skymarket/view'
import sellupbox from './sellup/view'
import monobox from './mono/view'
import privatbox from './privat/view'
import hallscreenbox from '../devices/view.vue'

export default ({
    props: ['obj'],
    components: { 
        modal, 
        skymarketbox, 
        sellupbox, 
        monobox, 
        privatbox, 
        hallscreenbox 
    },
    data() {
        return{
            form: "",
            showModal: true
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
    
    }
})
</script>
