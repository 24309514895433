import axios from 'axios';
import { storeS } from '@/store'

export function sendTelegramMessage(errorText, status, api, file, params, response) {
    
    const telegramToken = '6328417623:AAH8mfQB4aaUuDAksDmIY7drH_YGo4Ibqg8';
    const telegramChatId = '-4049553957'; 

    // Формуємо повідомлення для логу
    const logMessage = `
Користувач - ${storeS.userbase.name}
Токен - ${storeS.token}
Акаунт - ${storeS.userbase.accountId}

---------------------

Помилка - ${errorText}
Статус відповіді - ${status}
API - ${api}
Файл з якого викликається запит - ${file}
Параметри запиту - ${JSON.stringify(params)}
Відповідь помилки від серверу - ${JSON.stringify(response)}
`;

    const telegramApiUrl = `https://api.telegram.org/bot${telegramToken}/sendMessage`;

    return axios.post(telegramApiUrl, {
        chat_id: telegramChatId,
        text: logMessage
    });
}

export function sendTelegramDangerOperation(nameMethod, phoneClient, companyId, descText, createWorkerId) {
    // Лог на небезпечні операції
    
    const telegramToken = '6328417623:AAH8mfQB4aaUuDAksDmIY7drH_YGo4Ibqg8';
    const telegramChatId = '-4049553957'; 

    // Формуємо повідомлення для логу
    const logMessage = `
!!! Небезпечна операція !!!
Операція - ${nameMethod}
Користувач - ${createWorkerId}

---------------------

Номер клієнта - ${phoneClient}
ID компанії - ${companyId}

---------------------
${descText}
`;

    const telegramApiUrl = `https://api.telegram.org/bot${telegramToken}/sendMessage`;

    return axios.post(telegramApiUrl, {
        chat_id: telegramChatId,
        text: logMessage
    });
}


// Функція для вилучення HTML-тегів з рядка
function stripHtml(htmlString) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText;
}

export function sendTelegramTicket(ticketdId, type, problemEnvironment, createWorkerId, ticketName, createComment, reason, tgAccAuthorTicket) {
    
    const telegramToken = '731690536:AAEC6VhPcuzDRo8gqTU3PLPMOe8ymyLe0ZE';
    const telegramChatId = '-4208669213'; 

    // Використання функції stripHtml для вилучення HTML-тегів з createComment
    var createCommentDesc = stripHtml(createComment);

// Формуємо повідомлення
const logMessage = `
!!! Тікет з Критичним пріоритетом !!!
Створено новий тікет з ID: ${ticketdId}
Тип - ${type}
Cередовище проблеми - ${problemEnvironment}
Співробітником - ${createWorkerId}
----------------------------
Назва тікету - ${ticketName}
Причина критичності - ${reason}
Опис тікету - ${createCommentDesc}
----------------------------
Telegram-акаунт автора тікета - ${tgAccAuthorTicket}

За додатковими деталями зверніться до автора тікету або скористайтесь чатом до тікету.
Не забувайте надати зворотній зв'язок по проблемі вашому ПМ на наступні питання:
1. Чому виникла проблема?
2. Чи раніше була ця проблема?
3. Чи є тимчасові рішення або обхідні шляхи для цієї проблеми? (Залежить від проблеми)
4. Що можна зробити, щоб запобігти повторному виникненню цієї проблеми? (Залежить від проблеми)
5. Вирішення проблеми, чи буде вирішуватись проблема та терміни вирішення?
`;

    const telegramApiUrl = `https://api.telegram.org/bot${telegramToken}/sendMessage`;

    return axios.post(telegramApiUrl, {
        chat_id: telegramChatId,
        text: logMessage
    });
}

export function sendTelegramChangePTicket(ticketdId, type, problemEnvironment, createWorkerId, ticketName, createComment, oldPriority, newPriority, tgAccCreateWorker, tgAccAuthorTicket) {
    
    const telegramToken = '731690536:AAEC6VhPcuzDRo8gqTU3PLPMOe8ymyLe0ZE';
    const telegramChatId = '-4208669213'; 

    // Використання функції stripHtml для вилучення HTML-тегів з createComment
    var createCommentDesc = stripHtml(createComment);

// Формуємо повідомлення
const logMessage = `
!!! Змінено пріоритет в тікеті !!!
Змінено пріоритет з ${oldPriority} на ${newPriority}
----------------------------
Тікет : ${ticketdId}
Тип - ${type}
Cередовище проблеми - ${problemEnvironment}
Співробітником - ${createWorkerId} (${tgAccCreateWorker})
----------------------------
Назва тікету - ${ticketName}
Опис тікету - ${createCommentDesc}
----------------------------
Telegram-акаунт автора тікета - ${tgAccAuthorTicket}

За додатковими деталями зверніться до автора тікету або співробітника який змінив пріоритет. Або скористайтесь чатом до тікету.
Не забувайте надати зворотній зв'язок по проблемі вашому ПМ на наступні питання:
1. Чому виникла проблема?
2. Чи раніше була ця проблема?
3. Чи є тимчасові рішення або обхідні шляхи для цієї проблеми? (Залежить від проблеми)
4. Що можна зробити, щоб запобігти повторному виникненню цієї проблеми? (Залежить від проблеми)
5. Вирішення проблеми, чи буде вирішуватись та терміни вирішення?
`;

    const telegramApiUrl = `https://api.telegram.org/bot${telegramToken}/sendMessage`;

    return axios.post(telegramApiUrl, {
        chat_id: telegramChatId,
        text: logMessage
    });
}


export function sendTelegramTotalTicketPhone(ticketdId, userPhone, total) {
    
    const telegramToken = '731690536:AAEC6VhPcuzDRo8gqTU3PLPMOe8ymyLe0ZE';
    const telegramChatId = '-4208669213'; 

const logMessage = `
Увага. За номером ${userPhone} створено ${total} тікети/ів. 
Останній тікет за номером - # ${ticketdId}
----------------------------

Необхідно надати зворотній зв'язок по тікетам вашому ПМ на наступні питання:
1. Час розгляду всіх тікетів клієнта?
2. Детальну інформацію по вирішенню кожного тікету?
3. Вирішення проблеми, чи буде вирішуватись та терміни вирішення?
`;

    const telegramApiUrl = `https://api.telegram.org/bot${telegramToken}/sendMessage`;

    return axios.post(telegramApiUrl, {
        chat_id: telegramChatId,
        text: logMessage
    });
}

export function sendHelpTicketTelegram(ticketName, ticketdId, createWorkerId, tgAccCreateWorker, commentHelp) {

    const telegramToken = '731690536:AAEC6VhPcuzDRo8gqTU3PLPMOe8ymyLe0ZE';
    const telegramChatId = '-4208669213'; 
    
    const logMessage = `
Потрібна допомога за тікетом - # ${ticketdId} 
Назва тікету - ${ticketName}
Співробітнику - ${createWorkerId} (${tgAccCreateWorker})
Коментар - ${commentHelp}
`;

    const telegramApiUrl = `https://api.telegram.org/bot${telegramToken}/sendMessage`;

    return axios.post(telegramApiUrl, {
        chat_id: telegramChatId,
        text: logMessage
    });

}

export function sendNotTestedSolutionTicket(ticketdId) {

    const telegramToken = '731690536:AAEC6VhPcuzDRo8gqTU3PLPMOe8ymyLe0ZE';
    const telegramChatId = '-4208669213'; 
    
    const logMessage = `
Увага. Рішення до тікету #${ticketdId} не проходило тестування.
`;

    const telegramApiUrl = `https://api.telegram.org/bot${telegramToken}/sendMessage`;

    return axios.post(telegramApiUrl, {
        chat_id: telegramChatId,
        text: logMessage
    });

}

export function sendNotQATestedSolutionTicket(ticketdId) {
    // Лог додавання рішення до тікету, якщо тестувалось тільки розробником

    const telegramToken = '731690536:AAEC6VhPcuzDRo8gqTU3PLPMOe8ymyLe0ZE';
    const telegramChatId = '-4208669213'; 
    
    const logMessage = `
Увага. Рішення до тікету #${ticketdId} пройшло тестування тільки розробником.
`;

    const telegramApiUrl = `https://api.telegram.org/bot${telegramToken}/sendMessage`;

    return axios.post(telegramApiUrl, {
        chat_id: telegramChatId,
        text: logMessage
    });

}