<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Submit') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label for="VertimeassageInput" class="form-label">{{ $t('Manager') }}</label>
                <Multiselect
                    v-model="this.toWorker"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.listWorkers"
                    :placeholder="$t('Manager')"
                ></Multiselect>
            </div>
            <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">{{ this.$t('Comment') }}</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="this.form.comment"></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="transferRequest"
            >
                {{ $t('Submit') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import Multiselect from "@vueform/multiselect";
import { storeS } from '@/store';

export default{
    components: {
        dialogBox,
        Multiselect
    },
    props: ['id', 'obj'],
    data(){
        return{
            form: {
                comment: '',
            },
            toWorker: '',
            listWorkers: [
                { label: 'Борис', value: 33},
                { label: 'Max', value: 34}
            ],
        }
    },
    created() {
        storeS.workers.forEach( (el)=> {
            if(el.workerJob === 'managercallcenter') {
                if(this.form.inJobWorkerId !== el.workerJob) {
                    this.listWorkers.push({
                        value: el.workerId,
                        label: el.workerName
                    })
                }
            } //список работников
        })
    },
    methods: {
        transferRequest(){
            if(this.toWorker == '') {
                this.$toast.error(this.$t('EnterWorker')) // все успішно
            } else {
                this.$emit('transferRequest', this.id, this.toWorker, this.form)
                this.$emit('close')
            }
        }
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
}
</script>