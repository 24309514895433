<template>
    <!-- 
        Компонент інфо-блоків, з підсумками по обраним даним
    -->

    <div class="row">
        <!-- Розпочинається цикл для виведення інформаційних блоків -->
        <div 
            :class="`col-xl-${
                form.length == '2' 
                ? form.length + 4 : form.length == '3' 
                ? form.length + 1 : form.length == '4' 
                ? (form.length-1) : form.length == '5' 
                ? form.length - 2 : '6'} col-sm-12`
            " 
            v-for="(item, index) in form" 
            :key="item" 
        >
            <!-- Карточка блоку -->
            <div class="card card-animate" style="border-radius: 8px;">
                <div 
                    :class="`card-body bg-${item.type || item.type == 'colorsBlock' ? item.color : ''}`" 
                    style="border-radius: 8px;" :id="`infoStep`+index"
                >
                    <!-- Контент блоку з інформацією -->
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <p 
                                :class="`text-uppercase fw-medium ${item.type || item.type == 'colorsBlock' ? 'text-white' : ''} mb-0`"
                            >
                                <b>{{ item.title }}</b>
                            </p>
                        </div>
                    </div>

                    <!-- Додатковий контент залежно від умови наявності значення суми -->
                    <div class="d-flex align-items-end justify-content-between mt-3">
                        <!-- Умова наявності суми -->
                        <div v-if="item.sum">
                            <!-- Виведення суми, значення та відповідної позначки -->
                            <h4 class="fs-22 fw-semibold ff-secondary mb-4"><span class="counter-value">{{item.sum}}</span></h4>
                            <span class="badge bg-warning me-1">{{item.value}}</span> <span class="text-muted">{{$t('Total')}}</span>
                        </div>
                        <!-- Умова відсутності суми -->
                        <div v-else>
                            <h4 :class="`fs-22 fw-semibold ff-secondary ${item.type || item.type == 'colorsBlock' ? 'text-white' : ''}`"><span class="counter-value">{{item.value}}</span></h4>
                        </div>
                        <!-- Виведення аватарки з іконкою в залежності від умови -->
                        <div class="avatar-sm flex-shrink-0" v-if="!item.type">
                            <span
                                :class="`avatar-title bg-soft-${item.color} text-${item.color} rounded-circle fs-4`"
                            >
                                <i :class="`${item.icon}`"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props: ['obj'],
    data(){
        return{
            form: "" // Змінна для зберігання даних інформаційних блоків
        }
    },
    created(){
        // Ініціалізація змінної даних інформаційних блоків з отриманими даними
        this.form = this.obj
    }
}
</script>