<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="type-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('type') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <div class="col-lg-12">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radiotype" id="all_type" v-model="type" :value="''">
                    <label class="form-check-label" for="all_type">{{ $t('all') }}</label>
                </div>
            </div>
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-12">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radiotype" :id="'type'+index" v-model="type" :value="value.value">
                        <label class="form-check-label" :for="'type'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { storeS } from '@/store';

export default {
    data(){
        return {
            type: "",
            opt: [
                {
                    name: this.$t('EnablenewVersion'), // включити нову версію
                    value: "enable_new_version"
                },
                {
                    name: this.$t('EnableOldVersion'), // включити стару версію
                    value: "enable_old_version"
                },
                {
                    name: this.$t('trans_data_from_newServer_company_franchise'), // копіювання даних з нової версії в нову (між акаунтами)
                    value: "trans_data_from_newServer_company_franchise"
                },
                {
                    name: this.$t('trans_data_from_newServer_company'), // копіювання даних з нової версії в нову (в межах акаунту)
                    value: "trans_data_from_newServer_company"
                },
                {
                    name: this.$t('trans_data_from_old_company_franchise'), // перенесення даних зі старої версії в нову (між акаунтами)
                    value: "trans_data_from_old_company_franchise"
                },
                {
                    name: this.$t('trans_data_from_old_company'), // перенесення даних зі старої версії в нову (в межах акаунту)
                    value: "trans_data_from_old_company"
                },
                {
                    name: this.$t('transfer_funds_companies_in_account'), // перенести кошти між компаніями (в межах акаунту)
                    value: "transfer_funds_companies_in_account"
                },
                {
                    name: this.$t('transfer_funds_companies_between_accounts'), // перенести кошти між компаніями (між акаунтами)
                    value: "transfer_funds_companies_between_accounts"
                },
                {
                    name: this.$t('ReceivingTokenfromPrivatBank'), // Отримання токену Приватбанк
                    value: "receiving_token"
                },
                {
                    name: this.$t('ChangeAccountType'), // змінити тип акаунту
                    value: "change_account_type"
                },
                {
                    name: this.$t('tt_recovery'), // відновлення торгової точки
                    value: "repair_tt"
                },
                {
                    name: this.$t('RestorationExpenseCategory'), // відновлення категорій витрат
                    value: "restoration_expense_category"
                },
                {
                    name: this.$t('clearing_statistics'), // очищення статистики
                    value: "clear_stats"
                },
                {
                    name: this.$t('clearing_products'), // очищення товарів
                    value: "clearing_products"
                },
                {
                    name: this.$t('clearing_account'), // очищення акаунту
                    value: "clearing_account"
                },
                // {
                //     name: this.$t('administrator_change'),
                //     value: "administrator_change"
                // },
                {
                    name: this.$t('copying_data_franchise'), // копіювання даних (франшиза)
                    value: "copy_data_franchise"
                },
                {
                    name: this.$t('copying_data_inside'), // копіювання даних (в межах акаунту)
                    value: "copying_data_inside"
                },
                {
                    name: this.$t('password_reset'), // відновлення паролю
                    value: "discharge_password"
                },
                {
                    name: this.$t('SyncMode'), // режим синхронізації
                    value: "syncmode"
                },
                {
                    name: this.$t('MultiMode'), // режим мультитерміналу
                    value: "multimode"
                },
                {
                    name: this.$t('add_change_domain'), // Додати (змінити) домен
                    value: "add_change_domain"
                },
                {
                    name: this.$t('ChangeLimitCompanies'), // змінити ліміт компаній
                    value: "change_limit_companies"
                },
                {
                    name: this.$t('strenght_switch_owner_of_comp'), // примусова зміна власника компанії
                    value: "strenght_switch_owner_of_comp"
                },
                {
                    name: this.$t('strenght_switch_owner_of_acc'), // примусова зміна власника акаунту
                    value: "strenght_switch_owner_of_acc"
                },
            ],
        }
    },
    methods: {
        change(){
            this.$emit('change', 'taskName', this.type)
        },
    },
    computed: {
        // providers(){
        //     return storeS.providers
        // }
    }
}
</script>
