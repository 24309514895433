<template>
    <!-- 
        Модальне вікно для додавання пристрою в картку тікету
    -->

    <modal @close="this.$emit('close')">
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('addDevice') }}</template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            <div class="alert alert-danger" role="alert">
                {{ this.$t('ifOldVersion') }}
            </div>
            <!-- Визначення ряду, коли perm == false і nums існує та має довжину більше 0 -->
            <b-row v-if="perm == false && nums != undefined && nums.length > 0">

                <b-col lg="6">
                    <h4 class="fs-15">{{ $t('phone_num') }}</h4>
                    <div class="input-group mb-3" v-if="nums.length > 0">
                        <!-- Обрати номер з списку номерів тікету -->
                        <select
                            class="form-control"
                            v-model="selectnum"
                        >
                            <option 
                                v-for="item, k in nums" 
                                :key="k" 
                                :value="item.number ? item.number : item"
                            >
                                {{item.number ? item.number : item}}
                            </option>
                        </select>

                        <!-- Кнопка для отримання компаній -->
                        <button class="btn btn-info" type="button" @click="getcomps(selectnum)">{{ $t('getsCompany') }}</button>
                    </div>
                </b-col>

                <!-- Другий стовпець ряду, якщо компанії існують -->
                <b-col lg="6" v-if="companies.length > 0">
                    <h4 class="fs-15">{{ $t('company') }}</h4>
                    <div class="input-group mb-3">
                        <!-- Вибір компанії зі списку -->
                        <select
                            class="form-control"
                            v-model="selectComp"
                            @change="this.devices = []"
                        >
                            <option 
                                v-for="item in companies" 
                                :key="item" 
                                :value="item"
                            >
                                {{item.companyName}} - {{ item.more.companyServer === 's-1' ? this.$t('OldAdmin') : this.$t('NewAdmin_panel') }}
                            </option>
                        </select>
                        
                        <!-- 
                            Кнопка для отримання компанії 
                            Є перевірка:
                                *Якщо стара адмінка
                        -->
                        <button class="btn btn-info" type="button" v-if="selectComp?.more?.companyServer === 's-1'" @click="getCompany(this.selectComp.companyId)">{{$t('getComp')}}</button>
                        <!-- Кнопка для отримання пристроїв -->
                        <button class="btn btn-primary" type="button" @click="getclient(this.selectComp)">{{$t('getsDevices')}}</button>
                    </div>
                </b-col>
            </b-row>

            <!-- Ряд, якщо perm == false і nums не існує -->
            <b-row v-if="perm == false && nums == undefined">
                <b-col lg="6">
                     <!-- Карта з заголовком "Номер телефону" -->
                    <b-card>
                        <h4 class="fs-15">{{ $t('phone_num') }}</h4>
                        <!-- Введення та кнопка для копіювання -->
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" v-model="form.user_phone" disabled>
                            <button @click="copy(form.user_phone)" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-card>
                </b-col>
            </b-row>

            <!-- Ряд, якщо perm == false -->
            <b-row v-if="perm == false">

                <!-- Перший стовпець ряду, якщо nums існує і має довжину більше 0 -->
                <b-col lg="6" v-if="nums != undefined && nums.length > 0">
                    <!-- Заголовок "Область проблеми" та вибір пристрою -->
                    <h4 class="fs-15">{{ $t('problem_area') }}</h4>
                    <select
                        class="form-control"
                        v-model="selectdevice"
                        @change="changeDevice(selectdevice)"
                    >
                        <option 
                            v-for="item in devices" 
                            :key="item" 
                            :value="item" 
                        >
                            {{item.name ? item.name : item.deviceTitle}} - {{ this.$t('outlet') }}: {{ this.getTTName(item.tradepointId) }} ( {{ item.tradepointId }} )
                        </option>
                    </select>
                </b-col>

                <!-- Другий стовпець ряду, якщо nums існує і має довжину 0 -->
                <b-col lg="6" v-if="nums != undefined && nums.length == 0">
                    <!-- Заголовок "Область проблеми" та багатовибірковий вибір для "принтер", "RRO" та "POS" -->
                    <h4 class="fs-15">{{ $t('problem_area') }}</h4>
                    <Multiselect 
                        v-model="form.way_problem"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="true"
                        :options="problemEnvironment"
                        :placeholder="$t('device_printer')+', '+$t('device_rro')+', '+$t('dir_pos')"
                        :disabled="this.editform == false"
                    />
                </b-col>
            </b-row>
            <hr v-if="perm == false" />

            <!--   
                Компонент з налаштуваннями пристрою, відображений, якщо обрано "шлях проблеми" 
                - :key - ключ для перерендерингу компоненту при зміні даних
                - :obj - параметри девайсу
                - :sklad - точка
                - :editform - статус режиму редагування
            -->
            <viewdevice 
                :key="componentKey" 
                v-if="form.way_problem != ''" 
                :obj="form.device" 
                :sklad="sklad" 
                :editform="this.btnDisable" 
            />
        </template>
        <template v-slot:footer-bottom>

            <!-- Кнопка "Видалити", якщо існує ідентифікатор тікету -->
            <button 
                v-if="form.deviceTicketId"
                type="button" 
                class="btn btn-danger" 
                @click="showDialogRemove = true"
            >
                {{ $t('Delete') }}
            </button>

            <!-- Кнопка "Додати", додає вказаний пристрій -->
            <button 
                v-if="this.perm == false"
                type="button" 
                class="btn btn-success" 
                @click="add()"
            >
                {{ $t('Add') }}
            </button>

            <!-- Кнопка "Редагувати", якщо perm == true і редагування не вимкнено -->
            <button 
                v-if="this.perm == true && this.btnDisable == false"
                type="button" 
                class="btn btn-info" 
                @click="this.btnDisable = true"
            >
                {{ $t('Edit') }}
            </button>

            <!-- Кнопка "Зберегти", якщо perm == true і редагування вимкнено -->
            <button 
                v-if="this.perm == true && this.btnDisable == true"
                type="button" 
                class="btn btn-success" 
                @click="edit()"
            >
                {{ $t('save') }}
            </button>
        </template>
    </modal>

    <!-- 
        Модальне вікно підтвердження видалення пристрою
    -->
    <dialogRemove 
        v-if="showDialogRemove" 
        @close="showDialogRemove = false" 
        @remove="removeDeivce"
    />

</template>

<script>
import modal from '@/components/modal/modal-lg' // компонент модального вікна
import printer from '@/components/ticketdeviceblock/printer.vue' // компонент принтера
import label_printer from '@/components/ticketdeviceblock/label_printer.vue' // компонент принтера етикеток
import posterminal from '@/components/ticketdeviceblock/pos_terminal.vue'  // компонент пос-терміналу
import fiscal from '@/components/ticketdeviceblock/fiscal.vue'  // компонент рро
import bank_terminal from '@/components/ticketdeviceblock/bank_terminal.vue'  // компонент банк терміналу
import scales from '@/components/ticketdeviceblock/scales.vue' // компонент вагів
import tablecontrol from '@/components/ticketdeviceblock/table_control.vue' // компонент 
import viewdevice from '@/components/ticketdeviceblock/view.vue' // компонент картки девайсу
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import dialogRemove from '@/components/modal/modalremove' // компонент діалогового вікна видалення
import { Clients, SuperAdmin } from "@/API.js" // клас для взаємодії з API 
import { mutatePhone } from '../../usabilityScripts/globalMutate';
import {copy} from '@/usabilityScripts/copy.js'

let apiServe = new Clients();
let apiAdmin = new SuperAdmin()

export default{
    props: ['itemdevice', 'editform', 'numbers', 'typeAdd', 'perm'],
    components: { 
        modal, 
        Multiselect,
        printer,
        label_printer, 
        posterminal,
        fiscal,
        scales,
        bank_terminal ,
        tablecontrol,
        viewdevice,
        dialogRemove
    },
    data(){
        return{
            ownerPhone: '', // номер власника
            selectComp: '', // Вибрана компанія
            showDialogRemove: false, // Прапор відображення діалогового вікна видалення
            componentKey: '', // Ключ компонента для перерендерингу
            btnDisable: '',// Відключення кнопки (для режиму редагування)
            form: { // Форма для введення даних пристрою
                way_problem: "", // середовище проблеми
                user_phone: "", // номер телефону
                device: { // параметри пристрою
                    tradepointId: '', // id точки
                    id: "", // id пристрою
                    manufacturer: "", // виробник
                    model: "", // модель 
                    name: "", // назва
                    os: "", // операційна система
                    protocol:"", // протокол
                    type: "", // тип
                    data: {
                        additionalPrintParams:{
                            footer: "",
                            header: "",
                            logo: ""
                        },
                        bankingParams: [],
                        connectionParams: {
                            comport: "",
                            driver:"artsoft",
                            ip: "",
                            mac: "",
                            pass: "",
                            printer: "",
                            promise: "0",
                            systemPrinter: "",
                            usb: "",
                            user: ""
                        },
                        fiscalSettings: {
                            taxSystem: ""
                        },
                        indents: {
                            feedBottom: "3",
                            feedLeft: "0",
                            feedRight: "0",
                            feedTop: "0"
                        },
                        printParams: {
                            buffer: "0",
                            charset: "cp1251",
                            chineseOff: "",
                            codepage: "0",
                            number: "0",
                            parallel: "0",
                            printMode: "text",
                            whatToPrint: "3",
                            workshop: "0"
                        },
                        sizes: {
                            font: "",
                            size: "",
                            width: ""
                        },
                        version: {
                            app: "",
                            os: ""
                        }
                    }
                }
            },
            comapnyOfSelectClient: '', //компанія вибраного клієнта
            nums: "", // Номери телефонів
            companies: [], // Список компаній
            selectnum: "0", // Вибраний номер телефону
            selectdevice: "0", // Вибраний пристрій
            devices: "", // Список пристроїв
            sklad: '', // Склад
            problemEnvironment: [ // Список проблемного середовища
                {
                    // принтер
                    "label": this.$t('device_printer'),
                    "value": "printer"
                },
                {
                    // принтер етикеток
                    "label": this.$t('device_label_printer'),
                    "value": "label_printer"
                },
                {
                    // пос термінал
                    "label": this.$t('device_pos_terminal'),
                    "value": "possystem"
                },
                {
                    // рро
                    "label": this.$t('device_rro'),
                    "value": "rro"
                },
                {
                    // термінал
                    "label": this.$t('device_terminal'),
                    "value": "banking"
                },
                {
                    // ваги
                    "label": this.$t('device_scales'),
                    "value": "libra"
                },
                {
                    // сканер
                    "label": this.$t('device_scanner'),
                    "value": "scanner"
                },
                {
                    // Блок управління більярдними столами
                    "label": this.$t('device_table_control'),
                    "value": "pool"
                },
                {
                    // екран в зал
                    "label": this.$t('hallscreen_windows'),
                    "value": "screens"
                }
            ]
        }
    },
    created(){
        this.nums = this.numbers

        // Якщо компонент використовується у режимі редагування
        if(this.perm == true) {
            this.form = this.itemdevice
            this.btnDisable = this.editform;
        }
    },
    methods: {
        getTTName(id) {
            if(this.selectComp.more.companyServer === 's-1') { //якщо стара адмінка
                for (var item in this.comapnyOfSelectClient.companyDataObj.tradepoints) {
                    if(this.comapnyOfSelectClient.companyDataObj.tradepoints[item].tradepointId == id) {
                        return this.comapnyOfSelectClient.companyDataObj.tradepoints[item].tradepointName
                    } 
                }
            } else { // якщо нова адмінка
                for (var item in this.sklad) {
                    if(this.sklad[item].tradepointId == id) {
                        return this.sklad[item].tradepointName
                    } 
                }
            }
        },
        getCompany(id){
            // отримуємо компанію додатково
            apiAdmin.getCompClient(id).then(result => {
                if(result.status == 'done'){
                    this.comapnyOfSelectClient = result.data
                    this.ownerPhone = result.data.ownerPhone
                } else {
                    this.$toast.error(this.$t('err'));
                }
            })
        },
        getcomps(num) {
             // Отримання компаній за номером телефону
            
            var customNumber = num.toString();
            var numcode = ["39", "67", "68", "96", "97", "98", "50", "66", "95", "99", "63", "73", "93", "91", "92", "89", "94"]
            numcode.forEach(el => {
                if(customNumber.substr(0, 2) == el ) {
                    customNumber = this.phoneReset(customNumber.substring(1, customNumber.length))
                }
            })

            
            apiAdmin.getCard(customNumber).then(result => {
                if(result.status == 'done'){
                    result.data.сompanies.forEach(el => {
                        this.companies.push(el)
                    })
                }
            })
        },
        add(){
            // Додавання пристрою

            this.form.user_phone = this.selectnum
            this.$emit('addDeivce', this.form)
            this.$emit('close')
        },
        phoneReset(e) {
            // Форматування телефону
            return mutatePhone(e)
        },
        getclient(comp){
            // Отримання даних клієнта за компанією
            if(comp.more.companyServer == 's-1') {
                apiServe.getClient((String(this.ownerPhone))).then(result => {
                    if(result.status == "done"){
                        this.devices = result.data.devices;
                        this.sklad = result.data.license.sklad;
                        this.form.way_problem = ''
                        if(result.data.devices === null || result.data.devices.length === 0) {
                            this.$toast.error(this.$t('devicesIsNull'));
                        } else {
                            this.$toast.success(this.$t('DevicesReceived'));
                        }
                    } else {
                        this.$toast.error(this.$t('err'));
                    }
                })
            } else {
                apiAdmin.getCompClient(comp.companyId).then(result => {
                    if(result.status == "done"){
                        this.devices = result.data.companyDataObj.devices;
                        this.sklad = result.data.companyDataObj.tradepoints;
                        this.form.way_problem = ''
                        if(result.data.companyDataObj.devices === null || result.data.companyDataObj.devices.length === 0) {
                            this.$toast.error(this.$t('devicesIsNull'));
                        } else {
                            this.$toast.success(this.$t('DevicesReceived'));
                        }
                    } else {
                        this.$toast.error(this.$t('err'));
                    }
                })
            }
            // this.eventBus.emit('getClient', {status: true, number: this.number})
        },
        changeDevice(e){
            // Зміна вибраного пристрою
            this.form.way_problem = e.type
            this.form.device = e
            this.componentKey += 1
        },
        edit(){
            // Редагування пристрою
            this.$emit('editDeivce', this.form)
            this.$emit('close')
        },
        removeDeivce() {
            // Видалення пристрою
            this.$emit('removeDeivce', this.form.deviceTicketId);
            this.$emit('close')
        },
        input(e){
            // Обробка введення
            this.form.device = e;
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied') + " - " + item);
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    }
}
</script>