<template>
    <!-- модальное окно создания/отправки смс -->
    <dialogmodal>
        <template v-slot:title>{{ $t('confirm_close') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <div style="border: 1px black solid, border-radius: 20px;">
                <h4>Текст вашого повідомлення: </h4>
                <p>{{ this.form.text }} </p> 
                <h4> Буде відіслано на номер: </h4> 
                <p>{{ this.form.userPhone }}</p>
            </div>

            <h3> Якщо все вірно, то натисніть на кнопку "{{ $t('Add') }}" або закрийте вікно і повторіть дію</h3> 
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
            <button type="button" class="btn btn-primary" @click="sendSms">{{ $t('Add') }}</button>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue'
import autotag from '@/components/autotag'
import { Clients } from '@/API'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Clients();

export default ({
    data(){
        return {
            form:'',
        }
    },
    props:['obj'],
    components: { dialogmodal, autotag},
    created(){
        this.form = this.obj
    },
    methods: {
        sendSms(){
            apiServe.sendSms(this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('msgSent'));
                    this.$emit('close');
                } else {
                    if(result.error[0] == 'error with sending sms') {
                        this.$toast.error(this.$t('serviceSMSError'))
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);
                    }

                    var errorText = 'Помилка надсилання sms'; 
                    var statusRequest = result.status;
                    var api = 'sendSms';
                    var fileRequest = 'src/views/application/acceptSMS.vue';
                    var params = this.form;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }
    }
})
</script>
