<template>
    <!-- 
        Вкладка тікетів, дозволяє - 
        - переглянути тікети за спіробітником
        - та відкрити картку тікету
        * відкритий розробникам та ceo
    -->

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - @open - подія, яка спрацьовує при відкритті картки тікету
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @search - подія, яка спрацьовує при пошуку у таблиці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->    
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        @open="open" 
        @changeFilter="changeFilter"
        @getdata="getdata"
        @changelimit="changelimit"
    />

    <!-- 
       Виклик відкриття картки тікету
       - для відкриття застосовується перевірка значення showModal
       - @close - подія, яка спрацьовує при закритті картки
       - :obj - параметр-об'єкт з даними про тікет
       - :ifPrevopen - параметр, який передає статус відображення стрілочки назад
       - :ifNextopen - параметр, який передає статус відображення стрілочки вперед
       - @prevOpen - подія, яка спрацьовує коли натискаєш на стрілочку назад
       - @nextOpen - подія, яка спрацьовує коли натискаєш на стрілочку вперед
    -->
    <viewbox 
        v-if="showModal" 
        @close="showModal = false" 
        :obj="obj" 
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen"
    ></viewbox>

</template>

<script>
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewbox from '@/views/newtickets/view/index' // картка тікету
import { mutateTicket, 
        mutateWayproblem , 
        mutateStatusTicket, 
        mutatePriorityTicket, 
        mutateDecidedStatusTicket } from '@/usabilityScripts/globalMutate.js'
import { Tickets } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Tickets();

export default {
    props: ['id'],
    components: { 
        tablecustom, 
        viewbox 
    },
    data() {
        return {
            ifNextopen: "", // Прапорець статусу відображення кнопки "<"
            ifPrevopen: "", // Прапорець статусу відображення кнопки ">"
            showModal: false, // Прапорець відображення вікна тікету
            obj: {}, // Об'єкт з даними штрафу для відображення в модальному вікні
            pages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: false,  // true - показати кнопку фільтр, false - сховати
                page: "tickets"
            },
            objParams: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                search: '',
                inJobWorkerId: ''
            },
            rows: [], // Дані для відображення у таблиці
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: "ID",
                    text: "ticketId",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("ticket"),
                    text: "type",
                    align: "left",
                    mutate: (item) => this.mutateTicket(item),
                    status: true
                },
                {
                    name: this.$t("ticket_name"),
                    text: "ticketName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("problem_area"),
                    text: "problemEnvironment",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t("description"),
                    text: "createComment",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "right",
                    mutate: (item) => this.mutatePriorityTicket(item).name,
                    mutateClass: (item) => this.mutatePriorityTicket(item).color,
                    status: true
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "right",
                    mutate: (item) => this.mutateStatusTicket(item).name,
                    mutateClass: (item) => this.mutateStatusTicket(item).color,
                    status: true
                },
            ],
        }
    },
    created(){
         // Ініціалізація компонента та отримання даних
        this.getdata()
    },
    methods: {
        getdata(page){
             /**
                Отримує дані про тікети з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Number} page - Номер сторінки для отримання даних.
            */

            this.objParams.page = page != undefined ? page : '1';
            this.objParams.inJobWorkerId = this.id;

            // Отримання даних про проекти з API
            apiServe.getTickets(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.pages = result.data.links
                    this.rows = result.data.items;  
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            })
        },
        open: function(e){
            /**
                Функція для відкриття модального вікна з інформацією про тікет за його ідентифікатором.
                * @param {Object|String} e - Ідентифікатор тікету або об'єкт з інформацією про робітника.

                Викликає API для отримання інформації про проект.
                Результат присвоюється властивості obj, та встановлюється прапорці modalShowview в true для відображення модального вікна.

                Для відображення стрілочок перемикання вперед та назад - 
                * ifPrevopen - перевіряється, чи є ще картка тікету, до поточної відкритої картки
                * ifNextopen - перевіряється, чи є ще картка тікету, після поточної відкритої картки
            */

            var id = e.ticketId ? e.ticketId : e;
            this.ifPrevopen = id == this.rows[0].ticketId ? false : true;
            this.ifNextopen = id == this.rows[this.rows.length-1].ticketId ? false : true;
            
            apiServe.getTicket(id).then(result => {
                if(result.status == 'done'){
                    this.obj = result.data;
                    this.eventBus.emit('modalInModal', {
                        status: true,
                        openFromUser: 'ticket',
                        data: this.obj
                    })
                }
            })
        },
        prevOpen(e){
            /*
                Функція для відкриття модального вікна попереднього запису у списку
                Отримує ідентифікатор поточного тікету, знаходить ідентифікатор попереднього тікету в списку та викликає функцію open для відображення інформації про тікет.
            */

            var current_id = e;
            var prev_id = ""; 

            for(var value in this.rows){
                if(this.rows[value].ticketId == current_id){
                    prev_id = this.rows[Number(value)-Number('1')].ticketId;
                }
            }
            this.open(prev_id);
        },
        nextOpen(e){
            /*
                Функція для відкриття модального вікна наступного запису у списку
                Отримує ідентифікатор поточного тікету, знаходить ідентифікатор наступного тікету в списку та викликає функцію open для відображення інформації про тікет.
            */

            var current_id = e;
            var next_id = "";
            for(var value in this.rows){
                if(this.rows[value].ticketId == current_id){
                    next_id = this.rows[Number(value)+Number('1')].ticketId;
                }
            }
            this.open(next_id);
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
            */

            this.objParams.search = e
            this.getdata();
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(name == 'all'){
                this.objParams.search = '',
                this.objParams.priority = '',
                this.objParams.status = '',
                this.objParams.type = '',
                this.objParams.workerId = '',
                this.objParams.inJobWorkerId = '',
                this.objParams.pr1 = '',
                this.objParams.pr2 = '';
                this.getdata();
            } else if(!name.type && value != undefined){
                if(name == 'pr'){
                this.objParams['pr1'] = value[0];
                this.objParams['pr2'] = value[1];
                } else {
                this.objParams[name] = value;
                }
                this.getdata();
            }
        },
        mutateTicket(e){
            /*
                Функція для застосування перекладу до типу тікета
            */
            return mutateTicket(e);
        },
        mutateWayproblem(e){
            /*
                Функція для застосування перекладу до середовища проблеми тікету
            */
            return mutateWayproblem(e);
        },
        mutateStatusTicket(e){
            /*
                Функція для застосування перекладу до статусу тікета
            */
            return mutateStatusTicket(e);
        },
        mutatePriorityTicket(e){
            /*
                Функція для застосування перекладу до пріоритету тікета
            */
            return mutatePriorityTicket(e);
        },
        mutateDecidedStatusTicket(e){
            /*
                Функція для застосування перекладу до статусу рішення тікета
            */
            return mutateDecidedStatusTicket(e);
        }
    },
    computed: {
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        workers(){
            /*
                Повертає дані співробітників з глобального стору.
            */
            return storeS.workers
        },
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
    }
}
</script>