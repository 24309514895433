<template>
    <dialogBox @close="this.$emit('close')">
            <template v-slot:title>{{$t('closeTicketT')}}</template>
            <template v-slot:body>
                <div  class="mb-3">
                    <label class="form-label mb-2">{{ $t('QuickComments') }}</label>
                    <div class="mb-2">
                        <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('Working')" style="margin-right: 5px;">{{ $t('Working') }}</span>
                        <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('YesAllFine')" style="margin-right: 5px;">{{ $t('YesAllFine') }}</span>
                        <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('OkaytheSolutionWorks')" style="margin-right: 5px;">{{ $t('OkaytheSolutionWorks') }}</span>
                        <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('OkayButSolutionDoesntWork')" style="margin-right: 5px;">{{ $t('OkayButSolutionDoesntWork') }}</span>
                        <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('+')" style="margin-right: 5px;">+</span>
                    </div>
                </div>
                <div class="mb-3">
                    <label  class="form-label">{{ $t('Comment') }}</label>
                    <textarea class="form-control" rows="3" v-model="form.comment" :placeholder="$t('closeTicket')"></textarea>
                </div>
                <div class="form-check form-check-success mb-3">
                    <input class="form-check-input" type="checkbox" id="formcheck" v-model="form.informClients">
                    <label class="form-check-label" for="formcheck">{{ $t('notifyCustomers') }}</label>
                </div>
            </template>
            <template v-slot:footer-bottom>
                <button class="btn link-danger fw-medium" @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
                <button 
                    type="button" 
                    class="btn btn-success" 
                    @click="toClose()"
                >
                    {{ $t('Add') }}
                </button>
            </template>
        </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { Tickets } from '@/API'
import { storeS } from '@/store'

let apiServe = new Tickets();

export default {
    props: ['id'],
    components: {
        dialogBox
    },
    data(){
        return {
            form: {
                comment: "",
                informClients: false,
            },
        }
    },
    methods: {
        toClose(){
            if(!this.form.comment) {
                this.$toast.error(this.$t('enterComment'))
                return
            }

            apiServe.closeTicket(this.id, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(`done`);
                    // this.eventBus.emit('closeTicket', true);
                    this.$emit('toClose')
                } else if(result.status == 'error'){
                    this.$toast.error(result.error[0]);
                }
            })
        }
    }
}
</script>