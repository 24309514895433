<template>

    <client_block :obj="this.form" />

    <status_block :obj="this.form" />

    <operations :obj="this.form" />

    <communication />

    <report />

</template>

<script>
import client_block from './client_block.vue';
import status_block from './status_block.vue';
import operations from './operations/index.vue';
import communication from './communication.vue';
import report from './report.vue'

export default({
    props: ['json'],
    components: {
        client_block,
        status_block,
        operations,
        communication,
        report
    },
    data() {
        return{
            form: ""
        }
    },
    created(){
        this.form = this.json
    }
})
</script>
