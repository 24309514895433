<template>
    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="checks['001']">
      <button @click="infoblockShow == true ? infoblockShow = false : infoblock()" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
    </div>
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />
    
    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        :datafilter="filterAll"
        @change="change" 
        @clearFilter="clearFilter"
        :resetpass="true"
        @resetPass="resetPass"
        @open="open"
    />

    <!-- <views v-if="showModal == true" @close="showModal = false" :shortapplic="cardApplic" @resetPass="resetPass"/> -->
</template>

<script>
import tablecustom from '@/components/globaltable/index';
import infoblocks from '@/components/info/blocks'
import { Clients } from '@/API.js'
// import views from './view.vue'
import { storeS } from '@/store';
import { mutateWorkersAccount, mutateDirWorker } from '@/usabilityScripts/globalMutate.js'

let apiClients = new Clients();

export default {
    props: ['obj'],
    components: { 
        tablecustom, 
        infoblocks, 
        // views 
    },
    data() {
        return {
            form: {},
            search: "",
            infoblockShow: false,
            showModal: false,
            cardApplic:"",
            columns: [
                {
                    name: this.$t("Status"),
                    text: "active",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item) => this.mutateUser(item).name,
                    mutateClass: (item) => this.mutateUser(item).color
                },
                {
                    name: this.$t("phone_num"),
                    text: "workerPhone",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("name"),
                    text: "workerName",
                    align: "right",
                    status: true,
                    copy: true
                },
                // {
                //     name: "PIN",
                //     text: "user_pin",
                //     align: "right",
                //     copy: true
                // },
                // {
                //     name: this.$t('openEntry'),
                //     text: "user_dir",
                //     align: "right",
                //     copy: false,
                //     mutate: (item) => this.mutateDir(item).r,
                // },
          ],
          filterAll: [
                {
                    page: "workers",
                    name: "Status",
                    category: "0",
                    items: []
                }
            ],
          selectStatus: "0",
          information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                },
                {
                    title: this.$t('Activated'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success",
                },
                {
                    title: this.$t('blocked_user'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger",
                },
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj;
            // let newobj = this.obj
            let newArrStatus = Array.from(new Set(this.form.map(item => JSON.stringify(item.active)))).map(item => JSON.parse(item));
            this.filterAll[0]['items'] = newArrStatus;
            console.log('newArrStatus', newArrStatus)
        },
        infoblock(){
            this.information[0].value = this.filteredItems.length
            this.information[1].value = this.filteredItems.filter(item => item.active == true).length
            this.information[2].value = this.filteredItems.filter(item => item.active == false).length
            
            this.infoblockShow = true
        },
        searchB(e){
            this.search = e
        },
        open(e){
            this.cardApplic = e;
            this.showModal = true;
        },
        change(name, e){
            console.log('e', e);
            if(name == 'Status'){
                this.selectStatus = e;
            }
        },
        resetPass(e){
            apiClients.new_reset_passwordClient(e).then(res => {
                (res)
                if(res.status == "done"){
                    this.$toast.success(this.$t('resetPasswordDone'));
                }else {
                    this.$toast.error(this.$t('err'));
                }
            })
        },
        mutateDir(e){
            return mutateDirWorker(e)
        },
        mutateUser(e){
          return mutateWorkersAccount(e)
        },
        clearFilter(){
            this.selectStatus = "0";
        }
    },
    watch:{
        obj(value, oldValue){
            // this.form = value
            this.getdata(value)
        }
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.workerPhone.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.workerName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })

                // По типу сотрудника
                .filter(item => {
                    return this.selectStatus == 0 || item.active == this.selectStatus;
                })
        },
        checks(){
            return storeS.checks
        }
    }
}
</script>