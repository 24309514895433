<template >
    <div class="col-md-6">
        <!-- Компонент пос терміналу -->
            <div class="mb-3">
                <!-- Модель банківскьог терміналу -->
                <h4 class="fs-15">{{ $t('bank_terminal_model') }}</h4>
                <Multiselect class="mb-3"
                    @input="input"
                    v-model="form.model.value"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="true"
                    :options="models_terminal"
                    :disabled="this.editform == false"
                />
                <h4 class="fs-15">{{ $t('operatingSystem') }}</h4>
                <!-- Операційна система -->
                <select
                    class="form-select mb-3"
                    data-plugin="choices"
                    v-model="form.oc_types.value"
                    :disabled="this.editform == false"
                    >
                    <option v-for="item in oc_types" :key="item">{{ item.title }}</option>
                </select>
                <h4 class="fs-15">{{ $t('verSkyService') }}</h4>
                <!-- Версія Скаю -->
                <input type="number" class="form-control mb-3" :disabled="this.editform == false" v-model="form.version_apps.value" placeholder="9.9">
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <!-- Метод підключення -->
                <h4 class="fs-15">{{ $t('connection_method') }}</h4>
                <select
                    class="form-select mb-3"
                    data-plugin="choices"
                    v-model="form.connect_types.value"
                    :disabled="this.editform == false"
                    >
                    <option v-for="item in connect_types" :key="item">{{ item.title }}</option>
                </select>
                <h4 class="fs-15">{{ $t('ver_oc') }}</h4>
                <input type="number" class="form-control mb-3" :disabled="this.editform == false" v-model="form.version_os.value" placeholder="10">
            </div>
        </div>
        <hr>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
export default ({
    props: ['obj','editform'],
    components: { Multiselect },
    data(){
        return {
            models_terminal: [
                {
                    "label": "Ingenico APOS A8",
                    "value": "Ingenico APOS A8",
                },
                {
                    "label": "Ingenico DX8000",
                    "value": "Ingenico DX8000",
                },
                {
                    "label": "Verifone X990",
                    "value": "Verifone X990",
                },
                {
                    "label": "Sunmi V1",
                    "value": "Sunmi V1",
                },
                {
                    "label": "Sunmi T1mini",
                    "value": "Sunmi T1mini",
                },
                {
                    "label": "Sunmi D2mini",
                    "value": "Sunmi D2mini",
                },
                {
                    "label": "SmartPay P2000L",
                    "value": "SmartPay P2000L",
                },
                {
                    "label": "PosSector Smart Cube",
                    "value": "PosSector Smart Cube",
                },
                {
                    "label": "Centerm K9",
                    "value": "Centerm K9",
                },
                {
                    "label": "Gprinter",
                    "value": "Gprinter",
                },
                {
                    "label": "JASSWAY",
                    "value": "JASSWAY",
                },
                
            ],
            oc_types: [
                {
                    "title": "Android",
                }
            ],
            connect_types: [
                {
                    "title": 'SDK Driver',
                },
                {
                    "title": "APK Driver",
                },
                {
                    "title": "Sunmi SDK",
                },
                {
                    "title": "SmartCude APK",
                },
                {
                    "title": "Centerm SDK",
                },
                {
                    "title": "Posin SDK",
                }
            ],
            form: {
                model: {
                    icon: "ri-cpu-fill",
                    label: "bank_terminal_model",
                    value: ""
                },
                connect_types: {
                    icon: "mdi-connection",
                    label: "connection_method",
                    value: ""
                },
                oc_types: {
                    icon: "ri-user-2-fill",
                    label: "operatingSystem",
                    value: ""
                },
                version_os: {
                    icon: "ri-user-2-fill",
                    label: "ver_oc",
                    value: ""
                },
                version_apps: {
                    icon: "ri-user-2-fill",
                    label: "verSkyService",
                    value: ""
                }
            }
        }
    },
    created(){
        if(this.obj != ''){
            this.form = this.obj
        }
    },
    methods:{
        input(){
            this.$emit('input', this.form)
        }
    }
}
)
</script>
