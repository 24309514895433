<template>
    <dialogBox @close="this.$emit('close')">
            <template v-slot:title>{{$t('ReopenTicket')}}</template>
            <template v-slot:body>
                <!-- <div class="mb-3">
                    <label class="form-label">{{ $t('Status') }}</label>
                    <select
                        class="form-select mb-3"
                        data-plugin="choices"
                        v-model="form.decidedStatus"
                        >
                        <option v-for="item in listStatus" :key="item" :value="item.value">{{ item.label }}</option>
                    </select>
                </div> -->
                <div class="mb-3">
                    <label  class="form-label">{{ $t('Comment') }}</label>
                    <textarea class="form-control" rows="3" v-model="form.comment" :placeholder="$t('Cause')"></textarea>
                </div>
            </template>
            <template v-slot:footer-bottom>
                <button class="btn link-success fw-medium" @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
                <button 
                    type="button" 
                    class="btn btn-primary" 
                    @click="toReopen()"
                >
                    {{ $t('Add') }}
                </button>
            </template>
        </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { Tickets } from '@/API'
import { storeS } from '@/store'

let apiServe = new Tickets();

export default {
    props: ['id'],
    components: {
        dialogBox
    },
    data(){
        return {
            form: {
                comment: "",
            },
        }
    },
    methods: {
        toReopen(){
            apiServe.reopenTicket(this.id, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(`done`);
                    this.eventBus.emit('UpdateCard', true)
                    this.$emit('close')
                } else if(result.status == 'error'){
                    this.$toast.error(result.error[0]);
                }
            })
        }
    }
}
</script>