<template>
    <!-- Модальне вікно підтвердженням редагування -->

    <!-- <teleport to="body"> -->
        <div class="modal fade show" id="inviteMembersModal" style="display: block;">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content border-0" >
                    <div class="modal-header p-3 ps-4 bg-soft-success" >

                        <!-- Назва модального вікна -->
                        <h5 class="modal-title" id="inviteMembersModalLabel">
                            <slot name="title"></slot>
                        </h5>

                        <!-- Кнопка закриття вікна, "x" -->
                        <button type="button" class="btn-close" @click="$emit('close')"></button>
                    </div>

                    <!-- Основний вміст модального вікна -->
                    <div class="modal-body p-4" >
                        <slot name="body"></slot>
                    </div>
                    
                    <!-- Нижня частина модального вікна з кнопками -->
                    <div class="modal-footer" >
                        <!-- Кнопки, які будуть передані через слот -->
                        <slot name="footer-bottom"></slot>
                    </div>
                </div>
                <!-- end modal-content -->
            </div>
            <!-- modal-dialog -->
        </div>
    <!-- </teleport> -->
</template>

<style scoped>
.modalshow {
    display: block;
    opacity: 1;
}

.modal {
    background: #2e2e2eb8;
    transition: opacity 0.15s linear;
    backdrop-filter: blur(5px);
}

</style>
