<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label class="form-label">{{ $t('specify_date_timeNeedCall') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setHour(new Date().getHours() + 1)" style="margin-right: 5px;">{{ $t('AfterOneHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setHour(new Date().getHours() + 2)" style="margin-right: 5px;">{{ $t('AfterTwoHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setHour(new Date().getHours() + 3)" style="margin-right: 5px;">{{ $t('AfterThreeHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setDay('AtLunch')" style="margin-right: 5px;">{{ $t('AtLunch') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setDay('AtNight')">{{ $t('AtNight') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setDay('tomorrow')" style="margin-right: 5px;">{{ $t('Tomorrow') }}</span>
                </div>
                <div class="row g-2 list_filtr" >
                    <flatpickr v-model="date" :config="config" class="form-control border-0 dash-filter-picker shadow" @onChange="change"></flatpickr>
                    <setTime @setHour="setHour" @setMinute="setMinute" :timeProps="this.time" :key="this.currentIdx"></setTime>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label">{{ $t('Comment') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('NoAnswer')" style="margin-right: 5px;">{{ $t('NoAnswer') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('AskedToDialLater')" style="margin-right: 5px;">{{ $t('AskedToDialLater') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('Busy')" style="margin-right: 5px;">{{ $t('Busy') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('autoAnswer')" style="margin-right: 5px;">{{ $t('autoAnswer') }}</span>
                </div>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium"  @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="toDelay"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import setTime from '@/components/setTime'
import dialogBox from '@/components/modal/dialog'
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { storeS } from '@/store';

export default{
    components: {
        dialogBox,
        flatpickr: Vueflatpickr,
        setTime
    },
    props: ['id'],
    data(){
        return{
            form: {
                comment: '',
                delayedTime: '',
                delayedDate: ''
            },
            date: "today",
            config: {
                mode: "single",
                altInput: true,
                locale: "",
                enableTime: false,
                dateFormat: "Y-m-d",
            },
            time: '',
        }
    },
    created() {
        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }
        
        this.form.delayedDate = String(new Date().getFullYear()) + '-' + (Number(new Date().getMonth())+1) + '-' + String(new Date().getDate())
        
        this.time = String(new Date()).split(' ')[4].split(':')[0] + ':' + String(new Date()).split(' ')[4].split(':')[1]
        this.form.delayedTime = this.time
    }, 
    methods: {
        setHour(e){
            this.time = String(new Date(new Date().setHours(e)).getHours()) + ':' + this.time.split(':')[1]
            this.form.delayedTime = this.time
            this.currentIdx++
        },
        setMinute(e) {
            this.time = this.time.split(':')[0] + ':' + String(new Date(new Date().setMinutes(e)).getMinutes())
            this.form.delayedTime = this.time
            this.currentIdx++
        },
        setDay(a){
            if(a == 'tomorrow') {
                this.date = new Date().setDate(new Date().getDate() + 1)
                this.form.delayedDate = new Date(this.date).getFullYear() + '-' + (new Date(this.date).getMonth() + 1) + '-' + (new Date(this.date).getDate());
                this.time = '10:00'
                this.form.delayedTime = this.time
                this.currentIdx++
            } else if(a == 'AtLunch') {
                this.time = '14:00' 
                this.form.delayedTime = this.time
                this.currentIdx++
            } else if(a == 'AtNight') {
                this.time = '16:00' 
                this.form.delayedTime = this.time
                this.currentIdx++
            }
        },
        change(e, e1, e2){
            this.form.delayedDate = e1;
            this.currentIdx++
            let today = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()
            let delayedDate = this.form.delayedDate ? new Date(this.form.delayedDate).getFullYear() + '-' + (new Date(this.form.delayedDate).getMonth() + 1) + '-' + new Date(this.form.delayedDate).getDate() : ''
            if(today != delayedDate) {
                this.time = '9:00'
                this.form.delayedTime = this.time
            }
        },
        toDelay(){
            let str = this.form.delayedDate + " " + this.form.delayedTime // створюємо потрібну дату

            if(new Date(str) < new Date()) { // дивимося, чи вона не менше, ніж "зараз"
                this.$toast.error(this.$t('error'))
            } else {
                this.$emit('toDelay', this.id, this.form)
                this.$emit('close')
            }
        }
    }
}
</script>
