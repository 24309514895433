<template>
    <!-- 
        Модальне вікно з відображення картки послуги 
    -->

    <modal>
        <!-- Заголовок модалки -->
        <template v-slot:title>{{ $t('service') }} - {{ this.mutate_services(form.nomenclatureName) }}</template>
        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>

            <!-- Номер клієнта, кому надається послуга -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('phone_num') }}</h4>
                <input type="number" class="form-control" id="contactNumber" v-model="form.userPhone" :disabled="this.editStatus == false">
            </div>

            <!-- Назва послуги -->
            <div class="mb-4">
                <h4 class="fs-15">{{ $t('service') }}</h4>
                <!-- <input type="text" class="form-control" id="contactNumber" v-model="form.nomenclatureName" :disabled="this.editStatus == false"> -->
                <Multiselect 
                    v-model="form.nomenclatureName"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.tasktype"
                    :placeholder="$t('Enter_search_term_service')"
                    :disabled="this.editStatus == false"
                />
            </div>

            <div class="row">

                <!-- Статус послуги -->
                <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Status') }}</h4>
                        <!-- <input type="text" class="form-control" id="contactNumber" v-model="form.status" :disabled="this.editStatus == false"> -->
                        <Multiselect 
                            v-model="form.status"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.statusSevice"
                            :placeholder="$t('Status')"
                            :disabled="this.editStatus == false"
                        />
                    </div>
                </div>

                <!-- Вартість послуги -->
                <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('price') }}</h4>
                        <input type="text" class="form-control" id="contactNumber" v-model="form.price" :disabled="this.editStatus == false">
                        <!-- <Multiselect 
                            v-model="form.price"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="true"
                            :options="this.sumSevice"
                            :placeholder="$t('Sum')"
                            :disabled="this.editStatus == false"
                        /> -->
                    </div>
                </div>
            </div>

            <!-- Коментар до послуги -->
            <div class="mb-3">
                <label for="VertimeassageInput" class="form-label">{{ $t('desc') }}</label>
                <textarea class="form-control" rows="3" v-model="form.description" :disabled="this.editStatus == false"></textarea>
            </div>
        </template>

        <template v-slot:footer-bottom>

            <!-- 
                Кнопка "Редагувати" 
                - надає змогу відредагувати послугу
                - йде перевірка на права доступу під номером 512 (редагування послуги) та 2000 (режим бога)
            -->
            <button 
                type="button" 
                v-if="(this.perms['512'] || this.perms['2000']) && editStatus != true" 
                class="btn btn-info" 
                @click="editStatus = true"
            >
                {{ $t('toEditAp') }}
            </button>

            <!-- 
                Кнопка "Зберегти" 
                - надає змогу зберегти послугу
                - відкривається модальне вікно, для підтвердження збереження послуги
                - йде перевірка на права доступу під номером 512 (редагування послуги) та 2000 (режим бога)
            -->
            <button 
                type="button" 
                v-if="(this.perms['512'] || this.perms['2000']) && editStatus == true" 
                class="btn btn-success" 
                @click="dialogEditShow = true"
            >
                {{ $t('save') }}
            </button>

            <!-- 
                Кнопка "Видалити" 
                - надає змогу видалити послугу
                - відкривається модальне вікно, для підтвердження видалення послуги
                - йде перевірка на права доступу під номером 707 (у видалення) та 2000 (режим бога)
            -->
            <button 
                type="button" 
                v-if="this.perms['513'] && editStatus != true" 
                class="btn btn-danger" 
                @click="dialogShow = true"
            >
                {{ $t('Delete') }}
            </button>

        </template>
    </modal>

    <!-- 
        Модальне вікно підтвердження видалення послуги
    -->
    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove(this.form)"
    />
    
    <!-- 
        Модальне вікно підтвердження редагування послуги
    -->
    <modaledit 
        v-if="dialogEditShow" 
        @close="dialogEditShow = false" 
        @toEdit="toEdit(this.form)" 
    />

</template>

<script>
import modal from '@/components/modal-small' // компонент модального вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import modalremove from '@/components/modal/modalremove.vue' // компонент діалогового вікна видалення
import modaledit from '@/components/modal/dialog/dialogEdit.vue' // компонент діалогового вікна редагування
import { Services } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних
import { mutateServices } from '@/usabilityScripts/globalMutate.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Services();

export default ({
    data(){
        return {
            form: { // Дані для форми
                nomenclatureName: "",
                description: "",
                status: "",
                userPhone: "",
                price: ""
            },
            editStatus: false, // прапорець режиму редагування послуги
            dialogShow: false, // прапорець відображення вікна видалення послуги
            dialogEditShow: false, // прапорець відображення вікна редагування послуги
            tasktype: [
                {
                    "label": this.$t('prro_setting'),
                    "value": "prro_setting"
                },
                {
                    "label": this.$t('data_recovery'),
                    "value": "data_recovery"
                },
                {
                    "label": this.$t('connecting_printer'),
                    "value": "connecting_printer"
                },
                {
                    "label": this.$t('terminal_connection'),
                    "value": "terminal_connection"
                },
                {
                    "label": this.$t('scale_connection'),
                    "value": "scale_connection"
                },
                {
                    "label": this.$t('rro_connection'),
                    "value": "rro_connection"
                }
            ],
            statusSevice: [ 
                {
                    "label": this.$t('problem_solved'),
                    "value": "problem_solved"
                },
                {
                    "label": this.$t('problem_not_solved'),
                    "value": "problem_not_solved"
                }
            ],
            sumSevice: [ 
                {
                    "label": "0",
                    "value": "0.00"
                },
                {
                    "label": "200",
                    "value": "200.00"
                },
                {
                    "label": "900",
                    "value": "900.00"
                },
                {
                    "label": "1000",
                    "value": "1000.00"
                },
                {
                    "label": "1500",
                    "value": "1500.00"
                }
            ]
        }
    },
    props: ["objcard"],
    components: { modal, Multiselect, modalremove, modaledit },
    created(){
        this.getdata();
        // this.form.user_phone = this.phone
        // this.form.create_oper_name = this.user.name
    },
    methods: {
        getdata(){
            this.form = this.objcard
        },
        toEdit(e){
            /**
                * Редагування обраної послуги з урахуванням валідації полів.
            */

            if (!e.userPhone) {
                this.$toast.error(this.$t('EnterNumber'));
            }
            if (!e.nomenclatureName) {
                this.$toast.error(this.$t('EnterService'));
            }
            if (!e.description) {
                this.$toast.error(this.$t('EnterDesc'));
            }
            if (!e.status) {
                this.$toast.error(this.$t('EnterStatus'));
            }
            if (!e.price) {
                this.$toast.error(this.$t('EnterPrice'));
            } 
            if (e.userPhone && e.nomenclatureName && e.description && e.status && e.price) {
                apiServe.editService(e.nomenclatureId, e).then(result => {
                    if(result.status == 'done'){
                        this.dialogEditShow = false;
                        this.$toast.success(this.$t('alertChanged'));
                        this.$emit('close');
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);
                        
                        var tempObj = {
                            "nomenclatureId": e.nomenclatureId,
                            "params": e
                        }

                        var errorText = 'Помилка редагування картки послуги'; 
                        var statusRequest = result.status;
                        var api = 'deleteService';
                        var fileRequest = 'src/views/services/view.vue';
                        var params = tempObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    }
                })
            }
        },
        remove: function(e){
            /**
                * Видаляє поточну послугу за допомогою API-запиту та виводить сповіщення про успішне видалення.
                * @param {Object} e - об'єкт з інформацією про послугу
            */

            apiServe.deleteService(e.nomenclatureId).then(result => {
                if(result.status == 'done'){
                    this.dialogShow = false;
                    this.$toast.success(this.$t('Removed'));
                    this.$emit('close');
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка видалення картки послуги'; 
                    var statusRequest = result.status;
                    var api = 'deleteService';
                    var fileRequest = 'src/views/services/view.vue';
                    var params = e.nomenclatureId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        mutate_services(e){
            /*
                Функція для застосування перекладу типу послуги
            */
            return mutateServices(e)
        },
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        }
    }
})
</script>

<style scoped>
.multiselect.is-disabled {
    background-color: var(--vz-input-disabled-bg)!important;
    opacity: 1;
}
</style>