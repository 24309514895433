<template>
    <!-- Компонент перегляду статуса клієнта та встановлення нового статусу -->

    <Multiselect
        v-model="selectStatus"
        :close-on-select="true"
        :searchable="true"
        :create-option="false"
        :options="status"
        @select="change"
    />

</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { SuperAdmin } from '@/API'

let apiServe = new SuperAdmin();

export default {
    props:['clientstatus', 'phone'],
    components: { Multiselect },
    data(){
        return {
            selectStatus: "", // статус
            status: [ // Масив можливих статусів
                {
                    value: '0', label: this.$t('NotSpecified') 
                },
                {
                    value: '1', label: this.$t('statusNew') 
                },
                {
                    value: '2', label: this.$t('firstContact') 
                },
                {
                    value: '3', label: this.$t('Cold') 
                },
                {
                    value: '4', label: this.$t('Warm') 
                },
                {
                    value: '5', label: this.$t('Hot') 
                },
                {
                    value: '6', label: this.$t('Refused') 
                },
                {
                    value: '7', label: this.$t('usesPoster') 
                },
                {
                    value: '8', label: this.$t('usesSmarttouch') 
                },
                {
                    value: '9', label: this.$t('usesTrinipos') 
                },
                {
                    value: '10', label: this.$t('usesCloudPOS') 
                },
                {
                    value: '11', label: this.$t('usesPOS') 
                },
                {
                    value: '12', label: this.$t('uses1c') 
                },
                {
                    value: '13', label: this.$t('useskeeper') 
                },
                {
                    value: '14', label: this.$t('usesiiko') 
                },
                {
                    value: '15', label: this.$t('usesQuickResto') 
                },
                {
                    value: '16', label: this.$t('notRegistered') 
                },
                {
                    value: '17', label: this.$t('tryResurrect') 
                },
                {
                    value: '18', label: this.$t('OldClient') 
                }
            ]
        }
    },
    created(){
        this.selectStatus = this.clientstatus == null ? '0' : this.clientstatus
    },
    methods: {
        change(){
            // Змінюємо статус клієнта за допомогою API
            
            var request = {
                status: this.selectStatus
            }
            apiServe.updateAccountStatus(this.phone, request).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'))
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        }
    }
}
</script>