<template>
    <!-- Модальне вікно для перегляду зображення -->
    
    <!-- 
        Використовуємо Teleport щоб винести компонент за межі їхнього власного DOM.
        - В даному випадку, компонент, буде переміщений в елемент body документу. 
    -->
    <teleport to="body">
        <div id="glightbox-body" class="glightbox-container glightbox-clean" tabindex="-1" role="dialog" aria-hidden="false">
            <!-- Індикатор завантаження -->
            <div class="gloader visible" style="display: none;"></div>
            <!-- Підкладка для затемнення фону -->
            <div class="goverlay"></div>
            <div class="gcontainer">
                <!-- Один слайд (зображення) -->
                <div id="glightbox-slider" class="gslider">
                    <div class="gslide loaded current">
                        <div class="gslide-inner-content">
                            <div class="ginner-container">
                                <div class="gslide-media gslide-image">
                                    <!-- Вміст слоту body, який містить зображення -->
                                    <slot name="body"></slot>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Слот для розміщення додаткових кнопок, які використовуються в цьому модальному вікні -->
                <slot name="buttons"></slot>
                <!-- Кнопка закриття модального вікна -->
                <button 
                    class="gclose gbtn" 
                    @click="this.$emit('close')" 
                    aria-label="Close" 
                    data-taborder="3"
                ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><g><g><path d="M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306C514.019,27.23,514.019,14.135,505.943,6.058z"></path></g></g><g><g><path d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z"></path></g></g></svg></button>
            </div>
        </div>
    </teleport>
</template>

<script>
import { storeS } from "@/store"; // глобальне сховище даних

export default ({
    data() {
        return {

        };
    },
    // Параметри, передані в компонент через властивості
    props:['title', 'ifPrevopen', 'ifNextopen'],
    created() {
        // Додавання класу модального вікна в масив відкритих модалок
        storeS.modalsOpen.push('img')

        // Додавання обробників клавіш "Esc"
        this.eventKey.on('esc', () => {
            if(storeS.modalsOpen[storeS.modalsOpen.length - 1] == 'img' && !document.querySelector('#preloader')) {
                storeS.modalsOpen.pop()
                this.close()
            }
        });

        // Додавання обробників клавіш "Вліво"
        this.eventKey.off('arrowleft')
        this.eventKey.on('arrowleft', () => {
            if(this.ifPrevopen){
                this.prevOpen()
            }
        })
        
        // Додавання обробників клавіш "Вправо"
        this.eventKey.off('arrowright')
        this.eventKey.on('arrowright', () => {
            if(this.ifNextopen){
                this.nextOpen()
            }
        })
    },
    methods: {
        swipeHandler(event){
            // Обробник жестів "swipe" для переміщення до попереднього чи наступного слайда
            if(this.checks['024']){
                if(this.ifNextopen && event == 'right'){
                    if (window.screen.width <= 767) {
                        this.$emit('nextOpen')
                    }
                } else if(this.ifPrevopen && event == 'left'){
                    if (window.screen.width <= 767) {
                        this.$emit('prevOpen')
                    }
                }
            }
        },
        close: function(){
            // Закриття модального вікна
            this.$emit('close');
        },
        prevOpen(){
            // Перехід до попереднього відкритого елемента
            this.$emit('prevOpen')
        },
        nextOpen(){
            // Перехід до наступного відкритого елемента
            this.$emit('nextOpen')
        },
    },
    computed: {
        checks(){
            /*
                Повертає дані налаштувань користувача з глобального стору.
            */
            return storeS.checks
        }
    }
})
</script>

<style scoped>
.modal-fullscreen {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: -webkit-fill-available;
    margin: 20px;
}
.modal-fullscreen .modal-content {
    border-radius: 15px;
}
.modal {
    background: #2e2e2eb8;
    backdrop-filter: blur(5px);
}
@media (max-width: 768px) {
    .modal-fullscreen {
        margin: 0;
    }
    .modal-fullscreen .modal-content {
        border-radius: 0px;
    }
}


</style>
