<template>
    <!-- модальное окно создания заявки -->
    <modal @close="$emit('close')" @send="this.send()">
        <template v-slot:title>{{ $t('newApplication') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('information_create_application') }}</h4>
                    <input type="text" class="form-control" id="contactNumber" placeholder="+380" v-model="this.form.userPhone">
                </div>

                <div class="form-check form-check-success mb-3">
                    <input class="form-check-input" type="checkbox" id="formcheckname" v-model="checkedname">
                    <label class="form-check-label" for="formcheckname">{{ $t('addNameMail') }}</label>
                </div>
                 <div class="row" v-if="checkedname == true || this.perm == true && ((this.obj.createWorkerName != undefined || this.obj.createWorkerName != null)) ">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('name') }}</h4>
                            <input type="text" class="form-control" id="contactNumber" placeholder="Max" v-model="this.form.userName">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('mail') }}</h4>
                            <input type="mail" class="form-control" id="contactNumber" placeholder="m@skyservice.pro" v-model="this.form.userEmail">
                        </div>
                    </div>
                </div> 
                <div class="form-check form-check-success mb-3">
                    <input class="form-check-input" type="checkbox" id="formcheckdate" v-model="checkeddate">
                    <label class="form-check-label" for="formcheckdate">{{ $t('doitLater') }}</label>
                </div>
                
                <div v-if="checkeddate == true" class="mb-3">
                    <label class="form-label mb-0">{{ $t('specify_date_timeNeedCall') }}</label>
                    <b-row>
                        <b-col lg="6">
                            <flatpickr v-model="date.delayedDate" :config="configDate" class="form-control border-0 dash-filter-picker shadow" ></flatpickr>
                        </b-col>
                        <b-col lg="6">
                            <flatpickr v-model="date.delayedTime" :config="configTime" class="form-control border-0 dash-filter-picker shadow"></flatpickr>
                        </b-col>
                    </b-row>
                    <!-- <flat-pickr
                        v-model="this.form.delayedDatetime"
                        :config="config"
                        class="form-control"
                        @onClose="change"
                    ></flat-pickr> -->
                </div>
                <div class="row">
                    <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('choose_a_status') }}</h4>
                        <Multiselect 
                            v-model="this.form.status"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.listStatus"
                            :placeholder="$t('Status')"
                            disabled
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('choose_a_priority') }}</h4>
                            <Multiselect 
                                v-model="this.form.priority"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="this.listPriority"
                                :placeholder="$t('priority')"
                            />
                        </div>
                    </div>
                </div>

                <!-- Швидки коментарі для Макса :) -->
                <div class="mb-3" v-if="this.user.userid == 34 || this.user.userid == 32">
                    <label class="form-label mb-2">{{ $t('QuickComments') }}</label>
                    <div class="mb-2">
                        <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = 'Зафіксовані не синхронізовані чеки, оскільки компанія не оплачена'" style="margin-right: 5px;">Не синхронізовані чеки</span>
                        <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = 'Просить подзвонити, написав на пошті'" style="margin-right: 5px;">Написав на пошті</span>
                        <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = 'Поверніть, будь ласка, в чат'" style="margin-right: 5px;">Поверніть в чат</span>
                    </div>
                </div>

                <div class="mb-3" v-if="this.perm == false || !this.perm ">
                    <label for="VertimeassageInput" class="form-label">{{ $t('Message') }}</label>
                    <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="this.form.comment" :placeholder="$t('infoFutureCall')"></textarea>
                </div>
                <div class="mb-3" v-else>
                    <label for="VertimeassageInput" class="form-label">{{ $t('Message') }}</label>
                    <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="this.form.createComment" :placeholder="$t('infoFutureCall')"></textarea>
                </div>
                <div class="mb-3" v-if="this.user.userid === 33">
                    <button type="button" class="btn btn-success" @click="createMissing()">Missing</button>
                </div>
        </template>
        <template v-slot:footer-bottom >
            <div v-if="Boolean(this.perm) == false">
                <!-- <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
                <button type="button" class="btn btn-success" v-on:click="add">{{ $t('Add') }}</button>
            </div>
            <div v-else>
                <!-- <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
                <button type="button" class="btn btn-success" v-on:click="edit">{{ $t('Edit') }}</button>
            </div>
        </template>
    </modal>
    <dialogOpen v-if="showOpenDialog == true" :edit="this.perm" @openFromEdit = "openRequestFromEdit" @close="closeCreateApplic" :obj="this.form" :type = "this.type" @open="openRequest"></dialogOpen>

</template>

<script>
import modal from '@/components/modal-small'
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Applications } from '@/API.js';
import { storeS } from '../../store';
import dialogOpen from '../../components/globaltable/actions/dialogOpen.vue';
import {mutatePhone} from '@/usabilityScripts/globalMutate.js'
import dbPromise from '../../indexedDB';
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Applications();

export default ({
    props: ["phone", 'perm', 'obj'],
    components: { 
        modal, flatpickr: Vueflatpickr, 
        dialogOpen, 
        Multiselect,
    },
    data(){
        return {
            dontSave: false,
            showmodal: false,
            showOpenDialog: false,
            type:'',
            date1:"",
            received: '',
            checkeddate: false, //чекбокс на "сделать потом"
            checkedname: false, //чекбокс на добавить имя и почту
            config: {
                mode: "single",
                minDate: "today",
                altInput: true,
                locale: "",
                enableTime: true,
                dateFormat: "Y-m-d H:i",
                time_24hr: true
            },
            date: {
                delayedDate: '',
                delayedTime: ''
            },
            configTime: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                minTime: "09:00",
                maxTime: "18:00",
            },
            configDate: {
                minDate: 'today',
                enableTime: false,
                dateFormat: "Y-m-d",
            },
            listStatus: [ //статус заявки
                {
                    label: this.$t('statusNew'),
                    value: "new"
                },
                {
                    label: this.$t('statusrescheduled'),
                    value: "rescheduled"
                },
                {
                    label: this.$t('statusInWork'),
                    value: "jobs"
                },
                {
                    label: this.$t('DelayedOnTime'),
                    value: "delayed"
                },
            ],
            listPriority: [ //тип приоритета
                {
                    "label": this.$t('priorityHigh'),
                    "value": "2"
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": "1"
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": "0"
                }
            ],
            form: {
                userPhone: "", //номер телефона заявки
                userName: "", //имя клиента
                userEmail: "", //почта клиента
                priority: 0, //приоритет заявки
                status: "new", //статус заявки
                comment: "", //коммент 
                callDatetime: "", //время звонка
                action: '',
            },
        }
    },
    beforeUnmount() {
        if(this.dontSave == false) {
            if(this.perm) {
                sessionStorage.setItem('perm', this.perm)
            }
            if(this.form.userPhone !== '' || this.form.comment !== '') {
                let detail = {
                    dateTime: new Date(),
                    text: sessionStorage.getItem('perm') ? sessionStorage.getItem('perm') : '',
                    category: 'applic',
                    form: JSON.stringify(this.form)
                }; 

                dbPromise.add(detail) //додавання в indexedDB
            }   
        }
    },
    created(){
        if(this.perm === true) {
          this.form = this.obj;
        } 
        if(this.phone){
            this.form.userPhone = this.phone
        }
        
        if(storeS.lang == 'uk'){
            this.configDate.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.configDate.locale = Russian
        }
        this.currentDate();

        //перевірка на наявність не закінченних заявок
        dbPromise.getAll('applic')
        //перевірка на наявність не закінченних заявок

        //вибір дії було зроблено, обробка
        
        this.eventBus.off('openOld')
        this.eventBus.on('openOld', status => {
            if(status.status == true) {
                dbPromise.get(status.form.id, status.form.category) // ставимо галочку, що взяли форму, далі вона видалиться

                this.form = JSON.parse(status.form.form)
                if(status.form.text !== '') {
                    this.perm = status.form.text
                }
            }
        })
        
        this.eventBus.off('createNew')
        this.eventBus.on('createNew', status => {
            if(status.status == true) {
                return
            }
        })
        
        //вибір дії було зроблено, обробка
    },
    methods: {
        createMissing(){
            apiServe.createMissing({callnumber: this.form.userPhone}).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('success'))
                }
            })
        },
        send() {
            if(this.perm == (false || undefined || null)) {
                this.add()
            } else {
                this.edit()
            }
        },
        closeCreateApplic() {
            this.showOpenDialog = false; 
            this.dontSave = true;  
            this.$emit('close')
        },
        openRequest(e){
            this.showOpenDialog = false;
            let type = 'application'
            this.$emit('open', e, type); 
        },
        openRequestFromEdit(e){
            this.showOpenDialog = false;
            this.$emit('closeLast');
            this.$emit('open',e) 
        },
        edit() {
            this.form.userPhone = this.form.userPhone[0] == '0' ? (this.phoneReset(this.form.userPhone)) : this.form.userPhone
            this.form.comment = this.form.createComment
            // delete this.form.requestId
            if(this.form.userPhone == '') {
                this.$toast.error(this.$t('enterPhoneNumber'))
                return
            } else if(this.form.priority == '') {
                this.$toast.error(this.$t('choose_a_priority'))
                return
            } else if(this.form.status == '') {
                this.$toast.error(this.$t('choose_a_status'))
                return
            } else if(this.form.comment == '') {
                this.$toast.error(this.$t('enterComment'))
                return
            } else {
                if(this.form.delayedDatetime) {
                    this.form.callDatetime = this.form.delayedDatetime
                }
                apiServe.editRequest(this.form, this.form.requestId).then(result => {
                    if(result.status == "done") {
                        this.$toast.success(this.$t('success'));
                        if(this.checks['025'] == true) {
                            this.dontSave = true
                            // this.form = result.data 
                            this.type = 'application'
                            this.showOpenDialog = true
                        } else {
                            this.dontSave = true
                            this.$emit('close')
                        }
                        
                    } else if(result.status == "error") {
                        this.$toast.error(this.$t('error'));
                    }
                })
                this.comment = ""
            }     
            
        },
        audioPlay() {
            //  https://drive.google.com/uc?id=18ngbDZh5tcXK3JRysHA4hdiWeD9miDSD&export=play
            //  https://drive.google.com/uc?id=1dq4v9k-DZeBFy8iCNHywHsfNUxEcvzMJ&export=play
            //  https://drive.google.com/uc?id=14BpEaftTLhwCWgqE_MN7zFCO2RrwiIJ_&export=play
            var audio = new Audio(storeS.music);
            audio.autoplay = true;
            audio.play();
        },
        change(){
            var arrDate = String(this.form.callDatetime)
            this.form.callDatetime = arrDate;
            // this.form.delayedTime = arrDate[1];
        },
        phoneReset(e) {
            return mutatePhone(e)
        },
        add() {

            this.form.callDatetime = String(this.date.delayedDate) + " " + String(this.date.delayedTime);


            if(new Date(this.form.callDatetime) - new Date() < 0) {
                delete this.form.callDatetime
            }

            if(this.form.userPhone == '') {
                this.$toast.error('enterPhoneNumber')
                return
            } else if(this.form.status == '') {
                this.$toast.error('choose_a_status')
                return
            } else if(this.form.comment == '') {
                this.$toast.error('enterComment')
                return
            } else {
                console.log(this.form.userPhone[0])
                this.form.userPhone = (this.form.userPhone[0] == '0' ? (this.phoneReset(this.form.userPhone.slice(1, this.form.userPhone.length))) : this.form.userPhone)
                apiServe.addRequest(this.form).then(result => {
                    if(result.status === 'error') {
                        switch(result.error[0]){
                            case "Request already exists":
                            this.$toast.error(this.$t("aplic_already_yet"))
                            // this.$emit("close")
                            default: this.$toast.error(this.$t("error"))
                            // this.$emit("close")
                        }

                        var errorText = 'Помилка створення заявки'; 
                        var statusRequest = result.status;
                        var api = 'addRequest';
                        var fileRequest = 'src/views/application/createapplic.vue';
                        var params = this.form;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                            
                    }
                    if(result.status === "done"){
                        // this.audioPlay()
                        this.dontSave = true
                        this.$toast.success(this.$t('сreatedT'));
                        localStorage.removeItem("check_comment"+this.form.id);
                        if(this.checks['025'] == true) {
                            this.form = result.data
                            this.type = 'application'
                            this.showOpenDialog = true

                        } else {
                            this.$emit('close')
                        }
                    }
                })
                this.comment = ""
            }            
            
        },
        currentDate(){
            var date = new Date();
            var dateStr =
            date.getFullYear() + "-" + 
            ("00" + (date.getMonth()+1)).slice(-2) + "-" +
            ("00" + (date.getDate())).slice(-2);

            var timeStr = 
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);

            this.date.delayedTime = timeStr;
            this.date.delayedDate = dateStr;
        },
        normalnayaDataDattebayo(e) { //изменнить дату для формы в привычный вид
            e = e.split(' ')
            let eData = e[0].split(".").reverse();
            e[0] = "20" + eData.join('-');
            return e.join(" ")
        }
    },
    mounted(){
        this.comment = JSON.parse(localStorage.getItem("check_comment")) || [];
    },
    watch: {
        comment(newValue, oldValue) {
            if(this.checks['009'] == true) {
                localStorage.setItem("check_comment", JSON.stringify(newValue));
            }
        }
    },
    computed: {
        user() {
            return storeS.userbase
        },
        checks(){
            return storeS.checks
        },
    }
})
</script>
