<template>
    <activity :obj="form" :activeTab="activeTab"/>
</template>

<script>
import activity from './activity/index'

export default{
    props: ['obj', 'activeTab'],
    components: {
        activity
    },
    data(){
        return{
            form:"",
            msg: ''
        }
    },
    created(){
        this.getdata();
    },
    methods:{
        getdata(){
            this.form = this.obj
        },
    }
}
</script>