<template>
    <!-- 
        Права частина тіла модального вікна
        - включає в себе наступні таби/вкладки :
            * основне 
            * проекти  
            * тікети  
            * ярлики відповіді 
            * зміни 
            * запізнення 
            * перерви 
            * штрафи 
            * відпустки та вихідні
            * безпека 
            * налаштування 
            * історія входу 
    -->

    <b-row>
        <b-col>
            <div>
                <b-tabs pills>
                    <!-- 
                        Таб "Основне"
                        - :obj - параметр-об'єкт з даними по співробітнику
                        - @jobDesc - подія на збереження даних про посадову інструкцію
                        - @toConf - подія на підтвердження ознайомлення з *ПІ
                        - @toShift - подія на збереження даних про зміну
                    -->
                    <b-tab :title="$t('main')" active>
                        <div style="margin:20px 0px;">
                            <mainbox 
                                :obj="this.obj" 
                                @jobDesc="jobDesc" 
                                @toConf="toConf" 
                                @toShift="toShift" 
                            />
                        </div>
                    </b-tab>

                    <!-- 
                        Таб "Проекти"
                        - відкритий тільки для розробників та ceo
                    -->
                    <b-tab 
                        v-if="this.obj.workerJob == 'developer' || this.obj.workerJob == 'CEO'" 
                        :title="$t('navProjects')" 
                        @click="isOpenProjects = true"
                    >
                        <template v-if="isOpenProjects == true">
                            <projects :id="this.obj.workerId" />
                        </template>
                    </b-tab>

                    <!-- 
                        Таб "Тікети"
                        - відкритий тільки для розробників та ceo
                    -->
                    <b-tab 
                        v-if="this.obj.workerJob == 'developer' || this.obj.workerJob == 'CEO'" 
                        :title="$t('tickets')" 
                        @click="isOpenTickets = true" 
                    >
                        <template v-if="isOpenTickets == true">
                            <tickets :id="this.obj.workerId" />
                        </template>
                    </b-tab>

                    <!-- 
                        Таб "Ярлики відповідей"
                    -->
                    <b-tab 
                        :title="$t('answerLabels')" 
                        @click="isOpenLabels = true"
                    >
                        <div v-if="isOpenLabels">
                            <labels 
                                :id="this.obj.workerId"
                                @openlabelbox="openlabelbox" 
                                @editLabel="editLabel" 
                                @deleteLabel="deleteLabel" 
                            />
                        </div>
                    </b-tab>

                    <!-- 
                        Таб "Зміни"
                        - йде перевірка на права доступу 2000 (режим бога) або 506 (зміни) або на користувача, який переглядає
                    -->
                    <b-tab 
                        v-if="perms[2000] || perms[506] || this.obj.workerId == this.user.userid" 
                        :title="$t('shifts')" 
                        @click="isOpenShifts = true"
                    >
                        <template v-if="isOpenShifts == true">
                            <shifts :id="this.obj.workerId"/>
                        </template>
                    </b-tab>

                    <!-- 
                        Таб "Запізнення"
                        - йде перевірка на права доступу 2000 (режим бога) або 506 (запізнення) або на користувача, який переглядає
                    -->
                    <b-tab 
                        v-if="perms[2000] || perms[507] || this.obj.workerId == this.user.userid" 
                        :title="$t('beingLate')"
                        @click="isOpenLaten = true"
                    >
                        <template v-if="isOpenLaten == true">
                            <lateness :id="this.obj.workerId"/>
                        </template>
                    </b-tab>

                    <!-- 
                        Таб "Мої перерви"
                        - йде перевірка на права доступу 2000 (режим бога) або 508 (перерви) або на користувача, який переглядає
                    -->
                    <b-tab 
                        v-if="perms[2000] || perms[508] || this.obj.workerId == this.user.userid" 
                        :title="$t('myBreaks')" 
                        @click="isOpenBreaks = true"
                    >
                        <div v-if="isOpenBreaks" >
                            <breaks :id="this.obj.workerId" />
                        </div>
                    </b-tab>

                    <!-- 
                        Таб "Штрафи"
                        - йде перевірка на права доступу 2000 (режим бога) або 505 (штрафи) або на користувача, який переглядає
                    -->
                    <b-tab 
                        v-if="perms[2000] || perms[505] || this.obj.workerId == this.user.userid" 
                        :title="$t('Fines')"
                        @click="isOpenFines = true"
                    >
                        <div v-if="isOpenFines" >
                            <fines 
                                :id="this.obj.workerId" 
                                @openfinebox="openfinebox" 
                            />
                        </div>
                    </b-tab>

                    <!-- 
                        Таб "Відпустки та вихідні"
                        - йде перевірка на права доступу 2000 (режим бога) або 520 (відпустки/вихідні) або на користувача, який переглядає
                    -->
                    <b-tab 
                        v-if="perms[2000] || perms[520] || this.obj.workerId == this.user.userid" 
                        :title="$t('HolidaysandWeekends')" 
                        @click="isOpenHolidays = true"
                    >
                        <div v-if="isOpenHolidays" >
                            <holidays 
                                :id="this.obj.workerId" 
                                @openvacationbox="openvacationbox" 
                                @openfinebox="openfinebox" 
                            />
                        </div>
                    </b-tab>

                    <!-- 
                        Таб "Безпека"
                        - йде перевірка на права доступу 2000 (режим бога) або 702 (редагування прав доступу)
                    -->
                    <b-tab 
                        v-if="perms[2000] || this.perms['702'] == true" 
                        :title="$t('security')"
                        @click="getPerms()"
                    >
                        <template v-if="isOpenPerms == true">
                            <security 
                                :obj="this.objperms" 
                                :id="this.obj.workerId" 
                                @editUser="editUser" 
                                @editUserPerms="editUserPerms" 
                            />
                        </template>
                    </b-tab>

                    <!-- 
                        Таб "Налаштування"
                        - йде перевірка на права доступу 2000 (режим бога) або 702 (редагування прав доступу), або на користувача, який переглядає
                    -->
                    <b-tab 
                        :title="$t('Settings')" 
                        @click="getUserSettings()" 
                        v-if="this.obj.workerId == this.user.userid  || this.perms[2000] == true || this.perms['702'] == true"
                    >
                        <template v-if="isOpenSetting == true">
                            <setting 
                                :obj="this.objsetting" 
                                :objuser="this.obj" 
                                :id="this.obj.workerId" 
                                @editUserSettings="editUserSettings" 
                                @editUser="editUser"
                            />
                        </template>
                    </b-tab>

                    <!-- 
                        Таб "Історія входу"
                        - йде перевірка на права доступу 2000 (режим бога) або на користувача, який переглядає
                    -->
                    <b-tab 
                        v-if="this.obj.workerId == this.user.userid" 
                        :title="$t('loginHistory')" 
                        @click="isOpenHistory = true"
                    >
                        <div v-if="isOpenHistory" >
                            <loginHistory :id="this.obj.workerId" />
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import projects from './projects/index'
import tickets from './tickets/index'
import labels from './labels/labels'
import breaks from './breaks'
import security from './security/security'
import fines from './fines/fines'
import mainbox from './main/main'
import setting from './settings'
import shifts from './shifts/index'
import lateness from './latenesses/index'
import holidays from './vacation/index'
import loginHistory from './loginHistory/index'
import { Users } from "@/API.js";
import { storeS } from '@/store'

let apiServe = new Users();

export default {
    props:['obj'],
    components: { 
        projects,
        tickets,
        labels,
        breaks,
        security,
        fines,
        mainbox,
        setting,
        shifts,
        lateness,
        holidays,
        loginHistory
    },
    data() {
        return {
            form: "",
            isOpenProjects: false,
            isOpenTickets: false,
            isOpenLabels: false,
            isOpenBreaks: false,
            isOpenFines: false,
            isOpenPerms: false,
            isOpenSetting: false,
            isOpenShifts: false,
            isOpenLaten: false,
            isOpenHolidays: false,
            isOpenHistory: false,
            projects: "",
            tickets: "",
            labels: "",
            breaks: "",
            fines: "",
            objperms: "",
            objsetting: ""
        }
    },
    created(){
    },
    methods: {
        openvacationbox() {
            this.$emit('openvacationbox')
        },
        getPerms(){
            if(this.isOpenPerms === false) {
                apiServe.getPermsUser(this.obj.workerId).then(result => {
                    if(result.status == "done"){
                        this.objperms = result.data;
                        this.isOpenPerms = true;
                    } else if (result.status == 'error'){
                        this.$toast.error(this.$t('error') + '#702')
                    }
                }).catch(err => {
                    this.$toast.error(this.$t('error') + '#702');
                })
            }
        },
        getUserSettings(){
            if(this.isOpenSetting === false) {
                apiServe.getSettingsUser(this.obj.workerId).then(result => {
                    if(result.status == "done"){
                        this.objsetting = result.data;
                        this.isOpenSetting = true;
                    } else if (result.status == 'error'){
                        this.$toast.error(this.$t('error') + '#708')
                    }
                }).catch(err => {
                    this.$toast.error(this.$t('error') + '#708');
                })
            }
        },
        editUserPerms(id, form, checkSuper){
            var saveperms = {
                workerId: id,
                perms: form.perms,
                super: checkSuper
            };
            apiServe.editPermsUser(saveperms).then(result => {
                if(result.status == "done"){
                    this.$toast.success(this.$t('changeswereSuccessful'));
                    this.eventBus.emit('editUser', true)
                    localStorage.removeItem('tempPerms')
                    
                    if(id == this.user.userid){
                        location.reload()

                    }
                }
                else if (result.status == 'error'){
                    this.$toast.error(this.$t('error') + '#702')
                }
            }).catch(err => {
                this.$toast.error(this.$t('error') + '#702');
            })
        },
        editUserSettings(id, form){
            if (!id) {
                //перевіряємо чи вказаний юзер
                this.$toast.error(this.$t('EnterConnectionId'));
            }
            apiServe.saveWorkerSettings(id, form).then(result => {
                if(result.status == "done"){
                    this.$toast.success(this.$t('changeswereSuccessful'));

                    if(id == this.user.userid){
                        location.reload()
                    }
                    
                } else if (result.status == 'error'){
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            }).catch(err => {
                this.$toast.error(this.$t('error') + " " + result.error[0]);
            })
        },
        jobDesc(){
            // Метод для відправки події "Редагування посадової інструкції"
            this.$emit('jobDesc')
        },
        toConf(){
            // Метод для відправки події "Підтвердження ознайомлення"
            this.$emit('toConf')
        },
        toShift(time){
            // Метод для відправки події "Збереження змін у графіку"
            this.$emit('toShift', time)
        },
        editUser(e){
            // Метод для відправки події "Редагування користувача"
            this.$emit('editUser', e)
        },  
        openprojectbox: function(){
            // Метод для відправки події "Відкриття вікна проектів"
            this.$emit('openprojectbox')
        },
        openlabelbox: function(){
            // Метод для відправки події "Відкриття вікна міток"
            this.$emit('openlabelbox')
        },
        openfinebox: function(){
            // Метод для відправки події "Відкриття вікна штрафів"
            this.$emit('openfinebox')
        },
    },
    computed: {
        /*
            Властивість, яка повертає дані за користувача зі збережених даних у глобальному стору.
        */
        user() {
            return storeS.userbase
        },
        /*
            Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
        */
        perms() {
            return storeS.perms
        }
    }
}
</script>