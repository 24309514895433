<template>
    <!-- 
        Модальне вікно з відображення картки перерви 
    -->

    <dialogBox >
        <template v-slot:title>{{ $t('break') }}</template>
        <template v-slot:body>
            <b-row>

                <!-- Причина перерви -->
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('reasonforbreak') }}</h4>
                        <input class="form-control" :value="mutateBreak(form.reason)" disabled />
                    </div>
                </b-col>

                <!-- Початок перерви -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('BreakStart') }}</h4>
                        <input class="form-control" v-model="this.form.createDatetime" disabled />
                    </div>
                </b-col>

                <!-- Кінець перерви -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('BreakEnd') }}</h4>
                        <input class="form-control" :value="this.form.closeDatetime == '1970-01-01 06:00:00' ? '-' : this.form.closeDatetime " disabled />
                    </div>
                </b-col>

                <!-- Тривалість перерви -->
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Duration') }}</h4>
                        <input class="form-control" v-model="this.form.durationTime" disabled />
                    </div>
                </b-col>

                <!-- Коментар до перерви -->
                <b-col lg="12" v-if="form.comment != ''">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Comment') }}</h4>
                        <input class="form-control" v-model="this.form.comment" disabled />
                    </div>
                </b-col>
            </b-row>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { mutateBreak } from '@/usabilityScripts/globalMutate.js'

export default {
    props: ['objCard'],
    components: {
        dialogBox
    },
    data(){
        return{
            form: ''
        }
    },
    created(){
        this.form = this.objCard
    },
    methods: {
        mutateBreak(e){
            /*
                Функція для застосування перекладу причини перерви
            */

            return mutateBreak(e)
        }
    }
}
</script>