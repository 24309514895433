<template>
    <!-- 
        Таб "Безпека", включає в себе - 
        - редагування списку IP-адрес користвувача, з яких він може авторизуватись в інсайді
        - права доступу по інсайду
    -->

    <!-- 
        Блок "Доступ"
        - включає в собі дозволені IP-адреси, якщо адрес немає, блок не виконується
        - дані може змінювати юзер з правами доступу 2000 (режим бога) або 708 (редагувати доступ по IP (відкриття і закриття зміни))
    -->
    <b-row v-if="this.perms[2000] || this.perms[708]">
        <b-col lg="6">
            <b-card>
                <b-card-title><h4><b>{{ $t('Access') }}</b></h4></b-card-title>
                <b-card-text>
                    <b-row>
                        <b-col lg="12">
                            <div>
                                <label for="exampleInputtime" class="form-label">{{$t('AllowedIP')}}</label>
                                <Multiselect
                                    v-if="this.writeIP == true"
                                    v-model="this.IP_new"
                                    mode="tags"
                                    :close-on-select="false"
                                    :searchable="true"
                                    :create-option="true"
                                    :options="this.IP_new"
                                />
                            </div>
                            <ul style="list-style-type: none">
                                <li v-for="(idx, ip) in this.ip.ip" :key="ip"> 
                                    <span class="fs-14 badge bg-secondary mb-1">{{ idx }} </span><br />
                                </li>
                            </ul>
                        </b-col>
                    </b-row>
                    <b-button v-if="perms['2000'] == true || perms['708'] == true" @click="IP_settings()" variant="success" class="waves-effect waves-light" style="margin-top:10px"><i class="align-bottom me-1"></i>{{ $t('Edit') }}</b-button>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
    
    <b-card>
        <div class="mb-3">
            <!-- блок з швидким налаштуванням прав доступу -->
            <h5 class="card-title text-decoration-underline mb-3" style="cursor:pointer" >
                <b @click="blockAccess = !blockAccess">{{ $t('accessRights') }}:</b> 
            </h5>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('QuickPermSetting') }}</h4>
                <select
                    class="form-select mb-3"
                    @change="changeType(selectType)"
                    v-model="selectType"
                    :placeholder="$t('Position')"
                >
                    <option v-for="item in typeWorks" :key="item" :value="item">{{ item.name }}</option>
                </select>
            </div>

            <!-- пошук по правам доступу -->
            <input v-model="search" class="form-control" :placeholder="$t('searc_inp')" />

            <!-- Цикл виведення кожного елемента списку налаштувань -->
            <template v-for="(item, i) in filteredItems" :key="i.key" >
                <!-- Перевірка умови для відображення елементу -->
                <ul class="list-unstyled mb-0" v-if="isShowItem(item)">
                    <li class="d-flex block-as" >
                         <!-- Чекбокс для вибору налаштування -->
                        <div class="check-as form-check form-check-success" >
                            <input 
                                type="checkbox" 
                                autocomplete="off" 
                                v-model="this.form.perms[item.value]" 
                                @change="select(this.form.perms[item.value], item)" 
                                class="form-check-input" 
                                :id="`first_level_input_${item.value}`" 
                            >
                        </div>

                        <!-- Назва налаштування та інформаційний текст -->
                        <div class="flex-grow-1">
                            <label class="form-check-label fs-14" :for="`first_level_input_${item.value}`">
                                <b>{{ item.name }}</b>
                            </label>
                            <p class="text-muted">
                                {{ item.desc }}
                            </p>
                        </div>

                        <!-- Додаткові дії для вибраних налаштувань -->
                        <div class="col-auto" v-if="item.isOpen != undefined">
                            <!-- 
                                Кнопка для відкриття прихованого списку (саб-налаштувань), або сховати
                            -->
                            <button 
                                type="button" 
                                class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email" 
                                @click="item.isOpen = !item.isOpen" 
                                style="margin-right: 7px;" 
                            >
                                <i class="bx bx-down-arrow-alt align-bottom"></i>
                            </button>

                            <!-- 
                                Кнопка для проставлення чекбоксів до всіх налаштувань вибранного пункту
                            -->
                            <button 
                                type="button" 
                                class="btn btn-soft-success btn-icon btn-sm fs-16 close-btn-email" 
                                @click="checkAllchild(item)"
                            >
                                <i class="bx bx-checkbox-checked align-bottom"></i>
                            </button>
                        </div>
                    </li>

                    <!-- Внутрішні дочірні налаштування, якщо є -->
                    <div v-if="item.children && item.isOpen == true">
                        <!-- Внутрішні дочірні налаштування -->
                        <template v-for="(subIndex, j) in item.children" :key="j">
                            <!-- Перевірка умови для відображення елементу -->
                            <ul v-if="isShowItem(subIndex)">
                                <li class="d-flex block-as">
                                    <!-- Чекбокс для вибору дочірнього налаштування -->
                                    <div class="check-as form-check form-check-info">
                                        <input 
                                            type="checkbox" 
                                            autocomplete="off" 
                                            v-model="this.form.perms[subIndex.value]" 
                                            @change="firstChild(this.form.perms[subIndex.value], subIndex)" 
                                            class="form-check-input" 
                                            :id="`second_level_input_${subIndex.value}`" 
                                        />
                                    </div>

                                    <!-- Назва дочірнього налаштування та інформаційний текст -->
                                    <div class="flex-grow-1">
                                        <label class="form-check-label fs-14" :for="`second_level_input_${subIndex.value}`">
                                            <b>{{ subIndex.name }}</b>
                                        </label>
                                        <p class="text-muted">
                                            {{ subIndex.desc}}
                                        </p>
                                    </div>

                                    <!-- Додаткові дії для вибраних налаштувань -->
                                    <div class="col-auto" v-if="subIndex.isOpen != undefined">
                                         <!-- 
                                            Кнопка для відкриття прихованого списку (саб-налаштувань), або сховати
                                        -->
                                        <button 
                                            type="button"
                                            class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email" 
                                            @click="subIndex.isOpen = !subIndex.isOpen" 
                                            style="margin-right: 7px;" 
                                        >
                                            <i class="bx bx-down-arrow-alt align-bottom"></i>
                                        </button>

                                        <!-- 
                                            Кнопка для проставлення чекбоксів до всіх налаштувань вибранного пункту
                                        -->
                                        <button 
                                            type="button" 
                                            class="btn btn-soft-success btn-icon btn-sm fs-16 close-btn-email"
                                            @click="checkAllchild(item)"
                                        >
                                            <i class="bx bx-checkbox-checked align-bottom"></i>
                                        </button>
                                    </div>
                                </li>
                            </ul>

                            <!-- Внутрішні дочірні налаштування другого рівня -->
                            <div v-if="subIndex.child && subIndex.isOpen == true">
                                <template  v-for="(subsubIndex, k) in subIndex.child" :key="k">
                                    <!-- Перевірка умови для відображення елементу -->
                                    <ul class="block-sub" v-if="isShowItem(subsubIndex)">
                                        <li class="d-flex ">
                                            <!-- Чекбокс для вибору дочірнього налаштування -->
                                            <div class="check-as">
                                                <input 
                                                    type="checkbox" 
                                                    autocomplete="off" 
                                                    v-model="this.form.perms[subsubIndex.value]" 
                                                    @change="secChild(this.form.perms[subsubIndex.value], subsubIndex, item)" 
                                                    class="form-check-input" 
                                                    :id="`three_level_input_${subsubIndex.value}`" 
                                                />
                                            </div>

                                            <!-- Назва дочірнього налаштування та інформаційний текст -->
                                            <div class="flex-grow-1">
                                                <label class="form-check-label fs-14" :for="`three_level_input_${subsubIndex.value}`">
                                                    <b>{{ subsubIndex.name }}</b>
                                                </label>
                                                <p class="text-muted">
                                                    {{ subsubIndex?.desc}}
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </template>
                    </div>
                </ul>
            </template>
        </div>

        <!-- Кнопка для збереження внесених змін -->
        <div class="col-lg-12">
            <div class="text-end">
                <button @click="editUserPerms" class="btn btn-success">
                    {{ $t('Edit') }}
                </button>
            </div>
        </div>
    </b-card>

    <!-- <b-card>
        {{ $t('authorizationHistory') }} (хз нужна ли) 
    </b-card> -->
</template>

<script>
import { Users } from '@/API'
import { storeS } from '@/store'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

let apiServe = new Users();

export default {
    props: ['obj', 'id'],
    components: {  Multiselect },
    data() {
        return {
            writeIP: false,
            blockAccess: false,
            form:"",
            edit: true,
            search: "",
            selectType: "",
            typeWorks: [
                {
                    name: 'Support',
                    value: "support",
                    opt: [
                        '100', 
                        '101',
                        '102',
                        '104',
                        '110',
                        '111',
                        '112',
                        '114',
                        '200',
                        '201',
                        '300',
                        '301',
                        '304',
                        '305',
                        '370',
                        '400',
                        '401',
                        '402',
                        '403',
                        // '404',
                        '405',
                        '410',
                        '411',
                        '412',
                        '413',
                        '420',
                        '421',
                        '422',
                        '423',
                        '424',
                        '510',
                        '511',
                        '610',
                        '700',
                        '800',
                        '801',
                        '900',
                        '1000',
                        '1100',
                        '1101',
                        '1102',
                        '1103',
                        '1200',
                        '1300',
                        '1400',
                        '1500',
                        '1700',
                        '1800',
                        '1810',
                    ]
                },
                {
                    name: this.$t('Tester'),
                    value: "tester",
                    opt: [
                        '100',
                        '101',
                        '200',
                        '201',
                        '300',
                        '301',
                        '304',
                        '305',
                        '306',
                        '400',
                        '401',
                        '402',
                        '403',
                        '404',
                        '405',
                        '510',
                        '511',
                        '512',
                        '610',
                        '700'
                    ]
                },
                {
                    name: this.$t('Developer'),
                    value: "dev",
                    opt: [
                        '300', 
                        '301',
                        '302', 
                        '305',
                        '350', 
                        '351',
                        '352', 
                        // '354',
                        '370',
                        '371',
                        '372',
                        '380',
                        '381',
                        '382',
                        '383',
                        '400',
                        '403',
                        '406',
                        '410', 
                        '411', 
                        '412', 
                        '413', 
                        '420',
                        '421',
                        '422',
                        '423',
                        '610', 
                        '620',
                        '621',
                        '624', 
                        '700',
                        '705',
                    ]
                },
                {
                    name: 'SMM | ' + this.$t('Marketer'),
                    value: "smm",
                    opt: [
                        '350', 
                        '351',
                        '352',
                        '610', 
                        '700'
                    ]
                },
                {
                    name: 'CEO',
                    value: "ceo",
                    opt: [
                        '100', 
                        '101',
                        '102',
                        '103',
                        '104',
                        '105',
                        '110',
                        '111',
                        '112',
                        '113',
                        '114',
                        '115',
                        '200',
                        '201',
                        '202',
                        '203',
                        '204',
                        '205',
                        '300',
                        '301',
                        '302',
                        '303',
                        '304',
                        '305',
                        '306',
                        '307',
                        '350',
                        '351',
                        '352',
                        '353',
                        '354',
                        '370',
                        '371',
                        '372',
                        '373',
                        '380',
                        '381',
                        '382',
                        '383',
                        '400',
                        '401',
                        '402',
                        '403',
                        '404',
                        '405',
                        '406',
                        '410',
                        '411',
                        '412',
                        '413',
                        '420',
                        '421',
                        '422',
                        '423',
                        '424',
                        '500',
                        '501',
                        '502',
                        '503',
                        '504',
                        '505',
                        '506',
                        '507',
                        '508',
                        '510',
                        '511',
                        '512',
                        '513',
                        '520',
                        '521',
                        '522',
                        '610',
                        '620',
                        '621',
                        '622',
                        '623',
                        '624',
                        '700',
                        '701',
                        '702',
                        '703',
                        '704',
                        '705',
                        '706',
                        '800',
                        '801',
                        '802',
                        '803',
                        '900',
                        '1000',
                        '1001',
                        '1002',
                        '1003',
                        '1100',
                        '1101',
                        '1102',
                        '1103',
                        '1104',
                        '1200',
                        '1300',
                        '1301',
                        '1302',
                        '1303',
                        '1304',
                        '1400',
                        '1401',
                        '1402',
                        '1403',
                        '1500',
                        '1501',
                        '1502',
                        '1503',
                        '1600',
                        '1601',
                        '1602',
                        '1603',
                        '1700',
                        '1701',
                        '1702',
                        '1703',
                        '1704',
                        '1800',
                        '1810',
                        '1820',
                        '1821',
                        '1830',
                        '1840',
                        '1841',
                        '1850',
                        '1860',
                        '2000',
                    ]
                },
            ],
            option: [
                {
                    name: this.$t('GodMode'),
                    desc: this.$t('GodMode'),
                    isParent: true,
                    value: 2000,
                    children: []
                },
                {
                    name: this.$t('calls_all'),
                    desc: this.$t('callsdesc'),
                    isParent: true,
                    isOpen: false,
                    value: 100,
                    children: [
                        {
                            name: this.$t('AddApplication'),
                            desc: this.$t('callsdescAdd'),
                            value: 101,
                            parent: 100,
                        },
                        {
                            name: this.$t("SubmitApplication"),
                            desc: this.$t('callsdescSubmit'),
                            value: 102,
                            parent: 100,
                        },
                        {
                            name: this.$t("DeleteApplication"),
                            desc: this.$t('callsdescDelete'),
                            value: 103,
                            parent: 100,
                        },
                        {
                            name: this.$t("takeToWork"),
                            desc: this.$t('callsdesctoWork'),
                            value: 104,
                            parent: 100,
                        },
                        {
                            name: this.$t("toEditAp"),
                            desc: this.$t('callsdesctotoEdit'),
                            value: 105,
                            parent: 100,
                        },
                    ]
                },
                {
                    name: this.$t('presentations'),
                    desc: this.$t('PresentationsDesc'),
                    isParent: true,
                    isOpen: false,
                    value: 110,
                    children: [
                        {
                            name: this.$t('AddPresentation'),
                            desc: this.$t('PresentationDesctoAdd'),
                            value: 111,
                            parent: 110,
                        },
                        {
                            name: this.$t("takeToWork"),
                            desc: this.$t('PresentationDesctoWork'),
                            value: 112,
                            parent: 110,
                        },
                        {
                            name: this.$t("toEditAp"),
                            desc: this.$t('PresentationDesctoEdit'),
                            value: 113,
                            parent: 110,
                        },
                        {
                            name: this.$t("TrPresentation"),
                            desc: this.$t('PresentationDesctoTransfer'),
                            value: 114,
                            parent: 110,
                        },
                        {
                            name: this.$t("Delete"),
                            desc: this.$t('PresentationDesctoDelete'),
                            value: 115,
                            parent: 110,
                        },
                    ]
                },
                {
                    name: this.$t('tasks'),
                    desc: this.$t('tasksdesc'),
                    isParent: true,
                    isOpen: false,
                    value: 200,
                    children: [
                        {
                            name: this.$t('AddTask'),
                            desc: this.$t('tasksdescAdd'),
                            value: 201,
                            parent: 200,
                        },
                        {
                            name: this.$t("takeToWork"),
                            desc: this.$t('TaksDesctoWork'),
                            value: 202,
                            parent: 200,
                            isOpen: false,
                            isParent: true,
                            child: [
                                {
                                    name: this.$t("onNewVersion"),
                                    desc: this.$t('onNewVersion'),
                                    value: 2021,
                                    parent: 202,
                                },
                                {
                                    name: this.$t("switchTypeAcc"),
                                    desc: this.$t('switchTypeAcc'),
                                    value: 2022,
                                    parent: 202,
                                },
                                {
                                    name: this.$t("recoverTT"),
                                    desc: this.$t('recoverTTDesc'),
                                    value: 2023,
                                    parent: 202,
                                },
                                {
                                    name: this.$t("clearStat"),
                                    desc: this.$t('clearStatDesc'),
                                    value: 2024,
                                    parent: 202,
                                },
                                {
                                    name: this.$t("copyStatFransh"),
                                    desc: this.$t('copyStatFransh'),
                                    value: 2025,
                                    parent: 202,
                                },
                                {
                                    name: this.$t("copyStatInComp"),
                                    desc: this.$t('copyStatInComp'),
                                    value: 2026,
                                    parent: 202,
                                },
                                {
                                    name: this.$t("resepPass"),
                                    desc: this.$t('resepPassDesc'),
                                    value: 2027,
                                    parent: 202,
                                },
                                {
                                    name: this.$t("syncMode"),
                                    desc: this.$t('syncModeDesc'),
                                    value: 2028,
                                    parent: 202,
                                },
                                {
                                    name: this.$t("multiTermMode"),
                                    desc: this.$t('multiTermModeDesc'),
                                    value: 2029,
                                    parent: 202,
                                },
                                {
                                    name: this.$t("switchDomen"),
                                    desc: this.$t('switchDomen'),
                                    value: 20210,
                                    parent: 202,
                                },
                                {
                                    name: this.$t("changeLimComp"),
                                    desc: this.$t('changeLimComp'),
                                    value: 20211,
                                    parent: 202,
                                },
                                {
                                    name: "Копіювання даних з старої версії в нову",
                                    desc: "Користувач зможе скопіювати дані з старої версії на нову, вказавши дві компанії та торгову точку.",
                                    value: 20212,
                                    parent: 202,
                                },
                            ]
                        },
                        {
                            name: this.$t("Delete"),
                            desc: this.$t('tasksdescDelete'),
                            value: 203,
                            parent: 200,
                        },
                        {
                            name: this.$t("toEditAp"),
                            desc: this.$t('tasksdesctoEdit'),
                            value: 204,
                            parent: 200,
                        },
                        {
                            name: this.$t("delayTask"),
                            desc: this.$t('approveDelayTask'),
                            value: 205,
                            parent: 200,
                        },
                    ]
                },
                {
                    name: this.$t('tickets'),
                    desc: this.$t('ticketsdesc'),
                    isParent: true,
                    isOpen: false,
                    value: 300,
                    children: [
                        {
                            name: this.$t('AddTicket'),
                            desc: this.$t('ticketsdescAdd'),
                            value: 301,
                            parent: 300,
                        },
                        {
                            name: this.$t("takeToWork"),
                            desc: this.$t('ticketsdesctoWork'),
                            value: 302,
                            parent: 300,
                        },
                        {
                            name: this.$t("DeleteTicket"),
                            desc: this.$t('ticketsdescDelete'),
                            value: 303,
                            parent: 300,
                        },
                        {
                            name: this.$t("EditTicket"),
                            desc: this.$t('TicketDesctoEdit'),
                            value: 304,
                            parent: 300,
                        },
                        {
                            name: this.$t("AddReport"),
                            desc: this.$t('ticketsdescAddReport'),
                            value: 305,
                            parent: 300,
                        },
                        {
                            name: this.$t("DeleteReport"),
                            desc: this.$t('ticketsdescDeleteReport'),
                            value: 306,
                            parent: 300,
                        },
                        {
                            name: this.$t("Ticlket_appoint"),
                            desc: this.$t('Ticket_appoint_desc'),
                            value: 307,
                            parent: 300,  
                        },
                    ]
                },
                {
                    //под проекты
                    name: this.$t('navProjects'),
                    desc: this.$t('Projectsdesc'),
                    isParent: true,
                    isOpen: false,
                    value: 350,
                    children: [
                        {
                            name: this.$t('AddProject'),
                            desc: this.$t('ProjectsdescAdd'),
                            value: 351,
                            parent: 350,
                        },
                        {
                            name: this.$t("takeToWork"),
                            desc: this.$t('ProjectsdesctoWork'),
                            value: 352,
                            parent: 350,
                        },
                        {
                            name: this.$t("DeleteProject"),
                            desc: this.$t('ProjectsdescDelete'),
                            value: 353,
                            parent: 350,
                        },
                        {
                            name: this.$t("EditProject"),
                            desc: this.$t('ProjectsdescEdit'),
                            value: 354,
                            parent: 350,
                        },
                    ]
                },
                {
                    //під файловий менеджер
                    name: this.$t('fileManager'),
                    desc: this.$t('fileManagerdesc'),
                    isParent: true,
                    isOpen: false,
                    value: 950,
                    children: [
                        {
                            name: this.$t('CreateFolders'),
                            desc: this.$t('fileManagerdescCreatFol'),
                            value: 951,
                            parent: 950,
                        },
                        {
                            name: this.$t("UploadFoldersFiles"),
                            desc: this.$t('fileManagerdescDownload'),
                            value: 951,
                            parent: 950,
                        },
                    ]
                },
                {
                    //под заметки
                    name: this.$t('Notes'),
                    desc: this.$t('Notesdesc'),
                    isParent: true,
                    isOpen: false,
                    value: 410,
                    children: [
                        {
                            name: this.$t('AddNote'),
                            desc: this.$t('NotesdescAdd'),
                            value: 411,
                            parent: 410,
                        },
                        {
                            name: this.$t("EditNote"),
                            desc: this.$t('NotesdescEdit'),
                            value: 412,
                            parent: 410,
                        },
                        {
                            name: this.$t("DeleteNote"),
                            desc: this.$t('NotesdescDelete'),
                            value: 413,
                            parent: 410,
                        }
                    ]
                },
                {
                    //под календарь
                    name: this.$t('Calendar'),
                    desc: this.$t('Eventsdesc'),
                    isParent: true,
                    isOpen: false,
                    value: 420,
                    children: [
                        {
                            name: this.$t('addEvent'),
                            desc: this.$t('EventdescAdd'),
                            value: 421,
                            parent: 420,
                        },
                        {
                            name: this.$t("EditEvent"),
                            desc: this.$t('EventdescEdit'),
                            value: 422,
                            parent: 420,
                        },
                        {
                            name: this.$t("DeleteEvent"),
                            desc: this.$t('EventdescDelete'),
                            value: 423,
                            parent: 420,
                        },
                        {
                            name: this.$t("editAccEvent"),
                            desc: this.$t('EditAccDescEdit'),
                            value: 424,
                            parent: 420,
                        }
                    ]
                },
                {
                    name: this.$t('deadline'),
                    desc: this.$t('DeadLineDesc'),
                    isParent: true,
                    isOpen: false,
                    value: 370,
                    children: [
                        {
                            name: this.$t('AddDeadLine'),
                            desc: this.$t('DeadLinedescAdd'),
                            value: 371,
                            parent: 370,
                        },
                        {
                            name: this.$t("EditDeadLine"),
                            desc: this.$t('DeadLinedescEdit'),
                            value: 372,
                            parent: 370,
                        },
                        {
                            name: this.$t("DeleteDeadLine"),
                            desc: this.$t('DeadLinedescDelete'),
                            value: 373,
                            parent: 370,
                        },
                    ]
                },
                {
                    name: 'API',
                    desc: this.$t('ApiDesc'),
                    isParent: true,
                    isOpen: false,
                    value: 380,
                    children: [
                        {
                            name: this.$t('AddApi'),
                            desc: this.$t('ApidescAdd'),
                            value: 381,
                            parent: 380,
                        },
                        {
                            name: this.$t("EditApi"),
                            desc: this.$t('ApidescEdit'),
                            value: 382,
                            parent: 380,
                        },
                        {
                            name: this.$t("DeleteApi"),
                            desc: this.$t('ApidescDelete'),
                            value: 383,
                            parent: 380,
                        },
                    ]
                },
                {
                    name: this.$t('archive'),
                    desc: this.$t('archivedesc'),
                    isParent: true,
                    isOpen: false,
                    value: 400,
                    children: [
                        {
                            name: this.$t('archiveApplic'),
                            desc: this.$t('archivedescarchiveApplic'),
                            value: 401,
                            parent: 400,
                        },
                        {
                            name: this.$t("archiveTasks"),
                            desc: this.$t('archivedescarchiveTasks'),
                            value: 402,
                            parent: 400,
                        },
                        {
                            name: this.$t("archiveTickets"),
                            desc: this.$t('archivedescarchiveTickets'),
                            value: 403,
                            parent: 400,
                        },
                        {
                            name: this.$t("archiveProjects"),
                            desc: this.$t('archivedescarchiveProjects'),
                            value: 406,
                            parent: 400,
                        },
                        {
                            name: this.$t("archiveShift"),
                            desc: this.$t('archivedescarchiveShift'),
                            value: 404,
                            parent: 400,
                        },
                        {
                            name: this.$t("archiveSms"),
                            desc: this.$t('archivedescarchiveSms'),
                            value: 405,
                            parent: 400,
                        }
                    ]
                },
                {
                    name: this.$t('statistics'),
                    desc: this.$t('statisticsdesc'),
                    isParent: true,
                    isOpen: false,
                    value: 500,
                    children: [
                        {
                            name: this.$t('byclient'),
                            desc: this.$t('statisticsdescbyclient'),
                            value: 501,
                            parent: 500,
                        },
                        {
                            name: this.$t("bySupport"),
                            desc: this.$t('statisticsdescbySupport'),
                            value: 502,
                            parent: 500,
                        },
                        {
                            name: this.$t("byDevstat"),
                            desc: this.$t('statisticsdescbyDev'),
                            value: 503,
                            parent: 500,
                        },
                        {
                            name: this.$t("byWorketrsstat"),
                            desc: this.$t('statisticsdescbyWorkers'),
                            value: 504,
                            parent: 500,
                        },
                        {
                            name: this.$t("Fines"),
                            desc: this.$t('archivedescarchiveFines'),
                            value: 505,
                            parent: 500,
                        },
                        {
                            name: this.$t("shifts"),
                            desc: this.$t('archivedescarchiveShift'),
                            value: 506,
                            parent: 500,
                        },
                        {
                            name: this.$t("HolidaysandWeekends"),
                            desc: this.$t('Holidaysdesc'),
                            value: 520,
                            isParent: true,
                            isOpen: false,
                            parent: 500,
                            child : [
                                {
                                    name: this.$t('HolidaysConfCancel'),
                                    desc: this.$t('HolidaysdescConfirm'),
                                    value: 521,
                                    parent: 520,
                                },
                                {
                                    name: this.$t('HolidaysDelete'),
                                    desc: this.$t('HolidaysdescDelete'),
                                    value: 522,
                                    parent: 520,
                                }
                            ]
                        },
                        {
                            name: this.$t("beingLate"),
                            desc: this.$t('archivedescarchiveLate'),
                            value: 507,
                            parent: 500,
                        },
                        {
                            name: this.$t("breaks"),
                            desc: this.$t('archivedescarchiveBreaks'),
                            value: 508,
                            parent: 500,
                        },
                        {
                            name: this.$t("Services"),
                            desc: this.$t('archivedescarchiveService'),
                            value: 510,
                            isParent: true,
                            isOpen: false,
                            parent: 500,
                            child : [
                                {
                                    name: this.$t('add_service'),
                                    desc: this.$t('ServicedesctoAdd'),
                                    value: 511,
                                    parent: 510,
                                },
                                {
                                    name: this.$t('toEditAp'),
                                    desc: this.$t('ServicedescDelete'),
                                    value: 512,
                                    parent: 510,
                                },
                                {
                                    name: this.$t('Delete'),
                                    desc: this.$t('ServicedescEdit'),
                                    value: 513,
                                    parent: 510,
                                }
                            ]
                        }
                    ]
                },
                {
                    name: this.$t('shop'),
                    desc: this.$t('shopPermsDesk'),
                    isParent: true,
                    isOpen: false,
                    value: 1200,
                    children: [
                        {
                            name: this.$t('Products'),
                            desc: this.$t('ProductsDesc'),
                            value: 1300,
                            isParent: true,
                            isOpen: false,
                            parent: 1200,
                            child : [
                                {
                                    name: this.$t('Add_product'),
                                    desc: this.$t('ProductsDesctoAdd'),
                                    value: 1301,
                                    parent: 1300,
                                },
                                {
                                    name: this.$t('EditProduct'),
                                    desc: this.$t('ProductsDesctoEdit'),
                                    value: 1302,
                                    parent: 1300,
                                },
                                {
                                    name: this.$t('DeleteProduct'),
                                    desc: this.$t('ProductsDesctoDelete'),
                                    value: 1303,
                                    parent: 1300,
                                },
                                {
                                    name: this.$t('SupplierPriceDisplay'),
                                    desc: this.$t('SupplierPriceDisplayDesc'),
                                    value: 1304,
                                    parent: 1300,
                                }
                            ]
                        },
                        {
                            name: this.$t("ProductCategories"),
                            desc: this.$t('CategoriesDesc'),
                            value: 1400,
                            isParent: true,
                            isOpen: false,
                            parent: 1200,
                            child : [
                                {
                                    name: this.$t('addCategory'),
                                    desc: this.$t('CategoriesDesctoAdd'),
                                    value: 1401,
                                    parent: 1400,
                                },
                                {
                                    name: this.$t('EditCategory'),
                                    desc: this.$t('CategoriesDesctoEdit'),
                                    value: 1402,
                                    parent: 1400,
                                },
                                {
                                    name: this.$t('DeleteCategory'),
                                    desc: this.$t('CategoriesDesctoDelete'),
                                    value: 1403,
                                    parent: 1400,
                                }
                            ]
                        },
                        {
                            name: this.$t("providers"),
                            desc: this.$t('ProvidersDesc'),
                            value: 1500,
                            isParent: true,
                            isOpen: false,
                            parent: 1200,
                            child : [
                                {
                                    name: this.$t('AddProviders'),
                                    desc: this.$t('ProvidersDesctoAdd'),
                                    value: 1501,
                                    parent: 1500,
                                },
                                {
                                    name: this.$t('Editproviders'),
                                    desc: this.$t('ProvidersDesctoEdit'),
                                    value: 1502,
                                    parent: 1500,
                                },
                                {
                                    name: this.$t('Deleteproviders'),
                                    desc: this.$t('ProvidersDesctoDelete'),
                                    value: 1503,
                                    parent: 1500,
                                }
                            ]
                        },
                        {
                            name: this.$t("attributes"),
                            desc: this.$t('AttributesDesc'),
                            value: 1600,
                            isParent: true,
                            isOpen: false,
                            parent: 1200,
                            child : [
                                {
                                    name: this.$t('AddAttributes'),
                                    desc: this.$t('AttributesDesctoAdd'),
                                    value: 1601,
                                    parent: 1600,
                                },
                                {
                                    name: this.$t('EditAttributes'),
                                    desc: this.$t('AttributesDesctoEdit'),
                                    value: 1602,
                                    parent: 1600,
                                },
                                {
                                    name: this.$t('DeleteAttributes'),
                                    desc: this.$t('AttributesDesctoDelete'),
                                    value: 1603,
                                    parent: 1600,
                                }
                            ]
                        },
                        {
                            name: this.$t("invoices"),
                            desc: this.$t('InvoicesDesc'),
                            value: 1700,
                            isParent: true,
                            isOpen: false,
                            parent: 1200,
                            child : [
                                {
                                    name: this.$t('сreatingAccount'),
                                    desc: this.$t('InvoicesDesctoAdd'),
                                    value: 1701,
                                    parent: 1700,
                                },
                                {
                                    name: this.$t('InvoicesEdit'),
                                    desc: this.$t('InvoicesDesctoEdit'),
                                    value: 1702,
                                    parent: 1700,
                                },
                                {
                                    name: this.$t('InvoicesDelete'),
                                    desc: this.$t('InvoicesDesctoDelete'),
                                    value: 1703,
                                    parent: 1700,
                                },
                                {
                                    name: this.$t('InvoicesConfirm'),
                                    desc: this.$t('InvoicesDesctoConf'),
                                    value: 1704,
                                    parent: 1700,
                                }
                            ]
                        }
                    ]
                },
                {
                    name: this.$t('generalInfo'),
                    desc: this.$t('generalInfoDesc'),
                    isParent: true,
                    isOpen: false,
                    value: 800,
                    children: [
                        {
                            name: this.$t('Prices'),
                            desc: this.$t('pricesCalcDesc'),
                            value: 900,
                            parent: 800,
                        },
                        {
                            name: this.$t("Knowledge_base"),
                            desc: this.$t('Knowledge_basedesc'),
                            value: 801,
                            isParent: true,
                            isOpen: false,
                            parent: 800,
                            child : [
                                {
                                    name: this.$t('Add'),
                                    desc: this.$t('KnowledgeDesctoAdd'),
                                    value: 802,
                                    parent: 801,
                                },
                                {
                                    name: this.$t('toDeleteToEdit'),
                                    desc: this.$t('KnowledgeDesctoEdit'),
                                    value: 803,
                                    parent: 801,
                                }
                            ]
                        },
                        {
                            name: this.$t("qua_and_ans"),
                            desc: this.$t('Faqbasedesc'),
                            value: 1000,
                            isParent: true,
                            isOpen: false,
                            parent: 800,
                            child : [
                                {
                                    name: this.$t('addFaq'),
                                    desc: this.$t('FaqDesctoAdd'),
                                    value: 1001,
                                    parent: 1000,
                                },
                                {
                                    name: this.$t('EditFaq'),
                                    desc: this.$t('FaqDesctoEdit'),
                                    value: 1003,
                                    parent: 1000,
                                },
                                {
                                    name: this.$t('DeleteFaq'),
                                    desc: this.$t('FaqDesctoDelete'),
                                    value: 1002,
                                    parent: 1000,
                                },
                            ]
                        },
                        {
                            name: this.$t("answerLabels"),
                            desc: this.$t('answerLabelsDesc'),
                            value: 1100,
                            isParent: true,
                            isOpen: false,
                            parent: 800,
                            child : [
                                {
                                    name: this.$t('Add'),
                                    desc: this.$t('LabelDesctoAdd'),
                                    value: 1101,
                                    parent: 1100,
                                },
                                {
                                    name: this.$t('toEditAp'),
                                    desc: this.$t('LabelDesctoEdit'),
                                    value: 1102,
                                    parent: 1100,
                                },
                                {
                                    name: this.$t('toDelete'),
                                    desc: this.$t('LabelDesctoDelete'),
                                    value: 1103,
                                    parent: 1100,
                                },
                                {
                                    name: this.$t('editLabelOUser'),
                                    desc: this.$t('editLabelOUserdesc'),
                                    value: 1104,
                                    parent: 1100,
                                },
                            ]
                        }
                    ]
                },
                {
                    name: this.$t('Employees'),
                    desc: this.$t('userTeamDesk'),
                    isParent: true,
                    isOpen: false,
                    value: 610,
                    children: [
                        {
                            name: this.$t('teams'),
                            desc: this.$t('TeamDesc'),
                            value: 620,
                            isParent: true,
                            isOpen: false,
                            parent: 610,
                            child : [
                                {
                                    name: this.$t('addTeam'),
                                    desc: this.$t('TeamDesctoAdd'),
                                    value: 621,
                                    parent: 620,
                                },
                                {
                                    name: this.$t('EditTeam'),
                                    desc: this.$t('TeamDesctoEdit'),
                                    value: 622,
                                    parent: 620,
                                },
                                {
                                    name: this.$t('DeleteTeam'),
                                    desc: this.$t('TeamDesctoDelete'),
                                    value: 623,
                                    parent: 620,
                                },
                                {
                                    name: this.$t('AddWorkersTeam'),
                                    desc: this.$t('TeamDesctoAddWorkers'),
                                    value: 624,
                                    parent: 620,
                                },
                            ]
                        },
                        {
                            name: this.$t('Employees'),
                            desc: this.$t('WorkersDesc'),
                            value: 700,
                            isParent: true,
                            isOpen: false,
                            parent: 610,
                            child : [
                                {
                                    name: this.$t('addWorker'),
                                    desc: this.$t('WorkerDesctoAdd'),
                                    value: 701,
                                    parent: 700,
                                },
                                {
                                    name: this.$t('toEditPermsOther'),
                                    desc: this.$t('WorkerDesctoPermsOther'),
                                    value: 702,
                                    parent: 700,
                                },
                                {
                                    name: this.$t('EditWorker'),
                                    desc: this.$t('WorkerDesctoEdit'),
                                    value: 703,
                                    parent: 700,
                                },
                                {
                                    name: this.$t('DeleteWorker'),
                                    desc: this.$t('WorkerDesctoDelete'),
                                    value: 704,
                                    parent: 700,
                                },
                                {
                                    name: this.$t('AddWorkersWorker'),
                                    desc: this.$t('WorkerDesctoAddWorkers'),
                                    value: 705,
                                    parent: 700,
                                },
                                {
                                    name: this.$t('AddWorkersSkills'),
                                    desc: this.$t('WorkerDesctoAddSkills'),
                                    value: 706,
                                    parent: 700,
                                },
                                {
                                    name: this.$t('createFine'),
                                    desc: this.$t('WorkerDesctoCreateFine'),
                                    value: 707,
                                    parent: 700,
                                },
                                {
                                    name: this.$t('IP_access'),
                                    desc: this.$t('IP_access_desc'),
                                    value: 708,
                                    parent: 700,
                                },
                            ]
                        },
                    ],
                },
                {
                    name: this.$t('Message'),
                    desc: this.$t('MessageDescPerms'),
                    isParent: true,
                    isOpen: false,
                    value: 1900,
                    children: [
                        {
                            name: this.$t('AddMsgPerms'),
                            desc: this.$t('AddMsgDescPerms'),
                            value: 1901,
                            parent: 1900,
                        },
                        {
                            name: this.$t('EditMsgPerms'),
                            desc: this.$t('EditMsgDescPerms'),
                            value: 1902,
                            parent: 1900,
                        },
                        {
                            name: this.$t('DeleteMsgPerms'),
                            desc: this.$t('DeleteMsgDescPerms'),
                            value: 1903,
                            parent: 1900,
                        },
                        {
                            name: this.$t('ConfirmMsgPerms'),
                            desc: this.$t('ConfirmMsgDescPerms'),
                            value: 1904,
                            parent: 1900,
                        },
                        {
                            name: this.$t('SendMsgPerms'),
                            desc: this.$t('SendMsgDescPerms'),
                            value: 1905,
                            parent: 1900,
                        },
                    ]
                },
                {
                    name: this.$t('Accounts'),
                    desc: this.$t('allAccCompDesc'),
                    isParent: true,
                    isOpen: false,
                    value: 1800,
                    children: [
                        {
                            name: this.$t('AllAccounts'),
                            desc: this.$t('allAccDesc'),
                            value: 1810,
                            parent: 1800,
                        },
                        {
                            name: this.$t("ForRemoval"),
                            desc: this.$t('allAccsToDeleteDesc'),
                            value: 1820,
                            isParent: true,
                            isOpen: false,
                            parent: 1800,
                            child : [
                                {
                                    name: this.$t('DeleteAccount'),
                                    desc: this.$t('allAccToRemoveDesc'),
                                    value: 1821,
                                    parent: 1820,
                                },
                            ]
                        },
                        {
                            name: this.$t("AllCompany"),
                            desc: this.$t('allCompDesc'),
                            value: 1830,
                            parent: 1800,
                        },
                        {
                            name: this.$t("ForRemovalCompany"),
                            desc: this.$t('allCompToDeleteDesc'),
                            value: 1840,
                            isParent: true,
                            isOpen: false,
                            parent: 1800,
                            child : [
                                {
                                    name: this.$t('DeleteCompany'),
                                    desc: this.$t('allCompToRemoveDesc'),
                                    value: 1841,
                                    parent: 1840,
                                },
                            ]
                        },
                        {
                            name: this.$t("registration"),
                            desc: this.$t('allRegosterDesc'),
                            value: 1850,
                            parent: 1800,
                        },
                        {
                            name: this.$t("NoDoneRegistration"),
                            desc: this.$t('allNoDRegisterDesc'),
                            value: 1860,
                            parent: 1800,
                        }
                    ]
                },
                {
                    name: this.$t('monitoringLabel'),
                    desc: this.$t('monitoringDesc'),
                    isParent: true,
                    isOpen: false,
                    value: 2030,
                    children: [
                        {
                            name: this.$t("monitoringAdminLabel"),
                            desc: this.$t('monitoringAdminDesc'),
                            value: 2031,
                            parent: 2030,
                        },
                    ]
                }
            ],
            ip: [],
            IP_new: [],
        } 
    },
    created(){
        this.form = this.obj
        this.getAccess();
    },
    methods:{
        IP_settings() {
            // Функція для редагування налаштувань IP-адреси користувача

            // Створення об'єкту з новим IP-адресою
            let obj = {
                ip: this.IP_new
            }

            // Перевірка, чи ввімкнена можливість редагування IP
            if(this.writeIP == true) {

                // Відправлення запиту на збереження нового IP-адреси
                apiServe.saveWorkerIp(this.id, obj).then(result=> {
                    if(result.status === 'done') {
                        this.$toast.success(this.$t('IP_success'))

                        // Вимкнення режиму редагування IP, очищення поля та оновлення доступів
                        this.writeIP = false;
                        this.IP_new = ''
                        this.getAccess()
                    } else {
                        this.$toast.error(this.$t('err'))
                    }
                })
            } else {
                // Активація режиму редагування IP та встановлення поточного IP
                this.IP_new = this.ip.ip;
                this.writeIP = true;
            }
        },
        isShowItem(option) {
            // Перевірка умов для відображення елементу списку налаштувань
            return this.perms[option.value];
        },
        select(checked, item){
            // Обробка зміни головного чекбоксу та його дочірніх налаштувань

            if(!checked){
                for(let key in item.children){
                    this.form.perms[item.children[key].value] = null;
                    // document.getElementById(`second_level_input_${item.children[key].value}`).checked = null
                    item.children[key] = Object.assign({}, item.children[key])
                    if(item.children[key].child){
                        for(let value in item.children[key].child){
                            this.form.perms[item.children[key].child[value].value] = false;
                        }
                    }
                }
            } 
            let parsed = JSON.stringify(this.form.perms);
            localStorage.setItem('tempPerms', parsed);
        },
        firstChild(checked, itemchild){
            // Обробка зміни статусу дочірнього чекбоксу та його батьківського елементу

            if(!checked){
                for(let key in itemchild.child){
                    this.form.perms[itemchild.child[key].value] = false;
                    itemchild.child[key] = Object.assign({}, itemchild.child[key])
                }
            } else {
                this.form.perms[itemchild.parent] = true
            }
            let parsed = JSON.stringify(this.form.perms);
            localStorage.setItem('tempPerms', parsed);
        },
        secChild(checked, itemchild, parent){
            // Обробки зміни статусу чекбоксу та його батьківських елементів

            this.form.perms[parent.value] = true
            this.form.perms[itemchild.parent] = true
            let parsed = JSON.stringify(this.form.perms);
            localStorage.setItem('tempPerms', parsed);
        },
        getAccess(){
            // Функція для отримання доступів користувача

            // Відправлення запиту на отримання IP-адреси користувача
            apiServe.getWorkerIp(this.id).then(result => {
                if(result.status == 'done'){
                    // Присвоєння отриманого IP-адреси змінній
                    this.ip = result.data;
                } else {
                    this.$toast.error(this.$t('err'));
                }
            })
        },
        checkAllchild(item){
            // Вибір всіх дочірніх налаштувань для головного налаштування

            for(let key in item.children){
                item.isOpen = true;
                if(item.isOpen){
                    for(let key in item.children){
                        this.form.perms[item.value] = true
                        this.form.perms[item.children[key].value] = true
                        if(item.children[key].child){
                            for(let option in item.children[key].child){
                                this.form.perms[item.children[key].child[option].value] = true
                            }
                        }
                    }
                }
            }
            let parsed = JSON.stringify(this.form.perms);
            localStorage.setItem('tempPerms', parsed);
        },
        changeType(e){
            // Функція для зміни типу доступу

            // Вимкнення всіх наявних прав користувача
            for(let key in this.form.perms) {
                this.form.perms[key] = false
            }

            // Увімкнення обраних прав доступу з події
            for(let item in e.opt){
                this.form.perms[e.opt[item]] = true
            }
        },
        editUser(){
            // Функція для відправлення події редагування користувача
            this.$emit('editUser', this.form)
        },
        editUserPerms(){
            // Функція для відправлення події редагування прав доступу користувача

            // Перевірка, чи користувач має режим бога (2000)
            // Відправлення події редагування прав доступу користувача
            var checkSuper = this.form.perms[2000] == true ? true : false
            this.$emit('editUserPerms', this.id, this.form, checkSuper)
        }
    },
    computed: {
        filteredItems() {
            // Функція для фільтрації елементів за текстовим запитом

            // Перетворення текстового запиту у нижній регістр
            const SearchLower = this.search.toLowerCase()

            // Фільтрація елементів на основі текстового запиту
            return this.option.filter(item => item.name.toLowerCase().includes(SearchLower) ||
            item.children.filter(itemchildren => (
                // Автоматичне відкриття елементу, якщо його дочірні елементи відображаються
                item.isOpen = true,

                // Перевірка відповідності дочірніх елементів текстовому запиту
                itemchildren.name.toLowerCase().includes(SearchLower)
            )).length)
        },
        perms(){
            /*
                Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
            */
            return storeS.perms
        }
    }
}
</script>

<style scoped>
.block-as {
    margin: 20px auto;
}
.check-as {
    margin: 5px;
    margin-right: 20px;
}
.check-as .form-check-input {
    width: 1.6em;
    height: 1.6em;
}
.block-sub {
    margin-left: 30px ;
}
</style>