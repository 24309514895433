<template>
    <!-- <chat :msg="msg" @sendMsg="sendMsg" @sendReplyMsg="sendReplyMsg"/> -->
    <b-row>
        <b-col lg="9" md="12" xs="12">
            <clients :obj="form" @openclient="openclient" :showComp="true"/>
        </b-col>
    </b-row>
</template>

<script>
import clients from '../../../formright/clientnumber.vue'
export default{
    props: ['obj'],
    components: {
        clients
    },
    data(){
        return{
            form: {}
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.obj
        },
        openclient(e){
            this.$emit('openclient', e)
        }
    }
}
</script>