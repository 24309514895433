<template>
    <modal :key="componentKey" :title="$t('client') + ' ' + Number(form.user_phone)" @close="close" :stylebody="`background: var(--vz-body-bg)`">
        <template v-slot:status>
            <!-- <button @click="this.$emit('openClient')"> zzzzzzzzzzzz</button> -->
            <span :class="`badge ${mutateStatus(form.users[0].user_clientstatus).color} align-middle fs-12`">{{ mutateStatus(form.users[0].user_clientstatus).name }}</span>
            <span v-if="this.formRequest" :class="`badge ${ mutatePriority(formRequest.priority).color}  align-middle fs-12`" style="margin-left: 5px;">{{ mutatePriority(formRequest.priority).name }}</span>
        </template>
        <template v-slot:head-bottom>
            <headermodal 
                :obj="this.form" 
                @close="close" 
                @updatedata="updatedata"
                @opencalculator="opencalculator"
                @openstatistics="openstatistics" 
                @openshop="openshop"
                @openknowledge="openknowledge" 
                @openfaq="openfaq" 
                @opensmsbox="opensmsbox" 
                @openmailbox="openmailbox"
                @openapplicbox="openapplicbox" 
                @opentaskBox="opentaskBox" 
                @openticketBox="openticketBox" 
                @openservicebox="openservicebox" 
                @openinvoicesbox="openinvoicesbox" 
                @openblockComment="openblockComment" 
                @createdconnectbox="createdconnectbox" 
            />
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="2">
                    <requestcol 
                        v-if="this.formRequest"
                        :obj="this.formRequest"
                    />
                    <maincol  
                        v-else
                        :obj="this.form"
                        @opensmsbox="opensmsbox" 
                        @openmailbox="openmailbox"
                        @call = "call"
                    />
                </b-col>
                <b-col lg="8" style="background: var(--vz-body-bg);">
                <!-- <b-col lg="8" > -->
                    <activitycol  
                        :obj="this.form"
                        @openTicket="openTicket"
                        @openservicebox="openservicebox" 
                        @openinvoicesbox="openinvoicesbox" 
                        @createdconnectbox="createdconnectbox"
                    />
                </b-col>
                <b-col lg="2">
                    <othercol  
                        @opensmsbox="opensmsbox" 
                        @openmailbox="openmailbox"
                        @call = "call"
                        :obj="this.form"
                        :formRequest="this.formRequest"
                    />
                </b-col>
            </b-row>
            
        </template>
    </modal>

    <!-- статистика -->
    <template v-if="statisticsShow">
        <modallg @close="statisticsShow = false" :title="$t('statistics')">
            <template v-slot:head-bottom>
                <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="statisticsShow = false"><i class="ri-close-fill align-bottom"></i></button>
            </template>
            <template v-slot:body>
                <statistics :json="this.form"></statistics>
            </template>
        </modallg>
    </template>
    <!-- статистика -->

    <!-- база знаний -->
    <template v-if="knowledgeboxShow">
        <modallg @close="knowledgeboxShow = false">
            <template v-slot:head-bottom>
              <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="knowledgeboxShow = false"><i class="ri-close-fill align-bottom"></i></button>
            </template>
            <template v-slot:body>
                <knowledgeBox :phone="form.user_login"></knowledgeBox>
            </template>
        </modallg>
    </template>
    <!-- база знаний -->

    <!-- faq -->
    <template v-if="faqboxShow">
        <modallg @close="faqboxShow = false">
            <template v-slot:head-bottom>
              <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="faqboxShow = false"><i class="ri-close-fill align-bottom"></i></button>
            </template>
            <template v-slot:body>
                <faqBox></faqBox>
            </template>
        </modallg>
    </template>
    <!-- faq -->

    <!-- смс бокс -->
    <createsmsBox v-if="modalSmsbox" @close="modalSmsbox = false" :phone="Number(form.user_phone)" @sendSMS="sendSMS" @updatedata="updatedata"></createsmsBox>
    <!-- смс бокс -->

    <!-- mail бокс -->
    <createmailBox v-if="modalMailbox" :users="this.form" @close="modalMailbox = false"></createmailBox>
    <!-- mail бокс -->

    <!-- создание заявки -->
    <createapplicBox v-if="modalShow" @close="modalShow = false" :phone="Number(form.user_phone)"></createapplicBox>
    <!-- создание заявки -->

    <!-- создание задачи -->
    <createTaskBox v-if="modalTaskBox" @close="modalTaskBox = false" :phone="Number(form.user_phone)"></createTaskBox>
    <!-- создание задачи -->

    <!-- создание тикета -->
    <createTicketBox v-if="modalTicketBox" @close="modalTicketBox = false" :phone="Number(form.user_phone)"></createTicketBox>
    <!-- создание тикета -->

    <!-- создание счета -->
    <invoices v-if="modalInvoicesBox" @close="modalInvoicesBox = false" :phone="form.user_login" :license="form.license"></invoices>
    <!-- создание счета -->

    <!-- калькулятор -->
    <calculator v-if="modalcalculator" @close="modalcalculator = false" :country="form.user_country" :demo_license="demo_license" ></calculator>
    <!-- калькулятор -->

    <acceptSMS v-if="showAcceptSMS == true" @close = "showAcceptSMS = false" :obj="this.object"></acceptSMS>

    <!-- интернет-магазин -->
    <template v-if="modalshop">
      <!-- <modal>
        <template v-slot:body>
          <shop @close="modalshop = false"></shop>
        </template>
      </modal> -->
      <modallg @close="modalshop = false">
        <template v-slot:head-bottom>
          <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="knowledgeboxShow = false"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <shop></shop>
        </template>
      </modallg>
    </template>
    <!-- интернет-магазин -->

    <!-- создание услуги -->
    <service v-if="serviceShow" @close="serviceShow = false" :phone="form.user_login"></service>
    <!-- создание услуги -->

    <!-- создание настройки подключения -->
    <!-- <settins_box v-if="connectsShow" @close="connectsShow = false" :phone="form.user_login" :sklad="form.license.sklad"></settins_box> -->
    <!-- создание настройки подключения -->

</template>

<script>
import acceptSMSVue from '@/views/application/acceptSMS.vue';
import modal from '@/components/modal'
import modallg from '@/components/modal/modal-lg'
import headermodal from './header'
import maincol from './blocks/main/index'
import activitycol from './blocks/activity/index'
import othercol from './blocks/other/index'
import requestcol from './blocks/request/index'
import statistics from "./client_stat/index"
import knowledgeBox from '@/components/knowledge-base/index'
import faqBox from '@/components/faq/index'
import createapplicBox from '@/views/application/createapplic.vue'
import createsmsBox from '@/views/application/createsms.vue'
import createmailBox from '@/views/application/createmail.vue'
import createTaskBox from '@/views/tasks/createtask.vue'
import createTicketBox from '@/views/newtickets/createticket.vue'
import service from "@/views/services/createservice.vue"
// import settins_box from "./formright/connects/create"
import invoices from "@/views/invoices/createinvoices.vue"
import calculator from "@/components/calc_tariff.vue"
import shop from "@/components/shop/index.vue"
import { Clients } from "@/API.js";
import { storeS } from '@/store.js'
import { mutateStatusAccounts, mutatePriority } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Clients();

export default({
    props: ['obj', 'form_applic', 'signal'],
    components: {
        acceptSMSVue,
        createmailBox,
        modal,
        headermodal,
        maincol,
        activitycol,
        othercol,
        requestcol,
        modallg,
        statistics,
        knowledgeBox,
        faqBox,
        createsmsBox,
        createapplicBox,
        createTaskBox,
        createTicketBox,
        service,
        calculator,
        invoices,
        shop,
        // settins_box
    },
    data() {
        return {
            object: '',
            showAcceptSMS: false,
            componentKey:'',
            form: '',
            currentTab: '',
            formRequest: '',
            modalMailbox: false,
            statisticsShow: false,
            knowledgeboxShow: false,
            faqboxShow: false,
            modalSmsbox: false,
            modalShow: false,
            modalTaskBox: false,
            modalTicketBox: false,
            modalInvoicesBox: false,
            modalcalculator: false,
            modalshop: false,
            serviceShow: false,
            connectsShow: false,
            demo_license: {
                demo_count_tt: "0",
                demo_store_count_active: "0",
                demo_products_count: "0",
                demo_cookcard_count: "0"
            }
        }
    },
    created() {
        this.getdata()
    },
    methods: {
        sendSMS(e){
            
            this.obj = e 
            this.showAcceptSMS = true
        },
        call(e) {
            ;
            // this.$emit('call', e, this.form)
            localStorage.setItem('actualCall', e)
            this.eventBus.emit('actualCall', {
                status: true
            })

        },
        getdata(){
            this.formRequest = this.form_applic;
            this.form = this.obj;
        },
        close(){
            this.$emit('close')
        },
        updatedata: function() {
            apiServe.getClient(this.form.user_login).then(res => {
                if(res.status == 'done'){
                    this.form = res.data
                    this.componentKey += 1
                    this.$toast.success(`Update`);
                }
            })
        },
        openstatistics: function(){
            apiServe.getPayClient(this.form.user_login).then(res => {
                if(res){
                    this.form.pay = res.data
                    this.statisticsShow = true
                }
            })
        },
        openshop: function(){
            this.modalshop = true
        },
        openTicket: function(e) {
            this.$emit('openTicket', e)
        },
        openknopenTicketowledge: function(){
            this.knowledgeboxShow = true
        },
        openfaq: function(){
            this.faqboxShow = true
        },
        openmailbox: function() {
           this.modalMailbox = true
        },
        opensmsbox: function(){
            this.modalSmsbox = true
        },
        openapplicbox: function(){
            this.modalShow = true
        },
        opentaskBox: function(){
            this.modalTaskBox = true
        },
        openticketBox: function(){
            this.modalTicketBox = true
        },
        openservicebox: function(){
            this.serviceShow = true
        },
        createdconnectbox: function(){
            this.connectsShow = true
        },
        opencalculator: function(){
            this.demo_license.demo_store_count_active = this.form.license.store_count_active;
            this.demo_license.demo_count_tt = this.form.license.sklad.length;
            this.demo_license.demo_products_count = this.form.license.sklad[0].products_count;
            this.demo_license.demo_cookcard_count = this.form.license.sklad[0].cookcard_count;
            for(var i=0; i < this.form.license.sklad.length; i++){
                if(Number(this.demo_license.demo_products_count) < Number(this.form.license.sklad[i].products_count)){
                    this.demo_license.demo_products_count = this.form.license.sklad[i].products_count
                }

                if(Number(this.demo_license.demo_cookcard_count) < Number(this.form.license.sklad[i].cookcard_count)){
                    this.demo_license.demo_cookcard_count = this.form.license.sklad[i].cookcard_count
                }
            }
            this.modalcalculator = true
        },
        openblockComment: function(){
            this.blockComment = !this.blockComment;
        },
        openinvoicesbox: function(){
            this.modalInvoicesBox = true;
        },
        mutateStatus(e){
            return mutateStatusAccounts(e)
        },
        mutatePriority(e){
            return mutatePriority(e)
        }
    },
    computed: {
        user(){
            return storeS.userbase
        }
    }
})
</script>
