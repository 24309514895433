<template>
    <!-- вкладка настройки подключения -->

    <!-- компонент таблицы -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="true" 
        @create="showModalAdd = true"
        @getdata="getdata"
        @changelimit="changelimit"
        @open="open"
    />

    <!-- создание настройки -->
    <created 
        v-if="showModalAdd == true" 
        @close="showModalAdd = false" 
        :userPhone="this.number"
        :sklad="this.tt"
    />

    <!-- просмотр карточки подключения -->
    <viewcard 
        v-if="showModalCart == true" 
        @close="showModalCart = false" 
        :objCard="objCard"
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        :tt="tt"
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen"
        @getdata="getdata"
    />

</template>

<script>
import infoblocks from '@/components/info/blocks'
import tablecustom from '@/components/globaltable/index';
import created from './create'
import viewcard from './view'
import { Connects } from '@/API.js';
import { storeS } from '@/store.js'
import { mutateConnectStatus } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Connects();

export default {
    data() {
        return {
            showModalAdd: false,
            infoblockShow: false,
            showModalCart: false,
            ifNextopen: "",
            ifPrevopen: "",
            objcard: '',
            objPages: "",
            dataParams: {
                status: false,
                page: "connects"
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                phone: ''
            },
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "connectionSettingsId",
                    align: "left",
                    status: false,
                },
                {
                    name: this.$t('outlet'),
                    text: "namett",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateTT(item)
                },
                {
                    name: this.$t('app'),
                    text: "program",
                    align: "left",
                    status: true,
                },
                {
                    name: "ID Connect",
                    text: "connectionId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('Status'),
                    text: "status",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutatestatus(item).name,
                    mutateClass: (item) => this.mutatestatus(item).color
                },
                {
                    name: this.$t('Comment'),
                    text: 'comment',
                    align: "left",
                    status: true,
                }
            ],
            outlets: []
        }
    },
    props: ['number', 'sklad', 'tt'],
    components: {
        created,
        tablecustom,
        infoblocks,
        viewcard
    },
    created() {
        this.outlets = this.tt;
        this.getdata();
    },
    methods: {
        getdata(page){
            this.objParams.phone = Number(this.number);
            this.objParams.page = page != undefined ? page : '1';
            apiServe.getConnects(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                    this.objPages = result.data;

                    for(var item in this.rows){
                        for(var value in this.outlets){
                            if(this.rows[item].tradepointId === this.outlets[value].tradepointId){
                                // this.form[item].statustt = this.$t('active');
                                this.rows[item].namett = this.outlets[value].title;
                                break;
                            } else {
                                this.rows[item].namett = 'remove';
                            }
                        }
                    }

                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        open(e){
            ;
            var id = e.connectionSettingsId ? e.connectionSettingsId : e;
            this.ifPrevopen = id == this.rows[0].connectionSettingsId ? false : true;
            this.ifNextopen = id == this.rows[this.rows.length-1].connectionSettingsId ? false : true;
            apiServe.getSettings(id).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data;
                    this.showModalCart = true;
                }
            })
        },
        prevOpen(e){
            var current_id = e;
            var prev_id = ""; 

            for(var value in this.rows){
                if(this.rows[value].connectionSettingsId == current_id){
                    prev_id = this.rows[Number(value)-Number('1')].connectionSettingsId;
                }
            }
            this.open(prev_id);
        },
        nextOpen(e){
            var current_id = e;
            var next_id = "";

            for(var value in this.rows){
                if(this.rows[value].connectionSettingsId == current_id){
                    next_id = this.rows[Number(value)+Number('1')].connectionSettingsId;
                }
            }
            this.open(next_id);
        },
        searchB(e){
            this.objParams.search = e
            this.getdata()
        },
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        mutatestatus(e){
            return mutateConnectStatus(e)
        },
        mutateTT(e){
            var r = "";
            switch(e){
                case "remove":
                    r = this.$t('Removed')
                break;
                default:
                    r = e;
                break;
            }
            return r;
        }
    },
    computed: {
        
    },
    mounted() {
        this.eventBus.on('saveSettingConnect', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    }
}
</script>