<template>
    
    <globalTable 
        :columns="columns" 
        :rows="filteredItems" 
        @search="searchB" 
        :createdform="true"
        @create="showdevice = true"
        @open="open"
    />
    
    <adddevice 
        v-if="showdevice == true" 
        @close="closeCreate" 
        @addDeivce="addDeivce"
        @editDeivce="editDeivce"
        @removeDeivce="removeDeivce"
        :numbers="obj.userPhones"
        :typeAdd="'appendType'"
        :itemdevice="itemdevice"
        :perm="this.permToEdit"
        :editform="false"
    />

    <!-- <device 
        v-if="modaldevice" 
        @close="closeCreate"
        @addDeivce="addDeivce"
        @editDeivce="editDeivce"
        @removeDeivce="removeDeivce"
        :itemdevice="itemdevice"
        :editform="false"
        :perm="this.permToEdit" 
        :obj="this.form"
    /> -->

</template>

<script>
import globalTable from '@/components/globaltable/index';
import adddevice from '@/components/ticketdeviceblock/index.vue'
import device from '@/components/ticketdeviceblock/index.vue'
import { Tickets } from '@/API'

let apiServe = new Tickets();

export default{
    props: ['obj'],
    components: {
        globalTable,
        adddevice,
        device
    },
    data(){
        return{
            showdevice: false,
            modaldevice: false,
            search: '',
            form: {},
            rows: [],
            permToEdit: false,
            itemdevice: '',
            columns: [
                {
                    name: "ID",
                    text: "id",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t("Point_name"),
                    text: "device",
                    align: "left",
                    status: true,
                    mutate: (item) => item.name ? item.name : '-'
                },
                {
                    name: this.$t("type"),
                    text: "device",
                    align: "left",
                    status: true,
                    mutate: (item) => item.type ? item.type : '-'
                },
                {
                    name: this.$t("manufacturer"),
                    text: "device",
                    align: "left",
                    status: false,
                    mutate: (item) => item.manufacturer ? item.manufacturer : '-'
                },
                {
                    name: this.$t("bank_terminal_model"),
                    text: "device",
                    align: "left",
                    status: true,
                    mutate: (item) => item.model ? item.model : '-'
                },
                {
                    name: this.$t("OS"),
                    text: "device",
                    align: "left",
                    status: true,
                    mutate: (item) => item.os ? item.os : '-'
                },
                {
                    name: this.$t("Protocol"),
                    text: "device",
                    align: "left",
                    status: true,
                    mutate: (item) => item.protocol ? item.protocol : '-'
                }
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.rows = this.obj.devices
        },
        searchB(e){
            this.search = e
        },
        addDeivce(e){
            apiServe.addDevice(this.obj.ticketId, e).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(`done`);
                    this.rows = result.data;
                    this.$emit('updateCount', this.rows.length)
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            })
        },
        closeCreate(){
            this.permToEdit = false;
            this.showdevice = false;
        },
        editDeivce(e){
            apiServe.editDevice(this.obj.ticketId, e.deviceTicketId, e).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(`done`);
                    this.rows = result.data;
                    this.$emit('updateCount', this.rows.length)
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            })
        },
        removeDeivce(e){
            apiServe.deleteDevice(e).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(`done`);
                    this.rows = result.data;
                    this.$emit('updateCount', this.rows.length)
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            })
        },
        open(e){
            this.itemdevice = e
            this.permToEdit = true
            this.showdevice = true
        }
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.rows)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.id.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ;
                })
        },
    }
}
</script>