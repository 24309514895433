<template>
    <dialogModal>
        <!-- Модалка додавання нотатки -->
        <template v-slot:title>
            {{ this.$t('Add') }}
        </template>
        <template v-slot:body>  
            <div class="form-group">
                <!-- Вказати номер телефону -->
                <label for="exampleInputEmail1">{{ this.$t('phone_num') }}</label>
                <input type="tel" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" :placeholder="this.$t('enterPhoneNumber')" v-model="this.form.number">
            </div>
            <div class="form-group" style="margin-top: 20px;">
                <!-- Поле для нотатки -->
                <label for="exampleInputPassword1">{{ this.$t('note') }}</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="this.form.comment"></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <!-- Збереження нотатки -->
            <button class="btn btn-success" @click="addNote(this.form)">{{this.$t('save')}}</button>
        </template>
    </dialogModal>
</template>

<script>
import dialogModal from "@/components/modal/dialog.vue"
import dbPromise from '../../indexedDB'; // indexedDB для збереження нотаток

export default {
    data() {
        return {
            form: {
                comment: '',
                number: ''
            }
        }
    },
    components: {
        dialogModal
    },
    methods: {
        // Додавання нотатки по номеру
        addNote(form) {
            let detail = {
                dateTime: new Date(),
                text: sessionStorage.getItem('perm') ? sessionStorage.getItem('perm') : '',
                category: 'ATSNote',
                form: JSON.stringify(form)
            }; 

            dbPromise.add(detail) //додавання в indexedDB

            this.eventBus.emit('getDataNotes', {status: true})
            this.$emit('close')
        },
    },
}
</script>