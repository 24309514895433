<template>
    
    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        :objParams="objParams"
        :datafilter="filterAll"
        @change="change" 
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index';

export default{
    props: ['obj', 'objTerminals', 'objTradepoints', 'objWorkers'],
    components: {
        tablecustom,
    },
    data(){
        return{
            form: '',
            objCard: '',
            showModal: false,
            objParams: {
                search: ''
            },
            columns: [
                {
                    name: "ID",
                    text: "shiftId",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("Employee"),
                    text: "nameWorker",
                    align: "left",
                    status: true,
                    copy: true,
                    // mutate: (item) => item ? this.getWorker(item) : '-'
                },
                {
                    name: this.$t("outlet"),
                    text: "titleTT",
                    align: "right",
                    status: true,
                    copy: true,
                    // mutate: (item) => item ? this.getTitleTT(item) : '-'
                },
                {
                    name: this.$t("terminal"),
                    text: "titleTerminal",
                    align: "right",
                    status: true,
                    copy: true,
                    // mutate: (item) => item ? this.objTerminals[item].terminalName : '-'
                },
                {
                    name: this.$t("ShiftisOpen"),
                    text: "openDatetime",
                    align: "right",
                    status: true,
                    copy: true,
                },
            ],
            selectWorker: '0',
            selectTT: '0',
            selectTerminal: '0',
            filterAll: [
                {
                    page: "openShifts",
                    name: "Employee",
                    category: "0",
                    items: []
                },
                {
                    page: "openShifts",
                    name: "outlet",
                    category: "0",
                    items: []
                },
                {
                    page: "openShifts",
                    name: "terminal",
                    category: "0",
                    items: []
                },
            ],
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj

            for(var item in this.form){
                this.form[item].nameWorker = this.getWorker(this.form[item].workerId)
                this.form[item].titleTT = this.getTitleTT(this.form[item].tradepointId)
                this.form[item].titleTerminal = this.getTitleTerminal(this.form[item].terminalId)
            }

            let newArrWorker = Array.from(new Set(this.form.map(item => JSON.stringify(item.nameWorker)))).map(item => JSON.parse(item));
            this.filterAll[0]['items'] = newArrWorker;
            let newArrTT = Array.from(new Set(this.form.map(item => JSON.stringify(item.titleTT)))).map(item => JSON.parse(item));
            this.filterAll[1]['items'] = newArrTT;
            let newArrTerminal = Array.from(new Set(this.form.map(item => JSON.stringify(item.titleTerminal)))).map(item => JSON.parse(item));
            this.filterAll[2]['items'] = newArrTerminal;
        },
        getWorker(item){
            var name = "";
            this.objWorkers.forEach((el) => {
                if(el.workerId === item) {
                    name = el.workerName
                }
            })
            return name
        },
        getTitleTT(item){
            var name = "";
            this.objTradepoints.forEach((el) => {
                if(el.tradepointId === item) {
                    name = el.tradepointName
                }
            })
            return name
        },
        getTitleTerminal(item){
            var name = "";
            this.objTerminals.forEach((el) => {
                if(el.terminalId === item) {
                    name = el.terminalName
                }
            })
            return name
        },
        searchB(e){
            this.objParams.search = e
        },
        change(name, e){
            if(name == 'Employee'){
                this.selectWorker = e;
            } else if(name == 'outlet') {
                this.selectTT = e
            } else if(name == 'terminal') {
                this.selectTerminal = e
            }
        },
    },
    computed: {
        filteredItems: function() {
            return this.form
                // по пошуку
                .filter(item => {
                    return this.objParams.search == '' || item.titleTerminal.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1 || item.titleTT.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1;
                })

                // по співробітнику
                .filter(item => {
                    return this.selectWorker == '0' || item.nameWorker === this.selectWorker ;
                })

                // по точці 
                .filter(item => {
                    return this.selectTT == '0' || item.titleTT === this.selectTT ;
                })

                // по терміналу 
                .filter(item => {
                    return this.selectTerminal == '0' || item.titleTerminal === this.selectTerminal ;
                })
        },
    }
}
</script>