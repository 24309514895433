<template>
    <!-- модальное окно создания быстрого ответа -->
    <modal>
        <template v-slot:title>{{ $t('addQuickReplay') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <div class="form-check" style="margin-bottom: 10px;">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" @change="this.showStart == false ? this.showStart = true : this.showStart = false">
                <label class="form-check-label" for="flexCheckDefault">
                    Сховати назву та опис
                </label>
            </div>
            <b-row v-if="showStart == true" style="margin-bottom: 10px;">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Вкажіть назву скрипту" aria-label="Username" v-model="this.form.name">
                </div>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Вкажіть опис скрипту" aria-label="Username" v-model="this.form.description">
                </div>
            </b-row>

            <!-- <b-row v-if="this.lastWindow == false">
                <div class="input-group mb-3">
                    <div style="width: 100%">
                        <h3>Створити початок розмови</h3>
                    </div>
                    <input type="text" class="form-control" placeholder="Вкажіть стартове питання" aria-label="Username" v-model="this.title">
                    <button type="button" class="btn btn-outline-success" @click="addMain" >Створити заголовок</button>
                </div>
            </b-row>

            <b-row style="margin-bottom: 10px;" v-if="this.lastWindow == false">
                <div>
                    <h3>Новий елемент</h3>
                    <div class="input-group mb-3">
                        <Multiselect
                            v-model="this.connectFrom"
                            mode="tags"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="true"
                            :options="this.ways"
                            :placeholder="'Від якого можна перейти'"
                            style="margin-bottom: 10px;"
                        />

                        <Multiselect
                            v-model="this.connectTo"
                            mode="tags"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="true"
                            :options="this.ways"
                            :placeholder="'До якого можна перейти'"
                            style="margin-bottom: 10px;"
                        />

                        <Multiselect
                            v-model="this.currentBlock"
                            :close-on-select="true"
                            :searchable="true"
                            :options="this.blocks"
                            :placeholder="'Характер створенного вікна'"
                            style="margin-bottom: 10px;"
                        />

                        <input type="text" v-model="universalComment" class="form-control" id="exampleFormControlInput1" placeholder="Фраза менеджера, котра спільна для кількох блоків">
                        

                    </div>
                    <div class="mb-3">
                        <textarea class="form-control" rows="3" v-model="option" placeholder="Текст"></textarea>
                    </div>
                    <div style="display: grid; grid-template-columns: repeat(3, 1fr);">
                        <button type="button" class="btn btn-outline-success" @v-if="this.connectFrom.length > 0 || this.connectTo.length > 0" @click="addOption" style="grid-column-start: 2; grid-column-end: 3;">Створити опцію</button>
                    </div>
                </div>
                <div class="form-check" v-if="this.form.nodes.length > 0 && this.form.nodes.length > 1">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" @change="this.showEnd == false ? this.showEnd = true : this.showEnd = false">
                    <label class="form-check-label" for="flexCheckDefault">
                        Показати вікно завершального вікна
                    </label>
                </div>

            </b-row>

            <b-row v-if="this.showEndCreate == true">  
                <div class="input-group mb-3">
                    <div style="width: 100%">
                        <h3>Створити кінець розмови</h3>
                    </div>
                    <input type="text" class="form-control" placeholder="Вкажіть стартове питання" aria-label="Username" v-model="this.epilog">
                    <button type="button" class="btn btn-outline-success" @click="addEndShow()" >Створити кінець</button>
                </div>
            </b-row>

            <b-row>
                <ul class="list-group" style="height: 200px; overflow: auto;" v-if="this.ways.length > 0">
                    <li class="list-group-item" v-for="items in this.ways" :style="(items.value == 1 ? 'background-color: green' : '')">{{ items.id == 1 ? items.label.split(' ').splice(0, items.label.split(' ').length-1).join(' ') : items.label}}</li>
                </ul>
            </b-row> -->

            <div class="row" >
                <button type="button" class="btn btn-success" @click="this.fullScreen = !this.fullScreen"><i class='bx ' :class="this.fullScreen == true ? 'bx-exit-fullscreen' : 'bx-fullscreen'"></i></button>
            </div> 
        </template>  

        <!-- <template v-slot:footer-bottom>
            <b-button variant="success" @click="add" class="waves-effect waves-light"><i class="ri-edit-bin-line align-bottom me-1"></i>{{ $t('Add')}}</b-button>
        </template> -->
    </modal>

    <modal v-if="this.fullScreen == true" >
        <template v-slot:title>{{ $t('addQuickReplay') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="this.fullScreen = false"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body> 
            <div style="width: 100%; height: 50vh;"> 
                <playground :blocks="this.blocks"></playground>
            </div>
        </template>
    </modal>

</template>

<script>
import playground from './scriptElements/playground.vue';
import dialogBox from '@/components/modal/dialog'
import modal from '@/components/modal-small'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store.js'

export default ({
    data(){
        return {
            elements: [
                {id: 1, type: 'input', label: 'Node1', position: {x: 250, y:5}}        
            ],
            showStart: true,
            fullScreen: false,
            lastWindow: false,
            epilog: '',
            currentBlock: '',
            showEnd: false,
            option: '',
            connectFrom: [],
            connectTo: [],
            ways: [],
            item: '',
            dontSave: false,
            universalComment: '',
            form: {
                nodes: [],
                edges: [],
                name: '',
                description: '',
            },
            title: '',
            blocks: [
                {
                    label: 'Закінчення розмови з умовно негативним наслідком (клієнту ця функція недоступна)',
                    value: {color: '#ff0000', border: '#990000'}
                },
                {
                    label: 'Потребує уточнення від клієнту у третього обличчя',
                    value: {color: '#ff6600', border: '#993d00'},
                },
                {
                    label: 'Клієнту щось треба додатково',
                    value: {color: '#ffff00', border: '#808000'},
                },
                {
                    label: 'Відповіді',
                    value: {color: '#9900cc', border: '#600080'},
                },
                {
                    label: 'Потребує уточнення (для прикладу фотографія)',
                    value: {color: '#0000cc', border: '#000080'},
                },
                {
                    label: 'Фраза мендежера (робити пунктиром чи суцільно)',
                    value: {color: '#66ffff', border: '#008080'},
                },
            ],
            showEnd: false,
            smaller: 0
        }
    },
    props: [],
    components: { 
        modal,
        Multiselect,
        dialogBox,
        playground,
    },
    created(){
    
    //     //перевірка на наявність не закінченних заявок
    
    //     let openRequest = indexedDB.open("store", 1);
    //         openRequest.onsuccess = function() {
    //             let db = openRequest.result;
    //             let transaction = db.transaction("data", "readwrite"); // (1)
                
    //             // получить хранилище объектов для работы с ним
    //             let data = transaction.objectStore("data"); // (2)
    //             let categoryIndex = data.index("сategory_idx");

    //             let request = categoryIndex.getAll('shortcut')

    //             request.onsuccess = function() {
    //                 if (request.result !== undefined && request.result.length > 0) {
    //                     eventB.emit('haveForms', {status: true, forms: request.result})
    //                 } else {
    //                     console.log('Незакінченні форми не знайдені. Дякую за збереження екології, бо ви не зайняли зайвого місця на серверах Зімбабве =)')
    //                 }
    //             };

    //             request.onerror = function() {
    //                 alert('Щось пішло не так, перезапустіть будь ласка сторінку')
    //             }
    //         }
    //     //перевірка на наявність не закінченних заявок

    //     //вибір дії було зроблено, обробка
        
    //     this.eventBus.off('openOld')
    //     this.eventBus.on('openOld', status => {
    //         if(status.status == true) {
    //             let openRequest = indexedDB.open("store", 1);
    //                 openRequest.onsuccess = function() {
    //                     let db = openRequest.result;
    //                     let transaction = db.transaction("data", "readwrite"); // (1)
                        
    //                     // получить хранилище объектов для работы с ним
    //                     let data = transaction.objectStore("data"); // (2)
    //                     let categoryIndex = data.index("сategory_idx");

    //                     let request = categoryIndex.getAll(status.form.category)

    //                     request.onsuccess = function() {
    //                         if (request.result !== undefined) {
    //                             request.result.forEach(el=> {
    //                                 if(el.id == status.form.id) {
    //                                     let deleteReq = data.delete(el.id)
    //                                         deleteReq.onsuccess = function() {
    //                                             console.log('All okey') //видалення, щоб не було дублікатів
    //                                         }
    //                                 }
    //                             })
    //                         }
    //                     };

    //                     request.onerror = function() {
    //                         alert('Щось пішло не так, перезапустіть будь ласка сторінку')
    //                     }
    //                 }
    //             this.form = JSON.parse(status.form.form)
    //             if(status.form.text !== '') {
    //                 this.perm = status.form.text
    //             }
    //         }
    //     })
        
    //     this.eventBus.off('createNew')
    //     this.eventBus.on('createNew', status => {
    //         if(status.status == true) {
    //             return
    //         }
    //     })
        
    //     //вибір дії було зроблено, обробка
    // },
    // beforeUnmount() {
    //     if(this.dontSave == false) {
    //         sessionStorage.setItem('actual', JSON.stringify(this.form))
    //         if(this.form.userPhone !== '' || this.form.comment !== '' || this.form.delayedDatetime !== '') {
    //             let openRequest = indexedDB.open("store", 1);
    //                 openRequest.onsuccess = function() {
    //                     let db = openRequest.result;
    //                     let transaction = db.transaction("data", "readwrite"); // (1)
                    
    //                     // получить хранилище объектов для работы с ним
    //                     let data = transaction.objectStore("data"); // (2)
    //                     let category_text = 'shortcut';
    //                     let detail = {
    //                         dateTime: new Date(),
    //                         text: '',
    //                         category: category_text,
    //                         form: sessionStorage.getItem('actual')
    //                     };

    //                     let request = data.add(detail); // (3)

    //                     request.onsuccess = function() { // (4)
    //                         sessionStorage.removeItem('actual')
    //                         console.log("Данные добавлены в хранилище", request.result);
    //                     };

    //                     request.onerror = function() {
    //                         console.log("Ошибка", request.error);
    
    //                     };
    //                 } //додавання в indexedDB
    //         }   
    //     }
    },
    methods: {
    },
    mounted(){
    },
    computed: {
    }
})
</script>
