<template>
    <!-- 
        Модальне вікно створення заявки на вихідний/відпустку
    -->

    <!-- Модальне вікно для введення інформації про відпустку -->
    <dialogBox @close="this.$emit('close')">
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:body>
            <div class="row">
                <!-- Вибір типу відпустки (день відпочинку або відпустка) -->
                <div class="col-12">
                    <div class="mb-3">
                        <label class="form-label">{{ $t('type') }}</label>
                        <select class="form-control" v-model="form.type">
                            <option v-for="item in listVac" :key="item" :value="item.value">{{ item.title }}</option>
                        </select>
                    </div>
                </div>

                <!-- Вибір причини відпустки (з'являється тільки при обраній денній відпустці) -->
                <div class="col-12" v-if="form.type == 'dayoff'">
                    <div class="mb-3">
                        <label class="form-label">{{ $t('Cause') }}</label>
                        <select class="form-control" v-model="form.reason">
                            <option v-for="item in listReason" :key="item" :value="item.value">{{ item.title }}</option>
                        </select>
                    </div>
                </div>

                <!-- Виведення повідомлення при виборі причини "ownReason" -->
                <b-alert v-if="form.reason == '3'" show variant="warning" class="text-center">
                    <span class="fs-12 fw-semibold">{{ $t('enterReasonDesc') }}</span>
                </b-alert>

                <!-- Вибір дати відпустки за допомогою Flatpickr -->
                <div class="col-12">
                    <div class="mb-3" v-if="this.form.type != 'dayoffWithTime'">
                        <label>{{ $t('EventDate') }}</label>
                        <div class="input-group">
                            <flatpickr v-model="date" :config="config" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
                        </div>
                    </div>
                    <div class="gap-20 row" v-else style="margin-bottom: 10px;">
                        <label>{{ $t('from') }}</label>
                        <div class="input-group" style="margin-bottom: 10px;">
                            <flatpickr v-model="form.date" :config="configWithTime" class="form-control border-0 dash-filter-picker shadow"></flatpickr>
                        </div>
                        <label>{{ $t('to') }}</label>
                        <div class="input-group">
                            <flatpickr v-model="form.toDate" :config="configWithTime" class="form-control border-0 dash-filter-picker shadow"></flatpickr>
                        </div>
                    </div>
                </div>

                <!-- Приховане поле для збереження ідентифікатора події (поки не використовується) -->
                <input type="hidden" id="eventid" name="eventid" value="" />

                <!-- Введення коментаря/опису відпустки -->
                <div class="col-12">
                    <div class="mb-3">
                        <label class="form-label">{{ $t('description') }}</label>
                        <textarea class="form-control d-block" id="event-description" v-model="form.comment" :placeholder="$t('description')" rows="3" spellcheck="false"></textarea>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <!-- Кнопка для збереження відпустки -->
            <button 
                type="button" 
                class="btn btn-success" 
                @click="create"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модального вікна
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { Holidays } from '@/API' // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Holidays();

export default{
    props: ['obj'],
    components: {
        dialogBox,
        flatpickr: Vueflatpickr
    },
    data(){
        return{
            form: { // Дані для форми
                type: "", // тип вихідного
                date: "", //2023-06-01, від якої дати
                toDate: "", //2023-06-07, до якої дати
                reason: "", // причина
                comment: "" // коментар
            },
            listVac: [ // Перелік можливих відгулів
                { title: this.$t('DayOffWithTime'), value: 'dayoffWithTime' }, // вихідний по часу
                { title: this.$t('DayOff'), value: 'dayoff' }, // вихідний
                { title: this.$t('Vacation'), value: 'vacation' } // відпустка
            ],
            listReason: [ // Перелік можливих причин за замовчуванням
                { title: this.$t('fellIll'), value: '0' }, // Захворів/а
                { title: this.$t('IllnessFamilyMember'), value: '1' }, // Хвороба дитини чи іншого члена сім'ї
                { title: this.$t('BodyCheck'), value: '2' }, // Медогляд
                { title: this.$t('ownReason'), value: '3' } // своя причина
            ],
            date: '',
            config: { // параметри налаштування Flatpickr
                mode: "range", // параметр, який дозволяє вибрати період
                minDate: "today", // параметр, від якої дати можна обрати період
                altInput: true,
                altFormat: "F j, Y",
                enableTime: false, // вимкнення вказування часу
                dateFormat: "Y-m-d", // формат дати
                locale: "" // мова 
            },
            configWithTime: { // параметри налаштування Flatpickr
                mode: "single", // параметр, який дозволяє вибрати період
                minDate: "today", // параметр, від якої дати можна обрати період
                altInput: true,
                enableTime: false, // вимкнення вказування часу
                dateFormat: "Y-m-d H:i", // формат дати
                locale: "", // мова 
                enableTime: true,
                time_24hr: true
            },
        }
    },
    created(){

        // Налаштування мови Flatpickr в залежності від мови користувача
        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }
        
    },
    methods: {
        create(){
            // Збереження запиту на вихідний/відпустку

            if (!this.form.type) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterType'));
            }
            if (!this.form.date) {
                //перевіряємо чи вказана дата
                this.$toast.error(this.$t('specifyDate'));
            }
            if (!this.form.comment) {
                //перевіряємо чи вказаний опис
                this.$toast.error(this.$t('EnterDesc'));
            }

            console.log(this.form)

            if(this.form.type && this.form.date && this.form.comment){  
                apiServe.addRequest(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('LeaveRequestSent'));
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var errorText = 'Помилка створення заявки на вихідний'; 
                        var statusRequest = result.status;
                        var api = 'addRequest';
                        var fileRequest = 'src/views/users/view/formright/vacation/create.vue';
                        var params = this.form;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    }
                })
            }
        },
        change(selectedDates, dateStr, instance){
            // Оновлення дати при закритті вибору дати Flatpickr

            var arrDate = String(dateStr)
            // arrDate = arrDate.split(' to ')
            if(arrDate.indexOf('to') == -1){
                arrDate = arrDate.split(' — ')
            } else {
                arrDate = arrDate.split(' to ')
            }

            this.form.date = arrDate[0];
            this.form.toDate = arrDate[1];

            console.log(this.form, ...arguments)
        },
        basicDate() {
            // Задання базової дати (сьогоднішній день)

            let today = new Date()
            this.form.date = String(today).split(' ')[3]+ "-" +this.getMonth(String(today).split(' ')[1])+ "-" +String(today).split(' ')[2]
            this.form.toDate = String(today).split(' ')[3]+ "-" +this.getMonth(String(today).split(' ')[1])+ "-" +Number(String(today).split(' ')[2])+1
        },
        getMonth(e) {
            // Отримання номеру місяця з англійської абревіатури

            var res
            switch(e) {
                case "Jan":
                    res = '01'
                break;
                case "Feb":
                    res = '02'
                break;
                case "Mar":
                    res = '03'
                break;
                case "Apr":
                    res = '04'
                break;
                case "May":
                    res = '05'
                break;
                case "Jun":
                    res = '06'
                break;
                case "Jul":
                    res = '07'
                break;
                case "Aug":
                    res = '08'
                break;
                case "Sep":
                    res = '09'
                break;
                case "Oct":
                    res = '10'
                break;
                case "Nov":
                    res = '11'
                break;
                case "Dec":
                    res = '12'
                break;
            }
            return res
        },
    }
}
</script>
