<template>
     <!-- 
        Модальне вікно з відображення картки ярлика відповіді
    -->

    <modal @close="this.$emit('close')">
        <template v-slot:title>{{ $t('addQuickReplay') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <!-- Поле для назви ярлика -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Label') }}</h4>
                        <input type="text" class="form-control" v-model="form.shortcutName" :disabled="this.statusEdit == false">
                    </div>
                </b-col>

                <!-- Поле для групи ярлика -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Group') }}</h4>
                        <div class="row">
                            <div class="col" >
                                <select
                                    class="form-select mb-3"
                                    v-model="form.groupId"
                                    :disabled="this.statusEdit == false"
                                >
                                    
                                    <!-- Виведення опцій вибору групи -->
                                    <option 
                                        v-for="item in listGroup" 
                                        :key="item" 
                                        :value="item.groupId"
                                    >
                                        {{ item.groupName }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-auto" >

                                <!-- Кнопка для створення нової групи -->
                                <button 
                                    v-if="group == null || group == ''" 
                                    class="btn btn-soft-success small_btn" 
                                    style="margin-right: 5px;" 
                                    @click="showCreatedGroup = true"
                                >
                                    <i class="bx bxs-plus-circle" ></i>
                                </button>

                                <!-- Кнопка для редагування наявної групи -->
                                <button 
                                    v-if="ifGroup(listGroup,group)" 
                                    class="btn btn-soft-info small_btn" 
                                    style="margin-right: 5px;" 
                                    @click="editGroup(group)"
                                >
                                    <i class="ri-edit-2-fill" ></i>
                                </button>
                                
                                <!-- Кнопка для видалення наявної групи -->
                                <button 
                                    v-if="ifGroup(listGroup,group)" 
                                    class="btn btn-soft-danger small_btn" 
                                    @click="removeGroup(group)"
                                >
                                    <i class="ri-delete-bin-2-line" ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <!-- Поле для введення тексту повідомлення -->
            <div class="mb-3">
                <label class="form-label">{{ $t('Message') }}</label>
                <textarea class="form-control" rows="3" v-model="form.message" :disabled="this.statusEdit == false" ></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>

            <!-- 
                Кнопка "Видалити" 
                - надає змогу видалити ярлик
                - відкривається модальне вікно, для підтвердження видалення ярлику
            -->
            <b-button 
                variant="danger" 
                @click="dialogShow = true" 
                class="waves-effect waves-light"
            >
                <i class="ri-edit-bin-line align-bottom me-1"></i>{{ $t('Delete')}}
            </b-button>
                
            <!-- 
                Кнопка "Зберегти" або "Редагувати" 
                - надає змогу зберегти зміни ярлику або редагувати
                - відкривається модальне вікно, для підтвердження видалення ярлику
            -->
            <b-button 
                :variant="statusEdit === false ? 'info' : 'success'" 
                @click="statusEdit === false ? this.statusEdit = true : dialogEditShow = true" 
                class="waves-effect waves-light"
            >
                <i class="ri-edit-bin-line align-bottom me-1"></i>{{ statusEdit === false ? $t('Edit') : $t('save') }}
            </b-button>

        </template>
    </modal>

    <!-- Модальне вікно для створення нової групи -->
    <createGroup 
        v-if="showCreatedGroup == true" 
        @close="showCreatedGroup = false" 
        :objGroup="group"
    />

    <!-- Модальне вікно для підтвердження видалення ярлика -->
    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="removeLabel(form)"
    />

    <!-- Модальне вікно для редагування ярлика -->
    <modaledit 
        v-if="dialogEditShow"
        @close="dialogEditShow = false" 
        @toEdit="editLabel(form)" 
    />

</template>

<script>
import modal from '@/components/modal-small' // компонент модального вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import createGroup from './group/create' // картка створення групи ярлика
import modalremove from '@/components/modal/modalremove.vue' // компонент підтвердження видалення
import modaledit from '@/components/modal/dialog/dialogEdit.vue' // компонент редагування ярлика
import { Shortcuts } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних

let apiServe = new Shortcuts();

export default ({
    data(){
        return {
            form: { // Дані для форми
                shortcutName: "",
                groupId: "",
                message: ""
            },
            statusEdit: false,  // прапорець для редагування
            listGroup: [], // список груп
            showCreatedGroup: false, // прапорець відображення вікна створення групи
            dialogShow: false, // прапорець відображення вікна видалення ярлика
            dialogEditShow: false // прапорець відображення вікна редагування ярлику
        }
    },
    props: ['objcard'],
    components: { modal, Multiselect, createGroup, modalremove, modaledit },
    created(){
        this.form = this.objcard
        this.getGroup();
    },
    methods: {
        editLabel(e){
            /**
                * Редагування ярлика відповіді з урахуванням валідації полів.
                * @param {Object} e - Об'єкт ярлика відповіді для редагування.
            */

            if (!e.shortcutName) {
                this.$toast.error(this.$t('enterNameLabel'));
            }
            if (!e.message) {
                this.$toast.error(this.$t('enterMsg'));
            }
            if (!e.groupId) {
                this.$toast.error(this.$t('EnterGroup'));
            }
            if(e.shortcutName && e.message && e.groupId){
                apiServe.editShortcut(e.id, e).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('alertChanged'));
                        this.$emit('close')
                        this.eventBus.emit('saveShortcuts', true)
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);
                    }
                })
            }
        },
        getGroup(){
            /**
                * Отримання списку груп ярликів відповідей.
            */
            apiServe.getGroupsLabels().then(result => {
                if(result.status == 'done'){
                    this.listGroup = result.data
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            })
        },
        removeLabel(e){
            /**
                * Видалення ярлика відповіді.
                * @param {Object} e - Об'єкт ярлика відповіді для видалення.
            */

            apiServe.deleteShortcut(e.id).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Removed'));
                    this.$emit('close')
                    this.eventBus.emit('saveShortcuts', true)
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            })
        },
        ifGroup(arr, val) {
            /**
                * Перевірка наявності групи у списку груп.
                * @param {Array} arr - Масив груп.
                * @param {Object} val - Об'єкт групи для перевірки.
                * @returns {boolean} - Повертає `true`, якщо група існує, або `false` в іншому випадку.
            */
            if(val == null){
                return false
            } else {
                return arr.some(arrVal => val.value === arrVal.value);
            }
        },
        removeGroup(e){
            /**
                * Видалення групи
                * @param {Object} e - Об'єкт групи для видалення.
            */

            apiServe.deleteGroup(e.value).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Removed'))
                    const index = this.listGroup.findIndex(n => n.value === Number(result.data));
                    if (index !== -1) {
                        this.listGroup.splice(index, 1);
                    }
                    this.group = ""
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            })
        },
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
})
</script>
