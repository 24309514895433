<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('CompanyName') }}</h4>
                        <input type="text" class="form-control" id="contactNumber" v-model="form.name">
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('E-mail') }}</h4>
                        <input type="text" class="form-control" id="contactNumber" v-model="form.email">
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('phone_number') }}</h4>
                        <input type="num" class="form-control" id="contactNumber" v-model="form.phone">
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('site') }}</h4>
                        <input type="text" class="form-control" id="contactNumber" v-model="form.website">
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Telegram') }}</h4>
                        <input type="text" class="form-control" id="contactNumber" v-model="form.telegram">
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('BusinessEntity') }}</h4>
                        <select class="form-control" v-model="this.form.businessEntity">
                            <!-- <option value="">{{ this.$t('Null') }}</option> -->
                            <option value="FOP">{{ this.$t('FOp') }}</option>
                            <option value="TOV">{{ this.$t('TOv') }}</option>
                        </select>
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Provider') }}</h4>
                        <select class="form-control" v-model="this.form.providerType">
                            <!-- <option value="">{{ this.$t('Null') }}</option> -->
                            <option value="external">{{ this.$t('external') }}</option>
                            <option value="internal">{{ this.$t('internal') }}</option>
                        </select>
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('FormationAccountsWith') }} ({{ $t("VAT") }})</h4>
                        <select class="form-control" v-model="this.form.workWithPDV">
                            <!-- <option value="">{{ this.$t('Null') }}</option> -->
                            <option :value="true">{{ this.$t('WithVAT') }}</option>
                            <option :value="false">{{ this.$t('WithoutVAT') }}</option>
                        </select>
                    </div>
                </b-col>
            </b-row>
            <hr />
            <b-row class="mt-3">
                <p class="text-success mb-3 fs-18 fw-semibold">{{ $t('SpecifyFOPData') }}/ТОВ</p>
                <b-col lg="6" class="mb-3">
                    <p class="text-muted mb-2 fs-14 fw-semibold">{{ $t('Provider') }}</p>
                    <input class="form-control" v-model="form.providerTitle" />
                </b-col>
                <b-col lg="6" class="mb-3">
                    <p class="text-muted mb-2 fs-14 fw-semibold">{{ $t('checkingAccount') }} (IBAN)</p>
                    <input class="form-control" v-model="form.iban" />
                </b-col>
                <b-col lg="6" class="mb-3">
                    <p class="text-muted mb-2 fs-14 fw-semibold">{{ $t('title_bank') }}</p>
                    <input class="form-control" v-model="form.bank" />
                </b-col>
                <b-col lg="6" class="mb-3">
                    <p class="text-muted mb-2 fs-14 fw-semibold">{{ $t('address') }}</p>
                    <input class="form-control" v-model="form.address" />
                </b-col>
                <b-col lg="6" class="mb-3">
                    <p class="text-muted mb-2 fs-14 fw-semibold">МФО</p>
                    <input class="form-control" v-model="form.mfo" />
                </b-col>
                <b-col lg="6" class="mb-3">
                    <p class="text-muted mb-2 fs-14 fw-semibold">ЄРДПОУ</p>
                    <input class="form-control" v-model="form.erdpu" />
                </b-col>
                <b-col lg="6" class="mb-3">
                    <p class="text-muted mb-2 fs-14 fw-semibold">ІПН</p>
                    <input class="form-control" v-model="form.ipn" />
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <div>
                <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
                <button 
                    v-if="(this.perms[1501] == true || this.perms[2000] == true) && this.perm == false"
                    type="button" 
                    class="btn btn-primary" 
                    v-on:click="add"
                >
                    {{ $t('Add') }}
                </button>
                <button 
                    v-if="(this.perms[1502] == true || this.perms[2000] == true) && this.perm == true"
                    type="button" 
                    class="btn btn-primary" 
                    v-on:click="edit"
                >
                    {{ $t('Edit') }}
                </button>
            </div>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/modal-lg'
import { ProvidersProducts } from '@/API.js';
import { storeS } from '@/store.js'

let apiServe = new ProvidersProducts();

export default {
components: { dialogBox },
data() {
    return {
        // modalDialog: false,
        form:{
            name: "",
            phone: "",
            email: "",
            website: "",
            telegram: "",
            providerType: "external",
            providerTitle: "",
            bank: "",
            erdpu: "",
            iban: "", 
            address: "", 
            ipn: "",
            mfo: "",
            businessEntity: "fop",
            workWithPDV: false
        },
    };
},
props: ['obj', 'perm'],
created(){
    if(this.perm === true) { //проверка на редактированние
        this.form = this.obj;
    }
},
methods: {
    add() {
        if (!this.form.name) {
            //перевіряємо чи вказана назва
            this.$toast.error(this.$t('EnterNameTicket'));
            return
        }
        if (!this.form.phone) {
            //перевіряємо чи вказаний номер постач
            this.$toast.error(this.$t('EnterNumber'));
            return
        }
        if (!this.form.email) {
            //перевіряємо чи вказана пошта
            this.$toast.error(this.$t('EnterMail'));
            return
        }
        if (!this.form.website) {
            //перевіряємо чи вказаний сайт
            this.$toast.error(this.$t('EnterWebSite'));
            return
        }
        if (!this.form.telegram) {
            //перевіряємо чи вказаний телеграм
            this.$toast.error(this.$t('EnterTGChat'));
            return
        }
        if (!this.form.providerType || this.form.providerType == '') {
            //перевіряємо чи вказаний тип постачальника
            this.$toast.error(this.$t('providerTypeNull'));
            return
        }

        if(this.form.name && this.form.phone && this.form.email && this.form.website && this.form.telegram){
            apiServe.addProvider(this.form).then(res => {
                if(res.status == 'done'){
                    this.$toast.success(this.$t('сreatedT'));
                    this.$emit('close')
                    this.eventBus.emit('saveProvider', true)
                }
            })
        }
    },
    edit() {
        let id = this.form.providerId
        delete this.form.providerId
        apiServe.editProvider(id, this.form).then(result=>{
            if(result.status === 'done'){
                this.$toast.success(this.$t('alertChanged'))
                this.$emit('close')
            } else {
                this.$toast.error(this.$t('error'))
                this.$emit('close')
            } //конечное редактированние
        })
    },
    currentDate() {
        var date = new Date();
        var dateStr =
        ("00" + date.getMonth()).slice(-2) + "." +
        ("00" + (date.getDate() + 1)).slice(-2) + "." +
        date.getFullYear() + " " +
        ("00" + date.getHours()).slice(-2) + ":" +
        ("00" + date.getMinutes()).slice(-2) + ":" +
        ("00" + date.getSeconds()).slice(-2);
        return dateStr
    }
},
computed: {
    user() {
        return storeS.userbase
    },
    perms() {
        return storeS.perms
    }
}
}
</script>