<template>
    <!-- 
        Модальне вікно створення поста Питання та відповіді (FAQ)
    -->

    <dialogBox >
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:body>
            <b-card>

                <!-- Вибір мови -->
                <h5 class="kb-title">
                    <b>{{ $t('chooseLang') }}</b>
                </h5>
                <select class="form-select mb-3" v-model="form.lang">
                    <option value="0">{{ $t('chooseLangList') }}</option>
                    <option v-for="item in lang" :key="item" :value="item.value">{{item.title}}</option>
                </select>

                <!-- Вибір категорії для поста FAQ -->
                <h5 class="kb-title">
                    <b>{{ $t('chooseCategory') }}</b>
                </h5>
                <Multiselect 
                    v-model="this.form.categoryId"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.category"
                />
                
            </b-card>
            
            <!-- Поле з вказуванням запитання -->
            <b-card>
                <h4 class="fs-15">{{ $t('specifyQuestion') }}</h4>
                <input type="text" class="form-control" id="contactNumber" v-model="form.question">
            </b-card>
            
            <!-- Поле з вказуванням відповіді -->
            <b-card>
                <h4 class="fs-15">{{ $t('enterAnswer') }}</h4>
                <textqe @input="inputText" :objText="form.answer" />
            </b-card>

        </template>
        <template v-slot:footer-bottom>
            <div>
                <!-- Кнопка для закриття модального вікна -->
                <button class="btn link-success fw-medium" @click="$emit('close')">
                    <i class="ri-close-line me-1 align-middle"></i> 
                    {{ $t('close') }} 
                </button>

                <!-- Кнопка для додавання нового питання та відповіді -->
                <button 
                    v-if="Boolean(this.edit_perm) == false"
                    type="button" 
                    class="btn btn-primary" 
                    v-on:click="add"
                >
                    {{ $t('Add') }}
                </button>

                <!-- Кнопка для редагування питання та відповіді -->
                <button 
                    v-else
                    type="button" 
                    class="btn btn-primary"  
                    v-on:click="edit"
                >
                    {{ $t('Edit') }}
                </button>
                
            </div>
        </template>
    </dialogBox>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import dialogBox from '@/components/modal/modal-lg' // компонент модального вікна
import textqe from '@/components/textQE' // компонент текстового редактора
import { FAQ } from '@/API.js'; // клас для взаємодії з API 
import { eventB } from '@/main' // глобальна шина слухання подій
import { storeS } from '@/store.js' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new FAQ();

export default {
    components: { dialogBox, textqe, Multiselect },
    data() {
        return {
            dontSave: false, // прапорець, що вказує, чи потрібно зберігати дані перед закриттям компонента
            form:{ // Дані для форми
                categoryId: '', // id категорії
                question: "", // питання
                answer: "", // відповідь
                lang: "" // мова
            },
            category:[],
            lang:[ // Масив з доступними мовами
                {
                    title:"Українська",
                    value: "uk"
                },
                {
                    title:"Poland",
                    value: "pl"
                },
                {
                    title:"Російська",
                    value: "ru"
                }
            ]
        };
    },
    props:['obj', 'edit_perm', 'objCategory', 'categories'],
    created(){
        // Ініціалізація даних форми при створенні компонента
        if(this.edit_perm == true) {
            this.form = this.obj
        }
        if(this.objCategory) {
            this.form.categoryId = this.objCategory.categoryId
            this.form.lang = this.objCategory.lang
        }
        this.category = []
        if(this.categories) {
            this.categories.forEach(el => {
                this.category.push({
                    label: el.categoryName,
                    value: el.categoryId
                })
            })
        } else {
            storeS.FAQCateg.forEach(el => {
                this.category.push({
                    label: el.categoryName,
                    value: el.categoryId
                })
            })
        }
        
        //перевірка на наявність не закінченних заявок
        let openRequest = indexedDB.open("store", 1);
            openRequest.onsuccess = function() {
                let db = openRequest.result;
                let transaction = db.transaction("data", "readwrite"); // (1)
                
                // получить хранилище объектов для работы с ним
                let data = transaction.objectStore("data"); // (2)
                let categoryIndex = data.index("сategory_idx");

                let request = categoryIndex.getAll('FAQPost')

                request.onsuccess = function() {
                    if (request.result !== undefined && request.result.length > 0) {
                        eventB.emit('haveForms', {status: true, forms: request.result})
                    } else {
                        console.log('Незакінченні форми не знайдені. Дякую за збереження екології, бо ви не зайняли зайвого місця на серверах Зімбабве =)')
                    }
                };

                request.onerror = function() {
                    alert('Щось пішло не так, перезапустіть будь ласка сторінку')
                }
            }
        //перевірка на наявність не закінченних заявок

        //вибір дії було зроблено, обробка
        
        this.eventBus.off('openOld')
        this.eventBus.on('openOld', status => {
            if(status.status == true) {
                let openRequest = indexedDB.open("store", 1);
                    openRequest.onsuccess = function() {
                        let db = openRequest.result;
                        let transaction = db.transaction("data", "readwrite"); // (1)
                        
                        // получить хранилище объектов для работы с ним
                        let data = transaction.objectStore("data"); // (2)
                        let categoryIndex = data.index("сategory_idx");

                        let request = categoryIndex.getAll(status.form.category)

                        request.onsuccess = function() {
                            if (request.result !== undefined) {
                                request.result.forEach(el=> {
                                    if(el.id == status.form.id) {
                                        let deleteReq = data.delete(el.id)
                                            deleteReq.onsuccess = function() {
                                                console.log('All okey') //видалення, щоб не було дублікатів
                                            }
                                    }
                                })   
                            }
                        };

                        request.onerror = function() {
                            alert('Щось пішло не так, перезапустіть будь ласка сторінку')
                        }
                    }
                this.form = JSON.parse(status.form.form)
            }
        })
        
        this.eventBus.off('createNew')
        this.eventBus.on('createNew', status => {
            if(status.status == true) {
                return
            }
        })
        
        //вибір дії було зроблено, обробка
    },
    beforeUnmount() {
        if(this.dontSave == false) {
            sessionStorage.setItem('actual', JSON.stringify(this.form))
            if(this.form.categoryId !== '' || this.form.question !== '' || this.form.answer !== '' || this.form.lang !== '') {
                let openRequest = indexedDB.open("store", 1);
                    openRequest.onsuccess = function() {
                        let db = openRequest.result;
                        let transaction = db.transaction("data", "readwrite"); // (1)
                    
                        // получить хранилище объектов для работы с ним
                        let data = transaction.objectStore("data"); // (2)
                        let category_text = 'FAQPost';
                        let detail = {
                            dateTime: new Date(),
                            text: '',
                            category: category_text,
                            form: sessionStorage.getItem('actual')
                        };

                        let request = data.add(detail); // (3)

                        request.onsuccess = function() { // (4)
                            sessionStorage.removeItem('actual')
                            console.log("Данные добавлены в хранилище", request.result);
                        };

                        request.onerror = function() {
                            console.log("Ошибка", request.error);
                        };
                    } //додавання в indexedDB
            }
        }
    },
    methods: {
        inputText(text) {
            this.form.answer = text.target ? text.target.innerHTML : text;
        },
        edit() {
            /**
                * Редагування поста FAQ
            */

            let id = this.form.postId;
            delete this.form.postId
            apiServe.editLesson(id, this.form).then(result=> {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('alertChanged'));
                    this.$emit('getData')
                    this.dontSave = true
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "id": id,
                        "params": this.form
                    }

                    var errorText = 'Помилка редагування поста'; 
                    var statusRequest = result.status;
                    var api = 'editLesson';
                    var fileRequest = 'src/components/faq/addFAQ.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        add() {
            /**
                * Додавання нового поста FAQ з урахуванням валідації полів.
            */

            if (!this.form.categoryId) {
                //перевіряємо чи вказана категорія
                this.$toast.error(this.$t('EnterCategory'));
            }
            if (!this.form.question) {
                //перевіряємо чи вказане питання
                this.$toast.error(this.$t('EnterQuestion'));
            }
            if (!this.form.answer) {
                //перевіряємо чи вказана відповідь
                this.$toast.error(this.$t('EnterAnswer'));
            }
            if (!this.form.lang) {
                //перевіряємо чи вказана мова
                this.$toast.error(this.$t('EnterLang'));
            }
            if(this.form.categoryId && this.form.question && this.form.answer && this.form.lang){
                apiServe.addFaqCateg(this.form).then(result => {
                    if(result.status === 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('getData')
                        this.dontSave = true
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);
                        this.$emit('close')

                        var errorText = 'Помилка створення поста'; 
                        var statusRequest = result.status;
                        var api = 'addFaqCateg';
                        var fileRequest = 'src/components/faq/addFAQ.vue';
                        var params = this.form;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
}
</script>