<template>
    <dialogBox>
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:body>
            <h5 class="kb-title">
                <b>{{ $t('chooseTask') }}</b>
            </h5>
            <select class="form-select mb-3" v-model="editForm.taskId">
                <option value="0">{{ $t('chooseLangList') }}</option>
                <option v-for="item in form.tasks" :key="item" :value="item.taskId">{{item.taskName}}</option>
            </select>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('Edit') }}</h4>
                <textarea class="form-control" rows="3" v-model="editForm.comment" :placeholder="$t('Comment')"></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
            <button 
                type="button" 
                class="btn btn-primary" 
                v-on:click="add"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { Projects } from '@/API.js';
import { storeS } from '@/store.js'

let apiServe = new Projects();

export default {
    props: ['id', 'obj'],
    components: { dialogBox },
    data() {
        return {
            form: "",
            editForm:{
                taskId: "",
                comment: "",
                status: 0
            }
        }
    },
    created(){
        this.getdata();
    },
    methods:{
        getdata(){
            this.form = this.obj
        },
        add() {
            apiServe.addProjectEdit(this.id, this.editForm).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(`done`);
                    this.eventBus.emit('UpdateCard', true)
                    this.$emit('close')
                } else if(result.status == 'error'){
                    this.$toast.error(result.error[0]);
                }
            })
        },
    }
}
</script>