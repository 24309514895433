<template>
    <b-row>
        <b-col lg="12">
            <div class="card mt-n4 mx-n4 mb-n5">
                <div :class="
                    this.form.status == 'new' ? 'bg-soft-info' : 
                    this.form.status == 'statusInWork' ? 'bg-soft-secondary' : 
                    this.form.status == 'statusCompleted' ? 'bg-soft-success' : 
                    this.form.status == 'stasusDonetoConf' ? 'bg-soft-success' : 
                    this.form.status == 'changesMade' ? 'bg-soft-warning' : 
                    'bg-soft-info'
                    ">
                    <div class="card-body pb-4 mb-5">
                        <div class="row">
                            <div class="col-md">
                                <div class="row align-items-center">
                                    <div class="col-md-auto">
                                        <div class="avatar-md mb-md-0 mb-4">
                                            <div class="avatar-title bg-white rounded-circle">
                                                <span style="color: black;font-size: 36px;font-weight: 500;">#</span>
                                                <!-- <img src="@/assets/images/companies/img-4.png" alt="" class="avatar-sm" /> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <h4 class="fw-semibold">{{form.projectId}} - {{form.projectName}}</h4>
                                        <div class="hstack gap-3 flex-wrap">
                                            <!-- <div class="text-muted">{{ $t('createDate') }} : <span class="fw-medium">{{form.create_date_time}}</span></div> -->
                                        </div>
                                        <!-- <div class="hstack gap-3 flex-wrap" v-if="form.deadline != ''">
                                            <div class="text-muted">{{ $t('Deadline') }} : <span class="fw-medium">{{form.deadline['deadline']}}</span></div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default{
    props: ['obj'],
    data(){
        return{
            form:''
        }
    }, 
    created(){
        this.getdata();
    },
    methods:{
        getdata(){
            this.form = this.obj
        }
    }
}
</script>