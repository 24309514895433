<template>
    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="checks['001']">
        <div class="hstack gap-2">
            <button @click="infoblockShow == true ? infoblockShow = false : infoblock()" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
            <!-- <button @click="chartblockShow == true ? closechartblock() : chartblock()" type="button" :class="`btn btn-${chartblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${chartblockShow == true ? 'bx bxs-hide' : 'ri-pie-chart-fill'}`"></i></button> -->
        </div>
    </div>

    <!-- инфо-блоки -->
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <!-- компонент диагр -->
    <!-- <circular v-if="chartblockShow" :title="$t('admin_settingstradepoints')" :obj="this.chartall" style="margin-top:15px;" /> -->

    <!-- компонент таблицы -->
    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        @change="change" 
        @clearFilter="clearFilter"
        @open="open"
    />

    <!-- карточка точки -->
    <!-- <views v-if="showModal == true" @close="showModal = false" :shortapplic="cardApplic" :number="this.number" /> -->
</template>

<script>
import tablecustom from '@/components/globaltable/index';
import infoblocks from '@/components/info/blocks'
import circular from '@/components/statistics/circular'
import { mutateTariffingOutlets, mutateServerSyncOutlets } from '@/usabilityScripts/globalMutate.js'
// import views from './view.vue'
import { storeS } from '@/store';

export default {
    props: ['obj', 'number'],
    components: { 
        tablecustom, 
        infoblocks, 
        circular, 
        // views 
    },
    data() {
        return {
            form: {},
            oldRow: "",
            search: "",
            infoblockShow: false,
            chartblockShow: false,
            showModal: false,
            shortapplic: "",
            columns: [
                {
                    name: "ID",
                    text: "tradepointId",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("Point_name"),
                    text: "tradepointName",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("Status"),
                    text: "tradepointStatus",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item) => this.mutateTariffing(item).name,
                    mutateClass: (item) => this.mutateTariffing(item).color
                },
                {
                    name: this.$t("Products"),
                    text: "countProducts",
                    align: "right",
                    status: true,
                    copy: true,
                    mutate: (item, obj) => obj.countProducts ? obj.countProducts : obj.productsCount ? obj.productsCount : '-'
                },
                {
                    name: this.$t("calculationss"),
                    text: "countTechcards",
                    align: "right",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("countSale"),
                    text: "countSales",
                    align: "right",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("serverSync"),
                    text: "serverSync",
                    align: "right",
                    status: true,
                    copy: false,
                    mutate: (item) => this.mutateServerSync(item)
                },
          ],
          filterAll: [
                {
                    page: "outlets",
                    name: "Status",
                    category: "0",
                    items: []
                }
            ],
            selectStatus: "0",
            chartall: [],
            information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "ri-information-line",
                    color: "info"
                },
                {
                    title: this.$t('Charged'),
                    value: "0",
                    icon: "ri-checkbox-circle-line",
                    color: "success"
                },
                {
                    title: this.$t('Not_charged'),
                    value: "0",
                    icon: "ri-close-circle-line",
                    color: "danger"
                },
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj;
            // if(value != undefined){
            //     this.form = value;
            //     for(var item8 in this.form) {
            //         this.form[item8].title != oldValue[item8].title ? this.form[item8].oldtitle = oldValue[item8].title : null;
            //         this.form[item8].products_count != oldValue[item8].products_count ? this.form[item8].oldproducts_count = oldValue[item8].products_count : null;
            //         this.form[item8].cookcard_count != oldValue[item8].cookcard_count ? this.form[item8].oldcookcard_count = oldValue[item8].cookcard_count : null;
            //         this.form[item8].sale != oldValue[item8].sale ? this.form[item8].oldsale = oldValue[item8].sale : null;
            //     }
            // }
            // let newobj = this.form
            // let newArrStatus = Array.from(new Set(newobj.map(item => JSON.stringify(item.tradepointStatus)))).map(item => JSON.parse(item));
            // this.filterAll[0]['items'] = newArrStatus;
        },
        infoblock(){
            this.information[0].value = this.filteredItems.length
            this.information[1].value = this.filteredItems.filter(item => item.tradepointStatus == 'Tariffed').length
            this.information[2].value = this.filteredItems.filter(item => item.tradepointStatus == 'Not Tariffed').length
            this.infoblockShow = true
        },
        open(e){
            this.cardApplic = e;
            this.showModal = true;
        },
        chartblock(){
            for(var item in this.filteredItems){
                var value = {
                    name: this.filteredItems[item].title,
                    count: this.filteredItems[item].products_count,
                    items: [
                        {
                            category: "Products",
                            change: '0',
                            value: this.filteredItems[item].products_count
                        },
                        {
                            category: "Lineups",
                            change: '1',
                            value: this.filteredItems[item].cookcard_count
                        },
                        {
                            category: "countSale",
                            change: '2',
                            value: this.filteredItems[item].sale
                        }
                    ]
                }
                this.chartall.push(value)
            }
            this.chartblockShow = true
        },
        closechartblock(){
            this.chartall = []
            this.chartblockShow = false
        },
        searchB(e){
            this.search = e
        },
        change(name, e){
            if(name == 'Status'){
                this.selectStatus = e;
            }
        },
        mutateTariffing(e){
          return mutateTariffingOutlets(e)
        },
        mutateServerSync(e){
            return mutateServerSyncOutlets(e)
        },
        clearFilter(){
            this.selectStatus = "0";
        }
    },
    mounted(){
    },
    watch:{
        obj(value, oldValue){
            this.getdata(value, oldValue)
            // ("value", value)
            // ("oldValue", oldValue)
        }
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.title.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.tradepointId.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })

                // По статусу
                .filter(item => {
                    return this.selectStatus == 0 || item.status == this.selectStatus;
                })
        },
        checks(){
            return storeS.checks
        }
    }
}
</script>