<template>

    <!-- компонент таблицы -->
    <tablecustom 
        v-if="this.obj != null" 
        :columns="columns" 
        :rows="rows" 
        :createdform="false" 
        @open="open"
    />

    <!-- карточка заявки -->
    <viewBox 
      v-if="showBox"
      @close="showBox = false" 
      :shortapplic="objCard" 
    >
    </viewBox>

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewBox from '@/views/application/view/viewapplic'

export default {
    props: ['obj'],
    components: { tablecustom, viewBox },
    data() {
        return {
            objCard: '',
            showBox: false,
            rows: '',
            columns: [
                {
                    name: this.$t("Added"),
                    text: "createDatetime",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("closed"),
                    text: "closeDatetime",
                    align: "left",
                    status: true,
                    mutate: (item) => item ? item : '---------'
                },
                {
                    name: this.$t("Manager"),
                    text: "closeWorkerName",
                    align: "left",
                    status: true,
                    mutate: (item) => item ? item : '---------'
                },

            ],
            viewForms: 0
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.rows = this.obj
        },
        open(e){
            this.objCard = e;
            this.showBox = true
        }
    },
    computed: {
    }
}
</script>