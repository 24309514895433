<template>
  <!-- 
    Модальне вікно з відображення картки уроку та списку категорій
  -->

  <!-- 
    Компонент модального вікна 
    - :title - заголовок вікна
    - :stylebody - стилі вікна
  -->
  <modal 
    :title="form.lessonName ? form.lessonName : form.categoryName" 
    :stylebody="'var(--vz-body-bg)'"
  >
    <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
    <template v-slot:head-bottom>
      <div class="dropdown">
          <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="bx bx-dots-vertical-rounded align-bottom"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end">

            <!-- Додати урок -->
            <button class="dropdown-item" @click="this.$emit('addLesson')">{{ $t('addLesson') }}</button>

            <!-- Додати ярлик -->
            <button class="dropdown-item" v-if="form.lessonName" @click="showTagBox = true">{{ $t('addLabel') }}</button>
          </div>
      </div>

      <!-- Закрити вікно -->
      <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
    </template>

    <!-- Категорія під заголовком -->
    <template v-slot:status><br /><span class="fs-12 badge bg-secondary">{{ this.objCategory?.categoryName }}</span></template>

    <template v-slot:body>
        <!-- Вміст модального вікна, коли урок вибраний -->
        <div
          v-if="form.lessonName"
          id="knowledge-base-article"
        >
        <b-row>
          <!-- Ліва частина вікна зі списком уроків та посиланням -->
          <b-col
            lg="3"
            md="5"
            order="2"
            order-md="1"
          >
            <!-- Список уроків в категорії -->
            <b-card v-if="this.lessons">
              <h5 class="kb-title">
                <b>{{ $t('Lessons_in_this_category') }}</b>
              </h5>

              <b-list-group class="list-group-circle mt-1">
                <!-- Елемент списку для кожного уроку -->
                <b-list-group-item
                  v-for="item in lessons"
                  :key="item"
                  @click="open(item)"
                  :class="`${item.lessonName == form.lessonName ? 'bg-info text-white fs-14' : 'text-body fs-14'}`"
                  :style="`cursor: pointer;`"
                >
                  {{ item.lessonName }}
                </b-list-group-item>
              </b-list-group>
            </b-card>

            <!-- Посилання на урок в базі знань -->
            <b-card>
              <h5 class="kb-title">
                <b>{{ $t('linkToLesson') }}</b>
              </h5>
              <div class="input-group">
                <input type="text" class="form-control" v-model="form.link" disabled>
                <!-- Кнопка копіювати -->
                <button @click="copy(form.link)" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                <!-- Кнопка для надсилання смс -->
                <button @click="modalSmsbox = true" class="btn btn-success" type="button"><i class="bx bxs-send"></i></button>
              </div>
            </b-card>

            <!-- Список тегів уроку -->
            <b-card>
              <b-card-title> 
                <div class="card-header align-items-center d-flex border-bottom-dashed" >
                    <h4 class="card-title mb-0 flex-grow-1">{{$t('labels')}}</h4>
                    <div class="flex-shrink-0">
                      <button 
                        type="button" 
                        class="btn btn-soft-info btn-sm" 
                        @click="showTagBox = true"
                      >
                        <i class="ri-add-line align-bottom"></i>
                      </button>
                    </div>
                </div>
              </b-card-title> 

              <div class="pt-3">
                <!-- Виведення кожного тегу -->
                <div class="col-lg-12 mb-1">
                  <span class="fs-14 fw-semibold text-white" 
                    :style="`word-break: break-all;display: block;margin-right:6px;margin-bottom:5px;padding:7px;border-radius:5px;background-color: ${nameTagLess(item).color};`" 
                    v-for="item in form.tags" 
                    :key="item"
                  >
                    {{ nameTagLess(item).name }}
                  </span>
                </div>
              </div>

            </b-card>
          </b-col>

          <!-- Права частина вікна з вмістом обраного уроку -->
          <b-col
            lg="9"
            md="7"
            order="1"
            order-md="2"
          >
            <b-card>
              <!-- Заголовок уроку -->
              <h1 class="mb-2 pb-3 border-bottom" style="color: #009688">
                <b>{{ form.lessonName }}</b>
              </h1>

              <!-- Вміст уроку у форматі Quill Editor -->
              <div class="ql-container">
                <div class="ql-editor" id="print" v-html="form.comment"></div>
              </div>

            </b-card>
          </b-col>
        </b-row>
      </div>

      <!-- Вміст модального вікна, коли урок не вибраний -->
      <div v-else>
        <b-row>
          <b-col>
            <b-card >
              <!-- Заголовок категорії та поле пошуку уроків -->
              <h1 class="mb-2 pb-3 border-bottom text-center" style="color: #009688">
                <b>{{ categoryLess.categoryName }}</b>
              </h1>

              <!-- Поле пошуку уроків -->
              <div class="text-center">
                <h5 class="kb-title  text-muted" style="display: inline-flex;">
                  <input type="text" class="form-control" v-model="search" :placeholder="$t('titleLesson')" />
                </h5>
              </div>

              <!-- Список уроків у категорії -->
              <b-list-group class="list-group-circle mt-1">
                <!-- Елемент списку для кожного уроку -->
                <b-list-group-item
                  v-for="item in this.filteredItems"
                  :key="item"
                  @click="open(item)"
                  class="text-body "
                  style="cursor: pointer;text-align: center;"
                >
                  {{ item.lessonName }}
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
    </div>
    </template>

    <!-- Слот футера модального вікна з кнопками -->
    <template v-slot:footer-bottom>

      <!-- 
        Кнопка "Видалити" 
        - надає змогу видалити урок
        - відкривається модальне вікно, для підтвердження видалення уроку
        - йде перевірка на права доступу під номером 803 (видалення) та 2000 (режим бога)
      -->
      <button 
        type="button" 
        v-if="this.perms['803'] == true || this.perms['2000'] == true" 
        @click="showDialogRemove = true" 
        class="btn btn-danger"
      >
        {{ $t('Delete') }}
      </button>

      <!-- 
        Кнопка "Редагувати" 
        - надає змогу відредагувати урок
        - відкривається модальне вікно, для підтвердження видалення уроку
        - йде перевірка на права доступу під номером 803 (видалення faq) та 2000 (режим бога)
      -->
      <button 
        type="button" 
        v-if="(this.perms['803'] == true || this.perms['2000'] == true) && this.editMona == true" 
        @click="editreq(form)" 
        class="btn btn-info"
      >
        {{ $t('Edit') }}
      </button>

    </template>
  </modal>

  <!-- 
    Модальне вікно надсилання смс 
    - для відкриття застосовується перевірка значення modalSmsbox
    - @close - подія, яка спрацьовує при закритті картки
    - :link - параметр посилання уроку
    - :phone - параметр номеру клієнта
    - @sendSMS - подія, при якій йде надсилання смс
  -->
  <createsmsBox 
    v-if="modalSmsbox" 
    @close="modalSmsbox = false" 
    :link="form.link" 
    :phone="this.phone" 
    @sendSMS="sendSMS"
  />

  <!-- 
    Модальне вікно для редагування уроку
    - для відкриття застосовується перевірка значення showModalPost
    - @close - подія, яка спрацьовує при закритті картки
    - :categories - параметр з категоріями
    - :permEdit - параметр-прапорець режиму редагування
    - :obj - параметр з даними уроку
    - @edit - редагування уроку
  -->
  <addPost 
    v-if="showModalPost" 
    :categories = 'this.categories' 
    @close="closeEditLesson" 
    :permEdit ='this.permEdit' 
    :obj="this.form" 
    @edit="edit"
  />

  <!-- Компонент модального вікна для підтвердження видалення -->
  <dialogRemove 
    v-if="showDialogRemove" 
    @close="showDialogRemove = false" 
    @remove="remove(this.form)"
  />

  <!-- Компонент модального вікна для повторення уроку -->
  <dialogRepeat 
    v-if="showRepeatBox" 
    @close="showRepeatBox = false" 
    :fromType="'lesson'" 
    @repeat="edit(this.dataRepeat)" 
    @download="download" 
  />

  <!-- Модальне вікно для вибору тегів -->
  <modalTag 
    v-if="showTagBox"
    @close="showTagBox = false"
    :typeTags="'kw'"
    :checkedTags="form.tags"
    @selectedTags="selectedTags"
  />

</template>

<script>
import modal from '@/components/modal' // компонент модального вікна
import dialogRemove from '@/components/modal/modalremove' // компонент діалогового вікна видалення
import dialogRepeat from '@/components/modal/modalrepeat'
import createsmsBox from '../actions/createsms' // компонент діалогового вікна смс
import addPost from '@/components/knowledge-base/actions/addPost' // компонент вікна редагування уроку
import modalTag from '@/components/tags/list' // компонент вікна з тегами
import { KW, Clients } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних
import { nameTagLess } from '@/usabilityScripts/globalMutate'
import html2pdf from "html2pdf.js";
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import {copy} from '@/usabilityScripts/copy.js'

let apiServe = new KW();
let apiClients = new Clients()

export default {
  data() {
    return {
      showTagBox: false, // Прапорець відображення вікна вибору тегів
      permEdit: false, // Прапорець режиму редагування
      search: '', // Пошуковий запит для уроків
      form: { // параметри форми уроку
        id:"",
        title: "",
        content: "",
        content1: "",
        category: "0",
        lang:"0",
        create_user: "",
        create_date_time: "",
        url: "",
        tags:[]
      },
      editMona: false, // Статус редагування уроку
      lessons:{}, // Об'єкт з уроками
      modalSmsbox: false, // Прапорець модального вікна для відправки SMS
      showModalPost: false, // Прапорець вікна для редагування уроку
      showDialogRemove: false, // Прапорець відображення вікна видалення
      categoryLess: '',  // Об'єкт категорії уроку
      showRepeatBox: false,  // Прапорець модального вікна для повторення уроку
      dataRepeat: '' // Об'єкт для передачі даних у модальне вікно повторення
    };
  },
  props: ['obj', 'less', 'objLessons', 'categories', 'phone', 'objCategory'],
  components: {
    modal,
    createsmsBox,
    addPost,
    dialogRemove,
    modalTag,
    dialogRepeat
  },
  created() {
    // Завантаження даних для відображення уроків
    this.getdata();
  },
  methods: {
    sendSMS(e) {
      /**
        * Надсилання СМС за допомогою API-запиту та виводить сповіщення про успішне видалення.
        * @param {object} е - об'єкт повідомлення.
      */

      apiClients.sendSms(e).then(result => {
        if(result.status == 'done'){
          this.$toast.success(this.$t('msgSent'));
          this.$emit('close');
        } else {
          this.$toast.error(this.$t('error') + " " + result.error[0]);

          var errorText = 'Помилка надсилання смс з уроком'; 
          var statusRequest = result.status;
          var api = 'sendSms';
          var fileRequest = 'src/components/knowledge-base/view/index.vue';
          var params = e;
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    closeEditLesson() {
      /**
        * Закриває режим редагування уроку та сховує модальне вікно редагування.
      */
      this.permEdit = false;
      this.showModalPost = false;
    },
    edit(e) {
      /**
        * Редагує урок за допомогою API-запиту та виводить повідомлення про успішну зміну.
        * При успішній зміні закриває модальне вікно редагування та скидає флаг редагування та повторення уроку.
        * @param {Object} e - Об'єкт уроку для редагування.
      */

      try {
        apiServe.editLesson(e.lessonId, e).then(result=>{
          if(result.status === 'done') {
            this.$toast.success(this.$t('alertChanged'));
            this.showModalPost = false
            this.permEdit = false;
            this.showRepeatBox = false;
          } else {
            this.$toast.error(this.$t('error') + " " + result.error[0]);

            var tempObj = {
              "lessonId": e.lessonId,
              "params": e
            }

            var errorText = 'Помилка редагування уроку'; 
            var statusRequest = result.status;
            var api = 'editLesson';
            var fileRequest = 'src/components/knowledge-base/view/index.vue';
            var params = tempObj;
            var response = result

            // Викликаємо функцію для відправки повідомлення в телеграм
            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
            .then(response => console.log('Telegram API response:', response.data))
            .catch(error => console.error('Telegram API error:', error));
          }
        })
      } catch (error) {
        console.log('error', error)
      }
    },
    editreq(e) {
      /**
        * Встановлює об'єкт уроку для редагування та відображає модальне вікно редагування.
        * @param {Object} e - Об'єкт уроку для редагування.
      */

      this.form = e;
      this.permEdit = true;
      this.showModalPost = true; //запрос на редактированние
    },
    getdata(){
      /**
        * Ініціалізує дані компонента, встановлюючи дані категорії уроку, список уроків та сам урок для редагування.
      */
     
      this.categoryLess = this.objCategory;
      this.lessons = this.objLessons;
      this.form = this.obj;
    },
    selectedTags(ids){
      /**
        * Зберігає вибрані теги для уроку та здійснює API-запит для збереження змін.
        * @param {Array} ids - Масив ідентифікаторів обраних тегів.
      */

      this.form.tags = ids;
      apiServe.editLesson(this.form.lessonId, this.form).then(result => {
        if(result.status == 'done'){
          this.$toast.success('done');
        } else {
          this.$toast.error(this.$t('error') + " " + result.error[0]);

          var tempObj = {
            "lessonId": this.form.lessonId,
            "params": this.form
          }

          var errorText = 'Помилка редагування уроку з тегами'; 
          var statusRequest = result.status;
          var api = 'editLesson';
          var fileRequest = 'src/components/knowledge-base/view/index.vue';
          var params = tempObj;
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    open: function(e){
      /**
        * Відкриває вибраний урок для перегляду та редагування.
        * @param {Object} e - Об'єкт уроку для відкриття.
      */

      apiServe.getPost(e.lessonId).then(result => {
        if(result.status==='done'){
          this.form = result.data;
          this.editMona = true
        } else {
          this.$toast.error(this.$t('error') + " " + result.error[0]);

          var errorText = 'Помилка відкриття картки уроку'; 
          var statusRequest = result.status;
          var api = 'getPost';
          var fileRequest = 'src/components/knowledge-base/view/index.vue';
          var params = e.lessonId;
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    copy(item){
      // копіювання
      if(copy(item) == true) {
          this.$toast.success(this.$t('Copied'));
      } else {
          this.$toast.error(`Fucking mistake, contact Borys`);
      }
      return
    },
    remove: function(e){
      /**
        * Видаляє вибраний урок за допомогою API-запиту та виводить повідомлення про успішне видалення.
        * При успішному видаленні закриває модальне вікно.
        * @param {Object} e - Об'єкт уроку для видалення.
      */

        apiServe.deleteLesson(e.lessonId).then(result=> {
          if(result.status==='done') {
            this.$toast.success(this.$t('Removed'));
            this.$emit('close')
            this.$emit('getdata') //удалить урок
          } else {
            this.$toast.error(this.$t('error') + " " + result.error[0]);

            var errorText = 'Помилка видалення уроку'; 
            var statusRequest = result.status;
            var api = 'deleteLesson';
            var fileRequest = 'src/components/knowledge-base/view/index.vue';
            var params = e.lessonId;
            var response = result

            // Викликаємо функцію для відправки повідомлення в телеграм
            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
            .then(response => console.log('Telegram API response:', response.data))
            .catch(error => console.error('Telegram API error:', error));
          }
        })
    },
    download(){
      /**
        * Завантажує урок у PDF-форматі та виводить його відомості в діалоговому вікні.
      */

      storeS.preloaderTable = true

      const prtHtml = document.getElementById('print').innerHTML;

      var name = 'Урок - ' + this.form.lessonName ? this.form.lessonName : 'No Name';
      var options = {
          margin: 10,
          filename: name+".pdf",
      }

      html2pdf().from(prtHtml).set(options).outputPdf().then(function() {
          storeS.preloaderTable = false
      }).save();
    },
    nameTagLess(e){
      // Отримуємо дані тегу
      return nameTagLess(e)
    },
  },
  computed: {
    user(){
      /*
        Повертає дані користувача з глобального стору.
      */
      return storeS.userbase
    },
    perms() {
      /*
        Повертає дані прав доступу користувача з глобального стору.
      */
      return storeS.perms
    },
    tagsLessons(){
      /*
        Повертає дані тегів з глобального стору.
      */
      return storeS.tagsLessons
    },
    filteredItems: function() {
      // пошук по урокам в категорії
      return Object.values(this.lessons)
        // По полю поиска
        .filter(item => {
          return this.search == '' || item.lessonName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
        })
    }
  },
  mounted() {
    // Cлухання події showRepeatBox для оновлення даних 
    this.eventB.off('showRepeatBox');
    this.eventB.on('showRepeatBox', (data) => {
      this.showRepeatBox = true
      this.dataRepeat = data.obj
      console.log(data);
    })
  },
};
</script>

<style scoped>
.arrow_back {
  margin-bottom: 10px;
}
</style>