<template>
    <div style="padding-right: 7px;" class="mb-3">
    <div class="card-body text-center">
        <h5 class="mt-2 mb-1">{{ form.userName ? form.userName : checks['024'] ? 'John Doe' : '-' }}</h5>
        <p class="text-muted">{{ form.userPhone ? Number(form.userPhone) : '-' }}</p>

        <ul class="list-inline mb-0">
            <li class="list-inline-item avatar-xs" v-if="this.checks['006'] || this.perms[2000]">
                <a href="javascript:void(0);" class="avatar-title badge-soft-success text-success fs-15 rounded" @click="call(String(this.form.user_phone), this.form)">
                    <i class="ri-phone-line"></i>
                </a>
            </li>
            <li class="list-inline-item avatar-xs">
                <a @click="sendMAIL()" href="javascript:void(0);" class="avatar-title badge-soft-danger text-danger fs-15 rounded">
                    <i class="ri-mail-line"></i>
                </a>
            </li>
            <li class="list-inline-item avatar-xs">
                <a @click="sendSMS()" href="javascript:void(0);" class="avatar-title badge-soft-warning text-warning fs-15 rounded">
                    <i class="ri-question-answer-line"></i>
                </a>
            </li>
        </ul>
    </div>

        <b-tabs 
            pills
        >
            <b-tab :title="$t('Main')">
                <div style="padding: 0px 12px;;">
                    <div class="d-flex align-items-center py-3">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-16 mb-1 fw-semibold">{{ $t('name') }}</h5>
                                <p v-if="editForm != true" class="fs-15  mb-0 fw-normal" style="word-break: break-all;">{{ form.userName ? form.userName : checks['024'] ? 'John Doe' : '-' }}</p>
                                <input v-else v-model="form.userName" class="form-control" />
                            </div>
                        </div>
                        <div :class="`flex-shrink-0 ms-2 hstack gap-sm-1 mt-${editForm != true ? '2' : '4'}`">
                            <button type="button" @click="editForm == true ? edit() : editForm = true" :class="`btn btn-sm btn-outline-${editForm == true ? 'success': 'info'} fs-16`"><i :class="`bx bx-${editForm == true ? 'save': 'edit-alt'} align-middle`"></i></button>
                            <button type="button" @click="copy(form.userName)" class="btn btn-sm btn-outline-success fs-16"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 fw-semibold">{{ $t('phone_number') }}</h5>
                                <p class="fs-15 mb-0 fw-normal" style="word-break: break-all;">{{ Number(form.userPhone) }}</p>
                            </div>
                        </div>
                        <div class="flex-shrink-0 ms-2 ">
                            <button type="button" @click="copy(Number(form.userPhone))" class="btn btn-sm btn-outline-success fs-16"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 fw-semibold">{{ $t('mail') }}</h5>
                                <p v-if="editForm != true" class="fs-15  mb-0 fw-normal" style="word-break: break-all;">{{ form.userEmail == null ? "-": form.userEmail }}</p>
                                <input v-else v-model="form.userEmail" class="form-control" />
                            </div>
                        </div>
                        <div :class="`flex-shrink-0 ms-2 hstack gap-sm-1 mt-${editForm != true ? '2' : '4'}`">
                            <button type="button" @click="editForm == true ? edit() : editForm = true" :class="`btn btn-sm btn-outline-${editForm == true ? 'success': 'info'} fs-16`"><i :class="`bx bx-${editForm == true ? 'save': 'edit-alt'} align-middle`"></i></button>
                            <button type="button" @click="copy(form.userEmail)" class="btn btn-sm btn-outline-success fs-16"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('Details')">
                <div>
                    <div class="d-flex align-items-center py-3">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 text-muted">{{ $t('country') }}</h5>
                                <p class="fs-13  mb-0">{{ form.country }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { Unregistered } from '@/API'
import { storeS } from '@/store'
import {copy} from '@/usabilityScripts/copy.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Unregistered();

export default({
    props: ['obj'],
    data() {
        return {
            editForm: false,
            form: '',
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        sendSMS() {
            this.$emit('opensmsbox')
        },
        sendMAIL() {
            this.$emit('openmailbox')
        },
        call(e, form) {
            this.$emit('call', form.userPhone, form)
        },
        getdata(){
            this.form = this.obj
        },
        edit(){
            if (!this.form.id) {
                //перевіряємо чи вказаний id акаунту
                this.$toast.error(this.$t('idNotFound'));
            } else {
                apiServe.editAccount(this.form.id, this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('alertChanged'));
                        this.editForm = false
                    } else {
                        this.$toast.error(this.$t('error') + " #EditAcc");

                        var errorText = 'Помилка редагування акаунту'; 
                        var statusRequest = result.status;
                        var api = 'editAccount';
                        var fileRequest = 'src/views/accounts/unregistered/view/blocks/main/index.vue';
                        var params = {id: this.form.id, form: this.form};
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    },
    computed: {
        checks(){
            return storeS.checks
        },
        perms() {
            return storeS.perms
        },
        info (){
            return storeS.userbase
        }
    }
})
</script>
