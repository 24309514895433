<template>
    <!-- Принтер чеків -->
    <h4><b>{{$t('main')}}</b></h4>
    <hr />
    <b-row>
        <b-col
        lg="12"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Шаблони -->
                        <b>
                            {{$t('template')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <!-- Види шаблонів -->
                    <select
                        class="form-select mb-3"
                        v-model="form.data.labelParams.template"
                        :disabled="this.editform == false"
                        >
                        <option v-for="item in templates" :key="item" :value="item.value">{{ item.title }}</option>
                    </select>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="3"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Ширина етикетки -->
                        <b>
                            {{$t('LabelWidth')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div class="input-group">
                        <input type="text" class="form-control" :disabled="this.editform == false" v-model="form.data.sizes.labelWidthMM">
                        <span class="input-group-text">мм</span>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="3"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Висота етикетки -->
                        <b>
                            {{$t('LabelHeight')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div class="input-group">
                        <input type="text" class="form-control" :disabled="this.editform == false" v-model="form.data.sizes.labelHeightMM">
                        <span class="input-group-text">мм</span>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="3"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Дозволи -->
                        <b>
                            {{$t('permission')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div class="input-group">
                        <input type="text" class="form-control" :disabled="this.editform == false" v-model="form.data.sizes.dpi">
                        <span class="input-group-text">dpi</span>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="12"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Поля -->
                        <b>
                            {{$t('fields')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div class="hstack text-wrap gap-1">
                        <span class="fs-12 badge badge-soft-primary" v-for="item in form.data.labelParams.fields" :key="item">{{$t(item)}}</span>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
    <h4><b>{{$t('PrintOptions')}}</b></h4>
    <!-- Налаштування друку -->
    <hr />
    <b-row>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Режим друку -->
                        <b>
                            {{$t('print_mode')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <select
                        class="form-select mb-3"
                        v-model="form.data.printParams.printMode"
                        :disabled="this.editform == false"
                        >
                        <option v-for="item in printmode" :key="item" :value="item.value">{{ item.title }}</option>
                    </select>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
            lg="6"
            v-if="form.data.printParams.printMode == 'text'"
        >
            <b-card>
                <b-card-title>
                    <!-- Код сторінки -->
                    <h4>
                        <b>
                            {{$t('pageCode')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.labelParams.codepage">
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
    <h4><b>{{$t('sizes')}}</b></h4>
    <!-- Розміри -->
    <hr />
    <b-row>
        <b-col
        lg="6"
        v-if="form.data.labelParams.fields.filter(item => item == 'name')"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Розмір імені -->
                        <b>
                            {{$t('NameSize')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div v-if="form.data.printParams.printMode == 'image'">
                        <!-- Якщо режим друку графічний, то виводимо назву шрифта -->
                        <h5> - {{form.data.sizes.nameFont}}</h5>
                    </div>
                    <div v-else >
                        <!-- Якщо не графічний. Виводимо розміри -->
                        <h5>- {{form.data.sizes.nameFont_t.font}}</h5>
                        <h5>- {{$t('Width')}} - x{{form.data.sizes.nameFont_t.xMultiplication}}</h5>
                        <h5>- {{$t('Height')}} - x{{form.data.sizes.nameFont_t.yMultiplication}}</h5>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <b>
                            <!-- Розмір опису -->
                            {{$t('DescSize')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div v-if="form.data.printParams.printMode == 'image'">
                        <!-- Якщо режим друку графічний, то опис шрифта виводимо -->
                        <h5> - {{form.data.sizes.descriptionFont}}</h5>
                    </div>
                    <div v-else >
                        <!-- Якщо неграфічний, то розміри -->
                        <h5>- {{form.data.sizes.descriptionFont_t.font}}</h5>
                        <h5>- {{$t('Width')}} - x{{form.data.sizes.descriptionFont_t.xMultiplication}}</h5>
                        <h5>- {{$t('Height')}} - x{{form.data.sizes.descriptionFont_t.yMultiplication}}</h5>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <!-- Розмір ціни -->
                    <h4>
                        <b>
                            {{$t('PriceSize')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div v-if="form.data.printParams.printMode == 'image'">
                        <!-- Якщо режим друку графічний, то шрифт ціни виводимо -->
                        <h5> - {{form.data.sizes.priceFont}}</h5>
                    </div>
                    <div v-else >
                        <!-- Виводимо розміри, якщо не графічний режим -->
                        <h5>- {{form.data.sizes.priceFont_t.font}}</h5>
                        <h5>- {{$t('Width')}} - x{{form.data.sizes.priceFont_t.xMultiplication}}</h5>
                        <h5>- {{$t('Height')}} - x{{form.data.sizes.priceFont_t.yMultiplication}}</h5>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Розмір дати -->
                        <b>
                            {{$t('DateSize')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div v-if="form.data.printParams.printMode == 'image'">
                        <!-- Якщо режим друку графічний, то шрифт дати виводимо -->
                        <h5> - {{form.data.sizes.dateTimeFont}}</h5>
                    </div>
                    <div v-else >
                        <!-- Виводимо розміри дати, якщо не графічний -->
                        <h5>- {{form.data.sizes.dateTimeFont_t.font}}</h5>
                        <h5>- {{$t('Width')}} - x{{form.data.sizes.dateTimeFont_t.xMultiplication}}</h5>
                        <h5>- {{$t('Height')}} - x{{form.data.sizes.dateTimeFont_t.yMultiplication}}</h5>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Товщина шрифта -->
                        <b>
                            {{$t('FontWeight')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div v-if="form.data.printParams.printMode == 'image'">
                        <!-- Якщо режим друку графічний, то виводимо товщину шрифта -->
                        <h5> - {{form.data.sizes.weightFont}}</h5>
                    </div>
                    <div v-else >
                        <!-- Якщо не графічний, то розміри -->
                        <h5>- {{form.data.sizes.weightFont_t.font}}</h5>
                        <h5>- {{$t('Width')}} - x{{form.data.sizes.weightFont_t.xMultiplication}}</h5>
                        <h5>- {{$t('Height')}} - x{{form.data.sizes.weightFont_t.yMultiplication}}</h5>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Розмір шрифта -->
                        <b>
                            {{$t('FontSize')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div v-if="form.data.printParams.printMode == 'image'">
                        <!-- Якщо режим друку графічний, то виводимо розмір шрифта -->
                        <h5> - {{form.data.sizes.sizeFont}}</h5>
                    </div>
                    <div v-else >
                        <!-- Якщо не графічний, то розміри шрифта виводимо детально -->
                        <h5>- {{form.data.sizes.sizeFont_t.font}}</h5>
                        <h5>- {{$t('Width')}} - x{{form.data.sizes.sizeFont_t.xMultiplication}}</h5>
                        <h5>- {{$t('Height')}} - x{{form.data.sizes.sizeFont_t.yMultiplication}}</h5>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Шрифт -->
                        <b>
                            {{$t('ItemFont')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div v-if="form.data.printParams.printMode == 'image'">
                        <!-- Якщо режим друку графічний, то виводимо код шрифта -->
                        <h5> - {{form.data.sizes.vendorCodeFont}}</h5>
                    </div>
                    <div v-else >
                        <!-- Якщо ні, то детальні дані -->
                        <h5>- {{form.data.sizes.vendorCodeFont_t.font}}</h5>
                        <h5>- {{$t('Width')}} - x{{form.data.sizes.vendorCodeFont_t.xMultiplication}}</h5>
                        <h5>- {{$t('Height')}} - x{{form.data.sizes.vendorCodeFont_t.yMultiplication}}</h5>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Виводимо шрифт виробника -->
                        <b>
                            {{$t('ManufFont')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div v-if="form.data.printParams.printMode == 'image'">
                        <!-- Якщо режим друку графічний, то виводимо шрифт -->
                        <h5> - {{form.data.sizes.manufacturerFont}}</h5>
                    </div>
                    <div v-else >
                        <!-- Якщо ні, виводимо детальніше -->
                        <h5>- {{form.data.sizes.manufacturerFont_t.font}}</h5>
                        <h5>- {{$t('Width')}} - x{{form.data.sizes.manufacturerFont_t.xMultiplication}}</h5>
                        <h5>- {{$t('Height')}} - x{{form.data.sizes.manufacturerFont_t.yMultiplication}}</h5>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <!-- Тип шрифту -->
                    <h4>
                        <b>
                            {{$t('FontType')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <div v-if="form.data.printParams.printMode == 'image'">
                        <!-- Якщо режим друку графічний, то виводимо тип шрифта -->
                        <h5> - {{form.data.sizes.typeFont}}</h5>
                    </div>
                    <div v-else >
                        <!-- Якщо ні, то вводимо більш детальні дані -->
                        <h5>- {{form.data.sizes.typeFont_t.font}}</h5>
                        <h5>- {{$t('Width')}} - x{{form.data.sizes.typeFont_t.xMultiplication}}</h5>
                        <h5>- {{$t('Height')}} - x{{form.data.sizes.typeFont_t.yMultiplication}}</h5>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <!-- Ширина штрихкоду -->
                    <h4>
                        <b>
                            {{$t('BarcodeWidth')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <!-- Виводимо ширину штрихкоду -->
                    <h5> - {{form.data.sizes.barWidth}}</h5>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Висота штрихкоду -->
                        <b>
                            {{$t('BarcodeHeight')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <!-- Виводимо висоту штрихкоду -->
                    <h5> - {{form.data.sizes.barcodeHeight}}</h5>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        v-if="form.data.printParams.printMode == 'image'"
        >
        <!-- Якщо режим друку графічний -->
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Шрифт штрихкоду -->
                        <b>
                            {{$t('BarcodeFont')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <!-- Виводимо шрифт штрихкоду -->
                    <h5> - {{form.data.sizes.barcodeFont}}</h5>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <!-- Ширина -->
                        <b>
                            {{$t('Width')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <!-- Виводимо ширину -->
                    <h5> - {{form.data.sizes.labelWidthPX}}</h5>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        >
            <b-card>
                <b-card-title>
                    <h4>
                        <b>
                            <!-- Висота -->
                            {{$t('Height')}}
                        </b>
                    </h4>
                </b-card-title>
                <b-card-text>
                    <!-- Виводимо висоту -->
                    <h5> - {{form.data.sizes.labelHeightPX}}</h5>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
    <h4><b>{{$t('indents')}}</b></h4>
    <!-- Відступи -->
    <hr />
    <b-row >
        <b-col
        lg="6">
            <b-card>
                <b-card-title>
                    <!-- Відступ між етикетками -->
                    <h4><b>{{$t('GapbetweenLabels')}}</b></h4>
                </b-card-title>
                <b-card-text>
                    <div class="input-group">
                        <input type="text" class="form-control" :disabled="this.editform == false" v-model="form.data.labelParams.gap">
                        <span class="input-group-text">мм</span>
                    </div>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
    <h4><b>{{$t('additionally')}}</b></h4>
    <!-- Додатково -->
    <hr />
    <b-row >
        <b-col
        lg="6">
            <b-card>
                <b-card-title>
                    <!-- Напрямок друку -->
                    <h4><b>{{$t('PrintDirection')}}</b></h4>
                </b-card-title>
                <b-card-text>
                    <!-- Варіанти напрямків -->
                    <select
                        class="form-select mb-3"
                        v-model="form.data.labelParams.direction"
                        :disabled="this.editform == false"
                        >
                        <option v-for="item in directions" :key="item" :value="item.value">{{ item.title }}</option>
                    </select>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col
        lg="6">
            <b-card>
                <b-card-title>
                    <!-- Темота друку    -->
                    <h4><b>{{$t('PrintDarkness')}}</b></h4>
                </b-card-title>
                <b-card-text>
                    <input type="text" class="form-control" :disabled="this.editform == false" v-model="form.data.labelParams.density">
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
export default{
    props: ['obj', 'editform'],
    data(){
        return{
            form: "",
            templates: [
                {
                    "title": this.$t("temp_normal"),
                    "value": "simple"
                },
                {
                    "title": this.$t("temp_extended"),
                    "value": "advanced"
                },
                {
                    "title": this.$t("temp_jewelry"),
                    "value": "jewelry"
                },
                {
                    "title": this.$t("temp_jewelrymini"),
                    "value": "jewelry_mini"
                }
            ],
            printmode: [
                {
                    "title": this.$t('mode_text'),
                    "value": "text"
                },
                {
                    "title": this.$t('mode_graphic'),
                    "value": "image"
                }
            ],
            directions: [
                {
                    "title": this.$t('WayUp'),
                    "value": "up"
                },
                {
                    "title": this.$t('Waydown'),
                    "value": "down"
                }
            ],
        }
    },
    created(){
        this.form = this.obj;
    },
    methods: {
        
    }
}
</script>