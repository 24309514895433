<template>
    <!-- 
        Компонент модального з підтвердженням збереження
    -->
    
    <!-- 
        Використовуємо Teleport щоб винести компонент за межі їхнього власного DOM.
        - В даному випадку, компонент, буде переміщений в елемент body документу. 
    -->
    <teleport to="body">
        <div class="modal modalRemove fade show" style="display: block;" >
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content border-0" >
                    <div :class="'modal-header p-3 ps-4 ' + this.classhead" >

                        <!-- Назва модального вікна, передана через параметр title -->
                        <h5 class="modal-title">
                            <slot name="title"></slot>
                        </h5>

                        <!-- Кнопка закриття вікна, "x" -->
                        <button type="button" class="btn-close" @click="$emit('close')"></button>
                    </div>

                    <!-- Основний вміст модального вікна -->
                    <div class="modal-body p-4">
                        <slot name="body"></slot>
                    </div>

                    <!-- Нижня частина модального вікна з кнопками -->
                    <div class="modal-footer" >
                        <!-- Кнопки, які будуть передані через слот -->
                        <slot name="footer-bottom"></slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>

</template>

<script>
import { storeS } from "@/store"; // глобальне сховище даних

export default {
    props: ['classhead'],
    data() {
        return {
        }
    },
    methods:{
        close() {
            // Закриття модального вікна
            this.$emit('close')
        },
    },
    mounted(){
        // Додавання класу модального вікна в масив відкритих модалок
        storeS.modalsOpen.push('modalsave')

        // Додавання обробників клавіш "Esc"
        this.eventKey.on('esc', () => {
            if(storeS.modalsOpen[storeS.modalsOpen.length - 1] == 'modalsave' && !document.querySelector('#preloader')) {
                storeS.modalsOpen.pop()
                this.close()
            }
        });
    }
}
</script>


<style scoped>
.modalshow {
    display: block;
    opacity: 1;
}

.modal {
    background: #2e2e2eb8;
    transition: opacity 0.15s linear;
    backdrop-filter: blur(5px);
}
.modal-content {
    border-radius: 6px;
}

.modal-header{
    border-bottom: 1px solid #2125291a;
}
.modal-fullscreen .modal-body{
    overflow-y: initial;
}
.modal-footer {
    justify-content: center;
}

</style>
