<template>
    <!-- модальное окно закрытия проекта -->
    <dialogBox @close="this.$emit('close')">
        <template v-slot:title>{{$t('editDeadline')}}</template>
        <template v-slot:body>
            <div class="mb-3">
                <p class="text-muted mb-2">
                    <a @click="showModalCreate = true" class="link copy float-end text-decoration-underline">{{ $t('Add') }}</a
                    >{{ $t('Deadline') }}
                </p>
                <b-row>
                    <b-col lg="6">
                        <Multiselect
                            v-model="type"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="typeUpdates"
                            @select="changeListUpdate"
                            :placeholder="this.$t('Updates')"
                        />
                    </b-col>
                    <b-col lg="6">
                        <Multiselect
                            v-model="deadline"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="deadlinesList"
                            :disabled="type == ''"
                        />
                    </b-col>
                </b-row>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <!-- <button class="btn link-danger fw-medium" @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button> -->
            <button 
                type="button" 
                class="btn btn-success" 
                @click="toDec()"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
    <createDead v-if="showModalCreate == true" @close="showModalCreate = false;"  @getData="this.getData()"></createDead>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import dialogBox from '@/components/modal/dialog'
import { Projects } from '@/API'
import { storeS } from '@/store'
import createDead from '../../../changelog/new'
import { Updates } from '../../../../API';

let apiServe = new Projects();
let apiUpdates = new Updates()

export default {
    props: [ 'obj'],
    components: {
        dialogBox,
        Multiselect,
        createDead
    },
    data(){
        return {
            showModalCreate: false,
            deadlinesList: [],
            deadline: '',
            deadlines: '',
            typeUpdates: [
                {
                    label: 'SkyService',
                    value: "skyservice"
                },
                {
                    label: 'Pos-terminal',
                    value: "posterminal"
                },
                {
                    label: 'Dashboard',
                    value: "dashboard"
                },
                {
                    label: 'Sky Market',
                    value: "skymarket"
                },
                {
                    label: 'SellUP',
                    value: "sellup"
                },
                {
                    label: this.$t('orderBoard'),
                    value: "hallscreen"
                },
                {
                    label: 'Inside',
                    value: "inside"
                },
                {
                    label: this.$t('onlyForAndroid'),
                    value: "android"
                },
                {
                    label: this.$t('onlyForIOS'),
                    value: "ios"
                },
            ]
        }
    },
    methods: {
        toDec(){
            if(this.deadline != '') {
                this.obj.deadlineId = this.deadline
                apiServe.editProject(this.obj.projectId, this.obj).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('alertChanged'))
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error'))
                    }
                })
            } else {
                this.$toast.error(this.$t(
                    'Null'
                ))
            }
        },
        changeListUpdate(){
            console.log('e', this.type);
            this.deadlinesList = [];
            this.deadlines.forEach(element => {
                // if(new Date(element.deadlineDate) - new Date(this.currentDate()) >= 0 ) { // фільтр по даті
                    console.log('ele', element);
                    if(element.update == this.type && new Date() - new Date(element.deadlineDate) < 0){
                        this.deadlinesList.push({
                            label: `${this.$t('app')}: ` + element.update.charAt(0).toUpperCase() + element.update.slice(1) + ` | ${this.$t('version')}: ` + element.version + ` | ${this.$t('date')}: ` + element.deadlineDate,
                            value: element.deadlineId
                        })
                    }
                // } 
            });
        },
        currentDate() {
            var date = new Date();
            var dateStr =
            date.getFullYear() + "-" +
            ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
            ("00" + (date.getDate())).slice(-2) + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
            return dateStr
        },
        

        getData() {
            apiUpdates.getAllUpdates({pagelimit: 100}).then(result => {
                if(result.status === 'done'){
                    this.deadlines = result.data.items
                    this.modalEdit = true
                }
            })
        }
    },
    created() {

    }
}
</script>