import { storeS } from "@/store"; // глобальне сховище
import { eventB } from "@/main" // створювач глобальних подій

/* 
  Cтворюємо глобальну функцію обробника подій socket
  Ця функція буде приймати в себе саме повідмолення і ми будемо його обробляти
*/
export const wsMessageHandler = (message) => { 
  try {
    if (message.sendData) {
        console.table({
          "message": [message], 
          "message.sendData": [message.sendData]
        }); // debug console (don`t touch)

        switch (message.sendData.action) {
          // сайт
            case "orderCallOnSite": // З сайту замовили дзвінок
              var data = {
                call: message.sendData.call ? message.sendData.call : 'new',
                requestId: message.sendData.requestId
              }
              if(storeS.perms[100]){
                eventB.emit('newRequest', data)
              }
              break;
            case "orderPresentationOnSite": // З сайту прилетіла презентація, оброблюємо
              var data = {
                presentationId: message.sendData.presentationId
              }
              if(window.location.pathname == '/presentations'){ // якщо людина знаходиться в презентаціях, то оновлюємо сторінку
                eventB.emit("addNewPresentation", data)
              }
              
              break;
              // cold calss
              // скасування холодного
              case "cancelColdCall":
                eventB.emit("updateColdCalls", true)
              // взятття в роботу холодного дзвінка
              case "addColdCallInJobNoRegClient":
                // "action",
                // "workerName",
                // "workerId",
                // "requestId"
                eventB.emit("updateColdCalls", true)
              break; 
              // відкладання холодного
              case "delayColdCall":
                // "action",
                // "workerName",
                // "workerId",
                // "requestId"
                eventB.emit("updateColdCalls", true)
              break;
              // закриття холодного дзвінка
              case 'closedColdCall':
                // "action":
                // "workerName"
                // "workerId"
                // "requestId"
                eventB.emit("updateColdCalls", true)
                
              break;
              // cold calls
          //sip
            case 'startCall':
              // Початок дзвінку

              // accountId: null //Якщо аккаунт не зареєстрованний, то дає null. Дає id, коли зареєстрованний
              // call: "Incoming" // Incming - дзвінок вхідний, Outgoing - вихідний 
              // clientId: 507 //Якщо аккаунт не зареєстрованний, то дає id. Дає null, коли зареєстрованний
              // event: "DialBegin" //дзвінок почався (летить з Астера)
              // phone: "380969179377" // номер клієнту
              // type: "call"
              // workerId: 39 // id оператора
              // workerName:"Ольга" // ім'я оператора
            break;
            case "answerCall":
              // Відповідь на дзвінок

              // accountId: "c4820bb8-4690-4044-81ef-82f13563b42e" //Якщо аккаунт не зареєстрованний, то дає null. Дає id, коли зареєстрованний
              // action: "answerCall"
              // call: "Incoming"
              // clientId: null //Якщо аккаунт не зареєстрованний, то дає id. Дає null, коли зареєстрованний
              // event: "DialEnd"
              // phone: "380966576574" // номер клієнту
              // type: "call"
              // workerId: 33 // id оператора
              // workerName: "Борис" // ім'я оператора
              break;
          // Заявки
            case "changeOnCallNowRequests": // є заявки, котрим треба змінити статус на "Затеелефонувати зараз"
                // "action": "changeOnCallNowRequests",
                // "requestIds": [6303] //id заявок
                
                var data = {
                  requestId: message.sendData.requestIds
                }

                eventB.emit('changeStatusCall', data) // оновлюємо список дзвінків
                
            break;
            case "addNewRequest": // Додавання нової заявки
              // Створення заявки

              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'requestId' => $requestId
              var data = {
                action: "addNewRequest",
                status:true,
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                call: message.sendData.call ? message.sendData.call : 'new',
                requestId: message.sendData.requestId,
                phone: message.sendData.phone,
              }

              if(storeS.perms[100]){
                setTimeout(eventB.emit('newRequest', data), 200)
                if(message.sendData.workerId == storeS.userbase.userid) {
                  eventB.emit('closeReq', data) // отримуємо заявку після закінчення дзвінка, якщо юзер з кц
                  eventB.emit('commentInRequest', data) // відкриваємо картку після дзвінка
                }

                eventB.emit('updateRequestByCall', {status: true}) // оновлюємо список дзвінків 
              }
              break;
            case 'getRequest': // оновлення даних
                // "action": "getRequest", //
                // "call": "outgoing", // характер дзвінку
                // "workerName": "Борис", // ім'я оператора
                // "workerId": 33, // id оператора
                // "requestId": 4963 // id заявки
                // "type" : 'call'/'dump'
              var data = {
                action: "getRequest",
                status:true,
                requestId: message.sendData.requestId,
                phone: message.sendData.phone,
                type: message.sendData.requestType,
                call: message.sendData.call
              }

              if(message.sendData.workerId == storeS.userbase.userid) {
                eventB.emit('closeReq', data) // отримуємо заявку після закінчення дзвінка, якщо юзер з кц
                eventB.emit('commentInRequest', data) // відкриваємо картку після дзвінка
              }
              eventB.emit('updateRequestByCall', {status: true}) // оновлюємо список дзвінків
              break;
            case "transferRequest": // заявку передали, оновлюємо статус
              // передати заявку +
              // 'fromWorkerId' => $worker['workerId'],
              // 'toWorkerId' => $_GET['workerId']
              // 'requestId' => $_GET["requestId"]
              var data = {
                fromWorkerId: message.sendData.fromWorkerId,
                toWorkerId: message.sendData.toWorkerId,
                requestId: message.sendData.requestId
              }
              if(storeS.perms[102]){
                eventB.emit('transferRequest', data)
              }
              break;
            case "cancelRequest": // відміна заявки, оновлюємо дані
              // скасування заявки +
              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'requestId' =Я потенційно виправив, але мені здається, що > $_GET["requestId"]
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                requestId: message.sendData.requestId
              }
              if(storeS.perms[100]){
                eventB.emit("cancelRequest", data)
              }
              break;
            case "delayRequest": // перенесення заявки, оновлюємо дані
              // перенесення заявки +
              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'requestId' => $_GET["requestId"]
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                requestId: message.sendData.requestId
              }
              if(storeS.perms[100] && window.location.pathname == '/calls/all/'){
                eventB.emit("delayRequest", data)
              }
              break;
            case "closeRequest":
              // закриття заявки +
              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'requestId' => $_GET["requestId"]
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                requestId: message.sendData.requestId
              }

              if(storeS.perms[100] && window.location.pathname == '/calls/all/'){
                eventB.emit("delayRequest", data)
              }
              break;
            case "callNowRequest":
              // зателефонувати зараз за заявкою -
              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'requestId' => $_GET["requestId"]
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                requestId: message.sendData.requestId
              }
              if(storeS.perms[100] && window.location.pathname == '/calls/all/'){
                eventB.emit("delayRequest", data)
              }
              // eventB.emit("closePresentation", data)
              break;
            case "addReportOnCancelRequest":
              // репорт на скасування заявки +-
              // 'workerName' => $worker['workerName'],
              // 'requestId' => $_GET["requestId"],
              // 'comment' => $data['comment'],
              // 'userPhone' => $request['user_phone']
              var data = {
                workerName: message.sendData.workerName,
                requestId: message.sendData.requestId,
                comment: message.sendData.comment,
                userPhone: message.sendData.userPhone
              }
              
              if(storeS.perms[100] && window.location.pathname == '/calls/all/'){
                eventB.emit("delayRequest", data)
              }
              break;
            case "addRequestInJobRegClient":
              // заявку прийнято в роботу. Клієнт є у SkyService +
              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'requestId' => $request_id
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                requestId: message.sendData.requestId,
                userPhone: message.sendData.userPhone,
                type: "addRequestInJobRegClient"
              }
              if(storeS.perms[100] && window.location.pathname == '/calls/all/'){
                eventB.emit("updateRequestByCall", data)  // оновлюємо список дзвінків
              }
              break;
            case "addRequestInJobNoRegClient":
              // заявку прийнято в роботу. Клієнт не зареєстрований у SkyService +
              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'requestId' => $request_id
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                requestId: message.sendData.requestId,
                type: "addRequestInJobNoRegClient"
              }
              if(storeS.perms[100] && window.location.pathname == '/calls/all/'){
                eventB.emit("updateRequestByCall", data) // оновлюємо список дзвінків
              }
              break;
            case "fixationTransferRequest":
              // фіксація прийняття заявки +
              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'requestId' => $_GET["requestId"]
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                requestId: message.sendData.requestId,
              }
              if(storeS.perms[102]){
                eventB.emit("fixationTransferRequest", data)
              }
              break;
            case "rejectTransferRequest":
              // відмовитись від передачі заявки заявки 
              // 'requestId' => $_GET["requestId"]
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                requestId: message.sendData.requestId,
              }
              if(storeS.perms[102]){
                eventB.emit("fixationTransferRequest", data)
              }
              break;
          // інші дзвінки
            case "transferRequestByCall":
              // передати відвал -
              // 'fromWorkerId' => $worker['workerId'],
              // 'toWorkerId' => $_GET['workerId'],
              // 'requestId' => $_GET["requestId"]
              var data = {
                fromWorkerId: message.sendData.fromWorkerId,
                toWorkerId: message.sendData.toWorkerId,
                requestId: message.sendData.requestId
              }
              if(storeS.perms[100]){
                eventB.emit("transferRequestByCall", data)
              }
              break; 
            case "rejectTransferRequestByCall":
              // відмовитись від передачі відвалу -
              // 'requestId' => $_GET["requestId"]
              var data = {
                requestId: message.sendData.requestId,
                type: "rejectTransferRequestByCall"
              }
              if(storeS.perms[100] && window.location.pathname == '/calls/all/'){
                eventB.emit("updateRequestByCall", data)
              }
              break; 
            case "fixationTransferRequestByCall":
              // підтвердити передачу відвала -
              // 'requestId' => $_GET["requestId"]
              var data = {
                requestId: message.sendData.requestId,
                type: "fixationTransferRequestByCall"
              }
              if(storeS.perms[100] && window.location.pathname == '/calls/all/'){
                eventB.emit("updateRequestByCall", data)
              }
              break;
          // завдання
            case "addTaskWaitConfirm":
              // створення завдання +
              // taskId: "128"
              var data = {
                taskId: message.sendData.taskId,
              }
              if(storeS.perms[200]){
                eventB.emit("addTaskWaitConfirm", data)
              }
              break;
            case "addTaskInJob":
              // взяти задачу в роботу +
              // 'taskId' => $id
              var data = {
                taskId: message.sendData.taskId,
              }
              if(storeS.perms[200]){
                eventB.emit("addTaskInJob", data)
              }
              break;
            case "delayedTask":
              // відкласти завдання
              // 'taskId' => $_GET["taskId"]
              var data = {
                taskId: message.sendData.taskId,
              }
              if(storeS.perms[200]){
                eventB.emit("addTaskInJob", data)
              }
              break;
            case "addTaskNoConfirm":
              // завдання без підтвердження +
              // wait taskId
              if(storeS.perms[200]){
                eventB.emit("addTaskWaitConfirm")
              }
              break;
            case "confirmTask":
              // підтвердження завдання менеджером
              // 'taskId' => $_GET["taskId"]
              var data = {
                taskId: message.sendData.taskId,
              }
              if(storeS.perms[200]){
                eventB.emit("addTaskInJob", data)
              }
              break;
            case "performTask":
              // взяти в роботу завдання (бере в роботу той, хто виконує)
              // 'taskId' => $id
              var data = {
                taskId: message.sendData.taskId,
              }
              if(storeS.perms[200]){
                eventB.emit("addTaskInJob", data)
              }
              break;
            case "completedTask":
              // закрити задачу
              // 'taskId' => $_GET["taskId"]
              var data = {
                taskId: message.sendData.taskId,
              }
              if(storeS.perms[200]){
                eventB.emit("addTaskInJob", data)
              }
              break;
            case "cancelTask":
              // скасування завдання
              // 'taskId' => $_GET["taskId"]
              var data = {
                taskId: message.sendData.taskId,
              }
              if(storeS.perms[200]){
                eventB.emit("addTaskInJob", data)
              }
              break; 
            case "transferTask":
              // передати завдання -
              // 'fromWorkerId' => $worker['workerId'],
              // 'toWorkerId' => $_GET['workerId'],
              // 'taskId' => $_GET["taskId"],
              var data = {
                fromWorkerId: message.sendData.fromWorkerId,
                toWorkerId: message.sendData.toWorkerId,
                taskId: message.sendData.taskId
              }
              if(storeS.perms[200]){
                eventB.emit("transferTask", data)
              }
              break; 
            case "rejectTransferTask":
              // відмовитись від передачі завдання -
              // 'taskId' => $_GET["taskId"],
              var data = {
                taskId: message.sendData.taskId
              }
              if(storeS.perms[200]){
                eventB.emit("addTaskInJob", data)
              }
              break; 
            case "fixationTransferTask":
              // підтвердити передачу завдання -
              var data = {
                taskId: message.sendData.taskId
              }
              if(storeS.perms[200]){
                eventB.emit("addTaskInJob", data)
              }
              break; 
          //тикети
            case "createdTicket":
              // створення тикету +
              // 'ticketId' => $ticketId
              var data = {
                ticketId: message.sendData.ticketId
              }
              if(storeS.perms[300]){
                eventB.emit("createdTicket", data)
              }
              break;
            case "closedTicket":
              // закриття тикету +
              // 'ticketId' => $_GET['ticketId']
              var data = {
                ticketId: message.sendData.ticketId
              }
              if(storeS.perms[300]){
                eventB.emit("closedTicket", data)
              }
              break;
            case "decidedTicket":
              // рішення до тикета + 
              // 'ticketId' => $_GET['ticketId']
              var data = {
                ticketId: message.sendData.ticketId
              }
              if(storeS.perms[300]){
                eventB.emit("decidedTicket", data)
              }
              break;
            case "delayedTicket":
              // хз тикету, мабуть відклати до найкращих часів
              // 'ticketId' => $_GET['ticketId']
              var data = {
                ticketId: message.sendData.ticketId
              }
              if(storeS.perms[300]){
                eventB.emit("delayedTicket", data)
              }
              break;
            case "inJobTicket": 
              // тикет взяли в роботу +
              // 'ticketId' => $_GET['ticketId'],
              var data = {
                ticketId: message.sendData.ticketId,
                workerId: message.sendData.workerId
              }
              if(storeS.perms[300]){
                eventB.emit("inJobTicket", data)
              }
              break;
            case "inJobTicketManually": 
              // тикет призначили за кимось +
              // 'ticketId' => $_GET['ticketId'],
              // 'workerIds' => $data["workerIds"]
              var data = {
                ticketId: message.sendData.ticketId,
                workerIds: message.sendData.workerIds
              }
              if(storeS.perms[300]){
                eventB.emit("inJobTicketManually", data)
              }
              break;
            case "reopenedTicket": 
              // тикет перевідкрили +
              // 'ticketId' => $_GET['ticketId'],
              var data = {
                ticketId: message.sendData.ticketId
              }
              if(storeS.perms[300]){
                eventB.emit("reopenedTicket", data)
              }
              break;
            case "replyOnMessageInTicketChat":
              // реплай на повідомлення в чаті тикету +
              // 'message' => $data['message'],
              // 'fromWorkerId' => $worker['workerId'],
              // 'ticketId' => $_GET['ticketId'],
              var data = {
                message: message.sendData.message,
                fromWorkerId: message.sendData.fromWorkerId,
                ticketId: message.sendData.ticketId
              }
              if(storeS.perms[300]){
                eventB.emit("replyOnMessageInTicketChat", data)
              }
              break;
            case "sendMessageInTicketChat":
              // надсилання повідомлення в чаті тикету +
              // 'message' => $data['message'],
              // 'fromWorkerId' => $worker['workerId'],
              // 'ticketId' => $_GET['ticketId'],
              var data = {
                message: message.sendData.message,
                fromWorkerId: message.sendData.fromWorkerId,
                ticketId: message.sendData.ticketId
              }
              if(storeS.perms[300]){
                eventB.emit("sendMessageInTicketChat", data)
              }
              break;
          // проекти
            case "replyOnMessageInProjectChat":
              // реплай на повідомлення в чаті проекту +
              // 'message' => $data['message'],
              // 'fromWorkerId' => $worker['workerId'],
              // 'projectId' => $_GET['projectId'],
              var data = {
                message: message.sendData.message,
                fromWorkerId: message.sendData.fromWorkerId,
                projectId: message.sendData.projectId
              }
              if(storeS.perms[350]){
                eventB.emit("replyOnMessageInProjectChat", data)
              }
              break;
            case "sendMessageInProjectChat":
              // надсилання повідомлення в чаті проекту +
              // 'message' => $data['message'],
              // 'fromWorkerId' => $worker['workerId'],
              // 'projectId' => $_GET['projectId'],
              var data = {
                message: message.sendData.message,
                fromWorkerId: message.sendData.fromWorkerId,
                projectId: message.sendData.projectId
              }
              if(storeS.perms[350]){
                eventB.emit("sendMessageInProjectChat", data)
              }
              break;
          // перерви
            case "closeBreak":
              // закриття перерви +
              // 'workerName' => $worker['workerName'],
              // 'breakId' => $breakId,
              // 'duration' => $duration
              var data = {
                workerName: message.sendData.workerName,
                breakId: message.sendData.breakId,
                duration: (message.sendData.duration/60).toFixed(2)
              }

              eventB.emit("goFromBreak", data)
              
              if(storeS.perms[2000]){
                eventB.emit("closeBreak", data)
              }
              break;
            case "createBreak":
              // відкриття перерви +
              // 'workerName' => $worker['workerName'],
              // 'breakId' => $breakId
              var data = {
                workerName: message.sendData.workerName,
                breakId: message.sendData.breakId
              }

              eventB.emit("goOnBreak", data)

              if(storeS.perms[2000]){
                eventB.emit("createBreak", data)
              }
              break;
          // відвали
            case "addDump":
              // створення відвалу
              // 'dumpId' => $dumpId,
              var data = {
                dumpId: message.sendData.dumpId
              }
              if(storeS.perms[100]){
                eventB.emit("updateDumps", data)
              }
              break; 
            case "addDumpInJobNoRegClient":
              // заявку прийнято в роботу. Клієнт є у SkyService +
              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'requestId' => $request_id
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                requestId: message.sendData.requestId,
              }
              if(storeS.perms[100]){
                eventB.emit("dumpGetData", data)
              }
              break;
            case "closeDump": 
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                requestId: message.sendData.requestId,
              }
              if(storeS.perms[100]){
                eventB.emit("updateDumps", data)
              }
              break;
            case "transferDump":
              // передати відвал -
              // 'fromWorkerId' => $worker['workerId'],
              // 'toWorkerId' => $_GET['workerId'],
              // 'requestId' => $_GET["requestId"]
              var data = {
                fromWorkerId: message.sendData.fromWorkerId,
                toWorkerId: message.sendData.toWorkerId,
                requestId: message.sendData.requestId
              }
              if(storeS.perms[100]){
                eventB.emit("transferDump", data)
              }
              break; 
            case "rejectTransferDump":
              // відмовитись від передачі відвалу -
              // 'requestId' => $_GET["requestId"]
              var data = {
                requestId: message.sendData.requestId
              }
              if(storeS.perms[100]){
                eventB.emit("updateDumps", data)
              }
              break; 
            case "fixationTransferDump":
              // підтвердити передачу відвала -
              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'requestId' => $_GET["requestId"]
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                requestId: message.sendData.requestId,
              }
              if(storeS.perms[100]){
                eventB.emit("updateDumps", data)
              }
              break;
          // штрафи
            case "addFine":
              // створення штрафу +
              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'fineId' => $fineId
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                fineId: message.sendData.fineId
              }
              eventB.emit("addFine", data)
              break;
          // відпустка або вихідний
            case "addHoliday":
              // заявка на відпустку або вихідний +
              // 'workerName' => $worker['workerName'],
              // 'workerId' => $worker['workerId'],
              // 'holidayId' => $holidayId
              var data = {
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
                holidayId: message.sendData.holidayId
              }
              if(storeS.perms[521]){
                eventB.emit("addHoliday", data)
              }
              break;
          // презентації
            case "addNewPresentation":
              // створення заявки на презентацію
              // 'presentationId' => $presentationId,
              var data = {
                presentationId: message.sendData.presentationId
              }
              if(storeS.perms[110]){
                eventB.emit("addNewPresentation", data)
              }
              break;
            case "addPresentationInJobNoRegClient":
              // хз на презентацію
              // 'presentationId' => $request_id,
              var data = {
                presentationId: message.sendData.presentationId
              }
              if(storeS.perms[110]){
                eventB.emit("closePresentation", data)
              }
              break;
            case "cancelPresentation":
              // скасування презентації
              // 'presentationId' => $_GET["presentationId"],
              var data = {
                presentationId: message.sendData.presentationId
              }
              if(storeS.perms[110]){
                eventB.emit("cancelPresentation", data)
              }
              break;
            case "closePresentation":
              // закрити презентацію
              // 'presentationId' => $_GET["presentationId"],
              var data = {
                presentationId: message.sendData.presentationId
              }
              if(storeS.perms[110]){
                eventB.emit("closePresentation", data)
              }
              break;
            case "delayPresentation":
              // відкласти презентацію
              // 'presentationId' => $_GET["presentationId"],
              var data = {
                presentationId: message.sendData.presentationId
              }
              if(storeS.perms[110]){
                eventB.emit("closePresentation", data)
              }
              break;
            case "fixationTransferPresentation":
              // фіксація прийняття презентації
              // 'presentationId' => $_GET["presentationId"],
              var data = {
                presentationId: message.sendData.presentationId,
                workerName: message.sendData.workerName,
                workerId: message.sendData.workerId,
              }
              if(storeS.perms[110]){
                eventB.emit("fixationTransferPresentation", data)
              }
              break;
            case "rejectTransferPresentation":
              // відмовитись від передачі презент -
              // 'requestId' => $_GET["presentationId"]
              var data = {
                requestId: message.sendData.requestId
              }
              if(storeS.perms[110]){
                eventB.emit("fixationTransferPresentation", data)
              }
              break; 
            case "transferPresentation":
              // передача презентації
              // 'presentationId' => $_GET["presentationId"],
              var data = {
                presentationId: message.sendData.presentationId,
                fromWorkerId: message.sendData.fromWorkerId,
                toWorkerId: message.sendData.toWorkerId,
              }
              if(storeS.perms[110]){
                eventB.emit("transferPresentation", data)
              }
              break;
            case 'openShift':
              // "action": "openShift",
              // "workerId": 33, //id співробітника
              // "shiftId": "158" //id зміни
              var data = {
                workerId: message.sendData.workerId
              }
              eventB.emit('openShift', data)
            break;
            case 'closeShift':      
              // "action": "closeShift",
              // "workerId": 33, //id співробітника
              // "shiftId": "157" //id зміни
              var data = {
                workerId: message.sendData.workerId
              }
              eventB.emit('closeShift', data)
            break;
            case "registerInAts":
              // ATSLogin
              // "action": "registerInAts",
              // "workerId": 33 //id співробітника
              // workerName: //ім'я співробітника
              var data = {
                workerId: message.sendData.ATSLogin,
                workerName: message.sendData.workerName
              }
              eventB.emit('registerInAts', data)  
            break;
            case "unregisterInAts":
              // ATSLogin 
              // "action": "unregisterInAts",
              // "workerId": 33
              var data = {
                workerId: message.sendData.ATSLogin
              }
              eventB.emit('unregisterInAts', data)
            break;
            case "tryOpenShiftFromAnotherIp":
              //спроба відкрити зміну не з дозволеного IP
              // 'workerId' => $worker['workerId'],
              // 'ip' => SysMethods::getUserIpAddr()
              var data = {
                workerId: message.sendData.workerId,
                ip: message.sendData.ip
              }
              if(storeS.perms[2000]){
                eventB.emit("tryOpenShiftFromAnotherIp", data)
              }
              break;
            case "tryCloseShiftFromAnotherIp":
              //спроба закрити зміну не з дозволеного IP
              var data = {
                workerId: message.sendData.workerId,
                ip: message.sendData.ip
              }
              if(storeS.perms[2000]){
                eventB.emit("tryCloseShiftFromAnotherIp", data)
              }
              break;
          // співробітник
            case "editWorker":
              // редагування картки співробітника
              // 'action' => 'editWorker',
              // 'toWorkerId' => $_GET["workerId"]
              var data = {
                toWorkerId: message.sendData.toWorkerId,
                workerId: message.sendData.workerId
              }
              eventB.emit("editWorker", data)
              break;
            case "saveShiftSettings":
              // редагування картки співробітника
              // 'action' => 'editWorker',
              // 'toWorkerId' => $_GET["workerId"]
              var data = {
                toWorkerId: message.sendData.toWorkerId,
              }
              eventB.emit("editWorker", data)
              break;
            case "saveWorkerIp":
              // редагування картки співробітника
              // 'action' => 'editWorker',
              // 'toWorkerId' => $_GET["workerId"]
              var data = {
                toWorkerId: message.sendData.toWorkerId,
              }
              eventB.emit("editWorker", data)
              break;
            case "saveWorkerSettings":
              // редагування картки співробітника
              // 'action' => 'editWorker',
              // 'toWorkerId' => $_GET["workerId"]
              var data = {
                toWorkerId: message.sendData.toWorkerId,
              }
              eventB.emit("editWorker", data)
              break;
            case 'authServerOff':
              // если сервер авторизации лег
              // "action" => "authServerOff"

              eventB.emit('authServerOff', {status: true})
            break;
            case 'skyServerOff':
              // если сервер ская лег
              // "action" => "skyServerOff"

              eventB.emit('skyServerOff', {status: true})
            break;
            case 'addEvent':
              //якщо додали івент, оновити календар
              var data = {
                eventId: message.sendData.eventId,
                workerId: message.sendData.workerId,
                workerName: message.sendData.workerName
              }

              eventB.emit('addEvent', data)
            break;
            case 'deleteEvent':
              //якщо видалили івент, оновити календар
              var data = {
                eventId: message.sendData.eventId,
                workerId: message.sendData.workerId,
                workerName: message.sendData.workerName
              }

              eventB.emit('addEvent', data)
            break;
            case 'shareInFileManager':
              //поділились папкою
              var data = {
                fileManagerId: message.sendData.fileManagerId,
                name: message.sendData.name,
                shareWorkerPerms: message.sendData.shareWorkerPerms
              }

              eventB.emit('shareInFileManager', data)
            break;
        }
    } 
    // else if(message.userInfo){
    //   eventB.emit('online')
    // }
  } catch (err) {
    console.log(err)
  }
}
