<template>
    <div>
        <b-tabs 
            v-model="tabIndex"
            pills 
            content-class="mt-3"
        >
            <b-tab :title="$t('Main')" active>
                    <mainTab :obj="this.obj" />
            </b-tab>
            <b-tab :title="$t('chat')" @click="isOpenChat = true">
                <template v-if="isOpenChat == true">
                    <div style="margin:20px 0px;">
                        <chatTab :id="this.obj.projectId"/>
                    </div>
                </template>
            </b-tab>
            <b-tab :title="$t('tasks')" >
                <tasksTab :obj="this.obj" :bigger="this.bigger"/>
            </b-tab>
            <b-tab :title="$t('toEdit')" >
                <editsTab :obj="this.obj" />
            </b-tab>
            <b-tab :title="$t('appointed')" >
                <workersTab :obj="this.obj" @openCard = "openCard"/>
            </b-tab>
            <b-tab :title="$t('Files')" @click="isOpenFiles = true">
                <template v-if="isOpenFiles == true">
                    <filesTab :obj="this.obj" />
                </template>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import mainTab from './main/index'
import chatTab from './chat/index'
import tasksTab from '../../formright/tasks'
import editsTab from '../../formright/edits/toEdit'
import workersTab from '../../formright/teams/index'
import filesTab from './files/index'

export default ({
    props: ['obj', 'activeTab'],
    components: {
        mainTab,
        chatTab,
        tasksTab,
        editsTab,
        workersTab,
        filesTab
    },
    data(){
        return{
            bigger: true,
            form:'',
            tabIndex: 0,
            isOpenChat: false,
            isOpenFiles: false
        }
    },
    created(){
        if(this.activeTab && this.activeTab == 'chat'){
            this.tabIndex = 1
            this.isOpenChat = true
        }
    },
    methods: {
        openCard(e) {
            this.$emit('openCard',e)
        },
    }
})

</script>