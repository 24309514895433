<template>
    <!-- 
        Модальне вікно створення/надсилання смс
    -->

    <modal>
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('sendMessage') }}</template>
        <template v-slot:head-bottom>
            <!-- кнопка закриття вікна -->
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <template v-slot:body>

            <div class="mb-3">
                <!-- Вказування номеру телефону -->
                <h4 class="fs-15">{{ $t('enterPhoneNumber') }}</h4>
                <input type="number" class="form-control" id="contactNumber" v-model="this.form.userPhone" placeholder="+380">
            </div>

            <!-- Повідомлення -->
            <input type="text" class="form-control" id="contactNumber" v-model="this.form.text">
        </template>

        <template v-slot:footer-bottom>

            <!-- Закрити вікно -->
            <button 
                class="btn link-success fw-medium" 
                @click="$emit('close')"
            >
                <i class="ri-close-line me-1 align-middle"></i> 
                {{ $t('close') }} 
            </button>

            <!-- Надіслати смс -->
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="sendSms"
            >
                {{ $t('sendMessage') }}
            </button>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small'  // компонент модального вікна

export default ({
    data(){
        return {
            form: { // Дані для форми
                userPhone: "", // номер телефону
                text: "" // повідомлення
            }
        }
    },
    props:['link', 'phone'],
    components: { modal },
    created(){
        
        if(this.phone){
            this.form.userPhone = this.phone
        }
        
        this.form.text = this.link
    },
    methods: {
        sendSms(){
            if (!this.form.userPhone) {
                //перевіряємо чи вказаний номер
                this.$toast.error(this.$t('EnterNumber'));
            }
            if (!this.form.text) {
                //перевіряємо чи вказаний текст
                this.$toast.error(this.$t('EnterSms'));
            }

            this.$emit('sendSMS', this.form)
            this.$emit('close')
        }
    }
})
</script>
