
import axios from "axios";
import { storeS } from "@/store.js"
import Cookies from "js-cookie";

let baseURL = `https://api.admin.skyservice.online/`;
let token;
token = storeS.token

// апі по акаунту клієнта
export class Dashboard {

    //отримання картки бонусної програми
    getBonus(companyId, bonusId) {
        return axios.get(`${baseURL}?section=dashboard&action=getBonus&token=${token}&companyId=${companyId}&bonusId=${bonusId}`).then(response => response.data);
    }

    //отримання картки акції
    getStock(companyId, stockId) {
        return axios.get(`${baseURL}?section=dashboard&action=getStock&token=${token}&companyId=${companyId}&stockId=${stockId}`).then(response => response.data);
    }

    //отримання картки знижки
    getDiscount(companyId, discountId) {
        return axios.get(`${baseURL}?section=dashboard&action=getDiscount&token=${token}&companyId=${companyId}&discountId=${discountId}`).then(response => response.data);
    }

    //отримання картки терміналу/каси
    getTerminal(companyId, terminalId) {
        return axios.get(`${baseURL}?section=dashboard&action=getTerminal&token=${token}&companyId=${companyId}&terminalId=${terminalId}`).then(response => response.data);
    }

}

// побажання
export class Wishes {
    //надіслати анонімне повідомлення
    addWish(obj) {
        return axios.post(`${baseURL}?section=wishes&action=addWish&token=SaYzFyGtfFzFssDY8iaDhzSSNrsisQn8`, obj).then(response => response.data)
    }

}

// апі по перекладачу
export class TranslatorLocalise {

    // отримати статистику по проєктам
    getProjectsStats() {
        // return axios.get(`${baseURL}?section=localise&action=getProjectsStats&token=${token}`).then(response => response.data);
        return axios.get(`https://api.admin.skyservice.online/?section=localise&action=getProjectsStats&token=YBhiinaGbkkkh3DFhyiG9iD3fD5E379A`).then(response => response.data);
    }

    // отримати переклади
    getTranslationsKeys(obj) {
        // return axios.get(`${baseURL}?section=localise&action=getProjectsStats&token=${token}`).then(response => response.data);
        return axios.get(`https://api.admin.skyservice.online/?section=localise&action=getTranslationsKeys&token=YBhiinaGbkkkh3DFhyiG9iD3fD5E379A`, { params: obj }).then(response => response.data);
    }

    // редагувати ключ
    editKey(obj, paramsObj) {
        // return axios.get(`${baseURL}?section=localise&action=editKey&&project=inside-new$token=${token}`).then(response => response.data);
        return axios.post(`https://api.admin.skyservice.online/?section=localise&action=editKey&token=zHYn3Qs9ieHF7Y5bKyieR9244a4bsKh6`, obj, {params: paramsObj}).then(response => response.data);
    }

    // редагувати переклад
    editTranslate(obj, paramsObj) {
        // return axios.get(`${baseURL}?section=localise&action=editTranslate&&project=inside-new$token=${token}`).then(response => response.data);
        return axios.post(`https://api.admin.skyservice.online/?section=localise&action=editTranslate&token=zHYn3Qs9ieHF7Y5bKyieR9244a4bsKh6`, obj, {params: paramsObj}).then(response => response.data);
    }

    // видалити ключ
    delKey(obj, project){
        // return axios.get(`${baseURL}?section=localise&action=deleteKey&&project=inside-new$token=${token}`).then(response => response.data);
        return axios.post(`https://api.admin.skyservice.online/?section=localise&action=deleteKey&token=zHYn3Qs9ieHF7Y5bKyieR9244a4bsKh6&project=${project}`, obj).then(response => response.data);
    }

    // доадти переклад
    addTranslate(obj, project){
        return axios.post(`https://api.admin.skyservice.online/?section=localise&action=importTranslates&project=${project}&sourceLang=ru&needToTranslate=true&token=zHYn3Qs9ieHF7Y5bKyieR9244a4bsKh6`, obj).then(response => response.data);
    }
}

export class Messages {
    // отримати активні повідомлення
    getActiveMessages() {
        // return axios.get(`https://api.admin.skyservice.online/?section=messages&token=${token}&action=getMessages`).then(response => response.data);
        return axios.get(`https://api.admin.skyservice.online/?section=messages&token=${token}&action=getMessages`).then(response => response.data);
    }

    // отримати активне повідомлення
    getActiveMessage(id){
        // return axios.get(`https://api.admin.skyservice.online/?section=messages&action=getMessage&token=${token}&${id}`).then(response => response.data);
        return axios.get(`https://api.admin.skyservice.online/?section=messages&action=getMessage&token=${token}&messageId=${id}`).then(response => response.data);
    }

    // додати повідомлення
    addMessage(obj) {
        // return axios.post(`https://api.admin.skyservice.online/?section=messages&action=addMessage&token=${token}`, obj).then(response => response.data);
        return axios.post(`https://api.admin.skyservice.online/?section=messages&action=addMessage&token=${token}`, obj).then(response => response.data);
    }

    // перекласти один ключ
    translateOneKey(obj) {
        // return axios.post(`https://api.admin.skyservice.online/?section=messages&action=translateMessage&token=${token}`, obj).then(response => response.data);
        return axios.post(`https://api.admin.skyservice.online/?section=messages&action=translateMessage&token=${token}`, obj).then(response => response.data);
    }

    // перекласти всі ключі
    translateAllKey(obj) {
        // return axios.post(`https://api.admin.skyservice.online/?section=messages&action=translateAllMessages&token=${token}`, obj).then(response => response.data);
        return axios.post(`https://api.admin.skyservice.online/?section=messages&action=translateAllMessages&token=${token}`, obj).then(response => response.data);
    }
}