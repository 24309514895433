<template>
    <!-- 
        Модальне вікно створення події до документу | Нагадування
    -->

    <modal @close="this.$emit('updateCard'); this.$emit('close')">
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('CreateEvent') }}</template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>

            <!-- Назва події -->
            <div class="mt-4 text-center">
                <h3><b>{{ $t('CreateEvent') }}?</b></h3>
                <p class="text-muted fs-15 mb-4">{{ $t('addEventforCrOper') }}</p>
            </div>

            <!-- Тип події -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('productType') }}</h4>
                <select class="form-control" v-model="formEvent.type">
                    <option v-for="item in listType" :key="item" :value="item.value">{{ item.title }}</option>
                </select>
            </div>

            <!-- Дата і час події під проект або тікет -->
            <b-row v-if="this.crItem.type == 'ticket' || this.crItem.type == 'project'">
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('dateEvent') }}</h4>
                        <flatpickr v-model="this.crItem.date" :config="configDate" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('timeEvent') }}</h4>
                        <flatpickr v-model="this.crItem.time" :config="configTime" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
                    </div>
                </b-col>
            </b-row>

            <!-- Дата події під рахунок, нагадування -->
            <b-row v-if="this.crItem.type == 'invoice' && this.form.payment.status == 'pendingpay'">
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('dateEvent') }}</h4>
                        <flatpickr v-model="this.crItem.date" :config="configTimeInv" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
                    </div>
                </b-col>
            </b-row>

            <!-- Альорт щодо події під рахунок, нагадування -->
            <b-row v-if="this.crItem.type == 'invoice' && this.form.payment.status == 'pendingpay'">
                <b-col lg="12">
                   <p style="color:red">{{this.$t('invoiceAlert')}}</p>
                </b-col>
            </b-row>

        </template>
        
        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">

                <!-- 
                    Кнопка "Ні" 
                    - закриває вікно запиту 
                -->
                <button 
                    class="btn btn-danger" 
                    @click="this.$emit('updateCard'); this.$emit('close')"
                >
                    {{ $t('n') }}
                </button>

                <!-- 
                    Кнопка "Так" 
                    - надає змогу додати подію по докементу
                -->
                <button 
                    class="btn btn-success" 
                    @click="addEvent(this.obj, this.form)"
                >
                    {{ $t('y') }}
                </button>

            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog' // компонент модального вікна
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { storeS } from '@/store' // глобальне сховище даних
import { DataCalendar } from '@/API' // клас для взаємодії з API 
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new DataCalendar();

export default{
    props: ['obj', 'crItem', 'doBoth'],
    components: {
        modal,
        flatpickr: Vueflatpickr
    },
    data(){
        return{
            configTime: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            },
            configTimeInv: {
                enableTime: true,
                dateFormat: "Y-m-d H:i",
                time_24hr: true,
                minDate:'',
                maxDate: '',
            },
            form: '',
            formEvent: {
                name: "", // назва події
                type: "", // тип події
                status: "", // конф події
                date: "", // дата події, 2023-05-04
                time: "09:00", // час події, 14:00
                toDate: "", // 2-а дата події, 2023-05-10 , якщо вказується період
                description: "", // опис події
                document: { // документ по цьому створена подія (відгул, презентація, завдання)
                    id: '', // id документу
                    type: '' // тип документу
                }
            },
            listType: [
                // Список типів подій
                { title: this.$t('presentation'), value: 'presentation', perms: '110' }, // презентація
                { title: this.$t('connectionDevice'), value: 'connection', perms: '200' }, // підключення
                { title: this.$t('Task'), value: 'task', perms: '200' }, // завдання
                { title: this.$t('Meeting'), value: 'meeting', perms: null }, // зустріч
                { title: this.$t('deadLine'), value: 'deadline', perms: '370' }, // дедлайн
                { title: this.$t("ticket"), value: 'ticket', perms: '300' }, // тікет
                { title: this.$t("project"), value: 'project', perms: '350' }, // проект
                { title: this.$t('bDay'), value: 'bday', perms: null }, // день народження
                { title: this.$t('DayOff'), value: 'graphs', perms: '2000' }, // вихідний
                { title: this.$t('Vacation'), value: 'graphs', perms: '2000' }, // відпустка
                { title: this.$t('Other'), value: 'other', perms: null }, // інше
            ],
        }
    },
    created(){
        // Ініціалізація форми події з отриманих даних об'єкта
        this.form = this.obj

        // Встановлення типу події з об'єкта crItem
        this.formEvent.type = this.crItem.type

        /**
            * Встановлення типу модалки та її id
            * Потрібно для сокету
        */
        storeS.checkModal.type = 'event';
        storeS.checkModal.id = Math.floor(Math.random() * (9999 - 10)) + 10;
    },
    methods: {
        isShowItem(option) {
            /**
                * Метод для перевірки, чи слід показувати елемент відповідно до прав доступу користувача.
                * @param {Object} option - Об'єкт з параметрами елемента.
                * @returns {boolean} - Результат перевірки на відображення елемента.
            */

            return this.perms[2000] == true || this.perms[option.perms] == true || option.perms == null;
        },
        addEvent(){
            /**
                * Метод для додавання нової події в календар
            */

            // Заповнення форми події з об'єкта crItem
            this.formEvent.name = this.crItem.name;

            // Встановлення дати та часу в формі події в залежності від типу
            if(this.crItem.type == 'invoice') {
                this.formEvent.time = this.crItem.date.split(' ')[1]
                this.formEvent.date = this.crItem.date.split(' ')[0];
            } else {
                this.formEvent.time = this.crItem.time;
                this.formEvent.date = this.crItem.date;
                this.crItem.toDate != '' ? this.formEvent.toDate = this.crItem.toDate : ''
            }
            
            // Встановлення інших параметрів форми події
            this.formEvent.status = "public";
            
            this.formEvent.description = this.crItem.description == '' ? this.formEvent.description : this.crItem.description;
            this.formEvent.document = {
                id: this.crItem.id,
                type: this.crItem.type
            };

            // Виклик методу для додавання події
            apiServe.addEvent(this.formEvent).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Added'));
                    this.$emit('updateCard')
                     // Виклик обох подій, якщо doBoth == true
                    if(this.doBoth == true) {
                        this.$emit('doBoth', this.form)
                        this.$emit('close')
                    } else {
                        this.$emit('close')
                    }
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка створення події'; 
                    var statusRequest = result.status;
                    var api = 'addEvent';
                    var fileRequest = 'src/components/calendar/addEvent.vue';
                    var params = this.formEvent;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
            
            // Виклик зовнішнього події addEvent
            this.$emit('addEvent')
        }
    },
    computed: {
        maxDateCalc: function() {
            /**
                * maxDateCalc - властивість для обчислення максимальної дати для вибору у flatpickr.
                * @returns {string} - Рядок із максимальною датою.
            */

            let date = this.obj.datetime.split(' ')[0]
            date = date.split('-')[0]+'-'
                    + date.split('-')[1]
                    + '-' +String(Number(date.split('-')[2])+3) 
                    + " " + this.obj.datetime.split(' ')[1].split(':')[0] 
                    + ":" + this.obj.datetime.split(' ')[1].split(':')[1]
            return date
        },
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        }
    }
}
</script>