<template>
  <!-- Компонент калькулятор тарифу по даним клієнта -->

  <modal :title="$t('Calculator')">

    <template v-slot:head-bottom>
        <div class="" >
          <!-- Кнопка закриття модального вікна -->
          <button 
            type="button" 
            class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  
            @click="$emit('close')"
          >
            <i class="ri-close-fill align-bottom"></i>
          </button>
        </div>
    </template>

    <template v-slot:body>
      <div class="row justify-content-center block-calc">
        <div class="calculator col-md-6">
          <h1 class="text-muted" style="color: white !important">{{this.$t('company')}} 1</h1>
          <!-- Назва тарифу 1 -->
          <span 
            id="tip-test" 
            style="color: white;font-weight: bold;font-size: 30px;"
          >
            {{ this.firstTariff.license?.tariffName }}
          </span>

          <div style="text-align: left; width: 100%">
            <!-- Останній платіж, баланс -->
            <span 
              id="tip-sale" 
              style="color: #41957c;font-weight: bold;font-size: 30px;"
            >
              {{this.$t("balance")}}: {{ this.firstTariff.license?.userBalance }}
            </span>
            <br>
            <span 
              id="tip-sale" 
              style="color: #41957c;font-weight: bold;font-size: 30px;"
            >
              {{this.$t("active")}}: {{ this.firstTariff.license?.activeAccount }}
            </span>
          </div>

          <!-- Вибір країни -->
          <label class="bill mt-2">
            {{ $t('Choose_a_country') }}
          </label>
          <!-- Назва обраної країни -->
          <!-- <span class="badge bg-info fs-16 mb-2">{{ nameContry(this.selectedcountry) }}</span> -->
          <Multiselect 
            v-model="this.selectedcountry"
            :close-on-select="true"
            :searchable="true"
            :options="this.countrys"
            :placeholder="$t('StartTypingCountryName')"
            :disabled="true"
          />

          <!-- Введення кількості торгових точок -->
          <label class="bill mt-2">
            {{ $t('Outlets') }}
            <div class="quantity_inner en_div">
              <input 
                type="number" 
                name="tradepoint" 
                id="tradepoint"  
                v-model="tradepoint_first" 
                placeholder="0" 
                class="quantity"  
                disabled="true"
              />
            </div>
          </label>

          <!-- Введення кількості терміналів -->
          <label class="bill mt-2">
            {{ $t('NumberofTerminals') }}
            <div class="quantity_inner en_div">
              <input 
                type="number" 
                name="tradepoint" 
                id="tradepoint"  
                v-model="terminal_first" 
                placeholder="0" 
                class="quantity"  
                disabled="true"
              />
            </div>
          </label>

          <!-- Введення кількості товарів -->
          <label class="bill">
            {{ $t('Number_of_goods') }}
            <div class="quantity_inner en_div">
              <input 
                type="number" 
                name="product" 
                id="product"   
                v-model="product_first" 
                placeholder="0" 
                class="quantity" 
                disabled="true"
              />
            </div>
          </label>

          <!-- Введення кількості технічних карт -->
          <label class="bill">
            {{ $t('Number_of_technical_cards') }}
            <div class="quantity_inner en_div">
              <input 
                type="number" 
                name="routings" 
                id="routings" 
                v-model="routings_first" 
                placeholder="0"  
                class="quantity"
                disabled="true"
              />
            </div>
          </label>
        </div>

        <div class="calculator col-md-6">

          <h1 class="text-muted" style="color: white !important">{{this.$t('company')}} 2</h1>

          <!-- Назва тарифу 2 -->
          <span 
            id="tip-test" 
            style="color: white;font-weight: bold;font-size: 30px;"
          >
            {{ this.tariff_name() }}
          </span>
          <div style="text-align: left; width: 100%">
            <!-- Останній платіж, баланс -->
            <span 
              id="tip-sale" 
              style="color: #41957c;font-weight: bold;font-size: 30px;"
            >
              {{this.$t("balance")}}: {{ Number(this.secondTariff.license?.userBalance)}}
            </span>
            <br>
            <span 
              id="tip-sale" 
              style="color: #41957c;font-weight: bold;font-size: 30px;"
            >
              {{this.$t("active")}}: {{ this.secondTariff.license?.activeAccount }}
            </span>
          </div>
        <!-- Вибір країни -->
        <label class="bill mt-2">
          {{ $t('Choose_a_country') }}
        </label>
        <!-- Назва обраної країни -->
        <!-- <span class="badge bg-info fs-16 mb-2">{{ nameContry(this.selectedcountry) }}</span> -->
        <Multiselect 
          v-model="this.selectedcountry"
          :close-on-select="true"
          :searchable="true"
          :options="this.countrys"
          :placeholder="$t('StartTypingCountryName')"
          @select="getTariffCountry()"
          :disabled="true"
        />

        <!-- Введення кількості торгових точок -->
        <label class="bill mt-2">
          {{ $t('Outlets') }}
          <div class="quantity_inner en_div">
            <input 
              type="number" 
              name="tradepoint" 
              id="tradepoint"  
              v-model="tradepoint_second" 
              placeholder="0" 
              class="quantity"  
              @input="changeCountTerminal()"
            />
          </div>
        </label>

        <!-- Введення кількості терміналів -->
        <label class="bill mt-2">
          {{ $t('NumberofTerminals') }}
          <div class="quantity_inner en_div">
            <input 
              type="number" 
              name="tradepoint" 
              id="tradepoint"  
              v-model="terminal_second" 
              placeholder="0" 
              class="quantity"  
              @input="changeCountTerminal()"
            />
          </div>
        </label>

        <!-- Введення кількості товарів -->
        <label class="bill">
          {{ $t('Number_of_goods') }}
          <div class="quantity_inner en_div">
            <input 
              type="number" 
              name="product" 
              id="product"  
              v-model="product_second" 
              placeholder="0" 
              class="quantity" 
            />
          </div>
        </label>

        <!-- Введення кількості технічних карт -->
        <label class="bill">
          {{ $t('Number_of_technical_cards') }}
          <div class="quantity_inner en_div">
            <input 
              type="number" 
              name="routings" 
              id="routings" 
              v-model="routings_second" 
              placeholder="0"  
              class="quantity" 
            />
          </div>
        </label>
        </div>

        <!-- Виведення результатів -->
        <div class="result col-md-6" style="text-align: center;float: right; height: 900px;">
          <div>
            <!-- Місячний внесок + Валюта -->
            <div>
              <p style="text-transform: capitalize;">{{ $t('monthly') }}</p>
            </div>
            <span 
              class="value" 
              id="tip-result"
            >
              <span>
                {{ getSumMonth() }}  {{ this.data.currencyinfo.icon }}
              </span>
            </span>
          </div>

        <div>
          <!-- Сума знижки -->
          <div>
            <p style="text-transform: capitalize;">{{ $t('discount') }}</p>
          </div>
          <div style="align-items: revert;">
            <span 
              class="value" 
              id="tip-sale" 
              style="text-align: right;"
            >
              {{ getSumDiscont() }} {{ this.data.currencyinfo.icon }}
            </span>
          </div>
        </div>

        <div>
          <!-- Сума без знижки -->
          <div>
            <p>{{ $t('Without_discount') }}</p>
          </div>
          <span 
            class="value" 
            id="tip-not-sale"
          >
            {{ getSumNotSale() }} {{ this.data.currencyinfo.icon }}
          </span>
        </div>

        <div>
          <!-- Загальна вартість -->
          <div>
              <p>{{ $t('Total') }}</p>
          </div>
          <span 
            class="value" 
            id="tip-itog"
          >
            {{ getSumTotal() }}  {{ this.data.currencyinfo.icon }}
          </span>
        </div>
        <hr style="color: white;">
        <div>
          <!-- Загальна вартість -->
          <div style="text-align: left;">
            <p>{{ $t('Усього коштів, якщо зробити перенос') }}</p>
          </div>
            <span 
              class="value" 
              id="tip-itog"
              style="text-align: right; font-size: 48px;"   
            >
              {{ getSumTotalIfTransfer().balance }}
            </span>
        </div>

        <div>
          <!-- Загальна вартість -->
          <div style="text-align: left;">
            <p>{{ $t('Усього днів, якщо зробити перенос') }}</p>
          </div>
            <span 
              class="value" 
              id="tip-itog"
              style="text-align: right; font-size: 48px;"   
            >
              {{ getSumTotalIfTransfer().days }}
            </span>
        </div>

        <div>
          <!-- Загальна вартість -->
          <div style="text-align: left;">
            <p>{{ $t('Не вистачає для тарифу') }}</p>
          </div>
            <span 
              class="value" 
              id="tip-itog"
              style="text-align: right; font-size: 48px;"   
            >
              {{ getSumTotalIfTransfer().difference }}
            </span>
        </div>
        
        <!-- Кнопка скидання значень -->
        <button 
          style="margin-top: 30px;"
          class="reset-btn" 
          id="reset" 
          @click="reset"
        >
          {{ $t('Reset') }}
        </button>

        </div> 


        <div class="calculator col-md-6" style="padding: 3%; margin-left: 12px">
          <!-- Вибір періоду оплати -->
          <div class="tip">
            <span>{{ $t('Choose_a_payment_period') }}</span>
            <div class="en_div mt-2">
              <button 
                v-for="(tip, index) in tipsnew" 
                :key="index"
                :class="{ active: tip.isActive }"
                @click="
                  {
                      toggleActiveTip(index);
                  }"
                >
                  {{ tip.months }} {{ $t('month') }} <br> 
                <span class="discont_desc">{{tip.value}}% {{ $t('discounts_for') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer-bottom>
      <!-- Кнопка закриття модального вікна -->
      <a 
        class="btn btn-link link-success fw-medium"
        data-bs-dismiss="modal"
        @click="$emit('close')"
      >
        <i class="ri-close-line me-1 align-middle"></i>
        {{ $t('close') }}
      </a>
    </template>
</modal>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import modal from '@/components/modal/modal-lg' // компонент модального вікна
import { Clients, SuperAdmin } from '@/API' // клас для взаємодії з API 
import { getNameCountry } from '@/usabilityScripts/globalMutate'

let apiServe = new Clients();
let apiAdmin = new SuperAdmin()

export default {
  components: {
    Multiselect,
    modal
  },
  props: ["first", "second", 'obj'],
  data() {
    return {
      tipsnew: [
        { value: 0, months: 1, isActive: false },
        { value: 5, months: 3, isActive: false },
        { value: 7, months: 6, isActive: false },
        { value: 15, months: 12, isActive: false },
        { value: 25, months: 24, isActive: false },
        { value: 35, months: 60, isActive: false },
      ],
      sliderCustomzie: [1000, 3000],
      data: {
        currencyinfo: {}
      },
      op_tt: [ // Опції для вибору по яким точкам рахувати
        { label: this.$t('ActiveTT'), value: "active" }, // активні
        { label: this.$t('OnAllTT'), value: "all" } // всі
      ],
      select_mode_tt: "active", // Обраний режим 
      tradepoint_first: 0, // Кількість торгових точок 1
      tradepoint_second: 0, // Кількість торгових точок 2
      terminal_first: 0, // Кількість кас 1
      terminal_second: 0, // Кількість кас 2
      tariffItem_first: 0, // Кількість кас або точок 1
      tariffItem_second: 0, // Кількість кас або точок 2
      active_tradepoint_first: "0", // Кількість активних торгових точок 1
      active_tradepoint_secon: "0", // Кількість активних торгових точок 2
      active_terminal_first: "0", // Кількість активних терміналів 2
      active_terminal_second: "0", // Кількість активних терміналів 2
      product_first: 0, // Кількість товарів 1
      product_second: 0, // Кількість товарів 2
      routings_first: 0, // Кількість технічних карт 1
      routings_second: 0, // Кількість технічних карт 2
      StaticTip: 0, // Значення залишкових технічних карт
      staticMonths: 0,  // Кількість місяців 
      selectedcountry: "", // Обрана країна
      license: [],
      countrys: [], // Список країн,
      firstTariff: '',
      secondTariff: ''
    };
  },
  created(){
    this.getCountries();
    this.returnTariffFirst(this.first)
    this.returnTariffSecond(this.second)
  },
  methods: {
    getSumTotalIfTransfer() {
      // Прораховуємо скільки буде не вистачати або на скільки вистачить з урахуванням першого дня
      let total = this.getSumTotal()
      let balance = this.secondTariff.license ? this.secondTariff.license.userBalance : 0
      let perDay_first = (this.firstTariff.license?.userSumm / 30).toFixed(2)
      let days = 0
      
      let res = {
        balance: 0,
        days: 0,
        difference: 0
      }

      switch(this.obj.descType){
        case "balance":
          balance = balance + Number(this.firstTariff.license?.userBalance)
          res.balance = String(balance + '' + this.data.currencyinfo.icon)
          res.days = 0
          res.difference = (total - balance).toFixed(2) < 0 ? (0 + '' + this.data.currencyinfo.icon) : String((total - balance).toFixed(2) + '' + this.data.currencyinfo.icon)
        return res
        case "period":
          days = new Date(this.firstTariff.license?.activeAccount).getTime() - new Date().getTime() < 0 ? 0 : ((new Date(this.firstTariff.license?.activeAccount).getTime() - new Date().getTime()) / 86400000).toFixed(0)
          res.balance = 0
          res.days = String(balance + ' ' + this.$t('Days'))
          res.difference = 0
        return res
        case "balance_period":
          balance = Number(balance) + Number(this.firstTariff.license?.userBalance)
          days = new Date(this.firstTariff.license?.activeAccount).getTime() - new Date().getTime() < 0 ? 0 : ((new Date(this.firstTariff.license?.activeAccount).getTime() - new Date().getTime()) / 86400000).toFixed(0)
          res.balance = String(balance + '' + this.data.currencyinfo.icon)
          res.days = String(days + ' ' + this.$t('Days'))
          res.difference = (total - balance).toFixed(2) < 0 ? (0 + '' + this.data.currencyinfo.icon) : String((total - balance - perDay_first * days).toFixed(2) + '' + this.data.currencyinfo.icon)
        return res
      }
    },
    // отримуємо тариф для першої компанії, з котрої переносимо
    returnTariffFirst(id){
      apiAdmin.getCompClient(id).then(result => {
        if(result.status === 'done') {
            if(result.data.companyDataObj) {
              this.firstTariff = result.data.companyDataObj
              this.selectedcountry = result.data.companyDataObj.license.country
              // insert data
              this.tradepoint_first = this.firstTariff.tradepoints.length
              this.firstTariff.tradepoints.forEach(el => {
                console.log(el.terminalsCount)
                this.terminal_first += el.terminalsCount
                this.product_first += Number(el.productsCount)
                this.routings_first += Number(el.countTechcards)
              })
              // insert data
              this.getTariffCountry();
            } else {
              this.$toast.error(this.$t('error'))
            }
        }
      })
    },
    returnTariffSecond(id){
        // отримуємо тариф для другу компанію, на котру переносимо
        apiAdmin.getCompClient(id).then(result => {
            if(result.status === 'done') {
                if(result.data.companyDataObj) {
                  this.secondTariff = result.data.companyDataObj
                } else {
                  this.secondTariff = ''
                }
            }
        })
    },
    getCountries() {
      // Отримання списку країн

      apiServe.getCountries().then(result => {
        if(result.status == 'done'){
          for(var item in result.data){
            this.countrys.push({
              // label: result.data[item].name,
              label: this.getLangCountry(result.data[item].alpha2),
              value: result.data[item].alpha2,
              disabled: result.data[item].currencyinfo == null ? true : false
            })
          }
        } else {
          this.$toast.error(this.$t('error'))
        }
      })
    },
    getTariffCountry() {
      // Отримання тарифів для обраної країни

      apiServe.getCountry(this.selectedcountry).then(result => {
        if(result.status == 'done'){
          this.data = result.data;
        } else {
          this.$toast.error(this.$t('error'))
        }
      })
    },
    nameContry(item){
      // Отримання назви країни за її кодом

      var name = "";
      this.countrys.forEach((el) => {
        if(el.value === item) {
          name = el.label
        }
      })
      return name
    },
    ch_mode_tt(){
      // Зміна кількості торгових точок

      if(this.select_mode_tt == 'active'){
        this.tradepoint = this.active_tradepoint;
        this.terminal = this.active_terminal;
      } else {
        this.tradepoint = this.demo_license.demo_count_tt;
        this.terminal = this.demo_license.demo_count_terminal;
      }

      this.changeCountTerminal();
    },
    toggleActiveTip: function (i) {
      // Зміна періоду оплати

      this.tipsnew.forEach((tip) => {
        tip.isActive = false;
      });

      this.StaticTip = this.tipsnew[i].value;
      this.staticMonths = this.tipsnew[i].months;
      this.tipsnew[i].isActive = !this.tipsnew[i].isActive;
    },
    reset: function () {
      // Скидання значень до значень за замовчуванням

      this.tradepoint_second = 1;
      this.product_second = 1;
      this.routings_second = 1;
      // this.isEmpty = !this.isEmpty;
      this.tipsnew.forEach((tip) => {
        tip.isActive = false;
      });
      this.toggleActiveTip(0)
    },
    changeCountTerminal(){
      // Якщо кількість тарифікованих торгових точок більше, ніж терміналів
      if (this.tradepoint_second > this.terminal_second) {
        this.tariffItem_second = this.tradepoint_second;
      } else {
        this.tariffItem_second = this.terminal_second;
      }

    },
    tariff_name: function (get_tariff){
      // Визначення назви тарифу

      switch (this.get_tariff()) {
          case 0:
          return "FREE";
          case 1:
          return "ВUSINESSMAN";
          case 2:
          return "CORPORATION";
          case 3:
          return "BIGBOSS";
          case 4:
          return "ENTERPRISE";
      }
      return get_tariff
    },
    tariff_cash:function (thistariff){
      // Визначення вартості тарифу

      switch(thistariff){
          case 0:
        return "0";
          case 1:
        return "20";
          case 2:
        return "35";
          case 3:
        return "50";
          case 4:
        return "50";
      }
    },
    get_tariff:function (){
      // Визначення тарифу в залежності від кількості торгових точок, товарів і технічних карт

      let tariff = 0;

        if (this.product_second >= 1 && this.product_second <= 700) {
            if (tariff < 1) {
                tariff = 1;
            }

            // Якщо у клієнта більше однієї торгової точки або більше одного терміналу,
            // встановлюємо йому другий тариф
            if (this.tradepoint_second > 1 || this.terminal_second > 1) {
                tariff = 2;
            }
        } else if (this.product_second >= 701 && this.product_second <= 1150) {
            if (tariff < 2) {
                tariff = 2;
            }
        } else if (this.product_second >= 1151 && this.product_second <= 3500) {
            if (tariff < 3) {
                tariff = 3;
            }
        } else if (this.product_second >= 3501) {
            if (tariff < 4) {
                tariff = 4;
            }
        }

        if (this.routings_second >= 1 && this.routings_second <= 70) {
            if (tariff < 1) {
                tariff = 1;
            }

            // Затичка для того, щоб перемкнути тих, у кого 2 точки, на другий тариф
            if (this.tradepoint_second > 1 || this.terminal_second > 1) {
                if (tariff < 2) {
                    tariff = 2;
                }
            }
        } else if (this.routings_second >= 71 && this.routings_second <= 135) {
            if (tariff < 2) {
                tariff = 2;
            }
        } else if (this.routings_second >= 121 && this.routings_second <= 300) {
            if (tariff < 3) {
                tariff = 3;
            }
        } else if (this.routings_second >= 301) {
            if (tariff < 4) {
                tariff = 4;
            }
        }

        return tariff;
    },

    getsumm: function (num_tariff) {
        // Обчислення суми для обраного тарифу

        // var ex_tariff = 0;
        // var ex_summ = 0;
        num_tariff = this.get_tariff();
        const cash_tariff = this.tariff_cash(num_tariff);
        var bases;
        var ex_bases;
        var result;
        var objs = {};
        var summ_products_plus;
        var summ_cookcard_plus;
        
        switch (num_tariff) {
            case 0:
                // Тариф 0 - FREE

                return objs;
            case 1:
                // Тариф 1 - ВUSINESSMAN
                // На скільки товарів більше

                if(this.product_second > 600){
                    summ_products_plus = (this.product_second - 600) * 0.033;
                } else {
                    summ_products_plus = "0";
                }

                // На скільки техкарт більше
                if(this.routings_second > 60){
                    summ_cookcard_plus = (this.routings_second - 60) * 0.33;
                } else {
                    summ_cookcard_plus = "0"
                }

                ex_bases = Number(cash_tariff) + Number(summ_products_plus) + Number(summ_cookcard_plus);
                bases = (ex_bases * this.data.coeficient ) * this.data.currencyinfo.ratio;
                objs["month"] = bases.toFixed(0);
                return objs;
            case 2:
                // Тариф 2 - CORPORATION
                // На скільки товарів більше

                if(this.product_second > 1000){
                    summ_products_plus = (this.product_second - 1000) * 0.03;
                } else {
                    summ_products_plus = 0;
                }

                // На скільки техкарт більше
                if(this.routings_second > 120){
                    summ_cookcard_plus = (this.routings_second - 120) * 0.23;
                } else {
                    summ_cookcard_plus = 0
                }

                // Базова ціна + товари + техкарти
                // ex_tariff = 2;

                ex_bases = Number(cash_tariff) + Number(summ_products_plus) + Number(summ_cookcard_plus);
                bases = (ex_bases * this.data.coeficient ) * this.data.currencyinfo.ratio;
                result = bases.toFixed(0) / 100 * 70 * (Number(this.tariffItem_second) - 1 ) + bases;
                objs["month"] = result.toFixed(0);
                return objs;
            case 3:
                // Тариф 3 - BIGBOSS
                // На скільки товарів більше

                if(this.product_second > 3500){
                    summ_products_plus = (this.product_second - 3500) * 0.014;
                } else {
                    summ_products_plus = 0;
                }

                // На скільки техкарт більше
                if(this.routings_second > 300){
                    summ_cookcard_plus = (this.routings_second - 300) * 0.16;
                } else {
                    summ_cookcard_plus = 0
                }

                // Базова ціна + товари + техкарти
                ex_bases = Number(cash_tariff) + Number(summ_products_plus) + Number(summ_cookcard_plus);
                bases = (ex_bases * this.data.coeficient ) * this.data.currencyinfo.ratio;
                result = bases.toFixed(0) / 100 * 70 * (Number(this.tariffItem_second) - 1 ) + bases;
                objs["month"] = result.toFixed(0);
                return objs;
            case 4:
                // Тариф 4 - ENTERPRISE
                // На скільки товарів більше

                if(this.product_second > 3500){
                    summ_products_plus = (this.product_second - 3500) * 0.012;
                } else {
                    summ_products_plus = 0;
                }

                // На скільки техкарт більше
                if(this.routings > 300){
                    summ_cookcard_plus = (this.routings_second - 300) * 0.14;
                } else {
                    summ_cookcard_plus = 0
                }

                // Базова ціна + товари + техкарти
                ex_bases = Number(cash_tariff) + Number(summ_products_plus) + Number(summ_cookcard_plus);
                bases = (ex_bases * this.data.coeficient ) * this.data.currencyinfo.ratio;
                result = bases.toFixed(0) / 100 * 70 * (Number(this.tariffItem_second) - 1 ) + bases;
                objs["month"] = result.toFixed(0);
                return objs;
    }},
    getSumMonth: function () {
        // Виведення суми за місяць
        var sum = 0;
        sum = (this.getsumm().month - (this.getsumm().month / 100 * this.StaticTip)).toFixed(0)
        return sum
    },
    getSumDiscont: function() {
        // Виведення суми знижки

        var summd = 0;
        if (this.tradepoint_second != 0 && this.product_second != 0 && this.routings_second != 0) {
        summd = ((this.getsumm().month / 100 * Number(this.staticMonths)) * Number(this.StaticTip));
        }
        return  summd.toFixed(0);
    },
    getSumNotSale: function () {
        // Виведення суми без знижки
        var sum = 0;
        sum = (this.getsumm().month * this.staticMonths).toFixed(0)
        return sum
    },
    getSumTotal: function () {
        // Виведення загальної суми
        var sum = 0;
        sum = (this.getSumNotSale() - this.getSumDiscont()).toFixed(0)
        return sum
    },
    getNameTariff: function () {
        // Виведення назви тарифу
        return this.tariff_name(this.get_tariff())
    },
    getLangCountry(item){
      return getNameCountry(item)
    }
  },
  computed: {
  },
};
</script>

<style scoped>
.text-right {
    text-align: right;
}
:root {
  --veryDarkCyan: #374158;
  --darkGrayishCyan: hsl(186, 14%, 43%);
  --lightGrayishCyan: hsl(162deg 39% 42%);
  --white: hsl(0, 0%, 100%);
  --strongCyan: hsl(162deg 40% 41%);
}
.calculator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  /* margin: 50px auto; */
}

.block-calc {
  background-color: #374158;
  padding: 50px;
  border-radius: 41px;
}

.calculator .bill {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  font-size: 17px;
}

.calculator .bill input {
  width: 100%;
  max-width: 400px;
  padding: 8px;
  text-align: right;
  color: #374158;
  font-weight: 700;
  font-size: 24px;
  border-radius: 4px;
  border: none;
  margin-top: 8px;
  /* background-image: url("./images/icon-dollar.svg"); */
  background-repeat: no-repeat;
  background-position-x: 8px;
  background-position-y: center;
  background-size: 16px;
}

.calculator .tip {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  margin-top: 24px;
  font-size: 17px;
  /* margin-left: 67px; */
}

.calculator .en_div {
  width: 100%;
  /* width: 500px; */
  max-width: 400px;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 12px;
  justify-content: center;
}

.discont_desc{
  color: #5d777c;
  font-size: 14px;
}
.calculator .en_div button {
  padding: 12px;    
  background-color: #374158;
  color: #41957c;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  font-size: 20px;
  /* width: 200px; */
}
.calculator .en_div button.active {
    background-color: #41957c;
    color: #374158;
}
.calculator .en_div input {
  font-size: 24px;
  font-weight: 700;
  color: #374158;
  text-align: center;
  border-radius: 4px;
  border: none;
}
.calculator .bill input:focus {
  background: white;
  color: #374158;
}
.calculator .people {
  width: 100%;
  max-width: 400px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  color: var(--darkGrayishCyan);
}

/*  */

.result {
  /* background-color: #374158; */
  padding: 3%;
  width: 100%;
  max-width: 500px;
  margin-left: 12px;
  border-radius: 16px;
  min-height: 60vh;
  position: relative;
  /* box-shadow: 7px 7px 33px rgb(0, 0, 0, 0.3); */
}

.result div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result div div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.result div:nth-child(2) {
  margin-top: 24px;
}

.result div div p {
  color: #41957c;
  font-weight: 700;
  font-size: 20px;
}

.result div div span {
  color: var(--darkGrayishCyan);
}

.result .value {
  font-size: 48px;
  color: #3f9279;
  font-weight: 700;
}

.result .reset-btn {
  background-color: #3f9279;
  color: #374158;
  border: none;
  width: 88%;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  bottom: 8%;
  left: 6%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
}
.reset-btn a {
  color: #374158;
  text-decoration: none;
}
#getpred {
  bottom: 18%;
  display:none;
}

@media (max-width: 840px) {
  /* .container {
    flex-direction: column;
  } */
  .result {
    margin-top: 24px;
    margin-left: 0;
  }
}

@media (max-width: 560px) {
  /* .container {
    padding: 24px;
    margin-top: 24px;
  } */
  .calculator div {
    grid-template-columns: 48% 48%;
  }
  .result {
    min-height: 45vh;
    padding: 8%;
  }
  .result .value {
    font-size: 32px;
  }
}
.ityped {
  text-transform: capitalize;
}
.quantity_inner * {
    box-sizing: border-box;
}
.quantity_inner {
  display: flex!important;
  justify-content: center;
  grid-template-columns: 100%!important;
}
.quantity_inner .bt_minus,
.quantity_inner .bt_plus,
.quantity_inner .quantity {
  color: #41937b;
  height: 47px;
  width: 60px;
  padding: 0;
  margin: 7px 0px;
  border-radius: 10px;
  border: 4px solid #41937b;
  font-size: 26px;
}
.quantity_inner .bt_minus {
  color: white;
  border: 4px solid #e82b2b;
  background-color: #e82b2b;
}
.quantity_inner .bt_plus {
  background: #41937b;
  color: white;
}

</style>