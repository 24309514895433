function copy(item){
    // Перевіряємо чи то Сафарі (робиться копіювання через API ClipBoardItem та асинхронні операції)
    if(window.navigator.userAgent.indexOf('Version') != -1 && window.navigator.userAgent.indexOf('Safari') != -1) {
        async function someAsyncMethod() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve('resolved');
                }, 10);
            }); // та сама асинхронна операція для розгону
        }
        const clipboardItem = new ClipboardItem({
            'text/html': someAsyncMethod().then((result) => { // копіюємо html, щоб зберегти стилі тексту

            /**
             * We have to return an empty string to the clipboard if something bad happens, otherwise the
             * return type for the ClipBoardItem is incorrect.
             */
            if (!result) {
                return new Promise(async (resolve) => {
                    resolve(new Blob[``]()) // якшо результату нема, то пустий Блоб повертаємо
                })
            }

            const copyText = item
                return new Promise(async (resolve) => {
                    resolve(new Blob([copyText])) // якщо все ок, то копіюємо текст і зберігаємо в блоб
                })
            }),

        })
        try {
            // Now, we can write to the clipboard in Safari
            navigator.clipboard.write([clipboardItem]) // записуємо в буфер
            return true
        } catch {
            return false
        }
    }
    try {
        navigator.clipboard.writeText(item);
        return true
    } catch {
        return false
    }
}

// how to use in vue files

    // copy(item){
    //     // копіювання
    //     if(copy(item) == true) {
    //         this.$toast.success(this.$t('Copied'));
    //     } else {
    //         this.$toast.error(`Fucking mistake, contact Borys`);
    //     }
    //     return
    // },

// how to use in vue files

export {copy}