<template>
    <b-row>
        <b-col lg="12" style="background: var(--vz-body-bg);">
            <b-tabs 
                v-model="tabIndex"
                pills 
                content-class="mt-3"
            >
                <b-tab :title="$t('Main')" active>
                    dsdds
                </b-tab>
            </b-tabs>
        </b-col>
    </b-row>
</template>

<script>
export default{
    data(){
        return{
            form:''
        }
    }
}
</script>