<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label class="form-label">{{ $t('specify_date_timeNeedCall') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setHour(new Date().getHours() + 1)" style="margin-right: 5px;">{{ $t('AfterOneHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setHour(new Date().getHours() + 2)" style="margin-right: 5px;">{{ $t('AfterTwoHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setHour(new Date().getHours() + 3)" style="margin-right: 5px;">{{ $t('AfterThreeHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setDay('AtLunch')" style="margin-right: 5px;">{{ $t('AtLunch') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setDay('afterAWeek')" style="margin-right: 5px;">{{ $t('AfterAWeek') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setDay('tomorrow')" style="margin-right: 5px;">{{ $t('Tomorrow') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setDay('AtNight')">{{ $t('AtNight') }}</span>
                </div>
                <div class="row g-2 list_filtr" >
                    <flatpickr v-model="date" :config="config" class="form-control border-0 dash-filter-picker shadow" @onChange="change"></flatpickr>
                    <setTime @setHour="setHour" @setMinute="setMinute" :timeProps="this.form.delayedTime" :key="this.currentIdx"></setTime>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label">{{ $t('Comment') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('NoAnswer')" style="margin-right: 5px;">{{ $t('NoAnswer') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('AskedToDialLater')" style="margin-right: 5px;">{{ $t('AskedToDialLater') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('Busy')" style="margin-right: 5px;">{{ $t('Busy') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('autoAnswer')" style="margin-right: 5px;">{{ $t('autoAnswer') }}</span>
                </div>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium"  @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="toDelay"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import setTime from '@/components/setTime'
import dialogBox from '@/components/modal/dialog'
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { storeS } from '@/store';

export default{
    components: {
        dialogBox,
        flatpickr: Vueflatpickr,
        setTime
    },
    props: ['id'],
    data(){
        return{
            currentIdx: 0,
            form: {
                comment: '',
                delayedTime: '',
                delayedDate: '',
            },
            date: "today",
            config: {
                mode: "single",
                minDate: "today",
                altInput: true,
                locale: "",
                enableTime: false,
                dateFormat: "Y-m-d",
            },
        }
    },
    created() {
        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }
        this.time = String(new Date()).split(' ')[4].split(':')[0] + ':' + String(new Date()).split(' ')[4].split(':')[1]
        this.form.delayedDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' +  new Date().getDate()
        this.form.delayedTime = new Date().getHours() + ':' + new Date().getMinutes()
    },
    methods: {
        setHour(e){
            this.time = String(new Date(new Date().setHours(e)).getHours()) + ':' + this.time.split(':')[1]
            this.form.delayedTime = this.time
            this.currentIdx++
        },
        setMinute(e) {
            this.time = this.time.split(':')[0] + ':' + String(new Date(new Date().setMinutes(e)).getMinutes())
            this.form.delayedTime = this.time
            this.currentIdx++
        },
        setDay(a){
            if(a == 'tomorrow') {
                this.form.delayedDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() + 1)
                this.time = '10:00'
                this.form.delayedTime = this.time
                this.date = this.form.delayedDate
                this.currentIdx++
            } else if(a == 'AtLunch') {
                this.time = '14:00' 
                this.form.delayedTime = this.time
                this.currentIdx++
            } else if(a == 'AtNight') {
                this.time = '16:00' 
                this.form.delayedTime = this.time
                this.currentIdx++
            } else if(a == 'afterAWeek') {
                this.time = '9:00' 
                this.form.delayedDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() + 7)
                this.date = this.form.delayedDate
                this.form.delayedTime = this.time
                this.currentIdx++
            }
        },
        change(e, e1, e2){
            // var arrDate = String(this.date)
            // arrDate = arrDate.split(' ')
            this.form.delayedDate = e1;
            this.currentIdx++
            console.log(this.form.delayedDate, this.date)
            if(this.form.delayedDate) {
                this.form.delayedTime = this.date.split(' ')[1].split(':')[0] + ':' + this.date.split(' ')[1].split(':')[1]
            }
            // this.form.delayedTime = arrDate[1];
        },
        toDelay(){
            this.form.delayedDate = this.date.split(' ')[0]
            if(this.form.comment == '') {
                this.$toast.error(this.$t('enterComment'))
            } else if(this.form.delayedTime == ''){
                this.$toast.error(this.$t('specifyDateTime'))
            } else if(this.form.delayedDate == ''){
                this.$toast.error(this.$t('specifyDateTime'))
            } else {
                this.$emit('toDelay', this.id, this.form)
                this.$emit('close')
            }
        }
    }
}
</script>