<template>
    <!-- модальное окно просмотра заявки -->
    <modal :key="componentKey" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @close="this.$emit('close')" :styleAdd="'backdrop-filter: blur(0px) !important;'">
        <template v-slot:title>{{ form.status == 'new' ? $t('newApplication') : $t('Application') }} #{{ this.form.requestId }}</template>
        <template v-slot:status>
            <span v-if="form.action" :class="`badge badge ${this.mutateType(this.form.action).color} align-middle fs-14`"><i :class="this.mutateType(this.form.action).icon"></i></span>
            <span :style="form.action ? `margin-left: 5px;` : ''" :class="`badge badge ${this.mutateStatus(this.form.status, this.form).color} align-middle fs-14`">{{this.mutateStatus(this.form.status, this.form).name}}</span>
        </template>
        <template v-slot:head-button>
            <!-- Дропдаун, є перевірки 
                - По статусу заявки
                - По пермсам
                - На того, хто взяв в роботу
                - Чи є статус "зателефонувати зараз"
            -->
            <div class="dropdown" v-if="(form.status !== 'deleted' || this.form.status =='callNow' && (perms[103] || perms[102] || perms[105] || perms[2000]) && (form.inJobWorkerId == this.user.userid || form.inJobWorkerId == null))">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <button class="dropdown-item"  v-if='(perms[103] && (form.status === "wait_comment" || form.status === "jobs") && (form.inJobWorkerId == this.user.userid || form.inJobWorkerId == null)) || perms[2000]' @click="showModalClose = true">{{ $t('close') }}</button> <!-- закрыть заявку -->
                    <button class="dropdown-item" v-if="((form.status === 'jobs' || form.status === 'delayed' || form.status === 'wait_comment') && (form.inJobWorkerId == this.user.userid || form.inJobWorkerId == null)) || this.form.status=='callNow' || perms[2000]" @click="showModalDelay = true">{{ $t('postpone')}}</button>  <!-- отложить заявку -->
                    <button class="dropdown-item" v-if="((form.status === 'jobs'  || form.status === 'wait_comment') && (perms[102]|| perms[2000])) " @click="showModalTransfer = true">{{ $t('Submit')}}</button> <!-- передать заявку -->
                    <button class="dropdown-item" v-if="(perms[103] || perms[2000])" @click="dialogshow = true">{{ $t('Delete') }}</button> <!-- удалить заявку -->
                    <button class="dropdown-item" v-if="(perms[105] && form.status !== 'canceled' && this.form.type !== 'request' && form.status !== 'closed' && form.status !== 'callNow' && form.type=='requestByCall') || perms[2000] || this.user.workerJob == 'supportmanager' || this.user.workerJob == 'manager'" @click="callNow(this.form.requestId)">{{ $t('CallNow') }}</button>
                </div>
            </div>
        </template>
        <template v-slot:body>
            <!-- Якщо заявка перенесена, то буде альорт -->
            <b-alert v-if="form.status == 'transfered'" show variant="warning" class="text-center">
                <h5 class="alert-heading" v-if="this.user.userid != form.transferWorkerId">
                    <b>{{ this.form.inJobWorkerName }} {{ $t('submittedRequest') }} {{ nameWorker(form.transferWorkerId) }}</b>
                </h5>
                <h5 class="alert-heading" v-else>
                    <b>{{ this.form.inJobWorkerName }} {{ $t('sentYouRequest') }}</b>
                </h5>
            </b-alert>
            <b-row>
                <b-col lg="4">
                    <!-- Блок "Про заявку" -->
                    <aboutBlock 
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                </b-col>

                <b-col lg="4">
                    <!-- Блок "Клієнт" -->
                    <clientBlock
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                </b-col>

                <b-col lg="4">
                    <!-- Блок "ФОП" -->
                    <fopBlock
                        :obj="this.form"
                    />
                </b-col>

                <b-col lg="4" v-if="form.inJobWorkerId != null">
                    <!-- Блок "В роботі" -->
                    <jobsBlock
                        :obj="this.form"
                    />
                </b-col>

                <b-col lg="4" v-if="form.closeWorkerId != null">
                    <!-- Блок "Закриття" -->
                    <closedBlock 
                        :obj="this.form"
                    />
                </b-col>
                <b-col lg="4" v-if="form.cancelWorkerId != null">
                    <!-- Блок "Відміна" -->
                    <canceledBlock 
                        :obj="this.form"
                    />
                </b-col>
            </b-row>
                
        </template>
        <template v-slot:footer-bottom>

                <button 
                    type="button" 
                    v-if="(form.status != 'new' && (perms[103] || perms[2000]) && form.inJobWorkerId == this.user.userid)"
                    @click="showModalCancel = true" 
                    class="btn btn-danger r waves-effect waves-light">
                    {{ $t('cancel') }}
                </button> <!-- отмена заявки -->

                <button 
                    type="button" 
                    v-if="(form.status != 'new' && (perms[103] || perms[2000]) && form.inJobWorkerId == this.user.userid)"
                    @click="showModalRepCancel = true" 
                    class="btn btn-danger r waves-effect waves-light">
                    {{ $t('cancelReport') }} 
                </button> <!-- додати репорт на скасування заявки -->

                <button 
                    type="button" 
                    v-if="this.form.inJobWorkerName === this.user.name && (this.form.status=='jobs' || this.form.status=='wait_comment')"
                    @click="showModalClose = true"
                    class="btn btn-success r waves-effect waves-light">
                    {{ $t('done') }}
                </button> <!-- выполнить/закрыть заявку -->

                <button 
                    type="button"
                    v-if="perms[104] && (form.status == 'new'  || this.form.status=='callNow' || this.form.status=='delayed')"
                    @click="toWork()"
                    :class="`btn btn-success ss waves-effect waves-light`">
                    {{ $t('takeToWork') }}
                </button> <!-- взять в работу заявку -->
                
                <button 
                    type="button"
                    v-if="perms[114] && form.status == 'transfered' && form.transferWorkerId == this.user.userid"
                    @click="rejectTransfer()"
                    :class="`btn btn-danger ss waves-effect waves-light`">
                    {{ $t('Refusal') }}
                </button> <!-- відмовитись від передачі заявки -->

                <button 
                    type="button"
                    v-if="perms[104] && form.status == 'transfered' && form.transferWorkerId == this.user.userid"
                    @click="confirmTransfer()"
                    :class="`btn btn-success ss waves-effect waves-light`">
                    {{ $t('ConfirmTransfer') }}
                </button> <!-- підтвердити передачу -->

        </template>
    </modal>

    <!-- показати не зареєстровані -->
    <!-- <viewUnRegist
        v-if="showViewUnRegist == true"
        :objCard="this.objClient"
        @close = "showViewUnRegist = false"
    ></viewUnRegist> -->
    <!-- показати не зареєстровані -->
    
    <!-- модалка отмены -->
    <modalCancel 
        v-if="showModalCancel == true" 
        @close="showModalCancel = false"
        :id="this.form.requestId"
        @toCancel="toCancel"
    />
    <!-- модалка отмены -->

    
    <!-- модалка репорта скасування -->
    <modalCancelReport 
        v-if="showModalRepCancel == true" 
        @close="showModalRepCancel = false"
        :id="this.form.requestId"
        @toCancelReport="toCancelReport"
    />
    <!-- модалка репорта скасування -->

    <!-- отложить заявку -->
    <modalDelay 
        v-if="showModalDelay"
        @close="showModalDelay = false"
        :id="this.form.requestId"
        @toDelay="toDelay"
    />
    <!-- отложить заявку -->

    <!-- закрити заявку -->
    <modalClose 
        v-if="showModalClose == true"
        @close="showModalClose = false"
        :id="this.form.requestId"
        @toClose="toClose"
    />
    <!-- закрити заявку -->

    <!-- передати заявку -->
    <modalTransfer 
        v-if="showModalTransfer == true"
        @close="showModalTransfer = false"
        :id="this.form.requestId"
        :obj = "this.form"
        @transferRequest="transferRequest"
    />
    <!-- передати заявку -->

    <dialodel
        v-if="this.dialogshow == true" 
        @close="this.dialogshow = false" 
        :obj="item" 
        @remove="remove" />

</template>

<script>
import fopBlock from './blocks/fopInfo'
import viewUnRegist from '@/views/accounts/unregistered/view/index'
import modal from '@/components/modal/modal-lg'
import aboutBlock from './blocks/about'
import clientBlock from './blocks/client'
import jobsBlock from './blocks/jobs'
import closedBlock from './blocks/closed'
import canceledBlock from './blocks/canceled'
import viewboxnew from '@/views/accounts/allAccounts/viewnew/index.vue'
// import viewboxnew from '@/views/clientnew/index.vue'
import dialogBox from '@/components/modal/dialog'
import dialodel from '@/components/globaltable/actions/dialogdelete.vue'
import modalCancel from './actions/cancel'
import modalClose from './actions/close'
import modalDelay from './actions/delay'
import modalTransfer from './actions/transfer'
import modalCancelReport from './actions/cancelReport'
import { ColdCalls, SuperAdmin } from "@/API.js"
import { storeS } from '@/store.js'
import Multiselect from "@vueform/multiselect";
import { mutateStatus, mutateTypeRequest } from '@/usabilityScripts/globalMutate.js'
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new ColdCalls();
let apiAdmin = new SuperAdmin()

export default ({
    data(){
        return {
            modals: [],
            showViewUnRegist: false,
            showModalRepCancel: false,
            showModalCancel: false,
            showModalDelay: false,
            toWorkerName: '',
            modalConfirmSecond: false,
            modalConfirm: false,
            toWorker: '',
            modalDialogSnooze: false,
            date1:"",
            modalDialogThrow: false,
            checked: false,
            modalDialog: false,
            dialogshow: false,
            dateAndTime: {
                delayedTime: this.currentDateForApplic().minTime,
                delayedDate: this.currentDateForApplic().minDate,
            },
            commenttt: {
                comment: "",
            },
            mainmodal: false,
            dateTimeConfig: {
                enableTime: true,
                dateFormat: "d.m.y H:i",
            },
            testshowModalclient: false,
            objClient: {},
            form: {
                userPhone: "",
                // user_email: "",
                priority: "",
                // received: "",
                status: "",
            },
            showModal: false,
            showModalClose: false,
            showModalTransfer: false,
            number: "",
            listWorkers: [],
            componentKey: '',
            count: 0
        }
    },
    props: ["phone", "shortapplic", 'ifPrevopen', 'ifNextopen', 'closeOne', 'client'],
    components: {
        Multiselect,
        modal,
        dialogBox,
        viewboxnew,
        dialodel,
        aboutBlock,
        clientBlock,
        jobsBlock,
        closedBlock,
        modalCancel,
        modalClose,
        modalDelay,
        modalTransfer,
        modalCancelReport,
        viewUnRegist,
        canceledBlock,
        fopBlock
    },
    created(){        
        this.form = this.shortapplic;
        storeS.workers.forEach( (el)=> {
            if(el.workerJob === 'managercallcenter') {
                this.listWorkers.push({
                    value: el.workerId,
                    label: el.workerName
                })
            } //список работников
        })

        storeS.checkModal.type = 'request';
        storeS.checkModal.id = this.form.requestId;
    },
    methods: {
        // відміна заявки
        toCancel(id, form){
            apiServe.cancelCall(id, form).then(result=>{
                if(result.status=='done') {
                    this.$toast.success(this.$t('canceled'))
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка відміна холодного дзвінка'; 
                    var statusRequest = result.status;
                    var api = 'cancelCall';
                    var fileRequest = 'src/views/calls/cold/view/viewapplic.vue';
                    var params = {id: id, form: form};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        toCancelReport(id, form) {
            apiServe.addReportOnCancelCall(id, form).then(result=>{
                if(result.status=='done') {
                    this.$toast.success(this.$t('report_sent'))
                    this.$emit('getdata')
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка додавання репорту на відміну холодного дзвінка'; 
                    var statusRequest = result.status;
                    var api = 'cancelCall';
                    var fileRequest = 'src/views/calls/cold/view/viewapplic.vue';
                    var params = {id: id, form: form};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        callNow(id) {
            apiServe.callNowByCall(id).then(result=>{
                if(result.status=='done') {
                    this.$emit('close')
                    this.$toast.success(this.$t('successfully'))
                    this.$emit('getdata')
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка додавання репорту на відміну холодного дзвінка'; 
                    var statusRequest = result.status;
                    var api = 'callNowByCall';
                    var fileRequest = 'src/views/calls/cold/view/viewapplic.vue';
                    var params = {id: id};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        call(e) {
            localStorage.setItem('actualCall', e)
            this.eventBus.emit('actualCall', {
                status: true
            })
        },
        closeModal() {
            console.log('lo')
            this.$emit('close');
            // this.$emit('getdata');
        },
        prevOpen(){
            //пред заявка
            this.$emit('close');
            this.$emit('prevOpen', this.form.requestId)
        },
        nextOpen(){
            //след заявка
            this.$emit('close');
            this.$emit('nextOpen', this.form.requestId)
        },
        updateCard(){
            //обновить данные
            apiServe.getCall(this.form.requestId).then(result => {
                if(result.status == 'done'){
                    this.form = result.data;
                    this.componentKey += 1
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка отримання холодного дзвінка'; 
                    var statusRequest = result.status;
                    var api = 'getCall';
                    var fileRequest = 'src/views/calls/cold/view/viewapplic.vue';
                    var params = {id: this.form.requestId};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        toClose(id, obj) {
            //закрыть заявку
            apiServe.closeCall(id, obj).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('closed'))
                    this.updateCard()
                    this.$emit('getdata')
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка закриття холодного дзвінка'; 
                    var statusRequest = result.status;
                    var api = 'closeCall';
                    var fileRequest = 'src/views/calls/cold/view/viewapplic.vue';
                    var params = {id: id, form: form};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        toWork: function(){
            //взять в работу
            try {
                navigator.clipboard.writeText(this.form.userPhone)
                this.$toast.success(this.$t('Copied'));
            } catch {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            apiServe.addCallInJob(this.form.requestId).then(res => {
                if(res.status === 'done') {
                    // this.$emit('toWork') //инициализация события
                    this.updateCard();
                    this.$toast.success(this.$t('statusInWork'));
                    this.$emit('getdata')
                    // this.getClient(this.form.userPhone);
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка додавання холодного дзвінка в роботу'; 
                    var statusRequest = result.status;
                    var api = 'addCallInJob';
                    var fileRequest = 'src/views/calls/cold/view/viewapplic.vue';
                    var params = {id: this.form.requestId};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                } 
            })
        },
        toDelay: function(id,dateAndTime) {
            let  form = {
                delayedTime: dateAndTime.delayedTime,
                delayedDate: dateAndTime.delayedDate,
                comment: dateAndTime.comment
            }
            apiServe.delayCall(id, form).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('Delayed'))
                    this.showModalDelay = false
                    this.$emit('close') //отмена заявки
                    this.$nextTick().then(this.$emit('toSnooze'))
                } else {
                    if(result.error[0] == "Request already exists") {
                        this.$toast.error(this.$t('aplic_already_yet'))
                    } else {
                        this.$toast.error(this.$t('error'))
                    }
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка редагування холодного дзвінка'; 
                    var statusRequest = result.status;
                    var api = 'delayCall';
                    var fileRequest = 'src/views/calls/cold/view/viewapplic.vue';
                    var params = {id: id, form: form};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
           
        },
        transferRequest(id, toWorker, obj){
            apiServe.transferCall(id, toWorker, obj).then(result => {
                if(result.status === 'done') {
                    this.$toast.success('TransferRequestSent');
                    this.$emit('close') //передача заявки
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка передачі холодного дзвінка'; 
                    var statusRequest = result.status;
                    var api = 'transferCall';
                    var fileRequest = 'src/views/calls/cold/view/viewapplic.vue';
                    var params = {id: id, form: obj, toWorker: toWorker};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        confirmTransfer(){
            apiServe.fixationTransferCall(this.form.requestId, this.form.transferWorkerId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('threw'));
                    this.$emit('close') //передача заявки
                    this.$emit('getdata')
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка прийняття холодного дзвінка'; 
                    var statusRequest = result.status;
                    var api = 'confirmTransfer';
                    var fileRequest = 'src/views/calls/cold/view/viewapplic.vue';
                    var params = {id: this.form.requestId, toWorker: this.form.transferWorkerId};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
            
        },
        rejectTransfer(){
            apiServe.rejectTransferCall(this.form.requestId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('didn`t accept'));
                    this.$emit('close')
                    this.$emit('getdata')
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка відхилення холодного дзвінка'; 
                    var statusRequest = result.status;
                    var api = 'rejectTransferCall';
                    var fileRequest = 'src/views/calls/cold/view/viewapplic.vue';
                    var params = {id: this.form.requestId};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
            
        },
        remove: function(){
            //удалить
            apiServe.deleteCall(this.form.requestId).then(result=>{
                if(result.status=='done') {
                    this.$toast.success(this.$t('Removed'))
                    this.$emit('getdata')
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка видалення холодного дзвінка'; 
                    var statusRequest = result.status;
                    var api = 'deleteCall';
                    var fileRequest = 'src/views/calls/cold/view/viewapplic.vue';
                    var params = {id: this.form.requestId};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
            
        },
        getClient(e) {
            
            //открыть карточку клиента
            if(this.count !== 1) {
                this.count = 1
                apiAdmin.getCard(e).then(result => {
                    if(result.status == 'done'){
                        this.objClient = result.data
                        if(result.data.accountId == false) {
                            this.modals.push({
                                form: this.form,
                                name: 'applic',
                            })
                            this.$emit('openUnregistered',this.objClient, this.modals)
                            this.count = 0
                            // this.$emit('close')
                        } else {
                            this.modals.push({
                                form: this.form,
                                name: 'applic',
                            })
                            this.$emit('openRegistered',this.objClient, this.modals)
                            this.count = 0
                        }
                        
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var errorText = 'Помилка отримання картки клієнта'; 
                        var statusRequest = result.status;
                        var api = 'getCard';
                        var fileRequest = 'src/components/calendar/addEvent.vue';
                        var params = this.formEvent;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
            
        },
        currentDate() {
            var date = new Date();
            var dateStr =
            ("00" + date.getMonth()).slice(-2) + "." +
            ("00" + (date.getDate() + 1)).slice(-2) + "." +
            date.getFullYear() + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
            return dateStr
        },
        currentDateForApplic() {
            return {
                minTime: "" + new Date().getHours()+':'+new Date().getMinutes(),
                minDate: "" +  new Date().getFullYear() + '-' + this.mutateData(String(new Date()).split(' ')[1]) + '-' +  new Date().getDate()
            }
            //дата для переноса
        },
        nameWorker(item){
            return nameWorker(item)
        },
        mutateData(month) {
            switch(month){
                case "Jan": 
                    month = '1'
                    break;
                case "Feb": 
                    month = '2'
                    break;
                case "Mar": 
                    month = '3'
                    break;
                case "Apr": 
                    month = '4'
                    break;
                case "May": 
                    month = '5'
                    break;
                case "Jun": 
                    month = '6'
                    break;
                case "Jul": 
                    month = '7'
                    break;
                case "Aug": 
                    month = '8'
                    break;
                case "Sep": 
                    month = '9'
                    break;
                case "Oct": 
                    month = '10'
                    break;
                case "Nov": 
                    month = '11'
                    break;
                case "Dec": 
                    month = '12'
                    break;
            }
            return month //по месяцам
        },
        mutateStatus(e, obj){
            return mutateStatus(e, obj);
        },
        mutateType(e){
            return mutateTypeRequest(e)
        }
    },
    mounted() {

        console.log(this.scale)

        this.eventBus.off('updateRequest');
        this.eventBus.on('updateRequest', (status) => { // оновити картку заявки
            if(status == true) {
                this.updateCard();
            }
        })

        // this.eventB.off('sendToApplic')
        // this.eventB.on('sendToApplic', status => {
        //     if(status.status.requestId == this.form.requestId) {
        //         console.log(status)
        //     }
        // })
    },
    computed:{
        perms() {
            return storeS.perms
        },
        user() {
            return storeS.userbase
        },
        workers(){
            return storeS.workers //база работников
        },
        scale(){
            return window.devicePixelRatio
        }
    }
})
</script>


<style scoped>
.copybtn {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity .3s;
}
.c_block:hover .copybtn {
    opacity: 1;
    transition: opacity .3s;
}

.copybtn:checked {
    width: 500px;
}
</style>
