<template>
    <!-- 
        Модальне вікно перегляду надісланого смс
    -->

    <modal>
        <template v-slot:title>SMS #{{ form.jsonData.sms_id }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            
            <b-row>
                <!-- Номер телефону --> 
                <b-col lg="6">
                    <h4 class="fs-15">{{ $t('phone_number') }}</h4>
                    <input type="number" class="form-control" id="contactNumber" v-model="this.form.jsonData.user_phone" disabled>
                </b-col>

                <!-- Відправник -->
                <b-col lg="6">
                    <h4 class="fs-15">{{ $t('Sender') }}</h4>
                    <input type="text" class="form-control" :value="mutateName(this.form.account_id)" disabled>
                </b-col>
            </b-row>
            
            <b-row class="mt-3">
                <!-- Дата та час -->
                <b-col lg="6">
                    <h4 class="fs-15">{{ $t('date_time') }}</h4>
                    <input type="text" class="form-control"  v-model="this.form.datetime" disabled>
                </b-col>

                <!-- IP юзера -->
                <b-col lg="6">
                    <h4 class="fs-15">IP</h4>
                    <input type="text" class="form-control" v-model="this.form.ip" disabled>
                </b-col>
            </b-row>
            
            <!-- Текст повідомлення -->
            <div class="mb-3 mt-3">
                <h4 class="fs-15">{{ $t('Message') }}</h4>
                <textarea class="form-control" v-model="this.form.jsonData.text" disabled ></textarea>
            </div>   

        </template>
        <template v-slot:footer-bottom>

            <!-- Кнопка закрити вікно -->
            <button 
                class="btn link-success fw-medium" 
                @click="$emit('close')"
            >
                <i class="ri-close-line me-1 align-middle"></i> 
                {{ $t('close') }} 
            </button>

        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small' 
import { nameWorkerAcc } from '@/usabilityScripts/getNameWorker'

export default ({
    data(){
        return {
            form: {}
        }
    },
    props:['obj'],
    components: { modal },
    created(){
        this.form = this.obj
        console.log('form', this);
    },
    methods: {
        mutateName(e){
            // Беремо ім'я співробітника за його ідентифікатором
            return nameWorkerAcc(e)
        },
    }
})
</script>
