<template>
  <!-- Компонент додавання ярлика відповіді -->

    <div class="mb-4">

      <!-- Прапорець для відображення тільки моїх тегів -->
      <div class="form-check form-check-success mb-3">
          <input class="form-check-input" type="checkbox" id="formCheck8" v-model="checked" @click="getAlldata()">
          <label class="form-check-label" for="formCheck8">{{ $t('ShowOnlyMyTags') }} </label>
      </div>

      <!-- Вибір тегів з можливістю пошуку -->
      <Multiselect 
        :close-on-select="true"
        :searchable="true"
        :object="true"
        :create-option="false"
        :options="obj"
        @input="message($event)"
      />
      <!-- <select class="form-select" aria-label="Default select example" @input="message($event)">
        <option selected>{{ this.$t("Null") }}</option>
        <option v-for="items, k in obj" :key="k">{{ items.label }}</option>
      </select> -->
    </div>

    <!-- Текстове поле для введення повідомлення -->
    <div class="mb-3">
        <label for="VertimeassageInput" class="form-label">{{ $t('Message') }}</label>
        <textarea class="form-control" id="VertimeassageInput" rows="3" :placeholder="$t('Message')" v-model="textsms.message" @change="messageInput"></textarea> 
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Shortcuts } from '@/API.js' // клас для взаємодії з API 
import { storeS } from "../store"; // глобальне сховище даних

let apiServe = new Shortcuts();
export default {
  props: ['link'],
  data() {
    return {
      obj: [],
      textsms: {
        message: '' // повідомлення
      },
      checked: false, // прапорець відображення типу тегів
      objParams:{ // Параметри для фільтрації даних
        page: '1', // сторінка
        pagelimit: '1000', // ліміт записів
        search: '', // пошук
        create_worker_id: '' // хто створив
      },
    }
  },
  components: {
    Multiselect
  },
  created(){
    this.getAlldata();
  },
  methods: {
    messageInput() {
      // Обробник введення тексту повідомлення
      this.$emit('inputCustom', this.textsms)
    },
    message(e){
      // Обробник вибору тегу
      this.textsms.message = e.message
      this.$emit('inputCustom', this.textsms)
    },
    getAlldata(){
      // Отримання списку тегів
      this.obj = [];
      this.objParams.create_worker_id = this.checked == true ? this.user.userid : ''

      apiServe.getLabels(this.objParams).then(result => {
        if(result.status == 'done'){
          // this.obj = result.data;

          let idx = result.data.items.findIndex(el => el.shortcutId == 18)
          let firstEl = result.data.items[0]
          result.data.items[0] = result.data.items[idx]
          result.data.items[result.data.items.length - 1] = firstEl

          for(var item in result.data.items){
            this.obj.push({
              label: result.data.items[item].shortcutName,
              message: result.data.items[item].message,
              value: result.data.items[item].shortcutId
            })
          }
        }
      })
    },
    getUserdata(){
      // Отримання тегів користувача
      apiServe.getLabelsUser(this.user.phone).then(result => {
        if(result.status == 'done'){
          this.obj = res;
        } else {
          this.$toast.error(this.$t("err"))
        }
      })
    },
    check(){
      // Перевірка та отримання тегів при зміні стану прапорця

      if(this.checked == false) {
        // отримуємо теги юзера
        this.getUserdata()
      } else if(this.checked == true){
        // отримуємо всі теги
        this.getAlldata();
      }
    }
  },
  computed: {
    user(){
      /*
        Повертає дані налаштувань користувача з глобального стору.
      */
      return storeS.userbase
    }
  },
  mounted() {
  },
    
}
</script>