<template>
    <modal @close="$emit('close')">
        <template v-slot:title>{{ $t('Groups') }} </template>
        <template v-slot:body >
            <div class="row g-4 mb-3">
                <!-- <div class="col-sm-auto">
                    <div>
                    <a @click="modalBox = true" class="btn btn-success"
                        ><i class="ri-add-line align-bottom me-1"></i> {{ $t('Add') }}</a
                    >
                    </div>
                </div> -->
                <div class="col-sm">
                    <div class="d-flex justify-content-sm-end gap-2">
                    <div class="search-box ms-2">
                        <input type="text" class="form-control" v-model="objParams.search" :placeholder="$t('searc_inp')" @input="searchB(this.objParams.search)"/>
                        <i class="ri-search-line search-icon"></i>
                    </div>
                    </div>
                </div>
                </div>

                <div class="row g-0 text-center text-sm-start align-items-center mb-4" >
                <div class="col-sm-6" >
                    <div >
                        <p class="mb-sm-0 text-muted">{{ $t('Total') }} <span class="fw-semibold">{{ objPages.show }}</span> {{ $t('outOf') }} <span class="fw-semibold text-decoration-underline">{{ objPages.total }}</span></p>
                    </div>
                </div>
                <div class="col-sm-6" >
                    <ul class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                        <div class="pagination-wrap hstack gap-2" style="margin-right:5px;" >
                            <select class="form-select mb-0" v-model="limit" @change="changelimit(limit)">
                            <option v-for="item in limits" :key="item" :value="item.value" >{{item.value}}</option>
                            </select>
                        </div>
                        <li v-for="(pageNumber, index) in objPages.links" :key="index" :class="`page-item copy ${pageNumber.current == 'true' ? 'active' : null}`">
                            <a class="page-link" @click="getdata(pageNumber.page)">{{ pageNumber.page }}</a>
                        </li>
                    </ul>
                </div>
                </div>

                <b-row>
                <div
                    class="col-xxl-3 col-sm-6 project-card"
                    v-for="(item, index) of rows"
                    :key="index"
                >
                    <div class="card" >
                    <div class="card-body">
                        <div :class="`p-3 mt-n3 mx-n3 ${this.mutatePriority(item.priority).color} rounded-top`">
                        <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                <h5 class="mb-0 fs-14 click" 
                                    @click="modalShow = true;currentBlock = item"
                                >
                                    <b>{{ item.categoryName }}</b>
                                </h5>
                            </div>
                        </div>
                        </div>

                        <div class="py-3">
                        <div class="row gy-3">
                            <div class="col-6">
                                <div>
                                    <p class="text-muted mb-1">{{ $t('priority') }}</p>
                                    <div :class="`badge ${this.mutatePriority(item.priority).color} fs-12`">
                                        {{ this.mutatePriority(item.priority).name }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div>
                                    <p class="text-muted mb-1">{{ item.taskName }}</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </b-row>
        </template>
    </modal>

    <!-- створити заявку -->
    <createapplicBox 
        v-if="modalShow"
        :userPhone="obj.userPhone" 
        :infoBlock="currentBlock"
        @close="modalShow = false"
        @getdata="getdata"
    />
    <!-- стоврити заявку -->
</template>

<script>
import modal from '@/components/modal/modal-lg'
import createapplicBox from '@/views/calls/other/newrequest'
import { mutatePriorityOther } from '@/usabilityScripts/globalMutate.js'
import { OtherCalls } from '@/API'

let apiOtherCalls = new OtherCalls();

export default{
    props: ['obj'],
    components: {
        modal,
        createapplicBox
    },
    data(){
        return{
            form:'',
            modalShow: false,
            objPages: '',
            limit: '10',
            limits: [
                { value: '5'},
                { value: '10'},
                { value: '25'},
                { value: '50'},
                { value: '75'},
                { value: '100'},
            ],
            objParams:{
                page: '1',
                pagelimit: '10',
                search: ''
            },
            rows: [],
            currentBlock: ''
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(page){
            this.objParams.page = page != undefined ? page : '1';
            apiOtherCalls.getCategories(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items.reverse();
                    this.objPages = result.data;
                }
            })
        },
        searchB(search) {
            this.objParams.search = search
            this.getdata()
        },
        changelimit(limit) {
            this.objParams.pagelimit = limit
            this.getdata()
        },
        mutatePriority(e){
            return mutatePriorityOther(e);
        },
    }
}
</script>