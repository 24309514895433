<template>
    <div class="card" :style="{'max-heigth': maxHeigth}">
        <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">{{$t('tasks')}}</h4>
            <div class="flex-shrink-0">
                <div class="dropdown card-header-dropdown">
                    <a class="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <span class="text-muted"><i class="ri-settings-4-line align-middle me-1 fs-15"></i>{{$t('action')}}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                        <button class="dropdown-item" @click="showSearch = !showSearch">{{$t('searc_inp')}}</button>
                        <button class="dropdown-item" @click="allTasks">{{ $t('all_tasks') }}</button>
                        <button class="dropdown-item" @click="markedTasks">{{ $t('markedTasks') }}</button>
                        <button class="dropdown-item" @click="unmarkedTasks">{{ $t('unmarkedTasks') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body p-0">
            <div class="align-items-center p-3 justify-content-between d-flex">
                <div class="flex-shrink-0">
                    <div class="text-muted" v-if="form.tasks != null">
                        {{$t('Remaining')}} <span class="fw-semibold">{{ this.form.tasks.filter(item => item.checked == 0).length }}</span> 
                        {{$t('ofRemaining')}} <span class="fw-semibold">{{ form.tasks.length}}</span> 
                    </div>
                </div>
                <button 
                    v-if="this.form.status != 'completed'"
                    @click="showtask == true ? this.edittask() : this.showTask()"
                    type="button" 
                    class="btn btn-sm btn-success">
                    <i class="ri-add-line align-middle me-1"></i> {{$t('Edit')}}
                </button>
            </div>
            <div class="p-3" v-if="showSearch">
                <input class="form-control" v-model="search" />
            </div>
            <div v-if="showtask" class="card-body">
                <hr />
                <h6 class="fw-semibold text-uppercase mb-3">{{ $t('addNewTasks') }}</h6> 
                <div class="flex-grow-1 ms-2 mb-2" v-if="objForm.tasks.length == 0">
                    <div class="input-group">
                        <input type="text" v-model="item.taskName" class="form-control">
                        <button @click="add(item)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                    </div>
                </div>
                <ul class="list-unstyled vstack gap-3 mb-0" v-if="objForm.tasks.length > 0">
                    <input type="text" v-model="item.taskName" v-on:keyup.enter="add(item)" class="form-control" >
                    <li v-for="(input, index) in objForm.tasks" :key="index">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0">
                                <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-dark text-black">#{{index}}</div></div>
                            </div>
                            <div class="flex-grow-1 ms-2">
                                <div class="input-group">
                                    <input type="text" v-model="input.taskName" v-on:keyup.enter="add(input)" class="form-control" >
                                    <button @click="add(input, index)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                                    <button @click="remove(index)" v-if="this.userid === this.form.teamlead || this.objForm.tasks[this.objForm.tasks.indexOf(input)].taskId == null" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div data-simplebar class="simplebar" style="max-height: 257px;overflow: auto;" :style="this.bigger == true ? 'max-height: 80%px !important' : ''">
                <ul class="list-group list-group-flush border-dashed px-3">
                    <li 
                        class="list-group-item ps-0 dragable"
                        v-for="(item,index) in filteredItems" 
                        :key="index"
                        draggable="true" 
                        @dragstart="dragStart(index, $event)" 
                        @dragover.prevent 
                        @dragenter="dragEnter" 
                        @dragleave="dragLeave" 
                        @dragend="dragEnd" 
                        @drop="dragFinish(index, $event)">                       
                        <div class="d-flex align-items-start">
                            <div class="form-check ps-0 flex-sharink-0" v-if="this.form.workers.filter(item => item.workerId == this.user.userid).length > 0">
                                <input type="checkbox"  class="form-check-input ms-0" :id="item.taskName" v-model="item.checked" false-value="0" true-value="1" :checked="item.checked == 0 ? false : true" @change="change(item)" />
                            </div>
                            <div class="flex-grow-1">
                                <label class="form-check-label mb-0 ps-2" :for="item.taskName" :style="`text-decoration:${item.checked == 1 ? 'line-through' : 'auto'}`">{{item.taskName}}</label>
                            </div>
                            <!-- <div class="flex-shrink-0 ms-1">
                                <div class="dropdown">
                                    <button class="btn btn-ghost-secondary btn-icon btn-sm fs-14" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="bx bx-dots-vertical-rounded align-bottom"></i></button>
                                    <div class="dropdown-menu dropdown-menu-end" style="">
                                        <button class="dropdown-item" @click="copyToProject(item)">Скопировать в другую задачу</button>
                                        <button class="dropdown-item">Написать в чате по этой задаче</button>
                                    </div>
                                </div>
                            </div>  -->
                        <!-- <div class="flex-shrink-0 ms-2">
                            <p class="text-muted fs-12 mb-0">15 Sep, 2021</p>
                        </div> -->
                        </div>
                    </li>
                </ul>
            </div>
            
        </div>
    </div>

    <copyTaskProj 
        v-if="showCopy"
        @close="showCopy = false"
    />

</template>

<script>
import copyTaskProj from './copyTaskProj'
import { Projects } from '@/API.js';
import { storeS } from '@/store';

let apiServe = new Projects();

export default {
    props: ['obj', 'bigger'],
    components: {
        copyTaskProj
    },
    data() {
        return {
            maxHeigth: '',
            dragging: -1,
            showCopy: false,
            search: '',
            form: '',
            objForm: {
                tasks: []
            },
            tasks: [],
            item: {
                taskName: '',
                checked: 0
            },
            showtask: false,
            showSearch: false
        }
    },
    created(){
        this.getdata();
        console.log('bigger', this.bigger)
    },
    methods: {
        dragStart(which, ev) {
            ev.dataTransfer.setData('Text', this.id);
            ev.dataTransfer.dropEffect = 'move'
            this.dragging = which;
        },
        dragEnd(ev) {
            this.dragging = -1
        },
        dragFinish(to, ev) {
            this.moveItem(this.dragging, to);
            // ev.target.style.marginTop = '2px'
            // ev.target.style.marginBottom = '2px'
        },
        moveItem(from, to) {
            if (to === -1) {
                this.removeItemAt(from);
            } else {
                this.form.tasks.splice(to, 0, this.form.tasks.splice(from, 1)[0]);
            }
        },
        removeItemAt(index) {
            this.form.tasks.splice(index, 1);
        },
        getdata(){
            this.form = this.obj
            this.tasks = this.form.tasks;
        },
        add(e, index) {
            if(index == undefined) {
                this.objForm.tasks.push({
                    checked: 0,
                    taskName: e.taskName
                })
                e.taskName = ''
            } else {
                if(this.objForm.tasks.length > 0){
                    this.objForm.tasks.splice(index+1, 0, {
                        taskName: "",
                        checked: 0
                    });
                } else {
                    this.objForm.tasks.push({
                        taskName: e.taskName,
                        checked: 0
                    })
                }
            }
        },
        remove(index) {
            this.objForm.tasks.splice(index, 1)
        },
        showTask(){
            this.objForm.tasks = this.form.tasks
            this.showtask = true
        },
        allTasks(){
            this.form.tasks = this.tasks
        },
        markedTasks(){
            this.form.tasks = this.tasks.filter(item => item.checked == 1);
        },
        unmarkedTasks(){
            this.form.tasks = this.tasks.filter(item => item.checked == 0);
        },
        edittask(){
            apiServe.saveProjectTasks(this.form.projectId, this.objForm).then(result =>{
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'));
                    this.eventBus.emit('saveProject', true)
                    this.showtask = false
                }
            })
        },
        change(e){
            this.objForm.tasks = this.form.tasks;
            this.objForm.tasks.forEach((el) => {
                if(el.taskId === e.taskId) {
                    el.checked = e.checked == true ? 1 : 0
                }
            })
            apiServe.saveProjectTasks(this.form.projectId, this.objForm).then(result =>{
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'));
                    this.$emit('progressPercent', result.data.progressPercent)
                }
            })
        },
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form.tasks)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.taskName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        user(){
            return storeS.userbase
        }
    }
}
</script>
<style scoped>
.isSelected {
        color: red
}
.simplebar::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.simplebar::-webkit-scrollbar{
	width: 5px;
	background-color: #F5F5F5;
}

.simplebar::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
</style>