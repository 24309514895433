<template>
    <!-- 
        Модальне вікно для підтвердження відкриття об'єкта з можливістю редагування 
    -->

    <dialogmodal>
        <template v-slot:body>
          <div class="mt-4 text-center">
              <h3><b>{{ $t('doYouWantToOpen') }} {{text.name}}?</b></h3>
          </div>
        </template>
        
        <template v-slot:footer-bottom>
              <div class="hstack gap-2 justify-content-center remove">
                  <button class="btn btn-link link-success fw-medium text-decoration-none" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</button>
                  <button class="btn btn-danger" v-if="this.edit == false" @click="open(this.form)">{{ $t('yesOpenIt') }}</button>
                  <button class="btn btn-danger" v-else @click="openFromEdit(this.form)">{{ $t('yesOpenIt') }}</button>
              </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue'

export default {
    props: ['type', 'obj', 'edit'],
    components: {
        dialogmodal
    },
    data(){
        return{
            text: '',
            form: '',
        }
    },
    created(){
        this.form = this.obj

        switch (this.type){
            case 'ticket': 
                this.text = {
                    name: this.$t('ticketWithSmall'),
                }
                break;
            case 'presentation': 
                this.text = {
                    name: this.$t('presentationWithSmall'),
                }
                break;
            case 'application':
                this.text = {
                    name: this.$t('applicationWithSmall'),
                }
                break;
            case 'task':
                this.text = {
                    name: this.$t('taskWithSmall'),
                }
                break;
            case "project":
                this.text = {
                    name: this.$t('projectWithSmall')
                }
            case "invoice":
                this.text = {
                    name: this.$t('invoiceWithSmall')
                }
        }
    },
    methods: {
        openFromEdit(e) {
            // Метод, який видає подію відкриття об'єкта для редагування.
            this.$emit('openFromEdit', e)
        },
        open(e){
            // Метод, який видає подію відкриття об'єкта без редагування
            this.$emit('open', e)
        }
    },
    mounted(){
    }
}
</script>