<template>
    <modal :title="form.workerName + ' - ' + form.workerPhone ">
        <template v-slot:body>
            <b-card>
                <div class="card-header mb-3">
                    <h5 class="card-title mb-0"><b>{{ $t('generalInfo') }}</b></h5>
                </div>
                <b-row>
                    <b-col lg="6">
                        <h4 class="fs-15">{{ $t('phone_num') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.workerPhone" disabled>
                            <button @click="copy(form.workerPhone)" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h4 class="fs-15">{{ $t('name') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.workerName" disabled>
                            <button @click="copy(form.workerName)" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h4 class="fs-15">{{ $t('innSeller') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.workerData.inn ? form.workerData.inn : '-'" disabled>
                            <button @click="copy(form.workerData.inn ? form.workerData.inn : '-')" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h4 class="fs-15">{{ $t('mail') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.workerData.email ? form.workerData.email : '-'" disabled>
                            <button @click="copy(form.workerData.email ? form.workerData.email : '-')" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
            <b-card>
                <div class="card-header mb-3">
                    <h5 class="card-title mb-0"><b>{{ $t('additionally') }}</b></h5>
                </div>
                <b-row>
                    <b-col lg="6">
                        <h4 class="fs-15">{{ $t('openEntry') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="mutateDir(form.workerData.start_shift)" disabled>
                            <button @click="copy(mutateDir(form.workerData.start_shift))" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h4 class="fs-15">{{ $t('Position') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="mutatePosition(form.workerJob)" disabled>
                            <button @click="copy(mutatePosition(form.workerJob))" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h4 class="fs-15">{{ $t('EntrancebyCard') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.workerData.card ? form.workerData.card : '-'" disabled>
                            <button @click="copy(form.workerData.card ? form.workerData.card : '-')" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
            <b-card>
                <div class="card-header mb-3">
                    <h5 class="card-title mb-0"><b>{{ $t('security') }}</b></h5>
                </div>
                <b-row>
                    <b-col lg="6">
                        <h4 class="fs-15">{{ $t('PINlength') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.workerData.pinLength == 6 ? $t('sixDigit') : $t('fourDigit')" disabled>
                            <button @click="copy(mutateDir(form.workerData.start_shift))" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h4 class="fs-15">PIN</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.workerData.pin ? form.workerData.pin : '-'" disabled>
                            <button @click="copy(form.workerData.pin ? form.workerData.pin : '-')" class="btn btn-primary" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                </b-row>
            </b-card>

            <b-row>
                <b-col>
                    <div>
                        <b-tabs pills>
                            <b-tab :title="$t('Wage')" active>
                                <wageTab v-if="form.workerData.rateData" :obj="this.form.workerData.rateData"  />
                                <span class="fs-14 fw-semibold" v-else>null</span>
                            </b-tab>
                            <b-tab :title="$t('accessRights')" >
                                <permsTab :objPerms="form.perms ? form.perms : null" />
                            </b-tab>
                        </b-tabs>
                    </div>
                </b-col>
            </b-row>
        </template>
        <!-- <template v-slot:footer-bottom></template> -->
    </modal>
</template>

<script>
import modal from '@/components/modal/modal-lg'
import tablecustom from '@/components/globaltable/index';
import wageTab from './tabs/wage'
import permsTab from './tabs/perms'
import { mutateWorkersAccount, mutateDirWorker, mutateWorkerPosition } from '@/usabilityScripts/globalMutate.js'
import {copy} from '@/usabilityScripts/copy.js'

export default{
    props: ['obj'],
    components: {
        modal,
        tablecustom,
        wageTab,
        permsTab
    },
    data(){
        return{
            search: '',
            form: '',
        }
    },
    created(){
        this.form = this.obj
        console.log('wokr form', this.form);
    },
    methods: {
        mutateDir(e){
            return mutateDirWorker(e)
        },
        mutateUser(e){
          return mutateWorkersAccount(e)
        },
        mutatePosition(e){
            return mutateWorkerPosition(e)
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    },
    computed: {
    }
}
</script>