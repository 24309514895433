<template>
    <!-- 
        Таб "Налаштування", включає в себе - 
        - особисті налаштування при використанні інсайду
        - налаштування атс менеджеру
    -->

    <b-row v-if="this.form.settings['006'] === true">
        <b-col
            lg="6">
                <b-card>
                    <b-card-title><h4 class="border-bottom pb-2 mb-2"><b>АТС</b></h4></b-card-title>
                    <b-card-text>
                        <b-row>
                            <b-col lg="4">
                                <div>
                                    <label class="form-label">{{$t('Login')}}</label>
                                    <input type="text" class="form-control" v-model="formuser.uaATS.login" >
                                </div>
                            </b-col>
                            <b-col lg="4">
                                <div>
                                    <label class="form-label">{{ $t('password') }}</label>
                                    <input type="text" class="form-control" v-model="formuser.uaATS.password">
                                </div>
                            </b-col>
                        </b-row>
                        
                        <b-button 
                            v-if="perms[703]" 
                            @click="this.$emit('editUser', formuser)"
                            variant="success" class="waves-effect waves-light mt-3" 
                            style="margin-top:10px"
                        >
                            <i class="align-bottom me-1"></i>
                            {{ $t('Edit') }}
                        </b-button>
                        <!-- <b-button variant="info" class="waves-effect waves-light mt-3" style="margin-left:10px" @click="checkMicro" type="button">Test Microphone</b-button>
                        <b-button variant="info" class="waves-effect waves-light mt-3" style="margin-left:10px" @click="startr" type="button">startr Microphone</b-button>
                        <span id="lvlMicro"></span> -->
                    </b-card-text>
                </b-card>
        </b-col>
    </b-row>
    
    <b-card>
        <div class="mb-3" >
            <!-- Цикл виведення кожного елемента списку налаштувань -->
            <template v-for="(item, i) in option" :key="i.key">
                <!-- Перевірка умови для відображення елементу -->
                <ul class="list-unstyled mb-0" v-if="isShowItem(item)">
                    <li class="d-flex block-as" >

                        <!-- Чекбокс для вибору налаштування -->
                        <div class="check-as form-check form-check-success" >
                            <input
                                type="checkbox" 
                                autocomplete="off" 
                                v-model="this.form.settings[item.value]" 
                                :disabled="item.disabled" 
                                @change="select(this.form.settings[item.value], item)" 
                                class="form-check-input" 
                                :id="`first_level_input_${item.value}`" 
                            >
                        </div>

                        <!-- Назва налаштування та інформаційний текст -->
                        <div class="flex-grow-1">
                            <label class="form-check-label fs-14" :for="`first_level_input_${item.value}`">
                                <b>{{ item.name }}</b> <span v-if="item.disabled" class="badge bg-danger">{{ $t('inDevelop') }}</span>
                            </label>
                            <p class="text-muted">
                                {{ item.desc }} 
                            </p>
                        </div>

                        <!-- Додаткові дії для вибраних налаштувань -->
                        <div class="col-auto" v-if="item.isOpen != undefined">
                            <!-- 
                                Кнопка для відкриття прихованого списку (саб-налаштувань), або сховати
                            -->
                            <button 
                                type="button" 
                                class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email" 
                                @click="item.isOpen = !item.isOpen" 
                                style="margin-right: 7px;" 
                            >
                                <i class="bx bx-down-arrow-alt align-bottom"></i>
                            </button>

                            <!-- 
                                Кнопка для проставлення чекбоксів до всіх налаштувань вибранного пункту
                            -->
                            <button 
                                type="button" 
                                class="btn btn-soft-success btn-icon btn-sm fs-16 close-btn-email" 
                                :disabled="item.disabled" 
                                @click="checkAllchild(item)"
                            >
                                <i class="bx bx-checkbox-checked align-bottom"></i>
                            </button>
                        </div>

                    </li>
                    
                    <!-- Внутрішні дочірні налаштування, якщо є -->
                    <div v-if="item.children && item.isOpen == true"><!-- Контейнер для додаткових налаштувань pagelimit -->

                        <!-- <div class="border rounded border-dashed p-3 mb-2" v-if="form.settings['003'] && item.value == '003'">
                            <div class="m-1">
                                <h4 class="fs-15">{{$t('tableLimit')}}</h4>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <input type="number" class="form-control" v-model="form.pagelimit" />
                                </div>
                            </div>
                        </div> -->

                        <!-- Контейнер для додаткових налаштувань Telegram ID -->
                        <div class="border rounded border-dashed p-3 mb-2" v-if="form.settings['010'] && item.value == '010'">
                            <div class="m-1">
                                <h4 class="fs-15">Telegram ID</h4>
                                <span class="fs-12 text-muted">{{ $t('getTelIDBot') }} <a href="https://t.me/SkyInsideBot" target="_blank">@SkyInsideBot</a></span>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <input type="number" class="form-control" v-model="form.telegramChatId" />
                                </div>
                            </div>
                        </div>

                        <!-- Внутрішні дочірні налаштування -->
                        <template  v-for="(subIndex, j) in item.children" :key="j">
                            <ul v-if="isShowItem(subIndex)">

                                <li class="d-flex block-as" v-if="subIndex.select == false">
                                    <!-- Чекбокс для вибору дочірнього налаштування -->
                                    <div class="check-as">
                                        <input 
                                            type="checkbox" 
                                            autocomplete="off" 
                                            :disabled="item.disabled" 
                                            v-model="this.form.settings[subIndex.value]" 
                                            @change="firstChild(this.form.settings[subIndex.value], subIndex)" 
                                            class="form-check-input" 
                                            :id="`second_level_input_${subIndex.value}`" 
                                        />
                                    </div>

                                    <!-- Назва дочірнього налаштування та інформаційний текст -->
                                    <div class="flex-grow-1">
                                        <label class="form-check-label fs-14" :for="`second_level_input_${subIndex.value}`" style="padding-top: 8px;">
                                            <b>{{ subIndex.name }}</b> <span v-if="item.disabled" class="badge bg-danger">{{ $t('inDevelop') }}</span>
                                        </label>
                                        <p class="text-muted">
                                            {{ subIndex.desc}}
                                        </p>
                                    </div>

                                </li>

                                <!-- Випадаючий список для обрання значення (якщо є) -->
                                <li v-else class="d-flex block-as">
                                    <div class="check-as" v-if="!subIndex.options">
                                        <input 
                                            type="checkbox" 
                                            autocomplete="off" 
                                            :disabled="item.disabled" 
                                            v-model="this.form.settings[subIndex.value]" 
                                            @change="firstChild(this.form.settings[subIndex.value], subIndex)" 
                                            class="form-check-input" 
                                            :id="`second_level_input_${subIndex.value}`" 
                                        />
                                    </div>
                                    <div class="flex-grow-1">
                                        <b>{{ subIndex.name }}</b>
                                        <select class="form-control" v-if="subIndex.options" @change="selectCount(this.option, i, j, $event.target.value)" :v-model="this.form.countries">
                                            <option value="" :selected="this.form.countries == ''">{{ this.$t('Null') }}</option>
                                            <option v-for="items, k in subIndex.options" :key="k" :value="items.value" :selected="this.form.countries == items.value">{{ items.title }}</option>
                                        </select>
                                    </div>
                                </li>
                            </ul>

                            <!-- Внутрішні дочірні налаштування другого рівня -->
                            <div v-if="subIndex.child">
                                <template  v-for="(subsubIndex, k) in subIndex.child" :key="k">
                                    <ul class="block-sub" v-if="isShowItem(subsubIndex)" >
                                        <li class="d-flex ">
                                            <!-- Чекбокс для вибору дочірнього налаштування -->
                                            <div class="check-as">
                                                <input 
                                                    type="checkbox" 
                                                    autocomplete="off" 
                                                    v-model="this.form.settings[subsubIndex.value]" 
                                                    @change="secChild(this.form.settings[subsubIndex.value], subsubIndex, item)" 
                                                    class="form-check-input" 
                                                    :id="`three_level_input_${subsubIndex.value}`" 
                                                />
                                            </div>

                                            <!-- Назва дочірнього налаштування та інформаційний текст -->
                                            <div class="flex-grow-1">
                                                <label class="form-check-label fs-14" :for="`three_level_input_${subsubIndex.value}`" style="padding-top: 8px;">
                                                    <b>{{ subsubIndex.name }}</b>
                                                </label>
                                                <p class="text-muted">
                                                    {{ subsubIndex.desc}}
                                                </p>
                                            </div>

                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </template>
                    </div>
                </ul>
            </template>
            
            <!-- Кнопка для збереження внесених змін -->
            <div class="col-lg-12">
                <div class="text-end">
                    <button @click="editUserSettings" class="btn btn-success">
                        {{ $t('Edit') }}
                    </button>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import { storeS } from '@/store'
export default {
    props: ['obj', 'id', 'objuser'],
    data() {
        return {
            blockAccess: false,
            form: {
                settings: {},
                telegramChatId: '',
                countries: '',
                pagelimit: ''
            },
            formuser: {},
            edit: true,
            option: [
                {
                    name: this.$t('displayInfoBlocks'),
                    desc: this.$t('displayInfoBlocksdesc'),
                    isOpen: null,
                    value: '001',
                    disabled: false
                },
                {
                    name: this.$t('hotkey'),
                    desc: this.$t('hotkeydesc'),
                    isOpen: null,
                    value: '002',
                    disabled: false
                },
                // {
                //     name: this.$t('Filtration'),
                //     desc: this.$t('FiltersTableDesc'),
                //     isOpen: true,
                //     value: '003',
                //     disabled: false,
                //     children: [
                //         {
                //             name: this.$t('tableLimit'),
                //             desc: null,
                //             value: '',
                //             parent: '003',
                //             disabled: true
                //         },
                //     ]
                // },
                {
                    name: this.$t('hideAppl'),
                    desc: this.$t('hideApplDesc'),
                    isOpen: null,
                    value: '004',
                    disabled: false
                },
                {
                    name: this.$t('hideApplDeleted'),
                    desc: this.$t('hideApplDeletedDesc'),
                    isOpen: null,
                    value: '005',
                    disabled: true
                },
                {
                    name: this.$t('voipTitle'),
                    desc: this.$t('voipSettDesc'),
                    isOpen: false,
                    value: '006',
                    disabled: false,
                    children: [
                        {
                            name: this.$t('country'),
                            desc: null,
                            value: '',
                            parent: '006',
                            disabled: true,
                            select: true,
                            options: [{title: this.$t('SND'), value: 'SND'}, {title: this.$t('other'), value: 'other'}],
                        },
                    ]
                },
                {
                    name: this.$t('SearchOnlyNumphoneTicket'),
                    desc: this.$t('SearchOnlyNumphoneTicketDesc'),
                    isOpen: null,
                    value: '007',
                    disabled: false
                },
                {
                    name: this.$t('SearchOnlyWorker'),
                    desc: this.$t('SearchOnlyWorkerDesc'),
                    isOpen: null,
                    value: '008',
                    disabled: false
                },
                {
                    name: this.$t('WritingDataLocal'),
                    desc: this.$t('WritingDataLocalDesc'),
                    isOpen: null,
                    value: '009',
                    disabled: false
                },
                {
                    name: this.$t('TelegramNotification'),
                    desc: this.$t('TelegramNotificationDesc'),
                    isOpen: false,
                    value: '010',
                    disabled: false,
                    children: [
                        {
                            name: this.$t('ApplicationNotifications'),
                            desc: null,
                            value: '011',
                            parent: '010',
                            disabled: true
                        },
                        {
                            name: this.$t('PresentNotifications'),
                            desc: null,
                            value: '012',
                            parent: '010',
                            disabled: true
                        },
                        {
                            name: this.$t('TaskNotifications'),
                            desc: null,
                            value: '013',
                            parent: '010',
                            disabled: true
                        },
                        {
                            name: this.$t('TicketsNotifications'),
                            desc: null,
                            value: '014',
                            parent: '010',
                            disabled: true
                        },
                        {
                            name: this.$t('ProjectsNotifications'),
                            desc: null,
                            value: '015',
                            parent: '010',
                            disabled: true
                        },
                    ]
                },
                {
                    name: this.$t('darkTheme'),
                    desc: this.$t('darkThemeDesc'),
                    isOpen: false,
                    value: '022',
                    disabled: false,
                    children: [
                        {
                            name: this.$t('autoOnOffDarkTheme'),
                            desc: null,
                            value: '023',
                            parent: '022',
                            disabled: false
                        },
                    ]
                },
                {
                    name: this.$t('pashalToDev'),
                    desc: this.$t('pashalToDevDesc'),
                    isOpen: null,
                    value: '024',
                    disabled: false
                },
                {
                    name: this.$t('OpenActivityCreation'),
                    desc: this.$t('OffertoOpenApplication'),
                    isOpen: null,
                    value: '025',
                    disabled: false
                },
                {
                    name: this.$t('AddEventToCalendar'),
                    desc: this.$t('SuggestAddingActivityCalendar'),
                    isOpen: null,
                    value: '026',
                    disabled: false
                },
                {
                    name: this.$t('Alerts'),
                    desc: this.$t('AlertsDesc'),
                    isOpen: false,
                    value: '100',
                    disabled: false,
                    perms: [ 100, 110, 200, 300, 350, 506, 508 ],
                    children: [
                        {
                            name: this.$t('applications'),
                            desc: null,
                            value: '110',
                            parent: '100',
                            isParent: true,
                            isOpen: false,
                            perms: 100,
                            disabled: false,
                            child : [
                                {
                                    name: this.$t('RequestCreationNotification'),
                                    desc: null,
                                    value: '111',
                                    parent: '110',
                                    perms: 100,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('RequestMissingNotification'),
                                    desc: null,
                                    value: '112',
                                    parent: '110',
                                    perms: 100,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('RequestCancelNotification'),
                                    desc: null,
                                    value: '113',
                                    parent: '110',
                                    perms: 100,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('RequestTransferNotification'),
                                    desc: null,
                                    value: '114',
                                    parent: '110',
                                    perms: 100,
                                    disabled: false,
                                }
                            ]
                        },
                        {
                            name: this.$t('dumps'),
                            desc: null,
                            value: '120',
                            parent: '100',
                            isParent: true,
                            isOpen: false,
                            perms: 100,
                            disabled: false,
                            child : [
                                {
                                    name: this.$t('DumpCreationNotification'),
                                    desc: null,
                                    value: '121',
                                    parent: '120',
                                    perms: 100,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('DumpTransferNotification'),
                                    desc: null,
                                    value: '122',
                                    parent: '120',
                                    perms: 100,
                                    disabled: false,
                                }
                            ]
                        },
                        {
                            name: this.$t('presentations'),
                            desc: null,
                            value: '130',
                            parent: '100',
                            isParent: true,
                            isOpen: false,
                            perms: 110,
                            disabled: false,
                            child : [
                                {
                                    name: this.$t('PresentCreationNotification'),
                                    desc: null,
                                    value: '131',
                                    parent: '130',
                                    perms: 110,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('PresentCancelNotification'),
                                    desc: null,
                                    value: '132',
                                    parent: '130',
                                    perms: 110,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('PresentTransferNotification'),
                                    desc: null,
                                    value: '133',
                                    parent: '130',
                                    perms: 110,
                                    disabled: false,
                                }
                            ]
                        },
                        {
                            name: this.$t('tasks'),
                            desc: null,
                            value: '140',
                            parent: '100',
                            isParent: true,
                            isOpen: false,
                            perms: 200,
                            disabled: false,
                            child : [
                                {
                                    name: this.$t('TaskCreationNotification'),
                                    desc: null,
                                    value: '141',
                                    parent: '140',
                                    perms: 200,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('TaskTransferNotification'),
                                    desc: null,
                                    value: '142',
                                    parent: '140',
                                    perms: 200,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('TaskConfirmNotification'),
                                    desc: null,
                                    value: '143',
                                    parent: '140',
                                    perms: 200,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('TaskCancelNotification'),
                                    desc: null,
                                    value: '144',
                                    parent: '140',
                                    perms: 200,
                                    disabled: false,
                                }
                            ]
                        },
                        {
                            name: this.$t('tickets'),
                            desc: null,
                            value: '150',
                            parent: '100',
                            isParent: true,
                            isOpen: false,
                            perms: 300,
                            disabled: false,
                            child : [
                                {
                                    name: this.$t('TicketCreationAlert'),
                                    desc: null,
                                    value: '151',
                                    parent: '150',
                                    perms: 300,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('TicketReopenAlert'),
                                    desc: null,
                                    value: '152',
                                    parent: '150',
                                    perms: 300,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('TicketAssignmentAlert'),
                                    desc: null,
                                    value: '153',
                                    parent: '150',
                                    perms: 300,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('TicketInjobAlert'),
                                    desc: null,
                                    value: '154',
                                    parent: '150',
                                    perms: 300,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('TicketDecisionAlert'),
                                    desc: null,
                                    value: '155',
                                    parent: '150',
                                    perms: 300,
                                    disabled: false,
                                },
                                {
                                    name: this.$t('TicketClosingAlert'),
                                    desc: null,
                                    value: '156',
                                    parent: '150',
                                    perms: 300,
                                    disabled: false,
                                },
                            ]
                        },
                        // {
                        //     name: this.$t('navProjects'),
                        //     desc: null,
                        //     value: '160',
                        //     parent: '100',
                        //     isParent: true,
                        //     isOpen: false,
                        //     disabled: false,
                        //     perms: 350,
                        //     child : [
                        //         {
                        //             name: this.$t('ProjectAllMsgsAlert'),
                        //             desc: null,
                        //             value: '161',
                        //             parent: '160',
                        //             disabled: false,
                        //             perms: 350,
                        //         },
                        //         {
                        //             name: this.$t('ProjectCreationAlert'),
                        //             desc: null,
                        //             value: '162',
                        //             parent: '160',
                        //             disabled: false,
                        //             perms: 350,
                        //         },
                        //     ]
                        // },
                        {
                            name: this.$t('breaks'),
                            desc: null,
                            value: '170',
                            parent: '100',
                            isParent: true,
                            isOpen: false,
                            disabled: false,
                            perms: 508,
                            child : [
                                {
                                    name: this.$t('BreakAlert'),
                                    desc: null,
                                    value: '171',
                                    parent: '170',
                                    disabled: false,
                                    perms: 508,
                                },
                            ]
                        },
                        {
                            name: this.$t('shifts'),
                            desc: null,
                            value: '180',
                            parent: '100',
                            isParent: true,
                            isOpen: false,
                            disabled: false,
                            perms: 506,
                            child : [
                                {
                                    name: this.$t('OpenCloseShiftAlert'),
                                    desc: null,
                                    value: '181',
                                    parent: '180',
                                    disabled: false,
                                    perms: 506,
                                },
                            ]
                        },
                        {
                            name: this.$t('timeOff'),
                            desc: null,
                            value: '190',
                            parent: '100',
                            isParent: true,
                            isOpen: false,
                            disabled: false,
                            perms: 521,
                            child : [
                                {
                                    name: this.$t('requestHolidayAlert'),
                                    desc: null,
                                    value: '191',
                                    parent: '190',
                                    disabled: false,
                                    perms: 521,
                                },
                            ]
                        },
                        {   
                            name: this.$t('alertsForMe'),
                            desc: this.$t('showAlertsForMe'),
                            value: '200',
                            parent: '100',
                            isParent: true,
                            isOpen: false,
                            disabled: false,
                            child: [
                                {
                                    name: this.$t('showAllMessages'),
                                    desc: null,
                                    value: '201',
                                    parent: '200',
                                    disabled: false,
                                },
                        //         {
                        //             name: this.$t('showAlerts'),
                        //             desc: null,
                        //             value: '202',
                        //             parent: '200',
                        //             disabled: false,
                        //         },
                            ]
                        }
                    ]
                },
                // {
                //     name: this.$t('SoundAlerts'),
                //     desc: this.$t('SoundAlertsDesc'),
                //     isOpen: false,
                //     value: '027',
                //     disabled: false,
                //     perms: [ 100, 200, 300, 110, 350 ],
                //     children: [
                //         {
                //             name: this.$t('ByRequest'),
                //             desc: null,
                //             value: '028',
                //             parent: '027',
                //             disabled: false,
                //             perms: 100
                //         },
                //         {
                //             name: this.$t('ByTask'),
                //             desc: null,
                //             value: '029',
                //             parent: '027',
                //             disabled: false,
                //             perms: 200
                //         },
                //         {
                //             name: this.$t('ByTickets'),
                //             desc: null,
                //             value: '030',
                //             parent: '027',
                //             disabled: false,
                //             perms: 300
                //         },
                //         {
                //             name: this.$t('ByPresentations'),
                //             desc: null,
                //             value: '031',
                //             parent: '027',
                //             disabled: false,
                //             perms: 110
                //         },
                //         {
                //             name: this.$t('ByProjects'),
                //             desc: null,
                //             value: '032',
                //             parent: '027',
                //             disabled: false,
                //             perms: 350
                //         },
                //         {
                //             name: this.$t('ByMsg'),
                //             desc: null,
                //             value: '033',
                //             parent: '027',
                //             disabled: false,
                //             perms: [ 350, 300 ]
                //         },
                //     ]
                // },
            ]
        } 
    },
    created(){
        // Перевірка та присвоєння значень налаштувань користувача
        if(this.obj.settings != null){
            this.form.settings = this.obj.settings;
            this.form.telegramChatId = this.obj.telegramChatId;
        }

        // Перевірка та присвоєння значень іншого об'єкту користувача
        if(this.objuser != null){
            this.formuser = this.objuser
        }

        // console.log(this.form.countries)

        // Присвоєння значень країни з об'єкту користувача
        this.form.countries = this.obj.countries

    },
    methods:{
        isShowItem(option){
            // Перевірка умов для відображення елементу списку налаштувань

            var status = true;
            if(option.perms){
                if(option.perms.length > 1) {
                    for(var item in option.perms){
                        if(this.perms[option.perms[item]] == true){
                            status = true;
                        }
                    }
                } else {
                    status = this.perms[option.perms]
                }
            }
            return status
        },
        select(checked, item){
            // Обробка зміни головного чекбоксу та його дочірніх налаштувань

            if(!checked){
                for(let key in item.children){
                    this.form.settings[item.children[key].value] = null;
                    item.children[key] = Object.assign({}, item.children[key])
                    if(item.children[key].child){
                        for(let value in item.children[key].child){
                            this.form.settings[item.children[key].child[value].value] = false;
                        }
                    }
                }
            } 
        },
        firstChild(checked, itemchild){
            // Обробка зміни статусу дочірнього чекбоксу та його батьківського елементу

            if(!checked){
                for(let key in itemchild.child){
                    this.form.settings[itemchild.child[key].value] = false;
                    itemchild.child[key] = Object.assign({}, itemchild.child[key])
                }
            } else {
                this.form.settings[itemchild.parent] = true
            }
        },
        secChild(checked, itemchild, parent){
            // Обробки зміни статусу чекбоксу та його батьківських елементів

            this.form.settings[parent.value] = true
            this.form.settings[itemchild.parent] = true
        },
        checkAllchild(item){
            // Вибір всіх дочірніх налаштувань для головного налаштування

            for(let key in item.children){
                item.isOpen = true;
                if(item.isOpen){
                    for(let key in item.children){
                        this.form.settings[item.value] = true
                        this.form.settings[item.children[key].value] = true
                        if(item.children[key].child){
                            for(let option in item.children[key].child){
                                this.form.settings[item.children[key].child[option].value] = true
                            }
                        }
                    }
                }
            }
        },
        editUserSettings(){
            // Відправлення події редагування налаштувань користувача
            this.$emit('editUserSettings', this.id, this.form)
        },
        checkMicro(){
            // перевірки мікрофону
            if (navigator.mediaDevices) {
                    const constraints = window.constraints = {
                        audio: true, 
                        video: false
                }
                    navigator.mediaDevices.getUserMedia(constraints).then(this.handleSuccess).catch(this.handleError)
            }
        },
        handleSuccess(stream){
            // Обробка успішного отримання аудіоданих

            if (window.stream) {
                console.log('stream',window.stream);
                window.stream.getAudioTracks().forEach(track => track.stop());
                window.stream = null;
            } else {
                const audio = document.createElement('audio');
                audio.controls = true;
                audio.autoplay = true;
                window.stream = stream;
                audio.srcObject = stream;
                console.log('stream', stream);

                stream.oninactive = function() {
                };
            }
        },
        handleError(e){
            // Обробка помилки при отриманні аудіоданих
        },
        selectCount(arr, idx1, idx2, item) {
            this.form.countries = item
        },
        startr(){
            // Запуск отримання аудіоданих в реальному часі
            console.log("starting");
            navigator.getUserMedia = navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia;
            if (navigator.getUserMedia) {
               
                navigator.getUserMedia(
                    {
                        audio: true
                    },
                    function (stream) {
                        console.log('stream',stream)
                        var audioContext = new AudioContext();
                        var analyser = audioContext.createAnalyser();
                        var microphone = audioContext.createMediaStreamSource(stream);
                        var javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

                        analyser.smoothingTimeConstant = 0.8;
                        analyser.fftSize = 1024;

                        microphone.connect(analyser);
                        analyser.connect(javascriptNode);
                        javascriptNode.connect(audioContext.destination);

                        javascriptNode.onaudioprocess = function() {

                            var array = new Uint8Array(analyser.frequencyBinCount);
                            analyser.getByteFrequencyData(array);
                            var values = 0;

                            var length = array.length;
                            for (var i = 0; i < length; i++) {
                                values += (array[i]);
                            }

                            var average = values / length;

                            console.log('average -40', Math.round(average - 40))

                        }
                        // console.log('str', stream);
                    },
                    function (err) {
                        console.log("The following error occurred: " + err.name);
                    },
                )
            }
        }
    },
    computed: {
        checks(){
            /*
                Властивість, яка повертає налаштування користувача зі збережених даних у глобальному стору.
            */
            return storeS.checks
        },
        perms(){
            /*
                Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
            */
            return storeS.perms
        }
    }
}
</script>

<style scoped>
.block-as {
    margin: 20px auto;
}
.check-as {
    margin: 5px;
    margin-right: 20px;
}
.check-as .form-check-input {
    width: 1.6em;
    height: 1.6em;
}
.block-sub {
    margin-left: 30px ;
}
</style>