<template>
    <!-- 
        Компонент інфо-блоків, з підсумками по обраним даним
        - але, меньший за розмірами
    -->

    <div class="row" style="max-height: 500px;overflow: scroll;">
      <div :class="`col-sm-12`" v-for="item in form" :key="item" >
        <div class="card card-animate" style="border-radius: 8px; cursor: pointer;" @click="item.copy == true ? copy(item.title + ' ' + item.value): noCopy()">
            <div :class="`card-body bg-${item.type || item.type == 'colorsBlock' ? item.color : ''}`" style="border-radius: 8px;">
                <!-- Контент блоку з інформацією -->
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                        <p :class="`text-uppercase fw-medium ${item.type || item.type == 'colorsBlock' ? 'text-white' : ''} mb-0`"><b>{{ item.title }}</b></p>
                    </div>
                </div>

                <!-- Додатковий контент залежно від умови наявності значення суми -->
                <div class="d-flex align-items-end justify-content-between mt-3">
                    <!-- Умова наявності суми -->
                    <div v-if="item.sum" class="breakText">
                        <!-- Виведення суми, значення та відповідної позначки -->
                        <h4 class="fs-22 fw-semibold ff-secondary mb-4">₴<span class="counter-value">{{item.sum}}</span></h4>
                        <span class="badge bg-warning me-1">{{item.value}}</span> <span class="text-muted">{{$t('Total')}}</span>
                    </div>
                    <!-- Умова відсутності суми -->
                    <div v-else class="breakText">
                        <h4 :class="`fs-22 fw-semibold ff-secondary ${item.type || item.type == 'colorsBlock' ? 'text-white' : ''}`"><span class="counter-value">{{item.value}}</span></h4>
                    </div>
                    <!-- Виведення аватарки з іконкою в залежності від умови -->
                    <div class="avatar-sm flex-shrink-0" v-if="!item.type">
                        <span
                            :class="`avatar-title bg-soft-${item.color} text-${item.color} rounded-circle fs-4`"
                        >
                            <i :class="`${item.icon}`"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import {copy} from '@/usabilityScripts/copy.js'

export default{
    props: ['obj'],
    data(){
        return{
            form: "" // Змінна для зберігання даних інформаційних блоків
        }
    },
    created(){
        // Ініціалізація змінної даних інформаційних блоків з отриманими даними
        this.form = this.obj
    },
    methods: {
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        noCopy(){
            this.$toast.error(this.$t('TrytoClick'));
        }
    }
}
</script>

<style scoped>
.breakText h4{
    /* white-space: pre-line;
    word-wrap: break-word;
    overflow: hidden; */
    word-break: break-all;
}

.row::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.row::-webkit-scrollbar
{
	width: 10px;
    height: 10px;
	background-color: #F5F5F5;
}

.row::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
</style>