<template>
    <!-- карточка презентации -->
    <modal :key="componentKey" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @close="closeModal">
        <template v-slot:title>{{ $t('presentation') }} #{{ form.presentationId }}</template>
        <template v-slot:status >
            <span :class="`badge badge ${this.mutatestatus(this.form.status, this.form).color} align-middle fs-12`">{{this.mutatestatus(this.form.status, this.form).name}}</span>
        </template>
        <template v-slot:head-button>
            <div class="dropdown" v-if="((perms[2000] || perms[113] || perms[114] || perms[115]) && form.inJobWorkerId == this.user.userid)">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <button class="dropdown-item" v-if="(perms[113] || perms[2000]) && this.form.status == 'new'" @click="this.$emit('edit', this.form)">{{ $t('toEditAp') }}</button> <!-- редагувати заявку -->
                    <button type="button" v-if="(form.status == 'jobs' && form.inJobWorkerId == this.user.userid)" @click="showModalDelay = true" class="dropdown-item">{{ $t('postpone') }}</button> <!-- відкласти заявку -->
                    <button type="button" v-if="perms[115] == true" @click="showModalClose = true" class="dropdown-item">{{ $t('close') }}</button>
                    <button class="dropdown-item" v-if="form.status == 'jobs' && (perms[114] || perms[2000]) && form.inJobWorkerId == this.user.userid" @click="showModalTransfer = true">{{ $t('Submit')}}</button> <!-- передать заявку -->
                    <button type="button" v-if="(perms[115] || perms[2000])" class="dropdown-item" @click="this.showModalDelete=true">{{ $t('Delete') }}</button> <!-- видалити заявку -->
                    
                </div>
            </div>
        </template>
        <template v-slot:body>
            <b-alert v-if="form.status == 'transfered'" show variant="warning" class="text-center">
                <h5 class="alert-heading" v-if="this.user.userid != form.transfer.workerId">
                    <b>{{ form.inJobWorkerName }} {{ $t('submittedRequest') }} {{ form.transfer.workerName }}</b>
                </h5>
                <h5 class="alert-heading" v-else>
                    <b>{{ form.inJobWorkerName }} {{ $t('sentYouRequest') }}</b>
                </h5>
                <span v-if="form.transfer.comment">{{ $t('Comment') }}: {{ form.transfer.comment }}</span>
            </b-alert>
            <b-row>
                <b-col lg="6">
                    <aboutBlock 
                        :obj="this.form"
                    />
                </b-col>
                <b-col lg="6">
                    <clientBlock
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                </b-col>
                <b-col lg="6" v-if="this.form.inJobWorkerId != null">
                    <jobBlock
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                </b-col>
                <b-col lg="6" v-if="this.form.delayWorkerId != null">
                    <delayBlock
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                </b-col>
                <b-col lg="6" v-if="this.form.cancelWorkerId != null">
                    <canceledBlock
                        :obj="this.form"
                    />
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <button 
                type="button" 
                v-if="perms[112] == true"
                @click="showModalCancel = true" 
                class="btn btn-danger r waves-effect waves-light">
                {{ $t('cancel') }}
            </button> <!-- отмена презент -->
            <button 
                type="button" 
                v-if="perms[112] && form.status == 'new'"
                @click="toWork"
                class="btn btn-success r waves-effect waves-light">
                {{ $t('toWork') }}
            </button> <!-- взять в работу презент -->
            <button
                type="button"
                v-if="this.form.status=='jobs' && this.user.userid === this.form.inJobWorkerId"
                class="btn btn-success r waves-effect waves-light" 
                @click="showModalDelay = true"
                >{{ $t('Confirm') }}
            </button> <!-- підтвердити презентацію -->
            <button
                type="button"
                v-if="perms[115] == true&& this.user.userid === this.form.inJobWorkerId && form.status == 'inProgressNow'"
                class="btn btn-success r waves-effect waves-light" 
                @click="showModalClose = true"
                >{{ $t('close') }}
            </button> <!-- закрити презентацію -->
            <button 
                type="button"
                v-if="perms[114] && form.status == 'transfered' && form.transferWorkerId == this.user.userid"
                @click="rejectTransfer()"
                :class="`btn btn-danger ss waves-effect waves-light`">
                {{ $t('Refusal') }}
            </button> <!-- відмовитись від передачі презенташки -->
            <button 
                type="button"
                v-if="perms[114] && form.status == 'transfered' && form.transferWorkerId == this.user.userid"
                @click="confirmTransfer()"
                :class="`btn btn-success ss waves-effect waves-light`">
                {{ $t('ConfirmTransfer') }}
            </button> <!-- підтвердити передачу -->
        </template>
    </modal>

    <!-- модальне вікно скасування -->
    <modalCancel 
        v-if="showModalCancel == true" 
        @close="showModalCancel = false"
        :id="this.form.presentationId"
        @toCancel="toCancel"
    />
    <!-- модальне вікно скасування -->

    <!-- модальне вікно закриття презентації -->
    <modalClose
        v-if="showModalClose == true" 
        @close="showModalClose = false"
        :id="this.form.presentationId"
        delayPresentation
        @toClose="toClose"
    />
    <!-- модальне вікно закриття презентації -->

    <!-- модальне вікно перенесення презентації -->
    <modalDelay 
        v-if="showModalDelay == true" 
        @close="showModalDelay = false"
        :id="this.form.presentationId"
        @toDelay="toDelay"
    />
    <!-- модальне вікно перенесення презентації -->

    <!-- модальне вікно чогось -->
    <!-- <modalComplete 
        v-if="showModalComplete == true" 
        @close="showModalComplete = false"
        :id="this.form.presentationId"
        @toComplete="toComplete"
    /> -->
    <!-- модальне вікно чогось -->

    <!-- модальне вікно передачі презентації -->
    <modalTransfer 
        v-if="showModalTransfer == true" 
        @close="showModalTransfer = false"
        :id="this.form.presentationId"
        @toTransfer="toTransfer"
    />
    <!-- модальне вікно передачі презентації -->

    <!-- модальне вікно підтвердження видалення -->
    <modalDelete 
        v-if="showModalDelete == true" 
        @close="showModalDelete = false"
        :id="this.form.presentationId"
        @toDelete="toDelete"
    />
    <!-- модальне вікно підтвердження видалення -->

</template>

<script>
import clientview from '@/views/clientnew/index'
import modal from '@/components/modal/modal-lg'
import aboutBlock from './blocks/about'
import clientBlock from './blocks/client'
import jobBlock from './blocks/inJob.vue'
import delayBlock from './blocks/delayBlock.vue'
import canceledBlock from './blocks/canceled'
import modalCancel from '../actions/cancel'
import modalDelay from '../actions/delay'
import modalClose from '../actions/close'
// import modalComplete from '../actions/complete'
import modalTransfer from '../actions/transfer'
import modalDelete from '../actions/delete'
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { Presentations, Clients } from "@/API.js"
import { storeS } from '@/store.js'
import { mutateStatusPresent } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Presentations();
let apiClients = new Clients();

export default ({
    data(){
        return {
            showModalClose: false,
            crItem: {
                id: '',
                type: 'presentation',
                name: '',
                description: '',
                date: '',
                time: ''
            },
            showAddEvent: false,
            showModalDelete: false,
            name: '',
            email: '',
            checked: false,
            modalDialog: false,
            dialogshow: false,
            testshowModalclient: false,
            form: {},
            showModal: false,
            showModalClient: false,
            number: "",
            showModalCancel: false,
            showModalDelay: false,
            showModalComplete: false,
            showModalTransfer: false,
            keyUpdate: true,
            objClient: {},
            componentKey: ''
        }
    },
    props: ["phone", "shortapplic", 'ifPrevopen', 'ifNextopen', 'closeOne', 'client'],
    components: { 
        modalDelete,
        clientview,
        modal,
        modalCancel,
        modalClose,
        modalDelay,
        // modalComplete,
        modalTransfer,
        aboutBlock,
        clientBlock,
        jobBlock,
        delayBlock,
        canceledBlock
    },
    created(){
        this.form = this.shortapplic
        storeS.checkModal.type = 'presentation';
        storeS.checkModal.id = this.form.presentationId;
    },
    methods: {
        toDelay(id, e) {
            //перенести презентацію
            apiServe.delayPresentation(id, e).then(result=>{
                if(result.status==='done') {
                    this.crItem.id = id;
                    this.crItem.date = e.delayedDate
                    this.crItem.time = e.delayedTime
                    this.crItem.name = this.form.type
                    this.crItem.description = e.comment;
                    if(this.settingCheck['026']==true) {
                        this.$emit('addCalendar', this.crItem)
                        this.$emit('close')
                    } else {   
                        this.$toast.success(this.$t('DelayedOnTime'))
                        this.closeModal();
                    }
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        toDelete(e) {
            //видалити презентацію
            apiServe.deleteCard(e).then(result=>{
                if(result.status == 'done') {
                    this.$toast.success(this.$t("Removed"))
                    this.showModalDelete = false
                    this.closeModal();
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        },
        toWork(){
            //взять в работу презентацию
            apiServe.addPresentationInJob(this.form.presentationId).then(result => {
                if(result.status === 'done'){
                    this.updateCard();
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        toClose(id, e) {
            //выполнить презентацию
            apiServe.closePresentation(id, e).then(result=>{
                if(result.status === 'done'){ 
                    this.$toast.success(this.$t('closed'))
                    this.closeModal();
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        toCancel: function(id, e){
            //отменить презентацию
            apiServe.cancelPresentation(id, e).then(result => {
                if(result.status == 'done'){
                    this.closeModal();
                    this.$toast.success(this.$t('canceled'));
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        getClient(number){
            //открыть карточку клиента
            this.number = number
            this.eventBus.emit('getClient', {status: true, number: this.number})
        },
        updateCard(){
            //обновить данные
            apiServe.getCard(this.form.presentationId).then(result => {
                if(result.status == 'done'){
                    this.form = result.data;
                    this.componentKey += 1
                }
            })
        },
        toTransfer(id, toWorker){
            apiServe.transferPresentation(id, toWorker).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('TransferRequestSent'));
                    this.closeModal();
                } else if(result.status == "error" && result.error[0] == 'worker id not found') {
                    this.$toast.error(this.$t('noSelectWorker'))
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        confirmTransfer(){
            apiServe.fixationTransferPresentation(this.form.presentationId, this.form.transferWorkerId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('threw'));
                    // this.$emit('close') //фікасація отримання заявки
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        rejectTransfer(){
            apiServe.rejectTransferPresentation(this.form.presentationId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('didn`t accept'));
                    this.closeModal();
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        closeModal() {
            //закрытие презент
            storeS.checkModal.type = '';
            storeS.checkModal.id = '';
            this.$emit('close');
            // this.$emit('getdata');
        },
        prevOpen(){
            //пред презент
            this.$emit('close');
            this.$emit('prevOpen', this.form.presentationId)
        },
        nextOpen(){
            //след презент
            this.$emit('close');
            this.$emit('nextOpen', this.form.presentationId)
        },
        nameWorker(item){
            return nameWorker(item)
        },
        mutatestatus(e, obj){
            return mutateStatusPresent(e, obj)
        },
    },
    mounted() {
        this.eventBus.off('updatePresentation');
        this.eventBus.on('updatePresentation', (status) => {
            if(status == true) {
                this.updateCard();
            }
        })
    },
    computed:{
        perms() {
            return storeS.perms
        },
        user() {
            return storeS.userbase
        },
        workers() {
            return storeS.workers
        },
        settingCheck() {
            return storeS.checks
        },
    }
})
</script>
