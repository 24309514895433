<template>
    <!-- Компонент редактора Quill -->
    <QuillEditor 
      v-model:content="form" 
      :placeholder="defaultVal" 
      contentType="html" 
      toolbar="full" 
      theme="bubble"
      :modules="modules"
      @textChange="input(form)"
    />
  </template>

<script>
// Імпорт модуля BlotFormatter з бібліотеки quill-blot-formatter
import BlotFormatter from 'quill-blot-formatter'

export default({
  // Властивості, які передаються з батьківського компонента
  props: ['objText'],

  // Дані компонента
  data() {
    return {
      form: '', // Початковий текст форми
      defaultVal: '', // Початковий текст для placeholder
    }
  },

  // Методи, які викликаються при створенні компонента
  created() {
    // Якщо об'єкт objText не пустий, то встановлюємо початковий текст форми та placeholder
    if(this.objText != ''){
      this.form = this.objText
      this.defaultVal = this.objText
    }
  },

  // Методи компонента
  methods: {
    // Метод, який передає текст у батьківський компонент
    input(text){
      this.$emit('input', text)
    }
  },

  // Модулі, які використовуються в компоненті
  setup: () => {
    const modules = {
      name: 'blotFormatter', // Назва модуля
      module: BlotFormatter, // Модуль BlotFormatter
      options: {/* options */} // Опції модуля (не задані)
    }
    return { modules } // Повертаємо модуль
  },
})
</script>

<style>
.ql-editor {
    max-height: 35vmax!important;
    overflow: auto;
}
</style>