<template>
    <!-- Компонент терміналу -->

    <div class="col-md-6">
        <div class="mb-3">

            <!-- Заголовок для моделі банківського терміналу -->
            <h4 class="fs-15">{{ $t('bank_terminal_model') }}</h4>

            <!-- Випадаючий список для вибору моделі терміналу -->
            <Multiselect class="mb-3"
                @input="input"
                v-model="form.model.value"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="maker_terminal"
                :disabled="this.editform == false"
            />
            
            <!-- Заголовок для протоколу підключення -->
            <h4 class="fs-15">{{ $t('protocol_connect') }}</h4>

            <!-- Вибір протоколу підключення зі списку -->
            <select
                class="form-select mb-3"
                data-plugin="choices"
                v-model="form.protocol_type.value"
                :disabled="this.editform == false"
                >
                <option v-for="item in protocols" :key="item.id">{{ item.title }}</option>
            </select>

            <!-- Заголовок для операційної системи -->
            <h4 class="fs-15">{{ $t('operating_system') }}</h4>

            <!-- Вибір типу операційної системи зі списку -->
            <select
                class="form-select mb-3"
                data-plugin="choices"
                v-model="form.oc_types.value"
                :disabled="this.editform == false"
                >
                <option v-for="item in oc_types" :key="item.id">{{ item.title }}</option>
            </select>

            <!-- Заголовок для версії додатку -->
            <h4 class="fs-15">{{ $t('ver_apps') }}</h4>

            <!-- Введення текстового поля для версії додатку -->
            <input 
                type="text" 
                class="form-control mb-3" 
                id="text" 
                :disabled="this.editform == false" 
                v-model="form.version_os.value" 
                placeholder="9.9"
            >
        </div>
    </div>
    <div class="col-md-6">
        <div class="mb-3">
            <!-- Заголовок для банку -->
            <h4 class="fs-15">{{ $t('title_bank') }}</h4>

            <!-- Вибір банку зі списку -->
            <select
                class="form-select mb-3"
                data-plugin="choices"
                v-model="form.bank.value"
                :disabled="this.editform == false"
                >
                <option v-for="item in banks" :key="item.id">{{ item.title }}</option>
            </select>

            <!-- Заголовок для методу підключення -->
            <h4 class="fs-15">{{ $t('connection_method') }}</h4>

            <!-- Вибір методу підключення зі списку -->
            <select
                class="form-select mb-3"
                data-plugin="choices"
                v-model="form.connect_types.value"
                :disabled="this.editform == false"
                >
                <option v-for="item in connect_types" :key="item.id">{{ item.title }}</option>
            </select>
            
            <!-- Заголовок для версії операційної системи -->
            <h4 class="fs-15">{{ $t('ver_oc') }}</h4>

            <!-- Введення текстового поля для версії операційної системи -->
            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.version_apps.value" placeholder="10">
        </div>
    </div>
    <hr>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
export default ({
    props: ['obj','editform'],
    components: { Multiselect },
    data(){
        return {
                select_maker:"", // Обрана модель терміналу зі списку
                maker_terminal: [ // Список моделей терміналів
                    {
                        "label": "SkyService Emulator",
                        "value": "SkyService Emulator",
                    },
                    {
                        "label": "SmartPay",
                        "value": "SmartPay",
                    },
                    {
                        "label": "Portmone QR",
                        "value": "Portmone QR",
                    },
                    {
                        "label": "SumUP SumUp EMV Terminal",
                        "value": "SumUP SumUp EMV Terminal",
                    },
                    {
                        "label": "Ingenico Desk",
                        "value": "Ingenico Desk",
                    },
                    {
                        "label": "Ingenico Move",
                        "value": "Ingenico Move",
                    },
                    {
                        "label": "Ingenico Lane",
                        "value": "Ingenico Lane",
                    },
                    {
                        "label": "Ingenico ICT",
                        "value": "Ingenico ICT",
                    },
                    {
                        "label": "Ingenico IPP",
                        "value": "Ingenico IPP",
                    },
                    {
                        "label": "Ingenico APOS A8",
                        "value": "Ingenico APOS A8",
                    },
                    {
                        "label": "Verifone VX520",
                        "value": "Verifone VX520",
                    },
                    {
                        "label": "Verifone VX805",
                        "value": "Verifone VX805",
                    },
                    {
                        "label": "Verifone VX820",
                        "value": "Verifone VX820",
                    },
                    {
                        "label": "Verifone VX675",
                        "value": "Verifone VX675",
                    },
                    {
                        "label": "Verifone X990",
                        "value": "Verifone X990",
                    },
                    {
                        "label": "Pax A930",
                        "value": "Pax A930",
                    },
                    {
                        "label": "BluePad-50",
                        "value": "BluePad-50"
                    }
                ],
                select_protocol:"", // Обраний протокол підключення зі списку
                protocols: [ // Список протоколів підключення
                    {
                        "title": "SSI"
                    },
                    {
                        "title": "JSON"
                    },
                    {
                        "title": "BPOS"
                    },
                    {
                        "title": "BPOS Light"
                    },
                    {
                        "title": "Binary"
                    }
                ],
                select_oc:"", // Обраний тип операційної системи зі списку
                oc_types: [ // Список типів операційних систем
                    {
                        "title": "Windows"
                    },
                    {
                        "title": "Android"
                    },
                    {
                        "title": "IOS"
                    }
                ],
                banks: [ // Список банків
                    {
                        "title": "ПриватБанк"
                    },
                    {
                        "title": "А-Банк"
                    },
                    {
                        "title": "FUIB (Партнери ПЦ ПУМБ)"
                    },
                    {
                        "title": "Восток"
                    },
                    {
                        "title": "Південний"
                    },
                    {
                        "title": "Monobank"
                    },
                    {
                        "title": "OKSI"
                    },
                    {
                        "title": "Конкорд"
                    }
                ],
                select_type_connect:"", // Обраний тип підключення зі списку
                connect_types: [ // Список типів підключення
                    {
                        "title": "USB",
                    },
                    {
                        "title": "Lan/ Wi-Fi"
                    },
                    {
                        "title": "Bluetooth"
                    }
                ],
                form: { // Об'єкт форми з даними про банківський термінал
                    model: {
                        icon: "ri-cpu-fill",
                        label: "bank_terminal_model",
                        value: ""
                    },
                    bank: {
                        icon: "ri-cpu-fill",
                        label: "title_bank",
                        value: ""
                    },
                    protocol_type: {
                        icon: "mdi-connection",
                        label: "protocol_connect",
                        value: ""
                    },
                    connect_types: {
                        icon: "mdi-connection",
                        label: "connection_method",
                        value: ""
                    },
                    oc_types: {
                        icon: "ri-user-2-fill",
                        label: "operatingSystem",
                        value: ""
                    },
                    version_os: {
                        icon: "ri-user-2-fill",
                        label: "ver_oc",
                        value: ""
                    },
                    version_apps: {
                        icon: "ri-user-2-fill",
                        label: "verSkyService",
                        value: ""
                    },
                }
            }
        },
        created(){

            // Якщо передані дані, встановлюємо їх у форму
            if(this.obj != ''){
                this.form = this.obj
            }
        },
        methods: {
            input(){
                // Обробка події введення
                this.$emit('input', this.form)
            }
        },
        mounted() {
        }
    }
)
</script>
