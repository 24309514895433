<template>
    <!-- посадова інструкція -->
    <modal :title="$t('jobDescription')">
        <template v-slot:head-bottom>
            <div class="" >
                <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
            </div>
        </template>
        <template v-slot:body>
            <b-card>
                <h4 class="text-center"><b>{{$t('jobDescription')}}</b></h4>
                <QuillEditor v-model:content="form.jobDesc" theme="snow" contentType="html" toolbar="full"/>
            </b-card>
        </template>
        <template v-slot:footer-bottom>
           <a class="btn btn-link link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</a>
           <b-button  @click="add(form)" variant="success" class="waves-effect waves-light"><i class="align-bottom me-1"></i>{{ $t('Add') }}</b-button>
       </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/modal-lg'
import { Users } from '@/API.js';

let apiServe = new Users();

export default{
    props: ['obj'],
    components: { modal },
    data() {
        return {
            form: {
                jobDesc: ""
            }
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj
        },
        add(){
            apiServe.editUser(this.form.id, this.form).then(res => {
                if(res){
                    this.$toast.success(`Изменения прошли успешно `);
                    this.$emit('close')
                    this.eventBus.emit('editUser', true)
                }
            })
        }
    }
    
}
</script>