<template>
  <!-- 
    Глобальний компонент таблиці
    - 
  -->

  <b-row>
    <b-col>
      <b-card 
        v-if="this.showpag 
              || (this.objParams && this.objParams.hasOwnProperty('search')) 
              || (this.objParams && this.objParams.hasOwnProperty('pr1') && this.objParams.hasOwnProperty('pr2') && this.showCalendar == true)
              || this.dataParams?.status === true
            "
      >

        <!-- Контейнер для першого рядка з обмеженнями та пошуком -->
        <b-row id="step1">
          <div class="col" style="display:flex" id="limits">

            <!-- Випадаючий список для вибору кількості елементів на сторінці -->
            <div class="pagination-wrap hstack gap-2" style="margin-right:5px;" v-if="this.showpag">
              <select class="form-select mb-0" v-model="limit" @change="changelimit(limit)">
                <option v-for="item in limits" :key="item" :value="item.value" >{{item.value}}</option>
              </select>
            </div>
            
            <!-- 
              Пошук по таблиці
              - @searchB - подія, яка спрацьовує при пошуку
              - йде перевірка на objParams, та чи містить він 'search', якщо ні, поле пошуку не буде відображено 
            -->
            <searchBox 
              @searchB="searchB" 
              v-if="this.objParams && this.objParams.hasOwnProperty('search')" 
              :typesearch="this.typesearch"
            />
            
          </div>

          <!-- Календар та фільтри -->
          <div class="col-auto d-none d-lg-flex d-xl-flex">
            <div class="hstack gap-2">
              <div>

                <!-- Кнопка для відображення календаря -->
                <button 
                  class="mainCalendarButton" 
                  v-if="this.objParams && this.objParams.hasOwnProperty('pr1') && this.objParams.hasOwnProperty('pr2')" 
                  @click="this.showCalendar = !this.showCalendar"
                  style="height: 40px;"
                >
                  {{ this.date.title }}
                </button>

                <!-- 
                  Компонент Календаря
                  - йде перевірка на objParams, та чи містить він 'pr1' та 'pr2', якщо ні, поле вказування дати не буде відображено 
                -->
                <transition name="fade">
                  <filterDateTime 
                    v-if="this.objParams && this.objParams.hasOwnProperty('pr1') && this.objParams.hasOwnProperty('pr2') && this.showCalendar == true" 
                    @setDate="changeFilter"
                    :type="'filter'" 
                    :data="this.date"
                    key="showCalendar"
                    @close="this.showCalendar = false"
                  />
                </transition>

              </div>
            </div>
          </div>

          <!-- Кнопки по праву сторону -->
          <div class="col-auto">
            <div class="hstack gap-2">
              <div>

                <!-- Виклик модального вікна на створення, в кожному розділі викликається особисте вікно -->
                <b-button
                  v-if="this.createdform == true"
                  @click="create"
                  class="btn btn-success"
                >
                  <i class="ri-add-line align-bottom me-1"></i>
                </b-button>
                
              </div>

              <!-- 
                Фільтр для таблиці
                - йде перевірка на наявність параметрів в objParams, dataParams, та чи дорівнює статус true, якщо ні, кнопка фільтрація не буде відображена
                - :componentKey - ключ для перерендерингу блоку фільтрації при зміні даних
                - :dataParams - параметри фільтрації таблиці
                - :objParams - параметри для виклику фільтрації таблиці
                - @changeFilter - подія, яка фільтрує дані в таблиці
                - @clearFilter -  подія, для очищення фільтрів
              -->
              <Filter 
                v-if="objParams && (dataParams && dataParams.status == true)" 
                :componentKey="this.componentKey" 
                :dataParams="dataParams" 
                :objParams="objParams" 
                @changeFilter="changeFilter" 
                @clearFilter="changeFilter('all')"  
                @changeWithClearFilter="changeWithClearFilter"
              />
              
              <!-- 
                Фільтр для таблиці, де немає пагінації та окремого запиту 
                - йде перевірка на наявність параметрів в datafilter
                - :datafilter - параметри фільтрації таблиці
                - @change - подія, яка фільтрує дані в таблиці
                - @clearFilter -  подія, для очищення фільтрів
              -->
              <FilterTable 
                v-if="this.datafilter" 
                :datafilter="datafilter"
                @change="change" 
                @clearFilter="clearFilter"  
              />
              
              <!-- Кнопка для видалення обраних записів -->
              <div 
                class="flex-shrink-0" 
                v-if="this.allSelected == true || this.checkrows != ''"
              >
                <button 
                  class="btn btn-soft-danger" 
                  v-if="this.removeitem == true" 
                  @click="this.removeAll(this.checkrows)"
                >
                  {{ this.checkrows.length }}
                  <i class="ri-delete-bin-2-line"></i>
                </button>

              </div>
            </div>
          </div>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
    
  <!-- Рядок для відображення табів та вмісту таблиці -->
  <b-row>
    <b-col>

      <!-- Лоадер -->
      <preloaderTable v-if="preloaderTable" />

      <b-card>
        <div style="display: flex;">

          <!-- Таби по таблиці -->
          <ul 
            :class="`nav nav-tabs nav-tabs-custom`" 
            role="tablist" 
            style="width: 100%; overflow: auto!important; margin-bottom: 11px;"
          >
            <!-- Таб "Всі" -->
            <li class="nav-item" >
                <a 
                  class="nav-link  All py-2 fs-14 active" 
                  data-bs-toggle="tab" 
                  id="All" 
                  href="#home1" 
                  role="tab"
                  @click="changeFilter('all')" 
                >
                  <b>{{$t('all')}}</b>
                </a>
            </li>

            <!-- Таби, які додав юзер -->
            <template 
              v-for="(tab, index) in tabs" 
              :key="index"
            >
              <li 
                v-if="tab.checked == true"  
                class="nav-item" 
                style="margin-left: 4px;" 
              >
                <a 
                  :class="`nav-link py-2 ${tab.tab} text-white fs-14`" 
                  data-bs-toggle="tab" 
                  :id="tab.tab" 
                  :href="`#${tab.tab}`" 
                  role="tab"
                  @click="changeTab(tab.obj, tab.value)" 
                  aria-selected="false" 
                  :style="`background-color: ${tab.color}`"
                >
                  <!-- Назва табу -->
                  <b>{{ tab.title }}</b>
                </a>
              </li>
            </template>

            <!-- Кнопка для додавання/редагування табу -->
            <li v-if="addtabs" class="nav-item copy" >
                <a 
                  :class="`nav-link py-2 fs-14`"
                  @click="addCategory()"
                >
                  <b>{{ tabs.length > 0 ? $t('Edit') : $t('Add')}}</b>
                </a>
            </li>
          </ul>

        <!-- Додаткові налаштування таблиці -->
        <div class="dropdown">
          <div class="btn-group">

            <!-- Кнопка для відкриття випадаючого списку з колонками таблиці -->
            <button class="btn btn-ghost-secondary btn-icon btn-sm fs-18" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
              <i class="bx bx-table align-bottom"></i>
            </button>

            <!-- Список з вибором колонок -->
            <ul class="dropdown-menu  list-group-flush border-dashed px-3" aria-labelledby="dropdownMenuClickableInside">
              <li class="list-group-item ps-0" v-for="item in columns" :key="item">
                <div class="d-flex align-items-start">
                  <!-- Форма для вибору колонки -->
                  <div class="form-check ps-0 flex-sharink-0">
                      <input type="checkbox" class="form-check-input ms-0" :id="item.name" v-model="item.status" :checked="item.status" @change="changeColumn(item)" />
                  </div>
                  <!-- Назва колонки -->
                  <div class="flex-grow-1">
                      <label class="form-check-label mb-0 ps-2" :for="item.name">{{item.name}}</label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Таблиця з даними, якщо є рядки для відображення -->
      <div v-if="rows.length > 0" class="table-responsive table-card mb-4" >

        <!-- 
          Таблиця для відображення даних
          - :needFixed - Фіксований розмірам. Використовується для коментів в картці клієнта, щоб не спрацювував горизонтальний скрол
        -->
        <table
          :style="columns[0]?.needFixed == true ? 'width: 100% !important; word-break: break-all; table-layout: fixed;' : ''"
          class="table align-middle table-nowrap mb-0"
          id="customerTable"
          ref="table"
        >
          <!-- Заголовок таблиці -->
          <thead class="table-light text-muted">
            <tr>

              <!-- Колонка для вибору всіх рядків -->
              <th 
                v-if="this.checked == true || this.removeitem == true" 
                scope="col" 
                style="width: 40px" 
              >
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkAll"
                    value="option"
                    v-model="this.allSelected"
                    @click="selectAll"
                  />
                </div>
              </th>

              <!-- 
                Динамічні колонки 
                - :columns - конфігурація стовпців таблиці, у кожного розділу власна конфігурація
              -->
              <template v-for="(column, ind) in columns" :key="column.id">
                <th
                  v-if="column.status == true"
                  :class="`text-left ${column.hasOwnProperty('sort') ? 'copy' : ''}`"
                  @click="column.hasOwnProperty('sort') ? sort(column, objParams.ot) : ''"
                  :style="{ position: 'relative', width: column.width + 'px' }"
                  @mousedown="startMove($event, ind)"
                  @mousemove="move($event)"
                  @mouseup="stopMove"
                >

                  <!-- Плавна зміна ширини колонки -->
                  <div
                    style="cursor: col-resize;right: -5px;position: absolute;top: 0;width: 10px;bottom: 0;"
                    @mousedown="startMove($event, ind)"
                    @mousemove="move($event)"
                    @mouseup="stopMove"
                  ></div>
                    <v-btn
                      text
                      block
                      x-large
                      depressed
                    >
                      <!-- Назва колонки -->
                      {{ column.name }}
                      <i 
                        v-if="column.hasOwnProperty('sort')" 
                        :class="`bx bx-${objParams.ot == 'desc' ? 'down' : 'up'}-arrow-alt`"
                      ></i>
                    </v-btn>
                  </th>
              </template>
            
              <!-- Колонка дій, якщо вибрано видалення або скидання пароля -->
              <th
                v-if="removeitem || resetpass"
                style="display: none;"
                scope="col"
              >
                {{$t('action')}}
              </th>

            </tr>
          </thead>

          <!-- 
            Тіло таблиці
            - :rows - масив з даними рядків таблиці
          -->
          <tbody v-for="(row, index) in rows" :key="index">

            <!-- Умова для виключення конкретних рядків (для Бориса) -->
            <tr 
              @contextmenu.prevent="handleContextMenu($event, row) "
              :class="`tr_etner ${activeRow === index && this.user.userid != 36 ? 'active' : ''}`" 
              @click="setActiveRow(index)"
              v-if="(row?.userPhone !== '0637006095') && (row?.userPhone !== '0737006095') && (row?.userPhone !== '0630476445')" 
            >

            <!-- Колонка для вибору окремого рядка -->
            <th 
              v-if="this.checked == true || this.removeitem == true"
              scope="row" 
            >
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="checkAll"
                  v-model="checkrows"
                  @change="select(row, index)"
                  :value="row"
                />
              </div>
            </th>

            <!-- Динамічно генеровані колонки для відображення даних -->
            <template 
              v-for="column in columns" 
              :key="column"
            >
              <td
                :style="column?.needFixed == true ? 'word-break: break-all; white-space: unset;' : ''"
                :class="column.align"
                v-if="column.status == true"
                @click="open(row)"
              >
                
                <template v-if="column.text != 'progressPercent'">

                  <!-- Вміст колонки з можливістю копіювання -->
                  <div
                    v-if="column.copy == true"
                    class="ctext-wrap c_block"  
                    style="display:flex"
                  >
                      <div class="ctext-wrap-content">

                        <!-- 
                          Вміст колонки 
                          - mutate - використовується для змінення данихи, наприклад переклад
                          - mutateClass - додаткові стилі
                        -->
                        <span 
                          v-html="column.mutate ? column.mutate(row[column.text], row) : row[column.text]" 
                          :class="`${column.mutateClass ? column.mutateClass(row[column.text], row) : 'none'}`"
                        ></span>

                      </div>

                      <!-- Кнопка для копіювання значення колонки -->
                      <i class="ri-file-copy-2-fill fs-14 copybtn" @click="copy(row[column.text])" ></i>
                  </div>

                  <!-- Вміст колонки без можливості копіювання -->
                  <span 
                    v-else 
                    v-html="column.mutate ? column.mutate(row[column.text], row) : row[column.text]" 
                    :class="`fs-12 ${column.mutateClass ? column.mutateClass(row[column.text], row) : 'none'}`"
                  ></span>
                
                </template>

                <!-- Для колонки прогрес-бара -->
                <template v-if="column.text == 'progressPercent'">
                  <div class="d-flex align-items-center">
                    <!-- Відсоток прогрес-бару -->
                    <div class="flex-shrink-0 me-1 text-muted fs-13">{{ row[column.text] }} %</div>

                    <!-- Сам прогрес-бар -->
                    <div
                        class="progress progress-sm flex-grow-1 bg-soft-primary"
                        style="width: 68%"
                    >
                      <div
                      class="progress-bar bg-primary rounded"
                      role="progressbar"
                      :style="'width: ' + row[column.text] + '%'"
                      aria-valuenow="53"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </template>

              </td>
            </template>
            
            <!-- Колонка дій для видалення або скидання пароля -->
            <td v-if="removeitem || resetpass" style="display: none;">
              <ul class="list-inline hstack gap-2 mb-0">

                <!-- Кнопка для скидання пароля -->
                <li
                  v-if="resetpass"
                  class="list-inline-item"
                  :title="$t('reset')"
                >
                  <button class="btn btn-soft-primary small_btn " v-if="this.resetpass == true" @click="resetpassbox(row)"><i class="ri-lock-unlock-fill"></i></button>
                </li>

                <!-- Кнопка для видалення -->
                <li
                  v-if="removeitem"
                  class="list-inline-item"
                  :title="$t('Delete')"
                >
                  <button class="btn btn-soft-danger small_btn " v-if="this.removeitem == true" @click="removeBox(row)"><i class="ri-delete-bin-2-line"></i></button>
                </li>
              </ul>
            </td>
          </tr>
          
          <!-- Відображення дочірніх рядків -->
          <template 
            v-for="ch in row.child"  
            v-if="row.hasOwnProperty('child')"
          >
              <button 
                type="button" 
                class="btn btn-info waves-effect m-2 fs-12 fw-semibold" 
                v-html="ch.terminalName" 
                @click="$emit('openTerminal', ch)"
              ></button>
          </template>

        </tbody>
      </table>

          <!-- Відображення інформації про загальну кількість та пагінації -->
          <div style="margin-top:20px;margin-left:10px;" >
            <h5>
              <b>{{ $t('Total') }} : </b>{{rows.length}}
              <span v-if="this.showpag">{{ $t('outOf') }} {{ this.pages.total ? this.pages.total : this.pagesr }}</span>
            </h5>
          </div>

          <!-- 
            Пагінація
            - йде перевірка чи потрібна пацінація
          -->
          <div v-if="this.showpag" class="d-flex justify-content-start mt-3" style="margin-left:10px;">

            <!-- Вибір кількості елементів на сторінці -->
            <div class="pagination-wrap hstack gap-2" style="margin-right:5px;">
              <select class="form-select mb-0" v-model="limit" @change="changelimit(limit)">
                <option v-for="item in limits" :key="item" :value="item.value" >{{item.value}}</option>
              </select>
            </div>

            <!-- Відображення номерів сторінок -->
            <div class="pagination-wrap hstack gap-2">
              <ul class="pagination listjs-pagination mb-0">
                <li v-for="(pageNumber, index) in this.pages.links" :key="index"
                  :class="pageNumber.current == 'true' ? 'active' : null"
                  @click="getdata(pageNumber.page)"
                >
                  <button class="page">{{ pageNumber.page }}</button>
                </li>
              </ul>
            </div>

          </div>
        </div>

        <!-- Відображення повідомлення про відсутність результатів -->
        <div v-else class="noresult" style="display: block;">

          <div class="text-center" v-if="checks['024'] == false">
            <h5 class="mt-2">{{ $t('SorryNoResultFound') }}</h5>
            <p class="text-muted mb-0">{{ $t('WeveSearchedMoreThan2') }}</p>
          </div>
          <div class="text-center" v-else>
            <img class="gallery-img img-fluid mx-auto" style="max-width: 30%;" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Naruto_Shiki_Fujin.svg/1200px-Naruto_Shiki_Fujin.svg.png" alt="">
            <h5 class="mt-2">{{ $t('pashNotFound') }}</h5>
            <p class="text-muted mb-0">{{ $t('pashNotFoundDesc') }}</p>
          </div>

        </div>
      </b-card>
    </b-col>
  </b-row>
  

  <!-- Модальне вікно для підтвердження видалення -->
  <dialodel 
    v-if="dialogshow" 
    @close="dialogshow = false" 
    :obj="item" 
    @remove="remove" 
  />

  <!-- Модальне вікно для підтвердження скидання пароля -->
  <dialogresetpass 
    v-if="dialogresetshow" 
    @close="dialogresetshow = false" 
    :obj="item" 
    @resetPass="resetPass" 
  />

</template>

<script>
import {getMonth} from '@/usabilityScripts/globalMutate.js'
import Filter from "./globalFilter.vue"; // Фільтр по таблиці з запитом
import FilterTable from "./frontFilter"; // Фільтр для таблиці, де немає пагінації та окремого запиту 
import searchBox from "./searchbox.vue";
import dialodel from './actions/dialogdelete'
import dialogresetpass from './actions/dialogresetpass'
import preloaderTable from './preloader'
import filterDateTime from '../calendar'
import { storeS } from '@/store.js'
import {copy} from '@/usabilityScripts/copy.js'

export default {
  /*
    Про пропси - 
    - checked: Булеве значення, що вказує, чи включено виділення рядків.
    - checkedItems: Масив, що містить виділені рядки.
    - datafilter: Об'єкт, що містить фільтри для таблиці.
    - objParams: Параметри таблиці.
    - dataParams: Додаткові параметри таблиці.
    - tabs: Масив табів таблиці.
    - rows: Масив рядків для відображення в таблиці.
    - columns: Масив колонок таблиці.
    - createdform: Об'єкт, що містить дані про створення форми.
    - removeitem: Булеве значення, яке вказує, чи включена опція видалення рядків.
    - resetpass: Булеве значення, яке вказує, чи включена опція скидання паролю.
    - addtabs: Булеве значення, яке вказує, чи включена опція додавання закладок.
    - typesearch: Тип пошуку в таблиці.
    - showpag: Булеве значення, яке вказує, чи відображається пагінація.
    - pages: Інформація про сторінки для пагінації.
    - showContxtMenu: Булеве значення, яке вказує, чи відображати контекстне меню.
  */
  props:[ 
    'checked', 
    'checkedItems', 
    'datafilter', 
    'objParams', 
    'dataParams', 
    'tabs', 
    'rows', 
    'columns', 
    'createdform', 
    'removeitem', 
    'resetpass', 
    'addtabs', 
    'typesearch', 
    'showpag',
    'pages',
    'showContxtMenu'
  ],
  data() {
    return {
      showCalendar: '', // Прапорець, що вказує, чи відображається календар
      searchTime: '', // Ідентифікатор таймера для затримки пошуку
      search: "", // Рядок для пошуку в таблиці.
      selected: [], // Масив виділених рядків
      allSelected: false, // Булеве значення, що вказує, чи обрані всі рядки.
      dialogshow: false, // Прапорець на відображення вікна видалення
      dialogresetshow: false, // Прапорець на відображення вікна скидання паролю.
      dialocopyshow: false,// Прапорець на відображення вікна копіювання
      checkrows: [], // Масив для зберігання виділених рядків.
      item: "", // Об'єкт для зберігання інформації про рядок.
      page: 1, // Номер поточної сторінки.
      perPage: 5, // Кількість елементів на сторінці.
      limit: "", // Рядок, що вказує обрану кількість елементів на сторінці.
      date: '', // Об'єкт, що містить інформацію про вибрану дату.
      limits: [ // Масив об'єктів, що містять варіанти кількості елементів на сторінці.
        { value: '5'},
        { value: '10'},
        { value: '25'},
        { value: '50'},
        { value: '75'},
        { value: '100'},
        { value: this.$t('all')},
      ],
      draggable: false, // Булеве значення, що вказує, чи можна переміщати колонки.
      startOffset: 0, // Значення зсуву для початку переміщення колонки.
      selectedCol: null, // Індекс обраної колонки для переміщення
      activeRow: null
    }
  },
  components: {
    Filter,
    FilterTable,
    searchBox,
    dialodel,
    dialogresetpass,
    preloaderTable,
    filterDateTime
  },
  created(){
    console.log(this.objParams, this.dataParams, this.objParams && (this.dataParams && this.dataParams.status == true))

    this.limit = this.objParams?.pagelimit ? this.objParams.pagelimit : '25'
    
    this.date = {
      title: this.currentDate().day + ' ' + this.$t(`${this.currentDate().month}`) + ', ' +this.currentDate().year,
      value: this.currentDate().year + '-' + new Date().getMonth() + this.currentDate().day,
      month: this.currentDate().monthNum
    }

    if(this.checkedItems || this.checkedItems != undefined){
      this.getChecked()
    }

  },
  methods: {
    currentDate() {
      // Повертає об'єкт з інформацією про поточну дату

      var date = new Date();
      var dateStr = {
          day: ("00" + (date.getDate())).slice(-2),
          month: (this.month(date.getMonth() + 1)),
          year: date.getFullYear(),
          monthNum: date.getMonth()
      }
      return dateStr
    },
    month(e) {
      // Повертає назву місяця за його номером
      return getMonth(e)
    },
    open: function(e){
      // Відкриваємо вікно картки з розділу виклику
      this.$emit("open", e);
    },
    setActiveRow(index) {
      // Додаємо клас active до обраної строки (яку відкрили)
      this.activeRow = index;
    },
    changeColumn(e){
      if(this.dataParams?.page){
        localStorage.setItem(this.dataParams.page + " " + String(this.dataParams.status), JSON.stringify(this.columns))
      }
      // Змінюємо відображення колонки
      this.$emit('changeColumn', e)
    },
    getChecked(){
      // Заповнює масив виділених рядків при старті компонента
      for(var item in this.checkedItems){
        this.checkrows.push(this.checkedItems[item])
      }
    },
    move(e) {
      // Обробник події переміщення колонки
      if (this.draggable) {
        let newWidth = this.startOffset + e.pageX;
        let colObj = this.columns[this.selectedCol];
        colObj.width = newWidth;
      }
    },
    startMove(e, ind) {
      // Обробник події початку переміщення колонки
      this.draggable = true;
      this.startOffset = this.columns[ind].width - e.pageX;
      this.selectedCol = ind;
    },
    stopMove() {
      // Обробник події закінчення переміщення колонки
      this.draggable = false;
    },
    create(){
      // Викликаємо вікно створення
      this.$emit('create')
    },
    searchB(e){
      // Обробник події пошуку з затримкою

      clearTimeout(this.searchTime)
      this.search = e
      this.searchTime = setTimeout(() => this.$emit('search', e), 500)
    },
    changeWithClearFilter(name, value) {
      this.$emit('changeWithClearFilter', name, value)
    },
    changeFilter(nameFilter, filterChange){
      //для зміни фільтрації

      if(nameFilter == 'pr') {
        if(filterChange[0] === '') {
          return
        }
        else if(filterChange[0] == filterChange[1]) {
          this.date = {
            title: this.date = filterChange[0].split('-')[2] + ' ' + this.$t(this.month(filterChange[0].split('-')[1])) + ' ' + filterChange[0].split('-')[0],
            value: this.date = filterChange[0].split('-')[2] + ' ' + filterChange[0].split('-')[1] + ' ' + filterChange[0].split('-')[0]
          }
        } else {
          this.date = {
            title: filterChange[0].split('-')[2] + ' ' + this.$t(this.month(filterChange[0].split('-')[1])) + ' ' + filterChange[0].split('-')[0] + ' - ' + filterChange[1].split('-')[2] + ' ' + this.$t(this.month(filterChange[1].split('-')[1])) + ' ' + filterChange[1].split('-')[0],
            value: filterChange[0].split('-')[2] + ' ' + filterChange[0].split('-')[1] + ' ' + filterChange[0].split('-')[0] + ' - ' + filterChange[1].split('-')[2] + ' ' + filterChange[1].split('-')[1] + ' ' + filterChange[1].split('-')[0]
          }
        }
      }
      this.$emit('changeFilter', nameFilter, filterChange)
    },
    changeTab(nameFilter, filterChange){
      // Зміна активної вкладки/табу
      this.eventBus.emit('refreshFilters', {})
      this.$emit('changeTab', nameFilter, filterChange)
    },
    // change(name, e){
    //   // Фільтрує дані в таблиці 
    //   this.$emit('change', name, e)
    // },
    addCategory(){
      // Відкриваємо вікно створення табу на сторінці виклику
      this.$emit('addCategory')
    },
    getdata(e){
      // отримання даних по таблиці
      this.$emit('getdata', e)
    },
    selectAll: function() {
      // Вибирає всі рядки в таблиці.
      this.checkrows = [];
      if (this.allSelected == false) {
          for (var item in this.rows) {
              this.checkrows.push(this.rows[item]);
          }
      }
      this.$emit('check', this.checkrows)
    },
    select(checked, item) {
      // Обробник події вибору рядка
      this.allSelected = false;
      this.$emit('check', this.checkrows)
    },
    removeAll(e){
      // Видалення всіх даних
      this.$emit("removeAll", e);
    },
    // remove(e){
    //   // Видалення даних по рядку

    //   e.status = "Removed"
    //   this.$emit("remove", e, e);
    //   this.dialogshow = false
    // },
    removeBox(e){
      // Відображає діалог видалення для конкретного рядка.
      this.item = e;
      this.dialogshow = true
    },
    // resetPass(e){
    //   // Скидання паролю

    //   this.$emit("resetPass", e);
    //   this.dialogresetshow = false;
    // },
    resetpassbox(e){
      // Відображає діалог скидання паролю для конкретного рядка

      this.item = e;
      this.dialogresetshow = true;
    },
    copy(item){
      // копіювання
      if(copy(item) == true) {
          this.$toast.success(this.$t('Copied'));
      } else {
          this.$toast.error(`Fucking mistake, contact Borys`);
      }
      return
    },
    changelimit(limit){
      // storeS.pagelimit = limit
      // Подія для зміни кількості елементів на сторінці

      var newLimit = ''
      if(this.pages && limit == this.$t('all')){
        newLimit = this.pages.total
      } else {
        newLimit = limit;
      }
      
      this.$emit('changelimit', newLimit)
    },
    sort(e, ot){
      this.$emit('sortColumn', e, ot)
    },
    handleContextMenu(event, row) {
      // Викликаємо метод показу контекстного меню після оновлення DOM
      this.$nextTick(() => {
        this.$emit('showMenu', event, row);
        
      });
    },
    handleClickOutside(event) {
      // Прибираємо клас active, якщо натиснути поза межами таблиці

      // Отримати елемент, на який клікнули
      const targetElement = event.target;
      // Отримати елемент таблиці
      const tableElement = this.$refs.table;

      if (event.target.closest('.modal')) {
        return; // Ігноруємо кліки всередині модального вікна
      }

      if (!tableElement?.contains(targetElement)) {
        // Якщо так, обнулити activeRow
        this.activeRow = null;
      }
    }
  },
  mounted(){

    // Додаємо обробник події кліків при монтажі компонента
    document.addEventListener('click', this.handleClickOutside);

    document.addEventListener('mouseup', this.stopMove);
    document.addEventListener('mousemove', this.move);

    if(this.dataParams?.page){
      setTimeout(() => {
          let arr = JSON.parse(localStorage.getItem(this.dataParams.page + " " + String(this.dataParams.status)))
          if(Array.isArray(arr)) {
          arr.forEach(el => {
            this.columns[this.columns.indexOf(this.columns.find(k => el.name == k.name))].status = el.status
          })
        }
      }, 2000) //костиль з таймером, щоб встигнути підтягнути дані з localStorage
    }
  },
  beforeDestroy() {
    // Видаляємо обробник події кліків перед знищенням компонента
    document.removeEventListener('click', this.handleClickOutside);
  },
  beforeMount() {
    document.removeEventListener('mouseup', this.stopMove); document.removeEventListener('mousemove', this.stopMove);
  },
  computed: {
    user(){
      /*
        Повертає дані користувача з глобального стору.
      */
      return storeS.userbase
    },
    perms(){
      /*
        Повертає дані прав доступу користувача з глобального стору.
      */
      return storeS.perms
    },
    checks(){
      /*
        Повертає дані налаштувань користувача з глобального стору.
      */
      return storeS.checks
    },
    preloaderTable(){
      /*
        Повертає статус лоадера
      */
      return storeS.preloaderTable
    }
  }
}
</script>

<style>
/* <style scoped> */
.tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
}
.tr_etner.active {
  background: #212222db;
  color: white;
  transition: .3s;
}
.small_btn {
  max-width: 48px;
  max-height: 30px;
  padding: 0.3rem 1.0rem;
}
/* .tr_etner:hover .small_btn {
  color: #fff;
  background-color: #f06548;
  border-color: #f06548;
} */
[data-layout-mode="dark"] .tr_etner:hover {
  background: #09b39c45;
}

[data-layout-mode="dark"] td.left {
  font-weight: 600;
}

.cross-btn {
  display: none
}

a.nav-link:hover .cross-btn {
  display: inline-block
}

.copybtn {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity .3s;
}
.c_block:hover .copybtn {
    opacity: 1;
    transition: opacity .3s;
}

.table-nowrap td, .table-nowrap th {
  white-space: inherit;
  padding: 17px 16px;
  /* word-break: break-word; */
}
th.text-left {
  border-left: 1px solid #74747433;
  border-right: 1px solid #74747433;
}

.mainCalendarButton{
    height: 30px;
    border: none;
    border-radius: 0.25rem;
    background-color: #eff2f7;
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    width: 200px;
}
[data-layout-mode="dark"] .mainCalendarButton {
  background-color: #1A1D1E !important;
  color: white
}
[data-layout-mode="dark"] #All{
  color: white !important
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>