<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0"><b>{{ $t('generalInfo') }}</b></h5>
        </div>
        <div class="card-body">
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-sucess rounded-circle fs-16 text-white`" >
                            <i :class="`bx bxs-face`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('appointedManager') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`mb-0 fs-14 `" >
                                    {{ this.form.assignedWorkers.length > 0 ? this.form.assignedWorkers[0].workerName : '-' }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-sucess rounded-circle fs-16 text-white`" >
                            <i :class="`bx bxs-phone`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" v-if="this.form.companyId">
                        <p class="mb-1 fs-16">{{ $t('company') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content" style="cursor: pointer;" @click="this.$emit('getClient', this.form.companyId)">    
                                <h6 :class="`mb-0 fs-14 `" > {{ this.form.companyId }}</h6>
                            </div>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" v-else>
                        <p class="mb-1 fs-16">{{ $t('company') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">    
                                <h6 :class="`mb-0 fs-14 `" >  - </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-sucess rounded-circle fs-16 text-white`" >
                            <i :class="`bx bx-time`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('priority') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 style="color: white;" :class="`mb-0 fs-14 badge ` + this.mutatePriority(this.form.priority).color" > {{ this.mutatePriority(this.form.priority).name }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-sucess rounded-circle fs-16 text-white`" >
                            <i :class="`bx bx-comment`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <!-- Призначенний менеджер -->
                        <p class="mb-1 fs-16">{{ $t('type') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`mb-0 fs-14 badge ` + this.mutateType(this.form.type).color" > {{ this.mutateType(this.form.type).name }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import { mutatePriorityTicket, mutateMonitoringType } from '@/usabilityScripts/globalMutate'

export default{
   props: ['obj'],
   data(){
       return {
           form: ''
       }
   },
   created(){
       this.form = this.obj
   },
   methods: {
        mutatePriority(e){
            console.log(e);

            return mutatePriorityTicket(e)
        },
        mutateType(e) {
            console.log(e);
            
            return mutateMonitoringType(e)
        },
   }
}
</script>

<style lang="css" scoped>
.card-body {
    padding: 0.5rem !important;
}
</style>