<template>
  <!-- 
    Компонент для відображення списку онлайн працівників та користувачів ATS.
    - При кліку на кнопку відкривається випадаюче меню з вкладками для перегляду різних груп працівників та користувачів.
    - Всі дані відображаються у вигляді вкладок з можливістю пошуку. 
  -->

  <div class="dropdown">
    <!-- Кнопка з іконкою та кількістю онлайн працівників -->
    <button 
      class="btn btn-ghost-secondary btn-icon btn-sm fs-18" 
      type="button" 
      id="dropdownMenuClickableInside" 
      data-bs-toggle="dropdown" 
      data-bs-auto-close="outside" 
      aria-expanded="false"
    >
      <i class="ri-team-fill fs-22"></i>
      <span 
        :class="`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-${usersWS.length > 5 ? 'success' : 'danger'}`"
      >
        {{ usersWS.length }}
      </span>
    </button>

    <!-- Випадаюче меню з онлайн працівниками -->
    <div 
      class="dropdown-menu list-group-flush border-dashed px-3" 
      style="padding: 0 !important;" 
      aria-labelledby="dropdownMenuClickableInside"
    >
      <!-- Заголовок меню -->
      <div class="dropdown-head bg-secondary rounded-top">
        <div class="p-3">
          <b-row class="align-items-center">
            <b-col>
              <h6 class="m-0 fs-16 fw-semibold text-white">
                {{ $t('Employees') }}
              </h6>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- Вкладки для фільтрації працівників -->
      <div>
        <b-tabs 
          pills 
          content-class="mt-0 onlineBl"
          nav-class="nav nav-tabs dropdown-tabs nav-tabs-custom bg-secondary onlineBl px-2"
          nav-item-class="bg-white"
          active-nav-item-class="bg-white"
        >
          <!-- 
            Вкладка "Усі"
            - йде перевірка на права доступу 610 (співробітники) або 2000 (режим бога)
          -->
          <b-tab 
            v-if="perms[610] || perms[2000]" 
            :title="$t('all') + ' (' + filteredWorkers.length + ') '" active 
          >
            <!-- Поле для пошуку серед усіх працівників -->
            <div class="m-2">
              <input class="form-control" v-model="search" />
            </div>

            <!-- Список усіх працівників -->
            <div 
              v-for="item in filteredWorkers" 
              :key="item"
              @click="perms[2000] || perms[610] ? openWorker(item.workerId) : ''"
              class="text-reset notification-item d-block dropdown-item position-relative copy" 
            >
              <div class="d-flex" >
                <!-- Іконка статусу працівника -->
                <div class="avatar-xs me-3">
                  <div class="avatar-xs me-3">
                      <span 
                        :class="`avatar-title bg-${item.statusShift == 'open' ? 'success' : item.statusShift == 'close' ? 'danger' : 'warning'} rounded-circle fs-16`
                      ">
                      </span>
                  </div>
                </div>

                <!-- Інформація про працівника -->
                <div class="flex-1">
                  <!-- Ім'я працівника -->
                  <h6 class="mt-0 mb-1 lh-base" >
                    {{item.workerName}}
                  </h6>
                  <!-- Статус зміни -->
                  <p class="mb-0 fs-11 fw-medium text-uppercase text-muted" v-if="item.statusShift != 'close'">
                    <span>{{ item.statusShift == 'open' ? 'Work' : this.$t('goBreak') }}</span>
                  </p>
                  <!-- Посада -->
                  <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                    <span>{{ mutateWorkerJob(item.workerJob) }}</span>
                  </p>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Вкладка "Онлайн" -->
          <b-tab :title="'Online' + ' (' + this.usersWS.length + ') '">
            <!-- Список онлайн працівників -->
            <div 
              class="text-reset notification-item d-block dropdown-item position-relative copy" 
              v-for="item in this.onlineUsers" 
              :key="item" 
              @click="(perms[2000] || perms[702]) ? open(item) : ''"
            >
              <div class="d-flex" >
                <!-- Іконка статусу працівника -->
                <div class="avatar-xs me-3">
                  <span 
                    :class="`avatar-title bg-${item.statusShift == 'open' ? 'success' : item.statusShift == 'close' ? 'danger' : 'warning'} rounded-circle fs-16`"
                  >
                  </span>
                </div>

                <!-- Інформація про працівника -->
                <div class="flex-1">
                  <!-- Ім'я працівника -->
                  <h6 class="mt-0 mb-1 lh-base" >
                    {{item.workerName}}
                  </h6>
                  <!-- Статус зміни -->
                  <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                    <span>{{ item.statusShift == 'open' ? 'Work' : this.$t('goBreak') }}</span>
                  </p>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Вкладка "ATS SIP" -->
          <b-tab :title="$t('ATSSip') + ' (' + this.usersATS.length + ') '" @click="this.getOnline">
            <!-- Список онлайн ATS SIP операторів -->
            <template v-if="this.usersATS.length > 0">
              <div class="text-reset notification-item d-block dropdown-item" v-for="item in usersATS" :key="item">
                <div class="d-flex">
                  <!-- Іконка статусу ATS SIP оператора -->
                  <div class="avatar-xs me-3">
                      <span :class="`avatar-title bg-${item.free == 'true' ? 'success' : 'danger'} rounded-circle fs-16`">
                      </span>
                  </div>

                  <!-- Інформація про ATS SIP оператора -->
                  <div class="flex-1">
                    <!-- ID оператора -->
                    <h6 class="mt-0 mb-1 lh-base" >
                        {{item.user_id}} 
                    </h6>
                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                      <!-- Відображаємо статус оператора, його лінії -->
                      <span v-if="checks['024']">{{ item.free == 'true' ? 'добі вільний' : 'добі зайнятий' }}</span>
                      <span v-else>{{ item.free == 'true' ? this.$t('operatorIsFree') : this.$t('operatorIsBusy') }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </template> 
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>

  <!-- 
    Виклик відкриття картки співробітника з іфнормацією по зміні
    - для відкриття застосовується перевірка значення showBox
    - @close - подія, яка спрацьовує при закритті картки
    - :obj - параметр-об'єкт з інформацією по зміні юзера
  -->
  <viewBox 
    v-if="showBox"
    @close="showBox = false"
    :obj="obj"
  />

  <!-- 
    Виклик відкриття картки співробітника
    - для відкриття застосовується перевірка значення showModal
    - @close - подія, яка спрацьовує при закритті картки
    - :obj - параметр-об'єкт з даними по працівнику
  -->
  <viewcard 
    v-if="showModal == true" 
    @close="showModal = false" 
    :objcard="objcard"
  ></viewcard>


</template>

<script>
import viewcard from '@/views/users/view/index'; // картка співробітника
import viewBox from './viewOnl' // картка онлайн співробітника
import { storeS } from '@/store' // глобальне сховище даних
import { Users, ATS } from '@/API.js';  // клас для взаємодії з API 
import { mutateWorkerJob } from '@/usabilityScripts/globalMutate.js'
import { storeSip } from '@/telephony/telephony.js'
import { nameWorker } from '@/usabilityScripts/getNameWorker'

let apiServe = new Users();
let apiATS = new ATS()

export default ({
    components: {
        viewBox,
        viewcard
    },
    data() {
        return{
            obj: '', // об'єкт, який використовується для відображення даних у вікні
            showBox: false, // стан відображення картки співробітника
            search: '', // рядок пошуку
            objcard: '', // об'єкт, який використовується для відображення даних в карточці працівник
            showModal: false, // стан відображення картки онлайн юзера
            onlineUsers: [], // масив, що містить працівників, які знаходяться в онлайні
            onlineATS: [], // масив, що містить працівників телефонії, які знаходяться в онлайні
            checkDark: '', // змінна, яка вказує на вибір теми
            usersATS: '',
        }
    },
    created() {

      this.getData()

      this.checkDark = localStorage.getItem('nightTheme');

      // події під різні дії сокету для регуляції онлайну до перезавантаження
      this.eventB.off('openShift')
      this.eventB.on('openShift', status => {
        console.log('openShift', status)
        if(this.onlineUsers.find(el => el.workerId == status.workerId) == undefined) {
          this.onlineUsers.push({statusShift: 'open', workerName: this.nameWorker(status.workerId)})
        }
      }) // відкриття зміни (працює)

      
      this.eventB.off('goOnBreak')
      this.eventB.on('goOnBreak', status => {
        console.log('goOnBreak', status)
        this.onlineUsers.splice(this.onlineUsers.indexOf(this.onlineUsers.find(el => el.workerId == status.workerId)), 1)
      })  // пішов на перерву (працює)

      this.eventB.off('goFromBreak')
      this.eventB.on('goFromBreak', status => {
        console.log('goFromBreak', status)
        if(this.onlineUsers.find(el => el.workerId == status.workerId) == undefined) {
          this.onlineUsers.push({statusShift: 'open', workerName: status.workerName})
        }
      }) // повернувся з перерви (працює)

      this.eventB.off('closeShift')
      this.eventB.on('closeShift', status => {
        console.log('closeShift', status)
        this.onlineUsers.splice(this.onlineUsers.indexOf(this.onlineUsers.find(el => el.workerId == status.workerId)), 1)
      }) // закриття зміни (працює)

      
      // this.eventB.off('endCall')
      // this.eventB.on('endCall', status => {
      //   console.log('endCall', status, this.onlineATS)
      //   console.log(this.onlineATS.find( el => el.user_id == status.workerName + ` (${status.workerId})` == undefined))
      //   if(this.onlineATS.find( el => el.user_id == status.workerName + ` (${status.workerId})` == undefined)) {
      //     this.onlineATS.push({user_id: status.workerName + ` (${status.workerId})`, free: 'true'})
      //   } else {
      //     this.onlineATS.forEach(el => {
      //       if(el.user_id == status.workerName + ` (${status.workerId})`) {
      //         el.free = 'true'
      //       }
      //     })
      //   }
      // }) // оператор закінчив розмову (працює)
      
      // this.eventB.off('registerInAts')
      // this.eventB.on('registerInAts', status => {
      //   console.log('registerInAts', status, this.onlineATS)
      //   console.log(this.onlineATS.find( el => el.user_id == status.workerName + ` (${status.workerId})`))
      //   if(this.onlineATS.find( el => el.user_id == status.workerName + ` (${status.workerId})`) == undefined) {
      //     this.onlineATS.push({user_id: status.workerName + ` (${status.workerId})`, free: 'true'})
      //   }
      // }) //реєстрація в телефонії (працює)

      // this.eventB.off('unregisterInAts')
      // this.eventB.on('unregisterInAts', status => {
      //   console.log('unregisterInAts', status, this.onlineATS)
      //   this.onlineATS.splice(this.onlineATS.indexOf(this.onlineATS.find(el => el.user_id.split(' ')[1].replaceAll('(', '').replaceAll(')', '') == status.workerId)), 1)
      // }) //дереєстрація в телефонії (працює)
      // // події під різні дії сокету для регуляції онлайну до перезавантаження
    },
    methods: {
      // отримуємо онлайн юзерів в АТС
      getOnline(){
        apiATS.getOnlineUsers().then(result => {
          if(result.status == 'done') {
            this.usersATS = result.data
          }
        })
      },
      // отримуємо онлайн юзерів в АТС
      nameWorker(item){
        /**
          * Отримуємо ім'я працівника
          * @param {object} item - об'єкт, що містить дані працівника
          * @returns {string} - ім'я працівника.
        */
        return nameWorker(item)
      },
      changeOnline(){
        /**
          * Змінює статус онлайн для користувача ATS Sip та оновлює відповідний список.
          - Якщо статус onlineInSip рівний 'free', то додає користувача до списку зі статусом 'free'.
          - Якщо статус onlineInSip не рівний 'free', то змінює статус користувача на 'false' та додає його до списку.
        */

        if(this.onlineInSip == 'free') {
          if(this.onlineATS.find( el => el.user_id.split(' ')[1].replaceAll('(', '').replaceAll(')', '') == this.settingSip.login) == undefined) {
            this.onlineATS.push({user_id: this.settingSip.name + ' (' + this.settingSip.login + ')', free: 'true'})
          }
          this.onlineATS[this.onlineATS.indexOf(this.onlineATS.find( el => el.user_id.split(' ')[1].replaceAll('(', '').replaceAll(')', '') == this.settingSip.login))].free = 'true'
        } else {
          if(this.onlineATS.find(el => el.user_id.split(' ')[1].replaceAll('(', '').replaceAll(')', '') == this.user.uaATS.login) == undefined) {
            this.onlineATS.push({user_id: this.user.name + ' (' + this.user.uaATS.login + ')', free: 'false'})
            return
          }
          this.onlineATS[this.onlineATS.indexOf(this.onlineATS.find( el => el.user_id.split(' ')[1].replaceAll('(', '').replaceAll(')', '') == this.settingSip.login))].free = 'false'
        }
      },
      getData() {
        /**
          Отримуємо дані для відображення списків працівників та налаштувань теми.
          - Заповнює списки onlineUsers та onlineATS даними usersWS та usersATS.
          - Перевіряє статус теми та зберігає його в змінну checkDark.
        */

        this.onlineUsers = this.usersWS

        this.checkDark = localStorage.getItem('nightTheme');
      },
      open(e){
        /**
          Відкриває вікно з об'єктом e та встановлює прапорець showBox в true
        */
        this.obj = e;
        this.showBox = true
      },
      openWorker(e){
        /**
          Відкриваємо картку співробітника
          * @param {number} e - ідентифікатор працівника, для якого отримують інформацію
        */

        apiServe.getUser(e).then(result => {
          if(result.status == 'done') {
            this.objcard = result.data
            this.showModal = true
          } else {
            this.$toast.error(this.$t('err'))
          }
        })
      },
      mutateWorkerJob(e){
        // Повертаємо назву посади
        return mutateWorkerJob(e)
      },
      nameWorker(item){
        // Повертаємо ім'я працівника
        return nameWorker(item)
      }
    },
    computed: {
        user(){
          /*
            Повертає дані користувача з глобального стору.
          */
          return storeS.userbase
        },
        usersWS() {
          /*
            Повертає список користувачів, які знаходяться в онлайн
          */
          return storeS.usersWS;
        },
        // usersATS() {
        //   /*
        //     Повертає дані користувача з глобального стору.
        //   */
        //   return storeS.usersATS
        // },
        checks(){
          /*
            Повертає дані налаштувань користувача з глобального стору.
          */
          return storeS.checks
        },
        perms(){
          /*
            Повертає дані прав доступу користувача з глобального стору.
          */
          return storeS.perms
        },
        workers() {
          /*
            Повертає список з усіма користувачами з глобального стору.
          */
          return storeS.workers
        },
        filteredItems: function() {
          return Object.values(this.usersWS)
              // По полю поиска
              .filter(item => {
                  return this.search == '' || item.login.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
              })
        },
        filteredWorkers: function() {
          return this.workers
              // По полю поиска
              .filter(item => {
                  return this.search == '' || item.workerName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.workerPhone.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
              })
        },
        settingSip(){
          /*
            Повертає дані налаштувань АТС користувача з глобального стору.
          */
          return storeSip.info
        },
        onlineInSip() {
          /*
            Повертає список менеджерів онлайн з глобального стору.
          */
          return storeSip.info.status
        }
    },
})
</script>

<style scoped>
.onlineBl li.nav-item > a.nav-link {
  color: white;
  overflow: auto;
  padding: 0;
  margin: 0;
}
.onlineBl .tab-pane{
  max-height: 400px;
  overflow: auto;
}
.onlineBl {
  overflow: hidden!important;
}

.onlineBl .tab-pane::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.onlineBl .tab-pane::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.onlineBl .tab-pane::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
span {
  font-size: auto;
}
</style>