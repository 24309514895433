<template>
    <modal>
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col>
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('version') }}</h4>
                        <input type="text" class="form-control" id="contactNumber" v-model="form.version" >
                    </div>
                </b-col>
                <b-col>
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('project') }}</h4>
                        <select
                            class="form-select mb-3"
                            data-plugin="choices"
                            v-model="form.update"
                            >
                            <option v-for="item in list" :key="item" :value="item.value">{{ item.label }}</option>
                        </select>
                    </div>
                </b-col>
                <b-col>
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('dateDeadLine') }}</h4>
                        <flat-pickr
                            v-model="form.deadlineDate"
                            :config="config"
                            class="form-control"
                        ></flat-pickr>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="mb-3">
                        <label class="form-label">{{ $t('desc') }}</label>
                        <textqe @input="inputText" :objText="this.form.comment" />
                        <!-- <QuillEditor v-model:content="form.comment" theme="snow" contentType="html" toolbar="full"/> -->
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <!-- <button class="btn link-success fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
            <button type="button" class="btn btn-success" v-on:click="add">{{ $t('Add') }}</button>
        </template>
    </modal>
 </template>
 
<script>
import textqe from '@/components/textQE' // компонент текстового редактора
import modal from '@/components/modal-small'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import { Updates } from '@/API.js';
import { storeS } from '@/store.js' 
 
 let apiServe = new Updates();
 
export default ({
    props:['type', 'date'],
    data(){
        return {
            config: {
                mode: "single",
                minDate: "today",
                altInput: true,
                altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: ""
            },
            form: {
                version: "",
                deadlineDate: "",
                update: "",
                comment: ''
            },
            list: [
                { value: 'all', label: this.$t('all') },
                { value: 'skyservice', label: 'SkyService' },
                { value: 'posterminal', label: 'Pos-terminal' },
                { value: 'dashboard', label: 'Dashboard' },
                { value: 'skymarket', label: 'Sky Market' },
                { value: 'sellup', label: 'SellUP' },
                { value: 'hallscreen', label: this.$t('orderBoard') },
                { value: 'inside', label: 'Inside' },
                { value: 'android', label: this.$t('onlyForAndroid') },
                { value: 'ios', label: this.$t('onlyForIOS') },
            ]
        }
    },
    components: { modal, flatPickr, textqe },
    created(){
        if(this.type) {
            this.form.update = this.type
        }
        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }

        if(this.date) {
            this.form.deadlineDate = this.date
        }
    },
    methods: {
        inputText(text) {
            // Оновлення текстового поля форми з введеним текстом
            this.form.comment = text.target ? text.target.innerHTML : text;
        },
        add() {
            if (!this.form.version) {
                //перевіряємо чи вказана версія
                this.$toast.error(this.$t('EnterVersion'));
            }
            if (!this.form.deadlineDate) {
                //перевіряємо чи вказана дата
                this.$toast.error(this.$t('specifyDate'));
            }
            if (!this.form.comment) {
                //перевіряємо чи вказаний опис 
                this.$toast.error(this.$t('EnterDesc'));
            }

            if(this.form.version && this.form.deadlineDate && this.form.comment){
                apiServe.addUpdate(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('getData') //для тікетів
                        this.$emit('close')
                        this.eventBus.emit('saveUpdate', true)
                    } else {
                        this.$toast.error(this.$t("error"))
                    }
                })
            }
        },   
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
 })
</script>