<template>
    <!-- 
        Таб "Додатків" по новій версії Sky
    -->

    <!-- Пошук по меню -->
    <b-card>
        <searchBox @searchB="searchB" class="searchMenu"/>
    </b-card>

    <b-row v-if="filteredItems.length > 0">
        <template v-for="item in filteredItems" :key="item">
            <div class="col-md-4" >
                <div class="card card-body card-animate" style="cursor: pointer;" @click="open(item)">
                    <div class="d-flex mb-4 align-items-center" >
                        <div class="flex-shrink-0" >
                            <!-- Лого -->
                            <img :src="require(`@/assets/images/apps/${item.img}`)" :alt="`${item.name} Logo`" class="avatar-xl rounded" style="border-radius: 25px!important;">
                        </div>
                        <div class="flex-grow-1 ms-2" >
                            <!-- Назва -->
                            <h5 class="card-title mb-1 fw-semibold fs-18">{{ item.name}}</h5>
                            <!-- Опис -->
                            <p class="text-muted mb-0">{{ item.desc }}</p>
                        </div>
                    </div>
                    <!-- Вартість, якщо є -->
                    <h6 class="mb-1 text-success fw-semibold">{{ item.price }}</h6> 
                    <!-- Інформація про підключення -->
                    <p class="card-text text-muted">{{ item.infoConnect }}</p>

                    <!-- До коли активований додаток -->
                    <p v-if="item.license.endDate && item.license.paid == true" class="card-text text-info fw-semibold">{{ $t('ActivatedUntil') }} - {{ item.license.endDate }}</p>
                    <p v-if="item.license.paid == false" class="card-text text-danger fw-semibold">{{ $t('NotPaid') }}</p>
                </div>
            </div>
        </template>
    </b-row>

    <!-- 
        Модальне вікно налаштувань expirenza
    -->
    <viewExp 
        v-if="showModalExp"
        @close="showModalExp = false"
        :objCard="objCard"
    />

    <!-- 
        Модальне вікно налаштувань приват24
    -->
    <viewPrivat 
        v-if="showModalPrivat"
        @close="showModalPrivat = false"
        :objCard="objCard"
    />

    <!-- 
        Модальне вікно налаштувань Чек по смс
    -->
    <viewSendCheck 
        v-if="showModalSendCheck"
        @close="showModalSendCheck = false"
        :objCard="objCard"
    />


</template>

<script>
import searchBox from '@/components/globaltable/searchbox.vue'; // компонент боксу пошуку
import viewExp from './expirenza/index' // картка expirenza
import viewPrivat from './privat/view' // картка privat24
import viewSendCheck from './sendcheck/view' // картка чек по смс
import { mutateItemApps } from '@/usabilityScripts/globalMutate.js'

export default {
    props: ['number', 'apps'],
    components: { 
        searchBox,
        viewExp,
        viewPrivat,
        viewSendCheck
    },
    data() {
        return {
            listApps: [], // список додатків
            search: '', // поле пошуку
            showModalExp: false, // прапорець відображення вікна налаштувань expirenza
            showModalPrivat: false, // прапорець відображення вікна налаштувань приват24
            showModalSendCheck: false, // прапорець відображення вікна налаштувань чек по смс
            objCard: {} // об'єкт з даними додатку
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            // отримання даних
            for(var item in this.apps) {
                var objData = {
                    appData: this.apps[item].appData,
                    name: this.mutateApp(this.apps[item]).name,
                    img: this.mutateApp(this.apps[item]).img,
                    desc: this.mutateApp(this.apps[item]).desc,
                    price: this.mutateApp(this.apps[item]).price,
                    infoConnect: this.mutateApp(this.apps[item]).infoConnect,
                    license: this.apps[item].license,
                    module: this.mutateApp(this.apps[item]).module,
                    tradepoints: this.apps[item].tradepoints ? this.apps[item].tradepoints : null
                }
                this.listApps.push(objData)
            }
        },
        searchB(e){
            // Пошук по назва та опису
            this.search = e
        },
        open(e){
            // Відкриття картки налаштувань
            console.log("e", e);
            
            switch (e.module) {
                case 'expirenza':
                    this.objCard = e;
                    this.showModalExp = true
                    break;
                case 'privatbank':
                    this.objCard = e;
                    this.showModalPrivat = true;
                    break
                case 'sendcheck':
                    this.objCard = e;
                    this.showModalSendCheck = true;
                    break
                default:
                    break;
            }
        },
        mutateApp(e){
            return mutateItemApps(e)
        }
    },
    computed: {
        filteredItems: function() {
            // Пошук по додаткам
            const query = this.search.toLowerCase();
            return this.listApps.filter(
                (app) =>
                app.name.toLowerCase().includes(query) ||
                (app.desc && app.desc.toLowerCase().includes(query))
            );
        },
    }

}
</script>