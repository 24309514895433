<template>
    <!-- 
        Модальне вікно з відображення картки штрафу 
    -->

    <!-- 
        Виклик компоненту модального вікна
        - :ifPrevopen - стан кнопки "<" 
        - :ifNextopen - стан кнопки ">"
        - @prevOpen - подія відкриття попереднього штрафу
        - @nextOpen - подія відкриття наступного штрафу
        - @close - подія закриття модального вікна
    -->
    <modal 
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen" 
        @close="$emit('close')" 
    >
        <!-- Заголовок модалки -->
        <template v-slot:title>{{ $t('Fine') }}</template>
        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-button>
            <div class="dropdown">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">

                    <!--
                        Кнопка редагування картки
                        - йде перевірка на права доступу під номером 2000 (режим бога) або на автора заявки на штраф
                        - йде перевірка на статус, редагування можливе, якщо статус "очікує підтвердження"
                        - @click - обробник події кліку, переходить в режим редагування
                    -->
                    <button 
                        class="dropdown-item" 
                        v-if="form.fineStatus == 'waitingСonfirmation' && form.createWorkerId == this.user.userid" 
                        @click="edit"
                    >
                        {{ $t('Edit') }}
                    </button>

                    <!--
                        Кнопка видалення картки
                        - йде перевірка на права доступу під номером 707 (створення штрафу) та 2000 (режим бога) або на автора заявки на штраф
                        - @click - обробник події кліку, відкриває вікно підтвердження видалення 
                    -->
                    <button 
                        class="dropdown-item" 
                        v-if="(perms[2000] || perms[707]) || form.createWorkerId == this.user.userid" 
                        @click="dialogshow = true"
                    >
                        {{ $t('Delete') }}
                    </button>

                </div>
            </div>
        </template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            <b-row>
                <b-col lg="6">
                    <!-- Хто створив та дата створення -->
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('created') }}</b>
                        </b-card-title>
                        <h5>- {{ form.createWorkerName }} <span v-if="form.createDatetime">({{form.createDatetime}})</span></h5>
                    </b-card>

                    <!-- Кому штраф -->
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('WhoFined') }}</b>
                        </b-card-title>
                        <h5>- {{ form.workerName }}</h5>
                    </b-card>

                    <!-- Тип штрафу -->
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('typeFine') }}</b>
                        </b-card-title>
                        <h5>- {{ this.mutatetype(form.type) }}</h5>
                    </b-card>
                </b-col>
                <b-col lg="6">

                    <!-- Статус штрафу -->
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('Status') }}</b>
                        </b-card-title>
                        <h5 :class="`badge ${this.mutatestatus(form.fineStatus).color}`"> {{ this.mutatestatus(form.fineStatus).name }}</h5>
                    </b-card>

                    <!-- Сума штрафу -->
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('Sum') }}</b>
                        </b-card-title>
                        <h5>- {{ form.fineSum }}</h5>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <!-- Коментар до штрафу -->
                <b-col>
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('Comment') }}</b>
                        </b-card-title>
                        <div class="ql-container">
                            <div class="ql-editor" v-html="form.message">
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </template>

        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom>
            
            <!-- 
                Кнопка "Видалити" 
                - надає змогу видалити штраф
                - відкривається модальне вікно, для підтвердження видалення штрафу
                - йде перевірка на права доступу під номером 707 (створення штрафу) та 2000 (режим бога)
            -->
            <button 
                type="button" 
                @click="dialogshow = true"
                v-if="this.perms[707] || perms[2000]" 
                class="btn btn-danger r waves-effect waves-light"
            >
                {{ $t('Delete') }}
            </button>

            <!-- 
                Кнопка "Скасувати" 
                - надає змогу скасувати штраф
                - відкривається модальне вікно, для підтвердження скасування штрафу
                - йде перевірка на права доступу під номером 707 (створення штрафу) та 2000 (режим бога), і щоб статус не був "анульовано"
            -->
            <button 
                type="button" 
                @click="showCancelBox = true" 
                v-if="(this.perms[707] || this.perms[2000]) && form.fineStatus != 'annul'" 
                class="btn btn-warning r waves-effect waves-light"
            >
                {{ $t('cancel') }}
            </button>

            <!-- 
                Кнопка "Підтвердити" 
                - надає змогу підтвердити штраф
                - відкривається модальне вікно, для підтвердження штрафу
                - йде перевірка на права доступу під номером 707 (створення штрафу) та 2000 (режим бога), і щоб статус не був "підтверджено"
            -->
            <button 
                type="button"
                @click="toDone"
                v-if="(this.perms[707] || this.perms[2000]) && form.fineStatus != 'confirmed'" 
                :class="`btn btn-success ss waves-effect waves-light`"
            >
                {{ $t('Confirm') }}
            </button>

        </template>
    </modal>

    <!-- 
        Модальне вікно підтвердження видалення штрафу
    -->
    <modalremove 
        v-if="dialogshow" 
        @close="dialogshow = false" 
        @remove="toDelete()" 
    />

    <!-- 
        Модальне вікно скасування штрафу
    -->
    <cancelBox 
        v-if="showCancelBox"
        @close="showCancelBox = false"
        @toCancel="toCancel"
    />

</template>

<script>
import modal from '@/components/modal/modal-lg' // компонент модального вікна
import modalremove from '@/components/modal/modalremove' // компонент діалогового вікна видалення
import cancelBox from './actions/cancel' // компонент скасування
import { Fines } from '@/API.js' // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { mutatetypeFine, mutateStatusFine } from '@/usabilityScripts/globalMutate.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiFines = new Fines();

export default {
    props: ['obj', 'ifPrevopen', 'ifNextopen'],
    components: { modal, modalremove, cancelBox },
    data() {
        return{
            form: "",
            dialogshow: false, // прапорець відображення вікна видалення штрафу
            showCancelBox: false  // прапорець відображення вікна скасування штрафу
        }
    },
    created(){
        this.getdata()
    },
    methods:{
        getdata(){
            this.form = this.obj
        },
        prevOpen(){
            /**
                * Закриває поточне модальне вікно і відкриває попереднє за ID.
            */

            this.$emit('close');
            this.$emit('prevOpen', this.form.fineId)
        },
        nextOpen(){
            /**
                * Закриває поточне модальне вікно і відкриває наступне за ID. 
            */

            this.$emit('close');
            this.$emit('nextOpen', this.form.fineId)
        },
        toDelete(){
            /**
                * Видаляє поточний штраф за допомогою API-запиту та виводить сповіщення про успішне видалення.
                * @param {number} this.form.fineId - ідентифікатор штрафу.
            */

            apiFines.deleteFine(this.form.fineId).then(res =>{
                if(res){
                    this.$toast.success(this.$t('Removed'));
                    this.eventBus.emit('saveFines', true);
                    this.$emit('close');
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка видалення штрафу'; 
                    var statusRequest = result.status;
                    var api = 'deleteFine';
                    var fileRequest = 'src/views/statistics/fines/view.vue';
                    var params = this.form.fineId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        edit(){
            /**
                * Відкриває режим редагування поточного штрафу.
            */

            this.$emit('edit', this.form)
        },
        mutatetype(e){
            /*
                Функція для застосування перекладу до типу штрафу
            */
            return mutatetypeFine(e)
        },
        mutatestatus(e){
            /*
                Функція для застосування перекладу до статусу штрафу
            */
            return mutateStatusFine(e)
        },
        toDone(){
            /**
                * Підтверджує штраф за допомогою API-запиту та виводить сповіщення про успішне підтвердження.
                * @param {number} this.form.fineId - ідентифікатор штрафу.
            */

            apiFines.confirmFine(this.form.fineId).then(result =>{
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'));
                    this.eventBus.emit('saveFines', true);
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка підтвердження штрафу'; 
                    var statusRequest = result.status;
                    var api = 'confirmFine';
                    var fileRequest = 'src/views/statistics/fines/view.vue';
                    var params = this.form.fineId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        toCancel(e){
            /**
                * Скасовує штраф з вказаною причиною за допомогою API-запиту та виводить сповіщення про успішну скасування.
                * @param {string} e - Причина скасування штрафу.
                * @param {number} this.form.fineId - ідентифікатор штрафу.
            */

            apiFines.cancelFine(this.form.fineId, e).then(result =>{
                if(result.status == 'done'){
                    this.$toast.success(this.$t('canceled'));
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "fineId": this.form.fineId,
                        "params": e
                    };

                    var errorText = 'Помилка скасування штрафіу'; 
                    var statusRequest = result.status;
                    var api = 'getAllFines';
                    var fileRequest = 'src/views/statistics/fines/view.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
    },
    computed: {
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
}
</script>