<template>
    <b-card>
       <div class="card-header">
           <h5 class="card-title mb-0"><b>{{ $t('ifRefund') }}</b></h5>
       </div>
       <div class="card-body">
           <div class="mb-3">
               <div class="d-flex mt-3">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-primary rounded-circle fs-16 text-white`" >
                            <i class="bx bx-comment-dots"></i>
                        </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 fs-16">
                            1 {{ $t('company') }}. {{ $t('Remaining') }}: 
                        </p>
                       <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                               <h6 :class="`fs-14 mb-0 fw-normal`" style="word-break: break-word;">{{ new Date(this.firstTariff.days).getTime() - new Date().getTime() < 0 ? '0' : Math.round(Math.abs(new Date(this.firstTariff.days).getTime() - new Date().getTime()) / (1000 * 3600 * 24))  }} {{ $t('days') }}</h6>
                               <h6 :class="`fs-14 mb-0 fw-normal`" style="word-break: break-word;">{{ $t('balance') }}: {{  this.firstTariff.balance  }}₴</h6>
                            </div>
                       </div>
                    </div>
               </div>
           </div>
           <div class="mb-3">
               <div class="d-flex mt-3">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-primary rounded-circle fs-16 text-white`" >
                            <i class="bx bx-comment-dots"></i>
                        </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 fs-16">
                            2 {{ $t('company') }}. {{ $t('Remaining') }}: 
                        </p>
                       <div class="ctext-wrap" style="display:flex">
                           <div class="ctext-wrap-content">
                               <h6 :class="`fs-14 mb-0 fw-normal`" style="word-break: break-word;">{{  !this.secondTariff.days ? '0' : Math.round(Math.abs(new Date(this.secondTariff.days).getTime() - new Date().getTime()) / (1000 * 3600 * 24))  }} {{ $t('days') }}</h6>
                               <h6 :class="`fs-14 mb-0 fw-normal`" style="word-break: break-word;">{{ $t('balance') }}: {{  !this.secondTariff.balance ? '0' : this.secondTariff.balance }}₴</h6>
                           </div>
                       </div>
                    </div>
               </div>
           </div>
           <div class="mb-3">
               <div class="d-flex mt-3">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-primary rounded-circle fs-16 text-white`" >
                            <i class="bx bx-comment-dots"></i>
                        </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 fs-16">
                            {{ this.$t('ifRefund') }}:
                        </p>
                        <!-- input with balance -->
                        <!-- 
                        <div class="ctext-wrap" style="display:flex; margin-bottom: 10px;" v-if="this.form.descType == 'balance' || this.form.descType == 'balance_period'">
                            <div class="ctext-wrap-content">
                                <h6 class="fs-14 mb-0 fw-normal" style="word-break: break-word;">{{ this.$t('Тариф другої компанії') }}</h6>
                                <input type="text" class="form-control" :value="Number((this.secondTariff.tariff / 30).toFixed(2)) ? Number((this.secondTariff.tariff / 30).toFixed(2)) : 0" @input="this.countDays(this.firstTariff, this.secondTariff, $event)"/>
                            </div>
                        </div> 
                        -->
                        <!-- input with balance -->
                       <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal`" style="word-break: break-word;">+{{ this.countDays(this.firstTariff, this.secondTariff) }} {{ $t('days') }}</h6>
                            </div>
                       </div>
                    </div>
               </div>
           </div>
       </div>
    </b-card>
</template>

<script>
import { SuperAdmin } from '@/API.js'

let apiAdmin = new SuperAdmin()

export default{
    props: ['first', 'second', 'obj'],
    data(){
        return {
            form: '',
            showFirst: '',
            showSecond: '',
            firstTariff: '',
            secondTariff: '',
        }
    },
    created(){
        this.returnTariffFirst(this.first)
        this.returnTariffSecond(this.second)

        this.form = this.obj
    },
    methods: {
        // отримуємо тариф для першої компанії, з котрої переносимо
        returnTariffFirst(id){
            apiAdmin.getCompClient(id).then(result => {
                if(result.status === 'done') {
                    if(result.data.companyDataObj.license) {
                        this.firstTariff = {
                            balance: result.data.companyDataObj.license.userBalance,
                            tariff: result.data.companyDataObj.license.userSumm,
                            days: result.data.companyDataObj.license.activeAccount
                        }
                    } else {
                        this.firstTariff = ''
                    }
                }
            })
        },
        returnTariffSecond(id){
            // отримуємо тариф для другу компанію, на котру переносимо
            apiAdmin.getCompClient(id).then(result => {
                if(result.status === 'done') {
                    if(result.data.companyDataObj.license) {
                        this.secondTariff = {
                            balance: result.data.companyDataObj.license.userBalance,
                            tariff: result.data.companyDataObj.license.userSumm,
                            days: result.data.companyDataObj.license.activeAccount
                        }
                    } else {
                        this.secondTariff = ''
                    }
                }
            })
        },
        countDays(first, second, customInput){
            let secondPerDay;
            if(!customInput) {
                secondPerDay = Number((this.secondTariff.tariff / 30).toFixed(2))
            } else {
                secondPerDay = Number((Number(customInput.target.value) / 30).toFixed(2))
            }

            if(this.form.descType == 'balance') {
                return ((Number(this.firstTariff.balance) / secondPerDay).toFixed(2)) 
            } else if(this.form.descType == 'period') {
                if((new Date(this.firstTariff.days).getTime() - new Date().getTime()) > 0) {
                    return Math.round(Math.abs(new Date(this.firstTariff.days).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) 
                } else {
                    return '0'
                }
            } else if(this.form.descType == 'balance_period') {
                if((new Date(this.firstTariff.days).getTime() - new Date().getTime()) < 0) {
                    return (!this.firstTariff.balance || !secondPerDay ? 0 : (Number(this.firstTariff.balance) / secondPerDay).toFixed(2)) 
                } else {
                    if(this.firstTariff.balance === 0 || secondPerDay === 0) {
                        return Math.round((new Date(this.firstTariff.days).getTime() - new Date().getTime()) / (1000 * 3600 * 24))
                    }
                    return (Math.round((new Date(this.firstTariff.days).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) + Number((Number(this.firstTariff.balance) / secondPerDay).toFixed(2) == NaN ? 0 : (Number(this.firstTariff.balance) / secondPerDay).toFixed(2))).toFixed()
                }
            }
        }
    }
}
</script>