import { storeS } from "@/store";
import {uuid} from 'vue-uuid'
import Cookies from "js-cookie";
import {wsMessageHandler} from "@/socket/wsMessageHandler";

let socket;

let unknown = '-';

// browser
let nVer = navigator.appVersion;
let nAgt = navigator.userAgent;
let browser = navigator.appName;
let version = '' + parseFloat(navigator.appVersion);
let majorVersion = parseInt(navigator.appVersion, 10);
let nameOffset, verOffset, ix;
let appVersion = null;

// Opera
if ((verOffset = nAgt.indexOf('Opera')) != -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) != -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Opera Next
  if ((verOffset = nAgt.indexOf('OPR')) != -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 4);
  }
  // Legacy Edge
  else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
    browser = 'Microsoft Legacy Edge';
    version = nAgt.substring(verOffset + 5);
  }
  // Edge (Chromium)
  else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
    browser = 'Microsoft Edge';
    version = nAgt.substring(verOffset + 4);
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(verOffset + 5);
  }
  // Chrome
  else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
    browser = 'Chrome';
    version = nAgt.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
    browser = 'Safari';
    version = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) != -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
    browser = 'Firefox';
    version = nAgt.substring(verOffset + 8);
  }
  // MSIE 11+
  else if (nAgt.indexOf('Trident/') != -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(nAgt.indexOf('rv:') + 3);
  }
  // Other browsers
  else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
    browser = nAgt.substring(nameOffset, verOffset);
    version = nAgt.substring(verOffset + 1);
    if (browser.toLowerCase() == browser.toUpperCase()) {
      browser = navigator.appName;
    }
  }

  // trim the version string
if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

majorVersion = parseInt('' + version, 10);
if (isNaN(majorVersion)) {
  version = '' + parseFloat(navigator.appVersion);
  majorVersion = parseInt(navigator.appVersion, 10);
}

// system
let os = unknown;
let clientStrings = [
  { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
  { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
  { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
  { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
  { s: 'Windows Vista', r: /Windows NT 6.0/ },
  { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
  { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
  { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
  { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
  { s: 'Windows 98', r: /(Windows 98|Win98)/ },
  { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
  { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
  { s: 'Windows CE', r: /Windows CE/ },
  { s: 'Windows 3.11', r: /Win16/ },
  { s: 'Android', r: /Android/ },
  { s: 'Open BSD', r: /OpenBSD/ },
  { s: 'Sun OS', r: /SunOS/ },
  { s: 'Chrome OS', r: /CrOS/ },
  { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
  { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
  { s: 'Mac OS X', r: /Mac OS X/ },
  { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
  { s: 'QNX', r: /QNX/ },
  { s: 'UNIX', r: /UNIX/ },
  { s: 'BeOS', r: /BeOS/ },
  { s: 'OS/2', r: /OS\/2/ },
  { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
];
for (let id in clientStrings) {
  let cs = clientStrings[id];
  if (cs.r.test(nAgt)) {
    os = cs.s;
    break;
  }
}

let osVersion = unknown;

if (/Windows/.test(os)) {
  osVersion = /Windows (.*)/.exec(os)[1];
  os = 'Windows';
}

switch (os) {
  case 'Mac OS':
  case 'Mac OS X':
  case 'Android':
    osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1];
    break;

  case 'iOS':
    osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
    osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
    break;
}

try {
  //Получить версию приложения для андроид
  appVersion = Android.getAppVersion();
} catch (err) {

}

try {
  //Получить версию приложения для Windows
  let request = { method: "getAppVersion" }
  window.cefQuery({
    request: JSON.stringify(request),
    persistent: false,
    onSuccess(response) {
      appVersion = response;
    },
  });
} catch (err) {

}
  

export const ws = () => {
  let wsurl;
  let token = [];
  var url = new URL(window.location.href);

    if(process.env.NODE_ENV == 'development') {
      wsurl = "wss://ws.inside.skyservice.pro/" + storeS.token + "/sky_inside/" + storeS.token
    } else {
      console.log('token', url.searchParams.get('token'))
      if(url.searchParams.get('token') != '' && url.searchParams.get('token') != null){
          token = url.searchParams.get('token')
      } else if(document.cookie.indexOf('token=') != -1) {
          for(let i = document.cookie.indexOf('token=')+6; i<=document.cookie.indexOf('token=')+5+32; i++) {
              token.push(document.cookie[i])
          }
          token = token.join('')
      } else {
          alert("Не вдалося зчитати токен, перезайдіть з кабінету")
          window.location.href('https://cabinet.skyservice.online/')
      }
      wsurl = "wss://ws.inside.skyservice.pro/" + storeS.userbase.accountId + "/sky_inside/" + token
    }
    
    try {
        console.log("ws connect to", wsurl);

        try {
          socket = new ReconnectingWebSocket(wsurl);
          socket = new WebSocket(wsurl);
        } catch (err) {
          console.log(err)
        }

        storeS.socket = socket;
        
        socket.onopen = (event) => {
            console.log("connected");
            storeS.wsConnection = true;

            // let deviceInfo = {};
            // let deviceData = {};
            // let userInfo = {};

            // userInfo.browser = `${browser} ${majorVersion} (${version})`;
            // userInfo.appVersion = appVersion;
            // userInfo.appTitle = "Inside";
            // userInfo.os = `${os} ${osVersion}`;
            // userInfo.lang = storeS.lang;

            // userInfo.ip = storeS.ip;
            // socket.send(JSON.stringify({
            //     send: "all",
            //     userInfo: {
            //         ...userInfo,
            //         // ...deviceData
            //     }
          
            // }));


            socket.onmessage = (event) => {
                ("ws event:", event);
                let message = JSON.parse(event.data);
                ("ws message", message)
                wsMessageHandler(message);
            };
         
            socket.onclose = (event) => {
                ("WebSocket was closed now");
                (event.code);
                (event.reason);
                storeS.wsConnection = false;
                setTimeout(ws, 4000);
            };
          
            socket.onerror = (event) => {
                ("WebSocket error");
                (event);
                (event.reason);
                storeS.wsConnection = false;
                setTimeout(ws, 4000);
            }
        }
  } catch(err) {
    console.log('err', err)
  }
}
