<template>
    <!-- 
        Модальне вікно відкриття перерви 
    -->
    
    <dialogBox >
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('Add') }}</template>
        
        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            <div class="mb-3">
                <!-- Причина перерви -->
                <h4 class="fs-15">{{ $t('reasonforbreak') }}</h4>
                <select class="form-select mb-3" v-model="selectReason">
                    <option value="0" selected>{{ $t('Specifyreasonforbreak') }}</option>
                    <option v-for="item in list" :key="item" :value="item">{{item.title}}</option>
                </select>
                <!-- Якщо не дефолтна причина -->
                <div class="text_field">
                    <input type="text" :placeholder="$t('enterOwnreason')" v-model="form.reason" v-if="selectReason.value == '1'" /> 
                </div>
            </div>
        </template>

        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom>

            <!-- 
                Кнопка "Закрити" 
                - закриває вікно
            -->
            <button 
                class="btn link-success fw-medium"  
                @click="$emit('close')"
            >
                <i class="ri-close-line me-1 align-middle"></i> 
                {{ $t('close') }} 
            </button>

            <!-- 
                Кнопка "Додати" 
                - відкриває перерву з вказаною причиною
            -->
            <button 
                type="button" 
                class="btn btn-primary" 
                v-on:click="create"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модального вікна
import { Breaks } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних
import { telephony } from '@/telephony/telephony.js' // телефонія

let apiServe = new Breaks();

export default {
components: { dialogBox },
data() {
    return {
        form:{ // параметри форми
            shiftId: "", // id зміни
            reason: "" // причина
        },
        selectReason: "0",
        list: [ // Дефолтні причини
            {
                // своя причина
                title: this.$t('ownReason'),
                value: "1"
            },
            {
                // обід
                title: this.$t('Dinner'),
                value: "lunch"
            },
            {
                // перекур
                title: this.$t('smokeBreak'),
                value: "smokebreak"
            }
        ]
    };
},
created(){
},
methods: {
    create() {
        // Відкриття перерви

        // Ініціалізація бібліотеки телефонії
        this.ua = telephony()
        
         // Формування об'єкта з причиною перерви
        var formReason = {
            shiftId: this.user.shiftId,
            reason: this.selectReason.value == '1' ? this.form.reason : this.selectReason.value
        }

        // Перевірка наявності UA (User Agent) в локальному сховищі
        if(localStorage.getItem('UA') == true) {
            // Видалення UA з локального сховища
            localStorage.removeItem('UA')

            // Відключення UA
            this.ua.unreg({
                disconnect: () => {
                    // Дії при відключенні

                    // зупиняємо лоадер
                    this.preloaderTable = false
                    
                    // встановлюємо статус підключення
                    this.settingSip.connected = false    
                    
                    // редірект в кабінет
                    var url = 'https://cabinet.skyservice.online/'
                    window.location.href = url;
                },
                unregistered: () => {
                    // Дії при відмові від UA

                    // активуємо лоадер
                    this.preloaderTable = true
                }
            })

            // Виклик API для створення перерви з причиною
            apiServe.createBreak(this.user.userid, formReason).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('сreatedT'));
                    this.$emit('close')
                    storeS.break.statusBreak = true
                    var url = 'https://cabinet.skyservice.online/';
                    window.location.href = url;
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        } else {

            // Виклик API для створення перерви з причиною
            apiServe.createBreak(this.user.userid, formReason).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('сreatedT'));
                    this.$emit('close')
                    storeS.break.statusBreak = true
                    var url = 'https://cabinet.skyservice.online/';
                    window.location.href = url;
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        }
    },
},
computed: {
    user() {
        /*
            Повертає дані користувача з глобального стору.
        */
        return storeS.userbase
    }
}
}
</script>

<style scoped>
    .text_field input {
        width: 100%;
        border: 1px #ced4da solid;
        border-radius: 5px;
        height: 40px;
        padding-left: 15px;
    }
</style>