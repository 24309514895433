<template>
    <!-- Компонент для вибору фільтру типу дзвінка -->

    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
        >
            {{ $t('actionCall') }}
        </label>

        <br />
        <div class="row g-2 list_filtr" >
            <!-- Вибір "Усі" -->
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioAction" id="all_action" v-model="actionCall" :value="''">
                    <label class="form-check-label" for="all_action">{{ $t('all') }}</label>
                </div>
            </div>

            <!-- Цикл для виведення радіобатонів для типу заявки -->
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioAction" :id="'action'+index" v-model="actionCall" :value="value.value">
                        <label class="form-check-label" :for="'action'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            actionCall: "", // Обраний статус
            opt: [
                {
                    // Пропущений
                    name: this.$t('missing'),
                    value: "missing",
                },
                {
                    // вхідний
                    name: this.$t('incoming'),
                    value: "incoming",
                },
                {
                    // вихідний
                    name: this.$t('outgoing'),
                    value: "outgoing",
                }
            ]
        }
    },
    mounted() {
        this.eventBus.off('calls filters')
        this.eventBus.on('calls filters', data => {
            if(data.nameFilter == "actionCall") {
                this.actionCall = this.opt[this.opt.indexOf(this.opt.find(el => el.value == data.form.value))].value
                console.log(this.actionCall)
            }
        })
    },
    methods: {
        change(){
            // Метод, що викликається при зміні фільтру
            this.$emit('change', 'actionCall', this.actionCall)
        },
    },
    computed: {
    }
}
</script>