<template>
    <b-card>
       <div class="card-header">
           <h5 class="card-title mb-0"><b>{{ $t('performance') }}</b></h5>
       </div>
       <div class="card-body">
           <div class="mb-3">
               <div class="d-flex mt-4">
                   <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                       <div :class="`avatar-title bg-primary rounded-circle fs-16 text-white`" >
                           <i :class="`bx bxs-phone`"></i>
                       </div>
                   </div>
                   <div :class="`flex-grow-1 overflow-hidden`" >
                       <p class="mb-1 fs-16">{{ $t('Manager') }} :</p>
                       <div class="ctext-wrap" style="display:flex">
                           <div class="ctext-wrap-content">
                               <h6 :class="`text-truncate mb-0 fs-14 `" > {{ this.form.inJobWorkerName }}</h6>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           <div class="mb-3">
               <div class="d-flex mt-4">
                   <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                       <div :class="`avatar-title bg-primary rounded-circle fs-16 text-white`" >
                           <i :class="`bx bx-time`"></i>
                       </div>
                   </div>
                   <div :class="`flex-grow-1 overflow-hidden`" >
                       <p class="mb-1 fs-16">{{ $t('date_time') }} :</p>
                       <div class="ctext-wrap" style="display:flex">
                           <div class="ctext-wrap-content">
                                <h6 :class="`text-truncate mb-0 fs-14 `" > {{ this.form.inJobDatetime }}</h6>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
    </b-card>
</template>

<script>

export default{
   props: ['obj'],
   data(){
       return {
           form: ''
       }
   },
   created(){
       this.form = this.obj
   },
   methods: {
   }
}
</script>