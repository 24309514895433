<template>
    <!-- Компонент для відображення чату -->

    <b-card :class="{ 'fullscreen': isFullscreen }" ref="block">
        <div class="user-chat w-100 overflow-hidden user-chat-show">
            <!-- Вміст чату -->
            <div class="chat-content d-lg-flex">
                <!-- Блок для відображення повідомлень та редагування чату -->
                <div class="w-100 overflow-hidden position-relative">
                    <!-- Блок для розташування повідомлень -->
                    <div class="position-relative">
                        <!-- Блок для зверху чату з назвою та пошуковим полем -->
                        <div class="position-relative" id="users-chat" style="display: block;">
                            <!-- Верхня панель чату -->
                            <div class="p-1 user-chat-topbar">
                                <div class="row align-items-center">
                                    <div class="col-sm-4 col-8">
                                        <!-- Назва чату -->
                                        <div class="d-flex align-items-center">
                                            <div class="flex-grow-1 overflow-hidden">
                                                <div class="d-flex align-items-center">
                                                    <div class="flex-grow-1 overflow-hidden">
                                                        <h5 class="text-truncate mb-0 fs-16 fw-semibold">{{ $t('chat') }}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Пошукове поле -->
                                    <div class="col-sm-8 col-4">
                                        <ul class="list-inline user-chat-nav text-end mb-0">

                                            <!-- Кнопка для відправлення повідомлення на мобілках -->
                                            <!-- <li class="list-inline-item m-0" v-if="this.checkMobile() == true">
                                                <button v-touch:tap="updateMsgs" class="btn btn-ghost-secondary btn-icon">
                                                    <i class="ri-restart-line"></i>
                                                </button>
                                            </li> -->

                                            <!-- Кнопка для відправлення повідомлення на пк -->
                                            <li class="list-inline-item d-none d-lg-inline-block m-0" >
                                                <button  @click="updateMsgs" class="btn btn-ghost-secondary btn-icon">
                                                    <i class="ri-restart-line"></i>
                                                </button>
                                            </li>
                                            
                                            <!-- Зробити блок на весь екран -->
                                            <li class="list-inline-item d-none d-lg-inline-block m-0">
                                                <button 
                                                    @click="expandBlock"
                                                    type="button" 
                                                    class="btn btn-ghost-secondary btn-icon"
                                                >
                                                    <i :class="`bx bx-${isFullscreen == false ? 'fullscreen': 'exit-fullscreen'} fs-22`"></i>
                                                </button>
                                            </li>

                                            <li class="list-inline-item m-0">
                                                <!-- Кнопка для виклику меню пошуку -->
                                                <div class="dropdown">
                                                    <button class="btn btn-ghost-secondary btn-icon" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="feather feather-search icon-sm"
                                                        >
                                                            <circle cx="11" cy="11" r="8"></circle>
                                                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                        </svg>
                                                    </button>
                                                    <!-- Меню пошуку -->
                                                    <div class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
                                                        <!-- Поле для введення тексту пошуку -->
                                                        <div class="p-2">
                                                            <div class="search-box">
                                                                <input type="text" class="form-control bg-light border-light" :placeholder="$t('searc_inp')" v-model="search" id="searchMessage" />
                                                                <i class="ri-search-2-line search-icon"></i>
                                                            </div>
                                                        </div>
                                                        <!-- Відображення результатів пошуку -->
                                                        <div v-if="search != ''" style="max-height:400px;overflow: scroll;" class="search">
                                                            <template v-for="item in filteredItems" :key="item">
                                                                <!-- Виведення результатів пошуку -->
                                                                <div v-if="item.deleted == false" class="border-top text-reset notification-item d-block dropdown-item position-relative copy"  @click="scrollToMsg(item)">
                                                                    <div class="d-flex">
                                                                        <div class="avatar-xs me-3">
                                                                            <span class="avatar-title bg-soft-info text-info rounded-circle fs-16">
                                                                                {{ this.getInitials(this.nameWorker(item.workerId)) }}
                                                                            </span>
                                                                        </div>
                                                                        <!-- Вміст повідомлення -->
                                                                        <div class="flex-1">
                                                                            <h6 class="mt-0 mb-1 lh-base">{{ this.nameWorker(item.workerId) }}</h6>
                                                                            <p class="mb-1 fs-14 fw-semibold "><span v-html="item.message"></span></p>
                                                                            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted"><span>{{ item.datetime }} </span></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <!-- Блок для виведення повідомлень -->
                            <div :class="`chat-conversation ${isFullscreen == false ? '' : 'chat-conversation-full'} p-3 p-lg-4`" id="chat-conversation" data-simplebar="init" style="/* height: 500px !important; */">
                                <!-- Контент повідомлень -->
                                <div class="simplebar-wrapper" style="margin: -24px;">
                                    <div class="simplebar-height-auto-observer-wrapper"><div class="simplebar-height-auto-observer"></div></div>
                                    <div class="simplebar-mask">
                                        <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                                            <div class="simplebar-content-wrapper" id="scrollChat" tabindex="0" role="region" aria-label="scrollable content" style="height: 100%; overflow: auto;">
                                                <div class="simplebar-content" style="padding: 24px;">
                                                    <div id="elmLoader"></div>
                                                    <!-- Список повідомлень -->
                                                    <ul class="list-unstyled chat-conversation-list" id="users-conversation">

                                                        <template v-if="perms[666]">

                                                            <!-- тестовий блок -->
                                                            <li id="msg4222" class="chat-list right" data-v-3a2ec7eb="">
                                                                <div class="conversation-list" data-v-3a2ec7eb="" bis_skin_checked="1">
                                                                    <div class="user-chat-content" data-v-3a2ec7eb="" bis_skin_checked="1">
                                                                        <div class="ctext-wrap" data-v-3a2ec7eb="" bis_skin_checked="1">
                                                                            <!-- Вміст повідомлення без відповіді -->
                                                                            <div class="ctext-wrap-content bg-info text-white msg4222" data-v-3a2ec7eb="" bis_skin_checked="1">
                                                                                <span class="msgH" data-v-3a2ec7eb="">
                                                                                    <p>Повторне звернення від клієнта #3</p>
                                                                                    <p>Клієнт: 38066666</p>
                                                                                    <p style="text-decoration: underline; cursor: pointer;">Детальна інформація</p>
                                                                                </span>
                                                                            </div>
                                                                            <!-- Вміст повідомлення з відповіддю --><!--v-if--><!-- Контекстне меню для кожного повідомлення --><!-- :class="this.width < 768 ? '' : 'message-box-drop'" -->
                                                                            <div class="dropdown aling-self-start message-box-drop" data-v-3a2ec7eb="" bis_skin_checked="1">
                                                                                <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-v-3a2ec7eb=""><i class="ri-more-2-fill" data-v-3a2ec7eb=""></i></a>
                                                                                <!-- Меню опцій для кожного повідомлення -->
                                                                                <div class="dropdown-menu" data-v-3a2ec7eb="" bis_skin_checked="1">
                                                                                    <!-- 
                                                                                                                                                Кнопка "Відповісти"
                                                                                                                                                - опція для відповіді на повідомлення 
                                                                                                                                            -->
                                                                                    <button class="dropdown-item reply-message" data-v-3a2ec7eb=""><i class="ri-reply-line me-2 text-muted align-bottom" data-v-3a2ec7eb=""></i>Відповісти</button>
                                                                                    <!--
                                                                                                                                                Мобілки !
                                                                                                                                                Кнопка "Відповісти"
                                                                                                                                                - опція для відповіді на повідомлення 
                                                                                                                                            --><!-- <button 
                                                                                                                                                class="dropdown-item reply-message" 
                                                                                                                                                v-touch:tap="reply(item)"
                                                                                                                                            >
                                                                                                                                                <i class="ri-reply-line me-2 text-muted align-bottom"></i>{{$t('rePly')}}
                                                                                                                                            </button> --><!-- 
                                                                                                                                                Кнопка "Копіювати"
                                                                                                                                                - опція для копіювання тексту повідомлення
                                                                                                                                            -->
                                                                                    <button class="dropdown-item copy-message" data-v-3a2ec7eb=""><i class="ri-file-copy-line me-2 text-muted align-bottom" data-v-3a2ec7eb=""></i>Копіювати</button>
                                                                                    <!-- 
                                                                                                                                                Кнопка "Видалити"
                                                                                                                                                - опція для видалення повідомлення
                                                                                                                                                - йде перевірка на автора повідомлення
                                                                                                                                            --><!--v-if--><!-- 
                                                                                                                                                Кнопка "Копіювати"
                                                                                                                                                - опція для редагування повідомлення
                                                                                                                                            --><!--v-if-->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- {{ item.images }} --><!--v-if--><!-- Ім'я користувача та час відправлення повідомлення -->
                                                                        <div class="conversation-name" data-v-3a2ec7eb="" bis_skin_checked="1">
                                                                            <small class="text-muted time" data-v-3a2ec7eb="">2024-08-30 11:47:17</small> <span class="text-success check-message-icon" data-v-3a2ec7eb=""><i class="bx bx-check-double" data-v-3a2ec7eb=""></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>


                                                        </template>


                                                        <!-- Виведення повідомлень -->
                                                        <li 
                                                            v-for="item in objMsg" 
                                                            :key="item" 
                                                            :id="'msg'+item.id"
                                                            :class="`chat-list ${item.workerId == this.user.userid ? 'right' : 'left'}`" 
                                                        >
                                                            <div class="conversation-list">
                                                                <div class="chat-avatar">
                                                                    <div 
                                                                        class="avatar-xs" 
                                                                        style="margin: 0px auto;"
                                                                    >
                                                                        <div class="avatar-title rounded text-white" :style="{ backgroundColor: getColorByWorkerId(item.workerId) }">
                                                                            {{ this.getInitials(this.nameWorker(item.workerId)) }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="user-chat-content">
                                                                    <div class="ctext-wrap" v-if="item.message != ''">

                                                                        <!-- Вміст повідомлення без відповіді -->
                                                                        <div 
                                                                            v-if="item.replyMessageId == null" 
                                                                            v-on:dblclick="reply(item)"
                                                                            :class="`ctext-wrap-content  ${ item.deleted == true  ? 'deleted bg-danger text-white' : item.workerId == this.user.userid ? 'bg-success' : 'bg-info'} text-white msg${item.id}`" >
                                                                            <span v-html="item.deleted ? $t('messageDeleted') : item.message" class="msgH"></span>
                                                                        </div>

                                                                        <!-- Вміст повідомлення з відповіддю -->
                                                                        <div v-if="item.replyMessageId != null" class="ctext-wrap-content" v-on:dblclick="reply(item)">
                                                                            <!-- Блок з інформацією про відповідь та текстом відповіді -->
                                                                            <div class="replymessage-block mb-0 d-flex align-items-start" >
                                                                                <div class="flex-grow-1">
                                                                                    <h5 class="conversation-name">{{ replyMsg(item).name }}</h5>
                                                                                    <div class="mb-0" v-html="replyMsg(item).text"></div>
                                                                                </div>
                                                                                <div class="flex-shrink-0" >
                                                                                    <button type="button" class="btn btn-sm btn-link mt-n2 me-n3 font-size-18"></button>
                                                                                </div>
                                                                            </div>
                                                                            <!-- Текст повідомлення -->
                                                                            <div class="mb-0 ctext-content mt-1" v-if="item.deleted !== true" v-html="item.message"></div>
                                                                        </div>

                                                                        <!-- Контекстне меню для кожного повідомлення -->
                                                                        <!-- :class="this.width < 768 ? '' : 'message-box-drop'" -->
                                                                        <div :class="this.width < 768 ? 'dropdown-self-start' : 'dropdown aling-self-start message-box-drop'"  v-if="item.deleted !== true">
                                                                            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <i class="ri-more-2-fill"></i>
                                                                            </a>
                                                                            <!-- Меню опцій для кожного повідомлення -->
                                                                            <div class="dropdown-menu">

                                                                                <!-- 
                                                                                    Кнопка "Відповісти"
                                                                                    - опція для відповіді на повідомлення 
                                                                                -->
                                                                                <button 
                                                                                    class="dropdown-item reply-message" 
                                                                                    @click="reply(item)"
                                                                                >
                                                                                    <i class="ri-reply-line me-2 text-muted align-bottom"></i>{{$t('rePly')}}
                                                                                </button>

                                                                                <!--
                                                                                    Мобілки !
                                                                                    Кнопка "Відповісти"
                                                                                    - опція для відповіді на повідомлення 
                                                                                -->
                                                                                <!-- <button 
                                                                                    class="dropdown-item reply-message" 
                                                                                    v-touch:tap="reply(item)"
                                                                                >
                                                                                    <i class="ri-reply-line me-2 text-muted align-bottom"></i>{{$t('rePly')}}
                                                                                </button> -->

                                                                                <!-- 
                                                                                    Кнопка "Копіювати"
                                                                                    - опція для копіювання тексту повідомлення
                                                                                -->
                                                                                <button 
                                                                                    class="dropdown-item copy-message" 
                                                                                    @click="copy(item.message)"
                                                                                >
                                                                                    <i class="ri-file-copy-line me-2 text-muted align-bottom"></i>{{$t('toCopy')}}
                                                                                </button>

                                                                                <!-- 
                                                                                    Кнопка "Видалити"
                                                                                    - опція для видалення повідомлення
                                                                                    - йде перевірка на автора повідомлення
                                                                                -->
                                                                                <button 
                                                                                    class="dropdown-item delete-item" 
                                                                                    @click="removeRequest(item)"
                                                                                    v-if="item.workerId == this.user.userid"
                                                                                >
                                                                                    <i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>{{$t('Delete')}}
                                                                                </button>

                                                                                <!-- 
                                                                                    Кнопка "Копіювати"
                                                                                    - опція для редагування повідомлення
                                                                                -->
                                                                                <button 
                                                                                    class="dropdown-item delete-item" 
                                                                                    v-if="item.workerId == this.user.userid && this.inRelease == false" 
                                                                                    @click="editRequest(item)"
                                                                                >
                                                                                    <i class="ri-pencil-line me-2 text-muted align-bottom"></i>{{$t('Edit')}}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- {{ item.images }} -->
                                                                    <div class="ctext-wrap" v-if="item.images && item.deleted == false">
                                                                        <div class="message-img mb-0" >
                                                                            <div class="message-img-list" v-for="img in item.images" :key="img" >
                                                                                <div > 
                                                                                    <a 
                                                                                        class="popup-img d-inline-block msgH"
                                                                                    >
                                                                                        <img :src="img" alt="" class="rounded border msgH">
                                                                                    </a> 
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- Ім'я користувача та час відправлення повідомлення -->
                                                                    <div class="conversation-name">
                                                                        <span class=" name">{{ this.nameWorker(item.workerId) }}</span><small class="text-muted time">{{item.datetime}}</small>
                                                                        {{ fullShowImg }}
                                                                        <span class="text-success check-message-icon"><i class="bx bx-check-double"></i></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="simplebar-placeholder" style="width: auto; height: 642px;"></div>
                                </div>
                                <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;"><div class="simplebar-scrollbar" style="width: 0px; display: none;"></div></div>
                                <div class="simplebar-track simplebar-vertical" style="visibility: hidden;"><div class="simplebar-scrollbar" style="height: 0px; display: none;"></div></div>
                            </div>
                        </div>

                        <!-- Блок для введення тексту повідомлення -->
                        <div class="chat-input-section p-3 p-lg-4">
                            <div class="row g-0 align-items-center">
                                <div class="col">
                                    <!-- Редактор для введення тексту повідомлення -->
                                    <!-- <QuillEditor v-if="this.checkMobile() !== true" v-model:content="form.message" :modules="modules" theme="snow" @keydown="sendMsg($event)" contentType="html" toolbar="full"/> -->
                                    <QuillEditor  v-model:content="form.message" :modules="modules" theme="snow" contentType="html" toolbar="full" @keydown="sendMsg($event)"/>
                                </div>
                                <div class="col-auto" style="align-self: end !important;">
                                    <div class="chat-input-links ms-2">
                                        <div class="links-list-item">
                                            <!-- Кнопка для відправлення повідомлення -->
                                            <!-- <button v-if="this.checkMobile() == true" v-touch:tap="sendMsg" class="btn btn-success chat-send waves-effect waves-light">
                                                <i class="ri-send-plane-2-fill align-bottom"></i>
                                            </button> -->
                                            <button @click="sendMsg" class="btn btn-success chat-send waves-effect waves-light">
                                                <i class="ri-send-plane-2-fill align-bottom"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto" style="align-self: end !important;">
                                    <div class="chat-input-links ms-2">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Відображення відповіді на повідомлення -->
                        <div v-if="replyShow" :class="`replyCard ${replyShow ? 'show' : ''}`" >
                            <!-- Картка відповіді -->
                            <div class="card mb-0">
                                <div class="card-body py-3">
                                    <div class="replymessage-block mb-0 d-flex align-items-start">
                                        <div class="flex-grow-1">
                                            <h5 class="conversation-name">{{ this.replyData.userName }}</h5>
                                            <p class="mb-0" v-html="replyData.repMsg"></p>
                                        </div>
                                        <div class="flex-shrink-0">
                                            <!-- <button type="button" v-if="this.checkMobile() === true" v-touch:tap="replyShow = false" class="btn btn-sm btn-link mt-n2 me-n3 fs-18">
                                                <i class="bx bx-x align-middle"></i>
                                            </button> -->
                                            <button type="button" @click="replyShow = false" class="btn btn-sm btn-link mt-n2 me-n3 fs-18">
                                                <i class="bx bx-x align-middle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-card>

    <!-- 
        Модальне вікно редагування повідомлення
        - для відкриття застосовується перевірка значення editWindow
        - @close - подія, яка спрацьовує при закритті картки
        - :type - параметр типу документу, тікет або проект ..
        - :id - ідентифікатор повідомлення
        - @getdata - оновлюємо список повідомлень
    -->
    <edit 
        v-if="editWindow == true" 
        :type="this.typeA" 
        :id="this.id" 
        @getdata="this.$emit('getdata')" 
        @close="editWindow = false"
    ></edit>

    <!-- 
        Модальне вікно підтвердження видалення повідомлення
    -->
    <dialogdeleteVue 
        v-if="deleteWindow == true" 
        @remove="removeMsg(this.messageOnDelete)"
        @close="deleteWindow = false"
    ></dialogdeleteVue> 

</template>

<script>
import BlotFormatter from 'quill-blot-formatter'
import edit from './action/edit.vue' // картка редагування повідомлення
import dialogdeleteVue from '../globaltable/actions/dialogdelete.vue'; // компонент діалогового вікна видалення
import { storeS } from '@/store'; // глобальне сховище даних
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import {copy} from '@/usabilityScripts/copy.js'
import { isMobile } from "@/usabilityScripts/mobileDevice.js"
 
export default{
    props: ['msg', 'type'],
    components: {
        edit,
        dialogdeleteVue,
    },
    data(){
        return{
            inRelease: false,
            objMsg: [],
            editWindow: false,
            deleteWindow: false,
            form: {
                message: ""
            },
            replyData: {
                userid: '',
                userName: '',
                repMsg: '',
                id: '',
                message: ''
            },
            replyShow: false,
            messageOnDelete:'',
            messageOnEdit: '',
            search: '',
            isFullscreen: false,
            colorsNew: [
                { color: '#1ABC9C', title: '1ABC9C' },
                { color: '#2ECC71', title: '2ECC71' },
                { color: '#3498DB', title: '3498DB' },
                { color: '#9B59B6', title: '9B59B6' },
                { color: '#34495E', title: '34495E' },
                { color: '#F1C40F', title: 'F1C40F' },
                { color: '#F39C12', title: 'F39C12' },
                { color: '#E74C3C', title: 'E74C3C' },
                { color: '#C2185B', title: 'C2185B' },
                { color: '#BDC3C7', title: 'BDC3C7' },
                { color: '#95A5A6', title: '95A5A6' },
                { color: '#607D8B', title: '607D8B' },
            ],
        }
    },
    components: {
        edit,
        dialogdeleteVue
    },
    created(){
        console.log('msg', this.msg)
        // Ініціалізація даних компонента та слухачів подій
        this.getdata();
        this.typeA = this.type;

        this.autoScroll;

        this.eventKey.off('esc');
        this.eventKey.on('esc', () => {
            this.replyShow = false
        });
    },
    methods: {
        // checkMobile() {
        //     return isMobile()
        // },
        updateMsgs() {
            this.$emit('getdata')
        },
        getdata(){
            // Отримання даних

            let messages = this.msg
            // Проходимося по кожному об'єкту у масиві
            messages.forEach(message => {
                // Шукаємо тег <img> у повідомленні
                let imgTagRegex = /<img.*?src="(.*?)".*?>/g;
                let matches = [...message.message.matchAll(imgTagRegex)];

                // Якщо знайдено зображення
                if (matches.length > 0) {
                    // Видаляємо тег <img> з повідомлення
                    message.message = message.message.replace(imgTagRegex, '');
                    
                    // Додаємо зображення до об'єкта
                    message.images = [];
                    matches.forEach(match => {
                        let imageUrl = match[1];
                        message.images.push(imageUrl);
                    });
                }
                message.message = message.message.replace(/<p>\s*<\/p>|<p><br><\/p>/g, '');
            });

            this.objMsg = messages
        },
        autoScroll(){
            // Метод автопрокрутки чату, в кінець чату

            const elem = document.getElementById('scrollChat');
            elem.scrollTop = elem.scrollHeight;
        },
        scrollToMsg(e){
            // Метод прокрутки до певного повідомлення в чаті

            const cont = document.getElementById('scrollChat');
            const el = document.getElementById('msg'+e.id);
            var posX = (el.offsetTop - 30); 
            el.classList.toggle("findMsg");
            setTimeout(function(){
                el.classList.remove("findMsg");
            }, 2000);
            cont.scrollTop = posX;
        },
        sendMsg(e){
            console.log(e)
            // Надсилання повідомлення

            // Перевіряємо, чи є пристрій мобільним, і якщо так, то надсилаємо повідомлення
            // if (this.checkMobile() == true) {
            //     this.$emit('sendMsg', this.form)
            //     return
            // }

            // Перевірка, чи натиснута клавіша Enter разом з Ctrl або Command
            if(((e.key == 'Enter' && e.ctrlKey == true) || ((e.key == 'Enter' && e.metaKey == true))) || e.pointerType == 'mouse') {
                // Перевірка, чи активований режим відповіді
                if(this.replyShow){
                    // Встановлення повідомлення для відповіді та емітовання події sendReplyMsg
                    this.replyData.message = this.form.message
                    this.$emit('sendReplyMsg', this.replyData)
                } else{
                    this.$emit('sendMsg', this.form)
                }

                // Очищення форми повідомлення та виходження з режиму відповіді
                this.form.message = '';
                this.replyShow = false;
            }
        },
        inputText(text) {
            // Метод для обробки текстового введення

            this.form.message = text.target.innerHTML;
        },
        getInitials(string) {
            // Метод для генерації ініціалів з рядка

            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        nameWorker(item){
            // Отримання імені працівника
            return nameWorker(item)
        },
        reply(e){
            // Метод для обробки відповіді на повідомлення

            // Встановлення даних відповіді на основі отриманого повідомлення
            this.replyData.repMsg = e.message
            this.replyData.userid = e.workerId
            this.replyData.userName = this.nameWorker(e.workerId)
            this.replyData.id = e.id;

            // Активація режиму відповіді
            this.replyShow = true;
        },
        replyMsg(item){
            // Метод для отримання деталей відповіді на повідомлення

            var name = '';
            var text = '';

            // Пошук повідомлення для відповіді в списку повідомлень
            this.msg.forEach((el) => {
                if(el.id === item.replyMessageId) {
                    name = this.nameWorker(el.workerId);
                    text = el.message;
                }
            })

            return { name, text }
        },
        editRequest(e) {
            // Метод для ініціації редагування повідомлення

            this.id = e.id;
            this.editWindow = true
        },
        removeMsg(e) {
            // Метод для видалення повідомлення

            this.$emit('removeMsg', e)
        },
        removeRequest(e) {
            this.messageOnDelete = e
            this.deleteWindow = true
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        getColorByWorkerId(workerId) {
            const numericValue = workerId;
            const index = numericValue % this.colorsNew.length;
            return this.colorsNew[index].color;
        },
        expandBlock(){
            this.isFullscreen = !this.isFullscreen;
        },
    },
    setup: () => {
        // Налаштування компонентів
        const modules = {
            name: 'blotFormatter',  
            module: BlotFormatter, 
            options: {/* options */}
        }
        return { modules }
    },
    mounted(){
        // Автоматична прокрутка до кінця списку повідомлень
        this.autoScroll()

        // Додавання обробників подій для зображень у повідомленнях
        for(let i = 0; i < document.querySelectorAll('.msgH').length; i++){
            if(document.querySelectorAll('.msgH')[i].querySelectorAll('img').length != 0){
                document.querySelectorAll('.msgH')[i].querySelectorAll('img').forEach(el => {
                    el.addEventListener('click', () => {
                        this.eventBus.emit('fullImageSize', {
                            url: el.getAttribute("src") + '',
                        })
                    })
                })
            }
        }

        // console.log("msg > ", this.msg);

        // Отримати всі елементи з класом "mb-1" та зберегти їх у messages
        // var elements = document.querySelector('.msg1678');
        // // // elements.forEach((element) => {
        // //     // this.messages.push(element.innerHTML);
        //     console.log("le", elements);
        //     var imgElement = elements.querySelector('img');
    
        // //     // Перевірити, чи є такий тег
        //     if (imgElement) {
        //         // Отримати значення атрибуту src
        //         var imageUrl = imgElement.getAttribute('src');
                
        //         // Вивести посилання на зображення
        //         console.log("imageUrl > ",imageUrl);
        //     }
        // });
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        workers(){
            /*
                Повертає список співробітників з глобального стору.
            */
            return storeS.workers
        },
        perms(){
            return storeS.perms
        },
        filteredItems: function() {
            // пошук по повідомленням
            return Object.values(this.msg)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.message.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        width() {
            return window.innerWidth
        }
    }
}
</script>

<style scoped>
    .deleted{
        background-color: red;
    }
    .ctext-wrap-content p {
        margin: 0!important;
    }
    .ctext-wrap-content img {
        width: 100%;
    }
    .findMsg {
        border-radius: 10px;
        background-color: #47a7dd42;
        padding: 10px;
        margin: 10px 0;
        transition: .3s;
    }
    .replyCard{
        bottom: 75px;
    }
    .search::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.search::-webkit-scrollbar
{
	width: 5px;
    height: 5px;
	background-color: #F5F5F5;
}

.search::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}

.msgH {
    text-align: start;
}

.chat-conversation {
    height: calc(100vh - 500px);
}
.chat-conversation-full {
    height: calc(100vh - 230px)!important;
}
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: white; /* Змініть колір фону за потреби */
}

.dropdown-toggle::after {
    display: none
}

.chat-conversation .center {
    justify-content: center;
}
</style>