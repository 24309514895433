<template>
    <!-- модальное окно создания репорта -->
    <modal>
        <template v-slot:title>{{ $t('createReport') }}</template>
        <template v-slot:status><span class="text-muted mb-0 fs-13">{{ $t('ticket') }} #{{ id }}</span></template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('nameReport') }}</h4>
                    <input type="text" class="form-control" id="contactNumber" v-model="form.reportName" placeholder="Например">
                </div>
                <div class="mb-3">
                    <label class="form-label">{{ $t('desc') }}</label>
                    <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="form.description" placeholder="Описание проблемы, фото"></textarea>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{ $t('howtoProduce') }}</label>
                    <QuillEditor v-model:content="form.howProduce" theme="snow" contentType="html" toolbar="full"/>
                    <!-- <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="form.steps_create" placeholder="Шаги к созданию"></textarea> -->
                </div>
                <div class="mb-3">
                    <label class="form-label">{{ $t('actualResult') }}</label>
                    <input type="text" class="form-control" v-model="form.actualResult">
                </div>
                <div class="mb-3">
                    <label class="form-label">{{ $t('expectedResult') }}</label>
                    <input type="text" class="form-control" v-model="form.expectedResult">
                </div>
                
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
            <button type="button" class="btn btn-primary" v-on:click="add">{{ $t('Add') }}</button>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small'
import { ReportsTickets } from '@/API.js';
import { storeS } from '@/store'

let apiServe = new ReportsTickets();

export default ({
    data(){
        return {
            form: {
               reportName: '',
               description: '',
               howProduce: '',
               actualResult: '',
               expectedResult: '',
            }
        }
    },
    components: { modal },
    props: ['id'],
    created(){
    },
    methods: {
        add() {
            if (!this.form.reportName) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
            }
            if (!this.form.description) {
                //перевіряємо чи вказаний опис
                this.$toast.error(this.$t('EnterDesc'));
            }
            if (!this.form.howProduce) {
                //перевіряємо чи вказаний алгоритм дій
                this.$toast.error(this.$t('EnterhowProduce'));
            }
            if (!this.form.actualResult) {
                //перевіряємо чи вказаний актуальний результат
                this.$toast.error(this.$t('EnterActualResult'));
            }
            if (!this.form.expectedResult) {
                //перевіряємо чи вказаний очікуваний результат
                this.$toast.error(this.$t('EnterExpectedResult'));
            }

            if(this.form.reportName && this.form.description && this.form.howProduce && this.form.actualResult && this.form.expectedResult){
                apiServe.addReportTicket(this.id, this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('close')
                        this.eventBus.emit('getReports', true)
                    } else {
                        this.$toast.error(this.$t('error') + " #AddReport");
                    }
                }) // запрос
            }
        },
    },
    mounted(){
        
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
})
</script>
