<template>
    <!-- компонент "шапки" тікету -->

    <div 
        class="dropdown" 
        v-if="perms[101] == true || perms[304] == true || perms[354] == true || perms[2000] == true"
    >
        <button 
            class="btn btn-ghost-secondary btn-icon btn-sm fs-16" 
            type="button" 
            data-bs-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
        >
            <i class="bx bx-dots-vertical-rounded align-bottom"></i>
        </button>

        <div 
            class="dropdown-menu dropdown-menu-end"
        >
            <!-- 
                Створити заявку
                - перевірка йде на права доступу 101 (створити заявку) або 2000 (режим бога)
            -->
            <button 
                class="dropdown-item" 
                v-if="perms[101] || perms[2000]" 
                @click="openapplicbox()"
            >
                {{ $t('Application') }}
            </button>

            <!-- 
                Додати нагадування в календар 
                - перевірка йде на налаштування до акаунту 026 (пропонувати створити нагадування) і статус - новий
            -->
            <button 
                class="dropdown-item" 
                v-if="this.checks['026'] == true && this.form.status == 'new'" 
                @click="addReminder()"
            >
                {{ $t('AddReminderInCalendar') }}
            </button>

            <!-- 
                Додати репорт до тікету
                - перевірка йде на права доступу 304 (створити репорт) або 2000 (режим бога) і статус - не має дорівнювати "закритий"
            -->
            <button 
                class="dropdown-item" 
                v-if="(perms[305] || perms[2000]) && this.form.status !== 'closed'" 
                @click="openreportBox()"
            >
                {{ $t('report') }}
            </button>  
            
            <!-- 
                Редагування тікету 
                - перевірка йде на права доступу 304 (редагувати тікет) або 2000 (режим бога) і статус - має дорівнювати "новий" або "очікує на взяття в роботу"
            -->
            <button 
                class="dropdown-item" 
                v-if="(perms[304] || perms[2000]) && (this.form.status == 'new' || this.form.status == 'wait_jobs')" 
                @click="this.$emit('edit', this.form)"
            >
                {{ $t('Edit') }}
            </button> 

            <!-- 
                Редагувати дедлайн 
                - перевірка йде на права доступу 371(створити оновлення) або 2000 (режим бога)
                - - йде перевірка на користувача, він має бути автором тікету, або тим хто взяв в роботу
            -->
            <button 
                class="dropdown-item" 
                v-if="(perms[2000] || perms[371] == true) 
                        && (this.user.userid === this.form.createWorkerId || this.form.inJobWorkers.find((el) => this.user.userid == el.workerId))" 
                @click="this.$emit('getUpdates', this.obj)"
            >
                {{ $t('editDeadline') }}
            </button>

        </div>
    </div>

    <!-- Згорнути модальне вікно -->
    <!-- <button 
        type="button" 
        class="btn btn-soft-warning btn-icon btn-sm fs-16 close-btn-email" 
        
    >
        <span>_</span>
    </button> -->

    <!-- Закрити модальне вікно тікету -->
    <button 
        type="button" 
        class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" 
        @click="$emit('close')"
    >
        <i class="ri-close-fill align-bottom"></i>
    </button>
        
</template>

<script>
import { storeS } from '@/store';

export default {
    props: ['obj'],
    components:{},
    data(){
        return {
            form: "",
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        // getUpdates(){
        //     apiUpdates.getAllUpdates().then(result => {
        //         if(result.status === 'done'){
        //             this.updateslist = result.data.items
        //             this.modalEdit = true
        //         }
        //     })
        // },
        getdata(){
            this.form = this.obj
        },
        opensmsbox(){
            this.$emit('opensmsbox')
        },
        openapplicbox(){
            this.$emit('openapplicbox')
        },
        opentaskBox(){
            this.$emit('opentaskBox')
        },
        openticketBox(){
            this.$emit('openticketBox')
        },
        openreportBox(){
            this.$emit('openreportBox')
        },
        addReminder(){
            this.$emit('addReminder');
        }
    },
    computed:{
        perms(){
            return storeS.perms
        },
        checks(){
            return storeS.checks
        },
        user() {
            return storeS.userbase
        }
    }
}
</script>