<template>
    
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms['1701'] || this.perms['2000']" 
        @create="showModalAdd = true"
        @getdata="getdata"
        @changelimit="changelimit"
        @open="open"
    />
    
    <viewbox 
        v-if="showModal" 
        @close="showModal = false" 
        :obj="objCard" 
        @remove="remove" 
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen"
    />

    <createBox 
        v-if="showModalAdd" 
        @close="showModalAdd = false" 
        :number="number"
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewbox from '@/views/invoices/view.vue'
import createBox from '@/views/invoices/createinvoices.vue'
import { Invoices } from '@/API.js';
import { storeS } from '@/store';
import { mutateStatusInv, mutateProviderInv } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Invoices();

export default {
    props: ['obj', 'number'],
    components: { 
        tablecustom, 
        createBox,
        viewbox,
    },
    data() {
        return {
            form: {},
            showModal: false,
            showModalAdd: false,
            infoblockShow: false,
            ifNextopen: "",
            ifPrevopen: "",
            objcard: '',
            objPages: "",
            dataParams: {
                status: false,
                page: "invoices"
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                sellerPhone: ''
            },
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "documentId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Provider"),
                    text: "provider",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateProvider(item)
                },
                {
                    name: this.$t("created"),
                    text: "workerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("client"),
                    text: "sellerPhone",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("date"),
                    text: "datetime",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Sum"),
                    text: "sum",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Status"),
                    text: "payment",
                    align: "right",
                    status: true,
                    mutate: (item) => this.mutateStatus(item.status).name,
                    mutateClass: (item) => this.mutateStatus(item.status).color
                }
            ],
            information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                    sum: "0"
                },
                {
                    title: this.$t('Paid'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success"
                },
                {
                    title: this.$t('awaitingPayment'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning"
                },
                {
                    title: this.$t('Canceled'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger"
                }
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(page){
            this.objParams.sellerPhone = this.number
            this.objParams.page = page != undefined ? page : '1';
            apiServe.getAllInv(this.objParams).then(result => {
                if(result.status === "done") {
                    this.rows = result.data.products;
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` #1700`);
                }
            })
        },
        searchB(e){
            this.objParams.search = e
            this.getdata();
        },
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        change(name, e){
            if(name == 'Status'){
                this.selectstatus = e;
            } else if(name == 'created'){
                this.selectcreated = e;
            } else if(name == 'Provider'){
                this.selectprovider = e
            }
        },
        open(e){
            var id = e.documentId ? e.documentId : e;
            this.ifPrevopen = id == this.rows[0].documentId ? false : true;
            this.ifNextopen = id == this.rows[this.rows.length-1].documentId ? false : true;
            apiServe.getInv(id).then(result => {
                if(result.status == 'done') {
                    this.objCard = result.data;
                    this.showModal = true;
                }
            })
        },
        prevOpen(e){
            var current_id = e;
            var prev_id = ""; 

            for(var value in this.rows){
            if(this.rows[value].documentId == current_id){
                prev_id = this.rows[Number(value)-Number('1')].documentId;
            }
            }
            this.open(prev_id);
        },
        nextOpen(e){
            var current_id = e;
            var next_id = "";

            for(var value in this.rows){
            if(this.rows[value].documentId == current_id){
                next_id = this.rows[Number(value)+Number('1')].documentId;
            }
            }
            this.open(next_id);
        },
        remove: function(e, form){
            console.log(form);
            apiServe.deleteInv(form.documentId).then(res => {
                if(res.status === 'done'){
                    this.showModal = false;
                    this.$toast.success(this.$t('Removed'));
                    this.getdata();
                }
            })
        },
        mutateStatus(e){
            return mutateStatusInv(e);
        },
        mutateProvider(e){
            return mutateProviderInv(e)
        },
    },
    mounted() {
        this.eventBus.on('saveINV', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    },
    computed: {
        checks() {
            return storeS.checks
        },
        perms() {
            return storeS.perms
        }
    }
}
</script>