<template>
    <modal :title="form.discountTitle">
        <template v-slot:body>
            <b-card>
                <b-row>
                    <div class="card-header mb-3">
                        <h5 class="card-title mb-0"><b>{{ $t('main') }}</b></h5>
                    </div>
                    <b-col lg="6">
                        <h4 class="fs-18 fw-semibold">{{ $t('Name') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.discountTitle" disabled>
                            <button @click="copy(form.bonusTitle)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h4 class="fs-18 fw-semibold">{{ $t('desc') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.discountDescription ? form.discountDescription : '-'" disabled>
                            <button @click="copy(form.discountDescription)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <div class="mb-1">
                        <h5 class="card-title mb-3"><b>{{ $t('discount') }}</b></h5>
                        <div class="d-flex">
                            <div class="form-check mb-2" style="margin-right: 15px;">
                                <input class="form-check-input fs-14" type="radio" name="radioTypes" id="all_types" value="procent" v-model="form.discountType" disabled>
                                <label class="form-check-label fs-14" for="all_types">{{ $t('inProcent') }}</label>
                            </div>
                            <div class="form-check mb-2" >
                                <input class="form-check-input fs-14" type="radio" name="radioTypes" id="type0" value="summ" v-model="form.discountType" disabled>
                                <label class="form-check-label fs-14" for="type0">{{ $t('ForTheAmount') }}</label>
                            </div>
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <div class="card-header mb-3">
                        <h5 class="card-title mb-0"><b>{{ $t('password') }}</b></h5>
                    </div>
                    <b-col lg="6">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.discountPassword.value" disabled>
                            <button @click="copy(form.discountPassword.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <div class="card-header mb-3">
                        <h5 class="card-title mb-0"><b>{{ $t('Options') }}</b></h5>
                    </div>
                    <template v-for="item in checkboxBonus" :key="item">
                        <div class="d-flex">
                            <div class="check-as form-check form-check-success">
                                <input type="checkbox" autocomplete="off" class="form-check-input" :id="item.value" v-model="form[item.value]" disabled>
                            </div>
                            <div class="flex-grow-1" style="padding-top: 5px;" >
                                <label class="form-check-label fs-16" :for="item.value" >
                                    <b>{{ item.title }}</b> 
                                </label>
                            </div>
                        </div>
                    </template>
                    <b-col lg="6">
                        <div class="mb-2 mt-3">
                            <h4 class="fs-16 fw-semibold">{{ $t('Discount') }}</h4>
                            <div class="input-group mb-2" >
                                <span class="input-group-text" id="product-price-addon">{{ form.discountType == 'summ' ? '₴' : '%' }}</span>
                                <input type="number" class="form-control" id="product-price-input" v-model="form.discount" disabled>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-card>

            <tablecustom 
                v-if="form.allproducts == false"
                @search="searchB" 
                :objParams="objParams"
                :columns="columns" 
                :rows="filteredItems" 
            />

        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/modal-lg'
import tablecustom from '@/components/globaltable/index';
import {copy} from '@/usabilityScripts/copy.js'

export default{
    props: ['obj'],
    components: {
        modal,
        tablecustom
    },
    data(){
        return{
            form: '',
            objParams: {
                search: ''
            },
            checkboxBonus: [
                { title: this.$t('DiscountCheckbox1'), value: 'allproducts'},
                { title: this.$t('DiscountCheckbox2'), value: 'discountSale'},
                { title: this.$t('DiscountCheckbox3'), value: 'discountModificators'},
                { title: this.$t('DiscountCheckbox4'), value: 'noBonusWriteoff'},
                { title: this.$t('DiscountCheckbox5'), value: 'round'},
            ],
            columns: [
                {
                    name: "ID",
                    text: "nomenclatureId",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("Name"),
                    text: "nomenclatureName",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("price"),
                    text: "price",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("PriceAfterDiscount"),
                    text: "price",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item) => item ? (Number(item) - Number(this.form.discount)) : '-' 
                },
            ],
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        searchB(e){
            this.objParams.search = e
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form.products)
                // По полю поиска
                .filter(item => {
                    return this.objParams.search == '' || item.nomenclatureName.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>

<style>
.check-as{
    margin: 5px;
    margin-right: 10px;
}
.check-as .form-check-input {
    width: 1.6em;
    height: 1.6em;
}
.form-check-success .form-check-input:checked{
    background-color: #0ab39c;
    border-color: #0ab39c;
}
</style>