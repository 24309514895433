<template>
    <!-- 
        Модальне вікно з інформацією про зміну, запускається після закриття зміни
    -->
    
    <dialogmodal>
        <template v-slot:body>
            <div class="mt-4 text-center">
                <h3><b>{{ $t('infoShift') }}</b></h3>
            </div>
            <div class="row">
                <!-- Тривалість -->
                <div class="col">{{ this.$t('Duration') }}</div>
                <!-- Тривалість зміни -->
                <div class="col">{{ this.form.duration.split(', ') }}</div>
            </div>
        </template>
        <template v-slot:footer-bottom>
              <div class="hstack gap-2 justify-content-center remove">
                  <a href="https://cabinet.skyservice.online/" class="btn btn-link link-danger fw-medium text-decoration-none" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</a>
              </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue' // компонент модального вікна
import { storeS } from '@/store' // глобальне сховище даних

export default {
    props: ['obj'],
    components: {
        dialogmodal
    },
    data(){
        return{
            form: "",
            hours: '', // кількість годин
            minutes: '', // кількість хвилин
            seconds: '', // кількість секунд
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        getTime() {
            // Отримання тривалості в потрібному форматі
            this.hours = this.time.split(', ')[0].split(' ')[0]
            this.minutes = this.time.split(', ')[1].split(' ')[0]
            this.seconds = this.time.split(', ')[2].split(' ')[0]
        },
    },
    mounted(){
        setTimeout(function(){
            // Редірект в кабінет
            location.href="https://cabinet.skyservice.online/"
        }, 20000)
    },
    computed: {
        user(){
            /*
                Повертає дані користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
}
</script>