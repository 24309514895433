<template>
    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="checks['001']">
      <button @click="infoblockShow == true ? infoblockShow = false : infoblock()" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
    </div>
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        :datafilter="filterAll"
        :objParams="objParams"
        @change="change" 
        @clearFilter="clearFilter"
        @open="open"
    />

    <views v-if="showModal == true" @close="showModal = false" :obj="objCard" />
</template>

<script>
import tablecustom from '@/components/globaltable/index';
import infoblocks from '@/components/info/blocks'
import { Clients } from '@/API.js'
import views from './view.vue'
import { storeS } from '@/store';
import { mutateWorkersAccount, mutateDirWorker, mutateWorkerPosition } from '@/usabilityScripts/globalMutate.js'

let apiClients = new Clients();

export default {
    props: ['obj'],
    components: { 
        tablecustom, 
        infoblocks, 
        views 
    },
    data() {
        return {
            form: {},
            search: "",
            infoblockShow: false,
            showModal: false,
            objCard:"",
            objParams: {
                search: ''
            },
            columns: [
                {
                    name: this.$t("Status"),
                    text: "active",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item, obj) => obj.deleted ? this.mutateUser('removed').name : this.mutateUser(item).name,
                    mutateClass: (item, obj) => obj.deleted ? this.mutateUser('removed').color : this.mutateUser(item).color
                },
                {
                    name: this.$t("phone_num"),
                    text: "workerPhone",
                    align: "left",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("name"),
                    text: "workerName",
                    align: "right",
                    status: true,
                    copy: true
                },
                {
                    name: this.$t("Position"),
                    text: "workerJob",
                    align: "right",
                    status: true,
                    copy: true,
                    mutate: (item) => this.mutatePosition(item)
                },
                {
                    name: "PIN",
                    text: "workerData",
                    align: "right",
                    copy: true,
                    status: true,
                    mutate: (item) => item.pin ? item.pin : '-'
                },
                {
                    name: this.$t('openEntry'),
                    text: "workerData",
                    align: "right",
                    copy: false,
                    status: true,
                    mutate: (item) => item.start_shift ? this.mutateDir(item.start_shift) : '-',
                },
            ],
            filterAll: [
                    {
                        page: "workers",
                        name: "Status",
                        category: "0",
                        items: []
                    },
                    {
                        page: "workers",
                        name: "Position",
                        category: "0",
                        items: []
                    },
                ],
            selectStatus: '0',
            selectJob: '0',
            information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                },
                {
                    title: this.$t('Activated'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success",
                },
                {
                    title: this.$t('blocked_user'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger",
                },
                {
                    title: this.$t('Removed'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger",
                },
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj;


            for(var item in this.form){
                if(this.form[item].active == true && this.form[item].deleted == false){
                    this.form[item].status = 'active'
                } else if(this.form[item].active == false && this.form[item].deleted == false){
                    this.form[item].status = 'blocked'
                } else if (this.form[item].deleted == true){
                    this.form[item].status = 'removedev'
                }
            }
            // let newobj = this.obj
            let newArrStatus = Array.from(new Set(this.form.map(item => JSON.stringify(item.status)))).map(item => JSON.parse(item));
            this.filterAll[0]['items'] = newArrStatus;
            let newArrJob = Array.from(new Set(this.form.map(item => JSON.stringify(item.workerJob)))).map(item => JSON.parse(item));
            this.filterAll[1]['items'] = newArrJob;
        },
        infoblock(){
            console.log('infoblock', this.filteredItems);
            this.information[0].value = this.filteredItems.length
            this.information[1].value = this.filteredItems.filter(item => item.status == 'active').length
            this.information[2].value = this.filteredItems.filter(item => item.status == 'blocked').length
            this.information[3].value = this.filteredItems.filter(item => item.status == 'removedev').length
            
            this.infoblockShow = true
        },
        searchB(e){
            this.objParams.search = e
        },
        open(e){
            this.objCard = e;
            this.showModal = true;
        },
        change(name, e){
            if(name == 'Status'){
                this.selectStatus = e;
            } else if(name == 'Position') {
                this.selectJob = e
            }
        },
        resetPass(e){
            apiClients.new_reset_passwordClient(e).then(res => {
                (res)
                if(res.status == "done"){
                    this.$toast.success(this.$t('resetPasswordDone'));
                }else {
                    this.$toast.error(this.$t('err'));
                }
            })
        },
        mutateDir(e){
            return mutateDirWorker(e)
        },
        mutateUser(e){
          return mutateWorkersAccount(e)
        },
        mutatePosition(e){
            return mutateWorkerPosition(e)
        },
        clearFilter(){
            this.selectStatus = '0';
            this.selectJob = '0'
        }
    },
    watch:{
        obj(value, oldValue){
            // this.form = value
            this.getdata(value)
        }
    },
    computed: {
        filteredItems: function() {
            return this.form
                // по пошуку
                .filter(item => {
                    return this.objParams.search == '' || item.workerPhone.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1 || item.workerName.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1;
                })

                // по статусу співробітника
                .filter(item => {
                    return this.selectStatus == '0' || item.status === this.selectStatus ;
                })

                // по посаді співробітника
                .filter(item => {
                    return this.selectJob == '0' || item.workerJob === this.selectJob ;
                })
        },
        checks(){
            return storeS.checks
        }
    }
}
</script>