<template>
    <b-row>
      <component :is="tab"></component>
    </b-row>
    <b-row class="match-height">
        <div style="border: 1px rgba(119, 116, 116, 0) solid; border-radius: 10px; background-color: white; padding: 20px; margin-bottom: 20px; box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.079); display: grid; grid-template-columns: 7fr 2fr 1fr;s">
            <div style="width: 70%; grid-column-start: 1; grid-column-end: 2">
                <searchBox @searchB="searchB"/>
            </div>
            <div style="grid-column-start: 3; grid-column-end: 4; align-self: center; justify-self: center;">
                <b-button
                  @click="this.scriptboxCreateShow = true"
                  class="btn btn-success"
                ><i class="ri-add-line align-bottom me-1"></i></b-button
                >
            </div>
        </div>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="4"
        sm="6"
        v-for="items in this.scripts"
      >
        {{ items }}
        <b-card>
          <div class="d-flex border-bottom pb-2">
            <h6 class="card-title fw-semibold mb-0 flex-grow-1 fs-20">LOl1  <span class="badge badge-soft-secondary fs-14">Lol</span></h6>
          </div>
          <ul class="list-unstyled vstack gap-3 mb-0 mt-2">
              <li class="click">
                <a @click="open(key, item)">
                  <div class="d-flex align-items-center">
                      <div class="flex-grow-1 ms-2 pt-2 pb-2">
                          <h6 class="mb-1"><b>Lol</b></h6>
                      </div>
                  </div>
                  </a>
              </li>
          </ul>
          <!-- <div class="mt-3 text-center">
            <a class="text-muted text-decoration-underline copy">View all Events</a>
          </div> -->
        </b-card>
          <!-- <b-card
          >
          <a>
            <div class="kb-title-button" style="display:grid; grid-template-columns: 80% 20%;">
              <div>
                <h6 class="kb-title">
                  <feather-icon
                    size="20"
                    class="mr-50"
                  />{{ item.categoryName }}
                </h6>
              </div>
              <div>
                <div class="dropdown">
                    <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="bx bxs-plus-circle align-bottom"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">
                        <a class="dropdown-item" @click="this.editreq(item)">{{ $t('Edit') }}</a>
                        <a class="dropdown-item" @click="this.delreq(item)">{{ $t('Delete') }}</a> 
                    </div>
                </div>
              </div>
            </div>
            </a>
            <hr />
            <a v-for="key in item.lessons" :key="key">
              <p>{{ key.lessonName }}</p>
            </a>
          </b-card> -->
        </b-col>
      </b-row>
    
    <!-- script create -->
      <scriptboxCreateMe v-if="scriptboxCreateShow == true" @close="this.scriptboxCreateShow = false"></scriptboxCreateMe>
    <!-- script create -->
</template>

<script>
import scriptboxCreateMe from '@/views/scriptsForManagers/create.vue'
import searchBox from "@/components/globaltable/searchbox.vue";
import { Scripts } from '@/API.js'

let apiScripts = new Scripts()

export default {
    data() {
        return {
          scriptboxCreateShow: false,
          showCreate: '',
          scripts: '',
          objParams: {
            search: '',
          }
        }
    },
    components: {
      searchBox,
      scriptboxCreateMe,
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        apiScripts.getScripts(this.objParams).then(result => {
          if(result.status == 'done') {
            this.scripts = result.data.items
          }
        })
      },
      addScript(e) {
        // apiScripts.addScript(e).then(result => {
        //   if(result.status == 'done') {
        //     this.$toast.success(this.$t('success'))
        //   } else {
        //     this.$toast.error(this.$t('error'))
        //   }
        // })  
      },
      searchB(e){
      },
    }
}
</script>

<style scoped>

</style>