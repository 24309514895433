<template>
    <div class="border-end sticky-block" style="padding-right: 7px;">
    <div class="card-body text-center">
        <!-- <div class="position-relative d-inline-block">
            <img src="assets/images/users/avatar-10.jpg" alt="" class="avatar-lg rounded-circle img-thumbnail">
            <span class="contact-active position-absolute rounded-circle badge-soft-success"><span class="visually-hidden"></span>
        </span></div> -->
        <h5 class="mt-2 mb-1">{{ form.user_name }}</h5>
        <p class="text-muted">{{ Number(form.user_phone)}}</p>

        <ul class="list-inline mb-0">
            <li class="list-inline-item avatar-xs" v-if="this.info.workerJob == 'managercallcenter' || this.perms[2000] == true">
                <a href="javascript:void(0);" class="avatar-title badge-soft-success text-success fs-15 rounded" style="border:none" @click="call(String(this.form.user_phone), this.form)">
                    <i class="ri-phone-line"></i>
                </a>
            </li>
            <li class="list-inline-item avatar-xs">
                <button @click="sendMAIL()" class="avatar-title badge-soft-danger text-danger fs-15 rounded" style="border: none;">
                    <i class="ri-mail-line"></i>
                </button>
            </li>
            <li class="list-inline-item avatar-xs">
                <button @click="sendSMS()" class="avatar-title badge-soft-warning text-warning fs-15 rounded" style="border: none;">
                    <i class="ri-question-answer-line"></i>
                </button>
            </li>
        </ul>
    </div>

        <b-tabs 
            pills
        >
            <b-tab :title="$t('Main')">
                <div style="padding: 0px 12px;;">
                    <div class="d-flex align-items-center py-3">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 text-muted">{{ $t('name') }}</h5>
                                <p class="fs-15  mb-0 fw-normal" style="word-break: break-all;">{{ form.users[0].user_name }}</p>
                            </div>
                        </div>
                        <div class="flex-shrink-0 ms-2">
                            <button type="button" @click="copy(form.users[0].user_name)" class="btn btn-sm btn-outline-success fs-14"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 text-muted">{{ $t('phone_number') }}</h5>
                                <p class="fs-15 mb-0 fw-normal" style="word-break: break-all;">{{ Number(form.user_phone) }}</p>
                            </div>
                        </div>
                        <div class="flex-shrink-0 ms-2">
                            <button type="button" @click="copy(Number(form.user_phone))" class="btn btn-sm btn-outline-success fs-14"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 text-muted">{{ $t('Status') }}</h5>
                                <p :class="`fs-15 mb-0 ${this.mutateStatus(form.users[0].user_clientstatus).color}`">{{ this.mutateStatus(form.users[0].user_clientstatus).name }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 text-muted">{{ $t('mail') }}</h5>
                                <p class="fs-15  mb-0 fw-normal" style="word-break: break-all;">{{ form.users[0].user_email == null ? "-": form.users[0].user_email }}</p>
                            </div>
                        </div>
                        <div class="flex-shrink-0 ms-2">
                            <button type="button" @click="copy(form.users[0].user_email)" class="btn btn-sm btn-outline-success fs-14"><i class="bx bx-copy-alt align-middle"></i></button>
                        </div>
                    </div>
                    <div class="d-flex align-items-center py-2" v-if="form.refidacc">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 text-muted">{{ $t('CameFrom') }}</h5>
                                <p class="fs-15  mb-0 fw-normal">{{ form.refidacc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('Details')">
                <div>
                    <div class="d-flex align-items-center py-3">
                        <div class="flex-grow-1">
                            <div>
                                <h5 class="fs-14 mb-1 text-muted">{{ $t('Registered') }}</h5>
                                <p class="fs-13  mb-0">{{ form.user_date }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { storeS } from "../../../../store"
import { mutateStatusAccounts } from '@/usabilityScripts/globalMutate.js'
import {copy} from '@/usabilityScripts/copy.js'

export default({
    props: ['obj'],
    data() {
        return {
            form: ''
        }
    },
    created(){
        this.getdata()
    },
    computed: {
        info() {
            return storeS.userbase
        },
        perms() {
        return storeS.perms
        }
    },
    methods: {
        call(e) {
            
            this.$emit('call', e)
        },
        sendSMS() {
            this.$emit('opensmsbox')
        },
        sendMAIL() {
            this.$emit('openmailbox')
        },
        getdata(){
            this.form = this.obj
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        mutateStatus(e){
            return mutateStatusAccounts(e)
        }
    }
})
</script>
