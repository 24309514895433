<template>
    <b-card>
        <div class="form-check form-check-success mb-3">
            <input class="form-check-input" type="checkbox" id="formCheck8" v-model="checked">
            <label class="form-check-label" for="formCheck8">{{ $t('LeaveCall') }}</label>
        </div>
        <div v-if="checked == true">
            <div  class="mb-3">
                <label class="form-label mb-2">{{ $t('specify_date_timeNeedCall') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setTime(1)" style="margin-right: 5px;">{{ $t('AfterOneHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setTime(2)" style="margin-right: 5px;">{{ $t('AfterTwoHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setTime(3)" style="margin-right: 5px;">{{ $t('AfterThreeHour') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setTime(14)" style="margin-right: 5px;">{{ $t('AtLunch') }}</span>
                    <span class="fs-14 badge bg-secondary copy mb-2" @click="setTime(16)">{{ $t('AtNight') }}</span>
                </div>
                <flatpickr v-model="formRequest.delayedDatetime" :config="config" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
            </div>
            <div  class="mb-3">
                <label class="form-label mb-2">{{ $t('infoFutureCall') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-info copy mb-2" @click="formRequest.comment = $t('NoAnswer')" style="margin-right: 5px;">{{ $t('NoAnswer') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="formRequest.comment = $t('AskedToDialLater')" style="margin-right: 5px;">{{ $t('AskedToDialLater') }}</span>
                </div>
                <textarea class="form-control bg-light border-light" v-model="formRequest.comment" :placeholder="$t('infoFutureCall')"></textarea>
            </div>
        </div>
        <div class="row g-3">
            <div class="col-lg-12">
                <label for="exampleFormControlTextarea1" class="form-label fw-semibold">{{ $t('Comment') }}</label>
                <p class="text-success fw-semibold">{{ $t('commentWillbeAdded') }}</p>
                <div class="text-center mb-2">
                    <b-button variant="outline-info" class="waves-effect waves-light" @click="fixation()" >{{ $t('Fixation') }}</b-button>
                </div>
                <textarea class="form-control bg-light border-light" id="exampleFormControlTextarea1" rows="3" v-model="form.comment" :placeholder="$t('enterComment')"></textarea>
            </div>
            <div class="col-12 text-end">
                <a class="btn btn-success" @click="checked ? addRequest() : saveComment(comment)">{{ $t('save') }}</a>
            </div>
        </div>
    </b-card>
</template>

<script>
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { storeS } from '@/store';
import { Clients, Applications } from '@/API'

let apiServe = new Clients();
let apiApplications = new Applications();

export default {
    props: ['obj', 'number', 'name'],
    components: {
        flatpickr: Vueflatpickr
    },
    data(){
        return{
            form: {
                userPhone: "",
                comment: ""
            },
            formRequest: {
                userPhone: this.number, //номер телефона заявки
                priority: "2", //приоритет заявки
                status: "new", //статус заявки
                comment: "",
                userName: this.name,
                callDatetime: ""
            },
            checked: false,
            comment: "",
            config: {
                mode: "single",
                minDate: "today",
                altInput: true,
                locale: "",
                enableTime: true,
                dateFormat: "Y-m-d H:i",
                time_24hr: true
            },
            store_count_active: '',
            count_tt: '',
            products_counts_count: '',
            cookcard_count: ''
        }
    },
    created(){
        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }
    },
    methods: {
        saveComment(){
            this.form.userPhone = this.number;
            apiServe.fixationData(this.form).then(result => {
                // console.log('res', result);
            })
            this.form.comment = ''
        },
        change(){
            var arrDate = String(this.formRequest.delayedDatetime)
            this.formRequest.delayedDatetime = arrDate;
            // this.form.delayedTime = arrDate[1];
        },
        addRequest(){
            apiApplications.addRequest(this.formRequest).then(result => {
                if(result.status === 'error') {
                    switch(result.error[0]){
                        case "Request already exists":
                        this.$toast.error(this.$t("aplic_already_yet"))
                        this.$emit("close")
                        default: this.$toast.error(this.$t("error"))
                        this.$emit("close")
                    }
                }
                if(result.status === "done"){
                    // this.audioPlay()
                    this.formRequest.comment = ""
                    this.$toast.success(this.$t('сreatedT'));
                    this.eventBus.emit('saveApplic', true)
                    localStorage.removeItem("check_comment"+this.form.id);
                }
            })
        },
        fixation(){
            var string = "";
            this.store_count_active = this.obj.store_count_active;
            this.count_tt = this.obj.sklad.length;
            this.products_counts_count = this.obj.sklad[0].products_count;
            this.cookcard_count = this.obj.sklad[0].cookcard_count;
            var activeData = this.obj.sklad.filter(item => item.status == 'Тарифицируется');
            for(var i=0; i < activeData.length; i++){
                if(Number(this.products_counts_count) < Number(activeData[i].products_count)){
                    this.products_counts_count = activeData[i].products_count
                }

                if(Number(this.cookcard_count) < Number(activeData[i].cookcard_count)){
                    this.cookcard_count = activeData[i].cookcard_count
                }
            }	
            string = this.$t('Total') + " " + this.count_tt + " ТТ. " + this.$t('ActiveOutlet') + " - " + this.store_count_active + ". " ;
            for(var ind = 0; ind < this.count_tt; ind++) {
                string = string + " #" + (Number(ind)+Number(1)) + " ТТ (" + this.obj.sklad[ind].status + ") : " + this.obj.sklad[ind].products_count + " " + this.$t('goods') +
                    ", " + this.obj.sklad[ind].cookcard_count + " " + this.$t('calculations')
            }
            this.form.comment = string

        },
        setTime(e){
            const today = new Date()
            const finalDate = new Date(today)
            const currentHours = today.getHours()
            // finalDate.setHours(currentHours + e)lunch
            finalDate.setHours(e == 14 ? e : e == 16 ? e : currentHours + e)
            var dateStr = finalDate.getFullYear() + "-" +
            ("00" + (finalDate.getMonth() + 1)).slice(-2) + "-" +
            ("00" + (finalDate.getDate())).slice(-2) + " " +
            ("00" + finalDate.getHours()).slice(-2) + ":" +
            ("00" + finalDate.getMinutes()).slice(-2) + ":" +
            ("00" + finalDate.getSeconds()).slice(-2);
            // this.date = dateStr
        },
    }
}
</script>