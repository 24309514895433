<template>
    <b-card>
        <b-row>
            <h3><b>{{ $t('statusClient') }}</b></h3>
            <b-col lg="6">
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-hashtag"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('Status') }} :</p>
                            <h6 class="text-truncate mb-0"><statusclient :clientstatus="form.users[0].user_clientstatus" :read="true" /></h6>
                        </div>
                    </div>
                </div>
                <!-- <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bxs-phone"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">Оплата:</p>
                            <h6 class="text-truncate mb-0">Активный, нет задолжностей (Waiting API)</h6>
                        </div>
                    </div>
                </div> -->
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-mail-check-line"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('tariffPlan') }} :</p>
                            <h6 class="text-truncate mb-0">{{ form.license.tariff_name }}</h6>
                        </div>
                    </div>
                </div>
                    <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-mail-check-line"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('numberTT') }} :</p>
                            <h6 class="text-truncate mb-0"><span class="text-success fw-semibold"> {{ form.license.store_count }} | <span style="color: #ffc107;">{{ form.license.store_count_active }}</span></span></h6>
                        </div>
                    </div>
                </div>
                    <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-mail-check-line"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('soldDevice') }} :</p>
                            <h6 class="text-truncate mb-0">- (оборудование которое именно мы продали, возможно доп форма) (Waiting API)</h6>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col lg="6">
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bxs-user"></i></div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden" >
                            <p class="mb-1">{{ $t('Kind_of_activity') }}:</p>
                            <div class="hstack text-wrap gap-1">
                                <span class="badge badge-soft-primary" v-for="item in form.kinds" :key="item">{{this.mutateKind(item)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bx-comment-dots"></i></div>
                        </div>
                        <div class="flex-grow-1" >
                            <p class="mb-1">{{ $t('Business_type') }} :</p>
                            <div class="hstack text-wrap gap-1">
                                <span class="badge badge-soft-primary" v-for="item in form.business" :key="item">{{this.mutateBusiness(item)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bx-comment-dots"></i></div>
                        </div>
                        <div class="flex-grow-1" >
                            <p class="mb-1">{{ $t('connectedModules') }} :</p>
                            <h6 class="mb-0">SkyMarket (цена хз) (название, цена, статус), экран в зал (Waiting API)</h6>
                        </div>
                    </div>
                </div>
                <!-- <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bx-comment-dots"></i></div>
                        </div>
                        <div class="flex-grow-1" >
                            <p class="mb-1">{{ $t('connectedAddonsServices') }}:</p>
                            <h6 class="mb-0">Настройка пРРО (цена), выезд мастера (цена) + (мб статус платежа)(Waiting API)</h6>
                            
                        </div>
                    </div>
                </div> -->
                <div class="mb-3" >
                    <div class="d-flex mt-4" >
                        <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                            <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bx-comment-dots"></i></div>
                        </div>
                        <div class="flex-grow-1" >
                            <p class="mb-1">{{ $t('referrals') }} :</p>
                            <h6 class="mb-0">Номера рефералов + кнопка открытия карточки реферала</h6>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import statusclient from '../clientstatus.vue'
import { mutateActivity, mutateBusiness } from '@/usabilityScripts/globalMutate.js'
// import { Services } from '@/API.js'

// let apiServe = new Services();

export default({
    props: ['obj'],
    components: { statusclient },
    data() {
        return{
            form: "",
            services: ""
        }
    },
    created(){
        this.form = this.obj
        // this.getServices();
    },
    methods: {
        // getServices(){
        //     apiServe.getServiceClient(this.obj.user_login).then(result => {
        //         this.services = result.filter(item => item.status == 'problem_solved');
        //     })
        // },
        mutateKind(e){
            return mutateActivity(e)
        },
        mutateBusiness(e){
            return mutateBusiness(e)
        },
    }
})
</script>
