<template>
    <div class="mb-4" >
        <label
            for="status-select"
            class="form-label text-muted text-uppercase fw-semibold mb-3"
            >{{ $t('ChooseSupplier') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioprovider" id="all_provider" v-model="provider" :value="''">
                    <label class="form-check-label" for="all_provider">{{ $t('all') }}</label>
                </div>
            </div>
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioprovider" :id="'provider'+index" v-model="provider" :value="value.value">
                        <label class="form-check-label" :for="'provider'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { storeS } from '@/store';

export default {
    data(){
        return {
            provider: "",
            opt: [
                {
                    name: this.$t('FOp'),
                    value: "fop",
                },
                {
                    name: this.$t('TOv'),
                    value: "tov",
                }
            ]
        }
    },
    methods: {
        change(){
            this.$emit('change', 'provider', this.provider)
        },
    },
    computed: {
    }
}
</script>