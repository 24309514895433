<template>
    <!-- Компонент для вибору фільтру по працівнику -->

    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
        >
            {{ $t('Employee') }}
        </label>

        <br />
        <div class="row g-2 list_filtr" >
            <!-- Поле пошуку -->
            <input 
                v-model="search"
                class="form-control mb-2"
            />

            <div class="row list_filtr" style="max-height: 300px;overflow: auto;">
                <!-- Радіокнопка "Усі працівники" -->
                <div class="col-lg-6">
                    <div class="form-check mb-3" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioWorker" id="allWorker" v-model="WorkerId" :value="''">
                        <label class="form-check-label" for="allWorker">{{ $t('all') }}</label>
                    </div>
                </div>
                <!-- Цикл для виведення радіокнопок для кожного працівника -->
                <template v-for="(value, index) in filteredItems" :key="index" >
                    <div class="col-lg-6">
                        <div class="form-check mb-3" >
                            <input class="form-check-input" type="radio" @change="change()" name="radioWorker" :id="'Worker'+index" v-model="WorkerId" :value="value.workerId">
                            <label class="form-check-label" :for="'Worker'+index">{{ value.workerName }}</label>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store'; // глобальне сховище даних

export default {
    components: { Multiselect },
    data(){
        return {
            search: '', // Поле для пошуку працівників
            workerArr: [], // Масив для зберігання обраного фільтру по працівнику
            WorkerId: "",
            workersList: [
                { label: this.$t('all'), value: '' }
            ]
        }
    },
    created() {
        // Заповнення списку працівників
        for(var item in this.workers){
            this.workersList.push({
                label: this.workers[item].workerName,
                value: this.workers[item].workerId,
            })
        }
    },
    methods: {
        change(){     
            // Метод, що викликається при зміні фільтру            
            this.$emit('change', 'workerId', this.WorkerId)
        },
    },
    computed: {
        workers(){
            // Повертає список працівників зі стору
            return storeS.workers
        },
        filteredItems: function() {
            // Фільтрує працівників за полем пошуку
            return Object.values(this.workers)
                // По полю пошуку
                .filter(item => {
                    return this.search == '' || item.workerName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>