<template>
    
    <div >

        <div class="timeline">

            <template v-for="(item, index) in this.testTimeLine" :key="item">

                <div :class="['timeline-item', index % 2 === 0 ? 'left' : 'right']">
                    <span class="icon">{{ index+1 }}</span>
                    <div class="date fw-semibold fs-16" style="top:40px" >{{ mutateType(item.type) }}</div>
                    <div class="date" style="top: 70px;">{{ item.date }}</div>
                    
                    <div class="content" >
                        <div class="d-flex" >
                            <div class="flex-shrink-0" >
                                <div class="avatar-xs"  style="margin: 0px auto;" >
                                    <div class="avatar-title rounded bg-soft-info text-info"  >
                                        {{ this.getInitials(item.user ) }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1 ms-3" >
                                <h5 class="fs-15">{{ item.user }} <small class="text-muted fs-13 fw-normal">- {{ item.time }}</small></h5>
                                <p class="text-muted mb-2">
                                    {{ item.action_desc }}
                                </p>
                                <p class="text-muted mb-2">
                                    {{ item.comment }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </template>

        </div>

    </div>

</template>


<script>
import { mutateTypeTimelineTicket } from '@/usabilityScripts/globalMutate.js'

export default {
    props: ['objCard'],
    data() {
        return {
            form: '',
            testTimeLine: [
                {
                    date: '01.05.2024',
                    time: '00:35',
                    user: 'Віктор',
                    action_desc: '',
                    comment: '',
                    type: 'create_ticket',
                },
                {
                    date: '01.05.2024',
                    time: '01:01',
                    user: 'Віктор',
                    action_desc: 'З Пос-термінал на Адмін-панель',
                    type: 'changeProblem',
                },
                {
                    date: '01.05.2024',
                    time: '01:45',
                    user: 'Віктор',
                    action_desc: 'Змінено пріоритет з Низький на Високий',
                    comment: '',
                    type: 'changePriority',
                },
                {
                    date: '11.05.2024',
                    time: '10:15',
                    user: 'Сергій',
                    action_desc: '',
                    comment: '',
                    type: 'in_job_ticket',
                },
                {
                    date: '11.05.2024',
                    time: '14:15',
                    user: 'Олег',
                    action_desc: '',
                    comment: '',
                    type: 'joining_ticket',
                },
                {
                    date: '12.05.2024',
                    time: '16:45',
                    user: 'Сергій',
                    action_desc: '',
                    comment: 'Була помилка з боку серверів, виправили з ..',
                    type: 'decided_ticket',
                },
                {
                    date: '14.05.2024',
                    time: '14:30',
                    user: 'Олег',
                    action_desc: '',
                    comment: '',
                    type: 'exit_ticket',
                },
                {
                    date: '15.05.2024',
                    time: '19:01',
                    user: 'Віктор',
                    action_desc: '',
                    comment: 'Проблема залишилась',
                    type: 'reopen_ticket',
                },
                {
                    date: '16.05.2024',
                    time: '10:05',
                    user: 'Сергій',
                    action_desc: '',
                    comment: 'Знайшли проблему, вирішили',
                    type: 'decided_ticket',
                },
                {
                    date: '16.05.2024',
                    time: '18:33',
                    user: 'Віктор',
                    action_desc: '',
                    comment: 'Дякую, проблема була вирішена',
                    type: 'close_ticket',
                },
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        getInitials(string) {
            // Отримання ініціалів з імені користувача

            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        mutateType(e){
            // мутейт типу
            return mutateTypeTimelineTicket(e)
        },
    }
    
}
</script>


<!-- 
Життя тікету - 

- Створення + 
- Перегляд -
- Взяття в роботу +
- Додавання рішення +
- Закриття тікету +
- Видалення тікету

Додатково - 
- Змінення пріоритету + 
- Зміна середовища проблеми + 
- Приєднання до тікету +
- Вихід з тікету +
- Відновлення тікету
- Перевідкриття тікету +
- Запит на допомогу 
- Перепризначення

-->