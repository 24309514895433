<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('labels') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <Multiselect 
                v-model="tagsId"
                @Close="change()"
                :close-on-select="true"
                :searchable="true"
                mode="tags"
                :create-option="false"
                :options="tagsList"
            />
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store';

export default {
    props: ['typeTags'],
    components: { Multiselect },
    data(){
        return {
            tagsId: [''],
            tagsList: [
                { label: this.$t('all'), value: '' }
            ]
        }
    },
    created() {
        this.switchTags();
    },
    methods: {
        switchTags(){
            switch (this.typeTags) {
                case 'faq':
                    for(var item in this.tagsFaq){
                        this.tagsList.push({
                            label: this.tagsFaq[item].tagName,
                            value: this.tagsFaq[item].tagId,
                        })
                    }
                    break;
                case 'kw':
                    for(var item in this.tagsLessons){
                        this.tagsList.push({
                            label: this.tagsLessons[item].tagName,
                            value: this.tagsLessons[item].tagId,
                        })
                    }
                    break;
                case 'tickets':
                    for(var item in this.mainTags){
                        this.tagsList.push({
                            label: this.mainTags[item].tagName,
                            value: this.mainTags[item].tagId,
                        })
                    }
                    break;
                case 'projects':
                    for(var item in this.mainTags){
                        this.tagsList.push({
                            label: this.mainTags[item].tagName,
                            value: this.mainTags[item].tagId,
                        })
                    }
                    break;
            
                default:
                    break;
            }
        },
        change(){
            if(this.tagsId.length < 1){
                this.tagsId = ['']
            }
            this.$emit('change', 'tags', this.tagsId)
        },
    },
    computed: {
        mainTags(){
            return storeS.tags
        },
        tagsFaq(){
            return storeS.tagsFaq
        },
        tagsLessons(){
            return storeS.tagsLessons
        }
    }
}
</script>