<template>
    <div class="col-sm-auto" style="margin-bottom:15px;">
        <div class="hstack gap-2" data-v-3aa728ee="" bis_skin_checked="1">
            <button @click="infoblockShow == true ? infoblockShow = false : infoblock()" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
        </div>
    </div>
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="filteredItems" 
        @open="open"
        :datafilter="filterAll"
        @change="change" 
        @clearFilter="clearFilter"
    />

    <modal v-if="showModal" @close="showModal = false" :title="item.name">
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <viewbox v-if="showModal" @close="showModal = false" :obj="this.item" :editform="this.editform" />
        </template>
    </modal>
    <!-- <viewbox v-if="showModal" @close="showModal = false" :obj="this.item" :editform="this.editform" /> -->

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import modal from '@/components/modal/modal-lg'
import viewbox from './view'
import infoblocks from '@/components/info/blocks'
import { Clients } from '@/API'

let apiServe = new Clients();

export default {
    props: ['number', 'tt'],
    components: { 
        tablecustom, 
        viewbox, 
        modal,
        infoblocks
    },
    data() {
        return {
            form: {},
            oldRow: "",
            search: "",
            showModal: false,
            item: "", 
            editform: false,
            infoblockShow: false,
            columns: [
                {
                    name: this.$t("ID"),
                    text: "id",
                    align: "left",
                    status: true,
                },
                {
                    name: "ТТ",
                    text: "namett",
                    align: "left",
                    status: true,
                },
                // {
                //     name: "Status",
                //     text: "statustt",
                //     align: "left",
                // },
                {
                    name: this.$t("Point_name"),
                    text: "name",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("type"),
                    text: "type",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("manufacturer"),
                    text: "manufacturer",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("bank_terminal_model"),
                    text: "model",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("OS"),
                    text: "os",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Protocol"),
                    text: "protocol",
                    align: "left",
                    status: true,
                }
          ],
            filterAll: [
                {
                    page: "devices",
                    name: "outlet",
                    category: "0",
                    items: []
                },
                {
                    page: "devices",
                    name: "type",
                    category: "0",
                    items: []
                }
            ],
            selectnamett: "0",
            selecttype: "0",
            information: [
                {
                    title: this.$t('all'),
                    value: "0",
                    icon: "ri-information-line",
                    color: "info"
                },
                {
                    title: this.$t('printers'),
                    value: "0",
                    icon: "ri-printer-cloud-line",
                    color: "info"
                },
                {
                    title: this.$t('terminals'),
                    value: "0",
                    icon: "ri-visa-fill",
                    color: "success"
                },
                {
                    title: this.$t('scales'),
                    value: "0",
                    icon: "ri-scales-line",
                    color: "danger"
                },
            ]
        }
    },
    created(){
        // this.form = this.obj;
        this.outlets = this.tt;
        this.getDevices()
    },
    methods: {
        getdata(value, oldValue){
            if(value != undefined){
                this.form = value;
            }
        },
        getDevices(){
            apiServe.getClient(this.number).then(result => {
                if(result.status == 'done'){
                    console.log('re', result);
                    this.form = result.data.devices;
                    for(var item in this.form){
                        for(var value in this.outlets){
                            if(this.form[item].tradepointId === this.outlets[value].tradepointId){
                                // this.form[item].statustt = this.$t('active');
                                this.form[item].namett = this.outlets[value].tradepointName;
                                break;
                            } else {
                                this.form[item].namett = 'remove';
                            }
                        }
                    }
                    let newobj = this.form;
                    let newArrStatus = Array.from(new Set(newobj.map(item => JSON.stringify(item.namett)))).map(item => JSON.parse(item));
                    this.filterAll[0]['items'] = newArrStatus;
                    let newArrType = Array.from(new Set(newobj.map(item => JSON.stringify(item.type)))).map(item => JSON.parse(item));
                    this.filterAll[1]['items'] = newArrType;
                }
            })
        },
        searchB(e){
            this.search = e
        },
        open(e){
            this.item = e;
            this.showModal = true;
        },
        infoblock(){
            this.information[0].value = this.filteredItems.length
            this.information[1].value = this.filteredItems.filter(item => item.type == 'printer').length
            this.information[2].value = this.filteredItems.filter(item => item.type == 'banking').length
            this.information[3].value = this.filteredItems.filter(item => item.type == 'libra').length
            this.infoblockShow = true
        },
        change(name, e){
            if(name == 'outlet'){
                this.selectnamett = e;
            } else if (name == 'type'){
                this.selecttype = e;
            }
        },
        clearFilter(){
            this.selectnamett = "0";
            this.selecttype = "0";
        },
        mutateProtocol(e){
            return mutateProtocolDevices(e)
        },
    },
    mounted(){
    },
    watch:{
        obj(value, oldValue){
            this.getdata(value, oldValue)
        }
    },
    computed: {
        filteredItems: function() {
            if(this.form != null || this.form != undefined) {
                console.log(this.form)
                return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.id.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.name.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.namett.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ;
                })

                // По точке
                .filter(item => {
                    return this.selectnamett == 0 || item.namett == this.selectnamett;
                })

                // По типу
                .filter(item => {
                    return this.selecttype == 0 || item.type == this.selecttype;
                })
            }
        },
    }
}
</script>