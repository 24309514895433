<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="workerStatus-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('Status') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioworkerStatus" id="all_workerStatus" v-model="workerStatus" :value="''">
                    <label class="form-check-label" for="all_workerStatus">{{ $t('all') }}</label>
                </div>
            </div>
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioworkerStatus" :id="'workerStatus'+index" v-model="workerStatus" :value="value.value">
                        <label class="form-check-label" :for="'workerStatus'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { storeS } from '@/store';

export default {
    props: ['worker'],
    data(){
        return {
            workerStatus: "",
            opt: [
                {
                    name: this.$t('active'),
                    value: '1'
                },
                {
                    name: this.$t('blocked_user'),
                    value: "'0'"
                }
            ]
        }
    },
    created(){
        this.workerStatus = this.worker
    },
    methods: {
        change(){
            this.$emit('change', 'workerStatus', this.workerStatus)
        },
    },
    computed: {
    }
}
</script>