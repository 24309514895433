<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('Categories') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <Multiselect 
                v-model="productsCategoryId"
                @select="change()"
                :close-on-select="true"
                :searchable="true"
                :create-option="false"
                :options="productsList"
            />
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store';

export default {
    components: { Multiselect },
    data(){
        return {
            productsCategoryId: "",
            productsList: [
                { label: this.$t('all'), value: '' }
            ]
        }
    },
    created() {
        for(var item in this.productCategories){
            this.productsList.push({
                label: this.productCategories[item].categoryName,
                value: this.productCategories[item].categoryId,
            })
        }
    },
    methods: {
        change(){
            this.$emit('change', 'productsCategoryId', this.productsCategoryId)
        },
    },
    computed: {
        productCategories(){
            return storeS.productCategories
        }
    }
}
</script>