<template>
    <!-- 
        Компонент відображення категорії 
    -->
    
    <b-row>
        <b-col lg="6">
            <!-- Карта для відображення графіків та даних -->
            <div class="card card-height-100">
                <div class="card-header align-items-center d-flex">
                    <!-- Заголовок карти -->
                    <h4 class="card-title mb-0 flex-grow-1">{{this.title}}</h4>
                    <!-- Випадаючий список для вибору категорії -->
                    <div class="col-md-3">
                        <select
                            class="form-select"
                            v-model="selectcateg"
                            @change="change(selectcateg)"
                        >
                            <!-- Опції випадаючого списку, виведені зі змінної category -->
                            <option v-for="item in category" :key="item" :value="item.change" >{{ $t(item.category) }}</option>
                        </select>
                    </div>
                </div>

                <!-- Тіло карти з графіком та таблицею -->
                <div class="card-body">
                    <!-- Графік, реалізований за допомогою компонента ApexCharts -->
                    <apexchart
                        class="apex-charts"
                        dir="ltr"
                        height="219"
                        :series="seriesnew"
                        :options="chartOptions"
                    ></apexchart>

                <!-- Таблиця з деталями та відсотковими значеннями -->
                    <div class="table-responsive mt-3">
                        <table
                            class="table table-borderless table-sm table-centered align-middle table-nowrap mb-0"
                        >
                            <tbody class="border-0">
                                <!-- Рядки таблиці для кожної категорії -->
                                <tr v-for="(item, index) in this.form" :key="index">
                                    <!-- Ім'я категорії та кольоровий індикатор -->
                                    <td>
                                        <h4 class="text-truncate fs-14 fs-medium mb-0">
                                            <i
                                                :style="`color: ${chartOptions.colors[index]}!important`"
                                                class="ri-stop-fill align-middle fs-18 text-primary me-2"
                                            ></i>
                                            {{item.name}}
                                        </h4>
                                    </td>

                                    <!-- Кількість елементів у категорії -->
                                    <td>
                                        <p class="text-muted mb-0">
                                        <UsersIcon class="me-2 icon-sm"></UsersIcon>{{item.count}}
                                        </p>
                                    </td>
                                    
                                    <!-- Відсоткове значення відносно загальної суми -->
                                    <td class="text-end">
                                        <p 
                                            :class="`text-${Number(this.getproc(this.sum/this.form.length)) < Number(this.getproc(item.count)) ? 'success' : 'danger' } fw-medium fs-12 mb-0`" 
                                        >
                                            {{this.getproc(item.count)}}%
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>

export default{
    props: ['title', 'obj'],
    data(){
        return{
            selectcateg:"0",  // Вибрана категорія за замовчуванням
            series: [], // Дані для графіку
            seriesnew: [],  // Дані для графіку з врахуванням вибраної категорії
            chartOptions: { // Налаштування графіку
                labels: [],
                chart: {
                    type: "donut",
                    height: 219,
                },
                plotOptions: {
                pie: {
                        size: 100,
                        donut: {
                        size: "76%",
                    },
                },
                },
                dataLabels: {
                enabled: false,
                },
                legend: {
                show: false,
                position: "bottom",
                horizontalAlign: "center",
                offsetX: 0,
                offsetY: 0,
                markers: {
                    width: 20,
                    height: 6,
                    radius: 2,
                },
                itemMargin: {
                    horizontal: 12,
                    vertical: 0,
                },
                },
                stroke: {
                width: 0,
                },
                yaxis: {
                labels: {
                    formatter: function (value) {
                    return value ;
                    },
                },
                tickAmount: 4,
                min: 0,
                },
                colors: [
                    "#4b38b3", 
                    "#ffbe0b", 
                    "#299CDB",
                    "#f06548",
                    "#ff00ff",
                    "#ff0000",
                    "#ffff00",
                    "#008000",
                    "#008080",
                    "#fa8072",
                    "#ffe4b5",
                    "#008080",
                    "#1e90ff",
                    "#483d8b",
                    "#ff69b4"
                ],
            },
            sum: "", // Загальна сума для відсоткових розрахунків
            category: [] // Масив категорій
        }
    },
    created(){
        this.form = this.obj
        this.getdata();
    },
    methods: {
        getdata(){
            // Отримання даних

            this.newSeries(this.form)
            for(var values in this.form[0].items){
                this.category.push(this.form[0].items[values])
            }
            
        },
        change(e){
            // Зміна вибраної категорії

            if(e == '0'){
                this.getnewData(e)
                this.seriesnew = []
                this.newSeries()
            } else if(e == '1'){
                this.getnewData(e)
                this.seriesnew = []
                this.newSeries()
            } else if(e == '2'){
                this.getnewData(e)
                this.seriesnew = []
                this.newSeries()
            } else if(e == '3'){
                this.getnewData(e)
                this.seriesnew = []
                this.newSeries()
            } else if(e == '4'){
                this.getnewData(e)
                this.seriesnew = []
                this.newSeries()
            }
        },
        getnewData(x){
            // Оновлення даних відповідно до вибраної категорії

            for(var item in this.form) {
                // this.form[item].count = this.form[item].
                for(var value in this.form[item].items){
                    if(this.form[item].items[value].change == x){
                        this.form[item].count = this.form[item].items[value].value
                    }
                }
            }
        },
        newSeries(){
            // Оновлення даних графіку

            for(var item in this.form) {
                this.seriesnew.push(Number(this.form[item].count))
                this.chartOptions.labels.push(this.form[item].name)
            }
            this.sum = this.arraySum(this.seriesnew)
        },
        getproc(e){
            // Розрахунок відсоткового значення

            var result = "";
            result = (e/this.sum*100).toFixed(2)
            return result
        },
        arraySum(array, e){
            // Сума значень масиву

            var check = e
            var sum = 0;
            for(var i = 0; i < array.length; i++){
                sum += Number(array[i]);
            }
            return sum
        },
    }
}

</script>