<template>
    <!-- 
        Модальне вікно перегляду заявки на вихідний/відпустку
    -->

    <!-- 
        Компонент модального вікна
        - componentKey - унікальний ключ для перерендерення модалки при зміні даних 
    -->
    <modal 
        :key="componentKey" 
        @close="this.$emit('close')" 
    >

        <!-- Слот шапки модального вікна  -->
        <template v-slot:title>{{ mutateType(form.type) }}</template>

        <!-- Слот тіло модального вікна з формою для введення даних -->
        <template v-slot:body>
            <!-- <div class="text-end" v-if="perms[422]">
                <a class="btn btn-sm btn-soft-primary" id="edit-event-btn" data-id="edit-event" @click="this.$emit('edit', this.form)" role="button">{{ $t('Edit') }}</a>
            </div> -->
            
            <div class="event-details" >

                <!-- Тип події -->
                <div class="d-flex mb-2" >
                    <div class="flex-shrink-0 me-3" >
                        <i class="ri-flag-2-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1" >
                        <p class="d-block fw-semibold mb-0" id="event-type-tag">{{ mutateType(form.type) }}</p>
                    </div>
                </div>

                <!-- Причина події -->
                <div class="d-flex mb-2" >
                    <div class="flex-shrink-0 me-3" >
                        <i class="ri-flag-2-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1" >
                        <p class="d-block fw-semibold mb-0" id="event-type-tag">{{ mutateReason(form.reason) }}</p>
                    </div>
                </div>

                <!-- Дати події -->
                <div class="d-flex mb-2" >
                    <div class="flex-grow-1 d-flex align-items-center" >
                        <div class="flex-shrink-0 me-3" >
                            <i class="ri-calendar-event-line text-muted fs-16"></i>
                        </div>
                        <div class="flex-grow-1" >
                            <!-- <h6 class="d-block fw-semibold mb-0" id="event-start-date-tag">{{ formatDate(form.date).rDate }} {{ form.toDate ? ' - ' + formatDate(form.toDate).rDate : '' }}</h6> -->
                            <h6 class="d-block fw-semibold mb-0" id="event-start-date-tag">{{ form.date + " - " + form.toDate }}</h6>
                        </div>
                    </div>
                </div>

                <!-- Інформація про працівника -->
                <div class="d-flex align-items-center mb-2" >
                    <div class="flex-shrink-0 me-3" >
                        <i class="mdi mdi-account-circle text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1" >
                        <h6 class="d-block fw-semibold mb-0"><span id="event-user-tag">{{ form.createWorkerName }}</span></h6>
                    </div>
                </div>

                <!-- Коментар -->
                <div class="d-flex mb-3" v-if="form.comment != ''">
                    <div class="flex-shrink-0 me-3" >
                        <i class="ri-discuss-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1" >
                        <p class="d-block text-muted mb-0" id="event-description-tag">{{ form.comment }}</p>
                    </div>
                </div>

                <hr />

                <!-- Статус події -->
                <div class="d-flex align-items-center mb-2" >
                    <div class="flex-shrink-0 me-3" >
                        <i class="ri-git-repository-private-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1" >
                        <h6 class="d-block fw-semibold mb-0 fs-16"><span id="event-status-tag" :class="`${mutateStatus(form.status).color}`">{{ mutateStatus(form.status).name }}</span></h6>
                    </div>
                </div>

                <!-- Коментар до скасування чи підтвердження -->
                <div class="d-flex align-items-center mb-2" v-if="form.confirmComment || form.cancelComment">
                    <div class="flex-shrink-0 me-3" >
                        <i class="ri-discuss-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1" >
                        <h6 class="d-block fw-semibold mb-0"><span id="event-status-tag">{{ form.confirmComment != null ? form.confirmComment : form.cancelComment }}</span></h6>
                    </div>
                </div>

                <!-- Блок з подіями на вказаний період заявки, щоб не переходити в календар -->
                <div>
                    <hr />
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    {{ this.$t('EventsInSpecPeriod') }}
                                </button>
                            </h2>

                            <!-- Список подій -->
                            <div 
                                id="flush-collapseOne" 
                                class="accordion-collapse collapse" 
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                            >
                                <ul class="list-group">
                                    <template v-for="item in form.events" :key="item">
                                        <li 
                                            class="list-group-item d-flex justify-content-between align-items-center" 
                                            style="cursor: pointer;" 
                                            @click="openEvent(item)"
                                        >
                                            <!-- Назва події -->
                                            {{ item.name }} 
                                            
                                            <!-- Тип події -->
                                            <span :class="`fs-12 badge ${mutateTypeCalendar(item.type).bgcolor}`">
                                                {{ mutateTypeCalendar(item.type).name }}
                                            </span>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </template>

        <!-- Нижня частина модального вікна з кнопками -->
        <template v-slot:footer-bottom>

            <!-- Кнопка видалення -->
            <button 
                v-if="perms[522] || perms[2000]"
                type="button" 
                class="btn btn-danger" 
                @click="dialogShow = true"
            >
                {{ $t('Delete') }}
            </button>
            
            <!-- Кнопка скасування -->
            <button 
                v-if="form.status == 'new' && (perms[521] || perms[2000])" 
                type="button" 
                class="btn btn-warning" 
                @click="showModalCancel = true"
            >
                {{ $t('cancel') }}
            </button>
            
            <!-- Кнопка підтвердження -->
            <button 
                v-if="form.status == 'new' && (perms[521] || perms[2000])" 
                type="button" class="btn btn-success" 
                @click="showModalConfirm = true"
            >
                {{ $t('Confirm') }}
            </button>

        </template>
    </modal>

    <!-- 
        Модальне вікно з підтвердженням видалення
        - для відкриття застосовується перевірка значення dialogShow
        - @close - подія, яка спрацьовує при закритті картки
        - @remove - подія, яка спрацьовує коли підтверджуєш видалення
    -->
    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove()" 
    />

    <!-- 
        Модальне вікно скасування заявки на вихідний/відпустку
        - для відкриття застосовується перевірка значення showModalCancel
        - @close - подія, яка спрацьовує при закритті картки
        - :id - параметр-ідентифікатор заявки
        - @toCancel - подія, яка спрацьовує коли скасовуєш заявку
    -->
    <modalCancel 
        v-if="showModalCancel == true" 
        @close="showModalCancel = false"
        :id="this.form.holidayId"
        @toCancel="toCancel"
    />

    <!-- 
        Модальне вікно підтвердження заявки на вихідний/відпустку
        - для відкриття застосовується перевірка значення showModalConfirm
        - @close - подія, яка спрацьовує при закритті картки
        - :id - параметр-ідентифікатор заявки
        - @toConfirm - подія, яка спрацьовує коли підтверджуєш заявку
    -->
    <modalConfirm 
        v-if="showModalConfirm == true" 
        @close="showModalConfirm = false"
        :id="this.form.holidayId"
        @toConfirm="toConfirm"
    />

    <!-- 
        Діалогове вікно для додавання події в календар
        - для відкриття застосовується перевірка значення showAddEvent
        - @close - подія, яка спрацьовує при закритті картки
        - :crItem - параметр-об'єкт з інформацією про вихідний/відпустку
        - @updateCard - подія, яка оновлює картку заявки
    -->
    <addEvent
        v-if="showAddEvent"
        @close="showAddEvent = false"
        :crItem="crItem"
        @updateCard="updateCard"
    />

    <!-- 
        Модальне вікно з даними події в календарі
        - для відкриття застосовується перевірка значення showAddEvent
        - @close - подія, яка спрацьовує при закритті картки
        - :obj - параметри картки події
    -->
    <viewEvent
        v-if="objEvent.showModal"
        @close="objEvent.showModal = false"
        :obj="objEvent.data"
    />


</template>

<script>
import modal from '@/components/modal/dialog' // компонент модального вікна
import modalremove from '@/components/modal/modalremove' // компонент діалогового вікна з підтвердженням видалення заявки
import modalCancel from './actions/cancel' // вікно скасування заявки
import modalConfirm from './actions/confirm' // вікно підтвердження заявки
import addEvent from '@/components/calendar/addEvent' // діалогове вікно для додавання події в календар
import viewEvent from '@/components/calendar/viewEvent' // вікно події в календарі
import { Holidays, DataCalendar } from '@/API' // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { mutateTypeHoliday, mutateStatusHoliday , mutateReasonHoliday, mutateTypeCalendar } from '@/usabilityScripts/globalMutate.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiHolidays = new Holidays();
let apiDataCalendar = new DataCalendar();

export default {
    props: ['obj'],
    components: {
        modal,
        modalremove,
        modalCancel,
        modalConfirm,
        addEvent,
        viewEvent
    },
    data(){
        return{
            form: '',
            dialogShow: false, // Флаг відображення вікна видалення
            showModalCancel: false, // Флаг відображення вікна скасування
            showModalConfirm : false, // Флаг відображення вікна підтвердження
            showAddEvent: false, // Флаг відображення вікна створення івенту
            crItem: {
                id: '',
                type: 'graphs',
                name: '',
                description: '',
                date: '',
                toDate: '',
                time: ''
            },
            listEvents: [],
            objEvent: {
                data: {},
                showModal: false
            },
            componentKey: '' // ключ, використовується для ререндеру
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        remove(){
            /**
                Функція для видалення заявки на вихідний/відпустку

                Викликає API для видалення заявки.
                Результат присвоюється властивості objcard, та встановлюється прапорці showModal в true для відображення модального вікна.
            */

            apiHolidays.deleteRequest(this.form.holidayId).then(result => {
                if(result.status == 'done'){
                    this.dialogShow = false;
                    this.$toast.success(this.$t('Removed'));
                    this.$emit('getdata');
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка видалення картки заявки на вихідний'; 
                    var statusRequest = result.status;
                    var api = 'deleteRequest';
                    var fileRequest = 'src/views/statistics/holidays/view.vue';
                    var params = this.form.holidayId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        toCancel(id, e){;
            /**
                Функція для скасування заявки на вихідний/відпустку
                * @param {Object|String} id - Ідентифікатор заявки.
                * @param {Object} e - об'єкт-параметр щодо заявки.

                Викликає API для скасування заявки.
            */

            apiHolidays.cancelRequest(id, e).then(result => {
                if(result.status == 'done'){
                    this.$emit('close')
                    this.$toast.success(this.$t('canceled'));
                    this.$emit('getdata');
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "id": id,
                        "params": e
                    }

                    var errorText = 'Помилка скасування картки заявки на вихідний'; 
                    var statusRequest = result.status;
                    var api = 'cancelRequest';
                    var fileRequest = 'src/views/statistics/holidays/view.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        toConfirm(id, e){
            /**
                Функція для підтвердження заявки на вихідний/відпустку
                * @param {Object|String} id - Ідентифікатор заявки.
                * @param {Object} e - об'єкт-параметр щодо заявки.

                Викликає API для підтвердження заявки.
                - якщо у юзера відкрита можливість створення івену одразу, буде відображатись вікно з додаванням інформації в календар
            */

            apiHolidays.confirmRequest(id, e).then(result => {
                if(result.status == 'done'){
                    if(this.settingCheck['026']==true) {
                        this.crItem.id = id;
                        this.crItem.date = this.form.date
                        this.crItem.toDate = this.form.toDate
                        this.crItem.name = this.mutateType(this.form.type) + " " + this.form.createWorkerName;
                        this.crItem.description = e.comment;
                        this.showAddEvent = true;
                        this.$emit('getdata');
                    } else {   
                        this.$toast.success(this.$t('Confirmed'))
                        this.$emit('close')
                    }
                } else {
                    this.$toast.error(this.$t('error') + ' #202');

                    var tempObj = {
                        "id": id,
                        "params": e
                    }

                    var errorText = 'Помилка видалення картки заявки на вихідний'; 
                    var statusRequest = result.status;
                    var api = 'deleteRequest';
                    var fileRequest = 'src/views/statistics/holidays/view.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },  
        updateCard(){
            /**
                Функція для оновлення картки заявки на вихідний/відпустку, яка відкрита

                Викликає API отримання заявки за її ID.
            */

            apiHolidays.getRequest(this.form.holidayId).then(result => {
                if(result.status == 'done'){
                    this.form = result.data;
                    this.componentKey += 1
                }
            })
        },
        openEvent(e){
            /**
                Функція для відкриття модального вікна з інформацією про подію в календарі за його ідентифікатором.
                * @param {Object} e - Ідентифікатор події.

                Викликає API для отримання інформації про подію.
                Результат присвоюється властивості this.objEvent.data, та встановлюється прапорці this.objEvent.showModal в true для відображення модального вікна.
            */

            apiDataCalendar.getEvent(e.id).then(result => {
                if(result.status == 'done'){
                    this.objEvent.data.id = result.data.id;
                    this.objEvent.data.title = result.data.name;
                    this.objEvent.data.startStr = result.data.date+"T"+result.data.time;
                    this.objEvent.data.endStr = result.data.toDate;
                    this.objEvent.data.extendedProps = {
                        description: result.data.description,
                        nameWorker: result.data.createWorkerName,
                        type: result.data.type,
                        status: result.data.status,
                        idDoc: result.data.document.id,
                        idType: result.data.document.type
                    }
                    this.objEvent.showModal = true
                } else { 
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка відкриття картки події'; 
                    var statusRequest = result.status;
                    var api = 'getEvent';
                    var fileRequest = 'src/views/statistics/holidays/view.vue';
                    var params = e.id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        formatDate(date) {
            /**
                Функція для форматування дати та часу
                * @param {String} date - Рядок, який представляє собою дату та час.

                Приймає рядок, перетворює його в об'єкт типу Date та повертає об'єкт із відформатованою датою та часом.
            */

            // Створення об'єкту типу Date на основі переданого рядка дати та часу.
            var date3 = new Date(date);  

            // Форматування дати в рядок в форматі "рік-місяць-день".
            var dateStr = date3.getFullYear() + "-" + ("00" + (date3.getMonth() + 1)).slice(-2) + "-" + ("00" + date3.getDate()).slice(-2);

            // Форматування часу в рядок в форматі "години:хвилини".
            var timeStr = ("00" + date3.getHours()).slice(-2) +":"+("00" + date3.getMinutes()).slice(-2);

            // Створення масиву назв місяців для подальшого використання.
            var monthNames = [
                this.$t('January'),
                this.$t('February'),
                this.$t('March'),
                this.$t('April'),
                this.$t('May'),
                this.$t('June'),
                this.$t('July'),
                this.$t('August'),
                this.$t('September'),
                this.$t('October'),
                this.$t('November'),
                this.$t('December'),
            ];

            // Дістання значень року, місяця та дня з об'єкту типу Date.
            var d = new Date(dateStr),
                month = "" + monthNames[d.getMonth()],
                day = "" + d.getDate(),
                year = d.getFullYear();

            // Доповнення числових значень місяця та дня до двоцифрових значень, якщо потрібно.
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            // Створення відформатованої дати в форматі "день місяця рік" та часу у вигляді рядка.
            var rDate = [day + " " + month, year].join("  ")

            // Повертає об'єкт із відформатованою датою та часом.
            return { rDate, timeStr};
        },
        mutateType(e) {
            /*
                Функція для застосування перекладу до типу вихідного
            */

            return mutateTypeHoliday(e);
        },
        mutateReason(e) {
            /*
                Функція для застосування перекладу до причини вихідного/відпустки
            */

            return mutateReasonHoliday(e);
        },
        mutateStatus(e) {
            /*
                Функція для застосування перекладу до статусу заявки
            */

            return mutateStatusHoliday(e);
        },
        mutateTypeCalendar(e) {
            /*
                Функція для застосування перекладу до типу події
            */
            return mutateTypeCalendar(e)
        },
    },
    computed: {
        perms(){
            /*
                Властивість, яка повертає права доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        settingCheck(){
            /*
                Властивість, яка повертає налаштування користувача з глобального стору.
            */
            return storeS.checks
        },
    }
}
</script>