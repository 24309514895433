<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('Employee') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <input 
                v-model="search"
                class="form-control mb-2"
            />
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioWorker" id="allWorker" v-model="WorkerId" :value="''">
                    <label class="form-check-label" for="allWorker">{{ $t('all') }}</label>
                </div>
            </div>
            <template v-for="(value, index) in filteredItems" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioWorker" :id="'Worker'+index" v-model="WorkerId" :value="value.accountId">
                        <label class="form-check-label" :for="'Worker'+index">{{ value.workerName }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store';

export default {
    components: { Multiselect },
    data(){
        return {
            search: '',
            workerArr: [],
            WorkerId: [],
            workersList: [
                { label: this.$t('all'), value: '' }
            ]
        }
    },
    created() {
        for(var item in this.workers){
            this.workersList.push({
                label: this.workers[item].workerName,
                value: this.workers[item].accountId,
            })
        }
    },
    methods: {
        change(){         
            var items = [];
            items.push(this.WorkerId)
            this.$emit('change', 'userFilter', items)
            // this.$emit('change', 'userFilter', this.WorkerId)
        },
    },
    computed: {
        workers(){
            return storeS.workers
        },
        filteredItems: function() {
            return Object.values(this.workers)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.workerName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>