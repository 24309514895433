<template>
    <modal :title="$t('client') + ' - ' + Number(form.userPhone) + (form.userName != null ? ' | ' + form.userName : '')" @close="close" :stylebody="'var(--vz-body-bg)'">
        <template v-slot:head-bottom>
            <headerModal 
                :obj="this.form"
                @close="close" 
                @opencalculator="opencalculator"
                @openstatistics="openstatistics" 
                @openshop="openshop"
                @openknowledge="openknowledge" 
                @openfaq="openfaq" 
                @opensmsbox="opensmsbox" 
                @openapplicbox="openapplicbox" 
                @opentaskBox="opentaskBox" 
                @openticketBox="openticketBox" 
                @openservicebox="openservicebox" 
                @openinvoicesbox="openinvoicesbox" 
                @openblockComment="openblockComment" 
                @createdconnectbox="createdconnectbox" 
                @addToMonitoring="openMonitoringCreate"
            />
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="3">
                    <mainCol 
                        :obj="form"
                        @opensmsbox="opensmsbox" 
                        @openmailbox="openmailbox"
                        @call = "call"
                    />
                </b-col>
                <b-col lg="9">
                    <activityCol 
                        :obj="form"
                        :specialId="this.requestId"
                    />
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <button 
                type="button" 
                @click="dialogShow = true" 
                class="btn btn-danger r waves-effect waves-light">
                {{ $t('Delete') }}
            </button> <!-- видалення акаунту -->
        </template>
    </modal>


    <!-- база знаний -->
    <template v-if="knowledgeboxShow">
        <modallg @close="knowledgeboxShow = false">
            <template v-slot:head-bottom>
                <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="knowledgeboxShow = false"><i class="ri-close-fill align-bottom"></i></button>
            </template>
            <template v-slot:body>
                <knowledgeBox :phone="form.user_login"></knowledgeBox>
            </template>
        </modallg>
    </template>
    <!-- база знаний -->

    <!-- faq -->
    <template v-if="faqboxShow">
        <modallg @close="faqboxShow = false">
            <template v-slot:head-bottom>
                <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="faqboxShow = false"><i class="ri-close-fill align-bottom"></i></button>
            </template>
            <template v-slot:body>
                <faqBox></faqBox>
            </template>
        </modallg>
    </template>
    <!-- faq -->

    <!-- смс бокс -->
    <createsmsBox v-if="modalSmsbox" @close="modalSmsbox = false" @sendSMS="sendSMS" :phone="Number(form.userPhone)"></createsmsBox>
    <!-- смс бокс -->

    <!-- создание заявки -->
    <createapplicBox v-if="modalShow" @close="modalShow = false" :phone="Number(form.user_phone)"></createapplicBox>
    <!-- создание заявки -->

    <!-- создание задачи -->
    <createTaskBox v-if="modalTaskBox" @close="modalTaskBox = false" :phone="Number(form.user_phone)"></createTaskBox>
    <!-- создание задачи -->

    <!-- создание тикета -->
    <createTicketBox v-if="modalTicketBox" @close="modalTicketBox = false" :phone="Number(form.user_phone)"></createTicketBox>
    <!-- создание тикета -->

    <!-- создание счета -->
    <invoices v-if="modalInvoicesBox" @close="modalInvoicesBox = false" :phone="form.user_login" :license="form.license"></invoices>
    <!-- создание счета -->

    <!-- калькулятор -->
    <calculator v-if="modalcalculator" @close="modalcalculator = false" :country="form.user_country" :demo_license="demo_license" ></calculator>
    <!-- калькулятор -->

    <!-- mail бокс -->
    <createmailBox v-if="modalMailbox" :users="this.form" @close="modalMailbox = false"></createmailBox>
    <!-- mail бокс -->

    <acceptSMS v-if="showAcceptSMS == true" @close ="showAcceptSMS = false" :obj="this.obj"></acceptSMS>

    <!-- интернет-магазин -->
    <template v-if="modalshop">
      <modallg @close="modalshop = false">
        <template v-slot:head-bottom>
          <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="knowledgeboxShow = false"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <shop></shop>
        </template>
      </modallg>
    </template>
    <!-- интернет-магазин -->

    <!-- создание услуги -->
    <service v-if="serviceShow" @close="serviceShow = false" :phone="form.user_login"></service>
    <!-- создание услуги -->

    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove" 
    />

</template>

<script>
import acceptSMS from '@/views/application/acceptSMS.vue';
import createsmsBox from '@/views/application/createsms.vue'
import createmailBox from '@/views/application/createmail.vue'
import modal from '@/components/modal'
import modallg from '@/components/modal/modal-lg'
import modalremove from '@/components/modal/modalremove'
import headerModal from './header'
import knowledgeBox from '@/components/knowledge-base/index'
import faqBox from '@/components/faq/index'
import createapplicBox from '@/views/application/createapplic.vue'
import createTaskBox from '@/views/tasks/createtask.vue'
import createTicketBox from '@/views/newtickets/createticket.vue'
import service from "@/views/services/createservice.vue"
import invoices from "@/views/invoices/createinvoices.vue"
import calculator from "@/components/calc_tariff.vue"
import shop from "@/components/shop/index.vue"
import mainCol from './blocks/main/index'
import activityCol from './blocks/activity/index'
import { Unregistered } from '@/API'

let apiServe = new Unregistered();

export default{
    props: ['objCard', 'requestId'],
    components: {
        acceptSMS,
        createmailBox,
        modal,
        headerModal,
        modallg,
        knowledgeBox,
        faqBox,
        createsmsBox,
        createapplicBox,
        createTaskBox,
        createTicketBox,
        service,
        calculator,
        invoices,
        shop,
        mainCol,
        activityCol,
        modalremove
    },
    data(){
        return{
            modalMailbox: false,
            showAcceptSMS: false,
            obj: '',
            dialogShow: false,
            form:'',
            componentKey:'',
            colorTabs: 'success',
            knowledgeboxShow: false,
            faqboxShow: false,
            modalSmsbox: false,
            modalShow: false,
            modalTaskBox: false,
            modalTicketBox: false,
            modalInvoicesBox: false,
            modalcalculator: false,
            modalshop: false,
            serviceShow: false,
            connectsShow: false,
        }
    },
    created(){
        this.getdata();
        console.log(this.requestId)
    },
    methods: {
        openMonitoringCreate(num) {
            let obj = {
                "number": num,
                "compId": ""
            }

            this.eventBus.emit('createNewMonitoring', {
                data: obj
            })
        },
        sendSMS(e){
            this.obj = e 
            this.showAcceptSMS = true
        },
        call(e) {
            localStorage.setItem('actualCall', e)
            this.eventBus.emit('actualCall', {
                status: true
            })
        },
        openmailbox: function() {
           this.modalMailbox = true
        },
        opensmsbox: function(){
            this.modalSmsbox = true
        },
        getdata(){
            this.form = this.objCard;
            console.log(this.form.userPhone)
        },
        close(){
            this.$emit('close')
        },
        remove(){
            if (!this.form.id) {
                //перевіряємо чи вказаний акк
                this.$toast.error(this.$t('AccountnotFound'));
            } else {
                apiServe.deleteAccount(this.form.id).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('Removed'));
                        this.close();
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);
                    }
                })
            }
        },
        openshop: function(){
            this.modalshop = true
        },
        openknowledge: function(){
            this.knowledgeboxShow = true
        },
        openfaq: function(){
            this.faqboxShow = true
        },
        opensmsbox: function(){
            this.modalSmsbox = true
        },
        openapplicbox: function(){
            this.modalShow = true
        },
        opentaskBox: function(){
            this.modalTaskBox = true
        },
        openticketBox: function(){
            this.modalTicketBox = true
        },
        openservicebox: function(){
            this.serviceShow = true
        },
        createdconnectbox: function(){
            this.connectsShow = true
        },
        opencalculator: function(){
            // this.demo_license.demo_store_count_active = this.form.license.store_count_active;
            // this.demo_license.demo_count_tt = this.form.license.sklad.length;
            // this.demo_license.demo_products_count = this.form.license.sklad[0].products_count;
            // this.demo_license.demo_cookcard_count = this.form.license.sklad[0].cookcard_count;
            // for(var i=0; i < this.form.license.sklad.length; i++){
            //     if(Number(this.demo_license.demo_products_count) < Number(this.form.license.sklad[i].products_count)){
            //         this.demo_license.demo_products_count = this.form.license.sklad[i].products_count
            //     }

            //     if(Number(this.demo_license.demo_cookcard_count) < Number(this.form.license.sklad[i].cookcard_count)){
            //         this.demo_license.demo_cookcard_count = this.form.license.sklad[i].cookcard_count
            //     }
            // }
            this.modalcalculator = true
        },
        openblockComment: function(){
            this.blockComment = !this.blockComment;
        },
        openinvoicesbox: function(){
            this.modalInvoicesBox = true;
        },
    }
}
</script>