<template >
    <!-- Компонент принтера етикеток -->
    <div class="col-md-6">
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('printer_model') }}</h4>
                <!-- Вивід моделі принтера -->
                <input 
                    type="text" 
                    class="form-control mb-3" 
                    :disabled="this.editform == false" 
                    v-model="form.model.value" 
                    @input="input" 
                    placeholder="HPRT, Xprinter, Rongta"
                >
                <h4 class="fs-15">{{ $t('print_mode') }}</h4>
                <!-- Вивід режиму налаштування принтеру  -->
                <select
                    class="form-select mb-3"
                    data-plugin="choices"
                    v-model="form.modes_print.value"
                    :disabled="this.editform == false"
                >
                    <option 
                        v-for="item in modes_print" 
                        :key="item.id"
                    >
                        {{ item.title }}
                    </option>
                </select>
                <h4 class="fs-15">{{ $t('operatingSystem') }}</h4>
                <!-- Вивід операціної системи -->
                <select
                    class="form-select mb-3"
                    data-plugin="choices"
                    v-model="form.oc_types.value"
                    :disabled="this.editform == false"
                >
                    <option 
                        v-for="item in oc_types" 
                        :key="item.id"
                    >
                        {{ item.title }}
                    </option>
                </select>
                <h4 class="fs-15">{{ $t('verSkyService') }}</h4>
                <!-- Вивід версії програми -->
                <input 
                    type="number" 
                    class="form-control mb-3" 
                    :disabled="this.editform == false" 
                    v-model="form.version_apps.value" 
                    placeholder="9.9"
                >
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('connection_method') }}</h4>
                    <!-- Вивід типу підключення -->
                    <select
                        class="form-select mb-3"
                        data-plugin="choices"
                        v-model="form.connect_types.value"
                        :disabled="this.editform == false"
                    >
                        <option 
                            v-for="item in connect_types" 
                            :key="item.id"
                        >
                            {{ item.title }}
                        </option>
                </select>
                <h4 class="fs-15">{{ $t('what_is_connected_to') }}</h4>
                <!-- Вивід пристрою до котрого відбулось підключення -->
                <select
                    class="form-select mb-3"
                    data-plugin="choices"
                    v-model="form.device_types.value"
                    :disabled="this.editform == false"
                >
                    <option 
                        v-for="item in device_types" 
                        :key="item.id"
                    >
                        {{ item.title }}
                    </option>
                </select>
                <h4 class="fs-15">{{ $t('ver_oc') }}</h4>
                <!-- Вивід версії операційної системи -->
                <input 
                    type="number" 
                    class="form-control mb-3" 
                    :disabled="this.editform == false" 
                    v-model="form.version_os.value" 
                    placeholder="10"
                >
                <h4 class="fs-15">{{ $t('template') }}</h4>
                <!-- Вивід шаблону етикетки -->
                <select
                    class="form-select mb-3"
                    data-plugin="choices"
                    v-model="form.template.value"
                    :disabled="this.editform == false"
                >
                    <option 
                        v-for="item in templates" 
                        :key="item"
                    >
                        {{ item.title }}
                    </option>
                </select>
                
            </div>
        </div>
        <hr>
</template>

<script>

export default ({
    props: ['obj','editform'],
    data(){
        return {
                select_device:"",
                device_types: [
                    {
                        "title": this.$t('PC'),
                    },
                    {
                        "title": this.$t('tablet'),
                    },
                    {
                        "title": this.$t('telephone'),
                    }
                ],
                select_oc:"",
                oc_types: [
                    {
                        "title": "Windows",
                    },
                    {
                        "title": "Android",
                    }
                ],
                select_type_connect:"",
                connect_types: [
                    {
                        "title": this.$t('systemPrinter'),
                    },
                    {
                        "title": "USB",
                    }
                ],
                select_mode_print:"",
                modes_print: [
                    {
                        "title": this.$t('mode_text'),
                    },
                    {
                        "title": this.$t('mode_graphic'),
                    }
                ],
                templates: [
                    {
                        "title": this.$t('temp_normal'),
                    },
                    {
                        "title": this.$t('temp_extended'),
                    },
                    {
                        "title": this.$t('temp_jewelry'),
                    },
                    {
                        "title": this.$t('temp_jewelrymini'),
                    }
                ],
                form: {
                    model: {
                        icon: "ri-cpu-fill",
                        label: "bank_terminal_model",
                        value: ""
                    },
                    connect_types: {
                        icon: "mdi-connection",
                        label: "connection_method",
                        value: ""
                    },
                    modes_print: {
                        icon: "ri-user-2-fill",
                        label: "print_mode",
                        value: ""
                    },
                    device_types: {
                        icon: "ri-user-2-fill",
                        label: "device",
                        value: ""
                    },
                    oc_types: {
                        icon: "ri-user-2-fill",
                        label: "operatingSystem",
                        value: ""
                    },
                    version_os: {
                        icon: "ri-user-2-fill",
                        label: "ver_oc",
                        value: ""
                    },
                    version_apps: {
                        icon: "ri-user-2-fill",
                        label: "verSkyService",
                        value: ""
                    },
                    template: {
                        icon: "ri-mark-pen-line",
                        label: "template",
                        value: ""
                    }
                }

            }
        },
        created(){
            if(this.obj != ''){
                this.form = this.obj // основну форму з пропсу беремо
            }
        },
        methods:{
            input(){
                this.$emit('input', this.form) // подія вводу
            }
        }
    }
)
</script>
