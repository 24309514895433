<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0">{{$t('Privacy')}}</h5>
        </div>
        <div class="card-body">
            <div>
                <label for="choices-privacy-status-input" class="form-label"
                    >{{$t('Status')}}</label
                >
                <Multiselect
                    v-model="form.statusPrivacy"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="[
                    { value: 'Private', label: this.$t('private') },
                    { value: 'Team', label: this.$t('team') },
                    { value: 'Public', label: this.$t('public') },
                    ]"
                />
            </div>
        </div>
    </b-card>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
    props: ['obj'],
    components: { Multiselect },
    data() {
        return {
            form: ""
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.obj
        }
    }
}
</script>