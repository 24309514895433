<template>
    <!-- 
        Модальне вікно підтвердження скидання паролю
    -->

    <dialogmodal>
        <template v-slot:body>
          <div class="mt-4 text-center">
              <h3><b>{{ $t('ConfirmReset') }}?</b></h3>
              <p class="text-muted fs-15 mb-4">{{ $t('descResetpass') }}</p>
          </div>
        </template>
        <template v-slot:footer-bottom>
              <div class="hstack gap-2 justify-content-center remove">

                <button 
                    class="btn btn-link link-success fw-medium text-decoration-none" 
                    @click="$emit('close')"
                >
                    <i class="ri-close-line me-1 align-middle"></i>
                    {{ $t('close') }}
                </button>

                <button 
                    class="btn btn-danger" 
                    @click="reset(form.account_id)"
                >
                    {{ $t('y') }}
                </button>
                
              </div>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/modalsave.vue'

export default {
    props: ['obj'],
    components: {
        dialogmodal
    },
    data(){
        return{
            from: ""
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        reset(e){
            this.$emit('resetPass', e)
        }
    },
    mounted(){
        this.eventKey.on('enter', () => {
            this.remove(this.form)
        })
    }
}
</script>