<template>
    <!-- 
        Компонент карточка користувача онлайн 
    -->

    <!-- Діалогове вікно -->
    <dialogBox @close="this.$emit('close')">
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('Employee') }}</template>

        <!-- Тіло діалогового вікна -->
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <!-- Введення ім'я користувача -->
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('name') }}</h4>
                        <input class="form-control" v-model="this.obj.workerName" disabled />
                    </div>
                </b-col>
            </b-row>
            <b-row class="border rounded border-dashed p-2 mb-2">
                <!-- Інформація про зміну -->
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('statusShift') }}</h4>
                        <div class="input-group">
                            <input type="text" class="form-control" :value="this.obj.statusShift == 'close' ? $t('ShiftisClose') : $t('ShiftisOpen')" autocomplete="off"  disabled>
                            <!-- <button type="button" @click="openShift(this.obj.shift.shiftId)" class="btn btn-info waves-effect waves-light" >
                                <i class="bx bxs-show" ></i>
                            </button> -->
                        </div>
                    </div>
                </b-col>
                <!-- Інша інформація про користувача -->
                <!-- <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('StartShift') }}</h4>
                        <input class="form-control" :value="this.obj.shift.openShift" disabled />
                    </div>
                </b-col> -->
                <!-- <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('endShift') }}</h4>
                        <input class="form-control" :value="this.obj.shift.closeShift" disabled />
                    </div>
                </b-col> -->
                <!-- кнопку на карточку смены to dev -->
            </b-row>
            <!-- <b-row class="border rounded border-dashed p-2 mb-3" v-if="this.obj.break.statusBreak == true">
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('break') }}</h4>
                        <div class="input-group">
                            <input type="text" class="form-control" :value="this.obj.break.statusBreak == false ? '' : $t('goBreak')" autocomplete="off"  disabled>
                            <button type="button" @click="this.openBreak(this.obj.break.breakId)" class="btn btn-info waves-effect waves-light" >
                                <i class="bx bxs-show" ></i></button>
                        </div>
                    </div>
                </b-col>
            </b-row> -->
            <b-row>
                <!-- <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('site') }}</h4>
                        <input class="form-control" :value="this.obj.site ? this.obj.site : 'Old Inside'" disabled />
                    </div>
                </b-col> -->
                <!-- <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">IP</h4>
                        <input class="form-control" v-model="this.obj.ip" disabled />
                    </div>
                </b-col> -->
                <b-col lg="12" v-if="this.obj.city">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('city') }}</h4>
                        <input class="form-control" v-model="this.obj.city" disabled />
                    </div>
                </b-col>
                <b-col lg="12" v-if="this.obj.org">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('ProviderEth') }}</h4>
                        <input class="form-control" v-model="this.obj.org" disabled />
                    </div>
                </b-col>
                <!-- <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('operatingSystem') }}</h4>
                        <input class="form-control" v-model="this.obj.platform" disabled />
                    </div>
                </b-col> -->
            </b-row>
        </template>

        <!-- Нижня частина діалогового вікна -->
        <template v-slot:footer-bottom>
            <!-- Закрити зміну -->
            <button 
                v-if="this.user.userid == 33 || this.user.userid == 34"
                type="button" 
                class="btn btn-success" 
                @click="closeShift(obj)"
            >
                {{  this.$t("closeShiftForWorker") }}
            </button>
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="open(this.obj.workerId)"
            >
                Картка співробітника
            </button>
        </template>
    </dialogBox>

    <!-- Картка співробітника -->
    <viewcard 
        v-if="showModal == true" 
        @close="showModal = false" 
        :objcard="objcard"
    ></viewcard>

    <!-- Картка перерви -->
    <breakBox 
        v-if="showBreak"
        @close="showBreak = false"
        :objcard="objcard"
    /> 

    <!-- Картка зміни -->
    <shiftBox 
        v-if="showShift"
        @close="showShift = false"
        :objcard="objcard"
    />

</template>

<script>
import dialogBox from '@/components/modal/dialog'
import viewcard from '@/views/users/view/index';
import breakBox from '@/views/users/view/formright/breaks/view'
import shiftBox from '@/views/users/view/formright/shifts/view'
import { Users, Breaks } from '@/API.js';
import { storeS } from '../../store';

let apiServe = new Users();
let apiBreaks = new Breaks();

export default ({
    props: ['obj'],
    components: {
        dialogBox,
        viewcard,
        breakBox,
        shiftBox
    },
    data() {
        return{
            form: '',
            objcard: '',
            showModal: false,
            showBreak: '',
            showShift: ''
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        closeShift(item) {
            apiServe.closeShiftForWorker(item.workerId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit('close')
                }
            })
        },
        open(e){
            // Відкриття карточки користувача
            apiServe.getUser(e).then(result => {
                if(result.status == 'done') {
                    this.objcard = result.data
                    this.showModal = true
                }
            })
        },
        openBreak(e){
            // Відкриття карточки перерви
            apiBreaks.getBreak(e).then(result => {
                if(result.status == 'done'){
                    this.objcard = result.data;
                    this.showBreak = true;
                }
            })
        },
        openShift(e){
            // Відкриття карточки зміни

            apiServe.getCardShift(e).then(result => {
                if(result.status == 'done'){
                    this.objcard = result.data;
                    this.showShift = true;
                }
            })
        }
    },
    computed: {
        user(){
            return storeS.userbase
        },
    }
})
</script>
