<template>
  <div class="card card-info">
    <div class="card-body text-center">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1 ms-3">
          <h3 class="card-title">
            #{{ form.ticketId }} - {{ form.ticketName }}
          </h3>
          <p class="card-text">
            <span class="fw-medium"
              >{{ $t("createDate") }} : {{ form.createDatetime }}</span
            >
          </p>
        </div>
      </div>
    </div>
    <div
      class="card-footer copy"
      @click="copy('Тікет #' + form.ticketId + ' - ' + form.ticketName)"
    >
      <div class="text-center">
        <a class="link-light">
          {{ $t("toCopy") }}
          <i class="bx bx-copy align-middle lh-1"></i>
        </a>
      </div>
    </div>
  </div>

  <TimeTracking
    v-if="form.injobDatetime != null && form.decidedDatetime != null"
    :startDate="form.injobDatetime"
    :endDate="form.decidedDatetime"
  />

  <div class="mt-1 mb-3">
    <button type="button" class="btn btn-outline-danger waves-effect waves-light w-100" @click="showHelpBox = true">{{ $t("admin_help") }}</button>
  </div>

  <b-card>
    <div class="card-header align-items-center d-flex">
      <h4 class="card-title mb-0 flex-grow-1">{{ $t("aboutTicket") }}</h4>
      
      <!-- 
        Додаткові дії з тікетом
        - йде перевірка на права доступу 2000 (режим бога) 
        - або якщо той хто переглядає є автором тікету
        - або є розробником в цьому тікеті ( || this.form.inJobWorkers.filter((item) => item.workerId == this.user.userid).length > 0  ) - поки прибрав
      -->
      <div
        class="flex-shrink-0"
        v-if="
          form.createWorkerId == user.userid || 
          this.perms[2000] || 
          this.user.workerJob
        "
      >
        <div class="dropdown card-header-dropdown">
          <a
            class="text-reset dropdown-btn"
            href="#"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="text-muted">
              <i class="ri-settings-4-line align-middle me-1 fs-15"></i>
              {{ $t("action") }}
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-end">

            <!-- Кнопка змінити пріоритет -->
            <button class="dropdown-item" @click="showPriorityBox = true">
              {{ $t("ChangePriority") }}
            </button>
            
            <!-- Кнопка змінити середовище проблеми -->
            <button class="dropdown-item" @click="showProblemBox = true">
              {{ $t("ChangeEnvProblem") }}
            </button>

          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive table-card">
        <table class="table table-borderless align-middle mb-0">
          <tbody>
            <tr>
              <td class="fw-medium">{{ $t("ticket") }}</td>
              <td>#{{ form.ticketId }}</td>
            </tr>
            <tr>
              <td class="fw-medium">{{ $t("ticket_type") }}</td>
              <td class="hstack text-wrap gap-1">
                {{ this.mutateticket(form.type) }}
              </td>
            </tr>
            <tr v-if="form.problemEnvironment != null">
              <td class="fw-medium">{{ $t("problem_area") }}</td>
              <td class="hstack text-wrap gap-1">
                {{ mutateProblemEnv(form.problemEnvironment) }}
              </td>
            </tr>
            <tr v-if="form.decidedStatus">
              <td class="fw-medium">{{ $t("decidedStatus") }}:</td>
              <td>
                <span
                  :class="
                    'badge ' +
                    this.mutateDecidedStatus(form.decidedStatus).color
                  "
                  >{{ this.mutateDecidedStatus(form.decidedStatus).name }}</span
                >
              </td>
            </tr>
            <tr>
              <td class="fw-medium">{{ $t("Status") }}:</td>
              <td>
                <span
                  :class="
                    'badge ' + this.mutatestatus(form.status, this.form).color
                  "
                  >{{ this.mutatestatus(form.status, this.form).name }}</span
                >
              </td>
            </tr>
            <tr>
              <td class="fw-medium">{{ $t("priority") }}</td>
              <td>
                <span
                  :class="'badge ' + this.mutatepriority(form.priority).color"
                  >{{ this.mutatepriority(form.priority).name }}</span
                >
              </td>
            </tr>
            <tr>
              <td class="fw-medium">{{ $t("added") }}</td>
              <td>{{ nameWorker(form.createWorkerId) }}</td>
            </tr>
            <tr>
              <td class="fw-medium">{{ $t("createDate") }}</td>
              <td>{{ form.createDatetime }}</td>
            </tr>
            <tr class="border-top" v-if="form.injobDatetime">
              <td class="fw-medium">{{ $t("DateTimeExecution") }}</td>
              <td>{{ form.injobDatetime }}</td>
            </tr>
            <tr class="border-top">
              <td class="fw-medium">{{ $t("labels") }}</td>
              <td>
                <button
                  @click="showTagBox = true"
                  type="button"
                  class="btn btn-soft-info waves-effect waves-light"
                >
                  додати
                </button>
              </td>
            </tr>
            <div>
              <span
                class="fs-14 fw-semibold text-white"
                :style="`word-break: break-all;width:100%;display: block;margin-right:6px;margin-bottom:5px;padding:7px;border-radius:5px;background-color: ${
                  nameTag(item).color
                };`"
                v-for="item in form.tags"
                :key="item"
                >{{ nameTag(item).name }}
              </span>
            </div>
          </tbody>
        </table>
      </div>
    </div>
  </b-card>

  <!-- номера клиентов -->
  <numbers
    v-if="form.userPhones"
    :obj="form"
    @openclient="openclient"
    @editnum="editnum"
    :showComp="false"
  />
  <!-- номера клиентов -->

  <!-- устройства -->
  <!-- <devices v-if="this.form.devices.length > 0" :objDevice="form.devices" :obj="form" @opendevice="opendevice" @editnum="editnum" /> -->
  <!-- устройства -->

  <!-- репорты -->
  <!-- <reportsbox v-if="viewRepBox == true && form.reports && form.reports.length > 0" :obj="form.reports" :ticketId="form.ticketId" @openreport="openreport"/> -->
  <!-- репорты -->

  <!-- команды/ пользователи -->
  <teams @addteam="addteam" :obj="form" />
  <!-- команды/ пользователи -->

  <!-- файлы -->
  <!-- <filexbox :obj="form"/> -->
  <!-- файлы -->

  <dialogBox v-if="showPriorityBox" @close="showPriorityBox = false">
    <template v-slot:title>{{ $t("ChangePriority") }}</template>
    <template v-slot:body>
      <div class="mt-1 text-center">
        <h3 class="mb-3">
          <b>{{ $t("selectNewPr") }}</b>
        </h3>
        <Multiselect
          v-model="newForm.priority"
          :close-on-select="true"
          :searchable="true"
          :create-option="false"
          :options="this.listPriority"
          :placeholder="$t('priority')"
        />
      </div>
    </template>
    <template v-slot:footer-bottom>
      <div class="hstack gap-2 mt-3 justify-content-center remove">
        <button class="btn btn-danger" @click="$emit('close')">
          {{ $t("cancel") }}
        </button>
        <button class="btn btn-success" @click="changePriority">
          {{ $t("Edit") }}
        </button>
      </div>
    </template>
  </dialogBox>

  <modalTag
    v-if="showTagBox"
    @close="showTagBox = false"
    :typeTags="'tags'"
    :checkedTags="form.tags"
    @selectedTags="selectedTags"
  />

  <changeProblem
    v-if="showProblemBox"
    @close="showProblemBox = false"
    :objCard="form"
  />

  <helpBox 
    v-if="showHelpBox"
    @close="showHelpBox = false"
    :objCard="form"
  />

</template>

<script>
import dialogBox from "@/components/modal/dialog";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import reportsbox from "./reports/reports";
import teams from "./teamticket";
import filexbox from "./files/filesblock";
import numbers from "./clientnumber";
import devices from "./devices";
import TimeTracking from "@/components/timeTracking";
import changeProblem from "../actions/changeProblem.vue";
// import modalTag from '@/components/tags/add'
import modalTag from "@/components/tags/list";
import helpBox from "./helpBox";
import { nameWorker, tgAccWorker } from "@/usabilityScripts/getNameWorker";
import { Tickets } from "@/API";
import { storeS } from "@/store";
import {
  mutateTypeTicket,
  mutateStatusTicket,
  mutateDecidedStatusTicket,
  mutatePriorityTicket,
  mutateProblemEnv,
  nameTag,
} from "@/usabilityScripts/globalMutate.js";
import { sendTelegramChangePTicket } from '@/usabilityScripts/logsTelegram.js'
import {copy} from '@/usabilityScripts/copy.js'

let apiServe = new Tickets();

export default {
  props: ["obj", "viewRepBox"],
  components: {
    reportsbox,
    teams,
    filexbox,
    numbers,
    devices,
    TimeTracking,
    dialogBox,
    Multiselect,
    changeProblem,
    modalTag,
    helpBox
  },
  data() {
    return {
      showTagBox: false,
      showProblemBox: false,
      showHelpBox: false,
      form: "",
      newForm: {
        priority: "",
      },
      showPriorityBox: false,
      listPriority: [
        {
          label: this.$t("Critical"),
          value: "3",
        },
        {
          label: this.$t("priorityHigh"),
          value: "2",
        },
        {
          label: this.$t("priorityMedium"),
          value: "1",
        },
        {
          label: this.$t("priorityLow"),
          value: "0",
        },
      ],
    };
  },
  created() {
    this.getdata();
    // this.calcdate()
  },
  methods: {
    getdata() {
      console.log(this.obj);
      this.form = this.obj;
      this.newForm.priority = this.form.priority;
    },
    selectedTags(ids) {
      this.form.tags = ids;
      this.form.comment = this.form.createComment;
      apiServe.editTicket(this.form.ticketId, this.form).then((result) => {
        if (result.status == "done") {
          this.$toast.success("done");
        } else {
          this.$toast.error($t("error"));
        }
      });
      //додавання тегів до тикету
    },
    openreport(e) {
      this.$emit("openreport", e);
    },
    showteams() {
      this.$emit("showteams");
    },
    openclient(e) {
      this.$emit("openclient", e);
    },
    opendevice(e) {
      this.$emit("opendevice", e);
    },
    editnum(e) {
      this.$emit("editnum", e);
    },
    changePriority() {
      // Змінити пріоритет тікету

      apiServe
        .changePriority(this.form.ticketId, this.newForm)
        .then(result => {
          if (result.status == "done") {
            this.$toast.success(this.$t("PriorityChangSucc"));
            this.eventBus.emit("updateTicket", true);
            this.showPriorityBox = false;

            console.log("new", this.newForm);
            
            // Надсилаємо оповіщення ще в одну тг, щоб не пропустити
            if(this.newForm.priority == "2" || this.newForm.priority == "3"){

              var ticketdId = result.data.ticketId;
              var type = this.mutateticket(result.data.type);
              var problemEnvironment = this.mutateProblemEnv(result.data.problemEnvironment);
              var createWorkerId = this.nameWorker(this.user.userid);
              var tgAccCreateWorker = this.tgWorker(this.user.userid).tgNick;
              var tgAccAuthorTicket = this.tgWorker(result.data.createWorkerId).tgNick;
              var ticketName = result.data.ticketName;
              var createComment = result.data.createComment;
              var oldPriority = this.mutatepriority(this.form.priority).name;
              var newPriority = this.mutatepriority(result.data.priority).name;

              // Викликаємо функцію для відправки повідомлення в телеграм
              sendTelegramChangePTicket(ticketdId, type, problemEnvironment, createWorkerId, ticketName, createComment, oldPriority, newPriority, tgAccCreateWorker, tgAccAuthorTicket)
              .then(response => console.log('Telegram API response:', response.data))
              .catch(error => console.error('Telegram API error:', error)); 

            }

          } else {
            this.$toast.error(this.$t("error"));
          }
          
        });
    },
    copy(item){
      // копіювання
      if(copy(item) == true) {
          this.$toast.success(this.$t('Copied'));
      } else {
          this.$toast.error(`Fucking mistake, contact Borys`);
      }
      return
    },
    nameWorker(item) {
      return nameWorker(item);
    },
    tgWorker(item) {
      // отримання нікнейму співробітника
      return tgAccWorker(item);
    },
    mutateticket(e) {
      return mutateTypeTicket(e);
    },
    mutatestatus(e, form) {
      return mutateStatusTicket(e, this.nameWorker(form.assignedWorkerId));
    },
    mutatepriority(e) {
      return mutatePriorityTicket(e);
    },
    mutateDecidedStatus(e) {
      return mutateDecidedStatusTicket(e);
    },
    mutateProblemEnv(e) {
      return mutateProblemEnv(e);
    },
    nameTag(e) {
      return nameTag(e);
    },
  },
  mounted() {},
  computed: {
    workers() {
      return storeS.workers;
    },
    user() {
      return storeS.userbase;
    },
    perms() {
      return storeS.perms;
    },
  },
};
</script>
