<template>
    <!-- модальне вікно видалення категорії з уроками -->
    <modal :title="kb.title" :stylebody="'var(--vz-body-bg)'">
      <template v-slot:head-bottom>
        <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')">
          <i class="ri-close-fill align-bottom"></i>
        </button>
      </template>
      <template v-slot:body>
         <main class = 'main_section'>
          <div style="background-color: white; border-radius: 7px; padding: 20px; margin-top: 10px;">
            <!-- малюємл табличку по пропорціям -->
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" style="width: calc(100%/10);">#</th>
                  <th scope="col" style="width: calc(100% - ((100%/10)*7)); text-align: center;">{{ this.$t('name') }}</th>
                  <th scope="col" style="width: calc(100% - ((100%/10)*6)); text-align: center;">{{ this.$t('action') }}</th>
                  <th scope="col" style="width: calc(100% - ((100%/10)*8));  text-align: center;">{{ this.$t('transferedTo') }}</th>
                </tr>
              </thead>
              <tbody>
                 <!-- рендеримо уроки. Якщо урок знаходиться в папці на видалення, то підсвітка стає червона, якщо на переміщення - то зеленим -->
                <tr 
                  v-for="(item,index) in this.lessons" 
                  :key="index" 
                  :class="this.onDelete.indexOf(item) == -1 ? (this.onTransfer.indexOf(item) == -1 ? '' : 'table-success') : 'table-danger'"
                >
                  <th scope="row" style="text-align: center;">{{ index }}</th>
                  <td style="text-align: center;">{{ item.question }}</td>
                  <td style="text-align: center; display: grid; align-items: center; justify-content: center;">
                    <!-- селектор, що з'являється, коли урок ми збираємося переміщувати. З'являєть вибір між категоріями, що існують. Спрацювує функція з назвою, котра відобразить біль розробника -->
                    <select 
                      @input="razyob($event, item)" 
                      class="form-select" 
                      aria-label="Default select example" 
                      v-if="this.onDelete.indexOf(item) == -1 ? (this.onTransfer.indexOf(item) == -1 ? false : true) : false"
                    >
                      <option value="null">{{ this.$t('Null') }}</option>
                      <option v-for="items in this.child" :value="'' + items.value">{{ items.label }}</option>
                    </select>
                    <div><p></p></div>
                    <!-- кнопка, що додає урок на видалення -->
                    <button type="button" class="btn btn-danger" style="margin-bottom: 5px;" @click="addInDelete(item)">{{ this.$t('Delete') }}</button>
                    <!-- кнопка, що додає урок на видалення -->
                    <button type="button" class="btn btn-success" @click="addInTransfer(item, index)">{{this.$t('transfer')}}</button>
                  </td>
                  <!-- Показує інфу чи був переміщенний урок (якщо так, то показує куди), чи був видалений -->
                  <td style="text-align: center;">
                    {{ 
                      this.onDelete.indexOf(item) !== -1 ? 
                      this.$t('Removed') : (
                        this.onTransfer.indexOf(item) == -1 ?
                        '' : (
                          this.lessons[index].category == undefined ?
                          this.$t('Null') : this.categories.find(el => el.categoryId == this.lessons[index].category).categoryName
                        )
                      ) 
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </template>
      <template v-slot:footer-bottom>
        <!-- 
          відправити уроки на апі. Функція теж каже, що то весело дуже.
          Є перевірка:
            - кнопка з'являєть, коли "доля всіх постів була вирішена"
        -->
        <button type="button"  class="btn btn-danger" v-if="(this.onTransfer.length + this.onDelete.length) == this.objdata.posts.length" @click="finishTheServer()">{{ $t('done') }}</button>
        <!-- <button type="button"  class="btn btn-info" @click="checkall()">{{ this.$t('takeAll') }}</button> -->
      </template>
    </modal>
    

  </template>
  
  <script>
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import modal from '@/components/modal'
  import { FAQ } from '@/API.js';
  import { storeS } from '@/store.js'
  
  let apiServe = new FAQ();
  
  export default {
    data() {
      return {
        delForm: {
          deleted: [],
          transferPosts: []
        },
        checkedSelector: '',
        lessons: '',
        transferTo: [],
        onTransfer: [],
        onDelete: [],
        options: [],
        checked: [],
        kb: [],
        child: [],
        category: [],
        form: {
          id:"",
          title: "",
          content: "",
          content1: "",
          category: "0",
          lang:"0",
          create_user: "",
          create_date_time: "",
          url: "",
          tags:[]
        },
        
      };
    },
    props: ['categories', 'objdata'],
    components: {
      modal,
      Multiselect
    },
    created() {
      // збираємо категорії та уроки (пости. Модалка скопійована, тому підлаштовувався)
      this.lessons = this.objdata.posts
      this.categories.forEach(el => {
        if(el !== this.objdata) {
          this.child.push({
            label: el.categoryName,
            value: el.categoryId,
          }) 
        }
      })
    },
    methods: {
      finishTheServer() {
        // у форму закидуємо уроки на видалення
        this.onDelete.forEach(el => {
          this.delForm.deleted.push(el.postId)
        })
        // закидуємо уроки на переміщення
        this.onTransfer.forEach(el => {
          this.delForm.transferPosts.push({
            categoryId: el.category,
            postId: el.postId,
          })
        })
        // використовуємо апі, якщо все ок, то спрацює еміт на закриття модалки та отримання даних. Якщо ні, то лог відправиться в телеграм
        apiServe.deleteCategWithLessons(this.objdata.categoryId, this.delForm).then(result=>{
          if(result.status == 'done') {
            this.$toast.success(this.$t('Removed'))
            this.$emit('getdata')
            this.$emit('close')
          } else {
            this.$toast.error(this.$t('error'))
            
            var errorText = 'Помилка відкриття заявки завдання'; 
            var statusRequest = result.status;
            var api = 'deleteCategWithLessons';
            var fileRequest = 'src/components/faq/deleteCategmodal.vue';
            var params = id;
            var response = result

            // Викликаємо функцію для відправки повідомлення в телеграм
            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
            .then(response => console.log('Telegram API response:', response.data))
            .catch(error => console.error('Telegram API error:', error)); 

          }
        })
      },
      razyob(e, item) {
        // функція, що присвоює назву категорії до посту
        // якщо в категорії нема уроків, то додаємо перший
        if(this.child.find(el => el.value == e.target.value).lessons == undefined) {
          let lessons = {lessons: []}
          Object.assign(this.child[this.child.indexOf(this.child.find(el => el.value == e.target.value))], lessons)
          this.child[this.child.indexOf(this.child.find(el => el.value == e.target.value))].lessons.push(item.lessonId)
        } else {
          // якщо є якісь уроки, то просто додаємо id без об'явлення нового ключа
          this.child[this.child.indexOf(this.child.find(el => el.value == e.target.value))].lessons.push(item.lessonId)
        }
        // якщо в пості не вказана категорія, то створюємо ключ "category" вказуємо категорію і вставляємо 
        if(this.lessons.find(el => el.lessonId == item.lessonId).category == undefined) {
          let category = {category: Number(e.target.value)}
          Object.assign(this.lessons.find(el => el.lessonId == item.lessonId), category)
        } else {
          // якщо ключ є, то просто його змінюємо
          this.lessons.find(el => el.lessonId == item.lessonId).category = Number(e.target.value)
        }
      },
      checkall() {
        // відмітити всі (поки не працює)
        if(this.checked.length !== this.objdata.lessons.length) {
          this.checked = []
          this.objdata.lessons.forEach(el => {
            this.checked.push(el)
          }) 
        } else {
          this.checked = []
        }
      },
      addInDelete(e) {
        // функція, що додає на видалення пост
        if(this.onDelete.indexOf(e) != -1) {

        } else {
          // якщо пост був на переміщення, то його звідти видаляємо та переміщуємо на видалення
          if(this.onTransfer.indexOf(e) == -1) {
            this.onDelete.push(e)
          } else {
            this.onDelete.push(e)
            this.onTransfer.splice(this.onTransfer.indexOf(e), 1)
          }
        }
      },
      addInTransfer(e, index) {
        // функція, що додає на переміщення пост
        if(this.onTransfer.indexOf(e) != -1) {
          
        } else {
          // якщо пост був на видалення, то його звідти видаляємо та переміщуємо на переміщення
          if(this.onDelete.indexOf(e) == -1) {
            this.onTransfer.push(e)
          } else {
            this.onTransfer.push(e)
            this.onDelete.splice(this.onDelete.indexOf(e), 1)
          }
        }
      }
    },
    computed: {
      user(){
        return storeS.userbase
      },
      checkedNames() {
        return this.checked.join(', ')
      },
    },
    mounted() {
      
    },
  };
  </script>
  
  <style scoped>
  .arrow_back {
    margin-bottom: 10px;
  }
  </style>