<template>
   <b-card>
        <b-row>
            <h3><b>{{ $t('communicationWithClient') }}</b></h3>
        </b-row>
        <b-col>
            <div class="mb-3" >
                <div class="d-flex mt-4" >
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="ri-hashtag"></i></div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden" >
                        <p class="mb-1">{{ $t('clientBeforeActivation') }} :</p>
                        <h6 class="text-truncate mb-0">- (Waiting API)</h6>
                    </div>
                </div>
            </div>
            <div class="mb-3" >
                <div class="d-flex mt-4" >
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div class="avatar-title bg-light rounded-circle fs-16 text-primary" ><i class="bx bxs-phone"></i></div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden" >
                        <p class="mb-1">{{ $t('clientAfterActivation') }} :</p>
                        <h6 class="text-truncate mb-0">- (Waiting API)</h6>
                    </div>
                </div>
            </div>
        </b-col>
    </b-card>
</template>

<script>
export default({
    props: [],
    components: {},
    data() {
        return{
            form: ""
        }
    },
    created(){
        
    }
})
</script>
