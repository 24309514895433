<template>
    <modal :title="form.stockTitle">
        <template v-slot:status>
            <span class="fs-12 badge bg-info" v-if="form.auto">{{ $t('ApplyPromotionAutomatically') }}</span>
        </template>
        <template v-slot:body>
            <b-card>
                <b-row>
                    <div class="card-header mb-3">
                        <h5 class="card-title mb-0"><b>{{ $t('main') }}</b></h5>
                    </div>
                    <b-col lg="12">
                        <h4 class="fs-18 fw-semibold">{{ $t('Name') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.stockTitle" disabled>
                            <button @click="copy(form.bonusTitle)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h4 class="fs-18 fw-semibold">{{ $t('PromotionStartDate') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.bonusDiscountStartDate ? form.bonusDiscountStartDate : '-'" disabled>
                            <button @click="copy(form.bonusDiscountStartDate)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h4 class="fs-18 fw-semibold">{{ $t('PromotionEndDate') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.bonusDiscountEndDate ? form.bonusDiscountEndDate : '00.00.0000'" disabled>
                            <button @click="copy(form.bonusDiscountEndDate)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="12">
                        <h4 class="fs-18 fw-semibold">{{ $t('DaysThePromotion') }}</h4>
                        <div class="d-flex mb-3" style="flex-wrap: wrap;">
                            <template v-for="item in days" :key="item">
                                <div class="d-flex" style="margin-right: 8px;">
                                    <div class="check-as form-check form-check-success">
                                        <input type="checkbox" autocomplete="off" class="form-check-input" v-model="form.days[item.value]" disabled>
                                    </div>
                                    <div class="flex-grow-1" style="padding-top: 5px;" >
                                        <label class="form-check-label fs-16" >
                                            <b>{{ item.title }}</b> 
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </b-col>
                    <b-col lg="6" >
                        <h4 class="fs-18 fw-semibold">{{ $t('PromotionOpeningHours') }}</h4>
                        <div class="border rounded border-dashed p-2 mb-2">
                            <template v-for="(item, index) in form.times" :key="index">
                                <div class="d-flex align-items-center mb-1" >
                                    <div class="flex-shrink-0" >
                                        <div class="avatar-xs flex-shrink-0 me-1" ><div class="avatar-title rounded bg-soft-info text-black" >{{ index+1 }}</div></div>
                                    </div>
                                    <div class="flex-grow-1 ms-2" >
                                        <input class="form-control" v-model="item.start" disabled />
                                    </div>
                                    <div class="flex-grow-1 ms-2" >
                                        <input class="form-control" v-model="item.end" disabled />
                                    </div>
                                </div>
                            </template>
                        </div>
                    </b-col>
                </b-row>
            </b-card>

            <b-card>
                <div class="card-header mb-3">
                    <h5 class="card-title mb-0"><b>{{ $t('TermsAction') }}</b></h5>
                </div>
                <b-row>
                    <b-col lg="6">
                        <h4 class="fs-14 fw-semibold">{{ $t('TriggerConditions') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.condition == 'all' ? $t('AllConditions') : $t('AnyofAconditions')" disabled>
                            <button @click="copy(form.condition == 'all' ? $t('AllConditions') : $t('AnyofAconditions'))" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6">
                        <h4 class="fs-14 fw-semibold">{{ $t('tt_uchastwuiuschie_v_actsii') }}</h4>
                        <template v-if="form.tradepoints.type != 'all'" v-for="item in form.tradepoints.tradepointIds" :key="item">
                            <span class="fs-14 badge bg-info m-1">{{ getTitleTT(item) }}</span>
                        </template>
                        <b-form-select
                            v-model="form.tradepoints.type"
                            :options="outletsPromotion"
                            class="form-control"
                            disabled
                        >
                        </b-form-select>
                    </b-col>
                </b-row>
            </b-card>

            <tablecustom 
                @search="searchB" 
                :objParams="objParams"
                :columns="columns" 
                :rows="filteredItems" 
            />

            <b-card>
                <div class="card-header mb-3">
                    <h5 class="card-title mb-0"><b>{{ $t('PromotionResult') }}</b></h5>
                </div>
                <b-row>
                    <b-col lg="6" class="mb-2">
                        <h4 class="fs-14 fw-semibold">{{ $t('WhatDoesClientGet') }} 
                            <span v-if="form.fix && form.bonusResult === 'discountSum'" class="fs-12 badge bg-warning">{{ $t('EachConditionApplie') }}</span>
                        </h4>
                        <b-form-select
                            v-model="form.bonusResult"
                            :options="resultProms"
                            class="form-control"
                        >
                        </b-form-select>
                    </b-col>
                </b-row>
                <b-row v-if="form.bonusResult === 'discountPercent'">
                    <b-col lg="6" class="mb-2">
                        <h4 class="fs-14 fw-semibold">{{ $t('AmountDiscount') }} 
                            <span v-if="form.roundoff" class="fs-12 badge bg-warning">{{ $t('RoundAfterDiscount') }}</span>
                        </h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.discountPercent + ' %'" disabled>
                            <button @click="copy(form.discountPercent + ' %')" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" v-if="form.bonusResult === 'discountSum'" class="mb-2 mt-2">
                        <h4 class="fs-14 fw-semibold">{{ $t('Quantity') }}</h4>
                        <b-form-select
                            v-model="form.howMatch"
                            :options="resultQuantity"
                            class="form-control"
                        >
                        </b-form-select>
                    </b-col>
                    <b-col lg="6" class="mb-2">
                        <h4 class="fs-14 fw-semibold">{{ $t('BonusTime') }}</h4>
                        <b-form-select
                            v-model="form.giftTime"
                            :options="resultTimeBonus"
                            class="form-control"
                        >
                        </b-form-select>
                    </b-col>
                    <b-col lg="6" class="mb-2">
                        <h4 class="fs-14 fw-semibold">{{ $t('ParticipantsAction') }}</h4>
                        <b-form-select
                            v-model="form.shareholders"
                            :options="resultClient"
                            class="form-control"
                        >
                        </b-form-select>
                    </b-col>
                </b-row>
            </b-card>

            <tablecustom 
                v-if="form.bonusResult === 'discountSum'" 
                @search="searchB" 
                :objParams="objParams"
                :columns="columnsResult" 
                :rows="filteredItems" 
            />

            <tablecustom 
                v-if="form.bonusResult === 'bonusGoods'" 
                @search="searchB" 
                :objParams="objParamsBonus"
                :columns="columnsResult" 
                :rows="filteredBonusItems" 
            />

        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/modal-lg'
import tablecustom from '@/components/globaltable/index';
import {copy} from '@/usabilityScripts/copy.js'

export default{
    props: ['obj', 'objTradepoints'],
    components: {
        modal,
        tablecustom
    },
    data(){
        return{
            form: '',
            objParams: {
                search: ''
            },
            objParamsBonus: {
                search: ''
            },
            checkboxBonus: [
                { title: this.$t('BonusCheckbox1'), value: 'accrualOfBonusesWhenWritingoff'},
                { title: this.$t('BonusCheckbox2'), value: 'bonusModificators'},
                { title: this.$t('BonusCheckbox3'), value: 'bonusDiscountGoods'},
                { title: this.$t('BonusCheckbox4'), value: 'bonusSaleGoods'},
                { title: this.$t('BonusCheckbox5'), value: 'bonusTriggerSaleGoods'},
            ],
            days: [
                { title: this.$t('Monday'), value: 'Mon' },
                { title: this.$t('Tuesday'), value: 'Tue' },
                { title: this.$t('Wednesday'), value: 'Wed' },
                { title: this.$t('Thursday'), value: 'Thu' },
                { title: this.$t('Friday'), value: 'Fri' },
                { title: this.$t('Saturday'), value: 'Sat' },
                { title: this.$t('Sunday'), value: 'Sun' },
            ],
            columns: [
                {
                    name: this.$t("type"),
                    text: "type",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item) => item == 'product' ? this.$t('Product') : this.$t('Categories'),
                    mutateClass: (item) => item == 'product' ? 'fs-12 badge bg-success' : 'fs-12 badge bg-info',
                },
                {
                    name: this.$t("Name"),
                    text: "name",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item) => item.nomenclatureName != '' ? item.nomenclatureName : this.getCategory(item)
                    // getCategory
                },
                {
                    name: this.$t("Condition"),
                    text: "quantity",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item, obj) => {
                        var name = ''
                        switch (obj.ifs) {
                            case 'notless':
                                if(obj.unit == 'pcs'){
                                    name = this.$t('notless') + ' ' + obj.quantity + ' ' + this.$t('shtuk')
                                } else {
                                    name = this.$t('notless') + ' ' + obj.quantity + ' ₴'
                                }
                                break;
                            case 'equally':
                                if(obj.unit == 'pcs'){
                                    name = this.$t('equally') + ' ' + obj.quantity + ' ' + this.$t('shtuk')
                                } else {
                                    name = this.$t('equally') + ' ' + obj.quantity + ' ₴'
                                }
                                break;
                        
                            default:
                                break;
                        }
                        return name
                    }
                },
            ],
            columnsResult: [
                {
                    name: this.$t("type"),
                    text: "type",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item) => item == 'product' ? this.$t('Product') : this.$t('Categories'),
                    mutateClass: (item) => item == 'product' ? 'fs-12 badge bg-success' : 'fs-12 badge bg-info',
                },
                {
                    name: this.$t("Name"),
                    text: "name",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item) => item ? item.nomenclatureName : ''
                },
                {
                    name: this.$t("Quantity"),
                    text: "discCount",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item, obj) => obj.hasOwnProperty('discCount') ? obj.discCount : obj.discQuantity
                },
                {
                    name: this.$t("priceprod"),
                    text: "discSum",
                    align: "left",
                    status: true,
                    copy: true,
                    mutate: (item) => item ? item : '-'
                },
            ],
            outletsPromotion: [
                { value: 'all', text: this.$t('AllOutlets') },
                { value: 'include', text: this.$t('SelectedOutlets') },
                { value: 'except', text: this.$t('AllExceptSelectedOutlets') },
            ],
            resultProms: [
                { value: 'bonusGoods', text: this.$t('BonusProducts') },
                { value: 'discountSum', text: this.$t('FixPriceDiscountSum') },
                { value: 'discountPercent', text: this.$t('FixPriceDiscountPercent') },
            ],
            resultQuantity: [
                { value: 'all', text: this.$t('all') },
                { value: 'one', text: this.$t('OnefromListChooseFrom') },
            ],
            resultTimeBonus: [
                { value: 'now', text: this.$t('bonusisIssuedImmediately') },
                { value: 'nextPurchase', text: this.$t('nextPurchases') },
            ],
            resultClient: [
                { value: '1', text: this.$t('allClient') },
                { value: '2', text: this.$t('onlyRegister') },
            ],
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        searchB(e){
            this.objParams.search = e
        },
        getTitleTT(item) {
            var name = "";
            this.objTradepoints.forEach((el) => {
                if(el.tradepointId === item) {
                    name = el.tradepointName
                }
            })
            return name
        },
        getCategory(item) {
            var name = "";
            this.form.categoriesArr.forEach((el) => {
                if(el.categoryId === item.categoryId) {
                    name = el.parent ? '- ' + el.categoryTitle : el.categoryTitle
                }
            })
            return name
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form.goods)
                // По полю поиска
                .filter(item => {
                    return this.objParams.search == '' || item.name.nomenclatureName.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1;
                })
        },
        filteredBonusItems: function() {
            return Object.values(this.form.bonusGoods)
                // По полю поиска
                .filter(item => {
                    return this.objParams.search == '' || item.name.nomenclatureName.toString().toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1;
                })
        },
    }
}
</script>

<style>
.check-as{
    margin: 5px;
    margin-right: 10px;
}
.check-as .form-check-input {
    width: 1.6em;
    height: 1.6em;
}
.form-check-success .form-check-input:checked{
    background-color: #0ab39c;
    border-color: #0ab39c;
}
</style>