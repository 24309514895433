<template>
    <!-- 
        Список штрафів по співробітнику
        - дозволяє переглянути список штрафів ( з пошуком по імені/номеру телефона), та з фільтрацією по статусу
        - відкрити картку штрафу
        - викликати створення співробітника
        - та інше :)
    -->

    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
        <!-- кнопка відображення інфо-блоків -->
        <button 
            @click="infoblockShow == true ? infoblockShow = false : infoblock()" 
            type="button" 
            :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
        >
            <i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i>
        </button>
    </div>

    <!-- 
        Інформаційні блоки з підсумком по -
        - усього штрафів та на яку суму
        - скільки штрафів підтвердженно та на яку суму
        - скільки штрафів очікують підтвердження та на яку суму
        - скільки штрафів анульовано та на яку суму
    -->
    <infoblocks 
        v-if="infoblockShow" 
        :obj="this.information" 
        style="margin-top:15px;" 
    />

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - - йде перевірка на права доступу 2000 (режим бога) та 707 (створення штрафів) та перевірку хто переглядає картку співробітника
        - @create - подія, яка спрацьовує при створенні нового штрафу
        - @open - подія, яка спрацьовує при відкритті картки штрафу
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :createdform="(this.perms[707] || this.perms[2000]) && this.user.userid != this.id"
        @create="create" 
        @change="change" 
        @open="open"
        :pages="pages"
        :showpag="true"
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
    />

    <!-- 
       Виклик відкриття картки штрафу
       - для відкриття застосовується перевірка значення modalDialog
       - @close - подія, яка спрацьовує при закритті картки
       - :obj - параметр-об'єкт з даними про штраф
    -->
    <viewbox 
        v-if="modalDialog == true"
        @close="closeModal" 
        :obj="this.fine" 
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewbox from '@/views/statistics/fines/view' // картка штрафу
import infoblocks from '@/components/info/blocks' // інфо-блоки
import { Fines } from '@/API.js' // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { mutatetypeFine, mutateStatusFine } from '@/usabilityScripts/globalMutate.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiFines = new Fines();

export default {
    props: ['id'],
    components: { tablecustom, viewbox, infoblocks },
    data() {
        return {
            modalDialog: false, // прапорець відображення вікна створення штрафу
            infoblockShow: false, // прапорець відображення інфо-блоків
            fine: {}, // Об'єкт з даними штрафу для відображення в модальному вікні
            pages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "fines"
            },
            objParams: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                status: '',
                pr1: '',
                pr2: ''
            },
            rows: [], // Дані для відображення у таблиці
            columns: [ 
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: this.$t("typeFine"), 
                    text: "type", 
                    align: "left", 
                    status: true,
                    mutate: (item) => this.mutatetype(item) 
                },
                {
                    name: this.$t("Status"),
                    text: "fineStatus",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutatestatus(item).name,
                    mutateClass: (item) => this.mutatestatus(item).color
                },
                {
                    name: this.$t("Sum"),
                    text: "fineSum",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("added"),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("date_time"),
                    text: "createDatetime",
                    align: "left",
                    status: true,
                }
            ],
            information: [
                // Дані компонента, які відображають інформацію про кількість та суми штрафів в різних статусах.
                // Кожен елемент масиву містить:
                // * title - назву інформаційного блоку,
                // * value - кількість штрафів у відповідному статусі,
                // * icon - клас іконки,
                // * color - колір інформаційного блоку,
                // * sum - загальну суму штрафів у відповідному статусі.
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                    sum: "0"
                },
                {
                    title: this.$t('Confirmed'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success",
                    sum: "0"
                },
                {
                    title: this.$t('AwaitingСonfirmation'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning",
                    sum: "0"
                },
                {
                    title: this.$t('annul'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger",
                    sum: "0"
                }
            ],
        }
    },
    created(){
        // Ініціалізація компонента та отримання даних
        this.getdata()
    },
    methods: {
        getdata(page) {
            /**
                Отримує дані про штрафи з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Number} page - Номер сторінки для отримання даних.
            */

            // Встановлення параметрів для запиту до API
            this.objParams.workerId = this.id
            this.objParams.page = page != undefined ? page : '1';

            // Отримання даних про штрафи з API
            apiFines.getAllFines(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                    this.pages = result.data.links;
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка отримання списку штрафів'; 
                    var statusRequest = result.status;
                    var api = 'getAllFines';
                    var fileRequest = 'src/views/users/view/formright/fines/fines.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        infoblock(){
            /*
                Оновлює інформаційний блок з кількістю та сумами штрафів у різних статусах.
            */

            this.information[0].value = this.rows.length
            this.information[1].value = this.rows.filter(item => item.fineStatus == 'confirmed').length
            this.information[2].value = this.rows.filter(item => item.fineStatus == 'waitingСonfirmation').length
            this.information[3].value = this.rows.filter(item => item.fineStatus == 'annul').length
            
            this.information[0].sum = this.arraySum(this.rows).toFixed(2)
            this.information[1].sum = this.arraySum(this.rows.filter(item => item.fineStatus == 'confirmed')).toFixed(2)
            this.information[2].sum = this.arraySum(this.rows.filter(item => item.fineStatus == 'waitingСonfirmation')).toFixed(2)
            this.information[3].sum = this.arraySum(this.rows.filter(item => item.fineStatus == 'annul')).toFixed(2)
            
            this.infoblockShow = true
        },
        arraySum(array){
            /**
                Обчислює суму значень у масиві чисел.

                @method arraySum
                @param {Array} array - Масив чисел.
                @return {Number} - Загальна сума значень у масиві.
            */

            var sum = 0;
            for(var i = 0; i < array.length; i++){
                sum += Number(array[i].fineSum);
            }
            return sum
        },
        create(){
            // подія, яка спрацьовує при створенні нового штрафу

            this.$emit('openfinebox')
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        open(e){
            /**
                Функція для відкриття модального вікна з інформацією про штраф за його ідентифікатором.
                * @param {Object} e - об'єкт картки штрафу

                Викликає API для отримання інформації про штраф.
                Результат присвоюється властивості fine, та встановлюється прапорці modalDialog в true для відображення модального вікна.
            */

            apiFines.getFine(e.fineId).then(result => {
                if(result.status == 'done'){
                    this.fine = result.data;
                    this.modalDialog = true;
                } else {
                    this.$toast.error(this.$t('err'));

                    var errorText = 'Помилка відкриття картки штрафу'; 
                    var statusRequest = result.status;
                    var api = 'getFine';
                    var fileRequest = 'src/views/users/view/formright/fines/fines.vue';
                    var params = e.fineId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            }) 
        },
        closeModal(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці modalDialog в false, та викликає функцію getdata для оновлення даних таблиці.
            */

            this.modalDialog = false;
            this.getdata();
        },
        mutatetype(e){
            /*
                Функція для застосування перекладу до типу штрафу
            */
            return mutatetypeFine(e)
        },
        mutatestatus(e){
            /*
                Функція для застосування перекладу до статусу штрафу
            */
            return mutateStatusFine(e)
        },
    },
    mounted(){
        // Cлухання події saveFines для оновлення даних після збереження штрафів
        this.eventBus.off('saveFines');
        this.eventBus.on('saveFines', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    },
    computed: {
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
    }
}
</script>