<template>
    <!-- 
        Основна вкладка, містить в собі - 
        - відображення і редагування інформації про зміну робочого часу працівника.
    -->

    <h4 class="fs-16 mb-2">{{$t('hi')}}, {{this.form.workerName}}!</h4>
    <b-row>
        <!-- Блок для редагування графіку робочого часу -->
        <b-col lg="6">
            <b-card>
                <b-card-title><h4><b>{{$t('shift')}}</b></h4></b-card-title>
                <b-card-text>
                    <b-row>
                        <b-col lg="6">
                            <div>
                                <label for="exampleInputtime" class="form-label">{{$t('startShift')}}</label>
                                <input type="time" class="form-control"  v-model="form.workerOpenShift" :disabled="this.perms['2000'] != true && this.perms[703] != true">
                            </div>
                        </b-col>
                        <b-col lg="6">
                            <div>
                                <label for="exampleInputtime" class="form-label">{{$t('endShift')}}</label>
                                <input type="time" class="form-control" v-model="form.workerCloseShift" :disabled="this.perms['2000'] != true && this.perms[703] != true">
                            </div>
                        </b-col>
                    </b-row>
                    <b-button v-if="perms[2000] || perms[703]" @click="toShift()" variant="success" class="waves-effect waves-light" style="margin-top:10px"><i class="align-bottom me-1"></i>{{ $t('Edit') }}</b-button>
                </b-card-text>
            </b-card>
        </b-col>
        <!-- <b-col
        lg="6">
            <b-card>
                <b-card-title><h4><b>{{$t('workingTime')}}</b></h4></b-card-title>
                <b-card-text>
                    <p>{{$t('workthisMonth')}}</p> todo dev
                </b-card-text>
            </b-card>
        </b-col> -->
    </b-row>
    <!-- <b-row>
        <b-col>
            <b-card>
                <div class="d-flex">
                    <div class="flex-grow-1 click">
                        <h4><b>{{$t('jobDescription')}}</b></h4>
                    </div>
                    <div class="flex-shrink-0" v-if="perms['2000'] == true">
                        <div>
                            <button
                            v-if="perms['2000'] == true"
                            @click="jobDesc"
                            class="btn btn-light"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Edit"
                            >
                            <i class="ri-pencil-fill align-bottom"></i>
                            </button>
                        </div>
                    </div>
                </div>
                
                <b-card-text v-if="(jobDescription == true && form.jobdescCheck == '') || jobDescription == true " style="margin-top:15px">
                    <div class="ql-container">
                        <div class="ql-editor" v-html="form.jobDesc">
                        </div>
                    </div>
                    <b-button v-if="form.jobdescCheck == ''" @click="toConf()" variant="success" class="waves-effect waves-light"><i class="align-bottom me-1"></i>{{ $t('Familiarized') }}</b-button>
                </b-card-text>
                
            </b-card>

        </b-col>
    </b-row> -->
    <!-- выбор сингла нужно красиво переделать и + потом уже подвязать to dev -->
    <!-- <b-row>
        <b-col>
            <b-card-title><h4><b>{{$t('Music')}}</b></h4></b-card-title>
            <b-card-text>
                <Multiselect 
                v-model="this.music"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="this.listMusic"
                :placeholder="$t('Music')"
                />
                <button @click="saveMelody" class="btn btn-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"> {{ this.$t("Edit") }} </button>
            </b-card-text>    
        </b-col>    
    </b-row> -->
</template>

<script>
import { storeS } from '@/store'; // глобальне сховище даних
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default{
    props: ["obj"],
    data() {
        return {
            form: {
                workerOpenShift: "", // час початку зміни
                workerCloseShift: "" // час закінчення зміни
            },
            music: '',
            jobDescription: false,
            listMusic: [
                // Список мелодій для вибору (зазначено кілька прикладів)
                {
                    label: "forMax",
                    value: "https://drive.google.com/uc?id=1dq4v9k-DZeBFy8iCNHywHsfNUxEcvzMJ&export=play",
                },
                {
                    label: "alarm1",
                    value: "https://drive.google.com/uc?id=18ngbDZh5tcXK3JRysHA4hdiWeD9miDSD&export=play",
                },
                {
                    label: "alarm2",
                    value: "https://drive.google.com/uc?id=14BpEaftTLhwCWgqE_MN7zFCO2RrwiIJ_&export=play",
                },
                {
                    label: "tu-tu-tu",
                    value: "https://drive.google.com/uc?id=1URhqcRmZBKohiYQSDf8gviVuT_ZwtBpe&export=play",
                }
                
            ]
        };
    },
    created() {
        this.getdata();
    },
    methods: {
        saveMelody() {
            // Збереження обраної мелодії
            storeS.music = this.music;
            this.$toast.success(this.$t('alertChanged'))
        },
        getdata() {
            // Завантаження даних працівника
            this.form = this.obj;
        },
        jobDesc() {
            // Перехід до редагування опису роботи (закоментовано)
            this.$emit("jobDesc");
        },
        toConf() {
            // Перехід до підтвердження опису роботи (закоментовано)
            this.$emit("toConf");
        },
        toShift() {
            // редагування робочого часу
            this.$emit("toShift", this.form);
        },
    },
    computed: {
        user(){
            /*
                Повертає дані за користувача з глобальномго стору.
            */
            return storeS.userbase
        },
        perms() {
            /*
                Повертає дані прав доступу користувача з глобальномго стору.
            */
            return storeS.perms
        },
    },
    components: { Multiselect }
}
</script>

<style>
.click{
    cursor: pointer;
}

[data-layout-mode="dark"] .form-control::-webkit-calendar-picker-indicator {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

</style>