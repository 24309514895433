import {storeS} from './store'
import {eventB} from '@/main'

const dbPromise = {
    init() {    
        let openRequest = indexedDB.open("store", 1);

        openRequest.onupgradeneeded = function() {
            storeS.db = openRequest.result;
            if (!db.objectStoreNames.contains('data')) { // якщо сховище відсутнє
                let table = storeS.db.createObjectStore('data', {keyPath: 'id', autoIncrement: true}); // створюємо сховище

                let index1 = table.createIndex('time_idx', 'dateTime');
                let index2 = table.createIndex('сategory_idx', 'category');
                let index3 = table.createIndex('text_idx', 'text');
                
                console.log("База созданна, перезагрузка для кайфа");
                window.location.reload;
            }
            // спрацьовує, якщо на клієнті не має бази даних 
            // ініціалізуємося
        };

        openRequest.onerror = function() {
            console.error("Error", openRequest.error);
            window.location.reload;
        };

        openRequest.onsuccess = function() {
            storeS.db = openRequest.result;
            storeS.db.onversionchange = function() {
                storeS.db.close();
                alert("База данных устарела, пожалуйста, перезагрузите страницу.")
            };
            console.log('База на заебца работает')
        } 
    },
    add(data) { // додати в базу
        let transaction = storeS.db.transaction("data", "readwrite"); // (1)

        // отримати сховище об’єктів для роботи з ним
        let books = transaction.objectStore("data"); // (2)

        let request = books.add(data); // (3)

        request.onsuccess = function() { // (4)
            console.log("Запис додано в сховище", request.result);
        };

        request.onerror = function() {
            console.log("Помилка", request.error);
            
            console.log("Request", request);
        };
    },
    remove(id) { // видалити з бази по айдішнику
        
        let transaction = storeS.db.transaction("data", "readwrite"); // (1)
                        
        // получить хранилище объектов для работы с ним
        let data = transaction.objectStore("data"); // (2)

        let deleteReq = data.delete(id)
        deleteReq.onsuccess = function() {
            console.log('All okey') //видалення, щоб не було дублікатів
        }
    },
    getAll(category) { // отримати всі записи по категорії

        let transaction = storeS.db.transaction("data", "readwrite"); // (1)
                
        // отримуємо бажане сховище
        let data = transaction.objectStore("data"); // (2)
        let categoryIndex = data.index("сategory_idx");

        let request = categoryIndex.getAll(category)

        request.onsuccess = function() {
            if (request.result !== undefined && request.result.length > 0) {
                eventB.emit('haveForms', {status: true, forms: request.result})
            } else {
                console.log('Незакінченні форми не знайдені. Дякую за збереження екології, бо ви не зайняли зайвого місця на серверах Зімбабве =)')
            }
        };

        request.onerror = function() {
            alert('Щось пішло не так, перезапустіть будь ласка сторінку')
        }
    },
    get(id, category) { // отримати запис по id через категорію
        
        let transaction = storeS.db.transaction("data", "readwrite"); // (1)
                        
        // получить хранилище объектов для работы с ним
        let data = transaction.objectStore("data"); // (2)
        let categoryIndex = data.index("сategory_idx");

        let request = categoryIndex.getAll(category)

        request.onsuccess = function() {
            console.log(request.result)

            request.result.forEach(element => {
                if(element.id === id) {
                    dbPromise.remove(id)
                }
            });
        };

        request.onerror = function() {
            alert('Щось пішло не так, перезапустіть будь ласка сторінку')
        }
    },
    getAllNotes() { // отримати всі нотатки для АТС

        let transaction = storeS.db.transaction("data", "readwrite"); // (1)
                
        // отримуємо бажане сховище
        let data = transaction.objectStore("data"); // (2)
        let categoryIndex = data.index("сategory_idx");

        let request = categoryIndex.getAll('ATSNote')

        request.onsuccess = function() {
            if (request.result !== undefined && request.result.length > 0) {
                storeS.notesATS = request.result
            } else {
                console.log('Незакінченні форми не знайдені. Дякую за збереження екології, бо ви не зайняли зайвого місця на серверах Зімбабве =)')
            }
        };

        request.onerror = function() {
            alert('Щось пішло не так, перезапустіть будь ласка сторінку')
        }

    }
}

export default dbPromise