<template>
    <!-- 
        Список перерв по співробітнику
        - дозволяє переглянути список перерв
        - відкрити картку перерви
        - та інше :)
    -->
    
    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
        <!-- кнопка відображення інфо-блоків -->
        <button 
            v-if="this.checks['001'] == true" 
            @click="infoblockShow = !infoblockShow" 
            type="button" 
            :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
        >
            <i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i>
        </button>
    </div>

    <!-- 
        Інформаційні блоки з підсумком по -
        - усього перерв та на яку кількість часу
        - скільки перерв на обід та на яку кількість часу
        - скільки перерв на перекур та на яку кількість часу
    -->
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true"
        @open="open"
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
    />

    <!-- 
       Виклик відкриття картки перерви
       - для відкриття застосовується перевірка значення showModal
       - @close - подія, яка спрацьовує при закритті картки
       - :objCard - параметр-об'єкт з даними про перерву
    -->
    <viewbox 
        v-if="showModal"
        @close="showModal = false"
        :objCard="objCard"
    />
    

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewbox from './breaks/view'
import infoblocks from '@/components/info/blocks'
import dialogBox from '@/components/modal/modalEdit'
import { Breaks } from "@/API.js";
import { storeS } from '@/store';
import { mutateBreak } from '@/usabilityScripts/globalMutate.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiBreaks = new Breaks();

export default {
    props: ['id'],
    components: { tablecustom, viewbox, dialogBox, infoblocks },
    data() {
        return {
            showModal: false, // Прапорець відображення вікна перерви
            infoblockShow: false,  // прапорець відображення інфо-блоків
            objCard: {}, // Об'єкт з даними перерви для відображення в модальному вікні
            objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: false, // true - показати кнопку фільтр, false - сховати
                page: "breaks"
            },
            objParams: {  // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                search: '',
                workerId: '',
                pr1: '',
                pr2: ''
            },
            rows: [], // Дані для відображення у таблиці
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: 'ID',
                    text: "breakId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("break"),
                    text: "reason",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateBreak(item),
                },
                {
                    name: this.$t("BreakStart"),
                    text: "createDatetime",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("BreakEnd"),
                    text: "closeDatetime",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Duration"),
                    text: "durationTime",
                    align: "left",
                    status: true,
                }
            ],
            information: [
                // Дані компонента, які відображають інформацію про кількість та суму годин по перервам.
                // Кожен елемент масиву містить:
                // * title - назву інформаційного блоку,
                // * value - кількість перерв
                // * icon - клас іконки,
                // * color - колір інформаційного блоку,
                // * sum - загальну суму годин перерв 
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info"
                },
                {
                    title: this.$t('Dinner'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "secondary"
                },
                {
                    title: this.$t('smokeBreak'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger"
                },
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        searchB(e){
            /*
                Функція пошуку по таблиці
            */

            this.objParams.search = e
            this.getdata();
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        getdata(page){
            /**
                Функція для отримання даних для таблиці.
                Викликає API для отримання списку перерв з параметрами з об'єкта objParams.
                Результат присвоюється властивостям rows (список перерв) та objPages (інформація про сторінки).
            */

            this.objParams.workerId = this.id
            this.objParams.page = page != undefined ? page : '1';
            apiBreaks.getAllBreaks(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                    this.objPages = result.data

                    this.information[0].value = result.data.total;
                    this.information[0].sum = result.data.sumTime.hours + " " + this.$t('hours') + " " + result.data.sumTime.minutes + " " + this.$t('min');
                    this.information[1].value = result.data.lunchCount;
                    this.information[1].sum = result.data.sumLunchTime.hours + " " + this.$t('hours') + " " + result.data.sumLunchTime.minutes + " " + this.$t('min');
                    this.information[2].value = result.data.smokeBreakCount;
                    this.information[2].sum = result.data.sumSmokeBreakTime.hours + " " + this.$t('hours') + " " + result.data.sumSmokeBreakTime.minutes + " " + this.$t('min');
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка отримання списку перерв'; 
                    var statusRequest = result.status;
                    var api = 'getAllBreaks';
                    var fileRequest = 'src/views/users/view/formright/breaks.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }, 
        open(e){
            /**
                Функція для відкриття модального вікна з інформацією про перерву за його ідентифікатором.
                * @param {Object|String} e - Ідентифікатор перерви або об'єкт з інформацією про перерву.

                Викликає API для отримання інформації про перерву.
                Результат присвоюється властивості objCard, та встановлюється прапорці showModal в true для відображення модального вікна.
            */

            apiBreaks.getBreak(e.breakId).then(result => {
                if(result.status == 'done') {
                    this.objCard = result.data;
                    this.showModal = true;
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка відкриття картки перерви'; 
                    var statusRequest = result.status;
                    var api = 'getBreak';
                    var fileRequest = 'src/views/users/view/formright/breaks.vue';
                    var params = e.breakId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(name == 'all'){
                this.objParams.search = '',
                this.objParams.pr1 = '',
                this.objParams.pr2 = '';
                this.getdata();
            } else if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
        mutateBreak(e){
            /*
                Функція для застосування перекладу причини перерви
            */

            return mutateBreak(e)
        },
    },
    computed: {
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
    }
}
</script>