<template>
    <div style="display: none;" id="print"> 
    <div style="margin:35px">
               <div class="text-center" v-if="this.form.provider == 'FOP'">
                   <span style="font-size: 8pt; width: 100%; display: block; text-align: center;">Увага! Оплата даного рахунку означає згоду з умовами поставки товарів. Повідомлення про оплату є обов'язковим. <br />Рахунок дійсний 1 робочий день.</span>
               </div>
               <div class="text-center" v-if="this.form.provider == 'TOV'">
                    <span style="font-size: 8pt; width: 100%; display: block; text-align: center;">
                        Увага! Оплата цього рахунку означає погодження з умовами поставки товарів. Повідомлення про оплату є
                        обов'язковим, в іншому випадку не гарантується наявність товарів на складі. Товар відпускається за фактом
                        надходження коштів на р/р Постачальника самовивозом, за наявності довіреності та паспорта. <br />Рахунок дійсний 1 робочий день.
                    </span>
               </div>
               <br />
               <!-- <div class="text-center">
                   <label style="font-size: 10pt;font-weight: bold;">Зразок заповнення платіжного доручення</label>
               </div> -->
               <div style="border:1px solid; padding: 30px 1px;">
                       <table width="100%" style="font-family: Arial;">
                           <tr>
                               <td style="width: 10mm; vertical-align: top;">
                                   <div style=" padding-left:2px; ">Отримувач</div>
                               </td>
                               <td>
                                   <div style="font-weight:bold;padding-left:2px;" v-if="form.date >= '2023-11-28'">{{ provider.providerTitle }}</div>
                                   <div style="font-weight:bold;padding-left:2px;" v-if="form.date < '2023-11-27' && form.provider == 'FOP'">{{ settingInv.FOP.provider }}</div>
                                   <div style="font-weight:bold;padding-left:2px;" v-if="form.date < '2023-11-27' && form.provider == 'TOV'">{{ settingInv.TOV.provider }}</div>
                               </td>
                           </tr>
                           <tr>
                                <td style="width: 10mm; vertical-align: top;">
                                    <div style=" padding-left:2px;">Код</div>
                                </td>
                                <td>
                                    <template v-if="form.date >= '2023-11-28'">
                                        <div style="font-weight:bold;padding-left:2px; "><span style="border: 1px solid;padding:1px 10px">{{ provider.erdpu }}</span></div>
                                    </template>
                                    <template v-else >
                                        <div style="font-weight:bold;padding-left:2px; " v-if="this.form.provider == 'TOV'"><span style="border: 1px solid;padding:1px 10px">{{ settingInv.TOV.erdpu }}</span></div>
                                        <div style="font-weight:bold;padding-left:2px; " v-else><span style="border: 1px solid;padding:1px 10px">{{ settingInv.FOP.erdpu }}</span></div>
                                    </template>
                                </td>
                           </tr>
                           <tr>
                               <td style="width: 10mm; vertical-align: top;">
                                   <div style=" padding-left:2px;">Банк отримувач</div>
                               </td>
                               <td style="width: 10mm; vertical-align: top;text-align: center;" v-if="form.provider == 'FOP'">
                                   <div style=" padding-left:2px;"></div>
                               </td>
                               <td style="width: 10mm; vertical-align: top; text-align: center;">
                                   <div style=" padding-left:2px;">КРЕДИТ рах. No</div>
                               </td>
                           </tr>
                           <tr>
                                <td style="width: 10mm; vertical-align: top; border-bottom: 2px solid;" >
                                    <template v-if="form.date >= '2023-11-28'">
                                        <div style=" padding-left:2px;" ><b>{{ provider.bank }}</b></div>
                                    </template>
                                    <template v-else >
                                        <div style=" padding-left:2px;" v-if="this.form.provider == 'TOV'"><b>АТ КБ «ПриватБанк»</b></div>
                                        <div style=" padding-left:2px;" v-else><b>ПАО КБ "Приватбанк"</b></div>
                                    </template>
                                </td>
                                <td style="width: 10mm; vertical-align: top;text-align: center;" v-if="this.form.provider == 'FOP'">
                                    <template v-if="form.date >= '2023-11-28'">
                                        <div style=" padding-left:2px;" ><b><span style="border: 1px solid;padding:1px 10px">{{ provider.mfo }}</span></b></div>
                                    </template>
                                    <template v-else >
                                        <div style=" padding-left:2px;" ><b><span style="border: 1px solid;padding:1px 10px">{{ settingInv.FOP.mfi }}</span></b></div>
                                    </template>
                                </td>
                                <td style="width: 10mm; vertical-align: top;text-align: center;" >
                                    <template v-if="form.date >= '2023-11-28'">
                                        <div style=" padding-left:2px;" v-if="this.form.provider == 'TOV'"><b><span style="border: 1px solid;padding:1px 10px">{{ provider.iban }}</span></b></div>
                                        <div style=" padding-left:2px;" v-else><b><span style="border: 1px solid;padding:1px 10px">{{ provider.iban }}</span></b></div>
                                    </template>
                                    <template v-else >
                                        <div style="padding-left:2px;" v-if="this.form.provider == 'TOV'"><b><span style="border: 1px solid;padding:1px 10px">{{ this.settingInv['FOP']['iban'] }}</span></b></div>
                                        <div style="padding-left:2px;" v-else><b><span style="border: 1px solid;padding:1px 10px">{{ this.settingInv['FOP']['calculatedWallet'] }}</span></b></div>
                                    </template>
                                </td>
                            </tr>
                           
                       </table>
               </div>
               <br /><br />
               <!-- <div style="font-weight: bold; font-size: 14pt; padding-left:5px; border-bottom: 3px solid;">Рахунок на оплату № {{ this.toNum() }} {{ this.toDate() }}</div> -->
               <br />
               <div>
                    <div style="padding-left:10px; margin-bottom: 5px; font-weight: bold;"> 
                        <template v-if="form.date >= '2023-11-28'">
                            Рахунок на оплату № {{ form.num.id }} {{ this.toDate() }}
                        </template>
                        <template v-else >
                            Рахунок на оплату № {{ this.form.provider == 'TOV' ? this.form.numTOV.id : this.form.numFOP.id }} {{ this.toDate() }}
                        </template>
                    </div>
                    <table width="100%" style="font-family: Arial; border-top: 2px solid;">
                           <tr>
                                <td style="width: 30mm; vertical-align: top;">
                                    <div style=" padding-left:2px; ">Постачальник:</div>
                                </td>

                                <template v-if="form.date >= '2023-11-28'">
                                    <td v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">
                                        <div style="font-weight:bold;padding-left:2px;"> {{ provider.providerTitle }}
                                            <span style="font-weight: normal;">
                                                <br />IBAN: {{ provider.iban }} в {{ provider.bank }}, {{ provider.address }} 
                                                <br />Тел. {{ provider.phone }} 
                                                <br />Код ЄДРПОУ {{ provider.erdpu }}, ІПН {{ provider.ipn }}
                                            </span>
                                        </div>
                                    </td>
                                    <td v-if="provider.businessEntity == 'FOP' || (provider.businessEntity == 'TOV' && provider.workWithPDV == 'false')">
                                        <div style="font-weight:bold;padding-left:2px;"> {{ provider.providerTitle }}
                                            <span style="font-weight: normal;">
                                                <br />Р/р {{ provider.iban }}, {{ provider.bank }}, МФО {{ provider.mfo }} код по ЄРДПОУ {{ provider.erdpu }}
                                            </span>
                                        </div>
                                    </td>
                                </template>
                                <template v-else>
                                    <td v-if="form.provider == 'TOV' || form.provider == 'tov'">
                                        <div style="font-weight:bold;padding-left:2px;">{{ settingInv.TOV.provider }}
                                            <span style="font-weight: normal;">
                                                <br />IBAN: {{ settingInv.TOV.iban }} в {{ settingInv.TOV.bank }}, {{ settingInv.TOV.address }}
                                                <br />Тел. {{ settingInv.TOV.phone }} 
                                                <br />Код ЄДРПОУ {{ settingInv.TOV.erdpu }}, ІПН {{ settingInv.TOV.ipn }} 
                                            </span>
                                        </div>
                                    </td>
                                    <td v-if="form.provider == 'FOP' || form.provider == 'fop' ">
                                        <div style="font-weight:bold;padding-left:2px;">{{ settingInv.FOP.provider }}
                                        <span style="font-weight: normal;">
                                        <br />Р/р {{ settingInv.FOP.calculatedWallet }}, {{ settingInv.FOP.bank }}, МФО {{ settingInv.FOP.mfi }} код по ЄРДПОУ {{ settingInv.FOP.erdpu }}</span></div>
                                    </td>
                                </template>


                               <!-- <td v-if="this.form.provider == 'TOV'">
                                   <div style="font-weight:bold;padding-left:2px;">{{ settingInv.TOV.provider }}
                                   <span style="font-weight: normal;">
                                       <br />IBAN: {{ settingInv.TOV.iban }} в {{ settingInv.TOV.bank }}, {{ settingInv.TOV.address }}
                                       <br />Тел. {{ settingInv.TOV.phone }} 
                                       <br />Код ЄДРПОУ {{ settingInv.TOV.erdpu }}, ІПН {{ settingInv.TOV.ipn }} </span></div>
                               </td>
                               <td v-else>
                                   <div style="font-weight:bold;padding-left:2px;">{{ settingInv.FOP.provider }}
                                   <span style="font-weight: normal;">
                                   <br />Р/р {{ settingInv.FOP.calculatedWallet }}, {{ settingInv.FOP.bank }}, МФО {{ settingInv.FOP.mfi }} код по ЄРДПОУ {{ settingInv.FOP.erdpu }}</span></div>
                               </td> -->
                            </tr>
                            <tr>
                                <td style="width: 30mm; vertical-align: top;">
                                    <div style=" padding-left:2px; ">Покупець:</div>
                                </td>
                                <td>
                                    <div style="font-weight:bold;padding-left:2px;">{{ this.form.sellerName }}
                                    <span style="font-weight: normal;">
                                        <br />{{this.form.economicalSociety}} </span></div>
                                </td>
                            </tr>
                           <!-- <tr>
                               <td style="width: 30mm; vertical-align: top;">
                                   <div style=" padding-left:2px; ">Покупець: <b>{{this.form.sellerName}}</b></div>
                               </td>
                               <td>
                                   <div style="font-weight:bold;  padding-left:2px;">
                                   <span style="font-weight: normal;" >
                                   <br />{{this.form.businessEntity}}</span></div>
                               </td>
                           </tr> -->
                           <tr>
                               <td style="width: 30mm; vertical-align: top;">
                                   <div style=" padding-left:2px; ">Договір:</div>
                               </td>
                               <td>
                                   <div style="font-weight:bold;  padding-left:2px;">Рахунок</div>
                               </td>
                           </tr>
                       </table>
               </div>
               <div>
                   <table border="2" width="100%" cellpadding="2" cellspacing="2" style="border-collapse: collapse; width: 100%; font-family: Arial;">
                       <thead style="border: 1px solid;">
                       <tr class="head-table">
                            <th style="border: 1px solid;">№</th>
                            <th style="border: 1px solid;">{{ $t('Goods') }}</th>
                            <th style="border: 1px solid;">{{ $t('Quantity') }}</th>
                            <th style="border: 1px solid;">{{ $t('unit') }}</th>

                            <template v-if="form.date >= '2023-11-28'">
                                <th style="border: 1px solid;">{{ provider.businessEntity == 'TOV' && provider.workWithPDV == 'true' ? 'Ціна без ПДВ' : 'Ціна' }}</th>
                                <th style="border: 1px solid;">{{ provider.businessEntity == 'TOV' && provider.workWithPDV == 'true' ? 'Сума без ПДВ' : 'Сума'}}</th>
                                <th style="border: 1px solid;" v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">Сума ПДВ:</th>
                            </template>
                            <template v-else>
                                <th style="border: 1px solid;">{{ form.provider == 'TOV' && provider ? 'Ціна без ПДВ' : 'Ціна' }}</th>
                                <th style="border: 1px solid;">{{ form.provider == 'TOV' ? 'Сума без ПДВ' : 'Сума'}}</th>
                                <th style="border: 1px solid;" v-if="form.provider == 'TOV'">Сума ПДВ:</th>
                            </template>
                          
                        </tr>
                        </thead>
                        <tbody >
                            <tr v-for="(item, index) in form.products" :key="index">
                                <th class="text-center" style="border: 1px solid;">{{index+1}}</th>
                                <td style="border: 1px solid;">{{item.nomenclatureName}}</td>
                                <td class="text-center" style="border: 1px solid;">{{item.quantity}}</td>
                                <td class="text-center" style="border: 1px solid;">шт.</td>
                                <td class="text-center" style="border: 1px solid;">{{Number(item.priceWithoutVAT).toFixed(2)}}</td>
                                <td class="text-center" style="border: 1px solid;">{{Number(item.sumWithoutVAT).toFixed(2)}}</td>

                                <template v-if="form.date >= '2023-11-28'">
                                    <td class="text-center" style="border: 1px solid;" v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">{{Number(item.VATsum).toFixed(2)}}</td>
                                </template>
                                <template v-else>
                                    <td class="text-center" style="border: 1px solid;" v-if="form.provider == 'TOV'">{{Number(item.VATsum).toFixed(2)}}</td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <div>
                   <table style="font-family: Arial;" border="0" width="100%" cellpadding="1" cellspacing="1">
                        <template v-if="form.date >= '2023-11-28'">
                            <tr v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">
                                <td></td>
                                <td style="width:27mm; font-weight:bold;  text-align:right;">Усього без ПДВ:</td>
                                <td style="width:27mm; font-weight:bold;  text-align: center; ">{{(form.sum).toFixed(2)}} грн.</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr v-if="form.provider == 'TOV'">
                                <td></td>
                                <td style="width:27mm; font-weight:bold;  text-align:right;">Усього без ПДВ:</td>
                                <td style="width:27mm; font-weight:bold;  text-align: center; ">{{(form.sum).toFixed(2)}} грн.</td>
                            </tr>
                        </template>
                        
                        <template v-if="form.date >= '2023-11-28'">
                            <tr v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">
                                <td></td>
                                <td style="width:27mm; font-weight:bold;  text-align:right;">Сума ПДВ:</td>
                                <td style="width:27mm; font-weight:bold;  text-align: center; ">{{(Number(form.sum) * 0.2).toFixed(2)}} грн.</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr v-if="form.provider == 'TOV'">
                                <td></td>
                                <td style="width:27mm; font-weight:bold;  text-align:right;">Сума ПДВ:</td>
                                <td style="width:27mm; font-weight:bold;  text-align: center; ">{{(Number(form.sum) * 0.2).toFixed(2)}} грн.</td>
                            </tr>
                        </template>
                        <tr>
                            <td></td>
                            <template v-if="form.date >= '2023-11-28'">
                                <td style="width:37mm; font-weight:bold;  text-align:right;">{{ provider.businessEntity == 'TOV' && provider.workWithPDV == 'true' ? 'Усього з ПДВ' : 'Усього'  }}:</td>
                                <td v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'" style="width:27mm; font-weight:bold;  text-align: center; ">{{(form.sum+form.sum*0.2).toFixed(2)}} грн.</td>
                                <td v-else style="width:27mm; font-weight:bold;  text-align: center; ">{{(form.sum).toFixed(2)}} грн.</td>
                            </template>
                            <template v-else>
                                <td style="width:37mm; font-weight:bold;  text-align:right;">{{ form.provider == 'TOV' ? 'Усього з ПДВ' : 'Усього'  }}:</td>
                                <td v-if="form.provider == 'TOV'" style="width:27mm; font-weight:bold;  text-align: center; ">{{(form.sum+form.sum*0.2).toFixed(2)}} грн.</td>
                                <td v-else style="width:27mm; font-weight:bold;  text-align: center; ">{{(form.sum).toFixed(2)}} грн.</td>
                            </template>
                        </tr>
                   </table>
                </div>
                <br />
                <div style="font-family: Arial;">
                Всього найменувань {{form.products.length}}, на суму {{(form.sum).toFixed(2)}} грн.<br /> 
                <label class="sum" style="font-weight: bold;">{{this.ucFirst(number_to_string((this.form.sum).toFixed(2)))}}</label>
                    <!-- <label class="sum">{{this.ucFirst(number_to_string((this.form.sum).toFixed(2)))}}</label> -->
                    <br />
                <template v-if="form.date >= '2023-11-28'">
                    <b v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">У т.ч. ПДВ: <label class="sum">{{number_to_string((this.form.sum*1.2).toFixed(2))}}</label></b>
                </template>
                <template v-else>
                    <b v-if="form.provider == 'TOV'">У т.ч. ПДВ: <label class="sum">{{number_to_string((this.form.sum*1.2).toFixed(2))}}</label></b>
                </template>
                
                </div>
                <div style="border-bottom: 2px solid; width:100%;">&nbsp;</div><br />
                <div>
                    <table style="font-family: Arial;" border="0" width="100%" cellpadding="1" cellspacing="1">
                        <tr>
                            <td></td>
                            <td style="width:27mm; font-weight:bold;  text-align:right;">Виписав(ла):</td>
                            <td style="width:27mm; font-weight:bold;  text-align: center; ">    ________________________</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
</template>

<script>
import { storeS } from '@/store'

export default ({
   props: ['obj'],
   data() {
       return{
           form: "",
           m:"8",
           provider: ""
       }
   },
   created(){
       this.form = this.obj
       this.getDataProvider()
   },
   methods:{
       // toNum(){
       //     var s = (this.form.num).split("_")[1].substr(0,5);
       //     return s
       // },
       toDate(){
           var newFullDate = new Date(this.form.datetime);
           var newYear = newFullDate.getFullYear();
           var newMonth = newFullDate.getMonth();
           var newDate = newFullDate.getDate();
           var result = "від " + newDate + " " + this.getdata(newMonth) + " " + newYear + " р.";
           return result
       },
       getdata(m){
           var arr=[
           'січня',
           'лютого',
           'березня',
           'квітня',
           'травня',
           'червня',
           'липня',
           'серпня',
           'вересня',
           'жовтня',
           'листопада',
           'грудня',
           ];
           return arr[m]
       },
       number_to_string(num){
           var def_translite = {
               null: 'нуль',
               a1: ['один','дві','три','чотири',"п'ять",'шість','сім','вісім',"дев'ять"],
               a2: ['одна','дві','три','чотири',"п'ять",'шість','сім','вісім',"дев'ять"],
               a10: ['десять','одинадцять','дванадцять','тринадцять','чотирнадцять',"п'ятнадцять",'шістнадцять','сімнадцять','вісімнадцять',"дев'ятнадцять"],
               a20: ['двадцять','тридцять','сорок',"п'ятдесят",'шістдесят','сімдесят','вісімдесят',"дев'яносто"],
               a100: ['сто','двісті','триста','чотириста',"п'ятсот",'шістсот','сімсот','вісімсот',"дев'ятсот"],
               uc: ['копійка', 'копійки', 'копійок'],
               ur: ['гривня', 'гривні', 'гривень'],
               u3: ['тисяча', 'тисячі', 'тисяч'],
               u2: ['мільйон', 'мільйона', 'мільйонів'],
               u1: ['мільярд', 'мільярда', 'мільярдів'],
           }
           var i1, i2, i3, kop, out, uah, v, zeros, _ref, _ref1, _ref2, ax;
           _ref = parseFloat(num).toFixed(2).split('.'), uah = _ref[0], kop = _ref[1];
           var leading_zeros = 12 - uah.length;
           if (leading_zeros < 0) {
               return false;
           }
           zeros = [];
           while (leading_zeros--) {
               zeros.push('0');
           }

           uah = zeros.join('') + uah;
           out = [];
           if (uah > 0) {
               // Разбиваем число по три символа
               _ref1 = this.str_split(uah, 3);
               for (var i = -1; i < _ref1.length;i++) {
                   v = _ref1[i];
                   if (!(v > 0)) continue;
                   _ref2 = this.str_split(v, 1), i1 = parseInt(_ref2[0]), i2 = parseInt(_ref2[1]), i3 = parseInt(_ref2[2]);
                   out.push(def_translite.a100[i1-1]); // 1xx-9xx
                   ax = (i+1 == 3) ? 'a2' : 'a1';
                   if (i2 > 1) {
                       out.push(def_translite.a20[i2-2] + (i3 > 0 ?  ' ' + def_translite[ax][i3-1] : '')); // 20-99
                   } else {
                       out.push(i2 > 0 ? def_translite.a10[i3] : def_translite[ax][i3-1]); // 10-19 | 1-9
                   }
                   
                   if (_ref1.length > i+1){
                       var name = def_translite['u'+(i+1)]; 
                       out.push(this.morph(v,name));
                   }
               }
           } else {
               out.push(def_translite.null);
           }
           // Дописываем название "грн"
           out[1] = this.ucFirst(out[1]);
           out.push(this.morph(uah, def_translite.ur));
           // Дописываем название "копейка"
           out.push(kop + ' ' + this.morph(kop, def_translite.uc));
           // Объединяем маcсив в строку, удаляем лишние пробелы и возвращаем результат
           return out.join(' ').replace(RegExp(' {2,}', 'g'), ' ').trimLeft();
        },
        ucFirst(str) {
            if (!str) return str;

            return str[0].toUpperCase() + str.slice(1);
        },
        morph(number, titles){
            var cases = [2, 0, 1, 1, 1, 2];
            return titles[(number%100>4 && number%100<20) ? 2 : cases[Math.min(number%10, 5)]]
        },
        str_split(string, length){
           var chunks, len, pos;
   
           string = (string == null) ? "" : string;
           length =  (length == null) ? 1 : length;
           
           chunks = [];
           pos = 0;
           len = string.length;
           while (pos < len) {
               chunks.push(string.slice(pos, pos += length));
           }
           
           return chunks;
        },
        getDataProvider(){
            this.$nextTick(() => {
                this.providers.forEach(element => {
                    if (element.providerId == this.form.providerId) {
                        this.provider = element
                    }
                });
            });
        }
   },
    computed: {
        settingInv(){
            return storeS.invoice
        },
        providers() {
            return storeS.providers
        }
    }
})
</script>

<style scoped>
/* #print {
   width: 878px;
   margin: 35px;
   font-size: 17px;
} */
span, p, label {
    font-size: 16px !important
}

.head-table th {
   text-align: center;
   background: #b9b9b96b;
}
label.sum{
   font-weight: bold;
   /* text-transform: lowercase; */
   margin-bottom: 0px;
}
/* label.sum::first-letter{
   text-transform: capitalize;
} */
</style>