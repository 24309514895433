<template>
    <!-- Вікно підтвердження рахунку -->

    <dialogBox >
        <template v-slot:title>{{ $t('confirmation') }}</template>
        <template v-slot:body>
            <!-- Швидкі коментарі -->
            <div  class="mb-3">
                <label class="form-label mb-2">{{ $t('QuickComments') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('ConfirmedL')" style="margin-right: 5px;">{{ $t('ConfirmedL') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('+')" style="margin-right: 5px;">+</span>
                </div>
            </div>
            <!-- Комантар підтвердження -->
            <div class="mb-3">
                <label  class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" :placeholder="$t('enterComment')"></textarea>
            </div>
        </template>

        <template v-slot:footer-bottom>
            <!-- 
                Кнопка "Скасувати" 
                - закриває вікно
            -->
            <button 
                class="btn link-danger fw-medium"  
                @click="this.$emit('close')"
            >
                <i class="ri-close-line me-1 align-middle"></i> 
                {{ $t('cancel') }} 
            </button>

            <!-- 
                Кнопка "Додати" 
                - підтверджує рахунок та додає коментар (якщо вказали)
            -->
            <button 
                type="button" 
                class="btn btn-success" 
                @click="toConfirm"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog' // Компонент модального вікна
import { Invoices } from '@/API.js'; // клас для взаємодії з API 
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Invoices();

export default{
    props: ['obj'],
    components: {
        dialogBox
    },
    data(){
        return {
            form: {
                comment: "", // коментар
            },
        }
    },
    created(){
    },
    methods: {
        toConfirm(){
            /**
                Функція для підтвердження рахунку
                * @param {number} this.obj.documentId - Ідентифікатор рахунку.
                * @param {form} e - об'єкт з коментарем.

                Викликає API для підтвердження рахунку.
            */

            apiServe.confirmSale(this.obj.documentId, this.form).then(result =>{
                if(result.status == 'done'){
                    this.$toast.success(this.$t('AccountSuccessfullyConfirmed'));
                    this.$emit('close')
                } else if(result.status == 'error'){
                    this.$toast.error(this.$t('saleHasAlreadyBeenConfirmed'));

                    var tempObj = {
                        "documentId": this.obj.documentId,
                        "params": this.form
                    };

                    var errorText = 'Помилка підтвердження рахунку'; 
                    var statusRequest = result.status;
                    var api = 'confirmSale';
                    var fileRequest = 'src/views/invoices/actions/confirm.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        }
    }
}
</script>