<template>
    <!-- 
        Модальне вікно відмови запиту відгулу
    -->

    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>
            <!-- коментар до відмови -->
            <div class="mb-3">
                <label for="VertimeassageInput" class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>

            <!-- 
                Кнопка "Скасувати" 
                - закриває вікно
            -->
            <button 
                class="btn link-success fw-medium"  
                @click="this.$emit('close')"
            >
                <i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} 
            </button>

            <!-- 
                Кнопка "Додати" 
                - відмова на запит відгулу та додає коментар (якщо вказали)
            -->
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="toCancel"
            >
                {{ $t('Add') }}
            </button>

        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент вікна

export default{
    components: {
        dialogBox
    },
    props: ['id'],
    data(){
        return{
            form: {
                comment: ''
            }
        }
    },
    methods: {
        toCancel(){
            // звертаємось до методу відмови з API
            // закриваємо вікно

            this.$emit('toCancel', this.id, this.form)
            this.$emit('close')
        }
    }
}
</script>