<template>
    <!-- <div class="card">
        <div class="card-body">
            <h6 class="card-title mb-3 flex-grow-1 text-start"><b>Последний комментарий</b></h6>
            <div class="alert alert-info" role="alert">
                {{ filteredItems[filteredItems.length-1].text }}
            </div>
        </div>
    </div> -->

    <b-row v-if="showComment">
      <b-col>
        <comment :obj="this.sklad" :client="this.client" :number="this.actualNumber"  :specialId="this.requestId ? this.requestId : this.specialId" :companyId="(this.company?.companyId ? this.company.companyId : '')" @getdata="this.getComments()"/>
      </b-col>
    </b-row>

    <h3 class="fw-semibold">Нові коментарі</h3>
    <!-- компонент таблицы -->
    <tablecustom 
        
        @search="searchB" 
        :dataParams="dataParamsNew"
        :objParams="objParams"
        :columns="newColumns" 
        :rows="newRows" 
        :pages="objPages"
        :showpag="true" 
        :tabs="othertabs"
        @getdata="getComments"
        @changelimit="changelimit"
        @changeTab="changeTab"
        @changeFilter="changeFilter"
    />

    <h3 class="fw-semibold">Старі коментарі</h3>
    <!-- компонент таблицы -->
    <tablecustom 
        v-if="this.obj != null || this.form != null" 
        @search="searchB" 
        :columns="columns"
        :rows="filteredItems" 
        @sortBy="sortBy"
        @create="showComment = !showComment" 
        @open="open" 
        @getdata="getComments"
    />

    <viewbox v-if="showModal" @close="showModal = false" :obj="cardApplic"  @remove="remove"></viewbox>
</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewbox from '@/views/invoices/view.vue'
import comment from './comment.vue'
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { Invoices, Clients } from '@/API.js';
import { storeS } from '@/store';

let apiServe = new Invoices();
let apiClients = new Clients();

export default {
    props: ['obj', 'sklad', 'number', 'client', 'company', 'requestId', 'specialId'],
    components: { tablecustom, viewbox, comment },
    data() {
        return {
            objPages: {},
            dataParams: {
                status: false,
                page: "comments"
            },
            dataParamsNew: {
                status: false,
                page: "commentsNew"
            },
            othertabs: [
                {
                    title: this.$t('companyComm'),
                    tab: "comment",
                    color: "#198754",
                    obj: "commentForCompany",
                    value: "company",
                    checked: true
                },
            ],
            objParams:{
                page: '1',
                pagelimit: '25',
                // search: '',
                company: '',
            },
            newRows: [],
            newColumns: [
                {
                    name: 'ID',
                    text: "commentId",
                    align: "left",
                    status: false,
                    copy: true,
                    width: "25",
                    needFixed: true
                },
                {
                    name: this.$t("Comments"),
                    text: "text",
                    align: "left",
                    status: true,
                    copy: true,
                    width: "25" ,
                    needFixed: true
                },
                {
                    name: this.$t("Manager"),
                    text: "workerId",
                    align: "left",
                    status: true,
                    copy: true,
                    width: "25",
                    mutate: (item) => this.nameWorker(item),
                    needFixed: true
                },
                {
                    name: this.$t("date_time"),
                    text: "date",
                    align: "right",
                    status: true,
                    copy: false,
                    width: "25",
                    mutate: (item, obj) => obj.date + " " + obj.time,
                    needFixed: true
                },
            ],
            form: {},
            showComment: true,
            search: "",
            cardApplic: "",
            showModal: false,
            sort: "Asc",
            actualNumber: '',
            columns: [
                {
                    name: this.$t("Comments"),
                    text: "text",
                    align: "left",
                    status: true,
                    copy: true,
                    width: "33",
                    needFixed: true
                },
                {
                    name: this.$t("Manager"),
                    text: "workerId",
                    align: "left",
                    status: true,
                    copy: true,
                    width: "33",
                    needFixed: true,
                    mutate: (item) => this.nameWorker(item),
                },
                {
                    name: this.$t("date_time"),
                    text: "date",
                    align: "right",
                    status: true,
                    copy: false,
                    width: "33",
                    mutate: (item, obj) => obj.date + " " +obj.time,
                    needFixed: true
                },
                // fix all
          ],
        }
    },
    created(){
        if(this.obj != undefined) {
            this.getdata()
            this.form = this.obj.reverse();
            this.getComments();
        } else {
            this.getdata()  
            this.getCommentsComp()
            this.getComments();
        }
    
        
    },  
    methods: {
        changeFilter(e) {
            this.objParams.company = ''
            this.getComments()
        },
        changeTab(e) {
            console.log('this.company.companyId', this.company.companyId);
            this.objParams.company = this.company.companyId
            this.getComments()
        },
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getComments();
        },
        getCommentsComp() {
            apiClients.getCommentsWithoutComp(this.client.accountId).then(result => {
                if(result.status == "done"){
                    this.form = result.data.items
                }
            })
        },
        getdata(value){
            this.actualNumber = this.number
            if(value != undefined){
                this.form = value;
            }
        },
        getComments(page){
            this.objParams.page = page != undefined ? page : '1';
            apiClients.getComments(this.number, this.objParams).then(result => {
                if(result.status == 'done'){
                    this.newRows = result.data.items
                    this.objPages = result.data;
                }
            })
        },
        open(e){
            var text = []
            var id_inv;
            text = e.text.split(' ');
            text[0] == 'Виставлений' ? id_inv = text[2] : id_inv = '-';
            if(id_inv != '-'){
                apiServe.getInv(id_inv).then(res => {
                    this.cardApplic = res;
                    this.showModal = true;
                })
            }
        },
        searchB(e){
            this.search = e;
            this.getComments();
        },
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getComments();
        },
        create(){
            this.$emit('openblockComment')
        },
        sortBy(prop) {
            if (this.sort === "Asc") {
                this.form.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
                this.sort = "Desc";
            } else if (this.sort === "Desc") {
                this.form.sort((a, b) => (a[prop] > b[prop] ? -1 : 1));
                this.sort = "Asc";
            }
        },
        nameWorker(item){
            return nameWorker(item)
        }
    },
    watch:{
        obj(value, oldValue){
            this.getdata(value)
        }
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.text.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.user.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        workers(){
            return storeS.workers
        }
    }
}
</script>