<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label for="VertimeassageInput" class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium"  @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="toCancel"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'

export default{
    components: {
        dialogBox
    },
    props: ['id'],
    data(){
        return{
            form: {
                comment: ''
            }
        }
    },
    methods: {
        toCancel(){
            this.$emit('toCancel', this.id, this.form)
            this.$emit('close')
        }
    }
}
</script>