<template>
    <!-- Блок preloader (показується під час завантаження) -->
    <transition name="fade">
      <!-- Блок preloader з ідентифікатором "preloader" -->
      <div id="preloader">
        <!-- Блок статусу завантаження з ідентифікатором "status" -->
        <div id="status">
          <!-- Анімація завантаження (spinner) -->
          <div class="spinner-border text-primary avatar-sm" role="status">
            <!-- Текст для екранів читачів (для людей з вадами зору) -->
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </transition>
</template>

<script>
export default {
  // Назва компонента
  name: "preloader"
}
</script>

<style>
    .preloader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 9999;
    }
    .circles {
        display: flex;
    }
    .circle {
        width: 1.5rem;
        height: 1.5rem;
        margin: 1rem;
        background-color: blue;
        border-radius: 50%;
        animation-name: scaleIn;
        animation-iteration-count: infinite;
        animation-duration: 1s;
        transform: scale(0);
    }
    .circle:nth-child(1) {
        animation-delay: 0s;
    }
    .circle:nth-child(2) {
        animation-delay: 0.2s;
    }
    .circle:nth-child(3) {
        animation-delay: 0.4s;
    }
    .circle:nth-child(4) {
        animation-delay: 0.6s;
    }
    .circle:nth-child(5) {
        animation-delay: 0.8;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    } 
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    @keyframes scaleIn {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }  
</style>