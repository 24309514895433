<template>
    <modallg :title="'Визитка'" @close="this.$emit('close')">
        <template v-slot:body>
            <template v-if="obj == 'cutaway'">
                <b-card>
                    <div class="text-center">
                        <img src="@/assets/images/tempMail/cutaway.png" class="img-fluid" alt="Responsive image">
                    </div>
                </b-card>
            </template>
        </template>
    </modallg>
</template>

<script >
import modallg from '@/components/modal/modal-lg'
import cutaway from './cutaway'

export default ({
    props: ['obj'],
    components: {
        modallg,
        cutaway
    },
    data(){
        return{
            form: ''
        }
    }
})

</script>