<template>
    <!-- <modal :title="this.$t(this.mutateType(form.type)) + ' - ' + form.name"> -->
        <!-- <template v-slot:body> -->
            <b-row>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>ID</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <div class="input-group">
                                <input type="text" class="form-control" :disabled="this.editform == false" v-model="form.deviceId">
                                <span class="input-group-text">ID</span>
                            </div>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('outlet')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <div class="input-group">
                                <input type="text" class="form-control" :disabled="this.editform == false" v-model="form.namett">
                            </div>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <h4><b>{{$t('main')}}</b></h4>
            <hr />
            <b-row>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('Point_name')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.deviceTitle">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('type')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <select
                                class="form-select mb-3"
                                v-model="form.type"
                                :disabled="this.editform == false"
                                >
                                <option v-for="item in types" :key="item" :value="item.value">{{ item.title }}</option>
                            </select>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <h4><b>{{$t('connectionDevice')}}</b></h4>
            <hr />
            <b-row>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('manufacturer')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.manufacturer">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('bank_terminal_model')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.model">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('operating_system')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <select
                                class="form-select mb-3"
                                v-model="form.os"
                                :disabled="this.editform == false"
                                >
                                <option v-for="item in os" :key="item" :value="item.value">{{ item.title }}</option>
                            </select>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('connection_method')}} / {{$t('protocol_connect')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <h5>{{this.$t(this.mutateProtocol(form.protocol))}}</h5>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.protocol">
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>

            <template v-if="form.protocol == 'uaprro' && form.data.fiscalSettings.hasOwnProperty('prroSettings')">
                <h4><b>{{$t('PRROinSFS')}}</b></h4>
                <hr />
                <b-row>
                    <b-col>
                        <div>
                            <div class="info-section" >
                                <span >
                                    <table >
                                        <tbody style="font-size: 14px;">
                                            <tr >
                                                <td style="color: gray;">{{$t('NametheOrganization')}}:</td>
                                                <td style="font-weight: bold;">{{ form.data.fiscalSettings.prroSettings.ORGNM }}</td>
                                            </tr>
                                            <tr >
                                                <td style="color: gray;">{{$t('address')}}:</td>
                                                <td style="font-weight: bold;">{{ form.data.fiscalSettings.prroSettings.POINTADDR }}</td>
                                            </tr>
                                            <tr >
                                                <td style="color: gray;">{{$t('SequenceNumber')}}:</td>
                                                <td style="font-weight: bold;">{{ form.data.fiscalSettings.prroSettings.CASHDESKNUM }}</td>
                                            </tr>
                                            <tr >
                                                <td style="color: gray;">{{$t('FiscalNumber')}}:</td>
                                                <td style="color: green; font-weight: bold;">{{ form.data.fiscalSettings.prroSettings.CASHREGISTERNUM }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                            <br />
                        </div>
                    </b-col>
                </b-row>
            </template>

            <template v-if="form.protocol == 'uaprro' && form.data.printerData != ''">
                <h4><b>{{$t('settingConnectPrint')}}</b></h4>
                <hr />
                <b-row>
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('type')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <select
                                    class="form-select mb-3"
                                    v-model="form.data.connectionParams.deviceType"
                                    :disabled="this.editform == false"
                                    >
                                    <option v-for="item in types" :key="item" :value="item.value">{{ item.title }}</option>
                                </select>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col
                    v-if="form.data.connectionParams.deviceType != ''"
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('manufacturer')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.connectionParams.printerManufacturer">
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col
                    v-if="form.data.connectionParams.deviceType != ''"
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('bank_terminal_model')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.connectionParams.printerModel">
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col
                    v-if="form.data.connectionParams.deviceType != ''"
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('connection_method')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <h5> - {{this.mutateProtocol(form.data.connectionParams.printerProtocol)}}</h5>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.connectionParams.printerProtocol">
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
                <h4><b>{{$t('settingConnect')}} </b></h4>
                <hr />
                <b-row v-if="form.data.printerData.connectionParams">
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4>
                                    <b>
                                        {{this.mutateProtocol(form.data.connectionParams.printerProtocol)}}
                                    </b>
                                </h4>
                            </b-card-title>
                            <b-card-text>
                                <h5> - {{ form.data.printerData.connectionParams.ip != null ? form.data.printerData.connectionParams.ip : 
                                            form.data.printerData.connectionParams.comport != null ? form.data.printerData.connectionParams.comport : 
                                                form.data.printerData.connectionParams.usb != null ? form.data.printerData.connectionParams.usb :
                                                    form.data.printerData.connectionParams.mac != null ? form.data.printerData.connectionParams.mac :
                                                        form.data.printerData.connectionParams.systemPrinter != null ? form.data.printerData.connectionParams.systemPrinter : "-" }}
                                </h5>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
                <h4 ><b>{{$t('main')}}</b></h4>
                <hr />
                <b-row >
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('checkSize')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printerData.sizes.size">
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
                <h4><b>{{$t('advancedSettings')}}</b></h4>
                <hr />
                <b-row>
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('print_mode')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <select
                                    class="form-select mb-3"
                                    v-model="form.data.printerData.printParams.printMode"
                                    :disabled="this.editform == false"
                                    >
                                    <option v-for="item in printmode" :key="item" :value="item.value">{{ item.title }}</option>
                                </select>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col
                    v-if="form.data.printParams.printMode != 'image' && form.data.printerData.printParams.chineseOff != ''"
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('ChineseMode')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <select
                                    class="form-select mb-3"
                                    v-model="form.data.printerData.printParams.chineseOff"
                                    :disabled="this.editform == false"
                                    >
                                    <option v-for="item in chinesemode" :key="item" :value="item.value">{{ item.title }}</option>
                                </select>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col
                    v-if="form.data.printerData.printParams.printMode != 'image'"
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('encoding')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printerData.printParams.charset">
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col
                    v-if="form.data.printerData.printParams.printMode != 'image'"
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('pageCode')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printerData.printParams.codepage">
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
                <h4 v-if="form.data.printerData.printParams.printMode == 'image'" ><b>{{$t('sizes')}}</b></h4>
                <hr v-if="form.data.printerData.printParams.printMode == 'image'" />
                <b-row v-if="form.data.printerData.printParams.printMode == 'image'">
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('sizePixels')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printerData.sizes.width">
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('sizeFont')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printerData.sizes.width">
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
                <h4 ><b>{{$t('indents')}}</b></h4>
                <hr />
                <b-row >
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('feedBottom')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printerData.indents.feedBottom">
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('feedTop')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printerData.indents.feedTop">
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('feedLeft')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printerData.indents.feedLeft">
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('feedRight')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printerData.indents.feedRight">
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
            </template>
            <h4 v-if="form.type == 'printer' || form.type == 'possystem' "><b>{{$t('main')}}</b></h4>
            <hr v-if="form.type == 'printer' || form.type == 'possystem' " />
            <b-row v-if="form.type == 'printer' || form.type == 'possystem' ">
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('whattoPrint')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <select
                                class="form-select mb-3"
                                v-model="form.data.printParams.whatToPrint"
                                :disabled="this.editform == false"
                                >
                                <option v-for="item in whattoprint" :key="item" :value="item.value">{{ item.title }}</option>
                            </select>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('workshop')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printParams.workshop">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('checkSize')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.sizes.size">
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <h4 v-if="form.protocol != 'uaprro'"><b>{{$t('settingConnect')}}</b></h4>
            <hr v-if="form.protocol != 'uaprro'" />
            <b-row>
                <b-col
                lg="6"
                v-if="form.data.weightParams.length > 0"
                >
                    <b-card>
                        <b-card-title>
                            <h4>
                                <b>
                                    {{$t('dataTransferMode')}}
                                </b>
                            </h4>
                        </b-card-title>
                        <b-card-text>
                            <select
                                class="form-select mb-3"
                                v-model="form.data.weightParams.dataTransferMode"
                                :disabled="this.editform == false"
                                >
                                <option v-for="item in transfermodes" :key="item" :value="item.value">{{ item.title }}</option>
                            </select>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                v-if="form.protocol != 'uaprro'"
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4>
                                <b>
                                    <!-- this.form.hasOwnProperty('groupId') -->
                                    {{ form.data.connectionParams.hasOwnProperty('ip') ? $t('IP') : 
                                        form.data.connectionParams.hasOwnProperty('comport') ? $t('comport') : 
                                            form.data.connectionParams.hasOwnProperty('usb') ? $t('USB') : 
                                                form.data.connectionParams.hasOwnProperty('mac') ? $t('Bluetooth') : 
                                                    form.data.connectionParams.hasOwnProperty('systemPrinter') ? $t('systemPrinter') : 
                                                        form.data.connectionParams.hasOwnProperty('clientToken') ? $t('Token') : $t('Serial') }}
                                </b>
                            </h4>
                        </b-card-title>
                        <b-card-text>
                            <h5 v-if="this.editform == false">{{ form.data.connectionParams.hasOwnProperty('ip') != "" ? form.data.connectionParams.ip : 
                                        form.data.connectionParams.hasOwnProperty('comport') != "" ? form.data.connectionParams.comport : 
                                            form.data.connectionParams.hasOwnProperty('usb') != "" ? form.data.connectionParams.usb :
                                                form.data.connectionParams.hasOwnProperty('mac') != "" ? form.data.connectionParams.mac :
                                                    form.data.connectionParams.hasOwnProperty('systemPrinter') != "" ? form.data.connectionParams.systemPrinter : 
                                                        form.data.connectionParams.hasOwnProperty('clientToken') != "" ? form.data.connectionParams.clientToken : '-' }}</h5>
                            <input type="text" class="form-control" v-if="this.editform == true" v-model="form.data.sizes.labelHeightMM">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                v-if="form.type == 'rro' && form.protocol != 'uaprro'"
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4>
                                <b>
                                    {{$t('blocking')}}
                                </b>
                            </h4>
                        </b-card-title>
                        <b-card-text>
                            <select
                                class="form-select mb-3"
                                v-model="form.data.connectionParams.promise"
                                :disabled="this.editform == false"
                                >
                                <option v-for="item in promises" :key="item" :value="item.value">{{ item.title }}</option>
                            </select>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                v-if="form.protocol == 'helpmicro' || form.protocol == 'helpmicro2'"
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4>
                                <b>
                                    {{$t('User')}}
                                </b>
                            </h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.connectionParams.user">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                v-if="form.protocol == 'helpmicro' || form.protocol == 'helpmicro2'"
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4>
                                <b>
                                    {{$t('password')}}
                                </b>
                            </h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.connectionParams.pass">
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <labelprinter v-if="form.type == 'label_printer'" :obj="this.form" :editform="this.editform" />
            <h4 v-if="(form.type == 'printer' || form.type == 'possystem')"><b>{{$t('additionally')}}</b></h4>
            <hr v-if="(form.type == 'printer' || form.type == 'possystem')" />
            <b-row v-if="(form.type == 'printer' || form.type == 'possystem')">
                <b-col 
                    v-if="form.data.additionalPrintParams.logo"
                lg="12">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('Logo')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <img :src="form.data.additionalPrintParams.logo" alt="Red dot" />
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('pageHeader')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <textarea class="form-control" rows="3" :disabled="this.editform == false" :value="form.data.additionalPrintParams.header || '-'"></textarea>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('pageFooter')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <textarea class="form-control" rows="3" :disabled="this.editform == false" :value="form.data.additionalPrintParams.footer || '-'"></textarea>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('Cashier')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <textarea class="form-control" rows="3" :disabled="this.editform == false" :value="form.data.additionalPrintParams.сassier || '-'"></textarea>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('Bonuses')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <textarea class="form-control" rows="3" :disabled="this.editform == false" :value="form.data.additionalPrintParams.bonuses || '-'"></textarea>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <h4 v-if="(form.type == 'printer' || form.type == 'possystem')"><b>{{$t('advancedSettings')}}</b></h4>
            <hr v-if="(form.type == 'printer' || form.type == 'possystem')" />
            <b-row v-if="(form.type == 'printer' || form.type == 'possystem')">
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('print_mode')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <select
                                class="form-select mb-3"
                                v-model="form.data.printParams.printMode"
                                :disabled="this.editform == false"
                                >
                                <option v-for="item in printmode" :key="item" :value="item.value">{{ item.title }}</option>
                            </select>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('BufferSize')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printParams.buffer">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                v-if="form.data.printParams.printMode != 'image' && form.data.printParams.chineseOff != ''"
                lg="4">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('ChineseMode')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <select
                                class="form-select mb-3"
                                v-model="form.data.printParams.chineseOff"
                                :disabled="this.editform == false"
                                >
                                <option v-for="item in chinesemode" :key="item" :value="item.value">{{ item.title }}</option>
                            </select>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                v-if="form.data.printParams.printMode != 'image'"
                lg="4">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('encoding')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printParams.charset">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                v-if="form.data.printParams.printMode != 'image'"
                lg="4">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('pageCode')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.printParams.codepage">
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <h4 v-if="(form.type == 'printer' || form.type == 'possystem') && form.data.printParams.printMode == 'image'" ><b>{{$t('sizes')}}</b></h4>
            <hr v-if="(form.type == 'printer' || form.type == 'possystem') && form.data.printParams.printMode == 'image'" />
            <b-row v-if="(form.type == 'printer' || form.type == 'possystem') && form.data.printParams.printMode == 'image'">
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('sizePixels')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.sizes.width">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('sizeFont')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.sizes.font">
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <h4 v-if="(form.type == 'printer' || form.type == 'possystem')" ><b>{{$t('indents')}}</b></h4>
            <hr v-if="(form.type == 'printer' || form.type == 'possystem')" />
            <b-row v-if="(form.type == 'printer' || form.type == 'possystem')">
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('feedBottom')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.indents.feedBottom">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('feedTop')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.indents.feedTop">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('feedLeft')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.indents.feedLeft">
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col
                lg="6">
                    <b-card>
                        <b-card-title>
                            <h4><b>{{$t('feedRight')}}</b></h4>
                        </b-card-title>
                        <b-card-text>
                            <input type="text" class="form-control mb-3" id="text" :disabled="this.editform == false" v-model="form.data.indents.feedRight">
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <template v-if="form.type == 'banking'">
                <h4><b>{{$t('Settings')}}</b></h4>
                <hr />
                <b-row>
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('invoic')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <div class="input-group">
                                    <input type="text" class="form-control" :disabled="this.editform == false" v-model="form.data.bankingParams.score">
                                    <span class="input-group-text">ID</span>
                                </div>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col
                    lg="6">
                        <b-card>
                            <b-card-title>
                                <h4><b>{{$t('interestOfService')}}</b></h4>
                            </b-card-title>
                            <b-card-text>
                                <div class="input-group">
                                    <input type="text" class="form-control" :disabled="this.editform == false" v-model="form.data.bankingParams.interestOfService">
                                    <span class="input-group-text">%</span>
                                </div>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
            </template>
        <!-- </template> -->
    <!-- </modal> -->
</template>

<script>
import modal from '@/components/modal/modal-lg'
import labelprinter from './labelprinter'
import { mutateProtocol  } from '@/usabilityScripts/globalMutate.js'

export default ({
    props: ['obj', 'editform'],
    components: { 
        modal, 
        labelprinter 
    },
    data() {
        return{
            form: "",
            types: [
                    {
                        "title": this.$t('Null'),
                        "value": ""
                    },
                    {
                        "title": this.$t('device_printer'),
                        "value": "printer"
                    },
                    {
                        "title": this.$t('device_label_printer'),
                        "value": "label_printer"
                    },
                    {
                        "title": this.$t('device_pos_terminal'),
                        "value": "possystem"
                    },
                    {
                        "title": this.$t('fiscal_registrar'),
                        "value": "rro"
                    },
                    {
                        "title": this.$t('device_terminal'),
                        "value": "banking"
                    },
                    {
                        "title": this.$t('device_scales'),
                        "value": "libra"
                    },
                    {
                        "title": this.$t('device_scanner'),
                        "value": "scanner"
                    },
                    {
                        "title": this.$t('device_table_control'),
                        "value": "pool"
                    },
                    {
                        "title": this.$t('screens'),
                        "value": "screens"
                    }
            ],
            os: [
                {
                    "title": "Windows",
                    "value": "windows"
                },
                {
                    "title": "Android",
                    "value": "android"
                },
                {
                    "title": "IOS",
                    "value": "ios"
                }
            ],
            whattoprint: [
                {
                    "title": this.$t('OnlyRunners'),
                    "value": "1"
                },
                {
                    "title": this.$t('CheckRunners'),
                    "value": "2"
                },
                {
                    "title": this.$t('CheckOnly'),
                    "value": "3"
                },
                {
                    "title": this.$t('OnlyLabels'),
                    "value": "4"
                },
                {
                    "title": this.$t('RunnersChecks'),
                    "value": "5"
                }
            ],
            transfermodes: [
                {
                    "title": this.$t('listener'),
                    "value": "listener"
                },
                {
                    "title": this.$t('requestlibr'),
                    "value": "request"
                }
            ],
            printmode: [
                {
                    "title": this.$t('mode_text'),
                    "value": "text"
                },
                {
                    "title": this.$t('mode_graphic'),
                    "value": "image"
                }
            ],
            promises: [
                {
                    "title": this.$t('NotBlocking'),
                    "value": "0"
                },
                {
                    "title": this.$t('blockTr'),
                    "value": "1"
                },
                {
                    "title": this.$t('waitblocking'),
                    "value": "2"
                }
            ],
            chinesemode: [
                {
                    "title": this.$t('SwitchedOn'),
                    "value": "0"
                },
                {
                    "title": this.$t('SwitchedOff'),
                    "value": "1"
                }
            ],
        }
    },
    created(){
        this.form = this.obj
        console.log('f', this);
    },
    methods:{
        mutateProtocol(e){
            return mutateProtocol(e)
        },
    }
})
</script>
