<template>
    <!-- модальное окно просмотра заявки -->
    <modal :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @close="closeModal" :key="componentKey" :styleAdd="'backdrop-filter: blur(0px) !important;'">
        <template v-slot:title>{{ $t('newApplication') }}</template>
        <template v-slot:status>
            <span :class="`badge badge ${this.mutateStatus(this.form.status, this.form).color} align-middle fs-12`">{{this.form.status == 'callNow' ? this.$t(this.mutateStatus(this.form.status, this.form).name) : this.mutateStatus(this.form.status, this.form).name}}</span>
        </template>
        <template v-slot:head-button>
            <div class="dropdown" v-if="perms[103] || perms[105] || perms[2000]">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <button class="dropdown-item" v-if="form.status == 'new' && (perms[105] == true || perms[2000] == true)" @click="this.$emit('edit', this.form)">{{ $t('Edit') }}</button> <!-- редактировать заявку -->
                    <button class="dropdown-item" v-if="((form.status == 'jobs' || form.status == 'wait_comment') && form.inJobWorkerId == this.user.userid)" @click="showModalDelay = true">{{ $t('postpone')}}</button>  <!-- отложить заявку -->
                    <button class="dropdown-item" v-if="(form.status == 'jobs' || form.status == 'wait_comment') && (perms[105] || perms[2000]) && form.inJobWorkerId == this.user.userid" @click="showModalTransfer = true">{{ $t('Transfer')}}</button> <!-- передать заявку -->
                    <button class="dropdown-item" v-if="((perms[105] || perms[2000]) && (form.status !== 'canceled' && form.status !== 'closed' && form.status !== 'callNow'))" @click="callNow(this.form.requestId)">{{ $t('CallNow') }}</button>  <!-- удалить заявку -->
                    <button class="dropdown-item" v-if="(perms[103] || perms[2000])" @click="dialogshow = true">{{ $t('Delete') }}</button> <!-- удалить заявку -->
                </div>
            </div>
        </template>
        <template v-slot:body>
                <b-alert v-if="form.status == 'transfered'" show variant="warning" class="text-center">
                    <h5 class="alert-heading" v-if="this.user.userid != form.transfer.workerId">
                        <b>{{ form.inJobWorkerName }} {{ $t('submittedRequest') }} {{ form.transfer.workerName }}</b>
                    </h5>
                    <h5 class="alert-heading" v-else>
                        <b>{{ form.inJobWorkerName }} {{ $t('sentYouRequest') }}</b>
                    </h5>
                    <span v-if="form.transfer.comment">{{ $t('Comment') }}: {{ form.transfer.comment }}</span>
                </b-alert>
                <b-row>
                    <b-col lg="4">
                        <aboutBlock 
                            :obj="this.form"
                        />
                    </b-col>
                    <b-col lg="4">
                        <createdBlock
                            :obj="this.form"
                            :comment="this.form"
                        />
                    </b-col>
                    <b-col lg="4">
                        <clientBlock
                            :obj="this.form"
                            @getClient="this.getClient"
                        />
                        
                    </b-col>
                </b-row>
                <hr v-if="form.status == 'jobs' || form.status == 'closed' || form.status == 'transfered' || form.status == 'canceled' || form.status == 'wait_cancel'" />
                <b-row>
                    <b-col lg="4" v-if="form.inJobWorkerId !== null">
                        <jobsBlock 
                            :obj="this.form"
                        />
                    </b-col>
                    <b-col lg="4" v-if="form.transfer.workerId !== null && form.transfer.workerId !== 0">
                        <tranferBlock 
                            :obj="this.form.transfer"
                        />
                    </b-col>
                    <b-col lg="4" v-if="form.closeWorkerId !== null">
                        <closedBlock
                            :obj="this.form" 
                        />
                    </b-col>
                    <b-col lg="4" v-if="form.cancelWorkerId !== null">
                        <canceledBlock
                            :obj="this.form" 
                        />
                    </b-col>
                </b-row>
        </template>
        <template v-slot:footer-bottom>
                <!-- <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
                <button 
                    type="button" 
                    v-if="(perms[103] || perms[2000]) && form.status != 'transfered'" 
                    @click="dialogshow = true" 
                    class="btn btn-danger r waves-effect waves-light">
                    {{ $t('Delete') }}
                </button> <!-- видалити відвал -->
                <button 
                    type="button" 
                    v-if="(perms[100] || perms[2000]) && form.status != 'transfered'"
                    @click="modalDialog = true" 
                    class="btn btn-warning r waves-effect waves-light">
                    {{ $t('createReport') }}
                </button> <!-- репорт на скасування відвал -->
                <button 
                    type="button" 
                    v-if="(perms[2000] || perms[103]) && form.status != 'transfered'"
                    @click="modalDialogCancel = true" 
                    class="btn btn-danger r waves-effect waves-light">
                    {{ $t('cancel') }}
                </button> <!-- фулл скасування заявки -->
                <button 
                    type="button"
                    v-if="(form.status != 'jobs') && (perms[2000] || perms[104]) && form.status != 'transfered'"
                    @click="toWork(form.requestId)"
                    :class="`btn btn-${(form.status == 'waiting_confirmation' ? 'info' : 'success') } ss waves-effect waves-light`">
                    {{ $t('takeToWork') }}
                </button> <!-- взяти у роботу -->

                <button 
                    type="button"
                    v-if='(perms[104] || perms[2000]) && ((form.status == "jobs" || form.status=="callNow") && form.inJobWorkerId == this.user.userid ) && form.status != "transfered"' 
                    @click="showModalClose = true"
                    :class="`btn btn-success ss waves-effect waves-light`">
                    {{ $t('toComplete') }}
                </button> <!-- завершити заявку -->

                <button 
                    type="button"
                    v-if="perms[114] && form.status == 'transfered' && form.transferWorkerId == this.user.userid"
                    @click="rejectTransfer()"
                    :class="`btn btn-danger ss waves-effect waves-light`">
                    {{ $t('Refusal') }}
                </button> <!-- відмовитись від передачі заявки -->

                <button 
                    type="button"
                    v-if="perms[104] && form.status == 'transfered' && form.transferWorkerId == this.user.userid"
                    @click="confirmTransfer()"
                    :class="`btn btn-success ss waves-effect waves-light`">
                    {{ $t('ConfirmTransfer') }}
                </button> <!-- підтвердити передачу -->
        </template>
    </modal>

    <!-- модальне вікно підтвердження видалення -->
    <dialodel 
        v-if="dialogshow" 
        @close="dialogshow = false" 
        :obj="this.form" 
        @remove="remove" 
    />
    <!-- модальне вікно підтвердження видалення -->

    <!-- модальне вікно репорта на скасування -->
    <cancelReport 
        v-if="modalDialog == true" 
        @toCancelReport="toCancelReport" 
        @close="this.modalDialog = false" 
        :id="this.form.requestId"
    />
    <!-- модальне вікно репорта на скасування -->

    <!-- модальне вікно скасування -->
    <cancel 
        v-if="modalDialogCancel == true" 
        @toCancel="toCancel" 
        @close="this.modalDialogCancel = false" 
        :id="this.form.requestId"
    />
    <!-- модальне вікно скасування -->

    <!-- модальне вікно закриття заявки -->
    <close 
        v-if="showModalClose == true" 
        :id="this.form.requestId" 
        @close="showModalClose=false" 
        @toClose="toClose"
    />
    <!-- модальне вікно закриття заявки -->

    <!-- модальне вікно перенесення відвалу -->
    <delay 
        v-if="showModalDelay == true" 
        :id="this.form.requestId" 
        @close="showModalDelay=false" 
        @toDelay="toDelay"
    />
    <!-- модальне вікно перенесення відвалу -->

    <!-- модальне вікно передачі відвала -->
    <transfer 
        v-if="showModalTransfer == true" 
        @close="showModalTransfer = false" 
        @transferRequest="transferRequest" 
        :id="this.form.requestId"
    />
    <!-- модальне вікно передачі відвала -->

</template>

<script>
import transfer from './actions/transfer.vue'
import delay from './actions/delay.vue'
import close from './actions/close.vue'
import cancelReport from './actions/cancelReport.vue'
import cancel from './actions/cancel.vue'
import modal from '@/components/modal/modal-lg'
import aboutBlock from './blocks/about'
import createdBlock from './blocks/created'
import clientBlock from './blocks/client'
import jobsBlock from './blocks/jobs'
import tranferBlock from './blocks/transfer'
import canceledBlock from './blocks/canceled'
import closedBlock from './blocks/closed'
// import viewbox from '../clients/view.vue'
import viewboxnew from '@/views/accounts/allAccounts/viewnew/index.vue'
import dialogBox from '@/components/modal/dialog'
import dialodel from '@/components/globaltable/actions/dialogdelete.vue'
import { Clients, Dumps } from "@/API.js"
import { storeS } from '@/store.js'
import { mutateStatus } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Clients();
let apiApp = new Dumps();

export default ({
    props: ["phone", "shortapplic", 'ifPrevopen', 'ifNextopen', 'closeOne', 'client'],
    components: { 
        modal, 
        dialogBox, 
        viewboxnew, 
        dialodel, 
        cancel, 
        cancelReport, 
        close, 
        delay,
        transfer,
        aboutBlock,
        createdBlock,
        clientBlock,
        jobsBlock,
        tranferBlock,
        canceledBlock,
        closedBlock,
    },
    data(){
        return {
            date1:"",
            showModalClose:false,
            checked: false,
            modalDialog: false,
            modalDialogCancel:false,
            showModalTransfer: false,
            dialogshow: false,
            comment: "",
            mainmodal: false,
            dateTimeConfig: {
                enableTime: true,
                dateFormat: "d.m.y H:i",
            },
            testshowModalclient: false,
            form: {
                userPhone: "",
                priority: "",
                status: "",
            },
            showModal: false,
            number: "",
            showModalDelay: false,
        }
    },
    created(){
        this.form = this.shortapplic
        if(typeof this.form.userPhones== 'object' ) {
            this.form.userPhones= this.form.userPhone.join(' ')
        }
    },
    methods: {
        mutateStatus(e, obj){
            return mutateStatus(e, obj);
        },
        confirmTransfer(){
            apiApp.fixationTransferDump(this.form.requestId).then(result => {
                if(result.status === 'done') {
                    // this.$toast.success('Заявка на передачу отправлена');
                    this.$emit('close') //фікасація отримання заявки
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        rejectTransfer(){
            apiApp.rejectTransferDump(this.form.requestId).then(result => {
                if(result.status === 'done') {
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        closeModal() {
            this.$emit('close');
        },
        prevOpen(){
            this.$emit('close');
            this.$emit('prevOpen', this.form.id)
        },
        nextOpen(){
            this.$emit('close');
            this.$emit('nextOpen', this.form.id)
        },
        callNow(e) {
            apiApp.callNowByDump(e).then(result=>{
                if(result.status==='done') {
                    this.$emit('close')
                    this.$toast.success(this.$t('successfully'))
                    this.$emit('getdata')
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        toWork: function(e){
            //взять в работ
                apiApp.addDumpInJob(e).then(res => {
                    if(res.status === 'done') {
                        this.$toast.success(this.$t('statusInWork'));
                        // this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error'))
                    }
                })
            },
        toCancelReport: function(e, form){
            //отменить заявку
            apiApp.addReportOnCancelDump(e, form).then(result=>{
                if(result.status == 'done') {
                    this.$emit('close')
                    this.$toast.success(this.$t('successfully'))
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        toCancel: function(e, form) {
            apiApp.cancelDump(e, form).then(result=>{
                if(result.status== 'done') {
                    this.$emit('close')
                    this.$toast.success(this.$t('successfully'))
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        toDelay: function(e, form) {
            apiApp.delayDump(e, form).then(result=>{
                if(result.status== 'done') {
                    this.$emit('close')
                    this.$toast.success(this.$t('DelayedOnTime'))
                    this.$emit('getdata')
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        toClose:function(id, form) {
            apiApp.closeDump(id, form).then(result=>{
                console.log('here', result.data)
                if(result.data.status == 'done') {
                    this.$toast.success(this.$t('closed'))
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        remove: function(e){
            //удалить
            apiApp.deleteDump(e.requestId).then(result=>{
                if(result.status == 'done') {
                    this.$toast.success(this.$t('Removed'))
                    this.$emit('close')
                    this.$emit('getdata')
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        transferRequest(e, form) {
            apiApp.transferDump(e,form).then(result=>{
                if(result.status === 'done') {
                    this.$toast.success('Заявка на передачу отправлена');
                    this.$emit('close') //передача заявки
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        getClient(e) {
            if(typeof this.form.userPhones== 'object') {
                this.number = this.form.userPhone.split(' ')[0]
                this.eventBus.emit('getClient', {status: true, number: this.number, requestId: {id: this.form.requestId, isDump: true}})
            } else {
                this.number = this.form.userPhone
                this.eventBus.emit('getClient', {status: true, number: this.number, requestId: {id: this.form.requestId, isDump: true}})
            }
        },
    },
    mounted() {
        // this.eventKey.on('remove', () => {
        //     if(this.form.status == 'statuswait_delete'){
        //         this.dialogshow = true
        //     }
        // })

        this.eventB.off('dumpGetData');
        this.eventB.on('dumpGetData', (status) => {
            if(this.user.userid == status.workerId && this.form.requestId == status.requestId) {
                apiApp.getDump(status.requestId).then(result => {
                    if(result.status === 'done') {
                        this.form = result.data;
                        this.componentKey += 1
                    }
                })
            }
        })
    },
    computed:{
        perms() {
            return storeS.perms
        },
        user() {
            return storeS.userbase
        }
    }
})
</script>


<style scoped>
.copybtn {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity .3s;
}
.c_block:hover .copybtn {
    opacity: 1;
    transition: opacity .3s;
}

.copybtn:checked {
    width: 500px;
}
</style>