<template>
    <!-- 
        Модальне вікно створення уроку
    -->

    <modal 
        :title="$t('lesson')" 
        :stylebody="'var(--vz-body-bg)'" 
        :idModal="'scrollPost'"
    >
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')">
                <i class="ri-close-fill align-bottom"></i>
            </button>
        </template>

        <template v-slot:body>
            <b-row>
                <b-col
                    lg="3"
                    md="5"    
                >
                    <b-card>
                        <div>
                            <!-- Вибір мови -->
                            <h5 class="kb-title">
                                <b>{{ $t('chooseLang') }}</b>
                            </h5>
                            <select class="form-select mb-3" v-model="this.form.lang">
                                <option value="0">{{ $t('chooseLangList') }}</option>
                                <option v-for="item in this.langList" :key="item" :value="item.value">{{item.title}}</option>
                            </select>
                        </div>
                        
                        <!-- Вибір категорії -->
                        <div style="margin-top:10px">
                            <h5 class="kb-title">
                                <b>{{ $t('chooseCategory') }}</b>
                            </h5>
                            <Multiselect 
                                v-model="this.form.categoryId"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="this.categoriesList"
                            />
                        </div>

                        <!-- Порядковий номер/сортування -->
                        <div class="mt-2">
                            <h5 class="kb-title">{{ $t('Sorting') }}</h5>
                            <input type="text" class="form-control" placeholder="№" v-model="this.form.sort" />
                        </div>
                    </b-card>

                    <b-card>
                        <!-- Посилання на урок у базі знань -->
                        <h5 class="kb-title">
                            <b>{{ $t('linkToLesson') }}</b>
                        </h5>
                        <input type="text" class="form-control" v-model="this.form.link">
                    </b-card>
                    
                        <!-- теги к уроку -->
                    <!-- <b-card>
                        <h5 class="kb-title">
                            <b>{{ $t('Tags') }}</b>
                        </h5>
                        <Multiselect
                                    v-model="form.tags"
                                    mode="tags"
                                    :close-on-select="false"
                                    :searchable="true"
                                    :create-option="true"
                                    :placeholder="$t('tags')"
                                />
                         <button type="button" @click="showModalPost = true" class="btn btn-info">{{ $t('Add') }}</button> 
                    </b-card> -->
                </b-col>
                <b-col
                    lg="9"
                    md="7"
                >
                    <b-card>

                        <!-- Назва уроку -->
                        <b-card-title class="mb-1">
                            <h5 class="kb-title">
                                <b>{{ $t('titleLesson') }}</b>
                            </h5>
                            <input type="text" class="form-control" v-model="form.lessonName">
                        </b-card-title>
                    </b-card>
                    <b-card>
                        <!-- Опис уроку -->
                        <h5 class="kb-title">
                            <b>{{ $t('lessonDesc') }}</b>
                        </h5>
                        <textqe @input="inputText" :objText="this.form.comment" />
                    </b-card>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>

            <!-- Кнопка для додавання нового уроку -->
            <button 
                type="button" 
                v-if="Boolean(this.editPerm) == false" 
                v-on:click="add" 
                class="btn btn-success"
            >
                {{ $t('Add') }}
            </button>

            <!-- Кнопка для збереження/редагування уроку -->
            <button 
                type="button" 
                v-if="this.editPerm === true" 
                @click="edit"
                class="btn btn-success"
            >
                {{ $t('save') }}
            </button>

        </template>
    </modal>
</template>

<script>
import textqe from '@/components/textQE' // компонент текстового редактора
import modal from '@/components/modal' // компонент модального вікна
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { KW } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальна шина слухання подій
import { eventB } from '@/main' //for indexedDB
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new KW();

export default {
    data() {
        return {
            editPerm: '', //не використовуємо пропс через те, що ця хрінь повинна бути динамічна
            dontSave: false, // прапорець, що вказує, чи потрібно зберігати дані перед закриттям компонента
            form:{ // Дані для форми
                lessonName:"", // Назва уроку
                categoryId: '', // id категорії
                link: '', // посилання на урок
                lang: '', // мова уроку
                comment: '', // опис уроку
                sort: '', // порядковий номер
                lessonId: '',
            },
            category: {
                lessons:[]
            },
            editor: ClassicEditor,
            langList:[ // Масив з доступними мовами
                {
                    title:"Українська",
                    value: "uk"
                },
                {
                    title:"Poland",
                    value: "pl"
                },
                {
                    title:"Російська",
                    value: "ru"
                }
            ],
            objParams:{
                page: '1',
                pagelimit: '100',
                search: '',
                lang: ''
            },
            categoriesList: [],
            coldown: '',
        };
    },
    components: { ckeditor: CKEditor.component, modal, Multiselect, textqe},
    props: ['obj', 'permEdit', 'objCategory'],
    created(){
        this.editPerm = this.permEdit

        this.objParams.lang = this.user.lang;
        this.form.lang = this.user.lang;
        
        this.categories.forEach(el => {
            if(el.lang == this.user.lang) {
                this.categoriesList.push({
                    label: el.categoryName,
                    value: el.categoryId
                })
            }
        })    

        if(this.objCategory){
            this.form.lang = this.objCategory.lang;
            this.form.categoryId = this.objCategory.categoryId;
            this.form.sort = this.objCategory.countLessons+1;
        }

        if(this.editPerm == true) {
            this.form = this.obj
        }
        
        //перевірка на наявність не закінченних заявок
        let openRequest = indexedDB.open("store", 1);
            openRequest.onsuccess = function() {
                let db = openRequest.result;
                let transaction = db.transaction("data", "readwrite"); // (1)
                
                // получить хранилище объектов для работы с ним
                let data = transaction.objectStore("data"); // (2)
                let categoryIndex = data.index("сategory_idx");

                let request = categoryIndex.getAll('KWPost')

                request.onsuccess = function() {
                    console.log(request.result)
                    if (request.result !== undefined && request.result.length > 0) {
                        eventB.emit('haveForms', {status: true, forms: request.result})
                    } else {
                        console.log('Незакінченні форми не знайдені. Дякую за збереження екології, бо ви не зайняли зайвого місця на серверах Зімбабве =)')
                    }
                };

                request.onerror = function() {
                    alert('Щось пішло не так, перезапустіть будь ласка сторінку')
                }
            }
        //перевірка на наявність не закінченних заявок

        //вибір дії було зроблено, обробка
        
        this.eventBus.off('openOld')
        this.eventBus.on('openOld', status => {
            console.log(status)
            if(status.status == true) {
                let openRequest = indexedDB.open("store", 1);
                    openRequest.onsuccess = function() {
                        let db = openRequest.result;
                        let transaction = db.transaction("data", "readwrite"); // (1)
                        
                        // получить хранилище объектов для работы с ним
                        let data = transaction.objectStore("data"); // (2)
                        let categoryIndex = data.index("сategory_idx");

                        let request = categoryIndex.getAll(status.form.category)

                        request.onsuccess = function() {
                            if (request.result !== undefined) {
                                request.result.forEach(el=> {
                                    if(el.id == status.form.id) {
                                        let deleteReq = data.delete(el.id)
                                            deleteReq.onsuccess = function() {
                                                console.log('All okey') //видалення, щоб не було дублікатів
                                            }
                                    }
                                })   
                            }
                        };

                        request.onerror = function() {
                            alert('Щось пішло не так, перезапустіть будь ласка сторінку')
                        }
                    }
                this.form = JSON.parse(status.form.form)
                if(status.form.text !== '') {
                    this.editPerm = status.form.text
                }
            }
        })
        
        this.eventBus.off('createNew')
        this.eventBus.on('createNew', status => {
            if(status.status == true) {
                return
            }
        })
        
        //вибір дії було зроблено, обробка
    },
    beforeUnmount() {
        if(this.dontSave == false) {
            if(this.editPerm == true) {
                sessionStorage.setItem('perm', this.perm)
            }
            sessionStorage.setItem('actual', JSON.stringify(this.form))
            if(this.form.lessonName !== '' || this.form.link !== '' || this.form.comment !== '') {
                let openRequest = indexedDB.open("store", 1);
                    openRequest.onsuccess = function() {
                        let db = openRequest.result;
                        let transaction = db.transaction("data", "readwrite"); // (1)
                    
                        // получить хранилище объектов для работы с ним
                        let data = transaction.objectStore("data"); // (2)
                        let category_text = 'KWPost';
                        let detail = {
                            dateTime: new Date(),
                            text: sessionStorage.getItem('perm') ? sessionStorage.getItem('perm') : '',
                            category: category_text,
                            form: sessionStorage.getItem('actual')
                        };

                        let request = data.add(detail); // (3)

                        request.onsuccess = function() { // (4)
                            sessionStorage.removeItem('actual')
                            console.log("Данные добавлены в хранилище", request.result);
                        };

                        request.onerror = function() {
                            console.log("Ошибка", request.error);
                        };
                    } //додавання в indexedDB
            }
        }
        
    },
    methods: {
        inputText(text) {
            /**
                * Обробник події введення тексту у текстове поле.
                * @param {Event|String} text - Об'єкт події введення тексту або сам текст.
            */

            // Зупинка таймера, щоб уникнути надто частого відправлення запитів на сервер
            clearTimeout(this.coldown)
            
            // Оновлення текстового поля форми з введеним текстом
            this.form.comment = text.target ? text.target.innerHTML : text;

            // Запуск таймера, який відправить запит на сервер через 10 секунд з моменту останньої зміни
            this.coldown = setTimeout(() => {
                // Перевірка, чи маємо дозвіл на редагування
                if(this.editPerm == true) {
                    // Відправка запиту на сервер для редагування уроку
                    apiServe.editLesson(this.form.lessonId, this.form).then(result=>{
                        if(result.status === 'done') {
                            this.$toast.success(this.$t('success'))
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            var tempObj = {
                                "lessonId": this.form.lessonId,
                                "params": this.form
                            }

                            var errorText = 'Помилка збереження уроку'; 
                            var statusRequest = result.status;
                            var api = 'editLesson';
                            var fileRequest = 'src/components/knowledge-base/actions/addPost.vue';
                            var params = this.form;
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }
                    })
                } else {
                    // Відправка запиту на сервер для створення нового уроку
                    apiServe.createLesson(this.form).then(result=>{
                        if(result.status === 'done') {
                            // Оновлення ідентифікатора уроку та встановлення дозволу на редагування
                            this.form.lessonId = result.data
                            this.editPerm = true
                        } 
                    })
                }
            }, 10000)
        },
        add() {
            /**
                * Додавання нового уроку з урахуванням валідації полів.
            */

            if (!this.form.lang) {
                //перевіряємо чи вказана мова
                this.$toast.error(this.$t('EnterLang'));
            }
            if (!this.form.categoryId) {
                //перевіряємо чи вказана категорія
                this.$toast.error(this.$t('EnterCategory'));
            }
            if (!this.form.link) {
                //перевіряємо чи вказаний лінк
                this.$toast.error(this.$t('EnterURL'));
            }
            if (!this.form.lessonName) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
            }
            if (!this.form.comment) {
                //перевіряємо чи вказаний опис
                this.$toast.error(this.$t('EnterDesc'));
            }

            if(this.form.lang && this.form.categoryId && this.form.link && this.form.lessonName && this.form.comment){
                clearTimeout(this.coldown)

                apiServe.createLesson(this.form).then(result=>{
                    if(result.status === 'done') {
                        this.dontSave = true
                        this.$toast.success(this.$t('lessonCreated'));
                        this.$emit('close')
                        this.$emit('create')
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var errorText = 'Помилка створення уроку'; 
                        var statusRequest = result.status;
                        var api = 'createLesson';
                        var fileRequest = 'src/components/knowledge-base/actions/addPost.vue';
                        var params = this.form;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
        edit: function(){
            // Вмикаємо режим редагування 

            clearTimeout(this.coldown)
            this.dontSave = true
            this.$emit('edit', this.form);
        },
        scrollHandler(){
            // const elem = document.getElementById('scrollPost');
            // elem.scrollTop = elem.scrollTop;
            // console.log('elem.scrollTop', elem.scrollTop);
            // console.log('elem.scrollHeight', elem.scrollHeight);;

            console.log('scroll');
        }
    },
    mounted(){
        
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        categories() {
            /*
                Повертає категорії з глобального стору.
            */
            return storeS.KBCategories
        }
    }
}
</script>