<template>
    <dialogBox >
        <template v-slot:title>{{ $t('waiting_confirmation') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label for="VertimeassageInput" class="form-label">{{ $t('Manager') }}</label>
                <Multiselect
                    v-model="this.toWorker"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.listWorkers"
                    :placeholder="$t('Manager')"
                ></Multiselect>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button 
                type="button" 
                class="btn btn-success" 
                @click="transferRequest"
            >
                {{ $t('Submit') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import Multiselect from "@vueform/multiselect";
import { storeS } from '@/store';

export default{
    components: {
        dialogBox,
        Multiselect
    },
    props: ['id'],
    data(){
        return{
            toWorker: '',
            listWorkers: [
                { label: 'Борис', value: 33},
                { label: 'Max', value: 34}
            ],
        }
    },
    created() {
        storeS.workers.forEach( (el)=> {
            // if(el.workerJob === 'managercallcenter') {
                this.listWorkers.push({
                    value: el.workerId,
                    label: el.workerName
                })
            // } //список работников
        })
    },
    methods: {
        transferRequest(){
            this.$emit('transferRequest', this.id, this.toWorker)
            this.$emit('close')
        }
    }
}
</script>