<template>
    <!-- 
        Список запізнень по співробітнику
        - дозволяє переглянути список запізнень з фільтрацією по даті
        - відкрити картку запізнення
        - та інше :)
    -->
    
    <div 
        class="col-sm-auto" 
        style="margin-bottom:15px;"
        v-if="this.checks['001'] == true"
    >
        <!-- кнопка відображення інфо-блоків -->
        <button 
            v-if="this.checks['001'] == true" 
            @click="infoblockShow = !infoblockShow" 
            type="button" 
            :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
        >
            <i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i>
        </button>
    </div>

    <!-- 
        Інформаційні блоки з підсумком по -
        - суми часу запізення та кількість запізнень
    -->
    <infoblocks 
        v-if="infoblockShow" 
        :obj="this.information" 
        style="margin-top:15px;" 
    />

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - @open - подія, яка спрацьовує при відкритті картки запізнення
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true"
        @open="open"
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
    />

    <!-- 
       Виклик відкриття картки запізнення
       - для відкриття застосовується перевірка значення showModal
       - @close - подія, яка спрацьовує при закритті картки
       - :objCard - параметр-об'єкт з даними про запізнення
    -->
    <viewbox 
        v-if="showModal"
        @close="showModal = false"
        :objCard="objCard"
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewbox from './view' // картка запізнення
import infoblocks from '@/components/info/blocks' // інфо-блоки
import { Users } from "@/API.js"; // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiUsers = new Users();

export default {
    props: ['id'],
    components: { tablecustom, viewbox, infoblocks },
    data() {
        return {
            showModal: false, // прапорець відображення вікна запізнення
            infoblockShow: false, // прапорець відображення інфо-блоків
            objCard:"", // Об'єкт з даними запізнення для відображення в модальному вікні
            objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "shiftUser"
            },
            objParams: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                workerId: '',
                pr1: '',
                pr2: ''
            },
            rows: [], // Дані для відображення у таблиці
            columns: [ // Конфігурація колонок таблиці з інформацією про кожну колонку
                {
                    name: "ID", // Назва колонки для локалізованого відображення
                    text: "shiftId", // Ключове слово для отримання значення з даних
                    align: "left", // Вирівнювання в лівий бік
                    status: true, // Статус відображення колонки.
                },
                {
                    name: this.$t('Employee'),
                    text: "openedWorkerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('shift'),
                    text: "shiftCloseDateTime",
                    align: "left",
                    mutate: (item, obj) => obj.shiftOpenDatetime + "-" + obj.shiftCloseDateTime, // Функція для мутації значення колонки (переклад)
                    status: true,
                },
                {
                    name: this.$t("OpeningShift"),
                    text: "shiftOpenTime",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("StartShift"),
                    text: "workerOpenShift",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("lateTime"),
                    text: "lateTimeInMin",
                    align: "left",
                    status: true,
                    mutate: (item) => item + " " +this.$t('min')
                },
                {
                    name: this.$t("Cause"),
                    text: "lateComment",
                    align: "left",
                    status: true,
                },
            ],
            information: [
                // Дані компонента, які відображають інформацію про кількість та суми запізнень
                // * title - назву інформаційного блоку,
                // * value - кількість запізнень
                // * icon - клас іконки,
                // * color - колір інформаційного блоку,
                // * sum - загальну суму годин запізнень
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                    sum: "0"
                },
            ]
        }
    },
    created(){
        // Ініціалізація компонента та отримання даних
        this.getdata()
    },
    methods: {
        getdata(page){
             /**
                Отримує дані про запізнення з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Number} page - Номер сторінки для отримання даних.
            */

            // Встановлення параметрів для запиту до API
            this.objParams.workerId = this.id
            this.objParams.page = page != undefined ? page : '1';

            // Отримання даних про штрафи з API
            apiUsers.getLatenesses(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                    this.objPages = result.data

                    this.information[0].value = result.data.total;
                    this.information[0].sum = result.data.sumTime.hours + " " + this.$t('hours') + " " + result.data.sumTime.minutes + " " + this.$t('min');
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка отримання списку запізнень'; 
                    var statusRequest = result.status;
                    var api = 'getLatenesses';
                    var fileRequest = 'src/views/users/view/formright/latenesses/index.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        open(e){
            /**
                Функція для відкриття модального вікна з інформацією про запізнення.
                * @param {Object} e - об'єкт картки

                Об'єкт присвоюється властивості objCard, та встановлюється прапорці showModal в true для відображення модального вікна.
            */

            this.objCard = e;
            this.showModal = true
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(name == 'all'){
                this.objParams.search = '',
                this.objParams.priority = '',
                this.objParams.status = '',
                this.objParams.workerId = '',
                this.objParams.pr1 = '',
                this.objParams.pr2 = '';
                this.getdata();
            } else if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
    },
    computed: {
        user(){
            /*
                Повертає дані за користувача з глобальномго стору.
            */
            return storeS.userbase
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобальномго стору.
            */
            return storeS.checks
        },
    }
}
</script>