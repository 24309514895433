<template>
    <!-- 
        Модальне вікно створення послуги 
    -->

    <modal>
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('add_service') }}</template>
        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>

            <!-- Номер клієнта, кому надається послуга -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('information_for_service') }}</h4>
                <input type="number" class="form-control" id="contactNumber" placeholder="+380" v-model="form.userPhone" >
            </div>

            <!-- назва послуги -->
            <div class="mb-4">
                <h4 class="fs-15">{{ $t('type_service') }}</h4>
                <Multiselect 
                    v-model="form.nomenclatureName"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.tasktype"
                    :placeholder="$t('Enter_search_term_service')"
                />
            </div>


            <div class="row">
                <div class="col-md-6">
                    <!-- Статус послуги -->
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('choose_a_status') }}</h4>
                        <Multiselect 
                            v-model="form.status"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.statusSevice"
                            :placeholder="$t('Status')"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <!-- Сума послуги -->
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('price_service') }}</h4>
                        <Multiselect 
                            v-model="form.price"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="true"
                            :options="this.sumSevice"
                            :placeholder="$t('Sum')"
                        />
                    <!-- <input type="text" v-if="this.selectedSum == 'Своя сумма'" class="form-control" id="contactNumber"  v-model="resultSum"> -->
                    </div>
                </div>
            </div>

            <!-- Коментар до послуги -->
            <div class="mb-3">
                <label for="VertimeassageInput" class="form-label">{{ $t('Message') }}</label>
                <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="form.description" :placeholder="this.$t('information_for_service')"></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>

            <!-- 
                Кнопка "Додати" 
                - надає змогу створити послугу
            -->
            <button 
                type="button" 
                class="btn btn-success" 
                v-on:click="add"
            >
                {{ $t('Add') }}
            </button>

        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small' // компонент модального вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Services } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Services();

export default ({
    data(){
        return {
            form: { // Дані для форми
                nomenclatureName: "", // назва послуги
                description: "", // опис до послуги
                status: "", // статус надання послуги
                userPhone: "", // номер клієнта
                price: "" // вартість послуги
            },
            tasktype: [ // список послуг 
                {
                    // послуга "Налаштування ПРРО"
                    "label": this.$t('prro_setting'),
                    "value": "prro_setting"
                },
                {
                    // послуга "Відновлення даних"
                    "label": this.$t('data_recovery'),
                    "value": "data_recovery"
                },
                {
                    // послуга "Підключення принтера"
                    "label": this.$t('connecting_printer'),
                    "value": "connecting_printer"
                },
                {
                    // послуга "Підключення терміналу"
                    "label": this.$t('terminal_connection'),
                    "value": "terminal_connection"
                },
                {
                    // послуга "Підключення ваг"
                    "label": this.$t('scale_connection'),
                    "value": "scale_connection"
                },
                {
                    // послуга "Підключення РРО"
                    "label": this.$t('rro_connection'),
                    "value": "rro_connection"
                }
            ],
            selectedStatus: "",
            statusSevice: [ // статус послуги
                {
                    // статус "Проблема вирішена"
                    "label": this.$t('problem_solved'),
                    "value": "problem_solved"
                },
                {
                    // статус "Проблема не вирішена"
                    "label": this.$t('problem_not_solved'),
                    "value": "problem_not_solved"
                }
            ],
            selectedSum: "",
            resultSum: "5000",
            sumSevice: [ // вартість послуги
                {
                    "label": "0",
                    "value": "0"
                },
                {
                    "label": "200",
                    "value": "200"
                },
                {
                    "label": "900",
                    "value": "900"
                },
                {
                    "label": "1000",
                    "value": "1000"
                },
                {
                    "label": "1500",
                    "value": "1500"
                }
            ]
        }
    },
    props: ["number"],
    components: { modal, Multiselect },
    created(){
        if(this.number){
            this.form.userPhone = Number(this.number);
        }
    },
    methods: {
        add() {
            /**
                * Додавання нової послуги з урахуванням валідації полів.
            */

            if (!this.form.userPhone) {
                this.$toast.error(this.$t('EnterNumber'));
            }
            if (!this.form.nomenclatureName) {
                this.$toast.error(this.$t('EnterService'));
            }
            if (!this.form.status) {
                this.$toast.error(this.$t('EnterStatus'));
            }
            if (!this.form.description) {
                this.$toast.error(this.$t('EnterDesc'));
            }
            if (!this.form.price) {
                this.$toast.error(this.$t('EnterPrice'));
            } 
            if (this.form.userPhone && this.form.nomenclatureName && this.form.description && this.form.status && this.form.price) {
                apiServe.addService(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('close')
                        this.eventBus.emit('saveService', true)
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var errorText = 'Помилка створення послуги'; 
                        var statusRequest = result.status;
                        var api = 'addService';
                        var fileRequest = 'src/views/services/createservice.vue';
                        var params = this.form;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
})
</script>
