<template>
    <div class="wrapper">
        <div class="main" @dragover="onDragOver($event)" @drop="onDrop($event)">

        </div>
        <div class="sidebar">
            <div v-for="items, i in this.blocks" :key="i" :id="i" class="nodes" :style="`border: 2px solid ${items.color}`" draggable="true" @dragstart="onDragStart($event)"> 
                <div class="nodesDot nodesDot-top" v-if="i != 2" :style="`border: 2px solid ${items.color}; background-color: ${items.color}`">
                    
                </div>
                <p>{{ items.label }}</p>
                <div class="nodesDot nodesDot-bottom" v-if="i != 0" :style="`border: 2px solid ${items.color}; background-color: ${items.color}`">
                    
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    data() {
        return{
            blocks: [
                {
                    label: "Input node",
                    color: '#0041d0'
                },
                {
                    label: "Default node",
                    color: "#1a192b"
                },
                {
                    label: "Output node",
                    color: "#ff0072"
                }
            ],
            onBoard: [],
        }
    },
    created() {
        console.log(this.blocks)
    },
    methods: {
        onDragStart(event) {
            console.log('onDragStart', event)
            event.dataTransfer.setData('text/plain', event.target.id);
        },
        onDragOver(event) {
            console.log('onDragOver')
            event.preventDefault();
        },
        onDrop(event) {
            const id = event.dataTransfer.getData('text');
            console.log(event, id)
            const draggableElement = document.getElementById(id);
            const dropzone = event.target;
            dropzone.appendChild(draggableElement);
            event.dataTransfer.clearData();
        }
    }
}
</script>

<style scoped>
.wrapper{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 8fr 2fr;
}
.sidebar {
    background-color: rgba(16,185,129,.75);
}
.nodes{
    width: 30%; 
    display: inline-block; 
    margin: 1.5%;
    text-align: center;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 1px 3px 7px 0px;
}
.nodes > p {
    margin: 0;
    font-size: 16px;
}
.nodesDot{
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 100px;
}
.nodesDot-top{
    transform: translate(120px, -26px)
}
.nodesDot-bottom{
    transform: translate(120px, 26px)
}
.onBoard{

}
</style>