<template>
    <modallg @close="this.$emit('close')">
        <template v-slot:title>{{ $t('Edit') }}</template>
        <template v-slot:head-button>
            <div class="dropdown" v-if="this.user.userid === this.form.workerId && (form.status !== 'completed' && form.status !== 'confirmed')">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <button class="dropdown-item" v-if="form.status !== 'completed' && form.status !== 'confirmed'" @click="removeRequest(this.form)">{{ $t('Delete') }}</button>
                    <!-- <button class="dropdown-item" v-if="form.status === 'completed'" @click="approve">{{ $t('Confirm') }}</button> -->
                </div>
            </div>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col>
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('task') }}</b>
                        </b-card-title>
                        <h5>- {{ form.taskId }}</h5>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('Status') }}</b>
                        </b-card-title>
                        <h4 :class="this.mutateStatus(form.status).color" style="font-size:14px"> {{ this.mutateStatus(form.status).name }}</h4>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-card>
                        <b-card-title class="mb-1">
                            <b>{{ $t('desc') }}</b>
                        </b-card-title>
                        <div v-html="form.comment"></div>
                    </b-card>
                </b-col>
            </b-row>
            
        </template>
        <template v-slot:footer-bottom>
            <!-- <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
            <button @click="showComplete = true" v-if="form.status == 'new'" class="btn btn-success">{{ $t('done') }} <i class="ri-arrow-right-line align-bottom me-1"></i></button>
            <button class="btn btn-success" v-if="form.status === 'completed'" @click="approve">{{ $t('Confirm') }}</button>
        </template>
    </modallg>

    <comment 
        v-if="showComplete"
        @close="showComplete = false"
        @complete="complete" 
    />

    <dialogdelete 
        v-if="showdialogdelete == true" 
        :obj="this.obj" 
        @close="showdialogdelete = false" 
        @remove = "remove"
    />

</template>

<script>
import modallg from '@/components/modal/modal-lg'
import comment from './comment'
import { Projects } from '@/API.js';
import { storeS } from '@/store.js'
import dialogdelete from '@/components/globaltable/actions/dialogdelete.vue';
import { mutateStatusProject } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Projects();

export default {
components: { modallg, dialogdelete, comment },
props:['obja'],
data() {
    return {
        form:{},
        obj: '',
        showComplete: false,
        showdialogdelete:false,
    };
},
created(){
    this.form = this.obja
},
methods: {
    removeRequest(e) {
        this.obj = e
        this.showdialogdelete = true
        
    },
    remove(e) {
        apiServe.deleteProjectEdits(this.form.projectEditId).then(result=>{
            if(result.status == 'done') {
                this.$toast.success(this.$t('Removed'))
                this.$emit('close')
                this.$emit('getdata')
            } else {
                this.$toast.error(this.$t('error'))
            }
        })
    },
    mutateStatus(e) {
        return mutateStatusProject(e)
    },
    complete(e){
        apiServe.completeProjectEdit(this.form.projectEditId, e).then(result => {
            if(result.status == 'done'){
                // this.$emit('close');
                this.showComplete = false;
                this.eventBus.emit('UpdateCard', true)
                this.$toast.success(this.$t('done'));
            } else {
                this.$toast.error(this.$t('err'))
            }
        })
    },
    approve() {
        // var count = this.
        apiServe.approveProjectEdits(this.form.projectEditId).then(response=>{
            if(response.status == 'done') {
                this.$toast.success(this.$t('Confirmed'))
                // this.$emit('close')
                this.$emit('closeModal')
                // this.eventBus.emit('UpdateCard', true)
                // this.$emit('checkEdits')
            } else {
                this.$toast.error('error')
            }
        })
    }
},
computed: {
    user() {
        return storeS.userbase
    }
}
}
</script>