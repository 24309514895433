<template>
    <!-- 
        Модальне вікно закриття завдання
    -->

    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>

            <!-- Швидки коментарі -->
            <div class="mb-3">
                <label class="form-label mb-2">{{ $t('QuickComments') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = $t('done')" style="margin-right: 5px;">{{ $t('done') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="form.comment = 'done'" style="margin-right: 5px;">done</span>
                </div>
            </div>

            <!-- Токен від приватбанку -->
            <div class="mb-3" v-if="this.taskName == 'receiving_token'">
                <label class="form-label">{{ $t('Token') }}</label>
                <textarea class="form-control" rows="3" v-model="form.emailToken" ></textarea>
            </div>

            <!-- Коментар закриття завдання -->
            <div class="mb-3">
                <label class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>

        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium"  @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            <button 
                type="button" 
                class="btn btn-primary" 
                @click="toComplete"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { storeS } from '@/store'

export default{
    components: {
        dialogBox,
    },
    props: ['id', 'taskName'],
    data(){
        return{
            form: {
                comment: '',
                emailToken: ''
            },
        }
    },
    created() {

    },
    methods: {
        toComplete(){
            this.$emit('toComplete', this.id, this.form)
            this.$emit('close')
        },
    }
}
</script>