<template>
    <modal :title="form.taxTitle">
        <template v-slot:body>
            <b-card>
                <b-row>
                    <b-col lg="6">
                        <h4 class="fs-18 fw-semibold">{{ $t('Name') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.taxTitle" disabled>
                            <button @click="copy(form.taxTitle)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h4 class="fs-18 fw-semibold">{{ $t('TaxRate') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.value" disabled>
                            <button @click="copy(form.value)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6" v-if="form.value.length == 1">
                        <h4 class="fs-18 fw-semibold">{{ $t('TaxTypeCode') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="mutateTaxTypeCode(form.cno)" disabled>
                            <button @click="copy(form.cno)" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                    <b-col lg="6" v-if="form.percent">
                        <h4 class="fs-18 fw-semibold">{{ $t('TaxPercentage') }}</h4>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :value="form.percent + '%'" disabled>
                            <button @click="copy(form.percent + '%')" class="btn btn-success" type="button"><i class="bx bxs-copy-alt"></i></button>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/modal-lg'
import { mutateTaxTypeCode } from '@/usabilityScripts/globalMutate'
import {copy} from '@/usabilityScripts/copy.js'

export default{
    props: ['obj'],
    components: {
        modal
    },
    data(){
        return{
            form: ''
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        mutateTaxTypeCode(e){
            return mutateTaxTypeCode(e)
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    }
}
</script>