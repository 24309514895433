<template>
    <b-card>
        <div class="d-flex">
            <h6 class="card-title fw-semibold mb-0 flex-grow-1">{{ $t('devices') }}</h6>
            <div class="flex-shrink-0">
                <button type="button" 
                :class="`btn btn-soft-${showdevice == true ? 'success' : 'info'} btn-sm`"  
                @click="showdevice = true"><i class="ri-add-box-fill me-1 align-bottom"></i>{{$t('Add')}}</button>
            </div>
        </div>
        <ul class="list-unstyled vstack gap-3 mb-0 mt-2">
            <li v-for="(item, index) in this.formDevice" :key="index" class="click" @click="open(item)">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                        <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-info text-black">#{{index}}</div></div>
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="mb-1"><b>{{$t(item.way_problem)}}</b></h6>
                        <span class="text-muted">{{item.user_phone}}</span>
                    </div>
                </div>
            </li>
        </ul>
    </b-card>

    <adddevice 
        v-if="showdevice == true" 
        @close="closeItem" 
        @addDeivce="addDeivce"
        @editDeivce="editDeivce"
        @removeDeivce="removeDeivce"
        :numbers="form.userPhones"
        :itemdevice="itemdevice"
    />

</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import adddevice from '@/components/ticketdeviceblock/index.vue'
import { Tickets } from '@/API'

let apiServe = new Tickets();

export default{
    props: ['obj', 'objDevice'],
    components: { Multiselect, adddevice },
    data(){
        return{
            form: {},
            formDevice: [],
            shownum: false,
            showdevice: false,
            itemdevice: ""
        }
    },
    created(){
        this.getdata();
    },
    methods:{
        getdata(){
            this.form = this.obj
            this.formDevice = this.objDevice
        },
        open(e){
            this.$emit('opendevice', e)
        },
        editnum(){
            this.$emit('editnum', this.form);
            this.shownum = false
        },
        addDeivce(e){
            apiServe.addDevice(this.form.ticketId, e).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(`Done`);
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            })
        },
        editDeivce(e){
            for(var value in this.form.devices){
                if(this.form.devices[value].id == e.id){
                    this.form.devices[value] = e;
                }
            }
        },
        removeDeivce(e) {
            for(var value in this.form.devices){
                if(this.form.devices[value].id == e){
                    this.form.devices.splice(value, 1)
                }
            }
        },
        closeItem(){
            this.showdevice = false;
            this.itemdevice = ''
        },
        open(e){
            this.itemdevice = e
            this.showdevice = true
        },
    }
}
</script>

<style>
.click{
    cursor: pointer;
}
</style>