<template>
    <!-- 
        Модальне вікно скасування картки штрафу 
    -->

    <dialogBox >
        <template v-slot:title>{{ $t('Comment') }}</template>
        <template v-slot:body>
            <!-- Причина скасування -->
            <div class="mb-3">
                <label for="VertimeassageInput" class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" ></textarea>
            </div>
        </template>
        <template v-slot:footer-bottom>
            
            <!-- 
                Кнопка "Скасувати" 
                - закриває вікно
            -->
            <button 
                class="btn link-danger fw-medium"  
                @click="this.$emit('close')"
            >
                <i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }}
            </button>


            <!-- 
                Кнопка "Додати" 
                - анулює штраф та додає коментар (якщо вказали)
            -->
            <button 
                type="button" 
                class="btn btn-success" 
                @click="toCancel"
            >
                {{ $t('Add') }}
            </button>

        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'

export default{
    components: {
        dialogBox
    },
    data(){
        return{
            form: {
                comment: ''
            }
        }
    },
    methods: {
        toCancel(){
            // звертаємось до методу скасування з API
            // закриваємо вікно
            
            this.$emit('toCancel', this.form)
            this.$emit('close')
        }
    }
}
</script>