<template>
    <!-- 
        Модальне вікно з відображення картки зміни 
    -->

    <dialogBox @close="this.$emit('close')">
        <template v-slot:title>{{ $t('shift') }}</template>
        <template v-slot:body>
            <b-row>

                <!-- Початок зміни -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('ShiftisOpen') }}</h4>
                        <input class="form-control" v-model="form.openDatetime" disabled />
                    </div>
                </b-col>

                <!-- Кінець зміни -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('ShiftisClose') }}</h4>
                        <input class="form-control" :value="form.closeDatetime == false ? $t('ShiftStillOpen') : form.closeDatetime" disabled />
                    </div>
                </b-col>

                <!-- Причина запізнення -->
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('ReasonBeingLate') }}</h4>
                        <input class="form-control" v-model="form.lateComment" disabled />
                    </div>
                </b-col>
            </b-row>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модального вікна

export default {
    props: ['objcard'],
    components: {
        dialogBox
    },
    data(){
        return{
            form: ''
        }
    },
    created(){
        this.form = this.objcard
    },
    methods: {
    }
}
</script>