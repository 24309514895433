<template>
    <div class="border-start sticky-block">
        <div style="margin: 10px;" class="border-bottom" v-if="formRequest">
            <div>
                <div class="d-flex align-items-center" @click="showClient = !showClient" style="cursor: pointer;">
                    <div class="flex-grow-1" >
                        <h5 class="card-title mb-2">{{ $t('Account') }} 
                            <i :class="`${showClient ? 'ri-arrow-down-line' : 'ri-arrow-right-line'} fs-16`"></i>
                        </h5>
                    </div>
                </div>
                <div class="card-body text-center" v-if="showClient">
                    <h5 class="mt-2 mb-1">{{ form.user_name }}</h5>
                    <p class="text-muted">{{ Number(form.user_phone)}}</p>

                    <ul class="list-inline mb-0">
                        <li class="list-inline-item avatar-xs" v-if="this.info.workerJob == 'managercallcenter' || this.perms[2000]">
                            <a href="javascript:void(0);" class="avatar-title badge-soft-success text-success fs-15 rounded" style="border:none" @click="call(String(this.form.user_phone), this.form)">
                                <i class="ri-phone-line"></i>
                            </a>
                        </li>
                        <li class="list-inline-item avatar-xs" >
                            <a @click="sendMAIL()" class="avatar-title badge-soft-danger text-danger fs-15 rounded">
                                <i class="ri-mail-line"></i>
                            </a>
                        </li>
                        <li class="list-inline-item avatar-xs">
                            <a @click="sendSMS()" class="avatar-title badge-soft-warning text-warning fs-15 rounded">
                                <i class="ri-question-answer-line"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <b-tabs 
                v-if="showClient"
                pills
            >
                <b-tab :title="$t('main')">
                    <div style="padding: 0px 12px;;">
                        <div class="d-flex align-items-center py-3">
                            <div class="flex-grow-1">
                                <div>
                                    <h5 class="fs-14 mb-1 text-muted">{{ $t('name') }}</h5>
                                    <p class="fs-15 text-black mb-0 fw-normal">{{ form.users[0].user_name }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center py-2">
                            <div class="flex-grow-1">
                                <div>
                                    <h5 class="fs-14 mb-1 text-muted">{{ $t('phone_number') }}</h5>
                                    <p class="fs-15 text-black mb-0 fw-normal">{{ Number(form.user_phone) }}</p>
                                </div>
                            </div>
                            <!-- <div class="flex-shrink-0 ms-2">
                                <button type="button" class="btn btn-sm btn-outline-success"><i class="ri-user-add-line align-middle"></i></button>
                            </div> -->
                        </div>
                        <div class="d-flex align-items-center py-2">
                            <div class="flex-grow-1">
                                <div>
                                    <h5 class="fs-14 mb-1 text-muted">{{ $t('Status') }}</h5>
                                    <p :class="`fs-15 mb-0 ${this.mutateStatus(form.users[0].user_clientstatus).color}`">{{ this.mutateStatus(form.users[0].user_clientstatus).name }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center py-2">
                            <div class="flex-grow-1">
                                <div>
                                    <h5 class="fs-14 mb-1 text-muted">{{ $t('mail') }}</h5>
                                    <p class="fs-15 text-black mb-0 fw-normal">{{ form.users[0].user_email == null ? "-": form.users[0].user_email }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center py-2" v-if="form.refidacc">
                            <div class="flex-grow-1">
                                <div>
                                    <h5 class="fs-14 mb-1 text-muted">{{ $t('CameFrom') }}</h5>
                                    <p class="fs-15 text-black mb-0 fw-normal">{{ form.refidacc }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab :title="'Detail'">
                    <div>
                        <div class="d-flex align-items-center py-3">
                            <div class="flex-grow-1">
                                <div>
                                    <h5 class="fs-14 mb-1 text-muted">{{ $t('Registered') }}</h5>
                                    <p class="fs-13 text-black mb-0">{{ form.user_date }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
        <div style="margin: 10px;">
            <div class="border-bottom">
                <div class="d-flex align-items-center mb-4" @click="showPlan = !showPlan" style="cursor: pointer;">
                    <div class="flex-grow-1" >
                        <h5 class="card-title mb-0">{{ $t('tariffPlan') }} 
                            <i :class="`${showPlan ? 'ri-arrow-down-line' : 'ri-arrow-right-line'} fs-16`"></i>
                        </h5>
                    </div>
                </div>

                <b-tabs 
                    pills
                    v-if="showPlan"
                >
                    <b-tab :title="$t('currentTariff')">
                        <div style="padding-top: 10px;">
                            <h5 class="fs-14 mb-1 text-muted">{{ $t('TariffisFormed') }} - </h5>
                            <p class="fs-13 text-black mb-0">{{ $t('AmountOutlet') }} - <b>{{ dataCurrentTariff.store_count_active }}</b> ({{ dataCurrentTariff.count_tt }})</p>
                            <p class="fs-13 text-black mb-0">{{ $t('Number_of_goods') }} - <b>{{ dataCurrentTariff.products_counts_count }}</b></p>
                            <p class="fs-13 text-black mb-0">{{ $t('Number_of_technical_cards') }} - <b>{{ dataCurrentTariff.cookcard_count }}</b></p>
                            <infoblocks :obj="this.blocks" style="margin-top:15px;" />
                        </div>
                    </b-tab>
                    <b-tab :title="$t('PossibleTariff')">
                        <div style="padding-top: 10px;">
                            <h5 class="fs-14 mb-1 text-muted">{{ $t('TariffisFormed') }} - </h5>
                            <p class="fs-13 text-black mb-0">{{ $t('AmountOutlet') }} - <b>{{ dataPossibleTariff.count_tt }}</b></p>
                            <p class="fs-13 text-black mb-0">{{ $t('Number_of_goods') }} - <b>{{ dataPossibleTariff.products_counts_count }}</b></p>
                            <p class="fs-13 text-black mb-0">{{ $t('Number_of_technical_cards') }} - <b>{{ dataPossibleTariff.cookcard_count }}</b></p>
                            <infoblocks :obj="this.blocksPossible" style="margin-top:15px;" />
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import infoblocks from '@/components/info/blocks-small'
import { storeS } from '@/store';
import { mutateStatusAccounts } from '@/usabilityScripts/globalMutate.js'

export default({
    props: ['obj', 'formRequest'],
    components: {
        infoblocks
    },
    data() {
        return {
            form: '',
            currency: '',
            showClient: true,
            showPlan: true,
            testDate: '',
            dataCurrentTariff: {
                store_count_active: '0',
                count_tt: "0",
                products_counts_count: "0",
                cookcard_count: "0",
            },
            dataPossibleTariff: {
                count_tt: "0",
                products_counts_count: "0",
                cookcard_count: "0",
            },
            blocks: [
                {
                    title: this.$t('tariffPlan'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning",
                    copy: true,
                    copyText: this.$t('YourTariffPlan')
                },
                {
                    title: this.$t('PaidUpTo'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success",
                    copy: true,
                    copyText: this.$t('YourRateHasPaid')
                },
                {
                    title: this.$t('SubscriptionFee'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger",
                    copy: true,
                    copyText: this.$t('sumYourPlan')
                },
                {
                    title: this.$t('CurrentBalance'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                    copy: true,
                    copyText: this.$t('OnYourAccount')
                },
                {
                    title: this.$t('Lacks'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "primary",
                    copy: true,
                    copyText: this.$t('LacksCopy')
                }
            ],
            blocksPossible: [
            {
                    title: this.$t('tariffPlan'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning",
                    copy: true,
                    copyText: this.$t('YourTariffPlan')
                },
                {
                    title: this.$t('SubscriptionFee'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger",
                    copy: true,
                    copyText: this.$t('sumYourPlan')
                },
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        call(e, form) {
            
            this.$emit('call', e, form)
        },
        sendSMS() {
            this.$emit('opensmsbox')
        },
        sendMAIL() {
            this.$emit('openmailbox')
        },
        getdata(){
            this.form = this.obj
            this.currency = this.form.license.currency;

            this.blocks[0].value = this.form.license.tariff_name
            this.blocks[2].value = this.form.license.summ + " " + this.currency
            // this.blocks[2].value = this.form.license.active_account
            this.blocks[1].value = this.testPeriod();
            if(this.form.license.tariff_name == 'FREE'){
                this.blocks[1].title = this.$t('TrialPeriodUpTo');
            } else if(this.form.license.tariff_name != 'FREE' && this.form.license.credit != "0000-00-00" && this.form.license.activeAccount < this.form.license.credit){
                this.blocks[1].title = this.$t('CreditPeriodUpTo');
                this.blocks[1].value = this.creditPeriod();
            }
            this.blocks[3].value = this.form.license.balance + " " + this.currency
            this.blocks[4].value =( this.form.license.balance - this.form.license.summ ).toFixed(1) + " " + this.currency
            this.currentTariff()
            this.possibletariff()
        },
        currentTariff(){
            this.dataCurrentTariff.store_count_active = this.form.license.store_count_active;
            this.dataCurrentTariff.count_tt = this.form.license.sklad.length;
            this.dataCurrentTariff.products_counts_count = this.form.license.sklad[0].products_count;
            this.dataCurrentTariff.cookcard_count = this.form.license.sklad[0].cookcard_count;
            var activeData = this.form.license.sklad.filter(item => item.status == 'Тарифицируется');
            for(var i=0; i < activeData.length; i++){
                if(Number(this.dataCurrentTariff.products_counts_count) < Number(activeData[i].products_count)){
                    this.dataCurrentTariff.products_counts_count = activeData[i].products_count
                }

                if(Number(this.dataCurrentTariff.cookcard_count) < Number(activeData[i].cookcard_count)){
                    this.dataCurrentTariff.cookcard_count = activeData[i].cookcard_count
                }
            }
        },
        possibletariff(){
            this.dataPossibleTariff.count_tt = this.form.license.sklad.length;
            this.dataPossibleTariff.products_counts_count = this.form.license.sklad[0].products_count;
            this.dataPossibleTariff.cookcard_count = this.form.license.sklad[0].cookcard_count;
            for(var i=0; i < this.form.license.sklad.length; i++){
                if(Number(this.dataPossibleTariff.products_counts_count) < Number(this.form.license.sklad[i].products_count)){
                    this.dataPossibleTariff.products_counts_count = this.form.license.sklad[i].products_count
                }

                if(Number(this.dataPossibleTariff.cookcard_count) < Number(this.form.license.sklad[i].cookcard_count)){
                    this.dataPossibleTariff.cookcard_count = this.form.license.sklad[i].cookcard_count
                }
            }

            this.blocksPossible[0].value = this.tariff_name(this.get_tariff());
            this.blocksPossible[1].value = this.getSumMonth() + " " + this.currency
        },
        testPeriod() {
            if(this.form.license.tariff_name == "FREE") {
                var reg = this.form.user_date;
                var dateRegister = new Date(reg);
                dateRegister.setDate(dateRegister.getDate() + 14);
                this.testDate = dateRegister.toLocaleDateString('es-CL')
                return this.testDate
            } else { 
                this.testDate = this.form.license["active_account"];
                return this.testDate
            }
        },
        creditPeriod() {
            if(this.form.license.credit != "0000-00-00") {
                var creditDate_user = this.form.license.credit;
                var over_credit = new Date(creditDate_user);
                over_credit.setDate(over_credit.getDate() + 4);
                var dateStr =
                over_credit.getFullYear() + "-" +
                ("00" + (over_credit.getMonth() + 1)).slice(-2) + "-" +
                ("00" + (over_credit.getDate())).slice(-2);    
            }
            return dateStr
        },
        tariff_name: function (get_tariff){
            switch (get_tariff) {
                case 0:
                return "FREE";
                case 1:
                return "ВUSINESSMAN";
                case 2:
                return "CORPORATION";
                case 3:
                return "BIGBOSS";
                case 4:
                return "ENTERPRISE";
            }
            return get_tariff
        },
        tariff_cash:function (thistariff){
                    switch(thistariff){
                    case 0:
                    return "0";
                    case 1:
                    return "20";
                    case 2:
                    return "35";
                    case 3:
                    return "50";
                    case 4:
                    return "50";
                }
        },
        get_tariff:function (thistariff){
            thistariff = 1;
            switch (true) {
            case (this.dataPossibleTariff.count_tt == 1):
                if(thistariff < 1){
                    thistariff = 1;
                }
                break;
                case (this.dataPossibleTariff.count_tt > 1 && this.dataPossibleTariff.count_tt <= 49):
                    if(thistariff < 2){
                        thistariff = 2;
                    }
                break;
                
                case (this.dataPossibleTariff.count_tt > 1 ):
                    if(thistariff < 4){
                        thistariff = 4;
                    }
                break;
            }
            switch (true) {
            case (this.dataPossibleTariff.products_count >= 1 && this.dataPossibleTariff.products_count <= 700):
                if(thistariff < 1){
                    thistariff = 1;
                }
                break;
                case (this.dataPossibleTariff.products_count >= 701 && this.dataPossibleTariff.products_count <= 1150):
                    if(thistariff < 2){
                        thistariff = 2;
                    }
                break;

                case (this.dataPossibleTariff.products_count >= 1151 && this.dataPossibleTariff.products_count <= 3500):
                    if(thistariff < 3){
                        thistariff = 3;
                    }
                break;
                case (this.dataPossibleTariff.products_count >= 3501 ):
                    if(thistariff < 4){
                        thistariff = 4;
                    }
                break;
            }
            switch(true){
                case (this.dataPossibleTariff.cookcard_count >= 1 && this.dataPossibleTariff.cookcard_count <= 70):
                    if(thistariff < 1){
                        thistariff = 1;
                    }
                break;

                case (this.dataPossibleTariff.cookcard_count >= 71 && this.dataPossibleTariff.cookcard_count <= 135):
                    if(thistariff < 2){
                        thistariff = 2;
                    }
                break;

                case (this.dataPossibleTariff.cookcard_count >= 121 && this.dataPossibleTariff.cookcard_count <= 300):
                    if(thistariff < 3){
                        thistariff = 3;
                    }
                break;
                case (this.dataPossibleTariff.cookcard_count >= 301 ):
                    if(thistariff < 4){
                        thistariff = 4;
                    }
                break;
            }
            return thistariff;
        },

        getsumm: function (num_tariff) {
            // var ex_tariff = 0;
            // var ex_summ = 0;
            num_tariff = this.get_tariff();
            const cash_tariff = this.tariff_cash(num_tariff);
            var bases;
            var ex_bases;
            var result;
            var objs = {};
            var summ_products_counts_plus;
            var summ_cookcard_plus;
            var ratio = '26'
            
            switch (num_tariff) {
                case 0:
                    // ex_tariff = 0;
                    // ex_summ = 0;
                    return objs;
                case 1:
                    // На сколько товаров больше
                    if(this.dataPossibleTariff.products_count > 600){
                        summ_products_counts_plus = (this.dataPossibleTariff.products_count - 600) * 0.033;
                    } else {
                        summ_products_counts_plus = "0";
                    }
                    //На сколько техкарт больше
                    if(this.dataPossibleTariff.cookcard_count > 60){
                        summ_cookcard_plus = (this.dataPossibleTariff.cookcard_count - 60) * 0.33;
                    } else {
                        summ_cookcard_plus = "0"
                    }
                    ex_bases = Number(cash_tariff) + Number(summ_products_counts_plus) + Number(summ_cookcard_plus);
                    bases = (ex_bases * this.form.license.coeficient ) * ratio;
                    objs["month"] = bases.toFixed(0);
                    return objs;
                case 2:
                    //На сколько товаров больше
                    if(this.dataPossibleTariff.products_count > 1000){
                        summ_products_counts_plus = (this.dataPossibleTariff.products_count - 1000) * 0.03;
                    } else {
                        summ_products_counts_plus = 0;
                    }

                    //На сколько техкарт больше
                    if(this.dataPossibleTariff.cookcard_count > 120){
                        summ_cookcard_plus = (this.dataPossibleTariff.cookcard_count - 120) * 0.23;
                    } else {
                        summ_cookcard_plus = 0
                    }

                    //Базовая цена + товары + техкарты
                    // ex_tariff = 2;
                    ex_bases = Number(cash_tariff) + Number(summ_products_counts_plus) + Number(summ_cookcard_plus);
                    bases = (ex_bases * this.form.license.coeficient ) * ratio;
                    result = bases.toFixed(0) / 100 * 70 * (Number(this.dataPossibleTariff.count_tt) - 1 ) + bases;
                    objs["month"] = result.toFixed(0);
                    return objs;
                case 3:
                    //На сколько товаров больше
                    if(this.dataPossibleTariff.products_count > 3500){
                        summ_products_counts_plus = (this.dataPossibleTariff.products_count - 3500) * 0.014;
                    } else {
                        summ_products_counts_plus = 0;
                    }

                    //На сколько техкарт больше
                    if(this.dataPossibleTariff.cookcard_count > 300){
                        summ_cookcard_plus = (this.dataPossibleTariff.cookcard_count - 300) * 0.16;
                    } else {
                        summ_cookcard_plus = 0
                    }

                    //Базовая цена + товары + техкарты
                    ex_bases = Number(cash_tariff) + Number(summ_products_counts_plus) + Number(summ_cookcard_plus);
                    bases = (ex_bases * this.form.license.coeficient ) * ratio;
                    result = bases.toFixed(0) / 100 * 70 * (Number(this.dataPossibleTariff.count_tt) - 1 ) + bases;
                    objs["month"] = result.toFixed(0);
                    return objs;
                case 4:
                    //На сколько товаров больше
                    if(this.dataPossibleTariff.products_count > 3500){
                        summ_products_counts_plus = (this.dataPossibleTariff.products_count - 3500) * 0.012;
                    } else {
                        summ_products_counts_plus = 0;
                    }

                    //На сколько техкарт больше
                    if(this.dataPossibleTariff.cookcard_count > 300){
                        summ_cookcard_plus = (this.dataPossibleTariff.cookcard_count - 300) * 0.14;
                    } else {
                        summ_cookcard_plus = 0
                    }

                    //Базовая цена + товары + техкарты
                    ex_bases = Number(cash_tariff) + Number(summ_products_counts_plus) + Number(summ_cookcard_plus);
                    bases = (ex_bases * this.form.license.coeficient ) * ratio;
                    result = bases.toFixed(0) / 100 * 70 * (Number(this.dataPossibleTariff.count_tt) - 1 ) + bases;
                    objs["month"] = result.toFixed(0);
                    return objs;
        }},
        getSumMonth: function () {
            //вывод суммы за месяц
            var sum = 0;
            sum = this.getsumm().month
            return sum
        },
        getSumDiscont: function() {
            //вывод суммы скидки
            var summd = 0;
            if (this.dataPossibleTariff.count_tt != 0 && this.dataPossibleTariff.products_count != 0 && this.dataPossibleTariff.cookcard_count != 0) {
            summd = ((this.getsumm().month / 100 * Number(this.staticMonths)) * Number(this.StaticTip));
            }
            return  summd.toFixed(0);
        },
        getSumNotSale: function () {
            //вывод суммы без скидки
            var sum = 0;
            sum = (this.getsumm().month * this.staticMonths).toFixed(0)
            return sum
        },
        getSumTotal: function () {
            //вывод сумма всего
            var sum = 0;
            sum = (this.getSumNotSale() - this.getSumDiscont()).toFixed(0)
            return sum
        },
        getNameTariff: function () {
            //выводит название тарифа
            return this.tariff_name(this.get_tariff())
        },
        mutateStatus(e){
            return mutateStatusAccounts(e)
        }
    },
    computed: {
        info() {
            return storeS.userbase
        },
        perms() {
        return storeS.perms
        }
    }
})
</script>
