<template>
    <!-- Блок інформації про тарифний план -->

    <div class="border-start sticky-block">
        <div style="margin: 10px;">
            <div class="border-bottom">
                <!-- Заголовок тарифного плану з можливістю згортання/розгортання -->
                <div class="d-flex align-items-center mb-4" @click="showPlan = !showPlan" style="cursor: pointer;">
                    <div class="flex-grow-1" >
                        <h5 class="card-title mb-0">{{ $t('tariffPlan') }} 
                            <i :class="`${showPlan ? 'ri-arrow-down-line' : 'ri-arrow-right-line'} fs-16`"></i>
                        </h5>
                    </div>
                </div>

                <!-- Вкладки для відображення поточного та можливого тарифного плану -->
                <b-tabs 
                    pills
                    v-if="showPlan"
                >
                    <!-- Вкладка "Поточний тариф" -->
                    <b-tab :title="$t('currentTariff')">
                        <div style="padding-top: 10px;">
                            <h5 class="fs-14 mb-1 text-muted">{{ $t('TariffisFormed') }} - </h5>
                            <p class="fs-13 text-black mb-0">{{ $t('AmountOutlet') }} - <b>{{ dataCurrentTariff.store_count_active }}</b> ({{ dataCurrentTariff.count_tt }})</p>
                            <p class="fs-13 text-black mb-0">{{ $t('Number_of_goods') }} - <b>{{ dataCurrentTariff.products_counts_count }}</b></p>
                            <p class="fs-13 text-black mb-0">{{ $t('Number_of_technical_cards') }} - <b>{{ dataCurrentTariff.cookcard_count }}</b></p>
                            
                            <!-- Компонент для відображення додаткової інформації (У вигляді кольорових блоків) -->
                            <infoblocks :obj="this.blocks" style="margin-top:15px;" />
                        </div>
                    </b-tab>

                    <!-- Вкладка "Можливий тариф" -->
                    <b-tab :title="$t('PossibleTariff')">
                        <div style="padding-top: 10px;">
                            <h5 class="fs-14 mb-1 text-muted">{{ $t('TariffisFormed') }} - </h5>
                            <p class="fs-13 text-black mb-0">{{ $t('AmountOutlet') }} - <b>{{ dataPossibleTariff.count_tt }}</b></p>
                            <p class="fs-13 text-black mb-0">{{ $t('Number_of_goods') }} - <b>{{ dataPossibleTariff.products_counts_count }}</b></p>
                            <p class="fs-13 text-black mb-0">{{ $t('Number_of_technical_cards') }} - <b>{{ dataPossibleTariff.cookcard_count }}</b></p>

                            <!-- Компонент для відображення додаткової інформації (У вигляді кольорових блоків) -->
                            <infoblocks :obj="this.blocksPossible" style="margin-top:15px;" />
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import infoblocks from '@/components/info/blocks-small'

export default({
    props: ['obj', 'data', 'formRequest', 'client'],
    components: {
        infoblocks
    },
    data() {
        return {
            form: '', 
            currency: '', // Валюта
            showPlan: true, // Відобразити тарифний план
            testDate: '', // Дата тестового періоду
            dataCurrentTariff: {
                store_count_active: '0',
                count_tt: "0",
                products_counts_count: "0",
                cookcard_count: "0",
            },
            dataPossibleTariff: {
                count_tt: "0",
                products_counts_count: "0",
                cookcard_count: "0",
            },
            blocks: [ // Масив блоків інформації
                {
                    // Тарифний план
                    title: this.$t('tariffPlan'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning",
                    copy: true,
                    copyText: this.$t('YourTariffPlan')
                },
                {
                    // Сплачено до
                    title: this.$t('PaidUpTo'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success",
                    copy: true,
                    copyText: this.$t('YourRateHasPaid')
                },
                {
                    // Абонентська плата
                    title: this.$t('SubscriptionFee'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger",
                    copy: true,
                    copyText: this.$t('sumYourPlan')
                },
                {
                    // Поточний баланс
                    title: this.$t('CurrentBalance'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                    copy: true,
                    copyText: this.$t('OnYourAccount')
                },
                {
                    // Не вистачає
                    title: this.$t('Lacks'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "primary",
                    copy: true,
                    copyText: this.$t('LacksCopy')
                },
                {
                    // Зареєстровано
                    title: this.$t('Registered'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "primary",
                    copy: true,
                    copyText: this.$t('YourTariffPlan')
                },
            ],
            blocksPossible: [
                {
                    // Тарифний план
                    title: this.$t('tariffPlan'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning",
                    copy: true,
                    copyText: this.$t('YourTariffPlan')
                },
                {
                    // Абонентська плата
                    title: this.$t('SubscriptionFee'),
                    type: 'colorsBlock',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger",
                    copy: true,
                    copyText: this.$t('sumYourPlan')
                },
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            // Ініціалізація даних компонента

            this.form = this.obj

            // Перевірка, чи форма не є невизначеною
            if(this.form != undefined){

                // Визначення валюти
                this.currency = this.form.license ? this.form.license.currency : '$';

                // Присвоєння значення блоку інформації про тариф
                this.blocks[0].value = this.form.license.tariffName

                // Присвоєння значення блоку інформації про підсумкову суму користувача
                this.blocks[2].value = this.form.license.userSumm + " " + this.currency

                // Присвоєння значення блоку інформації про період тестового користування
                this.blocks[1].value = this.testPeriod();

                // Перевірка, чи тариф є безкоштовним
                if(this.form.license.tariffName == 'FREE'){
                    this.blocks[1].title = this.$t('TrialPeriodUpTo');
                } else if(this.form.license.tariffName != 'FREE' && this.form.license.credit != "0000-00-00" && this.form.license.activeAccount < this.form.license.credit){
                    // Перевірка, чи є кредитний період і присвоєння відповідного заголовка і значення блоку

                    this.blocks[1].title = this.$t('CreditPeriodUpTo');
                    this.blocks[1].value = this.creditPeriod();
                }
                
                // Присвоєння значення блоку інформації про баланс користувача
                this.blocks[3].value = Number(this.form.license.userBalance).toFixed(2) + " " + this.currency
                
                // Присвоєння значення блоку інформації про залишок коштів на рахунку
                this.blocks[4].value =( this.form.license.userBalance - this.form.license.userSumm ).toFixed(1) + " " + this.currency
                
                // Присвоєння значення блоку інформації про дату реєстрації клієнта
                this.blocks[5].value =  this.client.personalData.register_date
                
                // Визначення поточного тарифу
                this.currentTariff()
                
                // Визначення можливого тарифу
                this.possibletariff()
            }
        },
        currentTariff(){
            // Обчислення поточного тарифу

            // Присвоєння кількості активних торгових точок блоку даних поточного тарифу
            this.dataCurrentTariff.store_count_active = this.form.license.countActiveTradepoints;

            // Присвоєння загальної кількості торгових точок блоку даних поточного тарифу
            this.dataCurrentTariff.count_tt = this.form.license.countTradepoints;

            // Присвоєння кількості товарів блоку даних поточного тарифу (взято з першої торгової точки)
            this.dataCurrentTariff.products_counts_count = this.form.tradepoints[0].countProducts ? this.form.tradepoints[0].countProducts : this.form.tradepoints[0].productsCount;

            // Присвоєння кількості технічних карток блоку даних поточного тарифу (взято з першої торгової точки)
            this.dataCurrentTariff.cookcard_count = this.form.tradepoints[0].countTechcards;

            // Фільтрація активних торгових точок зі статусом 'Tariffed'/Тарифікуються
            var activeData = this.form.tradepoints.filter(item => item.tradepointStatus == 'Tariffed');

            // Прохід через всі активні торгові точки для знаходження максимальної кількості товарів та технічних карток
            for(var i=0; i < activeData.length; i++){

                // Перевірка та оновлення кількості товарів, якщо нове значення більше
                if(Number(this.dataCurrentTariff.products_counts_count) < Number(activeData[i].productsCount)){
                    this.dataCurrentTariff.products_counts_count = activeData[i].productsCount
                }

                // Перевірка та оновлення кількості технічних карток, якщо нове значення більше
                if(Number(this.dataCurrentTariff.cookcard_count) < Number(activeData[i].countTechcards)){
                    this.dataCurrentTariff.cookcard_count = activeData[i].countTechcards
                }
            }
        },
        possibletariff(){
            // Обчислення можливого тарифу

            // Присвоєння загальної кількості торгових точок блоку даних можливого тарифу
            this.dataPossibleTariff.count_tt = this.form.license.countTradepoints;

            // Присвоєння кількості товарів блоку даних можливого тарифу (взято з першої торгової точки)
            this.dataPossibleTariff.products_counts_count = this.form.tradepoints[0].countProducts ? this.form.tradepoints[0].countProducts : this.form.tradepoints[0].productsCount;

            // Присвоєння кількості технічних карток блоку даних можливого тарифу (взято з першої торгової точки)
            this.dataPossibleTariff.cookcard_count = this.form.tradepoints[0].countTechcards;

            // Прохід через всі торгові точки для знаходження максимальної кількості товарів та технічних карток
            for(var i=0; i < this.form.tradepoints.length; i++){

                // Перевірка та оновлення кількості товарів, якщо нове значення більше
                if(Number(this.dataPossibleTariff.products_counts_count) < Number(this.form.tradepoints[i].productsCount)){
                    this.dataPossibleTariff.products_counts_count = this.form.tradepoints[i].productsCount
                }

                // Перевірка та оновлення кількості технічних карток, якщо нове значення більше
                if(Number(this.dataPossibleTariff.cookcard_count) < Number(this.form.tradepoints[i].countTechcards)){
                    this.dataPossibleTariff.cookcard_count = this.form.tradepoints[i].countTechcards
                }
            }

            // Присвоєння значення назви тарифу блоку можливого тарифу
            this.blocksPossible[0].value = this.tariff_name(this.get_tariff());

            // Присвоєння значення суми за місяць та валюти блоку можливого тарифу
            this.blocksPossible[1].value = this.getSumMonth() + " " + this.currency
        },
        testPeriod() {
            // Отримання тестового періоду

            // Перевірка, чи тариф є безкоштовним (FREE)
            if(this.form.license.tariffName == "FREE") {
                
                // Отримання дати реєстрації користувача
                var reg = this.data.personalData.register_date;
                var dateRegister = new Date(reg);

                // Додавання 14 днів до дати реєстрації
                dateRegister.setDate(dateRegister.getDate() + 14);

                // Конвертація дати в строку та повернення результату
                this.testDate = dateRegister.toLocaleDateString('es-CL')
                console.log('comp testDate', this)
                return this.testDate
            } else { 
                // Якщо тариф не є безкоштовним, отримання активного періоду
                this.testDate = this.form.license.activeAccount;
                return this.testDate
            }
        },
        creditPeriod() {
            // Отримання кредитного періоду

            // Перевірка, чи існує кредитний період
            if(this.form.license.credit != "0000-00-00") {
                // Отримання дати закінчення кредитного періоду
                var creditDate_user = this.form.license.credit;
                var over_credit = new Date(creditDate_user);

                // Додавання 4 днів до дати закінчення кредитного періоду
                over_credit.setDate(over_credit.getDate() + 4);

                // Формування рядка з датою та повернення результату
                var dateStr =
                over_credit.getFullYear() + "-" +
                ("00" + (over_credit.getMonth() + 1)).slice(-2) + "-" +
                ("00" + (over_credit.getDate())).slice(-2);    
            }
            return dateStr
        },
        tariff_name: function (get_tariff){
            // Визначення назви тарифу
            switch (get_tariff) {
                case 0:
                return "FREE";
                case 1:
                return "ВUSINESSMAN";
                case 2:
                return "CORPORATION";
                case 3:
                return "BIGBOSS";
                case 4:
                return "ENTERPRISE";
            }
            return get_tariff
        },
        tariff_cash: function (thistariff){
            // Розрахунок готівкової вартості тарифу
            switch(thistariff){
                case 0:
                return "0";
                case 1:
                return "20";
                case 2:
                return "35";
                case 3:
                return "50";
                case 4:
                return "50";
            }
        },
        get_tariff:function (thistariff){
            // Отримання тарифу в залежності від умов

            thistariff = 1;
            switch (true) {
            case (this.dataPossibleTariff.count_tt == 1):
                if(thistariff < 1){
                    thistariff = 1;
                }
                break;
                case (this.dataPossibleTariff.count_tt > 1 && this.dataPossibleTariff.count_tt <= 49):
                    if(thistariff < 2){
                        thistariff = 2;
                    }
                break;
                
                case (this.dataPossibleTariff.count_tt > 1 ):
                    if(thistariff < 4){
                        thistariff = 4;
                    }
                break;
            }
            switch (true) {
            case (this.dataPossibleTariff.products_count >= 1 && this.dataPossibleTariff.products_count <= 700):
                if(thistariff < 1){
                    thistariff = 1;
                }
                break;
                case (this.dataPossibleTariff.products_count >= 701 && this.dataPossibleTariff.products_count <= 1150):
                    if(thistariff < 2){
                        thistariff = 2;
                    }
                break;

                case (this.dataPossibleTariff.products_count >= 1151 && this.dataPossibleTariff.products_count <= 3500):
                    if(thistariff < 3){
                        thistariff = 3;
                    }
                break;
                case (this.dataPossibleTariff.products_count >= 3501 ):
                    if(thistariff < 4){
                        thistariff = 4;
                    }
                break;
            }
            switch(true){
                case (this.dataPossibleTariff.cookcard_count >= 1 && this.dataPossibleTariff.cookcard_count <= 70):
                    if(thistariff < 1){
                        thistariff = 1;
                    }
                break;

                case (this.dataPossibleTariff.cookcard_count >= 71 && this.dataPossibleTariff.cookcard_count <= 135):
                    if(thistariff < 2){
                        thistariff = 2;
                    }
                break;

                case (this.dataPossibleTariff.cookcard_count >= 121 && this.dataPossibleTariff.cookcard_count <= 300):
                    if(thistariff < 3){
                        thistariff = 3;
                    }
                break;
                case (this.dataPossibleTariff.cookcard_count >= 301 ):
                    if(thistariff < 4){
                        thistariff = 4;
                    }
                break;
            }
            return thistariff;
        },

        getsumm: function (num_tariff) {
            // Розрахунок суми за місяць
            
            num_tariff = this.get_tariff();
            const cash_tariff = this.tariff_cash(num_tariff);
            var bases;
            var ex_bases;
            var result;
            var objs = {};
            var summ_products_counts_plus;
            var summ_cookcard_plus;
            var ratio = '40'
            
            switch (num_tariff) {
                case 0:
                    // ex_tariff = 0;
                    // ex_summ = 0;
                    return objs;
                case 1:
                    // На сколько товаров больше
                    if(this.dataPossibleTariff.products_count > 600){
                        summ_products_counts_plus = (this.dataPossibleTariff.products_count - 600) * 0.033;
                    } else {
                        summ_products_counts_plus = "0";
                    }
                    //На сколько техкарт больше
                    if(this.dataPossibleTariff.cookcard_count > 60){
                        summ_cookcard_plus = (this.dataPossibleTariff.cookcard_count - 60) * 0.33;
                    } else {
                        summ_cookcard_plus = "0"
                    }
                    ex_bases = Number(cash_tariff) + Number(summ_products_counts_plus) + Number(summ_cookcard_plus);
                    bases = (ex_bases * this.form.license.coeficient ) * ratio;
                    objs["month"] = bases.toFixed(0);
                    return objs;
                case 2:
                    //На сколько товаров больше
                    if(this.dataPossibleTariff.products_count > 1000){
                        summ_products_counts_plus = (this.dataPossibleTariff.products_count - 1000) * 0.03;
                    } else {
                        summ_products_counts_plus = 0;
                    }

                    //На сколько техкарт больше
                    if(this.dataPossibleTariff.cookcard_count > 120){
                        summ_cookcard_plus = (this.dataPossibleTariff.cookcard_count - 120) * 0.23;
                    } else {
                        summ_cookcard_plus = 0
                    }

                    //Базовая цена + товары + техкарты
                    // ex_tariff = 2;
                    ex_bases = Number(cash_tariff) + Number(summ_products_counts_plus) + Number(summ_cookcard_plus);
                    bases = (ex_bases * this.form.license.coeficient ) * ratio;
                    result = bases.toFixed(0) / 100 * 70 * (Number(this.dataPossibleTariff.count_tt) - 1 ) + bases;
                    objs["month"] = result.toFixed(0);
                    return objs;
                case 3:
                    //На сколько товаров больше
                    if(this.dataPossibleTariff.products_count > 3500){
                        summ_products_counts_plus = (this.dataPossibleTariff.products_count - 3500) * 0.014;
                    } else {
                        summ_products_counts_plus = 0;
                    }

                    //На сколько техкарт больше
                    if(this.dataPossibleTariff.cookcard_count > 300){
                        summ_cookcard_plus = (this.dataPossibleTariff.cookcard_count - 300) * 0.16;
                    } else {
                        summ_cookcard_plus = 0
                    }

                    //Базовая цена + товары + техкарты
                    ex_bases = Number(cash_tariff) + Number(summ_products_counts_plus) + Number(summ_cookcard_plus);
                    bases = (ex_bases * this.form.license.coeficient ) * ratio;
                    result = bases.toFixed(0) / 100 * 70 * (Number(this.dataPossibleTariff.count_tt) - 1 ) + bases;
                    objs["month"] = result.toFixed(0);
                    return objs;
                case 4:
                    //На сколько товаров больше
                    if(this.dataPossibleTariff.products_count > 3500){
                        summ_products_counts_plus = (this.dataPossibleTariff.products_count - 3500) * 0.012;
                    } else {
                        summ_products_counts_plus = 0;
                    }

                    //На сколько техкарт больше
                    if(this.dataPossibleTariff.cookcard_count > 300){
                        summ_cookcard_plus = (this.dataPossibleTariff.cookcard_count - 300) * 0.14;
                    } else {
                        summ_cookcard_plus = 0
                    }

                    //Базовая цена + товары + техкарты
                    ex_bases = Number(cash_tariff) + Number(summ_products_counts_plus) + Number(summ_cookcard_plus);
                    bases = (ex_bases * this.form.license.coeficient ) * ratio;
                    result = bases.toFixed(0) / 100 * 70 * (Number(this.dataPossibleTariff.count_tt) - 1 ) + bases;
                    objs["month"] = result.toFixed(0);
                    return objs;
        }},
        getSumMonth: function () {
            /// Отримання суми за місяць
            var sum = 0;
            sum = this.getsumm().month
            return sum
        },
        getSumDiscont: function() {
            // Отримання суми зі знижкою
            var summd = 0;
            if (this.dataPossibleTariff.count_tt != 0 && this.dataPossibleTariff.products_count != 0 && this.dataPossibleTariff.cookcard_count != 0) {
            summd = ((this.getsumm().month / 100 * Number(this.staticMonths)) * Number(this.StaticTip));
            }
            return  summd.toFixed(0);
        },
        getSumNotSale: function () {
            // Отримання суми без знижки
            var sum = 0;
            sum = (this.getsumm().month * this.staticMonths).toFixed(0)
            return sum
        },
        getSumTotal: function () {
            // Отримання загальної суми
            var sum = 0;
            sum = (this.getSumNotSale() - this.getSumDiscont()).toFixed(0)
            return sum
        },
        getNameTariff: function () {
            // Отримання назви тарифу
            return this.tariff_name(this.get_tariff())
        },
    },
    computed: {
    }
})
</script>
